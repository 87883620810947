/*
|--------------------------------------------------------------------------
| Financial > shared > props > travelSettings
|--------------------------------------------------------------------------
*/

'use strict';

import constants from './constants';
import {genPropsFromCollection} from '~/js/helpers/Vuex';

export default {

  pay_travel_distance: false,
  maximum_km: 0,
  minimum_travel_distance_fee: 0,
  travel_distance_fees: genPropsFromCollection(
    constants.TRANSLATOR_LVLS, {
      fee_per_km: 0,
      taxable_fee_per_km: 0
    }
  ),

  pay_travel_time: false,
  travel_time_chunk_hours: 0,
  travel_time_fees: genPropsFromCollection(
    constants.TRANSLATOR_LVLS, {
      fee_per_hour: 0,
    }
  ),

  pay_fixed_time: false,
  fixed_time_hours: 0,

  round_travel_time: false,
  rounding_minutes: 0,

  pay_inconvenience_travel_time: false,
  unpaid_travel_hours: 0,
  minimum_travel_hours: 0,
  maximum_travel_hours: 0,
  inconvenience_travel_time_fees: genPropsFromCollection(
    constants.TRANSLATOR_LVLS, {
      fee_per_half_hour: 0
    }
  ),

  pay_same_town_travel_time: true,
  specific_cities: [],

}
