/*
|--------------------------------------------------------------------------
| UI > state
|--------------------------------------------------------------------------
*/

'use strict';
export default {
  colorTheme: 'default',
  language:'en'
}
