/*
|--------------------------------------------------------------------------
| Store > Sector > Customer Type
|--------------------------------------------------------------------------
|
| A file that manages all the properties and abilities in relation
| to Customer Type Model and other related values.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {extendObj} from '~/js/helpers/Common'
import state from './state'

import actions from './actions'
import bread from '../bread'

const mutations = {};
const getters = {};
export default {
    namespaced: true,
    state: extendObj(bread.state, state),
    getters: extendObj(bread.getters, getters),
    mutations: extendObj(bread.mutations, mutations),
    actions: extendObj(bread.actions, actions),

} // End of export default

