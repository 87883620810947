export const expiredChartData = {
    type: 'bar',
    data: null,
    options: {
      legend: {
        display: false
      },
      plugins: {
        datalabels: {
          color: '#ffffff',
          font: {
            size: 15,
          }
        }
      },
      responsive: true,
      lineTension: 1,
      scales: {
        yAxes: [{
          ticks: {
            display: false,
          }
        }],
        xAxes: [{
          gridLines: {
            color: false
          },
        }]
      }
      /*plugins: {
        datalabels: {
          anchor: 'end',
          align: 'top',
          formatter: Math.round,
          font: {
            weight: 'bold'
          }
        }
      }*/
    }
  }
  
  export default expiredChartData;