<template>
  <div>
    <h3>{{ $t('txt_booking_notes_files') }} </h3>

    <el-form :model='iValue'>

      <el-row>
        <el-col :md="10">
          <h4>{{$t('booking_message')}}</h4>
          <el-form-item label=''>
            <el-input type='textarea' v-model="iValue.message" @change="trimWhiteSpace"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="12" :offset="2">
          <h4>{{$t('files')}}</h4>
          <el-form-item label=''>
            <aws-file-upload v-model="iValue.files" @progress="handleProgress" @deleteFile="handleDeleteFile"/>
          </el-form-item>
        </el-col>
        <!--      <el-form-item label=''>-->
        <!--        <el-button type='primary' @click="handleUpdateMessage" :disabled="disabledSubmit">{{$t('update_message_btn')}}</el-button>-->
        <!--      </el-form-item>-->
      </el-row>
    </el-form>
  </div>
</template>

<script>

  import AwsFileUpload from '~/components/displays/AwsFileUpload.vue'
  import {isEmpty} from '../../../../js/helpers/Common'
  import {mapActions} from 'vuex'

  export default {
    components: {
      AwsFileUpload
    },
    props: {
      value: {
        type: Object,
        default() {
          return {
            message: '',
            files: [],
            booking_id: ''
          }
        }
      }
    },
    data() {
      return {
        disabledSubmit: false
      }
    },
    computed: {
      iValue: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      }
    },
    methods: {
      ...mapActions('booking', ['deleteMessageFile', 'updateBookingMessage', 'addBookingMessage']),
      async handleDeleteFile(e) {

        if (!isEmpty(e.id)) {
          try {
            let res = await this.deleteMessageFile({
              booking_message_id: e.pivot.booking_message_id,
              file_id: e.id
            });
            this.iValue.files = this.iValue.files.filter((file) => {
              return file.id !== e.id
            })
          } catch (e) {
            console.log(e)
          }
        }
      },

      handleUpdateMessage() {
        if (!isEmpty(this.iValue.id)) {
          let payload = {
            id: this.iValue.id,
            message_files: this.iValue.files,
            message: this.iValue.message
          };
          this.updateBookingMessage(payload).then(r => {
            this.$store.dispatch('booking/getBooking', this.$route.params.id)
          });
        } else {

          if (!isEmpty(this.iValue.files) && isEmpty(this.iValue.message)) {
            this.iValue.message = 'Se bifogad fil';
          }
          let payload = {
            booking_id: this.$route.params.id,
            message_files: this.iValue.files,
            message: this.iValue.message
          };
          this.addBookingMessage(payload).then(r => {
            this.$store.dispatch('booking/getBooking', this.$route.params.id)
          })
        }

      },

      handleProgress(e) {
        this.disabledSubmit = e > 0 && e < 100;
      },

      trimWhiteSpace(e) {
        this.iValue.message = e.trim();
      }

    }
  }
</script>
