<template>
    <div class="app-form app-fields user-customer-settings-fields">

        <h3 class="text-center">{{$t('text_fill_settings')}}</h3>

        <div class="app-splitter two-cols">

            <!-- Booking Types-->
            <el-form-item :id="genContainerId('booking_types',formElId)"
                          :label="$t('booking_types')" class="label-block">
                <el-select :id="genFieldId('booking_types',formElId)"
                           v-model="iValue.booking_types"
                           :placeholder="$t('select_booking_types_customer')"
                           multiple>
                    <el-option :label="$t('phone')" value="phone"/>
                    <el-option :label="$t('physical')" value="physical"/>
                    <el-option :label="$t('video')" value="video"/>
                    <el-option :label="$t('video_skype')" value="video_skype"/>
                    <el-option :label="$t('video_physical')" value="video_physical"/>
                    <el-option :label="$t('convey')" value="convey"/>
                    <el-option :label="$t('text_translation')" value="text_translation"/>
                </el-select>
            </el-form-item>

            <!-- Customer Type -->
            <el-form-item :id="genContainerId('customer_type',formElId)"
                          :label="$t('customer_type')" class="label-block">
                <el-select :id="genFieldId('customer_type',formElId)"
                           filterable
                           v-model="iValue.customer_type"
                           :placeholder="$t('select_customer_type')"
                           @focus="handleClickCustomerTypeSelection"
                >
                    <el-option v-for="(v) in customerTypeOpts"
                               :key="v.id"
                               :value="v.id"
                               :label="v.name"/>
                </el-select>
            </el-form-item>

            <!-- Blacklisted Translators -->
            <el-form-item :id="genContainerId('blacklisted_users',formElId)"
                          :label="$t('blacklisted_translators')" class="label-block">
                <el-select :id="genFieldId('blacklisted_users',formElId)"
                           v-model="iValue.blacklisted_users"
                           :placeholder="$t('please_select_blacklisted_translator')" multiple filterable
                           @remove-tag="removeBlackListedTranslator"
                           @focus="handleClickBlacklistedTranslatorSelection"
                >
                    <el-option v-for="(v) in translatorOpts"
                               :key="v.id"
                               :value="v.id"
                               :value-key="v.id"
                               :label="v.name">
                        {{v.name}}
                    </el-option>
                </el-select>
            </el-form-item>

            <!-- Town for project -->
            <el-form-item :id="genContainerId('town_id',formElId)"
                          :label="$t('text_town_for_project')" class="label-block">
                <el-select :id="genFieldId('town_id',formElId)"
                           v-model="iValue.towns"
                           filterable
                           multiple
                           :placeholder="$t('please_select_town_physical_booking')"
                           @focus="handleClickTownSelection"
                >
                    <el-option v-for="(v) in townOpts"
                               :key="v.id"
                               :value="v.id"
                               :label="v.name"/>
                </el-select>
            </el-form-item>

            <!-- Cost Place -->
            <el-form-item :id="genContainerId('cost_place',formElId)"
                          :label="$t('cost_place')" class="label-block">
                <el-input :id="genFieldId('cost_place' ,formElId)"
                          v-model="iValue.cost_place"
                          :placeholder="$t('please_select_customer _cost_place')"/>
            </el-form-item>

        </div> <!-- /.app-splitter two-columns -->

    </div> <!-- /.app-fields user-customer-settings-fields -->
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import {populate} from '~/js/helpers/Vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      // Prop v-model of the component.
      value: {
        type: Object,
        required: true
      },

      // String reference for form element ID.
      formElId: {
        type: String,
        default: 'user_customer_settings'
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters({
        translatorOpts: 'translator/listAsOptions',
        customerTypeOpts: 'customerType/listAsOptions',
        townOpts: 'town/listAsOptions'
      }),

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        }
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions({
        loadTranslatorOpts: 'translator/browseAsOptions',
        loadCustomerTypeOpts: 'customerType/browseAsOptions',
        loadTownOpts: 'town/browseAsOptions',
        deleteBlackListedUser: 'blacklistedUsers/deleteBlackListedUser'
      }),

      /**
       * Handler when the customer type selector field was clicked.
       *
       * @return {void}
       */
      handleClickCustomerTypeSelection () {
        populate(this, 'loadCustomerTypeOpts', 'customerTypeOpts', {
          ref: 'Customer Type Options Listing'
        });
      },

      /**
       * Handler when the blacklisted translator selector field was clicked.
       *
       * @return {void}
       */
      handleClickBlacklistedTranslatorSelection () {
        populate(this, 'loadTranslatorOpts', 'translatorOpts', {
          ref: 'Translator Options Listing'
        });
      },

      /**
       * Handler when the town selector field was clicked.
       *
       * @return {void}
       */
      handleClickTownSelection () {
        populate(this, 'loadTownOpts', 'townOpts', {
          ref: 'Towns Options Listing'
        });
      },
      /**
       * Delete black listed translator
       */
      removeBlackListedTranslator(v) {
        let obj = this.value.blacklisted_users_temp.filter((id) =>  {
              return id.translator_id === v
          })
          if(obj) this.deleteBlackListedUser(obj[0].id);
    }
  }
  }
</script>
