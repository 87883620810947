/*
|--------------------------------------------------------------------------
| Store > Form
|--------------------------------------------------------------------------
|
| A Class file that manages all the properties and abilities in relation
| to Form options and other related values.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

// import APICaller from '../helpers/APICaller';
// import {isEmpty} from '../helpers/Common';
import countryIso from './selections/CountryIso';
import invoiceType from './selections/InvoiceType';
import invoiceSalaryVerification from './selections/InvoiceSalaryVerification';

const translator_levels = [
    'layman', 'read_courses', 'certified', 'certified_in_health', 'certified_in_law'
];

const translator_levels_extra = _.clone(translator_levels);
translator_levels_extra.push('deluxe');

/*
|--------------------------------------------------------------------------
| State
|--------------------------------------------------------------------------
*/
let state = {
    translator_levels,
    translator_levels_extra,
    booking_types: ['phone', 'physical','video'],
    per_page: [50, 35, 20, 15, 10, 5],
    countryIso,
    languages: [],
    invoiceType,
    invoiceSalaryVerification
}; // End of store > state

/*
|--------------------------------------------------------------------------
| Getters
|--------------------------------------------------------------------------
*/
let getters = {
    translatorLevelOpts: (state) => state.translator_levels,
    translatorLevelExtraOpts: (state) => state.translator_levels_extra,
    bookingTypeOpts: (state) => state.booking_types,
    perPageOpts: (state) => state.per_page,
    countryIsoOpts: (state) => state.countryIso,
    languageOpts: (state) => state.languages,
    invoiceTypes: (state) => state.invoiceType,
    invoiceSalaryVerification: (state) => state.invoiceSalaryVerification
}; // End of store > getters

/*
|--------------------------------------------------------------------------
| Mutations
|--------------------------------------------------------------------------
*/
let mutations = {

    /**
     * Set the languages state.
     *
     * @param  {object} state
     *   : the state property the current Vuex Object
     *
     * @param  {object} payload
     *   : an object which contains option values
     *
     * @return {void}
     */
    setLanguages: function (state, payload) {
        state.languages = payload;
    },

}; // End of store > mutations

/*
|--------------------------------------------------------------------------
| Actions
|--------------------------------------------------------------------------
*/
let actions = {

    /**
     * Load the languages data from the API.
     *
     * @param  {object} context
     *   : the context of $store
     *
     * @param  {object} [payload]
     *   : an object which contains option values
     *
     * @return {Promise}
     */
    loadLanguages(context, payload) {

        let method = 'GET';
        let url = 'https://aes-dev-api.digitaltolk.com:8443/api/languages?all';

        // noinspection ES6ModulesDependencies
        axios({url, method})
            .then((r) => {
                context.commit('setLanguages', r.data);
            });
    }, // End of loadLanguageOpts() method

}; // End of store > actions

export default {
    namespaced: true,
    state, getters, mutations, actions,
} // End of export default