/*
|--------------------------------------------------------------------------
| Store > Messaging-Centre > Notification > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
let composeProps = {
  status: "",
  title: "",
  content: "",
  recipients: [],
  message_link: "",
  text_link: "",
  sent_via: 1,
  sent_by: "",
  schedule: "",
  attachments:[]
};

export default {

  composeFresh: _.cloneDeep(composeProps),
  compose: _.cloneDeep(composeProps),
  notification: [],
  notificationState: {},
  statusOption: [
    {
      value: 3,
      label: "Email"
    },
    {
      value: 4,
      label: "Fax"
    },
    {
      value: 2,
      label: "Push"
    },
    {
      value: 1,
      label: "SMS"
    }
  ]
};
