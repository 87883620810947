/*
|--------------------------------------------------------------------------
| Store > Messaging-Centre > Blogs > Mutation
|--------------------------------------------------------------------------
|
| This file contains the mutation of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/

"use strict";
import { mapProps } from "~/js/helpers/Common";

export default {
  /**
   * Method to generate blog list
   *
   * @param   {array}  state
   *
   * @return  {void}
   */
  setBlogList: function(state, payload) {
    state.blogs = payload;
  },

  /**
   * Method to map the value target entry's values into the form.
   *
   * @param  {object} state
   *   : the state property the current Vuex Object.
   *
   * @return {void}
   */
  setCompose(state) {
    mapProps(state.blog, state.compose);
  },

  setLoading: (state, payload) => {
    state.loading = payload;
  },
  /**
   * Method to map single blog with compose
   *
   * @param   {object}  state
   *
   * @return  {void}
   */
  setBlog(state, payload) {
    state.blog = payload;
    state.blog.tags = payload.tags == null ? [] : payload.tags;
  },

  /**
   * Method to reset the compose prop to its pristine state.
   *
   * @param  {object} state
   *   : the state property the current Vuex Object.
   *
   * @return {void}
   */
  resetCompose(state) {
    state.compose = _.cloneDeep(state.composeFresh);
  }
};
