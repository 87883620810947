<template>
<el-form-item label="">
    <el-radio-group :value="iValue" tabindex="0">
      <el-radio :label="null">All</el-radio>
      <el-radio :label="'male'">Male</el-radio>
      <el-radio :label="'female'">Female</el-radio>
    </el-radio-group>
</el-form-item>
</template>

<script>
export default {
    name:'booking-type-radio-group',
    props:{
        value:{
            default:''
        }
    },
    computed:{
        iValue:{
            get(){
                return this.value
            },
            set(v){
                this.$emit('input',v)
            }
        }
    },
    methods:{

    }
}
</script>

<style>

</style>
