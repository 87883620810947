/*
|--------------------------------------------------------------------------
| store > bread > state
|--------------------------------------------------------------------------
|
| This file contains the state property of current Vuex scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

export default {

  model_info: {
    name: 'Base', // Just a name label value to know where this script is used from.
    module: '', // The equivalent module name.
    table: '' // The equivalent table name used in the database.
  },

  list: {
    data: [],
    pagination: {},
    loading: false,
  },

  listAsOptions: [],

  item: {
    data: {},
    loading: false,
  },

  compose: {},
  composeFresh: {},

  payloadData: {},

  api: {
    browse: {method: 'get', path: '', params: {per_page: 10}},
    browseAsOptions: {params: {all: true}},
    read: {method: 'get', path: '', params: {}, data: {}},
    edit: {method: 'put', path: '', params: {}, data: {}},
    add: {method: 'post', path: '', params: {}, data: {}},
    delete: {method: 'delete', path: '', params: {}, data: {}}
  },

  autoInjectPayload: false,
  autoReflectItem: false,
  autoUpdateList: false
}