/*
|--------------------------------------------------------------------------
| Financial > Shared > methods > resetSettings
|--------------------------------------------------------------------------
| Partial file as a reusable method for it's purpose.
*/

'use strict';

export default function(state) {
  state.settings = _.cloneDeep(state.settingsFresh);
}