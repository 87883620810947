/*
|--------------------------------------------------------------------------
| Route Helpers
|--------------------------------------------------------------------------
|
| This is a helper file which contains all Route related helpers
| that will possibily be called multiple times in different files.
|
| Kind put your methods in here and then just use them on Vuex or Components
| for cleaner implementation.
|
*/

import includes from 'lodash/includes';
import isObject from 'lodash/isObject';
import isNull from 'lodash/isNull';
import set from 'lodash/set';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import forOwn from 'lodash/forOwn';
import forEach from 'lodash/forEach';

/**
 * Helper method to determine wether what current mode is the page currently into.
 *
 * @param {object} vue
 *   The main Vue component instance. Defined by "this".
 *
 * @return {string}
 */
export function checkBreadMode (vue) {

  switch (true) {
    case includes(vue.$route.path, 'add'):
      return 'add';
    case includes(vue.$route.path, 'edit'):
      return 'edit';
    case includes(vue.$route.path, 'list'):
      return 'browse';
    case includes(vue.$route.path, 'item'):
      return 'read';
    case includes(vue.$route.path, 'delete'):
      return 'delete';
    case includes(vue.$route.path, 'duplicate'):
      return 'duplicate';
  }
} // End of checkBreadMode() method

/**
 * Helper method for updating the Query Parameters in the URL.
 *
 * @param {Object} va - The Vue App Instance.
 * @param {*} value - Value to be assigned.
 * @param {string|null} [key] - Key that will be used as the identifier.
 * @param {boolean} [allowNull=false] - Value to determine if nulls will be included in the query param.
 * @returns {void}
 */
export function updateQueryParams (va, value, key = null, allowNull = false) {
  let newQueryParams = {...va.$route.query};

  if (isObject(value)) {
    key = isNull(key) ? '' : key + '_';

    forOwn(value, (v, i) => {
      set(newQueryParams, `${key}${i}`, v);
    });

  } else {
    set(newQueryParams, key, value);
  }

  if (!allowNull) {
    forOwn(newQueryParams, (v, k) => {
      if (isNil(v)) delete newQueryParams[k];
    });
  }

  try {
    va.$router.replace({
      query: newQueryParams
    });
  } catch (e) {
    if (e.name !== 'NavigationDuplicated') throw e;
  }
}

/**
 * Helper method for removing the Query Parameters in the URL.
 *
 * @param {Object} va - The Vue App Instance.
 * @param {string|string[]} keys - Key that will be used as the identifier.
 * @returns {void}
 */
export function removeQueryParams (va, keys) {
  let newQueryParams = {...va.$route.query};

  forEach(keys, (k) => {
    delete newQueryParams[k];
  });

  try {
    va.$router.replace({
      query: newQueryParams
    });
  } catch (e) {
    if (e.name !== 'NavigationDuplicated') throw e;
  }
}

/**
 * Helper method for getting a particular Query Parameter in the URL.
 *
 * @param {Object} va - The Vue App Instance.
 * @param {string} key - Key that will be used as the identifier.
 * @param {*} [def] - Default value to be assign if the query param doesn't exist.
 * @param {Function} [mutator] - Method to mutate the value from the query param.
 * @returns {*}
 */
export function getQueryParam (va, key, def = null, mutator = null) {
  if (isNull(mutator)) { mutator = (v) => v; }

  const queryParamValue = get(va.$route.query, key);

  return !isNil(queryParamValue)
    ? mutator(queryParamValue)
    : def;
}

/**
 * Helper method for getting a particular URL Parameter in the URL.
 *
 * @param {Object} va - The Vue App Instance.
 * @param {string} key - Key that will be used as the identifier.
 * @param {*} [def] - Default value to be assign if the query param doesn't exist.
 * @param {Function} [mutator] - Method to mutate the value from the query param.
 * @returns {*}
 */
export function getUrlParam (va, key, def = null, mutator = null) {
  if (isNull(mutator)) { mutator = (v) => v; }

  const queryParamValue = get(va.$route.params, key);

  return !isNil(queryParamValue)
    ? mutator(queryParamValue)
    : def;
}
