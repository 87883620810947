<template>
    <div class="app-form admin-user-compose-form">

        <el-form :id="formElId" :ref="formElId">

            <!-- Name Field -->
            <el-form-item :id="genContainerId('name',formElId)"
                          class="label-short"
                          :label="$t('name')">

                <el-input :id="genFieldId('name',formElId)"
                          v-model="form.name"
                          :placeholder="$t('please_input_admin_name')"/>
            </el-form-item>

            <!-- Email Field -->
            <el-form-item :id="genContainerId('email',formElId)"
                          class="label-short"
                          :label="$t('email')">

                <el-input :id="genFieldId('email',formElId)"
                          v-model="form.email"
                          :placeholder="$t('please_input_admin_email')"/>
            </el-form-item>

            <!-- Mobile Field -->
            <el-form-item :id="genContainerId('mobile',formElId)"
                          class="label-short"
                          :label="$t('mobile')">

                <el-input :id="genFieldId('mobile',formElId)"
                          v-model="form.mobile"
                          :placeholder="$t('please_input_admin_mobile')"/>
            </el-form-item>

            <!-- Phone Field -->
            <el-form-item :id="genContainerId('phone',formElId)"
                          class="label-short"
                          :label="$t('phone')">

                <el-input :id="genFieldId('phone',formElId)"
                          v-model="form.phone"
                          :placeholder="$t('please_input_admin_phone')"/>
            </el-form-item>

            <!-- Password -->
            <el-form-item :id="genContainerId('password',formElId)"
                          :label="$t('password')" class="label-short" :class="{ 'is-error' : hasError }">
                <el-input :id="genFieldId('password' ,formElId)"
                          v-model="form.password"
                          type="password"
                          @change="validatePassword"
                          :placeholder="$t('please_enter_admin_password')"/>
            </el-form-item>

            <!-- Password Confirmation -->
            <el-form-item :id="genContainerId('password_confirmation',formElId)"
                          :label="$t('password_confirmation')" class="label-short" :class="{ 'is-error' : hasError }">
                <el-input :id="genFieldId('password_confirmation' ,formElId)"
                          v-model="form.password_confirmation"
                          type="password"
                          @change="validatePassword"
                          :placeholder="$t('please_input_admin_conf_password')"/>
            </el-form-item>

            <!-- Is 2FA enabled? -->
            <el-form-item :id="genContainerId('is_2fa_enabled',formElId)"
                          :label="$t('is_2fa_enabled')"
                          class="label-medium">
                <el-switch :id="genFieldId('is_2fa_enabled',formElId)"
                           v-model="form.tfa_enabled" />
            </el-form-item>

            <!-- Show QR code for 2FA? -->
            <el-form-item :id="genContainerId('is_show_2fa_qr',formElId)"
                          :label="$t('show_qr_code_2fa')"
                          class="label-medium">
                <el-switch :id="genFieldId('is_show_2fa_qr',formElId)"
                           v-model="form.show_2fa_qr" />
            </el-form-item>

            <!-- Is User enabled? -->
            <el-form-item :id="genContainerId('is_enabled',formElId)"
                          :label="$t('text_is_admin_user_enabled')"
                          class="label-medium">
                <el-switch :id="genFieldId('is_enabled',formElId)"
                           v-model="form.enabled"/>
            </el-form-item>

            <!-- Save button -->
            <el-form-item :id="genContainerId('save_btn',formElId)">

                <el-button :id="genFieldId('save_btn',formElId)"
                           type="primary"
                           @click="handleSubmit">
                    {{$t('save')}}
                </el-button>
            </el-form-item>

            <h2>{{$t('admin_roles')}}</h2>

            <table class="detail-list">
                <tr v-for="(role, index) in roles" :key="role.id">
                    <td>{{ role.label }} </td>
                    <td><el-switch v-model="selected_roles[index]" :active-value="role.name" @change="handleSubmit"></el-switch>
                        <span v-if="selected_roles[index]">
                            &nbsp;&nbsp;&nbsp;<a href="#" class="text-primary" @click.stop.prevent="handleEditRedirect(role.id, $route.params.id)">Edit</a>
                        </span>
                    </td>
                </tr>
            </table>

        </el-form>

    </div> <!-- /.app-form admin-user-compose-form -->
</template>

<script>
    import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
    import {checkBreadMode} from "../../../js/helpers/Route";
    import {isEmpty} from "../../../js/helpers/Common";
    import { populate } from '../../../js/helpers/Vuex';

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {

                formElId: 'admin_user_compose_form',
                hasError: false,
                selected_roles: []
            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapState('adminUser', {
                form: 'compose',
                payloadData: 'payloadData'
            }),

            ...mapGetters('adminPermissionsRoles', {
                roles: 'listData',
            }),

            ...mapGetters('auth', ['userData']),

            /**
             * Value to determine the current mode whether it's add or edit.
             *
             * @return {string}
             */
            mode() {
                return checkBreadMode(this);
            },

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            ...mapActions('adminUser', {
                addEntry: 'add',
                editEntry: 'edit',
                readEntry: 'read',
            }),

            ...mapActions('adminPermissionsRoles', {
                getRoles: 'browse',
            }),

            ...mapMutations('adminUser', {
                resetForm: 'resetCompose',
            }),

            /**
             * Handle when the form has been submitted.
             *
             * @return {void}
             */
            handleSubmit() {

                // Set the action to be made.
                let action = this.mode + 'Entry';
                let payload = {}; // Set container for payload.

                // If the current compose mode is EDIT, then
                // add the ID of the target into the payload.
                if (this.mode == 'edit') {
                    payload.id = this.$route.params.id;
                }

                // Roles
                let roleIds = [];
                for (let role of this.selected_roles) {
                    if (role) {
                        let roleObj = this.findRoleByRoleName(role);
                        roleIds.push(roleObj.id);
                    }
                }

                this.form.roles = roleIds;
                this.payloadData.roles = roleIds;

                this[action](payload).then(r => {
                    this.$store.dispatch('adminPermissions/getNavItemsFinal');
                });
            }, // End of handleSubmit() method

            /**
             * Helper method for validating the password value.
             *
             * @return {void}
             */
            validatePassword() {

                this.hasError = (isEmpty(this.form.password) || isEmpty(this.form.password_confirmation)) ||
                    this.form.password != this.form.password_confirmation;

            }, // End of validatePassword() method

            validateInsuranceKey() {

            },

            findRoleByRoleName(name) {
                return this.roles.find(r => r.name == name);
            },

            handleEditRedirect(role_id, admin_id) {
                window.location.href = '/#/admin-permissions/by-admin/' + admin_id + '?filter[role_id]=' + role_id;
            }

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {

            this.resetForm();

            populate(this, 'getRoles', 'roles', {
                force: true
            });

            // If the current mode is on edit, fetch the property values for the targeted entry.
            if (this.mode == 'edit') {
                this.readEntry({id: this.$route.params.id, extendParams: {include: 'roles'}}).then((r) => {
                    let activeRoles = r.data.data.user.roles;
                    this.getRoles().then((res) => {
                        for (let i in this.roles) {
                            let hit = false;
                            for (let role of activeRoles) {
                                if (role.name == this.roles[i].name) {
                                    hit = true;
                                }
                            }
                            this.selected_roles.push((hit) ? this.roles[i].name : false);
                        }
                    });
                });
            } else {
                populate(this, 'getRoles', 'roles', {
                    force: true
                });
            }

        }, // End of Component > mounted

    } // End of export default
</script>
