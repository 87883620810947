<template>
  <el-form-item label="">
    <el-radio-group :value="iValue" :aria-label="$t('type')" tabindex="0">
      <el-radio label="phone">Phone</el-radio>
      <el-radio label="physical">Physical</el-radio>
      <el-radio label="video">Video</el-radio>
    </el-radio-group>
  </el-form-item>
</template>

<script>
  export default {
    name: 'booking-type-radio-group',
    props: {
      value: {
        default: ''
      }
    },
    computed: {
      iValue: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        }
      }
    },
    methods: {}
  }
</script>
