<template>
  <div class="app-page login-page">

    <div class="login-page-overlay" :class="{ 'show-overlay' : isFormFocused }">
      <div class="washout"></div>
    </div>

    <div class="app-container">
      <div class="app-row">
        <div class="app-col">

          <template v-if="isShowForm">
            <div class="content-body"
                 @mouseenter="formFocusHandler(true)"
                 @mouseleave="formFocusHandler(false)">

              <h1>
                <img src="/images/defaults/tfv-logo.png" alt="brand-logo">
                <span>TFV Admin</span>
              </h1>

              <div class="form-container">
                <el-form id="login_form" :model="form" ref="login_form" :rules="rules"
                         @keyup.enter.native="handleFormSubmit">

                  <!-- Email -->
                  <el-form-item :label="$t('email')"
                                class="login-email-container label-short"
                                prop="email">
                    <el-input id="login_email"
                              v-model="form.email"
                              placeholder="your@email.com"/>
                  </el-form-item>

                  <!-- Password -->
                  <el-form-item :label="$t('password')"
                                class="login-password-container label-short"
                                prop="password">
                    <el-input id="login_password" v-model="form.password" type="password"
                              placeholder="*******"/>
                  </el-form-item>

                  <!-- Submit Button -->
                  <el-form-item>
                    <el-button id="login_submit_btn"
                               type="primary"
                               icon="el-icon-fa fa-sign-in"
                               @click="handleFormSubmit">
                      {{$t('log_in')}}
                    </el-button>
                  </el-form-item>

                </el-form> <!-- /#login_form -->
              </div> <!-- /.form-container -->


            </div> <!-- /.content-body -->
          </template>
          <template v-else>
            <h2 class="processing-login">Processing {{ $t('login') }}...</h2>
          </template>


        </div> <!-- /.app-col -->
      </div> <!-- /.app-row -->
    </div> <!-- /.app-container -->
    <verify :form="verifyForm" :visible.sync="showVerify"></verify>
  </div> <!-- /.app-page login-page -->
</template>

<script>
  import Verify from '~/components/modals/Verify2FA'

  export default {
    components: {
      Verify
    },
    /*
    |--------------------------------------------------------------------------
    | component > data
    |--------------------------------------------------------------------------
    */
    data () {
      const _this = this;
      return {
        showVerify: false,
        verifyForm: {
          client_id: app_client_id,
          client_secret: app_client_secret,
          grant_type: 'access_code',
          access_code: '',// code from previous step, we don't to show it to user
          verification_key: '' // 6-digits so it's good to have validation if easy.

        },
        form: {
          email: '',
          password: '',
          remember: false,
        },

        isFormFocused: false,

        rules: {
          email: [
            {required: true, message: _this.$t('please_input_your_email'), trigger: 'blur'},
            {type: 'email', message: _this.$t('please_input_correct_email_address'), trigger: ['blur']}
          ],
          password: [
            {required: true, message: _this.$t('please_input_your_password'), trigger: 'blur'},
          ],
        },
      };
    }, // End of component > data

    /*
    |--------------------------------------------------------------------------
    | component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * @returns {boolean}
       */
      isShowForm () {
        return !this.$store.getters['auth/hasAccessToken'];
      }
    }, // End of component > computed

    /*
    |--------------------------------------------------------------------------
    | component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      formFocusHandler (state) {
        this.isFormFocused = state;
      },

      /**
       * Handle when the Log In form was submitted.
       *
       * @return {boolean}
       */
      handleFormSubmit () {

        // Prepare payload data to be sent to API.
        let data = {
          grant_type: app_client_grant_type,
          username: this.form.email,
          password: this.form.password,
          client_id: app_client_id,
          client_secret: app_client_secret,
        };

        this.$store.commit('auth/destroyAccessToken');

        this.$store.dispatch('auth/requestAccessToken', {data})
            .then((r) => {
              if (r.data['token_type'] === 'Access') {
                this.verifyForm.qr_code = r.data.qr_code
                this.verifyForm.access_code = r.data.access_code
                this.showVerify = true
              } else {
                if (r.data.user.type === 1) {
                  window.location.reload();

                } else {
                  this.$notify.error({
                    title: this.$t('error'),
                    message: this.$t('user_is_not_an_admin')
                  });
                }
              }
            })
            .catch((e) => {
              this.$notify.error({
                title: this.$t('unable_to_login'),
                message: window._.isNil(e.response)
                         ? this.$t('email_or_password_is_incorrect')
                         : e.response.data.message,
              });
            });

      }, // End of handleFormSubmit() method

    }, // End of component > methods

    /*
    |--------------------------------------------------------------------------
    | component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      const hasAccessToken = !window._.isNil(localStorage.getItem("app_access_token"));

      // If user has access token already, redirect to dashboard.
      if (hasAccessToken) {
        console.log('There is a token already! Redirecting to Dashboard.');
        this.$router.push({name: 'starting-point'});
      }

    }, // End of component > mounted

  } // End of export default
</script>
