"use strict";

import APICaller from "~/js/helpers/APICaller";

import { gep } from "~/js/helpers/Vuex";
import { extendObjParams, isEmpty } from "~/js/helpers/Common";
import i18n from "~/js/i18n";
import { Notification } from "element-ui";
let apiPrefix = "v3";
export default {
  getInvoiceReminderSetting({ commit }, payload) {
    let params = payload.params;
    return APICaller({
      method: "get",
      params,
      endpoint: gep("invoice-reminder-settings", apiPrefix)
    })
      .then(response => {
        console.log(response.data);

        commit("setCompose", response.data);
      })
      .catch(e => {
        console.log(e.response);
        return e;
      });
  },

  createOrUpdateInvoiceReminderSetting({commit}, payload) {
    return APICaller({
      method: "POST",
      data: payload,
      isDataRaw: true,
      endpoint: gep("invoice-reminder-settings", apiPrefix)
    })
      .then(response => {
        console.log(response.data);

      })
      .catch(e => {
        console.log(e.response);
        return e;
      });
  }
};
