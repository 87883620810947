/*
|--------------------------------------------------------------------------
| store > bread > mutations
|--------------------------------------------------------------------------
|
| This file contains the mutations property of current Vuex scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {mapProps} from '~/js/helpers/Common';

export default {

  /**
   * Set the list properties
   *
   * @param  {object} state
   *   : the state property the current Vuex Object
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {void}
   */
  setList: function (state, payload) {

    // Get the first object key in the data
    let firstKey = Object.keys(payload.data)[0];

    state.list.data = payload.data[firstKey];
    state.list.pagination = payload.meta.pagination;
  },

  /**
   * Set the list as options
   *
   * @param  {object} state
   *   : the state property the current Vuex Object
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {void}
   */
  setListAsOptions: function (state, payload) {

    // Get the first object key in the data
    let firstKey = Object.keys(payload.data)[0];

    // Get the list collection.
    state.listAsOptions = payload.data[firstKey];
  },

  /**
   * Set the list loading state
   *
   * @param  {object} state
   *   : the state property the current Vuex Object
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {void}
   */
  setListLoading: function (state, payload) {
    state.list.loading = payload;
  },

  /**
   * Set the item properties
   *
   * @param  {object} state
   *   : the state property the current Vuex Object
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {void}
   */
  setItem: function (state, payload) {
    state.item.data = payload.data;
  },

  /**
   * Set the item loading state
   *
   * @param  {object} state
   *   : the state property the current Vuex Object
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {void}
   */
  setItemLoading: function (state, payload) {
    state.item.loading = payload;
  },

  /**
   * Method to map the value target entry's values into the form.
   *
   * @param  {object} state
   *   : the state property the current Vuex Object.
   *
   * @return {void}
   */
  setCompose (state) {
    mapProps(state.item.data, state.compose);
  },

  /**
   * Method to map the form values into the payload
   *
   * @param  {object} state
   *   : the state property the current Vuex Object.
   *
   * @return {void}
   */
  setPayloadData (state) {
    mapProps(state.compose, state.payloadData, {append: true});
  },


  /**
   * Method to reset the compose prop to its pristine state.
   *
   * @param  {object} state
   *   : the state property the current Vuex Object.
   *
   * @return {void}
   */
  resetCompose (state) {
    state.compose = _.cloneDeep(state.composeFresh);
  }, // End of resetCompose()

  /**
   * Method to reset the payload prop to its pristine state.
   *
   * @param state - state of the Vuex scope.
   * @return {void}
   */
  resetPayload (state) {
    state.payload = {};
  }

}
