<template>
    <div class="app-display dashboard-company-report-display">
        <div class="app-list booking-list">
          <el-table
            :data="blockedBooking"
            style="width: 100%">
            <el-table-column
              :label="$t('booking')">
              <template slot-scope="scope">
                <span>{{ scope.row.booking_id }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('language')">
              <template slot-scope="scope">
              <span>{{ scope.row.booking.language_name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('customer')">
              <template slot-scope="scope">
              <span>{{ scope.row.booking.booker_name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('date_time')">
              <template slot-scope="scope">
              <span>{{ scope.row.booking.due }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('duration')">
              <template slot-scope="scope">
              <span>{{ scope.row.booking.duration }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('comment')">
              <template slot-scope="scope">
              <span>{{ scope.row.comment }}</span>
              </template>
            </el-table-column>
            <el-table-column
            :label="$t('actions')">
            <template slot-scope="scope">
              <el-button class="circle b" type="success" title="Details" @click="handleDetails(scope)" size="mini" icon="el-icon-info">
              </el-button>
            </template>
          </el-table-column>
          </el-table>
        </div>

    </div> <!-- /.app-display dashboard-company-report-display -->
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {
          ...mapGetters('booking', {
            blockedBooking: 'getUserLockedBookings',
          }),
          ...mapGetters({
            user: 'auth/userData',
          }),
        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {
          ...mapActions('booking', {
            getUserBlockingBookings: 'getUserBlockingBookings'
          }),
          /**
           * method for handling details popups
           * @param {object} scope
           *
           * @return {void}
           */
          handleDetails(scope) {
              this.$router.push({name: 'booking-details', params: {id: scope.row.booking_id}});
          },
        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
          let option = {
              is_locked: 1,
              user_id: this.user.id
          }
          this.getUserBlockingBookings(option);
        }, // End of Component > mounted

    } // End of export default
</script>
