export default {

  /**
   * @param {Object} state - Vue context state.
   * @param {Object[]} types
   *     {
   *         key: string,
   *         label: string
   *     }
   * @return {void}
   */
  setSalaryTypeOpts (state, types) {
    state.salaryTypeOpts = types;
  },

  /**
   * @param {Object} state - Vue context state.
   * @param {Object[]} types
   *     {
   *         key: string,
   *         label: string
   *     }
   * @return {void}
   */
  setSalaryItemTypeOpts (state, types) {
    state.salaryItemTypeOpts = types;
  },

  /**
   * @param {Object} state - Vue context state.
   * @param {Object[]} types
   *     {
   *         key: string,
   *         label: string
   *     }
   * @return {void}
   */
  setInvoiceItemTypeOpts (state, types) {
    state.invoiceItemTypeOpts = types;
  }
}
