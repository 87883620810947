<template>
  <div>
    <base-list-table :table-data="listData" :extra-cols="listCols" :hasDetailsCol="false">
      <template slot="actionsColumn" slot-scope="scope">
        <el-button
          class="circle"
          type="primary"
          :title="$t('details')"
          icon="fa fa-tasks"
          @click="$router.push({props:{default: true}, name:'survey.response.detail',params:{id:scope.row.survey_id,survey:scope.row.survey.title, user:scope.row.user_id}})"
        >
          <!-- <span class="fa fa-pencil"></span> -->
        </el-button>
      </template>
    </base-list-table>

  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { populate, forcePopulate } from "~/js/helpers/Vuex";
import { isEmpty, sd } from "../../../js/helpers/Common";
export default {
  data() {
    const _this=this;
    return {
      listData: [],
      listCols: [
        {
          label: _this.$t('reponder_name'),
          prop: "user.name",
          width: "",
        },
        {
          label: _this.$t('user_type'),
          prop: "user.type",
          width: "",
          mutator(v) {
              return v == 1 ? _this.$t('admin'):
              v == 2 ? _this.$t('customer'):
              v == 3 ? _this.$t('translator'):
              null
          }
        },
        {
          label: _this.$t('submitted_at'),
          prop: "created_at",
          width: "",
          mutator(v) {
              return moment(v).format('YYYY-MM-DD')
           }

        },
      ]
    };
  },
  methods: {
    ...mapActions({
      getUserResponse: "surveyManager/userResponse"
    })
  },
  /**
   * Show all user response
   */
  mounted() {
    this.getUserResponse(this.$route.params.id).then((res) => {
        this.listData = res;
        console.log(this.listData);
    })
  }
};
</script>

<style>
.cell-title {
  display: none;
}
</style>
