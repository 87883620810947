/*
|--------------------------------------------------------------------------
| Store > Admin-Ui-Settings
|--------------------------------------------------------------------------
|
| A Class file that manages all the properties and abilities in relation
| to Feedback.
|
*/


import APICaller from "~/js/helpers/APICaller";

import { gep } from "~/js/helpers/Vuex";
import { Notification } from "element-ui";
import i18n from "../../../i18n";

let apiPrefix = 'v3';
export default {
    /**
     * Get Admin Ui Settings from Setting Table
     * @param {*} context 
     * @param {*} payload 
     */
    getAdminUiSettings(context, payload) {
        let params = {
            "filter[name]": 'adminUiSettings'
        }
        return APICaller({
            method: 'get',
            params,
            endpoint: gep('settings', apiPrefix)
        }).then(resp => {
            if (resp.data.data.settings.length > 0) {
                context.commit('setCompose', resp.data.data)
            }
            else {
                context.commit('resetCompose')
            }
            return resp;

        }).catch(err => {
            console.log(err)
        })
    },
    /**
     * Handle POST Admin Ui Settings
     * @param {*} context 
     * @param {*} payload 
     */
    postAdminUiInSettingsTable(context, payload) {
        const apiOpts = {
            method: "POST",
            endpoint: gep('settings', apiPrefix),
            data: payload,
            isDataRaw: true
        };

        return APICaller(apiOpts)
            .then(() => {
                Notification.success({
                    title: i18n.t("sucesss"),
                    message: "Admin Setting Created"
                });
            })
            .catch(e => {
                Notification.error({
                    title: i18n.t("error"),
                    message: e.response.data.data
                });
            })
    },

    updateAdminUiSettings(context, payload) {
        const apiOpts = {
            method: "PUT",
            endpoint: gep(`settings/` + payload.id, apiPrefix),
            data: payload,
            isDataRaw: true
        };

        return APICaller(apiOpts)
            .then(() => {
                Notification.success({
                    title: i18n.t("sucesss"),
                    message: "Admin UI Setting Updated"
                });
            })
            .catch(e => {
                Notification.error({
                    title: i18n.t("error"),
                    message: e.response.data.data
                });
            })
    }
}