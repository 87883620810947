<template>
    <div class="app-list financial-list">
        <el-row>
            <el-col :span="2" :offset="20">
                <router-link tag="button" to="/settings/price-list/create" @click.stop.prevent title="Create" class="el-button el-button--success">
                    <i class="fa fa-plus"></i> Create
                </router-link>
            </el-col>
        </el-row>

        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="success" title="Edit">
                    <span class="fa fa-pencil"></span>
                </el-button>
                <el-button class="circle" type="danger" title="Delete">
                    <span class="fa fa-trash"></span>
                </el-button>
            </template>
        </base-list-table>
    </div> <!-- /.app-list financial-list -->
</template>

<script>
    //import {mapGetters} from vuex

        export default {

        /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                listCols: [
                    {
                        label: 'Title',
                        prop: 'title',
                        width: '',
                        className: 'title-col',
                    },
                    {
                        label: 'Comment',
                        prop: 'comment',
                        width: '',
                        className: 'comment-col',
                    }
                    ,
                    {
                        label: 'Date',
                        prop: 'date',
                        width: '',
                        className: 'date-col',
                    }
                ],
                listData: [
                    {id:1, title:'Juristbyra', comment:'Lawyers standard pricelist', date: '2017-04-11 23:15:12'},
                    {id:2, title:'Riktprislistan', comment:'', date: '2017-04-11 23:15:12'},
                    {id:3, title:'AcadeMedia', comment:'', date: '2017-04-11 23:15:12'},
                ]
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            //...mapGetters('',['listData'])

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
        methods: {}, // End of Component > methods


        /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {}, // End of Component > mounted

    }
</script>