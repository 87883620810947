<template>
    <el-dialog :title="$t('translator_not_pickup')"
        :modal-append-to-body="false"
        :visible.sync="iVisible"
        :append-to-body="true"
        custom-class="custom-modal"
        width='60%'>
        <div>
          <el-form :model="form" @submit.native.prevent v-loading="loading">
           <h3 class="text-danger" v-if="!empty(iBooking.assigned_translator)&&iBooking.assigned_translator.translator.translator_data.warning_count > 0">This <a href="#" @click.stop.prevent="handleClickLink(`/#/user/profile/${iBooking.assigned_translator.translator_id}?type=3`)"> Translator</a> already have {{iBooking.assigned_translator.translator.translator_data.warning_count}} warnings</h3>
            <el-form-item label="">
              <el-radio-group v-model="form.notification" tabindex="0">
                  <el-radio value="warning" label="warning"> {{$t('send_warning_translator')}} </el-radio> <br>
                <el-radio value="penalty" label="penalty"> {{$t('send_penalty_text')}}</el-radio><br>
                <el-radio value="nothing" label="nothing"> {{$t('do_not_send_anything')}}</el-radio>
              </el-radio-group>
            </el-form-item>

            <template v-if="!empty(translator)">
              <div>
                <user-translator-booking-warning :user="translator"></user-translator-booking-warning>
              </div>
            </template>

            <el-form-item label="">
              <el-checkbox :label="$t('create_emergency_booking')" v-model="form.clone"></el-checkbox>
            </el-form-item>
            <el-form-item class="el-form-actions">
              <el-button class="modal-size" type="default" @click="handleClose">{{$t('cancel')}}</el-button>
              <el-button class="modal-size" type="primary" @click="handleSubmit" v-loading="loading">{{$t('send')}}</el-button>
            </el-form-item>

          </el-form>
        </div>

      <penalty-modal :visible.sync="showPenaltyModal" :booking="iBooking" :feedback="{id:0}" @updateIssues="handleSubmitPenalty"/>
    </el-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { Notification } from "element-ui";
import { isEmpty } from '../../../../js/helpers/Common';
  import UserTranslatorBookingWarning from '~/components/lists/user/UserTranslatorBookingWarning';

export default {
  props:{
    visible:{
      type: Boolean,
      default: false
    },
    booking:{
      type: Object
    }
  },
  components: {
    UserTranslatorBookingWarning
  },
  data(){
    return {
      form:{
        clone: 0,
        warning: 0,
        penalty:0,
        notification:'warning'
      },
      showPenaltyModal:false,
      loading:false
    }
  },
  computed:{
    ...mapState('auth',{
          user:'userData'
    }),
    ...mapGetters('user',{
      translator:'itemData'
    }),
    iVisible: {
        get() {
            return this.visible
        },
        set(v) {
            this.$emit('update:visible', v);
        }
      },
      iBooking(){
        return this.booking
      }
  },
  methods:{
    ...mapActions('booking',['postTranslatorNotPickup']),
     handleClose: function () {
          this.loading = false;
          this.$emit('update:visible', false);
      }, // End of handleClose() method
      handleSubmit(){
        this.loading = true;
        if(this.form.notification ==='penalty'){
          this.form.penalty = 1
        }else if(this.form.notification ==='warning'){
          this.form.warning = 1
        }else{
          this.form.warning = 0
          this.form.penalty = 0
        }

        this.form.clone =(this.form.clone) ? 1 :0;
        let payload = Object.assign(this.form,{id:this.iBooking.id})
        if(this.form.penalty){
            ///show penalty modal
            this.showPenaltyModal = true
          }else{
            this.postTranslatorNotPickup(payload).then(resp => {
              this.handleClose()
              this.loading = false;
              if(resp.status === 200){
              let r = resp.data.data.booking
              if(this.iBooking.id === r.id){
                window.location.reload()
              }
                this.$router.replace({name: 'booking-details', params: {id: r.id}});
              }

              this.loading = false;
            })
          }
      },
      handleSubmitPenalty(v){

        let payload = Object.assign(this.form,{id:this.iBooking.id})
        this.postTranslatorNotPickup(payload).then(resp => {
                this.handleClose()
                if(resp.status === 200){
                let r = resp.data.data.booking
                let message=''
                if(this.form.notification ==='warning'){
                  message = 'warning sent to the assigned translator'
                }else{

                  message = 'warning sent and penalty added to the assigned translator'
                }

                  let params = Object.assign(this.iBooking,{
                    translator_name: this.iBooking.assigned_translator.translator.name,
                    set_by: this.user.name,
                    price: v.price
                  })

                  this.sendPenaltyWarning(params).then(() =>{
                    Notification.success({
                      message: message,
                    });


                  this.$router.replace({name: 'booking-details', params: {id: r.id}});
                  })
                }
              })
      },
      sendPenaltyWarning(v){

        let data = {
          notification_type: 'email',
          template_code: 'translator_penalty_warning',
          project_code: app_core_project_code,
          data: v,
          recipients: [this.iBooking.assigned_translator.translator.email],
          booking_id:this.iBooking.id
        }

        let token = localStorage.getItem("app_access_token");
        return axios.post(`${app_dt_notifier_url}/api/v3/notifications`,data,{
          headers:{
            'Authorization': 'Bearer '+ token
          }
        });
      },
       handleClickLink (url) {
        window.open(url, '_blank');
      },
      empty(v){
        return isEmpty(v)
      }

  }
}
</script>

<style lang="scss" scoped>

  .el-radio__label {
    font-size: 14px !important;
  }
  .el-checkbox{
    padding: 5px;
  }

</style>
