<template>
    <div class="app-fields user-questions-booking-confirmation-fields text-left">

        <h3 class="text-center" style="margin-bottom: 35px;">{{$t('text_please_set_booking_confirm_fields')}}</h3>
        <div v-if="!isMunicipality && !configFound && municipalityId > 0" class="app-splitter two-cols">
          <el-tooltip placement="right-end">
              <div slot="content">{{$t('text_no_configs_found')}}</div>
              <h5 style="font-size:13px">{{$t('same_as')}} <a target="_blank" :href="'#/sector/municipality/edit/' + this.municipalityId">{{$t('municipality')}}?</a></h5>
            </el-tooltip>
        </div>
        <div v-if="!isMunicipality && configFound" class="app-splitter four-cols">
          <el-tooltip placement="top">
              <div slot="content">{{$t('text_click_update_configs')}}</div>
              <h5 style="font-size:13px">{{$t('same_as')}} <a target="_blank" :href="'#/sector/municipality/edit/' + this.municipalityId">{{$t('municipality')}}?</a></h5>
            </el-tooltip>
            <el-switch
              :id="genFieldId('same_as_municipality',formElId)"
              v-model="bookingFieldsUser.same_as_municipality"
              @change="handleSwitchChange($event,'same_as_municipality')"
            />
            <div></div>
            <div></div>
        </div>
            <!-- <el-form-item v-if="!isMunicipality && configFound" :id="genContainerId('same_as_municipality',formElId)"
                          label="Use same as municipality?"
                          class="label-medium">
                <el-switch :id="genFieldId('same_as_municipality',formElId)"
                           v-model="iValue.same_as_municipality" @change="handleSwitchChange($event,'same_as_municipality')"/>
            </el-form-item> -->

        <!-- ------------------------------ -->
        <!-- Reference Fields -->
        <!-- ------------------------------ -->

        <div class="app-splitter two-cols">
            <!-- Is Reference field enabled? -->
            <el-form-item :id="genContainerId('reference_enabled',formElId)"
                          :label="$t('text_is_reference_field_enabled')"
                          class="label-medium">
                <el-switch :disabled="!isMunicipality && bookingFieldsUser.same_as_municipality" :id="genFieldId('reference_enabled',formElId)"
                           v-model="iValue.reference_enabled" @change="handleSwitchChange($event,'reference_required')"/>
            </el-form-item>

            <!-- Is Reference field required? -->
            <el-form-item v-if="iValue.reference_enabled"
                          :id="genContainerId('reference_required',formElId)"
                          :label="$t('text_is_reference_field_required')"
                          class="label-medium">
                <el-switch :disabled="!isMunicipality && bookingFieldsUser.same_as_municipality" :id="genFieldId('reference_required',formElId)"
                           v-model="iValue.reference_required"/>
            </el-form-item>
        </div> <!-- /.app-splitter two-cols -->

        <!-- Reference field Placeholder value -->
        <el-form-item v-if="iValue.reference_enabled"
                      :id="genContainerId('reference_placeholder',formElId)"
                      :label="$t('reference_field_placeholder_value')"
                      class="label-long">
            <el-input :id="genFieldId('reference_placeholder',formElId)"
                      :disabled="!isMunicipality && bookingFieldsUser.same_as_municipality"
                      v-model="iValue.reference_placeholder"
                      :placeholder="$t('placeholder_value_reference_field')"/>
        </el-form-item>

        <!-- ------------------------------ -->
        <!-- Booker Name Fields -->
        <!-- ------------------------------ -->

        <div class="app-splitter two-cols">
            <!-- Is Booker Name field enabled? -->
            <el-form-item :id="genContainerId('booker_name_enabled',formElId)"
                          :label="$t('text_is_booker_name_field_enabled')"
                          class="label-medium">
                <el-switch :disabled="!isMunicipality && bookingFieldsUser.same_as_municipality" :id="genFieldId('booker_name_enabled',formElId)" @change="handleSwitchChange($event,'booker_name_required')"
                           v-model="iValue.booker_name_enabled"/>
            </el-form-item>

            <!-- Is Booker Name field required? -->
            <el-form-item v-if="iValue.booker_name_enabled"
                          :id="genContainerId('booker_name_required',formElId)"
                          :label="$t('text_is_booker_name_field_required')"
                          class="label-medium">
                <el-switch :disabled="!isMunicipality && bookingFieldsUser.same_as_municipality" :id="genFieldId('booker_name_required',formElId)"
                           v-model="iValue.booker_name_required" />
            </el-form-item>
        </div> <!-- /.app-splitter two-cols -->

        <!-- ------------------------------ -->
        <!-- Staff Name Fields -->
        <!-- ------------------------------ -->

        <div class="app-splitter two-cols">
            <!-- Is Staff Name field enabled? -->
            <el-form-item :id="genContainerId('staff_name_enabled',formElId)"
                          :label="$t('text_is_staff_name_field_enabled')"
                          class="label-medium">
                <el-switch :disabled="!isMunicipality && bookingFieldsUser.same_as_municipality" :id="genFieldId('staff_name_enabled',formElId)"
                           v-model="iValue.staff_name_enabled" @change="handleSwitchChange($event,'staff_name_required')"/>
            </el-form-item>

            <!-- Is Reference field required? -->
            <el-form-item v-if="iValue.staff_name_enabled"
                          :id="genContainerId('staff_name_required',formElId)"
                          :label="$t('text_is_staff_name_field_required')"
                          class="label-medium">
                <el-switch :disabled="!isMunicipality && bookingFieldsUser.same_as_municipality" :id="genFieldId('staff_name_required',formElId)"
                           v-model="iValue.staff_name_required" />
            </el-form-item>
        </div> <!-- /.app-splitter two-cols -->

        <!-- ------------------------------ -->
        <!-- Instructions Fields -->
        <!-- ------------------------------ -->

        <div class="app-splitter two-cols">
            <!-- Is Instructions field enabled? -->
            <el-form-item :id="genContainerId('instructions_enabled',formElId)"
                          :label="$t('text_is_instructions_field_enabled')"
                          class="label-medium">
                <el-switch :disabled="!isMunicipality && bookingFieldsUser.same_as_municipality" :id="genFieldId('instructions_enabled',formElId)"
                           v-model="iValue.instructions_enabled" @change="handleSwitchChange($event,'instructions_required')"/>
            </el-form-item>

            <!-- Is Instructions field required? -->
            <el-form-item v-if="iValue.instructions_enabled"
                          :id="genContainerId('instructions_required',formElId)"
                          :label="$t('text_is_instructions_field_required')"
                          class="label-medium">
                <el-switch :disabled="!isMunicipality && bookingFieldsUser.same_as_municipality" :id="genFieldId('instructions_required',formElId)"
                           v-model="iValue.instructions_required" />
            </el-form-item>
        </div>

        <!-- ------------------------------ -->
        <!-- Alternative Language Fields -->
        <!-- ------------------------------ -->

        <div class="app-splitter two-cols">
            <!-- Is Alternative Language field enabled? -->
            <el-form-item :id="genContainerId('alt_language_enabled',formElId)"
                          :label="$t('text_is_alternative_language_field_enabled')"
                          class="label-medium">
                <el-switch :disabled="!isMunicipality && bookingFieldsUser.same_as_municipality" :id="genFieldId('alt_language_enabled',formElId)"
                           v-model="iValue.alt_language_enabled"/>
            </el-form-item>

            <!-- Is Alternative Language field required? -->
            <el-form-item v-if="iValue.alt_language_enabled"
                          :id="genContainerId('alt_language_required',formElId)"
                          :label="$t('text_is_alternative_language_field_required')"
                          class="label-medium">
                <el-switch :disabled="!isMunicipality && bookingFieldsUser.same_as_municipality" :id="genFieldId('alt_language_required',formElId)"
                           v-model="iValue.alt_language_required"/>
            </el-form-item>
        </div> <!-- /.app-splitter two-cols -->


      <div class="app-splitter two-cols">
        <!-- Is LMA field enabled? -->
        <el-form-item :id="genContainerId('is_lma_enabled',formElId)"
                      :label="$t('text_is_lma_enabled')"
                      class="label-medium">
          <el-switch :disabled="!isMunicipality && bookingFieldsUser.same_as_municipality" :id="genFieldId('alt_language_enabled',formElId)"
                     v-model="iValue.lma_enabled"/>
        </el-form-item>

        <!-- Is LMA required? -->
        <el-form-item v-if="iValue.lma_enabled"
                      :id="genContainerId('is_lma_required',formElId)"
                      :label="$t('text_is_lma_required')"
                      class="label-medium">
          <el-switch :disabled="!isMunicipality && bookingFieldsUser.same_as_municipality" :id="genFieldId('alt_language_required',formElId)"
                     v-model="iValue.lma_required"/>
        </el-form-item>
      </div> <!-- /.app-splitter two-cols -->

    </div> <!-- /.app-fields user-questions-booking-confirmation-fields -->
</template>

<script>
  import {isEmpty} from "~/js/helpers/Common";
  import { mapGetters, mapActions} from "vuex";

  export default {
    data() {
      return {
        fields: this.bookingFieldsMunicipality,
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      // Parent form reference.
      formElId: {
        type: String,
        default: ''
      },
      isMunicipality: {
        type: Boolean,
        default: false,
      },
      bookingFieldsMunicipality: {
        default () {
          return {
            same_as_municipality: false,
            // Booking Questions ---
            // Booking Questions > Reference
            reference_enabled: false,
            reference_required: false,
            reference_placeholder: '',

            // Booking Questions > Booker's Name
            booker_name_enabled: false,
            booker_name_required: false,

            // Booking Questions > Staff Name / Translator's Name
            staff_name_enabled: false,
            staff_name_required: false,

            // Booking Questions > Instructions
            instructions_enabled: false,
            instructions_required: false,

            // Booking Questions > Alternative Language
            alt_language_enabled: false,
            alt_language_required: false,

            // LMA
            lma_enabled: false,
            lma_required: false
          }
        }
      },
      configFound: {
        type: Boolean,
        default: true,
      },
      municipalityId: {
        default: 0,
      },
      bookingFieldsUser: {
        default() {
          return {}
        },
      },

      // Serve as default values and documentation in regards to
      // this component's fields and values.
      value: {
        type: Object,
        default () {
          return {
            // Same as Municipality
            same_as_municipality: false,
            // Booking Questions ---
            // Booking Questions > Reference
            reference_enabled: false,
            reference_required: false,
            reference_placeholder: '',

            // Booking Questions > Booker's Name
            booker_name_enabled: false,
            booker_name_required: false,

            // Booking Questions > Staff Name / Translator's Name
            staff_name_enabled: false,
            staff_name_required: false,

            // Booking Questions > Instructions
            instructions_enabled: false,
            instructions_required: false,

            // Booking Questions > Alternative Language
            alt_language_enabled: false,
            alt_language_required: false,

            // LMA
            lma_enabled: false,
            lma_required: false
            // End of Booking Questions ---
          }
        }
      }

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          console.log(v)
          this.$emit('input', v);
        }
      },
      sameAs() {
        return this.bookingFieldsUser.same_as_municipality;
      }

    }, // End of Component > computed
    watch: {
      sameAs() {
        if(this.sameAs) {
          console.log(this.bookingFieldsMunicipality, this.iValue, this.value, this.bookingFieldsUser)
          if(!this.isMunicipality && this.configFound && this.bookingFieldsUser.same_as_municipality){
            // this.readBcfConfig({municipality_id: this.municipalityId,level:"municipality"}).then(response => {
            //   this.iValue = {
            //     ...JSON.parse(JSON.stringify(response.data.data.booking_field)),
            //     same_as_municipality: true,
            //   };
            // })
            setTimeout(( ) => {
              this.iValue = {
                ...JSON.parse(JSON.stringify(this.bookingFieldsMunicipality)),
                same_as_municipality: true,
              };
            }, 2000)
          }
        } else {
          if(!isEmpty(this.bookingFieldsUser)){
          this.iValue = JSON.parse(JSON.stringify(this.bookingFieldsUser));
          }
        }
      }
    },
    methods: {
      ...mapActions('customer', {
        editBcfConfig: 'editBcfConfig',
        readBcfConfig: 'readBcfConfigMunicipality',
      }),
      handleSwitchChange(e,field){
        // if(field === 'same_as_municipality' && e === true) {
        //   console.log(this.bookingFieldsMunicipality, this.iValue)
        //   this.iValue = _.cloneDeep(this.bookingFieldsMunicipality, this.iValue)
        //   // this.iValue[field] = e
        // }
        console.log(e, field)
        if(e === false){
          this.iValue[field] = e
        }
      },
      handleSyncedCheck() {
        if(this.bookingFieldsUser.synced_from_municipality) {
          return this.bookingFieldsUser.synced_from_municipality
        }
      },

    },
    mounted() {
    }

  } // End of export default
</script>
