'use strict';

export default {

  /**
   * Returns state.list.data.
   *
   * @param state
   * @returns {array}
   */
  listData: (state) => state.list.data,

  /**
   * Returns state.list.pagination.
   *
   * @param state
   * @returns {object}
   */
  listPagination: (state) => state.list.pagination,

  /**
   * Returns boolean to determine whether list pagination is valid.
   *
   * @param state
   * @returns {boolean}
   */
  isValidListPagination (state) {
    return state.list.pagination.per_page < state.list.pagination.total;
  },

  /**
   * Returns state.list.pagination.
   *
   * @param state
   * @returns {boolean}
   */
  isListLoading: (state) => state.list.loading,

  /**
   * Returns state.item.data.
   *
   * @param state
   * @returns {object}
   */
  itemData: (state) => state.item.data,

  /**
   * Returns state.list.pagination.
   *
   * @param state
   * @returns {boolean}
   */
  isItemLoading: (state) => state.item.loading,

  /**
   * Returns state.progress.invoice.
   *
   * @param state
   * @returns {object}
   */
  progressInvoice: (state) => state.progress.invoice,

  /**
   * Returns state.progress.salary.
   *
   * @param state
   * @returns {object}
   */
  progressSalary: (state) => state.progress.salary,

  /**
   * Returns state.progress.financial_list.
   *
   * @param state
   * @returns {object}
   */
  progressFinancialList: (state) => state.progress.financial_list,

  /**
   * Returns state.uncalculated.invoice.
   *
   * @param state
   * @returns {object}
   */
  uncalculatedInvoice: (state) => state.uncalculated.invoice,

  /**
   * Returns state.uncalculated.salary.
   *
   * @param state
   * @returns {object}
   */
  uncalculatedSalary: (state) => state.uncalculated.salary

}
