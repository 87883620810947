<template>
    <!-- <el-select :multiple="iMultiple" :collapse-tags="iCollapseTags" v-model="iValue" filterable :placeholder="iPlaceholder" remote
                :remote-method="searchTranslator" :loading="searchTranslatorLoading" clearable :ref="iRefs" auto-complete="off">
        <el-option v-for="i in searchTranslatorResult" :key="i.id" :label="i.name +' - '+ i.email+' - '+ i.id"
            :value="i.id">
        </el-option>
    </el-select> -->
    <multiselect
    v-model="iValue"
    :options="searchTranslatorResult"
    :placeholder="iPlaceholder"
    :multiple="iMultiple"
    :searchable="true" 
    :loading="searchTranslatorLoading"
    @search-change="searchTranslator"
    :custom-label="customLabel" :show-labels="false"
    track-by="email"
    :hide-selected="true"
    :internal-search="false" :clear-on-select="iMultiple" :close-on-select="!iMultiple"
    >
        <template slot="clear" slot-scope="props">
            <div class="multiselect__clear" v-if="iValue.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
        </template>
        <span slot="noResult">{{ $t('tfx_no_result_found_try_again') }}</span>
        <span slot="noOptions">{{ $t('txt_no_options') }}</span>
  
    </multiselect>
</template>

<script>

import Multiselect from 'vue-multiselect'
import { mapGetters, mapActions } from 'vuex';
import { isEmpty } from '../../../../js/helpers/Common';
export default {
    components:{Multiselect},
    props:{
        value: {
            default: '',
        },
        width:{
            type:String,
            default:'100%'
        },
        multiple:{
            type: Boolean,
            default:false
        },
        collapseTags:{
            type: Boolean,
            default:false
        },
        placeholder:{
            type:String,
            default:'Type to search translator'
        },
        refs:{
            type:String,
            detault:'translator_select'
        }
    },
    name: 'translator-select',
    data(){
        return {
            searchTranslatorLoading:false,
            searchTranslatorResult: [],
        }
    },
    computed:{
         iValue: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit('input', v);
            },
        },
         iWidth(){
             return this.wid
         },
         iCollapseTags(){
             return this.collapseTags
         },
         iMultiple(){
             return this.multiple
         },
         iPlaceholder(){
             return this.placeholder
         },
         iRefs(){
             return this.refs
         }
    },
    methods:{
        ...mapActions('booking',{
            filterTranslators:'filterTranslators'
        }),

        searchTranslator(query){
                if(query!=='' && query.length > 2){
                    this.searchTranslatorLoading = true;
                    let params ={
                        'filter[name]': query,
                        'all': true
                    }
                    
                    setTimeout(()=> {
                       this.filterTranslators(params).then((response) => {
                          
                          if(isEmpty(response)){
                              let params2 = {
                                    'filter[email]': query,
                                    'all': true
                                }
                                this.filterTranslators(params2).then((response) => {
                                     this.searchTranslatorResult =  response
                                     
                                })
                          }
                          
                         this.searchTranslatorResult =  response

                        this.searchTranslatorLoading = false;
                    })
               
                    },200)
                    
                }else{
                    this.searchTranslatorResult = []
                }
            },
        customLabel ({ id, email, name }) {
            return `${email} - ${name} - ${id}`
        },
        clearAll () {
            this.iValue = []
        }
    }
}
</script>

<style lang="scss" scoped>

// .el-select__tags-text {
//         max-width: 150px !important;
//         overflow: hidden;
        /* text-overflow: ellipsis; */
        /* white-space: nowrap; */
        /* display: inline-block; */
        // vertical-align: middle;
    // }


</style>

<style>
.multiselect, .multiselect__tags, .multiselect__single, .multiselect__input{
    font-size: 12px !important;
}
</style>


