<template>
    <div class="app-display">
        <el-dialog :class="genBem(blockCls, 'update-prefill-modal')"
                   :visible.sync="iVisible"
                   :modal-append-to-body="false"
                   width="80%"
                   :title="$t('tfv_fill_fields')">
            <el-form>
                <financial-preview-compose-prefill-form
                        v-model="form"
                        @update="handleUpdate"/>
            </el-form>
        </el-dialog>
    </div> <!-- Component DIV Wrapper -->
</template>

<script>
  import {mapState, mapMutations, mapActions} from 'vuex';
  import {notifSuccess, notifError} from "~/js/helpers/Notification";
  import FinancialPreviewComposePrefillForm
    from '~/components/forms/financial/preview/FinancialPreviewComposePrefillForm';
  import startCase from 'lodash/startCase';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      FinancialPreviewComposePrefillForm
    },

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    props: {

      // Varies between 'invoice' | 'salary'
      target: {
        type: String,
        required: true,
      },

      bookingId: {
        type: String | Number,
        require: true,
      },

      item: {
        type: Object,
        required: true,
      },

      visible: {
        type: Boolean,
        default: false
      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-preview-compose-prefill-modal'
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > watch
    |--------------------------------------------------------------------------
    */
    watch: {

      iVisible (v) {
        if (v) {
          const resetForm = this.target + 'ResetForm';
          this[resetForm]();

          this.applyItemValuesToForm();
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapState('financialPrefillInvoice', {
        invoiceForm: 'form'
      }),

      ...mapState('financialPrefillSalary', {
        salaryForm: 'form'
      }),

      form () {
        const form = `${this.target}Form`;
        return this[form];
      },

      iVisible: {
        get () { return this.visible; },
        set (v) { this.$emit('update:visible', v); }
      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapMutations({
        invoiceResetForm: 'financialPrefillInvoice/resetForm',
        salaryResetForm: 'financialPrefillSalary/resetForm'
      }),

      ...mapActions({
        invoiceAddOrEditPrefill: 'financialPrefillInvoice/addOrEdit',
        invoiceEditAdditional: 'financialAdditionalInvoice/edit',

        salaryAddOrEditPrefill: 'financialPrefillSalary/addOrEdit',
        salaryEditAdditional: 'financialAdditionalSalary/edit'
      }),

      /**
       * @param {string} key
       * @param {*} value
       * @returns {void}
       */
      setAdditionalCompose (key, value) {
        this.$store.state[`financialAdditional${startCase(this.target)}`].compose[key] = value;
      },

      handleUpdate () {
        let actionSuffix = '';
        let payload = {booking: this.bookingId};

        if (this.item.additional_item_id != null) {
          actionSuffix = 'EditAdditional';
          payload.id = this.item.additional_item_id;

          this.setAdditionalCompose('name', this.form.name);
          this.setAdditionalCompose('description', this.form.description);
          this.setAdditionalCompose('price', this.form.price);
          this.setAdditionalCompose('quantity', this.form.quantity);
          this.setAdditionalCompose('unit', this.form.unit);

        } else {
          actionSuffix = 'AddOrEditPrefill';
        }

        let action = this.target + actionSuffix;

        this[action](payload)
          .then(() => {
            this.$parent.populatePrefills();
            notifSuccess({message: 'Updated Successfully'});
          })
          .catch((e) => {
            notifError({message: e});
          })
          .finally(() => { this.iVisible = false; });
      },

      applyItemValuesToForm () {
        if (!window._.isEmpty(this.item)) {
          window._.assign(this.form, this.item);
        }
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      this.applyItemValuesToForm();
    },
  }
</script>
