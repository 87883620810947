/*
|--------------------------------------------------------------------------
| store > user > customer > state
|--------------------------------------------------------------------------
|
| This file contains the state property of current Vuex scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import API from '~/js/constants/api';
import {
  DEFAULT_PROPS,
  PERSONAL_INFO,
  CREDENTIAL,
  CUSTOMER_SETTINGS,
  ADDRESS,
  DEFAULT_QUESTIONS,
  CUSTOMER_QUESTIONS,
  BOOKING_CONFIRM_FIELDS,
} from '~/js/store/user/shared/props'

// Set the values of the compose.
const COMPOSE_PROPS = {
  ...DEFAULT_PROPS,
  personal_info: PERSONAL_INFO,
  credential: CREDENTIAL,
  settings: CUSTOMER_SETTINGS,
  address: ADDRESS,
  questions: {
    ...DEFAULT_QUESTIONS,
    ...CUSTOMER_QUESTIONS
  },
  booking_confirm_fields: BOOKING_CONFIRM_FIELDS,
  booking_confirm_fields_municipality: BOOKING_CONFIRM_FIELDS,
  booking_confirm_fields_default: BOOKING_CONFIRM_FIELDS,
}

// Set the values of the paths.
const PATH = API.CUSTOMERS;
const PATH_W_ID = API.CUSTOMERS + '/{id}';
// Set the instance.
export default {

  model_info: {
    name: 'Customer Store',
    module: 'customer',
    table: 'users'
  },

  api: {
    browse: {method: 'get', path: PATH, params: {per_page: 25}},
    read: {
      method: 'get', path: PATH_W_ID,
      params: {
        include: [
          'booking_types',
          'customer_notification_types',
          'blacklisted_users',
          'blacklisted_users.translator',
          'booking_types',
          'customer_data.department',
          'towns'
        ].join(',')
      }
    },
    edit: {method: 'put', path: PATH_W_ID},
    add: {method: 'post', path: PATH},
    delete: {method: 'delete', path: PATH_W_ID},
    bcf: {
      read: {method: 'get', path: API.BOOKING_CONFIRM_FIELDS},
      edit: {method: 'post', path: API.BOOKING_CONFIRM_FIELDS}
    },
    customer: {
      method: 'post',
      path: API.CUSTOMER_FROM_BOOKING
    }
  },

  composeFresh: _.cloneDeep(COMPOSE_PROPS),
  compose: _.cloneDeep(COMPOSE_PROPS),

  payloadData: {},

  autoInjectPayload: true,
  autoReflectItem: true,
  autoUpdateList: true,

  relationships: [
    'customerData',
    'languages',
    'countries',
    'blacklistedUsers',
    // 'bookingTypes',
    'files',
    'createdBy',
    // 'bookedBy',
    // 'confirmationFills',
    // 'towns',
    // 'translatorCustomerTypes',
    // 'bookingComment',
    // 'customerType',
    // 'lateAcceptAttempts',
    // 'survey',
    // 'surveyQuestion',
    // 'surveyAnswer',
    'warnings',
    // 'lockedBooking',
    'townUsersPivot',
    // 'translatorCustomerTypesPivot',
    // 'customerNotificationTypes',
    // 'bookingField',
    // 'textTranslationCustomer',
  ],
}
