<template>
    <div class="app-field languages-translator-level-field">
        <el-form-item :id="genContainerId(`trans_level_picker_${languageName}`,formElId)"
                      :label="languageName" class="label-short">
            <el-select :id="genFieldId(`trans_level_picker_${languageName}`,formElId)"
                       v-model="iValue" multiple clearable>
                <el-option v-for="(v) in translatorLevelOpts"
                           :key="v.id"
                           :value="v.id"
                           :label="v.name"/>
            </el-select>
        </el-form-item>
    </div> <!-- /.app-field languages-translator-level-field -->
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import {populate} from "../../../js/helpers/Vuex";

    export default {
        props: {

            formElId: {
                type: String,
                default: 'languages_translator_levels_parent'
            },

            value: {
                type: Array,
                default() {
                    return [];
                },
            },

            languageName: {
                type: String,
                default: ''
            },

        },

        computed: {
            ...mapGetters('translatorLevel', {
                translatorLevelOpts: 'listAsOptions',
            }),

            iValue: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                },
            },
        },

        methods: {
            ...mapActions('translatorLevel', {
                loadTranslatorLevels: 'browseAsOptions'
            }),
        },

        mounted() {
            populate(this, 'loadTranslatorLevels', 'translatorLevelOpts', {
                ref: 'Translator Level Listing',
            });
        },
    }
</script>
