 <template>
    <div>   
          <canvas :id="chartId"></canvas>        
    </div>
 </template>
 <script>
    import Chart from 'chart.js';
    import multiBarChartData from '../data/chart-data.js';

  
    export default {
        mounted() {

         // let self = this;
         // setTimeout(function(){
               this.multiBarChartFunction('#A3A1FB','#56d9fe','#60E3A1');
         // }, 2000);
        },
        props: ["chart-id", "type"],
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                multiBarChartData : multiBarChartData,
            };
        }, // End of Component > data
        computed: {
            fromDateData : function(){
                return this.fromDate;
            },
            toDateData : function(){
                return this.toDate;
            },
            returnType :function(){
                return this.type;
            }
        },
        methods: {

            multiBarChartFunction: function(ColorOne, ColorSec, ColorThird){
            let self = this;
            let d1 = [];
            let d2 = [];
            let d3 = [];
            let c1 = [];
            let c2 = [];
            let c3 = [];
            let labels = [];
            let sumValues = [];
            let token = localStorage.getItem("app_access_token");
            axios.get("https://api-gateway.digitaltolk.se/api/v3/bookings-statistics/user-or-type?type="+this.returnType,  
                {
                    headers:
                    {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then(resp => {
                    for(var i = 0; i < resp.data.length; i++)
                    {
                        if(this.returnType == "booking_types_last_two_years")
                        {
                            labels.push(moment("01-"+resp.data[i].date).format('DD-YY'));
                        }else{
                             labels.push(moment(resp.data[i].date).format('DD'));
                        }
                        c1.push(ColorOne);
                        c2.push(ColorSec); 
                        c3.push(ColorThird);
                        var vd = resp.data[i].data.video ? resp.data[i].data.video : 0; 
                        var phy = resp.data[i].data.physical ? resp.data[i].data.physical : 0; 
                        var ph = resp.data[i].data.phone ? resp.data[i].data.phone : 0; 
                        d1.push(ph);
                        d2.push(phy);
                        d3.push(vd);
                       
                        var sum = ph + phy + vd; 
                        sumValues.push(sum);
                    }
                        var multichartdata = {
                        labels: labels,
                        datasets: [
                            { // one line graph
                                label: 'Telephone',
                                data: d1,
                                backgroundColor: c1,
                                borderColor: c1,
                            
                                borderWidth: 0
                            },
                            { // another line graph
                                label: 'Physical',
                                data: d2,
                            
                                backgroundColor: c2,
                                borderColor: c2,
                                
                                borderWidth: 0
                            },
                            { // another line graph
                                label: 'Video',
                                data: d3,
                                sumValues : sumValues,
                                backgroundColor: c3,
                                borderColor: c3,
                                
                                borderWidth: 0
                            }
                            ]
                        };
                        this.createChart(this.chartId, this.multiBarChartData, multichartdata);
                    //self.data  = resp.data;
                }).catch(err => {
                    console.log(err);
                });
               
        
            
             //  return multichartdata;
            },
            createChart(chartId, chartData, MultiData) {
                const ctx = document.getElementById(chartId);
                const myChart = new Chart(ctx, {
                type: chartData.type,
                data: MultiData,
                options: chartData.options,
                 plugins :[{
                    afterDatasetsDraw: function (context, easing) {
                      var ctx = context.chart.ctx;
                          
                      context.data.datasets.forEach(function (dataset) {
                          
                        for (var i = 0; i < dataset.data.length; i++) {
                            
                            if(dataset.label == 'Video'){
                                var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                                var textY = model.y + (dataset.type == "line" ? -3 : 15);
                                ctx.font = Chart.helpers.fontString(15, 'normal', Chart.defaults.global.defaultFontFamily);
                                ctx.textAlign = 'center';
                                ctx.textBaseline = 'middle';
                                ctx.fillStyle = dataset.type == "line" ? "black" : "black";
                                ctx.save();
                                ctx.translate(model.x, textY-30);
                                ctx.fillText( dataset.sumValues[i], 0, 0);
                                ctx.restore();
                            }
                        }
                      });
                    }
                }]
                });
            }
        }
    }
 </script>