<template>

    <div class="app-page booking-edit-page" v-loading="loading">
        <!-- <page-header v-html="pageTitle" /> -->
        <page-header :title="$t('booking_edit')"
            :linkData="{
                text : this.$route.params.id,
                path : 'booking/' + this.$route.params.id + '/details',
                preText : '(#',
                postText : ')'
            }"
            :back-enabled="true"/>

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col full-width">
                        <booking-edit-form />
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page booking-edit-page -->
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
   import {populate,forcePopulate} from "~/js/helpers/Vuex"
    export default {
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters({
                booking: 'booking/getBooking',
                loading: 'booking/getLoading',
                customers: 'booking/getCustomersList',
                translators: 'booking/getTranslatorsList',
                customer: 'booking/getCustomer',
                languageOpts: 'language/getLanguages',
                translatorsLevels: 'booking/getTranslatorLevels',
                getTownList:'booking/getTownList'
            }),

             userId(){
                return JSON.parse(localStorage.getItem("app_user_data")).id
            },
             bookingId() {
                return this.$route.params.id
            },

        },
        methods:{
            ...mapActions('language', {
                loadLanguages: 'fetchLanguages'
            }),
            ...mapActions('booking', {
                loadCustomers: 'fetchCustomers',
                loadTranslators:'fetchTranslators',
                loadTranslatorLevels:'fetchTranslatorLevels',
                fetchTowns:'fetchTowns',
                deleteUserInsideBooking: 'deleteUserInsideBooking',
            }),
        },

        mounted(){

            this.$store.commit['booking/resetEditBookingForm']
            this.$store.dispatch('booking/initBookingEdit',this.$route.params.id);
            populate(this, 'loadLanguages', 'languageOpts', {
                ref: 'Languages Options'
            });
            // populate(this, 'loadCustomers', 'customers', {
            //     ref: 'Customer Options'
            // });
            populate(this, 'loadTranslators', 'translators', {
                ref: 'Translators Options'
            });
            populate(this, 'loadTranslatorLevels', 'translatorsLevels', {
                ref: 'Translator Levels Options'
            });
            populate(this, 'fetchTowns', 'getTownList', {
                ref: 'Towns Options'
            });
        },
         beforeRouteLeave (to, from, next) {
             let data = {
                    booking_id : this.bookingId,
                    user_id : this.userId,
                }
                this.deleteUserInsideBooking(data)
                next(true);
         },
           watch: {
                    "$route.params.id"(val) {
                    // call the method which loads your initial state
                  this.$store.dispatch('booking/getBooking',this.$route.params.id)
            }},
    }
</script>
