/*
|--------------------------------------------------------------------------
| Store > faq > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import API from '~/js/constants/api';
const endpoint = API.FAQ;
const endpointWithId = API.FAQ + '/{id}';

let asOptionsParams = {
    all:true,
    sort: 'created_at',
};

let composeProps = {
  name:'',
  role:'',
  subtitle:'',
  created_by:''

};

export default {
  api: {
        browse: { method: 'get', path: endpoint, params: { per_page: 10 }},
        browseAsOptions: { method: 'get', path: endpoint, params: asOptionsParams},
        read: { method: 'get', path: endpointWithId, params: {}},
        edit: { method: 'put', path: endpointWithId, params: {}, data: {}, },
        add: { method: 'post', path: endpoint, params: {}, data: {}, },
        delete: { method: 'delete', path: endpointWithId, params: {}, data: {}, },
    },

    composeFresh: _.cloneDeep(composeProps),
    compose: _.cloneDeep(composeProps),

    payloadData: {}, // End of payloadData state

    autoInjectPayload: true,
    autoReflectItem: true,
    autoUpdateList: true,

  /**
   * Array of faqs data
   * @type {array|null}
   */

  subtitles:[],

  /**
   * faq object
   * @type {object|null}
   */
  faq: {
    name:'',
    role:'',
    subtitle:'',
    created_by:''
  },
  subtitle:{
    question:'',
    response:'',
    faq_id:''
  },

}
