<template>
  <el-dialog
    :title="`${(editMode) ? $t('update') : $t('create')}  ${$t('holiday')}`"
    :visible.sync="dialogVisible"
    :modalAppendToBody="false"
  >
    <div class="holiday-form">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item :label="$t('name')" prop="name">
          <el-input v-model="form.name" :disabled="inProcess"></el-input>
        </el-form-item>
        <el-form-item :label="$t('start_date')" prop="start_date">
          <el-date-picker
            v-model="form.start_date"
            type="date"
            placeholder="Pick a Date"
            format="yyyy-MM-dd"
            :picker-options="dateOptions"
            value-format="yyyy-MM-dd"
            :disabled="inProcess"
            popper-class="day-picker-only"
          />
        </el-form-item>
        <el-form-item :label="$t('end_date')" prop="end_date">
          <el-date-picker
            v-model="form.end_date"
            type="date"
            placeholder="Pick a Date"
            format="yyyy-MM-dd"
            :picker-options="dateOptions"
            value-format="yyyy-MM-dd"
            :disabled="inProcess"
            popper-class="day-picker-only"
          />
        </el-form-item>
        <div class="holiday-form__buttons">
          <el-button @click="close" :disabled="inProcess">{{$t('cancel')}}</el-button>
          <el-button type="primary" @click="save" :disabled="inProcess">{{ (editMode) ? $t('update') : $t('create') }}</el-button>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data () {
      return {
        dialogVisible: false,
        editMode: false,
        inProcess: false,
        form: {
          name: '',
          start_date: '',
          end_date: ''
        },
        rules: {
          name: [
            { required: true, message: 'Name is required', trigger: 'blur' }
          ],
          start_date: [
            { required: true, message: 'Start Date is required', trigger: 'blur' }
          ],
          end_date: [
            { required: true, message: 'End Date is required', trigger: 'blur' }
          ]
        },
        dateOptions: {
          disabledDate(date) {
            let day = new Date();
            let yesterday = day.setDate(day.getDate() - 1)
            return date <= yesterday
          }
        }
      }
    },
    methods: {
      ...mapActions('holidays', [
        'createHoliday',
        'editHoliday'
      ]),
      open (data) {
        if (data) {
          this.form = Object.assign({}, data)
          this.editMode = true
        } else {
          this.form = {
            name: '',
            start_date: '',
            end_date: ''
          }
          this.editMode = false
        }
        this.dialogVisible = true
      },
      close () {
        this.dialogVisible = false
      },
      save () {
        this.$refs.form.validate((valid) => {
          if (!valid) return
          this.inProcess = true
          const callback = () => {
            this.inProcess = false
            this.$refs.form.resetFields()
            this.$emit('updated')
            this.close()
          }

          if (this.editMode) {
            this.editHoliday(this.form)
              .then(callback)
          } else {
            this.createHoliday(this.form)
              .then(callback)
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .holiday-form {
    .el-form-item {
      margin-bottom: 20px;
    }
    &__buttons {
      text-align: right;
    }
  }
</style>
