<template>
    <div class="app-list user-list">

        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">

            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="warning" :title="$t('user_access_log')" @click="handleRedirectUserAccessLog(scope)">
                    <span class="fa fa-key"></span>
                </el-button>
                <el-button class="circle" type="success" :title="$t('edit')" @click="handleEdit(scope)">
                    <span class="fa fa-pencil"></span>
                </el-button>
                <el-button class="circle" type="success" :title="$t('restore')" @click.stop="handelRestoreTrashedUsers(scope)">
                    <span class="fa fa-repeat"></span>
                </el-button>
            </template>

        </base-list-table>

        <!-- Pagination -->
        <el-pagination
                v-if="listPagination.total > listPagination.per_page"
                layout="prev, pager, next"
                :page-size="listPagination.per_page"
                :total="listPagination.total"
                :current-page="listPagination.current_page"
                @current-change="reloadList">
        </el-pagination>

    </div> <!-- /.app-list user-list -->
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import {populate} from "../../../js/helpers/Vuex";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            const _this=this;
            return {

                listCols: [
                    {
                        label: _this.$t('name'),
                        prop: 'name',
                        width: '',
                        className: 'name-col',
                    },
                    {
                        label: _this.$t('email'),
                        prop: 'email',
                        width: '',
                        className: 'email-col',
                    },
                ], // End of listCols

                populatePayload: {
                    user_type: 'users',
                },

            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {
            ...mapGetters("adminUser", {
              listData: "getTrashedUsers",
              listPagination: "paginationTrashedUsers"
            }),
        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            ...mapActions('adminUser', {
                browse: 'readTrashedUsers',
                restoreTrashedUsers: 'restoreTrashedUsers'
            }),

            /**
             * Handler for edit event in the table.
             *
             * @param {object} scope
             *   The current scope(row) target.
             *
             * @return {void}
             */
            handleEdit(scope) {

                // Redirect to the edit page.
                window.location.href = '/#/admin-user/edit/' + scope.row.id;

            }, // End of handleEdit() method

            /**
             * Method for reloading the list.
             *
             * @param {int} n
             *   The target page number.
             *
             * @return {void}
             */
            reloadList(n) {
                const param = {
                    page: n
                };
                this.browse(param)
            }, // End of reloadList() method

            handleRedirectUserAccessLog(scope) {
                this.$router.push({path:'/logs/user-access-logs', query:{admin:scope.row.name}})
            },
            handelRestoreTrashedUsers(scope){
                this.restoreTrashedUsers({id: scope.row.id}).then(() => {
                    this.browse();
                }).catch(() => {
                    // Silence is golden.
                });
            }

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
            const opts = {
                action: 'browse',
                data: 'listData',
                ref: 'User Listing'
            };
            const params = _.clone(this.$route.query);

            this.reloadList(null, opts, params);

        }, // End of Component > mounted

    } // End of export default
</script>
