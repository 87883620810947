/*
|--------------------------------------------------------------------------
| Store > Booking > Mutations
|--------------------------------------------------------------------------
|
| This file contains the mutations property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
import {isEmpty} from '~/js/helpers/Common';
export default{
  setFeedbacks: (state, payload) => {
    let group = [];
    if(!isEmpty(payload)){
    group =  _(payload)
    .groupBy('review_from_type')
    .map(function(items, type) {
      let newItems = _.map(items,(i)=> {return  {...i,isEdit:false}});
      return {
        review_from_type: type,
        feedback: newItems
      };
    }).value();
  }
    let translatorNotExist = !_.some(group, {review_from_type:'3'});
    let customerNotExist = !_.some(group, {review_from_type:'2'});
    let type = []
    if(translatorNotExist){
      type.push({ id: 3, value: 'translator' })
    }
    if(customerNotExist){
      type.push({id: 2,value: 'customer'})
    }
    state.feedbacks = group
    state.type = type
    state.addFeedback = isEmpty(group) || customerNotExist || translatorNotExist
  },

  setFeedbackList(state,payload){
    state.feedbacks = payload
  },

  setLoading:(state, payload) =>{
    state.loading = payload
  }
}