<template>
 <div class="app-row">
          <div class="app-col col-container quarter-width">
              <simple-count-display
                      v-model="currentPending.count"
                      :percentage="currentPending.percentage"
                      :title="$t('current_pending_bookings')"
                      icon="fa fa-book"
                      icon-color="FCD95F"/>
          </div>
          <div class="app-col col-container quarter-width">
              <simple-count-display
                      v-model="currentAccepted.count"
                      :percentage="currentAccepted.percentage"
                      :title="$t('current_accepted_bookings')"
                      icon="fa fa-book"
                      icon-color="ACDD8C"/>
          </div>
          <div class="app-col col-container quarter-width">
              <simple-count-display
                      v-model="totalCancelled.count"
                      :percentage="totalCancelled.percentage"
                      :title="$t('total_cancelled_bookings')"
                      icon="fa fa-book"
                      icon-color="BF4255"/>
          </div>
          <div class="app-col col-container quarter-width">
              <simple-count-display v-if="$route.query.type===2"
                      v-model="lateCancelled.count"
                      :percentage="lateCancelled.percentage"
                      :title="$t('total_late_cancellation_bookings')"
                      icon="fa fa-book"
                      icon-color="DDA177"/>

              <simple-count-display v-else
                      v-model="lateWithdrawal.count"
                      :percentage="lateWithdrawal.percentage"
                      :title="$t('total_late_withdrawal_bookings')"
                      icon="fa fa-book"
                      :clickable="true"
                      :url="lateWithdrawalUrl"
                      icon-color="DDA177">
                  <template slot="additionalValue">

                  <p style="text-align:center;">
                  (Delay)
                  <a :href="`javascript:;`" @click="handleDelayCount">{{getDelayedBookings.length}}</a>

                  </p>

                  <user-translator-delayed-bookings :visible.sync="showDelayedBookings"></user-translator-delayed-bookings>
                </template>
              </simple-count-display>
          </div>
          <div class="app-col col-container quarter-width">
              <simple-count-display
                      v-model="totalCompleted.count"
                      :percentage="totalCompleted.percentage"
                      :title="$t('total_completed_bookings')"
                      icon="fa fa-book"
                      icon-color="E99DC6"/>
          </div>
          <div class="app-col col-container quarter-width">
              <simple-count-display
                      v-model="feedbackAvg.count"
                      :percentage="percentage"
                      :title="$t('feedback_average_ratings')"
                      icon="fa fa-commenting"
                      icon-color="4AD5D6"
                      background-color="FCD95F"
                      :clickable="true"
                      :url="url"
                      >
                <template slot="additionalValue">

                  <p style="text-align:center;">
                  (low)
                  <router-link :to="url+'?low=true'">  {{lowFeedback.length}}</router-link>

                  </p>
                  <user-translator-delayed-bookings :visible.sync="showDelayedBookings"></user-translator-delayed-bookings>
                </template>
                </simple-count-display>
          </div>
          <div class="app-col col-container quarter-width">
              <simple-count-display
                      v-model="blacklisted.count"
                      :percentage="percentage"
                      :title="$t('blacklist_title')"
                      icon="fa fa-ban"
                      icon-color="BF4255"
                      :url="blacklisted.url"
                      :clickable="true"

                      background-color="FCD95F"/>
          </div>
          <div class="app-col col-container quarter-width">
              <simple-count-display
                      v-model="notCarriedOutTranslator.count"
                      :percentage="notCarriedOutTranslator.percentage"
                      :title="$t('not_carried_out_due_to_translator')"
                      icon="fa fa-book"
                      :modal="true"
                      @updateModal="updateTranslatorWarningModal"
                      icon-color="E99DC6"
                      />
                      <user-translator-booking-warning-modal :user="user" :visible.sync="showBookingWarning"></user-translator-booking-warning-modal>
          </div>

      </div>
      </div>

</template>

<script>
import {mapGetters} from 'vuex'
  import UserTranslatorBookingWarningModal from '~/components/lists/user/TranslatorBookingWarningModal';
  import UserTranslatorDelayedBookings from '~/components/lists/user/TranslatorDelayedBookings';
export default {
  name:'userStatistics',
  props:{
    user:{
      type:Object
    }
  },
  components:{UserTranslatorBookingWarningModal,UserTranslatorDelayedBookings},
  data(){
    return {
      counts:0,
      percentage:10,
      blacklist_title:'Block By How Many Customers',
      showBookingWarning:false,
      showDelayedBookings:false
    }
  },
  computed:{
      ...mapGetters('booking', {
        bookings: 'getBookings',
        currentPending: 'getCurrentPending',
        currentAccepted: 'getCurrentAccepted',
        totalCompleted: 'getTotalCompleted',
        totalCancelled: 'getTotalCancelled',
        lateCancelled: 'getLateCancelled',
        notCarriedOutTranslator: 'getNotCarriedOutTranslator',
        notCarriedOutCustomer: 'getNotCarriedOutcustomer',
        lateWithdrawal:'getLateWithdrawal'
    }),
    ...mapGetters('feedback', {
      feedbacks: 'getFeedbacks'
    }),

    ...mapGetters('blacklistedUsers', {
      blacklist: 'getBlacklistedUsers'
    }),
    ...mapGetters('translator', {
        getDelayedBookings:'getDelayedBookings'
      }),
    feedbackAvg(){
      let sum = _.sumBy(this.feedbacks, function(o) { return o.rating; });

      return {
        count: (this.feedbacks.length==0)? 0 : (sum / this.feedbacks.length).toFixed(1),
        percentage: 0
      }
    },
    url(){
        return `/feedbacks/${this.$route.params.id}`
    },
    lowFeedback(){
      let low = this.feedbacks.filter(o => o.rating < 3);
      return low;
    },
    blacklisted(){
      return {
        count: this.blacklist.length,
        percentage:0,
        url:`/blacklist-users/${this.$route.params.id}?type=${this.$route.query.type}`
      }
    },
    lateWithdrawalUrl(){
      return `/user/late-withdrawal/${this.$route.params.id}`
    },
  },
  methods:{
    updateTranslatorWarningModal(){
      this.showBookingWarning = !this.showBookingWarning
    },
    handleDelayCount(){

      this.showDelayedBookings = !this.showDelayedBookings
    }
  },
  mounted(){
    if(this.$route.query.type==2){
      this.blacklist_title = 'Blacklisted Translator'
    }

  }


}
</script>

<style>

</style>
