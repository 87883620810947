/*
|--------------------------------------------------------------------------
| Store > Municipality > Mutations
|--------------------------------------------------------------------------
|
| This file contains the mutations property of Municipality Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {mapProps} from "../../../helpers/Common";

export default {

    /**
     * Method to map the value target entry's values into the form.
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    setCompose(state) {
        mapProps(state.item.data, state.compose);
    },

    /**
     * Method to reset the compose prop to its pristine state.
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    resetCompose(state) {
        state.compose = _.cloneDeep(state.composeFresh);
    },

    /**
     * Method to map the form values into the payload
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    setPayloadData(state) {
        _.merge(
            state.payloadData, // The destination of the merged values.

            // Below are source of the values to be merged.
            state.compose,
        );
    }, // End of preparePayload

    resetList(state) {
        state.list.data = {
            'booking_resources': [],
        };
    },

    setIsBooking(state, payload) {
        state.is_show_on_booking = payload.is_hide_on_booking;
    }

} // End of export default