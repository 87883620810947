<template>
  <div>
    <h4>Message</h4>
    <el-form-item label=''>
      <el-input type='textarea' v-model="iValue.message" />
    </el-form-item>
<!--     <h4>Files</h4>-->
<!--    <el-form-item label=''>-->
<!--      <aws-file-upload v-model="iValue.files"  @progress="handleProgess" @deleteFile="handleDeleteFile"/>-->
<!--    </el-form-item>-->

<!--    <h4>Additional info</h4>-->
<!--    <el-form-item label=''>-->
<!--      <el-input type='textarea' v-model="iValue.additional_info" />-->
<!--    </el-form-item>-->

    <h4>Patient phone</h4>
    <el-form-item label=''>
      <el-input v-model="iValue.phone" />
    </el-form-item>

  </div>
</template>

<script>

import AwsFileUpload from '~/components/displays/AwsFileUpload.vue'
import { mapActions } from 'vuex'
import { isEmpty } from '../../../../js/helpers/Common'
export default {
  props:{
    value:{
      type:Object,
      default:{
        id:'',
        booking_id:'',
        message:'',
        additional_info:'',
        files:[]
      }
    }
  },
  components:{
    AwsFileUpload
  },
  computed:{
    iValue:{
      get(){ return this.value},
      set(v){ this.$emit('input',v) }
    }
  },
  methods:{

    ...mapActions('booking',['deleteConveyFile']),

    handleProgess(e){
      console.log(e)
    },
    async handleDeleteFile(e){
      if(!isEmpty(e.id)){
        try{
        let res = await this.deleteConveyFile({
          id: e.pivot.convey_booking_id,
          file_id: e.id
        });
        let filtered = this.iValue.files.filter((file) =>{
            return file.id !== e.id
          })
        this.iValue.files = filtered
        }catch(e){
          console.log(e)
        }
      }
    },


  }
}
</script>
