<template>
    <div class="app-field languages-field">

        <!-- Languages Selector -->
        <el-form-item :id="genContainerId('language_ids',formElId)"
                      :label="$t('languages')" class="label-short">
            <el-select :id="genFieldId('language_ids',formElId)"
                       v-model="language_ids" multiple filterable
                       :placeholder="$t('please_select_language_interpretation')">
                <el-option v-for="l in languageOpts"
                           :key="l.id"
                           :value="l.id"
                           :label="l.name"/>
            </el-select>
        </el-form-item>

        <div class="app-splitter two-cols" v-for="id in language_ids" :key="id">
          <!-- Translator Level Selector -->
          <languages-translator-levels-field :form-el-id="formElId"
                                            :language-name="getLangName(id)"
                                            v-model="languages_levels[id]"/>
          <!-- Customer Disallowed Types -->
          <customer-disallowed-types-field :form-el-id="formElId"
                                            v-model="customer_disallowed_types[id]"/>
        </div> <!-- /.app-splitter two-cols -->

    </div> <!-- /.app-field.languages-field -->
</template>

<script>
  import {mapGetters, mapActions} from "vuex";
  import {populate} from "../../../js/helpers/Vuex";
  import {isEmpty} from "../../../js/helpers/Common";
  import CustomerDisallowedTypesField from '~/components/forms/user/CustomerDisallowedTypesField';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > Components
    |--------------------------------------------------------------------------
    */
    components: {
      CustomerDisallowedTypesField,
    },

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      formElId: {
        type: String,
        default: 'languages_field_parent'
      },

      // Expected : Array of Objects.
      value: {
        type: Array,
        required: true
      },

      customerDisallowedTypes: {
        type: Array,
        required: true
      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        language_ids: [],
        languages_levels: {},
        customer_disallowed_types: {}
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters('language', {
        languageOpts: 'getLanguages'
      })
    },

    /*
    |--------------------------------------------------------------------------
    | Component > watch
    |--------------------------------------------------------------------------
    */
    watch: {

      /**
       * @param {array} ids
       * @return {void}
       */
      language_ids (ids) {

        // Unset the key from languages_levels when something is removed.
        _.each(Object.keys(this.languages_levels), (v) => {
          if (!_.includes(ids, parseInt(v))) {
            _.unset(this.languages_levels, v)
          }
        });

        this.updateValue();
      },

      languages_levels: {
        deep: true,
        handler (newVal) {
          this.updateValue();
        }
      },
      customer_disallowed_types: {
        deep: true,
        handler (newVal) {
          this.updateDisallowedTypeValue();
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions('language', {
        loadLanguages: 'fetchLanguages'
      }),

      /**
       * @param {int} id - the ID of the language
       * @return {string}
       */
      getLangName (id) {
        if (!isEmpty(this.languageOpts)) {
          let lang = window._.find(this.languageOpts, (o) => o.id == id);
          return lang.name;
        }
      },

      updateValue () {
        let result = [];
        _.each(this.languages_levels, (levels, langId) => {
          _.each(levels, (level) => {
            result.push({
              language_id: parseInt(langId),
              translator_level_id: parseInt(level)
            });
          });
        });

        this.$emit('input', result);
      },

      /**
       * Method to map this.value to the fields.
       *
       * @return {void}
       */
      applyValue () {
        let formatted = {};
        _.each(this.value, (v) => {
          if (isEmpty(formatted[v.language_id])) {
            formatted[v.language_id] = [];
          }
          formatted[v.language_id].push(v.translator_level_id);
        });

        // Apply to fields. -----
        this.language_ids = [];
        _.each(Object.keys(formatted), (id) => {
          this.language_ids.push(parseInt(id))
        });
        this.languages_levels = _.cloneDeep(formatted);
      },

      updateDisallowedTypeValue () {
        let result = [];
        _.each(this.customer_disallowed_types, (types, langId) => {
          _.each(types, (type) => {
            result.push({
              language_id: parseInt(langId),
              customer_type_id: parseInt(type)
            });
          });
        });

        this.$emit('update', result);
      },

      /**
       * Method to map this.customerDisallowedTypes to the fields.
       *
       * @return {void}
       */
      applyDisallowValue () {
        // Apply disallowed customer types to fields. -----

        let formatted = {};
        _.each(this.customerDisallowedTypes, (v) => {
          if (isEmpty(formatted[v.language_id])) {
            formatted[v.language_id] = [];
          }
          formatted[v.language_id].push(v.customer_type_id);
        });

        this.customer_disallowed_types= _.cloneDeep(formatted);
      },

      /**
       * Method for attempting to apply this.value into the fields.
       * I Apologize as this component is very faulty that I had to do this.
       *
       * @return {void}
       */
      attemptToApplyValue () {
        // Define min and max attempts;
        let min = 0;
        const max = 3;

        let timer = setInterval(() => {
          this.applyValue();
          this.applyDisallowValue();
          min++;
          // If the value has been applied or
          // the max attempts has been reached, then clear the timer.
          if (this.value.length > 0 || min === max) clearInterval(timer);
        }, 1000);
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      populate(this, 'loadLanguages', 'languageOpts', {
        ref: 'Languages Listing'
      });

      // Because of difficulties on mappers. Set and Interval to map the values initialy.
      this.attemptToApplyValue();
    }
  }
</script>
