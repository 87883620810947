/*
|--------------------------------------------------------------------------
| Store > UI
|--------------------------------------------------------------------------
|
| A Class file that manages all the properties and abilities in relation
| to UI. One example is, the state of the color-theme that should currently render.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/

'use strict';
import state from './state';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state, getters, mutations
}
