/*
|--------------------------------------------------------------------------
| Store > emails-templates > Actions
|--------------------------------------------------------------------------
|
| This file contains the actions property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import APICaller from '~/js/helpers/APICaller';

import API from '~/js/constants/api';
import {extendObjParams, isEmpty, sd,mapEndpoint} from "~/js/helpers/Common";
import i18n from '../../i18n';
import {Notification} from "element-ui";
const endpoint = API.EMAILS_TEMPLATES;
import moment from 'moment'
export default {
  /**
   * Request emails list
   *
   * @param {object} state
   *  : current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {void}
  */
  getEmailsList: ({commit}, payload) => {

    return APICaller({
      method: 'get',
      endpoint: endpoint
    }).then((response) => {
        commit('setEmails', response.data)
        return response
      }
    ).catch( (e) => {

      console.log(e)
      return e
    })
  },


  /**
   * Get the email data from the API.
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  getEmail (context , payload = {}){
    const endpoint_with_id = `${API.EMAILS_TEMPLATES}/${payload.id}`;
    return APICaller({
      method: 'get',
      endpoint: endpoint_with_id
    }).then((response) => {
      let data = response.data.data.emails_templates
      return response
    }).catch(error => {

      return error
    })
  },
  createEmail(context, payload) {

   return APICaller({
     method: 'post',
     endpoint: endpoint,
     data: payload,
     isDataRaw: true
   }).then((response) => {
     let data = response.data
     Notification.success({
       title: 'Created',
       message: 'Email template created',
     });
     return response
   }).catch(error => {

     return error
   })
 },
deleteEmail(context, payload) {
 const endpoint_with_id = `${API.EMAILS_TEMPLATES}/${payload}`;
 return APICaller({
   method: 'delete',
   endpoint: endpoint_with_id,
 }).then((response) => {
   let data = response.data
   Notification.success({
     title: 'Deleted',
     message: 'Email template deleted',
   });
   return response
 }).catch(error => {

   return error
 })
},
editEmail(context, payload) {
  const endpoint_with_id = `${API.EMAILS_TEMPLATES}/${payload.id}`;
  return APICaller({
    method: 'put',
    endpoint: endpoint_with_id,
    data: payload,
    isDataRaw: true
  }).then((response) => {
    let data = response.data
    Notification.success({
      title: 'Edited',
      message: 'Emails template edited',
    });
    return response
  }).catch(error => {

    return error
  })
},
sendEmail(context, payload) {
  let type = i18n.t(payload.booking.type)
  // let hour = moment(moment.duration(payload.booking.duration, "minutes").asMilliseconds()).format("H")
  // let min = moment(moment.duration(payload.booking.duration, "minutes").asMilliseconds()).format("mm")
  let duration = moment().startOf('day').add(payload.booking.duration, 'minutes').format('HH:mm')
  let data ={
    booking_id:[payload.booking.id],
    requester:{
      name: payload.booking.customer.name,
      email: payload.recipient,
    },
    status:'solved',
    title:payload.title,
    body:`<p>
          Bokningsnummer: ${payload.booking.id} <br>
          Språk : ${payload.booking.language_name}tolk <br>
          Datum : ${payload.booking.due} <br>
          Varaktighet : ${duration} <br>
          Typ : ${type} <br>
          ${(payload.booking.booker_name) ? 'Bokarens namn : '+payload.booking.booker_name+'<br>' :''}
          ${(payload.booking.reference) ? 'Referens:' + payload.booking.reference + '<br>' : ''}
          ${(payload.assigned_translator)? 'Assigned Translator :' + payload.assigned_translator: ''}
          </p>
          ${payload.email}`
  }

  return axios.post(`${app_dt_handesk_url}/api/tickets`,data,{
    headers:{
      'token':app_dt_handesk_token
    }
  }).then(response => {
      Notification.success({
        title: 'Sent',
        message: 'The email is sent',
      });
      return response;
    }).catch(error => {
      console.log(error)
      commit('setLoading', false)
      return error;
    });
},
}
