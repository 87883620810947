<template>
  <el-form @submit.native.prevent="submitForm">
    <el-form-item
      class="full-width center-block"
      style="max-width: 500px;"
      id="select-assigned-translator"
    >
      <el-select v-model="iValue" placeholder="Type to select sranslator" clearable filterable>
        <el-option v-for="t in translators" :key="t.id" :value="t" :label="t.email"></el-option>
      </el-select>
    </el-form-item>
    <el-button type="primary" @click="createCertificate()">Generate Certificate</el-button>
  </el-form>
</template>

<script>
import { isEmpty } from "~/js/helpers/Common";
import { Notification } from "element-ui";
import { populate } from "~/js/helpers/Vuex";
import { mapGetters, mapActions } from "vuex";
import {fontBase64, imageBase64} from './config'
import jsPDF from 'jspdf'
export default {
  data() {
    return {
      iValue: ""
    };
  },
  computed: {
    ...mapGetters({
      translators: "booking/getTranslatorsList"
    })
  },

  mounted() {
    populate(this, "loadTranslators", "translators", {
      ref: "translators Options"
    });

    console.log(this.translators);
  },
  methods: {
    ...mapActions("booking", {
      loadTranslators: "fetchTranslators"
    }),
    createCertificate() {
          const adminData =  JSON.parse(localStorage.getItem('app_user_data'));
          let pdfName = 'certificate';
          var imgData = imageBase64;
            var doc = new jsPDF();
   doc.setFontSize(13);

doc.addImage(imgData, 'JPEG', 10, 10, 100, 0);
doc.text("Stockholm, "+moment(new Date()).format("YYYY-MM-DD") +"", 150, 50);
var splitTitle = doc.splitTextToSize("Härmed intygas att, "+this.iValue.name+", "+this.iValue.dob+", har varit verksam som frilansande vid behov tolk genom DigitalTolk, Digital Interpretations Scandinavia AB, org nr 559032 - 5394, sedan "+moment(this.iValue.created_at).format("YYYY-MM-DD")+"", 180);
doc.text(splitTitle,10,80); 

doc.addFileToVFS('Honeymoon Avenue Script Demo.ttf', fontBase64)
doc.addFont('Honeymoon Avenue Script Demo.ttf', 'custom', 'normal');

doc.setFont('custom');

doc.setFontSize(70);
doc.text(adminData.name, 10, 188);




doc.line(10, 200, 110, 200);
doc.setFontSize(13);
doc.setFont('normal');
doc.text(adminData.name, 10, 215);
doc.text('Tolkförmedlare', 10, 220);
doc.text('Tfn: +46 (0)10 188 07 17', 10, 225);
doc.text('info@digitaltolk.se', 10, 230);
doc.text('www.digitaltolk.se', 10, 235);

doc.text('Besöksadress:', 10, 245);
doc.text('Hälsingegatan 49', 10, 250);
doc.text('113 31, Stockholm', 10, 255);
doc.save(pdfName + '.pdf');


    }
  }
};
</script>

<style>
</style>
