<template>
  <div v-if="!isEmpty(court_order)">
                <el-row>
                <el-col :span="4">{{$t('status')}}</el-col>
                <el-col :span="8">{{court_order.answer_status}}</el-col>
              </el-row>
              <el-row>
                <el-col :span="4">{{$t('comment')}}</el-col>
                <el-col :span="8"><div v-html="court_order.answer_comment"></div></el-col>
              </el-row>
              <el-row>
                <el-col :span="4">{{$t('files')}}</el-col>
                <el-col :span="8">
                  <ul class="file-list">
                    <li>
                      <el-button type="text" size="mini" @click="getFileLink({'ssn':court_order.subject_ssn},'order')">{{$t('order')}}</el-button>
                    </li>
                    <li>
                      <el-button type="text" size="mini" @click="getFileLink({'ssn':court_order.subject_ssn},'certificate')">{{$t('certificate')}}</el-button>
                    </li>
                    <li v-for="i in court_order.files" :key="i.id">
                      <el-button type="text" size="mini" @click="getFileLink({'ssn':court_order.subject_ssn,filename:i.name})">{{i.description}}</el-button>
                    </li>
                  </ul>
                </el-col>
              </el-row>
              </div>
</template>

<script>

  import {
    mapActions
  } from "vuex";

  import {
    isEmpty
  } from "~/js/helpers/Common";
  export default {
    props: {
      issue: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        court_order: {}
      }
    },
    computed: {
      iIssue() {
        return this.issue
      }
    },
    methods: {
      ...mapActions({
        translatorCourtOrder: 'translator/readCourtOrder',
        viewCourtFile: 'translator/generateOrderFileLink',
        crimeCheck: 'bookingIssue/browse'
      }),
      isEmpty(v) {
        return isEmpty(v)
      },
      getFileLink(val, type = 'file') {
        let params = val
        params.type = type
        this.viewCourtFile(params).then(r => {
          console.log(r)
        })
      }
    },
    mounted() {
      this.translatorCourtOrder({
        id: this.iIssue.translator_id,
      }).then(r => {
        this.court_order = r.data.data.order
      })
    }
  }
</script>

<style lang="scss">

ul.file-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  }
</style>
