<template>
  <div class="app-list financial-template-list">
    <base-list-table
      class="invoice-list-table"
      :table-data="listData"
      :extra-cols="listCols"
      :hasDetailsCol="false">

      <template slot="topControls">

        <el-button type="primary" size="mini" @click="handleClickAdd">
          <span class="fa fa-plus"></span> {{$t('add_template')}}
        </el-button>

      </template>

      <template slot="actionsColumn" slot-scope="scope">
        <el-button class="circle" type="success" :title="$t('edit')" @click="handleClickEdit(scope)">
          <span class="fa fa-pencil"></span>
        </el-button>
        <el-button class="circle" type="danger" :title="$t('remove')" @click="handleClickDelete(scope)">
          <span class="fa fa-trash"></span>
        </el-button>
        <el-button class="circle" type="warning" :title="$t('duplicate')" @click="handleClickDuplicate(scope)">
          <span class="fa fa-clone"></span>
        </el-button>
      </template>

    </base-list-table>

    <!-- Pagination -->
    <el-pagination
      v-if="listPagination.total > listPagination.per_page"
      layout="prev, pager, next"
      :page-size="listPagination.per_page"
      :total="listPagination.total"
      :current-page="listPagination.current_page"
      @current-change="handleChangePage">
    </el-pagination>

  </div> <!-- /.app-list financial-template-list -->
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import {populate} from '../../../js/helpers/Vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {}, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {};
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters({
        listData: 'financialPriceTemplate/listData',
        listPagination: 'financialPriceTemplate/listPagination',
      }),

      /**
       * Object to map the columns of the base-list-table.
       * @return {array}
       */
      listCols () {
        const _this=this;
        return [
          {
            label: _this.$t('id'),
            prop: 'id',
            width: '70px',
            className: 'id-col',
          },
          {
            label: _this.$t('name'),
            prop: 'name',
            className: 'name-col',
          },
        ];
      },

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions('financialPriceTemplate', {
        loadList: 'browse',
        deleteItem: 'delete',
        duplicateItem: 'duplicate'
      }),

      /**
       * Handler when the Add/Create button was clicked.
       *
       * @return {void}
       */
      handleClickAdd () {
        this.$router.push({
          name: 'financial-price-template-add',
        });
      },

      /**
       * Handler when the Edit/Update button was clicked.
       *
       * @param {object} scope - the target scope/row.
       * @return {void}
       */
      handleClickEdit (scope) {
        this.$router.push({
          name: 'financial-price-template-edit',
          params: {id: scope.row.id}
        });
      },

      /**
       * Handler when the Delete/Remove button was clicked.
       *
       * @param {object} scope - the target scope/row.
       * @return {void}
       */
      handleClickDelete (scope) {

        this.$confirm(this.$t('n_are_you_sure_to_delete'), {
          confirmButtonText: 'Yes', cancelButtonText: 'No', type: 'warning'

        }).then(() => {
          const row = scope.row;
          this.deleteItem({id: row.id});

        }).catch(() => {});
      },

      /**
       * Handler when the Duplicate button was clicked.
       *
       * @param {object} scope - the target scope/row.
       * @return {void}
       */
      handleClickDuplicate (scope) {
        const id = scope.row.id;

        this.$confirm(`Are you sure you want to duplicate template (${id})?`, {
          confirmButtonText: 'Yes', cancelButtonText: 'No', type: 'warning'

        }).then(() => {
          this.duplicateItem({id});

        }).catch(() => {});
      },

      /**
       * Handler when the pagination value was changed.
       * @return {void}
       */
      handleChangePage (n) {

        const opts = {
          action: 'loadList',
          data: 'listData',
          ref: 'Price Template Listing',
        };
        const params = _.clone(this.$route.query);

        this.reloadList(n, opts, params);
      },

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {

      populate(this, 'loadList', 'listData', {
        ref: 'Price Template List',
      });

    }, // End of Component > mounted

  } // End of export default
</script>
