<template>
  <div class="list-base-table">

    <div class="list-base-table-top-controls" v-if="hasTopControlsSlot">
      <slot name="topControls"/>
    </div> <!-- .list-base-table-top-controls -->

    <div class="list-base-table-wrapper">

      <!-- Base List Table -->
      <el-table :data="tableData" style="width: 100%" v-loading="loading"
                :row-class-name="checkRowClass">

        <el-table-column
          v-for="col in cols"
          :key="crProps(col, 'prop')"
          :prop="crProps(col, 'prop')"
          :label="crProps(col, 'label')"
          :width="crProps(col, 'width')"
          :class-name="'blt-col ' + crProps(col, 'className')"
          :sortable="crProps(col, 'sortable')">
          <template slot="header" slot-scope="scope">
            <h4>{{ crProps(col, 'label') }}</h4>
            <el-input v-if="col.is_input" v-model="iValue[col.form_prop]"></el-input>
            <el-date-picker
              v-else
              v-model="iValue[col.form_prop]"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              align="right"
              :start-placeholder="$t('start_date')"
              :end-placeholder="$t('end_date')"
              :default-time="['00:00:00', '23:59:50']"
              :picker-options="{
                firstDayOfWeek:1
              }"
            >
            </el-date-picker>
          </template>
          <template slot-scope="scope">
              <span class="cell-content" v-html="mok(scope.row, crProps(col, 'prop'), col.mutator)"></span>
          </template>
        </el-table-column>

      </el-table>
      <!-- End of Base List Table -->
    </div> <!-- .list-base-table-wrapper -->


  </div> <!-- .list-base-table -->
</template>

<script>
  import {mok, crProps, isEmpty} from "../../../js/helpers/Common";
  import isNil from 'lodash/isNil';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      tableData: {
        type: Array,
        default() {
          return [];
        }
      },
      extraCols: {
        type: Array,
        default() {
          return [];
        }
      },
      detailsColWidth: {
        type: String,
        default: '',
      },
      actionsColWidth: {
        type: String,
        default: '',
      },
      selectColWidth: {
        type: String,
        default: '',
      },
      companyColWidth: {
        type: String,
        default: '',
      },
      departmentColWidth: {
        type: String,
        default: '',
      },
      usersColWidth: {
        type: String,
        default: '',
      },
      infoColWidth: {
        type: String,
        default: '',
      },
      hasDetailsCol: {
        type: Boolean,
        default: true,
      },
      hasActionsCol: {
        type: Boolean,
        default: true,
      },
      hasSelectCol: {
        type: Boolean,
        default: true,
      },
      hasCompanyCol: {
        type: Boolean,
        default: true,
      },
      hasUsersCol: {
        type: Boolean,
        default: true,
      },
      hasDepartmentCol: {
        type: Boolean,
        default: true,
      },
      hasInfoCol: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false
      },
      detailsColTitle: String,
      rowClass: {
        type: Array,
        default() {
          return [];
        }
      },

      // Parent form reference.
      formElId: {
        type: String,
        default: ''
      },

      value: {
        type: Object,
        required: true
      }
    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data: function () {
      return {
        preloading: true
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Define default columns (this should be array or objects).
       * Please refer to /src/assets/js/helpers/Common.js@crProps() for the
       * documentation of the expected values.
       *
       * @return {array}
       */
      cols: function () {

        let extra = this.extraCols;

        if (_.isEmpty(extra)) {
          extra = [
            {
              label: this.$t('id'),
              prop: 'id',
              width: '50px',
              className: 'id-col',
              form_prop: 'id',
              is_input: true,
            },
          ];
        }

        for (let e of extra) {
          if (e.prop == 'admin.name') {
            e.form_prop = 'admin_name';
            e.is_input = true;
          }
          if (e.prop == 'action_name') {
            e.form_prop = 'action';
            e.is_input = true;
          }
          if (e.prop == 'user.name') {
            e.form_prop = 'user_name';
            e.is_input = true;
          }
          if (e.prop == 'user_type') {
            e.form_prop = 'user_type';
            e.is_input = true;
          }
          if (e.prop == 'created_at') {
            e.form_prop = 'created_at';
            e.is_input = false;
          }
        }

        return extra;

      }, // End of cols() method

      /**
       * Returns wether the topControls slot is defined.
       */
      hasTopControlsSlot() {
        return !!this.$slots['topControls'];
      },

      iRowClass() {
        return this.rowClass
      },
      empty(v){
        return isEmpty(v)
      },

      iValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },

    }, // End of Component > computed

    watch: {
        "iValue.admin_name"(val) {
          this.$parent.reloadList(1);
        },

        "iValue.action"(val) {
          this.$parent.reloadList(1);
        },

        "iValue.user_name"(val) {
          this.$parent.reloadList(1);
        },

        "iValue.user_type"(val) {
          this.$parent.reloadList(1);
        },

        "iValue.created_at"(val) {
          this.$parent.reloadList(1);
        }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      // Mapping of helpers
      crProps, mok,isEmpty,

      checkRowClass({row}) {
        if (!isEmpty(this.iRowClass)) {
          let className = '';
          _.each(this.iRowClass, (v) => {
            if (row[v.field]) {
              className = v.class
            }
          })
          return className
        }

        return '';
      },
    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted: function () {
      if (!isNil(this.$route.query.admin)) {
        this.iValue.admin_name = this.$route.query.admin;
      }
    }, // End of Component > mounted

  } // End of export default
</script>

<style>


</style>
