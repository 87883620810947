<template>
  <el-dialog :title="$t('transaltor_late_text')"
      :modal-append-to-body="false"
      :visible.sync="iVisible"
      :append-to-body="true"
      custom-class="custom-modal"
      width='40%'>
    <div>
        <el-form :model="form" @submit.native.prevent>
          <!-- <el-form-item label="">
            <el-checkbox label="Is Translator Late" v-model="form.is_late"></el-checkbox>
          </el-form-item> -->
          <div class="modal-text-center">
            <el-form-item :label="$t('delay_minute')" props="delay_time" class="label-quarter-width late-modal-delay-minute">
              <el-input-number v-model="form.delay_time" :step="5" class="late-modal-input-number"></el-input-number>
            </el-form-item>
          </div>
          <el-form-item :id="genContainerId('save_btn','translator-late')" class="el-form-actions">
             <el-button class="modal-size" type="default" @click="handleClose">{{$t('cancel')}}</el-button>
             <el-button class="modal-size" type="primary" @click="handleSubmit">{{$t('submit')}}</el-button>
          </el-form-item>
        </el-form>
    </div>
  </el-dialog>
</template>
<script>
import { mapActions } from 'vuex';
import { Notification } from 'element-ui';
export default {
  props:{
    visible:{
      type: Boolean,
      default: false
    },
    booking:{
      type: Object
    }
  },
  data(){
    return {
      form:{
        is_late:1,
        delay_time:0
      },
      rules:{
        delay_time:[
          {required:true,message:'Please add minutes'},
        ]
      }
    }
  },
  computed:{
    iVisible: {
        get() {
            return this.visible
        },
        set(v) {
            this.$emit('update:visible', v);
        }
      },
      iBooking(){
        return this.booking
      }
  },
  methods:{
    ...mapActions('booking',['postTranslatorLate']),

    handleClose: function () {
      this.$emit('update:visible', false);
    }, // End of handleClose() method

    handleSubmit(){
      let form = Object.assign(this.form,{
        id: this.iBooking.id,
        translator_id: this.iBooking.assigned_translator.translator_id,
      })
      this.postTranslatorLate(form).then((r)=>{

        Notification.success({
          title: 'Success',
          message: 'Translator delay added'
        });

        this.$emit('update:visible', false);
      })
    }
  }
}
</script>
