<template>
  <div :class="layoutClass" :lang="currentLocale">

    <maintenance-mode-display/>

    <template v-if="isShowSidebar">
      <!-- Sidebar and extras -->
      <div class="sidebar-menu-btn">
        <span class="strip-line strip-line-1"></span>
        <span class="strip-line strip-line-2"></span>
        <span class="strip-line strip-line-3"></span>
      </div>
      <div class="sidebar-overlay"></div>
      <aside id="admin_sidebar">
        <sidebar-nav></sidebar-nav>
      </aside>
    </template>

    <!-- Main Body -->
    <main id="main_body"
          :class="{'sidebar-margin' : $route.meta.show_sidebar || $route.meta.show_sidebar === undefined }">

            <page-top-bar v-if="isShowPageTopBar"></page-top-bar>
            <transition name="fade">
                <router-view :key="($route.path.includes('resources')) ? JSON.stringify($route) : ''"></router-view>
            </transition>
        </main>

  </div>
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockClass: 'master-layout'
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters('ui', ['colorTheme']),
      ...mapGetters('sidebar', ['isFoldSidebar']),
      ...mapGetters('viewport', ['isOnMobileViewport']),

      /**
       * @returns {boolean}
       */
      isShowSidebar () {
        return this.$route.meta.show_sidebar !== false;
      },

      /**
       * @returns {string[]}
       */
      layoutClass () {
        const pageClass = this.$route.meta.page_class == null
          ? ''
          : this.$route.meta.page_class;

        const foldClass = this.isFoldSidebar
          ? this.getBem(this.blockClass, null, 'fold-sidebar')
          : this.getBem(this.blockClass, null, 'unfold-sidebar');

        const viewportClass = this.isOnMobileViewport
          ? this.getBem(this.blockClass, null, 'mobile-viewport')
          : this.getBem(this.blockClass, null, 'desktop-viewport');

        document.body.className = [
          pageClass,
          this.colorTheme,
          foldClass,
          viewportClass
        ].join(' ');

        return [
          this.blockClass,
          'master-wrapper',
        ];
      },

      /**
       * @returns {boolean}
       */
      isShowPageTopBar () {
        return this.$store.getters['auth/hasAccessToken']
          && this.$route.path !== '/'
          && this.$route.path !== '/logout';
      },

      /**
       * @returns {string}
       */
      currentLocale () {
        return this.$root.$i18n.locale;
      }
    }
  };
</script>
