<template>
    <div class="app-page" :class="getBem(blockCls)">
        <page-header :title="$t('financial_settings_list')" :back-enabled="true" />

        <div class="app-container">
            <div class="app-wrapper">

                <!-- Top Controls -->
                <div class="app-row">
                    <div class="app-col full-width">
                        <financial-settings-entity-selector />
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->

                <!-- Settings List -->
                <div class="app-row">
                    <div class="app-col full-width">
                        <financial-settings-list />
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->

                <div
                    v-if="isComposeModeAdd || isComposeModeEdit"
                    class="app-row"
                >
                    <div class="app-col full-width">
                        <financial-settings-compose-partial />
                    </div> <!-- /.app-col -->
                </div>
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->
    </div> <!-- Component DIV Wrapper -->
</template>

<script>
  import {mapGetters} from 'vuex';
  import FinancialSettingsEntitySelector from '~/components/forms/financial/settings/FinancialSettingsEntitySelector';
  import FinancialSettingsList from '~/components/lists/financial/settings/FinancialSettingsList';
  import FinancialSettingsComposePartial from '~/components/forms/financial/settings/FinancialSettingsComposePartial';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      FinancialSettingsEntitySelector,
      FinancialSettingsList,
      FinancialSettingsComposePartial,
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-settings-list-page',
        activePanels: ['list', 'compose'],
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters('financialPriceList', [
        'isComposeModeAdd',
        'isComposeModeEdit',
      ]),
    },
  }
</script>
