<template>
    <div>
        {{$t('blocked_translators')}}
    </div>
</template>

<script>
export default {
    name: 'blockedTranslators'
}
</script>

<style>

</style>
