<template>
  <div class="temporary-number-container">

    <el-dialog
      :visible.sync="iVisible"
      :append-to-body="true"
      :lock-scroll="true"
      :title="$t('temporary_number')"
    >

      <div class="temporary-number-body">
        <el-form :model="form">
          <el-row>
            <el-col>
              <el-form-item label="Date Time" class="label-block">
                <el-date-picker
                  v-model="form.date_time"
                  type="datetimerange"
                  range-separator="To"
                  start-placeholder="Start date"
                  format="yyyy-MM-dd HH:mm:ss"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="Mobile" class="label-block">
                <el-input v-model="form.temporary_mobile_numbers[0].mobile"></el-input>
              </el-form-item>
            </el-col>

            <el-col>
              <div class="text-center">
                <el-button
                  type="primary"
                  @click="handleTemporaryNumberClick"
                >Save
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>

    </el-dialog>
  </div>

</template>

<script>

  import {mapActions} from 'vuex';

  export default {
    name: "TemporaryNumberModal",

    /**
     *
     */
    props: {
      visible: {
        type: Boolean,
        default: false
      },
    },

    /**
     *
     */
    data() {
      return {
        form: {
          date_time: '',

          temporary_mobile_numbers: [
            {
              valid_from: '',
              valid_until: '',
              mobile: ''
            }
          ]
        }
      }
    },

    computed: {

      /**
       *
       */
      iVisible: {
        get() {
          return this.visible;
        },
        set(v) {
          this.$emit("update:visible", v);
        },
      },

      /**
       * Return the USER ID
       */
      userId() {
        return this.$route.params.id
      }
    },

    /**
     *
     */
    methods: {
      ...mapActions('user', {
        updateTemporaryNumberOfUser: 'updateTemporaryNumberOfUser'
      }),

      /**
       * Click handle for saving temporary number
       */
      handleTemporaryNumberClick() {
        this.form.temporary_mobile_numbers[0].valid_from = moment(this.form.date_time[0]).format('YYYY-MM-DD HH:mm:ss');
        this.form.temporary_mobile_numbers[0].valid_until = moment(this.form.date_time[1]).format('YYYY-MM-DD HH:mm:ss');
        this.form.id = this.userId;
        delete this.form.date_time;
        this.updateTemporaryNumberOfUser(this.form).then(() => {
          this.iVisible = false
        })
      }
    }
  }
</script>

<style scoped>

</style>
