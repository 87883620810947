<template>
    <div class="app-fields user-service-fields">

        <h3 class="text-center">{{$t('please_fill_in_the_fields')}}</h3>

        <!-- Languages Picker -->
        <languages-field :form-el-id="formElId" v-model="iValue.languages" :customer-disallowed-types="iValue.disallowed_customer_types" @update="updateDisallowedTypes"/>

        <div class="app-splitter two-cols">

            <!-- Booking Types -->
            <el-form-item :id="genContainerId('booking_types',formElId)"
                          :label="$t('booking_types')" class="label-short">
                <el-select :id="genFieldId('booking_types',formElId)"
                           v-model="iValue.booking_types" multiple
                           :placeholder="$t('please_select_translator_booking_types')">
                    <el-option :label="$t('phone')" value="phone"/>
                    <el-option :label="$t('physical')" value="physical"/>
                    <el-option :label="$t('video')" value="video"/>
                    <el-option :label="$t('video_skype')" value="video_skype"/>
                    <el-option :label="$t('video_physical')" value="video_physical"/>
                    <el-option :label="$t('convey')" value="convey"/>
                    <el-option :label="$t('text_translation')" value="text_translation"/>

                </el-select>
            </el-form-item>

            <!-- Translator Customer Types -->
            <!-- <el-form-item :id="genContainerId('translator_customer_types',formElId)"
                          label="Translator Customer Types" class="label-short">
                <el-select :id="genFieldId('translator_customer_types',formElId)"
                           v-model="iValue.translator_customer_types" multiple>
                    <el-option v-for="(v) in customerTypeOpts"
                               :key="v.id"
                               :value="v.id"
                               :label="v.name"/>
                </el-select>
            </el-form-item> -->

            <!-- Towns -->
            <el-form-item :id="genContainerId('towns',formElId)"
                          :label="$t('text_town_for_project')" class="label-short">
                <el-select :id="genFieldId('towns',formElId)"
                           v-model="iValue.towns" multiple filterable
                           :placeholder="$t('please_select_translator_town')">
                    <el-option v-for="(v) in townOpts"
                               :key="v.id"
                               :value="v.id"
                               :label="v.name"/>
                </el-select>
            </el-form-item>


            <!-- Country -->
            <el-form-item :id="genContainerId('countries',formElId)"
                          :label="$t('country')" class="label-short">
                <el-select :id="genFieldId('countries',formElId)"
                           v-model="iValue.countries" filterable multiple
                           :placeholder="$t('please_select_translator_country')">
                    <el-option v-for="(v, k) in countryIsoOpts"
                               :key="k"
                               :label="v"
                               :value="k"/>
                </el-select>
            </el-form-item>

        </div> <!-- /.app-splitter two-cols -->
        <div>
            <!-- Allowed Customer Types -->
            <!-- <el-form-item :id="genContainerId('allowed_customer_types',formElId)"
                          label="Allowed Customer Types" class="label-short">
                <el-select :id="genFieldId('allowed_customer_types',formElId)"
                           v-model="iValue.allowed_customer_types" multiple>
                    <el-option v-for="(v) in customerTypeOpts"
                               :key="v.id"
                               :value="v.id"
                               :label="v.name"/>
                </el-select>
            </el-form-item> -->
            <!-- <el-form-item :id="genContainerId('disallowed_customer_types',formElId)"
                          label="Disallowed Customer Types" class="label-short">
                <el-select :id="genFieldId('disallowed_customer_types',formElId)"
                           v-model="iValue.disallowed_customer_types" multiple>
                    <el-option v-for="(v) in customerTypeOpts"
                               :key="v.id"
                               :value="v.id"
                               :label="v.name"/>
                </el-select>
            </el-form-item> -->

        <template v-if="iValue.booking_types.includes('text_translation')">
            <h3 class="text-center">{{$t('language_pair_levels')}}</h3>
            <translator-language-levels v-model="iValue.language_pair_levels"></translator-language-levels>

        </template>
        </div>
    </div> <!-- /.app-fields user-service-fields -->
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import {populate} from "../../../js/helpers/Vuex";
    import TranslatorLanguageLevels from './TranslatorLanguageLevels'

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            formElId: {
                type: String,
                default: '',
            },

            value: {
                type: Object,
                required: true,
            },

        }, // End of Component > props

        components:{TranslatorLanguageLevels},
        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapGetters('form', ['countryIsoOpts']),

            ...mapGetters('town', {
                townOpts: 'listAsOptions',
            }),

            /**
             * Interface for the v-model of this component.
             */
            iValue: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                },
            },

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            ...mapActions('town', {
                loadTowns: 'browseAsOptions'
            }),

            updateDisallowedTypes(data){
                this.iValue.disallowed_customer_types =  _.cloneDeep(data);
            }

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
            populate(this, 'loadTowns', 'townOpts', {
                ref: 'Towns Listing',
            });

        }, // End of Component > mounted

    } // End of export default

</script>
