
import state from './state'
import { extendObj } from '../../../../helpers/Common';
import bread from '../../../bread';
import actions from './actions';
const getters={}
const mutations={}
// const actions={}

export default {
  namespaced: true,
  state: extendObj(bread.state, state),
  getters: extendObj(bread.getters, getters),
  mutations: extendObj(bread.mutations, mutations),
  actions: extendObj(bread.actions, actions),
} // End of export default
