<template>
  <div class="app-form invoice-compose-form">
    <el-form :id="formElId" :ref="formElId">

      <!-- Language Field -->
      <el-form-item :id="genContainerId('language',formElId)"
                    label="Language" class="label-short">
        <el-select :id="genFieldId('language',formElId)"
                   v-model="form.language" filterable>
          <el-option v-for="l in languageOpts"
                     :key="l.id"
                     :value="l.id"
                     :label="l.name"/>
        </el-select>
      </el-form-item>

      <!-- Date Field -->
      <el-form-item :id="genContainerId('date',formElId)"
                    label="Payout Date" class="label-short">
        <el-date-picker :id="genFieldId('date',formElId)"
                        popper-class="day-picker-only"
                        v-model="form.date"/>
      </el-form-item>

      <!-- PDF Invoice Name Field -->
      <el-form-item :id="genContainerId('pdf_invoice_name',formElId)"
                    label="PDF Invoice Name" class="label-short">
        <el-input :id="genFieldId('pdf_invoice_name',formElId)"
                        v-model="form.pdf_invoice_name"/>
      </el-form-item>

      <!-- PDF Invoice Customer -->
      <el-form-item :id="genContainerId('pdf_customer_name',formElId)"
                    label="PDF Invoice Name" class="label-short">
        <el-input :id="genFieldId('pdf_customer_name',formElId)"
                  v-model="form.pdf_customer_name"/>
      </el-form-item>

      <!-- Sort By -->
      <el-form-item :id="genContainerId('sort_by',formElId)"
                    label="Sort By" class="label-short">
        <el-select :id="genFieldId('sort_by',formElId)"
                   v-model="form.sort_by" filterable>
          <el-option value="translator_name"
                     label="Translator Name"/>
        </el-select>
      </el-form-item>

      <!-- Action Button -->

    </el-form>
  </div> <!-- /.app-form invoice-compose-form -->
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import {populate} from '../../../../js/helpers/Vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data() {
      return {
        formElId: 'invoice_compose_form'
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('financial', {
        form : 'invoiceForm'
      }),

      ...mapGetters('language', {
        languageOpts: 'getLanguages'
      }),

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions('language', {
        loadLanguages: 'fetchLanguages'
      }),

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted() {

      populate(this, 'loadLanguages', 'languageOpts', {
        ref: 'Languages Listing'
      });

    }, // End of Component > mounted

  } // End of export default
</script>
