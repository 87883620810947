<template>
  <div class="nonfixed-text-translation-container">
      <el-table :data="bids">
        <el-table-column label="Translator">
            <template slot-scope="scope">
                <span class="cell-content">
                    {{scope.row.translator}}
                </span>
            </template>
        </el-table-column>
        <el-table-column label="Amount" align="left">
                <editable-col :show-input="(row.bid.length == 0)" slot-scope="{row}" v-model="row.bid" :row="row">
                    <span slot="content" >kr: {{row.bid}} </span>
                   
                    <span slot="suffix">Edit price </span>
                </editable-col>
            
        </el-table-column>
        <el-table-column label="Approve">
            <template slot-scope="scope">
            <el-button type="success" size="mini" class="el-button--medium"> <small>approve</small></el-button>
            </template>
        </el-table-column>
      </el-table>

      <el-form :model="form">
        
        <el-form-item label="Cost Per Customer kr:"> 
            <el-input v-model="form.cost_per_customer" placeholder="Cost Per Customer"></el-input>
        </el-form-item>
        <el-form-item label="">
            <el-button type="success"> Send</el-button>
        </el-form-item>
      </el-form>
  </div>
</template>

<script>

// import EditableColumn from '../../../../partials/EditableColumn'
export default {
  name: '',
//   components: {EditableColumn},
  data() {
    return {
        bids: [
            {id:1,translator: 'translator 1', bid: ''},
            {id:1,translator: 'translator 1', bid: ''},
            {id:1,translator: 'translator 1', bid: '100000'},
        ],
        form: {
            cost_per_customer: ''
        }
    }
  },
  computed: {},
  methods: {},
  mounted() {
      this.bids = this.bids.map(row => {
            return {
              ...row,
              editMode: false
            }
          })
  }
}

</script>

<style lang="scss">

</style>
