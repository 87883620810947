<template>
    <div class="app-page logout-page">

        <div class="app-display-flex app-flex-center">

            <div class="preloader-container">
                <ul>
                    <li>
                        <div class="lds-gear"><div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
                    </li>
                    <li>
                        <div class="lds-gear"><div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
                    </li>
                    <li>
                        <div class="lds-gear"><div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
                    </li>
                </ul>
                <h1>{{$t('text_logout')}}</h1>
            </div>

        </div>

    </div> <!-- /.app-page logout-page -->
</template>

<script>
    import {mapMutations} from "vuex";
    export default {

        methods: {
            ...mapMutations('auth', ['destroyAccessToken','destroyUserData']),
        },

        mounted() {

            // Clear the existing token.
            this.destroyAccessToken();


            // Clear the existing userdata.
            this.destroyUserData();

            // Set delay so that the route won't redirect back to
            // starting point.
            setTimeout(() => {
                window.location.href = APP_LOGOUT_REDIRECT;
            }, 2000)

        }, // End of Component > mounted

    } // End of export default
</script>

<style lang="scss" scoped>
    .logout-page,
    .logout-page > .app-display-flex {
        width: 100%; height: 100%;
    }

    .logout-page h1 {
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .logout-page .preloader-container {
        text-align: center;

        ul, li {
            list-style: none;
            margin: 0;
            padding: 0;
            display: inline-block;
        }

        li {
            margin: 0 15px 15px;
        }

    }

</style>
