/*
|*****************************************************************************
| En Locale
|*****************************************************************************
| Here resides all translations according to the title above
|
*/
export default {

  id: 'ID',
  batch_id: 'Batch ID',
  actions: 'Actions',
  hour: 'hour',
  min: 'min',
  translator_type: ' Translator Type',
  translator_level: 'Translator Level | Translator Levels',
  translator_name: 'Translator Name',
  translator_mobile: 'Translator Mobile',
  chargeable_time: 'Chargeable Time',
  inconvenience_charge: 'Inconvenience Charge',
  booking_fee: 'Booking Fee',
  emergency_fee: 'Emergency Fee',
  travel_time: 'Travel Time',
  reimbursement: 'Reimbursement',
  km_reimbursement: 'KM Reimburse',

  mobile: 'Mobile',
  booking: 'Booking',
  physical: 'Physical',
  phone: 'Phone',
  phone_immediate: "Phone Immediate",
  physical_video: 'Physical Video',
  patient_language: 'Patient Language | Patient Languages',
  video: 'Video',
  video_physical: 'Video Physical',
  video_skype: 'Video Skype',
  convey: 'Convey',
  excluded_translators: 'Excluded Translator',
  choose_translator: 'Choose Translator',
  booking_created: 'Booking Created',
  booking_success: 'Create Booking successfull',
  success: 'Success',
  booking_updated: 'Booking Updated',
  error: 'Error',
  interval: 'Interval',

  accepted_at: 'Accepted At',
  accepted: 'Accepted',
  cancelled_at: 'Cancelled At',
  cancelled: 'Cancelled',
  rejected_at: 'Rejected At',
  rejected: 'Rejected',
  created_at: 'Created At',
  created: 'Created',
  updated_at: 'Updated At',
  updated: 'Updated',
  will_send_at: 'Will Send At',
  sent_at: 'Sent At',

  dist_car: 'KM Car',
  time_car: 'Time Car',
  dist_public: 'KM Public',
  time_public: 'Time Public',
  notes: 'Notes',

  // Translator Levels (prefixed with tlvl)
  tlvl_layman: 'Layman',
  tlvl_read_courses: 'Read Translation courses',
  tlvl_certified: 'Certified',
  tlvl_trained: 'Trained',
  tlvl_certified_in_health: 'Certified with specialisation in health care',
  tlvl_certified_in_law: 'Certified with specialisation in law',
  tlvl_deluxe: 'Deluxe',

  // Notifications (prefixed with n)
  n_entry_added: 'Entry has been created.',
  n_entry_edited: 'Entry has been updated.',
  n_entry_deleted: 'Entry had been removed.',
  n_went_wrong: 'Something went wrong, please try again.',
  n_are_you_sure_to_delete: 'Are you sure you want to delete this item?',

  // text translation status
  awaiting_price:'Awaiting Price',
  pending_translator: 'Pending Translator',
  being_translated: 'Being Translated',
  submited_for_review: 'Submited For Review',
  in_review: 'In Review',
  submited_from_review: 'Submited From Review',
  need_to_revised: 'Need To Revised',
  completed:'Completed',

  accept_other_gender:'do you accept {other} translator if no {actual} translator available?',
  accept_alt_translator_levels:'accept other levels too?',
  accept_alt_specific_translators:'can you accept other translators if that translator is not available?',

  BOOKING_SPECIFICS: {
    TRANSLATOR: 'Can you accept another translator if this one is busy?',
    GENDER: 'Can you accept the other gender if nobody is available?',
    TRANSLATOR_LEVEL: 'Can you accept other levels if nobody available?',
    PHYSICAL: {
      INDEX: 'Telephone bookings are better for the environment and increase the likelihood of finding a translator. Would you accept a telephone translator?',
      CONVERT_TO_PHONE: 'Yes telephone translator is okay.',
      WAIT_FOR_A_CERTAIN_TIME_THEN_CONVERT_TO_PHONE: 'First hand I\'d prefer a physical translator within agreed max travel time; but if no translator is available you can try with a telephone.',
      STRICT_MAX_TRAVEL_TIME: 'No, I want a physical translator, and they must adhere to the agreed maximum travel times.',
      CONSIDER_REIMBURSE: 'No, Its very important that its a physical translator, even if that means that DT finds a translator outside the max travel time, we are okay with reimbursing with that.',
    }
  },
  note: 'Note | Notes',
  text_translation:'Text Translation',
  booking_locked_by: 'Locked By',
  unlock_booking: 'Click to unlock booking',
  lock_booking: 'Click to lock the booking',
  show_pop_up_warning: 'Lock booking and show pop up modal when two admins inside booking same time',
  btn_update_admin_ui_settings: "Update Admin Ui Settings",
  trained_translator: 'Trained Translator',
  sms_centre: 'SMS Help desk'

} // End of root
