/*
|--------------------------------------------------------------------------
| Store > Language
|--------------------------------------------------------------------------
|
| A Class file that manages all the properties and abilities in relation
| to Language.
|
*/
'use strict';

import APICaller from '~/js/helpers/APICaller';

import {gep} from "~/js/helpers/Vuex";
let apiPrefix = 'v3';
const state = {
    languages: [],
    to_language: null,
    from_language:null,
    appointmentTemplates: []
}

const getters = {
    getLanguages: (state) => state.languages,
    getLanguageTo: (state) => state.to_language,
    getLanguageFrom: (state) => state.from_language,
    getAppointmentTemplates: (state) => state.appointmentTemplates,
}

const mutations = {
    setLanguages: (state,payload) => state.languages = payload,
    setLanguageTo: (state,payload) => state.to_language = payload,
    setLanguageFrom: (state,payload) => state.from_language = payload,
    setAppointmentTemplates: (state,payload) => state.appointmentTemplates = payload,

}

const actions = {
    fetchLanguages: (context,payload) => {
        return APICaller({
            method: 'get',
            endpoint: gep('languages',apiPrefix),
            params: {all: true, sort: 'name',include:'dialects'},
            onSuccessCallback: (response) => {
                let data = response.data.data.languages
                context.commit('setLanguages',data)
            }
    })
    },

    fetchLanguage: (context,payload) => {

        return APICaller({
            method: 'get',
            endpoint: gep(`languages/${payload.id}`,apiPrefix),
            onSuccessCallback:(response) => {

                if(payload.language === 'from'){
                    context.commit('setLanguageFrom',response.data.data.language)
                }else{
                    context.commit('setLanguageTo',response.data.data.language)
                }
            }
        });
    },
    fetchAppointmentLanguage: (context,payload) => {

        let url = app_appointment_api +`/api/dt-language/${payload.id}/templates`
        return axios.get(url).then(response => {
            context.commit('setAppointmentTemplates',response.data.data)
            return response;
        }).catch(error => {
            console.log(error)
            return error;
        })
    },

}

export default {
    namespaced: true,
    state, getters, mutations, actions,
} // End of export default
