<template>
  <div class="app-form kpi-settings-form">
    <el-form :model="form">
      <el-form-item class="label-short" :label="$t('feedbacks')">
        <el-input v-model="form.value.feedback" :placeholder="$t('please_input_value_0_100')"></el-input>
      </el-form-item>
      <el-form-item class="label-short" :label="$t('customer_feedback')">
        <el-input v-model="form.value.customer_feedback" :placeholder="$t('please_input_value_0_100')"></el-input>
      </el-form-item>
      <el-form-item class="label-short" :label="$t('late_cancellations_delays')">
        <el-input v-model="form.value.late_cancellation" :placeholder="$t('please_input_value_0_100')"></el-input>
      </el-form-item>
      <el-form-item class="label-short" :label="$t('inactive_translator')">
        <el-input v-model="form.value.inactive_translator" :placeholder="$t('please_input_value_0_100')"></el-input>
      </el-form-item>
      <el-form-item class="label-short" :label="$t('translator_preferences')">
        <el-input
          v-model="form.value.translator_preferences"
          :placeholder="$t('please_input_value_0_100')"
        ></el-input>
        <el-form-item>
          <p>{{$t('txt_please_ensure_total_100')}}</p>
        </el-form-item>
      </el-form-item>
      <el-form-item class="text-right">
        <el-button type="primary" @click="handleKpiSubmit()">{{$t('btn_update_kpi_settings')}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>


<script>
import { mapGetters, mapState, mapActions } from 'vuex';
export default {
  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {};
  }, // End of Component > data
  /*
  |----------------------------------------------------------------------------
  | Component > computed
  |----------------------------------------------------------------------------
  */
  computed: {
    /**
     * state of vuex for kpi settings
     */
    ...mapState('kpiSettings', {
      form: 'compose'
    })
  }, // end of component > computed
  /*
  |----------------------------------------------------------------------------
  | Component > methods
  |----------------------------------------------------------------------------
  */
  methods: {
    /**
     * Actions for kpiSettings
     */
    ...mapActions('kpiSettings', {
      postKpiInSettingsTable: 'postKpiInSettingsTable',
      getKpiSettings: 'getKpiSettings',
      updateKPISettings: 'updateKPISettings'
    }),
    /**
     * Handle kpi settings submit
     */
    handleKpiSubmit() {
      var id = _.get(this.form, 'id');
      /**
       * If there is ID present in the form then just Update
       * Else POST KPI
       */
      if (!id) {
        this.postKpiInSettingsTable(this.form);
      } else {
        this.updateKPISettings(this.form);
      }
    },
    /**
     * Prepare the basic setup for KPI Settings
     */
    prepareKpiSettingsForm() {
      this.getKpiSettings();
    }
  }, // end of component > method
  /*
  |----------------------------------------------------------------------------
  | Component > mounted
  |----------------------------------------------------------------------------
  */
  mounted() {
    this.prepareKpiSettingsForm();
  } // End of component > mounted
}; // End of export default
</script>
