<template>
    <div class="app-list admin-permissions-list">
        <el-row>
            <el-col :span="2">
                <el-button @click="handleClickBack">
                    <i class="fa fa-long-arrow-left"></i> {{$t('back')}}
                </el-button>
            </el-col>
        </el-row>

        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false"
                :hasViewCol="true"
                :hasUpdateCol="true">

            <template slot="viewColumn" slot-scope="scope">
                <a href="#" v-if="canView(scope)" @click.prevent.stop="viewDoHide(scope)" title="Click to disable"><i class="fa fa-check-circle text-success check-font"></i></a>
                <a href="#" v-else @click.prevent.stop="viewDoShow(scope)" title="Click to enable"><i class="fa fa-times-circle text-danger check-font"></i></a>
            </template>

            <!-- <template slot="updateColumn" slot-scope="scope">
                <i v-if="canUpdate(scope)" class="fa fa-check-circle text-success check-font"></i>
                <i v-else class="fa fa-check-circle text-danger check-font"></i>
            </template> -->

            <!-- <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="success" title="Edit" @click="handleEdit(scope)">
                    <span class="fa fa-pencil"></span>
                </el-button>
            </template> -->
        </base-list-table>

        <el-pagination
            v-if="listPagination.total > listPagination.per_page"
            layout="prev, pager, next"
            :page-size="listPagination.per_page"
            :total="listPagination.total"
            :current-page="listPagination.current_page"
            @current-change="reloadList">
        </el-pagination>
    </div> <!-- /.app-list admin-permissions-roles-list -->
</template>

<script>
    import {mapGetters, mapActions, mapState} from 'vuex';
    import {populate} from "~/js/helpers/Vuex";
    import {sd} from "~/js/helpers/Common";

        export default {

        /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
        props: {
            is_admin: {
                type: Boolean,
                default: false
            }
        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                listCols: [
                    {
                        label: `${this.$t('Page')}`,
                        prop: 'page_name',
                        width: '',
                        className: 'page-name-col',
                    }
                ]
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapGetters('adminPermissions', ['listData','listPagination']),

            ...mapState('auth', {
                user: 'userData'
            })

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
        methods: {
            ...mapActions('adminPermissions', ['browse', 'delete', 'editPermission']),

            handleEdit(scope) {
                if (this.is_admin) {
                    let admin_id = this.$route.params.admin_id;
                    window.location.href = '/#/admin-permissions/by-admin/' + admin_id + '/edit/' + scope.row.id;
                } else {
                    window.location.href = '/#/admin-permissions/edit/' + scope.row.id;
                }
            },

            handleDelete(scope) {
                this.$confirm('Delete this Admin Role?', 'Warning', {
                    confirmButtonText: 'YES',
                    cancelButtonText: 'NO',
                    type: 'warning'
                }).then(() => {
                    this.delete({
                        id: scope.row.id,
                        admin_id: this.user.id
                    });
                });
            },
            reloadList(n) {
                let params = _.clone(this.$route.query);
                n = sd(n, sd(this.$route.query.page, 1)) // Set the page number.
                params.page = n;
                this.$router.push({query:params})
                populate(this, 'browse', 'listData', {
                    force: true,
                    payload: {
                        id: this.user.id
                    },
                    params
                });
            },

            canView(scope) {
                let admin = null;
                if ((admin = this.getAdmin(scope.row.admins)) != null) {
                    return admin.pivot.can_view;
                }
                return scope.row.can_view;
            },

            canUpdate(scope) {
                let admin = null;
                if ((admin = this.getAdmin(scope.row.admins)) != null) {
                    return admin.pivot.can_update;
                }
                return scope.row.can_update;
            },

            getAdmin(admins) {
                let admin = null;
                let admin_id = this.$route.params.admin_id;
                for (let a of admins) {
                    if (a.id == admin_id) {
                        admin = a;
                        break;
                    }
                }

                return admin;
            },

            viewDoHide(scope) {
                this.changeState(scope, false);
            },

            viewDoShow(scope) {
                this.changeState(scope, true);
            },

            changeState(scope, val) {

                let admin_id = this.$route.params.admin_id;

                // Create sync object
                let form = this.$store.state.adminPermissions.compose;
                form.id = scope.row.id;
                form.page_name = scope.row.page_name;
                form.role_id = scope.row.role_id;
                form.can_view = scope.row.can_view;
                form.can_create = scope.row.can_create;
                form.can_update = scope.row.can_update;
                form.sync[admin_id] = {
                    'can_view' : val,
                    'can_create' : false,
                    'can_update' : false,
                };

                let payload = {};
                payload.id = scope.row.id;

                this.editPermission(payload).then((r) => {
                    let n = sd(this.$route.query.page, 1);
                    this.$store.dispatch('adminPermissions/getNavItemsFinal');
                    this.reloadList(n);
                });
            },

            handleClickBack() {
                let adminId = this.$route.params.admin_id;
                this.$router.push({path: '/admin-user/edit/' + adminId});
            }
        }, // End of Component > methods


        /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
            this.reloadList(1);
        }, // End of Component > mounted

    }
</script>

<style scoped>
.check-font {
    font-size: 13pt;
}
</style>
