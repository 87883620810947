<template>
    <div class="app-list booking-list">
        <booking-base-list
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false"
                actions-col-width='55'
                actions-col-fixed="right"
                :row-class="tableRowClassName"
                :loading="loading"
                :isbooking=true>
            <template slot="topControls">
              <div class="text-right">
                <span class="text-right">{{$t('total_records')}} : {{listPagination.total}}</span>
              </div>
            </template>
            <template slot="actionsColumn" slot-scope="scope">
                <div @contextmenu.prevent="handleDetailsNew(scope)">
                <el-button class="circle" type="success" title="Details" @click="handleDetails(scope)" size="mini" icon="el-icon-info">
                </el-button>
                </div>
                <!-- <el-button class="circle" type="danger" title="Remove" size="mini" icon="el-icon-message">
                </el-button> -->
            </template>
        </booking-base-list>

        <el-pagination
                layout="total, sizes, prev, pager, next, jumper"
                :page-size="listPagination.per_page"
                :page-sizes= [10,25,50,100,200]
                :total="listPagination.total"
                :current-page="listPagination.current_page"
                 @size-change="handleSizeChange"
                @current-change="reloadList">
        </el-pagination>

    </div> <!-- /.app-list booking-list -->
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import {populate,forcePopulate} from "~/js/helpers/Vuex"
    import {sd,isEmpty} from "../../../js/helpers/Common";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {
            vPopulatePayload:{
                type: Object,
                default(){
                    return {
                    'filter[cannot_be_planned]': 1,
                    "filter[is_test]": "0",
                    append:'has_batches,translators_count,translators_sendable_count,translators_non_sendable_count'
                }
            }
        }
        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {

            const _this = this;

            return {

                tableElId: 'booking_planned',

                perPage: 50,
                search: '',

                tableRowClassName:[
                    {
                    field: 'flagged',
                    value: true,
                    class: 'is_flagged'
                    },
                    {
                      field: 'is_test',
                      value: true,
                      class: 'is_test'
                    },
                ],

                listCols: [
                  {
                    label: '',
                    prop: "user_in_booking",
                    showPopover: true,
                    width: "20px",
                    popoverClass: 'to-work-on-popover',
                    mutator(v, row) {
                      if(v.length > 0) {
                        return '<span><i style="color: #65B4C6" class="fa fa-users"></i></span>';
                      }
                    },
                    popover(v, row) {
                      return _.uniqBy(v, function (e) {
                        return e.user_id;
                      }).map((unique)=> {
                        return unique.user.name
                      }).join('<br/>')
                    }
                  },
                  {
                    label: '',
                    prop: "locked_booking",
                    showPopover: true,
                    width: "20px",
                    popoverClass: 'to-work-on-popover',
                    mutator(v, row) {

                      if(v.length > 0 && Boolean(v[0].is_locked) === true) {
                        return '<span><i style="color: #DE4848" class="fa fa-lock"></i></span>';
                      }
                    },
                    popover(v, row) {
                      return v.map((user) => {
                        return user.user.name
                      }).join('<br/>')
                    }
                  },
                    {
                        prop: 'id',
                        label: `${this.$t('job_id')}`,
                        width: '',
                        sortable: true,
                        showPopover: true,
                        mutator(v,row){
                            let val = `${v}<br>`;
                            let counts = ``;

                            if(!row.has_been_planned){
                                val+= `<span class="red-dot"></span>`
                            }

                            if(row.has_been_planned){
                                val+= `<i class="fa fa-ban" style="color:red"></i>`
                            }

                            let flagx = false;

                            if(!flagx) {
                                flagx = true;
                            }

                            if(row.translators_count != undefined){
                                counts += `S:${row.translators_sendable_count} | N:${row.translators_non_sendable_count}`
                            }
                            return (
                                val + `${row.translators_count === undefined ? "Not created yet" : counts}`
                            );
                        },
                        popover(a,v){
                            let criteria = [];

                            // FLAGGING CRITERIA

                            // criteria : if no one5 accept
                            // checked by booking status
                            if(v.status.id === 1) {
                                criteria.push('No One Accepted')
                            }

                            // criteria : if specific translator selected
                            // checked by specific translator
                            if(v.specific_translators.length > 0) {
                                criteria.push('Specific Translator')
                            }

                            // criteria : if no matching translator less than 10 matching translator
                            // checked by less than 10 matching translator count
                            if(v.translators_count < 10) {
                                criteria.push('No Matching Translator')
                            }

                            // no available translator
                            // checkked by translator count 0
                            if(v.translators_count === 0) {
                                criteria.push('No Available Translator')
                            }

                            return criteria.join(`, <br />`);
                        }
                    },
                    {
                        prop: 'from_language_id', label: `${this.$t('language')}`, width: '',sortable: true,
                        mutator(v) {
                            // Make sure language opts is populated before running this.
                            if (!isEmpty(_this.languageOpts) && !isEmpty(v)) {
                                const found = _.find(_this.languageOpts, (x) => x.id == v)
                                return found ? found.name : console.warn('Language ID : ' + v + ' not found.');
                            }
                        }
                    },
                    {prop: 'duration', label: `${this.$t('duration')}`, width: '', sortable: true,},
                    {
                        prop: 'status',
                        label: 'Status',
                        width: '',
                        sortable: true,
                        mutator(v){
                            return v.name
                        }

                    },
                    {prop: 'customer.name', label: `${this.$t('company_user')}`, width: ''},

                    {
                        prop: 'is_immediate',
                        label: `${this.$t('immediate')}`,
                        width: '70px',
                        sortable: true,
                        mutator(v){
                            return (v) ? 'yes': 'no';
                        }
                    },
                    {prop: 'gender', label: `${this.$t('gender')}`, width: '40px',sortable: true, mutator(v) {
                        return v && v.charAt(0).toUpperCase() || ''
                    }},
                    {
                        prop: 'specific_translators',
                        label: `${this.$t('tfv_spec')}`,
                        width: '',
                        sortable: true,
                        showPopover: true,
                        mutator(v) {
                            // Make sure language opts is populated before running this.
                            return !!v.length ? 'Y' : 'N'
                        },
                        //popover content value
                        popover(a,v){
                            // console.log(a, v,2341412412412, v.specific_translators.map(a => {
                            //     return a.translatorname;
                            // }).join(', '))
                            return v.specific_translators.map(a => {
                                return !isEmpty(a.translator) ? a.translator.name : '';
                            }).join(', ')
                        }
                    },
                    // {prop: 'level', label: 'Level', width: ''},
                    {prop: 'type', label: `${this.$t('job_type')}`, width: '',sortable: true},
                    {prop: 'expiry', label: `${this.$t('expired_at')}`, width: '80px',sortable: true},
                    // {prop: 'book_type', label: 'Bokn.', width: ''},
                    {prop: 'due', label: `${this.$t('tfv_due')}`, width: '80px', sortable: true},
                    {prop: 'created_at', label: `${this.$t('created')}`, width: '80px', sortable: true},
                ],

                hiddenCols: ['id'],
            //     populatePayload:{
            //     //   'filter[expire_within_hours]':168,
            //       'filter[status_id]':1,
            //       'filter[is_test]':0,
            //       'sort':'expiry',
            //       'per_page':50
            //   },
                row: null,


            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapGetters('booking', {
                listData: 'listData',
                listPagination: 'listPagination',
                loading: 'getLoading'
            }),

            ...mapGetters('language', {
                languageOpts: 'getLanguages'
            }),
            ...mapGetters('town', {
                townOpts: 'listAsOptions',
            }),

             populatePayload: {
                get(){
                    return this.vPopulatePayload
                },set(v){
                    this.$emit('input',v)
                }
            }
        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

             ...mapActions('town', {
                loadTowns: 'browseAsOptions'
            }),
            ...mapActions('booking', ['browse']),

            ...mapActions('language', {
                loadLanguages: 'fetchLanguages'
            }),

            ...mapActions({
              countAboutToExpire: 'dashboard/loadCountToExpire'
            }),

            /**
             * Helper method to check wether the column is enabled in the options or not.
             *
             * @param {string} key
             *   The switch value to check for whether to enable/disable a column.
             *
             * @return {boolean}
             */
            isColEnabled(key) {
                let r = true;

                if (_.includes(this.hiddenCols, key)) {
                    r = false;
                }

                return r;

            }, // End of isColEnabled() method

            /**
             * Method for reloading the list.
             *
             * @param {int} n
             *   The target page number.
             *
             * @return {void}
             */

            handleSizeChange(val) {
             this.reloadList(this.listPagination.current_page, val);
              },

            reloadList(n, per_page) {
                // let query = this.$route.query
                // Object.assign(this.populatePayload,query)
                // Object.assign(this.populatePayload,{page:n})
                // populate(this, 'browse', 'listData', {
                //     force: true,
                //     params: this.populatePayload
                // })
                let params=_.clone(this.$route.query);
                n = sd(n, sd(this.$route.query.page, 1)) // Set the page number.
                params.page = n;

               console.log(this.populatePayload)
                this.populatePayload.page = params.page

                if(!isEmpty(params['filter[is_test]'])&& params['filter[is_test]'] === 1 ){
                    this.populatePayload['filter[is_test]'] = 1
                }

                Object.assign(params,this.populatePayload)
                if(per_page && typeof per_page == 'number')
                {
                    params['per_page']= per_page;
                }

                this.$router.push({query: params}) // Reflect the chosen parameters in the URL Bar
                populate(this, 'browse', '', {
                    force: true,
                    params
                })
            }, // End of reloadList() method

            /**
             * method for handling details popups
             * @param {object} scope
             *
             * @return {void}
             */
            handleDetails(scope) {
                this.$router.push({name: 'booking-details', params: {id: scope.row.id}});

            },

            handleDetailsNew(scope) {
                let route = this.$router.resolve({name: 'booking-details', params: {id: scope.row.id}});
                window.open(route.href, '_blank');
            },
        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
            this.countAboutToExpire();
            // forcePopulate(this, 'browse', 'listData', this.populatePayload);

            let params =_.clone(this.$route.query)
            Object.assign(params,this.populatePayload)

            const opts = {
                action: 'browse',
                data: 'listData',
                ref: 'Booking Listing',
            };
            this.reloadList(null,opts,params)

            populate(this, 'loadLanguages', 'languageOpts', {
                ref: 'Languages Options'
            })

            populate(this, 'loadTowns', 'townOpts', {
                ref: 'Towns Listing',
            });

            // this.$store.dispatch('booking/fetchExpiringBookings',{hours:168})
        }, // End of Component > mounted


    } // End of export default

</script>
