<template>
  <div class="alert-modal-container">
    <div v-if="lockedForOtherAdmins == true">
      <el-row style="margin-top: 10px">
        <el-col :md="4" :offset="2">
          <div style="background-color:#d8dce5; padding:10px; border-radius:9px" @click="handleClickConfirm">
            <i style="font-size:24px; color:red; margin-left:4px" class="fa fa-lock"></i>
          </div>
        </el-col>

        <el-col :offset="1" :md="16">
          <h5 @click="handleClickConfirm">Bokningen är låst</h5>
          <h5 @click="handleClickConfirm">Klicka för att låsa upp</h5>
        </el-col>
      </el-row>
    </div>

    <div v-else>
      <el-row style="margin-top: 10px">
        <el-col :md="4" :offset="2">
          <div style="background-color:#d8dce5; padding:10px; border-radius:9px" @click="handleClickConfirm">
            <i style="font-size:24px; color:red; margin-left:4px" class="fa fa-unlock"></i>
          </div>
        </el-col>

        <el-col :offset="1" :md="16">
          <h5 @click="handleClickConfirm">Bokningen är olåst</h5>
          <h5 @click="handleClickConfirm">Klicka för att låsa bokning</h5>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'reportToCustomer',
  props: {
    lockedBooking: {
      type: Object,
      default: function () {
        return {}
      }
    },
    locked: {
      type: Array,
      default: function() {
        return [];
      }
    },
    handleLockedBooking: {
      type: Function
    },

  },
  data() {
    return {
      window,
      lockedForOtherAdmins: false
    };
  },
  computed: {
    /**
     * Interface for this.visible
     */
    iVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit('update:visible', v);
      },
    },
    userId()
      {
        return JSON.parse(localStorage.getItem('app_user_data')).id
      }
  },

     watch: {
      lockedBooking (newVa, OldVa) {
        if(newVa && newVa.is_locked == 1 && newVa.is_manual == 0 && this.userId != newVa.user_id) {
          this.lockedForOtherAdmins = true
        } else if (newVa && newVa.is_locked == 1 && newVa.is_manual == 1) {
            this.lockedForOtherAdmins = true
        } else {
          this.lockedForOtherAdmins = false
        }
      }
  },

  methods: {
    getUserName() {
      if (this.locked === undefined) return '';
      return this.locked.length > 0 ? this.locked[0].user.name : '';
    },
    getUserId() {
      if (this.locked === undefined) return '';
      return this.locked.length > 0 ? this.locked[0].user.id : '';
    },

    getUserComment() {
      if (this.locked === undefined) return '';
      return this.locked.length > 0 ? this.locked[0].comment : '';
    },
    /**
     * Handle when the modal has been closed
     *
     * @return {void}
     */
    handleClose: function() {
      this.$emit('update:visible', false);
    }, // End of handleClose() method

    handleClickConfirm() {
      this.handleLockedBooking();
    }
  }
};
</script>

<style lang="scss" scoped>
.el-dialog__header {
  text-align: center;
  background: #3a75ba;
}
.el-dialog__title {
  color: #ffffff;
  font-size: 22px;
}
.alert-modal-container {
  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  h5 {
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

 @media (max-width: 480px) {
  .alert-modal-container {
   width: 50px
  }

  }
</style>
