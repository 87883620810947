/*
|--------------------------------------------------------------------------
| store > user > translator > mutations
|--------------------------------------------------------------------------
|
| This file contains the mutations property of current Vuex scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {isEmpty, sd} from '~/js/helpers/Common';

/**
 * Helper method to mutate collection values for applying payload to compose.
 *
 * @param {object} col - collection of values to be mapped.
 * @param {int|string} key - key to where the value will be
 *                           pushed in the result.
 * @returns {array}
 */
function mc (col, key) {
  let r = [];
  if (!window._.isEmpty(col)) {
    _.forOwn(col, (item) => r.push(item[key]));
  }
  return r;
}

/**
 * Helper method for assign the values from source to destination.
 *
 * @param dest - destination object.
 * @param destKey - key on where to assign the value in destination.
 * @param src - source object.
 * @param [srcKey] - key on where to find the value in source.
 * @param [def] - default value whenever the source value does not exist.
 * @return {void}
 */
function av (dest, destKey, src, srcKey = null, def = null) {

  // Set Default value for srcKey
  srcKey = srcKey === null ? destKey : srcKey;

  if (isValueValid(src[srcKey])) {
    // If the source value is valid, run this block.
    dest[destKey] = src[srcKey]

  } else if (!window._.isNull(def)) {
    // Else if the source value is not valid, and default value is not null, run this block.
    dest[destKey] = def;
  }
}

/**
 * Helper to check whether the value is valid to be assigned.
 *
 * @param v - value to be evaluated.
 * @returns {boolean}
 */
function isValueValid (v) {
  if (v && typeof v === 'object' && v.constructor === Object) {
    return !window._.isEmpty(v);

  } else {
    return !window._.isUndefined(v) && !window._.isNull(v);
  }
}

export default {

  /**
   * Method for setting the values in state.compose
   *
   * @param state - state of the Vuex scope.
   * @param payload - source of the values.
   * @return {void}
   */
  setCompose (state, payload) {

    let src = payload;
    let dest = state.compose;
    let dk = 'translator_data'; // Data Key
    // Personal Info
    // Personal Info
    if(!payload.isDuplicated){
      av(dest.personal_info, 'name', src);
      av(dest.personal_info, 'email', src);
      av(dest.personal_info, 'dob', src);
      av(dest.personal_info, 'username', src);
    }else{
      dest.personal_info.name="";
      dest.personal_info.email="";
      dest.personal_info.dob="";
      dest.personal_info.username="";
    }

    av(dest.personal_info, 'notes', src);
    av(dest.personal_info, 'gender', src);
    av(dest.personal_info, 'phone', src);
    av(dest.personal_info, 'mobile', src);

    // Service
    const distinctTowns = window._.uniqBy(src.towns, 'id'); // Remove any duplicate entries for towns.
    dest.service.towns = sd(mc(distinctTowns, 'id'), []);

    dest.service.booking_types = sd(mc(src.booking_types, 'booking_type'), []);
    dest.service.translator_customer_types = sd(mc(src.translator_customer_types, 'id'), []);
    dest.service.allowed_customer_types = sd(mc(src.allowed_customer_types, 'id'), []);
    av(dest.service, 'disallowed_customer_types', src);
    dest.service.countries = sd(mc(src.countries, 'iso'), []);
    dest.service.language_pair_levels = sd(src.language_pair_levels,[]);
    av(dest.service, 'languages', src);

    // Settings
    av(dest.settings, 'tax', src[dk]);
    av(dest.settings, 'tax_type', src[dk]);
    av(dest.settings, 'bank_name', src[dk]);
    av(dest.settings, 'bank_account_number', src[dk]);

    // Address
    av(dest.address, 'address', src[dk]);
    av(dest.address, 'address_2', src[dk], 'address2');
    av(dest.address, 'post_code', src[dk]);
    av(dest.address, 'city', src[dk]);
    av(dest.address, 'country_iso', src[dk]);
    av(dest.address, 'additional_info', src[dk]);
    av(dest.address, 'coordinates', src[dk]);
    av(dest.address, 'office_area', src[dk]);

    // Additional Points
    av(dest, 'additional_points', src[dk]);

    // Questions
    av(dest.questions, 'enabled', src);
    av(dest.questions, 'disable_comment', src);
    // av(dest.questions, 'tfa_enabled', src);
    // av(dest.questions, 'show_2fa_qr', src);
    av(dest.questions, 'has_training_certificate', src[dk]);
    av(dest.questions, 'has_police_background', src[dk]);
    av(dest.questions, 'has_contract', src[dk]);
    av(dest.questions, 'has_driving_license', src[dk]);
    av(dest.questions, 'will_invoice_manually', src[dk]);
    av(dest.questions, 'is_sensitive_info', src[dk]);
    av(dest.questions, 'confidential_message', src[dk]);
    av(dest.questions, 'is_protected_identity', src[dk]);
    av(dest.questions, 'company_name', src[dk]);
    av(dest.questions, 'company_registration_no', src[dk]);
    av(dest.questions, 'invoice_tax', src[dk]);
    av(dest.questions, 'availability_switch', src[dk]);
    av(dest.questions, 'available', src[dk]);
    av(dest.questions, 'company_info_verified', src[dk]);

    if (!window._.isNil(src[dk].available_from)) {
      const availableFrom = src[dk].available_from.split(' ');
      dest.questions.available_from.date = availableFrom[0];
      dest.questions.available_from.time = availableFrom[1];
    }

    if (!window._.isNil(src[dk].available_until)) {
      const availableUntil = src[dk].available_until.split(' ');
      dest.questions.available_until.date = availableUntil[0];
      dest.questions.available_until.time = availableUntil[1];
    }

    // Instantiate this object if empty, just for failproofing.
    const notifSetting = sd(src.translator_notification_setting, {});

    // Translator Notification Questions
    av(dest.questions, 'translator_notify', notifSetting, 'notify');
    av(dest.questions, 'translator_notify_emergency', notifSetting, 'notify_emergency');
    av(dest.questions, 'translator_notify_nighttime', notifSetting, 'notify_nighttime');
    av(dest.questions, 'is_employee', src[dk]);
    av(dest.questions, 'salary_per_hour', src[dk]);
    av(dest.questions, 'amount_of_job', src[dk]);
    if (!window._.isNil(src[dk].is_available_weekend)) {
      dest.questions.is_available_weekend = src[dk].is_available_weekend === 1;
    }
    if (!window._.isNil(src[dk].higher_wage)) {
      dest.questions.higher_wage = src[dk].higher_wage === 1;
    }
    av(dest.questions, 'higher_wage_option', src[dk]);
    if (!window._.isNil(src[dk].lunch_switch)) {
      dest.questions.lunch_switch = src[dk].lunch_switch === 1;
    }
    if (!window._.isNil(src[dk].lunch_time)) {
      av(dest.questions, 'lunch_time', src[dk]);
    }
    av(dest.questions, 'duration', src[dk]);
    av(dest.questions, 'WorkingHours', src[dk]);
    if (!window._.isNil(src[dk].lunch_time_range_switch)) {
      dest.questions.lunch_time_range_switch = src[dk].lunch_time_range_switch === 1;
    }
    av(dest.questions, 'lunch_time_from', src[dk]);
    av(dest.questions, 'lunch_time_to', src[dk]);
    if (!window._.isNil(src[dk].lunch_time_fixed_switch)) {
      dest.questions.lunch_time_fixed_switch = src[dk].lunch_time_fixed_switch === 1;
    }
    av(dest.questions, 'wage_on_weekend', src[dk]);
    av(dest.questions, 'traveling_by', src[dk]);
    if (!window._.isNil(src[dk].is_mentor)) {
      dest.questions.is_mentor = src[dk].is_mentor === 1;
    }
    av(dest.questions, 'is_passive', src);
  },


  /**
   * Method for setting the values in state.payload
   *
   * @param state - state of the Vuex scope.
   * @return {void}
   */
  setPayloadData (state) {
    let src = state.compose;
    let dest = {};
    let dk = 'translator_data';
    let ts = 'trainings';
    dest[dk] = {}; // Instantiate the dk child container value.
    dest[ts] = {}; // Instantiate the dk child container value.

    console.log('COMPOSE', src);

    // Personal Info
    av(dest, 'name', src.personal_info);
    av(dest, 'notes', src.personal_info);
    av(dest, 'username', src.personal_info);
    av(dest, 'email', src.personal_info);
    av(dest, 'gender', src.personal_info);
    av(dest, 'dob', src.personal_info);
    av(dest, 'phone', src.personal_info);
    av(dest, 'mobile', src.personal_info);

    // Credential
    if (
      !isEmpty(src.credential.password)
      && !isEmpty(src.credential.password_confirmation)
    ) {
      av(dest, 'current_password', src.credential);
      av(dest, 'password', src.credential);
      av(dest, 'password_confirmation', src.credential);
    }

    // Service
    av(dest, 'towns', src.service);
    av(dest, 'countries', src.service);
    av(dest, 'booking_types', src.service);
    av(dest, 'translator_customer_types', src.service);
    av(dest, 'allowed_customer_types', src.service);
    av(dest, 'disallowed_customer_types', src.service);
    av(dest, 'languages', src.service);
    av(dest, 'language_pair_levels', src.service);

    // Settings
    av(dest[dk], 'tax', src.settings);
    av(dest[dk], 'tax_type', src.settings);
    av(dest[dk], 'bank_name', src.settings);
    av(dest[dk], 'bank_account_number', src.settings);

    // Address
    av(dest[dk], 'address', src.address);
    av(dest[dk], 'address2', src.address, 'address_2');
    av(dest[dk], 'post_code', src.address);
    av(dest[dk], 'city', src.address);
    av(dest[dk], 'country_iso', src.address);
    av(dest[dk], 'additional_info', src.address);
    av(dest[dk], 'coordinates', src.address);
    av(dest[dk], 'office_area', src.address);
    
    // Questions
    av(dest, 'enabled', src.questions);
    av(dest, 'disable_comment', src.questions);
    // av(dest, 'tfa_enabled', src.questions);
    // av(dest, 'show_2fa_qr', src.questions);
    av(dest[dk], 'has_training_certificate', src.questions);
    av(dest[dk], 'has_police_background', src.questions);
    av(dest[dk], 'has_contract', src.questions);
    av(dest[dk], 'has_driving_license', src.questions);
    av(dest[dk], 'will_invoice_manually', src.questions);
    av(dest[dk], 'is_sensitive_info', src.questions);
    av(dest[dk], 'confidential_message', src.questions);
    av(dest[dk], 'is_protected_identity', src.questions);
    av(dest[dk], 'company_info_verified', src.questions);

    dest.translator_notification_setting = sd(dest.translator_notification_setting, {});
    av(dest.translator_notification_setting, 'notify', src.questions, 'translator_notify');
    av(dest.translator_notification_setting, 'notify_emergency', src.questions, 'translator_notify_emergency');
    av(dest.translator_notification_setting, 'notify_nighttime', src.questions, 'translator_notify_nighttime');

    av(dest[dk], 'company_name', src.questions);
    av(dest[dk], 'company_registration_no', src.questions);
    av(dest[dk], 'invoice_tax', src.questions);

    av(dest[dk], 'availability_switch', src.questions);
    av(dest[dk], 'available', src.questions);

    if (src.questions.available_from.date !== '' && src.questions.available_from.time !== '') {
      dest[dk].available_from = `${src.questions.available_from.date} ${src.questions.available_from.time}`;
    }

    if (src.questions.available_until.date !== '' && src.questions.available_until.time !== '') {
      dest[dk].available_until = `${src.questions.available_until.date} ${src.questions.available_until.time}`;
    }
    av(dest[dk], 'is_employee', src.questions);
    av(dest[dk], 'salary_per_hour', src.questions);
    av(dest[dk], 'amount_of_job', src.questions);
    av(dest[dk], 'is_available_weekend', src.questions);
    av(dest[dk], 'higher_wage', src.questions);
    av(dest[dk], 'higher_wage_option', src.questions);
    av(dest[dk], 'lunch_switch',src.questions);
    if (!window._.isNil(src.questions.lunch_time) && src.questions.lunch_time !== '') {
      dest[dk].lunch_time = `${src.questions.lunch_time}`;
    }
    av(dest[dk], 'duration', src.questions);
    dest[dk].WorkingHours=[];
    src.questions.WorkingHours.forEach(function(value,index ) {
      if(src.questions.WorkingHours[index].time_from!=='' && src.questions.WorkingHours[index].time_end!==''){
        dest[dk].WorkingHours.push({
          time_from:src.questions.WorkingHours[index].time_from,
          time_end:src.questions.WorkingHours[index].time_end
        })
      }
    })
    av(dest[dk], 'lunch_time_range_switch', src.questions);
    if (src.questions.lunch_time_from !== '' && src.questions.lunch_time_to !== '') {
      dest[dk].lunch_time_from = `${src.questions.lunch_time_from}`;
      dest[dk].lunch_time_to = `${src.questions.lunch_time_to}`;
    }
    av(dest[dk], 'lunch_time_fixed_switch', src.questions);
    av(dest[dk], 'wage_on_weekend', src.questions);
    av(dest[dk], 'traveling_by', src.questions);
    av(dest[dk], 'is_mentor', src.questions);
    av(dest, 'is_passive', src.questions);

    //Additional Points
    dest[dk].additional_points = src.additional_points

    state.payloadData = _.cloneDeep(dest);
    console.log(state.payloadData)
  },

  /**
   * Set the Booking Confirm Field Config to the state.
   *
   * @param state - state of the Vuex scope.
   * @param payload - source of the values.
   * @return {void}
   */
  setBcfConfig (state, payload) {
    state.compose.booking_confirm_fields = _.cloneDeep(payload.data);
  },

  setBcfConfigMunicipality (state, payload) {
    state.compose.booking_confirm_fields_municipality = _.merge(state.compose.booking_confirm_fields_municipality, payload.data);
  },
  setEmployees: (state, payload) => {
    let key = Object.keys(payload.data)[0];
    state.employees = payload.data[key];
    state.pagination = payload.meta.pagination;
  },
  setDelayedBookings:(state,payload)=>{
    state.delayedBookings = payload
  }
}
