<template>
  <li class="sidebar-nav-item" :class="className">
    <template v-if="subItems.length === 0">
      <router-link
        :to="path"
        exact
        @click.native="handleClickLink"
      >
        <div class="icon-container">
          <template v-if="isfa">
            <span v-if="icon !== ''" :class="icon"></span>
          </template>
          <template v-else>
            <i v-if="icon !== '' && (colorTheme === 'default' || colorTheme === '')" class="material-icons md-dark">{{icon}}</i>
            <i v-else-if="icon !== '' && colorTheme==='dark'" class="material-icons md-light">{{icon}}</i>
          </template>
          <span v-else class="label-initial">{{ labelInitial }}</span>
        </div>
        <!-- /.icon-container -->

        <div class="label-container">
          <span v-html="label"></span>
        </div>
        <!-- /.label-container -->

        <template v-if="badge !== ''">
          <el-badge :value="badgeValue(badge)"></el-badge>
        </template>
      </router-link>
    </template>
    <template v-else>
      <el-collapse
        class="sidebar-nav-item-parent-subitem-set"
        v-model="activePanel"
      >
        <el-collapse-item :name="index">
          <template slot="title">
            <div class="set-title">
              <div class="icon-container">
                <template v-if="isfa">
                  <span v-if="icon !== ''" :class="icon"></span>
                </template>
                <template v-else>
                  <i v-if="icon !== '' && (colorTheme === 'default' || colorTheme === '')" class="material-icons md-dark">{{icon}}</i>
                  <i v-else-if="icon !== '' && colorTheme==='dark'" class="material-icons md-light">{{icon}}</i>
                </template>

                <span v-else class="label-initial">{{ labelInitial }}</span>
              </div>
              <!-- /.icon-container -->

              <div class="label-container">
                <span v-html="label"></span>
              </div>
              <!-- /.label-container -->
            </div>
            <!-- /.sidebar-nav-item-parent-subitem-set-title -->
          </template>

          <sidebar-nav-set
            :nav-items="subItems"
            class="sidebar-nav-item-subitems"
          />
        </el-collapse-item>
      </el-collapse>
    </template>
  </li>
  <!-- /.sidebar-nav-item -->
</template>

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      /**
       * The unique identifier value for this panel.
       */
      index: {
        default: ""
      },

      /**
       * The route path to where the nav item should
       *  navigate when clicked.
       */
      path: {
        type: String,
        default: ""
      },

      /**
       * The css class name that will be assigned to the
       * certain nav item element.
       */
      className: {
        type: String,
        default: ""
      },

      /**
       * The label that will be used for the nav item.
       */
      label: {
        type: String,
        default: ""
      },

      /**
       * The icon that will be used for the nav item.
       * This will be defaulted to the first letter of the label if empty.
       */
      icon: {
        type: String,
        default: ""
      },

      /**
       * Collection of sub-nav items to be defined under this parent-nav item.
       */
      subItems: {
        type: Array,
        default: []
      },

      /**
       * badge will be added at nav item label suffix
       */
      badge: {
        type: String,
        default: ""
      },

      /**
       * Condition to check if the icon is fa or material.
       */
      isfa: {
        type: Boolean,
        default: false

      }, // End of component > props
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        activePanel: 0
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters('viewport', [
        // @returns {boolean}
        'isOnMobileViewport'
      ]),
      ...mapState('ui', [
        'colorTheme'
      ]),

      to_expire () {
        return this.$store.state.dashboard.counts.to_expire;
      },

      pending () {
        return this.$store.state.dashboard.counts.pending_today;
      },

       to_work_on() {
        return this.$store.state.dashboard.counts.to_work_on;
      },
       is_difficult() {
          return this.$store.state.dashboard.counts.is_difficult;
      },

      /**
       * Method to return the label's first letter.
       */
      labelInitial () {
        return this.label.substr(0, 1);
      }
    }, // End of component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapMutations('sidebar', ['setIsFoldSidebar']),

      /**
       * @param badge
       * @returns {int}
       */
      badgeValue (badge) {
        if (badge === "to_expire") {
          return this.to_expire.count;
        } else if (badge === "pending") {
          return this.pending.count;
         } else if (badge === "to_work_on") {
        return this.to_work_on.count;
         } else if (badge === "is_difficult") {
        return this.is_difficult.count;
         }
        },

      /**
       * @returns {void}
       */
      handleClickLink () {
        if (this.isOnMobileViewport) {
          this.setIsFoldSidebar(true);
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      // If the nav item has children
      if (this.subItems.length > 0) {
        // Store the parent panel into container.
        let panel = this;

        // Check if the current path exists inside its child objects.
        _.find(this.subItems, o => {
          // If path matched with the current path, activate the panel.
          if (o.path === this.$route.fullPath) {
            this.activePanel = panel.index;
          }
        }); // End of _.find()
      } // End of if (this.subItems.length > 0)
    }
  } // End of export default
</script>
