/*
|--------------------------------------------------------------------------
| Store > Financial > Additional > Props
|--------------------------------------------------------------------------
| File to define the properties that will be used for the forms.
*/

'use strict';

export const additional = {
  salary_type: '',
  name: '',
  description: '',
  price: '',
  quantity: '1',
  unit: 'st'
}