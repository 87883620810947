/*
|--------------------------------------------------------------------------
| store > user > customer > actions
|--------------------------------------------------------------------------
|
| This file contains the actions property of current Vuex scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import APICaller from '~/js/helpers/APICaller';
import i18n from '~/js/i18n';
import {Notification} from "element-ui";
import {notifError} from '~/js/helpers/Notification';

export default {

  /**
   * Method with a purpose of generically reaching out to API
   * and doing the action READ BCF CONFIG.
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @param  {object} payload.customer_id - the ID of the target customer.
   * @return {Promise}
   */
  readBcfConfigGeneric (context, payload) {

    let method = context.state.api.bcf.read.method;
    let endpoint = context.state.api.bcf.read.path;
    let params = payload;

    return APICaller({method, endpoint, params})
      .then((r) => r)
      .catch((e) => e);
  },

  createCustomerFromBooking(context, payload) {
    let method = context.state.api.customer.method;
    let endpoint = context.state.api.customer.path;
    let data = payload;

    return APICaller({
        method,
        endpoint,
        data
      })
      .then((r) => r)
      .catch((e) => e);
  },

  /**
   * Action to fetch the Booking Confirm Field Config values from the API.
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} payload - contains necessary values for the process.
   * @param  {object} payload.customer_id - the ID of the target customer.
   * @return {Promise}
   */
  readBcfConfig (context, payload) {

    return context.dispatch('readBcfConfigGeneric', payload)
                  .then((r) => {

                    let raw = r.data.data; // Store into container so varname will be shorter.
                    let data = raw[Object.keys(raw)[0]]; // Get the first member of the object.
                    context.commit('setBcfConfig', {data});
                    context.commit('setBcfConfigDefault', {data});
                    return r;

                  })
                  .catch((e) => {

                    // Get the response message that's displayed during failure.
                    let message = e.response.data.data;
                    // Verify if the bcf config record is empty.
                    let isEmpty = _.includes(message, 'No query results for model');

                    if (isEmpty) {
                      // If the record is empty, then
                      console.log(`There is no BCF Config Value for customer(${payload.customer_id})`);

                    } else {
                      // Else, if it's another kind of error, just display it.
                      Notification.error({
                        title: i18n.t('error'),
                        message: e.message
                      });
                    }
                  });

  },


  /**
   * Method with a purpose of generically reaching out to API
   * and doing the action EDIT BCF CONFIG.
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @param  {object} payload.customer_id - the ID of the target customer.
   * @return {Promise}
   */
  editBcfConfigGeneric (context, payload) {

    let method = context.state.api.bcf.edit.method;
    let endpoint = context.state.api.bcf.edit.path;
    let data = payload.data;

    // Mutate the boolean values inside the object
    _.forOwn(data, (v, i) => {
      if (window._.isBoolean(v)) data[i] = v ? 1 : 0;
    });

    return new Promise((resolve, reject) => {
      APICaller({method, endpoint, data})
        .then((r) => resolve(r))
        .catch((e) => reject(e));
    });
  },

  /**
   * Action to update/submit the Booking Confirm Field Config value changes
   * to the API.
   *
   * @param  {object} context
   *   the context of $store
   *
   * @param  {object} payload - object which contains option values.
   * @param  {object} payload.data - data to be submitted to the API.
   *
   * @return {Promise}
   */
  editBcfConfig (context, payload) {
    context.dispatch('editBcfConfigGeneric', payload)
           .then(() => {
             Notification.success({
               title: i18n.t('success'),
               message: 'Booking Confirmation Field Config saved.'
             });
           })
           .catch((e) => {
             notifError({message: e});
           });
  },

  readBcfConfigMunicipality (context, payload) {
    return context.dispatch('readBcfConfigGeneric', payload)
      .then((r) => {
        if(r.data && r.data) {
          let raw = r.data.data; // Store into container so varname will be shorter.
          let data = raw[Object.keys(raw)[0]]; // Get the first member of the object.
          context.commit('setBcfConfigMunicipality', {data});
          return r;
        }
        return false
      }).catch(e => {
        console.log(`No booking fields config found for the municipality of selected company`)
      })
  },
  addCustomerFromBooking(context, payload) {
    return context.dispatch('createCustomerFromBooking', payload)
      .then((r) => {
        if (r.data && r.data) {
          console.log(r, 'asd')
          let raw = r.data.data; // Store into container so varname will be shorter.
          let data = raw[Object.keys(raw)[0]]; // Get the first member of the object.
          context.commit('setBcfConfigMunicipality', {
            data
          });
          return r;
        }
        return false;
      }).catch(e => {
        console.log(`No booking fields config found for the municipality of selected company`)
      })
  }
}
