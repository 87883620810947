<template>
    <div>
        <el-dialog             
            :append-to-body="true"
            title="Tags" :visible.sync="dialogFormVisible" 
            :modalAppendToBody="false"
            :closeOnClickModal="false"
            @closed="onDialogClosed()"
            >
            <el-form v-model="form">
                <el-form-item label="Name">
                    <el-input v-model="form.name" auto-complete="off" :placeholder="$t('please_input_tag_name')"/>
                </el-form-item>
                <el-form-item label="State">
                    <el-switch v-model="form.state"
                        :active-value="1"
                        :inactive-value="0"/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelModal()">{{$t('cancel')}}</el-button>
                <el-button type="primary" @click="handleSubmit(mode)">{{$t('confirm')}}</el-button>
            </span>
        </el-dialog>

        <el-button type="primary" @click="dialogFormVisible=true">{{ $t('create') }}</el-button>
    </div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from "vuex";
import {checkBreadMode} from "~/js/helpers/Route";
import {Notification} from 'element-ui';

export default {
    props : {
        tagData: {
            type: Object,
            default: {}
        },

        isBeingUpdated: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            mode: 'add',
            dialogFormVisible: false,
        }
    },

    computed: {
        ...mapState('bookingIssueTag', {
            form: 'compose',
            payloadData: 'payloadData',
        }),

        iIsBeingUpdated: {
            get() {
                return this.isBeingUpdated;
            },
            set(v) {
                this.$emit('input', v);
            },
        },
    },

    methods : {
        ...mapActions('bookingIssueTag', {
            browseAsOptions: 'browseAsOptions',
            browse:'browse',
            read: 'read',
            edit: 'edit',
            add: 'add',
        }),

        cancelModal: function() {
            this.iIsBeingUpdated = false
            this.dialogFormVisible = false
        },

        onDialogClosed: function() {
            this.mode = 'add'
            this.$emit('tagFormDialogClosed')
        },

        handleSubmit: function(submitType) {
            let payload = {}
            let authUserID = JSON.parse(localStorage.getItem('app_user_data')).id
            let n = this.form.name

            payload = {
                all : true,
                name : this.form.name
            }

            this.browseAsOptions(payload).then(r => {
                let d = r.data.data.booking_issue_tags;
                
                // filtered name
                const fn = d.filter(function (el) { return el.name == n });

                if(fn.length !== 0 && submitType === 'add') {
                    Notification.warning({
                        title: 'Validation',
                        message: 'Tag with that name already exist.'
                    })
                }
                else {
                    if(submitType === 'add') {
                        this.form.created_by = authUserID
                        this.form.updated_by = authUserID

                        payload = {
                            name : this.form.name,
                            state : this.form.state,
                            created_by : this.form.created_by,
                            updated_by : this.form.updated_by,
                        }
                    }
                    else if(submitType ==='edit') {
                        this.form.updated_by = authUserID
                        
                        payload = {
                            id : this.form.id,
                        }
                    }

                    this[submitType](payload).then(r => {
                        if (r.status == 201 || r.status == 200) {
                            this.cancelModal()
                        }
                    });
                }
            });
        },
    },

    watch: {
        isBeingUpdated: function() {

            if(this.isBeingUpdated) {
                this.mode = 'edit'
                
                this.form.id         = this.tagData.id
                this.form.name       = this.tagData.name
                this.form.state      = this.tagData.state
                this.form.created_by = this.tagData.created_by
                this.form.updated_by = this.tagData.updated_by

                this.dialogFormVisible = true
            }
            else {
                this.mode = 'add'

                this.form.id         = ''
                this.form.name       = ''
                this.form.state      = ''
                this.form.created_by = ''
                this.form.updated_by = ''
                this.dialogFormVisible = false
            }
        },
    }
}
</script>