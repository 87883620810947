/*
|--------------------------------------------------------------------------
| Store > Invoice Reminder Settings > Mutations
|--------------------------------------------------------------------------
|
| This file contains the mutations property of User Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
"use strict";

// import {mapProps} from "../../helpers/Common";
import { isEmpty, sd } from "~/js/helpers/Common";
import { userDataKey } from "~/js/helpers/Vuex";

/**
 * Setting the compose form
 *
 * @return  {void}
 */
export default {
  setCompose(state, payload) {
    state.compose = state.composeFresh;
    if(payload.data.items.length > 0) {
      state.compose = payload.data.items[0];
    }
    console.log(state.compose)
  }
};
