<template>
  <div class="app-row">
    <div class="app-col full-width">
      <h3>{{$t('latest_check_in_locations')}}</h3>
      <div class="app-list booking-list">
        <custom-checkins-list
          :table-data="locations"
          :extra-cols="listCols"
          :hasDetailsCol="false"
          actions-col-width='130'
          v-if="Loading"
        >
          <template slot="actionsColumn" slot-scope="scope">
            <el-button class="circle" type="success" title="See Map" size="mini" icon="el-icon-view">
            </el-button>
          </template>
        </custom-checkins-list>
        <template v-if="Loading === false"  class="full-width">
          <i class="el-icon-loading"></i><span> {{$t('loading_latest_locations')}} ...</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import APICaller from '~/js/helpers/APICaller';
  import { gep } from "~/js/helpers/Vuex";
  export default {
    name:'userLatestCheckingsPanel',
    props:{
      user: {
        type: Object,
        required: true,
      }
    },
    data(){
      const _this = this;
      return {
        ready: false,
        token: '',
        locations: [{
        }],
        listCols: [
          {
            prop: _this.$t('created_at'),
            label: '',
            width: '',
            sortable: true
          },
        ]
      }
    },
    computed:{
      Loading(){
        if (this.user.translator_data) {
          this.getLocations(this.user.translator_data.id);
        }
        return this.ready;
      },
    },
    methods:{
      getLocations(translatorID) {
        let apiPrefix = 'v3';
        const apiOpts = {
          endpoint: gep('translator_locations/' + translatorID, apiPrefix),
          method: 'get',
          isDataRaw: true
        };
        APICaller(apiOpts)
          .then((res) => {
            let resLocations = res.data.data.locations;
            for (let i = 0; i < resLocations.length; i++) {
              let datearr = resLocations[i].created_at.split(' ');
              resLocations[i].created_at = 'Check-in at ' + datearr[0] + ' at ' + datearr[1];
              resLocations[i].coordinates = resLocations[i].coordinates.replace(/\s+/g,"");
            }
            this.locations = resLocations;
            this.ready = true;
          });
      }
    }
  }
</script>

<style>
</style>
