<template>
  <el-form :model="form" ref="form">
    <el-form-item :label="$t('review_from')" class="label-block">
      <el-select v-model="form.review_from_type" :placeholder="$t('review_from')" @change="handleFromTypeChange">
        <el-option v-for="v in getType" :key="v.id" :value="v.id" :label="v.value" value-key="id">{{$t(`tfv_${v.value}`)}}</el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('rating_tfv')" class="label-block">
      <el-rate
        v-model="form.rating"
        :colors="colors"
        @change="handleRatingChange"
        :disabled="!hasReviewFrom"
        />
    </el-form-item>

    <!-- predefined feedbacks -->
    <el-form-item v-if="hasRating">
      <el-collapse v-model="commentPanel">
        <el-collapse-item :title="$t('select_one_more_option')" :name="1">
          <ul class="comment-option">
            <li v-for="v in commentOpts"
              @click="handleAddCommentOpts(v)"
              >
              {{ v }}
            </li>
          </ul>
        </el-collapse-item>
      </el-collapse>
    </el-form-item>

    <el-form-item :label="$t('comment')" class="label-block">
        <el-input
          type="textarea"
          autosize :placeholder="$t('please_add_comment')"
          v-model="form.comment"
          rows="4"
          >
              </el-input>
    </el-form-item>
    <span class="dialog-footer">
    <el-button @click="iVisible = false ">{{$t('cancel')}}</el-button>
    <el-button type="primary" @click="submitForm">{{$t('confirm')}}</el-button>
  </span>
  </el-form>
</template>

<script>

import { mapGetters } from 'vuex';
export default {
  name:'addFeedbackForm',
  props:{
    feedbacks:{
      type: Array,
      default:[]
    },
    booking:{
      type: Object,
      default:{}
    },
    visible: {
        type: Boolean,
        default: false
    }
  },
  data(){
    return {
      form:{
        review_from_id: null,
        review_from_type: '',
        review_to_id: null,
        review_to_type: '',
        booking_id: null,
        rating: 0,
        comment: ''
      },
      fromPlaceholder:'',
      toPlaceholder:'',
      hasRating: false,
      commentPanel: 1,
      ratingGrade: '',
      hasReviewFrom: false,
      colors: [
        '#F56C6C',
        '#F7BA2A',
        '#67C23A'
      ]
    }
  },
  computed:{
    iFeedbacks:{
      get(){
        return this.feedbacks
      },
      set(v){
        this.$emit('input',v)
      }
    },
    iBooking(){
      return this.booking
    },
    fromTypeSelect(){
      let list=[]
      if(!_.some(this.feedbacks, {review_from_type:3})){
        list.push('translator')
      }
      if(!_.some(this.feedbacks, {review_from_type:2})){
        list.push('customer')
      }
      return list
    },
    iVisible: {
      get() {
        return this.visible
      },
      set(v) {
        this.$emit('update', v);
      }
    },
    ...mapGetters({
      getType:'feedback/getType'
    }),

    commentOpts() {
      const o = {
        customer: {
          high: [
            'Trevlig tolk',
            'Professionell och korrekt',
            'Mycket god svenska',
            'Hanterade en svår situation bra',
          ],
          low: [
            'Tolken var försenad, ange minuter i fritext-rutan',
            'Tolken uteblev',
            'Telefontolk svarade inte',
            'Tolken tolkade inte i jag-form',
            'Tolken presenterade sig inte',
            'Tolken tolkade inte allt som sades',
            'Tolken pratade själv med klienten/patienten under uppdraget',
            'Tolken kom med egna kommentarer',
            'Problem vid telefontolkning, beskriv i fritext-rutan',
          ],
        },
        translator: {
          high: [
            'Tolkanvändaren var trevlig',
            'Tolkanvändaren talade tydligt',
            'Tolkanvändaren pratade i kort meningar och skapade goda förutsättningar för mig att tolka',
          ],
          low: [
            'Tolkanvändaren var otrevlig',
            'Tolkanvändaren talade otydligt eller lågt',
            'Kunden hade inte behov av tolk',
            'Tolkanvändaren pratade i långa meningar och skapade ej goda förutsättningar för mig att tolka',
          ],
        },
      };

      const type  = this.form.review_from_type === 3 ? 'translator' : 'customer' ;
      const grade = this.form.rating >= 3 ? 'high' : 'low';

      return o[type][grade];
    }
  },
  methods:{
      handleFromTypeChange(event){
        Object.assign(this.form,{booking_id: this.iBooking.id})
        if(event==='customer' || event=== 2 ){
          Object.assign(this.form , {
            review_from_id : this.iBooking.customer_id,
            review_from_type: 2,
            review_to_id: this.iBooking.assigned_translator.translator_id,
            review_to_type: 3
          });
        }
        else{
          Object.assign(this.form , {
              review_from_id: this.iBooking.assigned_translator_id,
              review_from_type: 3,
              review_to_id : this.iBooking.customer_id,
              review_to_type: 2,
            });
        }

        this.hasReviewFrom = true;
        this.handleRatingChange();
    },

    submitForm(){
      this.iVisible = false
      this.$store.dispatch('feedback/storeFeedback',this.form).then(r=>{
        window.location.reload();
      })
    },

    handleRatingChange(v) {
      this.hasRating = v ? true : false;
      this.ratingGrade = v >= 3 ? 'high' : 'low';
    },

    handleAddCommentOpts(cmt) {
      if(this.form.comment != '') {
        this.form.comment = this.form.comment + '\n' + cmt;
      }
      else {
        this.form.comment = cmt;
      }
    }
  },

  mounted() {
  },

  watch: {
    ratingGrade() {
      this.form.comment = ''
    }
  }
}
</script>

<style lang="scss">
  .comment-option {
    border: 1px solid #ddd;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid #ddd;
      list-style: none;
      margin: 0;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #eee;
      }
    }
  }
</style>
