<template>
    <div class="app-form price-list-settings-form">
        <el-row :gutter="20">
          <el-col :span="16">
              <h4>Price List</h4>
              <el-form :model="form" :id="formElId" :ref="formElId">
            <el-form-item :id="genContainerId('title',formElId)"
                        label="title" class="label-medium">
                <el-input :id="genFieldId('title',formElId)"
                 v-model="form.title"/>
                </el-input>
            </el-form-item>

            <el-form-item :id="genContainerId('comment',formElId)"
                        label="comment" class="label-medium">
                <el-input type="textarea" :rows="10" :id="genFieldId('comment',formElId)"
                 v-model="form.comment">
                </el-input>
            </el-form-item>

            <el-form-item :id="genContainerId('save_btn',formElId)">
                <el-button :id="genFieldId('save_btn',formElId)" type="primary">
                    {{ $t('save') }}
                </el-button>
            </el-form-item>
        </el-form>
          </el-col>
          <el-col :span="8" v-show="isEdit">
              <h4>Copy clone</h4>
              <el-button type="success">{{ $t('tfv_fill_price_list') }}</el-button>
          </el-col>
        </el-row>
        
        <el-row v-show="isEdit">
         
        <el-collapse v-model="activePanel" @change="handleColapse">
            <el-collapse-item name="invoice" title="Invoice Price List">
                  <el-tabs v-model="activeTab" v-show="!colapseInvoicePriceList">

                                <el-tab-pane label="Business Rules" name="business_rules">
                                    <invoice-business-rules-form/>
                                </el-tab-pane>

                                <el-tab-pane label="Charging" name="charging">
                                    <invoice-charging-form />
                                </el-tab-pane>

                                <el-tab-pane label="Transaction Fee" name="transaction_fee">
                                    <invoice-transaction-fee-form/>
                                </el-tab-pane>

                                <el-tab-pane label="Emergency Booking" name="emergency_booking">
                                    <invoice-emergency-booking-form />
                                </el-tab-pane>

                                <el-tab-pane label="Inconvenience Charge" name="inconvenience_charge">
                                    <invoice-inconvenience-charge-form />
                                </el-tab-pane>

                                <el-tab-pane label="Travel Settings" name="travel_settings">
                                    <invoice-travel-settings-form/>
                                </el-tab-pane>
                            </el-tabs>
            </el-collapse-item>
            <el-collapse-item name="salary" title="Salary Price List">
                <el-tabs v-model="salaryTab">

                                <el-tab-pane label="Inconvenience Charge" name="inconvenience_charge">

                                    <el-form>
                                        <el-form-item id="" label="Rate" class="label-short">
                                            <el-input v-model="inconvenienceCharge" />
                                        </el-form-item>
                                    </el-form>

                                </el-tab-pane>

                                <el-tab-pane :label="$t('travel_settings')" name="travel_settings">
                                    <salary-travel-settings-form />
                                </el-tab-pane>
                            </el-tabs>
            </el-collapse-item>
        </el-collapse>
        </el-row>
        


    </div>
</template>

<script>
    import {genFieldId, genContainerId} from "../../../js/helpers/Html";
    export default {
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                formElId: 'price-list-settings-form',
                form: {
                    title: '',
                    comment: ''
                },
                isEdit: false,
                activeTab: 'business_rules',
                salaryTab: 'travel_settings',
                colapseInvoicePriceList: false,
                colapseSalaryPriceList: false,
                activePanel: '',
                inconvenienceCharge:''

            };
        },// End of Component > data
        methods: {
            handleColapse(val){

            }
        },
        mounted() {
            console.log(this.$route);
            this.isEdit = (this.$route.name === 'settings.price-list.edit')
        }
    }// End of export default

</script>