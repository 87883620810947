<template>

    <div class="app-form booking-issue-setting-form">
      <el-form :model="form" :id="formElId" :ref="formElId">
        <el-form-item :label="$t('type')">
          <el-input v-model="form.type" :placeholder="$t('please_input_booking_type')" :disabled="iMode==='edit'"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deadline')">
          <el-select v-model="form.deadline" :placeholder="$t('please_specify_deadline')">
            <el-option v-for="(i,index) in deadlineOpt" :key="index" :value="i.value" :label="i.label">{{ i.label }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="handleSubmit">
            {{$t(iMode)}}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from "vuex";
import {checkBreadMode} from "~/js/helpers/Route";
export default {
  props:{
    mode:{
      type: String,
      default:'add'
    },
    // form:{
    //   type: Object,
    //   default(){
    //     return {
    //       type:'',
    //       deadline:'',
    //       id:''
    //     }
    //   }
    // }
  },
data() {
    const _this = this;
    return {
      formElId: 'booking-issue-setting',

      deadlineOpt: [
        {
          value: 'today',
          label: `${_this.$t('today')}`
        },
        {
          value: '1 day',
          label: `1 ${_this.$t('tfv_day')}`
        },
        {
          value: '3 days',
          label: `3 ${_this.$t('tfv_days')}`
        },
        {
          value: '7 days',
          label: `7 ${_this.$t('tfv_days')}`,
        },
        {
          value: '30 days',
          label: `30 ${_this.$t('tfv_days')}`, 
        },
        {
          value: 'end_of_month',
          label: `${_this.$t('end_of_month')}`
        }
      ]
    }
  },
  computed: {
    ...mapState('bookingIssueSetting', {
      form: 'compose',
      payloadData: 'payloadData',
    }),

    ...mapGetters('bookingIssueSetting', {
      entryData: 'itemData',
    }),
    iMode() {
      return this.mode;
    },
    // iForm(){
    //   return this.form
    // }
  },
  methods: {
    ...mapActions('bookingIssueSetting', {
      addEntry: 'add',
      editEntry: 'edit',
      readEntry: 'read',
      browse:'browse'
    }),
    ...mapMutations('bookingIssueSetting', {
      resetForm: 'resetCompose',
    }),

  /**
   * Handle when the form has been submitted.
   *
   * @return {void}
   */
  handleSubmit() {

    // Set the action to be made.
    let action = this.iMode + 'Entry';
    let payload = {}; // Set container for payload.

    // If the current compose mode is EDIT, then
    // add the ID of the target into the payload.
    if (this.iMode == 'edit') {
      payload.id = this.entryData.id;
    }
    // console.log

    this[action](payload).then(r => {
      if (r.status == 201) {
        this.$emit('clicked', false);
        this.browse();

      }
    });

  }, // End of handleSubmit() method
  }
}
</script>

<style>

</style>
