
import APICaller from '~/js/helpers/APICaller';
import {extendObjParams, mapEndpoint, sd} from '~/js/helpers/Common';
import {Notification} from 'element-ui';
import i18n from '~/js/i18n';
import { gep } from '~/js/helpers/Vuex';

let apiPrefix = 'v3';
export default {
  postPlannedLanguage(context, payload){
 
    return APICaller({
      method:'post',
      endpoint: gep(`planned-languages`,apiPrefix),
      data: payload
    }).then(r =>{

      Notification.success({
        title: i18n.t('success'),
        message: "Planned language added",
      });
    }).catch(error =>{
      Notification.error({
        title: i18n.t('error'),
        message: i18n.t('n_went_wrong'),
      });
    })
  }
}