<template>
<div>
  <el-row>
    <el-col :span="12">
  <h3>{{$t('feedbacks')}} </h3></el-col>
    <el-col :span="12">
       <div v-if="addFeedback && !isEmpty(iBooking.assigned_translator)" class="text-right" ><el-button type="primary" @click="dialogVisible = true" size="mini">{{$t('add_feedback')}}</el-button></div>
    </el-col>
  </el-row>

  <div v-for="(i, idx) in iFeedbacks" :key="idx">
    <div class="block" v-if="i.review_from_type=='3'">From Translator
      <ul style="list-style-type: none;">
        <li v-for="(feedback,index) in i.feedback" :key="index">
          <div class="block">
            <span class="demonstration">{{feedback.created_at}}</span>
            <el-rate v-model="feedback.rating" show-score @change="changeFeedback(feedback,index,idx)"></el-rate>
            <span v-if="!feedback.isEdit">{{feedback.comment}}</span>
            <template v-else>
              <el-input type="textarea" autosize placeholder="Please add comment" v-model="feedback.comment">
              </el-input>
              <el-button type="primary" :disabled="!feedback.isEdit" @click="handleUpdateFeedback(feedback,index,idx)">Update</el-button>
            </template>
          </div>
        </li>
      </ul>
    </div>
    <div class="block" v-else>From Customer
      <ul style="list-style-type: none;">
        <li v-for="(feedback,index) in i.feedback" :key="index">
          <div class="block">
            <span class="demonstration">{{feedback.created_at}}</span>
            <el-rate v-model="feedback.rating" show-score @change="changeFeedback(feedback,index,idx)"></el-rate>
            <span v-if="!feedback.isEdit">{{feedback.comment}}</span>
            <template v-else>
              <el-input type="textarea" autosize placeholder="Please add comment" v-model="feedback.comment">
              </el-input>
              <el-button type="primary" :disabled="!feedback.isEdit" @click="handleUpdateFeedback(feedback,index,idx)">Update</el-button>
            </template>
          </div>
        </li>
      </ul>
    </div>
  </div>


<el-dialog
  :title="$t('add_booking_feedback')"
  :visible.sync="dialogVisible"
  width="50%"
  :modal-append-to-body="true"
  :append-to-body="true">
  <feedback-form :feedbacks="iFeedbacks" :booking="iBooking" @update="setDialogVisible"></feedback-form>
</el-dialog>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FeedbackForm from './AddFeedbackForm'
import {isEmpty} from '~/js/helpers/Common';

export default {
  name:'bookingFeedback',
  props:{
    feedbacks:{
      type:Array,
      default:[]
    },
    booking:{
      type:Object,
      default:{}
    }
  },
  components:{
    FeedbackForm
  },
  data(){return{
    dialogVisible: false
  }
  },
  computed:{
    iFeedbacks:{
      get(){
        return this.feedbacks
      },
      set(v){
        this.$emit('input',v)
      }
    },
    iBooking(){
      return this.booking
    },
    ...mapGetters({
      addFeedback: 'feedback/addFeedback',

    })
  },
  methods:{
    ...mapActions({
      putFeedback:'feedback/putFeedback'
    }),
    changeFeedback(v,index,idx){
      this.feedbacks[idx].feedback[index].isEdit=true
    },
    handleUpdateFeedback(v,index,idx){
      this.feedbacks[idx].feedback[index].isEdit=false
      this.putFeedback(v)
    },
    setDialogVisible(event){
      this.dialogVisible = event
    },
    isEmpty (v) {
      return isEmpty(v)
    },
  },
  mounted(){
  }
}
</script>

<style>

</style>
