/*
|--------------------------------------------------------------------------
| UI > mutations
|--------------------------------------------------------------------------
*/

'use strict';
import i18n from '../../i18n';
const COLOR_THEME_KEY = 'app_color_theme';
const LANGUAGE_KEY = 'app_language';

export default {

  /**
   * Method to save the theme upon selecting it.
   *
   * @param {object} state - vuex state of current store scope.
   * @param {*} payload - contains mixed values necessary inside the method.
   *
   * @return {void}
   */
  saveColorTheme(state, payload) {
    state.colorTheme = payload;
    localStorage.setItem(COLOR_THEME_KEY, payload);
  },

  /**
   * Method to load the theme upon site initialization.
   *
   * @param {object} state - vuex state of current store scope.
   *
   * @return {void}
   */
  loadColorTheme(state) {
    const theme = localStorage.getItem(COLOR_THEME_KEY);
    if (!_.isNil(theme)) {
      state.colorTheme = theme;
    } else {
      state.colorTheme = '';
    }
  },
  /**
   * Method to save the language upon selecting it.
   *
   * @param {object} state - vuex state of current store scope.
   * @param {*} payload - contains mixed values necessary inside the method.
   *
   * @return {void}
   */
  saveLanguage(state, payload) {
    state.language = payload;
    i18n.locale=payload;
    localStorage.setItem(LANGUAGE_KEY, payload);
  },
  /**
   * Method to load the language upon site initialization.
   *
   * @param {object} state - vuex state of current store scope.
   *
   * @return {void}
   */
  loadLanguage(state) {
    const lang = localStorage.getItem(LANGUAGE_KEY);
    if (!_.isNil(lang)) {
      i18n.locale = lang;
      state.language = lang;
    } else {
      i18n.locale = 'se';
      state.language = 'se'
    }
  },
}
