<template>
  <el-dialog 
  :title="title" 
  :visible.sync="iVisible" 
  :modal-append-to-body="false"
  :append-to-body="true"
  custom-class="custom-modal"
  width='60%' 
  v-loading="loading">
  <el-row >
    <ElCol :span="12" class="right-border">
      <table class="table-borderless">
        <tr>
          <td>Booking ID</td>
          <td>:</td>
          <td>{{iRow.id}}</td>
        </tr>
        <tr>
          <td>Evt kundreferens</td>
          <td>:</td>
          <td>{{iRow.reference}}</td>
        </tr>
        <tr>
          <td>Beställarens namn</td>
          <td>:</td>
          <td>{{iRow.booker_name}}</td>
        </tr>
        <tr>
          <td>Datum och Tid</td>
          <td>:</td>
          <td>{{iRow.due}}</td>
        </tr>
        <template v-if="!isEmpty(iRow.customer.customer_data)">
        <tr>
          <td>Kundens typ</td>
          <td>:</td>
          <td>
            {{iRow.customer.customer_data.customer_type.name}}
            </td>
        </tr>
        <tr>
          <td>Avdelning</td>
          <td>:</td>
          <td>{{iRow.customer.customer_data.department.name}}</td>
        </tr>
        <tr>
          <td>Organisation</td>
          <td>:</td>
          <td>{{iRow.customer.customer_data.department.company.name}}</td>
        </tr>
        </template>
        <tr>
          <td>Tolk namn</td>
          <td>:</td>
          <td>{{iRow.assigned_translator.name}}</td>
        </tr>
        <tr>
          <td>Tolk tel</td>
          <td>:</td>
          <td>{{iRow.assigned_translator.phone}}</td>
        </tr>
        <tr>
          <td>Tolk mobil</td>
          <td>:</td>
          <td>{{iRow.assigned_translator.mobile}}</td>
        </tr>
        <tr>
          <td>Tolktild</td>
          <td>:</td>
          <td>{{iRow.session_time}}</td>
        </tr>
        <tr>
          <td>Sprak</td>
          <td>:</td>
          <td>{{iRow.from_language_id}}</td>
        </tr>
        <tr>
          <td>Feedback för översättare </td>
          <td>:</td>
          <td><el-rate v-model="iFeedback.rating" disabled></el-rate></td>
        </tr>
      </table>
      
    </ElCol>
    
    <ElCol :span="12">
      <div class="grid-content">
      <el-form :model="form" @submit.native.prevent>
        <ElFormItem label="Action taken">
          <el-select v-model="form.flag_id" placeholder="" multiple>
            <ElOption v-for="item in flags" :key="item.id" :value="item.id" :label="item.label" :class="item.style">
             
            </ElOption>
          </el-select>
        </ElFormItem>
        <h4 class="text-left">{{ $t('comment') }}</h4>
        <el-form-item label="">
          <!-- <el-input v-model="form.comment" placeholder="" type="textarea" :rows="10"></el-input> -->
        <div style="background-color:#fff" class="text-left" >
          <wysiwyg v-model="form.comment" :aria-placeholder="$t('enter_text')" :placeholder="$t('enter_text')"/>
        </div>
        </el-form-item>
        <el-button type="danger" @click="handleClose">Cancel</el-button>
        <el-button type="success" @click="handleSubmit">Send</el-button>
      </el-form>
      </div>

    </ElCol>
  </el-row>
 
  </el-dialog>

</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { isEmpty } from '../../../js/helpers/Common';
export default {
    name: 'reportToCustomer',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        title:{
          type:String,
          default:'Send report to customer'
        },
        row:{
          type:Object,
          default:{
            customer:{
              customer_data:{
                customer_type:{
                  name:''
                },
                department:{
                  company:{
                    name:''
                  }
                }
              }
            }
          }
        },
        feedback:{
          type:Object,
          default:{}
        },
        
    },
     data() {
        return {
          form:{
            feedback_id:'',
            comment:'',
            flag_id:[],
          },
          flags:[
            {id:6,label:'Penalty translator',style:'text-warning'},
            {id:4,label:'Customer discount applied',style:'text-primary'},
            {id:3,label:'Blacklist translator',style:'text-danger'},
            {id:7,label:'Translator warning applied',style:'text-info'},
          ]
        }
    },
    computed: {
      ...mapGetters({
        loading:'feedback/getLoading'
      }),
      /**
       * Interface for this.visible
       */
      iVisible: {
        get() {
            return this.visible
        },
        set(v) {
            this.$emit('update:visible', v);
        }
      },

      iTitle: {
        get() {
            return this.title
        }
      },
      iRow(){
        return this.row
      },
      iFeedback(){
        return this.feedback
      },
      
    },
    methods:{
      ...mapActions({
        storeFeedbackIssue:'feedback/storeFeedbackIssue'
      }),
      isEmpty(v){
        return isEmpty(v)
      },
       /**
       * Handle when the modal has been closed
       *
       * @return {void}
       */
      handleClose: function () {
          this.$emit('update:visible', false);
      }, // End of handleClose() method

      handleSubmit(){
        let data = {
          comment: this.form.comment,
          flag_id: this.form.flag_id,
          report:true,
          feedback_id: this.iFeedback.id
        }
        this.storeFeedbackIssue(data).then(r => {
          this.$emit('update:visible', false);
          if(r.status == 201){
          this.$emit('updateIssues',r.data.data.feedback_issue)
          this.form ={
              feedback_id:'',
              comment:'',
              flag_id:'',
            }
          }
          
        })
      }
    }
}
</script>
<style lang="scss" scoped>
.table-borderless td,
.table-borderless th {
    border: 0;
}
.table-borderless tr td:nth-child(3){
  color: #6EC19B;
}
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    height: 100%;
    padding: 10px;
    text-align: center;
  }
  .grid-details{
    border-radius: 4px;
    min-height: 36px;
    padding: 10px;
  }
.right-border{
  border-right: 1px solid #d3dce6;
}
</style>

