<template>
    <div class="app-form batches-settings-form">
        <page-header :title="$t('add_planned_language')" :back-enabled="true"/>
        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col">
                        <el-form :id="formElId" :model="form" :ref="formElId" :rules="rules">

                            <el-form-item :label="$t('Language')" class="label-short" prop="language_id">
                                <el-select v-model="form.language_id" filterable placeholder="select languges">
                                    <el-option v-for="language in languages" :key="language.id" :label="language.name" :value="language.id"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item :label="$t('rule')" class="label-short" prop="rule">
                                <el-select :id="genFieldId('rule',formElId)"
                                    v-model="form.rule"
                                    :placeholder="$t('select')">
                                    <el-option :label="$t('hours_before')" value="hours_before"/>
                                    <el-option :label="$t('time_of_day')" value="time_of_day"/>
                                </el-select>
                            </el-form-item>

                            <el-form-item v-if="form.rule=='hours_before'" :label="$t('hours_for_planning_stage')" class="label-short" prop="hours">
                                <el-input v-model="form.data.hours" placeholder=""></el-input>
                            </el-form-item>

                            <el-form-item v-if="form.rule=='time_of_day'" :label="$t('days_for_planning_stage')" class="label-short" prop="offset_days">
                                <el-input v-model="form.data.offset_days" placeholder=""></el-input>
                            </el-form-item>
                            <el-form-item v-if="form.rule=='time_of_day'" :label="$t('specific_time_for_planning_stage')" class="label-short" prop="time">
                                <el-time-picker :id="genFieldId('time',formElId)"
                                    v-model="form.data.time"
                                    format="HH:mm"
                                    value-format="HH:mm:ss"/>
                            </el-form-item>

                            <el-form-item :label="$t('offer_hours')" class="label-short" prop="offer_hours">
                                <el-input v-model="form.offer_hours" placeholder=""></el-input>
                            </el-form-item>

                            <el-form-item :id="genContainerId('save_btn','planned_language')">
                                <el-button class="modal-size" type="default" @click="handleClose()">{{$t('cancel')}}</el-button>
                                <el-button class="modal-size" type="primary" @click="submitForm(formElId)">{{$t('submit')}}</el-button>
                            </el-form-item>
                        </el-form>
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { isEmpty } from "../../../js/helpers/Common";
export default {
    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data() {
        return {
            formElId: 'planned-language',
            form: {
                language_id: '',
                rule: 'hours_before',
                data: {
                    hours: '',
                    offset_days: '',
                    time: ''
                },
                offer_hours: ''
            },
            rules: {
                language_id: [
                    {
                        required: true,
                        message: "Please add language"
                    }
                ],
                rule: [
                    {
                        required: true,
                        message: "Please add rule"
                    }
                ],
                offer_hours: [
                    {
                        required: true,
                        message: "Please add offer hours"
                    }
                ]
            }
        };
    },
    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
        ...mapGetters({
            languages: 'language/getLanguages'
        }),
    },
    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
        ...mapActions('language',{
            getLanguages: 'fetchLanguages'
        }),
        ...mapActions('plannedLanguages',{
            add:'postPlannedLanguage',
            getPlannedLanguageById:'read'
        }),

        submitForm(ruleForm) {
            const _this=this;
            this.$refs[ruleForm].validate((valid) => {
                if (valid) {
                    console.log(_this.form)
                    _this.add(_this.form);
                }else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    },
    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted() {
        this.getLanguages();
        if (this.$route.params.editMode == true) {
            this.getPlannedLanguageById(this.$route.params.id).then(r => {
                this.form = _.merge(this.form, r.data.planned_languages);
                this.editMode = true;
            });
        }
    }
};
</script>