/*
|--------------------------------------------------------------------------
| Financial > Invoice > actions
|--------------------------------------------------------------------------
| Partial file for actions object related to the scope.
*/

'use strict';
import setSettings from '../shared/methods/setSettings';
import setSettingsPayload from '../shared/methods/setSettingsPayload';
import loadSettings from '../shared/methods/loadSettings';
import addSettings from '../shared/methods/addSettings';
import editSettings from '../shared/methods/editSettings';
import deleteSettings from '../shared/methods/deleteSettings';
import importSettings from '../shared/methods/importSettings';

export default {
  setSettings,
  setSettingsPayload,
  loadSettings,
  addSettings,
  editSettings,
  deleteSettings,
  importSettings,
}
