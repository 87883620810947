/*
|--------------------------------------------------------------------------
| Store > SMS Centre
|--------------------------------------------------------------------------
|
| This file contains the state property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/

export default {

  conversationList: [],
  bookingIds: [],
  conversationCount: 0,
  selectedRow: null,
  conversationCount: '',
  conversation: {
    sms_centre_conversation: []
  },
};
