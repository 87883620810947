<template>     
        <div class="ratings">
                        <div class="ratings-header">
                          <div class="ratings-header-text"> 10 {{ title }}</div>
                          <div class="ratings-header-date">{{ date }}</div>
                        </div>
                        <table class="ratings-table">
                          <thead>
                            <tr>
                              <th>Language</th>
                              <th>{{ text }}</th>
                              <th>%</th>
                            </tr>
                          </thead>
                          <tbody>

                            <tr v-for="value,key in tableData">
                              <td> {{ value.municipality_name }}</td>
                              <td> {{ value.count }} expired ( {{ value.total }} expired bookings)</td>
                              <td>{{ calcPercentage(value.count, value.total) }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
</template>
<script>
  import {mapActions,mapGetters} from "vuex";
  import {populate} from "~/js/helpers/Vuex";
    export default{
        mounted(){
          let self = this;
          let token = localStorage.getItem("app_access_token");
          axios.get("https://api-gateway.digitaltolk.se/api/v3/bookings-statistics/municipality?type="+this.TypeData,
      
          {
              headers:
          {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
          },
            }
            )
              .then(resp => {
                self.tableData  = resp.data.slice(0,10);
              }).catch(err => {
                  console.log(err);       
              });
        },
        props : ["title", "date", "text", "type"],
        data() {
            return {
                window,
                tableData : null
            };
        },
        watch: {
          //
        },
        computed : {
          TypeData : function(){
            return this.type;
          }
        },
        methods : {
          calcPercentage : function(count,total){
            if(count !== 0 && total !== 0){
              var calpercentage = (count / total) * 100;
              var percentage = calpercentage.toFixed(0);
            }else{
              percentage = 0;
            }
            return percentage;
          }
        }
    }
</script>

<style scoped>
  .dashboard-stats {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    width: calc(100% - 14em);
    z-index: 5;
    padding: 0 7em;
    top: 70px;
    left: 0px;
  }
  
  .dashboard-stat {
    padding: 1.5em;
    border-radius: 5px;
    background: linear-gradient(180deg, rgba(26,169,241,1) 5%, rgba(11,116,209,1) 77%);
    color: white;
    flex: 1;
    margin: 0 0.7em;
    box-shadow: 3px 3px 6px rgba(128, 128, 128, 0.47058823529411764);
  }
  
  .dashboard-stat-number {
    color: white;
    font-size: 3em;
    margin: 0.3em 0;
    font-weight: 900;
  }
  
  .dashboard-stat-title {
    font-size: 1.2em;
    font-weight: 700;
  }
  
  .dashboard-stat-text {
    font-style: italic;
  }
  
  .app-container.app-container--statPadding {
    padding-top: 100px;
  }
  
  .app-col.app-col--statPadding {
    padding: 100px 15px 7px !important;
  }
  
  .ratings-header {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0.5em;
    font-size: 1.1em;
    margin-bottom: 1em;
  }
  
  .ratings-header-text {
    font-weight: bold;
    color: #6F6f6d;
    font-size: 13px;
    color: #000;
  }
  
  .ratings-header-date {
    color: #b5b5b5;
  }
  
  .ratings-table {
    text-align: center;
    width: 100%;
    font-size: 11px;
    border-spacing: 0px;
  }
  
  .ratings-table td,
  .ratings-table th {
    padding: 12px 0;
  }
  
  .ratings-table th {
    background-color: #edeffb;
  }
  
  .ratings-table tbody tr:not(:last-child) td {
    border-bottom: 1px solid #e2e2e2;
  }
  
</style>