export const expiredChartData = {
    type: 'bar',
    data: null,
    options: {
      legend: {
        display: false
      },
      plugins: {
        datalabels: {
          color: '#ffffff',
          font: {
            size: 15,
          }
        }
      },
      responsive: true,
      lineTension: 1,
      scales: {
        yAxes: [{
          ticks: {
            display: false,
            max : 300
          }
        }],
        xAxes: [{
          gridLines: {
            color: false
          },
        }]
      },
      hover: {
        "animationDuration": 0
      },
      animation: {
        duration: 0,
        onComplete: function () {
            var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = "rgba(0, 0, 0, 1)";
            ctx.textBaseline = 'bottom';
            console.log(this.topHeadings);
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                  console.log(dataset);
                    var data = 0;
                    if(dataset.la[index] == 0){
                      data = '0%';
                    }else{
                      data = (((dataset.data[index]/ dataset.la[index]) * 100 )).toFixed(2)+ '%';
                    }
                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                });
            });
        }
      }
      /*plugins: {
        datalabels: {
          anchor: 'end',
          align: 'top',
          formatter: Math.round,
          font: {
            weight: 'bold'
          }
        }
      }*/
    }
  }
  
  export default expiredChartData;