<template>
    <div class="app-form" :class="getBem(blockCls)">

        <el-form :class="getBem(blockCls, 'form')">
            <el-row :gutter="10">

                <!-- Entity ID Selector -->
                <el-col :xs="24" :sm="12" :md="8">
                    <el-form-item :class="getBem(blockCls, 'type-select-container')">
                        <el-select :class="getBem(blockCls, 'type-select')"
                                   v-model="form.entity_type"
                                   @change="handleChangeEntityTypeSelect"
                                   placeholder="Choose Entity Type"
                                   clearable
                                   filterable>
                            <el-option v-for="v in entityTypeOpts"
                                       :key="v.key"
                                       :value="v.key"
                                       :label="v.label" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <!-- Entity ID Selector -->
                <el-col :xs="24" :sm="12" :md="11">
                    <el-form-item :class="getBem(blockCls, 'id-select-container')">
                        <el-select :class="getBem(blockCls, 'id-select')"
                                   :disabled="!isEntityTypeOptsValid"
                                   v-model="form.entity_id"
                                   placeholder="Choose Entity"
                                   clearable
                                   filterable>
                            <el-option v-for="v in entityIdOpts"
                                       :key="v.id"
                                       :value="v.id"
                                       :label="v.name" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <!-- Actions -->
                <el-col :xs="24" :sm="12" :md="5">
                    <el-form-item :class="getBem(blockCls, 'load-button-container')">
                        <el-button :class="getBem(blockCls, 'load-button')"
                                   type="primary" size="mini"
                                   @click="handleClickLoad">
                            Load
                        </el-button>

                        <el-button :class="getBem(blockCls, 'reset-button')"
                                   type="warning" size="mini"
                                   @click="handleClickReset">
                            Reset
                        </el-button>

                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

        <div :class="getBem(blockCls, 'list-container')">
            <financial-settings-import-list
                :entity-type="form.entity_type"
                :entity-id="form.entity_id"
                ref="list"
                @apply="handleApplyImport"
            />
        </div>

    </div> <!-- Component DIV Wrapper -->
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import FinancialSettingsImportList from '~/components/lists/financial/settings/FinancialSettingsImportList';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      FinancialSettingsImportList,
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-settings-import-selector',
        form: {
          entity_id: '',
          entity_type: ''
        },
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters('municipality', {
        municipalityOpts: 'listAsOptions'
      }),

      ...mapGetters('company', {
        companyOpts: 'listAsOptions'
      }),

      ...mapGetters('department', {
        departmentOpts: 'listAsOptions'
      }),

      entityIdOpts () {
        // Define necessary values.
        const type = this.form.entity_type;
        let collection = '';

        if (type !== '') {
          // If type has a selected value, then set the collection key;
          if (type === 'municipalities') collection = 'municipalityOpts';
          else if (type === 'companies') collection = 'companyOpts';
          else if (type === 'departments') collection = 'departmentOpts';

          return this[collection];
        } else {
          return [];
        }
      },

      /**
       * Returns array of entity type for options.
       *
       * @returns {Array} - Array of Objects paired by key {string} : label {string}
       */
      entityTypeOpts () {
        return [
          {key: 'municipalities', label: 'Municipalities'},
          {key: 'companies', label: 'Companies'},
          {key: 'departments', label: 'Departments'}
        ];
      },

      /**
       * Returns boolean to determine if form.entity_type value valid.
       *
       * @returns {boolean}
       */
      isEntityTypeOptsValid () {
        return this.form.entity_type !== '';
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions('municipality', {
        loadMunicipalityOpts: 'browseAsOptions'
      }),

      ...mapActions('company', {
        loadCompanyOpts: 'browseAsOptions'
      }),

      ...mapActions('department', {
        loadDepartmentOpts: 'browseAsOptions'
      }),

      /**
       * Handler when the value of Entity Type Select was changed.
       *
       * @param {string} selectedValue -
       * @return {void}
       */
      handleChangeEntityTypeSelect (selectedValue) {
        if (selectedValue !== '') {
          this.entityIdOptsLoader(selectedValue);
        }
      },

      /**
       * Helper for loading the Entity ID Options.
       *
       * @param {string} entityType - Current Entity Type Selected.
       */
      entityIdOptsLoader (entityType) {
        let loader = '';
        if (entityType === 'municipalities') loader = 'loadMunicipalityOpts';
        else if (entityType === 'companies') loader = 'loadCompanyOpts';
        else if (entityType === 'departments') loader = 'loadDepartmentOpts';

        if (this.entityIdOpts.length === 0) this[loader]();
      },

      /**
       * Handler when the Reset button was clicked..
       *
       * @returns {void}
       */
      handleClickReset () {
        this.form.entity_id = '';
        this.form.entity_type = '';
      },

      /**
       * Handler when the Load button was clicked..
       *
       * @returns {void}
       */
      handleClickLoad () {
        this.$refs.list.reloadList();
      },

      /**
       * Handler for the apply event of financial-settings-import-list.
       *
       * @param {Object} payload - contains the targeted pricelist item.
       * @returns {void}
       */
      handleApplyImport (payload) {
        this.$emit('apply', payload);
      }

    },
  }
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
    .el-button {
        min-width: auto;
        font-size: 12px;
        margin: 2px 1px;
        padding: 7px 5px;
    }

    .app-splitter.three-cols, .el-form-item {
        margin-bottom: 0;
    }

</style>
