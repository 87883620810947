<template>
  <div :class="getBem(blockClass)" v-loading="isBatchesLoading">

    <div v-if="!isBatchesProcessComplete" class="text-center">
      <template v-if="batchesStatus === 'failed'">
        <div class="text-center" v-if="!isBatchesLoading && batchesList.length === 0">
          <i class="fa fa-exclamation-circle batches-failed-icon"></i>
          <h3>{{$t('batches_status')}}: {{batchesStatus}}</h3>
        </div>
        <div class="text-right" v-else>
          <h3>{{$t('prioapp_status')}}: {{batchesStatus}}</h3>
        </div>
      </template>

      <template v-else>
        <el-steps :active="getBatchesStatus(batchesStatus)" finish-status="success" align-center>
          <el-step v-for="(b, index) in batchesStatusList" :title="b.name" v-bind:key="index"></el-step>
        </el-steps>
      </template>
    </div>

    <!--  Top Controls -->
    <!-- -------------------------------------------------- -->
    <div
      :class="getBem(blockClass, 'top-controls-container')"
      v-if="isBatchesProcessComplete"
    >
      <el-collapse v-model="activeControls" :class="getBem(blockClass, 'top-controls')">

        <!-- -------------------------------------------------- -->
        <!-- Filter Section -->
        <!-- -------------------------------------------------- -->
        <el-collapse-item
          :class="[
            getBem(blockClass, 'filter-container'),
            getBem(blockClass, 'field-container'),
          ]"
          name="filter"
        >
          <template #title>
            <h3 :class="getBem(blockClass, 'field-title')">
              {{$t('filters')}}
            </h3>
          </template>

          <el-row type="flex" justify="start" align="top">

            <!-- Filter Query Search Input -->
            <el-col
              :xs="24" :sm="24" :md="8"
              :class="getBem(blockClass, 'filter-query')"
            >
              <div :class="getBem(blockClass, 'field-wrapper')">
                <span>{{$t('translator_or_email')}}</span>
                <el-input
                  :class="getBem(blockClass, 'filter-query-field')"
                  v-model="filter.query"
                  :placeholder="$t('search')"
                  clearable
                  size="small"
                  @keyup.enter.native="handleFilter"
                />
              </div>
            </el-col>

            <!-- Action -->
            <el-col
              :xs="24" :sm="24" :md="8"
              :class="getBem(blockClass, 'filter-action')"
            >
              <div :class="getBem(blockClass, 'field-wrapper')">
                <span>{{$t('tfv_action')}}</span>
                <el-checkbox-group
                  :class="getBem(blockClass, 'filter-action-field')"
                  v-model="filter.action"
                >
                  <el-checkbox label="rejected">{{$t('rejected')}}</el-checkbox>
                  <el-checkbox label="not_picked_up_call">{{$t('not_pick_up_call')}}</el-checkbox>
                  <el-checkbox label="non_sendable">{{$t('tfv_non_sendable')}}</el-checkbox>
                </el-checkbox-group>
              </div>
            </el-col>

            <!-- Buttons -->
            <el-col
              :xs="24" :sm="24" :md="8"
              :class="getBem(blockClass, 'filter-buttons')"
            >
              <el-button type="primary" size="mini" @click="handleFilter">
                {{$t('filter')}}
              </el-button>
              <el-button type="success" size="mini" @click="handleResetFilter">
                {{$t('reset')}}
              </el-button>
            </el-col>
          </el-row>

        </el-collapse-item>

        <!-- -------------------------------------------------- -->
        <!-- Resend Notifications -->
        <!-- -------------------------------------------------- -->
        <el-collapse-item
          :class="[
            getBem(blockClass, 'resend-notification-container'),
            getBem(blockClass, 'field-container'),
          ]"
          name="resend_notification"
        >
          <template #title>
            <h3 :class="getBem(blockClass, 'field-title')">
              {{$t('resend_notification')}}
            </h3>
          </template>

          <el-row type="flex" justify="start" align="top">

            <!-- Notification Selections -->
            <el-col
              :xs="24" :sm="12" :md="12"
              :class="getBem(blockClass, 'resend-notification-channels')"
            >
              <div :class="getBem(blockClass, 'field-wrapper')">
                <span>{{$t('channels')}}</span>

                <el-checkbox-group
                  :class="getBem(blockClass, 'filter-notification-type-field')"
                  v-model="notificationChannels"
                >
                  <el-checkbox label="push">{{$t('tfv_push')}}</el-checkbox>
                  <!--<el-checkbox label="email">Email</el-checkbox>
                  <el-checkbox label="sms">SMS</el-checkbox>-->
                </el-checkbox-group>
              </div>
            </el-col>

            <!-- Buttons -->
            <el-col
              :xs="24" :sm="12" :md="12"
              :class="getBem(blockClass, 'resend-notification-buttons')"
            >
              <el-button
                type="success" size="mini"
                :disabled="form.selected.length === 0"
                @click="handleResendNotifCheck(1)"
              >
                <span class="fa fa-bell"></span>
                {{$t('resend_notification')}}
              </el-button>
              <el-button
                type="success"
                size="mini"
                @click="handleResendNotifCheck(2)"
              >
                <span class="fa fa-bell"></span>
                {{$t('resend_notification_all')}}
              </el-button>
            </el-col>
          </el-row>

        </el-collapse-item>

        <!-- -------------------------------------------------- -->
        <!-- Schedule View -->
        <!-- -------------------------------------------------- -->
        <el-collapse-item
          :class="[
            getBem(blockClass, 'schedule-view-container'),
            getBem(blockClass, 'field-container'),
          ]"
          name="schedule_view"
        >
          <template #title>
            <h3 :class="getBem(blockClass, 'field-title')">
              {{$t('schedule_view')}}
            </h3>
          </template>

          <el-button
            :class="getBem(blockClass, 'show-selected-in-schedule-view-button')"
            type="success"
            size="mini"
            :disabled="!hasSelected"
            @click="handleClickShowSelectedInScheduleView"
          >
            <span class="fa fa-calendar"></span>
            {{$t('show_selected_schedule_view')}}
          </el-button>

          <el-button
            :class="getBem(blockClass, 'show-non-sendables-in-schedule-view-button')"
            type="success"
            size="mini"
            @click="handleClickShowNonSendablesInScheduleView"
          >
            <span class="fa fa-calendar"></span>
            {{$t('show_non_sendable_schedule_view')}}
          </el-button>

        </el-collapse-item>
      </el-collapse>
    </div>

    <div v-if="isBatchesEmpty" class="text-center">
      <h3>{{$t('list_empty')}}</h3>
    </div>
    <div v-else-if="isBatchesStillLoading" class="text-center">
      <h3>{{$t('batches_loading')}}</h3>
    </div>

    <el-row type="flex" justify="left" align="middle" :class="getBem(blockClass, 'batches-list-header')"
            v-if="isBatchesProcessComplete"
    >

      <!-- Interval -->
      <el-col :class="getBem(blockClass,'interval')" :xs="24" :sm="24" :md="24">
        <el-row type="flex" justify="left" align="middle">
          <el-col :class="getBem(blockClass,'interval')" :xs="24" :sm="12" :md="12">
            <span :class="getBem(blockClass, 'interval-label')">{{$t('batches_interval')}} : </span>
            <span :class="getBem(blockClass, 'interval-value')">{{ batchesInterval }}</span>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12">
            <div class="text-right">
              <span>{{$t('sendable')}}: {{booking.translators_sendable_count}} | {{$t('non_sendable')}}: {{booking.translators_non_sendable_count}}</span>
            </div>
          </el-col>
        </el-row >
      </el-col>

      <!-- Sort -->
      <el-col :class="getBem(blockClass, 'sort')" :xs="24" :sm="12" :md="12" v-if="false">
        <div :class="getBem(blockClass, 'sort-wrapper')">
          <span :class="getBem(blockClass, 'sort-label')">Sort by:</span>
          <div :class="getBem(blockClass, 'sort-by')">
            <el-select
              :class="getBem(blockClass, 'sort-by-field')"
              v-model="sort.by"
              @change="handleSort"
              filterable
              size="small"
            >
              <el-option value="batch_id" label="Batches ID"/>
              <el-option value="translator_id" label="Translator ID"/>
              <el-option value="gender" label="Translator Gender"/>
              <el-option value="language_id" label="Language ID"/>
              <el-option value="translator_level_id" label="Translator Level ID"/>
              <el-option value="rejected_at" label="Rejected At"/>
              <el-option value="last_booking_due" label="Last Job Due"/>
              <el-option value="last_booking_city" label="Last Job City"/>
              <el-option value="previous_bookings_count" label="Previous Job Count"/>
              <el-option value="temp_travel_distance_car" label="Distance (Car)"/>
              <el-option value="temp_travel_time_car" label="Time (Car)"/>
              <el-option value="temp_travel_distance_public" label="Distance (Public)"/>
              <el-option value="temp_travel_time_public" label="Time (Public)"/>
              <el-option value="sent_at" label="Sent At"/>
            </el-select>
          </div>
          <div :class="getBem(blockClass, 'sort-order')">
            <el-select
              :class="getBem(blockClass, 'sort-order-field')"
              v-model="sort.order"
              @change="handleSort"
              size="small"
            >
              <el-option value="asc" label="ASC"/>
              <el-option value="desc" label="DESC"/>
            </el-select>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- Batches -->
    <!-- -------------------------------------------------- -->
    <translator-batch-list-table
      ref="translator-batch-list-table"
      :booking="booking"
      :batches="batchesList"
      :selected.sync="form.selected"
      :is-assigned-already="isBookingCurrentlyAssigned"
      :sort.sync="sort"
      @click-show-call="handleClickShowCall"
      @click-sms-help-desk="handleClickSmsHelpDesk"
      @click-resend-notification="handleResendNotifToTranslator"
      @click-not-pick-up-call="handleClickNotPickUpCall"
      @click-rejected="handleClickRejected"
      @click-assign="handleClickAssign"
      @change-sort="handleSort"
    />

    <div
      v-if="!isBatchesStillLoading && batchesList.length > 0"
      style="text-align: center; font-weight: bold"
    >
      <h3 v-if="!isBatchesEndOfData"
          style="cursor: pointer;"
          @click="appendLoadBatches"
      >
        Load More...
      </h3>
      <h3 v-else>
        End of List
      </h3>
    </div>

    <resend-notif-confirmation
      :handleResendToSome="handleResendNotifToSome"
      :handleResendToAll="handleResendNotifToAll"
      :date="notificationFills"
      :width="modalWidth"
      :visible.sync="showResendNotifModal"
      :countBatch="countBatch"
      :isNotifToAll="isNotifToAll"
    />

    <booking-availability-scheduler-modal
      ref="booking-availability-scheduler-modal"
    />

    <call-mobile
      :booking="booking"
      :visible.sync="isShowCallModal"

    />
  </div>
  <!-- /.app-list.translator-batch-list -->
</template>

<script>
  import {mapGetters, mapMutations, mapActions, mapState} from 'vuex';
  import {isEmpty} from "~/js/helpers/Common";
  import isNil from "lodash/isNil";
  import clone from "lodash/clone";
  import map from "lodash/map";
  import uniq from "lodash/uniq";
  import forEach from "lodash/forEach";
  import includes from "lodash/includes";
  import filter from "lodash/filter";
  import isObject from "lodash/isObject";
  import isArray from "lodash/isArray";
  import isString from "lodash/isString";
  import has from "lodash/has";
  import find from "lodash/find";
  import APICaller from '~/js/helpers/APICaller';
  import API from '~/js/constants/api';
  import CallMobile from '../../modals/translator/CallMobile';
  import BookingAvailabilitySchedulerModal
    from '~/components/displays/booking-availability/BookingAvailabilitySchedulerModal';
  import TranslatorBatchListTable from '~/components/lists/batches/TranslatorBatchListTable';
  import {generateMessage} from '~/js/helpers/Messaging';
  import {isValidEmail} from '~/js/helpers/Common';
  import {getQueryParam, updateQueryParams, removeQueryParams} from '~/js/helpers/Route';
  import {notifSuccess, notifError} from '~/js/helpers/Notification';

  const filterFresh = {
    query: '',
    by: '',
    action: [
      'rejected',
      'not_picked_up_call',
      'non_sendable'
    ]
  };

  const sortFresh = {
    by: '',
    order: ''
  };

  export default {

    /*
    |--------------------------------------------------------------------------
    | Components > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      CallMobile,
      BookingAvailabilitySchedulerModal,
      TranslatorBatchListTable
    },

    /*
    |--------------------------------------------------------------------------
    | Components > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockClass: 'translator-batch-list',

        selected: [],
        form: {
          current: '',
          selected: []
        },
        showResendNotifModal: false,
        notificationFills: [],
        isNotifToAll: false,
        countBatch: 0,
        modalWidth: '50%',

        notificationChannels: [
          'push'
        ],

        isBookingCurrentlyAssigned: false,
        assignedCurrent: 0,

        isShowTranslator: false,
        isShowTranslatorLoading: false,
        translatorDetails: {},

        windowLastHeight: 0,

        filter: clone(filterFresh),
        sort: clone(sortFresh),

        activeControls: [
          'filter',
          'resend_notification',
          'schedule_view'
        ],

        isShowCallModal: false,
        callModal: {
          mobile: '',
          translator: ''
        },

        batcheEntry: {},
        translator: {}
      };
    }, // End of Components > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters('bookingBatches', [
        'booking',
        'batchesList',
        'batchesCurrentPage',
        'isBatchesLoading',
        'isBatchesEndOfData',
        'batchesStatus',
        'batchesSchedulerList'
      ]),

      ...mapGetters('feedback', {
        feedbacks: 'getFeedbacks'
      }),

      ...mapGetters('user', {
        authUser: 'authUserData',
        translatorData: 'itemData'
      }),

      ...mapState('bookingBatches', {
        batchesStatusList: 'batchesStatusList'
      }),

      ...mapGetters('adminUser', {adminList: 'listAsOptions'}),

      /**
       * @returns {boolean}
       */
      hasSelected () {
        return !isNil(this.form)
          && !isNil(this.form.selected)
          && this.form.selected.length > 0;
      },

      /**
       * Returns the booking id depending on the URL's ID param.
       *
       * @return {int}
       */
      bookingId () {
        return !isNil(this.$route.params) && !isNil(this.$route.params.id)
          ? parseInt(this.$route.params.id) : 0;
      },

      /**
       * @returns {boolean}
       */
      isBatchesProcessComplete () {
        return !isEmpty(this.batchesStatus)
          && this.batchesStatus === 'completed';
      },

      /**
       * @returns {boolean}
       */
      isBatchesEmpty () {
        return this.isBatchesProcessComplete
          && !this.isBatchesLoading
          && this.batchesList.length === 0;
      },

      /**
       * @returns {boolean}
       */
      isBatchesStillLoading () {
        return this.isBatchesLoading
          && this.batchesList.length === 0;
      },

      /**
       * @returns {string}
       */
      batchesInterval () {
        if (
          !isNil(this.batchesList)
          && !isEmpty(this.batchesList)
        ) {
          // noinspection JSCheckFunctionSignatures,JSUnresolvedVariable
          const intervals = uniq(map(this.batchesList, (v) => parseInt(v.dispatch_interval)));
          return intervals.join(', ');

        } else {
          return '';
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapMutations('bookingBatches', [
        'setBatchesPerPage',
        'setBatchesLoading'
      ]),

      ...mapActions('bookingBatches', [
        'loadBatchesForward',
        'loadBatchesScheduler',
        'resendNotif',
        'getBooking',
        'updateSendable'
      ]),

      ...mapActions({
        notiflist: 'booking/fetchNotifHistoryList',
        notiflistall: 'booking/fetchNotifHistoryListAll'
      }),

      ...mapActions("adminUser", {
        browseAdmin: "browseAsOptions"
      }),

      ...mapActions('user', {
        readUser: 'read'
      }),

      ...mapActions('feedback', {
        fetchFeedbacks: 'fetchFeedbacks'
      }),

      ...mapActions('bookingBatches', {
        getBatchStatus: 'getBatchStatus'
      }),

      ...mapActions('smsCentre', ['createSMS']),

      isEmpty (v) {
        return isEmpty(v);
      },

      /**
       * @param {Object} entry - Batch entry
       */
      handleClickRejected (entry) {
        this.setBatchesLoading(true);

        const apiOpts = {
          method: 'POST',
          endpoint: `${API.BATCHES}/${entry.id}/update`,
          data: {
            // Include is_called value because apparently it's required.
            is_called: !isNil(entry.is_called) ? parseInt(entry.is_called) : 0,
            rejected_at: 1
          }
        };

        APICaller(apiOpts)
          .then((r) => {
            notifSuccess({
              title: "Success!",
              message: "Translator flagged as rejected the booking."
            });

            if (
              !isNil(r.data.data)
              && !isNil(r.data.data.batch_entry)
              && !isNil(r.data.data.batch_entry.rejected_at)
            ) {
              entry.rejected_at = r.data.data.batch_entry.rejected_at;
            }
          })
          .catch((e) => {
            notifError({message: e});
          })
          .finally(() => {
            this.setBatchesLoading(false);
          });
      },


      /**
       * @param {Object} entry - Batch entry
       * @returns {void}
       */
      handleClickNotPickUpCall (entry) {
        this.setBatchesLoading(true);

        let called = !entry.is_called ? 1 : 0;
        let authUserID = this.authUser.id;

        APICaller({
          method: "POST",
          endpoint: `${API.BATCHES}/${entry.id}/update`,
          data: {
            is_called: called,
            last_called_by: authUserID
          }
        })
          .then((r) => {
            notifSuccess({
              title: "Success!",
              message: "Translator flagged as not picked up the call."
            });

            if (
              !isNil(r.data.data)
              && !isNil(r.data.data.batch_entry)
              && !isNil(r.data.data.batch_entry.last_called_at)
            ) {
              entry.is_called = called;
              entry.last_called_at = r.data.data.batch_entry.last_called_at;
            }

          })
          .catch((e) => {
            notifError({message: e});
          })
          .finally(() => {
            this.setBatchesLoading(false);
          });
      },

      /**
       * @param {Object} entry - Batch Entry
       * @returns {void}
       */
      handleClickShowCall (entry) {
        this.callModal.mobile = entry.translator.mobile;
        this.callModal.translator = entry.translator;
        this.isShowCallModal = true;
      },

      /**
       * @param {Object} entry - Batch Entry
       * @returns {void}
       */
      handleClickSmsHelpDesk (entry) {
        this.setBatchesLoading(true);

        const payload = {
          to_recipient_number: entry.translator.mobile,
          to_recipient: entry.translator.id
        };

        this.createSMS(payload)
            .then((r) => {
              this.$router.push({
                name: 'sms.centre',
                params: {smsConversation: r, message: generateMessage(this.booking)}
              });
            })
            .finally(() => {
              this.setBatchesLoading(false);
            });
      },

      /**
       * @param {Object} entry - Batch Entry
       * @returns {void}
       */
      handleClickAssign (entry) {
        if (isNil(entry.translator.id)) throw new Error('Missing required value entry.translator.id');

        this.$confirm(this.$t('txt_confirm_assign_translator'), this.$t('warning'), {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
          type: 'warning'
        })
            .then(() => {
              if (this.isBookingCurrentlyAssigned) {
                this.verifySetBookingToLateWithdraw()
                    .finally(() => {
                      this.assignTranslatorToBooking({translator_id: entry.translator.id});
                    });

              } else {
                this.assignTranslatorToBooking({translator_id: entry.translator.id});
              }
            })
            .catch((e) => console.error(e));
      },

      /**
       * @returns {Promise}
       */
      verifySetBookingToLateWithdraw () {
        // noinspection JSUnusedLocalSymbols
        return new Promise((resolve, reject) => {
          this.$confirm(this.$t('txt_set_previous_translator_as_withdraw'), this.$t('warning'), {
            confirmButtonText: 'YES',
            cancelButtonText: 'NO',
            type: 'warning'
          })
              .then(() => {
                this.withdrawTranslatorFromBooking(true)
                    .then(() => resolve())
                    .catch((e) => console.error(e));
              })
              .catch((e) => {
                if (isObject(e)) console.error(e);
                this.withdrawTranslatorFromBooking()
                  // Use resolve just to suppress catch() handling from outside this method.
                    .then(() => resolve())
                    .catch((e) => console.error(e));
              });
        });
      },

      /**
       * @param {boolean} [isLate]
       * @returns {Promise}
       */
      withdrawTranslatorFromBooking (isLate = false) {
        if (isNil(this.booking.id)) throw new Error('Missing required value this.booking.id');

        let payload = {booking_id: this.booking.id};
        if (isLate) payload.data = {late_withdraw: true};

        return new Promise((resolve, reject) => {
          this.$store.dispatch('booking/withdrawTranslator', payload)
              .then((r) => {
                this.$store.dispatch('booking/getBooking', this.booking.id);
                this.isBookingCurrentlyAssigned = false;
                resolve(r);
              })
              .catch((e) => {
                reject(e);
              });
        });
      },

      /**
       *
       * @param {Object} options
       * @param {boolean} [options.is_forced=false]
       * @param {int} options.translator_id
       * @returns {void}
       */
      assignTranslatorToBooking (options = {}) {
        if (isNil(this.booking.id)) throw new Error('Missing required value this.booking.id');
        if (isNil(options.translator_id)) throw new Error('Missing required value options.translator_id');
        options.is_forced = !isNil(options.is_forced) ? options.is_forced : false;

        let payload = {
          id: this.booking.id,
          translator_id: options.translator_id
        };

        if (options.is_forced) payload.force_assign = true;

        this.$store.dispatch('booking/assignTranslator', payload)
            .then(() => {
              this.$store.dispatch('booking/getBooking', this.booking.id);
              this.isBookingCurrentlyAssigned = true;
            })
            .catch((e) => {
              const errorData = e.response.data.data;
              if (
                isObject(errorData)
                && has(errorData, 'reasons')
                && !isNil(errorData)
                && !isEmpty(errorData)
              ) {
                this.verifyForceAssignTranslatorToBooking(options.translator_id, errorData.reasons);

              } else if (
                isString(errorData)
                && (
                  errorData === 'Du har redan en tolkning den tiden. Tolkning ej accepterad.'
                  || errorData === 'Tolkningen har accepterats av annan tolk.'
                )
              ) {
                this.verifyForceAssignTranslatorToBooking(options.translator_id, errorData);
              }
            });
      },

      /**
       * @param {int} translatorId
       * @param {string|array} reasons
       * @returns {void}
       */
      verifyForceAssignTranslatorToBooking (translatorId, reasons) {
        let message = this.$t('problem_assign_translator_text') + '\n\n';

        if (isArray(reasons)) {
          for (let i = 0; i < reasons.length; i++) {
            message += i + 1 + '. ' + reasons[i] + '\n';
          }

        } else if (isString(reasons)) {
          message += '1. ' + reasons + '\n';
        }

        message += '\n' + this.$t('force_assign_translator_text');

        this.$confirm(message, this.$t('warning'), {
          confirmButtonText: 'YES',
          cancelButtonText: 'NO',
          type: 'warning',
          customClass: 'force-assign-select-translator-to-booking-modal'
        })
            .then(() => {
              this.assignTranslatorToBooking({
                is_forced: true,
                translator_id: translatorId
              });
            })
            .catch((e) => console.error(e));
      },

      /**
       * Handler for resending notification to all translators.
       *
       * @return {void}
       */
      handleResendNotifToAll () {
        this.showResendNotifModal = false;
        let id = this.$route.params.id;
        this.resendNotif({
          id,
          channels: this.notificationChannels
        }).then(() => {
          this.$notify({
            type: 'success',
            message: 'Notifications re-sent.'
          });
        });
      },

      /**
       * Handler for resending notification to selected translators.
       */
      handleResendNotifToSome () {
        // Gather the selected batches.
        this.showResendNotifModal = false;
        let selectedBatches = filter(this.batchesList, (v) => {
          return includes(this.form.selected, v.id);
        });

        let translators = []; // Prepare container.

        // From each selected batches > entries, collect the translators within it.
        forEach(selectedBatches, (batch) => {
          let collected = uniq(map(batch.entries, 'translator_id'));
          translators = translators.concat(collected);
        });

        let id = this.$route.params.id;
        this.resendNotif({
          id,
          translators,
          channels: this.notificationChannels
        }).then(() => {
          this.$notify({
            type: 'success',
            message: 'Notifications re-sent.'
          });
        });
      },

      /**
       * Method for resending notification to specific translator button was clicked.
       *
       * @param {object} entry - Batch Entry with properties.
       * @return {void}
       */
      handleResendNotifToTranslator (entry) {
        let bookingId = this.$route.params.id;
        const translatorId = entry.translator.id;
        const translatorName = entry.translator.name;
        const channels = this.notificationChannels;

        this.resendNotif({
          id: bookingId,
          translators: [translatorId],
          channels
        }).then(() => {
          this.$notify({
            type: 'success',
            message: `Notification re-sent for ${translatorName} (${translatorId}).`
          });
        }).catch(() => {
          this.$notify({
            type: 'error',
            message: `Faield resending notification for ${translatorName} (${translatorId}).`
          });
        });
      },

      /**
       * Method to check the booking entry if it is assigned to a translator
       * already. If booking is assigned, reflect to the UI on which batch
       * the assigned translator belongs to.
       *
       * @return {void}
       */
      checkBookingIfAssignedAlready () {

        this.getBooking({id: this.$route.params.id}).then(() => {
          if (!isNil(this.booking.assigned_translator)) {
            console.log('Booking is currently assigned to :');
            console.log(this.booking.assigned_translator.translator_id);

            const current = this.booking.assigned_translator.translator_id;
            this.form.current = current;
            this.assignedCurrent = current;
            this.isBookingCurrentlyAssigned = true;
          }
        });
      },

      /**
       * Method for Resending Notification (information purpose) before
       * the actual resend action.
       *
       * @param v
       * @return {void}
       */
      handleResendNotifCheck (v) {
        this.isNotifToAll = v === 2;
        const selectedBatchIds = this.form.selected;

        const selectedBatches = filter(this.batchesList, (batch) => {
          return includes(selectedBatchIds, batch.id);
        });

        this.countBatch = 0;
        forEach(selectedBatches, (selectedBatch) => {
          this.countBatch = this.countBatch + selectedBatch.entries.length;
        });

        this.notificationFills = [];

        if (this.isNotifToAll) {
          this.notiflistall(this.bookingId).then((res) => {
            if (!!res.data.data.batch_notification_message_by_bookings) {
              this.showResendNotifModal = true;
            }

            res.data.data.batch_notification_message_by_bookings.map(item => {
              item["date"] = window.moment(item.created_at).format("DD-MM-YYYY");
              item["time"] = window.moment(item.created_at).format("h:mm:ss a");
              item["message"] = item.message;
              this.notificationFills.push(item);
              this.showResendNotifModal = true;
            });
          });
        } else {
          this.notiflistall(this.bookingId).then((res) => {
            if (!!res.data.data.batch_notification_message_by_bookings) {
              this.showResendNotifModal = true;
            }
            res.data.data.batch_notification_message_by_bookings.map(item => {
              item["date"] = window.moment(item.created_at).format("DD-MM-YYYY");
              item["time"] = window.moment(item.created_at).format("h:mm:ss a");
              item["message"] = item.message;
              this.notificationFills.push(item);
              this.showResendNotifModal = true;
            });
          });
        }

      },

      /**
       * Method to be used to load the initial data for the batches.
       *
       * @param {Object} [params]
       * @return {void}
       */
      initialLoadBatches (params = {}) {
        const id = this.bookingId;
        const mode = 'set';

        if (isNil(params.page)) {
          params.page = 1;
        }

        this.includeFilterValuesToParams(params);
        this.includeSortValuesToParams(params);

        this.loadBatchesForward({id, mode, params}).then(() => {
          this.checkBookingIfAssignedAlready();

          let translatorIds = [];
          for (let b of this.batchesList) {
            for (let e of b.entries) {
              translatorIds.push(e.translator.id);
            }
          }

          this.fetchFeedbacks({
            'filter[review_to_id]': translatorIds.join(','),
            default: true
          });
        });
      },

      /**
       * Method to be used along with the infinite scroll listener to load batches data.
       *
       * @return {void}
       */
      appendLoadBatches () {
        if (!this.isBatchesEndOfData) {
          const id = this.bookingId;
          const mode = 'append';
          let params = {};

          this.includeFilterValuesToParams(params);
          this.includeSortValuesToParams(params);

          this.loadBatchesForward({id, mode, params}).then(() => {
            this.checkBookingIfAssignedAlready();
          });
        }
      },

      /**
       * Method to attach the scroll listener to the DOM
       */
      applyScrollListener () {
        window.onscroll = () => {
          let bottomOfWindow = Math.round(document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;

          if (
            bottomOfWindow
            && this.windowLastHeight !== document.documentElement.offsetHeight
          ) {
            if (this.batchesCurrentPage > 1) {
              this.appendLoadBatches();
              this.windowLastHeight = document.documentElement.offsetHeight;
            }
          }
        };
      },

      /**
       * Method for handling the search/filter feature.
       *
       * @returns {void}
       */
      handleFilter () {
        updateQueryParams(this, this.filter.query, 'filter_query');
        updateQueryParams(this, this.filter.action.join(','), 'filter_action');
        this.initialLoadBatches();
      },

      /**
       * @returns {void}
       */
      handleResetFilter () {
        this.filter = clone(filterFresh);
        removeQueryParams(this, ['filter_query', 'filter_action']);
        this.initialLoadBatches();
      },

      /**
       * @returns {void}
       */
      setFilterValues () {
        this.filter.query = getQueryParam(this, 'filter_query', filterFresh.query);
        this.filter.action = getQueryParam(this, 'filter_action', filterFresh.action, (v) => v.split(','));
      },

      /**
       * @param {Object} params - Object to be mutated.
       * @returns {void}
       */
      includeFilterValuesToParams (params) {
        if (
          !isNil(this.filter.query)
          && this.filter.query !== ''
          && !isNil(this.filter.by)
        ) {

          if (isValidEmail(this.filter.query)) {
            this.filter.by = 'translator.email';
          } else {
            this.filter.by = 'translator.name';
          }

          params.filter_query = this.filter.query;
          params.filter_by = this.filter.by;
        }

        if (this.filter.action.length !== filterFresh.action.length) {
          params.filter_action = this.filter.action.join(',');
        }
      },

      /**
       * Method for handling the search/filter feature.
       *
       * @returns {void}
       */
      handleSort () {
        if (
          !isNil(this.sort.by) && this.sort.by !== ''
          && !isNil(this.sort.order) && this.sort.order !== ''
        ) {
          updateQueryParams(this, this.sort.by, 'sort_by');
          updateQueryParams(this, this.sort.order, 'sort_order');

        } else {
          removeQueryParams(this, ['sort_by', 'sort_order']);
        }

        this.initialLoadBatches();
      },

      /**
       * @returns {void}
       */
      handleResetSort () {
        this.sort = clone(sortFresh);
        removeQueryParams(this, ['sort_by', 'sort_order']);
        this.initialLoadBatches();
      },

      /**
       * @returns {void}
       */
      setSortValues () {
        this.sort.by = getQueryParam(this, 'sort_by', sortFresh.by);
        this.sort.order = getQueryParam(this, 'sort_order', sortFresh.order);
      },

      /**
       * @param {Object} params - Object to be mutated.
       * @returns {void}
       */
      includeSortValuesToParams (params) {
        if (
          !isNil(this.sort.by) && this.sort.by !== ''
          && !isNil(this.sort.order) && this.sort.order !== ''
        ) {
          params.sort_by = this.sort.by;
          params.sort_order = this.sort.order;
        }
      },

      updateBatchEvent () {
        window.WS.subscribe('booking.' + this.$route.params.id).listen(
          'batches.status.updated',
          this.updateBatchListener
        );
      },

      updateBatchListener (data) {
        this.$store.commit('bookingBatches/setBatchesStatus', data.calculation.status);
        if (data.calculation.status === 'completed') {
          this.initialLoadBatches();
          this.getBooking({id: this.$route.params.id});
        }
      },

      /**
       * @param {string} status
       * @returns {int}
       */
      getBatchesStatus (status) {
        if (status !== '' && !isEmpty(this.batchesStatusList)) {
          const found = find(this.batchesStatusList, (x) => x.code === status);
          return found ? found.id : 0;

        } else {
          return 0;
        }
      },

      gender (e) {
        if ((!isNil(e.translator)) && (!isNil(e.translator.gender))) {
          return e.translator.gender.replace(/^\w/, c => c.toUpperCase());
        }
        return 'Not Set';
      },

      /**
       * @returns {void}
       */
      handleClickShowSelectedInScheduleView () {
        if (isNil(this.form.selected) || isEmpty(this.form.selected)) return;

        const selectedBatches = filter(this.batchesList, (batch) => {
          return includes(this.form.selected, batch.id);
        });

        this.$refs['booking-availability-scheduler-modal'].open({
          booking: this.booking,
          batches: selectedBatches
        });
      },

      /**
       * @returns {void}
       */
      handleClickShowNonSendablesInScheduleView () {
        const payload = {
          id: this.bookingId,
          params: {
            all: true,
            'filter[sendable]': 0
          }
        };

        this.loadBatchesScheduler(payload)
            .then(() => {
              this.$refs['booking-availability-scheduler-modal'].open({
                booking: this.booking,
                batches: this.batchesSchedulerList
              });
            });
      }

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      this.setFilterValues();
      this.setSortValues();
      this.initialLoadBatches();
      this.applyScrollListener();

      let params = {
        "filter[type]": 1,
        all: true
      };

      this.browseAdmin({extendParams: params});
      this.updateBatchEvent();
      this.getBatchStatus({id: this.$route.params.id});
      this.getBooking({id: this.$route.params.id}).then(() => {
        if (this.booking.is_immediate) {
          this.$store.commit('bookingBatches/setBatchesStatus', 'completed');
        }
      });

    }, // End of Component > mounted

    /*
    |--------------------------------------------------------------------------
    | Component > beforeDestroy
    |--------------------------------------------------------------------------
    */
    beforeDestroy () {
      if (window.WS) {
        window.WS.unsubscribe('booking.' + this.$route.params.id);
      }
    }

  }; // End of export default
</script>
