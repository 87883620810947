<template>
  <el-form>
    <el-row :gutter="20">
      <ElCol :span="16" style="border-right: solid 1px">
        <h3>{{$t('filters')}}</h3>
        <div class="app-splitter two-cols">
          <div class="col-3" style="display:flex; flex-direction: row;">
            <el-checkbox
              @change="handleSendAll(3)"
              v-model="form.send_to_translator"
              :label="$t('send_all_translators')"
            ></el-checkbox>
            <el-checkbox
              :label="$t('send_all_customers')"
              @change="handleSendAll(2)"
              v-model="form.send_to_customer"
            ></el-checkbox>
          </div>
        </div>
        <br />

        <div class="app-splitter four-cols">
          <el-form-item :label="$t('translator_name')" class="label-block">
            <el-select
              v-model="form.translator_name"
              filterable
              :placeholder="$t('translator_name')"
              remote
              ref="customer"
              :remote-method="searchTranslator"
              :loading="searchCustomerLoading"
              clearable
              multiple
              collapse-tags
            >
              <el-option v-for="i in searchResult" :key="i.id" :label="i.name" :value="i.name"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            :label="$t('language')"
            :id="genContainerId('search_language')"
            class="label-block"
          >
            <el-select
              v-model="form.language_id"
              :placeholder="$t('language')"
              ref="language"
              filterable
              collapse-tags
            >
              <el-option :label="$t('all')" value="all"></el-option>
              <el-option
                v-for="language in languages"
                :key="language.id"
                :label="language.name"
                :value="language.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('state')" class="label-block">
            <el-select
              :id="genFieldId('company_selector')"
              :placeholder="$t('towns')"
              v-model="form.search_town"
              filterable
            >
              <el-option v-for="(v) in townOpts" :key="v.id" :value="v.id" :label="v.name" />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('translator_levels')" class="label-block">
            <el-select v-model="form.translator_level" :id="genFieldId('company_selector')" :placeholder="$t('translator_levels')">
              <el-option
                v-for="(v) in translatorLevelOpts"
                :key="v.id"
                :value="v.id"
                :label="v.name"
              />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('municipality')" class="label-block">
            <el-select
              :id="genFieldId('municipality_id')"
              filterable
              v-model="form.municipality_id"
              :placeholder="$t('municipality')"
            >
              <el-option
                v-for="o in municipalityOpts"
                :key="o.key"
                :value="o.key"
                :label="o.label"
              />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('company')" class="label-block">
            <el-select
              :id="genFieldId('company_id')"
              v-model="form.company_id"
              clearable
              filterable
              :placeholder="$t('company')"
            >
              <el-option v-for="o in companyOpts" :key="o.key" :value="o.key" :label="o.label" />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('department')" class="label-block">
            <el-select v-model="form.department_id" filterable :placeholder="$t('department')">
              <el-option v-for="o in departmentOpts" :key="o.key" :value="o.key" :label="o.label" />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('customer_name')" class="label-block">
            <el-select
              v-model="form.customer_name"
              filterable
              :placeholder="$t('customer_name')"
              remote
              ref="customer"
              :remote-method="searchCustomer"
              :loading="searchCustomerLoading"
              clearable
              multiple
              collapse-tags
            >
              <el-option v-for="i in searchResult" :key="i.id" :label="i.name" :value="i.name"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-button style="float:right" @click="resetFilter" type="primary">{{$t('reset')}}</el-button>
        <el-button style="float:right" @click="handleApply" type="primary">{{$t('filter')}}</el-button>
        <el-table
          ref="multipleTable"
          :data="listData"
          height="280"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="id" :label="$t('id')" width="280"></el-table-column>
          <el-table-column prop="name" :label="$t('name')" width="300"></el-table-column>
        </el-table>

        <div class="text-center">
          <el-pagination
            v-if="listPagination.total > listPagination.per_page"
            :page-size="listPagination.per_page"
            :total="listPagination.total"
            :current-page="listPagination.current_page"
            @current-change="handleApply"
            small
            layout="prev, pager, next"
          ></el-pagination>
        </div>

        <el-row style="margin-top:20px">
          <el-col :md="8">
            <el-form-item :label="$t('send_to_custom_recipients')" class="label-block">
              <el-input
                style="width:320px"
                v-model="form.custom_recipient"
                :placeholder="$t('type_recipient')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="16">
            <el-button
              type="primary"
              style="float:right; margin-top:20px"
              @click="handleAddRecipient()"
            >{{$t('select')}}</el-button>
          </el-col>
        </el-row>
      </ElCol>
      <ElCol :span="8">
        <div class="app-splitter two-cols">
          <h3>{{$t('recipient')}}</h3>
          <h3>{{$t('total')}}: {{recipient.length}}</h3>
        </div>
        <hr />
        <template v-if="viewAll">
          <div class="recipient-list">
            <div v-for="(item, index) in iValue" class="app-splitter two-cols" v-bind:key="index">
              <p>{{validateRecipient(item)}}</p>

              <el-button
                style="min-width:20px"
                @click="handleDeleteRecipient(index)"
                icon="el-icon-delete"
                circle
              ></el-button>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-for="(item, index) in top9" class="app-splitter two-cols" v-bind:key="index">
            <p>{{validateRecipient(item)}}</p>

            <el-button
              style="min-width:20px"
              @click="handleDeleteRecipient(index)"
              icon="el-icon-delete"
              circle
            ></el-button>
          </div>
          <el-button v-if="rest > 0" @click="viewAll=true">+{{rest}} More</el-button>
        </template>
      </ElCol>
    </el-row>
  </el-form>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { isEmpty } from '~/js/helpers/Common';
import { populate } from '~/js/helpers/Vuex';
export default {
  props: {
    recipient: {
      Type: Array,
      default: false
    },
    sent_via: {
      Type: Number,
      default: ''
    }
  },
  data() {
    return {
      form: {},
      multipleSelection: [],
      searchResult: [],
      searchCustomerLoading: false,
      viewAll: false
    };
  },
  computed: {
    /**
     * Computed property to list only top9 values in recipients list
     *
     */
    top9() {
      return this.iValue.slice(0, 9);
    },
    /**
     * Rest of the values of recipients
     */
    rest() {
      return this.iValue.length - 9;
    },

    /**
     * Interface for the v-model of this component.
     */
    iValue: {
      get() {
        return this.recipient;
      },
      set(v) {
        this.$emit('recipient', v);
      }
    },

    ...mapGetters('town', {
      townOpts: 'listAsOptions'
    }),
    ...mapGetters('translatorLevel', {
      translatorLevelOpts: 'listAsOptions'
    }),
    ...mapGetters({
      translators: 'booking/getTranslatorsList'
    }),
    ...mapGetters({
      languages: 'language/getLanguages'
    }),
    ...mapGetters('municipality', {
      municipalityOpts: 'listAsOptionsPaired'
    }),
    ...mapGetters('company', {
      companyOpts: 'listAsOptionsPaired'
    }),
    ...mapGetters('department', {
      departmentOpts: 'listAsOptionsPaired'
    }),
    ...mapGetters('user', {
      listData: 'listData',
      listPagination: 'listPagination'
    })
  },
  methods: {
    ...mapActions({
      filterCustomers: 'booking/filterCustomers',
      filterTranslators: 'booking/filterTranslators'
    }),
    ...mapActions('town', {
      loadTowns: 'browseAsOptions'
    }),
    loadData(params) {
      populate(this, 'findUser', '', {
        params
      });
    },
    ...mapActions('user', {
      findUser: 'browseRecipient'
    }),
    ...mapActions('language', {
      getLanguages: 'fetchLanguages'
    }),
    ...mapActions('municipality', {
      loadMunicipalityOpts: 'browseAsOptions'
    }),
    ...mapActions('company', {
      loadCompanyOpts: 'browseAsOptions'
    }),
    ...mapActions('department', {
      loadDepartmentOpts: 'browseAsOptions'
    }),
    ...mapActions('translatorLevel', {
      loadTranslatorLevels: 'browseAsOptions'
    }),
    resetFilter() {
      this.form = {};
    },
    handleSendAll(val) {
      if (this.form.send_to_customer == true) {
        if (!_.includes(this.iValue, 'send to all customer')) {
          this.iValue.push('send to all customer');
        }
      } else if (this.form.send_to_translator == true) {
        if (!_.includes(this.iValue, 'send to all translator')) {
          this.iValue.push('send to all translator');
        }
      }

      // this.loadData(param);
    },
    handleSelect() {
      this.selected == 'customer'
        ? (this.showSelect = 'customer')
        : this.selected == 'translator'
        ? (this.showSelect = 'translator')
        : null;
    },
    handleSelectionChange(row) {
      this.multipleSelection = row;
    },
    handleClose: function() {
      this.$emit('update:visible', false);
    }, // ,
    handleAddRecipient() {
      // console.log(this.iValue.recipient, ...this.multipleSelection)
      // this.iValue.recipient = [...this.multipleSelection, ...this.iValue.recipient];s
      if (this.sent_via == 3 || this.sent_via == 2) {
        this.multipleSelection.map(r => {
          if (!_.includes(this.iValue, r.email)) {
            this.iValue.push(r.email);
          }
        });
      }
      if (this.sent_via == 1) {
        this.multipleSelection.map(r => {
          if (!_.includes(this.iValue, r.mobile)) {
            this.iValue.push(r.mobile);
          }
        });
      }
      if (this.form.custom_recipient) {
        if (!_.includes(this.iValue, this.form.custom_recipient)) {
          this.iValue.push(this.form.custom_recipient);
        }
      }
    },
    validateRecipient(item) {
      if (_.isObject(item)) {
        if (this.sent_via == 2 && this.sent_via == 3) {
          return item.email;
        }
        if (this.sent_via == 1) {
          item.mobile;
        }
      }
      return item;
    },
    handleDeleteRecipient(index) {
      this.recipient.splice(index, 1);
    },
    handleSaveButton() {
      this.$emit('clicked', this.recipient);
    },
    populateOpts() {
      populate(this, 'getLanguages', 'languages', {
        ref: 'Languages Options'
      });
      populate(this, 'loadTowns', 'townOpts', {
        ref: 'Towns Listing'
      });
      populate(this, 'loadMunicipalityOpts', 'municipalityOpts', {
        ref: 'Municipality Options'
      });
      populate(this, 'loadCompanyOpts', 'companyOpts', {
        ref: 'Company Options'
      });
      populate(this, 'loadDepartmentOpts', 'departmentOpts', {
        ref: 'Department Options'
      });
      populate(this, 'loadTranslatorLevels', 'translatorLevelOpts', {
        ref: 'Translator Level Listing'
      });
    },
    handleApply(val) {

      let param = {
        'filter[company]':
          this.form.company_id == null ? '' : this.form.company_id,
        'filter[municipality]':
          this.form.municipality_id == null ? '' : this.form.municipality_id,
        'filter[language]':
          this.form.language_id == null ? '' : this.form.language_id,
        'filter[town]':
          this.form.search_town == null ? '' : this.form.search_town,
        'filter[department]':
          this.form.department_id == null ? '' : this.form.department_id,
        'filter[translator_level]':
          this.form.translator_level == null ? '' : this.form.translator_level
      };
      if (
        !isEmpty(this.form.translator_name) &&
        isEmpty(this.form.customer_name)
      ) {
        param['filter[name]'] = this.form.translator_name;
        param['filter[type]'] = 3;
      }

      if (
        !isEmpty(this.form.customer_name) &&
        isEmpty(this.form.translator_name)
      ) {
        param['filter[name]'] = this.form.customer_name;
        param['filter[type]'] = 2;
      }
      param.per_page = 100;
      if (Number.isInteger(val)) {
        param.page = val;
      }
      this.loadData(param);
    },
    searchTranslator(query) {
      if (query !== '' && query.length > 2) {
        this.searchCustomerLoading = true;
        let params = {
          'filter[name]': query,
          all: true
        };

        setTimeout(() => {
          this.filterTranslators(params).then(response => {
            if (isEmpty(response)) {
              let params2 = {
                'filter[email]': query,
                all: true
              };
              this.filterTranslators(params2).then(response => {
                this.searchResult = response;
              });
            }

            this.searchResult = response;

            this.searchCustomerLoading = false;
          });
        }, 200);
      } else {
        // this.searchResult = [];
      }
    },

    searchCustomer(query) {
      if (query !== '' && query.length > 2) {
        this.searchCustomerLoading = true;
        let params = {
          'filter[name]': query,
          all: true
        };
        setTimeout(() => {
          this.filterCustomers(params).then(response => {
            if (isEmpty(response)) {
              let params2 = {
                'filter[email]': query,
                all: true
              };
              this.filterCustomers(params2).then(response => {
                this.searchResult = response;
              });
            }

            this.searchResult = response;

            this.searchCustomerLoading = false;
          });
        }, 200);
      } else {
        // this.searchResult = [];
      }
    }
  },
  mounted() {
    this.populateOpts();
    console.log(this.iValue);
  }
};
</script>

<style lang="scss" scoped>
.edit-modal-footer .el-button {
  position: absolute;
  right: 0;
  bottom: 0;
}
.el-checkbox .el-checkbox__inner {
  background-color: #4bc188 !important;
}
.recipient-list {
  height: 600px;
  max-height: 600px;
  overflow-y: scroll;
}
</style>
