<template>
    <div class="el-input google-maps-address-field">
        <input type="text" v-bind:id="id"
               ref="google_address_autocomplete"
               v-model="iValue"
               class="el-input__inner"
               :placeholder="placeholder"
               v-on:keyup.space="updateCoordinates"
               v-on:keyup.tab="updateCoordinates"/>
    </div>
</template>

<script>
    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            // Components v-model.
            value: {
                type: String,
                default: '',
            },

            // Placeholder label for the input field.
            placeholder: {
                type: String,
                default: 'Address'
            },

            // Options that could be passed to Google Maps Autocomplete API.
            autocompleteOpts: {
                type: Object,
                default() {
                    return {
                        // componentRestrictions: {country: 'se'}
                    };
                }
            },

            // Container where the value (based on locality) to be generated
            // after choosing an autocomplete suggestion.
            city: {
                type: String,
                default: '',
            },
            id: {
                default : 'google_address_autocomplete'
            },
            coordinate: {
                default: '',
            },
        },

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {
            iValue: {
                get() {
                    return this.value
                },
                set(v) {
                    this.$emit('input', v);
                },
            },
        },

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            /**
             * Helper method to get and return the preferred city value form
             * Google Maps Autocomplete result.
             *
             * @param {object} place
             *   The getPlace() for Autocomplete result upon choosing a suggestion.
             *   e.g. autocomplete.getPlace()
             *
             * @return {string}
             */
            getCityValue(place) {
                let result = '';

                // Iterate and get "locality" value,
                // if there are none, just get "postal_code" as a fallback.
                _.each(place.address_components, (v) => {
                    if (
                        _.includes(v.types, 'locality') ||
                        _.includes(v.types, 'postal_town')
                    ) {
                        result = v.short_name;
                        return false; // Break the loop
                    }
                });
                return result;
            },

            getCoordinate(place) {
                return place.geometry.location.lat()+","+place.geometry.location.lng();
            },
            updateCoordinates(){
              this.$emit('update:coordinate', '');
            }
        },

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
            // Define the target element and instantiate it with google autocomplete.
            let input = document.getElementById(this.id);
            let autocomplete = new google.maps.places.Autocomplete(input, this.autocompleteOpts);

            // Add listener when a selection has been chosen or changed.
            autocomplete.addListener('place_changed', () => {
                let result = autocomplete.getPlace().formatted_address;
                if(result==null) this.$emit('update:coordinate', ' ');
                let city = this.getCityValue(autocomplete.getPlace());
                let coordinate= this.getCoordinate(autocomplete.getPlace());
                this.$emit('input', result); // Update this component's value.
                this.$emit('update:city', city); // Update city prop value.
                this.$emit('update:coordinate', coordinate); // Update coordinate prop value.
            });

            // Reflect the previous value to the form as it's disappearing
            // when autocomplete is re-instantiated on mount.
            input.value = this.value;
        },
    };
</script>
