/*
|--------------------------------------------------------------------------
| Store > Translator Level > getters
|--------------------------------------------------------------------------
| Partial file for getters object related to the scope.
*/

'use strict';

export default {

  /**
   * Returns a formatted translator level opts.
   *
   * @param {object} state - the state of the store's scope.
   *
   * @return {array};
   */
  formattedTranslatorLevelOpts(state) {
    if (state.listAsOptions.length > 0) {
      const rank = {
        layman: 1,
        read_courses: 2,
        certified: 3,
        certified_in_health: 4,
        certified_in_law: 5,
      };

      let opts = _.cloneDeep(state.listAsOptions);
      _.remove(opts, (o) => o.code == 'deluxe'); // Remove Deluxe.
      return _.sortBy(opts, (o) => rank[o.code]); // And sort to desired.

    } else { return [];}
  },

}