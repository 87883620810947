<template>
    <div class="app-page booking-histories-audit-page">
        <page-header :title="`${$t('booking')} ${$t('activity_histories_new')}`" 
            :linkData="{
                text : bookingId,
                path : 'booking/' + bookingId + '/details',
                preText : '(#',
                postText : ')'
            }"
            :back-enabled="true"/>

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col full-width">
                        <booking-histories-audit-list></booking-histories-audit-list>
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page booking-histories-page -->
</template>

<script>
export default {
    data(){
        return {
            bookingID:0
        }
    },
    computed:{
        bookingId: {
            get(){
                return this.bookingID
            },
            set(v){
                this.bookingID = v
            }
        }
        },
    mounted() {
        this.bookingId = this.$route.params.id
    },

}
</script>
