<template>
  <div id="right-bottom-wrapper" :class="getBem(blockClass)">
    <el-popover
      placement="top" trigger="click" width="250" v-model="visible"
      :popper-class="getBem(blockClass, 'popover')"
    >

      <div>
            <el-button type="primary" size="mini" @click="search('expired')">{{$t('booking_expired_today')}}</el-button>
            <el-button type="primary" size="mini" @click="search('expire_month')">{{$t('booking_expired_month')}}</el-button>
            <el-button type="primary" size="mini" @click="search('created_today')">{{$t('booking_created_today')}}</el-button>
            <el-button type="primary" size="mini" @click="search('due_today')" >{{$t('booking_due_today')}}</el-button>
            <el-button type="primary" size="mini" @click="search('created_month')">{{$t('booking_created_month')}}</el-button>
            <el-button type="primary" size="mini" @click="search('due_month' ,month)">{{$t('booking_due_month')}}</el-button>
        </div>

        <el-button class="fab circle" slot="reference" type="primary" icon="el-icon-info"></el-button>
        </el-popover>

    </div>
</template>

<script>
import {populate} from "~/js/helpers/Vuex"
import {mapActions} from "vuex";

export default {
    data() {
        return {
          blockClass: 'floating-action-button',
            visible: false,
            date: moment().format('YYYY-MM-DD'),
            month:moment().format('YYYY-MM')
        }
    },
    methods: {
        ...mapActions({
                browse: 'booking/browse'
        }),

        search(key) {
            this.visible = false
            let params = {}
            switch (key) {
                case 'created_today':
                    Object.assign(params,{'filter[created_at]': this.date,'filter[is_test]': 0})
                    break;
                case 'created_month':
                    Object.assign(params,{'filter[created_at]': this.month,'filter[is_test]': 0})
                    break;
                case 'due_today':
                     Object.assign(params,{'filter[due]': this.date,'filter[status_id]': '1,2,3,4,6,7,10,12,13', 'filter[is_test]': 0})
                    break;
                case 'due_month':
                     Object.assign(params,{'filter[due]': this.month,'filter[status_id]': '1,2,3,4,6,7,10,12,13','filter[is_test]': 0})
                    break;
                case 'expired':
                     Object.assign(params,{'filter[expiry]': this.date,'filter[status_id]': '8','filter[is_test]': 0})
                    break;

                case 'expire_month':
                    Object.assign(params,{'filter[expiry]': this.month,'filter[status_id]': '8','filter[is_test]': 0})
                   break;
                default:
                    break;
            }
            this.$router.push({query: params})
            this.$emit('update',params)
            populate(this, 'browse', '', {
                force: true,
                params
            })

        },

        // formatDate(today){
        //     let date =

        //     return date;
        // }
    }
}
</script>


<style lang="scss">
  //noinspection CssUnknownTarget
  @import '~/scss/global/_variables.scss';

  /*noinspection CssUnusedSymbol*/
  .floating-action-button__popover.el-popover.el-popper {
    background-color: #fff;
    padding: 0;

    .el-popper[x-placement^=top] .popper__arrow::after {
      background-color: #fff;
    }

    button.el-button.el-button--primary {
      width: 100%;
      background-color: transparent;
      color: #606266;
      margin: 5px 0 5px;
      padding: 10px 15px;
      border: 0;
      border-radius: 0;

      &:hover {
        background-color: #f0f8f9;
        color: $app-primary-color;
        text-decoration: underline;
      }
    }
  }

   .fab {
       z-index: 999;
       position: absolute;
       right: 5px;
       bottom: 17px;
   }

   .fab:hover {
       box-shadow: 0 6px 14px 0 #666;
       transform: scale(1.05);
   }
</style>
