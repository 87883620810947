import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import auth from './store/auth';
import audits from './store/audits';
import form from './store/form';
import financialPriceList from './store/financial/settings/price-list';
import financialInvoice from './store/financial/settings/invoice';
import financialSalary from './store/financial/settings/salary';
import financialAdditionalInvoice from './store/financial/additional/invoice';
import financialAdditionalSalary from './store/financial/additional/salary';
import financialAdjustmentInvoice from './store/financial/adjustment/invoice';
import financialAdjustmentSalary from './store/financial/adjustment/salary';
import financialPreviewInvoice from './store/financial/preview/invoice';
import financialPreviewSalary from './store/financial/preview/salary';
import financialPriceTemplate from './store/financial/price-template';
import financialExport from './store/financial/export';
import financialPrefillInvoice from './store/financial/prefill/invoice';
import financialPrefillSalary from './store/financial/prefill/salary';
import financialCreditInvoice from './store/financial/credits/invoice';
import financialCreditSalary from './store/financial/credits/salary';
import financialOptions from './store/financial/options';
import user from './store/user';
import customer from './store/user/customer';
import translator from './store/user/translator';
import adminUser from './store/user/admin';
import booking from './store/booking';
import language from './store/language';
import municipality from './store/sectors/municipality';
import company from './store/sectors/company';
import department from './store/sectors/department';
import logs from './store/logs';
import customerType from './store/customer-type';
import translatorLevel from './store/translator-level';
import town from './store/town';
import batchesSettings from './store/settings/batches-settings';
import bookingBatches from './store/booking/batches';
import dashboard from './store/dashboard';
import feedback from './store/feedback';
import textTranslation from './store/text-translation';
import blacklistedUsers from './store/blacklisted-users'
import blogs from './store/messaging-centre/blogs';
import notificationCentre from './store/messaging-centre/notification';
import ui from './store/ui';
import bookingIssue from './store/booking/issues'
import bookingIssueSetting from './store/booking/issues/settings'
import bookingIssueTag from './store/booking/issues/tags'
import bookingIssueTaggedIssue from './store/booking/issues/tagged-issues'
import todo from './store/todo'
import surveyManager from './store/survey-manager';
import kpiSettings from './store/settings/kpi-settings';
import invoiceReminderSettings from './store/invoice-reminder-settings';
import userAccessLogs from './store/user-access-logs';
import trainings from './store/trainings';
import trainingCourseCode from './store/training-course-code';
import resources from './store/booking/resources';
import resourceTypes from './store/booking/resource-types';
import faq from './store/faq';
import translatorUnavailableTimes from './store/translator-unavailable-times';
import adminPermissionsRoles from './store/admin-permissions-roles';
import adminPermissions from './store/admin-permissions';
import holidays from './store/holidays';
import expenseTypes from './store/expense-types';
import emailsTemplates from './store/emails-templates';
import testLanguages from './store/settings/test-languages';
import maintenanceMode from './store/settings/maintenance-mode';
import viewport from './store/viewport';
import sidebar from './store/sidebar';
import plannedLanguages from './store/settings/planned-languages';
import partners from './store/settings/partners';
import smsCentre from './store/sms-centre'
import adminUiSettings from './store/settings/admin-ui-settings';
import verbiages from './store/verbiages';

export default new Vuex.Store({
  modules: {
    auth,
    audits,
    form,
    financialPriceList,
    financialInvoice,
    financialSalary,
    financialAdditionalInvoice,
    financialAdditionalSalary,
    financialAdjustmentInvoice,
    financialAdjustmentSalary,
    financialPreviewInvoice,
    financialPreviewSalary,
    financialPriceTemplate,
    financialExport,
    financialPrefillInvoice,
    financialPrefillSalary,
    user,
    customer,
    translator,
    adminUser,
    booking,
    language,
    municipality,
    company,
    department,
    logs,
    customerType,
    translatorLevel,
    town,
    batchesSettings,
    bookingBatches,
    dashboard,
    feedback,
    ui,
    textTranslation,
    blacklistedUsers,
    bookingIssue,
    bookingIssueSetting,
    bookingIssueTag,
    bookingIssueTaggedIssue,

    todo,
    blogs,
    notificationCentre,
    surveyManager,
    invoiceReminderSettings,
    kpiSettings,
    userAccessLogs,
    trainings,
    trainingCourseCode,
    resources,

    resourceTypes,
    faq,
    translatorUnavailableTimes,
    adminPermissionsRoles,
    adminPermissions,
    holidays,
    expenseTypes,
    maintenanceMode,
    testLanguages,
    emailsTemplates,

    sidebar,
    viewport,
    plannedLanguages,
    adminUiSettings,
    partners,
    smsCentre,
    verbiages,
    financialCreditInvoice,
    financialCreditSalary,
    financialOptions
  }
});
