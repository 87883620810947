<template>
  <div class="app-form app-fields user-credential-fields">

    <h3 class="text-center" style="margin-bottom: 35px;">{{$t('please_input_credentials')}}</h3>
    <el-alert v-if="hasError"
              style="margin-top: -25px; margin-bottom: 35px;"
              title="Please make sure passwords match and not empty."
              type="error"
              :closable="false"
              center show-icon>
    </el-alert>

    <!-- Current Password -->
    <el-form-item v-if="false && mode == 'edit'"
                  :id="genContainerId('current_password',formElId)"
                  :label="$t('current_password')" class="label-short">
      <el-input :id="genFieldId('current_password' ,formElId)" type="password"
                v-model="iValue.current_password" autocomplete="dontfill" :placeholder="$t('please_enter_password')"/>
    </el-form-item>

    <div class="app-splitter two-cols">
      <!-- Password -->
      <el-form-item :id="genContainerId('x_input_pwd',formElId)"
                    :label="$t('password')" class="label-short" :class="{ 'is-error' : hasError }">
        <el-input :id="genFieldId('x_input_pwd' ,formElId)" type="password"
                  v-model="iValue.password"
                  @change="validatePassword" autocomplete="dontfill"
                  :placeholder="$t('please_enter_password')"/>
      </el-form-item>

      <!-- Password Confirmation -->
      <el-form-item :id="genContainerId('x_input_pwd_confirmation',formElId)"
                    :label="$t('password_confirmation')" class="label-short" :class="{ 'is-error' : hasError }">
        <el-input :id="genFieldId('x_input_pwd_confirmation' ,formElId)" type="password"
                  v-model="iValue.password_confirmation"
                  @change="validatePassword"
                  autocomplete="dontfill"
                  :placeholder="$t('please_reenter_password')"/>
      </el-form-item>
    </div> <!-- /.app-splitter two-cols -->


  </div> <!-- /.app-form app-fields user-credential-fields -->
</template>

<script>
  import {isEmpty} from "~/js/helpers/Common";
  import {checkBreadMode} from "~/js/helpers/Route";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      formElId: {
        type: String,
        default: ''
      },

      value: {
        type: Object,
        default () {
          return {
            current_password: '',
            password: '',
            password_confirmation: ''
          };
        }
      },

      hasError: {
        type: Boolean,
        default: false
      }

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        }
      },

      /**
       * Interface for the this.hasError prop of this component.
       */
      iHasError: {
        get () {
          return this.hasError;
        },
        set (v) {
          this.$emit('update:hasError', v);
        }
      },

      /**
       * Current route mode.
       */
      mode () {
        return checkBreadMode(this);
      }

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      validatePassword () {

        this.iHasError = (isEmpty(this.iValue.password) || isEmpty(this.iValue.password_confirmation)) ||
          this.iValue.password != this.iValue.password_confirmation;

      } // End of validatePassword() method

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {

      // Set the iHasError intially.
      this.iHasError = isEmpty(this.iValue.password) || isEmpty(this.iValue.password_confirmation);

    } // End of Component > mounted

  }; // End of export default
</script>
