<template>
	<div class="app-list booking-mappings-list">
    <el-button type="success" @click="$router.push({name:'text-translations.create'})">{{$t('create')}}</el-button>
		<div class="app-row list-container">
			<div class="app-col col-container">
				<base-list-table
				:table-data="listData"
				:extra-cols="listCols"
				:hasDetailsCol="false">

					<!-- Column for action buttons -->
					<template slot="actionsColumn" slot-scope="scope">
						<router-link tag="button" :to="`/text-translations/review/${scope.row.id}`" @click.stop.prevent title="Review" class="el-button circle el-button--default">
                    <i class="fa fa-pencil"></i>
                </router-link>
					</template>
				</base-list-table>
				<el-pagination
                layout="prev, pager, next"
                :page-size="listPagination.per_page"
                :total="listPagination.total"
                :current-page="listPagination.current_page"
                @current-change="reloadList">
        </el-pagination>
			</div> <!-- /.app-col -->
		</div> <!-- /.app-row list-container -->

	</div> <!-- /.app-list booking-mappings-list -->
</template>

<script>

	import { mapGetters,mapState, mapActions } from 'vuex';
	import {populate,forcePopulate} from "~/js/helpers/Vuex";
	import {isEmpty,sd} from "../../../js/helpers/Common";

    import i18n from '~/js/i18n';
  export default {
		props: {
			vPopulatePayload:{
					type: Object,
					default(){
							return {
							'sort': '-created_at',
							'include':'customer,translator'
					}
					}
			}
		}, // End of Component > props
		data() {
			const _this = this;
			return {
				perPage: 50,
				search: '',
				listCols:[
					{
						label: _this.$t('id'),
						prop: 'id',
						width:'',
						className:'id-col'
					},
					{
						label: _this.$t('customer'),
						prop: 'customer.name',
						width:'',
						className:'name-col'
					},
					{
						label: _this.$t('from_language'),
						prop: 'from_language',
						width:'',
						className:'language-col',
						// mutator(v,row){
            //   if (!isEmpty(_this.languageOpts) && !isEmpty(v)) {
						// 		const found = _.find(_this.languageOpts, (x) => x.id == v)
            //     return (found) ? found.name:''
						// 	}
						// }
					},
					{
						label: _this.$t('to_language'),
						prop: 'to_language',
						width:'',
						className:'language-col',
						// mutator(v,row){
            //   if (!isEmpty(_this.languageOpts) && !isEmpty(v)) {
						// 		const found = _.find(_this.languageOpts, (x) => x.id == v)
            //     return (found) ? found.name:''
						// 	}
						// }
					},
					{
						label: _this.$t('due'),
						prop: 'due',
						width:'',
						className:'due-col'
					},
					{
						label: _this.$t('cost'),
						prop: 'cost',
						width:'',
						className:'cost-col'
					},
					// {
					// 	label: 'Words count',
					// 	prop: 'files',
					// 	width:'',
					// 	className:'cost-col',
					// 	mutator(v,row){
					// 		let file = v.find(i=>i.pivot.type=='review')
					// 		return (file) ? file.words_count :0
					// 	}
					// },
					{
						label: _this.$t('salary'),
						prop: 'salary',
						width:'',
						className:'salary-col'
					},
					{
						label: _this.$t('status'),
						prop: 'status_code',
						width:'',
						className:'status-col',
						mutator(v){
							return i18n.t(v)
						}
					},
					{
						label: _this.$t('created_at'),
						prop: 'created_at',
						width:'',
						className:'created_at-col'
					},
				]
			};
		},
		computed: {
			...mapGetters('textTranslation', {
				listData: 'listData',
				listPagination:'listPagination'
			}),
			populatePayload: {
					get(){
							return this.vPopulatePayload
					},set(v){
							this.$emit('input',v)
					}
			},
			...mapGetters('language', {
				languageOpts: 'getLanguages'
			}),
		},
		methods:{
			...mapActions('textTranslation', {
				browse:'browse'
				}),
			...mapActions('language', {
					loadLanguages: 'fetchLanguages'
			}),
			reloadList(n){

				let params=_.clone(this.$route.query);
				n = sd(n, sd(this.$route.query.page, 1)) // Set the page number.
        params.page = n;
        Object.assign(params,this.populatePayload)
        this.$router.push({query: params}) // Reflect the chosen parameters in the URL Bar
        populate(this, 'browse', '', {
						force: true,
						params
				})
			}
		},
		mounted() {
			let params =_.clone(this.$route.query)

			const opts = {
					action: 'browse',
					data: 'listData',
					ref: 'text translation Listing',
			};
			this.reloadList(null,opts,params)
			//  populate(this, 'loadLanguages', 'languageOpts', {
			// 		ref: 'Languages Options'
			// 	})
		}
	}
</script>

<style>

</style>
