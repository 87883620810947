let data = {
  id: '',
  customer_id: '',
  created_by: '',
  from_language_id: '',
  to_language_id: [],
  duration: '',
  is_immediate: false,
  cancelled_at: '',
  cancelled_by: '',
  gender: '',
  session_time: '',
  session_time_confirmed: '',
  booker_name: '',
  staff_name: '',
  instructions: '',
  address: '',
  due: '',
  flagged: false,
  type: '',
  is_test: false,
  is_sensitive_info: 0,
  confidential_message: '',
  is_protected_identity: 0,
  dialect_id: '',
  country_iso: '',
  status_id: '',
  expiry: '',
  expiry_time_partner: '',
  manually_handled: false,
  reference: '',
  ended_at: '',
  created_at: '',
  updated_at: '',
  fallback_type: '',
  allow_fallback_type: false,
  status: {
    id: '',
    code: '',
    name: ''
  },
  translator_levels: [],
  specific_translators: [],
  alternative_languages: '',
  excluded_translators: [],
  assigned_translator: {
    id: '',
    booking_id: '',
    translator_id: '',
    name: '',
    gender: '',
    email: '',
    mobile: '',
    dob: '',
    language_id: '',
    cancelled_at: '',
    cancelled_by: '',
    cancellation_code: '',
    created_at: '',
    updated_at: '',
    temp_travel_time_public: '',
    temp_travel_time_car: '',
    temp_travel_distance_public: '',
    temp_travel_distance_car: '',
    booking: {}
  },
  assigned_translator_id: '',
  notification_types: [],
  comments: [],
  locked_booking: [],
  video_booking: '',
  email_notifications: [],
  fax_notification: '',
  sms_notification: '',
  sms_notification_enabled: false,
  fax_notification_enabled: false,
  comment: '',
  customer: {
    id: '',
    name: '',
    email: '',
    customer_data: {
      allow_fallback_type: false
    }
  },
  convey: {
    id: '',
    booking_id: '',
    message: '',
    phone: '',
    files: [],
    additional_info: ''
  },
  is_mentor_job: false,
  stop_financial_export: false,
  text_translation: {
    descriptions: '',
    additional_text: '',
    is_stamped: 0,
    is_confidential: 0,
    deliver_with: '',
    files: []
  },

  messages: {
    id: '',
    booking_id: '',
    message: '',
    files: []
  },
  translator_already_travelled: false,
  is_teacher_job: false,
  is_evaluator_job: false
};
export default data;
