<template>
    <el-form :model="form" :id="formElId" :ref="formElId">

        <div class="app-form admin-permissions-roles-list-settings-form">
            <el-form-item :id="genContainerId('page_name', formElId)"
                            class="label-short"
                            label="Page Name">
                <!-- <el-input :id="genFieldId('page_name', formElId)"
                    v-model="form.page_name" /> -->

                <el-select v-model="form.page_name" disabled="">
                            <el-option v-for="(v, k) in this.getNavItems()"
                                    :key="k"
                                    :value="v.label"
                                    :label="v.label"/>
                </el-select>
            </el-form-item>

            <el-form-item :id="genContainerId('paths', formElId)"
                            class="label-short"
                            label="Role Group">
                <el-select v-model="form.role_id" disabled="">
                            <el-option v-for="(v, k) in roles"
                                    :key="k"
                                    :value="v.id"
                                    :label="v.label"/>
                </el-select>
            </el-form-item>

            <el-form-item :id="genContainerId('paths', formElId)"
                            class="label-short"
                            label="Paths">
                <el-select v-model="selected_paths" placeholder="Select Path(s)" multiple disabled>
                  <el-option v-for="(path, k) in paths" :key="k" :label="path" :value="path"></el-option>
                </el-select>
            </el-form-item>
            
            <!-- <div class="app-splitter two-cols"> -->
                <el-form-item :id="genContainerId('can_view', formElId)"
                            class="label-short"
                            label="View">
                    <el-switch :id="genFieldId('can_view', formElId)"
                    v-model="can_view"></el-switch>
                </el-form-item>
                <!-- <el-form-item :id="genContainerId('can_update', formElId)"
                            class="label-short"
                            label="Update">
                    <el-switch :id="genFieldId('can_update', formElId)"
                    v-model="can_update"></el-switch>
                </el-form-item> -->

                <el-form-item :id="genContainerId('save_btn',formElId)">
                    <el-button :id="genFieldId('save_btn',formElId)" type="primary" @click="handleSave">
                        {{mode}}
                    </el-button>
                </el-form-item>
            <!-- </div> -->
        </div>

    </el-form>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import {genFieldId, genContainerId} from "../../../js/helpers/Html";
    import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
    import { checkBreadMode } from "~/js/helpers/Route";
    import { populate } from '../../../js/helpers/Vuex';

    export default {

        computed: {

            ...mapState('adminPermissions', {
                form: 'compose',
                payloadData: 'payloadData',
                navData: 'navData'
            }),

            ...mapState('auth', {
                user: 'userData'
            }),

            ...mapGetters('adminPermissions', {
                entryData: 'itemData',
            }),

            ...mapGetters('adminPermissionsRoles', {
                roles: 'listData',
            }),

            breadMode() {
                return checkBreadMode(this);
            },

            mode() {
                return (this.breadMode == 'edit') ? 'Edit' : 'Save';
            }
        },

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                formElId: 'admin-permissions-form',
                paths: [],
                selected_paths: [],
                preloaded_paths: [],

                can_view: false,
                can_create: false,
                can_update: false
            };
        },// End of Component > data

        components: {
            Multiselect
        },

        methods: {

            ...mapActions({
                editEntry: 'adminPermissions/edit',
                readEntry: 'adminPermissions/read',
                getRoles: 'adminPermissionsRoles/browse',
                getUserData: 'auth/getUserData',
            }),

            ...mapMutations('adminPermissions', {
                resetForm: 'resetCompose',
            }),

            handleSave() {
                let payload = {};
                if (this.breadMode == 'edit') {
                    payload.id = this.$route.params.id;

                    let admin_id = this.$route.params.admin_id;

                    // Create sync object
                    this.form.sync[admin_id] = {
                        'can_view' : this.can_view,
                        'can_create' : this.can_create,
                        'can_update' : this.can_update,
                    };
                    
                    this.editEntry(payload).then((r) => {
                        this.getUserData();
                        this.readEntry({
                            id: this.$route.params.id,
                            admin_id: this.user.id
                        }).then(r => {
                            this.form.paths = [];
                            this.selected_paths = [];
                            this.preloaded_paths = r.data.data.admin_permission.paths;
                            this.preloaded_paths.forEach(p => {
                                this.selected_paths.push(p.path);
                            });
                            this.can_view = this.getCanView();
                            this.can_create = this.getCanCreate();
                            this.can_update = this.getCanUpdate();

                            this.$store.dispatch('adminPermissions/getNavItemsFinal');
                        });
                    });
                }
            },

            getCanView() {
                let admin = null;
                if ((admin = this.getAdmin()) != null) {
                    return (admin.pivot.can_view == 1) ? true : false;
                }
                return (this.form.can_view == 1) ? true : false;
            },

            getCanCreate() {
                let admin = null;
                if ((admin = this.getAdmin()) != null) {
                    return (admin.pivot.can_create == 1) ? true : false;
                }
                return (this.form.can_create == 1) ? true : false;
            },

            getCanUpdate() {
                let admin = null;
                if ((admin = this.getAdmin()) != null) {
                    return (admin.pivot.can_update == 1) ? true : false;
                }
                return (this.form.can_update == 1) ? true : false;
            },

            getAdmin() {
                let admin = null;
                let admin_id = this.$route.params.admin_id;
                for (let a of this.form.admins) {
                    if (a.id == admin_id) {
                        admin = a;
                        break;
                    }
                }

                return admin;
            },

            getNavItems() {
                let items = this.navData.navItems;
                return items.filter(nav => {
                    return !nav.path.includes('dashboard');
                });
            }
        },

        mounted() {
            this.resetForm();
            if (this.breadMode == 'edit') {
                this.readEntry({
                    id: this.$route.params.id,
                    admin_id: this.user.id
                }).then(r => {
                    this.form.paths = [];
                    this.preloaded_paths = r.data.data.admin_permission.paths;
                    this.preloaded_paths.forEach(p => {
                        this.selected_paths.push(p.path);
                    });
                    this.can_view = this.getCanView();
                    this.can_create = this.getCanCreate();
                    this.can_update = this.getCanUpdate();
                });
            }

            this.$router.options.routes.forEach(route => {
                this.paths.push(route.path);
            });
            this.paths.sort();

            populate(this, 'getRoles', 'roles', {
                force: true
            });
        }

    }// End of export default

</script>