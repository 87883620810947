/*
|--------------------------------------------------------------------------
| Store > Municipality > State
|--------------------------------------------------------------------------
|
| This file contains the state property of User Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {
    gep
} from "../../../helpers/Vuex";

let target = 'booking-resource-types';
let apiPrefix = window.app_api_prefix_3;

let composeProps = {
    id: 0,
    type: '',
};

export default {

    model_info: {
        name: 'Resource Type Store',
        module: 'resourceTypes',
        table: target,
    },

    api: {
        browse: {
            method: 'get',
            path: gep(target, apiPrefix),
            params: {
                per_page: 10
            },
            data: {},
        },
        read: {
            method: 'get',
            path: gep(target + '/{id}', apiPrefix),
            params: {},
            data: {},
        },
        edit: {
            method: 'put',
            path: gep(target + '/{id}', apiPrefix),
            params: {},
            data: {},
        },
        add: {
            method: 'post',
            path: gep(target, apiPrefix),
            params: {},
            data: {},
        },
        delete: {
            method: 'delete',
            path: gep(target + '/{id}', apiPrefix),
            params: {},
            data: {},
        },
    },

    composeFresh: _.cloneDeep(composeProps),
    compose: _.cloneDeep(composeProps),

    payloadData: {}, // End of payloadData state

    autoInjectPayload: true,
    autoReflectItem: true,
    autoUpdateList: true,
    
    is_show_on_booking: false

} // End of export default