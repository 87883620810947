export default {
  namespaced: true,

  state: {
    isFoldSidebar: false
  },

  getters: {
    isFoldSidebar: (state) => state.isFoldSidebar
  },

  mutations: {

    /**
     * @param {object} state
     * @param {boolean} bool
     * @returns {void}
     */
    setIsFoldSidebar (state, bool) {
      state.isFoldSidebar = bool;
    }
  }
};
