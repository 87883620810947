<template>
    <div class="app-page user-list-page">

        <page-header :title="$t('user_list')" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col full-width">
                        <div class="content-body">

                            <user-list-filter-form />

                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                    <div class="app-col full-width">
                        <div class="content-body">
                            <el-tabs v-model="activeTab">
                                <el-tab-pane :label="$t('user_list')" name="user_list">
                                    <user-list/>
                                </el-tab-pane>
                                <el-tab-pane :label="$t('deleted_users')" name="deleted_user_list">
                                    <deleted-user-list/>
                                </el-tab-pane>
                            </el-tabs>
                            

                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page user-list-page -->
</template>
<script>
export default {
    data() {
        return {
            activeTab: 'user_list'
        }
    }
}
</script>