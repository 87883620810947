<template>

  <div class="app-list booking-issue-list">

                <custom-table-two
                  :table-data="listData"
                  :extra-cols="listCols"
                  :hasDetailsCol="true"
                  :detailsColTitle="$t('assigned_admin')">

                    <!-- Row for Top Controls -->
                    <template slot="topControls">
                      <filters-panel @updateList="updateLists"></filters-panel>

                    </template>

                    <template slot="detailsColumn" slot-scope="scope">
                      <assign-admin-form :issue="scope.row"></assign-admin-form>
                    </template>

                    <!-- Column for action buttons -->
                    <template slot="actionsColumn" slot-scope="scope">
                        <el-button class="circle" type="success" :title="$t('set_as_solved')" icon="el-icon-success"
                          @click="handleSolvedButton(scope.row)">
                          <!-- <span class="fa fa-pencil"></span> -->
                        </el-button>
                        <el-button class="circle" type="warning" :title="$t('reopen')" icon="el-icon-info" @click="handleReopenButton(scope.row.id)"></el-button>
                        <el-button class="circle" type="primary" :title="$t('details')" icon="el-icon-info" @click="handleDetailsButton(scope.row.id)"></el-button>
                            <!-- <span class="fa fa-trash"></span> -->
                    </template>
                </custom-table-two>
                <comment-modal
                  :visible.sync="showAddComment"
                  :dontcountfeedback.sync="do_not_count_feedback"
                  :issue="issue"
                  @addComment="setAsSolved">
                </comment-modal>

            </div> <!-- /.app-col -->

</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import {isEmpty} from '~/js/helpers/Common'
import FiltersPanel from '~/components/lists/booking-issue/Filters'
import CommentModal from '~/components/pages/booking-issue/partials/CommentModal'
import AssignAdminForm from './AssignAdminForm'

export default {
  components:{
    FiltersPanel,
    CommentModal,
    AssignAdminForm
  },
  data(){
    const _this=this;
    return {
      type:'',
      issue:{},
      showAddComment: false,
      listCols:[
        {
          label: _this.$t('id'),
          prop: 'id',
          width: '',
          className: 'type-col',
        },
        {
          label: _this.$t('type'),
          prop: 'type.type_name',
          width: '',
          className: 'type-col',

        },
        {
          label: _this.$t('booking_id'),
          prop: 'booking_id',
          width: '',
          className: 'booking_id-col',
        },
        {
          label: _this.$t('customer'),
          prop: 'booking.customer.name',
          width: '',
          className: 'booking_id-col',
        },
        {
          label: _this.$t('translator'),
          prop: 'translator.name',
          width: '',
          className: 'booking_id-col',
        },
        {
          label: _this.$t('due_date'),
          prop: 'deadline_date',
          width: '',
          className: 'booking_id-col',
          mutator(v,row) {
            return row.entity==='App\\Modules\\Todos\\Todo' ?  row.entities.date: v
          }
        },
        {
          label: _this.$t('solved_at'),
          prop: 'solved_at',
          width: '',
          className: 'booking_id-col',
        }
      ],
      do_not_count_feedback: false,
    }
  },
  computed:{
    ...mapState('auth', {
      user: 'userData'
    }),
    ...mapGetters('bookingIssue',{listData:'listData'}),

    ...mapGetters('bookingIssueSetting',{settingList:'listData'}),
    ...mapGetters('todo', {
      todoList: 'listData'
    }),
  },
  methods:{
     ...mapActions('bookingIssue', {
      updateIssue: 'updateIssue',
      reopenIssue:'reopenIssue',
      postComment: 'postComment',
    }),
     ...mapActions('booking',{
      addBookingComment:'addComment'
    }),
    ...mapActions('todo',{
      getTodos:'browse'
    }),
    ...mapActions('feedback',{
      putFeedback:'putFeedback'
    }),
    isEmpty(v){
      return isEmpty(v);
    },
    handleDetailsButton(v){
      this.$router.push({name:'booking.issue.details',params:{id:v}})
    },
    updateLists(e){
      console.log(e),
      this.$emit('updateList',e)
    },
    async handleReopenButton(v){
      let params = {
        id: v
      }
      let issue = await this.reopenIssue(params).then(()=>{
        this.$emit('updateList',{})
      })
    },

     handleSolvedButton(issue) {
      this.issue = Object.assign(this.issue,issue)
      this.showAddComment = true
    },
    async setAsSolved(e) {
    let params = {
      booking_issue_id: this.issue.id,
      solved: true
    }
    let issue = await this.updateIssue(params)
    if (issue.status === 200) {
      let payload = {
        booking_issue_id: this.issue.id,
        comment: e.comment,
        created_by: this.user.id
      }
      this.postComment(payload)
      
      if (this.do_not_count_feedback) {
          this.putFeedback({
            id: this.issue.entity_id,
            is_rejected: true
          });
          this.postComment({
            booking_issue_id: this.issue.id,
            comment: "Admin has decided not to count this rating towards the average rating",
            created_by: this.user.id
          });
      }

      this.$emit('updateList', {})
    }
  },

  },
  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {
    this.getTodos();
  }, // End of Component > mounted
}
</script>

<style>

</style>
