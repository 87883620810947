<template>
  <div class="language-options-modal-container">
    <el-dialog title="Language options" :visible.sync="iVisible" :modal-append-to-body="false">
      <el-form :model="languageOptions">
        <el-form-item label="" class="label-medium">
          <el-row>
            <el-col :md="18">
              <el-checkbox
                label="Translator must be from"
                aria-label="Translator must be from"
              />
            </el-col>
            <el-col :md="6">
              <el-select v-model="languageOptions.translator_from" placeholder="Select Country">
                <el-option v-for="country in countries" :key="country.id" :value="country.id"
                           :label="country.name"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="" class="label-medium">
          <el-row>
            <el-col :md="18">
              <el-checkbox
                label="Translator must be able to communicate with a person from"
                aria-label="Translator must be able to communicate with a person from"
              />
            </el-col>
            <el-col :md="6">
              <el-select v-model="languageOptions.customer_from" placeholder="Select Country">
                <el-option v-for="country in countries" :key="country.id" :value="country.id"
                           :label="country.name"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="" class="label-medium">
          <el-row>
            <el-col :md="18">
              <el-checkbox
                label="Translator has a specific dialect from within"
                aria-label="Translator has a specific dialect from within"
                v-model="checkBoxes.dialects_from"
              />
            </el-col>
            <el-col :md="6">
              <el-select v-model="languageOptions.dialects_from" placeholder="Select Country">
                <el-option v-for="country in countries" :key="country.id" :value="country.id"
                           :label="country.name"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="" class="label-medium">
          <el-row>
            <el-col :md="18">
              <el-checkbox
                label="Select Dialects"
                aria-label="Select Dialects"
                :disabled="!checkBoxes.dialects_from"
              />
            </el-col>
            <el-col :md="6">
              <el-select v-model="languageOptions.dialects" placeholder="Select Dialects"
                         :disabled="!checkBoxes.dialects_from">
                <el-option v-for="dialect in dialects" :key="dialect.id" :value="dialect.id"
                           :label="dialect.name"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button class="modal-size" @click="iVisible = false">{{$t('cancel')}}</el-button>
            <el-button class="modal-size" type="primary" @click="handleClickConfirm()">{{$t('submit')}}</el-button>
        </span>
    </el-dialog> <!-- ./Language options -->
  </div>
</template>

<script>
  export default {
    name: 'languageOptionsModal',
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      dialects: {
        type: Array,
        default: []
      }
    },
    data () {
      return {
        languageOptions: {
          translator_from: '',
          customer_from: '',
          dialects_from: '',
          dialect: '',
        },
        checkBoxes: {
          translator_from: false,
          customer_from: false,
          dialects_from: false,
        },
        countries: [{
          id: 1,
          name: 'Algeria'
        },
          {
            id: 2,
            name: 'Marocko'
          },
          {
            id: 3,
            name: 'Tunisia'
          },
        ],
      }
    },
    computed: {
      /**
       * Interface for this.visible
       */
      iVisible: {
        get () {
          return this.visible
        },
        set (v) {
          this.$emit('update:visible', v);
        }
      },
    },
    methods: {
      /** Handle when confirm button is clicked */
      handleClickConfirm () {
        // Todo show success or error notification
        this.iVisible = false

      },
    }
  }

</script>
