<template>
    <div class="app-page send-sms-page">

        <page-header :title="$t('send_sms')"/>
        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col">
                      <el-row>
                        <el-col :span="12">
                            <el-form :model="form" @submit.native.prevent="submitForm" ref="form" :rules="rules">
                          <el-form-item :label="$t('select_translator')" prop="id">
                            <translator-select v-model="form.id"></translator-select>
                          </el-form-item>
                          <el-form-item label="">
                            <el-button type="success" @click="submitForm">{{$t('get_court_registers')}}</el-button>
                          </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="12"></el-col>
                      </el-row>

                    </div>
                </div>
            </div>
        </div>


    </div> <!-- /.app-page page-not-found -->
</template>




<script>
import { mapActions } from 'vuex';
export default {
data(){
  return{
    form:{
      id:''
    },
    rules:{
      id:[{
        required:true, message:'please select translator'
      }]
    }
  }
},
methods:{
  ...mapActions({ translatorCourtOrder: 'translator/postCourtOrder'}),
  submitForm(){
    this.$refs['form'].validate(async (valid) => {
    if(valid){
      let resp = await this.translatorCourtOrder(this.form)
      if(resp.status === 200){
        Notification.success({
        title: 'Success!',
        message: `Court order for ${resp.data.data.order.name} is requested`,
      });
      }
    }
    })
  }
}
}
</script>

<style>

</style>
