<template>
    <div class="app-page user-compose-page user-translator-compose-page"
         v-loading.fullscreen.lock="itemLoading">

        <page-header :title="pageHeaderTitle" :back-enabled="true"/>

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col">

                        <!-- Profile button -->
                        <div class="text-right">
                            <el-button type="primary" size="mini" v-if="isEdit" @click="handleRedirectLogin">
                                {{$t('login_as')}}
                            </el-button>
                            <el-button type="primary" size="mini" @click="handleRedirectAudits">
                                {{$t('audit_logs')}}
                            </el-button>
                            <el-button v-if="isEdit"
                                       type="primary"
                                       size="mini"
                                       @click="handleClickProfile">
                                <span class="fa fa-user"></span>
                                <span style="margin-left: 5px;">{{$t('go_to_profile')}}</span>
                            </el-button>
                        </div>

                        <!-- The Form -->
                        <el-form :model="form" :ref="formElId" :rules="rules"  :class="genBem(blockCls)">
                            <el-collapse v-model="activePanels">

                                <!-- Personal Info -->
                                <el-collapse-item :name="1"
                                                  :title="$t('personal_info')"
                                                  :class="genBem(blockCls, 'personal-info')">
                                    <user-translator-personal-info-fields v-model="form.personal_info"
                                                                          :form-el-id="formElId"/>
                                    <goto-element :label="goto.label" :target="goto.target"/>
                                </el-collapse-item>

                                <!-- Credentials -->
                                <el-collapse-item :name="2"
                                                  :title="$t('credentials')"
                                                  :class="genBem(blockCls, 'credentials')">
                                    <user-credential-fields v-model="form.credential"
                                                            :form-el-id="formElId"/>
                                    <goto-element :label="goto.label" :target="goto.target"/>
                                </el-collapse-item>

                                <!-- Service -->
                                <el-collapse-item :name="3"
                                                  :title="$t('service')"
                                                  :class="genBem(blockCls, 'service')">
                                    <user-service-fields v-model="form.service"
                                                         :form-el-id="formElId"/>
                                    <goto-element :label="goto.label" :target="goto.target"/>
                                </el-collapse-item>

                                <!-- Address -->
                                <el-collapse-item :name="5"
                                                  :title="$t('address')"
                                                  :class="genBem(blockCls, 'address')">

                                    <user-address-fields v-model="form.address" :form-el-id="formElId"/>
                                    <goto-element :label="goto.label" :target="goto.target"/>
                                </el-collapse-item>

                                <!-- Questions -->
                                <el-collapse-item :name="6"
                                                  :title="$t('questions')"
                                                  :class="genBem(blockCls, 'questions')">
                                    <user-translator-questions-fields v-model="form.questions"
                                                                      :form-el-id="formElId"/>
                                    <goto-element :label="goto.label" :target="goto.target"/>
                                </el-collapse-item>

                            </el-collapse>

                            <el-form-item :class="genBem(blockCls, 'btns')">
                                <el-button v-if="isAdd"
                                           type="primary"
                                           :class="genBem(blockCls, 'submit-add-btn')"
                                           @click="handleClickSubmitAdd">
                                    {{$t('create')}}
                                </el-button>
                                <el-button v-if="isEdit"
                                           type="primary"
                                           :class="genBem(blockCls, 'submit-edit-btn')"
                                           @click="handleClickSubmitEdit">
                                    {{$t('update')}}
                                </el-button>
                                <el-button v-if="isDuplicate"
                                           type="primary"
                                           :class="genBem(blockCls, 'submit-add-btn')"
                                           @click="handleClickSubmitAdd">
                                    Duplicate
                                </el-button>
                            </el-form-item>

                        </el-form>

                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page user-translator-compose-page -->
</template>

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
  import {checkBreadMode} from '~/js/helpers/Route';
  import UserTranslatorPersonalInfoFields
    from '~/components/forms/user/personal-info-fields/UserTranslatorPersonalInfoFields';
  import UserCredentialFields from '~/components/forms/user/UserCredentialFields';
  import UserServiceFields from '~/components/forms/user/UserServiceFields';
  import UserAddressFields from '~/components/forms/user/UserAddressFields';
  import UserTranslatorSettingsFields from '~/components/forms/user/settings-fields/UserTranslatorSettingsFields';
  import UserTranslatorQuestionsFields from '~/components/forms/user/questions-fields/UserTranslatorQuestionsFields';
  import GotoElement from '~/components/displays/GotoElement';
  import {sd} from "../../../js/helpers/Common";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      UserTranslatorPersonalInfoFields,
      UserCredentialFields,
      UserServiceFields,
      UserTranslatorSettingsFields,
      UserAddressFields,
      UserTranslatorQuestionsFields,
      GotoElement
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'user-translator-compose',
        formElId: 'translator_compose_form',
        activePanels: [1, 2, 3, 4, 5, 6],
        goto: {
          label: `${this.$t('go_to_save_button')}`,
          target: '.user-translator-compose__btns'
        },

        rules: {
          personal_info:{
           // name: [{ required: true, message: 'Translator name is required', trigger: 'blur' }],
            dob: [
              { validator: this.checkDate, trigger: 'blur' },
            ],
          }
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > watch
    |--------------------------------------------------------------------------
    */
    watch: {
      userId (v) {
        this.prepareForm(v);
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapState('translator', {
        form: 'compose',
        rels: 'relationships',
      }),
      ...mapGetters('auth', ['accessToken']),

      ...mapGetters({
        itemLoading: 'translator/itemLoading'
      }),

      /**
       * Returns the title to be displayed in the page header.
       *
       * @returns {string}
       */
      pageHeaderTitle () {
        const mode = this.isAdd ? `${this.$t('create')}` : this.isEdit? `${this.$t('edit')}` : `${this.$t('duplicate')}`;
        const title = `${this.$t('translator')}`;
        return `${mode} ${title}`;
      },

      /**
       * Returns the user's ID from route param "id"
       * @return {int}
       */
      userId () {
        return !window._.isNil(this.$route.params) && !window._.isNil(this.$route.params.id)
          ? parseInt(this.$route.params.id)
          : 0;
      },

      /**
       * Returns boolean whether if the current compose page
       * processes an ADD action.
       *
       * @returns {boolean}
       */
      isAdd () { return this.breadMode === 'add'; },

      /**
       * Returns boolean whether if the current compose page
       * processes an EDIT action.
       *
       * @returns {boolean}
       */
      isEdit () { return this.breadMode === 'edit'; },
      /**
       * Returns boolean whether if the current compose page
       * processes an DuplicaTE action.
       *
       * @returns {boolean}
       */
      isDuplicate () { return this.breadMode === 'duplicate'; },

      /**
       * Returns the compose mode of the page. Varies between add | edit.
       *
       * @returns {string}
       */
      breadMode () { return checkBreadMode(this); }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapMutations('translator', ['resetCompose', 'resetPayload']),

      ...mapActions('translator', {
        readEntry: 'read',
        editEntry: 'edit',
        addEntry: 'add'
      }),

      ...mapActions({
        addActivity: 'userAccessLogs/addLogs'
      }),
      ...mapActions("auth", {
        loginAs: "loginAs"
      }),
      /**
       * Helper method for loading all the available option loaders.
       *
       * @return {void}
       */
      loadAllOpts () {
        this.loadTranslatorOpts();
        this.loadCustomerTypeOpts();
        this.loadTownOpts();
        this.loadCompanyOpts();
        this.loadDepartmentOpts();
      },

      /**
       * Handler when the submit button while in ADD mode was clicked.
       *
       * @return {void}
       */
      handleClickSubmitAdd () {
        this.$refs[this.formElId].validate((valid) => {
          if (valid) {
            console.log(this.compose);
            this.addEntry();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      /**
       * Handler when the submit button while in EDIT mode was clicked.
       *
       * @return {void}
       */
      handleClickSubmitEdit () {
        this.editEntry({id: this.userId});
      },

      /**
       * Handler when the profile button was clicked.
       *
       * @return {void}
       */
      handleClickProfile () {
        this.$router.push({
          name: 'user.profile',
          params: {id: this.userId},
          query: {type: 3}
        });
      },

      /**
       * Method to wrap the entry loading action.
       *
       * @param {int|string} id - ID of the entry.
       * @return {void}
       */
      prepareForm (id) {
        console.log(`Preparing Form: ${id}`);
        this.resetCompose();
        this.resetPayload();

        // If on edit mode, load the necessary data for the user.
        if (this.isEdit && this.userId) {
          this.readEntry({id: this.userId})
              .catch(() => {
                this.$notify.error({
                  title: 'Error',
                  message: 'There is no translator by that ID.'
                });
              });
        }
        // If on duplicate mode, load the necessary data for the user.
        if (this.isDuplicate && id) {
          this.readEntry({id:id,isDuplicate:true})
              .catch(() => {
                this.$notify.error({
                  title: 'Error',
                  message: 'There is no customer by that ID.',
                });
              });
        }
      },

      /**
       * Handle when redirecting to the translator user's audit logs
       *
       * @return {void}
       */
      handleRedirectAudits() {
        this.$router.push({
          name:'audits',
          params:{
            id:this.$route.params.id,
            auditable:'users'
          },
          query: {
            relationships: this.rels.join(',')
          }
        })
      },
      /**
       * Handle when redirecting to the translator to login
       *
       * @return {void}
       */
      handleRedirectLogin() {
          this.loginAs({
              email: this.form.personal_info.email
            })
            .then(response => {
              let params = `?access=${response.data.token}`;
              window.open(
                sd(
                  process.env.MIX_APP_APP_CT_URL,
                  "https://ct.digitaltolk.se/"
                ) +
                "login/as" +
                params,
                "_blank"
              );
            })
            .catch(error => {
              console.log(error);
              this.$notify.error({
                title: "Error",
                message: "Unable to Login"
              });
            });
      },

      /**
       * Generate HTML when the api return validation error
       *
       * @return {html}
       *
       */
      getGeneratedMessage(exception) {
        if(_.isObject(exception)) {
         let keys = Object.keys(exception)
         let html = '';
         keys.map((key) => {
            let value = exception[key][0];
            html += value+'<br>'

         })
         return html;
        }
         return exception
      },

      /**
       * checkdate methods check the format for given DOB
       */
      checkDate(rule, value, callback){
        let regex = /^[0-9-]+$/
        let allowedChars = regex.test(value);
        if (!value.startsWith('20') && !value.startsWith('19')){
          return callback(new Error('DOB start with 20 or 19 for example 19760423-0873'));
        }else if (value.length !== 13){
          return callback(new Error('DOB Must be 13 characters for example 19760423-0873'));
        }else if (value[8] !== '-'){
          return callback(new Error('DOB wrong format'));
        }else if (!allowedChars) {
          return callback(new Error('DOB contain alpha,special or space charachters'));
        }else{
          return callback()
        }
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      this.prepareForm(this.userId);

      if (this.isEdit) {
        this.addActivity({
          action: 'has-visited',
          id: this.$route.params.id,
          message: 'Edit Profile Page'
        });
      }
    }
  }
</script>
