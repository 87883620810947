<template>
    <div class="app-page" :class="genBem(blockCls)">

        <page-header title="employee Bookings Weekly" :backEnabled="true"></page-header>

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col" v-loading="chartLoading">

                        <div :class="genBem(blockCls, 'header')">

                            <div :class="genBem(blockCls, 'header-details')">
                                <div :class="genBem(blockCls, 'header-detail-name')">
                                    <span class="fa fa-info-circle"></span>
                                    <span class="content-name">{{ translator.name }}</span>
                                </div>
                                <div :class="genBem(blockCls, 'header-detail-languages')">
                                    <p class="content-languages" v-if="!this.showAllTranslatorLanguages">
                                        {{ shortendTranslatorBookingsLanguages.join(', ') }}
                                        <template v-if="translatorLanguages.length > 3">
                                            <a href="#" @click.prevent.stop="handleToggleSeeMoreTranslatorLanguages">
                                                See More
                                            </a>
                                        </template>
                                    </p>
                                    <p class="content-languages" v-else>
                                        {{ translatorLanguages.join(', ') }}
                                        <a href="#" @click.prevent.stop="handleToggleSeeMoreTranslatorLanguages">
                                            Hide
                                        </a>
                                    </p>
                                </div>
                            </div> <!-- header-details -->

                            <div :class="genBem(blockCls, 'header-controls')">
                                <p :class="genBem(blockCls, 'week-label')" @click="handleShowWeekPopper">
                                    <span class="fa fa-calendar content-icon"></span>
                                    <span class="content-label">{{ weekLabel }}</span>
                                </p>
                                <div :class="genBem(blockCls, 'week-picker')">
                                    <el-date-picker
                                            ref="week_picker"
                                            v-model="selectedDate"
                                            type="week"
                                            popper-class="day-picker-only"
                                            @change="handleChangeWeek"
                                            :picker-options="{
                                                firstDayOfWeek
                                            }"
                                    ></el-date-picker>
                                </div>
                                <div :class="genBem(blockCls, 'week-buttons')">
                                    <el-button type="default"
                                               size="mini"
                                               @click.stop.prevent="handleNavigateWeek('prev')">
                                        <span class="fa fa-caret-left"></span>
                                        Prev Week
                                    </el-button>
                                    <el-button type="default"
                                               size="mini"
                                               @click.stop.prevent="handleNavigateWeek('next')">
                                        Next Week
                                        <span class="fa fa-caret-right"></span>
                                    </el-button>
                                </div>
                            </div> <!-- header-controls -->

                        </div> <!-- header -->

                        <div :class="genBem(blockCls, 'body')">
                            <bookings-weekly
                                    :range="getWeekRange(selectedDate)"
                                    :bookings="bookings"
                                    @assign="handleAssignTranslator"
                            />
                        </div> <!-- body -->

                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page translator-bookings-weekly-page -->
</template>

<script>
  import BookingsWeekly from '~/components/displays/booking-weekly/BookingsWeekly';
  import APICaller from '~/js/helpers/APICaller';
  import API from '~/js/constants/api';
  import BOOKING_STATUS from '~/js/constants/booking-status';
  import {mapActions} from 'vuex';

  // noinspection JSUnusedGlobalSymbols
  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      BookingsWeekly
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'user-translator-bookings-weekly-page',
        translator: {},

        firstDayOfWeek: 1, // Monday
        selectedDate: '',

        bookings: {
          potential: [],
          assigned: []
        },

        languageCollection: [],
        showAllTranslatorLanguages: false,
        translator_id:'',
        chartLoading: false
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Returns a formatted range of the day selected.
       *
       * @return {string}
       */
      weekLabel () {
        const range = this.getWeekRange(this.selectedDate);

        if (range.start !== null && range.end !== null) {
          const firstDayOfWeek = range.start.format('DD MMM YYYY');
          const lastDayOfWeek = range.end.format('DD MMM YYYY');

          return `${firstDayOfWeek} - ${lastDayOfWeek}`

        } else {
          return 'Click here to pick a week';
        }
      },

      /**
       * Returns a collection of the translator's bookings languages.
       *
       * @return {array}
       */
      translatorLanguages () {
        let result = []; // Prepare container.

        if (
          !window._.isEmpty(this.translator)
          && !window._.isEmpty(this.languageCollection)
        ) {
          // Get the languages id related to the translator.
          const languageIds = window._.chain(this.translator.languages)
                                    .map('language_id')
                                    .uniq()
                                    .value();

          // Get the Name of the language
          window._.each(languageIds, (id) => {
            const found = window._.find(this.languageCollection, (lang) => lang.id === id);
            result.push(found.name);
          });
        }

        return result;
      },

      /**
       * Returns a shortened version of Translator Bookings Languages
       *
       * @return {array}
       */
      shortendTranslatorBookingsLanguages () {
        return window._.take(this.translatorLanguages, 3);
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions({
        actionReadUser: 'translator/read',
        actionBrowseLanguages: 'language/fetchLanguages'
      }),

      /**
       * Method to explicitly popup the calendar picker.
       *
       * @return {void}
       */
      handleShowWeekPopper () {
        this.$refs['week_picker'].focus();
      },

      /**
       * Handle when the week in date picker has changed.
       *
       * @return {void}
       */
      handleChangeWeek () {
        this.loadBookings();
      },

      /**
       * Handler when the "See more" for Translator Languages was clicked.
       */
      handleToggleSeeMoreTranslatorLanguages () {
        this.showAllTranslatorLanguages = !this.showAllTranslatorLanguages;
      },

      /**
       * Handler for navigating the week picker.
       *
       * @param {string} direction - direction to which week to go into.
       *                             varies between 'prev' || 'next'
       */
      handleNavigateWeek (direction) {
        if (direction === 'prev') {
          this.selectedDate = window.moment(this.selectedDate).subtract(7, 'days').toDate();

        } else if (direction === 'next') {
          this.selectedDate = window.moment(this.selectedDate).add(7, 'days').toDate();
        }

        this.loadBookings();
      },

      /**
       * Helper method to group the loaders of the booking.
       *
       * @return {void}
       */
      loadBookings () {
        // Reflect the range to URL.
        const selectedWeekRange = this.getWeekRange(this.selectedDate);
        this.reflectWeekRangeToUrl(selectedWeekRange);

        // Define the necessary values.
        let selectedDate = window.moment(this.selectedDate);
        let weekRange = this.getWeekRange(Date.now());

        if (
          selectedDate.isSame(weekRange.start, 'day')
          || selectedDate.isAfter(weekRange.start)
        ) {
          // If the selectedDate is the same or after the current week range,
          // load the proper bookings.
          this.getPotentialBookingsForTheWeek(this.selectedDate, this.translator);
          this.getTranslatorBookingsForTheWeek(this.selectedDate);

        } else {
          // Else if the selected date is from previous weeks,
          // load the past bookings.
          this.getPastBookingsForTheWeek(this.selectedDate, this.translator);
          this.getTranslatorCompletedBookingsForTheWeek(this.selectedDate);
        }
      },

      /**
       * Method to get the start and end of week in Moment Object Format.
       *
       * @param {Date|string} week
       * @return {Object}
       */
      getWeekRange (week) {
        let result = {start: null, end: null};

        if (!window._.isNil(week) && week !== '') {
          result.start = window.moment(week).weekday(this.firstDayOfWeek);
          result.end = window.moment(week).weekday(this.firstDayOfWeek + 6);
        }

        return result;
      },

      /**
       * Method to intiate the week value.
       *
       * @return {Date}
       */
      initiateWeek () {
        if (
          !window._.isNil(this.$route.query['start'])
          && !window._.isNil(this.$route.query['end'])
        ) {
          this.selectedDate = window.moment(this.$route.query['start'], 'YYYY-MM-DD').toDate();

        } else if (window._.isNil(this.selectedDate) || this.selectedDate === '') {
          this.selectedDate = Date.now();
        }
      },

      /**
       * Method to reflect the week range start and end value to URL.
       *
       * @param {Object} range - contains the start and end of the week range.
       * @return {void}
       */
      reflectWeekRangeToUrl (range) {
        this.$router.push({
          query: {
            start: range.start.format('YYYY-MM-DD'),
            end: range.end.format('YYYY-MM-DD')
          }
        })
      },

      /**
       * Method to get the Potential bookings for the given week from the API.
       *
       * @param {Date|string} week
       * @param {object} translator
       * @return {void}
       */
      getPotentialBookingsForTheWeek (week, translator) {
        // Define the start and end range.
        const range = this.getWeekRange(week);
        const start = range.start.format('YYYY-MM-DD');
        const end = range.end.format('YYYY-MM-DD');

        // Get the collection of translator languages.
        const languages = window._.chain(translator.languages).map('language_id').uniq().value();

        // Define API Caller Options.
        const opts = {
          method: 'GET',
          endpoint: API.BOOKINGS,
          params: {
            'filter[date_range]': `due,${start},${end}`,
            'filter[status_id]': BOOKING_STATUS.PENDING,
            'filter[from_language_id]': languages.join(','),
            sort: '-created_at',
            all: true,
            include: [
              'translator_levels',
              'specific_translators.translator',
              'excluded_translators',
              'assigned_translator'
              // 'alternative_languages',
              // 'booker',
              // 'notification_types',
              // 'comments',
              // 'comments.user',
              // 'video_booking'
            ].join(','),
            with: ['customer'].join(',')
          }
        };

        this.chartLoading = true;
        APICaller(opts)
          .then((r) => {
            let firstKey = Object.keys(r.data.data)[0];
            let bookings = r.data.data[firstKey];

            // Initiate a new property called flag
            window._.each(bookings, (booking) => {
              booking.mismatch_flags = {
                gender: false,
                specific_translator: false,
                excluded_translator: false,
                translator_level: false
              };
            });

            // Flag bookings that have a mismatch (in favor of the Translator) in regards
            // to specified gender.
            window._.each(bookings, (booking) => {
              booking.mismatch_flags.gender = booking.gender !== null
                && booking.gender !== translator.gender;
            });

            // Flag bookings that have a mismatch (in favor of the Translator) in regards
            // to specified translator.
            window._.each(bookings, (booking) => {
              if (booking.specific_translators.length > 0) {
                // Find if there is a match in the collection of specified translators, and set the flag.
                const found = window._.find(booking.specific_translators, (o) => o.translator.id === translator.id);
                booking.mismatch_flags.specific_translator = window._.isEmpty(found);
              }
            });

            // Flag bookings that have a match in regards to excluded translator.
            window._.each(bookings, (booking) => {
              booking.mismatch_flags.excluded_translator = booking.excluded_translators.length > 0
                && window._.includes(booking.excluded_translators, translator.id);
            });

            // Flag bookings that have a mismatch (in favor of the Translator) in regards
            // to language translator level.
            const translatorLanguages = translator.languages;
            window._.each(bookings, (booking) => {
              if (booking.translator_levels.length > 0) {
                // Define the language ID and language translator levels.
                const languageId = booking.from_language_id;
                const languageLevels = window._.map(booking.translator_levels, 'translator_level_id');

                // Get the translator language profile based on the given language id.
                let translatorLevels = window._.chain(translatorLanguages)
                                             .filter((o) => o.language_id === languageId)
                                             .map('translator_level_id')
                                             .value();

                const hasMatch = window._.intersection(languageLevels, translatorLevels).length > 0;
                booking.mismatch_flags.translator_level = !hasMatch;
              }
            });

            this.bookings.potential = bookings;
          })
          .finally(() => {
            this.chartLoading = false;
          });
      },

      /**
       * Method to get the Translator Assigned bookings for the given week
       * from the API.
       *
       * @param {Date|string} week
       * @return {void}
       */
      getTranslatorBookingsForTheWeek (week) {
        // Define the start and end range.
        const range = this.getWeekRange(week);
        const start = range.start.format('YYYY-MM-DD');
        const end = range.end.format('YYYY-MM-DD');

        // Define API Caller Options.
        const opts = {
          method: 'GET',
          endpoint: API.BOOKINGS,
          params: {
            'filter[date_range]': `due,${start},${end}`,
            'filter[status_id]': `${BOOKING_STATUS.ASSIGNED},${BOOKING_STATUS.STARTED}`,
            'filter[assigned_translator]': this.$route.params['id'],
            sort: '-created_at',
            all: true,
            with: ['customer'].join(',')
          }
        };

        this.chartLoading = true;
        APICaller(opts)
          .then((r) => {
            let firstKey = Object.keys(r.data.data)[0];
            this.bookings.assigned = r.data.data[firstKey];
          })
          .finally(() => {
            this.chartLoading = false;
          });
      },

      /**
       * Method to get the Potential bookings for the given week from the API.
       *
       * @param {Date|string} week
       * @param {object} translator
       * @return {void}
       */
      getPastBookingsForTheWeek (week, translator) {
        // Define the start and end range.
        const range = this.getWeekRange(week);
        const start = range.start.format('YYYY-MM-DD');
        const end = range.end.format('YYYY-MM-DD');

        // Get the collection of translator languages.
        const languages = window._.chain(translator.languages).map('language_id').uniq().value();

        // Define API Caller Options.
        const opts = {
          method: 'GET',
          endpoint: API.BOOKINGS,
          params: {
            'filter[date_range]': `due,${start},${end}`,
            'filter[status_id]': [
              BOOKING_STATUS.CANCELLED,
              BOOKING_STATUS.LATE_CANCELLED,
              BOOKING_STATUS.LATE_CANCELLED_CUSTOMER,
              BOOKING_STATUS.LATE_CANCELLED_CUSTOMER_TRANSLATOR
            ].join(','),
            'filter[from_language_id]': languages.join(','),
            sort: '-created_at',
            all: true,
            include: [
              'translator_levels',
              'specific_translators.translator',
              'excluded_translators',
              'assigned_translator'
              // 'alternative_languages',
              // 'booker',
              // 'notification_types',
              // 'comments',
              // 'comments.user',
              // 'video_booking'
            ].join(','),
            with: ['customer'].join(',')
          }
        };

        this.chartLoading = true;
        APICaller(opts)
          .then((r) => {
            let firstKey = Object.keys(r.data.data)[0];
            this.bookings.potential = r.data.data[firstKey];
          })
          .finally(() => {
            this.chartLoading = false;
          });
      },

      /**
       * Method to get the Translator Completed bookings for the given week
       * from the API.
       *
       * @param {Date|string} week
       * @return {void}
       */
      getTranslatorCompletedBookingsForTheWeek (week) {
        // Define the start and end range.
        const range = this.getWeekRange(week);
        const start = range.start.format('YYYY-MM-DD');
        const end = range.end.format('YYYY-MM-DD');

        // Define API Caller Options.
        const opts = {
          method: 'GET',
          endpoint: API.BOOKINGS,
          params: {
            'filter[date_range]': `due,${start},${end}`,
            'filter[status_id]': `${BOOKING_STATUS.COMPLETED}`,
            'filter[assigned_translator]': this.$route.params['id'],
            sort: '-created_at',
            all: true,
            with: ['customer'].join(',')
          }
        };

        this.chartLoading = true;
        APICaller(opts)
          .then((r) => {
            let firstKey = Object.keys(r.data.data)[0];
            this.bookings.assigned = r.data.data[firstKey];
          })
          .finally(() => {
            this.chartLoading = false;
          });
      },

      /**
       * Handler for the assign event.
       */
      handleAssignTranslator (booking) {
        const translator = this.translator;

        const apiOpts = {
          endpoint: `${API.BOOKINGS}/${booking.id}`,
          method: 'put',
          data: {
            assigned_translator_id: translator.id,
            id: booking.id
          },
          isDataRaw: true
        };

        APICaller(apiOpts)
          .then(() => {
            this.$notify.success({
              title: 'Success!',
              message: `Booking was assigned to ${translator.name} (${translator.id})`
            });
          })
          .catch((e) => {
            console.error(e);
            this.$notify.error({title: 'Error', message: 'Please contact the administrator.'});
          });
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      
      // Set the default value for the week.
      this.initiateWeek();

      // Load language collection
      this.actionBrowseLanguages()
          .then((r) => {
            let firstKey = Object.keys(r.data.data)[0];
            this.languageCollection = r.data.data[firstKey];
          });

      // Load the user details
      this.actionReadUser({id: this.$route.params['id']})
          .then((r) => {
            let firstKey = Object.keys(r.data.data)[0];
            this.translator = r.data.data[firstKey];

            this.loadBookings();
          });

    }

  }
</script>
