/*
|--------------------------------------------------------------------------
| Store > User > Actions
|--------------------------------------------------------------------------
|
| This file contains the actions property of User Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import APICaller from "../../helpers/APICaller";
import {extendObjParams, mapEndpoint, sd} from "../../helpers/Common";
import {Notification} from "element-ui";
import {eut} from "../../helpers/Vuex";
import i18n from '../../i18n';
import API from '~/js/constants/api';


export default {

  /**
   * Load All Items with no pagination
   *
   * @param  {object} context
   *   the context of $store
   *
   * @param  {object} [payload = {}]
   *   an object which contains option values
   *
   * @return {Promise}
   */
  browseAsOptions: function (context, payload = {}) {

    payload.target = sd(payload.target, 'user');
    let target = context.state.api.browse[payload.target];

    // Set the target endpoint.
    let method = target.method;
    let endpoint = target.path;
    let params = extendObjParams({
      all: true,
      'fields[users]': 'id,name',
    }, payload);

    return APICaller({method, endpoint, params})
      .then((r) => {
        context.commit('setListAsOptions', {data:r.data.data, target: payload.target});
      });

  }, // End of browseAsOptions() method

  browseRecipient: function (context, payload = {}) {
    payload.target = sd(payload.target, 'user');
    let target = context.state.api.browse[payload.target];

    // Set the target endpoint.
    let method = target.method;
    let endpoint = target.path;
    let params = extendObjParams({
      'fields[users]': 'id,name,email,phone,mobile'
    }, payload);

    return APICaller({method, endpoint, params})
      .then((r) => {

        context.commit('setList', r.data);
      });

  }, // End of browseRecipient method

  /**
   * Load as options helper for Translators
   *
   * @param  {object} context
   *   the context of $store
   */
  browseAsOptionsTranslator(context) {
    context.dispatch('browseAsOptions', {
      target: 'translator'
    });
  }, // End of browseAsOptionsTranslator() method

  /**
   * Load as options helper for Customers
   *
   * @param  {object} context
   *   the context of $store
   */
  browseAsOptionsCustomer(context) {
    context.dispatch('browseAsOptions', {
      target: 'customer'
    });
  }, // End of browseAsOptionsTranslator() method

  /**
   * Load All Items
   *
   * @param  {object} context
   *   the context of $store
   *
   * @param  {object} payload
   *   an object which contains option values
   *
   * @return {Promise}
   */
  browse: function (context, payload) {

    // let target = context.state.api.browse[eut(context.state.compose.user_type)];
    let target = context.state.api.browse['user'];

    // Set the target endpoint.
    let method = target.method;
    let endpoint = target.path;
    let params = extendObjParams(target.params, payload);

    return APICaller({method, endpoint, params})
      .then((r) => {
        context.commit('setList', r.data);
      });

  }, // End of browse() method

  /**
   * Get an Item according to the given ID
   *
   * @param  {object} context
   *   the context of $store
   *
   * @param  {object} [payload={}]
   *   an object which contains option values
   *
   * @return {Promise}
   */
  read: function (context, payload = {}) {

    payload.user_type = sd(payload.user_type, 'user');
    let target = context.state.api.read[payload.user_type];

    // Set the target endpoint.
    let method = target.method;
    let endpoint = mapEndpoint(target.path, payload);
    let params = extendObjParams(target.params, payload);

    return APICaller({method, endpoint, params})
      .then((r) => {
        let raw = r.data.data; // Store into container so varname will be shorter.
        let data = raw[Object.keys(raw)[0]]; // Get the first member of the object.

        context.commit('setItem', {data});

        if (context.state.autoReflectItem) {
          context.commit('setCompose');
        }

        return r;
      });

  }, // End of read() method

  /**
   * Remove an item.
   *
   * @param  {object} context
   *   the context of $store
   *
   * @param  {object} payload
   *   an object which contains option values
   *
   * @return {Promise}
   */
  delete(context, payload) {
    eut(payload.type);
    let target = context.state.api.delete[eut(payload.type)];

    let method = target.method;
    let endpoint = mapEndpoint(target.path, payload);

    return APICaller({method, endpoint})
      .then(() => {

        if (context.state.autoUpdateList) {
          context.dispatch('browse');
          context.dispatch('browseAsOptions');
        }

        Notification.success({
          title: i18n.t('success'),
          message: 'User deleted',
        });
      })
      .catch((e) => {
        Notification.error({
          title: i18n.t('error'),
          message: e.message,
        });
      });

  }, // End of delete() method

  /**
   * Action to change the page offset of the current listing
   *
   * @param  {object} context
   *   the context of $store
   *
   * @param  {object} payload
   *   an object which contains option values
   *
   * @return {void}
   */
  changePage: function (context, payload) {
    context.dispatch('all', {
      extendParams: {
        page: payload,
      },
    });
  }, // End of changePage() method


  /**
   * Action to fetch the Booking Confirm Field Config values from the API.
   *
   * @param  {object} context
   *   the context of $store.
   *
   * @param  {object} payload - object which contains option values.
   * @param  {object} payload.customer_id - the ID of the target customer.
   *
   * @return {Promise}
   */
  readBcfConfig(context, payload) {

    let method = context.state.api.bcf.read.method;
    let endpoint = context.state.api.bcf.read.path;
    let params = {customer_id: payload.customer_id};

    return APICaller({method, endpoint, params})
      .then((r) => {

        let raw = r.data.data; // Store into container so varname will be shorter.
        return raw[Object.keys(raw)[0]]; // Get the first member of the object.

      })
      .catch((e) => {

        // Get the response message that's displayed during failure.
        let message = e.response.data.data;
        // Verify if the bcf config record is empty.
        let isEmpty = _.includes(message, 'No query results for model');

        if (isEmpty) {
          // If the record is empty, then
          console.log(`There is no BCF Config Value for customer(${payload.customer_id})`);

        } else {
          // Else, if it's another kind of error, just display it.
          Notification.error({
            title: i18n.t('error'),
            message: e.message,
          });
        }
      });

  }, // End of readBcfConfig() method

  /**
   * Action to update/submit the Booking Confirm Field Config value changes
   * to the API.
   *
   * @param  {object} context
   *   the context of $store
   *
   * @param  {object} payload - object which contains option values.
   * @param  {object} payload.data - data to be submitted to the API.
   *
   * @return {Promise}
   */
  editBcfConfig(context, payload) {

    let method = context.state.api.bcf.edit.method;
    let endpoint = context.state.api.bcf.edit.path;
    let data = payload.data;

    // Mutate the boolean values inside the object
    _.forOwn(data, (v, i) => {
      if (i !== 'customer_id' && i !== 'reference_placeholder')
        data[i] = v ? 1 : 0;
    });

    console.log(data);

    return APICaller({method, endpoint, data})
      .then(() => {
        Notification.success({
          title: i18n.t('success'),
          // message: i18n.t('n_item_deleted'),
          message: 'Booking Confirmation Field Config saved.',
        });
      })
      .catch((e) => {
        Notification.error({
          title: i18n.t('error'),
          message: e.message,
        });
      });

  }, // End of editBcfConfig() method

  /**
   * only used to update users booking types
   * @param {object} context
   * @param {object} payload
   */
  updateBookingType(context, payload) {

    // Set the target endpoint.
    let method = 'PUT';
    let endpoint = mapEndpoint(`${API.USERS}/{id}`, payload);
    let data = payload.data;
    let isDataRaw = true;


    return APICaller({method, endpoint, data, isDataRaw})
      .then(() => {

        if (context.state.autoUpdateList) {
          context.dispatch('browse');
          context.dispatch('browseAsOptions');
        }

        Notification.success({
          title: 'Success!',
          message: 'Entry has been updated',
        });
      })
      .catch((e) => {
        Notification.error({
          title: 'Error!',
          message: e.message,
        });
      });
  },

  /**
   * Method to find the user in the API via given column.
   *
   * @param {object} context - current Vuex scope.
   * @param {object} payload - contains useful values.
   * @params {object} payload.params - query params that will be sent to API.
   * @params {object} payload.column - target db column to where the query will be based on.
   * @params {object} payload.query - query value to find.
   * @return {*}
   */
  findUser (context, payload = {}) {

    // Set defaults.
    payload.params = sd(payload.params, {});
    payload.column = sd(payload.column, 'name');
    payload.query = sd(payload.query, '');

    // Set the query params.
    let params = {};
    params['fields[users]'] = 'id,name';
    params[`filter[${payload.column}]`] = payload.query;

    _.extend(params, payload.params);

    // Set the API Opts.
    const opts = {
      endpoint: '/api/v3/users',
      method: 'GET',
      params
    };

    // Call the API.
    return APICaller(opts)
      .then(r => r)
      .catch(e => e);
  },

  readTrashedUsers(context, payload){
    let params = {}
    params['filter[type]'] = '2,3'
    params = Object.assign(params,payload)

    return APICaller({
        endpoint:API.TRASHED_USERS,
        method:'get',
        params : params
    }).then((response) => {
        context.commit('setTrashedUsers', response.data)
        return response
      }
    ).catch( (e) => {
        console.log(e)
        return e
    })
  },

  restoreTrashedUsers(context, payload){
    return APICaller({
        endpoint: `${API.RESTORE_TRASHED_USERS}/${payload.id}`,
        method:'post'
    }).then((response) => {

      Notification.success({
        title: 'Success',
        message: 'User is restored.',
      });

      return response
    }).catch( (e) => {
        console.log(e)
        return e
    })
  },

  updateTemporaryNumberOfUser(context, payload) {
    let isDataRaw = true;
    return APICaller({
      endpoint: `${API.USERS}/${payload.id}`,
      method:'put',
      data: payload,
      isDataRaw
    }).then((response) => {

      Notification.success({
        title: 'Success',
        message: 'Temporary number updated.',
      });

      return response
    }).catch( (e) => {
      console.log(e)
      return e
    })
  },

  storeUserFile(context, payload){
    return APICaller({
      endpoint: `${payload.url}`,
      method:'post',
      data:payload.data,
      isDataRaw: true
    }).then((response) => {
      Notification.success({
        title: 'Success',
        message: 'File saved.',
      });
      return response
    }).catch( (e) => {
      return e
    })
  }



}; // End of export default
