<template>
    <div class="app-list fax-logs-list">
        <p>
            {{$t('fax_logs')}}
        </p>

        <div class="list-base-table-top-controls">
            <div class="col-container hide-columns-container full-width text-right">
                            <el-input placeholder="Search Records" v-model="search" style="max-width: 350px;">
                    <el-button type="primary" slot="append" icon="el-icon-fa fa-search" style="min-width: 50px;"></el-button>
                </el-input>
            </div>
        </div>

        <el-table :data="mockData" border style="font-size: 12px" >

            <el-table-column v-for="item in tableCols"
                             :key="item.prop"
                             v-if="isColEnabled(item.prop)"
                             :label="item.label"
                             :width="item.width">
                <template slot-scope="scope">
                    <div class="cell-content" :id="genColId(tableElId, item.prop, scope.row.id)">
                        {{ scope.row[item.prop] }}
                    </div>
                </template>
            </el-table-column>

             <!-- Actions Column -->
            <el-table-column class-name="actions-col"
                             label="Actions">

                <template slot-scope="scope">
                    <el-button type="default" class="el-button el-button--default circle"
                    :id="scope.row.id+'details_btn'"
                    @click="handleActivity('details',scope.row.id, scope.row)">
                    <i class="fa fa-info"></i>
                    </el-button>
                </template>
        </el-table-column>
            <!-- </template>  -->
        </el-table>

        <div class="pagination-container">
            <div class="pagination-wrapper">
                <el-pagination
                        layout="prev, pager, next"
                        :total="1000">
                </el-pagination>
            </div> <!-- /.pagination-wrapper -->
        </div> <!-- /.pagination-container -->

        <base-details-modal
                :visible.sync="detailsVisible"
                custom-class="fax-logs-list-modal"
                :extra-rows="tableCols"
                :append-to-body="false"
                :info="detailsInfo">
        </base-details-modal>
    </div> <!-- /.app-list fax-list -->
</template>

<script>
    import {genFieldId, genContainerId, genColId} from "../../../js/helpers/Html";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {

                tableElId: 'fax-logs-list',
                search: '',
                tableCols: [
                    {prop: 'fax_number', label: 'Fax Number',width:'100'},
                    {prop: 'send_to', label: 'Send To',width:'100'},
                    {prop: 'job_id', label: 'Booking ID', width: '100'},
                    {prop: 'twilio_sid', label: 'Twilio SID'},
                    {prop: 'subject', label: 'Subject', width: '400'},
                    {prop: 'status', label: 'Status', width: '100'},
                    {prop: 'created_at', label: 'Sent At',width:'150'},
                    {prop: 'error_message', label: 'Error Message', width:'150'},
                    {prop: 'link', label: 'Link', width:'150'}
                ],

                hiddenCols: [],

                mockData: [
                    {
                        id: 1,
                        fax_number: '+1111111',
                        send_to: '+123123123',
                        job_id: '19180',
                        twilio_sid: 'FX1e88ec3ca6271aacf9f4be470935b166',
                        subject: 'Vi har mottagit er tolkbokning. Bokningsnr: # 72426',
                        status: 'Delivered',
                        created_at: '2018-07-19 13:08:37',
                        error_message: 'matilda.bjorksaga@nassjo.se',
                        link: ''
                    },

                    {
                        id: 2,
                        fax_number: '+1111111',
                        send_to: '+123123123',
                        job_id: '19180',
                        twilio_sid: 'FX1e88ec3ca6271aacf9f4be470935b166',
                        subject: 'Vi har mottagit er tolkbokning. Bokningsnr: # 72426',
                        status: 'Delivered',
                        created_at: '2018-07-19 13:08:37',
                        error_message: 'matilda.bjorksaga@nassjo.se',
                        link: ''
                    },

                    {
                        id: 3,
                        fax_number: '+1111111',
                        send_to: '+123123123',
                        job_id: '19180',
                        twilio_sid: 'FX1e88ec3ca6271aacf9f4be470935b166',
                        subject: 'Vi har mottagit er tolkbokning. Bokningsnr: # 72426',
                        status: 'Delivered',
                        created_at: '2018-07-19 13:08:37',
                        error_message: 'matilda.bjorksaga@nassjo.se',
                        link: ''
                    },

                    {
                        id: 4,
                        fax_number: '+1111111',
                        send_to: '+123123123',
                        job_id: '19180',
                        twilio_sid: 'FX1e88ec3ca6271aacf9f4be470935b166',
                        subject: 'Vi har mottagit er tolkbokning. Bokningsnr: # 72426',
                        status: 'Delivered',
                        created_at: '2018-07-19 13:08:37',
                        error_message: 'matilda.bjorksaga@nassjo.se',
                        link: ''
                    },


                ],

                detailsVisible: false,
                detailsInfo: {},
            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {}, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            /**
             * Helper method to check wether the column is enabled in the options or not.
             *
             * @param {string} key
             *   The switch value to check for whether to enable/disable a column.
             *
             * @return {boolean}
             */
            isColEnabled(key) {
                let r = true;

                if (_.includes(this.hiddenCols, key)) {
                    r = false;
                }

                return r;

            }, // End of isColEnabled() method

            // Map Helpers
            genFieldId: genFieldId,
            genContainerId: genContainerId,
            genColId: genColId,

            handleActivity(mode, rowIndex, rowItem){
                this.itemInfo = rowItem;
                console.log(rowItem)
                switch (mode) {
                    case 'details':
                        this.detailsVisible = true;
                        this.detailsInfo = rowItem;
                        break;

                    case 'edit':
                        this.composeMode = 'edit';
                        this.composeTitle = this.$t('edit');
                        this.iComposeForm = _.cloneDeep(this.composeFresh); // Reset form, and then map defaults
                        this.iComposeForm = mapProps(rowItem.attributes, this.iComposeForm, { byRef:false });
                        this.composeVisible = true;
                        break;

                    case 'delete':
                        if ( this.methodDelete !== false ) {
                            this.methodDelete(rowItem);
                        } else {
                            this.handleDelete(rowItem);
                        }
                        break;

                    default:
                        throw new Error('No mode specified in the activity');
                }
            }
        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
        }, // End of Component > mounted

    } // End of export default

</script>
