<template>
<div class="app-form default-municipality-settings-form">
    <el-form>
        <el-form-item class="label-short" label="Default Municipality">
            <el-select v-model="municipality_id" placeholder="Default Municipality">
                <el-option v-for="item in municipalities" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item class="text-right">
            <el-button @click="handleSubmit" type="primary">Update Default Municipality</el-button>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
import {
    mapGetters,
    mapState,
    mapActions
} from 'vuex';
import {
    populate
} from '../../../js/helpers/Vuex';
export default {
    /*
      |--------------------------------------------------------------------------
      | Component > data
      |--------------------------------------------------------------------------
      */
    data() {
        return {
            municipality_id: '',
            settings_id: ''
        };
    }, // End of Component > data
    /*
    |----------------------------------------------------------------------------
    | Component > computed
    |----------------------------------------------------------------------------
    */
    computed: {

        ...mapGetters('municipality', {
            municipalities: 'listAsOptions'
        }),
    }, // end of component > computed
    /*
    |----------------------------------------------------------------------------
    | Component > methods
    |----------------------------------------------------------------------------
    */
    methods: {

        /**
         * Actions for kpiSettings
         */
        ...mapActions('municipality', {
            browse: 'browseAsOptions',
            defaultMunicipality: 'getDefaultMunicipality',
            updateDefaultMunicipality: 'updateDefaultMunicipality',
        }),

        handleSubmit() {
            this.updateDefaultMunicipality({
                id: this.settings_id,
                value: this.municipality_id,
            });
        }
    }, // end of component > method
    /*
    |----------------------------------------------------------------------------
    | Component > mounted
    |----------------------------------------------------------------------------
    */
    mounted() {
        populate(this, 'browse', 'municipalities', {
            ref: 'Municipality List as Options'
        });

        this.defaultMunicipality().then(r => {
            let id = '';
            let sid = '';
            if (!window._.isNil(r.data.data.settings[0].value)) {
                id = r.data.data.settings[0].value;
                sid = r.data.data.settings[0].id;
            }
            this.municipality_id = id;
            this.settings_id = sid;
        });
    } // End of component > mounted
}; // End of export default
</script>
