<template>
  <div>
    <el-progress :percentage="uploadProgress" v-if="uploadProgress > 0 && uploadProgress < 100"></el-progress>

    <el-upload class="aws-upload-field" action=""
               :ref="refs" :drag="drag"
               :on-remove="handleRemove"
               :auto-upload="true"
               :http-request="handleFilesUpload"
               :multiple="multiple"
               :limit="limit"
               :file-list="iValue"
      :disabled="fileList.length > 0 && uploadProgress<100" :on-preview="handleOnPreview">
      <template v-if="drag">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text"> {{ $t('drop_file_here') }} / <em>{{ $t('click_to_upload') }}</em></div>
      </template>
      <template v-else>
        <el-button size="small" type="primary">{{$t(buttonText)}}</el-button>
      </template>
    </el-upload>
  </div>
</template>

<script>

import {storeFile,download} from "~/js/helpers/File";
import API from '~/js/constants/api';
import { isEmpty } from '../../js/helpers/Common';
export default {
  props:{
    value: Array,
    refs:{
      type:String,
      default: 'upload'
    },
    drag:{
      type:Boolean,
      default:false
    },
    limit:{
      default:5
    },
    buttonText:{
      type:String,
      default: 'select_files'
    },
    multiple:{
      type:Boolean,
      default:true
    }

  },
  data(){
    return {
      fileList:[],
      uploadProgress:0
    }
  },
  computed:{
    /**
     * Interface for the v-model of this component.
     */
    iValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    }
  },
  methods:{
    handleRemove(e){
        let filtered = this.iValue.filter((file) =>{
          return file.uid !== e.uid
        })
        this.iValue = filtered;
        this.$emit('deleteFile',e)
    },
    handleFilesUpload(e){
      if(this.limit === 1){
        this.iValue = []
      }
      let options = {
        url: `${API.BASE}/${API.SIGNED_STORAGE_URL}`,
        progress: progress => {
          this.uploadProgress = Math.round(progress * 100);
          this.$emit('progress', this.uploadProgress )
        }
      }
      storeFile(e.file,options).then((r)=>{
      let data = r.data
       data.uid = e.file.uid
       data.file_name = e.file.name
       data.name = e.file.name
       this.iValue.push(data)
       this.uploadProgress = 0
      });
    },
    handleOnPreview(e){
      if(!isEmpty(e.path)){
        download(e)
      }else{
        download({path:e.key,display_name:e.name})
      }
    }
  }
}
</script>
