/*
|--------------------------------------------------------------------------
| Financial > Price Template > mutations
|--------------------------------------------------------------------------
| Partial file for mutations object related to the scope.
*/

'use strict';

export default {

  /**
   * Set the list properties
   *
   * @param  {object} state
   *   : the state property the current Vuex Object
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {void}
   */
  setList: function (state, payload) {

    // Get the first object key in the data
    let firstKey = Object.keys(payload.data)[0];

    state.list.data = payload.data[firstKey];
    state.list.pagination = payload.meta.pagination;
  },

  /**
   * Set the list as options
   *
   * @param  {object} state
   *   : the state property the current Vuex Object
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {void}
   */
  setListAsOptions: function (state, payload) {

    // Get the first object key in the data
    let firstKey = Object.keys(payload.data)[0];

    // Get the list collection.
    state.listAsOptions = payload.data[firstKey];
  },

  /**
   * Method to set state.form.
   * @param  {object} state - the state property the current scope.
   * @param  {object} payload - values necessary inside the method.
   * @return {void}
   */
  setForm (state, payload) {
    state.form = {
      name: payload.name,
      template_for: payload.template_for
    };
  },

  /**
   * Method to reset state.form.
   * @param  {object} state - the state property the current scope.
   * @return {void}
   */
  resetForm (state) {
    state.form = _.cloneDeep(state.formFresh);
  },

  /**
   * Method to reset state.payload
   * @param  {object} state - the state property the current scope.
   * @return {void}
   */
  resetPayload (state) {
    state.payload = _.cloneDeep(state.payloadFresh);
  },

  /**
   * Added this for Pricelist (Non template) compatibility
   *
   * @param  {object} state - the state property the current scope.
   * @return {void}
   */
  resetSettingsPayload (state) {
    state.payload = _.cloneDeep(state.payloadFresh);
  }
};
