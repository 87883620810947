<template>
  <div class="app-form app-fields sector-settings-fields">
    <!-- Setting Status -->
    <el-form-item :id="genContainerId('name',formElId)" :label="$t('email_setting_status')" class="label-short">
      <el-select v-model="iValue.email_setting_status"
                 :placeholder="$t('select')">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="$t(item.value)"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>

    <template v-if="iValue.email_setting_status === 'allowed'">
      <!-- Enabled -->
      <el-form-item :id="genContainerId('number',formElId)" :label="$t('email_setting_enabled')" class="label-short">
        <el-select v-model="iValue.email_setting_enabled"
                   :placeholder="$t('select')">
          <el-option
            v-for="item in [true, false]"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- Enabled -->
      <el-form-item :id="genContainerId('number',formElId)" :label="$t('email_setting_send_24_hour')"
                    class="label-short">
        <el-select v-model="iValue.send_email_before_due"
                   :placeholder="$t('select')">
          <el-option
            v-for="item in [true, false]"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
    </template>
  </div>
</template>

<script>

  export default {
    /*
          |--------------------------------------------------------------------------
          | Component > props
          |--------------------------------------------------------------------------
          */
    props: {
      formElId: {
        type: String,
        default: ""
      },

      value: {
        type: Object,
        default() {
          return {};
        }
      }
    }, // End of Component > props
    data() {
      return {
        options: [
          {
            value: 'allowed'
          },
          {
            value: 'inherit'
          },
          {
            value: 'none'
          }
        ]
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit("input", v);
        }
      }
    }, // End of Component > computed,

    mounted() {
      setTimeout(() => {
        console.log(this.iValue)
      }, 2000)
    }

  }; // End of export default
</script>
