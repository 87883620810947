'use strict';

import state from './state';
import getters from '../shared/getters';
import mutations from '../shared/mutations';
import actions from '../shared/actions';

export default {
  namespaced: true,
  state, getters, mutations, actions
}
