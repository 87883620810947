<template>
  <el-dialog
    :visible.sync="iVisible"
    :modal-append-to-body="false"
    :append-to-body="true"
    custom-class="custom-modal"
    width='60%'
    :title="`${(assignedUser.type==2)? $t('assign_to_customer') : $t('assign_to_translator')}`"
  >
    <el-form :model="form" v-loading="loading" :rules="rules" ref="assign_form">
      {{$t('send_to')}} : {{assignedUser.name}}
      <hr/>
      <el-form-item class="label-medium" :label="$t('message')" prop="comment">
        <el-input type="textarea" v-model="form.comment"></el-input>
      </el-form-item>
      <hr/>
      <el-form-item class="label-medium" :label="$t('input_allowed')">
        <el-checkbox-group
          class="label-medium"
          aria-class="Assigned Actions"
          v-model="form.assigned_actions"
        >
          <el-checkbox :label="$t('text')"></el-checkbox>
          <el-checkbox :label="$t('upload')"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <hr/>
      <el-form-item :label="$t('deadline_date')" class="label-medium" prop="deadline_date">
        <el-date-picker
          v-model="form.deadline_date"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd HH:mm:ss"
          popper-class="day-picker-only"
        ></el-date-picker>
      </el-form-item>
      <el-form-item class="el-form-actions">
        <el-button class="modal-size" type="default" @click="iVisible = false" size="small">{{$t('cancel')}}</el-button>
        <el-button class="modal-size" type="primary" size="small" @click="submitForm('assign_form')">{{$t('submit')}}
        </el-button>
      </el-form-item>
    </el-form>

  </el-dialog>
</template>

<script>
  import {mapActions, mapState, mapMutations} from 'vuex';

  export default {
    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      issueId: Number,
      assignedUser: {
        type: Object,
        required: true
      },
      visible: {
        type: Boolean,
        default: false
      }
    },
    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('bookingIssue', {
        form: 'assignCustomerCompose'
      }),
      iVisible: {
        get () {
          return this.visible;
        },
        set (v) {
          this.$emit('update:visible', v);
        }
      }
    },
    /*
    |--------------------------------------------------------------------------
    | Component >   data
    |--------------------------------------------------------------------------
    */
    data () {
      const _this = this;
      return {
        loading: false,
        rules: {
          comment: [
            {
              required: true,
              message: 'Please add message'
            }
          ],
          deadline_date: [
            {
              required: true,
              message: _this.$t('select_deadline_date')
            }
          ]
        }
      };
    },
    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions('bookingIssue', {
        postComment: 'postComment'
      }),

      ...mapMutations('bookingIssue', {
        resetCompose: 'resetAssignCompose'
      }),

      submitForm (form) {
        this.loading = true;
        this.$refs[form].validate(valid => {
          if (valid) {
            this.form.booking_issue_id = this.issueId;
            this.form.user_id = this.assignedUser.id;
            this.form.assigned_actions = this.form.assigned_actions.join(',');

            this.postComment(this.form).then(r => {
              this.loading = false;
              this.$emit('update:visible', false);
              this.resetCompose();
            });
          } else {
            this.loading = false;
          }
        });
      }
    },
    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      this.resetCompose();
    }
  };
</script>
