<template>
    <div class="app-page booking-details-page">
        <page-header :title="$t('booking_details')" :back-enabled="true"/>

        <div class="app-container">
            <div class="app-wrapper" v-loading="loading">
                   <user-in-booking-feature :bookingId="bookingId" :userId="userId"></user-in-booking-feature>
                <div class="app-row">
                    <el-alert v-if="error.status"
                              :title="error.message"
                              type="error"
                              show-icon>
                    </el-alert>
                    <div class="app-col booking-detail-list-container">
                        <div class="content-body">
                            <el-row>
                                <el-col :md="8">
                                    <h3>{{$t('job_detail')}} #{{booking.id}}</h3>
                                </el-col>
                                <el-col :md="8" >

                                  <h3  v-if="isAskingPrice" class="red-title">{{$t('is_asking_price')}}</h3>
                                  <h3  v-if="booking.credit_applied" class="red-title">{{$t('credit_partial_credit_applied')}}</h3>
                                </el-col>
                                <el-col :md="8" class="text-right">
                                    <!-- <h3>Aes Detail #{{booking.id}}</h3> -->
                                    <h3>
                                      <booking-details-matched-translators :details="bookingInfoHighlights" />
                                    </h3>
                                </el-col>
                              <el-col :md="8" class="text-right" v-if="booking.dt_booking_id">
                                <h3>DT Booking #{{booking.dt_booking_id}}</h3>
                              </el-col>
                            </el-row>

                            <booking-detail-list @updateItem="reloadItem"/>

                            <el-collapse v-model="detailsPanel">
                                <el-collapse-item name="booking_feedback">
                                    <template slot="title">
                                        <h3>{{ $t('feedback') }}</h3>
                                    </template>
                                    <booking-feedback
                                        :feedbacks="feedbacks"
                                        :booking="booking"
                                    />
                                </el-collapse-item>
                                <el-collapse-item name="booking_travel_time" v-if="booking.type === 'physical' || booking.type === 'video_physical'">
                                    <template slot="title">
                                        <h3>{{ $t('traveltime') }}</h3>
                                    </template>
                                    <booking-traveltime
                                        v-if="booking.status_id >= 4"
                                        :traveltimes="traveltimes"
                                        :booking="booking"
                                    />
                                </el-collapse-item>
                            </el-collapse>

                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                    <div class="app-col booking-detail-form-container">
                        <div class="content-body">

                            <booking-detail-form :booking="booking"/>

                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page bookings-page -->
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import {isEmpty} from '../../../js/helpers/Common';
  import BookingDetailList from '~/components/lists/booking/BookingDetailList';
  import BookingDetailForm from '~/components/forms/booking/BookingDetailForm';
  import BookingDetailsMatchedTranslators from '~/components/displays/booking-details/BookingDetailsInfoHighlights';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      BookingDetailList,
      BookingDetailForm,
      BookingDetailsMatchedTranslators
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        detailsPanel: ['booking_feedback', 'booking_travel_time']
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters({
        booking: 'booking/getBooking',
        to_language: 'language/getLanguageFrom',
        from_language: 'language/getLanguageTo',
        error: 'booking/getError',
        loading: 'booking/getLoading',
        feedbacks: 'feedback/getFeedbacks',
        traveltimes: 'booking/getTraveltime'
      }),
         bookingId () {
        return !window._.isNil(this.$route.params['id'])
          ? parseInt(this.$route.params['id'])
          : 0;
      },

        userId(){
            return JSON.parse(localStorage.getItem("app_user_data")).id
        },
        bookingInfoHighlights() {
          return {
            batches_by_local: this.booking.batches_by_local,
            batches_by_prio: this.booking.batches_by_prio,
            translators_count: this.booking.translators_count,
            translators_sendable_count : this.booking.translators_sendable_count,
            translators_non_sendable_count : this.booking.translators_non_sendable_count
          };
      },

      isAskingPrice(){
        return (this.booking.type ==='text_translation' && !isEmpty(this.booking.text_translation) && this.booking.text_translation.is_asking_price)
      },
    },

    methods:{
      reloadItem(){
        this.$store.dispatch('booking/getBooking', this.$route.params.id).then(() => {
          let from_language = this.$store.state.booking.booking.from_language_id;
          this.$store.dispatch('language/fetchLanguage', {
            id: from_language,
            language: 'from'
          });

          this.$store.dispatch('feedback/fetchFeedbacks', {'all': true, 'filter[booking_id]': this.$route.params.id});
          // let customer = this.$store.state.booking.booking.customer_id
          // this.$store.dispatch('booking/fetchCustomer',customer);
          if (!isEmpty(this.$store.state.booking.assigned_translator)) {
            this.$store.dispatch('booking/fetchAssignedTranslator', this.$store.state.booking.assigned_translator.translator_id);
          }

          this.$store.dispatch('booking/fetchBookingTraveltime', this.$route.params.id)
        });
      },

      ...mapActions({
        deleteUserInsideBooking: 'booking/deleteUserInsideBooking',
      })
    },
    watch: {
      "$route.params.id" () {
        // call the method which loads your initial state
        this.$store.dispatch('booking/getBooking', this.$route.params.id).then(() => {
          this.$store.dispatch('feedback/fetchFeedbacks', {'all': true, 'filter[booking_id]': this.$route.params.id});
        });
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > beforeCreate
    |--------------------------------------------------------------------------
    */
    beforeCreate () {
      this.$store.dispatch('booking/getBooking', this.$route.params.id).then(() => {
        let from_language = this.$store.state.booking.booking.from_language_id;
        this.$store.dispatch('language/fetchLanguage', {
          id: from_language,
          language: 'from'
        });

        this.$store.dispatch('feedback/fetchFeedbacks', {'all': true, 'filter[booking_id]': this.$route.params.id});
        // let customer = this.$store.state.booking.booking.customer_id
        // this.$store.dispatch('booking/fetchCustomer',customer);
        if (!isEmpty(this.$store.state.booking.assigned_translator)) {
          this.$store.dispatch('booking/fetchAssignedTranslator', this.$store.state.booking.assigned_translator.translator_id);
        }

        this.$store.dispatch('booking/fetchBookingTraveltime', this.$route.params.id)
      });

      // this.$store.dispatch('booking/fetchTranslatorLevels');
    },
     beforeRouteLeave (to, from, next) {
            let data = {
                booking_id : this.bookingId,
                user_id : this.userId,
            };
            this.deleteUserInsideBooking(data);
            next(true);
        }
  }
</script>

<style lang="scss">
  .red-title{
    color:#de4848 !important;
  }
</style>
