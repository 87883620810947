<template>
  <div class="list-base-table">

    <div class="list-base-table-top-controls" v-if="hasTopControlsSlot">
      <slot name="topControls"/>
    </div> <!-- .list-base-table-top-controls -->

    <div class="list-base-table-wrapper">

      <!-- Base List Table -->
      <el-table :data="tableData" style="width: 100%" v-loading="loading"
                :row-class-name="checkRowClass" @row-click.self="handleProfile">

        <!-- Select Column -->
        <el-table-column class-name="select-col" v-if="hasSelectColSlot && hasSelectCol"
                          :label="$t('select')" :width="selectColWidth">
            <template slot-scope="scope">
              <slot
                name="selectColumn"
                :$index="scope.$index"
                :row="scope.row"/>
            </template>
        </el-table-column>

        <el-table-column
          v-for="col in cols"
          :key="crProps(col, 'prop')"
          :prop="crProps(col, 'prop')"
          :label="crProps(col, 'label')"
          :width="crProps(col, 'width')"
          :class-name="'blt-col ' + crProps(col, 'className')"
          :sortable="crProps(col, 'sortable')">
          <template slot-scope="scope">
            <h4 class="cell-title">
              {{ crProps(col, 'label') }}
            </h4>

            <span v-if="crProps(col, 'showPopover') && mok(scope.row, crProps(col, 'prop'), col.popover)" class="cell-content">
              <el-popover trigger="hover" placement="top">
              <p>{{mok(scope.row, crProps(col, 'prop'), col.popover)}}</p>
              <div slot="reference" class="name-wrapper">
                <span v-html="mok(scope.row, crProps(col, 'prop'), col.mutator)"></span>
              </div>
            </el-popover>
            </span>

              <span v-else class="cell-content" v-html="mok(scope.row, crProps(col, 'prop'), col.mutator)"></span>

          </template>
        </el-table-column>

        <!-- Info Column -->
        <el-table-column class-name="info-col" v-if="hasInfoColSlot && hasInfoCol"
                         :label="$t('info')" :width="infoColWidth">
          <template slot-scope="scope">
            <slot
              name="infoColumn"
              :$index="scope.$index"
              :row="scope.row"/>
          </template>
        </el-table-column>

        <!-- Details Column -->
        <el-table-column class-name="details-col" v-if="hasDetailsColSlot && hasDetailsCol"
                         :label="!isEmpty(detailsColTitle)? detailsColTitle : $t('details')" :width="detailsColWidth">
          <template slot-scope="scope">
            <slot
              name="detailsColumn"
              :$index="scope.$index"
              :row="scope.row"/>
          </template>
        </el-table-column>

        <!-- Company Column -->
        <el-table-column class-name="company-col" v-if="hasCompanyColSlot && hasCompanyCol"
                         :label="$t('Companies')" :width="companyColWidth">
          <template slot-scope="scope">
            <slot
              name="companyColumn"
              :$index="scope.$index"
              :row="scope.row"/>
              <div v-for="(company, index) in scope.row.companies" :key="index">
                <p v-if="index < 3" @click="handleNameClick('company', company.id)"><u>{{ company.name }}</u></p>
                <p v-if="index > 2 && index < 4" @click="handleNameClick('see-more-company', company.municipality_id)">See More</p>
              </div>
              <!-- <div v-html="printCompany(scope.row.companies)" ></div> -->
              <!-- {{{ handleCompany(scope.row.companies) }}} -->
          </template>
        </el-table-column>

         <!-- Users Column -->
        <el-table-column class-name="users-col" v-if="hasUsersColSlot && hasUsersCol"
                         :label="$t('users')" :width="usersColWidth">
          <template slot-scope="scope">
            <slot
              name="usersColumn"
              :$index="scope.$index"
              :row="scope.row"/>
              <div v-for="(customers, index) in scope.row.customers" :key="index">
                <p v-if="index < 3" @click="handleUsersClick('user', customers.id)"><u>{{ customers.name }}</u></p>
                <p v-if="index > 2 && index < 4" @click="handleUsersClick('see-more-users', customers.pivot.department_id)">See More</p>
              </div>
          </template>
        </el-table-column>

        <!--Departments Column -->
        <el-table-column class-name="department-col" v-if="hasDepartmentColSlot && hasDepartmentCol"
                         :label="$t('departments')" :width="departmentColWidth">
          <template slot-scope="scope">
            <slot
              name="departmentsColumn"
              :$index="scope.$index"
              :row="scope.row"/>
              <div v-for="(departments, index) in scope.row.departments" :key="index">
                <p v-if="index < 3" @click="handleDepartmentsClick('departments', departments.id)"><u>{{ departments.name }}</u></p>
                <p v-if="index > 2 && index < 4" @click="handleDepartmentsClick('see-more-departments', departments.company_id)">See More</p>
              </div>
          </template>
        </el-table-column>

        <!-- Actions Column -->
        <el-table-column class-name="actions-col" v-if="hasActionsColSlot && hasActionsCol"
                         :label="$t('actions')" :width="actionsColWidth">
          <template slot-scope="scope">
            <slot
              name="actionsColumn"
              :$index="scope.$index"
              :row="scope.row"/>
          </template>
        </el-table-column>

      </el-table>
      <!-- End of Base List Table -->
    </div> <!-- .list-base-table-wrapper -->


  </div> <!-- .list-base-table -->
</template>

<script>
  import {mok, crProps, isEmpty} from "../../../js/helpers/Common";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      tableData: {
        type: Array,
        default() {
          return [];
        }
      },
      extraCols: {
        type: Array,
        default() {
          return [];
        }
      },
      detailsColWidth: {
        type: String,
        default: '',
      },
      actionsColWidth: {
        type: String,
        default: '',
      },
      selectColWidth: {
        type: String,
        default: '',
      },
      companyColWidth: {
        type: String,
        default: '',
      },
      departmentColWidth: {
        type: String,
        default: '',
      },
      usersColWidth: {
        type: String,
        default: '',
      },
      infoColWidth: {
        type: String,
        default: '',
      },
      hasDetailsCol: {
        type: Boolean,
        default: true,
      },
      hasActionsCol: {
        type: Boolean,
        default: true,
      },
      hasSelectCol: {
        type: Boolean,
        default: true,
      },
      hasCompanyCol: {
        type: Boolean,
        default: true,
      },
      hasUsersCol: {
        type: Boolean,
        default: true,
      },
      hasDepartmentCol: {
        type: Boolean,
        default: true,
      },
      hasInfoCol: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false
      },
      detailsColTitle: String,
      rowClass: {
        type: Array,
        default() {
          return [];
        }
      }

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data: function () {
      return {
        preloading: true,
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Define default columns (this should be array or objects).
       * Please refer to /src/assets/js/helpers/Common.js@crProps() for the
       * documentation of the expected values.
       *
       * @return {array}
       */
      cols: function () {

        let extra = this.extraCols;

        if (_.isEmpty(extra)) {
          extra = [
            {
              label: this.$t('id'),
              prop: 'id',
              width: '50px',
              className: 'id-col',
            },
          ];
        }

        return extra;

      }, // End of cols() method

      /**
       * Returns wether the topControls slot is defined.
       */
      hasTopControlsSlot() {
        return !!this.$slots['topControls'];
      },

      /**
       * Returns wether the detailsColumn scoped slot is defined.
       */
      hasDetailsColSlot() {
        return !!this.$scopedSlots['detailsColumn'];
      },

      /**
       * Returns wether the actionsColumn scoped slot is defined.
       */
      hasActionsColSlot() {
        return !!this.$scopedSlots['actionsColumn'];
      },

      hasSelectColSlot() {
        return !!this.$scopedSlots['selectColumn'];
      },

      /**
       * Returns wether the companyColumn scoped slot is defined.
       */
      hasCompanyColSlot() {
        return !!this.$scopedSlots['companyColumn'];
      },

      /**
       * Returns wether the userColumn scoped slot is defined.
       */
      hasUsersColSlot() {
        return !!this.$scopedSlots['usersColumn'];
      },

      /**
       * Returns wether the departmentColumn scoped slot is defined.
       */
      hasDepartmentColSlot() {
        return !!this.$scopedSlots['departmentColumn'];
      },

      /**
       * Returns wether the infoColumn scoped slot is defined.
       */
      hasInfoColSlot() {
        return !!this.$scopedSlots['infoColumn'];
      },

      iRowClass() {
        return this.rowClass
      },
      empty(v){
        return isEmpty(v)
      }

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      // Mapping of helpers
      crProps, mok,isEmpty,

      checkRowClass({row}) {
        if (!isEmpty(this.iRowClass)) {
          let className = '';
          _.each(this.iRowClass, (v) => {
            if (row[v.field]) {
              className = v.class
            }
          })
          return className
        }

        return '';
      },
       handleProfile(val) {
        if(this.$router.currentRoute.path == '/user/list')
        {
            // Redirect to the profile page.
            // window.location.href = '/#/user/profile/' + scope.row.id;
              this.$router.push({name:'user.profile', params: {id: val.id}, query:{type: val.type}})
        }
      },

      printCompany(item) {
        let companies = '';
        let counter = 1;

        item.forEach(element => {
            if(counter > 3 && counter < 5) {
              companies += 'See More';
              // break;
            } else if(counter <= 3){
            companies += `<span onClick="$emit('company-click')">${element.name}</span>` + ' ';
            }
            counter++;
        });
        return companies;
      },

      handleNameClick(modules, id) {
        switch(modules) {
          case 'company':
          this.$router.push(`company/edit/${id}`);
          break;
          case 'see-more-company':
          this.$router.push(`municipality/edit/${id}`);
          break;
        }
        // this.$router.push({name:'company-edit', params: {id: id}, query:{}})
      },

      handleUsersClick(modules, id) {
        switch(modules) {
          case 'user':
          this.$router.push({name:'user.edit.customer', params: {id: id}})
          break;
          case 'see-more-users':
          this.$router.push(`department/edit/${id}`);
          break;
        }
        // this.$router.push({name:'company-edit', params: {id: id}, query:{}})
      },

      handleDepartmentsClick(modules, id) {
        switch(modules) {
          case 'departments':
          this.$router.push(`department/edit/${id}`);
          break;
          case 'see-more-departments':
          this.$router.push(`company/edit/${id}`);
          break;
        }
        // this.$router.push({name:'company-edit', params: {id: id}, query:{}})
      },

      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      handleSubmit(val) {
       switch (val) {
          case 'assign_translator' :
          {
        if(this.multipleSelection && this.multipleSelection)
        {
        this.multipleSelection.forEach(element => {
          const payload = {
           id: element.id,
           assigned_translator_id: this.translatorName

         }
         this.updateBooking(payload).then(()=>{
         });
        });
        }
        break;
          }
        case 'assign_status':
        {
           if(this.multipleSelection && this.multipleSelection)
        {
        this.multipleSelection.forEach(element => {
          const payload = {
           id: element.id,
           status_id: this.iStatus

         }
         this.updateBooking(payload).then(()=>{
         });
        });
        }
          break;

        }
        case 'assign_expiry':
        {
           if(this.multipleSelection && this.multipleSelection)
        {
        this.multipleSelection.forEach(element => {
          const payload = {
           id: element.id,
           expiry: this.expiry

         }
         this.updateBooking(payload).then(()=>{
         });
        });
        }
          break;
        }
       }
      }

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted: function () {

    }, // End of Component > mounted

  } // End of export default
</script>

<style>


</style>
