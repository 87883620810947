<template>
    <div class="app-page bookings-page">

        <page-header :title="$t('employees')" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">

                    <div class="app-col full-width">
                        <div class="content-body">

                            <employees-list :v-populate-payload="payloadParams"/>

                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->

        </div> <!-- /.app-container -->
    </div> <!-- /.app-page bookings-page -->

</template>

<script>
import fab from '~/components/displays/FloatingActionButton'
export default {
    components: {fab},
    data(){
        return {
            payloadParams: {
                include:'translator_data',
                'filter[employee]': 'true',
                'sort': '-created_at',
            }
        }
    },
    methods:{
        handleUpdate(e){
            this.payloadParams = e
            console.log(this.payloadParams)
        }
    }
}
</script>

<style lang="scss" scoped>
 .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }

</style>
