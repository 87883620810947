/*
|--------------------------------------------------------------------------
| Financial > Price List > getters
|--------------------------------------------------------------------------
|
| Partial file for getters object related to the scope.
*/

export default {

  entitySelectorForm: (state) => state.entitySelectorForm,

  /**
   * @returns {Array}
   */
  listData: (state) => state.list.data,

  /**
   * @returns {Object}
   */
  listPagination: (state) => state.list.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingList: (state) => state.list.loading,

  /**
   * @returns {Array}
   */
  listImportData: (state) => state.listImport.data,

  /**
   * @returns {Object}
   */
  listImportPagination: (state) => state.listImport.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingListImport: (state) => state.listImport.loading,

  /**
   * @returns {Boolean}
   */
  isLoadingItem: (state) => state.item.loading,

  /**
   * @returns {Boolean}
   */
  isLoadingTemplateItem: (state) => state.templateItem.loading,

  /**
   * @returns {Boolean}
   */
  isLoadingImportItem: (state) => state.importItem.loading,

  /**
   * @returns {boolean}
   */
  isComposeModeAdd: (state) => state.isComposeModeAdd,

  /**
   * @returns {boolean}
   */
  isComposeModeEdit: (state) => state.isComposeModeEdit,
}
