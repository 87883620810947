<template>
  <div class="app-fields financial-additional-shared-fields">

    <!-- Shortcuts -->
    <el-form-item :id="genContainerId('shortcuts',formElId)"
                  :label="$t('tfv_fn_shortcuts')" class="label-block">
      <el-button v-for="v in shortcutButtons"
                 :key="v.key"
                 size="mini"
                 @click="handleClickShortcut(v.key)">
        {{ v.label }}
      </el-button>
    </el-form-item>

    <!-- Others -->
    <el-form-item :id="genContainerId('custom_shortcut',formElId)"
                  :label="$t('tfv_fn_custom_shortcuts')"
                  class="label-short custom-shortcut-field">

      <el-input :id="genFieldId('custom_shortcut',formElId)"
                v-model="customShortcut"
                size="mini">
        <el-button slot="append" size="mini" type="default"
                   @click="handleClickShortcut(customShortcut)">
          {{ $t('tfv_actions_apply') }}
        </el-button>
      </el-input>
    </el-form-item>

    <hr class="hr-type1">

    <!-- Salary Type -->
    <el-form-item
      v-if="enableSalaryType"
      :id="genContainerId('salary_type',formElId)"
      :label="$t('tfv_fn_salary_type')"
      class="label-short"
      :class="[getValidFieldClass('salary_type')]"
    >
      <el-select :id="genFieldId('salary_type',formElId)"
                 v-model="iValue.salary_type">
        <el-option v-for="v in salaryTypeOpts"
                   :key="v.key"
                   :value="v.key"
                   :label="v.label"/>
      </el-select>
    </el-form-item>


    <!-- Name / Adjustment being done -->
    <el-form-item
      :id="genContainerId('name',formElId)"
      :label="$t('tfv_fn_adjustment_being_done')"
      class="label-short"
      :class="[getValidFieldClass('name')]"
    >
      <el-input :id="genFieldId('name',formElId)"
                v-model="iValue.name"/>
    </el-form-item>

    <!-- Description / Explanation  -->
    <el-form-item
      :id="genContainerId('description',formElId)"
      :label="$t('tfv_fn_description')" class="label-short"
      :class="[getValidFieldClass('description')]"
    >
      <el-input :id="genFieldId('description',formElId)"
                v-model="iValue.description"/>
    </el-form-item>

    <!-- Price / Amount -->
    <el-form-item
      :id="genContainerId('price',formElId)"
      :label="target === 'invoice' ? $t('tfv_fn_amount') :  $t('tfv_fn_amount_deduction')"
      class="label-short"
      :class="[getValidFieldClass('price')]"
    >
      <el-input :id="genFieldId('price',formElId)"
                v-model="iValue.price"/>
    </el-form-item>


    <!-- Add Button -->
    <el-form-item v-if="!iEditMode"
                  :id="genContainerId('add_btn',formElId)"
                  class="el-form-actions">
      <el-button :id="genFieldId('cancel_btn',formElId)"
                 type="default"
                 class="modal-size"
                 @click="handleClickCancel">
        {{ $t('cancel') }}
      </el-button>
      <el-button :id="genFieldId('add_btn',formElId)"
                 type="primary"
                 class="modal-size"
                 @click="handleClickAdd">
        {{ $t('add') }}
      </el-button>
    </el-form-item>


    <!-- Update and Reset Button -->
    <template v-else>
      <el-form-item :id="genContainerId('update_btn',formElId)" class="el-form-actions">
        <el-button :id="genFieldId('update_btn',formElId)"
                   type="primary"
                   class="modal-size"
                   @click="handleClickUpdate">
          {{ $t('update') }}
        </el-button>
        <el-button :id="genFieldId('reset_btn',formElId)"
                   type="primary"
                   class="modal-size"
                   @click="handleClickReset">
          {{ $t('reset') }}
        </el-button>
      </el-form-item>
    </template> <!-- v-else -->


  </div> <!-- /.app-form financial-additional-shared-fields -->
</template>

<script>
import {mapGetters} from 'vuex';
import {additional} from '~/js/store/financial/additional/shared/props';
import includes from 'lodash/includes';
import forOwn from 'lodash/forOwn';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

export default {

  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props: {

    formElId: {
      type: String,
      default: 'financial-additional-shared-fields'
    },

    value: {
      type: Object,
      default: () => _.cloneDeep(additional)
    },

    target: {
      type: String,
      default: ''
    },

    enableSalaryType: {
      type: Boolean,
      default: true
    },

    editMode: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    }

  }, // End of Component > props

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data () {
    return {

      customShortcut: '',
      validFields: {}
    };
  }, // End of Component > data

  /*
  |--------------------------------------------------------------------------
  | Component > watch
  |--------------------------------------------------------------------------
  */
  watch: {
    iVisible (visible) {
      if (visible) {
        this.validFields = {};
      }
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapGetters('financialOptions', [
      'salaryTypeOpts'
    ]),

    /**
     * Returns array of values defining the shortcut buttons.
     * @return {array}
     */
    shortcutButtons () {
      return [
        {key: '500', label: '500kr'},
        {key: '400', label: '400kr'},
        {key: '300', label: '300kr'},
        {key: '200', label: '200kr'},
        {key: '100', label: '100kr'}
      ];
    },

    /**
     * Interface for the v-model of this component.
     */
    iValue: {
      get () { return this.value; },
      set (v) { this.$emit('input', v); }
    },

    /**
     * Interface for this.editMode of this component.
     */
    iEditMode: {
      get () { return this.editMode; },
      set (v) { this.$emit('update:editMode', v); }
    },

    iVisible: {
      get () {
        return this.visible
      },
      set (v) {
        this.$emit('update:visible', v);
      }
    }

  }, // End of Component > computed

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {

    /**
     * Handler when a shortcut button was clicked.
     * @return {void}
     */
    handleClickShortcut (v) {

      // Set the following values programmatically.
      let o = {};

      if (this.enableSalaryType) {
        // If Salary Fields are enabled.
        o.salary_type = 'charge';
        o.name = 'Vite';
        o.description = 'Ej tolkat utan att meddela avbokning el sen avbokning';
        o.price = `-${v}`;

      } else {
        // If Invoice Fields are enabled.
        o.name = !window._.isNil(this.$route.params) && !window._.isNil(this.$route.params.id)
          ? `#${this.$route.params.id}` : '';
        o.description = '';
        o.price = v;
      }


      window._.merge(this.iValue, o);
    },

    /**
     * Handler when the Add button was clicked.
     *
     * @return {void}
     */
    handleClickAdd () {
      this.validFields = this.validateForm();
      if (!some(this.validFields, (f) => !f)) {
        this.$emit('add', this.target);
      }
    },

    /**
     * Handler when the Update/Save button was clicked during edit mode.
     *
     * @return {void}
     */
    handleClickUpdate () {
      this.validFields = this.validateForm();
      if (!some(this.validFields, (f) => !f)) {
        this.$emit('update', this.target);
      }
    },

    /**
     * Handler when the Reset button was clicked.
     *
     * @return {void}
     */
    handleClickReset () {
      this.$emit('reset', this.target);
    },

    handleClickCancel () {
      this.iVisible = false;
    },

    /**
     * @return {Object}
     */
    validateForm () {
      let required = ['name', 'description', 'price'];
      let result = {};

      if (this.enableSalaryType) {
        required.push('salary_type');
      }

      forOwn(this.iValue, (v, k) => {
        if (includes(required, k)) {
          result[k] = v !== '';
        }
      });

      return result;
    },

    /**
     * @param {string} k
     * @return {boolean}
     */
    isValidField (k) {
      if (
        !isNil(this.validFields)
        && !isEmpty(this.validFields)
        && !isNil(this.validFields[k])
      ) {
        return this.validFields[k];
      } else {
        return true;
      }
    },

    /**
     * @param {string} k
     * @return {string}
     */
    getValidFieldClass (k) {
      return this.isValidField(k) ? '' : 'is-error';
    }

  }, // End of Component > methods

  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted () {
    this.$store.dispatch('financialOptions/loadSalaryTypeOpts');
  } // End of Component > mounted

} // End of export default
</script>
