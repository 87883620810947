<template>
    <div class="app-page financial-export-page">
        <page-header :title="$t('financial_export')" :back-enabled="true"/>

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col">

                        <financial-export-list/>

                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->
    </div> <!-- /.app-page financial-export-page -->
</template>

<script>
  import FinancialExportList from '~/components/lists/financial/export/FinancialExportList';

  // noinspection JSUnusedGlobalSymbols
  export default {
    components: {
      FinancialExportList,
    }
  }
</script>
