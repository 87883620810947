<template>
  <div :class="`app-row ${iRefs}`" :id="iRefs" >
    <div class="app-col full-width">
      <h3>{{iTitle}}</h3>
      <el-button type="default" id="show-modal" @click="handleAdd" icon="fa fa-plus">{{$t('add')}}</el-button>

      <div class="app-list booking-list">
        <el-table
        :data="unavailableData"
        style="width: 100%">
            <el-table-column
            :label="$t('description')">
              <template slot-scope="scope">
                <span>{{ scope.row.description }}</span>
              </template>
            </el-table-column>
            <el-table-column
            :label="$t('unavailable_for')">
              <template slot-scope="scope">
                <span v-for="element in scope.row.translator_unvailable_booking_types" class="item" v-bind:key="element">
                <span>{{ element.booking_type }}</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column
            :label="$t('address')">
              <template slot-scope="scope">
                <span >{{ scope.row.Address }}</span>
              </template>
            </el-table-column>
            <el-table-column
            :label="$t('unavailable_from')">
              <template slot-scope="scope">
               <span >{{ scope.row.unavailable_from }}</span>
              </template>
            </el-table-column>
            <el-table-column
            :label="$t('unavailable_until')">
              <template slot-scope="scope">
                <span >{{ scope.row.unavailable_until }}</span>
              </template>
            </el-table-column>
            <el-table-column
            :label="$t('actions')">
              <template slot-scope="scope">
                <el-button class="circle" type="success" :title="$t('edit')" @click.stop="handleEdit(scope)">
                  <span class="fa fa-pencil"></span>
                </el-button>
                <el-button class="circle" type="danger" :title="$t('delete')" @click.stop="handleDelete(scope)">
                  <span class="fa fa-trash"></span>
                </el-button>
              </template>
            </el-table-column>
        </el-table>

        <!-- Pagination -->
        <el-pagination
          layout="prev, pager, next"
          :page-size="listPagination.per_page"
          :total="listPagination.total"
          :current-page="listPagination.current_page"
          @current-change="reloadLists">
        </el-pagination>

      </div> <!-- /.app-list booking-list -->
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {isEmpty,sd} from "~/js/helpers/Common";
export default {
  name:'userUnavailableList',
  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props:{
    title:{
      type:String,
      default: 'Translator unavailable times'
    },
    refs:{
      default:'unavailable'
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data(){

    const _this = this;
    return {
      dialogFormVisible: false,
      unavailableObject:{
        translator_id:'',
        description:'',
        unavailable_to:'',
        Address:'',
        unavailable_from:'',
        unavailable_until:'',
      }
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed:{

    iTitle(){
      return this.title
    },
    iRefs(){
      return this.refs
    },
    ...mapGetters('translatorUnavailableTimes', {
        listPagination: 'getPagination',
        unavailableData: 'getUnavailables'
    }),
  },

  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  methods:{
    ...mapActions('translatorUnavailableTimes',{
      getList: 'getUnavailablesList',
      createunavailable:'createUnavailable',
      deleteEntry:'deleteUnavailable'
    }),
    cancelModal() {
     this.dialogFormVisible = false;
      this.unavailableObject={
       translator_id:'',
       description:'',
       unavailable_to:'',
       Address:'',
       unavailable_from:'',
       unavailable_until:'',
      };
    },
    handleDelete (scope) {
      this.$confirm('This will delete the entry. Continue?', this.$t('warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.deleteEntry(scope.row.id).then(response => {
          this.getList({
            user_translator_id: this.$route.params.id
          });
        });
      }).catch(() => {
        // Silence is golden.
      });

    }, // End of handleDelete() method
    handleEdit (scope) {
      this.$router.push({path: '/translator/unavailable-times/edit/' + scope.row.id});
    },
    handleAdd(){
      this.$router.push({path: '/translator/unavailable-times/add/'+this.$route.params.id});
    },
    reloadLists (n) {
      const params = {page: n, user_translator_id: this.$route.params.id};
      this.getList(params);
    },

  },
  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {
    this.getList({
    user_translator_id: this.$route.params.id,
    page:1
    });
  },// End of Component > mounted
}
</script>
<style>
.item + .item:before {
  content: ", ";
}
</style>
