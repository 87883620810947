<template>
  <div>
    <el-row>
      <el-col :span="12">
        <h3>{{$t('traveltime')}}</h3>
      </el-col>
      <el-col :span="12" class="text-right" v-if="!edit">
        <el-button type="primary" size="mini" @click="handleAddTraveltime()">{{$t('add_traveltime')}}</el-button>
      </el-col>
    </el-row>
    <div v-show="!dialogVisible">
      <div v-for="(i,index) in iTraveltimes" :key="index">
        <span>{{$t('distance')}}: {{i.travel_distance}}</span>
        <br />
        <span>{{$t('traveltime')}}: {{duration(i.travel_time)}}</span>
        <br />
        <span>{{$t('travel_by')}}: {{i.traveled_by}}</span>
        <br />
        <span>{{$t('comment')}}: {{i.comment}}</span>
        <br />

        <div class="text-right">
          <el-button
            type="primary"
            size="mini"
            @click="handleUpdateTraveltime(i,index)"
          >{{$t('update_traveltime')}}</el-button>
        </div>
      </div>
    </div>

    <!-- <el-dialog
  title="update Booking Traveltime"
  :visible.sync="dialogVisible"
  width="50%"
  :modal-append-to-body="true"
    :append-to-body="true">-->
    <!-- <feedback-form :feedbacks="iFeedbacks" :booking="iBooking" @update="setDialogVisible"></feedback-form> -->
    <update-traveltime-form
      :booking="iBooking"
      :method="method"
      :traveltime="traveltime"
      @update="setDialogVisible"
      v-show="dialogVisible"
      @updateMethod="changeMethod"
    ></update-traveltime-form>
    <!-- </el-dialog> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UpdateTraveltimeForm from './UpdateTraveltimeForm';
import { isEmpty } from '../../../../js/helpers/Common';
export default {
  name: 'bookingTraveltime',
  props: {
    traveltimes: {
      type: Array,
      default: []
    },
    booking: {
      type: Object,
      default: {}
    }
  },
  components: {
    // FeedbackForm
    UpdateTraveltimeForm
  },
  data() {
    return {
      dialogVisible: false,
      traveltime: {
        id: '',
        entity_id: '',
        entity_type: 'bookings',
        by_user_id: '',
        travel_time: 0,
        travel_distance: 0,
        comment: '',
        manual: false,
        traveled_by: '',
        travel_time_formated: ''
      },
      method: 'update',
      edit: false
    };
  },
  computed: {
    iTraveltimes: {
      get() {
        return this.traveltimes;
      },
      set(v) {
        this.$emit('input', v);
      }
    },
    iBooking() {
      return this.booking;
    }
  },
  methods: {
    duration(v) {
      return moment()
        .startOf('day')
        .add(v, 'minutes')
        .format('HH:mm:ss');
    },
    handleUpdateTraveltime(v, index) {
      this.dialogVisible = true;
      this.traveltime.travel_time_formated = moment()
        .startOf('day')
        .add(v.travel_time, 'minutes')
        .format('HH:mm:ss');
      Object.assign(this.traveltime, v);
    },
    setDialogVisible(event) {
      this.dialogVisible = event;
    },
    isEmpty(v) {
      return isEmpty(v);
    },
    handleAddTraveltime() {
      this.dialogVisible = true;
      let v = {
        entity_id: this.booking.id,
        by_user_id: this.booking.assigned_translator.id
      };
      this.method = 'add';
      this.edit = true;
      Object.assign(this.traveltime, v);
    },
    changeMethod() {
      this.method = 'update';
    }
  },
  mounted() {}
};
</script>

<style>
</style>
