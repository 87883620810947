<template>
    <div class="app-display" :class="genBem(blockCls)">

        <!-- Header -->
        <div :class="genBem(blockCls, 'header')">
            <!-- Header Title -->
            <h3 v-if="title !== ''"
                :class="genBem(blockCls, 'header-title')">{{ title }}</h3>

        </div> <!-- End of Header -->

        <!-- Preloader -->
        <div v-if="listLoading"
             :class="genBem(blockCls, 'preloader')"
             class="app-display-flex app-flex-center"
             style="height: 100%;">
            <div :class="genBem(blockCls, 'preloader-wrapper')">
                <p :class="genBem(blockCls, 'preloader-label')">
                    Generating Preview...
                </p>
                <div :class="genBem(blockCls, 'preloader-icon')">
                    <span v-loading="true"></span>
                </div>
            </div>
        </div> <!-- End of Preloader -->

        <!-- Actual Content -->
        <div v-else
             :class="genBem(blockCls, 'body')">

            <table v-if="list.length > 0"
                   :class="genBem(blockCls, 'table')">
                <thead>
                <tr>
                    <th class="col-header col-adjustment">
                      <span class="cell">{{ $t('tfv_fn_adjustment') }}</span>
                    </th>
                    <th class="col-header col-description">
                      <span class="cell">{{ $t('tfv_fn_description') }}</span>
                    </th>
                    <th class="col-header col-amount">
                      <span class="cell">{{ $t('tfv_fn_amount') }}</span>
                    </th>
                    <th class="col-header col-per-unit">
                      <span class="cell">{{ $t('tfv_fn_per_unit') }}</span>
                    </th>
                    <th class="col-header col-subtotal">
                      <span class="cell">{{ $t('tfv_fn_subtotal') }}</span>
                    </th>
                    <th class="col-header col-action">
                      <span class="cell">{{ $t('actions') }}</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in list">
                    <td class="col-value">
                        <span class="cell">
                            {{ setTableValue(item, 'name') }}
                        </span>
                    </td>
                    <td class="col-value">
                        <span class="cell">
                            {{ setTableValue(item, 'description') }}
                        </span>
                    </td>
                    <td class="col-value">
                        <span class="cell">
                            {{ setTableValue(item, 'quantity') }}
                        </span>
                    </td>
                    <td class="col-value">
                        <span class="cell">
                            {{ setTableValue(item, 'price') }}
                        </span>
                    </td>
                    <td class="col-value">
                        <span class="cell">
                            {{ setTableValue(item, 'subtotal') }}
                        </span>
                    </td>
                    <td class="col-value">
                        <el-button class="circle" type="success" :title="$t('edit')" @click="handleClickUpdatePrefillV2(index)">
                            <span class="fa fa-pencil"></span>
                        </el-button>
                        <el-button class="circle" type="danger" :title="$t('remove')" @click="handleClickDeletePrefill(item)">
                            <span class="fa fa-trash"></span>
                        </el-button>
                    </td>
                </tr>
                </tbody>
            </table>

            <div v-else
                 style="width: 100%; text-align: center">
                <h4>{{ $t('no_data') }}</h4>
            </div>

        </div> <!-- End of Body -->

        <financial-preview-compose-prefill-modal
                :visible.sync="isShowComposePrefillModal"
                :target="target"
                :bookingId="bookingId"
                :item="indexItemInList"/>


    </div> <!-- template wrapper -->
</template>

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex';
  import {populate} from "~/js/helpers/Vuex";
  import FinancialPreviewComposePrefillModal
    from '~/components/displays/financial/export/FinancialPreviewComposePrefillModal';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      FinancialPreviewComposePrefillModal
    },

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      /**
       * Boolean for the visibility of the display.
       * Only used if this component will be inside a modal.
       */
      visible: {
        type: Boolean,
      },

      /**
       * String to be used as the title in header.
       */
      title: {
        type: String,
        default: ''
      },

      /**
       * Varies between invoice | salary
       */
      target: {
        type: String,
        default: '',
      },

      /**
       * The current Booking's ID
       */
      bookingId: {
        default: 0
      },

      /**
       * Boolean to determine whether to show header buttons.
       */
      isShowHeaderButtons: {
        type: Boolean,
        default: true
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-preview-display',
        isShowComposePrefillModal: false,
        listCurrentIndex: 0,
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    watch: {

      /**
       * Watcher method for this.visible property.
       *
       * @param  {Boolean} v
       * @return {void}
       */
      visible (v) {
        if (v) this.loadData();
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters({
        // Invoice
        invoiceList: 'financialPreviewInvoice/listData',
        invoiceListLoading: 'financialPreviewInvoice/listLoading',

        // Salary
        salaryList: 'financialPreviewSalary/listData',
        salaryListLoading: 'financialPreviewSalary/listLoading'
      }),

      /**
       * Returns the target list Object.
       * @returns {*}
       */
      list () {
        const list = `${this.target}List`;
        return this[list];
      },

      /**
       * Returns boolean for the loading state of the list data.
       *
       * @returns {boolean}
       */
      listLoading () {
        const listLoading = `${this.target}ListLoading`;
        return this[listLoading];
      },

      firstItemInList () {
        if (this.list.length > 0) {
          // noinspection JSPotentiallyInvalidTargetOfIndexedPropertyAccess
          return this.list[0];
        } else {
          return {};
        }
      },

      indexItemInList () {
        if (this.list.length > 0) {
          // noinspection JSPotentiallyInvalidTargetOfIndexedPropertyAccess
          return this.list[this.listCurrentIndex];
        } else {
          return {};
        }
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapMutations({
        // Invoice
        invoiceSetTargetBooking: 'financialPreviewInvoice/setTargetBooking',

        // Salary
        salarySetTargetBooking: 'financialPreviewSalary/setTargetBooking',
      }),

      ...mapActions({
        // Invoice
        invoiceLoadList: 'financialPreviewInvoice/browse',

        // Salary
        salaryLoadList: 'financialPreviewSalary/browse',

        deletePrefill: 'financialPrefillInvoice/delete'
      }),

      /**
       * Method for setting the target booking id for Vuex use.
       *
       * @param id - Booking's ID
       * @return {void}
       */
      setTargetBooking (id) {
        const action = `${this.target}SetTargetBooking`;
        this[action](id);
      },

      /**
       * Method to load the data according to the selected target.
       *
       * @return {Promise}
       */
      loadData () {
        const action = `${this.target}LoadList`;
        return this[action]();
      },

      /**
       * Helper method for setting the value of table data.
       *
       * @param {object} item - Row object and the source of the value.
       * @param {object} key - The key (in the row) to reference the value.
       * @return {*}
       */
      setTableValue (item, key) {
        const v = item[key];
        const isExists = !window._.isNil(v) && v !== '' && v !== 0;
        const stringTypes = ['name', 'description'];

        if (window._.includes(stringTypes, key)) {
          return isExists ? v : 'None';

        } else {
          return isExists ? v : '0';
        }
      },

      /**
       * Handle when the show compose prefill modal button is clicked.
       *
       * @return {void}
       */
      handleClickUpdatePrefill () {
        this.isShowComposePrefillModal = true;
      },

      handleClickUpdatePrefillV2 (index) {
        this.isShowComposePrefillModal = true;
        this.listCurrentIndex = index;
      },

      handleClickDeletePrefill (item) {
        this.deletePrefill({
          booking: this.bookingId,
          item: item.type
        }).then(() => {
          this.$notify({
            type: 'success', message: 'Updated Successfully'
          });
        })
        .catch((e) => {
          this.$notify({
            type: 'error', message: e.response.data
          });
        });
      },

      populatePrefills() {
        populate(this, 'loadData', 'list', {
          ref: `Preview (${this.target})`,
          force: true,
        });
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      this.setTargetBooking(this.bookingId);
      this.populatePrefills();
    },

  }
</script>