<template>
  <div>
      <div v-if="!isEmpty(customerFeedback)">
        <strong>{{$t('customer_feedback')}}</strong>
        <el-rate disabled v-model="customerFeedback.rating" :colors="['#FF6565']" disabled-void-color="#A9A9A9"></el-rate>
        <p>{{customerFeedback.comment}}</p>
      </div>
  </div>
</template>

<script>
import { isEmpty } from '../../../../js/helpers/Common';
export default {
  props:{
    feedback:{
      type: Array,
      default(){
        return []
      }
    }
  },
  data(){
    return {}
  },
  computed:{
    iFeedback(){
      return this.feedback
    },
    customerFeedback(){
      let val = this.iFeedback.filter(o=>o.review_from_type==2).find(o=>o.rating<3)
      return !isEmpty(val) ? val: this.iFeedback.find(o=>o.review_from_type==2)
    }
    
  },
  methods:{
    isEmpty(v) {
      return isEmpty(v)
    },
  }
}
</script>

<style>

</style>
