<template>
  <div class="app-form app-fields sector-batch-settings-max-travel-fields">
    <el-row>
      <el-col :md="10">
        <h4>{{$t('max_travel_distance_km')}}</h4>
        <el-form-item :id="genContainerId('global',formElId)" label="Global" class="label-medium">
          <el-input v-model="form.max_travel_distance.global" :id="genFieldId('global',formElId)" />
        </el-form-item>

        <el-form-item
          :id="genContainerId('certified',formElId)"
          :label="$t('certified')"
          class="label-medium"
        >
          <el-input
            v-model="form.max_travel_distance.certified"
            :id="genFieldId('certified',formElId)"
          />
        </el-form-item>

        <el-form-item
          :id="genContainerId('certified_in_law',formElId)"
          :label="$t('certified_in_law')"
          class="label-medium"
        >
          <el-input
            v-model="form.max_travel_distance.certified_in_law"
            :id="genFieldId('certified_in_law',formElId)"
          />
        </el-form-item>

        <el-form-item
          :id="genContainerId('certified_in_health',formElId)"
          :label="$t('certified_in_health')"
          class="label-medium"
        >
          <el-input
            v-model="form.max_travel_distance.certified_in_health"
            :id="genFieldId('certified_in_health',formElId)"
          />
        </el-form-item>

        <el-form-item :id="genContainerId('trained',formElId)" :label="$t('trained')" class="label-medium">
          <el-input
            v-model="form.max_travel_distance.trained"
            :id="genFieldId('trained',formElId)"
          />
        </el-form-item>

        <el-form-item
          :id="genContainerId('read_courses',formElId)"
          :label="$t('read_courses')"
          class="label-medium"
        >
          <el-input
            v-model="form.max_travel_distance.read_courses"
            :id="genFieldId('read_courses',formElId)"
          />
        </el-form-item>

        <el-form-item :id="genContainerId('layman',formElId)" :label="$t('layman')" class="label-medium">
          <el-input v-model="form.max_travel_distance.layman" :id="genFieldId('layman',formElId)" />
        </el-form-item>
      </el-col>
      <el-col :offset="2" :md="12">
        <h4>{{$t('max_travel_time_minutes')}}</h4>
        <el-form-item :id="genContainerId('global',formElId)" label="Global" class="label-medium">
          <el-input v-model="form.max_travel_time.global" :id="genFieldId('global',formElId)" />
        </el-form-item>

        <el-form-item
          :id="genContainerId('certified',formElId)"
          :label="$t('certified')"
          class="label-medium"
        >
          <el-input
            v-model="form.max_travel_time.certified"
            :id="genFieldId('certified',formElId)"
          />
        </el-form-item>

        <el-form-item
          :id="genContainerId('certified_in_law',formElId)"
          :label="$t('certified_in_law')"
          class="label-medium"
        >
          <el-input
            v-model="form.max_travel_time.certified_in_law"
            :id="genFieldId('certified_in_law',formElId)"
          />
        </el-form-item>

        <el-form-item
          :id="genContainerId('certified_in_health',formElId)"
          :label="$t('certified_in_health')"
          class="label-medium"
        >
          <el-input
            v-model="form.max_travel_time.certified_in_health"
            :id="genFieldId('certified_in_health',formElId)"
          />
        </el-form-item>

        <el-form-item :id="genContainerId('trained',formElId)" :label="$t('trained')" class="label-medium">
          <el-input v-model="form.max_travel_time.trained" :id="genFieldId('trained',formElId)" />
        </el-form-item>

        <el-form-item
          :id="genContainerId('read_courses',formElId)"
          :label="$t('read_courses')"
          class="label-medium"
        >
          <el-input
            v-model="form.max_travel_time.read_courses"
            :id="genFieldId('read_courses',formElId)"
          />
        </el-form-item>

        <el-form-item :id="genContainerId('layman',formElId)" :label="$t('layman')" class="label-medium">
          <el-input v-model="form.max_travel_time.layman" :id="genFieldId('layman',formElId)" />
        </el-form-item>
      </el-col>
    </el-row>
  </div>
  <!-- /.app-form app-fields sector-batch-settings-max-travel-fields -->
</template>

<script>
let formFresh = {
  max_travel_distance: {
    global: 1,
    certified: '',
    certified_in_law: '',
    certified_in_health: '',
    trained: '',
    read_courses: '',
    layman: ''
  },
  max_travel_time: {
    global: '',
    certified: '',
    certified_in_law: '',
    certified_in_health: '',
    trained: '',
    read_courses: '',
    layman: ''
  }
};

import { mapGetters, mapActions } from 'vuex';

export default {
  /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
  props: {
    formElId: {
      type: String,
      default: ''
    },

    form: {
      type: Object,
      default() {
        return _.cloneDeep(formFresh);
      }
    }
  }, // End of Component > props

  /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
  data() {
    return {};
  }, // End of Component > data
  mounted() {
    console.log(this.form);
  }
}; // End of export default
</script>
