/*
|--------------------------------------------------------------------------
| Financial > Shared > methods > loadSettings
|--------------------------------------------------------------------------
| Partial file as a reusable method for it's purpose.
*/

'use strict';

/**
 * Method where Financial Charges values are cloned
 *
 * @param {object} state - the state of the store's scope.
 * @param {object} payload - contains object of values that's usable inside the method.
 * @param {string} payload.src - the string reference of the source of the data.
 * @param {string} payload.dest - the string reference of the destination of the data.
 *
 * @return {void}
 */
export default function (state, payload = {}) {
  if (isValid(payload.src) && isValid(payload.dest)) {
    const {src, dest} = payload;
    const srcVal = _.get(state.settings.inconvenience_charge, src);
    _.set(state.settings.inconvenience_charge, dest, srcVal);

  } else {
    console.error('Either src or dest is invalid.');
  }
}

function isValid (v) {
  return !(_.isNil(v) && v === '');
}