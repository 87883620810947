/*
|--------------------------------------------------------------------------
| Financial > Invoice > mutations
|--------------------------------------------------------------------------
| Partial file for mutations object related to the scope.
*/

'use strict';

import setIsSettingsLoaded from '../shared/methods/setIsSettingsLoaded';
import cloneCharges from '../shared/methods/cloneCharges';
import cloneInconvenienceCharges from '../shared/methods/cloneInconvenienceCharges';
import resetSettings from '../shared/methods/resetSettings';
import resetSettingsPayload from '../shared/methods/resetSettingsPayload';

export default {
  setIsSettingsLoaded,
  cloneCharges,
  cloneInconvenienceCharges,
  resetSettings,
  resetSettingsPayload,
}
