/*
|--------------------------------------------------------------------------
| Store > Messaging-centre > Blogs
|--------------------------------------------------------------------------
|
| A Class file that manages all the properties and abilities in relation
| to Logs.
|
*/
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
};
