/*
|--------------------------------------------------------------------------
| Store > Municipality > State
|--------------------------------------------------------------------------
|
| This file contains the state property of User Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {
    gep
} from "../../../helpers/Vuex";

let target = 'booking-resources';
let apiPrefix = window.app_api_prefix_3;

let composeProps = {
    id: 0,
    name: '',
    description: '',
    economic_value: '',
    location: '',
    location_description: '',
    is_busy: false,
    types: [],
};

export default {

    model_info: {
        name: 'Resource Store',
        module: 'resources',
        table: target,
    },

    api: {
        browse: {
            method: 'get',
            path: gep(target, apiPrefix),
            params: {
                per_page: 10
            },
            data: {},
        },
        browseWithBooking: {
            method: 'get',
            path: gep('booking/{id}/resources', apiPrefix),
            params: {
                per_page: 50
            },
            data: {},
        },
        browseResourcesByBooking: {
            method: 'get',
            path: gep('booking/{id}/resources-assigned', apiPrefix),
            params: {
                per_page: 50
            },
            data: {},
        },
        read: {
            method: 'get',
            path: gep(target + '/{id}', apiPrefix),
            params: {},
            data: {},
        },
        edit: {
            method: 'put',
            path: gep(target + '/{id}', apiPrefix),
            params: {},
            data: {},
        },
        add: {
            method: 'post',
            path: gep(target, apiPrefix),
            params: {},
            data: {},
        },
        addWithBooking: {
            method: 'post',
            path: gep('booking/{id}/resources', apiPrefix),
            params: {},
            data: {},
        },
        delete: {
            method: 'delete',
            path: gep(target + '/{id}', apiPrefix),
            params: {},
            data: {},
        },
        deleteAssignment: {
            method: 'delete',
            path: gep('booking/{booking_id}/resources-assignment-delete/{id}', apiPrefix),
            params: {},
            data: {},
        },
    },

    composeFresh: _.cloneDeep(composeProps),
    compose: _.cloneDeep(composeProps),

    payloadData: {}, // End of payloadData state

    autoInjectPayload: true,
    autoReflectItem: true,
    autoUpdateList: true,
    
    is_show_on_booking: false

} // End of export default