import { render, staticRenderFns } from "./PriceBusinessRulesForm.vue?vue&type=template&id=44f2f500&"
import script from "./PriceBusinessRulesForm.vue?vue&type=script&lang=js&"
export * from "./PriceBusinessRulesForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports