import { gep } from "../../../../helpers/Vuex";

let apiPrefix = window.app_api_prefix_3;

let composeProps = {
  'name':'',
  'state':'',
  'created_by': '',
  'updated_by': ''
};

let asOptionsParams = { 
  all:true, 'fields[booking-issue-tags]': 'id,name', 'filter[name]' : true
};

export default {
  api:{
    browseAsOptions: { method: 'get', path: gep('booking-issue-tags', apiPrefix), params: {all: true, status: 1}},
    browse: {method:'get',path:gep('booking-issue-tags',apiPrefix),params: {per_page: 25}},
    read:{method:'get',path:gep('booking-issue-tags/{id}',apiPrefix),params:{},data:{},},
    add:{method:'post',path:gep('booking-issue-tags',apiPrefix),params:{},data:{},},
    edit:{method:'put',path:gep('booking-issue-tags/{id}',apiPrefix),params:{},data:{},},
    delete:{method:'delete',path:gep('booking-issue-tags/{id}',apiPrefix),params:{},data:{},},
    getBookingIssueTagsPerPage:{method:'delete',path:gep('booking-issue-tags',apiPrefix),params:{},data:{},},
    
  },
  autoInjectPayload: true,
  autoReflectItem: true,
  autoUpdateList: true,

  composeFresh: _.cloneDeep(composeProps),
  compose: _.cloneDeep(composeProps),

  payloadData: {}, // End of payloadData state

}