<template>
    <div class="app-page bookings-issue-tags-page">

        <page-header :title="`Booking Issues Tagged As \'${this.tagData.name}\'`"/>

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col full-width">
                        <div class="content-body">
                            <el-table :data="issueList" style="width: 100%">
                                <el-table-column width="150" label="Issue ID" prop="booking_issue_id"></el-table-column>
                                <el-table-column width="150" label="Booking ID" prop="booking_id"></el-table-column>
                                <el-table-column width="150" label="Created Date Time" prop="created_at"></el-table-column>
                                <el-table-column label="Issue Type" prop="issue_type"></el-table-column>
                                <el-table-column label="Description" prop="comment">
                                    <template slot-scope="scope">
                                        <el-alert
                                            v-if="scope.row.comment === null"
                                            title="No comment given."
                                            :closable="false"
                                            center
                                            type="warning" />
                                        <div v-else>
                                            {{ scope.row.comment }}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import{mapActions,mapGetters,mapState} from 'vuex'
import {isEmpty} from '../../../js/helpers/Common';

export default {
    props: {

    },

    data() {
        return {
            tagData : {
                name : 'TagName'
            },
            issueList : []
        }
    },

    computed : {
        
    },

    methods: {

        ...mapActions({
            readTags : 'bookingIssueTag/read',
            browseTaggedIssues  : 'bookingIssueTaggedIssue/getBookingIssueByTagId',
        }),

        loadTaggedIssues() {
            this.browseTaggedIssues({booking_issue_id : this.$route.params.id}).then(r => {
                this.issueList = r.data.data.booking_issue_tagged_issues
            });
        },

        loadTagDetail() {
            this.readTags({id : this.$route.params.id}).then(r => {
                this.tagData = r.data.data.booking_issue_tag
            });
        }
    },

    mounted() {
        this.loadTaggedIssues();
        this.loadTagDetail();
    },
}
</script>