<template>
  <div>
    <h4>{{$t('related_emails')}}</h4>
    <base-list-table
      :table-data="list"
      :extra-cols="listCols"
      :hasDetailsCol="false">

    </base-list-table>
  </div>
</template>

<script>
export default {
  props:{
    booking:{
      type:Object,
      default:{
        id:''
      }
    }
  },
  data(){
    const _this=this;
    return {
      list :[],
      listCols:[
        {
            label:  _this.$t('email_ticket_id'),
            prop: 'id',
            width: '',
            className: 'id-col',
            mutator(v){
							return `<a href="${app_dt_handesk_url}tickets/${v}" target="_blank">#${v}</a>`
						}
        },
        {
            label:  _this.$t('action_manager_id'),
            prop: 'booking_issue.booking_issue_id',
            width: '',
            className: 'id-col',
            mutator(v){
							return `<a href="/#/booking-issues/${v}" target="_blank">#${v}</a>`
						}
        },
        {
            label: _this.$t('subject'),
            prop: 'title',
            width: '',
            className: 'date-col',
        },
        {
            label: _this.$t('created_at'),
            prop: 'created_at',
            width: '',
            className: 'date-col',
            mutator(v){
							return moment(v).format('YYYY-MM-DD') +'<br>'+moment(v).format('HH:mm')
						}
        }
      ]

    }
  },
  methods:{
    getRelatedEmails(){
      axios.get(`${app_dt_handesk_url}api/tickets?booking_id=${this.$route.params.id}`,
      {
        headers:{
          'token':app_dt_handesk_token
        }
      }).then(r =>{

        this.list = r.data.data

      })
    },
    handleClickLink(v){
      console.log(v);

    }
  },
  mounted(){
    this.getRelatedEmails()
  }
}
</script>
