<template>
  <div class="app-fields financial-inconvenience-charge-fields">

    <h3>{{$t('text_what_times_inconvenient_for')}} {{ (new Date()).getFullYear() }}</h3>

    <!-- Is Flat Rate? -->
    <el-form-item :id="genContainerId('is_flat_rate',formElId)"
                  :label="$t('use_flat_rate')"
                  class="label-fluid">

      <el-switch :id="genFieldId('is_flat_rate',formElId)"
                 v-model="iValue.is_flat_rate"/>
    </el-form-item>

    <!-- How much is the flat rate? -->
    <el-form-item v-if="iValue.is_flat_rate"
                  :id="genContainerId('flat_rate',formElId)"
                  :label="$t('how_much_flat_rate')"
                  class="label-fluid">

      <el-input :id="genFieldId('flat_rate',formElId)"
                v-model="iValue.flat_rate"/>
    </el-form-item>

    <!-- Week days -->
    <el-collapse class="panel-shaded" v-model="panels.week_days">
      <el-collapse-item :name="1" :title="$t('week_days')">

        <div class="start-end-range-container app-splitter two-cols">
          <!-- Start Time -->
          <el-form-item :id="genContainerId('start_time',formElId)"
                        :label="$t('start_time')"
                        class="label-short">
            <el-time-picker :id="genFieldId('start_time',formElId)"
                            size="mini"
                            v-model="iInconvenienceStartTime"
                            format="HH:mm"/>
          </el-form-item>

          <!-- End Time -->
          <el-form-item :id="genContainerId('end_time',formElId)"
                        :label="$t('end_time')"
                        class="label-short">
            <el-time-picker :id="genFieldId('end_time',formElId)"
                            size="mini"
                            v-model="iInconvenienceEndTime"
                            format="HH:mm"/>
          </el-form-item>
        </div> <!-- /.app-splitter two-cols -->

        <template v-for="(tlvl) in translatorLevels">

          <h3>{{ $t(`tlvl_${tlvl}`) }}</h3>

          <financial-charging-timings-fields
            v-for="(bt) in bookingTypes"
            :form-el-id="formElId + '_week_days'"
            v-model="iValue.inconvenience_fees[tlvl][bt]"
            :key="`inconvenience_fees_${tlvl}_${bt}`"
            :tlvl="tlvl"
            :btype="bt"
            :business-rules="businessRules"
            :extra-badge-label="true" />
        </template>

        <!-- Fetch Charge From -->
        <el-form-item :id="genContainerId('inconvenience_fees_fetch_from',formElId)"
                      :label="$t('fetch_charge_from')" class="label-medium">

          <el-select :id="genFieldId('inconvenience_fees_fetch_from',formElId)"
                     size="mini"
                     v-model="fetchChargeFrom.inconvenience_fees"
                     @change="handleCloneInconvenienceCharges('inconvenience_fees')">
            <el-option label="None" value="none"/>
            <el-option v-for="(v) in fetchFromOpts"
                       :key="v.key"
                       :value="v.key"
                       :label="v.label"/>
          </el-select>
        </el-form-item>

      </el-collapse-item>
    </el-collapse> <!-- End of Week days group -->

    <!-- Week ends -->
    <el-collapse class="panel-shaded" v-model="panels.week_ends">
      <el-collapse-item :name="1" :title="$t('week_ends')">

        <template v-for="(tlvl) in translatorLevels">

          <h3>{{ $t(`tlvl_${tlvl}`) }}</h3>

          <financial-charging-timings-fields
            v-for="(bt) in bookingTypes"
            :form-el-id="formElId + '_week_ends'"
            v-model="iValue.weekend_fees[tlvl][bt]"
            :key="`weekend_fees_${tlvl}_${bt}`"
            :tlvl="tlvl"
            :btype="bt"
            :business-rules="businessRules"
            :extra-badge-label="true" />
        </template>

        <!-- Fetch Charge From -->
        <el-form-item :id="genContainerId('weekend_fees_fetch_from',formElId)"
                      :label="$t('fetch_charge_from')" class="label-medium">

          <el-select :id="genFieldId('weekend_fees_fetch_from',formElId)"
                     size="mini"
                     v-model="fetchChargeFrom.weekend_fees"
                     @change="handleCloneInconvenienceCharges('weekend_fees')">
            <el-option label="None" value="none"/>
            <el-option v-for="(v) in fetchFromOpts"
                       :key="v.key"
                       :value="v.key"
                       :label="v.label"/>
          </el-select>
        </el-form-item>

      </el-collapse-item>
    </el-collapse> <!-- End of Week ends group -->

    <!-- Holidays -->
    <el-collapse class="panel-shaded" v-model="panels.holidays">
      <el-collapse-item :name="1" :title="$t('holidays')">

        <template v-for="(tlvl) in translatorLevels">

          <h3>{{ $t(`tlvl_${tlvl}`) }}</h3>

          <financial-charging-timings-fields
            v-for="(bt) in bookingTypes"
            :form-el-id="formElId + '_holidays'"
            v-model="iValue.holiday_fees[tlvl][bt]"
            :key="`holiday_fees_${tlvl}_${bt}`"
            :tlvl="tlvl"
            :btype="bt"
            :business-rules="businessRules"
            :extra-badge-label="true" />
        </template>

        <!-- Fetch Charge From -->
        <el-form-item :id="genContainerId('holiday_fees_fetch_from',formElId)"
                      :label="$t('fetch_charge_from')" class="label-medium">

          <el-select :id="genFieldId('holiday_fees_fetch_from',formElId)"
                     size="mini"
                     v-model="fetchChargeFrom.holiday_fees"
                     @change="handleCloneInconvenienceCharges('holiday_fees')">
            <el-option :label="$t('none')" value="none"/>
            <el-option v-for="(v) in fetchFromOpts"
                       :key="v.key"
                       :value="v.key"
                       :label="$t(v.label)"/>
          </el-select>
        </el-form-item>

      </el-collapse-item>
    </el-collapse> <!-- End of Holidays group -->

  </div> <!-- /.app-form financial-inconvenience-charge-fields -->
</template>

<script>
  import {timePickerFormatter} from '~/js/helpers/Vuex';
  import {BOOKING_TYPES, TRANSLATOR_LVLS} from '~/js/store/financial/settings/shared/props/constants';
  import remove from 'lodash/remove';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      formElId: {
        type: String,
        default: ''
      },

      value: {
        type: Object,
        default () { return {} }
      },

      /**
       * The business_rules values usable inside this component.
       */
      businessRules: {
        type: Object,
        required: true,
      },

      isEnabledEmergencyCharges: {
        type: Boolean,
        default: false,
      },

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        panels: {
          week_days: 1,
          week_ends: 1,
          holidays: 1,
        },

        fetchChargeFrom: {
          week_days: '',
          week_ends: '',
          holidays: '',
        },

      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * @returns {string[]}
       */
      translatorLevels () {
        return TRANSLATOR_LVLS;
      },

      /**
       * Returns collection of appropriate booking types.
       *
       * @returns {string[]}
       */
      bookingTypes () {
        let cn = [...BOOKING_TYPES];

        if (!this.isEnabledEmergencyCharges) {
          remove(cn, (v) => v === 'phone_immediate');
        }

        return cn;
      },

      fetchFromOpts () {
        const _this=this;
        return [
          {
            key: 'inconvenience_fees',
            label: 'week_days'
          },
          {
            key: 'weekend_fees',
            label: 'week_ends'
          },
          {
            key: 'holiday_fees',
            label: 'holidays'
          },
        ];
      },


      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        },
      },

      /**
       * Interface for this.iValue.office_hours_fee.start_time
       */
      iInconvenienceStartTime: {
        get () {
          return timePickerFormatter('get', this.iValue.start_time);
        },
        set (v) {
          this.iValue.start_time = timePickerFormatter('set', v);
        }
      },

      /**
       * Interface for this.iValue.office_hours_fee.end_time
       */
      iInconvenienceEndTime: {
        get () {
          return timePickerFormatter('get', this.iValue.end_time);
        },
        set (v) {
          this.iValue.end_time = timePickerFormatter('set', v);
        }
      },

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * Handles the change event for Fetch Charge field.
       * Sorry for any inconvenience, this approach is temporary as
       * this is not the best approach. Will refactor in the future.
       *
       * @param {string} dest
       *
       * @return {void}
       */
      handleCloneInconvenienceCharges (dest) {
        if (this.fetchChargeFrom !== 'none') {
          const src = this.fetchChargeFrom[dest];
          this.$emit('clone-inconvenience-charges', {src, dest});
        }
      },

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {}, // End of Component > mounted

  } // End of export default
</script>
