import { isEmpty } from "../../../helpers/Common";

export default {
  sortComment :(state) => {
    if(!isEmpty(state.comments)){
      state.comments = _sortBy(state.comments,'created_at','desc');
      console.log(state.comments)
    }
  },
  setReviewType: (state, payload) => state.reviewType = payload.type,
  /**
     * Method to reset the compose prop to its pristine state.
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    resetAssignCompose(state) {
      state.assignCustomerCompose = _.cloneDeep(state.assignCustomerComposeFresh);
  },
}