<template>
    <div class="wrap-booking-info-highlight" v-html="html" />
</template>

<script>
import {mapGetters } from 'vuex';
import {isEmpty} from '../../../js/helpers/Common';
export default {
    props: {
        details : {
            type : Object,
            default : [],
        },
    },

    computed: {
        ...mapGetters({
            batchesStatus:  'bookingBatches/batchesStatus'
        }),
        batchesLoaded(){
            return isEmpty(this.batchesStatus) || this.batchesStatus === 'completed' || this.batchesStatus === 'failed'; 
        },

        html : function() {
            let html = '';
            let counts = ``;

            if(this.details.batches_by_local) {
                html += `<span class="yellow dot"></span>`
            }

            if(this.details.batches_by_prio) {
                html += `<span class="green dot"></span>`
            }

            if(this.details.translators_count < 10) {
                html += `<span class="red dot"></span>`
            }
            
            if(this.details.translators_count != undefined){
                counts += `${this.$t('sendable')}: ${this.details.translators_sendable_count} | ${this.$t('non_sendable')}: ${this.details.translators_non_sendable_count}`
            }

            return html + ` ${ !this.batchesLoaded ?  this.batchesStatus : this.details.translators_count == undefined ? this.$t('not_created_yet') : counts}`;
        }
    },
}
</script>

<style lang="scss">
    .wrap-booking-info-highlight {
        // font-size: 12px;

        .dot {
            width: 10px;
            height: 10px;
            margin:auto;
            border-radius:50%;
            display: inline-block !important;

            &.red {
                background: #F56C6C !important;
                color: #F56C6C;
            }

            &.green {
                background: #67C23A !important;
                color: #67C23A;
            }

            &.yellow {
                background: #E6A23C !important;
                color: #E6A23C;
            }
        }
    }
</style>
