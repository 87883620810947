<template>
  <el-dialog
    title="Bokningen är låst"
    :visible.sync="iVisible"
    :modal-append-to-body="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :lock-scroll="false"
    :append-to-body="true"
    :closeTag="false"
  >
    <div v-if="isNormalLock">
      <h3>
        Uppdraget har aktivt låsts av {{lockedBy.user && lockedBy.user.name}}
        <br />För att administrera uppdraget måste du först låsa upp uppdraget.
      </h3>
      <h3 style="margin-bottom: 5px">Kommentar vid låsning: </h3>
      <div v-html="lockedBy.comment">
      </div>
    </div>
    <h3
      v-else
    >Bokningen är låst då annan förmedlare, {{lockedBy.user && lockedBy.user.name}}, administrerar bokningen</h3>
    <div class="text-center">
      <el-button class="primary" @click="iVisible = false">OK</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isEmpty } from '../../../js/helpers/Common';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    lockedBy: {
      default: {}
    },
    isNormalLock: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      window,
      form: {}
    };
  },
  computed: {
    /**
     * Interface for this.visible
     */
    iVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit('update:visible', v);
      }
    }
  },
  methods: {
    /**
     * Handle when the modal has been closed
     *
     * @return {void}
     */
    handleClose: function() {
      this.$emit('update:visible', false);
    } // End of handleClose() method
  }
};
</script>
<style lang="scss" scoped>
.el-dialog__header {
  text-align: center !important;
  background: #3a75ba !important;
}
.el-dialog__title {
  color: #ffffff !important;
  font-size: 22px !important;
}
.el-form-actions {
  text-align: center !important;
  margin-top: 15px;
}
</style>
