'use strict';

import {form} from '../shared/props';
import API from '~/js/constants/api';

export default {

  api: API.FINANCIAL.PREFILL.INVOICES,

  list: {
    data: [],
    pagination: {},
    loading: false,
  },

  item: {
    data: {},
    loading: false,
  },

  autoReloadList: false,

  form: _.cloneDeep(form),
  formFresh: _.cloneDeep(form),

}