import API from '~/js/constants/api';
import {loadIfEmpty} from '~/js/helpers/Vuex';

export default {
  loadSalaryTypeOpts (context) {
    return loadIfEmpty(context.state.salaryTypeOpts, {
      endpoint: API.FINANCIAL.SALARY_TYPES,
      method: 'GET',
      onSuccessCallback (r) {
        context.commit('setSalaryTypeOpts', r.data.data.salary_types);
      }
    });
  },

  loadSalaryItemTypeOpts (context) {
    return loadIfEmpty(context.state.salaryItemTypeOpts, {
      endpoint: API.FINANCIAL.SALARY_ITEM_TYPES,
      method: 'GET',
      onSuccessCallback (r) {
        context.commit('setSalaryItemTypeOpts', r.data.data.salary_item_types);
      }
    });
  },

  loadInvoiceItemTypeOpts (context) {
    return loadIfEmpty(context.state.invoiceItemTypeOpts, {
      endpoint: API.FINANCIAL.INVOICE_ITEM_TYPES,
      method: 'GET',
      onSuccessCallback (r) {
        context.commit('setInvoiceItemTypeOpts', r.data.data.invoice_item_types);
      }
    });
  }
}
