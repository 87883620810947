<template>
  <el-form :model="iForm">
    <el-form-item label="">
      <el-select v-model="iForm.assigned_admin_id" :placeholder="$t('please_select_admin')" value-key="id" @change="handleChange" filterable>
        <el-option v-for="i in adminList" :key="i.id" :value="i.id" :label="i.name">{{i.name}}</el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import { isEmpty } from '../../../js/helpers/Common';
export default {
  props:{
    issue:{}
  },
  data(){
    return{
    }
  },
  computed:{

  ...mapGetters('adminUser', {adminList:'listAsOptions'}),
  iForm:{
    get(){
      return this.issue
    },
    set(v){
      this.$emit('input',v)
    }
  }
  },
  methods:{
    ...mapActions('bookingIssue',{
    postComment:'postComment',
    postAssignAdmin:'postAssignAdmin',
  }),
    handleChange(){
      let params = {
        id:this.iForm.id,
        assigned_admin_id:this.iForm.assigned_admin_id
      }

      this.postAssignAdmin(params).then(()=>{
      let payload={
        booking_issue_id: params.id,
        comment:'assigned admin with ID'+ params.assigned_admin_id
      }
        this.postComment(payload)
      });
    }
  }
}
</script>

<style>

</style>
