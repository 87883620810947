<template>
  <el-form style="padding: 0 7px">
    <el-row>
      <el-col :md="10">
        <el-form-item
          :id="genContainerId('template_name',formElId)"
          label="Template Name"
          class="label-medium"
        >
          <el-input v-model="form.template_name" />
        </el-form-item>
      </el-col>
      <el-col :offset="2" :md="8">
        <el-button type="primary" @click="handleImport">Import from physical</el-button>
      </el-col>
    </el-row>
    <div style="align-items: flex-start !important;" class="app-splitter two-cols">
      <div class="invoice-business-rules-form-container">
        <h3>Physical Booking</h3>
        <div class="app-form app-fields sector-batches-settings-bookings-fields">
          <!-- Physical Form !-->

          <!-- Distance -->
          <el-form-item
            :id="genContainerId('distance',formElId)"
            label="Distance"
            class="label-medium"
          >
            <el-input :id="genFieldId('distance',formElId)" v-model="form.physical.distance" />
          </el-form-item>

          <!-- Feedback -->
          <el-form-item
            :id="genContainerId('feedback',formElId)"
            label="Feedback"
            class="label-medium"
          >
            <el-input :id="genFieldId('feedback',formElId)" v-model="form.physical.feedback" />
          </el-form-item>

          <!-- Customer Feedback -->
          <el-form-item
            :id="genContainerId('customer_feedback',formElId)"
            label="Customer Feedback"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('customer_feedback',formElId)"
              v-model="form.physical.customer_feedback"
            />
          </el-form-item>

          <!-- Late Cancellation -->
          <el-form-item
            :id="genContainerId('late_cancellation',formElId)"
            label="Late Cancellation"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('late_cancellation',formElId)"
              v-model="form.physical.late_cancellation"
            />
          </el-form-item>

          <el-form-item :id="genContainerId('delays',formElId)" label="Delays" class="label-medium">
            <el-input :id="genFieldId('Delay',formElId)" v-model="form.physical.delays" />
          </el-form-item>

          <!-- Translator's Preferences -->
          <el-form-item
            :id="genContainerId('translators_preferences',formElId)"
            label="Translator's Preferences"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('translators_preferences',formElId)"
              v-model="form.physical.translators_preferences"
            />
          </el-form-item>

          <!-- KPI -->
          <el-form-item :id="genContainerId('kpi',formElId)" label="KPI" class="label-medium">
            <el-input :id="genFieldId('late_cancellation',formElId)" v-model="form.physical.kpi" />
          </el-form-item>

          <!-- Translator's Level Mode -->
          <template v-if="form.physical && form.physical.translators_levels_mode ==='soft'">
            <el-form-item
              :id="genContainerId('translators_levels',formElId)"
              label="Translator's Levels"
              class="label-medium"
            >
              <el-input
                :id="genFieldId('translators_levels',formElId)"
                v-model="form.physical.translators_levels"
              />
            </el-form-item>
          </template>

          <el-form-item
            :id="genContainerId('translator_lvls_mode',formElId)"
            label="Translator's Level Mode"
            class="label-medium"
          >
            <el-radio-group
              :id="genFieldId('translator_lvls_mode',formElId)"
              v-model="form.physical.translators_levels_mode"
              tabindex="0"
            >
              <el-radio label="hard">Hard</el-radio>
              <el-radio label="soft">Soft</el-radio>
            </el-radio-group>
          </el-form-item>
          <h4>Translator Levels Priority</h4>

          <!-- Layman -->
          <el-form-item :id="genContainerId('layman',formElId)" label="Layman" class="label-medium">
            <el-input
              :id="genFieldId('layman',formElId)"
              v-model="form.physical.translator_levels.layman"
            />
          </el-form-item>

          <!-- Read Translation courses -->
          <el-form-item
            :id="genContainerId('read_courses',formElId)"
            label="Read Translation courses"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('read_courses',formElId)"
              v-model="form.physical.translator_levels.read_courses"
            />
          </el-form-item>

           <!-- Trained Translator -->
             <el-form-item
            :id="genContainerId('trained_translator',formElId)"
            label="Trained Translator"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('trained_translator',formElId)"
              v-model="form.physical.translator_levels.trained"
            />
          </el-form-item>

          <!-- Certified -->
          <el-form-item
            :id="genContainerId('certified',formElId)"
            label="Certified"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('certified',formElId)"
              v-model="form.physical.translator_levels.certified"
            />
          </el-form-item>

          <!-- Certified with specialisation in health care -->
          <el-form-item
            :id="genContainerId('certified_in_health',formElId)"
            label="Certified with specialisation in health care"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('certified_in_health',formElId)"
              v-model="form.physical.translator_levels.certified_in_health"
            />
          </el-form-item>

          <!-- Certified with specialisation in law -->
          <el-form-item
            :id="genContainerId('certified_in_law',formElId)"
            label="Certified with specialisation in law"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('certified_in_law',formElId)"
              v-model="form.physical.translator_levels.certified_in_law"
            />
          </el-form-item>
          <!-- Deluxe -->
          <el-form-item :id="genContainerId('Deluxe',formElId)" label="Deluxe" class="label-medium">
            <el-input
              :id="genFieldId('',formElId)"
              v-model="form.physical.translator_levels.deluxe"
            />
          </el-form-item>

          <el-row>
            <el-col :md="10">
              <h4>Max Travel Distance (KM)</h4>
              <el-form-item
                :id="genContainerId('global',formElId)"
                label="Global"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_distance.global"
                  :id="genFieldId('global',formElId)"
                />
              </el-form-item>

              <el-form-item
                :id="genContainerId('certified',formElId)"
                label="Certified"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_distance.certified"
                  :id="genFieldId('certified',formElId)"
                />
              </el-form-item>

              <el-form-item
                :id="genContainerId('certified_in_law',formElId)"
                label="Certified in law"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_distance.certified_in_law"
                  :id="genFieldId('certified_in_law',formElId)"
                />
              </el-form-item>

              <el-form-item
                :id="genContainerId('certified_in_health',formElId)"
                label="Certified in health"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_distance.certified_in_health"
                  :id="genFieldId('certified_in_health',formElId)"
                />
              </el-form-item>

              <el-form-item
                :id="genContainerId('trained',formElId)"
                label="Trained"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_distance.trained"
                  :id="genFieldId('trained',formElId)"
                />
              </el-form-item>

              <el-form-item
                :id="genContainerId('read_courses',formElId)"
                label="Read Courses"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_distance.read_courses"
                  :id="genFieldId('read_courses',formElId)"
                />
              </el-form-item>

              <el-form-item
                :id="genContainerId('layman',formElId)"
                label="Layman"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_distance.layman"
                  :id="genFieldId('layman',formElId)"
                />
              </el-form-item>
            </el-col>
            <el-col :offset="2" :md="12">
              <h4>Max Travel Time (Minutes)</h4>
              <el-form-item
                :id="genContainerId('global',formElId)"
                label="Global"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_time.global"
                  :id="genFieldId('global',formElId)"
                />
              </el-form-item>

              <el-form-item
                :id="genContainerId('certified',formElId)"
                label="Certified"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_time.certified"
                  :id="genFieldId('certified',formElId)"
                />
              </el-form-item>

              <el-form-item
                :id="genContainerId('certified_in_law',formElId)"
                label="Certified in law"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_time.certified_in_law"
                  :id="genFieldId('certified_in_law',formElId)"
                />
              </el-form-item>

              <el-form-item
                :id="genContainerId('certified_in_health',formElId)"
                label="Certified in health"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_time.certified_in_health"
                  :id="genFieldId('certified_in_health',formElId)"
                />
              </el-form-item>

              <el-form-item
                :id="genContainerId('trained',formElId)"
                label="Trained"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_time.trained"
                  :id="genFieldId('trained',formElId)"
                />
              </el-form-item>

              <el-form-item
                :id="genContainerId('read_courses',formElId)"
                label="Read Courses"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_time.read_courses"
                  :id="genFieldId('read_courses',formElId)"
                />
              </el-form-item>

              <el-form-item
                :id="genContainerId('layman',formElId)"
                label="Layman"
                class="label-medium"
              >
                <el-input
                  v-model="form.physical.max_travel_time.layman"
                  :id="genFieldId('layman',formElId)"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div>
        <div class="invoice-business-rules-form-container">
          <h3>Phone Booking</h3>

          <!-- <el-form-item
            :id="genContainerId('distance',formElId)"
            label="Distance"
            class="label-medium"
          >
            <el-input :id="genFieldId('distance',formElId)" v-model="form.phone.distance" />
          </el-form-item> -->

          <!-- Feedback -->
          <el-form-item
            :id="genContainerId('feedback',formElId)"
            label="Feedback"
            class="label-medium"
          >
            <el-input :id="genFieldId('feedback',formElId)" v-model="form.phone.feedback" />
          </el-form-item>

          <!-- Customer Feedback -->
          <el-form-item
            :id="genContainerId('customer_feedback',formElId)"
            label="Customer Feedback"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('customer_feedback',formElId)"
              v-model="form.phone.customer_feedback"
            />
          </el-form-item>

          <!-- Late Cancellation -->
          <el-form-item
            :id="genContainerId('late_cancellation',formElId)"
            label="Late Cancellation"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('late_cancellation',formElId)"
              v-model="form.phone.late_cancellation"
            />
          </el-form-item>

          <!-- Delays -->
          <el-form-item :id="genContainerId('delays',formElId)" label="Delays" class="label-medium">
            <el-input :id="genFieldId('Delay',formElId)" v-model="form.phone.delays" />
          </el-form-item>

          <!-- Translator's Preferences -->
          <el-form-item
            :id="genContainerId('translators_preferences',formElId)"
            label="Translator's Preferences"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('translators_preferences',formElId)"
              v-model="form.phone.translators_preferences"
            />
          </el-form-item>

          <!-- KPI -->
          <el-form-item :id="genContainerId('kpi',formElId)" label="KPI" class="label-medium">
            <el-input :id="genFieldId('late_cancellation',formElId)" v-model="form.phone.kpi" />
          </el-form-item>

          <!-- Translator's Level Mode -->
          <template v-if="form.phone && form.phone.translators_levels_mode ==='soft'">
            <el-form-item
              :id="genContainerId('translators_levels',formElId)"
              label="Translator's Levels"
              class="label-medium"
            >
              <el-input
                :id="genFieldId('translators_levels',formElId)"
                v-model="form.phone.translators_levels"
              />
            </el-form-item>
          </template>
          <el-form-item
            :id="genContainerId('translator_lvls_mode',formElId)"
            label="Translator's Level Mode"
            class="label-medium"
          >
            <el-radio-group
              :id="genFieldId('translator_lvls_mode',formElId)"
              v-model="form.phone.translators_levels_mode"
              tabindex="0"
            >
              <el-radio label="hard">Hard</el-radio>
              <el-radio label="soft">Soft</el-radio>
            </el-radio-group>
          </el-form-item>

          <h4>Translator Levels Priority</h4>

          <!-- Layman -->
          <el-form-item :id="genContainerId('layman',formElId)" label="Layman" class="label-medium">
            <el-input
              :id="genFieldId('layman',formElId)"
              v-model="form.phone.translator_levels.layman"
            />
          </el-form-item>

          <!-- Read Translation courses -->
          <el-form-item
            :id="genContainerId('read_courses',formElId)"
            label="Read Translation courses"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('read_courses',formElId)"
              v-model="form.phone.translator_levels.read_courses"
            />
          </el-form-item>

          <!-- Trained Translator -->
             <el-form-item
            :id="genContainerId('trained_translator',formElId)"
            label="Trained Translator"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('trained_translator',formElId)"
              v-model="form.phone.translator_levels.trained"
            />
          </el-form-item>

          <!-- Certified -->
          <el-form-item
            :id="genContainerId('certified',formElId)"
            label="Certified"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('certified',formElId)"
              v-model="form.phone.translator_levels.certified"
            />
          </el-form-item>

          <!-- Certified with specialisation in health care -->
          <el-form-item
            :id="genContainerId('certified_in_health',formElId)"
            label="Certified with specialisation in health care"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('certified_in_health',formElId)"
              v-model="form.phone.translator_levels.certified_in_health"
            />
          </el-form-item>

          <!-- Certified with specialisation in law -->
          <el-form-item
            :id="genContainerId('certified_in_law',formElId)"
            label="Certified with specialisation in law"
            class="label-medium"
          >
            <el-input
              :id="genFieldId('certified_in_law',formElId)"
              v-model="form.phone.translator_levels.certified_in_law"
            />
          </el-form-item>
          <!-- Deluxe -->
          <el-form-item :id="genContainerId('Deluxe',formElId)" label="Deluxe" class="label-medium">
            <el-input :id="genFieldId('',formElId)" v-model="form.phone.translator_levels.deluxe" />
          </el-form-item>
        </div>
      </div>
    </div>
    <el-form-item :id="genContainerId('save_btn',formElId)">
      <el-button
        @click="handleSubmit"
        :id="genFieldId('save_btn',formElId)"
        type="primary"
      >{{editMode == true ? 'Update' : 'Save'}}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
  methods: {
    handleSubmit() {
      if (this.editMode == true) {
        const payload = {
          id: this.$route.params.id,
          settings: this.form
        };
        this.updateBatchTemplate(payload)
          .then(r => {
            this.$notify({
              title: 'Success',
              message: 'Batch setting updated',
              type: 'success'
            });
          })
          .catch(() => {
            this.$notify.error({
              title: 'Error',
              message: 'Something went wrong',
              type: 'success'
            });
          });
      } else {
        this.createBatchTemplate(this.form)
          .then(r => {
            if ((r.status = 201)) {
              this.$notify({
                title: 'Success',
                message: 'Batch setting Created',
                type: 'success'
              });
            }
          })
          .catch(() => {
            this.$notify.error({
              title: 'Error',
              message: 'Something went wrong',
              type: 'success'
            });
          });
      }
    },
    handleImport() {
      let physicalForm = { ...this.form.physical };
      ['max_travel_distance', 'max_travel_time'].forEach(element => {
        delete physicalForm[element];
      });
      this.form.phone = { ...physicalForm };
    },
    ...mapActions('bookingBatches', {
      createBatchTemplate: 'createBatchTemplate',
      getBatchTemplateById: 'getBatchTemplateById',
      updateBatchTemplate: 'updateBatchTemplate'
    })
  },
  props: {
    formElId: {
      type: String,
      default: ''
    }
  }, // End of Component > props

  /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
  data() {
    return {
      form: {
        phone: {
          distance: '',
          feedback: '',
          customer_feedback: '',
          late_cancellation: '',
          translator_levels: {
            deluxe: '',
            layman: '',
            certified: '',
            read_courses: '',
            certified_in_law: '',
            certified_in_health: ''
          },
          translators_levels: '',
          translators_levels_mode: 'soft',
          translators_preferences: '',
          delays: '',
          kpi: ''
        },
        physical: {
          distance: '',
          feedback: '',
          customer_feedback: '',
          late_cancellation: '',
          translator_levels: {
            deluxe: '',
            layman: '',
            certified: '',
            read_courses: '',
            certified_in_law: '',
            certified_in_health: ''
          },
          translators_levels: '',
          translators_levels_mode: 'soft',
          translators_preferences: '',
          delays: '',
          kpi: '',
          max_travel_distance: {
            global: '',
            certified: '',
            certified_in_law: '',
            certified_in_health: '',
            trained: '',
            read_courses: '',
            layman: ''
          },
          max_travel_time: {
            global: '',
            certified: '',
            certified_in_law: '',
            certified_in_health: '',
            trained: '',
            read_courses: '',
            layman: ''
          }
        },
        template_name: ''
      },
      editMode: false
    };
  }, // End of Component > data

  mounted() {
    if (this.$route.params.editMode == true) {
      this.getBatchTemplateById(this.$route.params.id).then(r => {
        this.form.phone = _.merge(this.form.phone, r.data.item.settings.phone);
        this.form.physical = _.merge(
          this.form.physical,
          r.data.item.settings.physical
        );
        this.form.template_name = r.data.item.name;
        this.editMode = true;
      });
    }
  }
}; // End of export default
</script>
