<template>
  <div>
    <div><i class="el-icon-edit"></i> {{$t('expense_type')}}</div>
    <div style="margin-bottom:10px"><span style="margin-left:20px; color:#6EC19B">{{expense.type.name}}</span></div>
    <div><i class="fa fa-money" aria-hidden="true"></i> {{$t('amount')}}</div>
    <div style="margin-bottom:10px"><span style="margin-left:20px; color:#6EC19B">{{expense.amount}} SEK</span></div>
    <div>{{$t('attachment')}}</div>
    <template v-if="expense.attachments.length > 0">
      <ul>
        <li v-for="i in expense.attachments" :key="i.id">
          <el-button type="text" @click="downloadFile(i)">{{i.path}}</el-button>
        </li>
      </ul>
    </template>

    <div></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {download} from "~/js/helpers/File";

export default {
  props:{
    issue:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return{
      expense:{
        amount: 0,
        booking_id: '',
        id: '',
        type: {id: 0, name: "", created_at: null, updated_at: null},
        type_id: 0,
        vat_amount: 0,
        vat_rate: 0,
        attachments:[]
      }
    }
  },
  methods:{
    ...mapActions('bookingIssue', {
        showExpense: 'showExpense',
        approve: 'approveExpense',
        reject: 'rejectExpense',
      }),

    downloadFile(i){
      i.display_name = i.path;

      download(i);

    }

  },
  mounted(){
    let payload ={
      booking_id: this.issue.booking_id,
      id: this.issue.entity_id,
    }
    // console.log(this.issue)
    this.showExpense(payload).then(r =>{
      this.expense = Object.assign(this.expense,r.data.data.expense)
    })
  }
}
</script>

<style>

</style>
