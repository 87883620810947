<template>
    <div class="app-form price-other-settings-form">

        <div class="app-splitter two-cols app-flex-space-b-top">

            <!-- Invoice Form Container -->
            <div class="invoice-other-settings-form-container">

                <h3 class="app-title title-shaded">
                    <span>{{$t('invoice')}}</span>
                </h3>

                <el-form :id="invoiceFormElId" :ref="invoiceFormElId" style="padding: 0 7px">
                    <financial-other-settings-fields
                        v-model="invoiceForm.other_settings"
                        form-type="invoice"
                        :form-el-id="invoiceFormElId"
                    />
                </el-form>
            </div>

            <!-- Salary Form Container -->
            <div class="salary-other-settings-form-container">

                <h3 class="app-title title-shaded">
                    <span>{{$t('salary')}}</span>
                </h3>

                <el-form :id="salaryFormElId" :ref="salaryFormElId" style="padding: 0 7px">
                    <financial-other-settings-fields
                        v-model="salaryForm.other_settings"
                        form-type="salary"
                        :form-el-id="salaryFormElId"
                    />
                </el-form>
            </div>

        </div>
        <!-- /.app-splitter two-cols -->

    </div> <!-- /.app-form price-other-settings-form -->
</template>

<script>
  import {mapState} from 'vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        invoiceFormElId: 'invoice_other_settings_form',
        salaryFormElId: 'salary_other_settings_form',
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('financialInvoice', {
        invoiceForm: 'settings',
      }),

      ...mapState('financialSalary', {
        salaryForm: 'settings',
      }),
    },

  }
</script>
