/*
|--------------------------------------------------------------------------
| Financial > Invoice > getters
|--------------------------------------------------------------------------
| Partial file for getters object related to the scope.
*/

'use strict';

import partsLoop from '../shared/methods/partsLoop'

export default {
  partsLoop,

  /**
   * Returns state.isSettingsLoaded
   * @param state
   * @return {object}
   */
  isSettingsLoaded: (state) => state.isSettingsLoaded,

  /**
   * @returns {Integer}
   */
  settingsId: (state) => state.settings.id,

  /**
   * @returns {object}
   */
  settingsPayload: (state) => state.settingsPayload,

  /**
   * Returns state.endpointKey
   * @param state
   * @return {string}
   */
  endpointKey: (state) => state.endpointKey,

  /**
   * Returns business_rules.
   * @param state
   * @return {Object}
   */
  businessRules: (state) => state.settings.business_rules,

  /**
   * Returns boolean switch for determining if use parts is enabled.
   * @param state
   * @return {boolean}
   */
  isUsePartsEnabled(state) {
    return !state.settings.charging.is_flat_rate && state.settings.charging.use_parts;
  },

  /**
   * @returns {boolean}
   */
  isEnabledEmergencyCharges: (state) => state.settings.emergency_booking.different_type_immediate,

}
