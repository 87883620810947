<template>
    <div>
        {{$t('has_booking_translator')}}
    </div>
</template>

<script>
export default {
    name: 'HasBookingTranslator'
}
</script>

<style>

</style>
