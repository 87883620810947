<template>
    <div>
        <el-table :data="bookingIssueTags" style="width: 100%">
            <el-table-column :label="$t('id')" prop="id"></el-table-column>
            <el-table-column :label="$t('name')" prop="name">
                <template slot-scope="scope" style="text-align: left;">
                    <router-link :to="'/booking-issue-tagged-list/'+ scope.row.id">
                      <span class="text-primary" style="text-decoration: underline">{{ scope.row.name }}</span>
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column :label="$t('status')" prop="state">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.state === 1">
                        {{$t('enabled')}}
                    </el-tag>
                    <el-tag v-else type="info">
                        {{$t('disabled')}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column :label="$t('actions')">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="handleEdit(scope.row)">{{$t('update')}}</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :total="parseInt(pagination.total)"
            :page-size="parseInt(pagination.per_page)"
            :page-sizes=[10,25,50,100,200]
            :current-page="parseInt(pagination.current_page)"
            @size-change="handleSizeChange"
            @current-change="reloadList"
            />

        
    </div>
</template>


<script>
import { mapActions } from "vuex";
import {populate} from "~/js/helpers/Vuex";

export default {
    props : {
      tagList : {
          type : Array,
          default : function() {
              return ['data', 'meta', 'status']
          }
      },

      tagPagination : {
          type : Object,
          default : function() {
              return {}
          }
      }

    },

    data() {
        return {

            pagination: {
                per_page: null,
                current_page: null,
                total_pages: null
            },
            bookingIssueTags : null
        }
    },

    computed : {

    },

    methods : {
        ...mapActions({
            getBookingIssueTagsPerPage: "bookingIssueTaggedIssue/getBookingIssueTagsPerPage"
        }),
        handleEdit(row) {
            this.$emit('updateTagClicked', row)
        },

        handleSizeChange(val) {
            this.reloadList(this.tagPagination.current_page, val);
        },

        reloadList(n, per_page) {
            if(!per_page){
                per_page = this.pagination.per_page;
            }

            const params = {};
            params['n'] = n;
            params['per_page'] = per_page

            this.getBookingIssueTagsPerPage(params).then(response => {
                console.log(response);
                this.pagination.total = parseInt(response.data.meta.pagination.total);
                this.pagination.current_page = parseInt(
                    response.data.meta.pagination.current_page
                );
                this.pagination.total_pages = parseInt(
                    response.data.meta.pagination.total_pages
                );
                this.pagination.per_page = parseInt(
                    response.data.meta.pagination.per_page
                );

                this.bookingIssueTags = response.data.data.booking_issue_tags;
            });


        }
    },

    mounted() {

    },

    watch : {
        tagPagination() {
            this.pagination.total = this.tagPagination.total;
            this.pagination.current_page = this.tagPagination.current_page;
            this.pagination.total_pages = this.tagPagination.total_pages;
            this.pagination.per_page = this.tagPagination.per_page;
        },

        tagList() {
            this.bookingIssueTags = this.tagList
        }
    }
}
</script>

<style lang="scss">
    .col-tag-name {
        text-decoration: underline;
    }
</style>