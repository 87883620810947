<template>
    <div class="app-field disallowed-customer-types-field">
        <el-form-item :id="genContainerId('disallowed_customer_types',formElId)"
                          :label="$t('disallowed_customer_types')" class="label-short">
                <el-select :id="genFieldId('disallowed_customer_types',formElId)"
                           v-model="iValue" multiple
                           :placeholder="$t('please_select_disallowed_customer_types')">
                    <el-option v-for="(v) in customerTypeOpts"
                               :key="v.id"
                               :value="v.id"
                               :label="v.name"/>
                </el-select>
            </el-form-item>
    </div> <!-- /.app-field disallowed-customer-types-field -->
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import {populate} from "../../../js/helpers/Vuex";

    export default {
        props: {

            formElId: {
                type: String,
                default: 'customer_disallowed_type_parent'
            },

            value: {
                type: Array,
                default() {
                    return [];
                },
            },

        },

        computed: {
            ...mapGetters('customerType', {
                customerTypeOpts: 'listAsOptions',
            }),

            iValue: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                },
            },
        },

        methods: {
            ...mapActions('customerType', {
                loadCustomerTypes: 'browseAsOptions'
            }),
        },

        mounted() {
            populate(this, 'loadCustomerTypes', 'customerTypeOpts', {
                ref: 'Customer Type Listing',
            });
        },
    }
</script>
