<template>
    <div class="app-page user-list-page">

        <page-header :title="$t('admin_user_list')" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">

                    <div class="app-col full-width">
                        <el-tabs v-model="activeTab"  @tab-click="handleTabClick">
                            <el-tab-pane :label="$t('admin_users')" name="admin_user_list">
                                <admin-user-list/>
                            </el-tab-pane>
                            <el-tab-pane :label="$t('deleted_user_list')" name="admin_deleted_user_list">
                                <admin-deleted-user-list />
                            </el-tab-pane>
                        </el-tabs>
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page user-list-page -->
</template>
<script>
import {mapGetters, mapActions} from "vuex";
export default {
    data() {
        return {
            activeTab: 'admin_user_list'
        }
    },
    methods: {
      ...mapActions('adminUser', {
            browse: 'readTrashedUsers'
      }),
      handleTabClick(tab, event) {
            this.browse();
      }
    }
}
</script>
