<template>
  <div class="app-list attachments-list">
   
    Attachment List
    <ul>
      <li v-for="item in iFiles" :key="item.id">
      <div v-if="!empty(item.display_name)">
        <el-button type="danger" @click="deleteFile(item)" class="circle" size='mini'>X</el-button>
        <el-button type="text" @click="downloadFile(item)" size="mini">{{item.display_name}}</el-button>
      </div>
      </li>
    </ul>
  </div>
</template>

<script>

import {download} from "~/js/helpers/File";
import { mapActions } from 'vuex';
import { isEmpty } from '../../../../../js/helpers/Common';
export default {
  props:{
    files:{
      type:Array
    }
  },
  computed:{
    iFiles:{
      get(){ return this.files},
      set(v) { this.$emit('input',v)}
    }
  },
  methods:{
    ...mapActions("notificationCentre", {
      deleteAttachment:'deleteFile'
    }),
    downloadFile(item){
      let payload ={
        params: `path=${item.path}&display_name=${item.display_name}`,
        filename: item.display_name
      }
      download(payload)
    },
    deleteFile(item){
      let payload = {
        id: item.pivot.messaging_centre_id,
        file_id: item.pivot.file_id,
      }
      this.deleteAttachment(payload)
    },
    empty(v){
      return isEmpty(v)
    }
  }
}
</script>

<style lang="scss">
  .attachments-list{
    ul{
      list-style: none;
    }
  }
</style>