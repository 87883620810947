/*
|--------------------------------------------------------------------------
| API Endpoints
|--------------------------------------------------------------------------
| Contains the list of endpoints grouped into categories.
*/

'use strict';

const BASE = window.app_api;

export default {
  BASE,

  AUTH: '/api/v3/oauth/token',
  USERS: '/api/v3/users',
  CUSTOMERS: '/api/v3/customers',
  TRANSLATORS: '/api/v3/translators',
  BOOKINGS: '/api/v3/bookings',
  LANGUAGES: '/api/v3/languages',
  DEPARTMENTS: '/api/v3/departments',
  COMPANIES: '/api/v3/companies',
  MUNICIPALITIES: '/api/v3/municipalities',
  BOOKING_CONFIRM_FIELDS: '/api/v3/booking-fields',
  FINANCIAL: {

    INVOICES: '/api/v3/financial/invoices',
    SALARIES: '/api/v3/financial/salaries',

    SALARY_TYPES: '/api/v3/financial/salary-types',
    SALARY_ITEM_TYPES: '/api/v3/financial/salary-item-types',
    INVOICE_ITEM_TYPES: '/api/v3/financial/invoice-item-types',

    PRICE_LISTS: '/api/v3/pricelists',

    PRICE_TEMPLATES: '/api/v3/financial-templates',

    SETTINGS: {
      INVOICES: '/api/v3/invoices/settings',
      SALARIES: '/api/v3/salaries/settings'
    },

    ADDITIONAL: {
      INVOICES: '/api/v3/bookings/{booking}/additional-invoice-items',
      SALARIES: '/api/v3/bookings/{booking}/additional-salary-items'
    },

    ADJUSTMENT: {
      INVOICES: '/api/v3/bookings/{booking}/invoice-adjustments',
      SALARIES: '/api/v3/bookings/{booking}/salary-adjustments'
    },

    PREVIEW: {
      INVOICES: '/api/v3/bookings/{booking}/invoice-preview',
      SALARIES: '/api/v3/bookings/{booking}/salary-preview'
    },

    PREFILL: {
      INVOICES: '/api/v3/bookings/{booking}/prefill-invoice-items',
      SALARIES: '/api/v3/bookings/{booking}/prefill-salary-items'
    },

    EXPORT: {
      FINANCIAL_LIST: '/api/v3/financial/financial-lists',
      PRECHECK: '/api/v3/financial/financial-lists/{financial_list}/precheck',
      PRECHECK_EXPORT: '/api/v3/financial/financial-lists/{financial_list}/precheck/export',

      CALCULATE: {
        INVOICES: '/api/v3/invoices/{invoice}/calculate',
        SALARIES: '/api/v3/salaries/{salary}/calculate'
      },

      POPULATE: {
        INVOICES: '/api/v3/invoices/{invoice}/populate',
        SALARIES: '/api/v3/salaries/{salary}/populate'
      },

      PROGRESS: {
        INVOICES: '/api/v3/invoices/{invoice}?append=progress,uncalculated,unpopulated',
        SALARIES: '/api/v3/salaries/{salary}?append=progress,uncalculated,unpopulated',
      },

      UNCALCULATED: {
        INVOICES: '/api/v3/invoices/{invoice}?append=uncalculated',
        SALARIES: '/api/v3/salaries/{salary}?append=uncalculated'
      },

      INVOICES: '/api/v3/invoices/{invoice}/export',
      SALARIES: '/api/v3/salaries/{salary}/export'
    },

    CREDITS: {
      INVOICES: '/api/v3/bookings/{booking}/invoice-credits',
      SALARIES: '/api/v3/bookings/{booking}/salary-credits'
    },
  },

  COURT_ORDER:'/api/v3/court-order',
  BOOKING_ISSUE:'/api/v3/booking-issues',
  CUSTOMER_FROM_BOOKING: '/api/v3/customer/create-from-booking',
  FAQ:'/api/v3/faq',
  SUBTITLE:'/api/v3/subtitle',
  TRANSLATORS_UNAVAILABLE_TIMES:'/api/v3/translator-unavailable-times',
  HOLIDAYS: '/api/v3/holidays',
  EXPENSE_TYPES: '/api/v3/expense-types',
  EMAILS_TEMPLATES: '/api/v3/emails-templates',
  BATCHES: '/api/v3/batch',
  SETTINGS: '/api/v3/settings',
  SIGNED_STORAGE_URL:'api/v3/signed-storage-url',
  TRASHED_USERS:'/api/v3/trashed-users',
  RESTORE_TRASHED_USERS:'/api/v3/restore-trashed-users',
}
