<template>
  <el-dialog
    :title="`${(editMode) ? $t('update') : $t('create')} ${$t('expense_type')}`"
    :visible.sync="dialogVisible"
    :modalAppendToBody="false"
  >
    <div class="expense-type-form">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        @submit.prevent
      >
        <el-form-item :label="$t('name')" prop="name">
          <el-input v-model="form.name" :disabled="inProcess"></el-input>
        </el-form-item>
        <div class="expense-type-form__buttons">
          <el-button @click="close" :disabled="inProcess">{{$t('cancel')}}</el-button>
          <el-button type="primary" @click="save" :disabled="inProcess">{{ (editMode) ? $t('update') : $t('create') }}</el-button>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data () {
      return {
        dialogVisible: false,
        editMode: false,
        inProcess: false,
        form: {
          name: ''
        },
        rules: {
          name: [
            { required: true, message: 'Name is required', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      ...mapActions('expenseTypes', [
        'createExpenseType',
        'editExpenseType'
      ]),
      open (data) {
        if (data) {
          this.form = Object.assign({}, data)
          this.editMode = true
        } else {
          this.form = {
            name: ''
          }
          this.editMode = false
        }
        this.dialogVisible = true
      },
      close () {
        this.dialogVisible = false
      },
      save () {
        this.$refs.form.validate((valid) => {
          if (!valid) return
          this.inProcess = true
          const callback = () => {
            this.inProcess = false
            this.$refs.form.resetFields()
            this.$emit('updated')
            this.close()
          }

          if (this.editMode) {
            this.editExpenseType(this.form)
              .then(callback)
          } else {
            this.createExpenseType(this.form)
              .then(callback)
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .expense-type-form {
    .el-form-item {
      margin-bottom: 20px;
    }
    &__buttons {
      text-align: right;
    }
  }
</style>
