'use strict';

export default {

  listData: (state) => state.list.data,
  listPagination: (state) => state.list.pagination,
  isListLoading: (state) => state.list.loading,

  itemData: (state) => state.item.data,
  isItemLoading: (state) => state.item.loading,
}