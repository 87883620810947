/*
|--------------------------------------------------------------------------
| Store > Financial > Additional > Salary
|--------------------------------------------------------------------------
|
| A file that manages all the properties and abilities in relation
| to the current scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {extendObj} from '~/js/helpers/Common';
import bread from '~/js/store/bread';
import state from './state';
import mutations from '../shared/mutations';
import actions from '../shared/actions';

let getters = {};

export default {
  namespaced: true,
  state: extendObj(bread.state, state),
  getters: extendObj(bread.getters, getters),
  mutations: extendObj(bread.mutations, mutations),
  actions: extendObj(bread.actions, actions),
} // End of export default
