/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > initViewportListener
|--------------------------------------------------------------------------
|
*/

const xsBreakpoint = 481;
const smBreakpoint = 577;
const mdBreakpoint = 767;
let isMobile = false;

/**
 * @returns {boolean}
 */
function isOnXsViewport () {
  return window != null ? window.innerWidth <= xsBreakpoint : false;
}

/**
 * @returns {boolean}
 */
function isOnSmViewport () {
  return window != null ? window.innerWidth <= smBreakpoint : false;
}

/**
 * @returns {boolean}
 */
function isOnMdViewport () {
  return window != null ? window.innerWidth <= mdBreakpoint : false;
}

/**
 * @returns {boolean}
 */
function isOnMobileViewport () {
  return window != null ? isOnXsViewport() || isOnSmViewport() || isOnMdViewport() : false;
}

/**
 * @returns {boolean}
 */
function isOnDesktopViewport () {
  return window != null ? !(isOnXsViewport() || isOnSmViewport() || isOnMdViewport()) : false;
}

/**
 * @param {object} va - Vue App Instance
 * @returns {void}
 */
function setModeToMobile (va) {
  console.log('Currently on Mobile Viewport.');
  isMobile = true;

  va.commit('sidebar/setIsFoldSidebar', true);
  va.commit('viewport/setIsOnMobileViewport', true);
}

/**
 * @param {object} va - Vue App Instance
 * @returns {void}
 */
function setModeToDesktop (va) {
  console.log('Currently on Desktop Viewport.');
  isMobile = false;

  va.commit('viewport/setIsOnMobileViewport', false);
}

/**
 * @param {string|object} element
 *   Could be either css selector for fetching the HTML element or DOM HTML Object.
 *
 * @param {string} type
 *   This is the event type to be listened into.
 *
 * @param {function} callback
 *   This is the callback method to be run as the handler.
 *
 * @return {void}
 */
function attachEventHandler (element, type, callback) {

  if (typeof (element) == 'string') {
    // Define the element via querySelector.
    element = document.querySelector(element);
  }

  // Verify that the element exists.
  if (element == null || typeof (element) == 'undefined') {
    console.warn('Warning! Element does not exist. Please ignore if you purposely turned off the sidebar.');
    return;
  }

  // Attach the listener and handler/callback.
  if (element.addEventListener) {
    element.addEventListener(type, callback, false);
  } else if (element.attachEvent) {
    element.attachEvent("on" + type, callback);
  } else {
    element["on" + type] = callback;
  }
}

/**
 * @returns {void}
 */
function widthResizeCallback (va) {
  if (isOnMobileViewport() && !isMobile) setModeToMobile(va);
  else if (isOnDesktopViewport() && isMobile) setModeToDesktop(va);
}

/**
 * @returns {void}
 */
export default {

  /**
   * @returns {void}
   */
  initWindowSizeListener () {
    if (window != null) {
      console.log('Initializing Viewport Size Listener...');
      const va = this;

      if (isOnMobileViewport()) setModeToMobile(va);
      else setModeToDesktop(va);

      // Attach listener and handler for window resizing.
      attachEventHandler(document, 'DOMContentLoaded', function () {
        widthResizeCallback(va);
      });
      attachEventHandler(window, 'resize', function () {
        widthResizeCallback(va);
      });
    }
  }
};

