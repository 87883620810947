<template>
  <tr :class="getBem(parentBlockClass, 'batch-item-entry-comment')">
    <td :class="getBem(parentBlockClass, 'batch-item-entry-comment-icon')">
      <a href="#" v-if="!isEditMode" @click.prevent="handleClickEdit(commentTemp)">
        <span class="fa fa-pencil"></span>
      </a>
      <a href="#" v-else @click.prevent="handleClickSave">
        <span class="fa fa-floppy-o"></span>
      </a>
    </td>
    <td
      :colspan="totalColumns - 1"
      :class="getBem(parentBlockClass, 'batch-item-entry-comment-field')"
      v-loading="isLoading"
    >
      <input
        ref="input"
        :disabled="!isEditMode"
        :class="inputClass"
        :placeholder="placeholder"
        @keyup.enter="handleClickSave"
        v-model="commentTemp"
      />
    </td>
  </tr>
</template>

<script>
  import APICaller from '~/js/helpers/APICaller';
  import API from '~/js/constants/api';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      entry: {
        Object,
        required: true
      },

      totalColumns: {
        type: Number,
        required: true
      },

      parentBlockClass: {
        type: String,
        required: true
      },

      placeholder: {
        type: String,
        default: ''
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        commentOld: '',
        commentTemp: '',
        isEditMode: false,
        isLoading: false
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * @returns {string}
       */
      inputClass () {
        const modifier = this.isEditMode ? 'edit' : 'read-only';
        return this.getBem(this.parentBlockClass, 'batch-item-entry-comment-field', modifier);
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * Helper for setting value for this.commentTemp.
       * @param comment
       * @returns {void}
       */
      setCommentTemp (comment) {
        this.commentTemp = comment != null ? comment : '';
        this.commentOld = this.commentTemp;
      },

      /**
       * @param comment
       * @returns {void}
       */
      handleClickEdit (comment) {
        if (!this.isEditMode) {
          this.setCommentTemp(comment);
          this.isEditMode = true;
          this.$nextTick(() => this.$refs['input'].focus());
        }
      },

      /**
       * @returns {void}
       */
      handleClickSave () {
        if (this.isLoading) return;
        this.isLoading = true;

        APICaller({
          endpoint: `${API.BATCHES}/${this.entry.id}/update`,
          method: 'POST',
          data: {
            is_called: this.entry.is_called,
            comment: this.commentTemp
          }
        })
          .then(() => {
            this.setCommentTemp(this.commentTemp);

            this.$notify({
              type: 'success',
              title: "Success!",
              message: "Comment Saved"
            });
          })
          .catch(() => {
            this.commentTemp = this.commentOld;
          })
          .finally(() => {
            this.isLoading = false;
            this.isEditMode = false;
          });
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      this.setCommentTemp(this.entry.comment);
    }
  };
</script>
