<template>
    <div class="app-page-top-bar" :class="getExtraClasses">

        <div class="app-page-top-bar-toggle-button" v-if="isOnMobileViewport">
            <el-button type="primary" @click="handleClickShowOptions">
                Show Options
            </el-button>
        </div>

        <div class="app-page-top-bar-wrapper">
            <top-bar-extras
                v-if="!isOnMobileViewport || (isOnMobileViewport && isShowTopBarExtras)"
            />
        </div> <!-- /.app-page-top-bar-wrapper -->
    </div> <!-- /.app-page-top-bar -->
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        isShowTopBarExtras: false
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters('viewport', [

        // @returns {boolean}
        'isOnMobileViewport'
      ]),

      /**
       * @returns {object}
       */
      getExtraClasses () {
        return {
          'fluid-height': this.isOnMobileViewport && this.isShowTopBarExtras
        };
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * @returns {void}
       */
      handleClickShowOptions () {
        this.isShowTopBarExtras = !this.isShowTopBarExtras;
      }
    }
  };
</script>
