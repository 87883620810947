/*
|--------------------------------------------------------------------------
| Store > Booking > issues > getters
|--------------------------------------------------------------------------
|
| This file contains the getters property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

export default {
  /**
   * Get the review type state
   *
   *  * @param {object} state
   *  : current state of the vuex Object
   *
   * @return {array}
   */
  getReviewType: (state) => state.reviewType,
}

