<script>
    import {Autocomplete} from "element-ui";


    export default {

        extends: Autocomplete,

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {
        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {};
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {
        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > watch
        |--------------------------------------------------------------------------
        */
        watch: {
        }, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            getData(queryString) {
                this.loading = true;
                this.fetchSuggestions(queryString, (suggestions) => {
                    this.loading = false;
                    if (Array.isArray(suggestions)) {
                        this.suggestions = suggestions;

                        if (_.size(this.suggestions) > 0) {
                            this.highlightedIndex = 0;
                        } else {
                            this.highlightedIndex = -1;
                        }

                    } else {
                        console.error('autocomplete suggestions must be an array');
                    }
                });
            },

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
        }, // End of Component > mounted

    } // End of export default
</script>
