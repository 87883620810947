/*
|--------------------------------------------------------------------------
| Store > Sector > Municipality
|--------------------------------------------------------------------------
|
| A file that manages all the properties and abilities in relation
| to Municipality Model and other related values.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {extendObj} from "../../helpers/Common";
import bread from '../bread';
import state from './state';
import mutations from './mutations';

let getters = {
    listCourseCodes: (state) => {
        let courseCodes = [];
        for (let d of state.list.data) {
            courseCodes.push(d.course_code);
        }
        return courseCodes;
    },
};
let actions = {
};

export default {
    namespaced: true,
    state: extendObj(bread.state, state),
    getters: extendObj(bread.getters, getters),
    mutations: extendObj(bread.mutations, mutations),
    actions: extendObj(bread.actions, actions),
} // End of export default
