/*
|--------------------------------------------------------------------------
| Store > Financial > Adjustment > Salary > State
|--------------------------------------------------------------------------
|
| This file contains the state property of the current scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import API from '~/js/constants/api';
import {adjustment} from '../shared/props';

const endpoint = API.FINANCIAL.ADJUSTMENT.SALARIES;
const endpointWithId = API.FINANCIAL.ADJUSTMENT.SALARIES + '/{id}';

export default {

  model_info: {
    name: 'Salary Financial Adjustment Store',
    module: 'salaryAdjustment'
  },

  api: {
    read: {method: 'get', path: endpoint, params: {}, data: {}},
    save: {method: 'post', path: endpoint, params: {}, data: {}}
  },

  composeFresh: _.cloneDeep(adjustment),
  compose: _.cloneDeep(adjustment),

  targetBooking: 0,

  payloadData: {}, // Container for payload data.

  autoInjectPayload: true,
  autoReflectItem: true,
  autoUpdateList: true

} // End of export default