export const pieChartData = {
    type: 'doughnut',
    data: null,
    options: {
      responsive: true,
      lineTension: 1,
      plugins: {
        datalabels: {
          display: false
        }
      },
      scales: {
        xAxes: [{
              display: false
          }],
        yAxes: [{
              display: false,
          ticks: {
            beginAtZero: true,
            padding: 25,
          }
        }]
      },
      legend: {
        display: false
      }
    }
  };
  
  export default pieChartData;