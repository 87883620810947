<template>
    <div class="app-form" :class="getBem(blockClass)">

        <el-form :model="form" :id="formElId" ref="formElId">

            <el-row>
                <el-col>
                    <!-- Booking ID -->
                    <el-form-item
                        :id="genContainerId('booking_id',formElId)"
                        :label="$t('tfv_booking_id')"
                        class="label-block"
                    >
                        <el-input
                            :id="genFieldId('booking_id',formElId)"
                            v-model="form.id"
                            :placeholder="$t('tfv_booking_id')"
                            size="mini"
                        />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col>
                    <!--Booking TFV ID-->
                    <el-form-item
                        :id="genContainerId('booking_tfv_id',formElId)"
                        :label="$t('booking_tfv_id')"
                        class="label-block"
                    >
                        <el-input
                            :id="genFieldId('booking_tfv_id',formElId)"
                            v-model="form.tfv_id"
                            :placeholder="$t('booking_tfv_id')"
                            size="mini"
                        />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row type="flex" justify="center" align="middle">
                <el-col :xs="24" :sm="24" :md="24">
                    <el-row>
                        <el-col :xs="24" :sm="8" :md="24">
                            <!-- Date Target -->
                            <el-form-item :id="genContainerId('view_bookings_by' ,formElId)" class="label-block"
                                          :label="$t('date_target')">
                              <el-radio-group
                                :id="genFieldId('view_bookings_by',formElId)"
                                v-model="sortBy"
                                tabindex="0"
                                size="mini"
                              >
                                <el-radio label="due" border>{{$t('due_date')}}</el-radio>
                                <el-radio label="created_at" border>{{$t('created_date')}}</el-radio>
                                <el-radio label="expiry" v-if="isExpiring" border>{{$t('expiry')}}</el-radio>
                              </el-radio-group>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="16" :md="24">
                            <!-- Date Range Picker -->
                            <el-form-item :id="genContainerId('from_date',formElId)" :label="$t('date_range')"
                                          class="label-block">
                                <el-date-picker
                                    v-model="daterange"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    type="datetimerange"
                                    align="right"
                                    :start-placeholder="$t('start_date')"
                                    :end-placeholder="$t('end_date')"
                                    :default-time="['00:00:00', '23:59:50']"
                                    popper-class="booking-filter-form-date-range-popper"
                                    size="mini"
                                    :picker-options="{
                                        firstDayOfWeek:1
                                     }"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>

                <el-col :xs="24" :sm="24" :md="24">
                    <el-row>
                        <el-col :xs="24" :sm="12" :md="24">
                            <!-- Translator Name -->
                            <el-form-item :id="genContainerId('translator_name',formElId)"
                                          :label="$t('translator_name')"
                                          class="label-block">

                                <translator-select
                                    v-model="assigned_translator"
                                    size="mini"
                                    v-if="!isPlannedPage"
                                    :placeholder="$t('translator_search_type')"
                                />
                                <translator-select
                                  v-model="planned_translator"
                                  size="mini"
                                  v-if="isPlannedPage"
                                  :placeholder="$t('translator_search_type')"
                                />
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="24">
                            <!-- Customer Name -->
                            <el-form-item :id="genContainerId('customer_name',formElId)"
                                          :label="$t('customer')"
                                          class="label-block">
                                <el-select
                                    v-model="form.customer_id" filterable :placeholder="$t('search')" remote
                                    :remote-method="searchCustomer" :loading="searchCustomerLoading" clearable
                                    size="mini"
                                >
                                    <el-option v-for="i in searchResult" :key="i.id"
                                               :label="i.name +' - '+ i.email+' - '+ i.id"
                                               :value="i.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                    </el-row>
                </el-col>
            </el-row>

            <el-row type="flex" justify="center" align="top">
                <el-col :xs="24" :sm="8" :md="24">
                    <!-- Include Test Bookings -->
                    <el-form-item :id="genContainerId('include_test',formElId)"
                                  :label="$t('show_test_bookings')" class="label-block">
                        <el-switch :id="genFieldId('include_test',formElId)"
                                   v-model="form.is_test"
                                   :active-value="1"
                                   :inactive-value="0" />
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="8" :md="24">
                    <!-- Customer Allow Phone -->
                    <el-form-item :id="genContainerId('flagged',formElId)"
                                  :label="$t('show_flagged_bookings')" class="label-block">
                        <el-switch :id="genFieldId('flagged',formElId)"
                                   v-model="form.flagged"
                                   :active-value="1"
                                   :inactive-value="0" />
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="8" :md="24">
                    <!-- Credit Applied -->
                    <el-form-item :id="genContainerId('credit_applied' ,formElId)"
                                  :label="$t('credit_applied')" class="label-block">
                        <el-radio-group
                            :id="genFieldId('credit_applied',formElId)"
                            v-model="form.credit_applied"
                            size="mini"
                            tabindex="0"
                        >
                            <el-radio label="1" border>{{$t('yes')}}</el-radio>
                            <el-radio label="0" border>{{$t('no')}}</el-radio>
                            <el-radio :label="null" border>{{$t('all')}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="8" :md="24">
                    <!-- Immediate -->
                    <el-form-item :id="genContainerId('immediate' ,formElId)"
                                  :label="$t('immediate')" class="label-block">
                        <el-radio-group
                            :id="genFieldId('immediate',formElId)"
                            v-model="form.is_immediate"
                            size="mini"
                            tabindex="0"
                        >
                            <el-radio label="1" border>{{$t('yes')}}</el-radio>
                            <el-radio label="0" border>{{$t('no')}}</el-radio>
                            <el-radio :label="null" border>{{$t('all')}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="8" :md="24">
                    <!-- stop_financial_export -->
                    <el-form-item :id="genContainerId('stop_financial_export' ,formElId)"
                                  :label="$t('stop_financial_export')" class="label-block">
                        <el-radio-group
                            :id="genFieldId('stop_financial_export',formElId)"
                            v-model="form.stop_financial_export"
                            size="mini"
                            tabindex="0"
                        >
                            <el-radio label="1" border>{{$t('yes')}}</el-radio>
                            <el-radio label="0" border>{{$t('no')}}</el-radio>
                            <el-radio :label="null" border>{{$t('all')}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :class="getBem(blockClass, 'other-filters-row')" :gutter="5">

                <!-- Language-->
                <el-col :xs="12" :sm="8" :md="24">
                    <el-form-item :id="genContainerId('language',formElId)"
                                  :label="$t('language')" class="label-block">
                        <el-select :id="genFieldId('language',formElId)"
                                   v-model="form.from_language_id"
                                   :placeholder="$t('select_language')"
                                   filterable
                                   default-first-option
                                   multiple
                                   size="mini"
                        >
                            <el-option v-for="item in languageOpts" :key="item.id" :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <!-- Translator Level -->
                <el-col :xs="12" :sm="8" :md="24">
                    <el-form-item :id="genContainerId('translator_level',formElId)"
                                  :label="$t('translator_level')" class="label-block">
                        <el-select
                            v-model="form.translator_level"
                            collapse-tags
                            multiple clearable
                            size="mini"
                            filterable=""
                        >
                            <el-option v-for="(v) in translatorLevelOpts"
                                       :key="v.id"
                                       :value="v.id"
                                       :label="v.name" />
                        </el-select>

                    </el-form-item>
                </el-col>


  				<!-- Translator Payable Level -->
                <el-col :xs="12" :sm="8" :md="24">
                  <el-form-item :id="genContainerId('translator_level',formElId)"
                                :label="$t('translator_payable_level')" class="label-block">
                    <el-select
                      v-model="form.payable_level"
                      collapse-tags
                      multiple clearable
                      size="mini"
                      filterable=""
                    >
                      <el-option v-for="(v) in translatorLevelOpts"
                                 :key="v.id"
                                 :value="v.id"
                                 :label="v.name" />
                    </el-select>

                  </el-form-item>
                </el-col>

                <el-col>
                  <!-- Booking ID -->
                  <el-form-item
                    :id="genContainerId('booking_id',formElId)"
                    :label="$t('office_area')"
                    class="label-block"
                  >
                    <el-input
                      :id="genFieldId('booking_id',formElId)"
                      v-model="form.office_area"
                      :placeholder="$t('office_area')"
                      size="mini"
                    />
                  </el-form-item>
                </el-col>

              <!-- LMA Number -->
              <el-col :xs="12" :sm="8" :md="24">
                <el-form-item :id="genContainerId('lma',formElId)"
                              label="LMA-nummer:" class="label-block">
                  <el-input v-model="form.lma">

                  </el-input>

                </el-form-item>
              </el-col>

              <!-- LMA Not Null Number -->
              <el-col :xs="12" :sm="8" :md="24">
                <el-form-item :id="genContainerId('lma',formElId)"
                              :label="$t('all_lma_number')" class="label-block">
                  <el-switch v-model="form.lma_not_null">

                  </el-switch>

                </el-form-item>
              </el-col>

              <!-- Job Type -->
                <el-col :xs="12" :sm="8" :md="24">
                    <el-form-item :id="genContainerId('job_type',formElId)"
                                  :label="$t('job_type')" class="label-block">
                        <el-select :id="genFieldId('job_type',formElId)"
                                   v-model="form.type"
                                   :placeholder="$t('select_job_type')" clearable size="mini" filterable="" multiple>
                            <el-option :label="$t('phone')" value="phone" />
                            <el-option :label="$t('physical')" value="physical" />
                            <el-option :label="$t('video')" value="video" />
                            <el-option :label="$t('video_skype')" value="video_skype" />
                            <el-option :label="$t('video_physical')" value="video_physical" />
                            <el-option :label="$t('convey')" value="convey" />
                            <el-option :label="$t('text_translation')" value="text_translation" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <!-- Town -->
                <el-col :xs="12" :sm="8" :md="24">
                    <el-form-item :id="genContainerId('town',formElId)"
                                  :label="$t('town')" class="label-block" v-if="form.type ==='physical'">
                        <el-select :id="genFieldId('town',formElId)"
                                   v-model="form.town_id" filterable clearable
                                   size="mini"
                                   :placeholder="$t('select_town')">
                            <el-option v-for="item in townOpts" :key="item.id" :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <!-- Status -->
                <el-col :xs="12" :sm="8" :md="24">
                    <el-form-item :id="genContainerId('status',formElId)"
                                  :label="$t('status')" class="label-block">
                        <el-select :id="genFieldId('status',formElId)"
                                   v-model="form.status_id" clearable="" multiple size="mini"
                                   :placeholder="$t('select_status')" filterable="">
                            <el-option v-for="i in statusList" :key="i.id" :value="i.id" :label="$t(`tfv_status_${i.code}`)">
                                {{ $t(`tfv_status_${i.code}`) }}
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <!-- Translator Email -->
                <el-col :xs="12" :sm="8" :md="24" v-if="false">
                    <el-form-item :id="genContainerId('translator_email',formElId)"
                                  :label="$t('translator_email')" class="label-block">
                        <el-input :id="genFieldId('translator_email',formElId)"
                                  v-model="form.translator_email" size="mini" />
                    </el-form-item>
                </el-col>

                <!-- Customer Email -->
                <el-col :xs="8" :sm="8" :md="24" v-if="false">
                    <el-form-item :id="genContainerId('customer_email',formElId)"
                                  :label="$t('customer_email')" class="label-block">
                        <el-input :id="genFieldId('customer_email',formElId)"
                                  v-model="form.customer_email" size="mini" />
                    </el-form-item>
                </el-col>

                <!-- Municipality -->
                <el-col :xs="12" :sm="8" :md="24">
                    <el-form-item :id="genContainerId('municipality_name',formElId)"
                                  :label="$t('municipality_name')" class="label-block">
                        <el-select v-model="form.municipality_id" filterable
                                   :placeholder="$t('search_municipality_type')" remote
                                   :remote-method="searchMunicipalities"
                                   :loading="searchMunicipalityLoading" clearable size="mini">
                            <el-option v-for="i in searchMunicipalityResult" :key="i.id"
                                       :label="i.name +' - '+ i.id"
                                       :value="i.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <!-- Company -->
                <el-col :xs="12" :sm="8" :md="24">
                    <el-form-item :id="genContainerId('company_name',formElId)" :label="$t('company_name')"
                                  class="label-block">
                        <el-select v-model="form.company_id" filterable :placeholder="$t('company_search')"
                                   remote
                                   :remote-method="searchCompanies" :loading="searchCompanyLoading"
                                   clearable size="mini">
                            <el-option v-for="i in searchCompanyResult" :key="i.id"
                                       :label="i.name +' - '+ i.id"
                                       :value="i.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <!-- Department -->
                <el-col :xs="12" :sm="8" :md="24">
                    <el-form-item :id="genContainerId('department_name',formElId)"
                                  :label="$t('department_name')" class="label-block">
                        <el-select v-model="form.department_id" filterable
                                   :placeholder="$t('department_search')" remote
                                   :remote-method="searchDepartments" :loading="searchDepartmentsLoading"
                                   clearable size="mini">
                            <el-option v-for="i in searchDepartmentsResult" :key="i.id"
                                       :label="i.name +' - '+ i.id"
                                       :value="i.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <!-- Created Via -->
                <el-col :xs="12" :sm="8" :md="24">
                    <el-form-item
                        :id="genContainerId('created_via',formElId)"
                        :label="$t('created_via')"
                        class="label-block"
                    >
                        <el-select collapse-tags multiple v-model="form.created_via"
                                   :placeholder="$t('select')"
                                   size="mini"
                                   filterable=""
                        >
                            <el-option
                                v-for="item in created_via_opts"
                                :key="item.value"

                                :label="$t(item.label)"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <!-- Town -->
                <el-col :xs="12" :sm="8" :md="24">
                    <el-form-item
                        :id="genContainerId('town_id',formElId)"
                        :label="$t('town')"
                        class="label-block"
                    >
                        <el-select collapse-tags multiple :id="genFieldId('town_id',formElId)"
                                   v-model="form.town_id"
                                   filterable
                                   size="mini"
                                   :placeholder="$t('select_town')"
                                   @focus="handleClickTownSelection"
                        >
                            <el-option v-for="(v) in townOpts"
                                       :key="v.id"
                                       :value="v.id"
                                       :label="v.name" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <!-- Session Time -->
                <el-col :xs="24" :sm="24" :md="24">
                    <el-form-item
                        :id="genContainerId('session_time_from',formElId)"
                        :label="$t('session_time')"
                        class="label-block"
                    >
                        <el-time-select
                            :placeholder="$t('from')"
                            size="mini"
                            v-model="session_time.from"
                            value-format="HH:mm:ss"
                            :picker-options="{
                                            start: '00:00',
                                            step: '00:15',
                                            end: '24:00'
                                        }">
                        </el-time-select>
                    </el-form-item>

                    <el-form-item :id="genContainerId('session_time_to',formElId)">
                        <el-time-select
                            :placeholder="$t('to')"
                            size="mini"
                            v-model="session_time.to"
                            :picker-options="{
                                            start: '00:00',
                                            step: '00:15',
                                            end: '24:00',
                                            minTime: session_time.from
                                        }">
                        </el-time-select>
                    </el-form-item>
                </el-col>

                <!-- <el-col :xs="24" :sm="8" :md="24">
                    <el-form-item :id="genContainerId('has_replies',formElId)"
                                  :label="$t('show_has_replies')" class="label-block">
                        <el-switch :id="genFieldId('has_replies',formElId)"
                                   v-model="showHasReplies"
                                   @change="changeShowHasReplies"
                                  />
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :md="24">
                    <el-form-item :id="genContainerId('has_tickets',formElId)"
                                  :label="$t('show_has_tickets')" class="label-block">
                        <el-switch :id="genFieldId('has_tickets',formElId)"
                                   v-model="showHasTickets"
                                   @change="changeHideTicketIcons"
                                  />
                    </el-form-item>
                </el-col> -->

                <!-- Duration -->
                <el-col :xs="24" :sm="24" :md="24">
                    <el-form-item
                        :id="genContainerId('duration_to',formElId)"
                        :label="$t('duration')"
                        class="label-block"
                    >
                        <el-time-select
                            :placeholder="$t('from')"
                            size="mini"
                            v-model="booked_time.from"
                            value-format="HH:mm:ss"
                            :picker-options="{
                                            start: '00:00',
                                            step: '00:15',
                                            end: '24:00'
                                        }">
                        </el-time-select>
                    </el-form-item>

                    <el-form-item :id="genContainerId('duration_from',formElId)">
                        <el-time-select
                            :placeholder="$t('to')"
                            size="mini"
                            v-model="booked_time.to"
                            :picker-options="{
                                            start: '00:00',
                                            step: '00:15',
                                            end: '24:00',
                                            minTime: session_time.from
                                        }">
                        </el-time-select>
                    </el-form-item>
                </el-col>

                <!-- travel time -->
                <el-col :xs="24" :sm="24" :md="24">
                  <el-form-item
                    :id="genContainerId('duration_from',formElId)"
                    :label="$t('travel_time')"
                    class="label-block"
                  >
                    <el-select v-model="travel_time.from" clearable :placeholder="$t('select')">
                      <el-option
                        v-for="item in 1000"
                        :key="item"
                        filterable
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item :id="genContainerId('duration_from',formElId)">
                    <el-select v-model="travel_time.to" clearable :placeholder="$t('select')">
                      <el-option
                        v-for="item in 1000"
                        :key="item"
                        filterable
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>


              <!-- travel time -->
              <el-col :xs="24" :sm="24" :md="24">
                <el-form-item
                  :id="genContainerId('duration_from',formElId)"
                  :label="$t('travel_distance')"
                  class="label-block"
                >
                  <el-select v-model="travel_distance.from" clearable :placeholder="$t('select')">
                    <el-option
                      v-for="item in 1000"
                      :key="item"
                      filterable
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item :id="genContainerId('duration_from',formElId)">
                  <el-select v-model="travel_distance.to" clearable :placeholder="$t('select')">
                    <el-option
                      v-for="item in 1000"
                      :key="item"
                      filterable
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>


            </el-row>

            <!-- Save -->
            <el-form-item :id="genContainerId('cta',formElId)">
                <el-button
                    type="secondary"
                    :id="genFieldId('reset',formElId)"
                    @click="resetForm('formElId')"
                    size="mini"
                >
                    {{$t('reset')}}
                </el-button>
                <el-button
                    type="primary"
                    :id="genFieldId('save',formElId)"
                    @click="reloadList"
                    size="mini"
                >
                    {{$t('submit')}}
                </el-button>

                <el-row  style="padding-top: 20px;">
                    <el-col :md="24">
                        <el-form :model="formExport" id="formExport" ref="formExport" :rules="rules">
<!--                            <el-form-item label="" prop="recipients">-->
<!--                                <multiselect v-model="formExport.recipients"-->
<!--                                             :options="recipients"-->
<!--                                             :multiple="true"-->
<!--                                             :taggable="true"-->
<!--                                             placeholder="add export booking recipients"-->
<!--                                             @tag="setTag"-->
<!--                                >-->
<!--                                    <span slot="noResult">add export booking recipients</span>-->
<!--                                </multiselect>-->
<!--                            </el-form-item>-->

                            <el-form-item   class="label-block" :label="$t('booking_export')">
                              <el-select v-model="bookingExport" :placeholder="$t('export_booking_options')"
                              @change="handleBookingExport(bookingExport)"
                              >
                                <el-option
                                  v-for="item in exportOptions"
                                  :key="item.value"
                                  :label="$t(`opt_${ $t(item.value) }`)"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                            </el-form-item>
                        </el-form>
                    </el-col>
                </el-row>
            </el-form-item>
        </el-form>
       <export-booking-modal :exportButton="exportButton" @resetBookingExport="resetBookingExport" :exportType="bookingExport" :visible.sync="showExportBookingModal"/>
    </div> <!-- /.app-form booking-filter-form -->
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import {mapActions, mapGetters} from "vuex";
  import {populate} from "~/js/helpers/Vuex";
  import {removeEmpty, isEmpty, cleanNull} from "~/js/helpers/Common";

  export default {

    components: {Multiselect},
    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      vPopulatePayload:{
        type: Object,
        default(){
          return {
            'filter[is_test]': '0',
            'sort': '-created_at',
            append:'has_batches,translators_count,translators_sendable_count,translators_non_sendable_count'
          }
        }
      }
    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockClass: 'booking-filter-form',

        datePickerOptions: {
          firstDayOfWeek: 1
        },

        booked_time: {
          from:'',
          to:''
        },
        session_time: {
          from:'',
          to:''
        },
        formElId: 'booking_filter_form',
        created_via_opts: [{
          label: 'created_via_tfv_ct',
          value: 'DT-CT'
        }, {
          label: 'App ios',
          value: 'ct-mobile-ios'
        }, {
          label: 'App android',
          value: 'ct-mobile-android'
        }, {
          lable: 'created_via_ct_responsive',
          value: 'ct-responsive'
        }, {
          label: 'created_via_ct_desktop',
          value: 'ct-desktop'
        }],
        duration: [{
          key: 15,
          value: '15 min'
        },
          {
            key: 30,
            value: '30 min'
          },
          {
            key: 45,
            value: '45 min'
          },
          {
            key: 60,
            value: '1 timme'
          },
          {
            key: 90,
            value: '1.5 timme'
          },
          {
            key: 120,
            value: '2 timme'
          },
          {
            key: 150,
            value: '2.5 timme'
          },
          {
            key: 180,
            value: '3 timme'
          },
          {
            key: 210,
            value: '3.5 timme'
          },
          {
            key: 240,
            value: '4 timme'
          },
          {
            key: 270,
            value: '4.5 timme'
          },
          {
            key: 300,
            value: '5 timme'
          },
          {
            key: 330,
            value: '5.5 timme'
          },
          {
            key: 360,
            value: '6timme'
          },
          {
            key: 420,
            value: '7 timme'
          },
          {
            key: 480,
            value: '8 timme'
          }],

        form: {

          id: '',
          aes_id: '',
          from_date: '',
          to_date: '',
          translator_name: '',
          customer_id: '',
          aes_customer_name: '',
          // -----
          from_language_id: '',
          town_id: [],
          type: '',
          // -----
          status_id: [],
          translator_email: '',
          customer_email: '',
          // -----
          municipality_id: '',
          company_id: '',
          department_id: '',
          // -----
          is_test: '0',
          flagged: '0',
          cus_allow_phone: '',
          is_immediate: null,
          date_range: '',
          translator_level:[],
          created_via:[],
          tfv_id: '',
          stop_financial_export: null,
          credit_applied: null

        },
        sortBy: '-created_at',
        opts: {
          due_date: 'Due Date',
          created_date: 'Created Date',
          expiry: 'Expiring'
        },
        travel_time: {
          from: '',
          to: ''
        },
        travel_distance: {
          from: '',
          to: ''
        },

        assigned_translator: '',
        planned_translator: '',
        from_date: '',
        to_date: '',
        searchCustomerLoading: false,
        searchResult: [],
        selectedCustomer: {},
        searchTranslatorLoading: false,
        searchTranslatorResult: [],
        selectedTranslator: {},
        isExpiring: false,
        per_page: 10,
        searchDepartmentsLoading: false,
        searchDepartmentsResult: [],
        searchCompanyLoading: false,
        searchCompanyResult: [],
        searchMunicipalityResult: [],
        searchMunicipalityLoading: false,
        export_loading: false,
        daterange: '',
        formExport: {
          recipients: []
        },
        recipients: [],
        rules: {
          recipients: [{required: true, message: 'Please add recipients email'}]
        },
        showHasTickets: true,
        showHasReplies: true,
        exportOptions: [
          {
            value: 'export_booking_normal',
            label: 'Export Bookings'
          },
          {
            value: 'export_booking_financial',
            label: 'Export Bookings Financial'
          }
        ],
        bookingExport: '',
        showExportBookingModal: false,

      };

    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters({
        customers: 'booking/getCustomersList',
        translators: 'booking/getTranslatorsList',
        languageOpts: 'language/getLanguages',
        statusList: 'booking/getStatusList'

      }),

      isPlannedPage() {
        return _.includes(this.$route.path, 'planned');
      },

      ...mapGetters('town', {
        townOpts: 'listAsOptions'
      }),
      ...mapGetters('translatorLevel', {
        translatorLevelOpts: 'listAsOptions'
      }),
      populatePayload() {
        return this.vPopulatePayload
      },


    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      setTag (e) {
        this.recipients.push(e);
        this.formExport.recipients.push(e);
      },
      ...mapActions('booking', {
        export: 'export',
        exportBookingFinancial: 'exportBookingFinancial',
        browse: 'browse',
        loadCustomers: 'fetchCustomers',
        loadTranslators: 'fetchTranslators',
        fetchCustomers: 'fetchCustomers',
        filterCustomers: 'filterCustomers',
        fetchDepartments: 'fetchDepartments',
        fetchCompanies: 'fetchCompanies',
        fetchMunicipalities: 'fetchMunicipalities'
      }),
      ...mapActions('translatorLevel', {
        loadTranslatorLevels: 'browseAsOptions'
      }),
      ...mapActions('language', {
        loadLanguages: 'fetchLanguages'
      }),
      ...mapActions('town', {
        loadTowns: 'browseAsOptions'
      }),
      showExportButton () {
        if (!this.assigned_translator == '' || !this.from_date == '' || !this.to_date == '' || !this.form.id == '' || !this.form.aes_id == '' || !this.form.aes_customer_name == '' || !this.form.customer_id == '' || !isEmpty(this.daterange)) {
          return true;
        } else {
          return false;
        }
      },
      async exportButton (recipient, exportType, driver) {
        let fields = this.setQuery();
        fields = _.omit(fields, ['append', 'page', 'per_page']);
        Object.assign(fields, {recipients: recipient});
        if (exportType === 'export_booking_normal') {
            this.export(fields).then(() => {
              this.showExportBookingModal = false
            });
        } else if(exportType === 'export_booking_financial') {
          Object.assign(fields, {drivers: driver});
          this.exportBookingFinancial(fields).then(() => {
            this.showExportBookingModal = false
          })
        }
        // });


      },
      setQuery () {
        let fields = removeEmpty(this.form);
        console.log("Reload List Called");
        Object.assign(fields, {'sort': this.sortBy});
        Object.assign(fields, {'per_page': this.per_page});
        Object.assign(fields, {'filter[is_test]': this.form.is_test});

        //get specific date filter and map it with sort by value as filter key
        if (!isEmpty(this.from_date) && !isEmpty(this.to_date)) {
          let sort = this.sortBy;
          if (sort.charAt(0) === '-') {
            sort = sort.slice(1);
          }
          let from = moment(this.from_date);
          let to = moment(this.to_date);

          let range = moment().range(from, to);
          let dates = Array.from(range.by('days'));
          let v = dates.map(m => m.format('YYYY-MM-DD'));
          fields[`filter[${sort}]`] = `${v.join(',')}`;
        } else if (!isEmpty(this.from_date) && isEmpty(this.to_date)) {
          let sort = this.sortBy;
          if (sort.charAt(0) === '-') {
            sort = sort.slice(1);
          }

          fields[`filter[${sort}]`] = this.from_date;
        }

        if (!isEmpty(this.daterange)) {
          let sort = this.sortBy;
          if (sort.charAt(0) === '-') {
            sort = sort.slice(1);
          }
          fields[`filter[date_range]`] = sort + ',' + this.daterange.join(",");
        }

        if (!isEmpty(this.assigned_translator)) {
          fields['filter[assigned_translator]'] = this.assigned_translator;
        }

        if (!isEmpty(this.planned_translator)) {
          fields['filter[planned_translator]'] = this.planned_translator;
        }
        if (!isEmpty(this.form.from_language_id)) {
          fields['filter[from_language_id]'] = this.form.from_language_id.join(',');
        }
        if (!isEmpty(this.form.status_id)) {
          fields['filter[status_id]'] = this.form.status_id.join(',');
        }
        if (!isEmpty(this.session_time.from)) {
          fields['filter[session_time_filter]'] = this.session_time.from + ',' + this.session_time.to;
        }
        if (!isEmpty(this.travel_time.from)) {
          fields['filter[travel_calculation]'] = 'travel_time' + ',' + this.travel_time.from + ',' + this.travel_time.to;
        }
        if (!isEmpty(this.travel_distance.from)) {
          fields['filter[travel_calculation]'] = 'travel_distance' + ',' + this.travel_distance.from + ',' + this.travel_distance.to;
        }
        if (!isEmpty(this.booked_time.from) && !isEmpty(this.booked_time.to)) {
          fields['filter[booked_time]'] = this.booked_time.from + ',' + this.booked_time.to;
        }
        if (!isEmpty(this.form.translator_level)) {
          fields['filter[translator_level]'] = this.form.translator_level.join(',');
        }
        if (!isEmpty(this.form.created_via)) {
          fields['filter[created_via]'] = this.form.created_via.join(',');
        }
        if (!isEmpty(this.form.town_id)) {
          fields['filter[town_id]'] = this.form.town_id.join(',');
        }
        if (!isEmpty(this.form.lma)) {
          fields['filter[lma]'] = this.form.lma;
        }
        if (!isEmpty(this.form.office_area)) {
          fields['filter[office_area]'] = this.form.office_area;
        }
        if(this.form.lma_not_null == true) {
          fields['filter[lma_not_null]'] = true
        }
        if (!isEmpty(this.form.type)) {
          fields['filter[type]'] = this.form.type.join(',');
        }

        return fields;
      },
      reloadList () {
        //remove empty values and map to filters
        let fields = this.setQuery();
        //console.log(fields)
        let params=_.clone(this.populatePayload);
        params = Object.assign(params,fields)
        //console.log(params)
        this.$router.push({query: params});
        populate(this, 'browse', 'listData', {
          force: true,
          params
        });
      },
      searchCustomer (query) {
        if (query !== '' && query.length > 2) {
          this.searchCustomerLoading = true;
          let params = {
            'filter[name]': query,
            'all': true
          };
          setTimeout(() => {
            this.filterCustomers(params).then((response) => {

              if (isEmpty(response)) {
                let params2 = {
                  'filter[email]': query,
                  'all': true
                };
                this.filterCustomers(params2).then((response) => {
                  if (isEmpty(response)) {
                    let params3 = {
                      'filter[id]': query,
                      'all': true
                    };
                    this.filterCustomers(params3).then((response) => {
                      this.searchResult = response;
                    });
                  }
                  this.searchResult = response;

                });
              }

              this.searchResult = response;

              this.searchCustomerLoading = false;
            });

          }, 300);

        } else {
          // this.searchResult = this.customers;
        }
      },
      searchDepartments (query) {
        if (query !== '') {
          this.searchDepartmentsLoading = true;
          let params = {
            'filter[name]': query,
            'all': true
          };

          setTimeout(() => {
            this.fetchDepartments(params).then((response) => {

              this.searchDepartmentsResult = response;

              this.searchDepartmentsLoading = false;
            });

          }, 200);

        } else {
          // this.searchResult = this.customers;
        }
      },
      searchCompanies (query) {
        console.log(query);
        if (query !== '') {
          this.searchCompanyLoading = true;
          let params = {
            'filter[name]': query,
            'all': true
          };

          setTimeout(() => {
            this.fetchCompanies(params).then((response) => {
              console.log(response);
              this.searchCompanyResult = response;

              this.searchCompanyLoading = false;
            });

          }, 200);

        } else {
          // this.searchResult = this.customers;
        }
      },
      searchMunicipalities (query) {
        if (query !== '') {
          this.searchMunicipalityLoading = true;
          let params = {
            'filter[name]': query,
            'all': true
          };

          setTimeout(() => {
            this.fetchMunicipalities(params).then((response) => {

              this.searchMunicipalityResult = response;

              this.searchMunicipalityLoading = false;
            });

          }, 200);

        } else {
          // this.searchResult = this.customers;
        }
      },
      searchTranslator (query) {
        if (query !== '') {
          this.searchTranslatorLoading = true;
          setTimeout(() => {

            this.searchTranslatorLoading = false;
            let searchOptions = {
              keys: ['id', 'name', 'email', 'mobile']
            };
            this.$search(query, this.translators, searchOptions).then(result => {
              this.searchTranslatorResult = result;
            });
          }, 200);
        } else {
          this.searchTranslatorResult = this.translators;
        }
      },
      resetForm (formName) {
        this.form = {
          id: '',
          aes_id: '',
          from_date: '',
          to_date: '',
          translator_name: '',
          customer_id: '',
          aes_customer_name: '',
          // -----
          form_language_id: '',
          town_id: [],
          type: '',
          // -----
          status_id: [],
          translator_email: '',
          customer_email: '',
          // -----
          municipality_id: '',
          company_id: '',
          department_id: '',
          // -----
          is_test: '0',
          flagged: '',
          cus_allow_phone: '',
          is_immediate: null,
          date_range: '',
          // -----
          from_language_id:'',
          translator_level:[],
          tfv_id: '',
          stop_financial_export: null,
          credit_applied: null,
          office_area: '',
        };
        this.daterange= '';
        this.assigned_translator= '';
        this.booked_time={
          from:'',
          to:''
        };
        this.session_time={
          from:'',
          to:''
        };
        this.sortBy= '-created_at'

        this.$router.replace({'query': this.populatePayload});
        populate(this, 'browse', 'listData', {
          force: true,
          params: this.populatePayload
        });
      },
      getQueryParams () {
        let fields = '';
        if (!isEmpty(this.daterange)) {
          fields += `&from=${moment(this.daterange[0]).format('YYYY-MM-DD 00:00:00')}&to=${moment(this.daterange[1]).format('YYYY-MM-DD')} 23:59:59`;
        }
        fields += this.from_date != ''
          ? `&from=${moment(this.from_date).format('YYYY-MM-DD')} 00:00:00`
          : '';
        fields += this.to_date != ''
          ? `&to=${moment(this.to_date).format('YYYY-MM-DD')} 23:59:59`
          : '';
        fields += this.form.department_id != ''
          ? `&department=${this.form.department_id}`
          : '';
        fields += this.form.municipality_id != ''
          ? `&municipality=${this.form.municipality_id}`
          : '';
        fields += this.form.company_id != ''
          ? `&company=${this.form.company_id}`
          : '';
        fields += this.sortBy == '-due'
          ? `&target=due`
          : `&target=created`;
        return fields;
      },
      handleClickTownSelection () {
        populate(this, 'loadTownOpts', 'townOpts', {
          ref: 'Towns Options Listing'
        });
      },

      changeShowHasReplies(e){

        let tr = document.querySelectorAll('.has_replies');
        if(!e){
          tr.forEach(e =>{
            e.classList.add('reset-row-col');
          });

        }else{
          tr.forEach(e =>{
            e.classList.remove('reset-row-col');
          });

        }
      },

      changeHideTicketIcons(e){
        // has-tickets-icon
        let icon = document.getElementsByClassName('has-tickets-icon');
        if(!e) {
          for (let i = 0; i < icon.length; i++) {
            icon[i].style.visibility = 'hidden';
          }
        }else{
          for (let i = 0; i < icon.length; i++) {
            icon[i].style.visibility = 'visible';
          }
        }
      },

      handleBookingExport(value) {
        let fields = this.setQuery();
        if(isEmpty(fields['filter[department_id]']) && isEmpty(fields['filter[municipality_id]']) && isEmpty(fields['filter[company_id]'])) {
          this.resetBookingExport()
          return this.$notify.error('Please select any department, company or municipality ');
        }
        this.showExportBookingModal = true;
      },
      resetBookingExport() {
        this.bookingExport = '';
        this.showExportBookingModal = false;
      }

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      if (this.$route.name === 'booking-expiring') {
        this.isExpiring = true;
        this.form.status_id = [1];
        this.per_page = 50;
      }
      if (this.$route.name === 'booking-to-work-on' || this.$route.name === 'booking-pending') {
        this.form.status_id = [1];
      }
      // console.log(query)

      // populate(this, 'loadCustomers', 'customers', {
      //     ref: 'Customer Options'
      // });
      // populate(this, 'loadTranslators', 'translators', {
      //     ref: 'translators Options'
      // });
      // populate(this, 'loadLanguages', 'languageOpts', {
      //     ref: 'Languages Options'
      // });
      populate(this, 'loadTowns', 'townOpts', {
        ref: 'Towns Listing'
      });

      populate(this, 'loadTranslatorLevels', 'translatorLevelOpts', {
        ref: 'Translator Level Listing'
      });

      // this.fetchDepartments({'all':true})
    } // End of Component > mounted

  }; // End of export default

</script>
