<template>
  <el-dialog
    :title="$t('edit_survey_period')"
    :visible.sync="iVisible"
    :modal-append-to-body="false"
    :lock-scroll="false"
    width="50%"
  >
  <!-- form Begins -->
    <el-form>
      <el-form-item class="full-width center-block" :label="$t('title')">
        <el-input v-model="form.title" :placeholder="$t('survey_manager_title')"/>
      </el-form-item>
      <el-form-item class="full-width center-block" :label="$t('select_period')">
        <div class="block">
          <span class="demonstration"></span>
          <el-date-picker
            v-model="form.date"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :start-placeholder="$t('start_date')"
            :end-placeholder="$t('end_date')"
          ></el-date-picker>
        </div>
      </el-form-item>
      <el-form-item class="el-form-actions">
          <el-button class="modal-size" @click="handleClose" type="default">{{$t('cancel')}}</el-button>
          <el-button class="modal-size" @click="handleSubmit" type="primary">{{$t('save')}}</el-button>
      </el-form-item>
    </el-form>
    <!-- form Ends -->
  </el-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      form: {}
    };
  },
  props: {
    visible: {
      Type: Boolean,
      default: false
    },
    addSurvey: {
      Type: Function
    }
  },
  computed: {
    iVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit("update:visible", v);
      }
    }
  },
  methods: {
    handleClose: function() {
      this.$emit("update:visible", false);
    }, // End of handleClose() method

    handleSubmit() {
      this.addSurvey(this.form);
      this.form = {};
    }
  }
};
</script>
