/*
|--------------------------------------------------------------------------
| Financial > Shared > methods > importSettings
|--------------------------------------------------------------------------
| Partial file as a reusable method for importing settings
| from one entity to another (or more entities).
*/

'use strict';

import API from '~/js/constants/api';
import APICaller from "~/js/helpers/APICaller";

/**
 * @param {object} context - Vuex Store context.
 * @param {object} payload - Contains values to be used inside this method.
 * @param {object} payload.data - Container for data to be sent to API.
 * @param {int} payload.data.setting_id - Source setting ID.
 * @param {string} payload.data.entity_type - Destination entity_type.
 * @param {Array.<int>} payload.data.entity_ids - Destination IDs for the import.
 *
 * @return {Promise|any}
 */
export default function (context, payload) {
  const target = context.state.endpointKey;
  const url = API.FINANCIAL.SETTINGS[target.toUpperCase()];

  const apiOpts = {
    method: 'POST',
    endpoint: `${url}/clone`,
    isDataRaw: true,
    data: payload.data
  };

  return new Promise((resolve, reject) => {
    APICaller(apiOpts)
      .then((r) => { resolve(r); })
      .catch((e) => { reject(e); });
  });
}