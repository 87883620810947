<template>
    <div class="app-page municipality-compose-page">

        <page-header :title="$t('municipality')" :back-enabled="true"/>

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">

                    <div class="app-col full-width">
                        <div class="top-controls">
                            <el-row>
                                <el-col :span="24" class="text-right">
                                    <el-button type="primary" @click="handleRedirectAudits">
                                        {{$t('audit_logs')}}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="content-body">

                            <municipality-compose-form :company="tableData"/>
                            <custom-table :user="'company'" :tableData="tableData" />
                            <municipality-files-list-panel></municipality-files-list-panel>

                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page municipality-compose-page -->
</template>

<script>
    import APICaller from "../../../../js/helpers/APICaller";
    import { gep } from "../../../../js/helpers/Vuex";
    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                tableData: [],
                window,
            };
        }, // End of Component > data

        methods: {

            /**
             * Handle when redirecting to the municipality's audit logs
             *
             * @return {void}
             */
            handleRedirectAudits() {
                this.$router.push({
                    name:'audits',
                    params:{
                        id:this.$route.params.id,
                        auditable:'municipalities'
                    },
                    query: {
                        relationships: 'files,invoiceReminderSetting,blacklisted_users,booking_fields,batches_settings'
                    }
                })
            }
        },

        mounted() {
          const id = this.$route.params.id;
          let target = 'municipalities';
          let apiPrefix = window.app_api_prefix_3;
          APICaller({
            method: 'get',
            endpoint: gep(target + `/${id}`, apiPrefix),
            params: { include: 'companies' },
            data: {},
          }).then(r => {
            this.tableData = r.data.data.municipality.companies
          });

        }, // End of Component > mounted

    } // End of export default
</script>
