<template>
  <div class="app-form maintenance-mode-settings-form">
    <el-form :model="form">
      <el-row class="maintenance-row">
        <el-col :md="4">
          <h3>{{$t('admin_panel')}}</h3>
        </el-col>
        <el-col :md="8">
          <el-row>
            <el-col style="margin-top:15px" :md="4">
              <el-switch
                active-value="1"
                inactive-value="0"
                v-model="form.value.admin_panel.read_only"
              ></el-switch>
            </el-col>
            <el-col :md="6">
              <h5>{{$t('big_warning_message')}}</h5>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-row class="maintenance-row">
        <el-col :md="4">
          <h3>CT</h3>
        </el-col>
        <el-col :md="8">
          <el-row>
            <el-col style="margin-top:15px" :md="4">
              <el-switch active-value="1" inactive-value="0" v-model="form.value.ct.read_only"></el-switch>
            </el-col>
            <el-col :md="6">
              <h5>{{$t('read_only')}}</h5>
            </el-col>
          </el-row>
          <el-row>
            <el-col style="margin-top:15px" :md="4">
              <el-switch active-value="1" inactive-value="0" v-model="form.value.ct.fully_closed"></el-switch>
            </el-col>
            <el-col :md="6">
              <h5>{{$t('fully_closed')}}</h5>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-row class="maintenance-row">
        <el-col :md="4">
          <h3>{{$t('mobile_app')}}</h3>
        </el-col>
        <el-col :md="8">
          <el-row>
            <el-col style="margin-top:15px" :md="4">
              <el-switch
                active-value="1"
                inactive-value="0"
                v-model="form.value.mobile_app.read_only"
              ></el-switch>
            </el-col>
            <el-col :md="6">
              <h5>{{$t('read_only')}}</h5>
            </el-col>
          </el-row>
          <el-row>
            <el-col style="margin-top:15px" :md="4">
              <el-switch
                active-value="1"
                inactive-value="0"
                v-model="form.value.mobile_app.fully_closed"
              ></el-switch>
            </el-col>
            <el-col :md="6">
              <h5>{{$t('fully_closed')}}</h5>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-form-item class="text-right">
        <el-button
          type="primary"
          @click="handleMaintenanceModeSubmit()"
        >{{$t('update_maintenance_mode_settings')}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>


<script>
import { mapGetters, mapState, mapActions } from 'vuex';
export default {
  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {};
  }, // End of Component > data
  /*
  |----------------------------------------------------------------------------
  | Component > computed
  |----------------------------------------------------------------------------
  */
  computed: {
    /**
     * state of vuex for MaintenanceMode
     */
    ...mapState('maintenanceMode', {
      form: 'compose'
    })
  }, // end of component > computed
  /*
  |----------------------------------------------------------------------------
  | Component > methods
  |----------------------------------------------------------------------------
  */
  methods: {
    /**
     * Actions for MaintenanceMode
     */
    ...mapActions('maintenanceMode', {
      postMaintenanceModeSettings: 'postMaintenanceModeSettings',
      getMaintenanceModeSettings: 'getMaintenanceModeSettings',
      updateMaintenanceModeSettings: 'updateMaintenanceModeSettings'
    }),
    /**
     * Handle handleMaintenanceModeSubmit settings submit
     */
    handleMaintenanceModeSubmit() {
      var id = _.get(this.form, 'id');
      /**
       * If there is ID present in the form then just Update
       * Else POST MaintenanceMode
       */
      if (!id) {
        this.postMaintenanceModeSettings(this.form);
      } else {
        this.updateMaintenanceModeSettings(this.form);
      }
    },
    /**
     * Prepare the basic setup for MaintenanceMode Settings
     */
    prepareMaintenanceModeSettingsForm() {
      this.getMaintenanceModeSettings();
    }
  }, // end of component > method
  /*
  |----------------------------------------------------------------------------
  | Component > mounted
  |----------------------------------------------------------------------------
  */
  mounted() {
    this.prepareMaintenanceModeSettingsForm();
  } // End of component > mounted
}; // End of export default
</script>

<style scoped>
.maintenance-row {
  border-bottom: 1px solid #ebeef5;
}
</style>
