<template>
  <el-dialog
  :title="title"
  :visible.sync="iVisible"
  :modal-append-to-body="false"
  :append-to-body="true"
  custom-class="custom-modal"
  width='70%'>
<h3>{{$t('please_provide_details_about_locking')}}</h3>
<wysiwyg v-model="form.comment" :aria-placeholder="$t('enter_text')" :placeholder="$t('enter_text')"></wysiwyg>
  <div class="el-form-actions">
    <el-button class="modal-size" type="default" @click="iVisible = false">{{$t('cancel')}}</el-button>
    <el-button class="modal-size" type="primary" @click="handleLockSubmit">{{action}}</el-button>
  </div>
  </el-dialog>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { isEmpty } from '../../../js/helpers/Common';
export default {
    props: {
         visible: {
            type: Boolean,
            default: false
        },
        lockBooking: {
            type: Function
        },
        status: {
          type: String,
          default: ''
        },
        closeTag: {
          type: Boolean,
          default : false
        },
        top : {
          type: String
        }

    },
     data() {
        return {
         window,
         form: {}
        }
    },
    computed: {
      /**
       * Interface for this.visible
       */
      iVisible: {
        get() {
            return this.visible
        },
        set(v) {
            this.$emit('update:visible', v);
        }
      },
      title () {
        return this.status === 'Lock booking' ? this.$t('lock_booking') : this.$t('unlock_booking');
      },
      action () {
        return this.status === 'Lock booking' ? this.$t('tfv_lock_action') : this.$t('unlock_booking');
      }
    },
    methods:{
       /**
       * Handle when the modal has been closed
       *
       * @return {void}
       */
      handleClose: function () {
          this.$emit('update:visible', false);
      }, // End of handleClose() method
      handleLockSubmit() {
        if(this.form.comment) {
         
        this.status ==  'Lock booking' ?  this.form.is_locked = 1 :   this.form.is_locked = 0
        this.lockBooking(this.form)
        }
      }

    }
}
</script>
<style lang="scss" scoped>

.el-dialog__header {
  text-align: center !important;
  background: #3a75ba !important;
}
.el-dialog__title {
  color: #ffffff !important;
  font-size: 22px !important;
}
.el-form-actions {
  text-align: center !important;
  margin-top: 15px;
}

</style>
