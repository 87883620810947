/*
|--------------------------------------------------------------------------
| Financial > Shared > methods > deleteSettings
|--------------------------------------------------------------------------
| Partial file as a reusable method for deleting a financial settings record.
*/
'use strict';

import API from '~/js/constants/api';
import APICaller from "~/js/helpers/APICaller";
import {mapEndpoint} from "~/js/helpers/Common";

export default function (context, payload) {
  const target = context.state.endpointKey;
  const url = `${API.FINANCIAL.SETTINGS[target.toUpperCase()]}/{id}`;

  const apiOpts = {
    method: 'DELETE',
    endpoint: mapEndpoint(url, payload),
  };

  return APICaller(apiOpts)
    .then(() => {
      context.commit('resetSettings');
    });
}

