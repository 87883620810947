/*
|--------------------------------------------------------------------------
| Store > SMS Centre
|--------------------------------------------------------------------------
|
| This file contains the mutation of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/

"use strict";
import {mapProps} from "~/js/helpers/Common";

export default {

  /**
   *
   * @param state
   * @param payload
   */
  setConversationList: function (state, payload) {
    state.conversationList = _.orderBy(payload, ['is_read', 'created_at'], ['asc', 'desc']);
  },

  /**
   *
   * @param state
   * @param payload
   */
  setConversation: function (state, payload) {
    state.conversation = payload;
  },

  /**
   *
   * @param state
   * @param payload
   */
  setNewMessage(state, payload) {
    state.conversation.sms_centre_conversation.push(payload.item);
    state.conversation.sms_centre_conversation = _.uniqBy(state.conversation.sms_centre_conversation, function (e) {
      return e.id;
    });
  },

  /**
   *
   * @param state
   * @param payload
   */
  setBookingIds(state, payload) {
    state.bookingIds = payload.sms_channel_bookings.map((booking) => {
      return booking.id
    });

  },

  /**
   *
   * @param state
   * @param payload
   */
  setSelectedRow(state, payload) {
    state.selectedRow = payload;
  },

  /**
   * 
   * @param state
   * @param payload
   */
  setConversationCount(state, payload) {
    state.conversationCount = payload;
  }


};
