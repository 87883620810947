<template>
  <div class="app-page booking-page">

    <div class="app-page-top-bar">
      <!-- topbar  -->
      <div class="app-page-top-bar-wrapper">
        <div class="logo">
          <img src="/images/defaults/brand-logo.png" alt="" srcset="">
        </div>
        <div class="top-bar-extras content-body">
          <ul>
            <li>
              <router-link to="/dashboard" title="Statistics">
                <span class="fa fa-bar-chart"></span> Admin Panel
              </router-link>
            </li>
            <li>
              <el-dropdown>
                <i class="el-icon-setting" style="margin-right: 15px"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>Logout</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--  end of Topbar -->

    <!-- Banner -->
    <section>
      <div class="img-container">
        <div class="banner-text">
        </div>
      </div>
    </section>
    <!-- Banner -->

    <!-- Booking Form -->
    <div class="booking-form-section" v-loading="loading">
      <el-row>
        <el-col :span="20" :offset="1">
          <div class="content-body text-booking-form" @mouseenter="formFocusHandler(true)"
               @mouseleave="formFocusHandler(false)">
            <div class="form-container">
              <el-form :model="form" ref="booking_form" :validate-on-rule-change="false" :rules="rules">

                <el-row :gutter="5">

                  <el-col :md="10" :offset="5">
                    <el-form-item label="" prop="customer_id">
                      <el-select :disabled="new_customer" v-model="form.customer_id" filterable
                                 placeholder="Type To Search Customer" remote ref="customer"
                                 :remote-method="searchCustomer" :loading="searchCustomerLoading" clearable>
                        <el-option v-for="i in searchResult" :key="i.id" :label="i.name +' - '+ i.email+' - '+ i.id"
                                   :value="i.id">
                        </el-option>
                      </el-select>
                    </el-form-item>

                  </el-col>
                  <el-col :md="6" style="padding-top: 10px">
                    <el-form-item>
                      <el-switch
                        style="display: block"
                        v-model="new_customer"
                        inactive-text="Existiing customer"
                        active-text="New Customer">
                      </el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :md="2">
                    <el-button type="success" size="mini" @click="resetForm">
                      <small>{{$t('reset')}}</small>
                    </el-button>
                  </el-col>
                </el-row>
                <el-row :gutter="5">
                  <el-col :md="3"> &nbsp;</el-col>
                  <el-col :md="6">
                    <el-form-item prop="from_language_id">

                      <el-select v-model="form.from_language_id" placeholder="From Language" filterable
                                 @change="checkDialects" ref="language" @blur="hasDialects=false">
                        <el-option v-for="language in languages" :key="language.id" :label="language.name"
                                   :value="language.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="6">
                    <el-form-item prop="to_language_id">

                      <el-select v-model="form.to_language_id" placeholder="To Language" filterable
                                 @change="checkDialects" ref="language" @blur="hasDialects=false">
                        <el-option v-for="language in languages" :key="language.id" :label="language.name"
                                   :value="language.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="6">
                    <el-form-item prop="due">
                      <el-date-picker v-model="form.due" type="date" :placeholder="$t('due_date')" format="yyyy-MM-dd"
                                      value-format="yyyy-MM-dd 00:00:00" popper-class="day-picker-only"
                                      @change="handleDateChange"></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :md="18" :offset="3">
                    <aws-file-upload
                      v-model="form.text_translation_files"
                    />


                    <h4> Descriptions</h4>
                    <el-form-item label="" :id="genContainerId('descriptions',formElId)" prop="descriptions">
                      <el-input v-model="form.descriptions" placeholder="" type="textarea" rows="5"></el-input>
                    </el-form-item>

                    <el-form-item :id="genContainerId('options',formElId)" label="" class="label-short">

                      <!-- <el-checkbox v-model="form.is_express" label="Express delivery" :true-label="1" :false-label='0' ></el-checkbox> <br> -->
                      <span>How Should the translation be delivered?</span> <br>
                      <el-radio-group
                        v-model="form.deliver_with"
                        :id="genFieldId('options',formElId)"
                        tabindex="0"
                      >
                        <el-radio label="email">Email</el-radio>
                        <br><br>
                        <el-radio label="dt_web">DT web</el-radio>
                        <br><br>
                        <el-radio label="mail">Mail</el-radio>
                      </el-radio-group>
                    </el-form-item>

                    <h4> Does the document need to be stamped?</h4>
                    <el-form-item :id="genContainerId('options',formElId)" label="" class="label-short">
                      <el-radio-group
                        v-model="form.is_stamped"
                        :id="genFieldId('is_stamped',formElId)"
                        tabindex="0"
                      >
                        <el-radio :label="1">Yes</el-radio>
                        <el-radio :label="0">No</el-radio>

                      </el-radio-group>
                    </el-form-item>

                    <h4>Is the document confidential or non-confidential info?</h4>
                    <el-form-item :id="genContainerId('options',formElId)" label="" class="label-short">

                      <el-radio-group
                        v-model="form.is_confidential"
                        :id="genFieldId('is_confidential',formElId)"
                        tabindex="0"
                      >
                        <el-radio :label="1">Yes</el-radio>
                        <el-radio :label="0">No</el-radio>
                      </el-radio-group>

                    </el-form-item>


                  </el-col>
                </el-row>
                <!-- more options -->
                <el-row :gutter="5">
                  <el-col :md="3">
                    <el-button icon="el-icon-plus" class="el-button--default circle mini"
                               @click="showMoreOptions(moreOptions)" size="mini"></el-button>
                    <small>{{$t('more_options')}}</small>
                  </el-col>

                  <el-col :md="6" v-show="moreOptions==true">
                    <!-- <el-select multiple collapse-tags filterable v-model="form.specific_translators" :placeholder="$t('specific_translators')">
                        <el-option v-for="translator in translators" :key="translator.id" :label="translator.name" :value="translator.id"></el-option>
                    </el-select> -->
                    <multiple-translator-select v-model="specific_translators" :multiple="true"
                                                :placeholder="'Please input 3 letters to search specific translator'"
                                                refs="specific_translator"></multiple-translator-select>

                  </el-col>


                  <el-col :md="6" v-show="moreOptions==true">
                    <!-- <el-select multiple filterable v-model="form.exclude_translators" :placeholder="$t('excluded_translators')">
                        <el-option v-for="translator in translators" :key="translator.id" :label="translator.name" :value="translator.id"></el-option>
                    </el-select> -->
                    <multiple-translator-select v-model="exclude_translators" :multiple="true"
                                                :placeholder="'Please input 3 letters to search excluded translator'"
                                                refs="excluded_translator"></multiple-translator-select>

                  </el-col>
                </el-row>

                <el-row v-show="moreOptions==true">
                  <el-col :md="3">&nbsp;</el-col>
                  <el-col :md="6">
                    <div style="margin:10px; text-align:right;">or assigned a translator directly
                    </div>
                  </el-col>
                  <el-col :md="6">
                    <span></span>
                    <!-- <el-select filterable clearable v-model="form.assigned_translator" :placeholder="$t('choose_translator')" @focus="getedFocus" @visible-change="switchFocus" ref="assigned_translator">
                        <el-option v-for="translator in translators" :key="translator.id" :label="translator.name" :value="translator.id"></el-option>
                    </el-select> -->
                    <translator-select v-model="form.assigned_translator"
                                       placeholder="Search assigned translator"></translator-select>

                  </el-col>
                </el-row>

                <!-- Submit Button -->
                <el-row :gutter="30">
                  <el-col :span="2" :offset="12" class="text-center">
                    <el-button type="primary" icon="el-icon-fa fa-sign-in" @click="submitForm('booking_form')">
                      Submit
                    </el-button>
                  </el-col>
                </el-row>
              </el-form>
              <!-- /#login_form -->
            </div>
            <!-- /.form-container -->
          </div>
          <!-- /.content-body -->
        </el-col>
      </el-row>
      <!-- /.booking-form el-row -->
    </div>
    <!-- /.booking-form section -->

    <div>
      <el-row :gutter="20" style="margin:15px 15px 50px;">
        <el-col :md="8">
          <el-card shadow="hover">
            <img src="https://dtolk.blob.core.windows.net/assets/cards/September2017/ZSVyZhLqfNZA5ieulEc0.png" alt=""
                 class="circle">
            <div class="title">
              <h2>Bli kund </h2>
            </div>
            <div class="border"></div>
            <div class="content">Det är enkelt att bli kund hos oss. Du kan betala direkt eller ansöka om kundnummer och
              få faktura.
            </div>
          </el-card>
        </el-col>
        <el-col :md="8">
          <el-card shadow="hover">
            <img src="https://dtolk.blob.core.windows.net/assets/cards/September2017/6POPexWxcphLvGKhULQ1.png" alt=""
                 class="circle">
            <div class="title">
              <h2>Välj rätt tolk och metod</h2>
            </div>
            <div class="border"></div>
            <div class="content">Beroende på uppdragets karaktär väljer du den kompetens och metod som krävs. Vi hjälper
              dig gärna om du är osäker på vad som blir bäst.
            </div>

          </el-card>
        </el-col>
        <el-col :md="8">
          <el-card shadow="hover">
            <img src="https://dtolk.blob.core.windows.net/assets/cards/September2017/i0d9JeFGF8aY1wIPcqrL.png" alt=""
                 class="circle">
            <div class="title">
              <h2>Tips för lyckad tolkning </h2>
            </div>
            <div class="border"></div>
            <div class="content">Tolkningens resultat beror på många faktorer. Det finns många saker som du som
              beställare kan tänka på för att öka förutsättningarna.
            </div>

          </el-card>
        </el-col>
      </el-row>
    </div>
    <language-options-modal :visible.sync="showLanguageOptions" :dialects='dialects'
                            :width="modalWidth"></language-options-modal>
    <confirmation-fills-modal
      :visible.sync="showConfirmationsFills"
      :is-physical="form.type ==='physical'"
      :language="language"
      :change-address.sync="changeAddress"
      :translator-levels="translatorLevel"
      :width="modalWidth"/>
    <create-department :afterCreateHandler="handleUserCreationChange" :visible.sync="showCreateDepartment"/>
  </div>

</template>

<script>

  import {Notification} from "element-ui";
  import {
    mapGetters,
    mapActions,
    mapState
  } from 'vuex';

  import LanguageOptionsModal from '~/components/modals/booking/LanguageOptionsModal';
  import {
    genFieldId,
    genContainerId
  } from '~/js/helpers/Html'
  import {
    isEmpty
  } from '~/js/helpers/Common'
  import {populate, forcePopulate} from "~/js/helpers/Vuex";

  import APICaller from '~/js/helpers/APICaller';
  import AwsFileUpload from '~/components/displays/AwsFileUpload';

  export default {
    components: {
      LanguageOptionsModal,
      AwsFileUpload
    },
    /*
    |--------------------------------------------------------------------------
    | component > data
    |--------------------------------------------------------------------------
    */
    data () {
      var checkTo = (rule, value, callback) => {
        let dueTime = window.moment(this.$refs.booking_form.model.time, 'HH:mm')
        let val = window.moment(this.toTime, 'HH:mm')
        if (value === '') {
          return callback(new Error('Please input To time value'));
        } else if (val.isSameOrBefore(dueTime)) {
          return callback(new Error('Please input To time value more than due time'));
        } else {
          callback()
        }
      }
      const a = this.new_customer
      return {
        toTime: '',
        timeOptions: {
          selectableRange: '00:00 - 23:59'
        },
        formElId: 'booking-form',
        moreOptions: false,
        showConfirmationsFills: false,
        showLanguageOptions: false,
        hasDialects: false,
        isConvey: false,
        isFormFocused: false,
        searchCustomerLoading: false,
        searchResult: [],
        selectedCustomer: {},
        durations: [{
          key: 15,
          value: '15 min'
        },
          {
            key: 30,
            value: '30 min'
          },
          {
            key: 45,
            value: '45 min'
          },
          {
            key: 60,
            value: '1 timme'
          },
          {
            key: 90,
            value: '1.5 timme'
          },
          {
            key: 120,
            value: '2 timme'
          },
          {
            key: 150,
            value: '2.5 timme'
          },
          {
            key: 180,
            value: '3 timme'
          },
          {
            key: 210,
            value: '3.5 timme'
          },
          {
            key: 240,
            value: '4 timme'
          },
          {
            key: 270,
            value: '4.5 timme'
          },
          {
            key: 300,
            value: '5 timme'
          },
          {
            key: 330,
            value: '5.5 timme'
          },
          {
            key: 360,
            value: '6timme'
          },
          {
            key: 420,
            value: '7 timme'
          },
          {
            key: 480,
            value: '8 timme'
          },
        ],
        enabled: 1,
        dialects: [],
        customer: null,
        timeRange: '00:00 - 23:59',
        modalWidth: '50%',
        translator_levels: [1, 2, 3, 4, 5, 6],
        changeAddress: false,
        temporaryDom: '',
        exclude_translators: [],
        specific_translators: [],
        new_customer: false,
        showCreateDepartment: false,
        fileList: []

      };
    }, // End of component > data

    /*
    |--------------------------------------------------------------------------
    | component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('booking', {
        form: 'bookingForm'
      }),
      ...mapGetters({
        // form: 'booking/getForm',
        is_immediate: 'booking/isImmediate',
        languages: 'language/getLanguages',
        translator_types: 'booking/getTranslatorTypes',
        translatorLevel: 'booking/getTranslatorLevels',
        // translator_levels: 'booking/getEnabledTranslatorLevels',
        customersList: 'booking/getCustomersList',
        translators: 'booking/getTranslatorsList',
        departments: 'booking/getDepartmentsList',
        companies: 'booking/getCompaniesList',
        getCustomer: 'booking/getCustomer',
        loading: 'booking/getLoading',
        departmentsListLoading: 'booking/getDepartmentsListLoading',
        customersListLoading: 'booking/getCustomersListLoading',
        bookingField: 'booking/getBookingField',
        confirmationFills: 'booking/getConfirmationFills',
      }),
      language: {
        get () {
          return this.languages.find((obj) => {
            return obj.id === this.form.from_language_id
          })
        }
      },

      /**
       * @return {array}
       */
      timeOpts () {
        let start = moment().startOf('day');
        let end = moment().endOf('day');
        let result = [];
        do {
          result.push({value: start.format('HH:mm')});
          start.add(30, 'minutes');
        } while (end.diff(start) > 0)
        return result;
      },

      now () {
        return moment().format('YYYY-MM-DD HH:mm:ss')
      },
      rules () {
        return {
          customer_id: [{
            required: !this.new_customer,
            message: 'Please Select Customer'
          }],
          from_language_id: [{
            required: true,
            message: 'Please Select Lanaguage'
          }],
          to_language_id: [{
            required: true,
            message: 'Please Select Lanaguage'
          }],

        }
      }
    }, // End of component > computed

    /*
    |--------------------------------------------------------------------------
    | component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions('language', {
        getLanguages: 'fetchLanguages',
      }),
      ...mapActions('booking', {
        resetForm: 'resetBookingForm',
        fetchTranslatorLevels: 'fetchTranslatorLevels',
        fetchCustomers: 'fetchCustomers',
        filterCustomers: 'filterCustomers',
        fetchTranslators: 'fetchTranslators',
        fetchDepartments: 'fetchDepartments',
        fetchCompanies: 'fetchCompanies',
        bookingsValidate: 'bookingsValidate',
        assignCustomer: 'assignCustomer',
        changeTimeFormat: 'changeTimeFormat',
        getBookingsList: 'getBookingsList'
      }),

      formFocusHandler (state) {
        this.isFormFocused = state;
      },

      showMoreOptions (val) {
        this.moreOptions = !val;
      },

      //show languages options modal handler
      handleShowLanguageOptions () {
        this.hasDialects = false
        this.showLanguageOptions = true
      },

      // check language dialects when select language value changed
      // then show set dialects modal
      checkDialects () {

        var language = this.languages.find(language => {
          return language.id == this.form.from_language_id
        })

        if (!isEmpty(language.dialects) && language.dialects.length > 0) {
          this.hasDialects = true
          this.dialects = language.dialects
        }

      },
      bookingValidate (form) {

        this.bookingsValidate(form).then(response => {
          if (response.status === 200) {
            if (this.form.type === 'physical' && isEmpty(this.confirmationFills.city)) {
              this.changeAddress = true
            }
            this.showConfirmationsFills = true
          }
        })
      },

      submitForm (form) {
        this.hasDialects = false;
        this.$refs[form].validate((valid) => {
          if (valid) {
            if (this.new_customer) {
              this.showCreateDepartment = true;
              return;
            }
            this.resetTimeFormat();
            if (!isEmpty(this.specific_translators)) {
              // newForm.specific_translators = this.specific_translators.map(o => o.id)
              this.$store.commit('booking/setSpecificTranslators', this.specific_translators.map(o => o.id))
            }
            if (!isEmpty(this.exclude_translators)) {
              this.$store.commit('booking/setExcludeTranslators', this.exclude_translators.map(o => o.id))
            }


            let newForm = {
              ...this.$store.state.booking.bookingForm,
              ...this.$store.state.booking.is_immediate
            }


            this.assignCustomer(newForm.customer_id)
            this.bookingValidate(newForm)
          } else {
            return false
          }
        })
      },

      // update enable emergency & update validations
      enableEmergency () {
        this.$store.dispatch('booking/enableEmergency', this.is_immediate)

        //set due date and time validation at emergency booking to false
        if (this.is_immediate) {
          this.rules.date[0].required = false
          this.rules.time[0].required = false
        } else {
          this.rules.date[0].required = true
          this.rules.time[0].required = true
        }
      },

      handleDateChange (e) {
        let v = e.split(' ')
        console.log(v)
        this.form.date = v[0];
        this.form.time = v[1];
      },

      /**
       * Set Customer Object
       */
      setCustomer () {
        this.assignCustomer(this.form.customer_id)
      },


      resetTimeFormat () {
        this.changeTimeFormat(this.form.time)
      },

      filterDepartments (event) {
        let params = {
          'filter[company_id]': event,
          all: true
        }
        this.fetchDepartments(params)
      },

      handleFilterTime (q, cb) {
        let opts = [];
        if (q !== '') {
          opts = _.filter(this.timeOpts, (v) => {
            return _.includes(v.value, q);
          });
        } else {
          opts = this.timeOpts;
        }
        cb(opts);
      },
      handleFilterTo (q, cb) {
        let opts = [];
        if (q !== '') {
          opts = _.filter(this.timeOpts, (v) => {
            return _.includes(v.value, q);
          });
        } else {
          opts = this.timeOpts;
        }
        cb(opts);
      },
      handleTimeChange (e) {
        if (!isEmpty(this.$route.params.cloned)) {
          if (this.$route.params.cloned.duration != "") {
            this.toTime = window.moment(this.form.time, 'HH:mm:ss').add(this.$route.params.cloned.duration, 'minutes').format('HH:mm');
          }
        } else {
          let toTime = window.moment(e.value, 'HH:mm').add('1', 'hours').format('HH:mm')
          this.toTime = toTime
        }
        this.handleToTimeChange()
      },
      handleTimeBlur (e) {
        if (!isEmpty(this.$route.params.cloned)) {
          if (this.$route.params.cloned.duration != "") {
            this.toTime = window.moment(this.form.time, 'HH:mm:ss').add(this.$route.params.cloned.duration, 'minutes').format('HH:mm');
          }
        } else {
          let toTime = window.moment(e.value, 'HH:mm').add('1', 'hours').format('HH:mm')
          this.toTime = toTime
        }
        this.handleToTimeChange()
      },
      handleToTimeChange () {
        let start = moment(this.form.time, 'HH:mm')
        let end = moment(this.toTime, 'HH:mm')
        let duration = end.diff(start, 'minutes')
        this.form.duration = duration
        console.log(this.form.duration)
      },


      searchCustomer (query) {
        if (query !== '' && query.length > 2) {
          this.searchCustomerLoading = true;
          let params = {
            'filter[name]': query,
            'all': true
          }

          setTimeout(() => {
            this.filterCustomers(params).then((response) => {

              if (isEmpty(response)) {
                let params2 = {
                  'filter[email]': query,
                  'all': true
                }
                this.filterCustomers(params2).then((response) => {
                  this.searchResult = response

                })
              }

              this.searchResult = response

              this.searchCustomerLoading = false;
            })

          }, 200)
        } else {
          // this.searchResult = [];
        }
      },
      checkTo (rule, value, callback) {
        let dueTime = window.moment(this.$refs.booking_form.model.time, 'HH:mm')
        let val = window.moment(this.toTime, 'HH:mm')
        if (value === '') {
          return callback(new Error('Please input To time value'));
        } else if (val.isSameOrBefore(dueTime)) {
          return callback(new Error('Please input To time value more than due time'));
        } else {
          callback()
        }
      },
      // Once the API call for creating department and user gets success call this function
      // with newly created customer's ID. It will make necessary data changes and then existing
      // process will continue to work just like before.
      handleUserCreationChange (customer_id) {
        this.form.customer_id = customer_id;
        this.showCreateDepartment = false;
        this.new_customer = false;
        this.submitForm('booking_form')
      },

      handleFilesUpload (e) {
        this.form.convey_files.push(e.file)
      },
      handleRemove (e) {
        this.form.convey_files.filter((file) => {
          return file.uid === e.uid
        })
        // this.form.convey_files = ''
      },
      initiate () {
        if (!isEmpty(this.$route.params.cloned.due)) {
          let datetime = this.$route.params.cloned.due.split(' ');
          this.form.date = datetime[0];
          this.form.time = window.moment(datetime[1], 'HH:mm').format('HH:mm');
          if (this.$route.params.cloned.duration != "") {
            this.toTime = window.moment(this.form.time, 'HH:mm:ss').add(this.$route.params.cloned.duration, 'minutes').format('HH:mm');
          } else {
            let toTime = window.moment(this.form.time, 'HH:mm').add('1', 'hours').format('HH:mm')
            this.toTime = toTime
          }
          this.handleToTimeChange();
        }
        this.form.from_language_id = this.$route.params.cloned.language;
        this.form.type = this.$route.params.cloned.type;
        this.form.customer_id = this.$route.params.cloned.customer_id;
        if (this.$route.params.cloned.gender === 'male') {
          this.translator_levels.push('male')
        }
        if (this.$route.params.cloned.gender === 'female') {
          this.translator_levels.push('female')
        }
        this.form.address = this.$route.params.cloned.address;
        this.form.booker_name = this.$route.params.cloned.booker_name;
        this.form.staff_name = this.$route.params.cloned.staff_name;
      }

    }, // End of component > methods
    /*
    |--------------------------------------------------------------------------
    | component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {

      let form = {
        type: 'text_translation',
        text_translation_files: [],
        duration: 15,
        deliver_with: 'email',
        is_express: 0,
        is_stamped: 0,
        is_confidential: 0,
        submission_type: 'review'
      }
      this.$store.commit('booking/setForm', Object.assign(this.form, form));
      let self = this

      populate(this, 'getLanguages', 'languages', {
        ref: 'Languages Options'
      });


      if (!isEmpty(this.$route.params.cloned)) {
        this.initiate();
      }

    },
    created () {
      if (window.innerWidth < 480) {
        this.modalWidth = '90%';
      }
    }, // End of component > created

  } // End of export default
</script>

<style lang="scss">
  .text-booking-form {
    margin-top: 20px;

    .el-input__inner {
      border-radius: 5px;
      // border-bottom-right-radius: 5px;
      background-color: #ffffff;
      border: 1px solid #d8dce5;
    }

    .el-radio-group {
      .el-radio__label {
        font-size: 10px !important;
      }
    }
  }

  .vue-input-tag-wrapper .input-tag .remove {
    padding-left: 5px;
  }

  .el-textarea__inner {
    border-radius: 5px !important;
  }

  .booking-form .el-row {
    margin-bottom: 10px;
  }

  button.mini {
    min-width: 20px !important;
    width: 20px !important;
    height: 20px !important;

    [class^="el-icon-"]:before {
      font-size: 12px !important
    }
  }

  .el-loading-mask {
    background-color: rgba(255, 255, 255, 0.45) !important;
  }

  .upload-wrapper {
    margin-right: 50px
  }
</style>
