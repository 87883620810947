<template>
    <div class="app-form training-compose-form">
        <el-form :model="form" :id="formElId" :ref="formElId">

            <!-- Name Field -->
            <el-form-item :id="genContainerId('name',formElId)"
                          class="label-short"
                          :label="$t('name')">

                <el-input :id="genFieldId('name',formElId)"
                          v-model="form.name"/>
            </el-form-item>

            <!-- Course Code -->
            <!-- <el-form-item :id="genContainerId('course_code',formElId)"
                          class="label-short"
                          label="Course Code">

                <el-input :id="genFieldId('course_code',formElId)"
                          v-model="form.course_code"/>
            </el-form-item> -->

            <el-form-item :id="genContainerId('course_code', formElId)"
                            class="label-short"
                            :label="$t('course_codes')">
                <multiselect v-model="form.course_code" :options="listCourseCodes" :multiple="true" :taggable="true"
                    placeholder="Course Codes" :clear-on-select="true" :hide-selected="true" @tag="addTag"
                    :internal-search="false">
                    <span slot="noResult">{{$t('insert_course_code')}}</span>
                </multiselect>
            </el-form-item>

            <!-- Description Field -->
            <el-form-item :id="genContainerId('description',formElId)"
                          class="label-short"
                          :label="$t('description')">

                <el-input :id="genFieldId('description',formElId)"
                          v-model="form.description" />
            </el-form-item>

            <!-- Save button -->
            <el-form-item :id="genContainerId('save_btn',formElId)">

                <el-button :id="genFieldId('save_btn',formElId)"
                           type="primary"
                           @click="handleSubmit">
                    {{mode}}
                </el-button>
            </el-form-item>
        </el-form>
    </div> <!-- /.app-form resources-compose-form -->
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import {mapState, mapGetters, mapMutations, mapActions} from "vuex";
    import {checkBreadMode} from "../../../js/helpers/Route";
    import {populate} from '~/js/helpers/Vuex';

    let moduleTarget = 'trainings';

    export default {

        components: {
            Multiselect
        },

        props: [],
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {

                formElId: moduleTarget + '_compose_form',
                blockCls: 'training-compose-page',

            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapState(moduleTarget, {
                form: 'compose',
                payloadData: 'payloadData',
            }),


            ...mapGetters(moduleTarget, {
                entryData: 'itemData',
            }),

            ...mapState('trainingCourseCode', {
                formCourseCode: 'compose',
                payloadDataCourseCode: 'payloadData',
            }),

            ...mapGetters('trainingCourseCode', ['listCourseCodes']),

            /**
             * Value to determine the current mode whether it's add or edit.
             *
             * @return {string}
             */
            mode() {
                return checkBreadMode(this);
            }

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            ...mapActions(moduleTarget, {
                addEntry: 'addTraining',
                editEntry: 'editTraining',
                readEntry: 'readTraining',
            }),

            ...mapActions('trainingCourseCode', {
                browseCourseCodes: 'browse',
                storeCourseCode: 'add',
            }),

            ...mapMutations(moduleTarget, {
                resetForm: 'resetCompose',
            }),

            /**
             * Handle when the form has been submitted.
             *
             * @return {void}
             */
            handleSubmit() {

                // Set the action to be made.
                let action = this.mode + 'Entry';
                let payload = {}; // Set container for payload.

                // If the current compose mode is EDIT, then
                // add the ID of the target into the payload.
                if (this.mode == 'edit') {
                    payload.id = this.$route.params.id;
                }

                this[action](payload).then(r =>{
                    // code here
                });

            }, // End of handleSubmit() method

            setDefaults(mappings) {
                _.forEach(mappings, (value, key) => {
                    this.form[key] = this.form[key] || value;
                });
            },

            addTag(newTag) {
                this.formCourseCode.course_code = newTag;
                this.storeCourseCode().then(r => {
                    populate(this, 'browseCourseCodes', 'listCourseCodes', {
                        force: true,
                        payload: this.populatePayload,
                        ref: 'Training Course Code Listing'
                    });
                });
                this.form.course_code.push(newTag);
            }

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
       watch: {
            "$route.params.id"(val) {
                // call the method which loads your initial state
                this.readEntry({id: this.$route.params.id});
            }
        },
        mounted() {
            this.resetForm();

            // If the current mode is on edit, fetch the property values for the targeted entry.
            if (this.mode == 'edit') {
                this.readEntry({id: this.$route.params.id}).then(response => {
                    // code here
                });
            }

            this.setDefaults({
            });

            populate(this, 'browseCourseCodes', 'listCourseCodes', {
                force: true,
                payload: this.populatePayload,
                ref: 'Training Course Code Listing'
            });

        }, // End of Component > mounted

    } // End of export default
</script>
