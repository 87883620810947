/*
|--------------------------------------------------------------------------
| Store > Messaging-Centre > Blogs > Mutation
|--------------------------------------------------------------------------
|
| This file contains the mutation of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/

"use strict";
import { mapProps } from "~/js/helpers/Common";

export default {
  /**
   * Method to map the value target entry's values into the form.
   *
   * @param  {object} state
   *   : the state property the current Vuex Object.
   *
   * @return {void}
   */
  setCompose(state) {
    mapProps(state.notificationState, state.compose);
  },


  setNotification(state, payload) {
    state.notificationState = payload;
  },

  setNotificationList: function(state, payload) {
    state.notification = payload;
  },

  /**
   * Method to reset the compose prop to its pristine state.
   *
   * @param  {object} state
   *   : the state property the current Vuex Object.
   *
   * @return {void}
   */
  resetCompose(state) {
    state.compose = _.cloneDeep(state.composeFresh);
  },

  setBookingStatusById: (state, {id, code}) => {
    state.statusOption.map((item) => {
      if (item.value === id) {
        state.compose.sent_via = id
        return state
      }

      
    })
  },
};
