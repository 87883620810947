<template>
  <el-form :model="iTraveltime" ref="form">
    <el-form-item :label="$t('travel_distance')">
      <el-input v-model="iTraveltime.travel_distance" :placeholder="`${iMethod} distance`"></el-input>

      <small>{{$t('txt_travel_time_input_number_km')}}</small>
    </el-form-item>
    <el-form-item label="Traveltime">
      <!-- <el-input v-model="iTraveltime.travel_time" placeholder="update time in minutes"></el-input> -->
      <el-autocomplete-default-first
        v-model="iTraveltime.travel_time_formated"
        :placeholder="`${iMethod} travel time with format`"
        :fetch-suggestions="handleFilterTime"
      />
      <small>{{$t('txt_please_input_time_format')}}</small>
    </el-form-item>
    <!-- <el-form-item label="">
        <el-input v-model="iTraveltime.comment" placeholder="update comment"></el-input>
    </el-form-item>-->
    <el-form-item :label="$t('traveled_by')">
      <el-select v-model="iTraveltime.traveled_by" :placeholder="`${iMethod} traveled by`" clearable>
        <el-option label="Car" value="car"></el-option>
        <el-option label="Public Transit" value="transit"></el-option>
        <el-option label="Bicycle" value="bicycle"></el-option>
        <el-option label="Walk" value="walk"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('comment')">
      <el-input v-model="iTraveltime.comment" :placeholder="`${iMethod} comments`"></el-input>
      <!-- {{!isEmpty(iTraveltime.comments)?iTraveltime.comments:''}} -->
    </el-form-item>
    <span class="dialog-footer">
      <el-button @click="iVisible = false" size="mini">{{$t('cancel')}}</el-button>
      <el-button type="primary" @click="submitForm" size="mini">{{$t(iMethod)}} Traveltime</el-button>
    </span>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from '../../../../js/helpers/Common';
export default {
  name: 'updateTravelTimeForm',
  props: {
    traveltime: {
      type: Object,
      default() {
        return {
          id: '',
          entity_id: '',
          entity_type: '',
          by_user_id: '',
          travel_time: 0,
          travel_distance: 0,
          comment: '',
          manual: false,
          traveled_by: '',
          travel_time_formated: ''
        };
      }
    },
    method: {
      type: String,
      default: 'update'
    },
    booking: {
      type: Object,
      default: {}
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    iTraveltime: {
      get() {
        return this.traveltime;
      },
      set(v) {
        this.$emit('input', v);
      }
    },
    iBooking() {
      return this.booking;
    },
    iMethod() {
      return this.method;
    },
    fromTypeSelect() {
      let list = [];
      if (!_.some(this.feedbacks, { review_from_type: 3 })) {
        list.push('translator');
      }
      if (!_.some(this.feedbacks, { review_from_type: 2 })) {
        list.push('customer');
      }
      return list;
    },
    // comments:{
    //   get(){
    //     return (this.traveltime.comments) ? this.traveltime.comments:''
    //   },
    //   set(v){
    //     this.traveltime.comment = v
    //   }

    // },
    iVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit('update', v);
      }
    },
    /**
     * @return {array}
     */
    timeOpts() {
      let start = moment().startOf('day');
      let end = moment().endOf('day');
      let result = [];
      do {
        result.push({ value: start.format('HH:mm:ss') });
        start.add(30, 'minutes');
      } while (end.diff(start) > 0);
      return result;
    }
  },
  methods: {
    isEmpty(v) {
      return isEmpty(v);
    },
    handleFilterTime(q, cb) {
      let opts = [];
      if (q !== '') {
        opts = _.filter(this.timeOpts, v => {
          return _.includes(v.value, q);
        });
      } else {
        opts = this.timeOpts;
      }
      cb(opts);
    },
    submitForm() {
      let form = _.omit(this.iTraveltime, ['updated_at', 'created_at']);
      let date = moment().startOf('day');
      let traveltime = moment(form.travel_time_formated, 'HH:mm:ss');
      form.travel_time = traveltime.diff(date, 'minutes', true);
      form.travel_distance = isEmpty(form.travel_distance)
        ? 0
        : form.travel_distance;

      if (this.iMethod === 'update') {
        this.$store.dispatch('booking/updateBookingTraveltime', form);
      } else {
        this.$store.dispatch('booking/addBookingTraveltime', form);
        this.$emit('updateMethod', 'update');
      }

      this.iVisible = false;
    }
  }
};
</script>

<style>
</style>
