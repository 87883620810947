<template>
  <div class="app-page survey-manager-page">
    <page-header :title="$t('tfv_survey_accumulate_title')" :back-enabled="true"/>

    <div class="app-container">
      <div class="app-wrapper">
        <el-row :gutter="20">
          <ElCol :span="16">
            <el-card class="box-card green">
              <div class="text item">
                <template>
                  <div v-for="(item, index) in listData">
                    <el-row style="backgorund: #f2f2f2" :gutter="10">
                      <ElCol :md="4">
                        <div class="grid-content bg-purple">
                          <h1>{{index+1}}</h1>
                        </div>
                      </ElCol>
                      <ElCol :md="16">
                        <div class="grid-details bg-purple">
                          <p>Q: {{item.title}}</p>
                        </div>
                        <div v-for="data in item.data" class="grid-details bg-purple-light">
                          <p v-if="data.question_type !='textarea'">
                            {{data.answer.replace(/\"/g, "")}}
                            <el-progress
                              :text-inside="true"
                              :stroke-width="26"
                              :percentage="returnRoundedValue(data.percentage)"
                            >Hel</el-progress>
                          </p>
                          <a v-else><u @click=" $router.push({name:'survey.accumulate.individual.response.page',params:{id:$route.params.id, question:data.question_id}}) ">See More</u></a>
                        </div>
                      </ElCol>
                    </el-row>
                  </div>
                </template>
              </div>
            </el-card>
          </ElCol>
        </el-row>
      </div>
      <!-- /.app-wrapper -->
    </div>
    <!-- /.app-container -->
  </div>
  <!-- /.app-page survey-manager-page -->
</template>


<script>
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      listData: []
    };
  },
  methods: {
    ...mapActions({
      getAccumulatedResult: "surveyManager/getAccumulatedResponse"
    }),
    /**
     * Method for filtering unfiltered data from API
     */
    filterData(data) {
      this.listData = _.chain(data)
        .groupBy("Title")
        .toPairs()
        .map(item => {
          return _.zipObject(["title", "data"], item);
        })
        .value();
    },
    returnRoundedValue(value) {
      return parseInt(value);
    },
  },

  /**
   * Fetching Data from API for Rendering Accumalated Response
   */
  mounted() {
    this.getAccumulatedResult(this.$route.params.id).then(res => {
      this.filterData(res);
    });
  }
};
</script>






<style lang="scss" scoped>
  //noinspection CssUnknownTarget
  @import '~/scss/global/_variables.scss';

.el-col {
  border-radius: 4px;
}
.el-card__header {
  background-color: $app-primary-color !important;
  color: white !important;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 20px;
  padding: 10px;
  text-align: center;
}
.grid-details {
  border-radius: 4px;
  min-height: 20px;
  padding: 10px;
  margin-top: 5px;
}
</style>
