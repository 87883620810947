<template>
    <div class="app-page resources-list-page">
        <page-header :title="pageTitle"
            :linkData="linkData"
            :back-enabled="true"
        />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col full-width">
                        <resources-list />
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page resources-list-page -->
</template>

<script>
export default {

    data() {
        return {
            pageTitle: '',
            linkData: {}
        }
    },

    mounted() {
        if (this.$route.query.assign_booking_id != null) {
            this.pageTitle = this.$t('assign_resource_booking') + this.$route.query.assign_booking_id;
        } else if (this.$route.name === 'resources.booking') {
            this.pageTitle = this.$t('resource_booking_for');
            this.linkData = {
                text : this.$route.params.id,
                path : 'booking/' + this.$route.params.id + '/details',
                preText : '(#',
                postText : ')'
            }
        } else {
            this.pageTitle = this.$t('resource_booking');
        }
    }
}
</script>
