<template>
     <div class="app-page bookings-issue-tags-page">

        <page-header :title="$t('booking_issue_tags')" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col full-width">
                        <div class="content-body" v-loading="list.loading">
                            <booking-issue-tags-form
                                v-bind:tagData="tagData"
                                :isBeingUpdated="isBeingUpdated"
                                @tagFormDialogClosed="this.tagFormDialogClosed"
                                @pagerChanged="pagerChanged"
                                />

                            <booking-issue-tags-list
                                :tagList="list.data"
                                :tagPagination="list.pagination"
                                @updateTagClicked="updateTag"
                                />
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import {populate,forcePopulate} from "~/js/helpers/Vuex"
export default {
    props: {

    },

    data() {
        return {
            populatePayload:{},
            tagData : {},
            isBeingUpdated: false
        }
    },

    computed: {
        ...mapState('bookingIssueTag',{list:'list'})
    },

    methods: {
        ...mapActions({
            'browse':'bookingIssueTag/browse'
        }),

        updateTag: function(updateTagData) {
            this.tagData = updateTagData
            this.isBeingUpdated = true
        },

        pagerChanged : function(updatedTagData) {
            this.list = updatedTagData
        },

        tagFormDialogClosed: function() {
            this.isBeingUpdated = false
        }
    },

    mounted() {
        populate(this, 'browse', '', {force: true,params:this.populatePayload})
    }
}
</script>