export const multiBarChartData = {
    type: 'bar',
    data: null,
    options: {
      plugins: {
        datalabels: {
          color: '#fff',
          font: {
            size: 12,
          }
        }
      },
      legend: {
        onClick: function(event, legendItem) {}
      },
      cornerRadius: 10,
      responsive: true,
      lineTension: 1,
      scales: {
        xAxes: [{
          stacked: true,
          gridlines: {
            display: false,
          }
        }],
        yAxes: [{
          stacked: true ,
          ticks: {
            beginAtZero: true,
            padding: 25
          }
        }],
        legend: {
          boxWidth: 10,
        }
      }
    }
  }
  
  export default multiBarChartData;