/*
|--------------------------------------------------------------------------
| Store > Bookings > Batches
|--------------------------------------------------------------------------
|
| A Class file that manages all the properties and abilities in relation
| to Bookings > Batches.
|
*/
'use strict';

import {extendObj} from '~/js/helpers/Common'
import bread from '../../bread';
import state from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: extendObj(bread.state, state),
  getters: extendObj(bread.getters, getters),
  mutations: extendObj(bread.mutations, mutations),
  actions: extendObj(bread.actions, actions),
} // End of export default


