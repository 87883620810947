/*
|--------------------------------------------------------------------------
| Store > Survey-manager
|--------------------------------------------------------------------------
|
| A Class file that manages all the properties and abilities in relation
| to Feedback.
|
*/

import actions from './actions'

export default {
  namespaced:true,
  actions
}