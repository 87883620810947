<template>
    <div class="app-list email-logs-list">
        <p>
            {{$t('email_logs')}}
        </p>


        <div class="list-base-table-top-controls">
            <div class="col-container full-width text-right">
                            <el-input placeholder="Search Records" v-model="search" style="max-width: 350px;">
                    <el-button type="primary" slot="append" icon="el-icon-fa fa-search" style="min-width: 50px;"></el-button>
                </el-input>
            </div> <!-- /.app-col -->

        </div>

        <el-table :data="mockData" border style="font-size: 12px">

            <el-table-column v-for="item in tableCols"
                             :key="item.prop"
                             v-if="isColEnabled(item.prop)"
                             :label="item.label"
                             :width="item.width">
                <template slot-scope="scope">
                    <div class="cell-content" :id="genColId(tableElId, item.prop, scope.row.booking_id)">
                        {{ scope.row[item.prop] }}
                    </div>
                </template>
            </el-table-column>

            <!-- Actions Column -->
            <el-table-column class-name="actions-col"
                             :label="$t('actions')">

                <template slot-scope="scope">
                    <el-button type="default" class="el-button el-button--default circle"
                    :id="scope.row.id+'details_btn'"
                    @click="handleActivity('details',scope.row.id, scope.row)">
                    <i class="fa fa-info"></i>
                    </el-button>
                </template>
        </el-table-column>
        </el-table>

        <div class="pagination-container">
            <div class="pagination-wrapper">
                <el-pagination
                        layout="prev, pager, next"
                        :total="1000">
                </el-pagination>
            </div> <!-- /.pagination-wrapper -->
        </div> <!-- /.pagination-container -->

         <base-details-modal
                :visible.sync="detailsVisible"
                custom-class="sms-logs-list-modal"
                :extra-rows="tableCols"
                :append-to-body="false"
                :info="detailsInfo">
        </base-details-modal>

    </div> <!-- /.app-list invoice-list -->
</template>

<script>
    import {genFieldId, genContainerId, genColId} from "../../../js/helpers/Html";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            const _this=this;
            return {

                search: '',

                tableElId: 'email-logs-list',

                tableCols: [
                    {prop: 'action', label: _this.$t('action')},
                    {prop: 're_send', label: _this.$t('resend'), width: '100'},
                    {prop: 'event', label: _this.$t('event')},
                    {prop: 'subject', label: _this.$t('subject'), width: '400'},
                    {prop: 'content', label:  _this.$t('content'), width: '400'},
                    {prop: 'created_at', label:  _this.$t('sent_at')},
                    {prop: 'email', label:  _this.$t('sent_to'), width:'150'}
                ],

                hiddenCols: [],

                mockData: [
                    {
                        action: 'Send via SendGrid',
                        re_send: 'No',
                        event: 'delivered',
                        subject: 'Vi har mottagit er tolkbokning. Bokningsnr: # 72426',
                        content: 'Hej! Tack för din tolkbokning! Bokningsdetaljer: Bokningsnummer: #72426 ...',
                        created_at: '2018-07-19 13:08:37',
                        email: 'matilda.bjorksaga@nassjo.se',
                    },
                    {
                        action: 'Send via SendGrid',
                        re_send: 'No',
                        event: 'delivered',
                        subject: 'Vi har mottagit er tolkbokning. Bokningsnr: # 72426',
                        content: 'Hej! Tack för din tolkbokning! Bokningsdetaljer: Bokningsnummer: #72426 ...',
                        created_at: '2018-07-19 13:08:37',
                        email: 'matilda.bjorksaga@nassjo.se',
                    },
                    {
                        action: 'Send via SendGrid',
                        re_send: 'No',
                        event: 'delivered',
                        subject: 'Vi har mottagit er tolkbokning. Bokningsnr: # 72426',
                        content: 'Hej! Tack för din tolkbokning! Bokningsdetaljer: Bokningsnummer: #72426 ...',
                        created_at: '2018-07-19 13:08:37',
                        email: 'matilda.bjorksaga@nassjo.se',
                    },
                    {
                        action: 'Send via SendGrid',
                        re_send: 'No',
                        event: 'delivered',
                        subject: 'Vi har mottagit er tolkbokning. Bokningsnr: # 72426',
                        content: 'Hej! Tack för din tolkbokning! Bokningsdetaljer: Bokningsnummer: #72426 ...',
                        created_at: '2018-07-19 13:08:37',
                        email: 'matilda.bjorksaga@nassjo.se',
                    },
                ],

                detailsVisible: false,
                detailsInfo: {},


            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {}, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            /**
             * Helper method to check wether the column is enabled in the options or not.
             *
             * @param {string} key
             *   The switch value to check for whether to enable/disable a column.
             *
             * @return {boolean}
             */
            isColEnabled(key) {
                let r = true;

                if (_.includes(this.hiddenCols, key)) {
                    r = false;
                }

                return r;

            }, // End of isColEnabled() method

            // Map Helpers
            genFieldId: genFieldId,
            genContainerId: genContainerId,
            genColId: genColId,

            handleActivity(mode, rowIndex, rowItem){
                    this.itemInfo = rowItem;

                    switch (mode) {
                        case 'details':
                            this.detailsVisible = true;
                            this.detailsInfo = rowItem;
                            break;

                        case 'edit':
                            this.composeMode = 'edit';
                            this.composeTitle = this.$t('edit');
                            this.iComposeForm = _.cloneDeep(this.composeFresh); // Reset form, and then map defaults
                            this.iComposeForm = mapProps(rowItem.attributes, this.iComposeForm, { byRef:false });
                            this.composeVisible = true;
                            break;

                        case 'delete':
                            if ( this.methodDelete !== false ) {
                                this.methodDelete(rowItem);
                            } else {
                                this.handleDelete(rowItem);
                            }
                            break;

                        default:
                            throw new Error('No mode specified in the activity');
                    }
                }
        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
        }, // End of Component > mounted

    } // End of export default
</script>
