<template>
  <div class="app-page bookings-page">
    <page-header :title="$t('faqs')" />
    <div class="app-container">
        <div class="app-wrapper">
            <div class="app-row">
              <div class="faq-side-button">
                <el-button type="default" id="show-modal" @click="dialogFormVisible = true" icon="fa fa-plus">{{$t('add')}}</el-button>
              </div>
              <div class="app-col full-width">
                <div class="content-body">
                  <faq-list :v-populate-payload="payloadParams"/>
                </div> <!-- /.content-body -->
              </div> <!-- /.app-col -->
            </div> <!-- /.app-row -->
        </div> <!-- /.app-wrapper -->
        <create-faq-modal :visible.sync="dialogFormVisible"></create-faq-modal>
    </div> <!-- /.app-container -->
  </div> <!-- /.app-page bookings-page -->
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapActions} from "vuex";

export default {
  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data(){
      return {
          payloadParams: {
            'sort': '-created_at'
          },
          dialogFormVisible: false,
      }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
  },
  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods:{
  }
}
</script>
