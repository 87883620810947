/*
|--------------------------------------------------------------------------
| Financial > shared > props > otherSettings
|--------------------------------------------------------------------------
*/

'use strict';

import cloneDeep from 'lodash/cloneDeep';

export const allowanceFeesItem = {
  fee: 0,
  minimum_minutes: 0,
};

export default {

  allowance_setting: {
    pay_allowance: false,
    minimum_km: 0,
  },

  allowance_fees: [
    cloneDeep(allowanceFeesItem),
  ],

  expense_setting: {
    pay_expenses: false,
    max_expenses: 0,
  },

  translator_level_order: [],

  transparency_enabled: false,
  transparency_fields: [],
}
