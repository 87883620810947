<template>
  <div class="app-row">
    <div class="app-col full-width">
      <h3>{{ $t(iTitle) }}</h3>

      <div class="app-list booking-list">
        <table v-if="listData.length > 0"
             :class="genBem(blockCls, 'table')">
        <thead>
          <tr>
            <th class="col-header col-adjustment">
              <span class="cell">{{ $t('description')}}</span>
            </th>
            <th class="col-header col-description">
              <span class="cell">{{ $t('booking') }}</span>
            </th>
            <th class="col-header col-amount">
              <span class="cell">{{ $t('amount') }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listData">
            <td class="col-value">
              <span class="cell">
                {{ item.description}}
              </span>
            </td>
            <td class="col-value">
              <span class="cell" @click="handleBooking(item)">
                <a>{{ item.booking_id }}</a>
              </span>
            </td>
            <td class="col-value">
              <span class="cell">
                {{ item.subtotal }}
              </span>
            </td>
          </tr>
        </tbody>

      </table>

      <div v-else
           style="width: 100%; text-align: center">
          <h4>{{ $t('no_data') }}</h4>
        </div>

        <!-- Pagination -->
        <el-pagination
          layout="prev, pager, next"
          :page-size="listPagination.per_page"
          :total="listPagination.total"
          :current-page="listPagination.current_page"
          @current-change="reloadLists">
        </el-pagination>
      </div> <!-- /.app-list booking-list -->
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {isEmpty,sd} from "~/js/helpers/Common";
export default {
  name:'TranslatorSalaryCreditsList',
  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props:{
    title:{
      type:String,
      default: 'tfv_fn_salary_crediting'
    },
  },
  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data(){
    return {
      blockCls: 'financial-preview-display',
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed:{

    iTitle(){
      return this.title
    },
    ...mapGetters('financialCreditInvoice', {
      listPagination: 'pagination',
      listData: 'translatorSalaryCredits'
    }),
  },
  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods:{
    ...mapActions('financialCreditInvoice',{
      loadList: 'getTranslatorSalaryCredits'
    }),
    
    reloadLists (n) {
      const params = {page: n, translator_id: this.$route.params.id};
      this.loadList(params);
    },
    handleBooking(item){
      let route = this.$router.resolve({
        name: "booking-details",
        params: {id: item.booking_id}
      });
      window.open(route.href, "_blank");
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {
    this.loadList({translator_id: this.$route.params.id,page:1});
  } // End of Component > mounted
}
</script>
