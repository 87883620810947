import { render, staticRenderFns } from "./AdminPermissionsList.vue?vue&type=template&id=7015e6ad&"
import script from "./AdminPermissionsList.vue?vue&type=script&lang=js&"
export * from "./AdminPermissionsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports