/*
|--------------------------------------------------------------------------
| Store > SMS Centre
|--------------------------------------------------------------------------
|
| This file contains the getters property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/


export default {
  /**
   *
   * @param state
   * @returns {[]}
   */
  getConversationList: (state) => state.conversationList,
}
