<template>
  <div class="app-list booking-history-audit-list">
    <user-in-booking-feature :bookingId="bookingId" :userId="userId"></user-in-booking-feature>
    <el-row style="padding: 20px 0px">
      <el-col :span="12">
        <el-switch v-model="showSystemAudits" />
        <span>{{$t('show_system_logs')}}</span>
      </el-col>
      <el-col :span="12" style="text-align: right">
        <el-input :placeholder="$t('search_records')" v-model="search" @keyup.enter.native="handleSearch" style="max-width: 350px;">
          <el-button type="primary" slot="append" icon="el-icon-fa fa-search" @click="handleSearch" style="min-width: 50px;"></el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-table :data="audits" border v-loading="loading">
        <el-table-column :label="$t('date_time')" prop="created_at" :formatter="dateFormat"></el-table-column>
        <el-table-column :label="$t('action')" prop="event" :formatter="messageFormat"></el-table-column>
        <el-table-column :label="$t('value_before')" width="280px">
            <template slot-scope="scope">
                <p v-for="(val, key) in scope.row.old_values" :key="key">
                    {{key}} : {{val}}
                </p>
            </template>
        </el-table-column>
        <el-table-column :label="$t('value_after')" width="280px">
            <template slot-scope="scope">
                <p v-for="(val, key) in scope.row.new_values" :key="key">
                    {{key}} : {{val}}
                </p>
            </template>
        </el-table-column>
        <el-table-column :label="$t('component_name')" prop="auditable_type" :formatter="componentName"></el-table-column>
        <el-table-column :label="$t('how')" prop="user_id">
            <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="tooltipContent(scope)" placement="top">
                    <p>{{ acceptedBy(scope) }}</p>
                </el-tooltip>
            </template>
        </el-table-column>
    </el-table>
  </div> <!-- /.app-list booking-history-list -->
</template>

<script>
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex';
  import {
    populate
  } from '../../../js/helpers/Vuex';
  import {
    isEmpty
  } from '../../../js/helpers/Common';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Components > props
    |--------------------------------------------------------------------------
    */
    props: {}, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Components > data
    |--------------------------------------------------------------------------
    */
    data() {
        return {
            search: '',
            tableElId: 'booking-histories-audit-list',
            showSystemAudits: false,
            compiledUsers: [],
            // list:[]
        }
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Components > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters({
        list: 'booking/getHistories',
        listAudit: 'booking/getHistoriesAudit',
        listAuditNoSystem: 'booking/getHistoriesNoSystemAudits',
        listAuditBackup: 'booking/getHistoriesAuditBackup',
        loading: 'booking/getLoading',
        users: 'booking/usersAll',
        statusList: 'booking/getStatusList',
      }),

      audits() {
        return (this.showSystemAudits) ? this.listAudit : this.listAuditNoSystem;
      },
      bookingId () {
        return !window._.isNil(this.$route.params['id'])
          ? parseInt(this.$route.params['id'])
          : 0;
      },
      userId() {
        return JSON.parse(localStorage.getItem("app_user_data")).id
      },


    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Components > watch
    |--------------------------------------------------------------------------
    */
    watch: {}, // End of Component > watch

    /*
    |--------------------------------------------------------------------------
    | Components > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions({
        fetchHistoriesList: 'booking/fetchHistoriesList',
        getBooking: 'booking/getBooking',
        fetchHistoriesListAudit: 'booking/fetchHistoriesListAudit',
        getUsers: 'booking/getAllUsers',
        readUser: 'user/read',
      }),

      ...mapMutations({
        setHistoriesAudit: 'booking/setHistoriesAudit'
      }),
      /**
       * Helper method to check wether the column is enabled in the options or not.
       *
       * @param {string} key
       *   The switch value to check for whether to enable/disable a column.
       *
       * @return {boolean}
       */
      isColEnabled(key) {
        let r = true;

        if (_.includes(this.hiddenCols, key)) {
          r = false;
        }

        return r;

      }, // End of isColEnabled() method

      //column formater
      dateFormat(row) {
        let date = moment(row.created_at).format('YYYY-MM-DD');
        let time = moment(row.created_at).format('hh:mm A');
        return date + ' at ' + time;
      },
      timeFormat(row) {
        return moment(moment.utc(row.created_at).toDate()).format('HH:mm:ss')
      },
      submitedBy(scope) {
        let user_agent = (_.has(scope.row.payload, 'submited_by')) ? scope.row.payload.submited_by : scope.row.user_agent;
        if (!user_agent) {
          return 'System'
        }
        return (user_agent.length > 0 && user_agent.toLowerCase() === 'symfony') ? 'System' : user_agent;
      },
      translatorBy(row) {
        if (row.description == 'Travel-time-updated' || row.description == 'feedback-added') {
          return ''
        }
        return (_.has(row.payload.booking.assigned_translator, 'name')) ? row.payload.booking.assigned_translator.name : '';
      },
      acceptedBy(scope) {
        let user = scope.row.users;
        return isEmpty(user) ? 'System Changes' : user.name;
      },
      messageFormat(row) {
        let event = (_.has(row.payload, 'message')) ? row.payload.message : row.event;
        let source = (row.tags != null) ? row.tags : 'system/cron';

        return source + ' - ' + event;
      },
      valueBefore(row) {
        let format = '';
        if (_.has(row, 'old_values')) {
          let newObj = _.transform(row.old_values, (result, value, key) => {
            if (key === 'status_id') {
              value = this.statusList.find(x => x.id == value).name
            }
            result[key] = value
          });
          format = Object.entries(newObj).map(x => {
            if (x[0] && x[0] != 'updated_at') {
              return x.join(":")
            }
          }).join(", ")
        }
        return format
      },
      valueAfter(row) {
        let format = '';
        if (_.has(row, 'new_values')) {
          let newObj = _.transform(row.old_values, (result, value, key) => {
            if (key === 'status_id') {
              value = this.statusList.find(x => x.id == value).name
            }
            result[key] = value
          });
          format = Object.entries(newObj).map(x => {
            if (x[0] && x[0] != 'updated_at') {
              return x.join(":")
            }
          }).join(", ")
        }
        return format
        // return (_.has(row.payload,'new')) ? JSON.stringify(row.payload.new) : row.payload.booking.status['name'];
      },
      componentName(row) {
        let parts = row.auditable_type.split('\\');
        let str = (parts.length > 0) ? parts[parts.length - 1] : '';
        return str.replace(/([A-Z])/g, ' $1');
      },
      tooltipContent(scope) {
        let content = '';
        content += 'IP Address: ' + scope.row.ip_address + " | ";
        content += 'User Agent: ' + this.submitedBy(scope);

        return content;
      },
      handleSearch() {
        let hits = [];
        let list = this.audits;
        if (this.search.length > 0) {
          for (let n in list) {
            for (let [key, val] of Object.entries(list[n])) {
              if (key === 'old_values' || key === 'new_values') {
                for (let [key2, val2] of Object.entries(list[n][key])) {
                  if (String(val2).toLowerCase().includes(this.search.trim().toLowerCase())) {
                    hits[n] = list[n];
                  }
                }
              } else if (key === 'user_id') {
                for (let u in this.users) {
                  if (list[n][key] == this.users[u].id) {
                    let name = this.users[u].name;
                    if (String(name).toLowerCase().includes(this.search.trim().toLowerCase())) {
                      hits[n] = list[n];
                    }
                  }
                }
              } else {
                if (String(val).toLowerCase().includes(this.search.trim().toLowerCase())) {
                  hits[n] = list[n];
                }
              }
            }
          }
          this.setHistoriesAudit({
            data: {
              items: hits
            }
          });
        } else {
          this.setHistoriesAudit({
            data: {
              items: this.listAuditBackup
            }
          });
        }
      }
    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Components > mounted
    |--------------------------------------------------------------------------
    */
    mounted() {
      this.getBooking(this.$route.params.id).then((response) => {
        this.fetchHistoriesListAudit({
          id: this.$route.params.id,
          from_date: moment(response.booking.created_at).format('YYYY-MM-DD'),
          to_date: moment().format('YYYY-MM-DD'),
        });
      })

    }, // End of Component > mounted

  }
</script>
