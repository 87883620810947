<template>
  <div class="app-form price-transaction-fee-form">

    <el-form :id="invoiceFormElId" :ref="invoiceFormElId">

      <h3 class="app-title title-shaded">
        <span>{{$t('invoice')}}</span>
      </h3>

      <financial-transaction-fee-fields
          v-model="invoiceForm.transaction_fee"
          :form-el-id="invoiceFormElId"
      />
    </el-form>

  </div> <!-- /.app-form price-transaction-fee-form -->
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data() {
      return {
        invoiceFormElId: 'invoice_transaction_fee_form',
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('financialInvoice', {
        invoiceForm: 'settings',
      }),
    },

  } // End of export default
</script>
