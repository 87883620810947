<template>
    <div class="app-page price-page">

        <page-header :title="$t('price_templates')" :back-enabled="true" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">

                    <div class="app-col full-width">
                        <div class="content-body">

                            <el-form :id="priceTemplateFormElId" :ref="priceTemplateFormElId">

                                <h3>{{$t('primary_infos')}}</h3>

                                <div class="app-splitter two-cols">
                                    <!-- Name -->
                                    <el-form-item :id="genContainerId('name', priceTemplateFormElId)"
                                                  :label="$t('name')" class="label-short">

                                        <el-input :id="genFieldId('name', priceTemplateFormElId)"
                                                  v-model="priceTemplateForm.name" />
                                    </el-form-item>

                                    <!-- Template for -->
                                    <el-form-item :id="genContainerId('template_for', priceTemplateFormElId)"
                                                  :label="$t('template_for')" class="label-short">

                                        <el-input :id="genFieldId('template_for', priceTemplateFormElId)"
                                                  v-model="priceTemplateForm.template_for" />
                                    </el-form-item>
                                </div>

                                <!-- Name -->
                                <el-form-item :id="genContainerId('save', priceTemplateFormElId)">

                                    <el-button type="primary"
                                               :id="genFieldId('save', priceTemplateFormElId)"
                                               @click="handleClickSave">
                                        {{$t('save')}}
                                    </el-button>

                                    <el-button type="primary"
                                               v-if="breadModeIsEdit()"
                                               :id="genFieldId('audits', priceTemplateFormElId)"
                                               @click="handleRedirectAudits">
                                        {{$t('audit_logs')}}
                                    </el-button>
                                </el-form-item>

                            </el-form>

                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                    <div class="app-col full-width">
                        <div class="content-body">

                            <el-tabs v-model="activeTab">

                                <el-tab-pane :label="$t('business_rules')" name="business_rules">
                                    <price-business-rules-form />
                                </el-tab-pane>

                                <el-tab-pane :label="$t('charging')" name="charging">
                                    <price-charging-form />
                                </el-tab-pane>

                                <el-tab-pane :label="$t('transaction_fee')" name="transaction_fee">
                                    <price-transaction-fee-form />
                                </el-tab-pane>

                                <el-tab-pane :label="$t('emergency_booking')" name="emergency_booking">
                                    <price-emergency-booking-form />
                                </el-tab-pane>

                                <el-tab-pane :label="$t('inconvenience_charge')" name="inconvenience_charge">
                                    <price-inconvenience-charge-form />
                                </el-tab-pane>

                                <el-tab-pane :label="$t('travel_settings')" name="travel_settings">
                                    <price-travel-settings-form />
                                </el-tab-pane>

                                <el-tab-pane :label="$t('late_cancellations')" name="late_cancellations">
                                    <price-late-cancellations-form />
                                </el-tab-pane>

                                <el-tab-pane :label="$t('language_settings')" name="language_settings">
                                    <price-language-settings-form />
                                </el-tab-pane>

                                <el-tab-pane :label="$t('text_translation')" name="text_translation">
                                    <price-text-translation-form />
                                </el-tab-pane>

                                <el-tab-pane :label="$t('other_settings')" name="other_settings">
                                    <price-other-settings-form />
                                </el-tab-pane>
                            </el-tabs>

                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->
    </div> <!-- /.app-page price-page -->
</template>

<script>
  import {mapState, mapMutations, mapActions} from 'vuex';
  import {populate} from "~/js/helpers/Vuex";
  import {checkBreadMode} from '../../../js/helpers/Route';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        activeTab: 'business_rules',
        priceTemplateFormElId: 'price_template_form',
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapState('financialPriceTemplate', {
        priceTemplateForm: 'form',
      }),

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapMutations({
        cloneInvoiceCharges: 'financialInvoice/cloneCharges',
        cloneSalaryCharges: 'financialSalary/cloneCharges',

        resetInvoiceSettings: 'financialInvoice/resetSettings',
        resetSalarySettings: 'financialSalary/resetSettings',
        resetPriceTemplateForm: 'financialPriceTemplate/resetForm',
      }),

      ...mapActions({
        loadTranslatorLevels: 'translatorLevel/browseAsOptions',
        readItem: 'financialPriceTemplate/read',
        createItem: 'financialPriceTemplate/add',
        updateItem: 'financialPriceTemplate/edit',
      }),

      breadModeIsEdit () {
        return checkBreadMode(this) === 'edit';
      },

      /**
       * Handler when the save button was clicked.
       * @return {void}
       */
      handleClickSave () {

        if (checkBreadMode(this) === 'add') {
          this.createItem().then(() => {
            this.$router.push({name: 'financial-price-template-list'});
          });

        } else if (checkBreadMode(this) === 'edit') {
          this.updateItem({id: this.$route.params.id});
        }
      },

      handleRedirectAudits () {
        this.$router.push({name: 'audits.financials.template', params: {template_id: this.$route.params.id}});
      },

      /**
       * Handler for cloning Charges and Parts Fields
       * @param {object} opts - contains values usable inside the method.
       * @return {void}
       */
      cloneCharges (opts) {

        if (opts.target === 'invoice') {
          this.cloneInvoiceCharges(opts);
        } else if (opts.target === 'salary') {
          this.cloneSalaryCharges(opts);
        }
      },

      /**
       * Handler for cloning Inconvenience Charges
       * @param {object} opts - contains values usable inside the method.
       * @return {void}
       */
      cloneInconvenienceCharges (opts) {

        if (opts.target === 'invoice') {
          this.cloneInvoiceInconvenienceCharges(opts);
        } else if (opts.target === 'salary') {
          this.cloneSalaryInconvenienceCharges(opts);
        }
      },

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {

      populate(this, 'loadTranslatorLevels', 'formattedTranslatorLevelOpts', {
        ref: 'Translator Level Listing',
      });

      this.resetInvoiceSettings();
      this.resetSalarySettings();
      this.resetPriceTemplateForm();

      if (checkBreadMode(this) === 'edit') {
        this.readItem({id: this.$route.params.id});
      }

    }, // End of Component > mounted

  } // End of export default

</script>
