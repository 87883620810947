'use strict';

import APICaller from "../../../helpers/APICaller";
import {gep} from "../../../helpers/Vuex";

let apiPrefix = 'v3';

export default {
  getMunicipalityBatchSetting(context, payload) {
    return APICaller({
      method: 'get',
      endpoint: gep(`municipalities/${payload}/batches-settings`, apiPrefix)
    })
      .then((response) => {
          context.commit('setComposeForm', response.data.data)
          return response.data;
        }
      ).catch((e) => {

        console.log(e)
        return e
      })
  },

  updateMunicipalityBatchSetting(context, payload) {
    return APICaller({
      method: 'post',
      endpoint: gep(`municipalities/${payload.id}/batches-settings`, apiPrefix),
      data: payload.settings,
      isDataRaw: true
    })
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e)
        return e;
      })
  },
  getCompanyBatchSetting(context, payload) {
    return APICaller({
      method: 'get',
      endpoint: gep(`companies/${payload}/batches-settings`, apiPrefix)
    })
      .then((response) => {
          context.commit('setComposeForm', response.data.data)
          return response.data;
        }
      ).catch((e) => {

        console.log(e)
        return e
      })
  },

  updateCompanyBatchSetting(context, payload) {
    return APICaller({
      method: 'post',
      endpoint: gep(`companies/${payload.id}/batches-settings`, apiPrefix),
      data: payload.settings,
      isDataRaw: true
    })
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e)
        return e;
      })
  }
}
