import { gep } from "../../../../helpers/Vuex";

let apiPrefix = window.app_api_prefix_3;

let composeProps = {
  'type':'',
  'deadline':''
}
export default {
  api:{
    browse: {method:'get',path:gep('booking-issue-settings',apiPrefix),params: {per_page: 25}},
    read:{method:'get',path:gep('booking-issue-settings/{id}',apiPrefix),params:{},data:{},},
    add:{method:'post',path:gep('booking-issue-settings',apiPrefix),params:{},data:{},},
    edit:{method:'put',path:gep('booking-issue-settings/{id}',apiPrefix),params:{},data:{},},
    delete:{method:'delete',path:gep('booking-issue-settings/{id}',apiPrefix),params:{},data:{},},
  },
  autoInjectPayload: true,
  autoReflectItem: true,
  autoUpdateList: true,

  composeFresh: _.cloneDeep(composeProps),
  compose: _.cloneDeep(composeProps),

  payloadData: {}, // End of payloadData state

}