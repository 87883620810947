<template>
  <div>
    <h4>Sms Conversation Channels</h4>
    <div v-for="channels in booking.sms_channels">
      <template v-if="channels.is_read === false">
        <el-alert

          :title="'Channel: '+channels.name"
          type="warning"
          :description="getLastMessage(channels.sms_centre_conversation)"
          :closable="false"
          show-icon
          @click.native="redirectToHelpDesk(channels, booking.sms_channel_bookings)"
          style="cursor: pointer"
        >
        </el-alert>
      </template>
      <br>
    </div>
    <el-collapse accordion>
      <el-collapse-item>
        <template slot="title">
          <p>Other Sms Conversation Channels</p>
        </template>
        <template v-for="channels in booking.sms_channels">
          <template v-if="channels.is_read === true">
            <el-alert

              :title="'Channel: '+channels.name"
              type="success"
              :description="'Initiated at: '+ channels.created_at"
              :closable="false"
              @click.native="redirectToHelpDesk(channels, booking.sms_channel_bookings)"
              style="cursor: pointer"
            >

            </el-alert>
            <br>
          </template>

        </template>
      </el-collapse-item>
    </el-collapse>
  </div>

</template>

<script>
  export default {
    name: "BookingRelatedSms",
    props: {
      booking: {
        type: Object,
        default: {}
      }
    },
    methods: {
      getLastMessage(message) {
        return _.last(message).message
      },
      redirectToHelpDesk(redirectTo, channel) {
        this.$router.push({name: 'sms.centre', params: {smsConversation: redirectTo}});
      }
    }
  }
</script>

<style scoped>

</style>
