<template>
    <div v-if="show_record" class="app-form app-fields user-translator-training-record-fields">

        <h3 class="text-center">{{ item.name }}</h3>

        <div class="app-splitter two-cols">

                <el-form-item :id="genContainerId('ord_' + ord + '_language',formElId)"
                            :label="$t('language')" class="label-block">
                    <el-input :id="genFieldId('ord_' + ord + '_language' ,formElId)"
                            v-model="form.language" />
                </el-form-item>

                <el-form-item :id="genContainerId('ord_' + ord + '_grade',formElId)"
                              :label="$t('grade')" class="label-block">
                    <el-input :id="genFieldId('ord_' + ord + '_grade' ,formElId)"
                            v-model="form.grade" />
                </el-form-item>

                <el-form-item :id="genContainerId('ord_' + ord + '_course_coordinator',formElId)"
                              :label="$t('tfv_course_coordinator')" class="label-block">
                    <el-input :id="genFieldId('ord_' + ord + '_course_coordinator' ,formElId)"
                            v-model="form.course_coordinator" />
                </el-form-item>

                <el-form-item :id="genContainerId('ord_' + ord + '_trainer',formElId)"
                              :label="$t('trainer')" class="label-block">
                    <el-input :id="genFieldId('ord_' + ord + '_trainer' ,formElId)"
                            v-model="form.trainer" />
                </el-form-item>

                <el-form-item :id="genContainerId('ord_' + ord + '_hours_of_training',formElId)"
                              :label="$t('hours_of_raining')" class="label-block">
                    <el-input :id="genFieldId('ord_' + ord + '_hours_of_training' ,formElId)"
                            v-model="form.hours_of_training" />
                </el-form-item>

                <el-form-item :id="genContainerId('ord_' + ord + '_university_credits',formElId)"
                              :label="$t('university_credits')" class="label-block">
                    <el-input :id="genFieldId('ord_' + ord + '_university_credits' ,formElId)"
                            v-model="form.university_credits" />
                </el-form-item>

                <el-form-item :label="$t('tfv_excution_start')" :id="genContainerId('ord_' + ord + '_execution_from',formElId)">
                    <el-date-picker v-model="form.execution_from" type="date" :placeholder="$t('tfv_txt_select_date')" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      popper-class="day-picker-only" :id="genFieldId('ord_' + ord + '_execution_from',formElId)">
                    </el-date-picker>
                </el-form-item>

                <el-form-item :label="$t('tfv_excution_end')" :id="genContainerId('ord_' + ord + '_execution_to',formElId)">
                    <el-date-picker v-model="form.execution_to" type="date" :placeholder="$t('tfv_txt_select_date')" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      popper-class="day-picker-only" :id="genFieldId('ord_' + ord + '_execution_to',formElId)">
                    </el-date-picker>
                </el-form-item>

        </div> <!-- /.app-splitter two-cols -->

    </div> <!-- /.app-form app-fields user-translator-settings-fields -->
</template>

<script>
  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      // Prop v-model of the component.
      value: {
        type: Object,
        required: true
      },

      // String reference for form element ID.
      formElId: {
        type: String,
        default: 'user_translator_training_records'
      },

      title: {
        type: String,
        required: true
      },

      tid: {
        type: Number,
        required: true
      },

      ord: {
        type: Number,
        required: true
      },

      item: {
        type: Object,
        required: true
      },

      training_ids: {
        type: Array,
        required: true
      },

      training_records: {
        type: Array,
        required: true
      }

    },

    data() {
      return {
        form: {
          id: 0,
          training_id: 0,
          user_translator_id: 0,
          language: '',
          grade: '',
          course_coordinator: '',
          trainer: '',
          hours_of_training: 0,
          university_credits: '',
          execution_from: '',
          execution_to: '',
          to_delete: false
        },
        items: [],
        show_record: true
      };
    },

    watch: {
      "form.language": function() {
        this.setItem();
      },
      "form.grade": function() {
        this.setItem();
      },
      "form.course_coordinator": function() {
        this.setItem();
      },
      "form.trainer": function() {
        this.setItem();
      },
      "form.hours_of_training": function() {
        this.setItem();
      },
      "form.university_credits": function() {
        this.setItem();
      },
      "form.execution_from": function() {
        this.setItem();
      },
      "form.execution_to": function() {
        this.setItem();
      },
      "training_ids": function() {
        this.show_record = this.toShow();
        if (typeof this.iValue.items[this.ord] != 'undefined') {
          if (!this.show_record) {
            this.iValue.items[this.ord].to_delete = true;
          } else {
            this.iValue.items[this.ord].to_delete = false;
          }
        }
      },
      "training_records": function() {
        this.setForm(this.findRecord(this.tid));
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        }
      }
    },

    methods: {

      setItem() {
        this.form.training_id = this.tid;
        this.form.user_translator_id = this.$route.params.id;
        this.iValue.items[this.ord] = this.form;
      },
      toShow() {
        if (typeof this.training_ids.find(t => {
            return t === this.tid
          }) == 'undefined') {
            return false;
        }
        return true;
      },
      setForm(record) {
        try {
          this.form.id                            = record.id;
          this.form.training_id                   = record.training.id;
          this.form.user_translator_id            = this.$route.params.id;
          this.form.language                      = record.language;
          this.form.grade                         = record.grade;
          this.form.course_coordinator            = record.course_coordinator;
          this.form.trainer                       = record.trainer;
          this.form.hours_of_training             = record.hours_of_training;
          this.form.university_credits            = record.university_credits;
          this.form.execution_from                = record.execution_from;
          this.form.execution_to                  = record.execution_to;
          this.form.to_delete                     = false;
        } catch (e) {
          // no action
        }
      },
      findRecord(tid) {
        return this.training_records.find(tr => {
          return tr.training.id == tid;
        });
      }
    },

    mounted() {
      this.show_record = this.toShow();
      if (this.show_record) {
        this.setForm(this.findRecord(this.tid));
        this.setItem();
      }
    }

  }
</script>
