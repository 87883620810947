import APICaller from "../../../../helpers/APICaller";
import {gep} from "~/js/helpers/Vuex";
import {Notification} from "element-ui";
import i18n from '~/js/i18n';
// import {extendObjParams, isEmpty, sd} from "~/js/helpers/Common";

let apiPrefix = 'v3';

export default{
  
  getByBookingIssueId(context, payload) {
    return APICaller({
        method:'get',
        endpoint: gep(`tags-by-issue/${payload.booking_issue_id}` ,apiPrefix),
      }).then(r =>{
        return r
      }).catch(e=>{
        Notification.error({
          title: i18n.t('error'),
          message: e.message
        });

        console.log(e);
      });
  },
  
  getBookingIssueByTagId(context, payload) {
    return APICaller({
        method:'get',
        endpoint: gep(`issues-by-tag/${payload.booking_issue_id}` ,apiPrefix),
      }).then(r =>{
        return r
      }).catch(err=>{
        console.log(err);
      });
  },

  getBookingIssueTagsPerPage(context, n) {
      return APICaller({
          method: 'get',
          endpoint: gep(`booking-issue-tags?per_page=${n['per_page']}&page=${parseInt(n['n'])}`, apiPrefix)
      })
          .then((response) => {
              return response;
          }
          ).catch((e) => {

              console.log(e)
              return e
          })
  },

  storeWithNewTag(context, payload) {
    console.log(payload);

    return APICaller({
        method:'post',
        endpoint: gep(`add-issue-with-new-tag` ,apiPrefix),
        data: {
          booking_issue_id  : payload.booking_issue_id,
          name              : payload.booking_issue_tag_name,
          state             : payload.state,
          created_by        : payload.created_by
        }
      }).then(r =>{
        Notification.success({
          title: i18n.t('success'),
          message: 'Booking issue is tagged with new issue tag.'
        });
        return r
      }).catch(err=>{
        console.log(err);
      });
  },
}