/*
|--------------------------------------------------------------------------
| Store > Financial > Credits > Mutations
|--------------------------------------------------------------------------
| File to define the mutations that is relevant for the current scope.
*/

'use strict';

export default {

  /**
   * Set the target booking
   *
   * @param  {object} state - the state property the current Vuex Object
   * @param  {object} payload - an object which contains option values
   * @return {void}
   */
  setTargetBooking (state, payload) { state.targetBooking = payload; },

  /**
   * Set the list properties
   *
   * @param  {object} state
   *   : the state property the current Vuex Object
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {void}
   */
  setList: function (state, payload) {

    // Get the first object key in the data
    let firstKey = Object.keys(payload.data)[0];

    state.list.data = payload.data[firstKey];
  },

  setTranslatorSalaryCredits(state, payload) {
    state.translatorSalaryCredits = payload.data.salary_credits;
    state.pagination = payload.meta.pagination;
  },

  setDepartmentInvoiceCredits(state, payload) {
      state.departmentInvoiceCredits = payload.data.invoice_credits;
      state.pagination = payload.meta.pagination;
  },

}
