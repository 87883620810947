<template>
  <vue-multiselect
    ref="select"
    :class="getBem(blockClass)"
    v-model="emails"
    :options="formattedOptions"
    :label="label"
    :track-by="trackBy"
    :placeholder="placeholder"
    :taggable="true"
    :searchable="true"
    :multiple="true"
    :hideSelected="true"
    @tag="addTag"
    selectLabel=""
    @keydown.tab.native.capture="handleKeyTab"
  >
    <span slot="noResult">Invalid. Consider changing the search query.</span>
  </vue-multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';
import forEach from 'lodash/forEach';
import remove from 'lodash/remove';
import find from 'lodash/find';
import isNil from 'lodash/isNil';

export default {
  name: "EmailPicker",

  components: {
    VueMultiselect: Multiselect
  },

  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props: {
    value: {
      type: Array,
      required: true
    },

    options: {
      type: Array,
      default () {
        return [];
      }
    },

    label: {
      type: String,
      default: 'name'
    },

    trackBy: {
      type: String,
      default: 'email'
    },

    placeholder: {
      type: String,
      default: 'Enter email'
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data () {
    return {
      blockClass: 'email-picker',
      formattedOptions: [],
      dropdownWrapperSelector: '.multiselect.email-picker .multiselect__content-wrapper',
      tagsSelector: '.multiselect.email-picker .multiselect__tag > span',
      emails: [],
      emailsWatcherEnabled: true,
      valueWatcherEnabled: true
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > watch
  |--------------------------------------------------------------------------
  */
  watch: {
    options () {
      this.setFormattedOptions();
    },

    emails (v) {
      if (!this.emailsWatcherEnabled) return;

      this.resetTagListeners();

      if (!this.isArrayValid(v)) {
        this.$emit('input', v);
      } else {
        let r = [];

        forEach(v, (x) => {
          if (typeof x === 'string') {
            r.push(x);
          } else {
            r.push(x.email);
          }
        });

        this.valueWatcherEnabled = false;
        this.$emit('input', r);

        this.$nextTick(() => {
          this.valueWatcherEnabled = true;
        });
      }
    },

    value () {
      if (!this.valueWatcherEnabled) return;
      this.initializeValues();
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {

    /**
     * @returns {void}
     */
    setFormattedOptions () {
      if (!this.isArrayValid(this.options)) return;

      this.formattedOptions = [];

      forEach(this.options, (email) => {
        this.formattedOptions.push(this.createTagObject(email));
      });
    },

    /**
     * @param {string} email
     * @returns {{name: string, email: string}}
     */
    createTagObject (email) {
      return {name: email, email};
    },

    /**
     * @param {string} email
     * @returns {void}
     */
    removeFromValue (email) {
      let newValue = [...this.emails];
      remove(newValue, (v) => v.email === email);
      this.emails = newValue;
    },

    /**
     * @param {string} email
     * @returns {void}
     */
    addToOptions (email) {
      this.formattedOptions.unshift(this.createTagObject(email));
    },

    /**
     * @param {string} tag
     * @returns {void}
     */
    addTag (tag) {
      tag = tag.trim();

      // Split the instance of emails inside the email
      // value if they are just joined by ',' or ';' or ' '.
      let separators = [' ', '\\\,', '\\\;'];
      let split = tag.split(new RegExp(separators.join('|'), 'g'));
      remove(split, (x) => x === '');
      let validEmails = [];

      forEach(split, (email) => {
        if (this.isValidEmail(email)) {

          if (!this.emailsHas(email)) {
            this.addToOptions(email);
            validEmails.push(this.createTagObject(email));
          }

        } else {
          this.$notify({
            type: 'error',
            message: 'Invalid email entry.'
          })
        }
      });

      // Do the assigning this way to avoid the "too fast assignment issue"
      if (validEmails.length > 0) {
        this.emails = [...this.emails, ...validEmails];
      }
    },

    /**
     * @param email
     * @returns {boolean}
     */
    isValidEmail (email) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return re.test(email);
    },

    /**
     * Callback for tag name click event.
     * @param event
     * @returns {void}
     */
    handleClickTagName (event) {
      event.stopPropagation();
      const email = event.target.innerText;

      this.$refs['select'].search = email;
      this.removeFromValue(email);
    },

    /**
     * @returns {void}
     */
    attachTagListeners () {
      const tags = document.querySelectorAll(this.tagsSelector);

      if (this.isArrayValid(tags)) {
        forEach(tags, (tag) => {
          tag.addEventListener('click', this.handleClickTagName);
        });
      }
    },

    /**
     * @returns {void}
     */
    removeTagListeners () {
      const tags = document.querySelectorAll(this.tagsSelector);

      if (this.isArrayValid(tags)) {
        forEach(tags, (tag) => {
          tag.removeEventListener('click', this.handleClickTagName);
        });
      }
    },

    /**
     * @returns {void}
     */
    resetTagListeners () {
      this.removeTagListeners();

      setTimeout(() => {
        this.attachTagListeners();
      }, 500);
    },

    applySimplebarToDropdown () {
      const dropdown = document.querySelector(this.dropdownWrapperSelector);
      new SimpleBar(dropdown);
    },

    /**
     * Handler when the tab key has beeen pressed while the input is being focused.
     *
     * @returns {void}
     */
    handleKeyTab ($event) {
      this.$refs['select'].addPointerElement($event);
    },

    /**
     * @param arr
     * @returns {boolean|boolean}
     */
    isArrayValid (arr) {
      return arr != null && arr.length > 0;
    },

    /**
     * @returns {void}
     */
    initializeValues () {
      if (!this.isArrayValid(this.value)) return;

      this.emailsWatcherEnabled = false;
      let r = [];
      forEach(this.value, (email) => {
        r.push(this.createTagObject(email))
      });
      this.emails = r;
      this.resetTagListeners();

      this.$nextTick(() => {
        this.emailsWatcherEnabled = true;
      });
    },

    /**
     * @param {string} email
     * @returns {boolean}
     */
    emailsHas (email) {
      const found = find(this.emails, (o) => o.email === email);
      return !isNil(found);
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component >  mounted
  |--------------------------------------------------------------------------
  */
  mounted () {
    this.initializeValues();
    this.setFormattedOptions();
    this.applySimplebarToDropdown();
  },

  /*
  |--------------------------------------------------------------------------
  | Component >  beforeDestroy
  |--------------------------------------------------------------------------
  */
  beforeDestroy () {
    this.removeTagListeners();
  }
}
</script>

<!--suppress CssUnknownProperty, CssUnknownTarget, SassScssResolvedByNameOnly -->
<style lang="scss">
@import '~/scss/global/_variables.scss';

.multiselect.email-picker {
  position: relative;

  .multiselect__content-wrapper {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    position: absolute !important;

    &::-webkit-scrollbar { /* WebKit */
      width: 0;
      height: 0;
    }
  }

  .multiselect__tag {
    position: relative;
    background-color: $app-primary-color;
    border-color: $app-primary-color;
    color: #fff;

    &-icon {
      background-color: $app-primary-color;

      &:after {
        color: #fff;
      }
    }
  }

  .multiselect__option--highlight {
    background: transparentize($app-primary-color, .85);
    color: #fff;
  }
}
</style>
