<template>
  <el-dialog
    :title="$t('import_batch_template')"
    :visible="dialogVisible"
    width="50%"
    :modal-append-to-body="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="dialogVisible = false">
    <el-table :data="gridData" highlight-current-row @current-change="handleCurrentChange">
      <el-table-column property="id" :label="$t('id')" width="150"></el-table-column>
      <el-table-column property="name" :label="$t('name')" width="200"></el-table-column>
      <el-table-column property="created_at" :label="$t('created_at')"></el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
    <el-button size="mini" type="danger" @click="dialogVisible = false">{{$t('cancel')}}</el-button>
    <el-button size="mini" type="success" @click="importBatch">{{$t('import')}}</el-button>
  </span>
</el-dialog>
</template>

<script>
    import { mapActions } from "vuex";
    export default {
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        props: ['dialogVisible','importBatchTemplate'],
        data() {
            return {
                selectedRow: '',
                gridData: [],
            }
        },
        mounted() {
            this.getbatches().then((r) => {
              this.gridData = r.data
              console.log(this.gridData)
            })

        },
        methods: {
          ...mapActions('bookingBatches', {
            getbatches: 'getBatchTemplateList',
            }),

          handleCurrentChange(val) {
            this.currentRow = val;
            this.selectedRow = val.settings;
          },

          importBatch() {
            this.importBatchTemplate(this.selectedRow);
          },

          inputSuccess() {
            this.newVal(this.inputVal)
            console.log(this.inputVal)
          }
        },
    }
</script>

<style>
.el-dialog__footer {
  text-align: center !important;
}
</style>
