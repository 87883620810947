'use strict';

import APICaller from "../../helpers/APICaller";
import { gep } from "../../helpers/Vuex";
import {extendObjParams, isEmpty} from "~/js/helpers/Common";
import i18n from '../../i18n';
import {Notification} from "element-ui";

let apiPrefix = 'v3';

export default {
    getCustomerTypes() {
        return APICaller({
            method: 'get',
            endpoint: gep('customer-types/?per_page=10', apiPrefix)
        })
            .then((response) => {
                return response;
            }
            ).catch((e) => {

                console.log(e)
                return e
            })
    },
    getCustomerTypesPerPage(context, n) {
        return APICaller({
            method: 'get',
            endpoint: gep(`customer-types/?per_page=${n['per_page']}&page=${parseInt(n['n'])}`, apiPrefix)
        })
            .then((response) => {
                return response;
            }
            ).catch((e) => {

                console.log(e)
                return e
            })
    },
    createCustomerTypes(context, payload) {
        
        return APICaller({
            method: 'post',
            endpoint: gep('customer-types', apiPrefix),
            data: payload,
        })
            .then((response) => {
                return response;
            })
            .catch((e) => {
                console.log(e)
                return e;
            })
    },
    updateCustomerTypes(context, payload) {
        return APICaller({
            method: 'put',
            endpoint: gep(`customer-types/${payload.id}`, apiPrefix),
            data: payload,
        })
            .then((response) => {
                return response;
            })
            .catch((e) => {
                console.log(e)
                return e;
            })
    },
    deleteCustomerTypes(context, payload) {
        return APICaller({
            method: 'delete',
            endpoint: gep(`customer-types/${payload}`, apiPrefix),
        })
        .then((response) => {
            return response;
        })
        .catch((e) => {
            console.log(e)
            return e;
        })
    },

}