<template>
  <div>

    <div id="conversation-body" :class="blockCls">
      <div :class="getBem(blockCls,'top-control')" v-if="conversation.sms_centre_conversation">
        <el-row>
          <el-col :md="12" :offset="12">

            <el-select
              :value="bookingIds"
              multiple
              filterable
              @input="assignBooking"
              allow-create
              default-first-option
              placeholder="Enter related booking IDs">
              <el-option
                v-for="item in bookingIds"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-col>
        </el-row>

      </div>
      <div
        v-bind:class="[(messages.direction === 'incoming') ? getBem(blockCls,'incoming-message-block') :  getBem(blockCls,'outgoing-message')]"
        v-for="messages in conversation.sms_centre_conversation">

        <div
          v-bind:class="[(messages.direction === 'incoming') ? getBem(blockCls,'received-message') : getBem(blockCls,'sent-message')]">
          <h4 v-if="messages.direction === 'incoming'">{{conversation.to_recipient_number}}</h4>
          <h4 v-else>DigitalTolk</h4>
          <div
            v-bind:class="[(messages.direction === 'incoming') ? getBem(blockCls,'received-message-data') : getBem(blockCls,'sent-message') ]">
            <p>{{messages.message}}</p>
            <span :class="getBem(blockCls,'time-date')"> {{formatDate(messages.updated_at)}}
              <b v-if="messages.sent_by">Sent By {{messages.sent_by}}</b></span></div>
        </div>
      </div>
    </div>
    <div :class="getBem(blockCls,'type-message')">
      <div :class="getBem(blockCls,'message-input')">
        <el-input
          type="textarea"
          placeholder="Type a message.."
          :rows="5"
          @keypress.enter.native="handleClickSend"
          v-model="message">
        </el-input>
        <button :class="getBem(blockCls,'message-send-btn')" @click="handleClickSend" type="button"><i
          class="fa fa-paper-plane-o"
          aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapMutations, mapState} from 'vuex';

  export default {
    data() {
      return {
        blockCls: 'SmsCentreConversationBody',
        message: '',
        booking_id: '',
      }
    },
    /**
     * Computed Properties
     */
    computed: {
      ...mapState('smsCentre', {
        conversation: 'conversation',
        bookingIds: 'bookingIds'
      }),

      bookingIds: {

        get() {
          return this.$store.state.smsCentre.bookingIds
        },
        set(val) {
        }
      }

    },
    /**
     * Props for the component
     */

    props: {
      messageBatch: {
        type: String,
        default: ''
      },
    },

    /**
     * Methods
     */
    methods: {

      /**
       * Map Actions of vuex
       */
      ...mapActions('smsCentre', {
        createConversation: 'createConversation',
        assignBookingToChannel: 'assignBooking'
      }),

      ...mapMutations('smsCentre', {
        setNewMessage: 'setNewMessage'
      }),

      /**
       * Send to API
       */
      handleClickSend() {
        let payload = {
          message: this.message,
          conversation_id: this.conversation.id,
          from: 'DigitalTolk',
          to: this.conversation.to_recipient_number,
          sent_by: JSON.parse(localStorage.getItem('app_user_data')).name
        };
        this.createConversation(payload);
        this.message = '';
      },

      /**
       *
       * Scroll to end while opening a conversation
       */

      scrollToEnd: function () {
        var container = this.$el.querySelector("#conversation-body");
        container.scrollTop = container.scrollHeight;
      },

      /**
       * Format Date
       * @param date
       * @returns {string}
       */

      formatDate(date) {
        return moment(date).calendar();
      },

      /**
       * Event for new conversation created
       * @param id
       */

      smsCentreConversationEvent(id) {
        if (window.WS) {
          window.WS.subscribe('sms.conversation.' + id)
            .listen('sms.conversation.created', this.smsCentreConversationListener);
        }

      },

      /**
       * Listener for the event
       * @param conversation
       */

      smsCentreConversationListener(conversation) {
        if (conversation.smsCentreConversation.direction === 'incoming') {
          this.setNewMessage({item: conversation.smsCentreConversation})
        }
      },

      /**
       * Assign Related Bookings
       * @param item
       */

      assignBooking(item) {
        this.assignBookingToChannel({id: this.conversation.id, booking_id: item})
      },
    },

    /**
     * Scroll to end in updated method
     */
    updated() {
      this.scrollToEnd();
    },

    /**
     * Mounted method for this component
     */

    mounted() {
      if (this.messageBatch) {
        this.message = this.messageBatch
      }
    },

    /**
     * Unsubscribe and subscribe to the events
     */

    watch: {
      conversation(newVal, OldVal) {
        if (OldVal && OldVal.id) {
          window.WS.unsubscribe('sms.conversation.' + OldVal.id);
          this.smsCentreConversationEvent(newVal.id);
        } else {
          this.smsCentreConversationEvent(newVal.id);
        }
      },
    }

  }
</script>

<style lang="scss">

  .SmsCentreConversationBody {
    overflow-y: auto;
    height: 700px;


    .el-input-group--append {
      .el-input-group__append {
        background-color: #3676BF !important;
      }
    }


    .incoming-message-block {
      display: inline-block;
      width: 6%;
    }

    &__outgoing-message {
      overflow: hidden;
      margin: 26px 0 26px;
    }


    &__received-message {
      display: inline-block;
      padding: 0 0 0 10px;
      vertical-align: top;
      width: 92%;
    }

    &__received-message h4 {
      margin-bottom: 0px;
    }

    &__received-message-data p {
      background: #ebebeb none repeat scroll 0 0;
      border-radius: 3px;
      color: #646464;
      font-size: 14px;
      margin: 0;
      padding: 5px 10px 5px 12px;
      width: 100%;
    }

    &__sent-message {
      float: right;
      width: 80%;
    }

    &__time-date {
      color: #747474;
      display: block;
      font-size: 12px;
      margin: 8px 0 0;
    }

    &__sent-message p {
      background: #05728f none repeat scroll 0 0;
      border-radius: 3px;
      font-size: 14px;
      margin: 0;
      color: #fff;
      padding: 5px 10px 5px 12px;
    }

    &__sent-message h4 {
      margin-bottom: 0px;
      margin-top: 0px;
      display: flex;
      flex-direction: row-reverse;
    }

    &__type-message {
      border-top: 5px #3975ba solid;

      position: relative;

    }

    &__message-send-btn {
      background: #05728f none repeat scroll 0 0;
      border: medium none;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      font-size: 17px;
      height: 33px;
      position: absolute;
      right: 0;
      top: 0;
      margin-right: 3px;
      width: 33px;
    }

    &__message-input input {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      color: #4c4c4c;
      font-size: 15px;
      min-height: 48px;
      width: 100%;
      border-radius: 25px;

      .el-textarea textarea {
        height: 50px !important;
      }

    }

    &__inner-input .el-form-item__label {
      margin-bottom: 40px;
    }

  }

</style>
