<template>
    <div class="app-page com-logs-page">
        <page-header :title="$t('communication_logs')"
            :linkData="{
                text : this.$route.params.id,
                path : 'booking/' + this.$route.params.id + '/details',
                preText : '(#',
                postText : ')'
            }"
            :back-enabled="true"
        />

        <div class="app-container">
            <div class="app-wrapper">
                <user-in-booking-feature :bookingId="booking_id" :userId="userId"></user-in-booking-feature>

              <div class="app-row">
                <div class="app-col full-width">
                  <ul>
                    <li v-for="i in booking.notification_types" :key="i.id">
                      {{$t(`notification_${i.type}_address`)}}: {{i.recipient}}
                    </li>
                  </ul>
                </div>
              </div>

                <div class="app-row" v-loading="loading">

                    <div class="app-col full-width">
                        <h3>{{$t('booking')}} #{{booking_id}} {{$t('email_logs')}}</h3>
                        <email-log-list :list="emailLogs"></email-log-list>
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->

                <div class="app-row">
                    <div class="app-col full-width">
                        <h3>{{$t('booking')}} #{{booking_id}} {{$t('push_logs')}}</h3>
                        <push-log-list :list="pushLogs"></push-log-list>
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->

                <!-- <div class="app-row">
                    <div class="app-col full-width">
                        <h3>{{$t('booking')}} #{{booking_id}} {{$t('fax_logs')}}</h3>
                        <fax-log-list :list="faxLogs"></fax-log-list>
                    </div>
                </div> --> <!-- /.app-row -->
                <div class="app-row">
                    <div class="app-col full-width">
                        <h3>{{$t('booking')}} #{{booking_id}} {{$t('sms_logs')}}</h3>
                        <sms-log-list :list="smsLogs"></sms-log-list>
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page com-logs-page -->
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import ColExpandTable from './partials/ColExpandTable.vue'
import EmailLogList from './partials/EmailLogList.vue'
import PushLogList from './partials/PushLogList.vue'
import FaxLogList from './partials/FaxLogList.vue'
import SmsLogList from './partials/SMSLogList.vue'
export default {
    components:{
        EmailLogList,
        PushLogList,
        FaxLogList,
        SmsLogList,
    },
    data(){
        return {
        }
    },
    computed:{
        ...mapGetters({
            logs: 'logs/getLogs',
            emailLogs: 'logs/getEmailLogs',
            pushLogs: 'logs/getPushLogs',
            faxLogs: 'logs/getFaxLogs',
            smsLogs: 'logs/getSmsLogs',
            loading: 'logs/getLoading',
          booking: 'booking/getBooking'
        }),
        booking_id(){
            return this.$route.params.id
        },
         userId(){
                return JSON.parse(localStorage.getItem("app_user_data")).id
            },
    },
    methods: {
          ...mapActions('booking', {
            fetchBooking: 'getBooking'
          }),


         isColEnabled(key) {
                let r = true;

                if (_.includes(this.hiddenCols, key)) {
                    r = false;
                }

                return r;

            }, // End of isColEnabled() method
         ...mapActions({
          deleteUserInsideBooking: 'booking/deleteUserInsideBooking',
         })
    },
    mounted(){
        this.$store.dispatch('logs/getBookingComLogs',this.$route.params.id)

      this.fetchBooking(this.$route.params.id);
    },
    beforeRouteLeave(to, from, next) {
    let data = {
      booking_id: this.booking_id,
      user_id: this.userId
    };
    this.deleteUserInsideBooking(data);
    next(true);
  }
}
</script>
