<template>
  <el-form :model="form" @submit.native.prevent="submitForm" ref="form" :rules="rules">
    <el-form-item :label="$t('phone_number')" prop="phone">
      <!-- <el-input v-model="form.phone" placeholder="Inset Phone Number for example: +94123456, +89123456"></el-input> -->
      <!-- <el-select v-model="form.phone" placeholder="Inset Phone Number for example: +94123456, +89123456" filterable allow-create multiple
      @blur.native.capture="setPhones" ref="phone"
      v-on:keyup.tab.space="setPhones"
      >
        <el-option v-for="(i, index) in phones" :key="index" :value="i" :label="i"></el-option>
      </el-select>
      <small>press [TAB] for add multiple values</small> -->
      <multiselect
      v-model="form.phone"
      :options="phones"
      :multiple="true"
      :taggable="true"
      :placeholder="$t('text_insert_phone_send_sms')"
      @tag="setTag"
      >
      <span slot="noResult">{{$t('insert_phone_example')}}</span>

      </multiselect>
    </el-form-item>
    <el-form-item :label="$t('text')" prop="text">
      <el-input type="textarea" v-model="form.text" placeholder="" size="medium"
      :autosize="{ minRows: 6, maxRows: 8}"></el-input>
      <div class="text-right">
        <small >{{count}} {{$t('characters_left')}}</small>
      </div>
    </el-form-item>
    <small>{{$t('text_please_observe_user_respond_sms')}}</small>
    <div class="text-right">
    <el-button native-type="submit" size="mini">{{$t('tfv_send')}}</el-button>
    </div>
  </el-form>
</template>

<script>

import Multiselect from 'vue-multiselect'
import {isEmpty} from '~/js/helpers/Common'
import {Notification} from "element-ui";
import i18n from '~/js/i18n';
export default {
name:'sendSmsForm',
components:{Multiselect},
data(){
  const _this = this;
  return {
    form:{
      phone:[],
      text:''
    },
    rules:{
      phone: [{required:true,message:_this.$t('please_add_phone_number')}],
      text: [
        {required:true,message: _this.$t('please_add_text')},
        {max: 160, message: 'max 160 character'}
        ],
    },
    phones:[]
  }
},
computed:{
  count(){
    return 160 - this.form.text.length
  }
},
methods:{
  submitForm(e){
    console.log(e)
    this.$refs['form'].validate((valid) => {
      console.log(valid)
      if(valid){
      let data = {
        notification_type: 'sms',
        template_code: 'sms-blank',
        project_code: app_dt_notifier_project_code,
        data: {
          text: this.form.text
        },
        recipients: this.form.phone
      }
      // axios.post(`${app_dt_notifier_url}/api/v1/notifications`,data)
        let token = localStorage.getItem("app_access_token");
        axios.post(`${app_dt_notifier_url}/api/v3/notifications`,data,{
          headers:{
            'Authorization': 'Bearer '+ token
          }
        })
      .then(resp => {
        Notification.success({
          title: i18n.t('success'),
          message: 'send sms notification requested',
        });
        this.form.phone = [];
        this.form.text = '';
      }).catch(err => {

         Notification.error({
          title: i18n.t('error'),
          message: i18n.t('n_went_wrong'),
        });
      })
      }
    })
  },
  setPhones(e){
    console.log(e)
    if(!isEmpty(event.target.value) && !this.form.phone.includes(event.target.value)){
    this.form.phone.push(e.target.value)
    this.$refs.phone.$el.focus();
    event.target.focus();
    }
  },
  setTag(e){
    this.phones.push(e)
    this.form.phone.push(e)
  },

}
}
</script>

<style>
.multiselect, .multiselect__tags, .multiselect__single, .multiselect__input{
    font-size: 12px !important;
}
</style>
