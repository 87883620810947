import actions from './actions';

export default {
  namespaced: true,
  state: {
    isOnMobileViewport: false
  },

  getters: {
    isOnMobileViewport: (state) => state.isOnMobileViewport
  },

  mutations: {
    /**
     * @param {object} state
     * @param {boolean} bool
     * @returns {void}
     */
    setIsOnMobileViewport (state, bool) {
      state.isOnMobileViewport = bool;
    }
  },

  actions
};
