<template>
    <div class="app-fields company-main-fields">

        <!-- Municipality -->
        <el-form-item :id="genContainerId('municipality_id',formElId)"
                      :label="$t('municipality')" class="label-short">
                          <span slot="label" style="text-decoration: underline;" @click="handleMunicipalityClick">{{$t('municipality')}}</span>
            <el-select :id="genFieldId('municipality_id',formElId)" filterable
                       v-model="iValue.municipality_id" :placeholder="$t('select_municipality')">
                <el-option v-for="o in municipalityOpts"
                           :key="o.key"
                           :value="o.key"
                           :label="o.label"/>
            </el-select>
        </el-form-item>

        <!-- Name -->
        <el-form-item :id="genContainerId('name',formElId)"
                      :label="$t('name')" class="label-short">
            <el-input :id="genFieldId('name',formElId)"
                      v-model="iValue.name"
                      :placeholder="$t('please_input_company_name')"/>
        </el-form-item>

        <!-- Email -->
        <el-form-item :id="genContainerId('email',formElId)"
                      :label="$t('email')" class="label-short">
            <el-input :id="genFieldId('email',formElId)"
                      v-model="iValue.email"
                      :placeholder="$t('please_input_company_email')"/>
        </el-form-item>

         <!-- Blacklisted Users -->
                  <el-form-item :id="genContainerId('town_id',formElId)"
                          :label="$t('blacklisted_translators')" class="label-short">
                <el-select :id="genFieldId('blacklisted_users',formElId)"
                           v-model="value.blacklisted_users"
                           @focus="handleClickBlacklistedTranslatorSelection"
                           @remove-tag="removeBlackListedTranslator"
                           :placeholder="$t('please_select_blacklisted_translator')" multiple filterable
                >
                    <el-option v-for="(v) in translatorOpts"
                               :key="v.id"
                               :value="v.id"
                               :value-key="v.id"
                               :label="v.name">
                        {{v.name}}
                    </el-option>
                </el-select>
                  </el-form-item>
        <!-- End Black Listed Users -->

        <!-- Add On Confirmation -->
        <el-form-item :id="genContainerId('add_on_confirmation',formElId)"
                      :label="$t('add_on_confirmation')" class="label-short">
        <el-select v-model="iValue.add_on_confirmation" multiple :placeholder="$t('select')">
            <el-option
                v-for="item in add_on_confirmation_options"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
        </el-select>
         </el-form-item>

        <!-- End Add On Confirmation -->

        <!-- Unified Number Settings -->
        <unified-number-setting v-model="iValue"></unified-number-setting>
        <!-- End of Unified Number Setting -->

        <!-- Notes -->
        <el-form-item :id="genContainerId('notes',formElId)"
                      :label="$t('notes')" class="label-short">
            <el-input :id="genFieldId('notes',formElId)"
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 7}"
                    v-model="iValue.notes"
                    :placeholder="$t('input_notes_about_company')"/>
        </el-form-item>

      <email-setting-fields v-model="iValue"></email-setting-fields>


        <!-- Confidential -->
  <br>
            <h3>{{$t('is_confidential')}}</h3>
           <div class="app-splitter two-cols">
              <el-form-item :id="genContainerId('is_confidential',formElId)"
                          :label="$t('is_confidential')" class="label-switch">
                            <span slot="label" ><p>{{$t('protected_identity')}}</p></span>
                <el-switch :id="genFieldId('is_protected_identity',formElId)"
                           v-model="iValue.is_protected_identity" :active-value="1" :inactive-value="0" @change="handleInfoChange(iValue.is_protected_identity)"/>

            </el-form-item>
            <el-form-item :id="genContainerId('', formElId)"
              :label="$t('senesitive_information')" class="label-switch">
               <span slot="label" ><p>{{$t('senesitive_information')}}</p></span>
                <el-switch :id="genFieldId('is_sensitive_info',formElId)"
                v-model="iValue.is_sensitive_info" :active-value="1" :inactive-value="0" @change="handleInfoChange(iValue.is_sensitive_info)" />
            </el-form-item>
           </div>

                    <div v-if="iValue.is_sensitive_info || iValue.is_protected_identity == 1">
            <el-form-item :id="genContainerId('comments',formElId)"
                          label="" class="label-block">
                <el-input :id="genFieldId('comments' ,formElId)"
                          v-model="iValue.confidential_message"
                          type="textarea"
                          :rows="2"/>
            </el-form-item>
                    </div>
            <!-- End Confidential -->



    </div> <!-- /.app-fields company-main-fields -->
</template>

<script>
    import {mapGetters, mapActions, mapState} from "vuex";
    import {populate} from "../../../../js/helpers/Vuex";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */

        data() {
            return {
                add_on_confirmation_options: [
                    'DOB', 'Address', 'Email'
                ]
            }
        }, // End data

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            formElId: {
                type: String,
                default: 'company_compose_form',
            },

            value: {
                type: Object,
                default() {
                    return {
                        municipality_id: '',
                        name: '',
                        email: '',
                        // gln: '',
                    }
                }
            }

        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            // Map Municipality Options getter to be used for dropdown selections.
            ...mapGetters('municipality', {
                municipalityOpts: 'listAsOptionsPaired',
            }),

            ...mapGetters({
                translatorOpts: 'translator/listAsOptions',
            }),

            ...mapState('company', {
                blacklisted_users_array : 'compose'
            }),



            /**
             * Interface for the v-model of this component.
             */
            iValue: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                },
            },

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            // Map Municipality Options list loader.
            ...mapActions('municipality', {
                loadMunicipalityOpts: 'browseAsOptions',
            }),
            ...mapActions({
                loadTranslatorOpts: 'translator/browseAsOptions',
                deleteBlackListedUser: 'blacklistedUsers/deleteBlackListedUser'
            }),
            handleMunicipalityClick() {
            this.$router.push({name:'municipality.edit',params:{id:this.iValue.municipality_id}})

            },
            handleInfoChange(value) {
                if(value == 0) {
                    this.iValue.confidential_message = '';
                }
            },
            handleClickBlacklistedTranslatorSelection () {
                populate(this, 'loadTranslatorOpts', 'translatorOpts', {
                ref: 'Translator Options Listing'
                });
            },
            removeBlackListedTranslator(v) {
               let obj = this.blacklisted_users_array.blacklisted_users.filter((id) =>  {
                   return id.translator_id === v
                })
                if(obj) this.deleteBlackListedUser(obj[0].id);
            }

        }, // End of Component > methods


        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {

            // Populate the options for company to be used for dropdown selection.
            populate(this, 'loadMunicipalityOpts', 'municipalityOpts', {
                ref: 'Municipality Options'
            });

        }, // End of Component > mounted


    } // End of export default
</script>
