/*
|--------------------------------------------------------------------------
| Financial > shared > props > constants
|--------------------------------------------------------------------------
| Contains values usable to the props files.
*/

'use strict';

export const BOOKING_TYPES = [
  'phone',
  'physical',
  'video',
  'video_skype',
  'video_physical',
  'convey',
  'text_translation',

  // This will be removed before assigning to payload when
  // settings.emergency_booking.different_type_immediate is false
  'phone_immediate'
];

export const BOOKING_METHODS = [
  'online', 'phone'
];

export const TRANSLATOR_LVLS = [
  'layman',
  'read_courses',
  'trained',
  'certified',
  'certified_in_health',
  'certified_in_law'
];

export const TRANSLATOR_LVLS_WITH_KEY = {
  1: 'layman',
  2: 'certified',
  3: 'read_courses',
  4: 'trained',
  5: 'certified_in_health',
  6: 'certified_in_law'
};

export default {
  BOOKING_TYPES,
  BOOKING_METHODS,
  TRANSLATOR_LVLS,
  TRANSLATOR_LVLS_WITH_KEY
};
