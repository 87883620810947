<template>
    <div class="app-list admin-permissions-roles-list">
        <el-row>
            <el-col :span="2" :offset="20">
                <router-link tag="button" to="/admin-permissions/roles/create" @click.stop.prevent :title="$t('create')" class="el-button el-button--success">
                    <i class="fa fa-plus"></i> {{$t('create')}}
                </router-link>
            </el-col>
        </el-row>

        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="warning" :title="$t('edit_pages')" @click="handleRedirect(scope)">
                    <span class="fa fa-cog"></span>
                </el-button>
                <el-button class="circle" type="success" :title="$t('edit')" @click="handleEdit(scope)">
                    <span class="fa fa-pencil"></span>
                </el-button>
                <el-button class="circle" type="danger" :title="$t('delete')" @click="handleDelete(scope)">
                    <span class="fa fa-trash"></span>
                </el-button>
            </template>
        </base-list-table>

        <el-pagination
            v-if="listPagination.total > listPagination.per_page"
            layout="prev, pager, next"
            :page-size="listPagination.per_page"
            :total="listPagination.total"
            :current-page="listPagination.current_page"
            @current-change="reloadList">
        </el-pagination>
    </div> <!-- /.app-list admin-permissions-roles-list -->
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import {populate} from "~/js/helpers/Vuex";

        export default {

        /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
        data() {
            const _this=this;
            return {
                listCols: [
                    {
                        label: _this.$t('label'),
                        prop: 'label',
                        width: '',
                        className: 'comment-col',
                    },
                    {
                        label: _this.$t('name'),
                        prop: 'name',
                        width: '',
                        className: 'name-col',
                    },
                    {
                        label: _this.$t('date'),
                        prop: 'created_at',
                        width: '',
                        className: 'date-col',
                    }
                ]
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapGetters('adminPermissionsRoles', ['listData','listPagination'])

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
        methods: {
            ...mapActions('adminPermissionsRoles', ['browse', 'delete']),

            handleRedirect(scope) {
                window.location.href = '/#/admin-permissions?filter[role_id]=' + scope.row.id;
            },

            handleEdit(scope) {
                window.location.href = '/#/admin-permissions/roles/edit/' + scope.row.id;
            },

            handleDelete(scope) {
                this.$confirm(this.$t('delete_this_admin_role'), 'Warning', {
                    confirmButtonText: 'YES',
                    cancelButtonText: 'NO',
                    type: 'warning'
                }).then(() => {
                    this.delete({
                        id: scope.row.id
                    });
                });
            },
            reloadList(n) {
                let params = _.clone(this.$route.query);
                n = sd(n, sd(this.$route.query.page, 1)) // Set the page number.
                params.page = n;
                this.$router.push({query:params})
                populate(this, 'browse', 'listData', {
                    force: true,
                    params
                });
            },
        }, // End of Component > methods


        /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
            populate(this, 'browse', 'listData', {
                force: true
            });
        }, // End of Component > mounted

    }
</script>
