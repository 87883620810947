<template>
    <div :class="genBem(blockCls)">

        <!-- Header -->
        <div :class="genBem(blockCls, 'header')">
            <!-- Header Title -->
            <h3 v-if="title !== ''"
                :class="genBem(blockCls, 'header-title')">{{ title }}</h3>

            <!-- Header Buttons -->
            <div :class="genBem(blockCls, 'header-buttons')">
                <el-button @click="handleClickSave">{{ $t('save') }}</el-button>
            </div>
        </div> <!-- End of Header -->

        <!-- Body -->
        <div :class="genBem(blockCls, 'body')">
            <table :class="genBem(blockCls, 'table')">
                <!-- Table Header -->
                <thead>
                <tr>
                    <th><span class="cell">{{ $t('tfv_fn_travel_settings') }}</span></th>
                    <th><span class="cell">{{ $t('tfv_fn_charge') }}</span></th>
                    <th><span class="cell">{{ $t('tfv_fn_maximum') }}</span></th>
                    <th><span class="cell">{{ $t('tfv_fn_unit') }}</span></th>
                </tr>
                </thead>

                <!-- Table Body -->
                <tbody>
                <tr>
                    <td><span class="cell cell-label">Maximum Travel Time</span></td>
                    <td><span class="cell cell-value">
                        <el-input v-model="iForm.travel_time_fee_per_hour"/>
                    </span></td>
                    <td><span class="cell cell-value">
                        <el-input v-model="iForm.max_travel_time"/>
                    </span></td>
                    <td><span class="cell cell-value">min</span></td>
                </tr>
                <tr>
                    <td><span class="cell cell-label">Maximum KM</span></td>
                    <td><span class="cell cell-value">
                        <el-input v-model="iForm.travel_distance_fee_per_km"/>
                    </span></td>
                    <td><span class="cell cell-value">
                        <el-input v-model="iForm.max_travel_distance"/>
                    </span></td>
                    <td><span class="cell cell-value">KM</span></td>
                </tr>
                </tbody>
            </table>
        </div> <!-- End of Body -->


    </div> <!-- template wrapper -->
</template>

<script>
  import {mapState, mapMutations, mapActions} from 'vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > prop
    |--------------------------------------------------------------------------
    */
    props: {

      title: {
        type: String,
        default: ''
      },

      // Varies between invoice | salary
      target: {
        type: String,
        default: ''
      },

      bookingId: {
        default: 0
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-adjustment-travel-settings-form'
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapState('financialAdjustmentInvoice', {
        invoiceForm: 'compose'
      }),

      ...mapState('financialAdjustmentSalary', {
        salaryForm: 'compose'
      }),

      /**
       * Interface for the the form data that will be used for this component.
       */
      iForm: {
        get () {
          const form = `${this.target}Form`;
          return this[form];
        },
        set (v) {
          const form = `${this.target}Form`;
          this[form] = v;
        }
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapMutations({

        // Invoice
        invoiceSetTargetBooking: 'financialAdjustmentInvoice/setTargetBooking',
        invoiceResetForm: 'financialAdjustmentInvoice/resetCompose',

        // Salary
        salarySetTargetBooking: 'financialAdjustmentSalary/setTargetBooking',
        salaryResetForm: 'financialAdjustmentSalary/resetCompose'
      }),

      ...mapActions({

        // Invoice
        invoiceReadItem: 'financialAdjustmentInvoice/read',
        invoiceSaveItem: 'financialAdjustmentInvoice/save',

        // Salary
        salaryReadItem: 'financialAdjustmentSalary/read',
        salarySaveItem: 'financialAdjustmentSalary/save'
      }),

      /**
       * Handler when the save button was clicked.
       *
       * @return {void}
       */
      handleClickSave () {
        const action = `${this.target}SaveItem`;
        this[action]();
      },

      /**
       * Method to load the table data for whichever was targeted.
       * @return {void}
       */
      loadTableData () {
        const action = `${this.target}ReadItem`;
        this[action]();
      },

      /**
       * Method to set the targeted booking value in Vuex to be used
       * inside READ and SAVE actions in the Vuex.
       *
       * @param {string|int} id - Booking ID
       * @return {void}
       */
      setTargetBooking (id) {
        const action = `${this.target}SetTargetBooking`;
        this[action](id);
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      this.setTargetBooking(this.bookingId);
      this.loadTableData();
    }

  }
</script>

<style scoped>

</style>