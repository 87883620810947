<template>
    <el-table :data="listDetail">
      <el-table-column label="Delivery Status">
          <template slot-scope="scope">
                <div class="cell-content">
                    {{ scope.row.status }}
                </div>
            </template>
      </el-table-column>
      <el-table-column label="created_at">
          <template slot-scope="scope">
                <div class="cell-content">
                    {{ scope.row.created_at }}
                </div>
            </template>
      </el-table-column>
      <el-table-column label="payloads">
          <template slot-scope="scope">
                <div class="cell-content">
                    {{ scope.row.payload }}
                </div>
            </template>
      </el-table-column>
    </el-table>
</template>

<script>
export default {
    props:{
        list:{
            type: Array,
            default:[]
        },
        type:{
            type: String,
            default:'email'
        }
    },
    computed:{
        listDetail(){
            return this.list
        }
    }
}
</script>

<style>

</style>
