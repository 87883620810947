<template>
  <div class="confirmation-fills-container">

    <el-dialog :title="$t('tfv_booking_confirmation_fills')" :visible.sync="iVisible" :append-to-body="true"
               :lock-scroll="true" :width="width">

      <el-form :model="confirmationFills" :rules="rules" ref='confirmation_fill'
               id="confirmation-fills-form" v-loading="loading"
               @submit.native.prevent="handleClickConfirm('confirmation_fill')">
        <el-form-item label="Beställarens (ditt) namn:" :class="bookingFieldRules.booker_name_class" prop="booker_name">
          <!-- <el-input v-model="confirmationFills.booker_name" auto-complete="off"></el-input> -->
          <el-select v-model="confirmationFills.booker_name" filterable auto-complete="off"
                     default-first-option
                     allow-create @blur.native.capture="setBookerName"
          >
            <el-option v-for="(item, index) in getBookerNameList" :key="index" :value="item">
              {{item}}
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Evt referens:" :class="bookingFieldRules.reference_class" prop="reference">
          <el-select v-model="confirmationFills.reference" filterable default-first-option
                     auto-complete="off"
                     allow-create
                     :placeholder="(!isEmpty(bookingFieldRules.reference_placeholder) ? bookingFieldRules.reference_placeholder: 'Evt referens:')"
                     @blur.native.capture="setReference"
          >
            <el-option v-for="(item, index) in getReferenceList" :key="index" :value="item">
              {{item}}
            </el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="Tolkanvändarens namn:" :class="bookingFieldRules.staff_name_class" prop="staff_name">
          <el-select v-model="confirmationFills.staff_name" filterable auto-complete="off"
                     allow-create default-first-option @blur.native.capture="setStaffName"

          >
            <el-option v-for="(item, index) in getStaffNameList" :key="index" :value="item">
              {{item}}
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="LMA-nummer:" :class="bookingFieldRules.lma_class" prop="lma">
          <el-input v-model="confirmationFills.lma" filterable auto-complete="off"
          >
          </el-input>
        </el-form-item>


        <h4>Vill du ha bekräftelse via:</h4>
        <el-form-item label="" prop="email">
          <el-row>
            <el-col :md="6">
              <el-checkbox
                label="E-postadres"
                aria-label="E-postadres"
                v-model="enabledEmail"
                @change="clearNotificationTypes('email')"
              />
            </el-col>
            <el-col :md="18">
              <multiselect
                v-model="confirmationFills.email"
                :options="getEmailList"
                :searchable="true"
                :multiple="true"
                :taggable="true"
                @tag="setEmails"
                @select="setEmails"
                @remove="removeTag"
                @close="setNotificationTypes('email')"
                :clear-on-select="false"
              ></multiselect>
            </el-col>
          </el-row>
        </el-form-item>
       <!--<el-form-item label="" prop="fax">
          <el-row>
            <el-col :md="6">
              <el-checkbox
                label="Fax"
                aria-label="Fax"
                v-model="confirmationFills.faxEnabled"
                @change="clearNotificationTypes('fax')"
              />
            </el-col>
            <el-col :md="18">
              <el-input v-model="confirmationFills.fax" placeholder=""
                        @blur="setNotificationTypes('fax')"
                        :disabled="!confirmationFills.faxEnabled" auto-complete="off"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="" prop="sms">
          <el-row>
            <el-col :md="6">
              <el-checkbox
                label="SMS"
                aria-label="SMS"
                v-model="enabledSMS"
                @change="clearNotificationTypes('sms')"
                auto-complete="off"
              />
            </el-col>
            <el-col :md="18">
              <el-input v-model="confirmationFills.sms" placeholder="" :disabled="!enabledSMS"
                        @blur="setNotificationTypes('sms')"></el-input>
            </el-col>
          </el-row>
        </el-form-item>-->
        <el-row v-if="isPhysical || confirmationFills.video_provider==='video_physical'">
          <el-col :span="24">
            <template>

              <el-form-item label="Address" prop="address" class="label-medium">
                <booking-maps-address v-model="confirmationFills.address"
                                      :city.sync="confirmationFills.city"></booking-maps-address>
              </el-form-item>

              <!-- <el-form-item label="town" class="label-medium" prop="city">
                <el-input v-model="confirmationFills.city" placeholder="town"></el-input>
              </el-form-item> -->
              <el-form-item :label="$t('tfv_instruction')" class="label-medium">
                <el-input type="textarea"
                          autosize
                          v-model="confirmationFills.instructions" :placeholder="$t('tfv_instruction')"
                          :disabled="!bookingFieldRules.instructions_enabled"></el-input>
              </el-form-item>

            </template>
          </el-col>
        </el-row>
        <template v-if="bookingForm.type==='video'">
          <el-form-item :label="$t('tfv_video_type')" class="label-medium">
            <el-radio-group v-model="confirmationFills.video_provider" tabindex="0">
              <el-radio label="jitsi">{{ $t('tfv_video_mode') }}</el-radio>
              <el-radio label="skype">{{ $t('tfv_skype') }}</el-radio>
              <el-radio label="video_physical">{{ $t('tfv_video_physical') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </template>

        <el-form-item label="Video url" v-if="confirmationFills.video_provider==='skype'" prop="video_url"
                      class="label-medium">
          <el-input v-model="confirmationFills.video_url" placeholder=""></el-input>
        </el-form-item>

        <el-form-item :label="$t('how_did_the_user_contact_us_to_make_the_booking')" class="label-medium">
          <el-radio-group v-model="confirmationFills.created_via" tabindex="0">
            <el-radio label="Admin Email">{{ $t('email') }}</el-radio>
            <br>
            <el-radio label="Admin Phone">{{ $t('phone') }}</el-radio>
            <br>
            <!-- <el-radio label="Admin Fax">Fax</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" prop="information_tolk">
          <el-row>
            <el-col :md="6">
              <label><b>{{$t('information_client')}}</b></label>
            </el-col>
            <el-col :md="18">
              <el-input v-model="confirmationFills.message"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="" prop="information_tolk">
          <el-row>
            <el-col :md="6">
            </el-col>
            <el-col :md="18">
              <aws-file-upload v-model="message_files" />
            </el-col>
          </el-row>
        </el-form-item>
        
       <!-- <el-form-item label="" prop="client_phone">
          <el-row>
            <el-col :md="6">
              <label><b>{{$t('client_phone')}}</b></label>
            </el-col>
            <el-col :md="18">
              <el-input v-model="client_phone" placeholder="+46701231234" :disabled="!iAppointmentTemplate"></el-input>
              <span v-if="!iAppointmentTemplate">{{$t('language_do_not_have_appointment_template')}}</span>
            </el-col>
          </el-row>
        </el-form-item>
        <template v-if="iAppointmentTemplate">
          <el-form-item :label="$t('do_you_want_to_send_link_to_patient')"
                        class="label-medium"
                        v-if="confirmationFills.video_provider==='jitsi' || confirmationFills.video_provider==='skype'">
            <el-switch v-model="send_video_template"/>
          </el-form-item>
        </template>-->

        <el-form-item label="" v-if="isPhysical || confirmationFills.video_provider==='video_physical'">
          <!--
          <p>Tyvärr finns det begränsat med {{ (language) ? language.name : ' ' }} tolkar under den
            aktuella tiden. Talar klienten
            något annat språk, i så fall vilket? Bokningen skickas i så fall till {{ (language) ? language.name : ' ' }}
            tolkar i första hand och i andra hand i det, i andra hand, valda språket:</p>
            -->

          <p>
            Telefontolk okej i andrahand
          </p>

          <div id="allow_fallback_type_notification" v-if="isNotSufficientTranslators">
            Kan kunden tänka sig telefontolk ifall platstolk ej finns tillgänglig?
          </div>
          <el-radio-group v-model="fallback_type" tabindex="0">
            <el-radio :label="true" :value="true">Ja</el-radio>
            <el-radio :label="false" :value="false">Nej</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="!isTextTranslation">
          <div v-if="!isPhysical || confirmationFills.video_provider==='video_physical'">
            <div v-if="isNotSufficientTranslators" class="text-danger">
              <b>{{$t('ask_the_questions')}}</b>
            </div>
            <div v-else style="color:#24bd8e">
              {{$t('matching_interpreters')}}
            </div>
          </div>

          <p>
            <!-- {{$t('speak_any_other_language_1')}} {{ (language) ? language.name : ' ' }}
            {{$t('speak_any_other_language_2')}} -->

            {{$t('tfv_alternative_languages')}}
          </p>
          <el-col :span="12">
            <el-form-item label="">
              <el-radio-group v-model="bookingFieldRules.alt_language_enabled" tabindex="0">
                <el-radio :label="true" :value="1">{{$t('yes')}}</el-radio>
                <el-radio :label="false" :value="0">{{$t('no')}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" prop="alt_language">
            <el-form-item label="" v-if="bookingFieldRules.alt_language_enabled">
              <el-select v-model="confirmationFills.alternative_languages"
                         placeholder="Språk"
                         multiple
                         filterable default-first-option

              >
                <!-- :placeholder="$tc('language')"  -->

                <el-option
                  v-for="language in languages"
                  :key="language.id"
                  :label="language.name"
                  :value="language.id"/>
              </el-select>
            </el-form-item>
          </el-col>


          <template v-if="!isEmpty(bookingForm.gender)">
            <div>
              <!-- <p> {{$t('accept_other_gender',{other:(bookingForm.gender=='male')?$t('female'):$t('male'),actual:$t(bookingForm.gender)})}}</p> -->
              <p>
                Ni har valt {{(bookingForm.gender == 'male') ? 'Manlig':'Kvinnlig' }} tolk. Om det ej finns tillgänglig
                tolk. Kan ni acceptera {{(bookingForm.gender == 'male') ? 'Kvinnlig':'Manlig' }} tolk i andra hand?
              </p>
              <el-form-item label="">
                <el-radio-group v-model="alt_gender_enabled" tabindex="0">
                  <el-radio :label="true" :value="1">Ja</el-radio>
                  <el-radio :label="false" :value="0">Nej</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </template>
          <template v-if="bookingForm.translator_levels.length === 1">
            <div>
              <!-- <p> {{$t('accept_alt_translator_levels')}}</p> -->
              <p>Ni har {{ getLevelsName(bookingForm.translator_levels) }}. Kan ni acceptera annan nivå om ingen {{
                getLevelsName(bookingForm.translator_levels) }} finns tillgänglig. </p>
              <el-form-item label="">
                <el-radio-group v-model="alt_translator_levels" tabindex="0">
                  <el-radio :label="true" :value="1">Ja</el-radio>
                  <el-radio :label="false" :value="0">Nej</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </template>

          <template v-if="!isEmpty(bookingForm.specific_translators)">
            <div>
              <!-- <p> {{$t('accept_alt_specific_translators')}}</p> -->
              <p>
                Ni har bokat specifik tolk. Kan ni tänka er annan tolk ifall den ni valt ej är tillgänglig
              </p>
              <el-form-item label="">
                <el-radio-group v-model="alt_specific_translators" tabindex="0">
                  <el-radio :label="true" :value="1">Ja</el-radio>
                  <el-radio :label="false" :value="0">Nej</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </template>
        </template>


        <div class="text-right">
          <el-button @click="iVisible = false">Cancel</el-button>
          <!-- <el-button type="primary" @click="handleClickConfirm('confirmation_fill')" v-loading="loading" :disabled="loading">Confirm</el-button> -->
          <el-button type="primary" native-type="submit" :disabled="loading" v-loading="loading">
            Confirm
          </el-button>
        </div>
      </el-form>

    </el-dialog> <!-- /. Booking Confirmation Fills -->

    <alert-modal :title="alertTitle" :alert-type="alertType" :visible.sync="showAlertModal"
                 :booking-id="bookingId" :message="message"/>
    <!-- <count-down :visible.sync="countdown" :booking="bookingData"></count-down> -->
  </div>
</template>

<script>
  import AlertModal from '~/components/modals/booking/AlertModal';
  import AwsFileUpload from '~/components/displays/AwsFileUpload';

  import Multiselect from 'vue-multiselect'
  import {mapActions, mapGetters, mapState} from 'vuex';
  import {extendObjParams, isEmpty} from '~/js/helpers/Common'
  import {Notification} from 'element-ui';

  export default {
    components: {
      AlertModal,
      Multiselect,
      AwsFileUpload
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      isPhysical: {
        type: Boolean,
        default: false
      },
      language: {
        type: Object,
        default: null
      },
      width: {
        type: String,
        default: '50%'
      },
      changeAddress: {
        type: Boolean,
        default: false
      },
      translatorLevels: {
        type: Array,
        default: []
      },
      appointmentTemplate: {
        type: Boolean,
        default: false
      }

    },
    data () {

      return {
        alt_gender_enabled: false,
        alt_specific_translators: false,
        alt_translator_levels: false,
        countdown: false,
        email: [],
        enabledEmail: true,
        enabledSMS: false,
        showAlertModal: false,
        alertTitle: 'booking_success',
        alertType: 'success',
        bookingId: 0,
        agreed_related_languages: 0,
        query_telephone: 1,
        email_notification: [],
        loading: false,
        message: '',
        bookingData: {
          due: '',
          id: ''
        },
        fallback_type: false,
        client_phone: '',
        send_video_template: false,
        message_files: []
      }
    },

    computed: {
      ...mapState('booking', {
        confirmationFills: 'confirmationFills',
        bookingFieldRules: 'bookingField',
        bookingForm: 'bookingForm'

      }),
      ...mapGetters({
        languages: 'language/getLanguages',
        // loading: 'booking/getLoading',
        default: 'booking/getDefaultConfirmationFills',
        getBookerNameList: 'booking/getBookerNameList',
        getReferenceList: 'booking/getReferenceList',
        getStaffNameList: 'booking/getStaffNameList',
        getEmailList: 'booking/getEmailList',
        customer: 'booking/getCustomer',
        booking: 'booking/getBooking',
        isNotSufficientTranslators: 'booking/isNotSufficientTranslators',

      }),
      /**
       * Interface for this.visible
       */
      iVisible: {
        get () {
          return this.visible
        },
        set (v) {
          this.$emit('update:visible', v);
        }
      },
      iTranslatorLevels () {
        return this.translatorLevels
      },

      isTextTranslation () {
        return this.bookingForm.type === 'text_translation'
      },

      iAppointmentTemplate () {
        return this.appointmentTemplate;
      },
      rules () {
        return {
          address: [{
            required: this.isPhysical,
            message: 'Please input address',
            trigger: 'blur'
          }],
          city: [{
            required: this.isPhysical,
            message: 'Please input town by selecting address'

          }],
          lma: [{
            required: this.bookingFieldRules.lma_required,
            message: 'Please input lma number',
          }],
          booker_name: [{
            required: this.bookingFieldRules.booker_name_required,
            message: this.$t('please_input_booker_name'),
          }],
          staff_name: [{
            required: this.bookingFieldRules.staff_name_required,
            message: 'Please input staff name',
          }],
          reference: [{
            required: this.bookingFieldRules.reference_required,
            message: 'Please input reference',
          }],
          instructions: [{
            required: this.bookingFieldRules.instructions_required,
            message: 'Please input reference',
          }],
          email: [{
            required: this.enabledEmail,
            message: 'Emails required if enabled'
          }],
          fax: [{
            required: this.confirmationFills.faxEnabled,
            message: 'Fax required if enabled'
          }],
          sms: [{
            required: this.enabledSMS,
            message: 'SMS required if enabled'
          }],
          alt_language: [{
            required: !this.isTextTranslation && this.bookingFieldRules.alt_language_required,
            message: 'Alternative Language required'
          }],
          video_url: [{
            required: this.confirmationFills.video_provider === 'skype',
            message: 'Url required if video type skype'
          }],
        }
      },

      bookerNameStyle () {
        console.log(this.bookingFieldRules)
        return 'label-medium'
      }

    },
    methods: {
      ...mapActions({
        submitEmail: 'booking/submitEmail',
        submitForm: 'booking/submitForm',
        assignTranslator: 'booking/assignTranslator',
        resetForm: 'booking/resetBookingForm',
      }),
      onUpdateAddressAutoComplete (value) {

        //  this.confirmationFills.address = value.route;
        // this.confirmationFills.town = value.administrative_area_level_1;
        this.confirmationFills.city = value.administrative_area_level_1;
      },
      setNotificationTypes (type) {

        if (type === 'email' && !isEmpty(this.confirmationFills.email)) {

          this.confirmationFills.notification_types = this.confirmationFills.notification_types.filter(e => e.type !== 'email');

          this.confirmationFills.email.forEach((item) => {
            this.confirmationFills.notification_types.push({
              type: 'email',
              recipient: item
            });
          })
        }

        if (type === 'fax' && !isEmpty(this.confirmationFills.fax)) {
          this.confirmationFills.notification_types = this.confirmationFills.notification_types.filter(e => e.type !== 'fax');

          this.confirmationFills.notification_types.push({
            type: 'fax',
            recipient: this.confirmationFills.fax
          });
        }

        if (type === 'sms' && !isEmpty(this.confirmationFills.sms)) {
          this.confirmationFills.notification_types = this.confirmationFills.notification_types.filter(e => e.type !== 'sms');
          this.confirmationFills.notification_types.push({
            type: 'sms',
            recipient: this.confirmationFills.sms
          });
        }
      },
      clearNotificationTypes (type) {
        if (type === 'sms' && this.enabledSMS === false) {
          // this.confirmationFills.sms = ''
          this.confirmationFills.notification_types = this.confirmationFills.notification_types.filter(e => e.type !== 'sms');
        }
        if (type === 'fax' && this.confirmationFills.faxEnabled === false) {
          // this.confirmationFills.fax = ''
          this.confirmationFills.notification_types = this.confirmationFills.notification_types.filter(e => e.type !== 'fax');
        }
        if (type === 'email' && this.enabledEmail === false) {
          // this.confirmationFills.email = []
          this.confirmationFills.notification_types = this.confirmationFills.notification_types.filter(e => e.type !== 'email');
        }
      },
      setBookerName (event) {
        console.log(event)
        this.confirmationFills.booker_name = event.target.value
      },
      setStaffName (event) {
        this.confirmationFills.staff_name = event.target.value
      },
      setEmails (event) {
        if (!isEmpty(event)) {
          let validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event);

          if (validEmail) {
            if (!this.getEmailList.includes(event)) {
              this.confirmationFills.email.push(event);
              this.getEmailList.push(event);
            }


            this.confirmationFills.notification_types = this.confirmationFills.notification_types.filter(e => e.type !== 'email');

            this.confirmationFills.email.forEach((item) => {
              this.confirmationFills.notification_types.push({
                type: 'email',
                recipient: item
              });
            })
          }

        }

      },

      removeTag (e) {

        let email = this.confirmationFills.email.filter(v => v !== e);

        this.confirmationFills.notification_types = this.confirmationFills.notification_types.filter(e => e.type !== 'email');
        if (!isEmpty(email)) {
          email.forEach((item) => {
            this.confirmationFills.notification_types.push({
              type: 'email',
              recipient: item
            });
          })
        }

      },
      // handleClickEmailOption(e) {
      //   // let newValue = e.filter( (item) =>  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item) )
      //   // this.confirmationFills.email = newValue
      // },
      setReference (event) {
        this.confirmationFills.reference = event.target.value

      },
      isEmpty (v) {
        return isEmpty(v)
      },
      /** Handle when confirm button is clicked */
      handleClickConfirm (form) {
        // console.log(form)
        // if (!this.isPhysical) {
        //   this.confirmationFills.address = null;
        //   this.confirmationFills.town = null;
        //   this.confirmationFills.city = null;
        // }
        this.clearNotificationTypes('sms');
        this.clearNotificationTypes('email');
        this.clearNotificationTypes('fax');
        this.$refs[form].validate(async (valid) => {
          // console.log(valid)
          if (valid) {

            if (this.isPhysical) {
              this.confirmationFills.fallback_type = this.fallback_type ? 'phone' : null;
            }

            let newForm = {
              ...this.$store.state.booking.bookingForm,
              ...this.$store.state.booking.is_immediate,
              ...this.confirmationFills
            };
            this.loading = true
            newForm.create_user = 0;
            newForm.client_phone = this.client_phone
            newForm.send_video_template= this.send_video_template

            delete newForm.messages;
            if(this.message_files.length === 0 && this.confirmationFills.message ==='') {
              delete newForm.message_files;
              delete newForm.message;
            }

            if (['video', 'video_skype', 'video_physical'].includes(newForm.type)) {
              if (newForm.video_provider === 'skype') {
                newForm.type = 'video_skype'
              }
              if (newForm.video_provider === 'video_physical') {
                newForm.type = 'video_physical'
              }
            } else {
              delete newForm.video_provider;
              delete newForm.video_url;
            }

            if (!['physical', 'video_physical'].includes(newForm.type)) {
              newForm.address = null;
              newForm.town = null;
              newForm.city = null;
            }


            // if (this.confirmationFills.email.length === 1) {
            //   let data = await this.submitEmail(this.confirmationFills.email)
            //
            //   if (data.data.success === false) {
            //     // try {
            //     let confirm = await this.$confirm('Do you want to create a new user?', 'Confirm', {
            //       distinguishCancelAndClose: true,
            //       confirmButtonText: 'Yes',
            //       cancelButtonText: 'No',
            //       type: 'info'
            //     })
            //
            //                             .then(() => {
            //                               newForm.create_user = 1
            //                             }).catch(() => {
            //         newForm.create_user = 0
            //       });
            //   }
            // }

            if (newForm.type === 'text_translation' && newForm.to_language_id.length > 0) {
              let loop = newForm.to_language_id;
              let form = newForm

              for (const i of loop) {
                form.to_language_id = i
                let resp = await this.submitForm(form)

                if (resp.status === 201) {
                  // console.log(resp.data)
                  // id.push(resp.data.data.booking.id)
                  let booking = resp.data.data.booking
                  Notification({
                    message: `Text translation #${booking.id} created`,
                    type: 'info',
                    onClick: function (data) {
                      window.location.href = `/#/booking/${booking.id}/details/`
                    }
                  });
                  this.bookingId = resp.data.data.booking.id;
                  this.alertType = 'success';
                  this.alertTitle = 'booking_success';
                  this.showAlertModal = true;
                }
              }

            } else {
              let resp = await this.submitForm(newForm);

              if (resp.status === 201) {
                this.bookingId = this.booking.id;
                this.alertType = 'success';
                this.alertTitle = 'booking_success';
                if (!isEmpty(newForm.assigned_translator)) {
                  let data = {
                    id: this.booking.id,
                    translator_id: newForm.assigned_translator
                  };
                  let assigned = await this.assignTranslator(data)
                }
              } else {

                this.bookingId = 0;
                this.alertType = 'error';
                this.alertTitle = 'booking_error';
                this.message = '<br>Please check if booking is still made. Sometimes the booking gets submitted despite error message';
                // this.showAlertModal = true
              }

              this.showAlertModal = true;
            }
            this.loading = false;
            this.iVisible = false;
            this.resetForm();

          } else {
            return false;
          }


        })
      },

      // getTranslatorLevelsName(v){
      //   console.log(v)
      //   console.log(this.translatorLevels)
      // },

      getLevelsName (v) {
        if (!isEmpty(v)) {
          return this.iTranslatorLevels.find(x => x.id === v[0]).name.toLowerCase()
        }
        return ''
      }

    }
  }

</script>

<style lang="scss">
  #confirmation-fills-form {
    .el-input__inner {
      // background: inherit;
      border-radius: 5px;
      // border-bottom-right-radius: 5px;
      background-color: #ffffff;
      border: 1px solid #d8dce5;
    }
  }

  .vue-input-tag-wrapper .input-tag {
    border: 0 !important;
    color: #5a5e66 !important;
    background-color: #d8dce5 !important;
  }

  .pac-container {
    z-index: 9999 !important;
  }

  #allow_fallback_type_notification {
    font-size: 12px;
    font-weight: bold;
    color: #F35531;
  }

  .red {
    label {
      color: #F35531 !important;
    }
  }

  .grey {
    label {
      color: #d8dce5 !important;
    }
  }

</style>

