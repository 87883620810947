/*
|--------------------------------------------------------------------------
| store > bread > getters
|--------------------------------------------------------------------------
|
| This file contains the getters property of current Vuex scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {keyAndLabels} from '~/js/helpers/Common';

export default {

  /**
   * Returns both List data and pagination in an object
   *
   * @return {object}
   */
  list: (state) => {
    return state.list
  },

  /**
   * Returns the List's Data in array form
   *
   * @return {array}
   */
  listData: (state) => {
    return state.list.data
  },

  /**
   * Returns the List's Pagination in object form
   *
   * @return {object}
   */
  listPagination: (state) => {
    return state.list.pagination
  },

  /**
   * Returns the for the loading "state" of the list.
   *
   * @return {boolean}
   */
  listLoading: (state) => {
    return state.list.loading;
  },

  /**
   * Returns the Item's Data
   *
   * @return {*}
   */
  itemData: (state) => {
    return state.item.data
  },

  /**
   * Returns the for the loading "state" of the item.
   *
   * @return {boolean}
   */
  itemLoading: (state) => {
    return state.item.loading;
  },

  /**
   * Returns listAsOptions array
   *
   * @return {object}
   */
  listAsOptions: (state) => {
    return state.listAsOptions;
  },

  /**
   * Returns listAsOptions array in paired format
   *
   * @return {object}
   */
  listAsOptionsPaired: (state) => {
    let mapper = {key: 'id', label: 'name'};
    return keyAndLabels(state.listAsOptions, mapper);
  }

}