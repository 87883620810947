<template>
    <div class="app-list languages-list">
        <el-row>
            <el-col :span="2" :offset="20">
                <router-link tag="button" to="/languages/create" @click.stop.prevent title="Create" class="el-button el-button--success">
                    <i class="fa fa-plus"></i> Create
                </router-link>
            </el-col>
        </el-row>

        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="success" title="Edit">
                    <span class="fa fa-pencil"></span>
                </el-button>
                <el-button class="circle" type="danger" title="Delete">
                    <span class="fa fa-trash"></span>
                </el-button>
            </template>
        </base-list-table>
    </div> <!-- /.app-list languages-list -->
</template>

<script>


        export default {

        /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                listCols: [
                    {
                        label: 'Name',
                        prop: 'name',
                        width: '',
                        className: 'name-col',
                    },
                    {
                        label: 'Dialects',
                        prop: 'dialects',
                        width: '',
                        className: 'dialects-col',
                    },
                    {
                        label: 'Related Languages',
                        prop: 'related_languages',
                        width: '',
                        className: 'related_languages-col',
                    }
                ],
                listData: [
                    {id:1, name: 'Albanska',dialects: '', related_languages: ''},
                    {id:2, name: 'Amhariska',dialects:'Algeriet/Marocko/Tunisien, Irak/Libanon/Syrien/Egypten/Jordanien, Sudan, Somalisk', related_languages: ''},
                    {id:3, name: 'arabiska',dialects:'', related_languages: ''},
                ]
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
        computed: {


        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
        methods: {}, // End of Component > methods


        /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {}, // End of Component > mounted

    }
</script>