

import APICaller from "../../helpers/APICaller";
import {extendObjParams, mapEndpoint, sd} from "../../helpers/Common";
import { gep } from "../../helpers/Vuex";
import {Notification} from 'element-ui';

const state ={
  blacklisted_users:[{
    user:{},
    customer:{}
  }]
}
const getters ={
  getBlacklistedUsers:(state) => state.blacklisted_users
}
const mutations ={
  setBlacklistedUsers(state,payload)  {
    state.blacklisted_users = payload
  }
}
const actions ={

  deleteBlackListedUser({context}, payload) {
    return APICaller({
      method:'delete',
      endpoint:gep(`blacklisted-users/${payload}`,'v3'),
    }).then(r =>{
      if(r.status == 202) {

        Notification.success({
          title: 'Success',
          message: 'User removed successfully'
        })
        return r
      }


    }).catch(e =>{
      return e
    })
  },


  fetchBlacklistedUsers({commit},params){

    return APICaller({
      method:'get',
      endpoint:gep('blacklisted-users','v3'),
      params
    }).then(r =>{
      commit('setBlacklistedUsers',r.data.data.blacklisted_users)
      return r

    }).catch(e =>{
      return e
    })
  }
}
export default {
  namespaced:true,
  state,actions,mutations,getters
}
