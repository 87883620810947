
let kpiSettings = {
    name: 'kpi',
    value:
    {
        feedback: '',
        customer_feedback: '',
        late_cancellation: '',
        inactive_translator: '',
        translator_preferences: ''
    }

}


export default {

    composeFresh: _.cloneDeep(kpiSettings),
    compose: _.cloneDeep(kpiSettings),

}