<template>
    <div class="app-fields financial-emergency-booking-fields">

        <!-- --------------------------------------------- -->
        <!-- Emergency Booking Section -->
        <!-- --------------------------------------------- -->

        <!-- Charge for Emergency Booking? -->
        <el-form-item :id="genContainerId('pay_emergency_fee',formElId)"
                      :label="$t('charge_emergency_booking')"
                      class="label-fluid">

            <el-switch :id="genFieldId('pay_emergency_fee',formElId)"
                       v-model="iValue.pay_emergency_fee" />
        </el-form-item>

        <template v-if="iValue.pay_emergency_fee">

            <!-- Emergency Minutes -->
            <el-form-item :id="genContainerId('pay_emergency_fee',formElId)"
                          :label="$t('emergency_minutes')"
                          class="label-fluid">

                <el-input :id="genFieldId('pay_emergency_fee',formElId)"
                          v-model="iValue.emergency_minutes" />
            </el-form-item>

            <!-- Emergency Fees -->
            <el-form-item v-for="bto in bookingTypeOpts"
                          :key="`booking_type_fee_${bto}`"
                          :id="genContainerId(`booking_type_fee_${bto}`,formElId)"
                          class="label-fluid">
                <template slot="label">
                    <span>{{$t('emergency_fee_for')}} </span>
                    <span class="app-badge badge-success">{{ bto }}</span>
                </template>

                <el-input :id="genFieldId(`booking_type_fee_${bto}`,formElId)"
                          v-model="iValue.emergency_fees[bto].fee" />
            </el-form-item>

        </template>

        <!-- --------------------------------------------- -->
        <!-- Rush Booking Fee Section -->
        <!-- --------------------------------------------- -->

        <!-- Charge for Rush Booking? -->
        <el-form-item :id="genContainerId('pay_rush_booking_fee',formElId)"
                      :label="$t('charge_rush_booking')" class="label-fluid">

            <el-switch :id="genFieldId('pay_rush_booking_fee',formElId)"
                       v-model="iValue.pay_rush_booking_fee" />
        </el-form-item>

        <template v-if="iValue.pay_rush_booking_fee">

            <template v-for="bto in bookingTypeOpts">

                <!-- Rush Booking Fee -->
                <el-form-item :key="`rush_booking_fee_${bto}_fee`"
                              :id="genContainerId(`rush_booking_fee_${bto}_fee`,formElId)"
                              class="label-fluid">
                    <template slot="label">
                        <span>{{$t('rush_booking_fee_for')}} </span>
                        <span class="app-badge badge-success">{{ bto }}</span>
                    </template>

                    <el-input :id="genFieldId(`rush_booking_fee_${bto}_fee`,formElId)"
                              v-model="iValue.rush_booking_fees[bto].fee" />
                </el-form-item>

                <!-- Rush Booking Minutes -->
                <el-form-item :key="`rush_booking_fee_${bto}_minutes`"
                              :id="genContainerId(`rush_booking_fee_${bto}_minutes`,formElId)"
                              class="label-fluid">
                    <template slot="label">
                        <span>{{$t('rush_booking_minutes_for')}} </span>
                        <span class="app-badge badge-success">{{ bto }}</span>
                    </template>

                    <el-input :id="genFieldId(`rush_booking_fee_${bto}_minutes`,formElId)"
                              v-model="iValue.rush_booking_fees[bto].minutes" />
                </el-form-item>

            </template>

        </template>

    </div> <!-- /.app-fields financial-emergency-booking-fields -->
</template>

<script>
  import constants from '~/js/store/financial/settings/shared/props/constants';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      formElId: {
        type: String,
        default: 'financial_emergency_booking_fields',
      },

      value: {
        type: Object,
        default () { return {}; },
      },

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {};
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () { return this.value; },
        set (v) { this.$emit('input', v); },
      },

      /**
       * Returns list of options for booking types.
       * @return {array}
       */
      bookingTypeOpts: () => constants.BOOKING_TYPES,

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {}, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
    }, // End of Component > mounted

  } // End of export default
</script>

<style scoped>

</style>
