<template>
    <div class="app-form text-translation-booking-form">
        <h3>{{$t('')}}</h3>
        <el-form :model="form" :id="formElId" :ref="formElId">
            <el-form-item :id="genContainerId('file',formElId)"
                        label="Review Document" class="label-medium">
                <span class="fa fa-paperclip"> attachment.txt</span>
            </el-form-item>
            
            <hr>
            <h4>Cost Per User</h4>
            <el-form-item :id="genContainerId('cost_per_user',formElId)"
                        label="kr" class="label-short">
                <el-input :id="genFieldId('cost_per_user',formElId)"
                 v-model="form.cost_per_user"/>
                </el-input>
            </el-form-item>
            
            <h4>Salary Per Translator</h4>
            <el-form-item :id="genContainerId('salary_per_translator',formElId)"
                        label="kr" class="label-short">
                <el-input :id="genFieldId('salary_per_translator',formElId)"
                 v-model="form.salary_per_translator"/>
                </el-input>
            </el-form-item>
            <hr>

            <h4>Options</h4>
             <el-form-item :id="genContainerId('status',formElId)"
                        label="" class="label-short">
                        <ul style="list-style: none;">
                            <li><el-radio v-model="form.status" label="1">Send for approval of customer</el-radio></li>
                            <li><el-radio v-model="form.status" label="2">Customer has already approved</el-radio> </li>
                            <li><el-radio v-model="form.status" label="3">Deadline specified by customer</el-radio> </li>
                            <li><el-radio v-model="form.status" label="4">Deadline to send to translator to submit job</el-radio></li>
                        </ul>
                </el-form-item>
            <hr>
            
            <h4>Translators with Matching Bookings</h4>
            <el-form-item label="">
                <el-select v-model="form.translator_id" multiple filterable placeholder="Select" :id="genFieldId('translator_id',formElId)">
                    <el-option
                    v-for="translator in translators"
                    :key="translator.id"
                    :label="translator.name"
                    :value="translator.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <h4>Send job to translator</h4>
            <el-form-item label="">
                <el-input v-model="form.email" placeholder="Translators email"></el-input>
            </el-form-item>
            <el-form-item label=""></el-form-item>
            
            <el-form-item :id="genContainerId('save_btn',formElId)">
                <el-button :id="genFieldId('save_btn',formElId)" type="primary">
                    Save
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {genFieldId, genContainerId} from "../../../../js/helpers/Html";
    export default {
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                formElId: 'text-translation-booking-form',
                form: {
                    cost_per_user: '',
                    salary_per_translator: '',
                    status:'',
                    translator_id: [1],
                    email: ''

                },
                translators: [
                    {id:1, name: 'translator 1'},
                    {id:2, name: 'translator 2'},
                    {id:3, name: 'translator 3'},
                    {id:4, name: 'translator 4'},
                    {id:5, name: 'translator 5'},
                ]
            };
        },// End of Component > data
    }// End of export default

</script>