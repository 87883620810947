<template>
  <el-dialog
  :title="$t('penalty')"
  :visible.sync="iVisible"
  :modal-append-to-body="false"
  :append-to-body="true"
  custom-class="custom-modal"
  width='60%'
  v-loading="loading">
<el-form>
    <financial-additional-shared-fields
      v-model="salaryForm"
      :form-el-id="salaryFormElId"
      :booking="booking"
      target="salary"
      :enable-salary-type="true"
      :edit-mode.sync="salaryEditMode"
      :visible.sync="iVisible"
      @add="handleAdd"
      @update="handleUpdate"
      @reset="handleReset"/>
</el-form>
  </el-dialog>
</template>

<script>

import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
export default {
 props: {
  visible: {
      type: Boolean,
      default: false
  },
  booking:{
    default: {}
  },
  feedback:{
    default:{}
  }
 },
 data(){
   return {
    salaryFormElId: 'salary_financial_adjustment_form',
    loading: false,
    salaryEditMode: false,
    salaryEditId: 0,
   }
 },
 computed:{
   ...mapState('financialAdditionalSalary', {
    salaryForm: 'compose'
  }),
  iVisible: {
    get() {
        return this.visible
    },
    set(v) {
        this.$emit('update:visible', v);
    }
  },
   iBooking(){
      return this.booking
    },
    iFeedback(){
      return this.feedback
    }

},
  methods:{
    ...mapActions({
      // Salary
      salaryLoadList: 'financialAdditionalSalary/browse',
      salaryReadItem: 'financialAdditionalSalary/read',
      salaryEditItem: 'financialAdditionalSalary/edit',
      salaryAddItem: 'financialAdditionalSalary/add',
      salaryDeleteItem: 'financialAdditionalSalary/delete',

      storeFeedbackIssue:'feedback/storeFeedbackIssue'

    }),
    ...mapMutations({
      // Salary
      salarySetTargetBooking: 'financialAdditionalSalary/setTargetBooking',
      salaryResetForm: 'financialAdditionalSalary/resetCompose'
    }),
    /**
     * Handler when the Update/Save button was clicked during Edit mode.
     * @return {void}
     */
    handleUpdate(target) {
      const id = this[`${target}EditId`];

      this[`${target}EditItem`]({id}).then(() => {

        this[`${target}EditMode`] = false;
        this[`${target}ResetForm`]();
      });
    },
    /**
     * Handler when the Reset button was clicked during Edit mode.
     * @return {void}
     */
    handleReset(target) {
      this[`${target}EditMode`] = false;
      this[`${target}ResetForm`]();
    },

    /**
     * Handler when the add event was broadcasted from the Child Form component.
     *
     * @param {string} target - the target entity between "invoice" & "salary"
     * @return {void}
     */
    handleAdd(target) {

      this.salaryForm.translator_id = this.iBooking.assigned_translator.id
      this.salarySetTargetBooking(this.iBooking.id);
      if (!this.isValidforSalary(target)) return;

      this[`${target}AddItem`]().then((r) => {
        this[`${target}ResetForm`]();

        let data = {
          comment: `charged penalty to translator with amount ${r.data.data.salaries_additional_item.price} kr`,
          flag_id: 6,
          feedback_id: this.iFeedback.id,
          price:r.data.data.salaries_additional_item.price
        }
        this.$emit('update:visible', false);
        this.$emit('updateIssues',data)
        // this.storeFeedbackIssue(data).then(r => {
        //   this.$emit('update:visible', false);
        //   // if(r.status == 201)
        //   // this.$emit('updateIssues',r.data.data.feedback_issue)
        // })
      });
    },
    /**
     * Helper method to validate according to salary conditions.
     *
     * @param {string} target - varies between invoice || salary.
     * @return {boolean}
     */
    isValidforSalary(target) {
      let r = true;

      if (
        target === 'salary'
        && window._.isNil(this.$route.query)
        && window._.isNil(this.$route.query.translator_id)
      ) {

        this.$notify.error({
          title: 'Error',
          message: 'Translator does not exist.'
        });

        r = false;
      }

      return r;
    },


 },
 mounted(){
  //  console.log(this.iBooking)
      this.salarySetTargetBooking(this.iBooking.id);
      this.salaryForm.translator_id = this.iBooking.assigned_translator.id
 }
}
</script>

<style>

</style>
