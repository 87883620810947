<template>
  <div :class="blockCls">
    <div :class="getBem(blockCls, 'search-control')">
    <h4>{{conversationCount}} Unread Conversation</h4>
    </div>
    <div :class="getBem(blockCls, 'inbox-container')">
      <div
        :class="[(selectedRow === recipient.id) ? getBem(blockCls, 'chat-list' + ' active-chat') :  getBem(blockCls, 'chat-list')] "
        v-for="recipient in getConversationList" @click="handleClickRow(recipient)">
        <div :class="getBem(blockCls, 'chat-people')">
          <div :class="getBem(blockCls, 'chat-thumbnail')">
            <h5>{{recipient.name ? recipient.name : 'Unknown'}} <span
              :class="getBem(blockCls, 'chat-date')">{{formatDate(recipient.updated_at)}} <br> <span
              v-if="checkUnreadMessage(recipient)" class="dot"></span>
            </span>

            </h5>
            <el-row>
              <el-col :md="8">
                <p>{{recipient.to_recipient_number}}</p>
              </el-col>
              <el-col :md="12" :offset="4">
                <p>{{showBookingNo(recipient.sms_channel_bookings)}}</p>

              </el-col>
            </el-row>
          </div>

        </div>
      </div>
      <recipient-select-modal :visible.sync="showModal"></recipient-select-modal>
    </div>
  </div>


</template>

<script>

  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

  export default {
    name: "SmsCentreSideBar",
    /**
     *
     * @returns {{selectedRow: string, showModal: boolean, blockCls: string}}
     */
    data() {
      return {
        blockCls: 'SmsCentreSideBar',
        showModal: false,
        searchBy: []
      }
    },
    /**
     * Props
     */
    props: {
      handleClickConversation: {
        type: Function,
        default: () => null
      },
      currentRow: {
        type: Number,
        default: null
      }
    },
    /**
     * Computed Properties
     */
    computed: {
      ...mapGetters('smsCentre', {
        getConversationList: 'getConversationList'
      }),

      ...mapState('smsCentre', {
        selectedRow: 'selectedRow',
        conversationCount: 'conversationCount'
      }),
    },
    /**
     * Methods
     */
    methods: {
      ...mapActions('smsCentre', {
        getConversation: 'getConversationList',
        toggleUnread: 'toggleUnread',
        getConversationCount: 'getConversationCount'

      }),

      /**
       * Map Mutations
       */

      ...mapMutations('smsCentre', {
        setConversation: 'setConversation',
        setNewMessage: 'setNewMessage',
        setBookingIds: 'setBookingIds',
        setSelectedRow: 'setSelectedRow'
      }),

      /**
       * Prepare Sidebar
       */
      prepareSideBar() {
        this.getConversation();
        this.getConversationCount();
      },

      /**
       * Format date
       * @param date
       * @returns {date}
       */
      formatDate(date) {
        return moment(date).format('D-MMM')
      },


      /**
       * Click row
       * @param row
       */
      handleClickRow(row) {
        this.setSelectedRow(row.id);
        const checkUnread = this.checkUnreadMessage(row);
        if (checkUnread === true) {
          this.toggleUnread({id: row.id})
        }
        this.setConversation(row);
        this.setBookingIds(row);
      },


      /**
       * Check unread message
       * @param row
       * @returns {boolean}
       */
      checkUnreadMessage(row) {

        return !!(row && row.is_read === false);
      },

      /**
       *  @param smsChannelBookings
       * @returns String
       */
      showBookingNo(smsChannelBookings) {
        return smsChannelBookings.map((booking) => {
          return booking.id
        }).join(',')
      },


    },

    /**
     * Mounted for this component
     */
    mounted() {
      this.prepareSideBar();
    }
  }
</script>

<style scoped lang="scss">
  .SmsCentreSideBar {
    border-right: 1px solid #c4c4c4;
    cursor: pointer;

    &__chat-thumbnail h5 {
      font-size: 15px;
      color: #464646;
      margin: 0 0 8px 0;
    }

    &__chat-thumbnail h5 span {
      font-size: 13px;
      float: right;
    }

    &__chat-thumbnail p {
      font-size: 14px;
      color: #989898;
      margin: auto
    }

    .chat-thumbnail {
      float: left;
      padding: 0 0 0 15px;
      width: 88%;
    }

    &__chat-people {
      overflow: hidden;
      clear: both;
    }

    &__chat-list {
      border-bottom: 1px solid #c4c4c4;
      margin: 0;
      padding: 18px 16px 10px;
    }

    &__inbox-container {
      height: 700px;
      overflow-y: scroll;
    }

    &__chat-date {
      color: #747474;
      display: block;
      font-size: 12px;
      margin: 8px 0 0;
    }

    &__search-control {
      margin-top: 5px;
      text-align: center;
      background-color: #FAFAFA;
      border: 1px solid #c4c4c4;
    }
  }

  .active-chat {
    background: #ebebeb;
  }

  .dot {
    height: 10px;
    width: 10px;
    background-color: #3875ba;
    border-radius: 50%;
    display: inline-block;
  }

</style>
