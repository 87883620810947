<template>
    <div class="app-list booking-list">

        <custom-user-list
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false"
                actions-col-width='130'>
            <template slot="topControls">
              <div class="text-right">
                <span class="text-right">{{$t('total_records')}} : {{listPagination.total}}</span>
              </div>
            </template>
            <template slot="actionsColumn" slot-scope="scope">
                <div @contextmenu.prevent="handleDetailsNew(scope)">
                <el-button class="circle b" type="success" :title="$t('details')" @click="handleDetails(scope)" size="mini" icon="el-icon-info">
                </el-button>
                <el-button class="circle b" type="info" :title="$t('profile')" @click="handleProfile(scope)" size="mini" icon="fa fa-user">
                </el-button>
                </div>
            </template>
        </custom-user-list>
        <el-pagination
            layout="total, prev, pager, next, jumper"
            :page-size="listPagination.per_page"
            :total="listPagination.total"
            :current-page="listPagination.current_page"
            @current-change="reloadList">
        </el-pagination>

    </div> <!-- /.app-list booking-list -->
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import {populate,forcePopulate} from "~/js/helpers/Vuex"
    import {isEmpty,sd} from "../../../js/helpers/Common";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {
            vPopulatePayload:{
                type: Object,
                default(){
                    return {
                }
                }
            }
        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {

            const _this = this;

            return {

                tableElId: 'Employees_list',

                listCols: [
                    {prop: 'id', label: _this.$t('id')},
                    {prop: 'name', label: _this.$t('name')},
                    {prop: 'phone', label: _this.$t('phone')},
                    {prop: 'translator_data.salary_per_hour', label: _this.$t('salary_per_hour'),sortable: true},
                    {prop: 'translator_data.address', label: _this.$t('address')},
                    {prop: 'translator_data.bank_name', label: _this.$t('bank_name'), width: '80px'},
                    {prop: 'translator_data.time_from', label: _this.$t('start_time'), width: '', sortable: true,},
                    {prop: 'translator_data.time_end', label: _this.$t('end_time'), width: '', sortable: true,},
                    {prop: 'translator_data.created_at', label: _this.$t('created'), width: '80px', sortable: true},
                ],
                hiddenCols: ['id'],

                row: null,
                tableRowClassName:[
                {
                    field: 'flagged',
                    value: true,
                    class: 'is_flagged'
                },
                {
                    field: 'is_test',
                    value: true,
                    class: 'is_test'
                }
                ]
            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {
            ...mapGetters('translator', {
                listData: 'getEmployees',
                listPagination:'getPagination'
            }),
            populatePayload: {
                get(){
                    return this.vPopulatePayload
                },set(v){
                    this.$emit('input',v)
                }
            },
        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {
            ...mapActions('translator', ['getEmployeesList']),
            /**
             * Helper method to check wether the column is enabled in the options or not.
             *
             * @param {string} key
             *   The switch value to check for whether to enable/disable a column.
             *
             * @return {boolean}
             */
            isColEnabled(key) {
                let r = true;

                if (_.includes(this.hiddenCols, key)) {
                    r = false;
                }

                return r;

            }, // End of isColEnabled() method


            /**
             * method for handling details popups
             * @param {object} scope
             *
             * @return {void}
             */
            handleDetails(scope) {
                this.$router.push({
                  name: 'user.translator.employee.bookings.weekly',
                  param: {
                    id: scope.row.id
                  }
                });
            },
            handleProfile(scope) {
                this.$router.push({name: 'user.profile', params: {id: scope.row.id}, query: {type: scope.row.type}})
            },
            handleDetailsNew(scope) {
                let route = this.$router.resolve({name: 'booking-details', params: {id: scope.row.id}});
                window.open(route.href, '_blank');
            },
            current_change(currentPage){
             this.currentPage = currentPage;
             console.log(this.currentPage);
           },
           /**
            * Method for reloading the list.
            *
            * @param {int} n
            *   The target page number.
            *
            * @return {void}
            */
           reloadList(n, per_page) {
               let params=_.clone(this.$route.query);
               n = sd(n, sd(this.$route.query.page, 1)) // Set the page number.
               params.page = n;
               if(per_page && typeof per_page == 'number')
               {
                   params['per_page']= per_page;
               }
               this.populatePayload.page = params.page
               Object.assign(params,this.populatePayload)
               this.$router.push({query: params}) // Reflect the chosen parameters in the URL Bar
               console.log(params);
               this.getEmployeesList(params);
           }, // End of reloadList() method
        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
          let params =_.clone(this.$route.query)
          Object.assign(params,this.populatePayload)
          const opts = {
              action: 'getEmployeesList',
              data: 'listData',
              ref: 'Employee Listing',
          };
          this.reloadList(null,opts,params)
        }, // End of Component > mounted

        /*
        |--------------------------------------------------------------------------
        | Component > beforeDestroy
        |--------------------------------------------------------------------------
        */
        beforeDestroy() {
        }, // End of Component > beforeDestroy

    } // End of export default

</script>

<style>
.red-dot{
    background-color:#F56C6C !important;  height: 10px;
  width: 10px;; color: #F56C6C ;margin:auto; border-radius:50%;display: inline-block;
}
.green-dot{
    background-color:#67C23A !important; height: 10px;
  width: 10px;color: #67C23A ;margin:auto; border-radius:50%;display: inline-block;
}
.yellow-dot{
    background-color:#E6A23C !important; height: 10px;
  width: 10px;color: #E6A23C ;margin:auto; border-radius:50%;display: inline-block;
}
</style>
