<template>
    <div class="app-form" :class="getBem(blockCls)">

        <el-form :class="getBem(blockCls, 'form')">
            <div class="app-splitter three-cols">

                <!-- Entity ID Selector -->
                <el-form-item :class="getBem(blockCls, 'type-select-container')">
                    <el-select :class="getBem(blockCls, 'type-select')"
                               v-model="form.entity_type"
                               @change="handleChangeEntityTypeSelect"
                               :placeholder="$t('choose_entity_type')"
                               clearable
                               filterable>
                        <el-option v-for="v in entityTypeOpts"
                                   :key="v.key"
                                   :value="v.key"
                                   :label="v.label" />
                    </el-select>
                </el-form-item>

                <!-- Entity ID Selector -->
                <el-form-item :class="getBem(blockCls, 'id-select-container')">
                    <el-select :class="getBem(blockCls, 'id-select')"
                               :disabled="!isEntityTypeOptsValid"
                               v-model="form.entity_id"
                               :placeholder="$t('choose_entity')"
                               clearable
                               filterable>
                        <el-option v-for="v in entityIdOpts"
                                   :key="v.id"
                                   :value="v.id"
                                   :label="v.name" />
                    </el-select>
                </el-form-item>

                <el-form-item :class="getBem(blockCls, 'load-button-container')">
                    <el-button :class="getBem(blockCls, 'load-button')"
                               type="primary" size="mini"
                               @click="handleClickLoad">
                        {{$t('load')}}
                    </el-button>

                    <el-button :class="getBem(blockCls, 'reset-button')"
                               type="warning" size="mini"
                               @click="handleClickReset">
                        {{$t('reset')}}
                    </el-button>

                    <el-button :class="getBem(blockCls, 'create-button')"
                               type="success" size="mini"
                               @click="handleClickCreate">
                        {{$t('create')}}
                    </el-button>
                </el-form-item>
            </div> <!-- div splitter -->

        </el-form>

    </div> <!-- Component DIV Wrapper -->
</template>

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
  import isNil from 'lodash/isNil';
  import isEmpty from 'lodash/isEmpty';
  import assignIn from 'lodash/assignIn';
  import omit from 'lodash/omit';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-settings-entity-selector',
        templateId: '',
        isShowTemplateModal: false
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('financialPriceList', {
        form: 'entitySelectorForm',
      }),

      ...mapGetters('municipality', {
        municipalityOpts: 'listAsOptions'
      }),

      ...mapGetters('company', {
        companyOpts: 'listAsOptions'
      }),

      ...mapGetters('department', {
        departmentOpts: 'listAsOptions'
      }),

      entityIdOpts () {
        // Define necessary values.
        const type = this.form.entity_type;
        let collection = '';

        if (type !== '') {
          // If type has a selected value, then set the collection key;
          if (type === 'municipalities') collection = 'municipalityOpts';
          else if (type === 'companies') collection = 'companyOpts';
          else if (type === 'departments') collection = 'departmentOpts';

          return this[collection];
        } else {
          return [];
        }
      },

      /**
       * Returns array of entity type for options.
       *
       * @returns {Array} - Array of Objects paired by key {string} : label {string}
       */
      entityTypeOpts () {
        return [
          {key: 'municipalities', label: 'Muncipalities'},
          {key: 'companies', label: 'Companies'},
          {key: 'departments', label: 'Departments'}
        ];
      },

      /**
       * Returns boolean to determine if form.entity_type value valid.
       *
       * @returns {boolean}
       */
      isEntityTypeOptsValid () {
        return this.form.entity_type !== '';
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapMutations('financialPriceList', {
        resetPriceLists: 'resetList',
        setComposeMode: 'setComposeMode',
        resetComposeMode: 'resetComposeMode',
      }),

      ...mapActions('financialPriceList', {
        loadList: 'browse',
      }),

      ...mapActions('municipality', {
        loadMunicipalityOpts: 'browseAsOptions'
      }),

      ...mapActions('company', {
        loadCompanyOpts: 'browseAsOptions'
      }),

      ...mapActions('department', {
        loadDepartmentOpts: 'browseAsOptions'
      }),

      /**
       * Handler when the value of Entity Type Select was changed.
       *
       * @param {string} selectedValue -
       * @returns {void}
       */
      handleChangeEntityTypeSelect (selectedValue) {
        if (selectedValue !== '') {
          this.entityIdOptsLoader(selectedValue);
        }
      },

      /**
       * Helper for loading the Entity ID Options.
       *
       * @param {string} entityType - Current Entity Type Selected.
       */
      entityIdOptsLoader (entityType) {
        let loader = '';
        if (entityType === 'municipalities') loader = 'loadMunicipalityOpts';
        else if (entityType === 'companies') loader = 'loadCompanyOpts';
        else if (entityType === 'departments') loader = 'loadDepartmentOpts';

        if (
          !isNil(this.entityIdOpts)
          && isEmpty(this.entityIdOpts)
        ) {
          this[loader]();
        }
      },

      /**
       * Handler when the Load button was clicked.
       *
       * @returns {void}
       */
      handleClickLoad () {
        if (
          !isNil(this.form.entity_type) && this.form.entity_type !== ''
          && !isNil(this.form.entity_id) && this.form.entity_id !== ''
        ) {

          const payload = {
            page: 1,
            entity_type: this.form.entity_type,
            entity_id: this.form.entity_id,
          };

          // Update values in the query params.
          let oldQueries = {...this.$route.query};
          let query = assignIn(oldQueries, payload);
          query = omit(query, ['compose_mode', 'record_id']);
          this.$router.push({query});

          query.page = payload.page;
          query.entity_type = payload.entity_type;
          query.entity_id = payload.entity_id;
          this.$router.push({query});

          this.loadList(payload);
          this.resetComposeMode();
        }
      },

      /**
       * Handler when the Reset button was clicked..
       *
       * @returns {void}
       */
      handleClickReset () {
        this.form.entity_id = '';
        this.form.entity_type = '';
        this.$router.push({
          query: {
            page: '',
            entity_type: '',
            entity_id: '',
          },
        });
        this.resetPriceLists();
      },

      /**
       * Handler when the Create Button was clicked.
       *
       * @returns {void}
       */
      handleClickCreate () {
        if (
          this.form.entity_id !== ''
          && this.form.entity_type !== ''
        ) {

          this.resetComposeMode();

          setTimeout(() => {
            this.setComposeMode('add');
          }, 200);

          let query = {
            ...this.$route.query,
            compose_mode: 'add',
          };

          query = omit(query, ['record_id']);

          this.$router.push({query});
        }
      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      // Check if there is entity_type and entity_id that exist in the query param,
      // and use it to update the form.
      if (
        !isNil(this.$route.query)
        && !isNil(this.$route.query.entity_type)
        && !isNil(this.$route.query.entity_id)
      ) {
        this.form.entity_type = this.$route.query.entity_type;
        this.form.entity_id = parseInt(this.$route.query.entity_id);

        this.entityIdOptsLoader(this.form.entity_type);
      }
    }
  }
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
    .el-button {
        min-width: auto;
        font-size: 12px;
        margin: 2px 1px;
        padding: 7px 5px;
    }

    .app-splitter.three-cols, .el-form-item {
        margin-bottom: 0;
    }

</style>
