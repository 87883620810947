/*
|--------------------------------------------------------------------------
| Financial > Shared > methods > resetSettingsPayload
|--------------------------------------------------------------------------
| Partial file as a reusable method for it's purpose.
*/

'use strict';

export default function (state) {
  state.settingsPayload = {};
}
