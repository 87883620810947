
'use strict';

import APICaller from '~/js/helpers/APICaller';

import { gep } from "~/js/helpers/Vuex";
import { extendObjParams } from "~/js/helpers/Common";
import i18n from '~/js/i18n';
import { Notification } from "element-ui";

let apiPrefix = 'v3'
export default {
  fetchList: ({ commit }, payload) => {
    let params = {
      include: 'files,customer,translator'
    }

    commit('setLoading', true);
    return APICaller({
      method: 'get',
      params,
      endpoint: gep('text-translations', apiPrefix),
    }).then((response) => {
      console.log(response.data)

      commit('setList', response.data)
    }).catch(e => {
      console.log(e.response)
      return e
    })
  },
  fetchTextTranslation: ({ commit }, payload) => {
    let params = {
      // include:'files,customer,translator,potential_translators,comments.user',
      // include:'files,customer,translator,potential_translators',
      include: 'files,customer.customer_data,translator,potential_translators.user,comments.user',
    }
    commit('setLoading', true);
    return APICaller({
      method: 'get',
      params,
      endpoint: gep(`text-translations/${payload.id}`, apiPrefix),
    }).then((response) => {
      console.log(response.data)

      commit('setTextTranslation', response.data)

      commit('setLoading', false);
    }).catch(e => {
      console.log(e.response)

      commit('setLoading', false);
      return e
    })
  },
  browse: (context, payload) => {

    context.commit('setLoading', true)
    let target = context.state.api.browse;
    // Set the target endpoint.
    let method = target.method;
    let endpoint = target.path;
    let params = extendObjParams(target.params, payload);

    return APICaller({ method, endpoint, params })
      .then((r) => {
        context.commit('setList', r.data);

        context.commit('setLoading', false)
      });

  },

  postSubmitCost: (context, payload) => {

    context.commit('setLoading', true)
    return APICaller({
      method: 'put',
      endpoint: gep(`text-translations/${payload.id}/submit-cost?include=files,customer.customer_data,translator,potential_translators.user,comments.user`, apiPrefix),
      data: payload,
      isDataRaw: true
    }).then((r) => {
      context.commit('setLoading', false)

      context.dispatch('fetchTextTranslation', { id: payload.id })
      Notification.success({
        title: i18n.t('sucesss'),
        message: 'Cost Submited',
      });
    }).catch(error => {

      context.commit('setLoading', false)
      Notification.error({
        title: i18n.t('error'),
        message: 'something happen',
      });
    })
  },
  postSubmitComplete: (context, payload) => {

    context.commit('setLoading', true)
    return APICaller({
      method: 'post',
      endpoint: gep(`text-translations/${payload.id}/complete?include=files,customer.customer_data,translator,potential_translators.user,comments.user`, apiPrefix),
    }).then((r) => {
      context.commit('setLoading', false)

      context.dispatch('fetchTextTranslation', { id: payload.id })
      Notification.success({
        title: i18n.t('sucesss'),
        message: 'Text Translation Completed',
      });
    }).catch(error => {

      context.commit('setLoading', false)
      Notification.error({
        title: i18n.t('error'),
        message: error.request.response.data,
      });
    })
  },
  acceptFile: (context, payload) => {

    context.commit('setLoading', true)
    return APICaller({
      method: 'post',
      endpoint: gep(`text-translations/${payload.id}/accept/${payload.file_id}?include=files,customer.customer_data,translator,potential_translators.user,comments.user`, apiPrefix),
    }).then(r => {
      context.commit('setLoading', false)

      // context.dispatch('fetchTextTranslation', { id: payload.id })
      Notification.success({
        title: i18n.t('sucesss'),
        message: 'Text Translation Accepted for review',
      });
      // window.location.reload()
    }).catch(error => {

      context.commit('setLoading', false)
      Notification.error({
        title: i18n.t('error'),
        message: error.request.response.data,
      });
    })
  },
  rejectFile: (context, payload) => {

    context.commit('setLoading', true)
    return APICaller({
      method: 'post',
      endpoint: gep(`text-translations/${payload.id}/reject/${payload.file_id}?include=files,customer.customer_data,translator,potential_translators.user,comments.user`, apiPrefix),
    }).then(r => {
      context.commit('setLoading', false)

      Notification.success({
        title: i18n.t('sucesss'),
        message: 'Text Translation File Rejected',
      });
      return r;
    }).catch(error => {

      context.commit('setLoading', false)
      Notification.error({

        title: i18n.t('error'),
        message: error.request.response.data,
      });
      return error;
    })
  },
  postCreate: (context, payload) => {

    let token = localStorage.getItem("app_access_token");
    let url = app_api + gep('text-translations', apiPrefix)
    return axios.post(url,
      payload,
      {
        headers:
        {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token
        },
      }
    )

  },
  postSubmitFile(context, payload) {
    context.commit('setLoading', true)
    let token = localStorage.getItem("app_access_token");
    let url = app_api + gep(`text-translations/${payload.id}/translated-file`, apiPrefix)
    return axios.post(url,
      payload,
      {
        headers:
        {
          // 'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token
        },
      }
    ).then((r) => {

      // context.commit('setTextTranslation',r.data)

      // context.dispatch('fetchTextTranslation', { id: payload.id })
      context.commit('setLoading', false)
      return r
    }).catch(err => {
      context.commit('setLoading', false)
      Notification.error({
        title: i18n.t('error'),
        message: 'failed when upload the file',
      });
      return err
    })

  },

  postComment(context, payload) {
    context.commit('setLoading', true)
    return APICaller({
      method: 'post',
      endpoint: gep(`text-translations-comments`, apiPrefix),
      data: payload,
      isDataRaw: true
    }).then((r) => {
      context.dispatch('fetchTextTranslation', { id: payload.text_translation_id })

      console.log(r)
      Notification.success({
        title: i18n.t('sucesss'),
        message: 'Comment added',
      });
    }).catch(err => {
      console.log(err)
      Notification.error({
        title: i18n.t('error'),
        message: 'Add Comment Failed',
      });
    })
  },
  deleteComment(context, payload) {
    context.commit('setLoading', true)
    return APICaller({
      method: 'delete',
      endpoint: gep(`text-translations-comments/${payload.id}`, apiPrefix),
    }).then((r) => {
      context.dispatch('fetchTextTranslation', { id: payload.text_translation_id })

      console.log(r)
      Notification.success({
        title: i18n.t('sucesss'),
        message: 'Comment added',
      });
    }).catch(err => {
      console.log(err)
      Notification.error({
        title: i18n.t('error'),
        message: 'Add Comment Failed',
      });
    })
  },

  putUpdate: (context, payload) => {

    context.commit('setLoading', true)
    return APICaller({
      method: 'put',
      endpoint: gep(`text-translations/${payload.id}?include=files,customer.customer_data,translator,potential_translators.user,comments.user`, apiPrefix),
      data: payload,
      isDataRaw: true
    }).then((r) => {
      context.commit('setLoading', false)

      context.dispatch('fetchTextTranslation', { id: payload.id })
      Notification.success({
        title: i18n.t('sucesss'),
        message: 'Update success',
      });
    }).catch(error => {

      context.commit('setLoading', false)
      Notification.error({
        title: i18n.t('error'),
        message: 'something happen',
      });
    })
  },
  postChangeTranslator: (context, payload) => {

    context.commit('setLoading', true)
    return APICaller({
      method: 'put',
      endpoint: gep(`text-translations/${payload.id}/change-translator?include=files,customer.customer_data,translator,potential_translators.user,comments.user`, apiPrefix),
      data: payload,
      isDataRaw: true
    }).then((r) => {
      context.commit('setLoading', false)

      context.dispatch('fetchTextTranslation', { id: payload.id })
      Notification.success({
        title: i18n.t('sucesss'),
        message: 'Translator changed',
      });
    }).catch(error => {

      context.commit('setLoading', false)
      Notification.error({
        title: i18n.t('error'),
        message: 'something happen',
      });
    })
  },

  downloadFile(context, payload) {
    return APICaller({
      method: 'get',
      endpoint: gep(`storage/get?${payload.params}`, apiPrefix),
      isBlob: true
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement('a');

      link.href = downloadUrl;

      link.setAttribute('download', payload.filename); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();


    })
  },

  updateTaWorkingHours(context, payload) {
    context.commit('setLoading', true)
    return APICaller({
      method: 'put',
      endpoint: gep(`text-translations/${payload.id}?include=files,customer.customer_data,translator,potential_translators.user,comments.user`, apiPrefix),
      data: payload,
    }).then((r) => {
      context.commit('setLoading', false)

      Notification.success({
        title: i18n.t('sucesss'),
        message: 'Update Working hours success',
      });
      return r;
    }).catch(error => {

      context.commit('setLoading', false)
      Notification.error({
        title: i18n.t('error'),
        message: 'something happen',
      });
      return error;
    })
  },

  sendPriceToCustomer(context, payload) {
    return APICaller({
      method: 'post',
      endpoint: gep(`text-translations/${payload.id}/send-price`, apiPrefix),
      data: payload,
      isDataRaw: true
    }).then(r => {

      Notification.success({
        title: i18n.t('sucesss'),
        message: i18n.t('submit_cost_to_customer_success'),
      });

    }).catch(err => {
      context.commit('setLoading', false)
      Notification.error({
        title: i18n.t('error'),
        message: 'something happen',
      });
      return error;
    })
  }

}
