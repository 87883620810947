/*
|--------------------------------------------------------------------------
| Store > Financial > Preview > Props
|--------------------------------------------------------------------------
| File to define the properties that will be used for the forms.
*/

'use strict';

export const preview = {
  booking_id: '',
  description: '',
  id: '',
  invoice_id: '',
  name: '',
  price: '',
  quantity: '',
  subtotal: '',
  unit: '',
  verified: false
}