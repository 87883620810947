/*
|--------------------------------------------------------------------------
| Store > translator-unavailable-times > Actions
|--------------------------------------------------------------------------
|
| This file contains the actions property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import APICaller from '~/js/helpers/APICaller';

import API from '~/js/constants/api';
import {extendObjParams, isEmpty, sd,mapEndpoint} from "~/js/helpers/Common";
import i18n from '../../i18n';
import {Notification} from "element-ui";
import { notifSuccess, notifError } from '~/js/helpers/Notification';

const endpoint=API.TRANSLATORS_UNAVAILABLE_TIMES;
export default {
  /**
   * Request unavailable list
   *
   * @param {object} state
   *  : current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {void}
  */
  getUnavailablesList: ({commit}, payload) => {

    return APICaller({
      method: 'get',
      endpoint: endpoint,
      params:{
        page: payload.page,
        per_page: 10,
        'filter[translator_id]':payload.user_translator_id,
        include:'translator_unvailable_booking_types',
      }
    }).then((response) => {
        commit('setUnavailables', response.data)
        return response
      }
    ).catch( (e) => {

      console.log(e)
      return e
    })
  },


  /**
   * Get the unavailable data from the API.
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  getUnavailable (context , payload = {}){
    const endpoint_with_id = `${API.TRANSLATORS_UNAVAILABLE_TIMES}/${payload.id}`;
    return APICaller({
      method: 'get',
      endpoint: endpoint_with_id,
      params:{
          include:'translator_unvailable_booking_types',
      }
    }).then((response) => {
      let data = response.data.data.translator_unvailable_time
      return response
    }).catch(error => {

      return error
    })
  },
  createUnavailable(context, payload) {

    return new Promise((resolve, reject) => {
        return APICaller({
          method: 'post',
          endpoint: endpoint,
          data: payload,
          isDataRaw: true
        }).then((response) => {
          let data = response.data
          Notification.success({
            title: 'Created',
            message: 'Add unavailable time successfully',
          });
          resolve(response);
          return response
        }).catch((e) => {
          notifError({ message: e });
          reject(e);
        })
    });
   
 },
deleteUnavailable(context, payload) {
 const endpoint_with_id = `${API.TRANSLATORS_UNAVAILABLE_TIMES}/${payload}`;
 return APICaller({
   method: 'delete',
   endpoint: endpoint_with_id,
 }).then((response) => {
   let data = response.data
   Notification.success({
     title: 'Deleted',
     message: 'Deleted unavailable time successfully',
   });
   return response
 }).catch(error => {

   return error
 })
},
editUnavailable(context, payload) {
  const endpoint_with_id = `${API.TRANSLATORS_UNAVAILABLE_TIMES}/${payload.id}`;
  return new Promise((resolve, reject) => {
    return APICaller({
      method: 'put',
      endpoint: endpoint_with_id,
      data: payload,
      isDataRaw: true
    }).then((response) => {
      let data = response.data
      Notification.success({
        title: 'Edited',
        message: 'Edited unavailable time successfully',
      });
      resolve(response);
      return response
    }).catch((e) => {
      notifError({ message: e });
      reject(e);
    })
  });
  
},
}
