<template>
    <div :class="getBem(blockCls)"
         class="app-container"
         v-loading="isLoadingList">

        <template v-if="isValidListData">
            <el-table :data="listData" :class="getBem(blockCls, 'table')">
                <el-table-column :label="$t('id')" prop="id" width="50px" />
                <el-table-column :label="$t('name')" prop="name" />

                <el-table-column :label="$t('actions')">
                    <template #default="{row}">
                        <el-button type="primary" class="extra-mini"
                                   @click="handleClickEdit(row)">
                            {{$t('edit')}}
                        </el-button>

                        <el-button type="danger" class="extra-mini"
                                   @click="handleClickDelete(row)">
                            {{$t('delete')}}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <template v-else>
            <h3 class="issue-warn" style="width: 100%; text-align: center;">
                {{$t('no_price_list_loaded')}}
            </h3>
        </template>

        <el-pagination v-if="isValidListPagination"
                       layout="prev, pager, next"
                       :page-size="listPagination.per_page"
                       :total="listPagination.total"
                       :current-page="listPagination.current_page"
                       @current-change="changePage">
        </el-pagination>

    </div> <!-- Component DIV Wrapper -->
</template>

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex';
  import isNil from 'lodash/isNil';
  import isEmpty from 'lodash/isEmpty';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-settings-list'
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters('financialPriceList', [
        'entitySelectorForm',
        'listData',
        'listPagination',
        'isLoadingList',
      ]),

      /**
       * Returns boolean to determine whether
       * the list data is valid to be displayed.
       *
       * @return {Boolean}
       */
      isValidListData () {
        return !window._.isEmpty(this.listData);
      },

      /**
       * Returns boolean whether if the list pagination is valid.
       *
       * @returns {Boolean}
       */
      isValidListPagination () {
        return !isEmpty(this.listPagination)
          && this.listPagination.per_page < this.listPagination.total;
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapMutations('financialPriceList', [
        'setComposeMode',
        'resetComposeMode',
        'setEditItemId',
      ]),

      ...mapActions('financialPriceList', {
        reloadList: 'uiReloadList',
        deleteItem: 'delete',
      }),

      /**
       * Handler for list page change event.
       *
       * @param {Integer} page
       * @returns {void}
       */
      changePage (page) {
        this.reloadList({page});
      },

      /**
       * Handler when the Edit table button was clicked.
       *
       * @param {object} setting - Current setting target.
       * @return {void}
       */
      handleClickEdit (setting) {
        const id = setting.id;

        this.resetComposeMode();

        setTimeout(() => {
          this.setComposeMode('edit');
        }, 200);

        const query = {
          ...this.$route.query,
          compose_mode: 'edit',
          record_id: id,
        };

        this.$router.push({query});
      },

      /**
       * @param {object} setting
       * @return {void}
       */
      handleClickDelete (setting) {
        this.$confirm('Are you sure you want to delete this?', 'Warning', {
          confirmButtonText: 'YES',
          cancelButtonText: 'NO',
          type: 'warning'

        }).then(() => {

          const id = setting.id;

          this.deleteItem({id}).then(() => {
            this.$notify.success('Deleted Successfully');
            this.reloadList();
          });

        }).catch(() => {})
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      // Check if there is entity_type and entity_id that exist in the query param,
      // and use it to fetch the price lists.
      if (
        !isNil(this.$route.query)
        && !isNil(this.$route.query.entity_type)
        && !isNil(this.$route.query.entity_id)
      ) {
        this.reloadList();
      }
    }

  }
</script>
