<template>
  <el-autocomplete
    v-model="iValue"
    :class="getBem(blockClass)"
    :popper-class="getBem(blockClass, 'popper')"
    :highlight-first-item="true"
    :trigger-on-focus="false"
    :debounce="750"
    :fetch-suggestions="handleSuggestions"
    @select="handleSelect"
  ></el-autocomplete>
</template>

<!--suppress JSUnresolvedVariable -->
<script>
  import axios from 'axios';
  import forEach from 'lodash/forEach';
  import uniqBy from 'lodash/uniqBy';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      // Components v-model.
      value: {
        type: String,
        default: ''
      },

      // Placeholder label for the input field.
      placeholder: {
        type: String,
        default: ''
      },

      // Container where the value to be generated
      // after choosing an autocomplete suggestion.
      city: {
        type: String,
        default: ''
      },

      // Container where the value to be generated
      // after choosing an autocomplete suggestion.
      postalCode: {
        type: String,
        default: ''
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockClass: 'booking-here-address-field',
        suggestUrl: 'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json',
        geocodeUrl: 'https://geocoder.ls.hereapi.com/6.2/geocode.json',
        maxResults: 5,
        apiKey: 'kWz4xJpmkmISKutx3A_vxM867jcP1poePJRIEi5Kv1U',
        optsContainer: [],
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for this.value.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * @param {string} q - query string.
       * @param {Function} cb - callback method.
       * @returns {array}
       */
      handleSuggestions (q, cb) {
        if (q != null && q !== '') {

          const queryParams = [
            `query=${encodeURIComponent(q)}`,
            // `beginHighlight=${encodeURIComponent('<mark>')}`,
            // `endHighlight=${encodeURIComponent('</mark>')}`,
            `maxresults=${this.maxResults}`,
            `apiKey=${this.apiKey}`,
            'resultType=address',
            'country=SWE'

          ].join('&');

          const url = this.suggestUrl + '?' + queryParams;
          const method = 'GET';

          const call = axios.create({url, method});
          delete call.defaults.headers.common["Authorization"];

          // noinspection JSCheckFunctionSignatures
          call()
            .then((r) => {
              const suggestions = r.data.suggestions;

              if (suggestions.length > 0) {
                let result = [];

                forEach(suggestions, (s) => {
                  const a = s.address;
                  const matchLevel = s.matchLevel;
                  let label;

                  if (matchLevel === 'street') {
                    label = `${a.street}, ${a.city}, ${a.country}, ${a.postalCode}`;

                  } else if (matchLevel === 'houseNumber') {
                    label = `${a.street} ${a.houseNumber}, ${a.postalCode}, ${a.city}, ${a.country}`;

                  } else if (matchLevel === 'city') {
                    label = `${a.city}, ${a.country}, ${a.postalCode}`;

                  } else if (matchLevel === 'county') {
                    label = `${a.county}, ${a.country}, ${a.postalCode}`;

                  } else if (matchLevel === 'state') {
                    label = `${a.country}, ${a.state}`;

                  } else {
                    label = s.label;
                  }

                  // noinspection JSUnresolvedFunction
                  result.push({
                    value: label,
                    data: s
                  });

                  // noinspection JSCheckFunctionSignatures
                  this.optsContainer = uniqBy(result, 'value');
                });

              } else {
                this.optsContainer = [];
              }
              cb(this.optsContainer);
            })
            .catch((e) => {
              cb([]);
              console.error(e);
              this.$notify({
                type: 'error',
                title: 'Error',
                message: e.message
              });
            });
        }
      },

      /**
       * @param {string} locationId
       * @returns {Promise}
       */
      async getLocationDetails (locationId) {
        const queryParams = [
          `apiKey=${this.apiKey}`,
          `locationid=${locationId}`,
          'jsonattributes=1',
          'gen=9'

        ].join('&');

        const url = this.geocodeUrl + '?' + queryParams;
        const method = 'GET';

        const call = axios.create({url, method});
        delete call.defaults.headers.common["Authorization"];

        try {
          // noinspection JSCheckFunctionSignatures
          const r = await call();
          return r.data.response.view[0].result[0].location;

        } catch (e) {
          throw e;
        }
      },

      /**
       * @param {object} selected
       * @returns {void}
       */
      handleSelect (selected) {
        this.getLocationDetails(selected.data.locationId)
            .then((location) => {
              const city = location.address.city || location.address.county;
              const postalCode = location.address.postalCode;
              this.$emit('update:city', city);
              this.$emit('update:postalCode', postalCode);
              this.$emit('change', location);

              // Added updateAddress emitter to match the format of the components that used google-maps-address
              this.$emit('updateAddress', {
                town: city,
                postal_code: postalCode,
                coord: location.displayPosition.latitude + ',' + location.displayPosition.longitude,
                country: location.address.country
              });
            });
      },
    }
  };
</script>

<style lang="scss">
  .booking-here-address-field {
    width: 100%;

    &__popper {
      .el-select-dropdown__item {
        font-size: 11px;
      }
    }
  }
</style>

