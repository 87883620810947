/*
|--------------------------------------------------------------------------
| Html Helpers
|--------------------------------------------------------------------------
|
| This is a helper file which contains all Html DOM Element related helpers
| that will possibily be called multiple times in different files.
|
| Kind put your methods in here and then just use them on Vuex or Components
| for cleaner implementation.
|
*/

/**
 * Helper method to generate a container css id.
 *
 * @param {string} key
 * @param {string} parent
 *
 * @return {string}
 */
export function genContainerId(key, parent) {
    parent = _.isUndefined(parent) ? '' : parent + '_';
    return parent + key + '_container';
}

/**
 * Helper method to generate a field css id.
 *
 * @param {string} key
 * @param {string} parent
 *
 * @return {string}
 */
export function genFieldId(key, parent) {
    parent = _.isUndefined(parent) ? '' : parent + '_';
    return parent + key;
}

/**
 * Helper method to generate a table column css id.
 *
 * @param {string} parent
 * @param {string} key
 * @param {string} [id=''] - This is just another identifer
 *                           (via any object id you want to attach)
 *
 * @return {string}
 */
export function genColId(parent, key, id) {
    id = _.isUndefined(parent) ? '' : '_' + id;
    parent = _.isUndefined(parent) ? '' : parent;
    return parent + '_' + key + '_col' + id;
}

export function  ucFirst(string) 
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}


/**
 * Generates a class reference name using BEM convention.
 *
 * @param {string} block - the block reference
 * @param {string} elem - the element reference
 * @param {string} modifier - the modifier refernece
 * @returns {string}
 */
export function genBem (block, elem = '', modifier = '') {

  // Make sure parent value was provided.
  if (window._.isNil(block)) console.error('Parent does not exist, but required.');

  let result = block;

  if (elem !== '') {
    if (window._.isString(elem)) {
      result += '__' + elem; // Assign elem

    } else if (window._.isArray(elem)) {
      window._.each(elem, (e) => {
        result += '__' + e; // Assign elem
      });
    }
  }

  if (modifier !== '') result += '--' + modifier; // Assign modifier

  return result;
}

export function convertToFormData(data, formData, parentKey) {
  if(data === null || data === undefined) return null;

  formData = formData || new FormData();

  if (typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => 
      // convertToFormData(data[key], formData, (!parentKey ? key : (data[key] instanceof File ? parentKey : `${parentKey}[${key}]`)))
      convertToFormData(data[key], formData, parentKey ? `${parentKey}[${key}]` : key)
    );
  } else {
    formData.append(parentKey, data);
  }

  return formData;
}