// store > booking > batches > getters
'use strict';

export default {
  booking: (state) => state.booking,

  itemPagination: (state) => state.item.pagination,

  // Batches
  batchesList: (state) => state.batches.list,
  batchesTotal: (state) => state.batches.total,
  batchesCurrentPage: (state) => state.batches.page,
  isBatchesLoading: (state) => state.batches.loading,
  isBatchesEndOfData: (state) => state.batches.end_of_data,

  batchesNonSendableList: (state) => state.batchesNonSendable.list,
  batchesNonSendableTotal: (state) => state.batchesNonSendable.total,
  batchesNonSendableCurrentPage: (state) => state.batchesNonSendable.page,
  batchesNonSendableLoading: (state) => state.batchesNonSendable.loading,
  batchesNonSendableEnd: (state) => state.batchesNonSendable.end_of_data,

  batchesSchedulerList: (state) => state.batchesScheduler.list,
  batchesSchedulerTotal: (state) => state.batchesScheduler.total,
  batchesSchedulerCurrentPage: (state) => state.batchesScheduler.page,
  isBatchesSchedulerLoading: (state) => state.batchesScheduler.loading,
  isBatchesSchedulerEndOfData: (state) => state.batchesScheduler.end_of_data,

  translatorTriesToAcceptList: (state) => state.translatorTriesToAcceptList,
  batchesStatus: (state) => state.batchesStatus
}
