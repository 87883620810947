<template>

  <div @click="onFieldClick" class="edit-cell">
    <el-row>
      <el-col :span="1" v-if="!editMode && !showInput">
        <slot name="prefix"></slot>
      </el-col>
      <el-col :span="18">
        <el-tooltip v-if="!editMode && !showInput" :placement="toolTipPlacement" :open-delay="toolTipDelay" :content="toolTipContent">
          <div tabindex="0" @keyup.enter="onFieldClick">
            <slot name="content"></slot>
          </div>

        </el-tooltip>
      </el-col>
      <el-col :span="4" v-if="!editMode && !showInput">
        <slot name="suffix"></slot>
      </el-col>
    </el-row>



    <component :is="editableComponent" v-if="editMode || showInput" ref="input" @focus="onFieldClick"
      @keyup.enter.native="onInputExit" @change="onInputChange" v-on="listeners" v-bind="$attrs" v-model="model"
      v-loading="loading">
      <slot name="edit-component-slot"></slot>
    </component>
  </div>
</template>
<script>
  export default {
    name: 'editable-column',
    inheritAttrs: false,
    props: {
      value: {
        type: String,
        default: ''
      },
      toolTipContent: {
        type: String,
        default: 'Click to edit'
      },
      toolTipDelay: {
        type: Number,
        default: 0
      },
      toolTipPlacement: {
        type: String,
        default: 'top-start'
      },
      showInput: {
        type: Boolean,
        default: false
      },
      editableComponent: {
        type: String,
        default: 'el-input'
      },
      closeEvent: {
        type: String,
        default: 'blur'
      },
      row: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        editMode: false,
        loading: false
      }
    },
    computed: {
      model: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        }
      },
      listeners() {
        return {
          [this.closeEvent]: this.onInputExit,
          ...this.$listeners
        }
      }
    },
    methods: {
      onFieldClick() {
        this.editMode = true
        this.$nextTick(() => {
          const inputRef = this.$refs.input
          if (inputRef) {
            inputRef.focus()
          }
        })
      },
      onInputExit() {
        this.editMode = false
      },
      onInputChange(val) {
        this.$emit('input', val)
        // this.loading = true

      }
    }
  }
</script>
<style>

</style>