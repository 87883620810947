/*
|--------------------------------------------------------------------------
| Store > Municipality > State
|--------------------------------------------------------------------------
|
| This file contains the state property of User Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {
    gep
} from "../../../helpers/Vuex";
import {
    BOOKING_CONFIRM_FIELDS
} from '~/js/store/user/shared/props'

let target = 'municipalities';
let apiPrefix = window.app_api_prefix_3;

let composeProps = {

    // Main props
    id: '',
    name: '',
    email: '',

    // Info props
    gln: '',
    phone: '',
    mobile: '',
    organization_number: '',
    invoice_verification: '',
    salary_verification: '',
    invoice_type: '',
    address: '',
    address_2: '',
    city: '',
    post_code: '',
    country_iso: '',
    registration_enabled: false,
    valid_domains_array: [],
    valid_domains: '',
    booking_confirm_fields: BOOKING_CONFIRM_FIELDS,
    is_sensitive_info: 0,
    is_protected_identity: 0,
    confidential_message: '',
    contract_start: '',
    contract_end: '',
    renegotiation_date: '',
    email_for_cost_place: '',
    town_id: '',
    customer_type_id: '',
    allow_send_invoices: false,
    allow_show_invoices: false,
    allow_fallback_type: true,
    blacklisted_users: [],
    number_settings_status: 'none',
    number_settings_number: '',
    blacklisted_users_array: [],

    company_name_register_label: '',
    department_name_register_label: '',
    notes: '',
    email_setting_status: 'none',
    email_setting_enabled: false,
    send_email_before_due: false


};

export default {

    model_info: {
        name: 'Municipality Store',
        module: 'municipality',
        table: target,
    },

    api: {
        browse: {
            method: 'get',
            path: gep(target, apiPrefix),
            params: {
                per_page: 10,
                include: 'companies'
            },
            data: {},
        },
        read: {
            method: 'get',
            path: gep(target + '/{id}', apiPrefix),
            params: {
                include: 'companies,blacklisted_users'
            },
            data: {},
        },
        edit: {
            method: 'put',
            path: gep(target + '/{id}', apiPrefix),
            params: {},
            data: {},
        },
        add: {
            method: 'post',
            path: gep(target, apiPrefix),
            params: {},
            data: {},
        },
        delete: {
            method: 'delete',
            path: gep(target + '/{id}', apiPrefix),
            params: {},
            data: {},
        },
    },

    composeFresh: _.cloneDeep(composeProps),
    compose: _.cloneDeep(composeProps),

    payloadData: {}, // End of payloadData state

    autoInjectPayload: true,
    autoReflectItem: true,
    autoUpdateList: true,

} // End of export default
