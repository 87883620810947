/*
|--------------------------------------------------------------------------
| Store > Booking > getters
|--------------------------------------------------------------------------
|
| This file contains the getters property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';
import {isEmpty} from '~/js/helpers/Common'
import { setTimedLocalStorage } from '../../helpers/Common';

export default {
  /**
   * Get the bookings list state
   *
   *  * @param {object} state
   *  : current state of the vuex Object
   *
   * @return {array}
   */
  getBookings: (state) => state.bookings,

  /**
   * get Booking object state
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   * @return {object}
   */
  getBooking: (state) => state.booking,

  /**
   * get Booking form state
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   * @return {object}
   */
  getForm: (state) => state.bookingForm,

  /**
   * get Booking form state
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   * @return {object}
   */
  getEditBookingForm: (state) => state.editBookingForm,

  /**
   * get emergency object state
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   * @return {boolean}
   */
  isImmediate: (state) => state.is_immediate,

  /**
   * Set translator types array state
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   * @return {array}
   */
  getTranslatorTypes: (state) => state.translator_types,
  getTranslatorLevels: (state) => state.translatorLevels,

  getCustomersList: (state) => {
    let customers = JSON.parse(localStorage.getItem('customers'))
    if(!isEmpty(customers)){
      return customers
    }
   return  state.customersList
  },
  getTranslatorsList: (state) => state.translatorsList,
  getDepartmentsList: (state) => state.departmentsList,
  getCompaniesList: (state) => state.companiesList,
  getLoading: (state) => state.loading,
  getDepartmentsListLoading: (state) => state.departmentsListLoading,
  getCustomersListLoading: (state) => state.customersListLoading,
  getError: (state) => state.error,
  getCustomer: (state) => state.customer,
  getConfirmationFills: (state) => state.confirmationFills,
  getDefaultConfirmationFills: (state) => state.defaultConfirmationFills,
  getBookerNameList: (state) => state.bookerNameList,
  getStaffNameList: (state) => state.staffNameList,
  getReferenceList: (state) => state.referenceList,
  getEmailList: (state) => state.emailList,
  getSelectedTranslatorLevels: (state) => state.selectedTranslatorLevels,
  getStatusList: (state) => state.statusList,
  getHistories: (state) => state.histories,
  getHistoriesAudit: (state) => state.historiesAudit,
  getHistoriesNoSystemAudits(state) {
    return state.historiesAudit.filter(audit => (audit.tags != null && audit.tags !== 'system/cron'
      || audit.auditable_type == 'App\\Modules\\BookingTranslators\\BookingTranslator')
      || ((audit.hasOwnProperty('new_values')  && audit.new_values.hasOwnProperty('status_id'))
      || (audit.hasOwnProperty('old_values') && audit.old_values.hasOwnProperty('status_id'))));
  },
  getHistoriesAuditBackup: (state) => state.historiesAuditBackup,
  getExpiringBookings: (state) => state.expiringBookings,
  getAssignedTranslator: (state) => state.assignedTranslator,
  getComments: (state) => state.booking.comments,
  getBookingField: (state) => state.bookingField,
  usersAll: (state) => state.usersAll,
  isNotSufficientTranslators: (state) => state.availableTranslators < 10,
  getTraveltime: (state) => state.traveltime,

  getCurrentPending:(state) => state.currentPending,
  getCurrentAccepted:(state) => state.currentAccepted,
  getTotalCompleted:(state) => state.totalCompleted,
  getTotalCancelled:(state) => state.totalCancelled,
  getLateCancelled:(state) => state.lateCancelled,
  getNotCarriedOutTranslator:(state) => state.notCarriedOutTranslator,
  getNotCarriedOutcustomer:(state) => state.notCarriedOutcustomer,
  getLateWithdrawal:(state) => state.lateWithdrawal,
  getLateWithdrawalList:(state) => state.lateWithdrawalList,
  getIsSpecificTranslatorReject:(state) => state.isSpecificTranslatorReject,
  getTownList:(state) => state.towns,
  getUserLockedBookings:(state) => state.userLockedBookings,
  getReasons:(state) => state.reasons,
  getUsersInsideBookingList:(state) => state.usersInsideBooking

}
