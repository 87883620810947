<template>
  <el-dialog
    :title="$t('edit_recipients')"
    :visible.sync="iVisible"
    :modal-append-to-body="false"
    :lock-scroll="true"
    width="90%"
    style="margin-left: 20%"
    :center="true"
  >
    <!-- form begins -->
    <el-form>
      <el-row :gutter="20">
        <!--el row start -->
        <ElCol :span="16" style="border-right: solid 1px">
          <h3>{{ $t('filters') }}</h3>
          <div class="app-splitter two-cols">
            <div class="col-3" style="display:flex; flex-direction: row;">
              <el-checkbox
                @change="handleSendAll(3)"
                v-model="form.send_to_translator"
                :label="$t('send_all_translators')"
              ></el-checkbox>
              <el-checkbox
                :label="$t('send_all_customers')"
                @change="handleSendAll(2)"
                v-model="form.send_to_customer"
              ></el-checkbox>
            </div>
          </div>
          <br />

          <div class="app-splitter four-cols">
            <el-form-item :label="$t('tfv_translator_name')" class="label-block">
              <el-select
                v-model="form.translator_name"
                filterable
                :placeholder="$t('translator_search_type')"
                remote
                ref="customer"
                :remote-method="searchTranslator"
                :loading="searchCustomerLoading"
                clearable
                multiple
                collapse-tags
              >
                <el-option v-for="i in searchResult" :key="i.id" :label="i.name" :value="i.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('language')"
              :id="genContainerId('search_language')"
              class="label-block"
            >
              <el-select
                v-model="form.language_id"
                :placeholder="$t('select_language')"
                ref="language"
                filterable
                collapse-tags
              >
                <el-option :label="$t('all')" value="all"></el-option>
                <el-option
                  v-for="language in languages"
                  :key="language.id"
                  :label="language.name"
                  :value="language.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('translator_state')" class="label-block">
              <el-select
                :id="genFieldId('company_selector')"
                :placeholder="$t('tfv_search_translator_towns')"
                v-model="form.search_town_translator"
                filterable
              >
                <el-option v-for="(v) in townOpts" :key="v.id" :value="v.id" :label="v.name" />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('tfv_customer_state')" class="label-block">
              <el-select
                :id="genFieldId('company_selector')"
                :placeholder="$t('tfv_customer_towns')"
                v-model="form.search_town_customer"
                filterable
              >
                <el-option v-for="(v) in townOpts" :key="v.id" :value="v.id" :label="v.name" />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('tfv_translator_level')" class="label-block">
              <el-select v-model="form.translator_level" :id="genFieldId('company_selector')">
                <el-option v-for="(v) in translatorLevelOpts"
                           :key="v.id"
                           :value="v.id"
                           :label="v.name"/>
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('municipality')" class="label-block">
              <el-select
                :id="genFieldId('municipality_id')"
                filterable
                v-model="form.municipality_id"
              >
                <el-option
                  v-for="o in municipalityOpts"
                  :key="o.key"
                  :value="o.key"
                  :label="o.label"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('company')" class="label-block">
              <el-select
                :id="genFieldId('company_id')"
                v-model="form.company_id"
                clearable
                filterable
              >
                <el-option v-for="o in companyOpts" :key="o.key" :value="o.key" :label="o.label" />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('department')" class="label-block">
              <el-select v-model="form.department_id" filterable>
                <el-option
                  v-for="o in departmentOpts"
                  :key="o.key"
                  :value="o.key"
                  :label="o.label"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('tfv_customer_name')" class="label-block">
              <el-select
                v-model="form.customer_name"
                filterable
                :placeholder="$t('type_search_customer')"
                remote
                ref="customer"
                :remote-method="searchCustomer"
                :loading="searchCustomerLoading"
                clearable
                collapse-tags
                multiple
              >
                <el-option v-for="i in searchResult" :key="i.id" :label="i.name" :value="i.name"></el-option>
              </el-select>
            </el-form-item>
          </div>
           <el-button style="float:right" @click="resetFilter()" type="primary">{{ $t('reset') }}</el-button>
          <el-button style="float:right" @click="handleApply" type="primary">{{ $t('filter') }}</el-button>
          <el-table
            ref="multipleTable"
            :data="listData"
            height="280"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="id" label="ID" width="280"></el-table-column>
            <el-table-column prop="name" :label="$t('name')" width="300"></el-table-column>
          </el-table>

          <el-pagination
            v-if="listPagination.total > listPagination.per_page"
            layout="prev, pager, next"
            :page-size="listPagination.per_page"
            :total="listPagination.total"
            :current-page="listPagination.current_page"
            @current-change="reloadList"
          ></el-pagination>

          <el-button type="primary" style="float:right" @click="handleAddRecipient()">{{ $t('select') }}</el-button>
        </ElCol>
        <ElCol :span="8">
          <div class="app-splitter two-cols">
            <h3>{{ $t('recipient') }}</h3>
            <h3>{{ $t('total') }}: {{recipient.length}}</h3>
          </div>
          <hr />
          <div v-for="(item, index) in top9" class="app-splitter two-cols">
            <p>{{item.name}}</p>
            <el-button
              style="min-width:20px"
              @click="handleDeleteRecipient(index)"
              icon="el-icon-delete"
              circle
            ></el-button>
          </div>
          <b v-if="rest > 0">+{{rest}} {{ $t('tfv_more') }}</b>

          <div class="edit-modal-footer">
            <el-button type="default" @click="handleClose()">{{$t('cancel')}}</el-button>
            <el-button type="primary" @click="handleSaveButton()">{{$t('save')}}</el-button>
            <!-- <el-button  @click="viewBookingsDetails()">Booking Details</el-button> -->
          </div>
        </ElCol>
      </el-row>
      <!--el row ends -->
    </el-form>
    <!-- form ends -->
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import {isEmpty, sd} from '~/js/helpers/Common';
import { populate } from '../../../js/helpers/Vuex';
export default {
  props: {
    visible: {
      Type: Boolean,
      default: false
    }
  },
  data() {
    return {
      recipient: [],
      form: {},
      searchResult: [],
      searchCustomerLoading: false,
      multipleSelection: []
    };
  },
  computed: {
    /**
     * Computed property to list only top9 values in recipients list
     *
     */
    top9() {
      return this.recipient.slice(0, 9);
    },
    /**
     * Rest of the values of recipients
     */
    rest() {
      return this.recipient.length - 9;
    },
    iVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        console.log(v);
        this.$emit('update:visible', v);
      }
    },
    ...mapGetters('translatorLevel', {
                translatorLevelOpts: 'listAsOptions',
            }),
    ...mapGetters("town", {
      townOpts: "listAsOptions"
    }),
    ...mapGetters({
      translators: 'booking/getTranslatorsList'
    }),
    ...mapGetters({
      languages: 'language/getLanguages'
    }),
    ...mapGetters('municipality', {
      municipalityOpts: 'listAsOptionsPaired'
    }),
    ...mapGetters('company', {
      companyOpts: 'listAsOptionsPaired'
    }),
    ...mapGetters('department', {
      departmentOpts: 'listAsOptionsPaired'
    }),
    ...mapGetters('user', {
      listData: 'listData',
      listPagination: 'listPagination'
    })
  },
  methods: {
    ...mapActions({
      filterCustomers: 'booking/filterCustomers',
      filterTranslators: 'booking/filterTranslators'
    }),
    ...mapActions('town', {
      loadTowns: 'browseAsOptions'
    }),
    loadData(params) {
      populate(this, 'findUser', '', {
        params
      });
    },
    ...mapActions('user', {
      findUser: 'browseRecipient'
    }),
    ...mapActions('language', {
      getLanguages: 'fetchLanguages'
    }),
    ...mapActions('translatorLevel', {
      loadTranslatorLevels: 'browseAsOptions'
    }),
    ...mapActions("municipality", {
      loadMunicipalityOpts: "browseAsOptions"
    }),
    ...mapActions('company', {
      loadCompanyOpts: 'browseAsOptions'
    }),
    ...mapActions('department', {
      loadDepartmentOpts: 'browseAsOptions'
    }),
    handleSelect() {
      this.selected == 'customer'
        ? (this.showSelect = 'customer')
        : this.selected == 'translator'
        ? (this.showSelect = 'translator')
        : null;
    },
    /**
     * Recipients selection in the el-table
     */
    handleSelectionChange(row) {
      this.multipleSelection = row;
    },
    handleClose: function() {
      this.$emit('update:visible', false);
    }, // ,

    /**
     * using spread operator to join exisitng recipients with recipient array
     *
     */
    handleAddRecipient() {
      this.recipient = [...this.multipleSelection, ...this.recipient];
      this.recipient = _.uniqBy(this.recipient, 'id');
    },

    handleSendAll(val) {
      let param = {};
      if (
        this.form.send_to_customer == true &&
        this.form.send_to_translator == true
      ) {
        param['filter[type]'] = '2,3';
      } else if (this.form.send_to_customer == true) {
        param['filter[type]'] = '2';
      } else if (this.form.send_to_translator == true) {
        param['filter[type]'] = '3';
      }
      this.loadData(param);
    },

    /**
     * delete recipient from array
     */
    handleDeleteRecipient(index) {
      this.recipient.splice(index, 1);
    },

    /**
     * Passing Recipients to parent component
     */
    handleSaveButton() {
      this.$emit('clicked', this.recipient);
    },
    populateOpts() {
      populate(this, 'getLanguages', 'languages', {
        ref: 'Languages Options'
      });
      populate(this, 'loadMunicipalityOpts', 'municipalityOpts', {
        ref: 'Municipality Options'
      });
      populate(this, 'loadCompanyOpts', 'companyOpts', {
        ref: 'Company Options'
      });
      populate(this, 'loadDepartmentOpts', 'departmentOpts', {
        ref: 'Department Options'
      });
    },
    searchTranslator(query) {
      if (query !== '' && query.length > 2) {
        this.searchCustomerLoading = true;
        let params = {
          'filter[name]': query,
          all: true
        };

        setTimeout(() => {
          this.filterTranslators(params).then(response => {
            if (isEmpty(response)) {
              let params2 = {
                'filter[email]': query,
                all: true
              };
              this.filterTranslators(params2).then(response => {
                this.searchResult = response;
              });
            }

            this.searchResult = response;

            this.searchCustomerLoading = false;
          });
        }, 200);
      } else {
        // this.searchResult = [];
      }
    },

    searchCustomer(query) {
      if (query !== '' && query.length > 2) {
        this.searchCustomerLoading = true;
        let params = {
          'filter[name]': query,
          all: true
        };

        setTimeout(() => {
          this.filterCustomers(params).then(response => {
            if (isEmpty(response)) {
              let params2 = {
                'filter[email]': query,
                all: true
              };
              this.filterCustomers(params2).then(response => {
                this.searchResult = response;
              });
            }

            this.searchResult = response;

            this.searchCustomerLoading = false;
          });
        }, 200);
      } else {
        // this.searchResult = [];
      }
    },

    resetFilter() {
      this.form = {}
    },

    reloadList(n) {
      let params = _.clone(this.$route.query);
      n = sd(n, sd(this.$route.query.page, 1)) // Set the page number.
      params.page = n;
      this.$router.push({query:params})
      populate(this, 'findUser', '', {
        params
      });
    },

    /**
     *
     * Generating filter query for searching recipients in API
     */

    handleApply(page) {
      let param = {
        'filter[company]':
          this.form.company_id == null ? '' : this.form.company_id,
        'filter[municipality]':
          this.form.municipality_id == null ? '' : this.form.municipality_id,
        'filter[language]':
          this.form.language_id == null ? '' : this.form.language_id,
        'filter[department]':
          this.form.department_id == null ? '' : this.form.department_id,
        'filter[translator_level]':
          this.form.translator_level == null ? '' : this.form.translator_level
      };

      if(!isEmpty(this.form.search_town_translator)) {
        param['filter[town]'] = this.form.search_town_translator;
        param['filter[type]'] = 3;
      }

      if(!isEmpty(this.form.search_town_customer)) {
        param['filter[town]'] = this.form.search_town_customer;
        param['filter[type]'] = 2;
      }


      if (
        !isEmpty(this.form.translator_name) &&
        isEmpty(this.form.customer_name)
      ) {
        param['filter[name]'] = this.form.translator_name;
        param['filter[type]'] = 3;
      }

      if (
        !isEmpty(this.form.customer_name) &&
        isEmpty(this.form.translator_name)
      ) {
        param['filter[name]'] = this.form.customer_name;
        param['filter[type]'] = 2;
      }
      this.$router.push({query: param})
      this.loadData(param);
    }
  },
  mounted() {
    this.populateOpts();
  }
};
</script>

<style lang="scss" scoped>
.edit-modal-footer{
  position: absolute;
  right: 0;
  bottom: 0;
  display: inline-block;
}
.el-checkbox .el-checkbox__inner {
  background-color: #4bc188 !important;
}

</style>

