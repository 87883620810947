<template>
  <div class="app-page low-feedback-Detail-page">
    <page-header :title="$t('survey_details')" :back-enabled="true"/>

    <div class="app-container">
      <div class="app-wrapper">
        <el-row :gutter="20">
          <ElCol :span="16">
            <el-card class="box-card green">
              <div slot="header" class="clearfix">
                <span>{{form.title}}</span>
              </div>
              <div class="text item">
                <template v-for="(item, index ) in questions">
                  <el-row :gutter="10" style="margin-top:10px">
                    <ElCol :md="4">
                      <div class="grid-content bg-purple">
                        <h1>{{index+1}}</h1>
                      </div>
                    </ElCol>
                    <ElCol :md="16">
                      <div class="grid-details">
                        <el-input v-model="item.title"></el-input>
                      </div>
                      <div class="top-control">
                        <el-row v-if="item.question_type">
                          <el-col :md="6">
                            <h4>{{$t('question_type')}}</h4>
                          </el-col>
                          <el-col :md="8">
                            <el-select
                              v-model="item.question_type"
                              :placeholder="$t('select')"
                              size="mini"
                              style="margin-top:10px"
                            >
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </el-col>
                        </el-row>

                        <el-row v-if="item.question_type != 'textarea'">
                          <el-col :md="6" v-if="questions[index].option_name.length>0">
                            <h4>{{$t('tfv_options')}}</h4>
                          </el-col>
                          <el-col :md="18">
                            <div class="app-splitter two-cols" v-for="(items, loop) in questions[index].option_name">
                              <div >
                              <el-input size="mini" style="margin-bottom:2px" v-model="questions[index].option_name[loop]"></el-input>
                              </div>
                              <div ><i @click="handleQuestionDelete(index,loop)" class="el-icon-delete"></i></div>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                      <el-select
                        style="margin-left:10px"
                        v-model="form.question_type"
                        v-if="!item.question_type"
                        @change="handleQuestionType(index, form.question_type)"
                        :placeholder="$t('select')"
                      >
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                      <div v-if="item.question_type != 'textarea'" style="margin-left:10px">
                        <p>{{$t('add_options')}}</p>
                        <el-input :placeholder="$t('please_enter_option')" v-model="form.option[index]"></el-input>
                        <el-button
                          style="min-width: 0px"
                          type="primary"
                          icon="el-icon-circle-plus-outline"
                          @click="addOptions(index, form.option[index])"
                          round
                        >{{$t('add_options')}}</el-button>
                      </div>
                    </ElCol>
                    <ElCol :md="2">
                      <el-dropdown style="margin-top:40px" :hide-on-click="false">
                        <span class="el-dropdown-link">
                          <i class="el-icon-more"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item @click.native="handleDelete(index)">{{$t('delete')}}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </ElCol>
                  </el-row>
                </template>
                <template v-if="showAddComment">
                  <h4>{{$t('enter_queston_title')}}</h4>
                  <el-input
                    type="textarea"
                    :placeholder="$t('please_enter_question')"
                    v-model="form.comment"
                    show-word-limit
                  ></el-input>

                  <div class="text-right">
                    <el-button type="success" round @click="handleQuestionAdd">{{$t('add')}}</el-button>
                  </div>
                </template>
                <div class="text-right">
                  <el-button type="text" @click="showAddComment = !showAddComment">{{$t('add_question')}}</el-button>
                </div>
              </div>
            </el-card>
          </ElCol>
          <ElCol :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>{{$t('details')}}</span>
              </div>
              <template>
                <div class="survey-right">
                  <ul>
                    <li>
                      <h4>{{$t('survey_name')}}</h4>
                      <p>
                        <span class="text-success">{{form.title}}</span>
                      </p>
                    </li>

                    <li>
                      <h4>{{$t('responses')}}</h4>
                      <p>
                        <span class="text-success">{{form.response_count}}</span>
                      </p>
                    </li>

                    <li>
                      <h4>{{$t('date_created')}}</h4>
                      <p>
                        <span class="text-success">{{form.date_created}}</span>
                      </p>
                    </li>

                    <li>
                      <h4>{{$t('survey_period')}}</h4>
                      <p>
                        <span class="text-success">{{form.start_at}} -- {{form.end_at}}</span>
                      </p>
                    </li>
                  </ul>
                </div>

                <hr />
                <template>
                  <h4>{{$t('actions')}}</h4>
                  <div class="action-panel">
                    <ul style="list-style-type:none;">
                      <li>
                        <el-button  @click="$router.push({name:'survey.response',params:{id:$route.params.id, name:form.title}})" type="success">
                          <i class="fa fa-comments" aria-hidden="true"></i>
                          <span
                            class="action-btn"
                          >{{$t('see_responses')}}</span>
                        </el-button>
                      </li>
                      <li>
                        <el-button
                          type="success"
                          @click="showEditRecipentModal = !showEditRecipentModal"
                        >
                          <i class="fa fa-envelope-o" aria-hidden="true"></i>
                          <span class="action-btn">{{$t('edit_recipients')}}</span>
                        </el-button>
                      </li>
                      <li>
                        <el-button
                          type="success"
                          @click="showEditSurveyPeriod= !showEditSurveyPeriod"
                        >
                          <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                          <span class="action-btn">{{$t('edit_survey_period')}}</span>
                        </el-button>
                      </li>
                      <li>
                        <el-button type="success" @click="handleSubmit">
                          <i class="fa fa-check-circle" aria-hidden="true"></i>
                          <span class="action-btn" >{{$t('save_questions')}}</span>
                        </el-button>
                      </li>
                    </ul>
                  </div>
                </template>
              </template>
            </el-card>
          </ElCol>
        </el-row>
      </div>
      <!-- /.app-wrapper -->
    </div>
    <edit-recipent-modal
      @clicked="getRecipient"
      :addRecipient="addRecipient"
      :visible.sync="showEditRecipentModal"
    ></edit-recipent-modal>
    <edit-survey-period
      :updateSurveyTime="updateSurveyTime"
      :modal-append-to-body="true"
      :visible.sync="showEditSurveyPeriod"
    ></edit-survey-period>
    <send-email-modal :survey="form" :visible.sync="showEmailModal"></send-email-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import i18n from '~/js/i18n';
import { Notification } from 'element-ui';
import {populate} from "~/js/helpers/Vuex";
export default {
  data() {
    const _this=this;
    return {
      showAddComment: false,
      showEditRecipentModal: false,
      showEditSurveyPeriod: false,
      showEmailModal: false,
      form: {
        option: []
      },
      questions: [],
      counter: 0,
      title: '',
      options: [
        {
          value: 'radio',
          label: _this.$t('radio_button')
        },
        {
          value: 'checkbox',
          label: _this.$t('checkbox')
        },
        {
          value: 'textarea',
          label: _this.$t('textarea')
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      addQuestions: 'surveyManager/addQuestions',
      getSurveywithQuestions: 'surveyManager/getSurveywithQuestions',
      deleteQuestion: 'surveyManager/deleteQuestion',
      updateSurvey: 'surveyManager/updateSurvey',
      addRecipients: 'surveyManager/addRecipient',
      updateSurveyQuestion: 'surveyManager/updateSurveyQuestion',
      loadTranslatorLevels: 'translatorLevel/browseAsOptions',

    }),

    ...mapActions('town', {
      loadTowns: 'browseAsOptions'
    }),

    getRecipient(arrayOfRecipient) {
      let payload = _.map(arrayOfRecipient, 'id');
      this.addRecipient(payload);
    },
    /**
     * Pushing new question to a questions array
     */
    handleQuestionAdd() {
      if (this.form.comment) {
        this.questions.push({
          title: this.form.comment,
          option_name: [],
          survey_id: this.$route.params.id,
          user_id: JSON.parse(localStorage.getItem('app_user_data')).id,
          question_type: ''
        });
        this.showAddComment = false;
        this.form.comment = '';
      }
    },
    /**
     * Adding options for the survey questions
     */
    addOptions(index, option) {
      if (option) {
        this.questions[index].option_name.push(option);
        this.form.option = {};
      }
    },
    /**
     * Definng Question Type
     */
    handleQuestionType(index, type) {
      this.questions[index].question_type = type;
      this.form.question_type = '';
    },
    /**
     * Method for deleting question.
     * If there is ID present in question delete from DB as well
     */
    handleDelete(index) {
      if (this.questions[index].id) {
        this.deleteQuestion(this.questions[index].id).then(resp => {
          console.log(resp);
        });
      }
      this.questions.splice(index, 1);
    },
    /**
     * Handle question Option delete
     */
    handleQuestionDelete(index, loop)
    {
      this.questions[index].option_name.splice(loop, 1)
    },
    /**
     * Saving Question and Posting Data
     * to API
     */
    handleSubmit() {
      this.$confirm('This will save all questions, Continue?', this.$t('warning'), {
        confirmButtonText: 'YES',
        cancelButtonText: 'NO',
        type: 'warning'
      }).then(() => {

            if (this.questions.length > 0) {
              const filtered = _.filter(this.questions, function(o) {
                return !o.id;
              });
              const needToUpdate = _.filter(this.questions, function(o) {
                return o.id
              });
              if(needToUpdate.length > 0) {
                this.updateSurveyQuestion(needToUpdate).then(() => {
                   Notification.success({
                    title: i18n.t('success'),
                    message: 'Questions updated successfully'
                  });
                })
              }
              if (filtered.length > 0) {
                this.addQuestions(filtered).then(res => {
                  this.questions = _.merge(
                    this.questions,
                    res.data.data.item.survey_question
                  );
                  Notification.success({
                    title: i18n.t('success'),
                    message: 'Questions saved successfully'
                  });
                });
              }
            }

      }).catch(() => {
        console.log('canceled')
      });

    },

    /**
     * Method for updating survey time
     */
    updateSurveyTime(date) {
      let payload = {
        id: this.$route.params.id,
        start_date: date[0],
        end_date: date[1],
        title: this.form.title,
        user_id: JSON.parse(localStorage.getItem('app_user_data')).id
      };
      this.updateSurvey(payload).then(res => {
        this.getSurvey();
        Notification.success({
          title: i18n.t('success'),
          message: 'Time period updated'
        });
        this.form.start_at = moment(date[0]).format('YYYY-MM-DD');
        this.form.end_at = moment(date[0]).format('YYYY-MM-DD');
      });
    },
    /**
     * Method for getting survey with questions
     */
    getSurvey() {
      this.getSurveywithQuestions(this.$route.params.id).then(resp => {
        this.questions = resp.survey_question;
        this.form.title = resp.title;
        this.form.date_created = resp.created_at;
        this.form.start_at = moment(resp.start_date).format('YYYY-MM-DD');
        this.form.end_at = moment(resp.end_date).format('YYYY-MM-DD');
        this.form.response_count = resp.response_count;
        this.form.user = resp.user;
        console.log(this.form);
      });
    },
    /**
     * Method for adding recipients to the survey
     */
    addRecipient(recipients) {
      let payload = {
        id: this.$route.params.id,
        user: recipients
      };

      this.addRecipients(payload).then(res => {
        this.showEditRecipentModal = false;
        Notification.success({
          title: i18n.t('success'),
          message: 'Recipients added'
        });
      });
    }
  },
  /**
   * Getting survey
   */
  mounted() {
    this.getSurvey();

    populate(this, 'loadTowns', 'townOpts', {
      ref: 'Towns Listing'
    });

    populate(this, 'loadTranslatorLevels', 'translatorLevelOpts', {
      ref: 'Translator Level Listing'
    });

  }
};
</script>

<style lang="scss" scoped>
.survey-right h4 {
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 0.4em;
}
.survey-right p {
  line-height: 0.4em;
}
.action-panel .el-button {
  min-width: 220px;
  font-size: 12px;
}
.action-panel .el-button i.fa {
  float: left;
}
.top-control {
  margin-left: 10px;
}
.action-btn {
  float: left;
  padding-left: 10px;
}
</style>
