<template>
  <div class="app-form booking-detail-form">

    <el-form :model="booking" :id="formElId" :ref="formElId">

      <locked-booking-warning :locked="locked" :handleLockedBooking="handleLockedBooking"  :lockedBooking="lockedBooking"/>
      <hr class="app-hr hr-type1">

      <h3>{{$t('job_detail_controls')}}</h3>
      <div class="form-btn-container" style="margin-top: 35px;">
        <el-dropdown>
          <el-button type="primary">
            {{$t('actions')}}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu
            slot="dropdown"
            :class="getBem('booking-detail-form', 'actions-dropdown-menu')"
          >
            <el-dropdown-item @click.native="editBooking">{{$t('change')}} {{$t('booking')}}</el-dropdown-item>
            <el-dropdown-item :disabled="handleDisableEditButton()" @click.native="postCancelBooking(booking.id)">{{$t('tfv_cancel')}}
            </el-dropdown-item>
            <el-dropdown-item :disabled="handleDisableEditButton()" v-if="!isEmpty(booking.assigned_translator)"
                              @click.native="postWithdrawTranslator(booking.id)">{{$t('withdraw_booking')}}
            </el-dropdown-item>
            <el-dropdown-item v-if="!isEmpty(booking.assigned_translator) || booking.status_id==1"
                              @click.native="postExpireBooking(booking.id)">{{$t('booking_expire')}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown>
          <el-button type="primary">
            {{$t('translators')}}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu
            slot="dropdown"
            :class="getBem('booking-detail-form', 'translators-dropdown-menu')"
          >
            <el-dropdown-item @click.native="handleClickBookingAvailability">{{$t('schedule')}}</el-dropdown-item>
            <el-dropdown-item @click.native="handleViewTranslator">{{$t('view_translator')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="primary" @click="handleClickFinancialAdjustment">{{$t('financial_adjustment')}}</el-button>
        <el-dropdown>
          <el-button type="primary">
            {{$t('logs')}}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu
            slot="dropdown"
            :class="getBem('booking-detail-form', 'logs-dropdown-menu')"
          >
            <el-dropdown-item @click.native="handleClickBookingTrailLogsAudit">{{$t('booking_trail_logs')}}
              ({{$t('new')}})
            </el-dropdown-item>
            <el-dropdown-item @click.native="handleClickComlogs">{{$t('communication_logs')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!-- Assign Translator -->
        <translator-select v-model="translatorId"></translator-select>
        <el-button type="primary"  :disabled="handleDisableEditButton()" @click="handleClickAssignTranslator">
          {{$t('assign_translator')}}
        </el-button>
        <el-button type="primary" @click="handleFollowUp">{{$t('follow_up')}}</el-button>
        <!-- <el-select  v-model="translatorId" style="width:45%" filterable placeholder="Select Translator">
    <el-option v-for="translator in translators" :key="translator.id" :label="translator.email" :value="translator.id"></el-option>
   </el-select> -->


        <el-button type="primary" v-if="booking.status_id===1" @click="postRecreateBatches(booking.id)">
          {{$t('recreate_batches')}}
        </el-button>

        <el-button type="primary" v-if="booking.status_id===1" @click="handleDeleteBatches(booking.id)">
          {{$t('delete_batches')}}
        </el-button>

        <!-- <el-button type="primary" v-if="booking.status_id===3" @click="showNotPickupModal=true">Translator not picking up
       </el-button>
        <el-button type="primary" v-if="!isEmpty(booking.assigned_translator)" @click="showTranslatorLateModal=true">Translator Delay
       </el-button> -->
        <el-dropdown v-if="!isEmpty(booking.assigned_translator)">
          <el-button type="primary">
            {{$t('problem')}}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="handleClikTranslatorNotPickup">
              {{$t('translator_not_picking_up')}}
            </el-dropdown-item>
            <el-dropdown-item @click.native="showTranslatorLateModal=true">{{$t('translator_late')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-button type="primary" @click="handleClickViewBookingResources">
          {{$t('view_booked_resources')}}
        </el-button>
        <!-- <el-button :id="genFieldId('save_btn',formElId)" type="primary" @click="handleLockedBooking(status, true, '15vh')">
               {{(locked !== undefined && locked.length > 0 && locked[0].is_locked == 1) ? $t('unlock') : $t('lock')}}
         </el-button> -->

        <el-select
          v-model="emailsTemplate" filterable :placeholder="$t('select_email_send')"
          @change="handleSelectEmail"
        >
          <el-option v-for="template in emailsData" :key="template.id" :label="template.title"
                     :value="template"></el-option>
        </el-select>
        <el-button type="primary" v-if="booking.type != 'text_translation'" @click="cloneBooking">
          {{$t('clone_booking')}}
        </el-button>

        <el-button type="primary" @click="handleAddLowFeedbackIssue">{{$t('add_open_issue_at_action_manager')}}</el-button>

        <el-select  v-model="partner"
                    filterable
                    :placeholder="$t('select_partner_send')"
                    @change="handleSendPartner" value-key="id">
          <el-option v-for="i in partnersData" :key="i.id" :label="i.name"
                     :value="i"></el-option>
        </el-select>

      </div> <!-- /.form-btn-container -->

      <hr class="app-hr hr-type1">
      <div v-if="assignedResourcesData.length > 0">
        <h3>Assigned Resources</h3>

        <el-form-item v-for="(v,k) in assignedResourcesData" :key="k" :label="v.name" class="label-switch">
          <el-button @click="handleDeleteAssignment(v)" class="circle" type="danger" size="mini" title="Remove Assignment">
            <span class="fa fa-close"></span>
          </el-button>
        </el-form-item>
      </div>

      <hr class="app-hr hr-type1">
      <h3>{{$t('job_detail_switches')}}</h3>

      <!-- Booking made by admin? (Made via self) -->
      <el-form-item :id="genContainerId('is_by_admin',formElId)"
                    :label="$t('booking_made_by_admin')" class="label-switch">
        <span slot="label">{{$t('booking_made_by_admin')}}?</span>
        <span slot="label"
              class="blue-text" v-if="booking.booker&& booking.booker.type == 1"><p>{{$t('admin')}} {{booking.booker.name}}</p></span>
        <span slot="label" class="blue-text" v-if="booking.booker&& booking.booker.type == 2"><p>{{$t('customer')}} {{booking.booker.name}}</p></span>
        <el-switch :id="genFieldId('is_by_admin',formElId)"
                   v-if="booking.booker&& booking.booker.type" v-model="booking.booker.type" :active-value="1"
                   :inactive-value="2" />
      </el-form-item>

      <!-- Manually arranged translator for this booking (by calling)? (for stats) -->
      <el-form-item :id="genContainerId('is_manually_arranged',formElId)"
                    :label="$t('manually_arranged_text')"
                    class="label-switch">
        <el-switch :id="genFieldId('is_manually_arranged',formElId)" @change="updateSwitch($event,'manually_handled')"
                   v-model="booking.manually_handled" />
      </el-form-item>

      <!-- Flag this booking -->
      <el-form-item :id="genContainerId('is_flagged',formElId)"
                    :label="$t('flag_booking')" class="label-switch">
        <el-switch :id="genFieldId('is_flagged',formElId)"
                   v-model="booking.flagged" @change="submitForm" />
      </el-form-item>

      <!-- Is test booking -->
      <el-form-item :id="genContainerId('is_test',formElId)"
                    :label="$t('is_test_booking')" class="label-switch">
        <el-switch :id="genFieldId('is_test',formElId)"
                   v-model="booking.is_test" @change="submitForm" />
      </el-form-item>

      <!-- Request financial adjustment -->
      <!-- <el-form-item :id="genContainerId('request_financial_adjustment',formElId)"
                    :label="$t('request_financial_text')"
                    class="label-switch">
        <el-switch :id="genFieldId('request_financial_adjustment',formElId)"
                   @change="updateSwitch($event,'request_financial_adjustment')"
                   v-model="booking.request_financial_adjustment" />
      </el-form-item> -->


      <!-- stop financial request -->
      <el-form-item :id="genContainerId('stop_financial_export',formElId)"
                    :label="$t('stop_financial_export')"
                    class="label-switch">
        <el-switch :id="genFieldId('stop_financial_export',formElId)"
                   @change="updateFinancialSwitch($event)"
                   v-model="booking.stop_financial_export" />
      </el-form-item>

      <!-- Translator Already Travelled -->
      <el-form-item :id="genContainerId('translator_already_travelled',formElId)"
                    :label="$t('translator_already_travelled')"
                    class="label-switch">
        <el-switch :id="genFieldId('translator_already_travelled',formElId)"
                  @change="updateSwitch($event,'translator_already_travelled')"
                   v-model="booking.translator_already_travelled"/>
      </el-form-item>

      <!-- Mentor booking -->
      <el-form-item :id="genContainerId('is_mentor_job',formElId)"
                    :label="$t('mentor_job')"
                    class="label-switch">
        <el-switch :id="genFieldId('is_mentor_job',formElId)" @change="updateSwitch($event,'is_mentor_job')"
                   v-model="booking.is_mentor_job" />
      </el-form-item>

      <!-- Teacher booking -->
      <el-form-item :id="genContainerId('is_teacher_job',formElId)"
                    :label="$t('teacher_job')"
                    class="label-switch">
        <el-switch :id="genFieldId('is_teacher_job',formElId)" @change="updateSwitch($event,'is_teacher_job')"
                   v-model="booking.is_teacher_job"/>
      </el-form-item>

      <!-- Evaluator booking -->
      <el-form-item :id="genContainerId('is_evaluator_job',formElId)"
                    :label="$t('evaluator_job')"
                    class="label-switch">
        <el-switch :id="genFieldId('is_evaluator_job',formElId)" @change="updateSwitch($event,'is_evaluator_job')"
                   v-model="booking.is_evaluator_job"/>
      </el-form-item>

      <!-- protected identity -->
      <el-form-item :id="genContainerId('is_protected_identity',formElId)"
                    :label="$t('protected_identity')" class="label-switch">
        <el-switch :id="genFieldId('is_protected_identity',formElId)"
                   v-model="booking.is_protected_identity" :active-value="1" :inactive-value="0"
                   @change="handleConfidentialInfo(booking.is_protected_identity)" />
      </el-form-item>

      <!-- sensitive information -->
      <el-form-item :id="genContainerId('is_sensitive_info',formElId)"
                    :label="$t('sensitive_information')" class="label-switch">
        <el-switch :id="genFieldId('is_sensitive_info',formElId)"
                   v-model="booking.is_sensitive_info" :active-value="1" :inactive-value="0"
                   @change="handleConfidentialInfo(booking.is_sensitive_info)" />
      </el-form-item>

      <el-form-item :id="genContainerId('flag_as_difficult',formElId)"
                    :label="$t('flag_as_difficult')"
                    class="label-switch">
        <el-switch :id="genFieldId('flag_as_difficult',formElId)"
                   @change="updateSwitch($event,'is_difficult')"
                   v-model="booking.is_difficult" />
      </el-form-item>

<!--      <el-form-item :id="genContainerId('has_tickets',formElId)"-->
<!--                    :label="$t('has_tickets')"-->
<!--                    class="label-switch">-->
<!--        <el-switch :id="genFieldId('has_tickets',formElId)"-->
<!--                   @change="updateSwitch($event,'has_tickets')"-->
<!--                   v-model="booking.has_tickets" />-->
<!--      </el-form-item>-->

      <!-- <el-form-item :id="genContainerId('is_ticket_replied',formElId)"
                    :label="$t('is_ticket_replied')"
                    class="label-switch">
        <el-switch :id="genFieldId('is_ticket_replied',formElId)"
                   @change="updateSwitch($event,'is_ticket_replied')"
                   v-model="booking.is_ticket_replied" />
      </el-form-item> -->



      <hr class="app-hr hr-type1">

      <!-- Customer Answers in regards to specifics -->
      <booking-details-customer-specifics-preference v-if="!isEmpty(booking.specific_preference)"
                                                     :booking="booking"
      />

      <hr class="app-hr hr-type1">

      <!-- Comments -->
      <el-collapse class="panel-shaded" v-if="(!isEmpty(comments))" v-model="showComments">
        <el-collapse-item name="comment" :title="$t('latest_comments')">
          <ul class="comment-list">
            <li v-for="comment in comments" :key="comment.id">
              <div class="comment-time">
                <el-row>
                  <el-col :md="4">
                    <el-button-group>
                      <el-button type="text" icon="el-icon-delete" size="mini" class="circle"
                                 @click="handleDeleteComment(comment)"></el-button>
                      <el-button type="text" icon="el-icon-edit" size="mini" class="circle"
                                 @click="handleGetComment(comment)"></el-button>
                    </el-button-group>
                  </el-col>
                  <el-col :md="15">
                    <div class="text-justify top-10" v-html="comment.comment"></div>
                  </el-col>
                  <el-col :md="5">
                    <div class="text-right top-10" style="color:#84c3d1;">
                      {{ comment.user && comment.user.name }}
                      {{ formatDate(comment.created_at)}}
                    </div>
                  </el-col>
                </el-row>

              </div>
            </li>
          </ul>
        </el-collapse-item>
      </el-collapse>

      <el-form-item :id="genContainerId('comments',formElId)"
                    label="" class="label-block">
        <el-input :id="genFieldId('comments' ,formElId)"
                  v-model="comment"
                  type="textarea"
                  :rows="2" />
      </el-form-item>
      <div class="text-right">
        <el-button v-if="isUpdate" type="default" :id="genFieldId('cancel_btn',formElId)" class="modal-size" size="mini"
                   @click="handleCancelEdit">
          {{$t('cancel')}}
        </el-button>
        <el-button v-if="isUpdate" type="primary" :id="genFieldId('update_comment',formElId)" class="modal-size"
                   @click="handleUpdateComment">
          {{$t('update_comment')}}
        </el-button>
        <el-button v-if="!isUpdate" type="primary" :id="genFieldId('add_new_comment',formElId)" class="modal-size"
                   @click="handleAddComment"
                   :disabled="(isEmpty(comment))">
          {{$t('add_new_comment')}}
        </el-button>
      </div>

    </el-form>

    <todo-form :visible.sync="showTodoForm" :booking="booking"></todo-form>

    <translator-not-pickup-modal :visible.sync="showNotPickupModal" :booking="booking"></translator-not-pickup-modal>
    <translator-late-modal :visible.sync="showTranslatorLateModal" :booking="booking"></translator-late-modal>
    <locked-booking-modal :status="status" :top="top" :closeTag="showCloseTag"  :lockBooking="lockBooking" :visible.sync="showLockedBookingModal"/>
    <emails-templates-send-modal v-if="!isEmpty(booking.customer)" :booker="booking.customer.email" :booking="booking" :email="emailsTemplate" :visible.sync="emailsModal"/>
    <clone-booking-modal :booking="booking" :visible.sync="cloneBookingModal"/>

  </div> <!-- /.app-form booking-detail-form -->
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import {isEmpty} from '../../../js/helpers/Common';
  import {notifError} from '~/js/helpers/Notification';
  import BookingDetailsCustomerSpecificsPreference
    from '~/components/displays/booking-details/BookingDetailsCustomerSpecificsPreference';
  import TranslatorNotPickupModal from '~/components/forms/booking/partials/TranslatorNotPickupModal';
  import TranslatorLateModal from '~/components/forms/booking/partials/TranslatorLateModal';
  import {populate} from "../../../js/helpers/Vuex";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      BookingDetailsCustomerSpecificsPreference,
      TranslatorNotPickupModal, TranslatorLateModal
    },

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      booking: {}
    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        disableEditButton: false,
        showCloseTag: false,
        top: '',
        status: '',
        showLockedBookingModal: false,
        showNotPickupModal:false,
        lockedBooking: {},
        showTranslatorLateModal:false,
        formElId: 'booking_detail_form',
        is_by_admin: '',
        comment: '',
        isUpdate: false,
        selectedComment: null,
        showComments: ['comment'],
        isLoading: false,
        translatorId: null,
        showTodoForm: false,
        showLockedBookingWarningModal: false,
        emailsTemplate: '',
        emailsModal: false,
        cloneBookingModal: false,
        partner: {}

      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters({
        comments: 'booking/getComments',
        translators: 'booking/getTranslatorsList',
        emailsData: 'emailsTemplates/getEmails',
        reasons: 'booking/getReasons',
        partnersData: 'partners/listAsOptions'
      }),
      ...mapState('booking', {
        locked: 'locked_booking'
      }),
      ...mapGetters('resources', {
        assignedResourcesData: 'listData'
      }),
      userId()
      {
        return JSON.parse(localStorage.getItem('app_user_data')).id
      },
      /**
       * @returns {string}
       */
      bookingCustomerEmail () {
        return !window._.isNil(this.booking)
        && !window._.isNil(this.booking.customer)
        && !window._.isNil(this.booking.customer.email)
          ? this.booking.customer.email : '';
      }
    }, // End of Component > computed

    watch: {
      locked (newVa, OldVa) {
        this.lockedBooking = newVa[0];
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions('user', {
        readEntry: 'read'
      }),
      ...mapActions({
        updateBooking: 'booking/updateBooking',
        withdrawTranslator: 'booking/withdrawTranslator',
        cancelBooking: 'booking/cancelBooking',
        recreateBatches: 'booking/recreateBatches',
        createOrUpdateBookingLock: 'booking/createOrUpdateBookingLock',
        expireBooking: 'booking/expireBooking',
        getEmailsList: 'emailsTemplates/getEmailsList',
        assignTranslatorBooking: 'booking/assignTranslator',
        addLowFeedbackIssue:'bookingIssue/addIssue',
        getPartnersData: 'partners/browseAsOptions',
        sendPartner: 'partners/sendPartner',
        deleteBatches: 'booking/deleteBatches'
      }),
      ...mapActions('bookingBatches', {
        resendNotif: 'resendNotif'
      }),

      ...mapActions('resources', {
        assignedResources: 'browseResourcesByBooking',
        deleteAssignment: 'deleteAssignment',
      }),

      submitForm () {
        let data = {
          id: this.booking.id,
          is_test: this.booking.is_test ? 1 : 0,
          flagged: this.booking.flagged ? 1 : 0,
          is_sensitive_info: this.booking.is_sensitive_info,
          confidential_message: this.booking.confidential_message,
          is_protected_identity: this.booking.is_protected_identity,
          is_mentor_job: this.booking.is_mentor_job,
          is_force_specific_translator_value: !isEmpty(this.booking.specific_translators) ? true: false,
          is_force_alternative_languages_value : !isEmpty(this.booking.alternative_languages) ? true: false,
        };

        this.updateBooking(data);

      },
      handleLockedBooking(status, showClose, top) {
        if(this.locked[0])
        {
          if(this.locked[0].is_locked == 1 && this.locked[0].is_manual == false && this.locked[0].user_id == this.userId)
          {
            this.status = 'Lock booking'
            this.showLockedBookingModal = true;
            this.showCloseTag = showClose;
            this.top = top;
          }
          else{
            this.locked[0].is_locked == 0 ? this.status = 'Lock booking' : this.status ='Unlock booking'
            this.showLockedBookingModal = true;
            this.showCloseTag = showClose;
            this.top = top;
          }
        }
        else {
          this.status = 'Lock booking'
          this.showLockedBookingModal = true;
          this.showCloseTag = showClose;
          this.top = top;
        }
      },
      handleConfidentialInfo(value) {
        console.log(value);
        if (value == 0) {
          console.log('here');
          this.booking.confidential_message = '';
        }
        this.submitForm();
      },

      /**
       * @returns {void}
       */
      handleClickAssignTranslator () {
        const hasSelectedTranslator = !window._.isNil(this.translatorId);

        if (hasSelectedTranslator) {
          this.verifyToAssignThisBooking();

        } else {
          notifError({
            message: 'Please select a translator first'
          });
        }
      },

      /**
       * @returns {void}
       */
      verifyToAssignThisBooking () {
        if (window._.isNil(this.booking)) throw new Error('Missing required value this.booking.id');

        const hours = moment().diff(moment(this.booking.due), 'hours');
        const isBookingCurrentlyAssigned = !window._.isEmpty(this.booking.assigned_translator) && hours <= 24;

        this.$confirm(this.$t('txt_confirm_assign_translator'), this.$t('warning'), {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
          type: 'warning'
        })
          .then(() => {

            if (isBookingCurrentlyAssigned) {
              this.verifySetBookingToLateWithdraw()
                .finally(() => {
                  this.assignSelectedTranslatorToBooking();
                });

            } else {
              this.assignSelectedTranslatorToBooking();
            }
          })
          .catch((e) => {
            if (window._.isObject(e)) console.error(e);
            this.translatorId = null;
          });
      },

      /**
       * @returns {Promise}
       */
      verifySetBookingToLateWithdraw () {
        // noinspection JSUnusedLocalSymbols
        return new Promise((resolve, reject) => {
          this.$confirm(this.$t('txt_set_previous_translator_as_withdraw'), this.$t('warning'), {
            confirmButtonText: this.$t('yes'),
            cancelButtonText: this.$t('no'),
            type: 'warning'
          })
            .then(() => {
              this.withdrawTranslatorFromBooking(true)
                .then(() => {
                  resolve();
                })
                .catch((e) => console.error(e));
            })
            .catch((e) => {
              if (window._.isObject(e)) console.error(e);
              this.withdrawTranslatorFromBooking()
                .then(() => {
                  // Use resolve just to suppress catch() handling from outside this method.
                  resolve();
                })
                .catch((e) => console.error(e));
            });
        });
      },

      /**
       * @param {boolean} [isLate]
       * @returns {Promise}
       */
      withdrawTranslatorFromBooking (isLate = false) {
        if (window._.isNil(this.booking.id)) throw new Error('Missing required value this.booking.id');

        let payload = {booking_id: this.booking.id};
        if (isLate) payload.data = {late_withdraw: true};

        return new Promise((resolve, reject) => {
          this.withdrawTranslator(payload)
            .then((r) => {
              this.fetchBooking(this.booking.id);
              resolve(r);
            })
            .catch((e) => {
              reject(e);
            });
        });
      },

      /**
       *
       * @param {Object} options
       * * @param {boolean} options.is_forced
       * @returns {void}
       */
      assignSelectedTranslatorToBooking (options = {}) {
        if (window._.isNil(this.booking.id)) throw new Error('Missing required value this.booking.id');
        if (window._.isNil(this.translatorId)) throw new Error('Missing required value this.translatorId');
        options.is_forced = !window._.isNil(options.is_forced) ? options.is_forced : false;

        let payload = {
          id: this.booking.id,
          translator_id: this.translatorId
        };

        if (options.is_forced) payload.force_assign = true;

        this.assignTranslatorBooking(payload)
          .then(() => {
            this.fetchBooking(this.booking.id);
            this.$emit('translator_assigned');
          })
          .catch((e) => {
            console.error(e);
            const errorData = e.response.data.data;
            if (
              window._.isObject(errorData)
              && window._.has(errorData, 'reasons')
              && !window._.isNil(errorData, 'reasons')
              && !window._.isEmpty(errorData, 'reasons')
            ) {
              this.verifyForceAssignSelectedTranslatorToBooking(errorData.reasons);

            } else if (
              window._.isString(errorData)
              && (
                errorData === 'Du har redan en tolkning den tiden. Tolkning ej accepterad.'
                || errorData === 'Tolkningen har accepterats av annan tolk.'
              )
            ) {
              this.verifyForceAssignSelectedTranslatorToBooking(errorData);
            }
          });
      },

      /**
       * @param reasons
       * @returns {void}
       */
      verifyForceAssignSelectedTranslatorToBooking (reasons) {
        let message = this.$t('problem_assign_translator_text') + '\n\n';

        if (window._.isArray(reasons)) {
          for (let i = 0; i < reasons.length; i++) {
            message += i + 1 + '. ' + reasons[i] + '\n';
          }

        } else if (window._.isString(reasons)) {
          message += '1. ' + reasons + '\n';
        }

        message += '\n' + this.$t('force_assign_translator_text');

        this.$confirm(message, this.$t('warning'), {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
          type: 'warning',
          customClass: 'force-assign-select-translator-to-booking-modal'
        })
          .then(() => {
            this.assignSelectedTranslatorToBooking({is_forced: true});
          })
          .catch(() => {});
      },

      editBooking () {
        this.$router.push({
          name: 'booking-edit',
          params: {
            id: this.booking.id
          }
        });
      },

      isEmpty (array) {
        return isEmpty(array);
      },

      /**
       * Handler when the Financial Adjustment Button was clicked.
       *
       * @return {void}
       */
      handleClickFinancialAdjustment () {
        let routerOpts = {
          name: 'booking-financial-adjustment',
          params: {
            id: this.booking.id
          },
          query: {
            type: this.booking.type
          }
        };

        // If there is no translator assigned to the booking,
        // then halt the process.
        if (!window._.isNil(this.booking.assigned_translator)) {
          routerOpts.query = {
            translator_id: this.booking.assigned_translator.id
          };
        }

        this.$router.push(routerOpts);
      }, // End of handleClickFinancialAdjustment() method


      /**
       * Handler when the Booking Availability button was clicked.
       * @return {void}
       */
      handleClickBookingAvailability () {
        let id = this.$route.params.id;
        this.$router.push({
          name: 'booking-availability',
          params: {
            id
          }
        });
      },

      handleClickViewBookingResources () {
        let id = this.$route.params.id;
        this.$router.push({
          name: 'resources.booking',
          params: {
            id
          }
        });
      },

      handleClickBookingTrailLogsAudit () {

        let id = this.$route.params.id;
        this.$router.push({
          name: 'booking-histories-audit',
          params: {
            id
          }
        });

      },

      /**
       * Handler when the View Translator button was clicked.
       *
       * @return {void}
       */
      handleViewTranslator () {
        let id = this.$route.params.id;
        this.$router.push({
          name: 'booking.details.translator',
          params: {
            id
          }
        });
      }, // End of handleViewTranslator() method

      handleAddComment () {
        let data = {
          comment: this.comment,
          booking_id: this.booking.id
        };
        this.$store.dispatch('booking/addComment', data).then(r => {
          this.fetchBooking(this.$route.params.id);
        });
        this.comment = '';
      },
      handleUpdateComment () {
        let comment = this.selectedComment;
        comment.comment = this.comment;
        let response = this.$store.dispatch('booking/updateBookingComment', comment);
        if (response) {
          this.isUpdate = false;
          this.comment = '';
        }
      },
      handleGetComment (comment) {
        this.comment = comment.comment;
        this.isUpdate = true;
        this.selectedComment = comment;

      },
      handleCancelEdit () {
        this.comment = '';
        this.isUpdate = false;
        this.selectedComment = '';
      },
      handleDeleteComment (comment) {
        let key = _.findIndex(this.comments, {
          id: comment.id
        });

        let response = this.$store.dispatch('booking/deleteBookingComment', comment.id);
        if (response) {
          this.comments.splice(key, 1);
        }

      },
      handleClickComlogs () {
        let id = this.$route.params.id;
        this.$router.push({
          name: 'comlogs.list',
          params: {
            id
          }
        });

      },
      formatDate (date) {
        return moment(date).fromNow();
      },
      postWithdrawTranslator (v) {
        this.$confirm(this.$t('withdraw_confirmation'), this.$t('warning'), {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
          type: 'warning'
        }).then(() => {
          this.withdrawTranslator({booking_id: v})
            .then(() => {
              this.$store.dispatch('booking/getBooking', this.$route.params.id);
            });
        }).catch(() => {
          console.log('canceled');
        });

      },
      postRecreateBatches (v) {
        this.recreateBatches(v);
      },
      postCancelBooking(v) {
        this.$confirm(this.$t('cancel_booking_warning'), this.$t('warning'), {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
          type: 'warning'
        }).then(() => {
          this.cancelBooking(v)
            .then(() => {
              this.fetchBooking(this.booking.id);
            });
        }).catch(() => {
          console.log('canceled')
        });

      },

      showTranslatorNotPickingUp () {
        this.showNotPickupModal = true;
      },

      handleResendNotifToAll () {
        let id = this.$route.params.id;
        this.isLoading = true;
        this.resendNotif({
          id
        }).then(() => {
          this.isLoading = false;
          this.$notify({
            type: 'success',
            message: 'Notifications re-sent.'
          });
        });
      },
      updateBookingListener (data) {
        console.log(this.booking);
        if (data.booking.id == this.$route.params.id) {
          this.booking = _.merge(this.booking, data.booking);
          this.$store.commit('booking/setUpdateEvent', this.booking);
        }
      },

      /**
       * @returns {void}
       */
      updateBookingEvent () {
        if (window.WS) {
          window.WS.subscribe('booking.updated')
            .listen('booking.updated', this.updateBookingListener);
        }
      },

      updateSwitch (e, field) {
        let params = {
          id: this.booking.id,
          is_force_specific_translator_value: !isEmpty(this.booking.specific_translators) ? true: false,
          is_force_alternative_languages_value : !isEmpty(this.booking.alternative_languages) ? true: false,
        };
        params[field] = e ? 1 : 0;

        this.updateBooking(params);
      },

      handleFollowUp () {
        this.showTodoForm = true;
      },
      handleClikTranslatorNotPickup () {

        this.readEntry({
          user_type: 'translator',
          id: this.booking.assigned_translator.translator_id,
          extendParams: {
            include: 'warnings'
          }
        });
        this.showNotPickupModal = true;
      },
      handleAddLowFeedbackIssue(){
        let param = {

          type: 'low_feedback',
          created_by: JSON.parse(localStorage.getItem('app_user_data')).id,
          booking_id: this.booking.id,

      }
        this.$confirm(this.$t('this_will_add_open_issue_at_action_manager'), 'Warning', {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
          type: 'warning'
        }).then(() => {
          this.addLowFeedbackIssue(param).then(r => {
            console.log(r)
          });
        }).catch(() => {
          // Silence is golden.
        });

      },
      /**
       * Lock booking method which will create entry in the model
       * for locking current status
       */
      lockBooking(payload) {
        this.showLockedBookingWarningModal =! this.showLockedBookingWarningModal;
        let option = {
          comment: payload.comment,
          is_locked: payload.is_locked,
          is_manual: 1,
          booking_id: this.$route.params.id,
          user_id: JSON.parse(localStorage.getItem('app_user_data')).id
        }
        this.createOrUpdateBookingLock(option).then((r) => {
          this.showLockedBookingModal = false
        })
      },
      cloneBooking(){
        this.cloneBookingModal=true;
      },
      handleSelectEmail(){
        this.emailsModal=true;
      },
      /**
       * Method for disabling edit button
       * when booking is locked
       */
      handleDisableEditButton () {
        let locked = this.locked !== undefined && this.locked.length > 0 && this.locked[0].is_locked == 1 ? true : false;
        if (locked == true && this.locked[0].user_id != JSON.parse(localStorage.getItem('app_user_data')).id) {
          return locked;
        } else {
          return false;
        }
      },
      handleDeleteAssignment(v) {
        this.$confirm(this.$t('tfv_txt_remove_booking_resouce'), this.$t('warning'), {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
          type: 'warning'
        }).then(() => {
          this.deleteAssignment({id: v.id, booking_id: this.$route.params.id})
            .then(r => {
              populate(this, 'assignedResources', 'assignedResourcesData', {
                force: true,
                payload: {
                  id: this.$route.params.id
                },
                ref: 'Resource Listing (forced) from booking'
              });
            });
        }).catch(() => {
          // Silence is golden.
        });
      },
      postExpireBooking (v) {
        this.$confirm(this.$t('are_you_sure_want_booking_expired'), this.$t('warning'), {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
          type: 'warning'
        }).then(() => {
          this.expireBooking(v)
            .then(() => {
              this.fetchBooking(this.booking.id);
            });
        }).catch(() => {
          // Silence is golden.
        });
      },

      ...mapActions('bookingBatches', {
        getBatchStatus:'getBatchStatus'
      }),
      ...mapActions('booking', {
        fetchBooking: 'getBooking'
      }),
      updateBatchEvent () {
        window.WS.subscribe('booking.' + this.$route.params.id).listen(
          'batches.status.updated',
          this.updateBatchListner
        );
      },
      updateBatchListner (data) {
        this.$store.commit('bookingBatches/setBatchesStatus', data.calculation.status);
        if (data.calculation.status === 'completed') {
          this.fetchBooking(this.$route.params.id);
        }
      },
      handleSendPartner(){
        let message = `This will send this booking to partner:  ${this.partner.name}, Continue?`;
        this.$confirm(message, this.$t('warning'), {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
          type: 'warning'
        }).then(() => {
          let payload={
            booking: this.booking,
            partner: this.partner
          }
          this.sendPartner(payload).then((r)=>{
            // this.handlePartnerComment(r.data.data);
          });

        }).catch(() => {
          // Silence is golden.
        });
      },
      handlePartnerComment(payload) {
        let data = {
          comment: `Related email: <a href="${app_dt_handesk_url}/tickets/${payload.id}" target="_blank">#${payload.id}</a> created`,
          booking_id: this.booking.id
        }
        this.$store.dispatch('booking/addComment', data).then(r =>{
          window.location.reload()
        });
      },
      handleDeleteBatches(bookingId) {
        this.$confirm(this.$t('tfv_txt_remove_batches'), this.$t('warning'), {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
          type: 'warning'
        }).then(() => {
          this.deleteBatches({id:bookingId});
        }).catch(() => {
          // Silence is golden.
        });
      },

      /**
       * Method to update Stop financial export switch
       * custom fields when turn off
       */
      updateFinancialSwitch (e) {
        let params = {
          id: this.booking.id,
          is_force_specific_translator_value: !isEmpty(this.booking.specific_translators) ? true: false,
          is_force_alternative_languages_value : !isEmpty(this.booking.alternative_languages) ? true: false,
        };
        params['stop_financial_export'] = e ? 1 : 0;

        if(!e){
          params['dispute_resolved_at'] = moment().format('YYYY-MM-DD HH:mm:ss');
        }

        this.updateBooking(params);
      },

    },// End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted() {
      populate(this, 'assignedResources', 'assignedResourcesData', {
        force: true,
        payload: {
          id: this.$route.params.id
        },
        ref: 'Resource Listing (forced) from booking'
      });
      this.updateBookingEvent();
      //this.updateBatchEvent();
      this.getBatchStatus({id:this.$route.params.id});
      this.getEmailsList();
      this.getPartnersData();
    }, // End of Component > mounted

    /*
    |--------------------------------------------------------------------------
    | Component > beforeDestroy
    |--------------------------------------------------------------------------
    */
    beforeDestroy () {
      if (window.WS) {
        //window.WS.unsubscribe('booking.'+this.$route.params.id);
        window.WS.unsubscribe('booking.updated');
      }
    }

  }; // End of export default
</script>

<style lang="scss">
  //noinspection CssUnknownTarget
  @import '~/scss/global/_variables.scss';

  .booking-detail-form {
    .comment-list {
      list-style-type: none;
      padding-inline-start: 0px;

      li {
        border-bottom: 1px #f0f0f0 solid;
      }
    }

    .top-10 {
      margin-top: 10px;
    }

    &__actions-dropdown-menu,
    &__translators-dropdown-menu,
    &__logs-dropdown-menu {
      li {
        font-weight: bold;

        &:hover {
          color: $app-primary-color !important;
          text-decoration: underline;
        }
      }
    }
  }

  .force-assign-select-translator-to-booking-modal {
    .el-message-box__message p {
      white-space: pre-line;
    }

    .el-message-box__status {
      top: -19px;
    }

    .el-message-box__status + .el-message-box__message {
      padding-left: 12px;
    }

    .el-message-box__title span {
      display: inline-block;
      padding-left: 31px;
    }
  }
</style>
