<template>
    <div class="app-form"
         :class="genBem(blockCls)">

        <el-form>
            <!-- Name / Adjustment being done -->
            <el-form-item :id="genBem(blockCls, 'name-field')"
                          :label="$t('tfv_fn_adjustment_being_done')" class="label-short">
                <el-input v-model="iValue.name"/>
            </el-form-item>

            <!-- Description / Explanation  -->
            <el-form-item :class="genBem(blockCls, 'description-field')"
                          :label="$t('tfv_fn_description') " class="label-short">
                <el-input v-model="iValue.description"/>
            </el-form-item>

            <!-- Amount -->
            <el-form-item :class="genBem(blockCls, 'quantity-field')"
                          :label="$t('tfv_fn_amount')"
                          class="label-short">
                <el-input v-model="iValue.quantity"/>
            </el-form-item>

            <!-- Per Unit -->
            <el-form-item :class="genBem(blockCls, 'price-field')"
                          :label="$t('tfv_fn_per_unit')"
                          class="label-short">
                <el-input v-model="iValue.price"/>
            </el-form-item>

            <!-- Add Button -->
            <el-form-item :class="genBem(blockCls, 'update-btn')">
                <el-button type="primary"
                           @click="handleClickUpdate">
                    {{ $t('save') }}
                </el-button>
            </el-form-item>
        </el-form>

    </div>
</template>

<script>


  export default {

    props: {

      value: {
        type: Object,
        required: true
      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-preview-compose-prefill-form',
      }
    },


    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      iValue: {
        get () { return this.value },
        set (v) { this.$emit('input', v); },
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      handleClickUpdate () {
        this.$emit('update');
      }
    },

  }
</script>