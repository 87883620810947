/*
|--------------------------------------------------------------------------
| store > bread > actions
|--------------------------------------------------------------------------
|
| This file contains the actions property of current Vuex scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {extendObjParams, mapEndpoint, sd} from '~/js/helpers/Common';
import APICaller from '~/js/helpers/APICaller';
import {Notification} from 'element-ui';
import i18n from '~/js/i18n';
import {notifError} from '~/js/helpers/Notification';

export default {

  /**
   * Method with a purpose of generically reaching out to API
   * and doing the action BROWSE AS OPTIONS.
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @return {Promise}
   */
  browseAsOptionsGeneric (context, payload = {}) {

    // Define fallback value for context.state.api.browseAsOptions
    let browseAsOptions = sd(context.state.api.browseAsOptions, {});
    let browse = context.state.api.browse;
    let method = sd(browseAsOptions.method, browse.method);
    let endpoint = mapEndpoint(sd(browseAsOptions.path, browse.path), payload);
    let params = extendObjParams(sd(browseAsOptions.params, browse.params), payload);

    _.unset(params, 'page'); // Remove page value if it is defined.

    return new Promise((resolve, reject) => {
      APICaller({method, endpoint, params})
        .then((r) => {
          resolve(r);
        })
        .catch((e) => {
          reject(e)
        });
    });
  },

  /**
   * Load All Items with no pagination
   *
   * @param  {object} context - the context of current
   * \Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @return {Promise}
   */
  browseAsOptions (context, payload = {}) {

    return new Promise((resolve, reject) => {
      context.dispatch('browseAsOptionsGeneric', payload)
             .then((r) => {
               context.commit('setListAsOptions', r.data);
               resolve(r);
             })
             .catch((e) => {
               reject(e);
             });
    });
  },

  /**
   * Method with a purpose of generically reaching out to API
   * and doing the action BROWSE.
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @return {Promise}
   */
  browseGeneric (context, payload = {}) {

    let method = context.state.api.browse.method;
    let endpoint = mapEndpoint(context.state.api.browse.path, payload);
    let params = extendObjParams(context.state.api.browse.params, payload);

    return new Promise((resolve, reject) => {
      APICaller({method, endpoint, params})
        .then((r) => {
          resolve(r);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * Load All Items
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @return {Promise}
   */
  browse (context, payload = {}) {

    context.commit('setListLoading', true);

    return new Promise((resolve, reject) => {
      context.dispatch('browseGeneric', payload)
             .then((r) => {
               context.commit('setList', r.data);
               resolve(r);
             })
             .catch((e) => {
               reject(e);
             })
             .finally(() => {
               context.commit('setListLoading', false);
             });

    });
  },

  /**
   * Method with a purpose of generically reaching out to API
   * and doing the action READ.
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @return {Promise}
   */
  readGeneric (context, payload = {}) {

    let method = context.state.api.read.method;
    let endpoint = mapEndpoint(context.state.api.read.path, payload);
    let params = extendObjParams(context.state.api.read.params, payload);

    return new Promise((resolve, reject) => {
      APICaller({method, endpoint, params})
        .then((r) => {
          resolve(r);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * Get an Item according to the given ID
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @return {Promise}
   */
  read (context, payload = {}) {

    context.commit('setItemLoading', true);

    return new Promise((resolve, reject) => {
      context.dispatch('readGeneric', payload)
             .then((r) => {
               let raw = r.data.data; // Store into container so varname will be shorter.
               let data = raw[Object.keys(raw)[0]]; // Get the first member of the object.

               context.commit('setItem', {data});

               if (context.state.autoReflectItem) {
                 if(payload.isDuplicate){
                   data.isDuplicated=true;
                 }
                 context.commit('setCompose', data);
               }
               resolve(r);
             })
             .catch((e) => {
               reject(e);
             })
             .finally(() => {
               context.commit('setItemLoading', false);
             });
    });
  },

  /**
   * Method with a purpose of generically reaching out to API
   * and doing the action EDIT.
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @return {Promise}
   */
  editGeneric (context, payload) {

    let method = context.state.api.edit.method;
    let endpoint = mapEndpoint(context.state.api.edit.path, payload);
    let data = {};
    let isDataRaw = true;

    // Set the value for payload.data
    if (context.state.autoInjectPayload) {
      context.commit('setPayloadData');
      data = context.state.payloadData;
    } else {
      data = payload.data;
    }

    return new Promise((resolve, reject) => {
      APICaller({method, endpoint, data, isDataRaw})
        .then((r) => {
          resolve(r);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * Update an item.
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @return {Promise}
   */
  edit (context, payload) {

    return new Promise((resolve, reject) => {
      context.dispatch('editGeneric', payload)
             .then((r) => {

               if (context.state.autoUpdateList) {
                 context.dispatch('browse');
                 context.dispatch('browseAsOptions');
               }

               Notification.success({
                 title: i18n.t('success'),
                 message: i18n.t('n_entry_edited')
               });

               resolve(r);
             })
             .catch((e) => {
               Notification.error({
                 title: i18n.t('error'),
                 message: i18n.t('n_went_wrong')
               });
               reject(e);
             });
    });
  },

  /**
   * Method with a purpose of generically reaching out to API
   * and doing the action ADD.
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @return {Promise}
   */
  addGeneric (context, payload) {

    let method = context.state.api.add.method;
    let endpoint = context.state.api.add.path;
    let data = {};
    let isDataRaw = true;

    // Set the value for payload.data
    if (context.state.autoInjectPayload) {
      context.commit('setPayloadData');
      data = context.state.payloadData;
    } else {
      data = payload.data;
    }

    return new Promise((resolve, reject) => {
      APICaller({method, endpoint, data, isDataRaw})
        .then((r) => {
          resolve(r);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * Create an item.
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @return {Promise}
   */
  add (context, payload) {

    return new Promise((resolve, reject) => {
      context.dispatch('addGeneric', payload)
             .then((r) => {

               if (context.state.autoUpdateList) {
                 context.dispatch('browse');
                 context.dispatch('browseAsOptions');
               }

               Notification.success({
                 title: i18n.t('success'),
                 message: i18n.t('n_entry_added')
               });

               resolve(r);
             })
             .catch((e) => {
               notifError({message: e});
               reject(e);
             });
    });

  },

  /**
   * Method with a purpose of generically reaching out to API
   * and doing the action DELETE.
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @return {Promise}
   */
  deleteGeneric (context, payload) {

    let method = context.state.api.delete.method;
    let endpoint = mapEndpoint(context.state.api.delete.path, payload);
    let params = extendObjParams(context.state.api.delete.params, payload);

    return new Promise((resolve, reject) => {
      APICaller({method, endpoint, params})
        .then((r) => {
          resolve(r);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * Remove an item.
   *
   * @param  {object} context - the context of current Vuex scope.
   * @param  {object} [payload] - contains necessary values for the process.
   * @return {Promise}
   */
  delete (context, payload) {

    return new Promise((resolve, reject) => {
      context.dispatch('deleteGeneric', payload)
             .then((r) => {

               if (context.state.autoUpdateList) {
                 context.dispatch('browse');
                 context.dispatch('browseAsOptions');
               }

               Notification.success({
                 title: 'Success',
                 message: 'Item removed.'
               });

               resolve(r);
             })
             .catch((e) => {
               Notification.error({
                 title: i18n.t('error'),
                 message: i18n.t('n_went_wrong')
               });

               reject(e);
             });
    });
  }
}
