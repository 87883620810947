/*
|--------------------------------------------------------------------------
| Route Middlewares
|--------------------------------------------------------------------------
|
| This file contains the middlewares used in Vue Routes
| Kindly use this file for cleaner implementation.
|
*/
import store from '../store'

export default function (to, from, next) {

  /*
  |--------------------------------------------------------------------------
  | Helper Methods
  |--------------------------------------------------------------------------
  |
  | This section contains all the reusable methods that could be used
  | all throughout this middleware file.
  | Kindly put your helper methods in here for cleaner implementation.
  |
  */

  let init = (to, func) => {

    // Get matched paths
    let matched = [];
    for (let p of to.matched) {
      matched.push(p.path);
    }

    // Get user available paths
    let user = store.getters['auth/userData'];
    if (user.id != null && user.id !== '' && user.id !== 0) {
      let prom = store.dispatch('auth/getUserData');
      prom.then(() => {
        user = store.getters['auth/userData'];
        func(to, matched);
      });
    } else {
      func(to, matched);
    }
  };

  let hasMatched = (matched, paths) => {
    let hit = false;
    for (let m of matched) {
      for (let p of paths) {
        let fp = (p.slice(-1) === '/') ? p.slice(0, -1) : p;
        if (m == fp) {
          hit = true;
          break;
        }
      }
      if (hit) {
        break;
      }
    }
    return hit;
  };

  /*
  |--------------------------------------------------------------------------
  | Middlewares
  |--------------------------------------------------------------------------
  |
  | This section contains all the middleware applied globally to Vue JS Routes.
  | Kindly put all middleware conditions in this section for cleaner implementation.
  | Suggesting to following the conventions as well for uniformity.
  |
  */

  /**
   * is_public.
   */
  if (to.matched.some(record => record.meta.is_public)) {
    next();
  }

  init(to, function(to, matched) {

    if (!to.matched.some(record => record.meta.is_public)) {

      let hasAccessToken = store.getters['auth/hasAccessToken'];
      if (!hasAccessToken) {
        next({path: '/'})
      } else {
        if (hasMatched(matched, store.getters['auth/userRolePaths'])) {
          next({path: '/unauthorized'})
        } else {
          next();
        }
      }
    }
  });


} // End of default
