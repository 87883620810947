import {
  gep
} from "../../../helpers/Vuex";


let composeProps = {
  booking_id: '',
  type_id: '',
  type: {
    id: '',
    type_name: '',
    type: '',
    deadline: ''
  },

}
let apiPrefix = window.app_api_prefix_3;
let includes = [
  'translator',
  'booking.customer.customer_data',
  'comments.user',
  'booking.assigned_translator',
  'type',
  'user',
  'booking.comments',
  'booking.feedback',
  'booking.batches.entries.translator',
  'comments.assigned_users',
  'comments.files',
  'entities'
]
let extended = {
  include: includes.join(',')
}

let assignCustomerProps={
  comment: '',
  assign: true,
  deadline_date: '',
  user_id: '',
  assigned_actions: ['text']
}

export default {
  api: {
    browse: {
      method: 'get',
      path: gep('booking-issues', apiPrefix),
      params: {
        //per_page: 25
      }
    },
    read: {
      method: 'get',
      path: gep('booking-issues/{id}', apiPrefix),
      params: extended,
      data: {},
    },
    add: {
      method: 'post',
      path: gep('booking-issues/', apiPrefix),
      params: {}, data: {},
    },
  },
  autoInjectPayload: false,
  autoReflectItem: true,
  autoUpdateList: true,

  composeFresh: _.cloneDeep(composeProps),
  compose: _.cloneDeep(composeProps),

  payloadData: {}, // End of payloadData state

  item: {
    data: _.cloneDeep(composeProps),
    loading: false,
  },
  reviewType: '',
  assignCustomerComposeFresh: _.cloneDeep(assignCustomerProps),
  assignCustomerCompose: _.cloneDeep(assignCustomerProps),

}
