/*
|--------------------------------------------------------------------------
| Store > Settings > Maintenance Mode > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Maintenance Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';


let maintenanceMode = {
    name: 'maintenance-mode',
    value:
    {
        ct: {
            read_only: false,
            fully_closed: false,
        },
        mobile_app: {
            read_only: false,
            fully_closed: false,
        },
        admin_panel: {
            read_only: false,
        },
    }

}


export default {

    composeFresh: _.cloneDeep(maintenanceMode),
    compose: _.cloneDeep(maintenanceMode),

} // End of export default