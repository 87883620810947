/*
|--------------------------------------------------------------------------
| Store > emails-templates > getters
|--------------------------------------------------------------------------
|
| This file contains the getters property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';
import {isEmpty} from '~/js/helpers/Common'

export default {
  /**
   * Get the emails list state
   *
   *  * @param {object} state
   *  : current state of the vuex Object
   *
   * @return {array}
   */
  getEmails: (state) => state.emails,

  /**
   * get email object state
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   * @return {object}
   */
  getEmail: (state) => state.email,
  listPagination: (state) => state.listPagination,

}
