<template>
  <div class="confirmation-fills-container">
    <el-dialog
      title="File Upload"
      :visible.sync="iVisible"
      :append-to-body="true"
      :lock-scroll="true"
      :width="width"
    >
      <el-form
        id="confirmation-fills-form"
        v-loading="loading"
        @submit.native.prevent
        :rules="rules"
        ref="submitFile"
        :model="uploadData"
      >
        <el-row :gutter="20">
          <el-col >
            <div class="grid-content">
               <el-form-item prop="files">
<!--                  <el-upload-->
<!--                    class="upload-demo"-->
<!--                    ref="upload"-->
<!--                    :data="uploadData"-->
<!--                    :headers="importHeader"-->
<!--                    :on-success="onSuccess"-->
<!--                    :before-upload="setUploadData"-->
<!--                    :action="uploadUrl"-->
<!--                    :auto-upload="false"-->
<!--                  >-->
<!--                   <i class="el-icon-upload"></i>-->
<!--                  </el-upload>-->

                 <aws-file-upload v-model="uploadData.files" :drag="true" :multiple="false" @progress="handleProgress"></aws-file-upload>
                </el-form-item>
              </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="16">
          <el-input placeholder="Please enter file name" v-model="uploadData.name"></el-input>
           <el-select v-model="uploadData.type" placeholder="Select">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
            <el-button @click="handleChange('submitFile')" type="primary" :disabled="disabledSubmit">Submit</el-button>
            <el-button @click="iVisible = false" type="danger">cancel</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import AlertModal from "~/components/modals/booking/AlertModal";

import Multiselect from "vue-multiselect";
import { mapActions, mapGetters, mapState } from "vuex";
import { gep } from "~/js/helpers/Vuex";
import { extendObjParams, isEmpty } from "~/js/helpers/Common";

import AwsFileUpload from '~/components/displays/AwsFileUpload'
export default {
  components: {
    AlertModal,
    Multiselect,
    AwsFileUpload
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    getFiles: {
      type: Function
    },
    width: {
      type: String,
      default: "50%"
    },
    type: {
      type: String,
      default: ''
    },
    rules:{
      files:[{
        required: true,
        message: 'Please Select upload file'
      }],
    }
  },
  data() {
    return {
      uploadData: {
        name:'',
        type: '',
        files:[]
      },
         options: [{
          value: '1',
          label: 'Training Certificate'
        }, {
          value: '2',
          label: 'Police Certificate'
        }, {
          value: '3',
          label: 'Contract'
        }],
      showAlertModal: false,
      loading: false,
      message: "",
      importHeader: {
         Authorization: `Bearer ${localStorage.getItem("app_access_token")}`
      },
      disabledSubmit:false

    };
  },

  computed: {
    /**
     * Interface for this.visible
     */
    iVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit("update:visible", v);
      }
    },
     uploadUrl () {
      return this.type == 'municipality'
        ?  gep(`municipalities/${this.$route.params.id}/files`, "v3")
        :  gep(`users/${this.$route.params.id}/files`, "v3")

    }
  },
  methods: {
    ...mapActions('user', ['storeUserFile']),

    handleChange(form) {

      this.$refs[form].validate((valid) => {
          if(valid){
            let payload = {
              url: this.uploadUrl,
              data : {
                name: this.uploadData.name,
                type: this.uploadData.type,
                key: this.uploadData.files[0].uuid
              }
            }
            this.storeUserFile(payload).then(() => {
              this.onSuccess();
            })
          }
      });

    },
    setUploadData() {
        // this.importHeader ={
        //   Authorization : localStorage.getItem("app_access_token")
        // }
    },
    onSuccess() {
      this.iVisible = false;
      if(this.type == 'municipality') {
        this.getFiles();
      }

      this.uploadData = {
        name:'',
        type: '',
        files:[]
      }
    },

    handleProgress(e) {

      if (e > 0 && e < 100) {
        this.disabledSubmit = true
      } else {
        this.disabledSubmit = false
      }
    },
  }
};
</script>

<style lang="scss" scoped>
    //noinspection CssUnknownTarget
    @import '~/scss/global/_variables.scss';

#confirmation-fills-form {
  .el-input__inner {
    // background: inherit;
    border-radius: 5px;
    // border-bottom-right-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #d8dce5;
  }
}

.vue-input-tag-wrapper .input-tag {
  border: 0 !important;
  color: #5a5e66 !important;
  background-color: #d8dce5 !important;
}

.pac-container {
  z-index: 9999 !important;
}

.el-icon-upload:before {
    font-size: 119px;
}

#allow_fallback_type_notification {
  font-size: 12px;
  font-weight: bold;
  color: #f35531;
}
.el-dialog__header {
    // padding: 10px;
    // padding-bottom: 10px;
    background: $app-primary-color;
    text-align: center;
}
</style>

