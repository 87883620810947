<template>
    <div class="app-display goto-element">
        <a href="#" @click.stop.prevent="gotoElement">
            {{ label }}
        </a>
    </div>
</template>

<script>
  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      label: {
        type: String,
        default: ''
      },

      target: {
        type: String,
        default: ''
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      gotoElement () {
        const element = document.querySelector(this.target);
        if (element) {
          window.scroll({
            top: element.getBoundingClientRect().top + window.scrollY,
            behavior: 'smooth'
          });
        }
      }
    }
  }
</script>
