<template>
    <div class="app-page admin-permissions-form-page">

        <page-header :title="$t('admin_permissions')" :back-enabled="true" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div v-if="is_admin" class="app-col app-col full-width">
                        <admin-permissions-form-by-admin />
                    </div> <!-- /.app-col -->
                    <div v-else class="app-col app-col full-width">
                        <admin-permissions-form />
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page price-list-settings-page -->
</template>

<script>
export default {

    data() {
        return {
            is_admin: false
        }
    },

    mounted() {
        if (this.$route.path.includes('/admin-permissions/by-admin/')) {
            this.is_admin = true;
        }
    }
}
</script>
