<template>
  <div class="app-fields  app-splitter two-cols financial-charging-use-parts-fields">

    <el-form-item v-if="loop.length > 0"
                  v-for="x in loop"
                  :key="genElId(x)"
                  :id="genContainerId(genElId(x),formElId)"
                  class="label-block">
      <template slot="label">
        {{$t('price_for')}} {{btype}}
        <span class="app-badge badge-success"> > {{ x }} </span>
      </template>

      <!--suppress HtmlFormInputWithoutLabel -->
      <input :id="genFieldId(genElId(x),formElId)" v-model="iValue[genDataIndex(x)]" />
    </el-form-item>

  </div> <!-- /.app-fields financial-charging-use-parts-fields -->
</template>

<script>
  import isNil from 'lodash/isNil';
  import snakeCase from 'lodash/snakeCase';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      /**
       * The V-Model property of this component.
       */
      value: {
        type: Object,
        default() {
          return {}
        },
      },

      /**
       * The Form reference value to be appended into the element ID.
       */
      formElId: {
        type: String,
        default: '',
      },

      /**
       * The Translator Level value to be appended into the element ID.
       */
      tlvl: {
        type: String,
        default: 'none'
      },

      /**
       * The Booking Type value to be appended into the element ID.
       */
      btype: {
        type: String,
        default: '',
      },

      /**
       * The business_rules values usable inside this component.
       */
      businessRules: {
        type: Object,
        required: true,
      },

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data() {
      return {};
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },

      /**
       * Method to return the parts loop collection.
       * @return {array}
       */
      loop() {
        const bookingTimings = this.businessRules.booking_timings;

        if (isNil(bookingTimings) || isNil(bookingTimings[this.btype])) return [];

        let result = [];
        let min = bookingTimings[this.btype].minimum_hours;
        let step = this.businessRules.parts_step;
        let max = this.businessRules.parts_max;

        const isValid = (v) => !_.isNil(v) && v !== '' && v !== 0;

        if ( isValid(min) && isValid(step) && isValid(max) && step < max ) {
          min = parseFloat(min);
          step = parseFloat(step);
          max = parseFloat(max);

          while (min < max) {
            min = min + step;
            result.push(Math.round(min * 10) / 10); // Round up to 1 decimal
          }
        }

        return result;
      }

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * Helper method for defining the element ID.
       *
       * @param {string} suffix
       *   : value to append on the last part of the string.
       *
       * @return {string}
       */
      genElId(suffix) {
        return `use_parts_${this.tlvl}_${this.btype}_p_${snakeCase(suffix)}`;
        // return 'use_parts_' + this.btype + '_p_' + _.snakeCase(suffix);
      },

      /**
       * Helper method for defining the index for the data.
       *
       * @param {string} suffix
       *   : value to append on the last part of the string.
       *
       * @return {string}
       */
      genDataIndex(suffix) {
        return `x${snakeCase(suffix)}`;
        // return 'x' + _.snakeCase(suffix);
      },

    }, // End of Component > methods

  } // End of export default
</script>
