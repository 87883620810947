<template>
  <div class="app-form app-fields user-address-fields">

    <h3 class="text-center">{{$t('text_fill_address')}}</h3>

    <div class="app-splitter two-cols">
      <!-- Address 1 -->
      <el-form-item :id="genContainerId('address',formElId)"
                    :label="$t('address_1')" class="label-short">
        <booking-here-address-field
          :id="genFieldId('address',formElId)"
          v-model="iValue.address"
          @change="updateAddress"
          :placeholder="$t('select_address')"
        />
      </el-form-item>

      <!-- Address 2 -->
      <el-form-item :id="genContainerId('address_2',formElId)"
                    :label="$t('address_2')" class="label-short">
        <el-input :id="genFieldId('address_2',formElId)"
                  v-model="iValue.address_2"
                  :placeholder="$t('select_address2')"/>
      </el-form-item>

      <!-- Post Code -->
      <el-form-item :id="genContainerId('post_code',formElId)"
                    :label="$t('post_code')" class="label-short">
        <el-input :id="genFieldId('post_code',formElId)"
                  v-model="iValue.post_code"
                  :placeholder="$t('please_input_postal_code')"/>
      </el-form-item>

      <!-- City -->
      <el-form-item :id="genContainerId('city',formElId)"
                    :label="$t('tfv_city')" class="label-short">
        <el-input :id="genFieldId('city',formElId)"
                  v-model="iValue.city"
                  :placeholder="$t('please_select_city')"/>
      </el-form-item>

      <!-- Country -->
      <el-form-item :id="genContainerId('country_iso',formElId)"
                    :label="$t('country')" class="label-short">
        <el-select :id="genFieldId('country_iso',formElId)"
                   v-model="iValue.country_iso" filterable :placeholder="$t('please_select_country')">
          <el-option v-for="(v, k) in $store.getters['form/countryIsoOpts']"
                     :key="k"
                     :label="v"
                     :value="k"/>

        </el-select>
      </el-form-item>

      <!-- coordinates -->
      <el-form-item :id="genContainerId('coordinates',formElId)"
                    :label="$t('geo_coordiantes')" class="label-short">
        <el-input :id="genFieldId('coordinates',formElId)"
                  v-model="iValue.coordinates"
                  :placeholder="$t('latitude_longitude')"/>
      </el-form-item>

      <!-- office_area -->
      <el-form-item :id="genContainerId('office_area',formElId)"
                    :label="$t('office_area')" class="label-short">
        <el-input :id="genFieldId('office_area',formElId)"
                  v-model="iValue.office_area"
                  :placeholder="$t('please_input_office_area')"/>
      </el-form-item>

    </div> <!-- /.app-splitter two-columns -->

    <!-- Additional Info -->
    <el-form-item :id="genContainerId('additional_info',formElId)" class="label-large">
      <span>{{$t('additional_information')}}</span>
      <el-input :id="genFieldId('additional_info',formElId)"
                v-model="iValue.additional_info" type="textarea"
                :rows="5"
                :placeholder="$t('please_input_additional_information')"/>
    </el-form-item>

  </div> <!-- /.app-form app-fields user-address-fields -->
</template>

<script>
  import BookingHereAddressField from "~/components/forms/misc/BookingHereAddressField";
  import {mapGetters} from "vuex";
  import findKey from 'lodash/findKey';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      BookingHereAddressField
    },

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      formElId: {
        type: String,
        default: '',
      },

      value: {
        type: Object,
        default () {
          return {
            address: '',
            address_2: '',
            city: '',
            country_iso: 'SE',
            post_code: '',
            additional_info: '',
            coordinates: '',
          };
        }
      },

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        },
      },

    }, // End of Component > computed
    methods: {


      /**
       * @param {object} location
       * @returns {void}
       */
      updateAddress (location) {
        this.iValue.post_code = location.address.postalCode
        this.iValue.city = location.address.city || location.address.county;
        this.iValue.coordinates = location.displayPosition.latitude + ',' + location.displayPosition.longitude;
        this.iValue.country_iso = this.convertCountryISO(location.address.country, 2);
      },

      /**
       * @param {string} iso
       * @param {number} convertTo
       * @returns {string}
       */
      convertCountryISO (iso, convertTo) {
        if (typeof iso === 'string') {
          const isos = {
            SWE: 'SE'
          };
          let result;

          if (convertTo === 2) {
            result = isos[iso];

          } else if (convertTo === 3) {
            // noinspection JSCheckFunctionSignatures
            result = findKey(isos, iso);
          }

          if (result == null) {
            throw new Error(`No country found for ${iso} during conversion.`);
          } else {
            return result;
          }

        } else {
          throw new Error('Invalid paramter type');
        }
      }
    }

  } // End of export default
</script>
