<template>
    <div class="app-list sms-logs-list">
        <p>
            {{$t('sms_logs')}}
        </p>

        <div class="list-base-table-top-controls">

            <div class="app-col full-width text-right col-container" >
                            <el-input placeholder="Search Records" v-model="search" style="max-width: 350px;">
                    <el-button type="primary" slot="append" icon="el-icon-fa fa-search" style="min-width: 50px;"></el-button>
                </el-input>
            </div> <!-- /.app-col -->

        </div>

        <el-table :data="mockData" border style="font-size: 12px">

            <el-table-column v-for="item in tableCols"
                             :key="item.prop"
                             v-if="isColEnabled(item.prop)"
                             :label="item.label"
                             :width="item.width">
                <template slot-scope="scope">
                    <div class="cell-content" :id="genColId(tableElId, item.prop, scope.row.booking_id)">
                        {{ scope.row[item.prop] }}
                    </div>
                </template>
            </el-table-column>

            <!-- Actions Column -->
            <el-table-column class-name="actions-col"
                             label="Actions">

                <template slot-scope="scope">
                    <el-button type="default" class="el-button el-button--default circle"
                    :id="scope.row.id+'details_btn'"
                    @click="handleActivity('details',scope.row.id, scope.row)">
                    <i class="fa fa-info"></i>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <div class="pagination-wrapper">
                <el-pagination
                        layout="prev, pager, next"
                        :total="1000">
                </el-pagination>
            </div> <!-- /.pagination-wrapper -->
        </div> <!-- /.pagination-container -->

        <base-details-modal
                :visible.sync="detailsVisible"
                custom-class="sms-logs-list-modal"
                :extra-rows="tableCols"
                :append-to-body="false"
                :info="detailsInfo">
        </base-details-modal>
    </div> <!-- /.app-list invoice-list -->
</template>

<script>
    import {genFieldId, genContainerId, genColId} from "../../../js/helpers/Html";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                search: '',
                tableElId: 'sms-logs-list',

                tableCols: [
                    {prop: 'sender', label: 'Sender'},
                    {prop: 'recipient', label: 'Recipient'},
                    {prop: 'message', label: 'Message', width: '500'},
                    {prop: 'job_id', label: 'Booking ID'},
                    {prop: 'type', label: 'Type'},
                    {prop: 'created_at', label: 'Created At'}
                ],

                hiddenCols: ['id'],

                mockData: [
                    {
                        id: 1,
                        sender: '+46766862235',
                        recipient: '+46705139637',
                        message: 'Hej! Ny telefontolkning tillgänglig! 18.07.2018 kl 12:45. Tolkningen beräknas till 30min Svara med "Ja 72349" om du kan ta den. Tack DigitalTolk',
                        job_id: '72349',
                        type: 'Outgoing',
                        created_at: '2018-07-18 12:42:32',
                    },
                    {
                        id: 2,
                        sender: '+46766862235',
                        recipient: '+46705139637',
                        message: 'Hej! Ny telefontolkning tillgänglig! 18.07.2018 kl 12:45. Tolkningen beräknas till 30min Svara med "Ja 72349" om du kan ta den. Tack DigitalTolk',
                        job_id: '72349',
                        type: 'Outgoing',
                        created_at: '2018-07-18 12:42:32',
                    },
                    {
                        id:3,
                        sender: '+46766862235',
                        recipient: '+46705139637',
                        message: 'Hej! Ny telefontolkning tillgänglig! 18.07.2018 kl 12:45. Tolkningen beräknas till 30min Svara med "Ja 72349" om du kan ta den. Tack DigitalTolk',
                        job_id: '72349',
                        type: 'Outgoing',
                        created_at: '2018-07-18 12:42:32',
                    },
                    {
                        id:4,
                        sender: '+46766862235',
                        recipient: '+46705139637',
                        message: 'Hej! Ny telefontolkning tillgänglig! 18.07.2018 kl 12:45. Tolkningen beräknas till 30min Svara med "Ja 72349" om du kan ta den. Tack DigitalTolk',
                        job_id: '72349',
                        type: 'Outgoing',
                        created_at: '2018-07-18 12:42:32',
                    },
                    {
                        id:5,
                        sender: '+46766862235',
                        recipient: '+46705139637',
                        message: 'Hej! Ny telefontolkning tillgänglig! 18.07.2018 kl 12:45. Tolkningen beräknas till 30min Svara med "Ja 72349" om du kan ta den. Tack DigitalTolk',
                        job_id: '72349',
                        type: 'Outgoing',
                        created_at: '2018-07-18 12:42:32',
                    },

                ],

                detailsVisible: false,
                detailsInfo: {},

            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {}, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            /**
             * Helper method to check wether the column is enabled in the options or not.
             *
             * @param {string} key
             *   The switch value to check for whether to enable/disable a column.
             *
             * @return {boolean}
             */
            isColEnabled(key) {
                let r = true;

                if (_.includes(this.hiddenCols, key)) {
                    r = false;
                }

                return r;

            }, // End of isColEnabled() method

            // Map Helpers
            genFieldId: genFieldId,
            genContainerId: genContainerId,
            genColId: genColId,

            handleActivity(mode, rowIndex, rowItem) {
                this.itemInfo = rowItem;

                switch (mode) {
                    case 'details':
                        this.detailsVisible = true;
                        this.detailsInfo = rowItem;
                        break;

                    case 'edit':
                        this.composeMode = 'edit';
                        this.composeTitle = this.$t('edit');
                        this.iComposeForm = _.cloneDeep(this.composeFresh); // Reset form, and then map defaults
                        this.iComposeForm = mapProps(rowItem.attributes, this.iComposeForm, {
                            byRef: false
                        });
                        this.composeVisible = true;
                        break;

                    case 'delete':
                        if (this.methodDelete !== false) {
                            this.methodDelete(rowItem);
                        } else {
                            this.handleDelete(rowItem);
                        }
                        break;

                    default:
                        throw new Error('No mode specified in the activity');
                }
            }
        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
        }, // End of Component > mounted

    } // End of export default
</script>
