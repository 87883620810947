<template>
  <div class="confirmation-fills-container">
    <el-dialog
      :visible.sync="iVisible"
      :append-to-body="true"
      :lock-scroll="true"
      :width="width"
    >
    <div id= "heading">
      <h2 v-if="isNotifToAll">Are you sure you want resend to all translators?</h2>
      <h2 v-else>Are you sure you want resend to {{countBatch}} translator? </h2>
    </div>
    <div id ="Notifbody">
      <el-table :data="date" style="width: 100%">
        <el-table-column style="color: antiquewhite;"  prop="date" label="Date" width="80"></el-table-column>
        <el-table-column prop="time" label="Time" width="80"></el-table-column>
        <el-table-column prop="message" label="Previous this booking has been sent"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="iVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleConfirm">Confirm</el-button>
      </span>
    </div>
    </el-dialog>
  </div>
</template>

<script>
import AlertModal from "~/components/modals/booking/AlertModal";

import Multiselect from "vue-multiselect";
import { mapActions, mapGetters, mapState } from "vuex";
import { extendObjParams, isEmpty } from "~/js/helpers/Common";

export default {
  components: {
    AlertModal,
    Multiselect
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isNotifToAll: {
        type: Boolean,
        default: false
    },
    width: {
      type: String,
      default: "50%"
    },
    date: {
      type: Array,
      default: []
    }, 
    handleResendToAll: {
        default: function(){}
    },
    handleResendToSome: {
        default: function(){}
    },
    countBatch: {
        type: Number,
        default: 0
    }
  },
  data() {
    return {
      showAlertModal: false,
      loading: false,
      message: "",
    };
  },

  computed: {
    /**
     * Interface for this.visible
     */
    iVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit("update:visible", v);
      }
    }
  },
  methods: {
    ...mapActions({
      notiflist: "booking/fetchNotifHistoryList",
      fetchCustomers: "booking/fetchCustomers"
    }),
    handleConfirm() {
        if(this.isNotifToAll == true) {
            this.handleResendToAll();
        }
        else {
        this.handleResendToSome(); 
        }
    }
  }, 
  mounted() { }
};
</script>

<style lang="scss">
#Notifbody th {
    color: #45b78a;
}
#heading {
     color:#45b78a;
     text-align: center;
  .el-input__inner {
    // background: inherit;
    border-radius: 5px;
    // border-bottom-right-radius: 5px;
    border: 1px solid #d8dce5;
  }
}
</style>