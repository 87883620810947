<template>
   <div :class="`app-row ${iRefs}`" :id="iRefs" >
     <div class="app-col full-width">
    <h3>{{iTitle}}</h3>
    <el-button type="default" id="show-modal" @click="handleAdd" icon="fa fa-plus">
    {{$t('add')}}</el-button>

    <div class="app-list booking-list">
        <el-table :data="listData" style="width: 100%">
          <el-table-column :label="$t('name')">
            <template slot-scope="scope">
              <span>{{ showName(scope) }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('course_code')">
            <template slot-scope="scope">
              <span>{{ showCourseCode(scope) }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('description')">
            <template slot-scope="scope">
              <span>{{ showDescription(scope) }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('language')">
            <template slot-scope="scope">
              <span>{{ showLanguage(scope) }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('grade')">
            <template slot-scope="scope">
              <span>{{ showGrade(scope) }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('tfv_course_coordinator')">
            <template slot-scope="scope">
              <span>{{ showCourseCode(scope) }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('trainer')">
            <template slot-scope="scope">
              <span>{{ showTrainer(scope) }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('hours_of_raining')">
            <template slot-scope="scope">
              <span>{{ showHoursOfTraining(scope) }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('university_credits')">
            <template slot-scope="scope">
              <span>{{ showUniversityCredits(scope) }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('tfv_excution_start')">
            <template slot-scope="scope">
              <span>{{ showExecutionFrom(scope) }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('tfv_excution_end')">
            <template slot-scope="scope">
              <span>{{ showExecutionTo(scope) }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')" width="130px">
            <template slot-scope="scope">
                <el-button class="circle" type="success" :title="$t('edit')" @click.stop="handleEdit(scope)">
                    <span class="fa fa-pencil"></span>
                </el-button>
                <el-button class="circle" type="danger" :title="$t('remove')" @click.stop="handleDelete(scope)">
                    <span class="fa fa-trash"></span>
                </el-button>
            </template>
          </el-table-column>

        </el-table>

    </div> <!-- /.app-list booking-list -->
    </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {isEmpty,sd} from "~/js/helpers/Common";
export default {
  name:'userBookingListPanel',
  props:{
    title:{
      type:String,
      default: 'Translator Trainings'
    },
    refs:{
      default:'training'
    }
  },
  data(){

    const _this = this;
    return {
    }
  },
  computed:{

    iTitle(){
      return this.title
    },
    iRefs(){
      return this.refs
    },
    ...mapGetters('trainings', ['listData', 'listPagination'])
  },
  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {
    this.getList({
      user_translator_id: this.$route.params.id
    });
  }, // End of Component > mounted
  methods:{
    ...mapActions('trainings',{
      getList: 'getTrainingRecordsByTranslator',
      deleteEntry: 'deleteTrainingRecordByTranslator'
    }),
    handleEdit (scope) {
      this.$router.push({path: '/training-records/edit/' + scope.row.id});
    },
    handleDelete (scope) {

      this.$confirm('This will delete the entry. Continue?', this.$t('warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.deleteEntry({
          id: scope.row.id,
          user_translator_id: this.$route.params.id
        }).then(response => {
          // code here
        });
      }).catch(() => {
        // Silence is golden.
      });

    }, // End of handleDelete() method
    handleAdd(){
      this.$router.push({path: '/training-records/add/' + this.$route.params.id});
    },

    showName(scope) {
      if (scope.row.training != null) {
        return scope.row.training.name;
      }
      return '';
    },

    showCourseCode(scope) {
      if (scope.row.training != null) {
        return scope.row.training.course_code;
      }
      return '';
    },

    showDescription(scope) {
      if (scope.row.training != null) {
        return scope.row.training.description;
      }
      return '';
    },

    showLanguage(scope) {
      return scope.row.language;
    },

    showGrade(scope) {
      return scope.row.grade;
    },

    showCourseCoordinator(scope) {
      return scope.row.course_coordinator;
    },

    showTrainer(scope) {
      return scope.row.trainer;
    },

    showHoursOfTraining(scope) {
      return scope.row.hours_of_training;
    },

    showUniversityCredits(scope) {
      return scope.row.university_credits;
    },

    showExecutionFrom(scope) {
      return scope.row.execution_from;
    },

    showExecutionTo(scope) {
      return scope.row.execution_to;
    }
  }
}
</script>
