<template>
    <el-select v-model="iValue"
               :placeholder="$t(iPlaceholder)"
               :ref="iRefs"
               :multiple="iMultiple"
               :collapse-tags="iCollapseTags"
               @focus="getedFocus"
               @visible-change="switchFocus"
               :size="size"
               remote
               :remote-method="searchTranslator"
               :loading="searchTranslatorLoading" clearable auto-complete="off"
               filterable
    >
        <el-option v-for="i in searchTranslatorResult" :key="i.id" :label="i.name +' - '+ i.email+' - '+ i.id"
            :value="i.id">
        </el-option>
    </el-select>

</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import { isEmpty } from '../../../../js/helpers/Common';
export default {
    props:{
        value: {
            default: '',
        },
        width:{
            type:String,
            default:'100%'
        },
        multiple:{
            type: Boolean,
            default:false
        },
        collapseTags:{
            type: Boolean,
            default:false
        },
        placeholder:{
            type:String,
            default:'translator_search_type'
        },
        refs:{
            type:String,
            detault:'translator_select'
        },
      size: {
        type: String,
        default: 'medium'
      }
    },
    name: 'translator-select',
    data(){
        return {
            searchTranslatorLoading:false,
            searchTranslatorResult: [],
        }
    },
    computed:{
         iValue: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit('input', v);
            },
        },
         iWidth(){
             return this.wid
         },
         iCollapseTags(){
             return this.collapseTags
         },
         iMultiple(){
             return this.multiple
         },
         iPlaceholder(){
             return this.placeholder
         },
         iRefs(){
             return this.refs
         }
    },
    methods:{
        ...mapActions('booking',{
            filterTranslators:'filterTranslators'
        }),

        searchTranslator(query){
                if(query!==''){
                    this.searchTranslatorLoading = true;
                    let params ={
                        'filter[name]': query,
                        'all': true
                    }

                    setTimeout(()=> {
                       this.filterTranslators(params).then((response) => {

                            if(isEmpty(response)){
                                let params2 = {
                                    'filter[email]': query,
                                    'all': true
                                }
                                this.filterTranslators(params2).then((response) => {
                                    if(isEmpty(response)){
                                        let params3 = {
                                            'filter[id]': query,
                                            'all': true
                                        }
                                        this.filterTranslators(params3).then((response) => {
                                            this.searchTranslatorResult =  response
                                        })

                                        this.searchTranslatorResult =  response
                                    }
                                    this.searchTranslatorResult =  response

                                })
                            }

                            this.searchTranslatorResult =  response

                            this.searchTranslatorLoading = false;
                        })

                    },300)

                }else{
                    this.searchTranslatorResult = []
                }
            },
        customLabel ({ id, email, name }) {
            return `${email}`
        },
        clearAll () {
            this.iValue = []
        },
        getedFocus(e) {
                this.temporaryDom = e.target;
                },
        switchFocus(e) {
            if(e === false && this.temporaryDom) {
                this.temporaryDom.blur(); this.temporaryDom = null;
                }
            }
    }
}
</script>

<style lang="scss" scoped>



</style>

<style>
.multiselect, .multiselect__tags, .multiselect__single, .multiselect__input{
    font-size: 12px !important;
}
</style>
