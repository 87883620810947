/*
|--------------------------------------------------------------------------
| Store > User
|--------------------------------------------------------------------------
|
| A file that manages all the properties and abilities in relation
| to User Model and other related values.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {extendObj} from "../../helpers/Common";
import bread from "../bread";
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import API from '~/js/constants/api';

const state = {
    api: {
        browse: {
            customer: {method: 'get', path: API.CUSTOMERS, params: {per_page: 25}},
            translator: {method: 'get', path: API.TRANSLATORS, params: {per_page: 25}, data: {},},
            user: {
                method: 'get',
                path: API.USERS,
                params: {
                    'fields[users]': 'id,name,mobile,type,email,notes',
                    'filter[type]': '2,3',
                    include: 'translator_data'
                }
            },
        },

        read: {
            customer: {method: 'get', path: `${API.CUSTOMERS}/{id}`, params: {}},
            translator: {method: 'get', path: `${API.TRANSLATORS}/{id}`, params: {}},
            user: {method: 'get', path: `${API.USERS}/{id}`, params: {}, data: {}},
        },

        delete: {
            customer: {method: 'delete', path: `${API.CUSTOMERS}/{id}`, params: {}},
            translator: {method: 'delete', path: `${API.TRANSLATORS}/{id}`, params: {}},
            user: {method: 'delete', path: `${API.USERS}/{id}`, params: {}, data: {}},
        },

        // bcf stands for - Booking Confirm Fields (configuration values).
        bcf: {
            read: {method: 'get', path: API.BOOKING_CONFIRM_FIELDS},
            edit: {method: 'post', path: API.BOOKING_CONFIRM_FIELDS},
        }
    },

    autoUpdateList: true,
    trashedUsers:[],
    paginationTrashedUsers:{}
};

//const mutations = {};

export default {
    namespaced: true,
    state: extendObj(bread.state, state),
    getters: extendObj(bread.getters, getters),
    mutations: extendObj(bread.mutations, mutations),
    actions: extendObj(bread.actions, actions),
} // End of export default
