/*
|--------------------------------------------------------------------------
| Store > Messaging-Centre > Blogs > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
let composeProps = {
  id: "",
  author_id: "",
  content: "",
  title: "",
  status: "",
  tags: [],
  is_push_sent: 0,
  feature_image: '',
  category: [],
  is_featured: false,
  is_critical: 0,
  critical_message: ''
};

export default {
  blogs: [],
  blog: {},
  category:[],
  loading:false,
  composeFresh: _.cloneDeep(composeProps),
  compose: _.cloneDeep(composeProps)
};
