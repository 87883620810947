<template>
  <div class="app-list">
    <base-list-table 
				:table-data="entries" 
				:extra-cols="listCols" 
				:hasDetailsCol="false">
    </base-list-table>
  </div>
</template>

<script>
export default {
props:{
  batchesEntries:{
    type:Array,
    default(){
      return []
    }
  }
},
data(){
  return {
    listCols:[
      {
        label: 'Batch Id',
        prop: 'id',
        width:'',
        className:'id-col'
      },
      {
        label: 'Rejected By',
        prop: 'entries',
        width:'',
        className:'id-col',
        mutator(v){
          return v.map(e => e.translator.id + ' -' + e.translator.email+ ' - ' + e.translator.name)
        }
      },
    ]
  }
},
computed:{
  entries(){
    let entries =this.batchesEntries.filter(e => e.entries.some(i => i.rejected_at!==null))
    return entries
  }
}
}
</script>

<style>

</style>
