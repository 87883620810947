<template>
  <a
    :class="getBem(blockClass)"
    href="#"
    @click.prevent="handleClick"
  >
    <span>
        <template v-if="isSlotExists('label')">
        <slot name="label"></slot>
      </template>
      <template>
        {{ label }}
      </template>
      </span>

    <span v-if="isShowAscIcon" class="fa fa-caret-up"></span>
    <span v-else-if="isShowDescIcon" class="fa fa-caret-down"></span>
  </a>
</template>

<script>
  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      label: {
        type: String,
        default: ''
      },

      isSortable: {
        type: Boolean,
        default: false
      },

      col: {
        type: String,
        default: ''
      },

      sort: {
        type: Object,
        default () {
          return {
            by: '',
            order: ''
          };
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockClass: 'translator-batch-list-table-header-title'
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      iSort: {
        get () {
          return this.sort;
        },
        set (v) {
          this.$emit('update:sort', v);
        }
      },

      isShowAscIcon () {
        return this.iSort.by === this.col && this.iSort.order === 'asc';
      },

      isShowDescIcon () {
        return this.iSort.by === this.col && this.iSort.order === 'desc';
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * @returns {void}
       */
      toggleOrder () {
        if (this.iSort.order === '') {
          this.iSort.order = 'asc';

        } else if (this.iSort.order === 'asc') {
          this.iSort.order = 'desc';

        } else if (this.iSort.order === 'desc') {
          this.iSort.order = '';
        }
      },

      /**
       * @returns {void}
       */
      handleClick () {
        if (this.iSort.by === this.col) {
          this.toggleOrder();

        } else {
          this.iSort.order = 'asc';
        }


        let payload = {
          by: '',
          order: ''
        };

        if (this.iSort.order !== '') {
          payload.by = this.col;
          payload.order = this.iSort.order;
        }

        this.iSort = payload;
        this.$emit('click-sort', payload);
      },

      /**
       * @param {string} slot
       * @returns {boolean}
       */
      isSlotExists (slot) {
        return !!this.$slots[slot];
      }
    }
  };
</script>
