<template>
  <div class="app-page booking-page">

    <div class="app-page-top-bar">
      <!-- topbar  -->
      <div class="app-page-top-bar-wrapper">
        <div class="logo">
          <!--                    <img src="/images/defaults/brand-logo.png" alt="" srcset="">-->
        </div>
        <div class="top-bar-extras content-body">
          <ul>
            <li>
              <router-link to="/dashboard" title="Statistics">
                <span class="fa fa-bar-chart"></span> <span>{{$t('admin_panel')}}</span>
              </router-link>
            </li>
            <li>
              <el-dropdown>
                <span class="el-icon-setting" style="margin-right: 15px"></span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>{{$t('logout')}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--  end of Topbar -->

    <!-- Banner -->
    <section v-if="false">
      <div class="img-container">
        <div class="banner-text">
        </div>
      </div>
    </section>
    <!-- Banner -->

    <!-- Booking Form -->
    <div class="booking-form-section" v-loading="loading">
      <el-row>
        <el-col :span="24">
          <div class="content-body booking-form" @mouseenter="formFocusHandler(true)"
               @mouseleave="formFocusHandler(false)">
            <div class="form-container">
              <el-form :model="form" ref="booking_form" :validate-on-rule-change="false" :rules="rules">

                <el-row :gutter="5">
                  <el-col :md="3">
                    <el-button type="success" size="mini" @click="enableEmergency(is_immediate)"
                               :id="genFieldId('emergency_button', formElId)">
                      {{ (!is_immediate) ? $t('enable_emergency') : $t('disable_emergency') }}
                    </el-button>
                  </el-col>
                  <el-col :md="2">
                    <el-form-item :id="genContainerId('search_from',formElId)">
                      <el-select :id="genFieldId('search_from',formElId)"
                                 v-model="form.search_customer_by"
                                 placeholder="">
                        <el-option :label="$t('id')" value="id"/>
                        <el-option :label="$t('name')" value="name"/>
                        <el-option :label="$t('email')" value="email"/>
                        <el-option :label="$t('notes_2')" value="notes"/>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="16">
                    <el-form-item label="" prop="customer_id">
                      <el-select :disabled="new_customer" v-model="form.customer_id" filterable
                                 :placeholder="$t('customer_type_search')" remote ref="customer"
                                 :remote-method="searchCustomer" :loading="searchCustomerLoading"
                                 clearable>
                        <el-option v-for="i in searchResult" :key="i.id"
                                   :label="i.name +' - '+ i.email+' - '+ i.id" :value="i.id">
                        </el-option>
                      </el-select>
                    </el-form-item>

                  </el-col>
                  <el-col style="padding-top: 10px" v-if="false">
                    <el-form-item>
                      <el-switch style="display: block" v-model="new_customer"
                                 :inactive-text="$t('existing_customer')"
                                 :active-text="$t('new_customer')">
                      </el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :md="3" class="text-right">
                    <el-button type="success" size="mini" @click="resetForm" class="reset-button">
                      <small>{{ $t('reset') }}</small>
                    </el-button>
                  </el-col>
                </el-row>
                <el-row :gutter="5">
                  <el-col :md="3" v-if="form.type !== 'text_translation'"> &nbsp;</el-col>
                  <el-col :md="6">
                    <el-form-item prop="from_language_id">

                      <el-select v-model="form.from_language_id" :placeholder="$tc('language')"
                                 filterable @change="checkDialects" ref="language"
                                 @blur="hasDialects=false">
                        <el-option v-for="language in languages" :key="language.id"
                                   :label="language.name" :value="language.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="6" v-if="form.type === 'text_translation'">
                    <el-form-item prop="to_language_id">

                      <el-select v-model="form.to_language_id" :placeholder="$t('to_language')"
                                 filterable ref="to_language_id" multiple>
                        <el-option v-for="language in languages" :key="language.id"
                                   :label="language.name" :value="language.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <template v-if="is_immediate===0">
                    <el-col :xs="24" :sm="24" :md="3">
                      <el-form-item prop="date">
                        <el-date-picker :picker-options="datePickerOptions" type="date"
                                        v-model="form.date" :placeholder="$t('due_date')"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                        popper-class="day-picker-only"></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <template v-if="!['text_translation'].includes(form.type)">
                      <el-col :xs="24" :sm="24" :md="3">
                        <el-form-item prop="time">
                          <el-autocomplete-default-first v-model="form.time"
                                                         :placeholder="$t('from_time')"
                                                         :fetch-suggestions="handleFilterTime"
                                                         @select="handleTimeChange" @blur="handleTimeBlur"/>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="3" v-if="!['convey'].includes(form.type)">
                        <el-form-item prop="toTime">
                          <el-autocomplete-default-first v-model="toTime"
                                                         :placeholder="$t('to_time')"
                                                         :fetch-suggestions="handleFilterTo"
                                                         @select="handleToTimeChange" @blur="handleToTimeChange"/>

                        </el-form-item>
                      </el-col>
                    </template>
                  </template>
                  <template v-else>
                    <el-col :xs="24" :sm="24" :md="7">
                      <el-form-item prop="duration">
                        <el-select v-model="form.duration" placeholder="Hur länge?">
                          <el-option v-for="d in durations" :key="d.key" :label="d.value"
                                     :value="d.key">{{ d.value }}
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </template>

                  <el-col :xs="24" :sm="24" :md="is_immediate ? 8 : 6">
                    <el-form-item prop="type">
                      <el-radio-group
                        v-model="form.type"
                        :aria-label="$t('type')"
                        tabindex="0"
                        @chage="handleTypeChanged"
                      >
                        <el-radio label="phone">{{ $t('phone') }}</el-radio>
                        <el-radio label="physical">{{ $t('physical') }}</el-radio>
                        <el-radio label="video">{{ $t('video') }}</el-radio>
                        <el-radio label="convey">{{ $t('convey') }}</el-radio>
                        <el-radio label="text_translation">{{ $t('text_translation') }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- show if form is convey -->
                <template v-if="isConvey">
                  <el-row>
                    <el-col :xs="24" :sm="24" :md="3"> &nbsp;</el-col>
                    <el-col :xs="24" :sm="24" :md="15">
                      <el-form-item prop="convey.message">
                        <el-input v-model="form.convey.message"
                                  :placeholder="$t('input_conveyed_message')" type="textarea"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
<!--                  <el-row>-->
<!--                    <el-col :xs="24" :sm="24" :md="3"> &nbsp;</el-col>-->
<!--                    <el-col :xs="24" :sm="24" :md="15">-->
<!--                      <el-form-item>-->
<!--                        <el-input v-model="form.convey.additional_info"-->
<!--                                  :placeholder="$t('input_additional_info_for_translator')"-->
<!--                                  type="textarea">-->
<!--                        </el-input>-->
<!--                      </el-form-item>-->
<!--                    </el-col>-->
<!--                  </el-row>-->
                  <el-row :gutter="5">
                    <el-col :xs="24" :sm="24" :md="3"> &nbsp;</el-col>
                    <el-col :xs="24" :sm="24" :md="6">
                      <el-form-item prop="convey.phone">
                        <el-input v-model="form.convey.phone" :placeholder="$t('patient_phone')">
                        </el-input>
                      </el-form-item>
                    </el-col>

                  </el-row>
<!--                  <el-row>-->
<!--                    <el-col :md="3"> &nbsp;</el-col>-->
<!--                    <el-col :xs="24" :sm="24" :md="15">-->

<!--                      <aws-file-upload v-model="form.convey_files" :drag="true"-->
<!--                                       @progress="handleProgress"/>-->
<!--                    </el-col>-->
<!--                  </el-row>-->
                </template>
                <!-- end of convey form-->

                <!-- text translation -->
                <template v-if="form.type === 'text_translation'">

                  <text-translation-form v-model="form" :form-el-id="formElId"
                                         @progress="handleProgress"/>

                </template>
                <!-- text translation -->

                <el-row>
                  <el-col :xs="24" :sm="24" :md="3">
                    <el-button type="primary"
                               class="more-options-button"
                               @click="showMoreOptions(moreOptions)">
                      <template v-if="moreOptions">
                        <span class="fa fa-minus"></span>
                      </template>
                      <template v-else>
                        <span class="fa fa-plus"></span>
                      </template>

                      {{ $t('more_options') }}
                    </el-button>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="21" v-if="moreOptions">
                    <el-row :gutter="5">
                      <el-col :xs="24" :sm="24" :md="24" class="pb-col">
                        <template v-if="form.type === 'text_translation'">
                          <el-select v-model='form.language_pair_levels' multiple clearable
                                    :placeholder="$t('select_languages_level')">
                            <el-option value="deluxe">Deluxe</el-option>
                            <el-option value="non-certified">Non Certified</el-option>
                            <el-option value="certified">Certified</el-option>
                          </el-select>
                        </template>
                        <template v-else>
                          <el-select multiple clearable collapse-tags :collapse-tags-size="'medium'"
                                     v-model="translator_levels" id="translator_type" ref="translator_type"
                                     @change="changeSelected">
                            <el-option v-for="translator_type in translator_types"
                                       :key="translator_type.id" :label="translator_type.name"
                                       :value="translator_type.id" value-key="id"></el-option>
                          </el-select>
                        </template>
                      </el-col>

                      <el-col :xs="24" :sm="24" :md="12" class="pb-col">
                        <multiple-translator-select v-model="specific_translators" :multiple="true"
                                                    :placeholder="$t('specific_translators_type')"
                                                    refs="specific_translator">
                        </multiple-translator-select>

                      </el-col>

                      <el-col :xs="24" :sm="24" :md="12" class="pb-col" v-if="false">
                        <!--<multiple-translator-select v-model="exclude_translators" :multiple="true"
                                                    :placeholder="$t('excluded_translators_type')"
                                                    refs="excluded_translator">
                        </multiple-translator-select>-->

                      </el-col>

                      <el-col :xs="24" :sm="24" :md="12" class="pb-col">
                        <el-form-item>
                          <translator-select v-model="form.assigned_translator" ref="assignTransSelect"
                                             class="assigned-translator-select"
                                             :placeholder="$t('assigned_translator_type')"></translator-select>
                        </el-form-item>
                      </el-col>

                      <el-col :xs="24" :sm="24" :md="4" class="pb-col">
                        <el-select v-model="searchBy" placeholder="Select">
                          <el-option :label="$t('department')" value="dept"></el-option>
                          <el-option :label="$t('customer')" value="cust"></el-option>
                        </el-select>
                      </el-col>

                      <el-col :xs="24" :sm="24" :md="20" class="pb-col">
                        <template v-if="searchBy === 'cust'">
                          <el-select :disabled="new_customer" v-model="form.invoicing_department_id" filterable
                                     :placeholder="$t('label_send_invoice_to_customer_department') + ':'"
                                     remote
                                     ref="customer"
                                     :remote-method="searchCustomer" :loading="searchCustomerLoading"
                                     clearable>
                            <el-option v-for="i in searchResult" :key="i.id"
                                       :label="i.name +' - '+ i.email+' - '+ i.id"
                                       :value="i.customer_data.department_id">
                            </el-option>
                          </el-select>
                        </template>
                        <template v-else>
                          <department-search :showLabel="false" v-model="form.invoicing_department_id"/>
                        </template>

                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :xs="24" :ms="24" :md="24" class="text-right">
                    <el-button type="primary" icon="el-icon-fa fa-sign-in"
                               class="submit-button"
                               @click="handleCustomerVerification(form.customer_id)" :disabled="disabledSubmit">
                      {{ $t('submit') }}
                    </el-button>
                  </el-col>
                </el-row>


                <!--                              <el-row v-show="moreOptions==true">-->
                <!--                              -->

                <!--                              </el-row>-->

                <!-- Submit Button -->

              </el-form>
              <!-- /#login_form -->
            </div>
            <!-- /.form-container -->
          </div>
          <!-- /.content-body -->
        </el-col>
      </el-row>
      <!-- /.booking-form el-row -->
    </div>
    <!-- /.booking-form section -->

    <div v-if="false">
      <el-row :gutter="20" style="margin:15px 15px 50px;">
        <el-col :md="8">
          <el-card shadow="hover">
            <div
              class="highlight-img"
              style="background-image: url(https://dtolk.blob.core.windows.net/assets/cards/September2017/ZSVyZhLqfNZA5ieulEc0.png)"></div>
            <div class="title">
              <h2>Bli kund </h2>
            </div>
            <div class="border"></div>
            <div class="content">Det är enkelt att bli kund hos oss. Du kan betala direkt eller ansöka om
              kundnummer och få faktura.
            </div>
          </el-card>
        </el-col>
        <el-col :md="8">
          <el-card shadow="hover">
            <div
              class="highlight-img"
              style="background-image: url(https://dtolk.blob.core.windows.net/assets/cards/September2017/6POPexWxcphLvGKhULQ1.png)"></div>
            <div class="title">
              <h2>Välj rätt tolk och metod</h2>
            </div>
            <div class="border"></div>
            <div class="content">Beroende på uppdragets karaktär väljer du den kompetens och metod som
              krävs. Vi hjälper dig gärna om du är osäker på vad som blir bäst.
            </div>

          </el-card>
        </el-col>
        <el-col :md="8">
          <el-card shadow="hover">
            <div
              class="highlight-img"
              style="background-image: url(https://dtolk.blob.core.windows.net/assets/cards/September2017/i0d9JeFGF8aY1wIPcqrL.png)"></div>
            <div class="title">
              <h2>Tips för lyckad tolkning </h2>
            </div>
            <div class="border"></div>
            <div class="content">Tolkningens resultat beror på många faktorer. Det finns många saker som du
              som beställare kan tänka på för att öka förutsättningarna.
            </div>

          </el-card>
        </el-col>
      </el-row>
    </div>
    <language-options-modal :visible.sync="showLanguageOptions" :dialects='dialects' :width="modalWidth">
    </language-options-modal>
    <confirmation-fills-modal :visible.sync="showConfirmationsFills" :is-physical="form.type ==='physical'"
                              :language="language" :change-address.sync="changeAddress"
                              :translator-levels="translatorLevel"
                              :width="modalWidth" :appointmentTemplate="appointmentTemplate"/>
    <create-department :afterCreateHandler="handleUserCreationChange" :visible.sync="showCreateDepartment"/>
  </div>

</template>

<script>

  import {
    Notification
  } from "element-ui";
  import {
    mapGetters,
    // mapState,
    // mapMutations,
    mapActions,
    mapState
  } from 'vuex';

  import LanguageOptionsModal from '~/components/modals/booking/LanguageOptionsModal'
  import {
    genFieldId,
    genContainerId
  } from '~/js/helpers/Html'
  import {
    isEmpty
  } from '~/js/helpers/Common'
  import {
    populate,
    forcePopulate
  } from "~/js/helpers/Vuex"

  import APICaller from '~/js/helpers/APICaller';
  import AwsFileUpload from '~/components/displays/AwsFileUpload'
  import TextTranslationForm from '~/components/forms/booking/TextTranslation/FieldsForm'

  export default {
    components: {
      LanguageOptionsModal,
      AwsFileUpload,
      TextTranslationForm
    },
    /*
    |--------------------------------------------------------------------------
    | component > data
    |--------------------------------------------------------------------------
    */
    data () {
      var checkTo = (rule, value, callback) => {
        console.log(this.$refs.booking_form.model)
        let dueTime = window.moment(this.$refs.booking_form.model.time, 'HH:mm')
        let val = window.moment(this.toTime, 'HH:mm')

        if (value === '') {
          return callback(new Error('Please input To time value'));
        } else {
          callback()
        }
      }
      const a = this.new_customer
      return {
        toTime: '',
        datePickerOptions: {
          disabledDate (date) {
            let day = moment();
            let yesterday = moment().subtract(1, 'day')
            return date <= yesterday
          },
          firstDayOfWeek: 1
        },
        timeOptions: {
          selectableRange: '00:00 - 23:59'
        },
        formElId: 'booking-form',
        moreOptions: false,
        showConfirmationsFills: false,
        showLanguageOptions: false,
        hasDialects: false,
        // isConvey: false,
        isTextTranslation: false,
        hideToTime: false,
        isFormFocused: false,
        searchCustomerLoading: false,
        searchBy: 'cust',
        searchResult: [],
        selectedCustomer: {},
        disabled: false,
        durations: [{
          key: 15,
          value: '15 min'
        },
          {
            key: 30,
            value: '30 min'
          },
          {
            key: 45,
            value: '45 min'
          },
          {
            key: 60,
            value: '1 timme'
          },
          {
            key: 90,
            value: '1.5 timme'
          },
          {
            key: 120,
            value: '2 timme'
          },
          {
            key: 150,
            value: '2.5 timme'
          },
          {
            key: 180,
            value: '3 timme'
          },
          {
            key: 210,
            value: '3.5 timme'
          },
          {
            key: 240,
            value: '4 timme'
          },
          {
            key: 270,
            value: '4.5 timme'
          },
          {
            key: 300,
            value: '5 timme'
          },
          {
            key: 330,
            value: '5.5 timme'
          },
          {
            key: 360,
            value: '6timme'
          },
          {
            key: 420,
            value: '7 timme'
          },
          {
            key: 480,
            value: '8 timme'
          },
        ],
        enabled: 1,
        dialects: [],

        customer: null,
        timeRange: '00:00 - 23:59',
        modalWidth: '60%',
        translator_levels: [1, 2, 3, 4, 5, 6],
        changeAddress: false,
        temporaryDom: '',
        exclude_translators: [],
        specific_translators: [],
        new_customer: false,
        showCreateDepartment: false,
        fileList: [],
        is_general_login: false,
        provided_email: '',
        appointmentTemplate: false,
        disabledSubmit: false

      };
    }, // End of component > data

    /*
    |--------------------------------------------------------------------------
    | component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('booking', {
        form: 'bookingForm'
      }),
      ...mapGetters({
        // form: 'booking/getForm',
        is_immediate: 'booking/isImmediate',
        languages: 'language/getLanguages',
        appointmentTemplates: 'language/getAppointmentTemplates',
        translator_types: 'booking/getTranslatorTypes',
        translatorLevel: 'booking/getTranslatorLevels',
        // translator_levels: 'booking/getEnabledTranslatorLevels',
        customersList: 'booking/getCustomersList',
        translators: 'booking/getTranslatorsList',
        departments: 'booking/getDepartmentsList',
        companies: 'booking/getCompaniesList',
        getCustomer: 'booking/getCustomer',
        loading: 'booking/getLoading',
        departmentsListLoading: 'booking/getDepartmentsListLoading',
        customersListLoading: 'booking/getCustomersListLoading',
        bookingField: 'booking/getBookingField',
        confirmationFills: 'booking/getConfirmationFills',
      }),
      language: {
        get () {
          return this.languages.find((obj) => {
            return obj.id === this.form.from_language_id
          })
        }
      },

      /**
       * @return {array}
       */
      timeOpts () {
        let start = moment().startOf('day');
        let end = moment().endOf('day');
        let result = [];
        do {
          result.push({
            value: start.format('HH:mm')
          });
          start.add(30, 'minutes');
        } while (end.diff(start) > 0)
        return result;
      },

      now () {
        return moment().format('YYYY-MM-DD HH:mm:ss')
      },
      rules () {
        return {
          customer_id: [{
            required: !this.new_customer,
            message: this.$t('select_customer')
          }],
          from_language_id: [{
            required: true,
            message: this.$t('select_language')
          }],
          date: [{
            required: true,
            message: this.$t('input_due_date')
          }],
          time: [{
            required: this.form.type !== 'text_translation',
            message: this.$t('input_due_time')
          }],
          toTime: [{
            validator: this.checkTo,
            trigger: 'blur'
          }],
          duration: [{
            required: true,
            message: this.$t('input_duration')
          }],
          type: [{
            required: true,
            message: this.$t('select_booking_type')
          }],
          convey: {
            message: [{
              required: this.form.type === 'convey',
              message: this.$t('input_conveyed_message')
            }],
            phone: [{
              required: this.form.type === 'convey',
              message: this.$t('input_patient_phone')
            }]
          },
          descriptions: [{
            required: this.form.type === 'text_translation',
            message: this.$t('input_description')
          }],
          text_translation_files: [{
            required: this.form.type === 'text_translation',
            message: this.$t('input_add_file')
          }],
          to_language_id: [{
            required: this.form.type === 'text_translation',
            message: this.$t('input_to_language')
          }],
        }
      },

      isConvey () {
        return this.form.type === 'convey'
      }
    }, // End of component > computed

    /*
    |--------------------------------------------------------------------------
    | component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions('language', {
        getLanguages: 'fetchLanguages',
        fetchAppointmentLanguage: 'fetchAppointmentLanguage'
      }),
      ...mapActions('booking', {
        resetForm: 'resetBookingForm',
        fetchTranslatorLevels: 'fetchTranslatorLevels',
        fetchCustomers: 'fetchCustomers',
        filterCustomers: 'filterCustomers',
        fetchTranslators: 'fetchTranslators',
        fetchDepartments: 'fetchDepartments',
        fetchCompanies: 'fetchCompanies',
        bookingsValidate: 'bookingsValidate',
        assignCustomer: 'assignCustomer',
        changeTimeFormat: 'changeTimeFormat',
        getBookingsList: 'getBookingsList'
      }),

      formFocusHandler(state) {
        this.isFormFocused = state;
      },

      showMoreOptions(val) {
        this.moreOptions = !val;
      },

      //show languages options modal handler
      handleShowLanguageOptions() {
        this.hasDialects = false
        this.showLanguageOptions = true
      },

      // check language dialects when select language value changed
      // then show set dialects modal
      checkDialects() {
        console.log(this.appointmentTemplates);
        this.checkAppoimentTemplate();
        var language = this.languages.find(language => {
          return language.id == this.form.from_language_id
        })


        if (!isEmpty(language.dialects) && language.dialects.length > 0) {
          this.hasDialects = true
          this.dialects = language.dialects
        }

      },
      bookingValidate(form) {

        delete form.to_language_id;
        this.bookingsValidate(form).then(response => {
          if (response.status === 200) {
            if (!isEmpty(response.data.booking_exists)) {
              let existingBooking = response.data.booking_exists;
              let language = this.language.name;
              let dueDate = existingBooking.due.split(' ');
              let message = `Du har en liknande bokning samma tid. Språk: ${language}, ${dueDate[0]}, kl ${dueDate[1]}, bokningsnummer ${existingBooking.id}. Vill ni lägga en till bokning?`;

              // As the user to make sure.
              this.$confirm(message, {
                cancelButtonText: 'Nej',
                confirmButtonText: 'Ja'
              }).then(() => {
                this.setIsShowBookingConfirmModal();
                this.resetData();
              }).catch(() => {
              });

            } else {
              this.setIsShowBookingConfirmModal();
              this.resetData();
            }

          }
        })
      },

      setIsShowBookingConfirmModal() {
        if (this.form.type === 'physical' && isEmpty(this.confirmationFills.city)) {
          this.changeAddress = true
        }
        if (!isEmpty(this.$route.params.cloned)) {
          let cloned = this.$route.params.cloned;
          this.confirmationFills.booker_name = cloned.booker_name;
          this.confirmationFills.reference = cloned.ref;
          this.confirmationFills.staff_name = cloned.staff_name;
          if (!isEmpty(cloned.alternative_languages)) {
            this.confirmationFills.alternative_languages = cloned.alternative_languages;
            this.bookingFieldRules.alt_language_enabled = true;
          }
        }
        if (this.is_general_login) {
          this.confirmationFills.email = [this.provided_email];
        }
        this.showConfirmationsFills = true
      },

      submitForm(form) {
        this.hasDialects = false;
        // if (this.form.type === 'text_translation' && isEmpty(this.form.text_translation_files)) {
        //     return false;
        // }
        this.$refs[form].validate((valid) => {
          if (valid) {
            if (this.new_customer) {
              this.showCreateDepartment = true;
              return;
            }
            this.resetTimeFormat();
            if (!isEmpty(this.specific_translators)) {
              // newForm.specific_translators = this.specific_translators.map(o => o.id)
              this.$store.commit('booking/setSpecificTranslators', this.specific_translators.map(o => o.id))
            }
            if (!isEmpty(this.exclude_translators)) {
              this.$store.commit('booking/setExcludeTranslators', this.exclude_translators.map(o => o.id))
            }

            if (['text_translation'].includes(this.form.type)) {
              this.toTime = "23:00";
              this.$store.state.booking.bookingForm.time = this.toTime + ':00';
              this.$store.state.booking.bookingForm.duration = 30;
            }

            if(this.form.type === 'convey'){
              this.$store.state.booking.bookingForm.duration = 15;
            }
            let newForm = {
              ...this.$store.state.booking.bookingForm,
              ...this.$store.state.booking.is_immediate
            }

            this.$store.dispatch('booking/fetchAndAssignCustomer', {
              customerId: newForm.customer_id
            })
                .then(() => {
                  const customer = this.$store.getters['booking/getCustomer'];

                  let payload = {...newForm};

                  if (newForm.type === 'text_translation') {
                    delete payload.to_language_id
                  } else if (newForm.type === 'physical') {
                    payload.address = customer.customer_data.address;
                  }

                  this.bookingValidate(payload);
                });
          } else {
            return false
          }
        })
      },

      // update enable emergency & update validations
      enableEmergency() {
        this.$store.dispatch('booking/enableEmergency', this.is_immediate)

        //set due date and time validation at emergency booking to false
        if (this.is_immediate) {
          this.rules.date[0].required = false
          this.rules.time[0].required = false
        } else {
          this.rules.date[0].required = true
          this.rules.time[0].required = true
        }
      },

      changeSelected() {

        if (_.last(this.translator_levels) === 'male') {
          this.translator_levels = _.without(this.translator_levels, 'female')
          this.$store.dispatch('booking/updateGender', 'male');
        }

        if (_.last(this.translator_levels) === 'female') {
          this.translator_levels = _.without(this.translator_levels, 'male')
          this.$store.dispatch('booking/updateGender', 'female');
        }

        if (!this.translator_levels.includes('female') && !this.translator_levels.includes('male')) {
          this.$store.dispatch('booking/updateGender', '');
        }

        this.form.translator_levels = _.without(this.translator_levels, 'male', 'female')
      },

      /**
       * Set Customer Object
       */
      setCustomer() {
        this.assignCustomer(this.form.customer_id)
      },


      resetTimeFormat() {
        this.changeTimeFormat(this.form.time)
      },

      filterDepartments(event) {
        let params = {
          'filter[company_id]': event,
          all: true
        }
        this.fetchDepartments(params)
      },

      handleFilterTime(q, cb) {
        let opts = [];
        if (q !== '') {
          opts = _.filter(this.timeOpts, (v) => {
            return _.includes(v.value, q);
          });
        } else {
          opts = this.timeOpts;
        }
        cb(opts);
      },
      handleFilterTo(q, cb) {
        let opts = [];
        if (q !== '') {
          opts = _.filter(this.timeOpts, (v) => {
            return _.includes(v.value, q);
          });
        } else {
          opts = this.timeOpts;
        }
        cb(opts);
      },
      handleTimeChange(e) {
        if (!isEmpty(this.$route.params.cloned)) {
          if (this.$route.params.cloned.duration != "") {
            this.toTime = window.moment(this.form.time, 'HH:mm:ss').add(this.$route.params.cloned.duration, 'minutes').format('HH:mm');
          }
        } else {
          let toTime = window.moment(this.form.time, 'HH:mm').add('1', 'hours').format('HH:mm')
          this.toTime = toTime
        }
        this.handleToTimeChange()
      },
      handleTimeBlur(e) {
        if (!isEmpty(this.$route.params.cloned)) {
          if (this.$route.params.cloned.duration != "") {
            this.toTime = window.moment(this.form.time, 'HH:mm:ss').add(this.$route.params.cloned.duration, 'minutes').format('HH:mm');
          }
        } else {
          let toTime = window.moment(this.form.time, 'HH:mm').add('1', 'hours').format('HH:mm')
          if (toTime != 'Invalid date') {
            this.toTime = toTime
          }
        }
        this.handleToTimeChange()
      },
      handleToTimeChange() {
        if (this.form.type === 'convey') {
          this.form.duration = 15
        } else {
          if (!isEmpty(this.toTime)) {
            let start = moment(this.form.time, 'HH:mm')
            let end = moment(this.toTime, 'HH:mm')
            if (end.isBefore(start)) {
              end.add(1, 'day');
            }
            var duration = moment.duration(end.diff(start))
            var result = duration.asHours();

            if (result < 12) {
              let start = moment(this.form.time, 'HH:mm')
              duration = end.diff(start, 'minutes')
            } else {
              this.$notify.error(this.$t('invalid_time'));
              this.toTime = ''
              duration = ''
            }
          }
          this.form.duration = duration
        }
      },


      searchCustomer(query) {
        const key = this.form.search_customer_by;

        if (query !== '' && query.length > 2) {
          this.searchCustomerLoading = true;
          let params = {
            'all': true
          }
          params[`filter[${key}]`] = query;

          setTimeout(() => {
            this.filterCustomers(params).then((response) => {
              this.searchResult = response
              this.searchCustomerLoading = false;
            })
          }, 200)

        } else {
          // this.searchResult = [];
        }
      },
      checkTo(rule, value, callback) {
        let dueTime = window.moment(this.$refs.booking_form.model.time, 'HH:mm')
        let val = window.moment(this.toTime, 'HH:mm')
        if (this.toTime === '') {
          return callback(new Error('Please input To time value'));
        }else if (this.$refs.booking_form.model.time === this.toTime){
          return callback(new Error('To time is same with from time'));
        } else {
          callback()
        }
      },
      // Once the API call for creating department and user gets success call this function
      // with newly created customer's ID. It will make necessary data changes and then existing
      // process will continue to work just like before.
      handleUserCreationChange(customer_id, is_general_login, email) {
        this.form.customer_id = customer_id;
        this.showCreateDepartment = false;
        this.new_customer = false;
        this.is_general_login = is_general_login;
        this.provided_email = email;
        this.submitForm('booking_form')
      },

      handleFilesUpload(e) {
        this.form.convey_files.push(e.file)
      },
      handleRemove(e) {
        this.form.convey_files.filter((file) => {
          return file.uid === e.uid
        })
        // this.form.convey_files = ''
      },
      initiate() {
        if (!isEmpty(this.$route.params.cloned.due)) {
          let datetime = this.$route.params.cloned.due.split(' ');
          this.form.date = datetime[0];
          this.form.time = window.moment(datetime[1], 'HH:mm').format('HH:mm');
          if (this.$route.params.cloned.duration != "") {
            this.toTime = window.moment(this.form.time, 'HH:mm:ss').add(this.$route.params.cloned.duration, 'minutes').format('HH:mm');
          } else {
            let toTime = window.moment(this.form.time, 'HH:mm').add('1', 'hours').format('HH:mm')
            this.toTime = toTime
          }
          this.handleToTimeChange();
        }
        this.form.from_language_id = this.$route.params.cloned.language;
        this.form.type = this.$route.params.cloned.type;
        this.form.customer_id = this.$route.params.cloned.customer_id;
        if (this.$route.params.cloned.gender === 'male') {
          this.translator_levels.push('male')
        }
        if (this.$route.params.cloned.gender === 'female') {
          this.translator_levels.push('female')
        }
        this.form.address = this.$route.params.cloned.address;
        this.form.booker_name = this.$route.params.cloned.booker_name;
        this.form.staff_name = this.$route.params.cloned.staff_name;

        if (this.$route.params.cloned.specific_translators.length > 0) {
          this.specific_translators = this.$route.params.cloned.specific_translators;
        }
        if (this.$route.params.cloned.translator_levels.length > 0) {
          this.translator_levels = [];
          for (let tl of this.$route.params.cloned.translator_levels) {
            this.translator_levels.push(tl);
          }
          this.form.translator_levels = _.without(this.translator_levels, 'male', 'female');
        }
        if (!isEmpty(this.$route.params.cloned.convey)){
          this.form.convey.phone = this.$route.params.cloned.convey.phone
          this.form.convey.message = this.$route.params.cloned.convey.message
        }

        if (!isEmpty(this.$route.params.cloned.assigned_translator)) {
          this.form.assigned_translator = this.$route.params.cloned.assigned_translator.id;
          // this.$refs.assignTransSelect.searchTranslator(this.$route.params.cloned.assigned_translator.email);
        }

      },
      handleProgress(e) {

        if (e > 0 && e < 100) {
          this.disabledSubmit = true
        } else {
          this.disabledSubmit = false
        }
      },
      checkAppoimentTemplate () {
        this.fetchAppointmentLanguage({id: this.form.from_language_id}).then(() => {
          let template = this.appointmentTemplates.find((obj) => {
            return obj.template_type === 'register'
          });
          this.appointmentTemplate = !isEmpty(template);

          return !isEmpty(template);
        });
      },
      resetData() {
        this.specific_translators = []
        this.exclude_translators = []
        this.translator_levels = [1, 2, 3, 4, 5, 6]
        this.toTime = ''
      },

      handleCustomerVerification(customer) {
        const user = this.searchResult.filter((user) => {
          return user.id == customer
        });
        console.log(user);
        if (user.length > 0 && user[0].enabled == false) {
          return this.$confirm('<strong>This customer is blocked. Are you sure you want to make the booking?</strong> <br><br> <p>'+user[0].disable_comment+'</p>', this.$t('warning'), {
            confirmButtonText: 'Make the booking anyway',
            cancelButtonText: 'Don’t make booking',
            type: 'warning',
            dangerouslyUseHTMLString: true
          }).then(() => {
            this.submitForm('booking_form')
          }).catch(() => {
            this.resetData();
          });
        }
        this.submitForm('booking_form')
      },

      handleTypeChanged(){

        console.log(this.form.type)
      }



    }, // End of component > methods
    watch: {
      // 'form.type'(val) {
      //     console.log(val)
      //     switch (val) {
      //         case 'convey':
      //             this.isConvey = true;
      //             this.rules.convey.message[0].required = true
      //             this.rules.convey.phone[0].required = true
      //             break;
      //         default:
      //             this.isConvey = false;
      //             this.rules.convey.message[0].required = false
      //             this.rules.convey.phone[0].required = false
      //             break;
      //     }

      // }
    },
    /*
    |--------------------------------------------------------------------------
    | component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {

      let self = this
      // self.form = self.booking.form
      self.changeSelected()
      this.$store.dispatch('booking/fetchTranslatorLevels')
      // populate(this, 'fetchCustomers', 'customersList', {
      //     ref: 'Customers Options'
      // });

      populate(this, 'getLanguages', 'languages', {
        ref: 'Languages Options'
      });

      // populate(this, 'fetchTranslators', 'translators', {
      //     ref: 'Translators Options'
      // });
      if (!isEmpty(this.$route.params.cloned)) {
        this.initiate();
      }

    },
    created () {
      if (window.innerWidth < 480) {
        this.modalWidth = '90%';
      }
    }, // End of component > created

  } // End of export default
</script>

<style lang="scss">
  .booking-form {
    .el-input__inner {
      border-radius: 5px;
      // border-bottom-right-radius: 5px;
      background-color: #ffffff;
      border: 1px solid #d8dce5;
    }

    .el-radio-group {
      .el-radio__label {
        font-size: 10px !important;
        color: #3a75ba !important;
      }
    }
  }

  .vue-input-tag-wrapper .input-tag .remove {
    padding-left: 5px;
  }

  .el-textarea__inner {
    border-radius: 5px !important;
  }

  .booking-form .el-row {
    margin-bottom: 10px;
  }

  button.mini {
    min-width: 20px !important;
    width: 20px !important;
    height: 20px !important;

    [class^="el-icon-"]:before {
      font-size: 12px !important
    }
  }

  .el-loading-mask {
    background-color: rgba(255, 255, 255, 0.45) !important;
  }

</style>

