<template>
    <div class="app-fields user-questions-customer-fields">

        <div class="app-splitter two-cols">
            <!-- Allow download invoices? -->
            <!-- <el-form-item :id="genContainerId('allow_show_invoices',formElId)"
                          :label="$t('text_allow_download_invoices')"
                          class="label-medium">
                <el-switch :id="genFieldId('allow_show_invoices',formElId)"
                           v-model="iValue.allow_show_invoices"/>
            </el-form-item> -->

            <!-- Send email invoices? -->
            <!-- <el-form-item :id="genContainerId('allow_send_invoices',formElId)"
                          :label="$t('text_send_email_invoices')"
                          class="label-medium">
                <el-switch :id="genFieldId('allow_send_invoices',formElId)"
                           v-model="iValue.allow_send_invoices"/>
            </el-form-item> -->
        </div> <!-- /.app-splitter two-cols -->

        <div class="app-splitter two-cols">
            <!-- Allow fax confirmation? -->
            <!-- <el-form-item :id="genContainerId('allow_fax_confirmation',formElId)"
                          :label="$t('text_allow_tax_confirmation')"
                          class="label-medium">
                <el-switch :id="genFieldId('allow_fax_confirmation',formElId)"
                           v-model="iValue.allow_fax_confirmation"/>
            </el-form-item> -->

            <!-- Allow credit card? -->
            <!-- <el-form-item :id="genContainerId('allow_credit_card',formElId)"
                          :label="$t('text_allow_credit_card')"
                          class="label-medium">
                <el-switch :id="genFieldId('allow_credit_card',formElId)"
                           v-model="iValue.allow_credit_card"/>
            </el-form-item> -->
        </div> <!-- /.app-splitter two-cols -->
        <div class="app-splitter two-cols">

            <!-- Allow fallback type? -->
            <el-form-item :id="genContainerId('allow_fallback_type',formElId)"
                          :label="$t('text_allow_fallback_type')"
                          class="label-medium">
                <el-switch :id="genFieldId('allow_fallback_type',formElId)"
                           v-model="iValue.allow_fallback_type"/>

            </el-form-item>

            <!-- Is User enabled? -->
            <el-form-item :id="genContainerId('is_enabled',formElId)"
                          :label="$t('text_is_user_enabled')"
                          class="label-medium">
                <el-switch :id="genFieldId('is_enabled',formElId)"
                           v-model="iValue.enabled"/>
            </el-form-item>
        </div> <!-- /.app-splitter two-cols -->

        <el-form-item v-if="!iValue.enabled" :id="genContainerId('disable_comment',formElId)"
                :label="$t('text_reason_for_disabling_user')"
                class="label-medium">
            <el-input :id="genFieldId('disable_comment',formElId)"
                      v-model="iValue.disable_comment" type="textarea"
                      :rows="5"/>
        </el-form-item>

          <div class="app-splitter two-cols">
            <el-form-item
                          :id="genContainerId('allow_sms_confirmation',formElId)"
                          :label="$t('text_allow_sms_confirmation')"
                          class="label-medium">
                <el-switch  :id="genFieldId('allow_sms_confirmation',formElId)"
                           v-model="iValue.allow_sms_confirmation"/>
            </el-form-item>

             <el-form-item
                          :id="genContainerId('allow_appointment_webapp',formElId)"
                          :label="$t('text_allow_appointment_webapp')"
                          class="label-medium">
                <el-switch  :id="genFieldId('allow_appointment_webapp',formElId)"
                           v-model="iValue.allow_appointment_webapp"/>
            </el-form-item>
            <el-form-item
                          :id="genContainerId('allow_suggest_time_appointment',formElId)"
                          :label="$t('text_allow_suggest_time_appointment')"
                          class="label-medium">
                <el-switch  :id="genFieldId('allow_suggest_time_appointment',formElId)"
                           v-model="iValue.allow_suggest_time_appointment"/>
            </el-form-item>
             
        </div>

         <h3>{{$t('is_confidential')}}</h3>
           <div class="app-splitter two-cols">
              <el-form-item :id="genContainerId('is_confidential',formElId)"
                          :label="$t('is_confidential')" class="label-switch">
                            <span slot="label" ><p>{{$t('is_confidential')}}</p></span>
                <el-switch :id="genFieldId('is_protected_identity',formElId)"
                           v-model="iValue.is_protected_identity" :active-value="1" :inactive-value="0" @change="handleInfoChange(iValue.is_protected_identity)"/>

            </el-form-item>
            <el-form-item :id="genContainerId('', formElId)"
              :label="$t('sensitive_information')" class="label-switch">
               <span slot="label" ><p>{{$t('sensitive_information')}}</p></span>
                <el-switch :id="genFieldId('is_sensitive_info',formElId)"
                v-model="iValue.is_sensitive_info" :active-value="1" :inactive-value="0" @change="handleInfoChange(iValue.is_sensitive_info)"/>
            </el-form-item>
           </div> <!--  div column splitter -->

         <div v-if="iValue.is_sensitive_info || iValue.is_protected_identity == 1">
            <el-form-item :id="genContainerId('comments',formElId)"
                          label="" class="label-block">
                <el-input :id="genFieldId('comments' ,formElId)"
                          v-model="iValue.confidential_message"
                          type="textarea"
                          :rows="2"/>
            </el-form-item>
         </div>



    </div> <!-- /.app-fields user-questions-customer-fields -->
</template>

<script>
  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      // Parent form reference.
      formElId: {
        type: String,
        default: ''
      },

      value: {
        type: Object,
        required: true
      }

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        }
      }

    } // End of Component > computed

  } // End of export default
</script>
