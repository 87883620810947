/*
|--------------------------------------------------------------------------
| Financial > Shared > methods > editSettings
|--------------------------------------------------------------------------
| Partial file as a reusable method for it's purpose.
*/

'use strict';

import APICaller from "~/js/helpers/APICaller";
import {Notification} from 'element-ui';
import i18n from '~/js/i18n';

/**
 * Action to save the changes of the settings into the API.
 *
 * @param context - the scope of the store.
 *
 * @return {Promise|boolean}
 */
export default function(context) {

  // Get the selected entity object defined in the Financial top form.
  const topForm = context.rootGetters['financialTopForm/selectedEntity'];

  // Prepare the payload.
  context.dispatch('setSettingsPayload', topForm);

  // Reach out to API.
  const endpointKey = context.state.endpointKey;
  const endpoint = `/api/v3/${endpointKey}/settings`;
  const method = 'PUT';
  const data = context.state.settingsPayload;

  return APICaller({method, endpoint, data, isDataRaw: true})
    .then((r) => {
      console.log(r);
      Notification.success({
        title: i18n.t('success'),
        message: _.capitalize(endpointKey) +' Settings saved!',
      });

      // Reload the settings
      context.dispatch('loadSettings', {
        entity_id: data.entity_id,
        entity_type: data.entity_type
      });

    }).catch((e) => {
      console.error(e);

      const errMsg = !_.isNil(e.response.data.data) ?
        e.response.data.data : 'Something went wrong';

      Notification.error({
        title: i18n.t('error'),
        message: errMsg,
      });

    });
}
