<template>
    <div class="app-list admin-permissions-list">
        <el-row>
            <el-col :span="2">
                <el-button @click="handleClickBack">
                    <i class="fa fa-long-arrow-left"></i> {{$t('back')}}
                </el-button>
            </el-col>
            <el-col :span="2" :offset="18">
                <router-link tag="button" to="/admin-permissions/create" @click.stop.prevent title="Create" class="el-button el-button--success">
                    <i class="fa fa-plus"></i> {{$t('create')}}
                </router-link>
            </el-col>
        </el-row>

        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="success" :title="$t('edit')" @click="handleEdit(scope)">
                    <span class="fa fa-pencil"></span>
                </el-button>
                <el-button class="circle" type="danger" :title="$t('delete')" @click="handleDelete(scope)">
                    <span class="fa fa-trash"></span>
                </el-button>
            </template>
        </base-list-table>

        <el-pagination
            v-if="listPagination.total > listPagination.per_page"
            layout="prev, pager, next"
            :page-size="listPagination.per_page"
            :total="listPagination.total"
            :current-page="listPagination.current_page"
            @current-change="reloadList">
        </el-pagination>
    </div> <!-- /.app-list admin-permissions-roles-list -->
</template>

<script>
    import {mapGetters, mapActions, mapState} from 'vuex';
    import {populate} from "~/js/helpers/Vuex";
    import {sd} from "~/js/helpers/Common";

        export default {

        /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                listCols: [
                    {
                        label: `${this.$t('page')}`,
                        prop: 'page_name',
                        width: '',
                        className: 'page-name-col',
                    },
                    {
                        label: `${this.$t('date')}`,
                        prop: 'created_at',
                        width: '',
                        className: 'date-col',
                    }
                ]
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapGetters('adminPermissions', ['listData','listPagination']),

            ...mapState('auth', {
                user: 'userData'
            })

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
        methods: {
            ...mapActions('adminPermissions', ['browse', 'delete', 'deletePermission']),

            handleEdit(scope) {
                window.location.href = '/#/admin-permissions/edit/' + scope.row.id;
            },

            handleDelete(scope) {
                this.$confirm('Delete this Admin Role?', 'Warning', {
                    confirmButtonText: 'YES',
                    cancelButtonText: 'NO',
                    type: 'warning'
                }).then(() => {
                    this.deletePermission({
                        id: scope.row.id,
                        admin_id: this.user.id
                    }).then(r => {
                        let n = sd(this.$route.query.page, 1);
                        this.reloadList(n);
                    });
                });
            },
            reloadList(n) {
                let params = _.clone(this.$route.query);
                n = sd(n, sd(this.$route.query.page, 1)) // Set the page number.
                params.page = n;
                this.$router.push({query:params})
                populate(this, 'browse', 'listData', {
                    force: true,
                    payload: {
                        id: this.user.id
                    },
                    params
                });
            },
            handleClickBack() {
                this.$router.push({path: '/admin-permissions/roles'});
            }
        }, // End of Component > methods


        /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
            this.reloadList(1);
        }, // End of Component > mounted

    }
</script>
