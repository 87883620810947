<template>
  <div class="app-page bookings-page">

    <page-header title="Text Booking Details" :back-enabled="true"/>

    <div class="app-container" v-loading="loading">
      <div class="app-wrapper">
        <div class="app-row">
          <div class="app-col half-width">
            <div class="content-body">
              <h3>JOB ID DOC-{{text_translation.id}}</h3>
              <template v-if="!isEmpty(review_file)">
                <h4>Review Document:
                  <!-- <el-button type="text" @click="getFile(review_file)">{{review_file.display_name}}</el-button> -->
                  <a @click="getFile(review_file)" :href="`javascript:;`"> <i class="fa fa-file"></i>
                    {{review_file.display_name}}</a>
                    </h4>
              </template>
              <div class="app-list booking-detail-list">

                <table class="detail-list">
                  <tr>
                    <td class="cell-label"><span>Status</span></td>
                    <td class="cell-value"><span>: {{text_translation.status_code}}</span></td>
                  </tr>

                  <template v-if="text_translation.status_code === 'awaiting_price'">
                    <tr>
                      <td class="cell-label"><span>Words Count</span></td>
                      <td class="cell-value"><span>
                          <el-input v-model="words" placeholder="words count" @input="calculate"></el-input>
                        </span></td>
                    </tr>
                    <tr>
                      <td class="cell-label"><span>Cost per words</span></td>
                      <td class="cell-value"><span>
                          <el-input v-model="cost_per_words" placeholder="cost per words" @input="calculate"></el-input>
                        </span></td>
                    </tr>

                    <tr>
                      <td class="cell-label"><span>Cost</span></td>
                      <td class="cell-value"><span>: kr {{text_translation.cost}}
                        </span></td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td class="cell-label"><span>Cost</span></td>
                      <td class="cell-value"><span>: kr {{text_translation.cost}}
                        </span></td>
                    </tr>
                  </template>

                  <template v-if="text_translation.status_code === 'awaiting_price'">
                    <tr>
                      <td class="cell-label"><span>Salary per words</span></td>
                      <td class="cell-value"><span>
                          <el-input v-model="salary_per_words" placeholder="salary per words" @input="calculate">
                          </el-input>
                        </span></td>
                    </tr>
                    <tr>
                      <td class="cell-label"><span>Salary</span></td>
                      <td class="cell-value"><span>: kr {{text_translation.salary}}
                        </span></td>
                    </tr>

                  </template>
                  <template v-else>
                    <tr>
                      <td class="cell-label"><span>Salary</span></td>
                      <td class="cell-value"><span>: kr {{text_translation.salary}}
                        </span></td>
                    </tr>
                  </template>
                  <template v-if="!isEmpty(text_translation.potential_translators) && (text_translation.status ==2 || text_translation.status ==3)">
                    <tr>
                      <td class="cell-label"><span>Translators</span></td>
                      <td class="cell-value"><span>:
                          {{ text_translation.potential_translators.map(o=>o.user.name+' - '+o.user.email).join(', ')}}
                          <el-button type="success" size="small" class="circle"
                            @click="changeTranslator = !changeTranslator"><i class="fa fa-pencil"></i></el-button>
                        </span></td>
                    </tr>


                    <template v-if="changeTranslator">
                      <tr>
                        <td class="cell-label"><span>Change Translator</span></td>
                        <td class="cell-value"><span>
                            <el-select filterable v-model="assigned_translator" :placeholder="$t('translators')">
                              <el-option v-for="translator in translators" :key="translator.id"
                                :label="translator.id+'-'+translator.email+'-'+'-'+translator.name"
                                :value="translator.id"></el-option>
                            </el-select>
                            </el-col>
                            <el-button type="success" @click="handleChangeTranslator" size="small">change</el-button>
                          </span></td>
                      </tr>
                    </template>
                  </template>
                  <template v-else-if="!isEmpty(text_translation.assigned_translator) && text_translation.status > 3">
                    <tr>
                      <td class="cell-label"><span>Translator Name</span></td>
                      <td class="cell-value"><span>: {{ text_translation.translator.name }}</span></td>
                    </tr>
                    <tr>
                      <td class="cell-label"><span>Translator Email</span></td>
                      <td class="cell-value"><span>: {{ text_translation.translator.email }}
                          <el-button type="success" size="small" class="circle"
                            @click="changeTranslator = !changeTranslator"><i class="fa fa-pencil"></i></el-button>
                        </span></td>
                    </tr>
                    <template v-if="changeTranslator">
                      <tr>
                        <td class="cell-label"><span>Change Translator</span></td>
                        <td class="cell-value"><span>
                            <el-select filterable v-model="assigned_translator" :placeholder="$t('translators')">
                              <el-option v-for="translator in translators" :key="translator.id"
                                :label="translator.id+'-'+translator.email+'-'+'-'+translator.name"
                                :value="translator.id"></el-option>
                            </el-select>
                            </el-col>
                            <el-button type="success" @click="handleChangeTranslator" size="small">change</el-button>
                          </span></td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td class="cell-label"><span>Translator </span></td>
                      <td class="cell-value"><span>
                          <el-select filterable v-model="assigned_translator" :placeholder="$t('translators')">
                            <el-option v-for="translator in translators" :key="translator.id"
                              :label="translator.id+'-'+translator.email+'-'+'-'+translator.name"
                              :value="translator.id"></el-option>
                          </el-select>

                          <!-- <el-button type="success" @click="handleChangeTranslator" size="small">change</el-button> -->
                        </span>
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <td class="cell-label"><span>Delivery Date</span></td>
                    <td class="cell-value"><span>: {{ text_translation.due }}</span></td>
                  </tr>
                  <tr>
                    <td class="cell-label"><span>Customer Name</span></td>
                    <td class="cell-value"><span>: {{ text_translation.customer.name }}</span></td>
                  </tr>
                  <tr>
                    <td class="cell-label"><span>Customer Email</span></td>
                    <td class="cell-value"><span>: {{ text_translation.customer.email }}</span></td>
                  </tr>
                  <tr>
                    <td class="cell-label"><span>Customer Phone</span></td>
                    <td class="cell-value"><span>: {{ text_translation.customer.phone }}</span></td>
                  </tr>
                  <tr>
                    <td class="cell-label"><span>Reference email</span></td>
                    <td class="cell-value"><span>: {{ text_translation.email }}</span></td>
                  </tr>
                  <tr>
                    <td class="cell-label"><span>Reference phone</span></td>
                    <td class="cell-value"><span>: {{ text_translation.phone }}</span></td>
                  </tr>

                  <!-- <tr>
                    <td class="cell-label"><span>Is express delivery?</span></td>
                    <td class="cell-value"><span>: {{(text_translation.is_express)? 'Yes':'No'}}</span></td>
                  </tr> -->
                  <tr>
                    <td class="cell-label"><span>Delivered with?</span></td>
                    <td class="cell-value"><span>: {{text_translation.deliver_with}}</span></td>
                  </tr>
                  <template v-if="text_translation.deliver_with ==='mail'">
                    <tr>
                      <td class="cell-label"><span>Customer Address</span></td>
                      <td class="cell-value"><span>: {{text_translation.customer.customer_data.address}},
                          {{text_translation.customer.customer_data.town_name}},
                          {{text_translation.customer.customer_data.post_code}},</span></td>
                    </tr>
                  </template>
                  <tr>
                    <td class="cell-label"><span>Is Need Stamp?</span></td>
                    <td class="cell-value"><span>: {{(text_translation.is_stamped) ? 'Yes':'No'}}</span></td>

                  </tr>
                  <tr>
                    <td class="cell-label"><span> Document is confidential?</span></td>
                    <td class="cell-value"><span>: {{(text_translation.is_confidential) ? 'Yes':'No'}}</span></td>

                  </tr>

                </table>
                <el-row>
                  <el-col :span="6" v-if="text_translation.status_code === 'awaiting_price'">
                    <el-button size="small" type="primary" @click="submitCost">Submit
                      cost</el-button>
                  </el-col>
                </el-row>
                 <template v-if="text_translation.status === 7">
            <el-button type="info" @click="showCostEdit = !showCostEdit"> Edit Cost</el-button>
          </template>
          <div v-if="showCostEdit">

            <cost-edit-form :form="editCostForm"></cost-edit-form>
          </div>
              </div>
            </div> <!-- /.content-body -->
          </div> <!-- /.app-col -->
          <div class="app-col half-width">
            <div class="content-body">
              <div class="app-list booking-detail-list">

                <table class="detail-list">
                  <tr>
                    <td class="cell-label"><span> Original Language</span></td>
                    <td class="cell-value"><span>: {{text_translation.from_language}}</span></td>
                  </tr>
                  <tr>
                    <td class="cell-label"><span> Translated into</span></td>
                    <td class="cell-value"><span>: {{text_translation.to_language}}</span></td>
                  </tr>
                  <tr>
                    <td class="cell-label"><span> Descriptions</span></td>
                    <td class="cell-value"><span>: {{text_translation.descriptions}}</span></td>
                  </tr>
                  <tr>
                    <td class="cell-label"><span> Original Document</span></td>
                    <td class="cell-value"><span v-if="!isEmpty(review_file)">
                      <a @click="getFile(review_file)" :href="`javascript:;`">
                          <i class="fa fa-file"></i> {{review_file.display_name}}</a>
                      </span></td>
                  </tr>
                  <tr>
                    <td class="cell-label"><span> Translated Document</span></td>
                    <td class="cell-value">
                      <template v-if="!isEmpty(fromreview_file)">
                        <a @click="getFile(fromreview_file)" :href="`javascript:;`"> <i class="fa fa-file"></i>
                          {{fromreview_file.display_name}}</a>
                        <el-button size="small" type="primary" @click="submitSetComplete(fromreview_file)">Set
                          Complete
                        </el-button>

                      </template>

                      <template v-else-if="isEmpty(fromreview_file) && !isEmpty(forreview_file)">

                        <a @click="getFile(forreview_file)" :href="`javascript:;`"> <i class="fa fa-file"></i>
                          {{forreview_file.display_name}}</a>


                        <template v-if="text_translation.status_code === 'in_review'">

                          <el-button size="small" type="primary" @click="submitSetComplete(forreview_file)">Set
                            Complete
                          </el-button>

                        </template>
                        <template v-else>
                          <el-button size="small" type="primary" @click="submitAccept(forreview_file)">Accept for
                            review
                          </el-button>

                        </template>


                      </template>
                      <template v-if="text_translation.status_code==='completed' && !isEmpty(completedFile)">

                        <a @click="getFile(completedFile)" :href="`javascript:;`"> <i class="fa fa-file"></i>
                          {{completedFile.display_name}}</a>

                      </template>

                    </td>
                  </tr>
                </table>
                <template v-if="text_translation.status_code === 'being_translated'">
                  <el-row>
                    <el-form :model="form" :rules="rules" :ref="formElId" @submit.native.prevent>
                      <el-form-item label="" prop="file">
                        <div class="upload-btn-wrapper">
                          <button class="el-button el-button--primary"> Upload File</button>
                          <input type="file" ref="file" name="file" @change="handleFileChange">

                          <el-progress :text-inside="true" :stroke-width="26" :percentage="uploadProgress"></el-progress>
                          {{fileName}}
                        </div>
                      </el-form-item>
                      <!-- <el-form-item label="Select Translator" prop="uploaded_by">
                        <el-select v-model="form.uploaded_by" placeholder="">
                          <ElOption v-for="i in text_translation.potential_translators" :key="i.translator_id" :value="i.translator_id"
                            :label="`${i.user.name}-${i.user.email}`">{{i.user.name}} - {{i.user.email}}</ElOption>
                        </el-select>
                      </el-form-item>
                      <el-form-item label=""> -->

                        <el-button type="primary" @click="submitForm(formElId)" :disabled="disableUploadButton">
                          Submit Translated File
                        </el-button>
                      </el-form-item>
                    </el-form>
                  </el-row>

                </template>
              </div>



            </div>
          </div>



          <el-row style="margin-top:20px;">
            <h3>Comments</h3>
            <el-row :gutter="10" v-for="(item,i) in text_translation.comments" :key="i">
              <ElCol :md="2"><i class="fa fa-user-circle fa-3x"></i></ElCol>
              <ElCol :md="20">
                {{item.user.name}}<br>
                <small> {{item.user.email}}</small>

                <p v-html="item.comment"></p>

              </ElCol>


            </el-row>
            <el-input :id="genFieldId('comments' ,formElId)" v-model="comment" type="textarea" :rows="2" />
            <div class="text-right">

              <el-button type="primary" :id="genFieldId('add_new_comment',formElId)" size="mini"
                @click="handleAddComment" :disabled="(isEmpty(comment))">
                Add New Comment
              </el-button>
            </div>
          </el-row>



        </div> <!-- /.app-row -->
      </div> <!-- /.app-wrapper -->
    </div> <!-- /.app-container -->

  </div> <!-- /.app-page bookings-page -->
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import { isEmpty } from '../../../../js/helpers/Common';
import {storeFile,download} from "~/js/helpers/File";
import {populate,forcePopulate,gep} from "~/js/helpers/Vuex";
import API from '~/js/constants/api';
import CostEditForm from "~/components/forms/booking/TextTranslation/CostEditForm"
export default {
  components:{
    CostEditForm
  },
  data() {
    return {
      formElId:'translator_submit',
      cost_per_words: 3,
      salary_per_words: 2,
      uploadProgress:0,
      words: 0,
      storage_url: app_api+'/api/v3/storage/get?path=',
      assigned_translator: [],
      fileName: '',
      fileList: [],
      comment:'',
      disableUploadButton: true,
      form:{
        file:[],
        submission_type:'submited_for_review',
        uploaded_by:'',
        key:''
      },
      rules:{
        uploaded_by:[{
          required: true,
            message: 'Please Select Translator who upload the file'
        }],
        file:[{
          required: true,
            message: 'Please Select upload file'
        }]
      },
      showCostEdit:false,
      changeTranslator:false
    }
  },
  computed: {
    ...mapState('textTranslation', {
      text_translation: 'text_translation',
      loading: 'loading'
    }),
    ...mapGetters('textTranslation', {
      review_file: 'reviewFile',
      fromreview_file: 'fromReviewFile',
      forreview_file: 'forReviewFile',
      completedFile: 'completedFile'

    }),
    ...mapGetters({
      translators: 'booking/getTranslatorsList',
    }),
    ...mapGetters({
      user: 'auth/userData',
    }),
    editCostForm(){
      return {
        cost: this.text_translation.cost,
        salary:this.text_translation.salary,
        id:this.text_translation.id
      }
    }
  },
  methods: {
    ...mapActions('textTranslation', {
      read: 'fetchTextTranslation',
      postSubmitCost: 'postSubmitCost',
      acceptFile: 'acceptFile',
      postSubmitComplete: 'postSubmitComplete',
      postSubmitFile:'postSubmitFile',
      postComment:'postComment',
      deleteComment:'deleteComment',
      postChangeTranslator:'postChangeTranslator',
      downloadFile:'downloadFile'
    }),
    ...mapActions('booking', {
      fetchTranslators: 'fetchTranslators',
    }),
    isZero(v) {
      console.log(Math.floor(v) == 0)
      return Math.floor(v) == 0
    },
    isEmpty(v) {
      return isEmpty(v)
    },
    calculate() {
      // let words = this.review_file.words_count
      let words = this.words
      let cost = words * this.cost_per_words
      let salary = words * this.salary_per_words
      if (cost) {
        this.text_translation.cost = cost.toFixed(2)
        this.text_translation.salary = salary.toFixed(2)
      }
    },
    submitCost() {
      let params = {
        cost: this.text_translation.cost,
        salary: this.text_translation.salary,
        translator: this.assigned_translator,
        id: this.text_translation.id
      }
      // console.log(params)
      this.postSubmitCost(params)
    },
    submitAccept(file) {
      let payload = {
        file_id: file.id,
        id: this.text_translation.id
      }
      this.acceptFile(payload)
    },
    submitSetComplete(file) {
      let payload = {
        file_id: file.id,
        id: this.text_translation.id
      }
      this.postSubmitComplete(payload)
    },
    handleFileChange() {
      this.form.file = this.$refs.file.files[0]
      this.fileName = this.$refs.file.files[0].name

      let options = {
        url: `${API.BASE}/${API.SIGNED_STORAGE_URL}`,
        progress: progress => {
          this.uploadProgress = Math.round(progress * 100);
        }
      }
      storeFile(this.form.file,options).then((r)=>{

        this.form.key = r.data.key;
        this.disableUploadButton = false;
      });
    },

    submitForm(form){
      this.$refs[form].validate((valid) => {
        if(valid){
          this.form.uploaded_by = this.text_translation.potential_translators[0].translator_id
          let formData = new FormData()
           formData.append('file_name', this.$refs.file.files[0].name)
           formData.append('key', this.form.key)
           formData.append('type',  this.$refs.file.files[0].type)
          formData.append('submission_type', this.form.submission_type)
          // this.form.uploaded_by = this.text_translation.potential_translators[0].translator_id
          formData.append('uploaded_by', this.form.uploaded_by)

          formData.append('id', this.text_translation.id)

          this.postSubmitFile(formData).then((r)=>{
            // this.submitAccept(this.forreview_file)
          })
        }
      })
    },
    handleAddComment(){
      let payload = {
        comment: this.comment,
        user_id: this.user.id,
        text_translation_id: this.$route.params.id
      }
      this.postComment(payload)
      this.comment =''
    },
    handleDeleteComment(id){
      let payload = {
        id: id,
        text_translation_id: this.$route.params.id
      }
      this.deleteComment(payload)
    },
    handleChangeTranslator(){
      let payload= {
        translator_id: this.assigned_translator,
        id: this.$route.params.id
      }
      this.changeTranslator = false
      this.postChangeTranslator(payload)

    },
    getFile(file){

      download(file)
    }

  },
  mounted() {
    let id = this.$route.params
    this.read(id)
    populate(this, 'fetchTranslators', 'translators', {
      ref: 'Translators Options'
    });

  }
}
</script>
<style lang="scss" scoped>
.box{
    border:1px solid;
    .box-content{
        padding: 40px;

        .el-row{
            margin-bottom: 10px;
        }
    }
}
.border-right{
    border-right: solid 1px;
}
.sent-at{

    color: red !important;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
</style>
