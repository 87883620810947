<template>
    <div class="app-list admin-logs-list">

        <custom-table-user-access-logs
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false"
                v-model="searchFields">

            <template slot="topControls">
                <el-row>
                    <el-col :span="12">
                    </el-col>
                    <el-col :span="12">
                        <el-input :placeholder="$t('type_to_search')" v-model="search" @keyup.enter.native="handleSearch">
                            <el-select class="filter-dropdown" v-model="searchOption" slot="prepend"
                                       :placeholder="$t('select')">
                                <el-option :label="$t('name')" value="admin_name"></el-option>
                                <el-option :label="$t('activity')" value="action"></el-option>
                                <el-option :label="$t('user')" value="user_name"></el-option>
                                <el-option :label="$t('type')" value="user_type"></el-option>
                                <el-option :label="$t('created_at')" value="created_at"></el-option>
                            </el-select>
                            <span class="el-icon-search el-input__icon app-cursor-pointer"
                                  slot="suffix" @click="handleSearch"></span>
                        </el-input>
                    </el-col>
                </el-row>

            </template>
        </custom-table-user-access-logs>

        <el-pagination
                v-if="listPagination.total > listPagination.per_page"
                layout="prev, pager, next"
                :page-size="listPagination.per_page"
                :total="listPagination.total"
                :current-page="listPagination.current_page"
                @current-change="reloadList">
        </el-pagination>

    </div> <!-- /.app-list admin-logs-list -->
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import { populate } from '../../../js/helpers/Vuex';
    import { sd } from "../../../js/helpers/Common";
    import isNil from 'lodash/isNil';

    // import {mapGetters} from vuex

        export default {

        /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
        data() {
            const _this=this;
            return {
                listCols: [
                    {
                        label: _this.$t('admin'),
                        prop: 'admin.name',
                        width: '',
                        className: 'admin-name-col'
                    },{
                        label: _this.$t('activity'),
                        prop: 'action_name',
                        width: '',
                        className: 'action-name-col'
                    },{
                        label: _this.$t('user'),
                        prop: 'user.name',
                        width: '',
                        className: 'user-name-col'
                    },{
                        label: _this.$t('type'),
                        prop: 'user_type',
                        width: '',
                        className: 'user-type-col'
                    },{
                        label: _this.$t('created_at'),
                        prop: 'created_at',
                        width: '',
                        className: 'created-at-col'
                    }
                ],
                search: '',
                searchOption: 'admin_name',
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapGetters('userAccessLogs', ['listData', 'listPagination']),
            ...mapState('userAccessLogs', {
                searchFields: 'composeSearch'
            }),

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
        watch: {

        }, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
        methods: {
            ...mapActions({
                browse: 'userAccessLogs/browse',
                searchLogs: 'userAccessLogs/searchLogs',
            }),

            reloadList (n) {
                let params = _.clone(this.$route.query);
                n = sd(n, sd(this.$route.query.page, 1)) // Set the page number.
                params.page = n;
                this.$router.push({query: params})

                let isNotEmpty = false;
                for (let sf of Object.values(this.searchFields)) {
                    if (sf != null && sf.length > 0) {
                        isNotEmpty = true;
                        break;
                    }
                }

                if (isNotEmpty) {
                    let data = this.searchFields;
                    this.searchLogs({
                        params,
                        data
                    });
                } else if (this.search.length > 0) {
                    let data = {};
                    data[this.searchOption] = this.search;
                    this.searchLogs({
                        params,
                        data
                    });
                } else {
                    populate(this, 'browse', 'listData', {
                        force: true,
                        params,
                        ref: 'User Access Logs Listing (forced)'
                    })
                }
            },
            handleSearch (e) {
                this.reloadList(1);
            }
        }, // End of Component > methods


        /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
            if (!isNil(this.$route.query.admin)) {
                this.reloadList(1);
            } else {
                populate(this, 'browse', 'listData', {
                    force: true
                });
            }

        }, // End of Component > mounted

    }
</script>
