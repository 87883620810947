<template>
  <div class="app-form price-emergency-booking-form">

    <el-form :id="invoiceFormElId" :ref="invoiceFormElId">

      <el-form-item :label="$t('enable_charging_for_emergency_booking')" class="label-fluid">
        <el-switch v-model="iDifferentTypeImmediate" />
      </el-form-item>

      <h3 class="app-title title-shaded">
        <span>{{$t('invoice')}}</span>
      </h3>

      <financial-emergency-booking-fields
          :form-el-id="invoiceFormElId"
          v-model="invoiceForm.emergency_booking"
      />

    </el-form>

  </div> <!-- /.app-form price-emergency-booking-form -->
</template>

<script>
  import {mapState} from 'vuex';

  export default {

    /*
     |--------------------------------------------------------------------------
     | Component > data
     |--------------------------------------------------------------------------
     */
    data() {
      return {
        invoiceFormElId: 'invoice_emergency_booking_form',
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('financialInvoice', {
        invoiceForm: 'settings',
      }),

      ...mapState('financialSalary', {
        salaryForm: 'settings',
      }),

      /**
       * Interface for different_type_immediate value of invoice and salary.
       */
      iDifferentTypeImmediate: {
        get () {
          return this.invoiceForm.emergency_booking.different_type_immediate
              && this.salaryForm.emergency_booking.different_type_immediate;
        },
        set (v) {
          this.invoiceForm.emergency_booking.different_type_immediate = v;
          this.salaryForm.emergency_booking.different_type_immediate = v;
        },
      },
    },

  } // End of export default
</script>
