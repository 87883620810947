<template>
  <div class="app-form app-fields financial-charging-timings-fields">

    <div class="app-splitter two-cols base-charges-fields">

      <!-- Booking minimum time -->
      <el-form-item :id="genContainerId(`${tlvl}_${btype}minimum_fee`,formElId)"
                    class="label-medium">
        <template slot="label">
          {{ genMinTimeLabel() }}
          <div class="app-badge-container">
            <span class="extra-badge-label" v-if="extraBadgeLabel">
              {{ formatExtraBadgeLabel(tlvl) }}
            </span>
            <span class="app-badge badge-success">
              {{ genMinTimeBadge() }}
            </span>
          </div>
        </template>

        <el-input :id="genFieldId(`${tlvl}_${btype}_minimum_fee`,formElId)"
                  v-model="iValue.minimum_fee"/>
      </el-form-item>

      <!-- Booking time after -->
      <el-form-item v-if="!isFlatRate"
                    :id="genContainerId(`${tlvl}_${btype}_succeeding_fee`,formElId)"
                    class="label-medium">
        <template slot="label">
          {{ genTimeAfterLabel() }}
          <div class="app-badge-container">
            <span class="extra-badge-label" v-if="extraBadgeLabel">
              {{ formatExtraBadgeLabel(tlvl) }}
            </span>
            <span class="app-badge badge-success">
              {{ genTimeAfterBadge() }}
            </span>
          </div>
        </template>

        <el-input :id="genFieldId(`${tlvl}_${btype}_succeeding_fee`,formElId)"
                  v-model="iValue.succeeding_fee"/>
      </el-form-item>

    </div> <!-- /.app-splitter two-cols -->

    <div class="use-parts-fields-container" v-if="isUseParts && !isFlatRate">
      <financial-charging-use-parts-fields :business-rules="businessRules"
                                           v-model="iValue.parts_fees"
                                           :form-el-id="formElId"
                                           :tlvl="tlvl"
                                           :btype="btype"/>

    </div> <!-- /.use-parts-fields-container -->


  </div> <!-- /.app-form app-fields financial-charging-timings-fields -->
</template>

<script>
  import {mapGetters} from "vuex";
  import isNil from 'lodash/isNil';
  import isEmpty from 'lodash/isEmpty';
  import startCase from 'lodash/startCase';
  import toLower from 'lodash/toLower';
  import isString from 'lodash/isString';
  import cloneDeep from 'lodash/cloneDeep';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      /**
       * The Form reference value to be appended into the element ID.
       */
      formElId: {
        type: String,
        default: '',
      },

      /**
       * The form object.
       */
      value: {
        type: Object,
        default() {
          return {
            min_time: '',
            time_after: '',
            charges: {},
          };
        }
      },

      /**
       * The business_rules values usable inside this component.
       */
      businessRules: {
        type: Object,
        required: true,
      },

      /**
       * The Translator Level value to be appended into the element ID.
       */
      tlvl: {
        type: String,
        default: 'none',
      },

      /**
       * The Booking Type value to be appended into the element ID.
       */
      btype: {
        type: String,
        default: 'none',
      },

      /**
       * A syncable value for showing/hiding flat-rate input fields.
       */
      isFlatRate: false,

      /**
       * A syncable value for showing/hiding use-parts input fields.
       */
      isUseParts: false,

      /**
       * A value whether to show the extra badge label content.
       */
      extraBadgeLabel: {
        type: Boolean,
        default: false,
      }

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data() {
      return {

        fetchChargeFrom: '',
        resetValue: {},

      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters({
        bookingTypeOpts: 'form/bookingTypeOpts',
        formattedTranslatorLevelOpts: 'translatorLevel/formattedTranslatorLevelOpts',
      }),

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * Helper method for generating a label for "min time" field.
       *
       * @return {string}
       */
      genMinTimeLabel() {
        return this.$t(toLower(this.btype)) + ' '+this.$t('booking_minimum_time');
      }, // End of genMinTimeLabel() method

      /**
       * Helper method for generating a label for "time after" field.
       *
       * @return {string}
       */
      genTimeAfterLabel() {
        return this.$t(toLower(this.btype)) + ' '+this.$t('booking_time_after');
      }, // End of genTimeAfterLabel() method

      /**
       * Helper method for generating a content for the min time badge
       *
       * @return {string}
       */
      genMinTimeBadge() {
        const bt = this.btype;

        return this.isValidBookingTimingsMinimumHours(bt) ?
          this.businessRules.booking_timings[bt].minimum_hours : 0;
      }, // End of genMinTimeBadge() method

      /**
       * Helper method for generating a content for the min time badge
       *
       * @return {string}
       */
      genTimeAfterBadge() {
        const bt = this.btype;

        return this.isValidBookingTimingsSucceedingHours(bt) ?
          this.businessRules.booking_timings[bt].succeeding_hours : 0;
      }, // End of genMinTimeBadge() method

      /**
       * Helper method to format the extra badge label before showing it.
       * @param {string} v - the value to be formatted.
       * @return {string}
       */
      formatExtraBadgeLabel(v) {
        if (isString(v)) {
          return this.$t('tlvl_' + v) + ' ';
        } else {
          console.error('Expected string. Got' + typeof v + '.');
        }
      },

      /**
       * Helper method for check if businessRules exist.
       * @param {string} bt - booking type reference
       * @return {boolean}
       */
      doesBusinessRulesExist(bt) {
        return !isEmpty(this.businessRules)
            && !isNil(this.businessRules.booking_timings)
            && !isNil(this.businessRules.booking_timings[bt])
      },

      /**
       * Helper method for determining wether the target value for
       * business_rules > booking_timings > minimum_hours exist.
       *
       * @param {string} bt - Booking Type
       * @returns {boolean}
       */
      isValidBookingTimingsMinimumHours (bt) {
        return this.doesBusinessRulesExist(bt)
            && !isNil(this.businessRules.booking_timings[bt].minimum_hours)
      },

      /**
       * Helper method for determining wether the target value for
       * business_rules > booking_timings > succeeding_hours exist.
       *
       * @param {string} bt - Booking Type
       * @returns {boolean}
       */
      isValidBookingTimingsSucceedingHours (bt) {
        return this.doesBusinessRulesExist(bt)
            && !isNil(this.businessRules.booking_timings[bt].succeeding_hours)
      },

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted() {

      this.resetValue = cloneDeep(this.value);
    }, // End of Component > mounted

  } // End of export default
</script>
