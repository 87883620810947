<template>
  <div :class="getBem(blockClass)" v-if="isValidValue">

    <h3>{{ title }}</h3>

    <ul :class="getBem(blockClass, 'message-list')">

      <template v-if="isObjectValue">
        <li
          v-for="(v, k) in value"
          :class="getBem(blockClass, 'message-list-item')"
        >
          <div :class="getBem(blockClass, 'message-list-item-header')">
            {{ k }}
          </div>
          <div :class="getBem(blockClass, 'message-list-item-body')">
            {{ v }}
          </div>
        </li>
      </template>

      <template v-else-if="isArrayValue">
        <li
          v-for="(v) in value"
          :class="getBem(blockClass, 'message-list-item')"
        >
          <div :class="getBem(blockClass, 'message-list-item-body')">
            {{ v }}
          </div>
        </li>
      </template>

      <template v-else-if="isStringValue">
        <li :class="getBem(blockClass, 'message-list-item')">
          <div :class="getBem(blockClass, 'message-list-item-body')">
            {{ value }}
          </div>
        </li>
      </template>

    </ul>
  </div>
</template>

<script>
  import isNil from 'lodash/isNil';
  import isEmpty from 'lodash/isEmpty';
  import isObject from 'lodash/isObject';
  import isArray from 'lodash/isArray';
  import isString from 'lodash/isString';

  export default {
    name: "FinancialExportMessageDisplay",

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      value: {
        required: true
      },

      title: {
        type: String,
        default: ''
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockClass: 'financial-export-message-display'
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * @returns {boolean}
       */
      isValidValue () {
        return !isNil(this.value) && !isEmpty(this.value);
      },

      /**
       * @returns {boolean}
       */
      isObjectValue () {
        return this.isObject(this.value);
      },

      /**
       * @returns {boolean}
       */
      isArrayValue () {
        return this.isArray(this.value);
      },

      /**
       * @returns {boolean}
       */
      isStringValue () {
        return this.isString(this.value);
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * @returns {boolean}
       */
      isObject (v) {
        return isObject(v) && !isArray(v);
      },

      isArray, isString
    }
  }
</script>

<!--suppress CssUnknownTarget, SassScssResolvedByNameOnly -->
<style lang="scss">
  @import '~/scss/global/_variables.scss';

  .financial-export-message-display {
    $header-color: #000;
    $body-color: lighten(#000, 50%);
    $border-color: lighten($body-color, 20%);

    &__message-list,
    &__message-list-item {
      margin: 0;
      padding: 0;
      list-style: none;
      border-bottom: 1px solid $border-color;

      &:last-child {
        border-bottom: 0;
      }
    }

    &__message-list {
      &-item {
        margin-bottom: 5px;
        padding-bottom: 5px;

        &-header {
          font-size: 12px;
          color: $header-color;
          font-weight: bold;
          margin-bottom: 2px;
        }

        &-body {
          font-size: 10px;
          color: $body-color;
        }
      }
    }
  }
</style>
