<template>
    <div class="app-fields customer-allow-video-booking-fields">
        <div class="app-splitter two-cols">
         <el-form-item :id="genContainerId('customer-allow-video-booking',formElId)"
                      label="Allow video booking?"
                      class="label-switch">
            <el-switch :id="genFieldId('customer-allow-video-booking',formElId)"
                       v-model="allow_video_booking"/>
        </el-form-item>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { isEmpty } from '~/js/helpers/Common'
export default {
    props:{
        formElId: {
            type: String,
            default: '',
        },
        value:{
            type: Array,
            default:{
                booking_types:[]
            }
        },
        
    },
    data(){
        return {
            // allow_video_booking:false
        }
    },
    computed:{
         /**
             * Interface for the v-model of this component.
             */
            iValue: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                },
            },
            allow_video_booking:{
                get(){
                    return _.includes(this.iValue,'video')
                },
                set(v){
                   if(v){
                       this.iValue.push('video')
                   }else{
                       var index = this.iValue.indexOf('video')
                       if (index > -1) {
                            this.iValue.splice(index, 1);
                       }
                   }
                    
                }
            }
    }
}
</script>

<style>

</style>
