<template>
    <div class="app-list batches-settings-list">

        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="topControls">
                <div class="search-input-container">
                    <div style="display: inline-block; width: 50%;">
                        <el-input v-model="search" placeholder="Search" clearable/>
                    </div>
                    <div style="display: inline-block; margin-left: 15px;">
                        <el-button type="success" title="Search" class="fluid-width"
                                   @click="handleSearch">
                            <span class="fa fa-search"></span> Search
                        </el-button>
                        <el-button type="warning" title="Reset" class="fluid-width"
                                   @click="handleSearchReset">
                            <span class="fa fa-refresh"></span> Reset
                        </el-button>
                    </div>
                </div>
                <!-- /.search-input-container -->

            </template>
            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="success" title="Edit" @click="handleEdit(scope)">
                    <span class="fa fa-pencil"></span>
                </el-button>
                <el-button class="circle" type="danger" title="Delete">
                    <span class="fa fa-trash"></span>
                </el-button>
            </template>
        </base-list-table>

        <!-- Pagination -->
        <el-pagination
                v-if="listPagination.total > listPagination.per_page"
                layout="prev, pager, next"
                :page-size="listPagination.per_page"
                :total="listPagination.total"
                :current-page="listPagination.current_page"
                @current-change="reloadList">
        </el-pagination>

    </div> <!-- /.app-list batches-settings-list -->
</template>

<script>
    import {mapGetters, mapActions} from "vuex";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {

                search: '',

                refCompListing: 'Company Listing (forced)',

                listCols: [
                    {
                        label: 'ID',
                        prop: 'id',
                        width: '',
                        className: 'id-col',
                    },
                    {
                        label: 'Name',
                        prop: 'name',
                        width: '',
                        className: 'name-col',
                    },
                    {
                        label: 'Date',
                        prop: 'created_at',
                        width: '',
                        className: 'created-at-col',
                    }
                ],
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapGetters('company', ['listData', 'listPagination'])

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            ...mapActions('company', ['browse']),

            /**
             * Handler for searching an entry.
             *
             * @return {void}
             */
            handleSearch() {
                this.reloadList(
                    null,
                    {ref: this.refCompListing + ' via search'},
                    {'filter[name]': this.search}
                );
            }, // End of handleSearch() method

            /**
             * Handler for resetting the search made.
             */
            handleSearchReset() {
                this.search = '';
                this.reloadList(1, {ref: this.refCompListing});
            }, // End of handleSearchReset() method

            /**
             * Handler when the edit button in the table listing was clicked.
             *
             * @return {void}
             */
            handleEdit(scope) {
                this.$router.push({name: 'settings.batches.edit', params: {id: scope.row.id}})
            }, // End of handleEdit() method

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {

            this.reloadList(null, {ref: this.refCompListing});

        }, // End of Component > mounted

    }
</script>