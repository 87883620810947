<template>
  <div class="app-form financial-charging-fields">

    <div class="app-splitter two-cols">

      <!-- Flat rate switch -->
      <el-form-item :id="genContainerId('is_flat_rate',formElId)"
                    :label="$t('flat_rate')" class="label-short">

        <el-switch :id="genFieldId('is_flat_rate',formElId)"
                   v-model="iValue.is_flat_rate"/>
      </el-form-item>

      <!-- Use Parts switch -->
      <el-form-item v-if="!isFlatRate"
                    :id="genContainerId('use_parts',formElId)"
                    :label="$t('use_parts')" class="label-short">

        <el-switch :id="genFieldId('use_parts',formElId)"
                   v-model="iValue.use_parts"/>
      </el-form-item>

    </div> <!-- /.app-splitter two-cols -->

    <!-- Financial Charging > Charges Section -->
    <el-collapse v-for="(tlvl) in translatorLevels"
                 class="panel-shaded"
                 :class="tlvl + '-panel'"
                 :key="tlvl"
                 v-model="panels[tlvl]">
      <el-collapse-item
          :name="1"
          :title="$t('add_booking_price')+' - ' + $t('tlvl_' + tlvl)">

        <template v-for="bt in bookingTypes">
          <financial-charging-timings-fields
              v-model="iValue.charges[tlvl][bt]"
              :is-flat-rate.sync="isFlatRate"
              :is-use-parts.sync="isUseParts"
              :form-el-id="formElId"
              :key="tlvl + '_' + bt"
              :tlvl="tlvl"
              :btype="bt"
              :business-rules="businessRules" />

          <div class="fetch-charge-container">

            <!-- Fetch Charge From -->
            <el-form-item :id="genContainerId(`${tlvl}_${bt}_fetch_from`,formElId)"
                          :label="$t('fetch_charge_from')" class="label-medium">

              <el-select :id="genFieldId(`${tlvl}_${bt}_fetch_from`,formElId)"
                         v-model="fetchChargeFrom[`${tlvl}__${bt}`]"
                         @change="handleCloneCharges(`${tlvl}__${bt}`)"
                         size="mini"
                         filterable
              >
                <el-option :label="$t('none')" value="none" />
                <el-option v-for="(v) in fetchChargeOpts"
                           :key="v.key"
                           :value="v.key"
                           :label="v.label"/>
              </el-select>
            </el-form-item>

          </div> <!-- /.fetch-charge-container -->
        </template>

      </el-collapse-item>
    </el-collapse>

  </div> <!-- /.app-form financial-charging-fields -->
</template>

<script>
  import {BOOKING_TYPES, TRANSLATOR_LVLS} from '~/js/store/financial/settings/shared/props/constants';
  import isNil from 'lodash/isNil';
  import forEach from 'lodash/forEach';
  import remove from 'lodash/remove';
  import size from 'lodash/size';
  import capitalize from 'lodash/capitalize';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      formElId: {
        type: String,
        default: 'finance_charging_form',
      },

      value: {
        type: Object,
        default() {
          return {
            // Check the format from financial > shared > props > charging > charges.
          };
        }
      },

      /**
       * The business_rules values usable inside this component.
       */
      businessRules: {
        type: Object,
        required: true,
      },

      isEnabledEmergencyCharges: {
        type: Boolean,
        default: false,
      },

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data() {
      return {

        fetchChargeFrom: {},

        panels: {
          layman: 1,
          certified: 1,
          trained: 1,
          read_courses: 1,
          certified_in_health: 1,
          certified_in_law: 1,
        },
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * @returns {string[]}
       */
      translatorLevels () {
        return TRANSLATOR_LVLS;
      },

      /**
       * Returns collection of appropriate booking types.
       *
       * @returns {string[]}
       */
      bookingTypes () {
        let cn = [...BOOKING_TYPES];

        if (!this.isEnabledEmergencyCharges) {
          remove(cn, (v) => v === 'phone_immediate');
        }

        return cn;
      },

      /**
       * Returns options that will be used for "Clone Charges" dropdown.
       * @return {array}
       */
      fetchChargeOpts() {
        if (size(this.translatorLevels) > 0) {
          let result = [];

          forEach(this.translatorLevels, (tlvl) => {
            forEach(this.bookingTypes, (bt) => {
              let o = {
                key: tlvl + '__' + bt,
                label: capitalize(bt) + ' - ' + this.$t('tlvl_' + tlvl),
              };

              result.push(o);
            });
          });

          return result;
        }
      },

      /**
       * Serves as interface of Is Flat Rate switch
       * @return {boolean}
       */
      isFlatRate: {
        get() {
          return isNil(this.iValue.is_flat_rate)
              ? false : this.iValue.is_flat_rate;
        },
        set(v) {
          this.iValue.is_flat_rate = v;
        }
      },

      /**
       * Serves as interface of Use Parts switch
       * @return {boolean}
       */
      isUseParts: {
        get() {
          return isNil(this.iValue.use_parts)
              ? false : this.iValue.use_parts;
        },
        set(v) {
          this.iValue.use_parts = v;
        }
      },

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {



      /**
       * Handles the change event for Fetch Charge field.
       * Sorry for any inconvenience, this approach is temporary as
       * this is not the best approach. Will refactor in the future.
       *
       * @param {string} dest
       *
       * @return {void}
       */
      handleCloneCharges(dest) {

        if (this.fetchChargeFrom !== 'none') {
          const src = this.fetchChargeFrom[dest];
          this.$emit('clone-charges', {src, dest});
        }

      }, // End of handleChangeFetchCharge() method

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted() {}, // End of Component > mounted

  } // End of export default
</script>
