"use strict";

import APICaller from "../../helpers/APICaller";
import moment from "moment";
import { gep } from "../../helpers/Vuex";
import { sd, isEmpty } from "../../helpers/Common";

const dateToday = moment().format("YYYY-MM-DD");
const apiPrefix = window.app_api_prefix_3;

const bookingsEndpoint = gep("bookings", apiPrefix);
const bookingsStatisticEndpoint = gep("core/statistics/dashboard", apiPrefix);
const feedbackEndpoint = gep("feedback", apiPrefix);
const translatorsEndpoint = gep("translators", apiPrefix);

const totalMap = "data.meta.pagination.total";

// Helpers
const setDefault = sd;

export default {
  namespaced: true,

  state: {
    last_statistic_update: null,
    update_interval: null,

    counts: {
      bookings_total: {
        count: 0
      },

      session_today: {
        count: 0,
        percentage: 0
      },

      bookings_made: {
        count: 0,
        percentage: 0
      },

      cancels_today: {
        count: 0,
        percentage: 0
      },

      to_expire: {
        count: 0,
        percentage: 0
      },

      pending_today: {
        count: 0,
        percentage: 0
      },

      expired_today: {
        count: 0,
        percentage: 0
      },

      expired_due_to_preference: {
        count: 0,
        percentage: 0
      },

      bookings_today: {
        count: 0,
        percentage: 0
      },

      ave_feedback: {
        count: 0,
        percentage: 0
      },

      translators_joined: {
        count: 0,
        percentage: 0
      },
      survey: {
        count: 0,
        percentage: 0
      },
      blog: {
        count: 0,
        percentage: 0
      },
      to_work_on: {
        count: 0,
        percentage: 0
      },
      stuck: {
        count: 0,
        percentage: 0
      },
      is_difficult: {
        count: 0,
        percentage: 0
      },
      planned: {
        count: 0,
        percentage: 0
      },
      to_be_planned: {
        count: 0,
        percentage: 0
      },
      cannot_be_planned: {
        count: 0,
        percentage: 0
      },
      translators_available_today: {
        count: 0,
        percentage: 0
      }
    },
    gauges: {
      gauge1: 0.32,
      gauge2: 0.21,
      gauge3: 0.19,
      gauge4: 0.11
    }
  },

  getters: {
    counts: state => state.counts,
    gauges: state => state.gauges,
    last_statistic_update: state => state.last_statistic_update,
    update_interval: state => state.update_interval
  },

  mutations: {
    setCount(state, payload) {
      _.set(state, `counts.${payload.state}.count`, payload.data);

      const total = state.counts.bookings_total.count;
      const raw = (payload.data / total) * 100;
      let percentage =
        Math.round(raw) == 0 ? parseFloat(raw.toFixed(1)) : Math.round(raw);

      _.set(state, `counts.${payload.state}.percentage`, percentage);
    },
    setFeedback(state, payload) {
      let feedbacks = payload.data.data.feedback;
      let sum = _.sumBy(feedbacks, function (o) {
        return o.rating;
      });
      if (sum !== 0) {
        let rating = (sum / feedbacks.length).toFixed(1);
        _.set(state, 'counts.ave_feedback.count', rating);
      }
    },
    setCounts(state, payload) {
      const states = {
        'bookings': [
          'bookings_total',
          'to_expire',
          'session_today',
          'bookings_made',
          'cancels_today',
          'to_expire',
          'pending_today',
          'expired_today',
          'bookings_today',
          'expired_due_to_preference',
          // 'to_work_on',
          'is_difficult',
          'planned',
          'to_be_planned',
          'cannot_be_planned',
        ],
        'feedbacks': [
          'ave_feedback'
        ],
        'translators': [
          'translators_joined',
          'translators_available_today'
        ],
        'admin_notification': [
          'blog',
          'survey'
        ]
      };

      _.each(states.bookings, function (v) {
        let getResult = `data.data.bookings.${v}`;
        payload.context.commit("setCount", {
          state: v,
          data: _.get(payload.data, getResult)
        });
      });

      _.each(states.translators, function (v) {
        let getResult = `data.data.translators.${v}`;
        payload.context.commit("setCount", {
          state: v,
          data: _.get(payload.data, getResult)
        });
      });

      _.each(states.feedbacks, function (v) {
        let getResult = `data.data.feedback.${v}`;
        _.set(state, 'counts.ave_feedback.count', _.get(payload.data, getResult));
      });

      _.each(states.admin_notification, function (v) {
        let getResult = `data.data.admin_notification.${v}`;
        payload.context.commit("setCount", {
          state: v,
          data: _.get(payload.data, getResult)
        });
      });

      _.set(state, 'last_statistic_update', _.get(payload.data, "data.data.last_update"));
      _.set(state, 'update_interval', _.get(payload.data, "data.data.update_interval"));
    }
  },

  actions: {
    /**
     * @param {object} context - current store context.
     * @param {object} payload - object payload.
     * @param {string} payload.endpoint - request target endpoint.
     * @param {object} payload.params - request query params.
     * @param {object} payload.state - state mutation target.
     */
    browse(context, payload) {
      payload.params = setDefault(payload.params, {});
      // payload.params['fields[bookings]'] = 'id';
      payload.params.page = 1;

      return APICaller({
        method: "GET",
        endpoint: payload.endpoint,
        params: payload.params
      })
        .then(r => {
          if (payload.state === 'statistics') {
            context.commit("setCounts", {
              data: r,
              context: context
            });
          } else {
            context.commit("setCount", {
              state: payload.state,
              data: _.get(r, totalMap)
            });
          }
          return r;
        })
        .catch(e => {
          console.error(e);
          return e;
        });
    },

    /**
     * @param {object} context - current store context.
     */
    loadCountRequests(context, payload) {
      const actions = [
        // "loadCountSessions",
        // "loadCountBookingsMade",
        // "loadCountCancels",
        // "loadCountToExpire",
        // "loadCountPending",
        // "loadCountExpired",
        // "loadCountBookingsToday",
        // "loadCountAveFeedback",
        // "loadCountTranslatorsJoined",
        "loadCountToWorkOn",
        // "loadCountStuck",
        // "loadCountBookingsIsDifficult",
        // 'loadCountTranslatorsAvailableToday'
      ];
      // context.dispatch("loadCountBookingsTotal").then(() => {
      //   _.each(actions, v => context.dispatch(v, payload));
      // });
      context.dispatch("loadDashboardStatistic", payload).then(() => {
        _.each(actions, v => context.dispatch(v, payload));
      });
    },

    /**
     * @param {object} context - current store context.
     */
    loadCountBookingsTotal(context, payload) {
      let params = {
        "filter[is_test]": 0,
        "fields[bookings]": 'id,status_id'
      };
      if (!isEmpty(payload) && payload.include_test) {
        params = {
          "fields[bookings]": 'id,status_id'
        };
      }
      return context.dispatch("browse", {
        endpoint: bookingsEndpoint,
        state: "bookings_total",
        params
      });
    },

    /**
     * @param {object} context - current store context.
     */
    loadCountSessions(context, payload) {
      let params = {
        'filter[due]': dateToday,
        'filter[is_test]': 0,
        "fields[bookings]": 'id,status_id'
      }
      if (!isEmpty(payload) && payload.include_test) {
        params = {
          "filter[due]": dateToday,
          "fields[bookings]": 'id,status_id'
        };
      }
      return context.dispatch("browse", {
        endpoint: bookingsEndpoint,
        state: "session_today",
        params
      });
    },

    /**
     * @param {object} context - current store context.
     */
    loadCountBookingsMade(context, payload) {
      let params = {
        "filter[created_at]": dateToday,
        "filter[is_test]": 0,
        "fields[bookings]": 'id,status_id'
      };
      if (!isEmpty(payload) && payload.include_test) {
        params = {
          "filter[created_at]": dateToday,
          "fields[bookings]": 'id,status_id'
        };
      }
      return context.dispatch("browse", {
        endpoint: bookingsEndpoint,
        state: "bookings_made",
        params
      });
    },

    /**
     * @param {object} context - current store context.
     */
    loadCountCancels(context, payload) {
      let params = {
        "filter[status_id]": 5,
        "filter[is_test]": 0,
        "filter[cancelled_at]": dateToday,
        "fields[bookings]": 'id,status_id'
      };
      if (!isEmpty(payload) && payload.include_test) {
        params = {
          "filter[status_id]": 5,
          "filter[cancelled_at]": dateToday,
          "fields[bookings]": 'id,status_id'
        };
      }
      return context.dispatch("browse", {
        endpoint: bookingsEndpoint,
        state: "cancels_today",
        params
      });
    },

    /**
     * load booking expiring
     * @param {object} context - current store context.
     */
    loadCountToExpire(context, payload) {
      let start = moment(new Date()).format("YYYY-MM-DD");
      let end = moment(new Date())
        .add(8, "days")
        .format("YYYY-MM-DD");
      let params = {
        "filter[status_id]": 1,
        "filter[is_test]": 0,
        "fields[bookings]": 'id,status_id',
        "filter[date_range]": `due, ${start}, ${end}`
      };
      if (!isEmpty(payload) && payload.include_test) {
        params = {
          "filter[status_id]": 1,
          "fields[bookings]": 'id,status_id',
          "filter[date_range]": `due, ${start}, ${end}`
        };
      }
      return context.dispatch("browse", {
        endpoint: bookingsEndpoint,
        state: "to_expire",
        params
      });
    },

    /**
     * load booking pending for future
     * @param {object} context - current store context.
     */
    loadCountPending(context, payload) {
      let start = moment(new Date()).format("YYYY-MM-DD");
      let end = moment(new Date())
        .add(1000, "days")
        .format("YYYY-MM-DD");

      let params = {
        "filter[status_id]": 1,
        "filter[is_test]": 0,
        "filter[date_range]": `due, ${start}, ${end}`,
        "fields[bookings]": 'id,status_id'
      };
      if (!isEmpty(payload) && payload.include_test) {
        params = {
          "filter[status_id]": 1,
          "fields[bookings]": 'id,status_id',
          "filter[date_range]": `due, ${start}, ${end}`
        };
      }
      return context.dispatch("browse", {
        endpoint: bookingsEndpoint,
        state: "pending_today",
        params
      });
    },

    /**
     * @param {object} context - current store context.
     */
    loadCountExpired(context, payload) {
      let params = {
        "filter[status_id]": 8,
        "filter[expiry]": dateToday,
        "filter[is_test]": 0,
        "fields[bookings]": 'id,status_id'
      };
      if (!isEmpty(payload) && payload.include_test) {
        params = {
          "filter[status_id]": 8,
          "filter[expiry]": dateToday,
          "fields[bookings]": 'id,status_id'
        };
      }
      return context.dispatch("browse", {
        endpoint: bookingsEndpoint,
        state: "expired_today",
        params
      });
    },

    /**
     * @param {object} context - current store context.
     */
    loadCountBookingsToday(context, payload) {
      let params = {
        "filter[created_at]": dateToday,
        "filter[is_test]": 0,
        "filter[due]": dateToday,
        "fields[bookings]": 'id,status_id'
      };
      if (!isEmpty(payload) && payload.include_test) {
        params = {
          "filter[created_at]": dateToday,
          "filter[due]": dateToday,
          "fields[bookings]": 'id,status_id'
        };
      }
      return context.dispatch("browse", {
        endpoint: bookingsEndpoint,
        state: "bookings_today",
        params
      });
    },

    /**
     * @param {object} context - current store context.
     */
    loadCountAveFeedback(context) {
      return APICaller({
        method: "GET",
        endpoint: feedbackEndpoint,
        params: {
          "filter[created_at]": dateToday,

        }
      })
        .then(r => {
          context.commit("setFeedback", {
            data: r.data
          });
          return r;
        })
        .catch(e => {
          console.error(e);
          return e;
        });
    },

    /**
     * @param {object} context - current store context.
     */
    loadCountTranslatorsJoined(context) {
      return context.dispatch("browse", {
        endpoint: translatorsEndpoint,
        state: "translators_joined",
        params: {
          "filter[created_at]": dateToday,

        }
      });
    },

    /**
     * load booking to work on
     * @param {object} context - current store context.
     */
    loadCountToWorkOn(context, payload) {
      let start = moment(new Date()).format("YYYY-MM-DD");
      let end = moment(new Date())
        .add(2, "years")
        .format("YYYY-MM-DD");
      let params = {
        'filter[status_id]':1,
        'filter[is_test]':0,
        'filter[date_range]': `due, ${start}, ${end}`,
        'filter[only_all_translator_notified]' : true,
        // 'filter[to_work_on]' : true,
        'sort':'due',
        'per_page':15,
      };
      if (!isEmpty(payload) && payload.include_test) {
        params = {
          "filter[status_id]": 1,
          "fields[bookings]": 'id,status_id',
          "filter[only_all_translator_notified]": true,
          // 'filter[to_work_on]' : true,
        };
      }
      return context.dispatch("browse", {
        endpoint: bookingsEndpoint,
        state: "to_work_on",
        params
      });
    },

    /**
     * @param {object} context - current store context.
     */
    loadCountBookingsIsDifficult(context, payload) {
      let params = {
        "filter[is_test]": 0,
        "filter[is_difficult]": 1,
        'filter[status_id]': 1,
        "fields[bookings]": 'id,status_id'
      };
      return context.dispatch("browse", {
        endpoint: bookingsEndpoint,
        state: "is_difficult",
        params
      });
    },
    /**
     * @param {object} context - current store context.
     */
    loadCountStuck(context, payload) {
      let params = {
        "filter[is_test]": 0,
        "filter[stuck]": 1,
        'filter[status_id]': 1,
        "fields[bookings]": 'id,status_id'
      };
      return context.dispatch("browse", {
        endpoint: bookingsEndpoint,
        state: "stuck",
        params
      });
    },

    /**
     * @param {object} context - current store context.
     */
    loadCountTranslatorsAvailableToday(context) {
      return context.dispatch("browse", {
        endpoint: translatorsEndpoint,
        state: "translators_available_today",
        params: {
          "filter[available_now]": 1,
        }
      });
    },

    /**
     * @param {object} context - current store context.
     */
    loadDashboardStatistic(context, payload) {
      let params = {
        "filter[is_test]": 0,
      };

      if (!isEmpty(payload) && payload.include_test) {
        params = {};
      }

      return context.dispatch("browse", {
        endpoint: bookingsStatisticEndpoint,
        state: "statistics",
        params
      });
    },

  }
};
