<template>
  <div>
    <el-dropdown>
      <span class="el-dropdown-link">
        <i :class="iconClass"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <div class="top-heading-notification">
          <h4>{{$t('my_notifications')}}</h4>
        </div>
        <el-row>
          <el-col :md="20" @click.native="handleRedirect('sms.centre')">
            <el-dropdown-item><i class="fa fa-comments"></i> <small>SMS</small>
            </el-dropdown-item>
          </el-col>
          <el-col :md="4">
            <div class="notification-count "><small>{{conversationCount}} </small></div>
          </el-col>
        </el-row>


        <el-row>
          <el-col :md="20" @click.native="handleRedirect('booking-to-work-on')">
            <el-dropdown-item><i class="fa fa-file"></i> <small> {{$t('new_bookings_to_work_on')}}</small>
            </el-dropdown-item>
          </el-col>
          <el-col :md="4">
            <div class="notification-count"><small>{{to_work_on.count}}</small></div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :md="20" @click.native="handleRedirect('booking-expiring')">
            <el-dropdown-item><i class="fa fa-hourglass-start"></i> <small> {{$t('new_booking_about_to_expire')}} </small>
            </el-dropdown-item>
          </el-col>

          <el-col :md="4">
            <div class="notification-count"><small> {{to_expire.count}}</small></div>
          </el-col>
        </el-row>
        <!--        <el-dropdown-item><i class="fa fa-comments circle-icon"></i> <small> You have low feedback Items </small>-->
        <!--        </el-dropdown-item>-->
        <!--        -->
        <el-row>
          <el-col :md="20" @click.native="handleRedirect('booking.issue.pending-list')">
            <el-dropdown-item><i class="fa fa-clock-o"></i> <small> {{$t('pending_items_action_manager')}}</small>
            </el-dropdown-item>
          </el-col>

          <el-col :md="4">
            <div class="notification-count"><small> {{pending_issues}}</small></div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :md="20" @click.native="handleRedirect('booking.issue.list')">
            <el-dropdown-item><i class="fa fa-tasks"></i> <small>{{$t('assigned_issues_action_manager')}}</small>
            </el-dropdown-item>
          </el-col>

          <el-col :md="4">
            <div class="notification-count"><small> {{assigned_issues}}</small></div>
          </el-col>
        </el-row>

      </el-dropdown-menu>

    </el-dropdown>
  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex';
  import APICaller from '~/js/helpers/APICaller';
  import {gep} from "~/js/helpers/Vuex";


  export default {
    name: "TopBarNotification",

    computed: {
      ...mapState('smsCentre', {
        'conversationCount': 'conversationCount'
      }),

      to_expire() {
        return this.$store.state.dashboard.counts.to_expire;
      },

      pending() {
        return this.$store.state.dashboard.counts.pending_today;
      },

      to_work_on() {
        return this.$store.state.dashboard.counts.to_work_on;
      },
      is_difficult() {
        return this.$store.state.dashboard.counts.is_difficult;
      },
      userId() {
        return JSON.parse(localStorage.getItem('app_user_data')).id
      },

      priority () {
        if (this.conversationCount > 1) {
          return 1
        } else if (this.to_work_on.count > 1) {
          return 2
        } else {
          return 3
        }
      },

      /**
       * @returns {string[]}
       */
      iconClass () {
        let color = 'fa fa-bell ';

        if (this.priority === 1) {
          color += 'red';

        } else if (this.priority === 2) {
          color += 'orange';

        } else if (this.priority === 3) {
          color += 'purple';
        }

        return [
          this.blockClass + '-bell',
          color,
        ];
      }

    },

    data() {
      return {
        blockClass: 'top-bar-notification',
        pending_issues: 0,
        assigned_issues: 0,
      }
    },


    methods: {
      ...mapActions('smsCentre', {
        getConversationCount: 'getConversationCount'
      }),

      preparePage() {
        this.getConversationCount();
        APICaller({
          method: 'get',
          endpoint: gep('booking-issues', 'v3'),
          params: {
            'sort': '-deadline_date',
            'filter[pending]': true
          }
        }).then((res) => {
          this.pending_issues = res.data.meta.pagination.total;
        })

        APICaller({
          method: 'get',
          endpoint: gep('booking-issues', 'v3'),
          params: {
            'filter[assigned_admin_id]': this.userId
          }
        }).then((res) => {
          this.assigned_issues = res.data.meta.pagination.total;
        })
      },


      handleRedirect(redirectTo) {
        this.$router.push({name: redirectTo});
      }


    },
    mounted() {
      this.preparePage();
    }


  }
</script>

<style scoped>
  .circle-icon {
    background: #EEF1F7;
    padding: 10px;
    border-radius: 50%;
  }

  .top-heading-notification {
    margin-left: 20px;
    color: #4A80C0;
  }

  .el-dropdown-menu__item {
    margin-top: 20px;
  }

  .el-dropdown-menu__item:not(.is-disabled):hover,
  .el-dropdown-menu__item:focus {
    background-color: #ffffff;
    color: #84c3d1;
  }

  .top-bar-notification-bell {
    font-size: 18px;
  }


  .el-dropdown-menu {
    width: 360px;
  }

  .notification-count {
    margin-top: 25px;
  }

  .notification-count small {
    background: #EEF1F7;
    padding: 10px;
    border-radius: 25%;
  }

  .red {
    color: red;
  }

  .orange {
    color: orange;
  }

  .purple {
    color: purple;
  }
</style>
