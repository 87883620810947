<template>
    <div class="app-list user-mappings-list">

        <div class="app-row list-container">
            <div class="app-col col-container">

                <base-list-table
                        :table-data="listData"
                        :extra-cols="listCols"
                        :hasDetailsCol="false">

                    <!-- Row for Top Controls -->
                    <template slot="topControls" slot-scope="scope">
                        <div class="text-right">
                            <router-link tag="button" to="/aes/add/user" @click.stop.prevent title="Create"
                                         class="el-button el-button--success">
                                <i class="fa fa-plus"> </i> Create
                            </router-link>
                        </div>
                    </template>

                    <!-- Column for action buttons -->
                    <template slot="actionsColumn" slot-scope="scope">
                        <el-button class="circle" type="success" title="Edit">
                            <span class="fa fa-pencil"></span>
                        </el-button>
                        <el-button class="circle" type="danger" title="Delete">
                            <span class="fa fa-trash"></span>
                        </el-button>
                    </template>
                </base-list-table>

            </div> <!-- /.app-col -->
        </div> <!-- /.app-row list-container -->

    </div> <!-- /.app-list user-mappings-list -->
</template>

<script>
    // import {mapGetters} from "vuex";
    export default {

        /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                listCols: [
                    {
                        label: 'DT ID',
                        prop: 'dt_id',
                        width: '',
                        className: 'dt_id-col',
                    },
                    {
                        label: 'AES ID',
                        prop: 'aes_id',
                        width: '',
                        className: 'aes_id-col',
                    }
                ],
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            // ...mapGetters('company',['listData'])
            listData() {
                return [
                    {id: 1, dt_id: 519, aes_id: 518},
                    {id: 2, dt_id: 521, aes_id: 519},
                    {id: 3, dt_id: 523, aes_id: 520},
                ];
            },

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
        methods: {}, // End of Component > methods


        /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
        }, // End of Component > mounted

    }
</script>