<template>
  <div class="confirmation-fills-container">

    <el-dialog title="Create User and Department" :visible.sync="iVisible" :append-to-body="true"
            :lock-scroll="true" :width="width">

      <el-form :model="newDepartmentAndUser" ref='newDepartmentAndUser'
               id="new-department-and-user-form" v-loading="false"
               @submit.native.prevent="handleClickConfirm('confirmation_fill')">

              <el-form-item label="Name" class="label-short" prop="name" :rules="nameRules">
                  <el-input :id="genFieldId('name',formElId)" placeholder="Name" 
                        v-model="newDepartmentAndUser.name"/>
              </el-form-item>

              <el-form-item label="Email" class="label-short" prop="email" :rules="emailRules">
                  <el-input :id="genFieldId('username',formElId)" placeholder="Email"
                        v-model="newDepartmentAndUser.email"/>
              </el-form-item>

              <el-form-item label="Address" class="label-short" prop="address" :rules="addressRules">
                  <el-input :id="genFieldId('address',formElId)" placeholder="User address"
                        v-model="newDepartmentAndUser.address"/>
              </el-form-item>

              <el-form-item label="City" class="label-short" prop="city" :rules="cityRules">
                  <el-input :id="genFieldId('city',formElId)" placeholder="User City"
                        v-model="newDepartmentAndUser.city"/>
              </el-form-item>

              <el-form-item :id="genContainerId('generate_general_login',formElId)"
                              label="Generate a general login customer account instead?"
                              class="label-block">
                    <el-switch :id="genFieldId('generate_general_login',formElId)"
                              v-model="newDepartmentAndUser.generate_general_login"/>
              </el-form-item>

              <div class="app-splitter two-cols">

                <!-- Allow download invoices? -->
                <el-form-item :id="genContainerId('allow_show_invoices',formElId)"
                              label="Allow download invoices?"
                              class="label-medium">
                    <el-switch :id="genFieldId('allow_show_invoices',formElId)"
                              v-model="newDepartmentAndUser.allow_show_invoices"/>
                </el-form-item>

                <!-- Send email invoices? -->
                <el-form-item :id="genContainerId('allow_send_invoices',formElId)"
                              label="Send email invoices?"
                              class="label-medium">
                    <el-switch :id="genFieldId('allow_send_invoices',formElId)"
                              v-model="newDepartmentAndUser.allow_send_invoices"/>
                </el-form-item>
              
              </div>

              <div class="app-splitter two-cols">

                <!-- Allow fallback type? -->
                <el-form-item :id="genContainerId('allow_fallback_type',formElId)"
                              label="Allow fallback type?"
                              class="label-medium">
                    <el-switch :id="genFieldId('allow_fallback_type',formElId)"
                              v-model="newDepartmentAndUser.allow_fallback_type"/>

                </el-form-item>

                <el-form-item 
                              :id="genContainerId('allow_sms_confirmation',formElId)"
                              label="Allow SMS Confirmation"
                              class="label-medium">
                    <el-switch  :id="genFieldId('allow_sms_confirmation',formElId)"
                              v-model="newDepartmentAndUser.allow_sms_confirmation"/>
                </el-form-item>

              </div>

                <!-- <user-company-form v-model="newDepartmentAndUser.company_id"
                    :form-el-id="formElId"/> -->
              <user-department-form :showAdditional="false" v-model="newDepartmentAndUser.department_id"
                    :form-el-id="formElId"/>

        <div class="text-right">
          <el-button @click="iVisible = false">Cancel</el-button>
          <!-- <el-button type="primary" @click="handleClickConfirm('confirmation_fill')" v-loading="loading" :disabled="loading">Confirm</el-button> -->
          <el-button type="primary" native-type="submit" :disabled="false" v-loading="false" v-if="newDepartmentAndUser.show_confirm_btn">
            Confirm
          </el-button>
        </div>
      </el-form>

    </el-dialog> <!-- /. Booking Confirmation Fills -->
    <!-- <count-down :visible.sync="countdown" :booking="bookingData"></count-down> -->
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import {extendObjParams, isEmpty} from '~/js/helpers/Common'
  import UserCompanyForm from '~/components/forms/user/UserCompanyForm';
  import UserDepartmentForm from '~/components/forms/user/UserDepartmentForm';

  export default {
    components: {
        UserCompanyForm,
        UserDepartmentForm,
      },
      props: {
        visible: {
          type: Boolean,
          default: false
        },
        width: {
          type: String,
          default: '50%'
        },
        afterCreateHandler: {
            type: Function,
            default: () => null
        }
      },
      data() {

        return {
            formElId: 'create_user_and_department',
            emailRules: [
              { required: true, message: 'Please input email address', trigger: 'blur' },
              { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
            ],
            nameRules: [
              { required: true, message: 'Please input the customer name', trigger: 'blur' },
              { type: 'string', message: 'Name must not contain a number and/or special characters', trigger: ['blur', 'change'] }
            ],
            cityRules: [
              { required: true, message: 'Please input a city', trigger: 'blur' }
            ],
            addressRules: [
              { required: true, message: 'Please input an address', trigger: 'blur' }
            ]
        }
      },
     
      computed: {
        ...mapState('booking', {
          newDepartmentAndUser: 'newDepartmentAndUser',
        }),
        ...mapGetters({
            
        }),
        /**
         * Interface for this.visible
         */
        iVisible: {
          get() {
            return this.visible
          },
          set(v) {
            this.$emit('update:visible', v);
          }
        }
      },
      methods: {
        ...mapActions('customer', {
            addCustomer: 'addCustomerFromBooking',
        }),
        handleClickConfirm() {
            this.$confirm('Are you sure you want to create department and user', 'Confirm', {
                distinguishCancelAndClose: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'info'
            })
            .then(res => {
                this.addCustomer(this.newDepartmentAndUser).then(res => {
                  this.afterCreateHandler(res.data.data.customer.id, this.newDepartmentAndUser.generate_general_login, this.newDepartmentAndUser.email)
                }).catch(error => {
                  console.log(error)
                  this.$notify.error({
                    title: 'Error',
                    message: 'Configs are not set for this municipality'
                  });
                })
                // setTimeout(() => this.afterCreateHandler(447), 1500)
            })
            .catch(console.log)
        }
        
      }
    }

</script>

<style lang="scss">
  #confirmation-fills-form {
    .el-input__inner {
      // background: inherit;
      border-radius: 5px;
      // border-bottom-right-radius: 5px;
      background-color: #ffffff;
      border: 1px solid #d8dce5;
    }
  }

  .vue-input-tag-wrapper .input-tag {
    border: 0 !important;
    color: #5a5e66 !important;
    background-color: #d8dce5 !important;
  }

  .pac-container {
    z-index: 9999 !important;
  }

  #allow_fallback_type_notification {
    font-size: 12px;
    font-weight: bold;
    color: #F35531;
  }

  .red{
    label{
    color:#F35531 !important;
    }
  }
  .grey{
    label{
    color:#d8dce5 !important;
    }
  }

</style>