<template>
  <div class="app-form issue-list-filter-form">

      <el-form ref="formElId">

          <div class="app-splitter three-cols">
            <!-- Sort By -->
            <el-form-item>
                <el-select v-model="sort" :placeholder="$t('sort_by')" filterable>
                  <el-option value="-type_id" :label="$t('type')"></el-option>
                  <el-option value="-deadline_date" :label="$t('deadline_date')"></el-option>
                  <el-option value="-created_at" :label="$t('created_at')"></el-option>
                  <el-option value="-translator_id" :label="$t('translator')"></el-option>
                  <el-option value="-customer_id" :label="$t('customer')"></el-option>
                </el-select>
            </el-form-item>
            <!-- Booking ID -->
            <el-form-item>
              <el-input v-model="booking_id" :placeholder="$t('find_booking')"/>
            </el-form-item>
            <!-- Booking ID -->
            <el-form-item>
              <el-select v-model="type" :placeholder="$t('filter_type')" multiple clearable filterable>
                <el-option v-for="i in settingList" 
                           :key="i.id" 
                           :value="i.id" 
                           :label="$t(`tfv_types_${i.type}`)"
                           :value-key="i.id">{{$t(`tfv_types_${i.type}`)}}</el-option>
              </el-select>
            </el-form-item>
            <!-- municipality_id -->
            <el-form-item>
              <el-select v-model="municipality_id" filterable :placeholder="$t('txt_type_search_municipality')" remote
                  :remote-method="searchMunicipalities" :loading="searchMunicipalityLoading" clearable>
                  <el-option v-for="i in searchMunicipalityResult"
                             :key="i.id"
                             :label="i.name +' - '+ i.id"
                             :value="i.id">
                  </el-option>
              </el-select>
            </el-form-item>
            <!-- translator_id -->
            <el-form-item>
              <translator-select v-model="translator_id"></translator-select>
            </el-form-item>
            <!-- feedback_id -->
            <el-form-item>
              <el-input
                  v-model="feedback_id"
                  :placeholder="$t('txt_search_case')"
              />
            </el-form-item>

            <!-- customer -->
            <el-form-item>
              <el-select v-model="customer" filterable :placeholder="$t('type_search_customer')" remote clearable
                :remote-method="searchCustomer">
                <el-option v-for="i in searchResult" :key="i.id" :label="i.name +' - '+ i.id"
                    :value="i.id">
                </el-option>
              </el-select>
            </el-form-item>
            

          </div> <!-- /.app-splitter three-cols -->
          <div class="user-filter-action-buttons">
            <el-button type="primary" @click="resetForm">{{$t('reset')}}</el-button>
            <el-button type="success" @click="handleSearch">{{$t('find_now')}}</el-button>
            <el-button type="success" @click="handleFollowupButton">{{$t('follow_up')}}</el-button>
          </div>
      </el-form>
      
      <todo-form :visible.sync="showTodoForm"></todo-form>

  </div> <!-- /.app-form user-list-filter-form -->

</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty } from '../../../js/helpers/Common'
export default {
  data() {
    return {
      type: '',
      sort: '-deadline_date',
      showTodoForm:false,
      booking_id:'',
      translator_id:'',
      searchMunicipalityResult:[],
      searchMunicipalityLoading:false,
      municipality_id: '',
      customer_id: '',
      department_id: '',
      company_id: '',
      searchCustomerLoading:false,
      searchResult: [],
      selectedCustomer:{},
      searchDepartmentsLoading:false,
      searchDepartmentsResult:[],
      searchCompanyLoading:false,
      searchCompanyResult:[],
      feedback_id:'',
      customer:''
    }
  },
  computed:{
    ...mapGetters('bookingIssueSetting', {
      settingList: 'listData'
    }),
    ...mapGetters('customer', {
      customersList: 'listData'
    })
  },
  methods:{
    ...mapActions('booking',[
      'fetchDepartments',
      'fetchCompanies',
      'fetchMunicipalities',
      'filterCustomers'
    ]),
    ...mapActions('customer',{
      'fetchCustomers':'browse'
    }),
    handleFollowupButton(){
      this.showTodoForm = true
    },
    filterTranslator(){
      if(isEmpty(this.translator_id)) return false;
      console.log(this.translator_id);
      let params = {
        'filter[translator_id]':this.translator_id,
        'sort':'-deadline_date'
      }
      this.$emit('updateList', params)
    },
    filterMunicipality(){
      if(isEmpty(this.municipality_id)) return false;
         let omit = _.omit(this.$route.query,'filter[customer_id]')
        omit = _.omit(omit,'filter[department_id]')
        omit = _.omit(omit,'filter[company_id]')
      // this.$router.replace({ path: this.$route.params[0],query:omit})
      this.$router.replace({})
      console.log(this.$route)
      let params = {
        'filter[municipality_id]':this.municipality_id,
        'sort':'-deadline_date'
      }
      this.$emit('updateList', params)
    },
    filterList(){
      if(isEmpty(this.feedback_id)) return false;
      console.log(this.feedback_id);
      let params = {
        'filter[id]':this.feedback_id,
        'sort':'-deadline_date'
      }
      this.$emit('updateList', params)

    },
    filterByCustomer(){
      if(isEmpty(this.customer)) return false;
      console.log(this.customer);
      let params = {
        'filter[customer_id]':this.customer,
        'sort':'-deadline_date'
      }
      this.$emit('updateList', params)

    },
    
    filterCustomer(){
      if(isEmpty(this.customer_id)) return false;
      let omit = _.omit(this.$route.query,'filter[municipality_id]')
        omit = _.omit(omit,'filter[department_id]')
        omit = _.omit(omit,'filter[company_id]')
      // delete query['filter[municipality_id]']
      // delete query['filter[department_id]']
      // delete query['filter[company_id]']

      this.$router.replace({})
      console.log(this.$route)
      let params = {
        'filter[customer_id]':this.customer_id,
        'sort':'-deadline_date'
      }
      this.$emit('updateList', params)
    },
    searchMunicipalities(query){
      if(query!==''){
          this.searchMunicipalityLoading = true;
          let params ={
              'filter[name]': query,
              'all': true
          }

          setTimeout(()=> {
              this.fetchMunicipalities(params).then((response) => {

                this.searchMunicipalityResult =  response

              this.searchMunicipalityLoading = false;
          })

          },200)

      }else{
          // this.searchResult = this.customers;
      }
    },
    searchCustomer(query){
      if(query!=='' && query.length > 2){
          this.searchCustomerLoading = true;
          let params ={
              'filter[name]': query,
              'all': true
          }

          setTimeout(()=> {
              this.filterCustomers(params).then((response) => {

                if(isEmpty(response)){
                    let params2 = {
                          'filter[email]': query,
                          'all': true
                      }
                      this.filterCustomers(params2).then((response) => {
                            this.searchResult =  response

                      })
                }

                this.searchResult =  response

              this.searchCustomerLoading = false;
          })

          },200)

      }else{
          // this.searchResult = this.customers;
      }
    },
    resetForm(){
      this.sort=''
      this.type=''
      this.booking_id=''
      this.municipality_id=''
      this.translator_id=''
      this.customer=''
      this.$emit('updateList')
    },
    handleSearch() {
      const sort = this.sort;
      const booking_id = this.booking_id;
      const type = this.type;
      const municipality_id = this.municipality_id
      const translator_id = this.translator_id
      const feedback_id = this.feedback_id
      const customer = this.customer
      const params = {};

      params['sort'] = '-deadline_date'

      if (!isEmpty(sort)) {
        params['sort'] = sort;
      }
      if (!isEmpty(booking_id)) {
        params['filter[booking_id]'] = booking_id;
      }
      if (!isEmpty(this.type)) {
        params['filter[type]'] = this.type.join(',');
      }
      if(!isEmpty(municipality_id)){
        params['filter[municipality_id]'] = municipality_id
      }
      if(!isEmpty(translator_id)){
        params['filter[translator_id]'] = translator_id
      }
      if(!isEmpty(feedback_id)){
        params['filter[id]'] = feedback_id
      }
      if(!isEmpty(customer)){
        params['filter[customer_id]'] = customer
      }
      
      
      this.$emit('updateList', params)
    },
  },
  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted(){
    this.fetchCustomers()
  }
}
</script>
<style lang="scss" scoped>
.btn-suffix{
  margin: 2px !important;
}
</style>
