<template>
  <el-dialog
    :title="$t('clone_modal_title')"
    :visible.sync="iVisible"
    :show-close="true"
    :modal-append-to-body="false"
    :append-to-body="true"
    custom-class="custom-modal"
    width='70%'>

    <table>
      <tr>
        <td class="cell-label">
          <span>
            <el-checkbox
              @change="selectAll(selectedAll)" v-model="selectedAll"
              aria-label="Select All"
            />
            {{$t('select_all')}}
          </span>
        </td>
      </tr>
      <tr>
        <td class="cell-label">
          <span>
            <el-checkbox
              @change="handleChanges"
              v-model="clonedBooking.language"
              aria-label="Language"
            />
            {{$t('language')}}:
          </span>
        </td>
        <td class="cell-value"><span>{{ booking.language_name }}</span></td>
      </tr>
      <tr>
        <td class="cell-label">
          <span>
            <el-checkbox
              @change="handleChanges"
              v-model="clonedBooking.type"
              aria-label="Type"
            />
            {{$t('tfv_type')}}:
          </span>
        </td>
        <td class="cell-value"><span>{{ $t(booking.type) }}</span></td>
      </tr>
      <tr>
        <td class="cell-label">
          <span>
            <el-checkbox
              @change="handleChanges"
              v-model="clonedBooking.gender"
              aria-label="Gender"
            />
            {{$t('gender')}}:
          </span>
        </td>
        <td class="cell-value"><span>{{ booking.gender}}</span></td>
      </tr>
      <tr>
        <td class="cell-label">
          <span>
            <el-checkbox
              @change="handleChanges"
              v-model="clonedBooking.translator_levels"
              aria-label="Levels"
            />  {{$t('tfv_levels')}}:
          </span>
        </td>
        <td class="cell-value">
          <!-- <el-select :id="genFieldId(`trans_level_picker`)" v-model="booking.translator_levels" multiple clearable>
              <el-option v-for="level in translatorsLevels"
                        :key="level.id"
                        :value="level.id"
                        :label="level.name"/>
          </el-select> -->
          <ul>
            <li v-for="(level, k) in booking.translator_levels" :key="k">
              {{ (getTranslatorLevelById(level) != null) ? getTranslatorLevelById(level).name : '' }}
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td class="cell-label">
          <span>
            <el-checkbox
              @change="handleChanges"
              v-model="clonedBooking.address"
              aria-label="Booking address"
            />
            {{$t('booking_address')}}:
          </span>
        </td>
        <td class="cell-value"><span>{{ booking.address}}</span></td>
      </tr>
      <tr>
        <td class="cell-label">
          <span>
            <el-checkbox
              @change="handleChanges"
              v-model="clonedBooking.ref"
              aria-label="Reference"
            />
            {{ $t('reference') }}:
          </span>
        </td>
        <td class="cell-value"><span><a style="color:inherit !important; text-decoration: underline;"
                                        :href="`tel:${getNumFromRef(booking)}`">{{booking.reference}}</a></span>
        </td>
      </tr>
      <tr>
        <td class="cell-label"><span>
          <el-checkbox
            @change="handleChanges"
            v-model="clonedBooking.booker_name"
            aria-label="Booked Name"
          />
          {{$t('booked_name')}}:
        </span>
        </td>
        <td class="cell-value"><span>{{booking.booker_name}}</span></td>
      </tr>
      <tr>
        <td class="cell-label">
          <span>
            <el-checkbox
              @change="handleChanges"
              v-model="clonedBooking.staff_name"
              aria-label="Staff Name"
            />
            {{$t('staff_name')}}:
          </span>
        </td>
        <td class="cell-value"><span>{{booking.staff_name}}</span></td>
      </tr>

      <tr>
        <td class="cell-label">
          <span>
            <el-checkbox
              @change="handleChanges"
              v-model="clonedBooking.userDepartement"
              aria-label="User Department"
            />
            {{$t('tfv_user_department')}}:
          </span>
        </td>
        <td class="cell-value">
          <a href="#" style="color: inherit;"
             v-if="!window._.isNil(booking.customer.customer_data)"
             @click.stop.prevent="handleDepartment(booking.customer.customer_data)">
            <u>
              {{
              (booking.customer.customer_data)
              ? booking.customer.customer_data.department.name : ''
              }}
            </u>
          </a>
        </td>
      </tr>

      <tr>
        <td class="cell-label">
          <span>
            <el-checkbox
              @change="handleChanges"
              v-model="clonedBooking.customer_id"
              aria-label="User email"
            />
            {{$t('email')}}:
          </span>
        </td>
        <td class="cell-value">
          <a href="#" style="color: inherit;"
             v-if="!window._.isNil(booking.customer)"
             @click.stop.prevent="handleClickUserEmail(booking)">
            <u>{{booking.customer.email}}</u>
          </a>
        </td>
      </tr>

      <template v-if="booking.notification_types">
        <tr v-for="t in booking.notification_types" :key="t.type">
          <td class="cell-label">
            <span>
              <el-checkbox
                @change="handleChanges"
                v-model="clonedBooking.recipient"
                :aria-label="'Notification through' + t.type"
              />
              {{ $t('notification_through') }} {{ $t(t.type) }}:
            </span>
          </td>
          <td class="cell-value"><span>{{t.recipient}}</span></td>
        </tr>
      </template>

      <tr>
        <td class="cell-label">
          <span>
            <el-checkbox
              @change="handleChanges"
              v-model="clonedBooking.due"
              aria-label="Due"
            />
            {{$t('tfv_booking_due')}}:
          </span>
        </td>
        <td class="cell-value"><span>{{booking.due}}</span></td>
      </tr>
      <tr>
        <td class="cell-label">
          <span>
            <el-checkbox
              @change="handleChanges"
              v-model="clonedBooking.duration"
              aria-label="Duration"
            />
            {{$t('duration')}}:
          </span>
        </td>
        <td class="cell-value"><span>{{booking.duration | minutesToTime }}</span></td>
      </tr>
      <tr>
        <td class="cell-label"><span>
          <el-checkbox
            @change="handleChanges"
            v-model="clonedBooking.assigned_translator"
            aria-label="Assigned translator"
          />
          {{$t('assigned_translator')}}:
        </span>
        </td>
        <td class="cell-value">
          <span>{{ (booking.assigned_translator != null) ? booking.assigned_translator.name : '' }}</span></td>
      </tr>
      <tr>
        <td class="cell-label">
          <span>
          <el-checkbox
            @change="handleChanges"
            v-model="clonedBooking.specific_translators"
            aria-label="Specific Translators"
          />
          {{$t('specific_translators')}}:
          </span>
        </td>
        <td class="cell-value" v-if="booking.specific_translators && booking.specific_translators.length > 0">
          <!-- <el-select v-model="booking.specific_translators" multiple clearable>
              <el-option v-for="(item, index) in booking.specific_translators_name" :key="index"
                        :value="item.translator.id"
                        :label="item.translator.name"/>
          </el-select> -->
          <ul>
            <li v-for="item in booking.specific_translators_name" :key="item.translator.id">{{item.translator.name}}
            </li>
          </ul>
        </td>
        <td class="cell-value" v-else>
          <span>N</span>
        </td>
      </tr>
      <tr>
        <td class="cell-label">
          <span>
          <el-checkbox
            @change="handleChanges"
            v-model="clonedBooking.alternative_languages"
            aria-label="Alternative Languages"
          />
          {{$t('alternative_languages')}}:
          </span>
        </td>
        <td class="cell-value" v-if="booking.alternative_languages && booking.alternative_languages.length > 0">
          <ul>
            <li v-for="item in booking.alternative_languages" :key="item">{{ item | getLanguageName(languages) }}
            </li>
          </ul>
        </td>
        <td class="cell-value" v-else>
          <span>N</span>
        </td>
      </tr>
    </table>
    <el-row>
      &nbsp;

    </el-row>
    <el-row class="el-row-actions">
      <el-button class="modal-size" type="default" size="mini" @click="cancel">{{$t('cancel')}}</el-button>
      <el-button class="modal-size" type="primary" size="mini" @click="clone">{{$t('duplicate')}}</el-button>
    </el-row>


  </el-dialog>
</template>

<script>
  import {mapGetters, mapActions, mapState} from 'vuex';
  import {populate} from "~/js/helpers/Vuex"
  import {isEmpty} from '~/js/helpers/Common';

  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        window,
        clonedBooking: {
          language: '',
          type: '',
          due: '',
          customer_id: '',
          duration: '',
          gender: '',
          address: '',
          translator_levels: '',
          userDepartement: '',
          recipient: '',
          ref: '',
          specific_translators: '',
          booker_name: '',
          staff_name: '',
          assigned_translator: '',
          alternative_languages: '',
          convey:{phone:'',message:''}
        },
        bookingDetails: {
          language: '',
          type: '',
          due: '',
          customer_id: '',
          duration: '',
          gender: '',
          address: '',
          translator_levels: '',
          userDepartement: '',
          recipient: '',
          ref: '',
          specific_translators: '',
          booker_name: '',
          staff_name: '',
          assigned_translator: '',
          alternative_languages: '',
          convey:{phone:'',message:''}
        },
        selectedAll: false,
      };
    },
    filters: {
      minutesToTime: (v) => {
        return window.moment().startOf('day').add(v, 'minutes').format('HH:mm')
      },
      getKeyVal: (v, key) => {
        return _.map(v, key)
      },
      getLanguageName (v, languages) {

        return _.map(v, (o) => {

          if (!isEmpty(languages)) {
            const found = window._.find(languages, (x) => x.id === o);
            return found ? found.name : '';
          }
        }).join(', ')
      },
      getTownName: (v, o) => {

        if (!isEmpty(o) && !isEmpty(v)) {
          const found = window._.find(o, (x) => x.id === v);
          return found ? found.name : '';
        }
        return v

      },
      getTranslatorNames (v, translators) {
        let str = [];
        for (let lang of languages) {
          if (v === lang.id) {
            str.push(lang.name);
          }
        }
        return str.join(', ');
      },
      getStatusList (v, o) {
        if (!isEmpty(o) && !isEmpty(v)) {
          const found = window._.find(o, (x) => x.id === v);
          return found ? found.name : '';
        }
        return v
      }
    },
    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters({
        booking: 'booking/getBooking',
        from_language: 'language/getLanguageFrom',
        to_language: 'language/getLanguageTo',
        customer: 'booking/getCustomer',
        translator: 'booking/getAssignedTranslator',
        translators: 'booking/getTranslatorsList',
        languages: 'language/getLanguages',
        translatorsLevels: 'booking/getTranslatorLevels',
      }),
      /**
       * Interface for this.visible
       */
      iVisible: {
        get () {
          return this.visible
        },
        set (v) {
          this.$emit('update:visible', v);
        },
      },
    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions('booking', {
        loadTranslatorLevels: 'fetchTranslatorLevels',
        loadTranslators: 'fetchTranslators'
      }),
      ...mapActions('language', {
        loadLanguages: 'fetchLanguages'
      }),

      isEmpty (v) {
        return isEmpty(v)
      },
      getNumFromRef (val) {
        this.thenum = val.reference && val.reference.replace(/^\D+/g, '');
        return this.thenum;
      },
      handleChanges () {
        this.bookingDetails.language = this.clonedBooking.language ? this.booking.from_language_id : '';
        this.bookingDetails.type = this.clonedBooking.type ? this.booking.type : '';
        this.bookingDetails.due = this.clonedBooking.due ? this.booking.due : '';

        if (!window._.isNil(this.booking.customer)) {
          this.bookingDetails.customer_id = this.clonedBooking.customer_id ? this.booking.customer.id : '';
        } else {
          this.bookingDetails.customer_id = '';
        }

        this.bookingDetails.duration = this.clonedBooking.duration ? this.booking.duration : '';
        this.bookingDetails.gender = this.clonedBooking.gender ? this.booking.gender : '';
        this.bookingDetails.address = this.clonedBooking.address ? this.booking.address : '';
        this.bookingDetails.booker_name = this.clonedBooking.booker_name ? this.booking.booker_name : '';
        this.bookingDetails.staff_name = this.clonedBooking.staff_name ? this.booking.staff_name : '';
        this.bookingDetails.translator_levels = this.clonedBooking.translator_levels ? this.booking.translator_levels : '';

        if (this.clonedBooking.assigned_translator) {
          this.bookingDetails.assigned_translator = (this.booking.assigned_translator != null) ? this.booking.assigned_translator.translator : '';
        } else {
          this.bookingDetails.assigned_translator = '';
        }

        this.bookingDetails.specific_translators = this.clonedBooking.specific_translators ? this.getTranslatorsFromIds(this.booking.specific_translators) : '';
        this.bookingDetails.ref = this.clonedBooking.ref ? this.booking.reference : '';

        if (!window._.isNil(this.booking.customer.customer_data.department)) {
          this.bookingDetails.userDepartement = this.clonedBooking.userDepartement ? this.booking.customer.customer_data.department.name : '';
        } else {
          this.bookingDetails.userDepartement = '';
        }

        this.bookingDetails.alternative_languages = this.clonedBooking.alternative_languages ? this.booking.alternative_languages : '';

        if (this.bookingDetails.type === 'convey'){
          this.bookingDetails.convey = {
            phone: this.booking.convey.phone,
            message: this.booking.convey.message
          }
        }
      },
      getTranslatorsFromIds (ids) {
        let translators = [];
        for (let t of this.translators) {
          for (let id of ids) {
            if (t.id == id) {
              translators.push(t);
              break;
            }
          }
        }
        return translators;
      },
      getTranslatorLevelById (id) {
        let l = null;
        for (let level of this.translatorsLevels) {
          if (id == level.id) {
            l = level;
            break;
          }
        }
        return l;
      },
      clone () {
        this.$router.push({name: 'booking-create', params: {cloned: this.bookingDetails}});
      },
      cancel () {
        this.iVisible = false;
      },
      selectAll (entry) {
        this.clonedBooking = {
          language: entry,
          type: entry,
          due: entry,
          customer_id: entry,
          duration: entry,
          gender: entry,
          address: entry,
          translator_levels: entry,
          userDepartement: entry,
          recipient: entry,
          ref: entry,
          specific_translators: entry,
          booker_name: entry,
          staff_name: entry,
          assigned_translator: entry,
          alternative_languages: entry,
        };
        this.handleChanges();
      },

      /**
       * @param {Object} customerData
       */
      handleDepartment (customerData) {
        // Missing implementation by Joshua/Mokhtar
      },

      /**
       * @param {Object} booking
       */
      handleClickUserEmail (booking) {
        // Missing implementation by Joshua/Mokhtar
      }
    },

    mounted () {
      populate(this, 'loadTranslatorLevels', 'translatorsLevels', {
        ref: 'Translator Levels Options'
      });
      populate(this, 'loadTranslators', 'translators', {
        ref: 'Load translators'
      });
      populate(this, 'loadLanguages', 'languages', {
        ref: 'Load languages'
      });
    }
  }
</script>

<style scoped>
  .cell-label {
    min-width: 305px;
  }

  .cell-value ul {
    padding-left: 15px !important;
  }
</style>
