<template>
  <div class="app-list partners-list">
    <base-list-table
      class="partners-list-table"
      :table-data="listData"
      :extra-cols="listCols"
      :hasDetailsCol="false">

      <template slot="topControls">

        <el-button type="primary" @click="handleClickAdd">
          <span class="fa fa-plus"></span> {{$t('add_partner')}}
        </el-button>

      </template>

      <template slot="actionsColumn" slot-scope="scope">
        <el-button class="circle" type="success" title="Edit" @click="handleClickEdit(scope)">
          <span class="fa fa-pencil"></span>
        </el-button>
        <el-button class="circle" type="danger" title="Remove" @click="handleClickDelete(scope)">
          <span class="fa fa-trash"></span>
        </el-button>
      </template>

    </base-list-table>

    <!-- Pagination -->
    <el-pagination
      v-if="listPagination.total > listPagination.per_page"
      layout="prev, pager, next"
      :page-size="listPagination.per_page"
      :total="listPagination.total"
      :current-page="listPagination.current_page"
      @current-change="reloadList">
    </el-pagination>

    <partners-modal :value="partner" :visible.sync="partnerModal" :breadMode="breadMode"></partners-modal>

  </div> <!-- /.app-list financial-template-list -->
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {populate} from '../../../js/helpers/Vuex';

export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {}, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
        return {
            partnerModal: false,
            partner: {},
            breadMode: 'add'
        };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

        ...mapGetters('partners',{
            listData: 'listData',
            listPagination: 'listPagination',
        }),

        /**
         * Object to map the columns of the base-list-table.
         * @return {array}
         */
        listCols () {
        return [
            {
                label: 'ID',
                prop: 'id',
                width: '70px',
                className: 'id-col',
            },
            {
                label: 'Name',
                prop: 'name',
                className: 'name-col',
            },
            {
                label: 'Email',
                prop: 'email',
                className: 'name-col',
            },
            {
                label: 'Prio',
                prop: 'prio',
            }
        ];
        },

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

        ...mapActions('partners', {
            browse: 'browse',
            delete:'delete'
        }),

        /**
         * Handler when the Add/Create button was clicked.
         *
         * @return {void}
         */
        handleClickAdd () {
            this.partner= {};
            this.partnerModal= true;
        },

        /**
         * Handler when the Edit/Update button was clicked.
         *
         * @param {object} scope - the target scope/row.
         * @return {void}
         */
        handleClickEdit (scope) {
            this.breadMode= 'edit';
            this.partner= scope.row;
            this.partnerModal= true;
        },

        /**
        * Handler when the Delete/Remove button was clicked.
        *
        * @param {object} scope - the target scope/row.
        * @return {void}
        */
        handleClickDelete (scope) {

            this.$confirm(this.$t('are_you_sure_to_delete'), {
                confirmButtonText: 'Yes', cancelButtonText: 'No', type: 'warning'
            }).then(() => {
                const row = scope.row;
                this.delete({id: row.id}).then(()=>{
                    this.browse();
                });

            }).catch(() => {});
        },

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
        this.browse();
    }, // End of Component > mounted

} // End of export default
</script>
