<template>
  <div class="app-row">
    <div v-if="userInBookingList.length > 0">
      <div style="display: flex">
        <h3>Booking is being managed by</h3>
        <div
          style="margin-top:10px;padding-left: 6px;"
          v-for="(items, index) in userInBookingList"
          :key="index"
        >
          <el-tag type="success">{{items.user.name}}</el-tag>
        </div>
      </div>
    </div>
    <locked-booking-alert-modal :lockedBy="lockedBy" :isNormalLock="isNormalLock"
                                :visible.sync="showLockedBookingModal"/>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapState} from 'vuex';
  import {compare} from '~/js/helpers/GetObjectDifference';
  import {isEmpty} from '../../../js/helpers/Common';

  export default {
    data() {
      return {
        showLockedBookingModal: false,
        lockedBy: {},
        adminUiSettings: false,
        isNormalLock: false,
      };
    },
    props: {
      bookingId: {
        required: true
      },
      userId: {
        required: true
      }
    },
    computed: {
      ...mapGetters({
        userInBookingList: 'booking/getUsersInsideBookingList'
      }),

      ...mapState('adminUiSettings', {
        adminUiSetting: 'compose'
      }),

      ...mapState('booking', {
        locked: 'locked_booking'
      })
    },
    methods: {
      ...mapActions({
        postUserDataToFirebolt: 'booking/postUserDataToFirebolt',
        addUserInsideBooking: 'booking/addUserInsideBooking',
        deleteUserInsideBooking: 'booking/deleteUserInsideBooking',
        getUserInsideBooking: 'booking/getUserInsideBooking',
        getAdminUiSettings: 'adminUiSettings/getAdminUiSettings',
        createOrUpdateBookingLock: 'booking/createOrUpdateBookingLock',
        unlockTheBookingWhenUserLeaves: 'booking/unlockTheBookingWhenUserLeaves'
      }),
      /**
       * Event for listening the booking updated chanel
       */
      updateBookingEvent() {
        window.WS.subscribe('booking.' + this.bookingId).listen(
          'booking.updated.all',
          this.updateBookingListener
        );
      },

      /**
       * Listener for the booking updated chanel
       */
      updateBookingListener(data) {
        if (data.booking.id == this.$route.params.id) {
          if (data.booking.last_updated_by.id != this.userId) {
            this.$confirm(
              `Bokning uppdaterad av ${ data.booking.last_updated_by.type === 2 ? this.$t('customer') : this.$t('translator') }:` +
              data.booking.last_updated_by.name +
              '<br>' +
              'på: ' +
              data.booking.updated_at,
              this.$t('warning'),
              {
                confirmButtonText: 'OK',
                dangerouslyUseHTMLString: true,
                type: 'warning',
                showCancelButton: false
              }
            ).then(() => {
              this.$router.push({
                name: 'booking-histories-audit',
                params: {
                  id: this.bookingId
                }
              });
            });
          }
        }
      },
      /**
       * Event for listening the user in booking chanel
       */

      updateUserInBookingEvent() {
        window.WS.subscribe('user.in.booking.' + this.bookingId).listen(
          'user.in.booking.created',
          this.updateUserInBookingListner
        );
      },


      /**
       * Event for listening the user in booking chanel
       */

      bookingWithdrawnEvent() {
        window.WS.subscribe('booking.' + this.bookingId).listen('booking.withdrawn',
          this.updateBookingListener
        )

      },

      /**
       * Listener for the user in booking chanel
       */

      updateUserInBookingListner(data) {
        console.log(this.userInBookingList);
        if (data.bookingId == this.$route.params.id) {
          this.getUserInsideBooking(this.bookingId);
        }
      },

      // bookingAcceptedEvent() {
      //   window.WS.subscribe('booking.' + this.bookingId).listen(
      //     'booking.accepted.by.admin',
      //     this.updateBookingListener
      //   );
      // },

      bookingAcceptedByUserEvent() {
        window.WS.subscribe('booking.' + this.bookingId).listen(
          'booking.accepted',
          this.updateBookingListener
        );
      },

      /**
       * Event for delete user in booking
       */

      deleteUserInBookingEvent() {
        window.WS.subscribe('user.in.booking.' + this.bookingId).listen(
          'user.in.booking.deleted',
          this.deleteUserInBookingListner
        );
      },

      /**
       * Edit customer cancel booking envent
       */
      customerCancelBookingEvent() {
        window.WS.subscribe('booking.' + this.bookingId).listen(
          'booking.cancelled',
          this.updateBookingListener
        );
      },


      /**
       * Listener for delete user in booking
       */

      deleteUserInBookingListner(data) {
        if (data.bookingId == this.$route.params.id) {
          this.getUserInsideBooking(this.bookingId);
        }
      },
      /**
       * Retreiving socketID from pusher only when page is refreshed.
       * Else it will pick from AppInitialize
       */

      getSocketId() {
        return new Promise((resolve, reject) => {
          Echo.connector.pusher.connection.bind('connected', function () {
            resolve(Echo.socketId());
          });
        });
      },

      /**
       * Lock booking method which will create entry in the model
       * for locking current status
       */
      lockBooking() {
        let option = {
          comment: 'Testing',
          is_locked: 1,
          is_manual: false,
          booking_id: this.$route.params.id,
          user_id: JSON.parse(localStorage.getItem('app_user_data')).id
        };
        this.createOrUpdateBookingLock(option);
      },

      fetchUserAndCheckSettings() {
        this.getUserInsideBooking(this.bookingId).then(response => {
          console.log(response.data.data.items.length);
          let user = _.uniqBy(
            _.filter(response.data.data.items, function (o) {
              return (
                o.user_id != JSON.parse(localStorage.getItem('app_user_data')).id
              );
            }),
            'user_id'
          );


          setTimeout(() => {
            this.getAdminUiSettings().then(() => {
              if (this.adminUiSetting.value.showPopUpWarning == true) {
                this.adminUiSettings = this.adminUiSetting.value.showPopUpWarning;

                if (user.length == 0) {
                  if (isEmpty(this.locked) || this.locked[0] && this.locked[0].is_locked == 0) {
                    this.lockBooking();
                  } else if (this.locked[0].user_id != this.userId) {
                    this.showLockedBookingModal = true;
                    this.lockedBy = this.locked[0];
                    this.isNormalLock = true;
                    console.log(this.lockedBy)
                  }
                } else {
                  if (user[0].user_id != this.userId) {
                    this.showLockedBookingModal = true;
                    this.lockedBy = user[0];
                  }
                }
              } else if (!isEmpty(this.locked) && this.locked[0] && this.locked[0].is_locked == 1) {
                console.log(this.locked)
                this.lockedBy = this.locked[0];
                this.isNormalLock = true;
                this.showLockedBookingModal = true;

              }
            });
          }, 2000)

        });
      },

      /**
       * Initializing the feature of User Inside the booking
       */

      prepareUserInBookingFeature() {
        this.updateUserInBookingEvent();
        this.updateBookingEvent();
        this.deleteUserInBookingEvent();
        // this.bookingAcceptedEvent();
        this.bookingAcceptedByUserEvent();
        this.fetchUserAndCheckSettings();
        this.bookingWithdrawnEvent();
        this.customerCancelBookingEvent();

        // var socketID = Echo.socketId();
        let payload = {
          action: 'join_webhook',
          data: {
            booking_id: this.$route.params.id,
            user_id: this.userId,
            is_active: 1
          }
        };
        window.WS.sendBack(payload);
        this.addUserInsideBooking(payload);
        // }
      }
    },

    watch: {
      bookingId: function(newVal, oldVal) { // watch it
       this.prepareUserInBookingFeature();
      }
    },

    mounted() {
      this.prepareUserInBookingFeature();
    }, //End of mounted

    /**
     * Unlisten the events
     */
    beforeDestroy() {
      window.WS.channel('booking.' + this.bookingId).unlisten(
        'booking.updated.all',
        this.updateBookingListener
      );

      window.WS.unsubscribe(['user.in.booking.' + this.bookingId, 'booking.' + this.bookingId]);
      if (this.adminUiSettings == true) {
        this.unlockTheBookingWhenUserLeaves({
          booking_id: this.bookingId,
          user_id: this.userId
        });
      }
    } // End of beforeDestroy
  };
</script>
