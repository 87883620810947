/*
|--------------------------------------------------------------------------
| Financial > Price Template > state
|--------------------------------------------------------------------------
| Partial file for state object related to the scope.
*/

'use strict';

import businessRules from '../settings/shared/props/businessRules';
import charging from '../settings/shared/props/charging';
import transactionFee from '../settings/shared/props/transactionFee';
import emergencyBooking from '../settings/shared/props/emergencyBooking';
import inconvenienceCharge from '../settings/shared/props/inconvenienceCharge';
import travelSettings from '../settings/shared/props/travelSettings';

let settingsFresh = {
  business_rules: _.cloneDeep(businessRules),
  charging: _.cloneDeep(charging),
  transaction_fee: _.cloneDeep(transactionFee),
  emergency_booking: _.cloneDeep(emergencyBooking),
  inconvenience_charge: _.cloneDeep(inconvenienceCharge),
  travel_settings: _.cloneDeep(travelSettings),
}

const formFresh = {
  name: '',
  template_for: '',
}

const payloadFresh = {
  name: '',
  template_for: '',
  invoice_setting: {},
  salary_setting: {}
}

export default {

  list: {
    data: [],
    pagination: {total: 0, per_page: 10, current_page: 1,}
  },

  listAsOptions: [],

  form: _.cloneDeep(formFresh),
  formFresh: _.cloneDeep(formFresh),

  payload: _.cloneDeep(payloadFresh),
  payloadFresh: _.cloneDeep(payloadFresh),

}