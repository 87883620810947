<template>
  <div class="list-base-table">

    <div class="list-base-table-top-controls" v-if="hasTopControlsSlot">
      <slot name="topControls"/>
    </div> <!-- .list-base-table-top-controls -->

    <div class="list-base-table-wrapper">
      <!-- Base List Table -->
      <el-table :data="tableData" style="width: 100%" v-loading="loading"
                :row-class-name="checkRowClass" @row-click.self="handleProfile">
        <el-table-column
          v-for="col in cols"
          :key="crProps(col, 'prop')"
          :prop="crProps(col, 'prop')"
          :label="crProps(col, 'label')"
          :width="crProps(col, 'width')"
          :class-name="'blt-col ' + crProps(col, 'className')"
          :sortable="crProps(col, 'sortable')">
          <template slot-scope="scope">
            <h4 class="cell-title">
              {{ crProps(col, 'label') }}
            </h4>

            <span v-if="crProps(col, 'showPopover') && mok(scope.row, crProps(col, 'prop'), col.popover)" class="cell-content">
              <el-popover trigger="hover" placement="top">
              <p>{{mok(scope.row, crProps(col, 'prop'), col.popover)}}</p>
              <div slot="reference" class="name-wrapper">
                <span v-html="mok(scope.row, crProps(col, 'prop'), col.mutator)"></span>
              </div>
            </el-popover>
            </span>

              <span v-else class="cell-content" v-html="mok(scope.row, crProps(col, 'prop'), col.mutator)"></span>

          </template>
        </el-table-column>

        <!-- Info Column -->
        <el-table-column class-name="info-col" v-if="hasInfoColSlot && hasInfoCol"
                         :label="$t('info')" :width="infoColWidth">
          <template slot-scope="scope">
            <slot
              name="infoColumn"
              :$index="scope.$index"
              :row="scope.row"/>
          </template>
        </el-table-column>

        <!-- Details Column -->
        <el-table-column class-name="details-col" v-if="hasDetailsColSlot && hasDetailsCol"
                         :label="$t('details')" :width="detailsColWidth">
          <template slot-scope="scope">
            <slot
              name="detailsColumn"
              :$index="scope.$index"
              :row="scope.row"/>
          </template>
        </el-table-column>

        <!-- Actions Column -->
        <el-table-column class-name="actions-col" v-if="hasActionsColSlot && hasActionsCol"
                         :label="$t('actions')" :width="actionsColWidth">
          <template slot-scope="scope">
            <slot
              name="actionsColumn"
              :$index="scope.$index"
              :row="scope.row"/>
          </template>
        </el-table-column>

      </el-table>
      <!-- End of Base List Table -->
    </div> <!-- .list-base-table-wrapper -->


  </div> <!-- .list-base-table -->
</template>

<script>
  import {mok, crProps, isEmpty} from "../../../js/helpers/Common";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      tableData: {
        type: Array,
        default() {
          return [];
        }
      },
      extraCols: {
        type: Array,
        default() {
          return [];
        }
      },
      detailsColWidth: {
        type: String,
        default: '',
      },
      actionsColWidth: {
        type: String,
        default: '240px',
      },
      infoColWidth: {
        type: String,
        default: '',
      },
      hasDetailsCol: {
        type: Boolean,
        default: true,
      },
      hasActionsCol: {
        type: Boolean,
        default: true,
      },
      hasInfoCol: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false
      },
      rowClass: {
        type: Array,
        default() {
          return [];
        }
      }

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data: function () {
      return {
        preloading: true,
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Define default columns (this should be array or objects).
       * Please refer to /src/assets/js/helpers/Common.js@crProps() for the
       * documentation of the expected values.
       *
       * @return {array}
       */
      cols: function () {

        let extra = this.extraCols;

        if (_.isEmpty(extra)) {
          extra = [
            {
              label: this.$t('id'),
              prop: 'id',
              width: '50px',
              className: 'id-col',
            },
          ];
        }

        return extra;

      }, // End of cols() method

      /**
       * Returns wether the topControls slot is defined.
       */
      hasTopControlsSlot() {
        return !!this.$slots['topControls'];
      },

      /**
       * Returns wether the detailsColumn scoped slot is defined.
       */
      hasDetailsColSlot() {
        return !!this.$scopedSlots['detailsColumn'];
      },

      /**
       * Returns wether the actionsColumn scoped slot is defined.
       */
      hasActionsColSlot() {
        return !!this.$scopedSlots['actionsColumn'];
      },

      /**
       * Returns wether the infoColumn scoped slot is defined.
       */
      hasInfoColSlot() {
        return !!this.$scopedSlots['infoColumn'];
      },

      iRowClass() {
        return this.rowClass
      },
      empty(v){
        return isEmpty(v)
      }

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      // Mapping of helpers
      crProps, mok,isEmpty,

      checkRowClass({row}) {
        if (!isEmpty(this.iRowClass)) {
          let className = '';
          _.each(this.iRowClass, (v) => {
            if (row[v.field]) {
              className = v.class
            }
          })
          return className
        }

        return '';
      },
       handleProfile(val) {
        if(this.$router.currentRoute.path == '/user/list')
        {
            // Redirect to the profile page.
            // window.location.href = '/#/user/profile/' + scope.row.id;
              this.$router.push({name:'user.profile', params: {id: val.id}, query:{type: val.type}})
        }
       }
    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted: function () {

    }, // End of Component > mounted

  } // End of export default
</script>

<style>


</style>
