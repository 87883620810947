/*
|--------------------------------------------------------------------------
| Store > Financial > Adjustment > Actions
|--------------------------------------------------------------------------
| File to define the actions that is relevant for the current scope.
*/

'use strict';

import APICaller from '~/js/helpers/APICaller';
import {extendObjParams, mapEndpoint, sd} from '~/js/helpers/Common';
import {Notification} from 'element-ui';
import i18n from '~/js/i18n';

export default {

  /**
   * Get an Item according to the given ID
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} [payload]
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  read (context, payload = {}) {

    // Define the target booking id if it doesn't exist yet.
    payload.booking = sd(payload.booking, context.state.targetBooking);

    let method = context.state.api.read.method;
    let endpoint = mapEndpoint(context.state.api.read.path, payload);
    let params = extendObjParams(context.state.api.read.params, payload);

    return APICaller({method, endpoint, params})
      .then((r) => {
        let raw = r.data.data; // Store into container so varname will be shorter.
        let data = raw[Object.keys(raw)[0]]; // Get the first member of the object.
        context.commit('setItem', {data});

        if (context.state.autoReflectItem) {
          context.commit('setCompose');
        }

        return r;
      });

  },

  /**
   * Save an item.
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  save (context, payload = {}) {

    // Define the target booking id if it doesn't exist yet.
    payload.booking = sd(payload.booking, context.state.targetBooking);

    let method = context.state.api.save.method;
    let endpoint = mapEndpoint(context.state.api.save.path, payload);
    let data = {};
    let isDataRaw = true;

    // Set the value for payload.data
    if (context.state.autoInjectPayload) {
      context.commit('setPayloadData');
      data = context.state.payloadData;
    } else {
      data = payload.data;
    }

    console.log(data);

    return APICaller({method, endpoint, data, isDataRaw})
      .then((r) => {

        Notification.success({
          title: i18n.t('success'),
          message: i18n.t('n_entry_added')
        });

        return r;
      })
      .catch(() => {
        Notification.error({
          title: i18n.t('error'),
          message: i18n.t('n_went_wrong')
        });
      });

  }
}