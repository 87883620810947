<template>
  <div class="app-list" :class="blockClass">

    <div :class="getBem(blockClass, 'top-controls')">
      <el-button :class="getBem(blockClass, 'create-financial-list-btn')"
                 type="primary" size="mini"
                 @click="handleClickCreateFinancialList">
        {{ $t('create_financial_list') }}
      </el-button>
      <div :class="getBem(blockClass, 'show-in-list')">
        <span>{{ $t('tfv_show') }}:</span>
        <el-select v-model="showInList" size="mini" @change="handleChangeShowInList">
          <el-option :label="$t('all')" value="all"/>
          <el-option :label="$t('complete')" value="complete"/>
          <el-option :label="$t('incomplete')" value="incomplete"/>
        </el-select>
      </div>
    </div> <!-- Top Controls -->

    <el-table :class="getBem(blockClass, 'table')"
              :data="listData"
              v-loading="isListLoading">

      <el-table-column
        label="ID"
        prop="id"
        width="70px"
      />

      <el-table-column
        :label="$t('name')"
        prop="name"
      />

      <el-table-column
        :label="$t('period_start')"
        prop="period_start"
        width="120px"
      />

      <el-table-column
        :label="$t('periode_end')"
        prop="period_end"
        width="120px"
      />

      <el-table-column :label="$t('completed')" width="100px">
        <template slot-scope="scope">
          {{ scope.row.is_complete ? $t('yes') : $t('no') }}
        </template>
      </el-table-column>

      <el-table-column :label="$t('actions')">
        <template slot-scope="scope">
          <el-button type="primary"
                     size="mini"
                     :class="getBem(blockClass, 'table-btn')"
                     @click="handleClickProcessExport(scope.row.id)">
            {{ $t('process_export') }}
          </el-button>

          <el-button type="warning"
                     size="mini"
                     :class="getBem(blockClass, 'table-btn')"
                     @click="handleClickDelete(scope.row.id)">
            {{ $t('delete') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination v-if="isValidListPagination"
                   layout="prev, pager, next"
                   :page-size="listPagination.per_page"
                   :total="listPagination.total"
                   :current-page="listPagination.current_page"
                   @current-change="reloadList">
    </el-pagination>

    <el-dialog :class="getBem(blockClass, 'compose-modal')"
               class="with-sidebar-margin"
               :visible.sync="isShowComposeModal"
               :modal-append-to-body="true"
               :append-to-body="true"
               width="90%"
               :title="$t('tfv_fill_fields')">
      <financial-export-compose-financial-list-form
        v-model="exportForm"
        @submit="handleSubmitComposeFinancialList"/>
    </el-dialog>


  </div> <!-- /.app-list financial-export-list -->
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import FinancialExportComposeFinancialListForm
  from '~/components/forms/financial/export/FinancialExportComposeFinancialListForm';

export default {

  /*
  |--------------------------------------------------------------------------
  | Component > imported components
  |--------------------------------------------------------------------------
  */
  components: {
    FinancialExportComposeFinancialListForm
  },

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data () {
    return {
      blockClass: 'financial-export-list',
      isShowComposeModal: false,
      showInList: 'all'
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapState('financialExport', [
      'exportForm'
    ]),

    ...mapGetters('financialExport', [
      'listData',
      'listPagination',
      'isListLoading',
      'isValidListPagination'
    ])
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    ...mapMutations('financialExport', [
      'resetExportForm'
    ]),

    ...mapActions('financialExport', [
      'loadFinancialLists',
      'createFinancialList',
      'removeFinancialList'
    ]),

    /**
     * Handle when the Create Financial List button was clicked.
     *
     * @return {void}
     */
    handleClickCreateFinancialList () {
      this.resetExportForm();
      this.setDefaultValueForExportForm();
      this.isShowComposeModal = true;
    },

    /**
     * Handle when the Compose Form was submitted.
     *
     * @erturn {void}
     */
    handleSubmitComposeFinancialList () {
      this.createFinancialList().then(() => {
        this.isShowComposeModal = false;
        this.reloadList();
      });
    },

    /**
     * Handler when the Process Export button was clicked.
     *
     * @param financialListId
     * @return {void}
     */
    handleClickProcessExport (financialListId) {
      this.$router.push({
        name: 'financial.export.process',
        params: {
          id: financialListId
        }
      });
    },

    /**
     * Handler when the Delete button was clicked.
     *
     * @param financialListId
     * @return {void}
     */
    handleClickDelete (financialListId) {
      this.$confirm('Are you sure you want to delete this item?', {
        confirmButtonText: 'Yes', cancelButtonText: 'No', type: 'warning'

      }).then(() => {
        this.removeFinancialList({financial_list: financialListId}).then(() => {
          this.reloadList();
        });

      }).catch(() => {});
    },

    /**
     * Method for loading/reloading data for the list table.
     *
     * @param page
     * @return {void}
     */
    reloadList (page) {

      if (window._.isNil(page)) {
        page = window._.isNil(this.$route.query.page)
          ? 1 : parseInt(this.$route.query.page);
      }

      this.$router.push({query: {page}});

      let params = {page};

      if (this.showInList === 'complete') {
        params['filter[is_complete]'] = 1;

      } else if (this.showInList === 'incomplete') {
        params['filter[is_complete]'] = 0;
      }

      this.loadFinancialLists({params});
    },

    /**
     * @return {void}
     */
    handleChangeShowInList () {
      this.reloadList(1);
    },

    /**
     * Helper method for setting default values for the form.
     *
     * @return {void}
     */
    setDefaultValueForExportForm () {
      this.exportForm.name = `Financial List for Month of ${window.moment().format('MMMM')}`;
      this.exportForm.options.month = window.moment().format('MMMM');
      this.exportForm.period_start = window.moment().startOf('month').format('YYYY-MM-DD');
      this.exportForm.period_end = window.moment().endOf('month').format('YYYY-MM-DD');
      this.exportForm.salary_payment_date = window.moment().endOf('month').format('YYYY-MM-DD');
    },

  },

  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted () {
    this.reloadList();

  }

}
</script>
