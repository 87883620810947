/*
|--------------------------------------------------------------------------
| Store > Setting -> Maintenance Mode
|--------------------------------------------------------------------------
|
| This file contains the mutation of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/

"use strict";
import { mapProps } from "~/js/helpers/Common";

export default {

    /**
     * Method to map the value target entry's values into the form.
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    setCompose(state, payload) {
        state.compose = payload.settings[0];
    },

    /**
     * Method to reset the compose prop to its pristine state.
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    resetCompose(state) {
        state.compose = _.cloneDeep(state.composeFresh);
    },

}