<template>
  <div class="alert-modal-container">
    <el-dialog :title="$t(title)"
               @close="handleClose"
               :modal-append-to-body="appendToBody"
               :visible.sync="modalVisible" center>
      <div v-if="alertType==='success'">
        <div class="alert">
          <img src="/images/defaults/success-icon-19.png" alt="" srcset="">
        </div>

        <div>
          <h1> {{$t('booking_created')}} ID: {{bookingId}}</h1></div>

        <el-form :model="form">
          <el-form-item label="">
            <el-checkbox
              :label="$t('create_another_booking')"
              :aria-label="$t('create_another_booking')"
              v-model="form.new_booking"
            />
          </el-form-item>
        </el-form>
      </div>
      <div v-else>
        <div class="alert">
          <img src="/images/defaults/message-24-error.png" alt="" srcset="">
        </div>
        <h1>Create booking failed</h1>
        <p v-html="message"></p>

      </div>
      <div class="dialog-footer" v-if="alertType==='success'">
        <!-- <el-button @click="handleClose">Cancel</el-button> -->

        <el-button type="primary" @click="handleClickConfirm()">Confirm</el-button>
        <router-link :to="'/booking/'+ bookingId + '/details'" title="Booking Details" tag="button"
                     class="el-button el-button--default">
          Booking Details
        </router-link>
        <!-- <el-button  @click="viewBookingsDetails()">Booking Details</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'AlertModal',
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: 'booking_created'
      },
      appendToBody: {
        type: Boolean,
        default: false
      },
      alertType: {
        type: String,
        default: 'success'
      },
      bookingId: {
        default: '1'
      },
      message: {
        default: ''
      }
    },
    data () {
      return {
        modalVisible: this.visible,
        form: {
          new_booking: false
        }
      }
    },
    computed: {},
    watch: {
      visible: function (newVal) {
        this.modalVisible = newVal;
      },
    }, // End of Component > watch
    methods: {
      handleClose: function () {
        this.$emit('update:visible', false);
      }, // End of handleClose() method

      handleClickConfirm () {
        const self = this;
        this.$emit('update:visible', false);
      }
    }
  }

</script>
<style lang="scss">
  .alert-modal-container {
    .el-dialog__body {
      text-align: center;

      h1 {
        font-size: 40px;
      }

      h2 {
        font-size: 30px;
      }
    }

    .alert {
      img {
        width: 100px;
      }
    }
  }
</style>
