import APICaller from '~/js/helpers/APICaller'
import API from '~/js/constants/api'

export default {
  createHoliday (context, payload) {
    const apiOpts = {
      endpoint: `${API.HOLIDAYS}`,
      method: 'post',
      data: payload,
      isDataRaw: true
    }

    return APICaller(apiOpts)
      .then((res) => {
        return res
      }).catch(err => {
        return err
      })
  },
  editHoliday (context, payload) {
    const apiOpts = {
      endpoint: `${API.HOLIDAYS}/${payload.id}`,
      method: 'put',
      data: payload,
      isDataRaw: true
    }

    return APICaller(apiOpts)
      .then((res) => {
        return res
      }).catch(err => {
        return err
      })
  }
}