<template>

  <div>
    <h4>{{$t('related_emails')}}</h4>
     <h5>{{$t('add_ticket_id')}}</h5>
        <el-form :model="ticketForm" ref="ticketForm" @submit.native.prevent="handleClickSubmitTicket('ticketForm')">
          <el-form-item>
            <el-input v-model="ticketForm.ticket_id" placeholder='Ticket id'></el-input>
          </el-form-item>
          <el-form-item >
            <el-button type="primary" native-type="submit" :disabled="loading" v-loading="loading">
              {{$t('submit')}}
            </el-button>
          </el-form-item>
        </el-form>
      <base-list-table
        :table-data="list"
        :extra-cols="listCols"
        :hasDetailsCol="false">
      </base-list-table>
  </div>
</template>

<script>
import { isEmpty } from '../../../../js/helpers/Common';

  import {Notification} from 'element-ui';
export default {
  props:{
    issue:{
      type:Object,
      default:{
        id:''
      }
    }
  },
  data(){
    const _this=this;
    return {
      list :[],
      listCols:[
        {
            label:  _this.$t('ticket_id'),
            prop: 'id',
            width: '',
            className: 'id-col',
            mutator(v){
							return `<a href="${app_dt_handesk_url}tickets/${v}" target="_blank">#${v}</a>`
						}
        },
        {
            label: _this.$t('subject'),
            prop: 'title',
            width: '',
            className: 'date-col',
        },
        {
            label: _this.$t('created_at'),
            prop: 'created_at',
            width: '',
            className: 'date-col',
        }
      ],
      ticketForm:{
        ticket_id:''
      },
      loading:false

    }
  },
  computed:{
    iIssue(){
      return this.issue
    }
  },
  methods:{
    getRelatedEmails(){
      axios.get(`${app_dt_handesk_url}api/booking-issues?booking_issue_id=${this.$route.params.id}`,
      {
        headers:{
          'token':app_dt_handesk_token
        }
      }).then(r =>{

        this.list = r.data.data
        if(!isEmpty(this.issue.booking_id)){
          this.attachBooking(r.data.data)
        }
      })
    },
    attachBooking(v){
      v.forEach(async (e) => {
        if(isEmpty(e.bookings)){
        let data  = await axios.post(`${app_dt_handesk_url}api/tickets/${e.id}/bookings`,
        {tag:this.issue.booking_id },
        {
          headers:{
            'token':app_dt_handesk_token
          }
        })
        }
      });
    },
    async handleClickSubmitTicket(){
      this.loading = true;
      try {
        let data  = await axios.post(`${app_dt_handesk_url}api/tickets/${this.ticketForm.ticket_id}/booking-issue`,
        {
          booking_issue_id: this.issue.id
        },
        {
          headers:{
            'token':app_dt_handesk_token
          }
        });
        if(data.status === 200){
          this.getRelatedEmails()
          Notification.success({
          title: 'Success!',
          message: `Ticket Id Added`,
        });
        this.loading = false;
        }
      } catch (error) {
        Notification.error({
        title: 'Error!',
        message: `Ticket Id Not Found, please check again`,
        });
        this.loading = false;
      }
     
``
      // }else if(data.status === 404){
      //   
      // }
    }


  },
  mounted(){
    this.getRelatedEmails()
  }
}
</script>
