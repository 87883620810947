<template>
    <div class="app-page bookings-page">

        <page-header :title="$t(title)" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col booking-filter-form-container">
                        <div class="content-body">

                            <h3>{{$t('filter_booking_by')}}</h3>
                            <booking-filter-form :v-populate-payload="payloadParams"/>

                        </div> <!-- /.content-body -->

                    <fab @update.sync="handleUpdate"/>
                    </div> <!-- /.app-col -->

                    <div class="app-col booking-list-container">
                        <div class="content-body">

                            <text-translation-booking-list :v-populate-payload="payloadParams"/>

                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->

        </div> <!-- /.app-container -->
    </div> <!-- /.app-page bookings-page -->

</template>

<script>
import { isEmpty,sd } from '../../../js/helpers/Common';

import fab from '~/components/displays/FloatingActionButton'
import { mapActions } from 'vuex';
import TextTranslationBookingList from "~/components/lists/text-translation/TextTranslationBookingList";

import { populate, forcePopulate } from "~/js/helpers/Vuex";
export default {
  components: {fab,TextTranslationBookingList},
  data() {
    return {
      title: 'text_translation_list',
      payloadParams: {
          'filter[is_test]': '0',
          'filter[type]':'text_translation',
          'sort': '-created_at',
          append:'has_batches,translators_count',
          include:'text_translation.files'
      }

    }
  },
  watch: {
    "$route.name"() {
      // call the method which loads your initial state
      this.initPage()
    }
  },
  methods:{
    ...mapActions("booking", ["browse"]),

    mergeQuery(n,per_page){
      let params = _.clone(this.$route.query);
      n = sd(n, sd(this.$route.query.page, 1)); // Set the page number.
      params.page = n;
      if (per_page && typeof per_page == "number") {
        params["per_page"] = per_page;
      }
      this.payloadParams.page = params.page;

      if (
        !isEmpty(params["filter[is_test]"]) &&
        params["filter[is_test]"] === 1
      ) {
        this.payloadParams["filter[is_test]"] = 1;
      }

      Object.assign(params, this.payloadParams);
      this.$router.push({ query: params }); // Reflect the chosen parameters in the URL Bar

      populate(this, "browse", "", {
        force: true,
        params
      });
    },
    initPage(){
      switch (this.$route.name) {
        case 'text-translations.list.all':
          this.title = 'text_translation_list_all';

          if(!isEmpty(this.payloadParams['filter[status_id]'])){
            delete this.payloadParams['filter[status_id]']
          }
          this.mergeQuery();

          break;

        case 'text-translations.list.pending':
          this.title = 'text_translation_list_pending';

          this.payloadParams = Object.assign(this.payloadParams, {'filter[status_id]':'1'} );
          this.mergeQuery();
          break;

        case 'text-translations.list.ongoing':

          this.title = 'text_translation_list_ongoing';

          this.payloadParams = Object.assign(this.payloadParams, {'filter[status_id]':'2,3'} );
          this.mergeQuery();

          break;

        case 'text-translations.list.completed':
          this.title = 'text_translation_list_completed';
          this.payloadParams = Object.assign(this.payloadParams, {'filter[status_id]':'4'} );
          this.mergeQuery();

          break;

        default:
          this.title = 'text_translation_list_all';
          if(!isEmpty(this.payloadParams['filter[status_id]'])){
            delete this.payloadParams['filter[status_id]']
          }
          this.mergeQuery();
          break;
      }
    },
    handleUpdate(e){
        this.payloadParams = e;
    }
  },
  mounted() {
    this.initPage()
  }

}
</script>
