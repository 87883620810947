// store > booking > batches > state
'use strict';

import API from '~/js/constants/api';

let batchesFresh = {
  list: [],
  loading: false,
  page: 1, // Indication of list page currently appended to list.
  per_page: 15,
  total: 0,
  fetched: 0, // For storing how many entries are already fetched.
  end_of_data: false // Indication if the list is beyond it's last page already.
};

export default {
  api: {
    read: {
      method: 'get',
      path: `${API.BOOKINGS}/{id}/batches`,
      params: {
        include: 'entries.translator'
      }
    },
  },

  batches: window._.cloneDeep(batchesFresh),
  batchesFresh: window._.cloneDeep(batchesFresh),

  batchesNonSendable: window._.cloneDeep(batchesFresh),
  batchesNonSendableFresh: window._.cloneDeep(batchesFresh),

  batchesScheduler: window._.cloneDeep(batchesFresh),
  batchesSchedulerFresh: window._.cloneDeep(batchesFresh),

  booking: {},
  translatorTriesToAcceptList: [],
  batchesStatus: '',
  batchesStatusList: [
    {id: 1, code: 'pending', name: 'Pending', step: '1/9'},
    {id: 2, code: 'collecting_data', name: 'Collecting Data', step: '2/9'},
    {id: 3, code: 'data_collected', name: 'Data Collected', step: '3/9'},
    {id: 4, code: 'processing_data', name: 'Processing Data', step: '4/9'},
    {id: 5, code: 'processed_data', name: 'Processed Data', step: '5/9'},
    {id:6, code: 'prioritizing', name: 'Prioritizing', step:'6/9'},
    {id:7, code: 'completed_list', name: 'Completed List', step:'7/9'},
    {id:8, code: 'returning_list', name: 'Returning List', step:'8/9'},
    {id:9, code: 'completed', name: 'Completed', step:'9/9'},
    //{id:10, code: 'failed', name: 'Failed', step:'10/10'}
  ]
}
