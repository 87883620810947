/*
|--------------------------------------------------------------------------
| Store > SMS Centre
|--------------------------------------------------------------------------
|
| This file contains the action of Auth Vuex Module
|
|
*/
import {Notification} from "element-ui";

import i18n from "../../i18n";
import {isEmpty} from "../../helpers/Common";
import APICaller from "../../helpers/APICaller";

let apiPrefix = "v3";
import {gep} from "~/js/helpers/Vuex";

export default {

  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  getConversationList(context, payload) {
    let params = {
      include: ['user', 'sms_centre_conversation', 'sms_channel_bookings']
    };
    if (payload) params = _.extend(params, payload.params);

    const method = "get";
    const endpoint = gep("sms-centre", apiPrefix);

    return APICaller({method, endpoint, params})
      .then(r => {
        const data = r.data.data.items;
        context.commit("setConversationList", data);
      })
      .catch(e => {
        console.error(e);
      });
  },
  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  getSpecificConversation(context, payload) {
    let params = {
      include: ['user', 'sms_centre_conversation', 'sms_channel_bookings']
    };
    const method = "get";
    const endpoint = gep(`sms-centre/${payload.id}`, apiPrefix);

    return APICaller({method, endpoint, params})
      .then(r => {
        const data = r.data.data.item;
        context.commit("setConversation", data);
        context.commit("setBookingIds", data)
      })
      .catch(e => {
        console.error(e);
      });
  },

  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  getConversationCount(context, payload) {
    const method = "get";
    const endpoint = gep(`sms-centre-unread-conversation`, apiPrefix);

    return APICaller({method, endpoint})
      .then(r => {
        const data = r.data.data.items[0].unread_conversation;

        context.commit("setConversationCount", data);
      })
      .catch(e => {
        console.error(e);
      });
  },

  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  createConversation(context, payload) {
    const method = "POST";
    const endpoint = gep(`sms-centre-conversation`, apiPrefix);

    return APICaller({
      method,
      endpoint,
      data: payload,
      isDataRaw: true
    })
      .then(r => {
        context.commit('setNewMessage', r.data.data)
        Notification.success({
          title: i18n.t("Success"),
          message: "Message sent"
        });
      })
      .catch(e => {

        Notification.error({
          title: i18n.t("error"),
          message: e.response.data.data
        });
      })
      .catch(e => {
        console.error(e);
      });
  },
  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  toggleUnread(context, payload) {
    const method = "POST";
    const endpoint = gep(`sms-centre-conversation-toggle-unread`, apiPrefix);

    return APICaller({
      method,
      endpoint,
      data: payload,
      isDataRaw: true
    })
      .then(r => {
        context.dispatch('getConversationList')
      })
      .catch(e => {
        console.error(e);
      });
  },

  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  createSMS(context, payload) {
    const method = "POST";
    const endpoint = gep(`sms-centre`, apiPrefix);

    return APICaller({
      method,
      endpoint,
      data: payload,
      isDataRaw: true
    })
      .then(r => {
        context.dispatch('getConversationList');
        return r.data.data.item;
      })
      .catch(e => {
        console.error(e);
      });
  },

  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  assignBooking(context, payload) {
    const method = "POST";
    const endpoint = gep(`sms-centre-assign-booking/${payload.id}`, apiPrefix);

    return APICaller({
      method,
      endpoint,
      data: payload,
      isDataRaw: true
    })
      .then(r => {
        context.dispatch('getConversationList')
        context.commit('setBookingIds', r.data.data.item);
      })
      .catch(e => {
        Notification.error({
          title: i18n.t('error'),
          message: e.response.data.data,
        });
      });
  },


};
