<template>
  <el-dialog
    :visible.sync="iVisible"
    :append-to-body="true"
    :before-close="resetOption"
    title="Booking Export">
    <el-form>
      <template v-if="String(exportType) === 'export_booking_financial'">
        <el-form-item class="label-block" label="Drivers">

          <multiselect v-model="form.drivers"
                       :multiple="true"
                       :taggable="true"
                       :options="drivers"
                       placeholder="add export booking drivers"
                       @tag="setTag">
            <span slot="noResult">add export booking drivers</span>
          </multiselect>

        </el-form-item>
      </template>

      <el-form-item class="label-block" label="Recipients">

        <multiselect v-model="form.recipients"
                     :multiple="true"
                     :taggable="true"
                     :options="recipients"
                     placeholder="add export booking recipients"
                     @tag="setTag"
        >
          <span slot="noResult">add export booking recipients</span>
        </multiselect>

      </el-form-item>

      <el-button
        :loading="export_loading"
        type="primary"
        size="mini"
        @click="handleExport('formExport')"
      >
        Export
      </el-button>

    </el-form>
  </el-dialog>
</template>

<script>
  import {mapActions} from "vuex";
  import Multiselect from "vue-multiselect";

  export default {
    components: {Multiselect},
    data() {

      return {
        form: {
          drivers: '',
          recipients: []
        },

        recipients: [],
        drivers: [
          'fast-excel',
          'csv'
        ],
        export_loading: false
      }
    },
    name: "ExportBookingModal",
    props: {
      visible: {
        type: Boolean,
        default: false
      },

      exportType: {
        type: String,
        default: ''
      },
      exportButton: {
        type: Function
      }
    },
    computed: {
      /**
       * Interface for this.visible
       */
      iVisible: {
        get() {
          return this.visible
        },
        set(v) {
          this.$emit('update:visible', v);
        }
      },
    },

    methods: {
      ...mapActions('booking', {
        export: 'export',
      }),

      handleExport() {
        this.exportButton(this.form.recipients, this.exportType, this.form.drivers)
      },
      setTag(e) {
        this.recipients.push(e);
        this.form.recipients.push(e);
      },
      resetOption() {
        this.$emit('resetBookingExport')
      }
    }
  }


</script>

<style scoped>

</style>
