/*
|--------------------------------------------------------------------------
| Financial > shared > props > languagesSettings
|--------------------------------------------------------------------------
*/

'use strict';

export default {

  languages: [],
}
