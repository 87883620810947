/*
|--------------------------------------------------------------------------
| Financial > shared > props > transactionFee
|--------------------------------------------------------------------------
*/

'use strict';

import constants from './constants';
import {genPropsFromCollection} from '~/js/helpers/Vuex';

const translatorLevelFeeSubProps = genPropsFromCollection(
  constants.BOOKING_TYPES, {fee: 0}
);

export default {

  // Transaction Fee
  pay_transaction_fee: false,

  transaction_fees: genPropsFromCollection(
    constants.BOOKING_METHODS, {fee: 0}
  ),

  // Office Hours
  office_hours_fee: {
    pay_outside_hours: false,
    fee: 0,
    start_time: '00:00',
    end_time: '00:00',
    transaction_fee_percentage: 0,
  },

  // Booking Type Fee
  pay_booking_type_fee: false,

  booking_type_fees: genPropsFromCollection(
    constants.BOOKING_TYPES, {fee: 0}
  ),

  // Translator Level Fee
  pay_translator_level_fee: false,
  translator_level_fees: genPropsFromCollection(
    constants.TRANSLATOR_LVLS, translatorLevelFeeSubProps,
  ),

}
