/*
|--------------------------------------------------------------------------
| Store > translator-unvailable-times > getters
|--------------------------------------------------------------------------
|
| This file contains the getters property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';
import {isEmpty} from '~/js/helpers/Common'

export default {
  /**
   * Get the unvailbles list state
   *
   *  * @param {object} state
   *  : current state of the vuex Object
   *
   * @return {array}
   */
  getEmployees: (state) => state.employees,
  getPagination: (state) => state.pagination,
  getDelayedBookings:(state) => state.delayedBookings

}
