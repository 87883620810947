export default {

  /**
   * @param state
   * @param payload
   */
  setVerbiages (state, payload) {
    state.verbiages = payload;
    localStorage.setItem(state.verbiagesCookieKey, JSON.stringify(payload));
  },

  /**
   * @param state
   * @param bool
   */
  setIsLoadingVerbiages (state, bool) {
    state.isLoadingVerbiages = bool;
  },

  /**
   * @param state
   * @param payload
   */
  setLastUpdate (state, payload) {
    state.lastUpdate = payload;
    localStorage.setItem(state.lastUpdateCookieKey, JSON.stringify(payload));
  },

  /**
   * @param state
   * @param payload
   */
  setNewLastUpdate (state, payload) {
    state.newLastUpdate = payload;
  },

  /**
   * @param state
   * @param bool
   */
  setIsLoadingLastUpdate (state, bool) {
    state.isLoadingLastUpdate = bool;
  }
};
