<template>
    <div class='app-display dashboard-count-display ' :style="'background-color:#' + backgroundColor">

        <div class="app-content-header">
            <h4>{{ title }}</h4>
        </div>

        <div class="app-content-body">
            <div class="icon-container">
                <span :class="icon" :style="'color: #' + iconColor + ';'"></span>
            </div>
            <div class="count-container">
                {{value}}
            </div>
        </div>

        <div class="app-content-footer">
            {{percentage + '%'}}
        </div>

    </div> <!-- /.app-display dashboard-count-display -->
</template>

<script>
    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            /**
             * The title value that will be displayed.
             */
            title: {
                type: String,
                default: 'No Title',
            },

            /**
             * The count value that will be displayed.
             */
            value: {
                type: Number,
                default: 0,
            },

            /**
             * The percentage value that will be displayed.
             */
            percentage: {
                type: Number,
                default: 0,
            },

            /**
             * The icon (through css class) the will be displayed.
             */
            icon: {
                type: String,
                default: 'fa fa-user',
            },

            /**
             * Hex code value (color) to be applied as the icon's color.
             */
            iconColor: {
                type: String,
                default: '333333',
            },
            /**
             * Hex code value (color) to be applied as the icon's color.
             */
            backgroundColor: {
                type: String,
                default: '',
            },

        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {};
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {}, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {}, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
        }, // End of Component > mounted

    } // End of export default
</script>
