<template>
  <el-dialog
    title="Edit Survey Period"
    :visible.sync="iVisible"
    :modal-append-to-body="false"
    :lock-scroll="false"
    width="50%"
  >
    <el-form>
      <div class="block">
        <span class="demonstration"></span>
        <el-date-picker
          v-model="date"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          start-placeholder="Start Date"
          end-placeholder="End Date"
        ></el-date-picker>
      </div>

      <el-form-item class="el-form-actions">
        <el-button class="modal-size" @click="handleClose" type="default" >Cancel</el-button>
        <el-button class="modal-size" @click="handleSubmit" type="primary" >Save</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      date: ""
    };
  },
  props: {
    visible: {
      Type: Boolean,
      default: false
    },
    updateSurveyTime: {
        Type: Function
    }
  },
  computed: {
    iVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit("update:visible", v);
      }
    }
  },
  methods: {
    /**
     * Method from parent component to update survey time
     */
    handleSubmit() {
        if(this.date) {
      this.updateSurveyTime(this.date)
      this.handleClose()
        }
    },
    handleClose: function() {
      this.$emit("update:visible", false);
    } // End of handleClose() method
  }
};
</script>

<style lang="scss">
  //noinspection CssUnknownTarget
  @import '~/scss/global/_variables.scss';

.el-dialog__header {
  text-align: center;
  background: $app-primary-color;
}
.el-dialog__title {
  color: #ffffff;
  font-size: 22px;
}
</style>
