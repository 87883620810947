
'use strict';

import {gep} from "~/js/helpers/Vuex";

let text_translation = {
  assigned_translator: '',
  cost: '',
  created_by: '',
  customer_id: '',
  deliver_with: '',
  descriptions: '',
  due: '',
  files: [{
    pivot:{
      type:'review'
    },
    path:'',
    words_count:0
  }],
  from_language_id: '',
  id: '',
  is_confidential: false,
  is_express: false,
  is_fixed_price: false,
  is_stamped: false,
  is_test: false,
  salary: false,
  status: '',
  status_code: '',
  to_language_id: [],
  will_send_at: null,
  email:'',
  phone:'',
  customer:{
    name:'',
    email:''
  },
  translator:{
    name:'',
    email:''
  },
  potential_translators:[],
  comments:[],
  locked_booking: []
}

let apiPrefix = 'v3'
export default {
  text_translations: [],
  text_translation:_.cloneDeep(text_translation),
  loading:false,
  api: {
    browse: {method: 'get', path: gep('text-translations', apiPrefix), params: {
      'include': 'files,',
    }, data: {}},
    read: {method: 'get', path: gep('text-translations/{id}', apiPrefix), params: {}, data: {}},
  },
  files:[{
    pivot:{
      type:'review'
    },
    path:'',
    words_count:0
  }]
}
