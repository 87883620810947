/*
|--------------------------------------------------------------------------
| Store > Feedback > getters
|--------------------------------------------------------------------------
|
| This file contains the getters property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/

// import {isEmpty} from '~/js/helpers/Common'

export default {
  getFeedbacks: (state) => state.feedbacks,
  addFeedback: (state) => state.addFeedback,
  getType: (state) => state.type,
  getLoading: (state) => state.loading
}