<template>
<div>
    <template v-if="reviewType==3">
      <template v-if="!isEmpty(customerFeedback)">
        <h4>{{$t('customer_feedback')}}</h4>
        <el-rate disabled v-model="customerFeedback.rating" :colors="['#FF6565']" disabled-void-color="#A9A9A9"></el-rate>
        <p>
          {{customerFeedback.comment}}
        </p>

      </template>
      <template v-else>
        <span class="el-alert el-alert--warning">{{$t('no_feedback_from_customer_yet')}}</span>
      </template>
    </template>

    <template v-else>
      <template  v-if="!isEmpty(translatorFeedback)">
        <h4>{{$t('translator_feedback')}}</h4>
        <el-rate disabled v-model="translatorFeedback.rating" :colors="['#FF6565']" disabled-void-color="#A9A9A9"></el-rate>
        <p>
          {{translatorFeedback.comment}}
        </p>
      </template>
      <template v-else>
        <span class="el-alert el-alert--warning">{{$t('no_feedback_from_translator_yet')}}</span>
      </template>

    </template>
    <hr>
</div>

</template>

<script>
import { isEmpty } from '../../../../js/helpers/Common';
import {mapActions, mapState, mapGetters} from 'vuex';
export default {
  props:{
    feedback:{
      type: Array,
      default(){
        return []
      }
    },
    solved:{
      type:Boolean,
      default:false
    },
    issue:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return {
    }
  },
  computed:{
    ...mapState('auth',{
      user:'userData'
    }),
    ...mapGetters('bookingIssue', {
      reviewType: 'getReviewType'
    }),
    iFeedback(){
      return this.feedback
    },
    translatorFeedback(){
      let val = this.iFeedback.filter(o=>o.review_from_type==3).find(o=>o.rating<3)
      return !isEmpty(val) ? val: this.iFeedback.find(o=>o.review_from_type==3)
    },
    customerFeedback(){
      let val = this.iFeedback.filter(o=>o.review_from_type==2).find(o=>o.rating<3)
      return !isEmpty(val) ? val: this.iFeedback.find(o=>o.review_from_type==2)
    },
    isSolved(){
      return !isEmpty(this.issue.solved_at)
    },
    iIssue(){
      return this.issue
    }
  },
  methods:{

    isEmpty(v) {
        return isEmpty(v)
      },
      ...mapActions('bookingIssue', {
        updateIssue: 'updateIssue',
        postComment: 'postComment'
      }),
      ...mapActions({
        storeBlacklist: 'feedback/storeBlacklist'
      }),
      handleSolvedButton() {
        let params = {
          booking_issue_id: this.issue.id,
          solved: true
        }
        this.updateIssue(params).then(() => {
          let payload = {
            booking_issue_id: this.issue.id,
            comment: 'set As Solved By ' + this.user.name,
            created_by: this.user.id
          }
        });
      },
    }
}
</script>
