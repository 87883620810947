<template>
    <div class="app-display preloader-gear text-center">
        <div class="preloader-container" style="display: inline-block;">
            <div class="lds-gear" style="display: inline-block;"><div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
            <p>{{ title }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: 'Loading Data... Please wait.'
            },
        },
    }
</script>
