
'use strict';
import {isEmpty} from '~/js/helpers/Common'
export default {
  getTextTranslation: (state) => state.text_translation,
  getLoading: (state) => state.loading,
  reviewFile:(state) => {
    return state.text_translation.files.find(i => {
      return i.pivot.submission_type == 'review'
    })
  },
  forReviewFile:(state) => {
    return state.text_translation.files.find(i => {
      return i.pivot.submission_type == 'submited_for_review'
    })
  },
  fromReviewFile:(state) => {
    return state.text_translation.files.find(i => {
      return i.pivot.submission_type == 'submited_from_review'
    })
  },
  completedFile:(state) => {
    return state.text_translation.files.find(i => {
      return i.pivot.submission_type == 'complete'
    })
  },
}