/*
|--------------------------------------------------------------------------
| Store > Financial > credits > Invoice > State
|--------------------------------------------------------------------------
|
| This file contains the state property of the current scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import API from '~/js/constants/api';
import {credit} from '../shared/props';

const endpoint = API.FINANCIAL.CREDITS.INVOICES;
const endpointWithId = API.FINANCIAL.CREDITS.INVOICES + '/{id}';

const invoiceTypeOptions = [
  'allowance',
  'appointment-fee',
  'booking-type-fee',
  'emergency-fee',
  'flat-inconvenience-fee',
  'flat-session-fee',
  'immediate-fee',
  'inconvenience-travel-time-fee',
  'inconvenience-travel-distance-fee',
  'minimum-inconvenience-fee',
  'minimum-session-fee',
  'off-hours-fee',
  'parts-session-fee',
  'rush-booking-fee',
  'succeeding-inconvenience-fee',
  'succeeding-session-fee',
  'transaction-fee',
  'translator-level-fee',
  'travel-distance-fee',
  'travel-time-fee',
  'minimum-travel-time-fee',
  'succeeding-travel-time-fee',
  'text-translation-fee',
  'text-translation-express-fee',
  'text-translation-formatting-fee',
  'text-translation-transaction-fee',
  'text-translation-verification-fee'
]

export default {

  model_info: {
    name: 'Invoice Financial Preview Store',
    module: 'invoicePreview'
  },

  api: {
    browse: {method: 'get', path: endpoint, params: {per_page: 15}},
    browseAsOptions: {params: {}},
    read: {method: 'get', path: endpointWithId, params: {}, data: {}},
    edit: {method: 'put', path: endpointWithId, params: {}, data: {}},
    add: {method: 'post', path: endpoint, params: {}, data: {}},
    delete: {method: 'delete', path: endpointWithId, params: {}, data: {}}
  },

  composeFresh: _.cloneDeep(credit),
  compose: _.cloneDeep(credit),

  invoiceTypeOpts: _.cloneDeep(invoiceTypeOptions),
  targetBooking: 0,

  payloadData: {}, // Container for payload data.
  pagination: {},
  translatorSalaryCredits: [],
  departmentInvoiceCredits: [],

  autoInjectPayload: true,
  autoReflectItem: true,
  autoUpdateList: true

} // End of export default
