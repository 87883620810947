<template>
    <div class="app-page user-list-page">

        <page-header title="Blacklist User List" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">

                    <div class="app-col full-width">
                        <div class="content-body">
                          <div class="app-list">
                          <base-list-table
                        :table-data="blacklist"
                        :extra-cols="listCols"
                        :hasDetailsCol="false">
                          </base-list-table>
                      </div>
                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page user-list-page -->
</template>

<script>

import { mapGetters,mapActions } from 'vuex';

export default {
  
  data(){
    return {
      type:this.$route.query.type
    }
  },
  computed:{
    ...mapGetters('blacklistedUsers', {
      blacklist: 'getBlacklistedUsers'
    }),
    
    listCols(){
      
      return [
        {
          label: 'User',
          prop: (this.type ==2) ?'customer':'translator',
          mutator(v,row){
            return `${v.name} - ${v.email} - ${v.id}`
          
          }
        },
        {
          label: (this.type ==2) ? 'Blacklisted users': 'Blacklist by',
          prop: (this.type ==2) ?'translator':'customer',
          mutator(v,row){
            return `${v.name} - ${v.email} - ${v.id}`
          
          }
        },
      ]
      
    }
  },
  methods:{
     ...mapActions('blacklistedUsers',{
      fetchBlacklistedUsers:'fetchBlacklistedUsers'
    }),
  },
  mounted(){
    // this.type = this.$route.query.type
    let params = {
        all:true,
        include:'translator,customer'
      }

    if (this.$route.query.type == 2) {
      params = Object.assign(params,{
        'filter[user_id]': this.$route.params.id
      })
    }else{
      params = Object.assign(params,{
        'filter[translator_id]': this.$route.params.id
      })
    }


    this.fetchBlacklistedUsers(params)

    setTimeout(() => {
      console.log(this.blacklist)
    }, 3000);
  }
}
</script>