'use strict';

import {extendObj} from "../../helpers/Common";
import bread from '../bread';
import state from './state';
import actions from './actions';

let mutations = {};
let getters = {};


export default {
    namespaced: true,
    state: extendObj(bread.state, state),
    getters: extendObj(bread.getters, getters),
    mutations: extendObj(bread.mutations, mutations),
    actions: extendObj(bread.actions, actions),
} // End of export default
