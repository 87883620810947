<template>
    <div class="app-form translations-form">
        <h3>New Translation</h3>
        <el-form :model="form" :id="formElId" :ref="formElId">
            <el-form-item :id="genContainerId('group',formElId)"
                        label="Group" class="label-short">
                <el-input :id="genFieldId('group',formElId)"
                 v-model="form.group"/>
                </el-input>
            </el-form-item>
            <el-form-item :id="genContainerId('item',formElId)"
                        label="Item" class="label-short">
                <el-input :id="genFieldId('item',formElId)"
                 v-model="form.item"/>
                </el-input>
            </el-form-item>
            <el-form-item :id="genContainerId('text_for_swedish',formElId)"
                        label="Text for Swedish" class="label-short">
                <el-input :id="genFieldId('text_for_swedish',formElId)"
                 v-model="form.text_for_swedish"/>
                </el-input>
            </el-form-item>
            <el-form-item :id="genContainerId('text_for_english',formElId)"
                        label="Text for English" class="label-short">
                <el-input :id="genFieldId('text_for_english',formElId)"
                 v-model="form.text_for_english"/>
                </el-input>
            </el-form-item>

            <el-form-item :id="genContainerId('save_btn',formElId)">
                <el-button :id="genFieldId('save_btn',formElId)" type="primary">
                    Save
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {genFieldId, genContainerId} from "../../../js/helpers/Html";
    export default {
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                formElId: 'translations-form',
                form: {
                    group: '',
                    item: '',
                    text_for_swedish: '',
                    text_for_english: '',
                }
            };
        },// End of Component > data
    }// End of export default

</script>