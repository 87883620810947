<template>
    <div class="app-page settings-page">
        <page-header :title="$t('settings')" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col full-width">
                        <el-tabs v-model="activeTab">
                          <el-tab-pane :label="$t('basic_settings')" name="basic">
                              <basic-settings-form />
                          </el-tab-pane>
                          <!-- <el-tab-pane label="Financial List" name="financial">
                             <financial-settings-list />
                          </el-tab-pane> -->
                          <el-tab-pane :label="$t('holidays')" name="holidays">
                              <holidays-settings-list />
                          </el-tab-pane>
                          <el-tab-pane :label="$t('test_languages')" name="test_languages">
                              <test-languages-settings-form />
                          </el-tab-pane>
                          <el-tab-pane label="Default Municipality" name="default_municipality">
                              <default-municipality-settings />
                          </el-tab-pane>
                          <!-- <el-tab-pane label="Test Users" name="test_users">
                              <test-users-settings-list />
                          </el-tab-pane>
                          <el-tab-pane label="Taxes" name="taxes">
                               <taxes-settings-form />
                          </el-tab-pane> -->
                          <!-- <el-tab-pane label="Batches Setting" name="batches_settings">
                              <batches-settings-list />
                          </el-tab-pane> -->
                          <el-tab-pane :label="$t('batch_templates')" name="batch_templates">
                               <batch-settings-list/>
                          </el-tab-pane>
                          <el-tab-pane :label="$t('customer_types')" name="customer_types">
                              <customer-types />
                          </el-tab-pane>
                          <el-tab-pane :label="$t('kpi_settings')" name="kpi_settings">
                              <kpi-settings/>
                          </el-tab-pane>
                           <el-tab-pane :label="$t('maintenance_mode_settings')" name="maintenance_mode_settings">
                              <maintenance-mode-settings/>
                          </el-tab-pane>
                          <el-tab-pane :label="$t('trainings_register')" name="trainings_register">
                              <trainings-list />
                          </el-tab-pane>
                          <el-tab-pane :label="$t('booking_resources')" name="booking_resources">
                              <resources-list />
                          </el-tab-pane>
                          <el-tab-pane :label="$t('expense_types')" name="expense_types">
                              <expense-types-list />
                          </el-tab-pane>
                           <!-- <el-tab-pane label="KPI Settings" name="kpi_settings">
                              <kpi-settings/>
                          </el-tab-pane> -->
                          <el-tab-pane :label="$t('emails_settings')" name="emails_settings">
                              <emails-templates-list/>
                          </el-tab-pane>
                          <el-tab-pane :label="$t('planned_languages')" name="planned_languages">
                              <planned-languages-list/>
                          </el-tab-pane>
                          <el-tab-pane :label="$t('admin_ui_settings')" name="admin_ui_settings">
                              <admin-ui-settings/>
                          </el-tab-pane>
                          <el-tab-pane :label="$t('partners')" name="partners">
                              <partners-list/>
                          </el-tab-pane>
                        </el-tabs>
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page settings-page -->
</template>

<script>
export default {
    data() {
        return {
            activeTab: 'basic'
        }
    }
}
</script>
