<template>
    <div :class="getBem(blockClass)">
        <el-row v-show="Isbooking"
                type="flex"
                style="flex-wrap: wrap"
                justify="center"
                align="middle"
                :gutter="2"
        >
            <el-col :xs="12" :sm="4" :md="4">
                <translator-select
                    :class="getBem(blockClass, 'translator-select-field')"
                    v-model="translatorName" size="mini"
                />
            </el-col>

            <el-col :xs="12" :sm="4" :md="4">
                <el-button
                    :class="getBem(blockClass, 'assign-button')"
                    type="primary" size="mini"
                    @click="handleSubmit('assign_translator')"
                >
                    {{$t('assign_button')}}
                </el-button>
            </el-col>

            <el-col :xs="12" :sm="4" :md="4">
                <el-select
                    :class="getBem(blockClass, 'status-field')"
                    v-model="iStatus"
                    :placeholder="$t('change_status')"
                    filterable
                    size="mini"
                >
                    <el-option v-for="t in statusList" :key="t.id" :value="t.id" :label="$t(`tfv_status_${t.code}`)"></el-option>
                </el-select>
            </el-col>

            <el-col :xs="12" :sm="4" :md="4">
                <el-button
                    :class="getBem(blockClass, 'update-status-button')"
                    type="primary" size="mini"
                    @click="handleSubmit('assign_status')"
                >
                    {{$t('change')}}
                </el-button>
            </el-col>

            <el-col :xs="12" :sm="4" :md="4">
                <el-date-picker
                    :class="getBem(blockClass, 'expiry-date-field')"
                    v-model="expiry"
                    type="datetime"
                    :placeholder="$t('select_date_time')"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    popper-class="day-picker-only"
                    size="mini"
                >
                </el-date-picker>
            </el-col>

            <el-col :xs="12" :sm="4" :md="4">
                <el-button
                    :class="getBem(blockClass, 'update-expiry-button')"
                    type="primary"
                    size="mini"
                    @click="handleSubmit('assign_expiry')"
                >
                    {{$t('change_expiry')}}
                </el-button>
            </el-col>

        </el-row>
        <div class="list-base-table-top-controls" v-if="hasTopControlsSlot">
            <slot name="topControls" />
        </div> <!-- .list-base-table-top-controls -->

        <div class="list-base-table-wrapper">
            <!-- Base List Table -->
            <el-table :data="tableData" style="width: 100%" v-loading="loading"
                      :row-class-name="checkRowClass" @selection-change="handleSelectionChange"
                      @row-click.self="handleProfile">
                <el-table-column type="selection" width="55" v-if="iShowSelection">
                </el-table-column>
                <el-table-column
                    v-for="col in cols"
                    :key="crProps(col, 'prop')"
                    :prop="crProps(col, 'prop')"
                    :label="crProps(col, 'label')"
                    :width="crProps(col, 'width')"
                    :class-name="'blt-col ' + crProps(col, 'className')"
                    :sortable="crProps(col, 'sortable')">
                    <template slot-scope="scope">
                        <!-- <h4 class="cell-title">
                          {{ crProps(col, 'label') }}
                        </h4> -->

                        <span v-if="crProps(col, 'showPopover') && mok(scope.row, crProps(col, 'prop'), col.popover)"
                              class="cell-content">
              <el-popover trigger="hover" placement="top" :popper-class="crProps(col, 'popoverClass')">

              <p
                v-html=" mok(scope.row, crProps(col, 'prop'), col.popover) "
                style="padding: 0 5px;"
                />
              <div slot="reference" class="name-wrapper">
                <span v-html="mok(scope.row, crProps(col, 'prop'), col.mutator)"></span>
              </div>
            </el-popover>
            </span>

                        <span v-else class="cell-content"
                              v-html="mok(scope.row, crProps(col, 'prop'), col.mutator)"></span>

                    </template>
                </el-table-column>

                <!-- Info Column -->
                <el-table-column class-name="info-col" v-if="hasInfoColSlot && hasInfoCol"
                                 :label="$t('info')" :width="infoColWidth">
                    <template slot-scope="scope">
                        <slot
                            name="infoColumn"
                            :$index="scope.$index"
                            :row="scope.row" />
                    </template>
                </el-table-column>

                <!-- Details Column -->
                <el-table-column class-name="details-col" v-if="hasDetailsColSlot && hasDetailsCol"
                                 :label="$t('details')" :width="detailsColWidth">
                    <template slot-scope="scope">
                        <slot
                            name="detailsColumn"
                            :$index="scope.$index"
                            :row="scope.row" />
                    </template>
                </el-table-column>

                <!-- Actions Column -->
                <el-table-column
                    class-name="actions-col" v-if="hasActionsColSlot && hasActionsCol"
                    :label="$t('actions')"
                    :width="actionsColWidth"
                    :fixed="actionsColFixed"
                >
                    <template slot-scope="scope">
                        <slot
                            name="actionsColumn"
                            :$index="scope.$index"
                            :row="scope.row" />
                    </template>
                </el-table-column>

            </el-table>
            <!-- End of Base List Table -->
        </div> <!-- .list-base-table-wrapper -->


    </div> <!-- .list-base-table -->
</template>

<script>
  import {mok, crProps, isEmpty} from "../../../js/helpers/Common";
  import {mapGetters, mapActions} from "vuex";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      tableData: {
        type: Array,
        default () {
          return [];
        }
      },
      extraCols: {
        type: Array,
        default () {
          return [];
        }
      },
      detailsColWidth: {
        type: String,
        default: ''
      },
      actionsColWidth: {
        type: String,
        default: ''
      },
      actionsColFixed: {
        type: Boolean | String,
        default: false
      },
      infoColWidth: {
        type: String,
        default: ''
      },
      hasDetailsCol: {
        type: Boolean,
        default: true
      },
      showSelection: {
        type: Boolean,
        default: true
      },
      hasActionsCol: {
        type: Boolean,
        default: true
      },
      hasInfoCol: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      rowClass: {
        type: Array,
        default () {
          return [];
        }
      },
      isbooking: {
        type: Boolean,
        default: false
      }
    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data: function () {
      return {
        blockClass: 'list-base-table',
        preloading: true,
        translatorName: null,
        multipleSelection: null,
        iStatus: null,
        expiry: null,
        Isbooking: this.isbooking
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters({
        translators: 'booking/getTranslatorsList',
        statusList: 'booking/getStatusList'
      }),
      /**
       * Define default columns (this should be array or objects).
       * Please refer to /src/assets/js/helpers/Common.js@crProps() for the
       * documentation of the expected values.
       *
       * @return {array}
       */
      cols: function () {

        let extra = this.extraCols;

        if (_.isEmpty(extra)) {
          extra = [
            {
              label: this.$t('id'),
              prop: 'id',
              width: '50px',
              className: 'id-col'
            }
          ];
        }

        return extra;

      }, // End of cols() method

      /**
       * Returns wether the topControls slot is defined.
       */
      hasTopControlsSlot () {
        return !!this.$slots['topControls'];
      },

      /**
       * Returns wether the detailsColumn scoped slot is defined.
       */
      hasDetailsColSlot () {
        return !!this.$scopedSlots['detailsColumn'];
      },

      /**
       * Returns wether the actionsColumn scoped slot is defined.
       */
      hasActionsColSlot () {
        return !!this.$scopedSlots['actionsColumn'];
      },

      /**
       * Returns wether the infoColumn scoped slot is defined.
       */
      hasInfoColSlot () {
        return !!this.$scopedSlots['infoColumn'];
      },

      iRowClass () {
        return this.rowClass;
      },
      empty (v) {
        return isEmpty(v);
      },

      iShowSelection () {
        return this.showSelection;
      }

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions({
        updateBooking: 'booking/updateBooking',
        assignTranslatorBooking: 'booking/assignTranslator'
      }),
      // Mapping of helpers
      crProps, mok, isEmpty,

      checkRowClass ({row}) {
        if (!isEmpty(this.iRowClass)) {
          let className = '';
          _.each(this.iRowClass, (v) => {
            if (row[v.field]) {
              className = v.class;
            }
          });
          return className;
        }

        return '';
      },
      handleProfile (val) {
        if (this.$router.currentRoute.path === '/user/list') {
          // Redirect to the profile page.
          // window.location.href = '/#/user/profile/' + scope.row.id;
          this.$router.push({name: 'user.profile', params: {id: val.id}, query: {type: val.type}});
        }
      },
      handleSelectionChange (val) {
        this.multipleSelection = val;
      },
      handleSubmit (val) {
        switch (val) {
          case 'assign_translator' : {
            if (this.multipleSelection && this.multipleSelection) {
              this.multipleSelection.forEach(element => {
                this.checkAssignTranslator(element.id);
              });
            }
            break;
          }
          case 'assign_status': {
            if (this.multipleSelection && this.multipleSelection) {
              this.multipleSelection.forEach(element => {
                const payload = {
                  id: element.id,
                  status_id: this.iStatus

                };
                this.updateBooking(payload).then(() => {
                });
              });
            }
            break;

          }
          case 'assign_expiry': {
            if (this.multipleSelection && this.multipleSelection) {
              this.multipleSelection.forEach(element => {
                const payload = {
                  id: element.id,
                  expiry: this.expiry

                };
                this.updateBooking(payload).then(() => {
                });
              });
            }
            break;
          }
        }
      },
      checkAssignTranslator (id) {
        const payload = {
          id: id,
          translator_id: this.translatorName
        };
        this.assignTranslatorBooking(payload).then((r) => {
          //let data=JSON.parse(r);
          if (!isEmpty(r.data.reasons) && r.status === 'error') {
            let reasons = r.data.reasons.join('\r\n');
            this.$confirm(`${reasons} You sure you want to assign Translator to the job?`, 'Warning', {
              confirmButtonText: 'YES',
              cancelButtonText: 'NO',
              type: 'warning'
            }).then(() => {
              const forcePayload = {
                id: id,
                translator_id: this.translatorName,
                force_assign: true
              };
              this.assignTranslatorBooking(forcePayload);
              this.$emit('translator_assigned');
            }).catch(() => {
              console.log('canceled');
            });
          }
        });
      }

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted: function () {

    } // End of Component > mounted

  }; // End of export default
</script>

<style>


</style>
