/*
|--------------------------------------------------------------------------
| Store > Financial > Preview > Salary > State
|--------------------------------------------------------------------------
|
| This file contains the state property of the current scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import API from '~/js/constants/api';
import {preview} from '../shared/props';

const endpoint = API.FINANCIAL.PREVIEW.SALARIES;
const endpointWithId = API.FINANCIAL.PREVIEW.SALARIES + '/{id}';

export default {

  model_info: {
    name: 'Salary Financial Preview Store',
    module: 'salaryPreview'
  },

  api: {
    browse: {method: 'get', path: endpoint, params: {per_page: 15}},
    browseAsOptions: {params: {}},
    read: {method: 'get', path: endpointWithId, params: {}, data: {}},
    edit: {method: 'put', path: endpointWithId, params: {}, data: {}},
    add: {method: 'post', path: endpoint, params: {}, data: {}},
    delete: {method: 'delete', path: endpointWithId, params: {}, data: {}}
  },

  composeFresh: _.cloneDeep(preview),
  compose: _.cloneDeep(preview),

  targetBooking: 0,

  payloadData: {}, // Container for payload data.

  autoInjectPayload: true,
  autoReflectItem: true,
  autoUpdateList: true

} // End of export default