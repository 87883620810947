<template>
    <div class="app-form admin-permissions-roles-list-settings-form">
        <el-form :model="form" :id="formElId" :ref="formElId">
            <el-form-item :id="genContainerId('label', formElId)"
                        :label="$t('label')">
                <el-input :id="genFieldId('label', formElId)"
                          v-model="form.label" 
                          :placeholder="$t('please_input_role_label')"/>
            </el-form-item>
            <el-form-item :id="genContainerId('name', formElId)"
                        :label="$t('name')">
                <el-input :id="genFieldId('name', formElId)"
                          v-model="form.name" 
                          :placeholder="$t('please_input_admin_group_name')"/>
            </el-form-item>

            <el-form-item :id="genContainerId('save_btn',formElId)">
                <el-button :id="genFieldId('save_btn',formElId)" type="primary" @click="handleSave">
                    {{mode}}
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {genFieldId, genContainerId} from "../../../js/helpers/Html";
    import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
    import { checkBreadMode } from "~/js/helpers/Route";
import { isEmpty } from '../../../js/helpers/Common';

    export default {

        computed: {

            ...mapState('adminPermissionsRoles', {
                form: 'compose',
                payloadData: 'payloadData',
            }),

            ...mapState('adminPermissions', {
                navData: 'navData'
            }),

            ...mapGetters('adminPermissionsRoles', {
                entryData: 'itemData',
            }),

            breadMode() {
                return checkBreadMode(this);
            },

            mode() {
                return (this.breadMode == 'edit') ? this.$t('edit') : this.$t('save');
            }
        },

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                formElId: 'admin-permissions-roles-list-settings-form'
            };
        },// End of Component > data

        methods: {

            ...mapActions({
                addEntry: 'adminPermissionsRoles/addRole',
                editEntry: 'adminPermissionsRoles/editRole',
                readEntry: 'adminPermissionsRoles/read',
                batchAddPermissions: 'adminPermissions/batchAddPermissions'
            }),

            ...mapMutations('adminPermissionsRoles', {
                resetForm: 'resetCompose',
            }),

            handleSave() {
                let payload = {};
                if (this.breadMode == 'edit') {
                    payload.id = this.$route.params.id;
                    this.editEntry(payload);
                } else {
                    this.addEntry(payload).then((r) => {
                        let data = r.data.data.admin_permissions_role;

                        let navs = this.getNavItems();
                        for (let n in navs) {
                            let routes = this.getRelatedPaths(navs[n]);
                            if (!isEmpty(routes)) {
                                navs[n]['related_paths'] = routes;
                            }
                        }
                        let batchPayload = {
                            role_id: data.id,
                            pages: navs
                        };

                        this.batchAddPermissions(batchPayload);
                        this.resetForm();
                    });
                }
            },

            getNavItems() {
                let items = this.navData.navItems;
                return items.filter(nav => {
                    return !nav.path.includes('dashboard');
                });
            },

            getRelatedPaths(nav) {
              let paths = [];

              let path = nav.path.split('?')[0];
              paths.push(path);

              if (nav.hasOwnProperty('subItems')) {
                for (let n of nav.subItems) {
                  let path = n.path.split('?')[0];
                  paths.push(path);
                }
              }

              return this.$router.options.routes.filter(route => {
                return paths.includes(route.path);
              });
            }
        },

        mounted() {
            this.resetForm();
            if (this.breadMode == 'edit') {
                this.readEntry({
                    id: this.$route.params.id
                });
            }
        }

    }// End of export default

</script>
