<template>
  <el-form>
    <el-form-item v-if="showLabel===true" :id="genContainerId('department_id',formElId)"
                  :label="$t('txt_invoice_following_department')" class="label-short">
      <el-select :id="genFieldId('search_from',formElId)"
                 v-model="search_by"
                 placeholder="">
        <el-option :label="$t('id')" value="id"/>
        <el-option :label="$t('name')" value="name"/>
      </el-select>
      <el-select v-model="iValue" filterable
                 :placeholder="$t('search')" remote ref="customer"
                 :remote-method="searchDepartment" :loading="searchDepartmentLoading"
                 clearable>
        <el-option v-for="i in listData" :key="i.id"
                   :label="i.name +' - '+ i.email+' - '+ i.id" :value="i.id">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item v-else :id="genContainerId('department_id',formElId)"
                  class="label-short">
      <el-select :id="genFieldId('search_from',formElId)"
                 v-model="search_by"
                 placeholder="">
        <el-option :label="$t('id')" value="id"/>
        <el-option :label="$t('name')" value="name"/>
      </el-select>
      <el-select v-model="iValue" filterable
                 :placeholder="$t('txt_invoice_following_department')" remote ref="customer"
                 :remote-method="searchDepartment" :loading="searchDepartmentLoading"
                 clearable>
        <el-option v-for="i in listData" :key="i.id"
                   :label="i.name +' - '+ i.email+' - '+ i.id" :value="i.id">
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import {populate} from "~/js/helpers/Vuex";

  export default {

    /**
     *
     */
    name: "DepartmentSearch",

    /**
     * Data for component
     */
    data() {
      return {
        formElId: 'department-search',
        searchDepartmentLoading: false,
        isFirstSearch: true,
        search_by: 'id'
      }
    },
    /**
     * Props for component
     */
    props: {
      value: {
        default: '',
      },

      showLabel: {
        type: Boolean,
        default: true
      }
    },

    /**
     * Computed properties for the component
     */
    computed: {

      ...mapGetters('department', ['listData', 'listPagination']),

      /**
       * Getter Setter for iValue
       */
      iValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },
    },

    /**
     * Watch for the component
     */
    watch: {
      /**
       *
       */
      value(newVal, OldVa) {
        if (this.isFirstSearch === true) {
          this.searchDepartment(newVal, true);
          this.isFirstSearch = false
        }
      }
    },
    /**
     *
     */
    methods: {
      /**
       * Methods for the component
       */
      ...mapActions('department', {
        browse: 'browse',
      }),
      /**
       *
       * @param query
       * @param byId
       */
      searchDepartment(query, byId) {
        let params = {};
        params[`filter[${this.search_by}]`] = query;
        if (byId === true) {
          params = {
            'filter[id]': query,
          };
        }

        this.searchDepartmentLoading = true;
        populate(this, 'browse', 'listData', {
          force: true,
          params,
          ref: 'Department Listing (forced)'
        });

        setTimeout(() => {
          this.searchDepartmentLoading = false
        }, 2000)
      }
    },

  }
</script>

<style scoped>

</style>
