<template>
    <div class="app-list">
     <base-list-table
                :table-data="list"
                :extra-cols="tableCols"
                :hasDetailsCol="false"
                actions-col-width='130'
                :show-selection="false"
                >

            <template slot="actionsColumn" slot-scope="scope">
                <!-- <el-button type="primary" icon="el-icon-refresh" size="mini" class="circle" title="Resend" @click="handleResendButton(scope.row)"></el-button> -->
                <!-- <el-button type="success" icon="el-icon-document" size="mini" class="circle" title="View" @click="handleViewButton(scope.row)"></el-button> -->

            </template>
        </base-list-table>
    </div>
</template>

<script>

import ColExpandTable from './ColExpandTable.vue'
import moment from "moment";
export default {
    props:{
        list:{
            type:Array,
            default:[]
        }
    },
    components:{
        ColExpandTable
    },
    data(){
        const _this=this;
        return {

            tableElId: 'push-logs-list',
            tableCols: [

                    {prop:'id',label:_this.$t('id')},
                    {prop:'template_code',label:_this.$t('template_code',),showPopover: true,
                    popover(v, row) {
                      if(v === 'messaging-centre-push') {
                        return row.data.content.replace(/(<([^>]+)>)/ig,"")

                      }
                    }
                    },
                    {prop:'recipients',label:_this.$t('recipients'), width:'300px',
                     mutator(v){
                            return v.join(', ')
                      }
                    },
                    {prop:'status',label:_this.$t('status')},
                    {
                      prop: 'not_sending_reason',
                      label: _this.$t('not_sending_reason')
                    },
                    {
                      prop:'created_at',
                      label:_this.$t('created_at'),
                      mutator(v) {
                        let date = moment(v)
                        return date.tz('Europe/Stockholm"').format('YYYY-MM-DD HH:mm:ss')
                      }
                    },
                ],

                hiddenCols: [],
        }
    },
    methods: {
         isColEnabled(key) {
                let r = true;

                if (_.includes(this.hiddenCols, key)) {
                    r = false;
                }

                return r;

            }, // End of isColEnabled() method

    },
}
</script>

<style>

</style>
