/*
|--------------------------------------------------------------------------
| Store > Sector > Town
|--------------------------------------------------------------------------
|
| A file that manages all the properties and abilities in relation
| to Town Model and other related values.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {extendObj, mapEndpoint} from "../../helpers/Common";
import APICaller from '~/js/helpers/APICaller';
import bread from '../bread';
import state from './state';
import store from '../../store';
import {Notification} from 'element-ui';
import i18n from '~/js/i18n';

let mutations = {};
let getters = {};
let actions = {
    deletePaths (context, payload) {

        let method = context.state.api.deletePaths.method;
        let endpoint = mapEndpoint(context.state.api.deletePaths.path, payload);
        let data = {};
        let isDataRaw = true;

        data = payload.data;

        return new Promise((resolve, reject) => {
          APICaller({method, endpoint, data, isDataRaw})
            .then((r) => {
              resolve(r);
            })
            .catch((e) => {
              reject(e);
            });
        });
    },

    deletePermission(context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch('deleteGeneric', payload)
               .then((r) => {
                 Notification.success({
                   title: 'Success',
                   message: 'Item removed.'
                 });

                 resolve(r);
               })
               .catch((e) => {
                 Notification.error({
                   title: i18n.t('error'),
                   message: i18n.t('n_went_wrong')
                 });

                 reject(e);
               });
      });
    },

    editPermission (context, payload) {

      return new Promise((resolve, reject) => {
        context.dispatch('editGeneric', payload)
               .then((r) => {

                 Notification.success({
                   title: i18n.t('success'),
                   message: i18n.t('n_entry_edited')
                 });

                 resolve(r);
               })
               .catch((e) => {
                 Notification.error({
                   title: i18n.t('error'),
                   message: i18n.t('n_went_wrong')
                 });
                 reject(e);
               });
      });
    },

    getNavItemsFinal(context, payload) {

        let getMatchedNavItemFromPermissions = (permissions, navItems) => {
            let matched = [];
            for (let p of permissions) {
              let hit = false;
              for (let n of navItems) {
                hit = n.label.toLowerCase() === p.page_name.toLowerCase();
                if (hit) {
                  matched.push(p);
                }
              }
            }
            return matched;
        }

        let getFinalNavItems = (permissions, navItems) => {
            let toShowNavItems = [];
            let matched = getMatchedNavItemFromPermissions(permissions, navItems);
            console.log('MATCHED');
            console.log(matched);

            if (matched.length > 0) {
              for (let nav of navItems) {
                let hit = false;
                for (let m of matched) {
                  hit = nav.label.toLowerCase() === m.page_name.toLowerCase();
                  if (hit && m.can_view == 1) {
                    toShowNavItems.push(nav);
                  }
                  if (hit) {
                    break;
                  }
                }
                if (nav.label.toLowerCase() === 'dashboard') {
                  toShowNavItems.push(nav);
                }
              }
            } else {
              toShowNavItems = toShowNavItems.concat(navItems);
            }

            return toShowNavItems;
        }

        store.dispatch('auth/getUserData').then(r => {
            let permissions = context.rootGetters['auth/userCollectivePermissions'];
            let navItems = context.state.navData.navItems;
            let navItemsFinal = getFinalNavItems(permissions, navItems);

            // Apply final items
            context.state.navData.navItemsFinal = navItemsFinal;
        });
    },

    batchAddPermissions(context, payload) {
      let method = context.state.api.batchAdd.method;
      let endpoint = mapEndpoint(context.state.api.batchAdd.path, payload);
      let data = {};
      let isDataRaw = true;

      data = payload;

      return new Promise((resolve, reject) => {
        APICaller({method, endpoint, data, isDataRaw})
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    }
};

export default {
    namespaced: true,
    state: extendObj(bread.state, state),
    getters: extendObj(bread.getters, getters),
    mutations: extendObj(bread.mutations, mutations),
    actions: extendObj(bread.actions, actions),
} // End of export default
