/*
|--------------------------------------------------------------------------
| Notification Helpers
|--------------------------------------------------------------------------
|
| This is a helper file which contains all notification helpers that will
| be called multiple times in different files.
|
| Kind put your methods in here and then just use them on Vuex or Components
| for cleaner implementation.
|
*/

import { Notification } from 'element-ui';
import i18n from '../i18n';

/**
 * Will display a successful notification.
 *
 * @param {Object} options
 * @return {void}
 */
export function notifSuccess (options = {}) {
  options.type = 'success';
  options.title = window._.isNil(options.title) ? i18n.t('success') : options.title;
  notifBase(options);
}

/**
 * Helper for checking if the child of an object is valid via custom conditions.
 *
 * @param {Object} parent
 * @param {string} child
 * @returns {boolean}
 */
function doesContainChild (parent, child) {
  return window._.has(parent, child)
    && !window._.isNil(parent, child)
    && !window._.isEmpty(parent, child);
}

/**
 * Will display a error notification.
 *
 * @param {Object} options
 * @return {void}
 */
export function notifError (options = {}) {
  options.type = 'error';
  options.title = window._.isNil(options.title) ? i18n.t('error') : options.title;

  if (window._.isObject(options.message)) {
    const error = options.message;
    console.error(error);

    if (!window._.isNil(error.response)) {
      const errorData = error.response.data.data;

      if (window._.isObject(errorData)) {

        if (doesContainChild(errorData, 'reasons')) {
          options.message = errorData.reasons.join('\n');

        } else if (doesContainChild(errorData, 'message')) {
          options.message = errorData.message;

        } else if (!window._.isEmpty(errorData)) { // Default condition if errorData has children.
          let messages = [];

          window._.forOwn(errorData, (v, k) => {
            messages.push(`${k} : \n ${v} \n`);
          });

          options.message = messages.join('\n');

        } else {
          options.message = 'The Error is an Object, but is not recognized by the current logic conditions. ' +
            'Please Contact your Administrator';
        }

        // Marker for end of if (window._.isObject(errorData))

      } else if (window._.isString(errorData)) {
        options.message = errorData;

      } else {
        options.message = 'Please Contact your Administrator';
      }

    } else if (!window._.isNil(error.message) && window._.isString(error.message)) {
      options.message = error.message;
    }
  }

  notifBase(options);
} // End of notifError

/**
 * Will display a warning notification.
 *
 * @param {Object} options
 * @return {void}
 */
export function notifWarning (options = {}) {
  options.type = 'warning';
  options.title = window._.isNil(options.title) ? i18n.t('warning') : options.title;
  notifBase(options);
}

/**
 * Helper for displaying notification
 *
 * @param {Object} options
 * @param {string} [options.title]
 * @param {string} options.message
 * @param {string} [options.type]
 * @param {int} [options.duration]
 * @param {int} [options.translate]
 * @return {void}
 */
function notifBase (options = {}) {
  if (window._.isNil(options.message)) {
    throw new Error('Missing required value options.message');
  }

  let translate = window._.isNil(options.translate) ? false : options.translate;
  let duration = window._.isNil(options.duration) ? 4500 : options.duration;

  let notifOpts = {
    duration,
    customClass: 'app-notif-base',
    message: translate ? i18n.t(options.message) : options.message
  };

  if (!window._.isNil(options.title)) {
    notifOpts.title = translate ? i18n.t(options.title) : options.title;
  }

  if (!window._.isNil(options.type)) {
    notifOpts.type = options.type;
  }

  Notification(notifOpts);
}

