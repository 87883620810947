<template>
    <div class="app-page">
        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <page-header :title="$t('user_financial_settings')" />
                    <div class="app-col full-width">

                        <div>
                            <el-form>
                                <!-- Tax -->
                                <el-form-item :id="genContainerId('tax',formElId)"
                                            :label="$t('tax')" class="label-short">
                                    <el-input :id="genFieldId('tax' ,formElId)"
                                            v-model="form.settings.tax"/>
                                </el-form-item>

                                <!-- Tax Type -->
                                <el-form-item :id="genContainerId('tax_type',formElId)"
                                            :label="$t('tax_type')" class="label-short">
                                            <el-select v-model="form.settings.tax_type" :placeholder="$t('select')">
                                                <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                                </el-option>
                                            </el-select>
                                </el-form-item>

                                <!-- Bank Name -->
                                <el-form-item :id="genContainerId('bank_name',formElId)"
                                            :label="$t('bank_name')" class="label-short">
                                    <el-input :id="genFieldId('bank_name' ,formElId)"
                                            v-model="form.settings.bank_name"/>
                                </el-form-item>

                                <!-- Bank Account Number -->
                                <el-form-item :id="genContainerId('bank_account_number',formElId)"
                                            :label="$t('bank_account_number')" class="label-short">
                                    <el-input :id="genFieldId('bank_account_number' ,formElId)"
                                            v-model="form.settings.bank_account_number"/>
                                </el-form-item>
                                
                                <div class="app-splitter two-cols">
                                    <el-form-item :id="genContainerId('will_invoice_manually',formElId)"
                                                :label="$t('will_invoice_manually')"
                                                class="label-medium">
                                        <el-switch :id="genFieldId('will_invoice_manually',formElId)"
                                                v-model="form.questions.will_invoice_manually"/>
                                    </el-form-item>
                                    <!-- Company Info Verified -->
                                    <el-form-item :id="genContainerId('company_info_verified',formElId)"
                                                :label="$t('company_info_verified')"
                                                class="label-medium">
                                        <el-switch :id="genFieldId('company_info_verified',formElId)"
                                                v-model="form.questions.company_info_verified"/>
                                    </el-form-item>
                                </div>

                                <!-- Will Invoice Manually? -->
                                <div v-if="form.questions.will_invoice_manually">
                                    <el-form-item :id="genContainerId('company_name',formElId)"
                                                class="label-short"
                                                :label="$t('company_name')">

                                        <el-input :id="genFieldId('company_name',formElId)"
                                                class="label-short"
                                                v-model="form.questions.company_name"/>
                                    </el-form-item>
                                    <el-form-item :id="genContainerId('company_registration_no',formElId)"
                                                class="label-short"
                                                :label="$t('company_registration_no')">

                                        <el-input :id="genFieldId('company_registration_no',formElId)"
                                                class="label-short"
                                                v-model="form.questions.company_registration_no"/>
                                    </el-form-item>
                                    <el-form-item :id="genContainerId('invoice_tax',formElId)"
                                                class="label-short"
                                                :label="$t('invoice_tax')">

                                        <el-input :id="genFieldId('invoice_tax',formElId)"
                                                class="label-short"
                                                @keyup.native="isNumber($event)"
                                                v-model="form.questions.invoice_tax"/>
                                    </el-form-item>
                                </div>
                                <el-button  type="primary"
                                            :class="genBem(blockCls, 'submit-edit-btn')"
                                            @click="handleClickSubmitEdit">
                                    {{$t('update')}}
                                </el-button>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> <!-- /.app-form app-fields user-address-fields -->
</template>

<script>
    import {mapActions, mapGetters, mapState, mapMutations } from "vuex";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data () {
            return {
                blockCls: 'user-translator-compose',
                formElId: 'user_financial_settings_form',
                options: [
                    {
                        value: 'standard',
                        label: 'Standard %'
                    }, 
                    {
                        value: 'table',
                        label: 'Table'
                    }
                ],
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > watch
        |--------------------------------------------------------------------------
        */
        watch: {
            userId (v) {
                this.prepareForm(v);
            }
        },// End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {
            ...mapState('translator', {
                form: 'compose'
            }),
            /**
            * Returns the user's ID from route param "id"
            * @return {int}
            */
            userId () {
                return !window._.isNil(this.$route.params) && !window._.isNil(this.$route.params.id)
                ? parseInt(this.$route.params.id)
                : 0;
            },

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods:{
            ...mapMutations('translator', ['resetCompose', 'resetPayload']),

            ...mapActions('translator', {
                readEntry: 'read',
                editEntry: 'edit',
                addEntry: 'add'
            }),
            
            /**
             * Method to wrap the entry loading action.
             *
             * @param {int|string} id - ID of the entry.
             * @return {void}
             */
            prepareForm (id) {
                console.log(`Preparing Form: ${id}`);
                this.resetCompose();
                this.resetPayload();

                this.readEntry({id: this.userId})
                .catch(() => {
                    this.$notify.error({
                    title: 'Error',
                    message: 'There is no translator by that ID.'
                    });
                });
                
            },
            /**
            * Handler when the submit button while in EDIT mode was clicked.
            *
            * @return {void}
            */
            handleClickSubmitEdit () {
                this.editEntry({id: this.userId});
            },

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted () {
            this.prepareForm(this.userId);

        }// End of Component > mounted

    } // End of export default
</script>
