<template>
    <el-dialog 
      :title="$t('add_new_subtitle')" 
      :visible.sync="iVisible"
      :modal-append-to-body="false"
      :append-to-body="true"
      custom-class="faq-question-modal"
      width='70%'>
        <h4>{{$t('add_question')}}</h4>
        <el-form>
            <el-form-item label="Question" class="label-short">
                    <el-input v-model="faq.question"></el-input>
            </el-form-item>
            <el-form-item label="Response" class="label-short">
                <vue-editor v-model="faq.response"></vue-editor>
            </el-form-item>
            <el-form-item class="el-form-actions">
                <el-button class="modal-size" type="default" @click="cancelModal()">{{$t('cancel')}}</el-button>
                <el-button class="modal-size" type="primary" @click="addFAQ">{{$t('save')}}</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {isEmpty} from '../../../js/helpers/Common';
import Vue from 'vue';
import { VueEditor } from "vue2-editor";

export default {
    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
        visible: {
          type: Boolean,
          default: false
        }
    },
    /*
    |--------------------------------------------------------------------------
    | Component > components
    |--------------------------------------------------------------------------
    */
    components: {
        VueEditor
    },
    /*
    |--------------------------------------------------------------------------
    | Component > Data
    |--------------------------------------------------------------------------
    */
    data () {
        return {
            faq:{
                question:'',
                response:'',
                faq_id:''
            },
        }
    },
    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
        iVisible: {
            get() {
                return this.visible;
            },
            set(v) {
                this.$emit("update:visible", v);
            }
        },
    },
    /*
    |--------------------------------------------------------------------------
    | Component > Methods
    |--------------------------------------------------------------------------
    */
    methods: {
        ...mapActions('faq',{
            getList: 'getSubtitlesList',
            createSubtitle:'createSubtitle',
        }),

        fetchFaq(){
         this.getList(this.$route.params.id);
        },

        addFAQ() {
            this.faq.faq_id=this.$route.params.id;
            this.createSubtitle(this.faq).then(response => {
                this.cancelModal();
                this.fetchFaq();
            });

        },

        cancelModal() {
            this.iVisible = false;
            this.faq={
                question:'',
                response:'',
                faq_id:''
            };
        },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted() {

    }, // End of Component > mounted
}
</script>
