import APICaller from "../../../helpers/APICaller";
import {Notification} from "element-ui";

import i18n from '~/js/i18n';
import {gep} from "~/js/helpers/Vuex";
import {extendObjParams, isEmpty, sd} from "~/js/helpers/Common";

let apiPrefix = 'v3';
// let extended = {include: 'translator,booking.customer.customer_data,comments.user,booking.assigned_translator,type,user,booking.comments,booking.feedback,booking.batches.entries.translator'}
let includes = [
  'translator',
  'booking.customer.customer_data',
  'comments.user',
  'booking.assigned_translator',
  'type',
  'user',
  'booking.comments',
  'booking.feedback',
  'booking.batches.entries.translator',
  'comments.assigned_users',
  'comments.files',
  'entities'
]
let extended = {
  include: includes.join(',')
}
export default {
  postComment(context,payload){
    return APICaller({
      method:'post',
      endpoint: gep(`booking-issues/${payload.booking_issue_id}/comment?include=comments`,apiPrefix),
      data:payload,
      // isDataRaw: true,
    }).then(r =>{
      let params = {
        id:payload.booking_issue_id,
        extendParams: extended
      }
      context.dispatch('read',params)
      Notification.success({
        title: i18n.t('success'),
        message: 'Booking Issue Comment added ' + (!isEmpty(payload.message) ? `"${payload.message}"` :''),
      });
      return r
    }).catch(err=>{
      Notification.error({
        title: i18n.t('error'),
        message: 'add comment failed!!, please try again',
      });
    });
  },
  updateIssue(context,payload){
    return APICaller({
      method:'put',
      endpoint: gep(`booking-issues/${payload.booking_issue_id}`,apiPrefix),
      data:payload
    }).then(r =>{
      let params = {
        id:payload.booking_issue_id,
        extendParams: extended
      }
      context.dispatch('read',params)
      Notification.success({
        title: i18n.t('success'),
        message: 'Issue Updated',
      });
      return r;
    }).catch(err=>{
      Notification.error({
        title: i18n.t('error'),
        message: 'Update Issue Failed',
      });

      return err;
    });
  },
  postAssignAdmin(context,payload){
    return APICaller({
      method:'post',
      endpoint: gep(`booking-issues/${payload.id}/assign`,apiPrefix),
      data:payload
    }).then(r =>{
      let params = {
        id:payload.id,
        extendParams: extended
      }
      context.dispatch('read',params)
      Notification.success({
        title: i18n.t('success'),
        message: 'Issue Assigned',
      });
    }).catch(err=>{
      Notification.error({
        title: i18n.t('error'),
        message: 'Assign Issue Failed',
      });
    });
  },
  showExpense(context,payload){
    return APICaller({
      method:'get',
      endpoint:gep(`bookings/${payload.booking_id}/expenses/${payload.id}?include=type,attachments`,apiPrefix)
    }).then(r => {
      return r
    }).catch(err =>{
      Notification.error({
        title: i18n.t('error'),
        message: 'Failed Get expenses details',
      });
    })
  },
  approveExpense(context,payload){
    return APICaller({
      method:'post',
      endpoint:gep(`bookings/${payload.booking_id}/expenses/${payload.id}/approve`,apiPrefix)
    }).then(r => {
      console.log(r)
      return r
    }).catch(err =>{
      Notification.error({
        title: i18n.t('error'),
        message: 'Failed approve expenses',
      });
    })
  },
  rejectExpense(context,payload){
    return APICaller({
      method:'post',
      endpoint:gep(`bookings/${payload.booking_id}/expenses/${payload.id}/reject`,apiPrefix)
    }).then(r => {
      console.log(r)
      return r
    }).catch(err =>{
      Notification.error({
        title: i18n.t('error'),
        message: 'Failed reject expenses',
      });
    })
  },
  pendingIssue(context,payload){
    return APICaller({
      method:'post',
      endpoint:gep(`booking-issues/${payload.id}/pending`,apiPrefix)
    }).then(r => {
      return r
    }).catch(err =>{
      Notification.error({
        title: i18n.t('error'),
        message: 'Failed set issue as pending',
      });
    })
  },
  reopenIssue(context,payload){
    return APICaller({
      method:'post',
      endpoint:gep(`booking-issues/${payload.id}/reopen`,apiPrefix)
    }).then(r => {
      return r
    }).catch(err =>{
      Notification.error({
        title: i18n.t('error'),
        message: 'Failed set issue as pending',
      });
    })
  },
  addIssue(context,payload){
    return APICaller({
      method:'post',
      endpoint:gep(`booking-issues`,apiPrefix),
      data:payload
    }).then(r => {
      Notification.success({
        title: i18n.t('success'),
        message: 'Action manager ticket created for this booking',
      });
      return r
    }).catch(err =>{
      Notification.error({
        title: i18n.t('error'),
        message: 'Failed adding issue for this booking',
      });
    })
  },
  deleteComment(context,payload){
    return APICaller({
      method:'delete',
      endpoint: gep(`core/booking-issues-comment/${payload.id}`,apiPrefix),
      isDataRaw: true,
    }).then(r =>{
      let params = {
        id:payload.booking_issue_id,
        extendParams: extended
      }
      context.dispatch('read',params)
      Notification.success({
        title: i18n.t('success'),
        message: 'Booking Issue Comment deleted ',
      });
      return r
    }).catch(err=>{
      Notification.error({
        title: i18n.t('error'),
        message: 'Delete comment failed!!, please try again',
      });
    });
  },
  editComment(context,payload){
    return APICaller({
      method:'put',
      endpoint: gep(`core/booking-issues-comment/${payload.id}`,apiPrefix),
      data:payload
    }).then(r =>{
      let params = {
        id:payload.booking_issue_id,
        extendParams: extended
      }
      context.dispatch('read',params)
      Notification.success({
        title: i18n.t('success'),
        message: 'Booking Issue Comment updated ',
      });
      return r
    }).catch(err=>{
      Notification.error({
        title: i18n.t('error'),
        message: 'Update comment failed!!, please try again',
      });
    });
  },
}
