<template>
    <div class="app-form basic-settings-form">
        <el-form :model="form" :id="formElId" :ref="formElId">
            <h4>{{$t('txt_emails_account_number_notification_separate_with_comma')}}</h4>
            <el-form-item :id="genContainerId('emails',formElId)"
                        label="">
                <el-input :id="genFieldId('emails',formElId)"
                 v-model="form.emails"/>
                </el-input>
            </el-form-item>

            <el-form-item :id="genContainerId('save_btn',formElId)">
                <el-button :id="genFieldId('save_btn',formElId)" type="primary">
                    {{$t('save')}}
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {genFieldId, genContainerId} from "../../../js/helpers/Html";
    export default {
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                formElId: 'basic-settings-form',
                form: {
                    emails: 'kirjwuk@gmail.com, Chyneclicks@gmail.com, virpals.08@gmail.com'
                }
            };
        },// End of Component > data
    }// End of export default

</script>
