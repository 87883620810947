<template>
  <div class="app-row booking-warnings">
    <div class="app-col full-width">
      <h3>Warnings for not carried out by translator</h3>
      <!-- {{user.warnings}} -->
      <base-list-table 
				:table-data="user.warnings" 
				:extra-cols="listCols" 
				:hasDetailsCol="false">
      </base-list-table>
    </div>
  </div>

</template>
<script>
export default {
  props:{
    user:{
      type:Object,
      required: true,
    }
  },
  data(){
    return {
      listCols:[
        {
          label: 'Booking Id',
          prop: 'booking_id',
          width:'',
          className:'id-col',
          mutator(v){
							return `<a href="/#/booking/${v}/details" target="_blank">${v}</a>`
						}
        },
        {
          label: 'New Booking Id',
          prop: 'new_booking_id',
          width:'',
          className:'id-col',
          mutator(v){
							return (v ==-0)? '' :`<a href="/#/booking/${v}/details" target="_blank">${v}</a>`
						}
        },
        {
          label: 'Warning',
          prop: 'warning',
          width:'',
          className:'id-col'
        },
        {
          label: 'Penalty',
          prop: 'penalty',
          width:'',
          className:'id-col'
        }
      ]
    }
  },
  computed:{
    
    
  }
}
</script>