<template>
  <div>
    <el-row>
      <el-col :md="18">
        <el-form-item props="text_translation_files" ref="text_translation_files" prop='text_translation_files'>
          <aws-file-upload
            v-model="iValue.text_translation_files"
            @progress="handleProgress"
            :button-text="$t('select_files')"
          />
        </el-form-item>

        <h4> {{$t('descriptions')}}</h4>
        <el-form-item label="" :id="genContainerId('descriptions',formElId)" prop="descriptions">
          <el-input v-model="iValue.descriptions" placeholder="" type="textarea" rows="5"></el-input>
        </el-form-item>

        <el-form-item :id="genContainerId('options',formElId)" label="" class="label-short">

          <!-- <el-checkbox v-model="iValue.is_express" label="Express delivery" :true-label="1" :false-label='0'/><br>-->
          <h4 :id="genFieldId('options_how_should_the_translation_be_delivered',formElId)">
            {{ $t('label_how_should_translation_be_delivered') }}?
          </h4> <br>
          <el-radio-group
            :aria-labelledby="genFieldId('options_how_should_the_translation_be_delivered',formElId)"
            v-model="iValue.deliver_with" :id="genFieldId('options',formElId)"
            tabindex="0"
          >
            <el-radio label="email">{{ $t('email') }}</el-radio>
            <br><br>
            <el-radio label="dt_web">DT web</el-radio>
            <br><br>
            <el-radio label="mail">{{ $t('mail') }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <h4
          :id="genFieldId('options_does_the_document_need_to_be_stamped',formElId)"
        >
          {{ $t('label_document_need_stamp') }}?
        </h4>
        <el-form-item :id="genContainerId('options',formElId)" label="" class="label-short">
          <el-radio-group
            v-model="iValue.is_stamped"
            :id="genFieldId('is_stamped',formElId)"
            :aria-labelledby="genFieldId('options_does_the_document_need_to_be_stamped',formElId)"
            tabindex="0"
          >
            <el-radio :label="1">{{ $t('yes') }}</el-radio>
            <el-radio :label="0">{{ $t('no') }}</el-radio>

          </el-radio-group>
        </el-form-item>

        <h4
          :id="genFieldId('options_confidential_or_not',formElId)"
        >
          {{ $t('label_is_document_confidential_or_not') }}?
        </h4>
        <el-form-item :id="genContainerId('options',formElId)" label="" class="label-short">

          <el-radio-group
            v-model="iValue.is_confidential" :id="genFieldId('is_confidential',formElId)"
            :aria-labelledby="genFieldId('options_confidential_or_not',formElId)"
            tabindex="0"
          >
            <el-radio :label="1">{{ $t('yes') }}</el-radio>
            <el-radio :label="0">{{ $t('no') }}</el-radio>
          </el-radio-group>

        </el-form-item>


      </el-col>
    </el-row>

  </div>
</template>
<script>
import AwsFileUpload from '~/components/displays/AwsFileUpload.vue'

export default {
  props:{
    value:{
      type:Object
    },
    formElId:{
      type: String
    }
  },
  components:{
    AwsFileUpload
  },
  data(){
    return {}
  },
  computed:{
    iValue:{
      get(){
        return this.value
      },
      set(v) { this.$emit('input',v) }
    }
  },
  watch:{
    errors: (value) =>{
      console.log(value)
    }
  },
  methods:{
    handleProgress(e){
      this.$emit('progress',e)
    }
  }
}
</script>
