<template>
    <div :class="genBem(blockCls)" v-if="isValidSpecifics">

        <h3>Customer Preferences</h3>

        <!-- Translator -->
        <div :class="[
                genBem(blockCls, 'question-wrapper'),
                genBem(blockCls, 'translator')
             ]">
            <p :class="getQuestionCls('translator')">
                {{ $t('BOOKING_SPECIFICS.TRANSLATOR') }}
            </p>
            <p :class="getAnswerCls('translator')">
                <span :class="getIconCls('translator')"></span>
                {{ getAnswer('translator') }}

                <el-button v-if="getValue('translator')"
                           :class="getButtonCls('translator')"
                           type="primary" size="mini"
                           :disabled="isAdjusted.translator"
                           @click="handleClickAdjust('translator')">
                    {{ isAdjusted.translator ? 'Adjusted' : 'Adjust' }}
                </el-button>
            </p>
        </div>

        <!-- Gender -->
        <div :class="[
                genBem(blockCls, 'question-wrapper'),
                genBem(blockCls, 'gender')
             ]">
            <p :class="getQuestionCls('gender')">
                {{ $t('BOOKING_SPECIFICS.GENDER') }}
            </p>
            <p :class="getAnswerCls('gender')">
                <span :class="getIconCls('gender')"></span>
                {{ getAnswer('gender') }}

                <el-button v-if="getValue('gender')"
                           :class="getButtonCls('gender')"
                           type="primary" size="mini"
                           :disabled="isAdjusted.gender"
                           @click="handleClickAdjust('gender')">
                    {{ isAdjusted.gender ? 'Adjusted' : 'Adjust' }}
                </el-button>
            </p>
        </div>

        <!-- Translator Level -->
        <div :class="[
                genBem(blockCls, 'question-wrapper'),
                genBem(blockCls, 'translator_level')
             ]">
            <p :class="getQuestionCls('translator_level')">
                {{ $t('BOOKING_SPECIFICS.TRANSLATOR_LEVEL') }}
            </p>
            <p :class="getAnswerCls('translator_level')">
                <span :class="getIconCls('translator_level')"></span>
                {{ getAnswer('translator_level') }}

                <el-button v-if="getValue('translator_level')"
                           :class="getButtonCls('translator_level')"
                           type="primary" size="mini"
                           :disabled="isAdjusted.translator_level"
                           @click="handleClickAdjust('translator_level')">
                    {{ isAdjusted.translator_level ? 'Adjusted' : 'Adjust' }}
                </el-button>
            </p>
        </div>

        <!-- Physical Booking -->
        <div :class="genBem(blockCls, 'physical')">
            <p :class="genBem(blockCls, 'physical__question')">
                {{ $t('BOOKING_SPECIFICS.PHYSICAL.INDEX') }}
            </p>
            <p :class="genBem(blockCls, 'physical__answer')">
                {{ $t(`BOOKING_SPECIFICS.PHYSICAL.${specifics.physical_consideration.toUpperCase()}`) }}
            </p>
        </div>

    </div> <!-- template div wrapper -->
</template>

<script>
  import {mapActions} from 'vuex';
import { isEmpty } from '../../../js/helpers/Common';
  
  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      booking: {
        type: Object,
        required: true,
      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'booking-details-customer-specifics-preference',

        isAdjusted: {
          translator: false,
          gender: false,
          translator_level: false,
        },


      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > watch
    |--------------------------------------------------------------------------
    */
    watch: {
      booking () {
        this.adjustmentVerification();
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      specifics () {
        return this.booking.specific_preference;
      },

      isValidSpecifics () {
        return !isEmpty(this.booking.specific_preference);
      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions({
        getBookingAction: 'booking/getBooking',
        updateBookingAction: 'booking/updateBooking',
      }),

      /**
       * Helper method to get the reference key for the target's value.
       *
       * @param {string} target
       * @return {string}
       */
      getValueKey (target) {
        if (target === 'translator') return 'is_take_other_translator';
        else if (target === 'gender') return 'is_take_other_gender';
        else if (target === 'translator_level') return 'is_take_other_translator_level';
        else if (target === 'physical') return 'physical_consideration';
      },


      /**
       * Helper method for getting the value of the target.
       *
       * @param {string} target
       * @return {*}
       */
      getValue (target) {
        const key = this.getValueKey(target);
        return this.specifics[key];
      },

      /**
       * Helper method for getitng the Font Awesome icon class.
       *
       * @param {string} target
       * @return {string}
       */
      getIconCls (target) {
        if (this.getValue(target) === 0) return 'fa fa-times';
        else if (this.getValue(target) === 1) return 'fa fa-check';
        else if (this.getValue(target) === null) return 'fa fa-circle';
      },

      /**
       * Helper method for getitng the Status css class.
       *
       * @param {string} target
       * @return {string}
       */
      getStatusCls (target) {
        if (this.getValue(target) === 0) return 'status-false';
        else if (this.getValue(target) === 1) return 'status-true';
        else if (this.getValue(target) === null) return 'status-neutral';
      },

      /**
       * Helper method for getitng the Answer verbiage.
       *
       * @param {string} target
       * @return {string}
       */
      getAnswer (target) {
        if (this.getValue(target) === 0) return 'No';
        else if (this.getValue(target) === 1) return 'Yes';
        else if (this.getValue(target) === null) return 'Nothing Specified';
      },

      /**
       * Helper method for generating css class for question section.
       *
       * @param {string} target
       * @return {array}
       */
      getQuestionCls (target) {
        const cls = window._.snakeCase(target);
        return [
          this.genBem(this.blockCls, 'question'),
          this.genBem(this.blockCls, `${cls}__question`)
        ]
      },

      /**
       * Helper method for generating css class for answer section.
       *
       * @param {string} target
       * @return {array}
       */
      getAnswerCls (target) {
        const cls = window._.snakeCase(target);
        return [
          this.genBem(this.blockCls, 'answer'),
          this.genBem(this.blockCls, 'answer', this.getStatusCls(cls)),
          this.genBem(this.blockCls, `${cls}__answer`),
          this.genBem(this.blockCls, `${cls}__answer`, this.getStatusCls(target)),
        ]
      },

      /**
       * Helper method for generating css class for question button.
       *
       * @param {string} target
       * @return {array}
       */
      getButtonCls (target) {
        const cls = window._.snakeCase(target);
        const modifier = this.isAdjusted[target] ? 'is-adjusted' : 'is-not-adjusted';
        return [
          this.genBem(this.blockCls, `answer__adjust-btn`),
          this.genBem(this.blockCls, `answer__adjust-btn`, modifier),
          this.genBem(this.blockCls, `${cls}__answer__adjust-btn`),
          this.genBem(this.blockCls, `${cls}__answer__adjust-btn`, modifier),
        ];
      },

      /**
       * Method to handle when the Adjust button was clicked.
       *
       * @param {string} target
       * @return {void}
       */
      handleClickAdjust (target) {
        if (target === 'translator') this.handleAdjustTranslator();
        else if (target === 'gender') this.handleAdjustGender();
        else if (target === 'translator_level') this.handleAdjustTranslatorLevel();
      },

      /**
       * Method to adjust the Specific Translator of the Booking.
       *
       * @return {void}
       */
      handleAdjustTranslator () {
        const payload = {
          id: this.booking.id,
          specific_translators: [0]
        };
        this.updateBookingAction(payload).then(() => {
          this.isAdjusted.translator = true;
        });
      },

      /**
       * Method to adjust the Gender of the Booking.
       *
       * @return {void}
       */
      handleAdjustGender () {
        const payload = {
          id: this.booking.id,
          gender: ''
        };
        this.updateBookingAction(payload).then(() => {
          this.isAdjusted.gender = true;
        });
      },

      /**
       * Method to adjust the Translator Level of the Booking.
       *
       * @return {void}
       */
      handleAdjustTranslatorLevel () {
        const payload = {
          id: this.booking.id,
          translator_levels: [1, 2, 3, 5, 6]
        };
        this.updateBookingAction(payload).then(() => {
          this.isAdjusted.translator_level = true;
        });
      },

      /**
       * Method to check if the necessary adjustments was made already.
       * @return {void}
       */
      adjustmentVerification () {
        if (window._.isNil(this.booking.id) || this.booking.id === '') return;

        const specificTranslators = this.booking.specific_translators;
        if (this.specifics.is_take_other_translator && isEmpty(specificTranslators)) {
          this.isAdjusted.translator = true;
        }

        const gender = this.booking.gender;
        if (this.specifics.is_take_other_translator && isEmpty(gender)) {
          this.isAdjusted.gender = true;
        }

        const translatorLevels = this.booking.translator_levels;
        if (this.specifics.is_take_other_translator && translatorLevels.length === 5) {
          this.isAdjusted.translator_level = true;
        }


      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {

    }
  }
</script>