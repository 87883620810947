<template>
    <div class="app-form app-fields user-department-form">

        <h3 class="text-center">{{$t('text_existing_departement')}}</h3>

        <!-- isBelongToExistingDepartment -->

        <el-form-item :id="genContainerId('is_belong_to_existing_department',formElId)"
                      class="full-width center-block text-center" style="max-width: 250px;">
            <el-radio-group :id="genFieldId('is_belong_to_existing_department',formElId)"
                            v-model="isBelongToExistingDepartment" tabindex="0">
                <el-radio :label="true">{{$t('yes')}}</el-radio>
                <el-radio :label="false">{{$t('no')}}</el-radio>
            </el-radio-group>
        </el-form-item>

        <!-- isBelongToExistingDepartment == true -->

        <template v-if="isBelongToExistingDepartment">

            <el-form-item :id="genContainerId('department_selections',formElId)"
                          class="full-width center-block" style="max-width: 500px;">
                <el-select :id="genFieldId('department_selections',formElId)"
                           v-model="iValue" clearable filterable
                           @focus="handleClickDepartmentSelection"
                           :placeholder="$t('please_select_customer_departement')">
                    <el-option v-for="o in filteredDepartmentOpts"
                               :key="o.id"
                               :value="o.id"
                               :label="o.name" value-key="id"/>
                </el-select>
            </el-form-item>

        </template>

        <template v-else>

            <h3>{{$t('create_department')}}</h3>

            <!-- Form for the Main Information -->
            <el-form :id="mainFormElId" :ref="mainFormElId">

                <department-main-fields :shouldShowConfidential="showAdditional" v-model="form.main" />

            </el-form>

            <el-form v-if="showAdditional" :id="additionalInfoFormElId" :ref="additionalInfoFormElId">

                <h3>{{$t('additional_information')}}</h3>
                <el-collapse class="panel-tabbed">

                    <el-collapse-item :title="$t('contact')" name="Contact" v-if="false">
                        <sector-contact-info-fields v-model="form.contact"
                                                    :form-el-id="additionalInfoFormElId"/>
                    </el-collapse-item>

                    <el-collapse-item :title="$t('address')" name="address">
                        <sector-address-fields v-model="form.address"
                                               :form-el-id="additionalInfoFormElId"/>
                    </el-collapse-item>

                    <el-collapse-item :title="$t('settings')" name="settings">
                        <sector-settings-fields v-model="form.sector_settings"
                                                :form-el-id="additionalInfoFormElId"/>
                    </el-collapse-item>

                </el-collapse>

                <el-collapse class="panel-tabbed">

                    <el-collapse-item title="Financial Settings" name="financial_settings">
                        <sector-financial-settings-form/>
                    </el-collapse-item>

                </el-collapse>

            </el-form>
            <!-- Save -->
            <!-- Muhammad Nauman (2019-09-03): Moved it here because now this component has been used in more than one place and some of the components are not needed there -->
                <el-form-item>
                    <el-button type="primary" :id="genFieldId('save',mainFormElId)"
                               @click="handleSubmit">
                        {{$t('save')}}
                    </el-button>
                </el-form-item>
        </template>

    </div> <!-- /.app-form app-fields user-department-form -->
</template>

<script>
  import {mapState, mapGetters, mapActions} from "vuex";
  import {populate} from "../../../js/helpers/Vuex";
  import {isEmpty} from "../../../js/helpers/Common";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      formElId: {
        type: String,
        default: 'user_department_form'
      },

      value: {
        default: ''
      },
      showAdditional: {
        type: Boolean,
        default: true,
      }

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        isBelongToExistingDepartment: true,

        mainFormElId: 'department_main_form',
        additionalInfoFormElId: 'department_additional_info_form'
      }
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapState('department', {
        form: 'compose'
      }),
      ...mapState('customer', {
        formCustomer: 'compose'
      }),

      ...mapGetters('department', {
        departmentOpts: 'listAsOptions'
      }),

      ...mapGetters('user', ['selectedCompany']),

      /**
       * Returns the filtered department options according to the
       * company_id value that was selected on UserCompanyForm.
       *
       * @return {array}
       */
      filteredDepartmentOpts () {

        // Define the necessary values.
        let company_id = this.formCustomer.company_id;
        let opts = this.departmentOpts;

        if (!isEmpty(company_id)) {

          // If there is a company_id that was selected, filtered the
          // options according to the given company id.
          return _.filter(opts, (o) => {
            return o.company_id == company_id;
          });

        } else {

          // Else, just return the unfiltered option.
          return opts;
        }

      }, // End of filteredDepartmentOpts

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        }
      }

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions('department', {
        loadDepartmentOpts: 'browseAsOptions',
        createEntry: 'add'
      }),

      /**
       * Handle when the form has been submitted.
       *
       * @return {void}
       */
      handleSubmit () {

        this.createEntry().then((r) => {

          let raw = r.data.data; // Store into container so varname will be shorter.
          let data = raw[Object.keys(raw)[0]]; // Get the first member of the object.
          this.iValue = data.id; // Set the created ID as the current value.
          // this.iValue.send_email_to_municipality = this.form.newDepartmentAndUser.send_email_to_municipality;
          // this.iValue.send_email_to_user = this.form.newDepartmentAndUser.send_email_to_user;

          this.isBelongToExistingDepartment = true;
        });

      }, // End of handleSubmit() method


      /**
       * Handler when the Department Selection was clicked.
       *
       * @return {void}
       */
      handleClickDepartmentSelection () {
        populate(this, 'loadDepartmentOpts', 'departmentOpts', {
          ref: 'Deparment Options'
        });
      }

    }, // End of Component > methods

    watch: {
      // "isBelongToExistingDepartment"(val) {
      //   this.iValue.show_confirm_btn = val;
      // }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      this.form.newDepartmentAndUser = this.iValue;
    }

  } // End of export default
</script>
