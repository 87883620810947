<template>
    <div class="app-display" :class="[
        genBem(blockCls),
        bookingStatusCls,
        mismatchCls,
    ]"
         @mouseenter="handleMouseenterBar"
         @mouseleave="handleMouseleaveBar">

        <div :class="genBem(blockCls, 'booking-id')">
            <a href="#" @click.stop.prevent="handleClickBookingId(booking.id)">
                <span>#{{ booking.id }}</span>
            </a>
        </div>
        <div :class="genBem(blockCls, 'language-name')">
            {{ booking.language_name }}
        </div>
        <div :class="genBem(blockCls, 'duration-label')">
            {{ booking.start_duration_label }} -
            {{ booking.end_duration_label }}
        </div>
        <div :class="genBem(blockCls, 'duration-and-type')">
            {{ booking.duration }} mins, {{ booking.type }}
        </div>
        <div v-if="isBookingStatus(booking.status.id, 'potential') && booking.mismatch_flags !== undefined"
             :class="genBem(blockCls, 'mismatch-flags')">
            <span v-if="booking.mismatch_flags.gender"
                  class="fa fa-flag" :class="genBem(blockCls, 'mismatch-flags', 'gender')"></span>
            <span v-if="booking.mismatch_flags.specific_translator"
                  class="fa fa-flag" :class="genBem(blockCls, 'mismatch-flags', 'specific-translator')"></span>
            <span v-if="booking.mismatch_flags.excluded_translator"
                  class="fa fa-flag" :class="genBem(blockCls, 'mismatch-flags', 'excluded-translator')"></span>
            <span v-if="booking.mismatch_flags.translator_level"
                  class="fa fa-flag" :class="genBem(blockCls, 'mismatch-flags', 'translator-level')"></span>
        </div>

        <div v-if="isBookingStatus(booking.status.id, 'potential')" :class="genBem(blockCls, 'assign-button')">
            <el-button @click.stop="handleClickAssign">
                Assign
            </el-button>
        </div>

    </div> <!-- template wrapper -->
</template>

<script>
  import BOOKING_STATUS from '~/js/constants/booking-status';
  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      booking: {
        type: Object,
        required: true
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'bookings-weekly__chart-bar',
        origZIndex: '',
        origHeight: '',
        minHeight: 80
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Returns a css class depending from mismatch flags.
       *
       * @returns {string}
       */
      mismatchCls () {
        const flags = this.booking.mismatch_flags;
        let result = '';

        if (flags === undefined) return '';

        // Loop tru object properties
        for (let key in flags) {
          if (flags.hasOwnProperty(key) && flags[key]) {
            result = window._.kebabCase(`mismatch-${key}`);
            break;
          }
        }

        return result === ''
          ? ''
          : this.genBem(this.blockCls, '', result);
      },

      /**
       * Returns a css class depending from booking status.
       *
       * @returns {string}
       */
      bookingStatusCls () {
        let result = '';

        if (this.booking.status.id === 1) {
          result = 'potential'
        } else {
          let str = window._.findKey(BOOKING_STATUS, (v) => v === this.booking.status.id);
          result = window._.kebabCase(str);
        }

        return this.genBem(this.blockCls, '', result);
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * Handler when the bar was hovered in.
       *
       * @param e - the mouse event.
       * @return {void}
       */
      handleMouseenterBar (e) {
        let el = e.target;
        this.origBarZIndex = el.style.zIndex;
        el.style.zIndex = '900';

        // Set custom css class if it's too short for preview.
        this.origHeight = parseInt(el.style.height.replace('px', ''));
        if (this.origHeight < this.minHeight) {
          el.style.height = `${this.minHeight}px`;
        }
      },

      /**
       * Handler when the bar was hovered out.
       *
       * @param e - the mouse event.
       * @return {void}
       */
      handleMouseleaveBar (e) {
        let el = e.target;
        el.style.zIndex = this.origBarZIndex;
        if (this.origHeight < this.minHeight) {
          el.style.height = `${this.origHeight}px`;
        }
      },

      /**
       * Handler when the assign button was clicked.
       *
       * @return {void}
       */
      handleClickAssign () {
        // Send an event when clicked.
        this.$emit('assign', this.booking);
      },

      /**
       * Handler when the Booking ID was clicked.
       *
       * @param id - Booking ID
       * @returns {void}
       */
      handleClickBookingId (id) {
        // Redirect the user to the booking details according to the given ID.
        const url = `/#/booking/${id}/details`
        window.open(url, '_blank');
      },

      /**
       * Helper method to return a boolean to confirm wether the
       * current booking status is the given value.
       *
       * @param {int} statusId
       * @param {string} assertStatus
       * @return {boolean}
       */
      isBookingStatus (statusId, assertStatus) {
        return BOOKING_STATUS[assertStatus.toUpperCase()] === statusId;
      }
    }
  }
</script>