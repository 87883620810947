<template>
  <div class="app-form price-inconvenience-charge-form">

    <div class="app-splitter two-cols app-flex-top">

      <!-- Invoice Form -->
      <div>

        <h3 class="app-title title-shaded">
          <span>{{$t('invoice')}}</span>
        </h3>

        <el-form :id="invoiceFormElId" :ref="invoiceFormElId">
          <financial-inconvenience-charge-fields
              :form-el-id="invoiceFormElId"
              v-model="invoiceForm.inconvenience_charge"
              :business-rules="invoiceBusinessRules"
              @clone-inconvenience-charges="cloneInvoiceInconvenienceCharges"
              :is-enabled-emergency-charges="isEnabledInvoiceEmergencyCharges"
          />
        </el-form>
      </div>

      <!-- Salary Form -->
      <div>

        <h3 class="app-title title-shaded">
          <span>{{$t('salary')}}</span>
        </h3>

        <el-form :id="salaryFormElId" :ref="salaryFormElId">
          <financial-inconvenience-charge-fields
              :form-el-id="salaryFormElId"
              v-model="salaryForm.inconvenience_charge"
              :business-rules="salaryBusinessRules"
              @clone-inconvenience-charges="cloneSalaryInconvenienceCharges"
              :is-enabled-emergency-charges="isEnabledSalaryEmergencyCharges"
          />
        </el-form>
      </div>

    </div> <!-- /.app-splitter two-cols -->

  </div> <!-- /.app-form price-inconvenience-charge-form -->
</template>

<script>
  import {mapState, mapGetters, mapMutations} from 'vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        invoiceFormElId: 'invoice_inconvenience_charge_form',
        salaryFormElId: 'salary_inconvenience_charge_form',
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('financialInvoice', {
        invoiceForm: 'settings',
      }),

      ...mapState('financialSalary', {
        salaryForm: 'settings',
      }),

      ...mapGetters('financialInvoice', {
        invoiceBusinessRules: 'businessRules',
        isEnabledInvoiceEmergencyCharges: 'isEnabledEmergencyCharges',
      }),

      ...mapGetters('financialSalary', {
        salaryBusinessRules: 'businessRules',
        isEnabledSalaryEmergencyCharges: 'isEnabledEmergencyCharges',
      }),
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapMutations('financialInvoice', {
        cloneInvoiceInconvenienceCharges: 'cloneInconvenienceCharges',
      }),

      ...mapMutations('financialSalary', {
        cloneSalaryInconvenienceCharges: 'cloneInconvenienceCharges',
      }),
    },

  } // End of export default
</script>
