<template>
    <div class="app-list booking-trail-log-list">

        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false"
                actions-col-width='130'
                :loading="loading">
            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="danger" :title="$t('cancel')" size="mini" icon="el-icon-fa fa-times">
                </el-button>
            </template>
        </base-list-table>

        <el-pagination
                v-if="false"
                layout="prev, pager, next"
                :page-size="listPagination.per_page"
                :total="listPagination.total"
                :current-page="listPagination.current_page"
                @current-change="reloadList">
        </el-pagination>

    </div> <!-- /.app-list booking-trail-log-list -->
</template>

<script>
    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {
        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {

                loading: false,

                listData: [
                    {
                        date: '2018-09-12',
                        time: '17:00',
                        action: 'Virpal accepted the Booking',
                        accepted_by: 'DT Admin',
                        accepted_via: 'DT Website',
                        old_value: 'Pending',
                        new_value: 'Accepted',
                    }
                ],

                listCols: [
                    {
                        prop: 'date',
                        label: 'Date',
                        width: '',
                        mutator(v){
                            return v
                        }
                    },
                    {
                        prop: 'time',
                        label: 'Time',
                        width: '',
                        mutator(v){
                            return v
                        }
                    },
                    {prop: 'action', label: 'Action', width: ''},
                    {prop: 'accepted_by', label: 'By', width: ''},
                    {prop: 'accepted_via', label: 'Via', width: ''},
                    {prop: 'old_value', label: 'Value Before', width: ''},
                    {prop: 'new_value', label: 'Value After', width: ''},
                ],

            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {
        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > watch
        |--------------------------------------------------------------------------
        */
        watch: {
        }, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {
        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
        }, // End of Component > mounted

    } // End of export default
</script>
