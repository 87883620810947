<template>
  <div class="submit-translated-file-modal">
    <el-dialog title='Submit Translated File'
    :visible.sync="iVisible"
    :modal-append-to-body="false"
    :append-to-body="true"
    width="90%"
    v-loading="loading"
    :lock-scroll="false">
    <el-form :model="form" :rules="rules" ref="submitFile" @submit.native.prevent>

      <el-form-item label='' prop='files'>
        <aws-file-upload v-model="form.files" ></aws-file-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
          <el-button @click="iVisible = false">Cancel</el-button>
          <el-button type='primary' @click="submitForm('submitFile')" :disabled="disabled" > Submit File</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>

import AwsFileUpload from '~/components/displays/AwsFileUpload'
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
      visible: {
        type: Boolean,
        default: false
      },
      booking:{
        type: Object,
        required:true
      }
    },
  components:{
    AwsFileUpload
  },
  data() {
    return {
      loading:false,
      form:{
        files:[],
        submission_type:'submited_for_review',
        uploaded_by:'',
        key:''
      },
      rules:{
        uploaded_by:[{
          required: true,
            message: 'Please Select Translator who upload the file'
        }],
        files:[{
          required: true,
            message: 'Please Select upload file'
        }]
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/userData',
    }),
      iVisible: {
        get() {
          return this.visible
        },
        set(v) {
          this.$emit('update:visible', v);
        }
      },
      disabled(){
        return this.form.files.length === 0 || this.loading === true
      },
      iBooking(){
        return this.booking
      }
    },
  methods:{
    ...mapActions('textTranslation', {
      postSubmitFile:'postSubmitFile'
    }),
    submitForm(form){
      this.loading = true;
      this.$refs[form].validate((valid) => {
        if(valid){

          let formData ={
            submission_type:'submited_for_review',
            id:this.iBooking.text_translation.id,
            uploaded_by:this.user.id,
            hours_by_translator: this.iBooking.text_translation.hours_by_translator,
            words_amount: this.iBooking.text_translation.words_amount,
            files: this.form.files
          };

          this.postSubmitFile(formData).then((r)=>{

            // this.$store.dispatch('booking/getBooking', this.iBooking.id)
            this.$emit('reload')
            this.iVisible = false;

            this.loading = false;
          });
        }
      })
    },
  }
}
</script>
