<template>
    <div class="base-details-modal">
        <el-dialog
                :title="$t('details')"
                :visible.sync="modalVisible"
                @close="handleClose"
                :custom-class="customClass"
                :width="width"
                :modalAppendToBody="appendToBody"
                top="3%">
            <table>
                <template v-for="row in rows">
                    <tr v-if="crProps(row, 'title')">
                        <td class="td-title" colspan="2">
                            <div>{{ crProps(row, 'title') }}</div>
                        </td>
                    </tr>
                    <tr :class="'bdm-row ' + crProps(row, 'className')">
                        <td class="td-label" width="20%">
                            <div>{{ crProps(row, 'label') }}</div>
                        </td>
                        <td class="td-value">
                            <div v-if="crProps(row, 'list')">

                                <template v-if="crProps(row, 'childProp') != 'no_prop'">
                                    <p v-for="v in mok(info, crProps(row, 'prop'))">
                                        {{ mok(v, crProps(row, 'childProp')) }}
                                    </p>
                                </template>

                                <template v-else>
                                    <p v-for="v in mok(info, crProps(row, 'prop'))">{{ v }}</p>
                                </template>

                            </div>

                            <div v-else>
                                {{ mok(info, crProps(row, 'prop')) }}
                            </div>
                        </td>
                    </tr>
                </template>


            </table>

        </el-dialog>
    </div> <!-- .base-details-modal -->
</template>

<script>
    import {mok, crProps} from "../../../js/helpers/Common";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            visible: {
                type: Boolean,
                default: () => {
                    return false;
                }
            },

            extraRows: {
                type: Array,
                default: () => {
                    return [];
                }
            },

            customClass: {
                type: String,
                default: 'base-details-modal',
            },

            width: {
                type: String,
                default: '40%',
            },

            info: {
                type: Object,
                default: () => {
                    return {};
                }
            },

            appendToBody: {
                type: Boolean,
                default: true
            }

        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data: function () {
            return {
                modalVisible: this.visible
            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            /**
             * Define default rows (this should be array or objects).
             * Please refer to /src/assets/js/helpers/Common.js@crProps() for the
             * documentation of the expected values.
             *
             * @return {array}
             */
            rows: function () {

                let r = [
                    {
                        label: this.$t('id'),
                        prop: 'id',
                        width: '50px',
                        className: 'id-row',
                    },
                ];

                let extra = this.extraRows;

                if ( !_.isEmpty(extra) ) {
                    _.each(extra, (x) => {
                        r.push(x);
                    });
                }

                return r;

            }, // End of rows() method

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > watch
        |--------------------------------------------------------------------------
        */
        watch: {
            visible: function (newVal) {
                this.modalVisible = newVal;
            },
        }, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            /**
             * Handle when the modal has been closed
             *
             * @return {void}
             */
            handleClose: function () {
                this.$emit('update:visible', false);
            }, // End of handleClose() method

            // Mapping of helpers
            crProps, mok,

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted: function () {
        }, // End of Component > mounted

    } // End of export default

</script>
