<template>
    <div class="app-list translation-languages-list">
        <el-row>
            <el-col  :offset="14">
                <router-link tag="button" to="/locale/translation" @click.stop.prevent title="Create Language" class="el-button el-button--success">
                    <i class="fa fa-plus"></i> New Translation
                </router-link>
                <router-link tag="button" to="/locale/create" @click.stop.prevent title="Create Language" class="el-button el-button--success">
                    <i class="fa fa-plus"></i> Create Language
                </router-link>
            </el-col>
        </el-row>

        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="actionsColumn" slot-scope="scope">
                <!-- <el-button class="circle" type="default" title="Group">
                    <span class="fa fa-group"></span>
                </el-button> -->
                <router-link tag="button" :to="`/locale/groups/${scope.row.locale}`" @click.stop.prevent title="Groups" class="el-button circle el-button--default">
                    <i class="fa fa-group"></i>
                </router-link>
                <el-button class="circle" type="success" title="Edit">
                    <span class="fa fa-pencil"></span>
                </el-button>
                <el-button class="circle" type="danger" title="Delete">
                    <span class="fa fa-trash"></span>
                </el-button>
            </template>
        </base-list-table>
    </div> <!-- /.app-list translation-languages-list -->
</template>

<script>
    // import {mapGetters} from vuex

        export default {

        /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {

                hiddenCols: ['id'],
                listCols: [
                    {
                        label: 'Locale',
                        prop: 'locale',
                        width: '',
                        className: 'locale-col',
                    },
                    {
                        label: 'Language Name',
                        prop: 'language_name',
                        width: '',
                        className: 'language_name-col',
                    }
                ],
                listData: [
                    {id: '1', locale: 'sv',language_name: 'Swedish'},
                    {id: '2', locale: 'en',language_name: 'English'},
                ]
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            // ...mapGetters('',['listData'])

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
        methods: {}, // End of Component > methods


        /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {}, // End of Component > mounted

    }
</script>