<template>
  <div class="app-page user-compose-page user-customer-compose-page"
       v-loading.fullscreen.lock="itemLoading">

    <page-header :title="pageHeaderTitle" :back-enabled="true"/>

    <div class="app-container">
      <div class="app-wrapper">
        <div class="app-row">
          <div class="app-col">

            <!-- Profile button -->
            <div class="text-right">
              <el-button type="primary" size="mini" v-if="isEdit" @click="handleRedirectLogin">
                {{$t('login_as')}}
              </el-button>
              <el-button type="primary" size="mini" @click="handleRedirectAudits">
                {{$t('audit_logs')}}
              </el-button>
              <el-button v-if="isEdit"
                         type="primary"
                         size="mini"
                         @click="handleClickProfile">
                <span class="fa fa-user"></span>
                <span style="margin-left: 5px;">{{$t('go_to_profile')}}</span>
              </el-button>
            </div>

            <!-- The Form -->
            <el-form :id="formElId" :ref="formElId" :class="genBem(blockCls)">

              <el-collapse v-model="activePanels">

                <!-- Personal Info -->
                <el-collapse-item :name="1"
                                  :title="$t('personal_info')"
                                  :class="genBem(blockCls, 'personal-info')">
                  <user-customer-personal-info-fields v-model="form.personal_info"
                                                      :form-el-id="formElId"/>
                  <goto-element :label="goto.label" :target="goto.target"/>
                </el-collapse-item>

                <!-- Credentials -->
                <el-collapse-item :name="2"
                                  :title="$t('credentials')"
                                  :class="genBem(blockCls, 'credentials')">
                  <user-credential-fields v-model="form.credential"
                                          :form-el-id="formElId"/>
                  <goto-element :label="goto.label" :target="goto.target"/>
                </el-collapse-item>

                <!-- Company -->
                <el-collapse-item :name="3"
                                  :title="$t('company')"
                                  :class="genBem(blockCls, 'company')">
                  <user-company-form v-model="form.company_id"
                                     :form-el-id="formElId"/>
                  <goto-element :label="goto.label" :target="goto.target"/>
                </el-collapse-item>

                <!-- Department -->
                <el-collapse-item :name="4"
                                  :title="$t('department')"
                                  :class="genBem(blockCls, 'department')">
                  <user-department-form v-model="form.department_id"
                                        :form-el-id="formElId"/>
                  <goto-element :label="goto.label" :target="goto.target"/>
                </el-collapse-item>

                <!-- Settings -->
                <el-collapse-item :name="5"
                                  :title="$t('settings')"
                                  :class="genBem(blockCls, 'settings')">
                  <user-customer-settings-fields v-model="form.settings"
                                                 :form-el-id="formElId"/>
                  <goto-element :label="goto.label" :target="goto.target"/>
                </el-collapse-item>

                <!-- Address -->
                <el-collapse-item :name="6"
                                  :title="$t('address')"
                                  :class="genBem(blockCls, 'address')">
                  <user-address-fields v-model="form.address" :form-el-id="formElId"/>
                  <goto-element :label="goto.label" :target="goto.target"/>
                </el-collapse-item>

                <!-- Questions -->
                <el-collapse-item :name="7"
                                  :title="$t('questions')"
                                  :class="genBem(blockCls, 'questions')">
                  <user-customer-questions-fields v-model="form.questions"
                                                  :form-el-id="formElId"/>
                  <goto-element :label="goto.label" :target="goto.target"/>
                </el-collapse-item>

                <!-- Booking Confirm Fields -->
                <el-collapse-item :name="8"
                                  :title="$t('booking_confirm_fields')"
                                  :class="genBem(blockCls, 'booking-confirm-fields')">
                  <user-booking-confirm-fields
                    :configFound="municipalityConfigFound"
                    :municipalityId="municipality_id"
                    v-model="form.booking_confirm_fields"
                    :bookingFieldsMunicipality="form.booking_confirm_fields_municipality"
                    :bookingFieldsUser="form.booking_confirm_fields_default"
                    :form-el-id="formElId"/>
                  <goto-element :label="goto.label" :target="goto.target"/>
                </el-collapse-item>

              </el-collapse>

              <el-form-item :class="genBem(blockCls, 'btns')">
                <el-button v-if="isAdd"
                           type="primary"
                           :class="genBem(blockCls, 'submit-add-btn')"
                           @click="handleClickSubmitAdd">
                  {{$t('create')}}
                </el-button>
                <el-button v-if="isEdit"
                           type="primary"
                           :class="genBem(blockCls, 'submit-edit-btn')"
                           @click="handleClickSubmitEdit">
                  {{$t('update')}}
                </el-button>
                <el-button v-if="isDuplicate"
                           type="primary"
                           :class="genBem(blockCls, 'submit-add-btn')"
                           @click="handleClickSubmitAdd">
                  Duplicate
                </el-button>
              </el-form-item>

            </el-form>

          </div> <!-- /.app-col -->
        </div> <!-- /.app-row -->
      </div> <!-- /.app-wrapper -->
    </div> <!-- /.app-container -->

  </div> <!-- /.app-page user-customer-compose-page -->
</template>

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
  import {checkBreadMode} from '~/js/helpers/Route';
  import UserCustomerPersonalInfoFields
    from '~/components/forms/user/personal-info-fields/UserCustomerPersonalInfoFields';
  import UserCredentialFields from '~/components/forms/user/UserCredentialFields';
  import UserCompanyForm from '~/components/forms/user/UserCompanyForm';
  import UserDepartmentForm from '~/components/forms/user/UserDepartmentForm';
  import UserCustomerSettingsFields from '~/components/forms/user/settings-fields/UserCustomerSettingsFields';
  import UserAddressFields from '~/components/forms/user/UserAddressFields';
  import UserBookingConfirmFields from '~/components/forms/user/questions-fields/UserBookingConfirmFields';
  import UserCustomerQuestionsFields from '~/components/forms/user/questions-fields/UserCustomerQuestionsFields';
  import GotoElement from '~/components/displays/GotoElement';
  import {sd} from "~/js/helpers/Common";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      UserCustomerPersonalInfoFields,
      UserCredentialFields,
      UserCompanyForm,
      UserDepartmentForm,
      UserCustomerSettingsFields,
      UserAddressFields,
      UserBookingConfirmFields,
      UserCustomerQuestionsFields,
      GotoElement
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'user-customer-compose',
        formElId: 'customer_compose_form',
        activePanels: [1, 2, 3, 4, 5, 6, 7, 8],
        goto: {
          label: `${this.$t('go_to_save_button')}`,
          target: '.user-customer-compose__btns'
        },
        municipality_id: 0,
        municipalityConfigFound: true,
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > watch
    |--------------------------------------------------------------------------
    */
    watch: {
      userId (v) {
        this.prepareForm(v);
      },
      company_id () {
        // if(this.isAdd) {
        this.loadCustomerTypeOpts();
        this.loadTownOpts();
        this.loadCompanySingle({id: this.form.company_id}).then(res => {
          const {company} = res.data.data
          this.municipality_id = company.municipality_id
          /*this.readBcfConfigMunicipality({municipality_id: company.municipality_id,level:"municipality"}).then(r => {
            this.municipalityConfigFound = r !== false;
          })*/
          if (this.isAdd) {
            this.loadMunicipalitySingle({id: company.municipality_id}).then(response => {
              const {municipality} = response.data.data
              this.form.settings.customer_type = municipality.customer_type_id || ''
              this.form.questions.allow_send_invoices = municipality.allow_send_invoices || false
              this.form.questions.allow_show_invoices = municipality.allow_show_invoices || false
              this.form.questions.allow_fallback_type = municipality.allow_fallback_type || true
              this.form.settings.town_id = municipality.town_id || ''
            })
          }

        })
        // }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapState('customer', {
        form: 'compose',
        rels: 'relationships',
      }),

      ...mapGetters({
        itemLoading: 'customer/itemLoading'
      }),

      /**
       * Returns the title to be displayed in the page header.
       *
       * @returns {string}
       */
      pageHeaderTitle () {
        const mode = this.isAdd ? `${this.$t('create')}` : this.isEdit ? `${this.$t('edit')}` : `${this.$t('duplicate')}`;
        const title = `${this.$t('customer')}`;
        return `${mode} ${title}`;
      },

      /**
       * Returns the user's ID from route param "id"
       * @return {int}
       */
      userId () {
        return !window._.isNil(this.$route.params) && !window._.isNil(this.$route.params.id)
          ? parseInt(this.$route.params.id)
          : 0;
      },

      /**
       * Returns boolean whether if the current compose page
       * processes an ADD action.
       *
       * @returns {boolean}
       */
      isAdd () {
        return this.breadMode === 'add';
      },

      /**
       * Returns boolean whether if the current compose page
       * processes an EDIT action.
       *
       * @returns {boolean}
       */
      isEdit () {
        return this.breadMode === 'edit';
      },

      /**
       * Returns boolean whether if the current compose page
       * processes an DuplicaTE action.
       *
       * @returns {boolean}
       */
      isDuplicate () {
        return this.breadMode === 'duplicate';
      },

      /**
       * Returns the compose mode of the page. Varies between add | edit.
       *
       * @returns {string}
       */
      breadMode () {
        return checkBreadMode(this);
      },
      company_id () {
        return this.form.company_id;
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapMutations('customer', ['resetCompose', 'resetPayload']),

      ...mapActions('customer', {
        readEntry: 'read',
        editEntry: 'edit',
        addEntry: 'add',
        readBcfConfig: 'readBcfConfig',
        readBcfConfigMunicipality: 'readBcfConfigMunicipality',
        editBcfConfig: 'editBcfConfig',
      }),

      // Options
      ...mapActions({
        loadTranslatorOpts: 'translator/browseAsOptions',
        loadCustomerTypeOpts: 'customerType/browseAsOptions',
        loadTownOpts: 'town/browseAsOptions',
        loadCompanyOpts: 'company/browseAsOptions',
        loadDepartmentOpts: 'department/browseAsOptions',
        loadCompanySingle: 'company/read',
        loadMunicipalitySingle: 'municipality/read',
        addActivity: 'userAccessLogs/addLogs'
      }),
      ...mapActions("auth", {
        loginAs: "loginAs"
      }),

      /**
       * Helper method for loading all the available option loaders.
       *
       * @return {void}
       */
      loadAllOpts () {
        this.loadTranslatorOpts();
        this.loadCustomerTypeOpts();
        this.loadTownOpts();
        this.loadCompanyOpts()
          // sorry!!! i comments this due to issues with  issues with update user bcf
            .then(response => {
              let company = response.data.data.companies.filter(company => company.id === this.form.company_id);
              this.readBcfConfigMunicipality({municipality_id: company[0].municipality_id,level:"municipality"})
                  .then(r => {
                    this.municipalityConfigFound = r !== false;
                  }).catch(console.log)
            })
        this.loadDepartmentOpts();
      },

      /**
       * Handler when the submit button while in ADD mode was clicked.
       *
       * @return {void}
       */
      handleClickSubmitAdd () {
        this.addEntry()
            .then((r) => {
              const customerId = r.data.data.customer.id;
              this.handleUpdateBcf(customerId);
            });
      },

      /**
       * Handle when redirecting to the user's audit logs
       *
       * @return {void}
       */
      handleRedirectAudits () {
        this.$router.push({
          name: 'audits',
          params: {
            id: this.$route.params.id,
            auditable: 'users'
          },
          query: {
            relationships: this.rels.join(',')
          }
        })
      },

      /**
       * Handler when the submit button while in EDIT mode was clicked.
       *
       * @return {void}
       */
      handleClickSubmitEdit () {
        this.editEntry({id: this.userId})
            .then(() => {
              this.handleUpdateBcf(this.userId);
            });
      },

      /**
       * Handle when the configuration for Booking Confirm Fields is submitted.
       *
       * @return {void}
       */
      handleUpdateBcf (customerId) {

        // Define the data to be sent.
        let data = _.cloneDeep(this.form.booking_confirm_fields);
        if (this.form.booking_confirm_fields.same_as_municipality) {
          data.synced_from_municipality = data.municipality_id
        }
        data.customer_id = customerId;
        delete data.municipality_id;
        // Reach out to API for creating/updating the entry.
        this.editBcfConfig({data});
      },

      /**
       * Handler when the profile button was clicked.
       *
       * @return {void}
       */
      handleClickProfile () {
        this.$router.push({
          name: 'user.profile',
          params: {id: this.userId},
          query: {type: 2}
        });
      },

      /**
       * Method to wrap the entry loading action.
       *
       * @param {int|string} id - ID of the entry.
       * @return {void}
       */
      prepareForm (id) {
        console.log(`Preparing Form: ${id}`);
        this.resetCompose();
        this.resetPayload();

        // If on edit mode, load the necessary data for the user.
        if (this.isEdit && id) {
          this.readEntry({id})
              .then(() => {
                this.loadAllOpts();
                this.readBcfConfig({customer_id: id,level: 'customer'});
              })
              .catch(() => {
                this.$notify.error({
                  title: 'Error',
                  message: 'There is no customer by that ID.',
                });
              });
        }
        // If on duplicate mode, load the necessary data for the user.
        if (this.isDuplicate && id) {
          this.readEntry({id: id, isDuplicate: true})
              .then(() => {
                this.loadAllOpts();
                this.readBcfConfig({customer_id: id,level: 'customer'});
              })
              .catch(() => {
                this.$notify.error({
                  title: 'Error',
                  message: 'There is no customer by that ID.',
                });
              });
        }
      },

      /**
       * Generate HTML when the api return validation error
       *
       * @return {html}
       *
       */
      getGeneratedMessage (exception) {
        if (_.isObject(exception)) {
          let keys = Object.keys(exception)
          let html = '';
          keys.map((key) => {
            let value = exception[key][0];
            html += value + '<br>'

          })
          return html;
        }
        return exception
      },
      /**
       * Handle when redirecting to the translator to login
       *
       * @return {void}
       */
      handleRedirectLogin () {
        this.loginAs({
          email: this.form.personal_info.email
        })
            .then(response => {
              let params = `?access=${response.data.token}`;
              window.open(
                sd(
                  process.env.MIX_APP_APP_CT_URL,
                  "https://ct.digitaltolk.se/"
                ) +
                "login/as" +
                params,
                "_blank"
              );
            })
            .catch(error => {
              console.log(error);
              this.$notify.error({
                title: "Error",
                message: "Unable to Login"
              });
            });
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      this.prepareForm(this.userId);

      if (this.isEdit) {
        this.addActivity({
          action: 'has-visited',
          id: this.$route.params.id,
          message: 'Edit Profile Page'
        });
      }
    }

  }
</script>
