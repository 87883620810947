/*
|--------------------------------------------------------------------------
| Store > Invoice Reminder Settings > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/

let invoiceReminderSettings = {
  name: "",
  number: "",
  sent_via: 1,
  value: {
    email: "",
    address: "",
    comment: ""
  }
};

export default {
  sent_via: [
    {
      value: 1,
      label: "Email"
    },
    {
      value: 2,
      label: "Mail Post"
    },
    {
      value: 3,
      label: "Other"
    }
  ],
  composeFresh: _.cloneDeep(invoiceReminderSettings),
  compose: _.cloneDeep(invoiceReminderSettings)
};
