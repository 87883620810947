<template>
    <el-dialog title="Translator Not Pickup Warnings" 
        :modal-append-to-body="false" 
        :visible.sync="iVisible" :lock-scroll="false">


        <user-translator-booking-warning :user="iUser"></user-translator-booking-warning>
    </el-dialog>
</template>
<script>

  import UserTranslatorBookingWarning from '~/components/lists/user/UserTranslatorBookingWarning';
export default {
  props:{
    user:{
      type: Object,
      required:true,
    },
    visible:{
      type: Boolean,
      default:false
    }
  },
  components:{
    UserTranslatorBookingWarning
  },
  data(){
    return {}
  },
  computed:{
    iUser(){
      return this.user
    },
    iVisible: {
        get() {
            return this.visible
        },
        set(v) {
            this.$emit('update:visible', v);
        }
      },
  }
}
</script>