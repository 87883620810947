<template>
    <div v-loading="loading" class="wrap-feedback-cause-tags">
        <h4>{{$t('feedback_cause_tags')}}:</h4>
        <div>
            <el-tag
                :key="tag.id"
                v-for="tag in poppedTaggedIssues"
                closable
                type="info"
                :disable-transitions="true"
                @close="handleClose(tag)"
                class="feedback-cause-tags"
                >
                {{tag.booking_issue_tag_name}}
            </el-tag>

            <template v-if="tagSelectVisible">
                <el-select
                    ref="addTagSelect"
                    class="select-booking-issue-tags"
                    type="small"
                    v-model="issueTags"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    :disabled="loading"
                    placeholder="Enter a keyword"
                    @visible-change="handleVisibleChange($event);"
                    @change="handleAddTag"
                    >
                    <el-option
                        v-for="item in untaggedTags"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </template>

            <el-tag
                type="info"
                :loading="loading"
                :disable-transitions="true"
                v-if="btnAddTagVisible"
                @click.prevent="showInput"
                >
                {{$t('add_tag')}}
            </el-tag>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import {populate,forcePopulate} from "~/js/helpers/Vuex";
import { isEmpty } from '../../../../js/helpers/Common';
import {Notification} from 'element-ui';

export default {
    data() {
        return {
            tagSelectVisible: false,
            btnAddTagVisible: true,
            initialOptionLoad: true,
            loading: false,
            poppedTagList: [],
            poppedTaggedIssues : [],
            untaggedTags: [],
            taggedTags: [],
            issueTags : [],
        }
    },

    computed: {
        ...mapState('bookingIssueTag',{
            formTag : 'compose',
            bookingIssueTags:'list'
        }),

        ...mapState('bookingIssueTaggedIssue',{
            formTaggedIssue : 'compose',
        }),
    },

    methods: {
        ...mapActions({
            browseTag           : 'bookingIssueTag/browseAsOptions',
            addTag              : 'bookingIssueTag/add',

            browseTaggedIssues  : 'bookingIssueTaggedIssue/getByBookingIssueId',
            addIssue            : 'bookingIssueTaggedIssue/add',
            addIssueWithNewTag  : 'bookingIssueTaggedIssue/storeWithNewTag',
            deleteIssue         : 'bookingIssueTaggedIssue/delete'
        }),

        handleVisibleChange(e) {
            if(this.initialOptionLoad) {
                this.initialOptionLoad = false;
            }

            if(e) {
                this.initialOptionLoad = true;
            }

            if(!e && !this.initialOptionLoad) {
                setTimeout(() => {
                    this.tagSelectVisible = false;
                    this.initialOptionLoad = true;
                    this.loading = false;
                }, 100);
            }
        },

        showInput() {
            this.tagSelectVisible=true;
            this.$nextTick(() => {
                this.$refs.addTagSelect.focus();
            });
        },

        handleClose(tag) {
            this.loading=true;

            this.deleteIssue({id : tag.id}).then(r => {
                this.loading=false;
                this.loadTaggedIssues()
            });
        },

        handleAddTag(query) {
            let isNewTag = true
            let payload = {}
            let authUserID = JSON.parse(
              localStorage.getItem('app_user_data')
            ).id
            let bookingIssueId = this.$route.params.id
            let bookingIssueTagId = this.issueTags[0]

            for(var i=0; i< this.untaggedTags.length; i++) {
                if(this.issueTags[0] === this.untaggedTags[i].id) {
                    isNewTag=false
                    break
                }
            }

            this.loading = true;
            this.tagSelectVisible=false;

            if(!isEmpty(query)) {
                if(isNewTag) {

                    const n = this.taggedTags.filter(function (el) { return el.booking_issue_tag_name == bookingIssueTagId });
                    if(n !== 0) {
                        Notification.warning({
                            title: 'Validation',
                            message: 'Booking issue is already tagged with the same tag name.'
                        })

                        this.issueTags = []
                        this.loading = false
                        return
                    }

                    this.formTag.name = bookingIssueTagId,
                    this.formTag.state = true,
                    this.formTag.created_by = authUserID,
                    this.formTag.updated_by = authUserID

                    payload = {
                        booking_issue_id : bookingIssueId,
                        booking_issue_tag_name : bookingIssueTagId,
                        state : true,
                        created_by : authUserID
                    }

                    this.addIssueWithNewTag(payload).then(r => {
                        this.reloadTags();
                    });
                }
                else {
                    // prep to add tagged issue
                    this.formTaggedIssue.booking_issue_id = bookingIssueId
                    this.formTaggedIssue.booking_issue_tag_id = bookingIssueTagId
                    this.formTaggedIssue.created_by = authUserID

                    payload = {
                        booking_issue_id : bookingIssueId,
                        booking_issue_tag_id : bookingIssueTagId,
                        created_by : authUserID
                    }

                    // add tagged issue
                    this.addIssue(payload).then(r => {
                        if (r.status == 201) {
                            this.reloadTags();
                        }
                    });
                }
            }
        },

        loadTags() {
            this.loading = true;
            this.browseTag({params : {all: true, state: 1}}).then(r => {
                let d = r.data.data.booking_issue_tags;
                this.poppedTagList = d.filter(function (el) { return el.state == 1 });
                this.loadTaggedIssues()
            });
        },

        loadTaggedIssues() {
            this.browseTaggedIssues({booking_issue_id : this.$route.params.id}).then(r => {
                this.poppedTaggedIssues = r.data.data.booking_issue_tagged_issues;
                this.listUntaggedTags();
            });
        },

        listUntaggedTags() {
            this.untaggedTags = this.poppedTagList.filter(({id}) => !this.poppedTaggedIssues.some(x => x.booking_issue_tag_id == id))
            this.taggedTags = this.poppedTaggedIssues.filter(({booking_issue_tag_id}) => !this.poppedTagList.some(x => x.id == booking_issue_tag_id))
            this.loading = false
        },

        reloadTags() {
            this.loadTaggedIssues()
            this.tagSelectVisible=false
            this.issueTags = []
        }
    },

    mounted() {
        populate(this, 'browseTag', '', {force: true,params:this.populatePayload})
        this.loadTags()
    },

    watch : {
        tagSelectVisible: function(){
            if(this.tagSelectVisible === true) {
                this.btnAddTagVisible=false;
            }
            else {
                this.btnAddTagVisible=true
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .wrap-feedback-cause-tags {
        margin-bottom: 5px;

        .select-booking-issue-tags {
            input[type=text] {
                border: 0px solid transparent !important;
                margin-left: 0px;
            }
        }

        .feedback-cause-tags {
            margin-bottom: 5px;
            margin-right: 5px;
        }
    }
</style>
