import moment from 'moment';
import i18n from '~/js/i18n';

/**
 * @param {Object} booking
 * @returns {string}
 */
export function generateMessage (booking) {
  let batchTowns = booking.batch_town || booking.towns;

  if (booking.type === 'physical') {
    return "Ny bokning " + moment(booking.due).format('YYYY-MM-DD') + " kl " + moment(booking.due).format('HH:mm') + "\n" +
      "Typ: " + i18n.t(booking.type) + "\n" +
      "Kund: " + booking.customer.department.company.name + "\n" +
      "Språk: " + booking.language_name + "\n" +
      "Kan du ta det, eller annan tid?\n" +
      "Ort: " + batchTowns.map((town) => {
        return town.name;
      }).join(',') + "\n" +
      "Tack\n" +
      "DigitalTolk";

  } else {
    return "Ny bokning " + moment(booking.due).format('YYYY-MM-DD') + " kl " + moment(booking.due).format('HH:mm') + "\n" +
      "Typ: " + i18n.t(booking.type) + "\n" +
      "Kund: " + booking.customer.department.company.name + "\n" +
      "Språk: " + booking.language_name + "\n" +
      "Kan du ta det, eller annan tid?\n" +
      "Tack\n" +
      "DigitalTolk";
  }
}
