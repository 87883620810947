<template>
    <div class="app-list" :class="genBem(blockCls)">

        <!-- Header -->
        <div :class="genBem(blockCls, 'header')">
            <!-- Header Title -->
            <h3 v-if="title !== ''"
                :class="genBem(blockCls, 'header-title')">{{ title }}</h3>

            <!-- Header Buttons -->
            <div :class="genBem(blockCls, 'header-buttons')">
                <el-button @click="handleClickAdd">{{ $t('tfv_fn_add_item') }}</el-button>
            </div>
        </div> <!-- End of Header -->

        <!-- Body -->
        <div :class="genBem(blockCls, 'body')"
             v-loading="listLoading">
            <base-list-table
                    class="invoice-list-table"
                    :table-data="listData"
                    :extra-cols="listCols"
                    :hasDetailsCol="false">

                <template slot="actionsColumn" slot-scope="scope">
                    <el-button class="circle" type="success" :title="$t('edit')" @click="handleClickEdit(scope)">
                        <span class="fa fa-pencil"></span>
                    </el-button>
                    <el-button class="circle" type="danger" :title="$t('remove')" @click="handleClickDelete(scope)">
                        <span class="fa fa-trash"></span>
                    </el-button>
                </template>

            </base-list-table>

            <!-- Pagination -->
            <el-pagination
                    v-if="listPaginationValid && listPagination.total > listPagination.per_page"
                    layout="prev, pager, next"
                    :page-size="listPagination.per_page"
                    :total="listPagination.total"
                    :current-page="listPagination.current_page"
                    @current-change="handleChangePage">
            </el-pagination>
        </div> <!-- End of Body -->

        <!-- Compose Modal -->
        <el-dialog :id="`${target}_financial_additional_compose_modal`"
                   type="primary"
                   :title="target === 'invoice' ? $t('tfv_fn_add_charge') : $t('tfv_fn_add_penalty')"
                   :visible.sync="showComposeModal"
                   :modal-append-to-body="false"
                   top="3%">
            <el-form>
                <!-- Preview -->
                <financial-preview-display
                        :id="`${target}_financial_preview_display`"
                        :booking-id="bookingId"
                        :target="target"
                        :is-show-header-buttons="false"/>

                <hr class="hr-type1">

                <!-- Form -->
                <financial-additional-shared-fields
                        :form-el-id="`${target}_financial_additional_form`"
                        v-model="iForm"
                        :target="target"
                        :visible.sync="showComposeModal"
                        :enable-salary-type="target === 'salary'"
                        :edit-mode="editMode"
                        @add="handleAddSubmit"
                        @update="handleEditSubmit"
                        @reset="handleReset"
                />
            </el-form>
        </el-dialog>

    </div> <!-- template wrapper -->
</template>

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
  import FinancialAdditionalSharedFields from '~/components/forms/financial/additional/FinancialAdditionalSharedFields';
  import FinancialPreviewDisplay from '~/components/displays/financial/preview/FinancialPreviewDisplay';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      FinancialPreviewDisplay,
      FinancialAdditionalSharedFields,
    },

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      title: {
        type: String,
        default: ''
      },

      target: {
        type: String,
        default: '',
      },

      bookingId: {
        default: 0
      }

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-additional-list',

        showComposeModal: false,
        editMode: false,
        editId: 0,
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapState('financialAdditionalInvoice', {
        invoiceForm: 'compose'
      }),

      ...mapState('financialAdditionalSalary', {
        salaryForm: 'compose'
      }),

      ...mapGetters({
        // Invoice
        invoiceList: 'financialAdditionalInvoice/list',
        invoiceListLoading: 'financialAdditionalInvoice/listLoading',

        // Salary
        salaryList: 'financialAdditionalSalary/list',
        salaryListLoading: 'financialAdditionalSalary/listLoading'
      }),

      /**
       * Interface for the the form data that will be used for this component.
       */
      iForm: {
        get () {
          const form = `${this.target}Form`;
          return this[form];
        },
        set (v) {
          const form = `${this.target}Form`;
          this[form] = v;
        }
      },

      /**
       * Object to map the columns of the base-list-table.
       *
       * @return {array}
       */
      listCols () {
        return [
          {

            label: this.$t('tfv_fn_explanation_field'),
            prop: 'description',
            className: 'description-col',
          },
          {
            label: this.$t('tfv_fn_amount_field'),
            prop: 'price',
            className: 'price-col',
          },
        ];
      },

      /**
       * Returns the target list Object.
       * @returns {*}
       */
      list () {
        const list = `${this.target}List`;
        return this[list];
      },

      /**
       * Returns the array of data to be displayed in the list.
       *
       * @return {array}
       */
      listData () {
        return this.list.data;
      },

      /**
       * Returns the pagination object as basis of pagination controls.
       *
       * @return {object}
       */
      listPagination () {
        return this.list.pagination;
      },

      /**
       * Returns boolean to know whether the pagination object is valid.
       *
       * @return {boolean}
       */
      listPaginationValid () {
        return !window._.isEmpty(this.list.pagination);
      },

      /**
       * Returns boolean for the loading state of the list data.
       *
       * @returns {boolean}
       */
      listLoading () {
        const listLoading = `${this.target}ListLoading`;
        return this[listLoading];
      },

      /**
       * Boolean that confirms if the setup of the page is valid for use of
       * the salary form.
       *
       * @returns {boolean}
       */
      isValidForSalary () {
        return !window._.isNil(this.$route.query)
          && !window._.isNil(this.$route.query.translator_id);
      }

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapMutations({

        // Invoice
        invoiceSetTargetBooking: 'financialAdditionalInvoice/setTargetBooking',
        invoiceResetForm: 'financialAdditionalInvoice/resetCompose',

        // Salary
        salarySetTargetBooking: 'financialAdditionalSalary/setTargetBooking',
        salaryResetForm: 'financialAdditionalSalary/resetCompose'
      }),

      ...mapActions({

        // Invoice
        invoiceLoadList: 'financialAdditionalInvoice/browse',
        invoiceReadItem: 'financialAdditionalInvoice/read',
        invoiceEditItem: 'financialAdditionalInvoice/edit',
        invoiceAddItem: 'financialAdditionalInvoice/add',
        invoiceDeleteItem: 'financialAdditionalInvoice/delete',

        // Salary
        salaryLoadList: 'financialAdditionalSalary/browse',
        salaryReadItem: 'financialAdditionalSalary/read',
        salaryEditItem: 'financialAdditionalSalary/edit',
        salaryAddItem: 'financialAdditionalSalary/add',
        salaryDeleteItem: 'financialAdditionalSalary/delete'
      }),

      ...mapActions({
        // Invoice
        invoiceLoadPreview: 'financialPreviewInvoice/browse',

        // Salary
        salaryLoadPreview: 'financialPreviewSalary/browse',
      }),

      /**
       * Method to set the target booking on the Vuex state level for
       * the use of READ, ADD, EDIT, and DELETE actions (see actions.js).
       *
       * @param {string|int} bookingId - Booking ID
       * @return {void}
       */
      setTargetBooking (bookingId) {
        let action = `${this.target}SetTargetBooking`;
        this[action](bookingId);
      },

      /**
       * Method for loading the list data according to this.target.
       *
       * @return {void}
       */
      loadList () {
        let action = `${this.target}LoadList`;
        this[action]();
      },

      /**
       * Handler when the Add button was clicked.
       *
       * @return {void}
       */
      handleClickAdd () {
        this.showComposeModal = true;
        this.editMode = false;
      },

      /**
       * Method to handle the manner of submitting the ADD data to the API.
       *
       * @return {void}
       */
      handleAddSubmit () {
        if (this.target === 'salary' && !this.isValidForSalary) {
          this.$notify.error({
            title: 'Error',
            message: 'Missing Translator ID value.'
          });
          return;
        }

        const action = `${this.target}AddItem`;
        const resetForm = `${this.target}ResetForm`;
        this[action]().then(() => {
          this[resetForm]();
          this.loadPreview();
          // this.showComposeModal = false;
        });
      },

      /**
       * Handler when the Edit button was clicked.
       *
       * @param {object} o - contains object of necessary values.
       * @return {void}
       */
      handleClickEdit (o) {
        this.showComposeModal = true;
        this.editMode = true;

        this.editId = o.row.id;
        this[`${this.target}ReadItem`]({id: o.row.id});
      },

      /**
       * Method to handle the manner of submitting the EDIT data to the API.
       *
       * @return {void}
       */
      handleEditSubmit () {
        if (this.target === 'salary' && !this.isValidForSalary) {
          this.$notify.error({
            title: 'Error',
            message: 'Missing Translator ID value.'
          });
          return;
        }

        const id = this.editId;
        const action = `${this.target}EditItem`;
        const resetForm = `${this.target}ResetForm`;

        this[action]({id}).then(() => {
          this[resetForm]();
          this.loadPreview();
          // this.showComposeModal = false;
        });
      },

      /**
       * Handler when the Reset button was clicked during Edit mode.
       * @return {void}
       */
      handleReset () {
        const resetForm = `${this.target}ResetForm`;
        this[resetForm]();
      },

      /**
       * Handler when the Delete button was clicked
       *
       * @param {object} o - Target List Data
       * @return {void}
       */
      handleClickDelete (o) {
        this.$confirm('Are you sure you want to delete this?', {
          confirmButtonText: 'Yes', cancelButtonText: 'No', type: 'warning'

        }).then(() => {
          this.handleDeleteSubmit(o);

        }).catch(() => {});
      },

      /**
       * Method to handle the manner of submitting the DELETE action to the API.
       *
       * @param {object} o - Target List Data
       * @return {void}
       */
      handleDeleteSubmit (o) {
        const action = `${this.target}DeleteItem`;
        this[action]({id: o.row.id}).then(() => {
          this.loadPreview();
        });
      },

      /**
       * Helper method for handling the changes in pagination.
       */
      handleChangePage (o) {
        const n = o.n; // Set the chosen page number.

        const opts = {
          action: `${this.target}LoadList`,
          data: `}List`,
          ref: `Financial Additional (${this.target}) Listing`
        };

        const params = _.clone(this.$route.query); // Reflect the page number to URL.
        this.reloadList(n, opts, params); // This method is a Global Mixin.
      },

      /**
       * Method to load the preview display data depending to the target.
       *
       * @return {void}
       */
      loadPreview () {
        const action = `${this.target}LoadPreview`;
        this[action]();
      }

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {

      // Set the Target Booking ID via Vuex, so that
      // it will be used in the backgroud by Vuex actions.
      this.setTargetBooking(this.bookingId);

      // Load the lists.
      this.loadList();

      // Apply the translator_id value in salaryForm if it exists.
      if (this.target === 'salary' && this.isValidForSalary) {
        this.salaryForm.translator_id = parseInt(this.$route.query.translator_id);
      }

    } // End of Component > mounted

  } // End of export default
</script>
