/*
|--------------------------------------------------------------------------
| Store > Sector > Town
|--------------------------------------------------------------------------
|
| A file that manages all the properties and abilities in relation
| to Town Model and other related values.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {extendObj, mapEndpoint} from '~/js/helpers/Common';
import bread from '../bread';
import state from './state';
import {Notification} from 'element-ui';
import i18n from '~/js/i18n';
import APICaller from '~/js/helpers/APICaller';
import {gep} from "../../helpers/Vuex";

let mutations = {};
let getters = {};
let actions = {
    addLogsGeneric (context, payload) {

        let method = context.state.api.addActivity.method;
        let endpoint = mapEndpoint(context.state.api.addActivity.path, payload);
        let params = {};
        let data = {};
        let isDataRaw = true;

        if (typeof payload.message == 'string') {
            params = {message:payload.message};
        }
    
        // Set the value for payload.data
        if (context.state.autoInjectPayload) {
            context.commit('setPayloadData');
            data = context.state.payloadData;
        } else {
            data = payload.data;
        }
    
        return new Promise((resolve, reject) => {
            APICaller({method, endpoint, params, data, isDataRaw})
                .then((r) => {
                resolve(r);
            })
            .catch((e) => {
                reject(e);
            });
        });
    },
    addLogs (context, payload) {
        return new Promise((resolve, reject) => {
            context.dispatch('addLogsGeneric', payload)
                .then((r) => {
                    if (context.state.autoUpdateList) {
                        context.dispatch('browse');
                        context.dispatch('browseAsOptions');
                    }
                    resolve(r);
                })
                .catch((e) => {
                    Notification.error({
                        title: i18n.t('error'),
                        message: i18n.t('n_went_wrong')
                    });
    
                    reject(e);
                });
        });
    },
    searchLogs (context, payload) {
        let method = 'post';
        let endpoint = mapEndpoint(gep('user-access-logs/search', window.app_api_prefix_3), payload);
        let data = {};
        let isDataRaw = true;
        let params = payload.params;
        params.include='admin,user';

        // Set the value for payload.data
        data = payload.data;

        context.commit('setListLoading', true);

        return new Promise((resolve, reject) => {
            APICaller({method, endpoint, params, data, isDataRaw})
                .then((r) => {
                    context.commit('setList', r.data);
                    resolve(r);
                })
                .catch((e) => {
                    reject(e);
                })
                .finally(() => {
                    context.commit('setListLoading', false);
                });
        });
    }
};

export default {
    namespaced: true,
    state: extendObj(bread.state, state),
    getters: extendObj(bread.getters, getters),
    mutations: extendObj(bread.mutations, mutations),
    actions: extendObj(bread.actions, actions),
} // End of export default
