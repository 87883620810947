/*
|--------------------------------------------------------------------------
| Store > translator-unavailable-times > getters
|--------------------------------------------------------------------------
|
| This file contains the getters property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';
import {isEmpty} from '~/js/helpers/Common'

export default {
  /**
   * Get the unavailable list state
   *
   *  * @param {object} state
   *  : current state of the vuex Object
   *
   * @return {array}
   */
  getUnavailables: (state) => state.unavailables,

  /**
   * get unvailble object state
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   * @return {object}
   */
  getUnavailable: (state) => state.unavailable,
  
  /**
   * get pagination object state
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   * @return {object}
   */
  getPagination: (state) => state.pagination,


}
