<template>
    <div class="app-list user-list">

        <custom-user-list
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="actionsColumn" slot-scope="scope" style="z-index: 10">
                <el-button class="circle" type="warning" :title="$t('login')" @click.stop="handleLoginAs(scope)">
                    <span class="fa fa-sign-in"></span>
                </el-button>
                <el-button class="circle" type="success" :title="$t('edit')" @click.stop="handleEdit(scope)">
                    <span class="fa fa-pencil"></span>
                </el-button>
                <el-button class="circle" :title="$t('duplicate')" @click.stop="handleDuplicate(scope)">
                    <span class="fa fa-clone"></span>
                </el-button>
                <el-button class="circle" type="danger" :title="$t('remove')" @click.stop="handleDelete(scope)">
                    <span class="fa fa-trash"></span>
                </el-button>
                <el-button class="circle" type="info" :title="$t('call')" @click.stop="handleClickCall(scope)">
                    <span class="fa fa-phone"></span>
                </el-button>
            </template>

        </custom-user-list>

        <!-- Pagination -->
        <el-pagination
                v-if="listPagination.total > listPagination.per_page"
                layout="prev, pager, next"
                :page-size="listPagination.per_page"
                :total="listPagination.total"
                :current-page="listPagination.current_page"
                @current-change="reloadLists">
        </el-pagination>

        <call-mobile :translator="user" :visible.sync="showCallMobile" :mobile="mobile"></call-mobile>

    </div> <!-- /.app-list user-list -->
</template>

<script>
  import {mapGetters, mapActions} from "vuex";
  import {sd} from "../../../js/helpers/Common";
  import CallMobile from '../../modals/translator/CallMobile';

  export default {
      components:{
          CallMobile
      },
    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {}, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      const _this =  this;
      return {

        listCols: [
          {
            label: 'ID',
            prop: 'id',
            width: '70px',
            className: 'id-col'
          },
          {
            label:  `${this.$t('name')}`,
            prop: 'name',
            width: '',
            className: 'name-col'
          },
          // {
          //     label: 'Email',
          //     prop: 'email',
          //     width: '',
          //     className: 'email-col',
          // },
           {
                        label: `${this.$t('notes')}`,
                        prop: 'notes',
                        width: '',
                        className: 'notes-col',
          },
          {
            label: `${this.$t('mobile')}`,
            prop: 'mobile',
            width: '',
            className: 'mobile-col'
          },
          {
            label: `${this.$t('user_type')}`,
            prop: 'type',
            width: '',
            className: 'user-type-col',
            mutator (v) {
              switch (v) {
                case 1:
                  return`${_this.$t('admin')}`;
                case 2:
                  return `${_this.$t('tfv_customer')}`;
                case 3:
                  return `${_this.$t('tfv_translator')}`;
              } // End of switch
            } // End of mutator(v) method
          }
        ], // End of listCols

        populatePayload: {
          user_type: 'users'
        },
        showCallMobile: false,
        mobile: '',
        user: {}

      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters('user', ['listData', 'listPagination'])

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions('user', {
        browse: 'browse',
        deleteEntry: 'delete'
      }),
      ...mapActions('auth', {
        loginAs: 'loginAs'
      }),

      reloadLists (n) {
        const opts = {
          action: 'browse',
          data: 'listData',
          ref: 'User Listing'
        };
        const params = _.clone(this.$route.query);
        this.reloadList(n, opts, params)
      },

      /**
       * Handler for edit event in the table.
       *
       * @param {object} scope
       *   The current scope(row) target.
       *
       * @return {void}
       */
      handleEdit (scope) {
        // LEGACY
        // window.location.href = '/#/user/edit/' + scope.row.id + '?type=' + scope.row.type;

        // Redirect to the edit page.
        const routeName = scope.row.type === 2
          ? 'user.edit.customer'
          : 'user.edit.translator';

        this.$router.push({
          name: routeName,
          params: {id: scope.row.id}
        });
      },

      handleProfile (scope) {
        console.log(scope.row)
        // Redirect to the profile page.
        // window.location.href = '/#/user/profile/' + scope.row.id;
        this.$router.push({name: 'user.profile', params: {id: scope.row.id}, query: {type: scope.row.type}})

      }, // End of handleprofile() method

      /**
       * Handler for delete event in the table.
       *
       * @param {object} scope
       *   The current scope(row) target.
       *
       * @return {void}
       */
      handleDelete (scope) {

        this.$confirm('This will permanently delete the entry. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.deleteEntry(scope.row).then(() =>{
            window.location.reload();
          });
        }).catch(() => {
          // Silence is golden.
        });

      }, // End of handleDelete() method

      handleLoginAs (scope) {
        this.loginAs({email: scope.row.email})
            .then((response) => {
              let params = `?access=${response.data.token}`;
              window.open(sd(process.env.MIX_APP_APP_CT_URL, 'https://ct.digitaltolk.se/') + 'login/as' + params, '_blank');
            })
            .catch(error => {
              console.log(error)
              this.$notify.error({
                title: 'Error',
                message: 'Unable to Login'
              });
            });
      },

    /**
     * Show calling mobile modal
     */
    handleClickCall (scope) {
        this.mobile = scope.row.mobile.replace(/[- )(,]/g,'')
        this.showCallMobile = true
        this.user = scope.row
    },
    handleDuplicate(scope){
      const routeName = scope.row.type === 2
        ? 'user.duplicate.customer'
        : 'user.duplicate.translator';

      this.$router.push({
        name: routeName,
        params: {id: scope.row.id}
      });
    }

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      const opts = {
        action: 'browse',
        data: 'listData',
        ref: 'User Listing'
      };
      const params = _.clone(this.$route.query);

      this.reloadList(null, opts, params);
    } // End of Component > mounted

  } // End of export default
</script>
