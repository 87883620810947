<template>
  <div>
    <el-form-item :label="$t('use_unified_number')" class="label-short" prop="number_setting_status">
      <el-select v-model="iValue.number_settings_status"
       :placeholder="$t('select')">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="$t(item.value)"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      v-if="iValue.number_settings_status != 'none'"
      :label="$t('settings_number')"
      class="label-short"
      prop="number_settings_number"
    >
      <el-input v-model="iValue.number_settings_number"></el-input>
    </el-form-item>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          number_settings_status: 'none',
          number_settings_number: ''
        };
      }
    }
  },
  computed: {
    /**
     * Interface for the v-model of this component.
     */
    iValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    }
  },
  data() {
    return {
      options: [
        {
          value: 'allowed'
        },
        {
          value: 'inherit'
        },
        {
          value: 'none'
        }
      ]
    };
  }
};
</script>
