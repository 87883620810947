<template>
  <el-dialog
    :title="$t('send_email_responder')"
    :visible.sync="iVisible"
    :modal-append-to-body="false"
    :lock-scroll="false"
    width="70%"
  >
    <table class="detail-list">
      <tr>
        <td class="cell-label">
          <span>{{$t('subject')}}</span>
        </td>
        <td>
          <el-input v-model="form.subject"></el-input>
        </td>
      </tr>

      <tr>
        <td class="cell-label">
          <span>{{$t('message')}}</span>
        </td>
      </tr>
    </table>
    <wysiwyg v-model="form.message" :aria-placeholder="$t('enter_text')" :placeholder="$t('enter_text')"/>

    <div class="text-center" style="margin-top:10px">
      <el-button class="modal-size" @click="handleClose" type="default">{{$t('cancel')}}</el-button>
      <el-button class="modal-size" type="primary" @click="handleSend">{{$t('submit')}}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { Notification } from "element-ui";
import i18n from "~/js/i18n";
import { setTimeout } from "timers";
export default {
  data() {
    return {
      form: {},
      showSelect: true
    };
  },
  props: {
    visible: {
      Type: Boolean,
      default: false
    },
    user: {
      Type: Object,
      default: {}
    }
  },
  computed: {
    iVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit("update:visible", v);
      }
    },
    getUserEmail() {
      return this.user[0].user.email != null ? this.user[0].user.email : "";
    }
  },
  methods: {
    handleClose: function() {
      this.$emit("update:visible", false);
    }, // End of handleClose() method
    /**
     * Building recipeints list for sending
     */
    handleSend() {
      if (this.form.subject && this.form.message) {
        this.sendToNotifier(this.getUserEmail);
      }
      this.handleClose();
    },

    /**
     * sending to notifier API
     */
    sendToNotifier(recipientEmail) {
      let data = {
        notification_type: "email",
        template_code: "send-survey-email",
        project_code: app_dt_notifier_project_code,
        data: {
          text: this.form.message,
          subject: this.form.subject
        },
        recipients: [recipientEmail]
      };
      let token = localStorage.getItem("app_access_token");
      // axios.post(`${app_dt_notifier_url}/api/v1/notifications`,data)
      axios.post(`${app_dt_notifier_url}/api/v3/notifications`,data,{
        headers:{
          'Authorization': 'Bearer '+ token
        }
      })
        .then(resp => {
          Notification.success({
            title: i18n.t("success"),
            message: "send email notification requested"
          });
          this.form.phone = [];
          this.form.text = "";
        })
        .catch(err => {
          Notification.error({
            title: i18n.t("error"),
            message: i18n.t("n_went_wrong")
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.cell-label {
  width: 122px;
}

.el-select {
  width: 50%;
}
</style>
