<template>
 <div class="app-page bookings-issue-setting-page">

  <page-header :title="$t('booking_issue_settings')" />

  <div class="app-container">
    <div class="app-wrapper">
      <div class="app-row">
        <div class="app-col full-width">
          <div class="content-body" v-loading="list.loading">

            <booking-issue-setting-list></booking-issue-setting-list>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import {populate,forcePopulate} from "~/js/helpers/Vuex"
export default {
  data(){
    return {
      populatePayload:{}
    }
  },
  computed:{
    ...mapState('bookingIssueSetting',{list:'list'})
  },
  methods:{
    ...mapActions({
      'browse':'bookingIssueSetting/browse'
    })
  },
  mounted(){
  populate(this, 'browse', '', {force: true,params:this.populatePayload})
  }
}
</script>

<style>

</style>
