<template>
  <div class="app-page survey-manager-page">
    <page-header title="Survey Accumulate Individual Response" />

    <div class="app-container">
      <div class="app-wrapper">
        <el-row :gutter="20">
          <ElCol :span="16">
            <el-card class="box-card green">
              <div slot="header" class="clearfix">
                <span>{{listData[0].survey_question.title}}</span>
              </div>
              <div class="text item">
                <template>
                  <div v-for="(item, index) in listData">
                    <el-row style="backgorund: #f2f2f2" :gutter="10">
                      <ElCol :md="4">
                        <div class="grid-content bg-purple">
                          <h1>{{index+1}}</h1>
                        </div>
                      </ElCol>
                      <ElCol :md="16">
                          <div  class="grid-details bg-purple">
                          <p>
                            {{item.answer}}
                          </p>
                          </div>
                          <div class="grid-details bg-purple-light">
                            <small>{{item.user.name}} {{item.user_id}}</small>

                          </div>
                      </ElCol>
                    </el-row>
                  </div>
                </template>
              </div>
            </el-card>
          </ElCol>
        </el-row>
      </div>
      <!-- /.app-wrapper -->
    </div>
    <!-- /.app-container -->
  </div>
  <!-- /.app-page survey-manager-page -->
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      listData: []
    };
  },

  methods: {
    ...mapActions({
      getResponse: "surveyManager/getAccumulatedIndividualResponse"
    })
  },

  /**
   * Fetching data from API for listData
   */
  mounted() {
    let payload = {
      id: this.$route.params.id,
      question_id: this.$route.params.question
    };
    this.getResponse(payload).then(r => {
     this.listData = r
     console.log(this.listData)
    });
  }
};
</script>

<style lang="scss" scoped>
  //noinspection CssUnknownTarget
  @import '~/scss/global/_variables.scss';

.el-col {
  border-radius: 4px;
}
.el-card__header {
  background-color: $app-primary-color !important;
  color: white !important;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 20px;
  padding: 10px;
  text-align: center;
}
.grid-details {
  border-radius: 4px;
  min-height: 20px;
  padding: 10px;
  margin-top: 5px;
}
</style>
