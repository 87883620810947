/*
|--------------------------------------------------------------------------
| Financial > Price List > actions
|--------------------------------------------------------------------------
|
| Partial file for actions object related to the scope.
*/
import API from '~/js/constants/api';
import APICaller from '~/js/helpers/APICaller';
import isNil from 'lodash/isNil';
import router from '~/js/routes';
import {uploadRequestData} from "~/js/helpers/File";

/**
 * Browse Action Helper.
 *
 * @param {Object} context - the scope of the store.
 * @param {Object} payload - contains necessary values.
 * @param {Object} options - contains option values.
 * @param {Object} options.set_list_ref - Vuex mutation reference for setting
 *                                        the list data and pagination value.
 * @param {Object} options.set_list_loading_ref - Vuex mutation reference for
 *                                                setting the list loading value.
 * @returns {Promise<any> | Promise}
 */
function browseHelper (context, payload, options) {
  return new Promise((resolve, reject) => {

    const endpoint = API.FINANCIAL.PRICE_LISTS;
    const method = 'GET';
    let params = {};

    if (
      !isNil(payload.entity_type) && payload.entity_type !== ''
      && !isNil(payload.entity_id) && payload.entity_id !== ''
    ) {
      params['filter[entity_type]'] = payload.entity_type;
      params['filter[entity_id]'] = payload.entity_id;
    }

    if (!isNil(payload.page)) params.page = payload.page;

    context.commit(options.set_list_loading_ref, true);

    APICaller({endpoint, method, params})
      .then((r) => {
        context.commit(options.set_list_ref, r.data);
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      })
      .finally(() => {
        context.commit(options.set_list_loading_ref, false);
      });
  });
}

/**
 * Read Action Helper.
 *
 * @param {Object} context - the scope of the store.
 * @param {Object} payload - contains necessary values.
 * @param {Object} options - contains option values.
 * @param {Object} options.set_item_loading_ref - Vuex mutation reference for
 *                                                setting the item loading value.
 * @returns {Promise<any> | Promise}
 */
function readHelper (context, payload, options) {
  return new Promise((resolve, reject) => {
    // Make sure price_list_id exists.
    if (isNil(payload.id)) console.error('ID is should exist!');

    const endpoint = `${API.FINANCIAL.PRICE_LISTS}/${payload.id}?include=all`;
    const method = 'GET';

    context.commit(options.set_item_loading_ref, true);

    APICaller({endpoint, method})
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      })
      .finally(() => {
        context.commit(options.set_item_loading_ref, false);
      });
  });
}

export default {

  /**
   * Action to load a list of items from the API.
   *
   * @param {Object} context - the scope of the store.
   * @param {Object} payload - contains necessary values.
   * @returns {Promise<any> | Promise}
   */
  browse (context, payload) {
    return browseHelper(context, payload, {
      set_list_ref: 'setList',
      set_list_loading_ref: 'setListLoading',
    });
  },

  /**
   * Action to load a list of items from the API.
   *
   * @param {Object} context - the scope of the store.
   * @param {Object} payload - contains necessary values.
   * @returns {Promise<any> | Promise}
   */
  browseForImport (context, payload) {
    return browseHelper(context, payload, {
      set_list_ref: 'setListImport',
      set_list_loading_ref: 'setListImportLoading',
    });
  },

  /**
   * Action to load a certain record from the API.
   *
   * @param {Object} context - the scope of the store.
   * @param {Object} payload - contains necessary values.
   * @returns {Promise<any> | Promise}
   */
  read (context, payload) {
    return readHelper(context, payload, {
      set_item_loading_ref: 'setItemLoading',
    });
  },

  /**
   * @param {Object} context - the scope of the store.
   * @param {Object} payload - contains necessary values.
   * @returns {Promise<any> | Promise}
   */
  readTemplateItem (context, payload) {

    return new Promise((resolve, reject) => {

      if (isNil(payload.id)) console.error('ID is should exist!');

      const endpoint = `${API.FINANCIAL.PRICE_TEMPLATES}/${payload.id}?include=all`;
      const method = 'GET';

      context.commit('setTemplateLoading', true);

      APICaller({endpoint, method})
        .then((r) => {
          resolve(r);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          context.commit('setTemplateLoading', false);
        });
    });
  },

  /**
   * Action to load a certain price list item (for import) from the API.
   *
   * @param {Object} context - the scope of the store.
   * @param {Object} payload - contains necessary values.
   * @returns {Promise<any> | Promise}
   */
  readImportItem (context, payload) {
    return readHelper(context, payload, {
      set_item_loading_ref: 'setImportLoading',
    });
  },

  /**
   * Action to edit/update the price list item.
   *
   * @param {Object} context - the scope of the store.
   * @param {Object} payload - contains necessary values.
   * @returns {Promise<any> | Promise}
   */
  edit (context, payload) {
    context.dispatch('financialInvoice/setSettingsPayload', null, {root: true});
    context.dispatch('financialSalary/setSettingsPayload', null, {root: true});

    const invoiceSettings = context.rootGetters['financialInvoice/settingsPayload'];
    const salarySettings = context.rootGetters['financialSalary/settingsPayload'];

    let data = {
      name: payload.name,
      entity_id: payload.entity_id,
      entity_type: payload.entity_type,
      invoice_setting: invoiceSettings,
      salary_setting: salarySettings,
    };

    const file = new Blob(
      [JSON.stringify(data)],
      {type: "application/json"}
    );

    const endpoint = `${API.FINANCIAL.PRICE_LISTS}/${payload.id}`;
    const method = 'PUT';

    return new Promise((resolve, reject) => {

      return uploadRequestData(file).then((cloudReponse) => {
        APICaller({endpoint, method, data: {request_file: cloudReponse.cloud_details.key}, isDataRaw: true})
          .then((r) => {
            context.commit('financialInvoice/resetSettingsPayload', null, {root: true});
            context.commit('financialSalary/resetSettingsPayload', null, {root: true});
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
        });
    });
  },

  /**
   * Action to edit/update the price list item.
   *
   * @param {Object} context - the scope of the store.
   * @param {Object} payload - contains necessary values.
   * @returns {Promise<any> | Promise}
   */
  add (context, payload) {
    context.dispatch('financialInvoice/setSettingsPayload', null, {root: true});
    context.dispatch('financialSalary/setSettingsPayload', null, {root: true});

    const invoiceSettings = context.rootGetters['financialInvoice/settingsPayload'];
    const salarySettings = context.rootGetters['financialSalary/settingsPayload'];

    let data = {
      name: payload.name,
      entity_id: payload.entity_id,
      entity_type: payload.entity_type,
      invoice_setting: invoiceSettings,
      salary_setting: salarySettings,
    };

    const file = new Blob(
      [JSON.stringify(data)],
      {type: "application/json"}
    );

    const endpoint = API.FINANCIAL.PRICE_LISTS;
    const method = 'POST';

    return new Promise((resolve, reject) => {

      return uploadRequestData(file).then((cloudReponse) => {

        APICaller({endpoint, method, data: {request_file: cloudReponse.cloud_details.key}, isDataRaw: true})
          .then((r) => {
            context.commit('financialInvoice/resetSettingsPayload', null, {root: true});
            context.commit('financialSalary/resetSettingsPayload', null, {root: true});
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });

    });
  },

  /**
   * Action to remove a certain record from the API.
   *
   * @param {Object} context - the scope of the store.
   * @param {Object} payload - contains necessary values.
   * @returns {Promise<any> | Promise}
   */
  delete (context, payload) {
    return new Promise((resolve, reject) => {
      // Make sure price_list_id exists.
      if (isNil(payload.id)) console.error('Price List ID is should exist!');

      const endpoint = `${API.FINANCIAL.PRICE_LISTS}/${payload.id}`;
      const method = 'DELETE';

      APICaller({endpoint, method})
        .then((r) => {
          resolve(r);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  // ---------------
  // UI Actions
  // ---------------

  /**
   * @param context
   * @param payload
   * @returns {void}
   */
  uiReloadList (context, payload = {}) {
    const query = router.currentRoute.query;

    // Set value for payload.page.
    if (isNil(payload.page)) {
      if (!isNil(query) && !isNil(query.page)) {
        payload.page = query.page;

      } else {
        payload.page = 1;
      }
    }

    payload.entity_type = context.state.entitySelectorForm.entity_type || query.entity_type;
    payload.entity_id = context.state.entitySelectorForm.entity_id || query.entity_id;

    context.dispatch('browse', payload);
  },
}
