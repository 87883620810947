import { isEmpty } from "../../helpers/Common";

export default {
    setLogs: (state,payload) =>{
        state.list = payload
    },
    setEmailLogs: (state,payload) =>{
        state.emailLogs = payload
        
    },
    setFaxLogs: (state,payload) =>{
        state.faxLogs = payload
    },
    setSmsLogs: (state,payload) =>{
        state.smsLogs = payload
    },
    setPushLogs: (state,payload) =>{
        state.pushLogs = payload
    },
    setLoading:(state,payload) =>{
        state.loading = payload
    }
}