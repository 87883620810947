<template>
<el-dialog title="ranslator delayed Booking" 
        :modal-append-to-body="false" 
        :visible.sync="iVisible" :lock-scroll="false">

  <div class="app-row booking-warnings">
    <div class="app-col full-width">
      <!-- {{user.warnings}} -->
      <base-list-table 
				:table-data="getDelayedBookings" 
				:extra-cols="listCols" 
				:hasDetailsCol="false">
      </base-list-table>
    </div>
  </div>
</el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props:{
    visible:{
      type: Boolean,
      default:false
    }
  },
  data(){
    return {
      listCols:[
        {
          label: 'Booking Id',
          prop: 'booking_id',
          width:'',
          className:'id-col',
          mutator(v){
							return `<a href="/#/booking/${v}/details" target="_blank">${v}</a>`
						}
        },
        {
          label: 'Booking due',
          prop: 'booking.due',
          width:'',
          className:'id-col',
          // mutator(v){
					// 		return `<a href="/#/booking/${v}/details" target="_blank">${v}</a>`
					// 	}
        },
        {
          label: 'Customer Id',
          prop: 'booking.customer_id',
          width:'',
          className:'id-col',
          mutator(v){
							return `<a href="/#/user/profile/${v}?type=2" target="_blank">${v}</a>`
						}
        },
        {
          label: 'Delay (minutes)',
          prop: 'delay_time',
          width:'',
          className:'id-col',
          // mutator(v){
					// 		return `<a href="/#/user/profile/${v}?type=2" target="_blank">${v}</a>`
					// 	}
        },
        
      ]
    }
  },
  computed:{
    ...mapGetters('translator',{
      getDelayedBookings:'getDelayedBookings'
    }),
    iVisible: {
      get() {
          return this.visible
      },
      set(v) {
          this.$emit('update:visible', v);
      }
    },
  }
    
}
</script>