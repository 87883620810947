<template>
  <div class="sidebar-nav-user">
      <div class="user-details-title-container set-title">
        <div class="user-details">
          <div class="user-name">
            {{ name }}
          </div>
        </div>
      </div><!-- /.user-details-title-container -->
  </div> <!-- /.sidebar-nav-user -->
</template>

<script>

  export default {

    /*
    |--------------------------------------------------------------------------
    | component > props
    |--------------------------------------------------------------------------
    */
    props: {

      /**
       * Avatar Image
       *   This should be an image path or url.
       */
      avatar: {
        type: String,
        default: '/images/defaults/user-avatar-placeholder.png',
      },

      /**
       * Name
       *   This should be the user's full name.
       */
      name: {
        type: String,
        default: 'John Doe',
      },

      /**
       * Email
       *   This should be the user's unique email.
       */
      email: {
        type: String,
        default: 'johndoe@email.com',
      },

    }, // End of component > props

    /*
    |--------------------------------------------------------------------------
    | component > data
    |--------------------------------------------------------------------------
    */
    data() {
      return {

        navActive: '',
        // navActive: 'user_details',

        navItems: [
          {path: '/profile', label: 'profile', icon: 'fa fa-user'},
          {path: '/logout', label: 'logout', icon: 'fa fa-sign-out'},
        ],

      };
    }, // End of component > data

    /*
    |--------------------------------------------------------------------------
    | component > methods
    |--------------------------------------------------------------------------
    */
    methods: {}, // End of component > methods

  } // End of export default
</script>
