<template>
    <div class="app-form app-fields sector-batches-settings-bookings-fields">
        <!-- Distance -->
        <el-form-item v-if="phone == false" :id="genContainerId('distance',formElId)"
                      :label="$t('distance')" class="label-medium">
            <el-input  :id="genFieldId('distance',formElId)"
                      v-model="form.distance"/>
        </el-form-item>

        <!-- Feedback -->
        <el-form-item  :id="genContainerId('feedback',formElId)"
                      :label="$t('feedback')" class="label-medium">
            <el-input :id="genFieldId('feedback',formElId)"
                      v-model="form.feedback"/>
        </el-form-item>

        <!-- Customer Feedback -->
        <el-form-item :id="genContainerId('customer_feedback',formElId)"
                      :label="$t('customer_feedback')" class="label-medium">
            <el-input :id="genFieldId('customer_feedback',formElId)"
                      v-model="form.customer_feedback"/>
        </el-form-item>

        <!-- Late Cancellation -->
        <el-form-item  :id="genContainerId('late_cancellation',formElId)"
                      :label="$t('late_cancellation')" class="label-medium">
            <el-input :id="genFieldId('late_cancellation',formElId)"
                      v-model="form.late_cancellation"/>
        </el-form-item>

        <!-- Delays -->
          <el-form-item  :id="genContainerId('delays',formElId)"
                      :label="$t('delays')" class="label-medium">
            <el-input :id="genFieldId('Delay',formElId)"
                      v-model="form.delays"/>
        </el-form-item>

        <!-- Translator's Preferences -->
        <el-form-item  :id="genContainerId('translators_preferences',formElId)"
                      :label="$t('translators_preferences')" class="label-medium">
            <el-input :id="genFieldId('translators_preferences',formElId)"
                      v-model="form.translators_preferences"/>
        </el-form-item>
            <!-- KPI -->
          <el-form-item  :id="genContainerId('kpi',formElId)"
                      label="KPI" class="label-medium">
            <el-input :id="genFieldId('late_cancellation',formElId)"
                      v-model="form.kpi"/>
        </el-form-item>


        <!-- Translator's Level -->
    <template  v-if="form.translators_levels_mode ==='soft'" >
        <el-form-item :id="genContainerId('translators_level',formElId)"
                      :label="$t('translators_level')" class="label-medium">
            <el-input :id="genFieldId('translators_level',formElId)"
                      v-model="form.translators_levels"/>
        </el-form-item>
    </template>

        <!-- Translator's Level Mode -->

        <el-form-item :id="genContainerId('translator_lvls_mode',formElId)"
                      :label="$t('translator_level_mode')" class="label-medium">
            <el-radio-group :id="genFieldId('translator_lvls_mode',formElId)"
                            v-model="form.translators_levels_mode" tabindex="0">
                <el-radio label="hard">{{$t('hard')}}</el-radio>
                <el-radio label="soft">{{$t('soft')}}</el-radio>
            </el-radio-group>
        </el-form-item>
    </div> <!-- /.app-form app-fields sector-batches-settings-bookings-fields -->
</template>

<script>
  import {mapGetters, mapActions} from "vuex";
    let formFresh = {
        distance: '',
        feedback: '',
        customer_feedback: '',
        late_cancellation: '',
        translators_preferences: '',
        translator_lvls_mode: '',
    };

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            formElId: {
                type: String,
                default: '',
            },

            form: {
                type: Object
            },

            phone: {
                default: false
            }

        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {

            };
        }, // End of Component > data
    } // End of export default
</script>
