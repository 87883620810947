<template>
  <div class="app-fields" :class="getBem(blockCls)">

    <div
      v-for="bookingType in BOOKING_TYPES"
      :class="getBem(blockCls, 'wrapper')"
    >
      <p>{{ $t(bookingType) }}</p>

      <el-form-item
        :class="getBem(blockCls, bookingType + ' rules-container')"
        :label="$t('rules')"
        class="label-block"
      >
        <el-radio-group
          :class="getBem(blockCls, bookingType + ' rules-field')"
          v-model="iValue.late_cancellation_rules[bookingType].rule"
          :aria-label="$t('rules')"
          tabindex="0"
        >
          <el-radio label="hours_before">
            {{$t('via_hours_before')}}
          </el-radio>
          <el-radio label="time_of_day">
            {{$t('via_time_of_day')}}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        v-if="isRuleHoursBefore(iValue.late_cancellation_rules[bookingType].rule)"
        :class="getBem(blockCls, bookingType + ' hours-container')"
        class="label-fluid"
        :label="$t('hours')"
      >
        <el-input
          :class="getBem(blockCls, bookingType + ' hours-field')"
          v-model="iValue.late_cancellation_rules[bookingType].data.hours"
        />
      </el-form-item>

      <template v-if="isRuleTimeOfDay(iValue.late_cancellation_rules[bookingType].rule)">
        <el-form-item
          :class="getBem(blockCls, bookingType + ' offset-days-container')"
          class="label-fluid"
          :label="$t('offset_days')"
        >
          <el-input
            :class="getBem(blockCls, bookingType + ' offset-days-field')"
            v-model="iValue.late_cancellation_rules[bookingType].data.offset_days"
          />
        </el-form-item>

        <el-form-item
          :class="getBem(blockCls, bookingType + ' time-container')"
          class="label-fluid"
          :label="$t('time')"
        >
          <el-time-picker
            :class="getBem(blockCls, bookingType + ' time-field')"
            v-model="iValue.late_cancellation_rules[bookingType].data.time"
            :picker-options="{format: 'HH:mm'}"
            value-format="HH:mm:ss"
          />
        </el-form-item>
      </template>

      <el-form-item
        :class="getBem(blockCls, bookingType + ' exclude-weeked-container')"
        class="label-fluid"
        :label="$t('exclude_weekend')"
      >
        <el-switch
          :class="getBem(blockCls, bookingType + ' exclude-weeked-field')"
          v-model="iValue.late_cancellation_rules[bookingType].exclude_weekend"
        />
      </el-form-item>

      <el-form-item
        :class="getBem(blockCls, bookingType + ' exclude-holiday-container')"
        class="label-fluid"
        :label="$t('exclude_holiday')"
      >
        <el-switch
          :class="getBem(blockCls, bookingType + ' exclude-holiday-field')"
          v-model="iValue.late_cancellation_rules[bookingType].exclude_holiday"
        />
      </el-form-item>
    </div>

    <el-form-item
      :class="getBem(blockCls, 'pay-late-cancellation-travel-time-container')"
      class="label-fluid"
      :label="$t('pay_late_cancellation_travel_time')"
    >
      <el-switch
        :class="getBem(blockCls, 'pay-late-cancellation-travel-time-field')"
        v-model="iValue.pay_late_cancellation_travel_time"
      />
    </el-form-item>

    <el-form-item
      :class="getBem(blockCls, 'late-cancellation-actual-travel-time-only-container')"
      class="label-fluid"
      :label="$t('late_cancellation_actual_travel_time_only')"
    >
      <el-switch
        :class="getBem(blockCls, 'late-cancellation-actual-travel-time-only-field')"
        v-model="iValue.late_cancellation_actual_travel_only"
      />
    </el-form-item>

  </div>
</template>

<script>
  import {BOOKING_TYPES} from '~/js/store/financial/settings/shared/props/constants';

  export default {


    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      blockCls: {
        type: String,
        default: 'financial-late-cancellations-fields',
      },

      value: {
        type: Object,
        default () {
          return {};
        },
      }, // End of value

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        BOOKING_TYPES,
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        },
      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * @param {string} rule
       * @returns {boolean}
       */
      isRuleHoursBefore (rule) {
        return rule === 'hours_before';
      },

      /**
       * @param {string} rule
       * @returns {boolean}
       */
      isRuleTimeOfDay (rule) {
        return rule === 'time_of_day';
      },
    },

  } // End of export default
</script>

<style lang="scss">
  .financial-late-cancellations-fields {
    &__wrapper {
      border-bottom: 1px solid #eaeaea;

      &:last-child {
        border-bottom: 0;
      }

      .el-radio {
        display: block;
        margin-right: 0;
      }
    }

  }
</style>
