<template>
  <el-dialog
    title="Select Recipient"
    :visible.sync="iVisible"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="true"
    :lock-scroll="false"
  >
    <!--    el form start-->
    <el-form :model="form" status-icon :rules="rules" ref="ruleForm">
      <el-form-item :label="$t('name')" class="label-block">
        <el-select
          v-model="form.user"
          filterable
          placeholder="Search user"
          remote
          ref="customer"
          :remote-method="handleRecipientSearch"
          :loading="searchCustomerLoading"
          @change="addRecipient"
          clearable
        >
          <el-option v-for="i in listData" :key="i.id" :label="i.name" :value="i"></el-option>
        </el-select>
      </el-form-item>
      <div class="text-center"><h4>OR</h4></div>
      <el-form-item :label="$t('Number')" class="label-block" prop="to_recipient_number">
        <el-input placeholder="Input number in +46-format" v-model="form.to_recipient_number"></el-input>
      </el-form-item>

      <div class="text-center">
        <el-button type="primary" @click="createSMS">Select Recipient</el-button>
      </div>
    </el-form>
    <!--    el form ends-->

  </el-dialog>
</template>

<script>

  import {mapActions, mapGetters, mapMutations} from 'vuex';
  import {isEmpty} from '~/js/helpers/Common';
  import {populate} from "~/js/helpers/Vuex";

  export default {
    name: 'RecipientSelectModal',
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },

    /**
     * Data
     */
    data() {
      /* var validateNumber  */
      var validateNumber = (rule, value, callback) => {

        if (value === '') {
          callback(new Error('Please input number'));
        } else if (value.substring(0, 3) !== '+46') {
          console.log(value.substring(0, 2));
          callback(new Error('Please input number in correct format. +467xxxxx'));
        } else {
          callback();
        }
      };
      return {
        form: {
          to_recipient_number: ''
        },
        searchCustomerLoading: false,
        searchResult: [],

        rules: {
          to_recipient_number: [
            {validator: validateNumber, trigger: 'blur'}
          ]
        }
      }
    },
    /**
     * Computed Properties
     */
    computed: {
      /**
       * Interface for this.visible
       */
      iVisible: {
        get() {
          return this.visible
        },
        set(v) {
          this.$emit('update:visible', v);
        },
      },
      /**
       * Map Getters of Vuex
       *
       */
      ...mapGetters('user', {
        listData: 'listData',
      })
    },


    /**
     * Methods
     */
    methods: {
      ...mapActions('user', {
        findUser: 'browseRecipient'
      }),

      ...mapActions('smsCentre', {
        createSMSAction: 'createSMS',
        getSpecificConversation: 'getSpecificConversation'
      }),

      ...mapMutations('smsCentre', {
        setSelectedRow: 'setSelectedRow'
      }),
      /**
       *
       * Search User
       */
      handleRecipientSearch(query) {
        if (query !== '' && query.length > 2) {
          let param = {};
          param['filter[name]'] = query;
          this.loadData(param);
        }
      },
      /**
       * Add Recipient
       */
      addRecipient(i) {
        this.form.to_recipient_number = i.mobile;
        this.form.to_recipient = i.id
      },

      /**
       * Create SMS
       */
      createSMS() {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            this.createSMSAction({
              to_recipient_number: this.form.to_recipient_number,
              to_recipient: this.form.to_recipient
            }).then((r) => {
              this.getSpecificConversation({id: r.id})
              this.setSelectedRow(r.id);
            });
            this.form = {};
            this.iVisible = false;
          }
        });


      },

      /**
       *
       * @param params
       */
      loadData(params) {
        populate(this, 'findUser', '', {
          params
        });
      }
    }
  }
</script>
