/*
|--------------------------------------------------------------------------
| Store > faq > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import API from '~/js/constants/api';
const endpoint = API.EXPENSE_TYPES;
const endpointWithId = API.EXPENSE_TYPES + '/{id}';

export default {
  api: {
    browse: { method: 'get', path: endpoint, params: { per_page: 10 }},
    read: { method: 'get', path: endpointWithId, params: {}},
    edit: { method: 'put', path: endpointWithId, params: {}, data: {}, },
    add: { method: 'post', path: endpoint, params: {}, data: {}, },
    delete: { method: 'delete', path: endpointWithId, params: {}, data: {}, }
  },

  payloadData: {}, // End of payloadData state

  autoInjectPayload: true,
  autoReflectItem: true,
  autoUpdateList: true
}
