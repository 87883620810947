var fullToolbar = [
    [{ font: [] }],
  
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
  
    [{ size: ["small", false, "large", "huge"] }],
  
    ["bold", "italic", "underline", "strike"],
  
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" }
    ],
  
    [{ header: 1 }, { header: 2 }],
  
    ["blockquote", "code-block"],
  
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  
    [{ script: "sub" }, { script: "super" }],
  
    [{ indent: "-1" }, { indent: "+1" }],
  
    [{ color: [] }, { background: [] }],
  
    ["link", "image", "video", "formula"],
  
    [{ direction: "rtl" }],
    ["clean"]
  ];
  
  export default fullToolbar;
  