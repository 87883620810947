<template>
    <el-form :model="form" :id="formElId" :ref="formElId">

        <div class="app-form admin-permissions-roles-list-settings-form">
            <el-form-item :id="genContainerId('page_name', formElId)"
                            class="label-short"
                            label="Page Name">
                <!-- <el-input :id="genFieldId('page_name', formElId)"
                    v-model="form.page_name" /> -->

                <el-select v-model="form.page_name">
                            <el-option v-for="(v, k) in this.getNavItems()"
                                    :key="k"
                                    :value="v.label"
                                    :label="v.label"/>
                </el-select>
            </el-form-item>

            <el-form-item :id="genContainerId('paths', formElId)"
                            class="label-short"
                            label="Role Group">
                <el-select v-model="form.role_id">
                            <el-option v-for="(v, k) in roles"
                                    :key="k"
                                    :value="v.id"
                                    :label="v.label"/>
                </el-select>
            </el-form-item>

            <el-form-item :id="genContainerId('paths', formElId)"
                            class="label-short"
                            label="Paths">
                <el-select v-model="selected_paths" placeholder="Select Path(s)" multiple >
                  <el-option v-for="(path, k) in paths" :key="k" :label="path" :value="path"></el-option>
                </el-select>
            </el-form-item>
            
            <!-- <div class="app-splitter two-cols"> -->
                <el-form-item :id="genContainerId('can_view', formElId)"
                            class="label-short"
                            label="View">
                    <el-switch :id="genFieldId('can_view', formElId)"
                    v-model="form.can_view"></el-switch>
                </el-form-item>
                <!-- <el-form-item :id="genContainerId('can_update', formElId)"
                            class="label-short"
                            label="Update">
                    <el-switch :id="genFieldId('can_update', formElId)"
                    v-model="form.can_update"></el-switch>
                </el-form-item> -->

                <el-form-item :id="genContainerId('save_btn',formElId)">
                    <el-button :id="genFieldId('save_btn',formElId)" type="primary" @click="handleSave">
                        {{mode}}
                    </el-button>
                </el-form-item>
            <!-- </div> -->
        </div>

    </el-form>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import {genFieldId, genContainerId} from "../../../js/helpers/Html";
    import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
    import { checkBreadMode } from "~/js/helpers/Route";
    import { populate } from '../../../js/helpers/Vuex';

    export default {

        computed: {

            ...mapState('adminPermissions', {
                form: 'compose',
                payloadData: 'payloadData',
                navData: 'navData'
            }),

            ...mapState('auth', {
                user: 'userData'
            }),

            ...mapGetters('adminPermissions', {
                entryData: 'itemData',
            }),

            ...mapGetters('adminPermissionsRoles', {
                roles: 'listData',
            }),

            breadMode() {
                return checkBreadMode(this);
            },

            mode() {
                return (this.breadMode == 'edit') ? 'Edit' : 'Save';
            }
        },

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                formElId: 'admin-permissions-form',
                paths: [],
                selected_paths: [],
                preloaded_paths: []
            };
        },// End of Component > data

        components: {
            Multiselect
        },

        methods: {

            ...mapActions({
                addEntry: 'adminPermissions/add',
                editEntry: 'adminPermissions/edit',
                readEntry: 'adminPermissions/read',
                getRoles: 'adminPermissionsRoles/browse',
                deletePaths: 'adminPermissions/deletePaths',
                getUserData: 'auth/getUserData',
            }),

            ...mapMutations('adminPermissions', {
                resetForm: 'resetCompose',
            }),

            handleSave() {
                let payload = {};
                if (this.breadMode == 'edit') {
                    payload.id = this.$route.params.id;
                    this.onlyAddNewPaths(this.selected_paths).forEach(p => {
                        this.form.paths.push({path:p});
                    });
                    
                    let ids = this.onlyAddDeletedIds(this.selected_paths);
                    this.deletePaths({
                        id: this.$route.params.id,
                        data: {
                            path_ids: ids
                        }
                    }).then((r) => {
                        this.editEntry(payload).then((r) => {
                            this.getUserData();
                            this.readEntry({
                                id: this.$route.params.id,
                                admin_id: this.user.id
                            }).then(r => {
                                this.form.paths = [];
                                this.selected_paths = [];
                                this.preloaded_paths = r.data.data.admin_permission.paths;
                                this.preloaded_paths.forEach(p => {
                                    this.selected_paths.push(p.path);
                                });
                                this.form.can_view = (this.form.can_view == 1) ? true : false;
                                this.form.can_create = (this.form.can_create == 1) ? true : false;
                                this.form.can_update = (this.form.can_update == 1) ? true : false;

                                this.$store.dispatch('adminPermissions/getNavItemsFinal');
                            });
                        });
                    });
                } else {
                    payload.admin_id = this.user.id;
                    this.selected_paths.forEach(p => {
                        this.form.paths.push({path:p});
                    });
                    this.addEntry(payload).then((r) => {
                        this.resetForm();
                        this.getUserData();
                        this.selected_paths = [];
                    });
                }
            },

            onlyAddNewPaths(cmpPaths) {
                let finalPaths = [];
                for (let cmp of cmpPaths) {
                    if (!this.preloaded_paths.find(p => p.path == cmp)) {
                        finalPaths.push(cmp);
                    }
                }
                
                return finalPaths;
            },

            onlyAddDeletedIds(cmpPaths) {
                let finalIds = [];
                let preloaded_paths_converted = JSON.parse(JSON.stringify(this.preloaded_paths));
                for (let pcmp of preloaded_paths_converted) {
                    let hit = false;
                    for (let cmp of cmpPaths) {
                        if (pcmp.path == cmp) {
                            hit = true;
                        }
                    }
                    if (!hit) {
                        finalIds.push(pcmp.id);
                    }
                }
                
                return finalIds;
            },

            getNavItems() {
                let items = this.navData.navItems;
                return items.filter(nav => {
                    return !nav.path.includes('dashboard');
                });
            }
        },

        mounted() {
            this.resetForm();
            if (this.breadMode == 'edit') {
                this.readEntry({
                    id: this.$route.params.id,
                    admin_id: this.user.id
                }).then(r => {
                    this.form.paths = [];
                    this.preloaded_paths = r.data.data.admin_permission.paths;
                    this.preloaded_paths.forEach(p => {
                        this.selected_paths.push(p.path);
                    });
                    this.form.can_view = (this.form.can_view == 1) ? true : false;
                    this.form.can_create = (this.form.can_create == 1) ? true : false;
                    this.form.can_update = (this.form.can_update == 1) ? true : false;
                });
            }

            this.$router.options.routes.forEach(route => {
                if ((!route.path.includes('dashboard')) && (route.path != '/') && (route.path != '*')) {
                    this.paths.push(route.path);
                }
            });
            this.paths.sort();

            populate(this, 'getRoles', 'roles', {
                force: true
            });
        }

    }// End of export default

</script>