<template>
   <div :class="`app-row ${iRefs}`" :id="iRefs" >
     <div class="app-col full-width">
    <h3>{{ $t(iTitle)}}</h3>

    <div class="app-list booking-list">
        <base-list-table
                :table-data="filesData"
                :extra-cols="listCols"
                :hasDetailsCol="false"
                actions-col-width='130'
                :row-class="tableRowClassName"
                >
            <template slot="topControls">
              <div class="text-right">
                <!-- <span class="text-right">Total Records : {{listPagination.total}}</span> -->
              </div>
            </template>
            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="success" title="Download" @click="downloadFile(scope)" size="mini" icon="el-icon-download">
                </el-button>
                <el-button class="circle" type="danger" title="Delete" @click="filesDelete(scope)" size="mini" icon="el-icon-delete">
                </el-button>

            </template>
        </base-list-table>

        <!-- <el-pagination
                layout="prev, pager, next"
                :page-size="listPagination.per_page"
                :total="listPagination.total"
                :current-page="listPagination.current_page"
                @current-change="reloadList">
        </el-pagination> -->

    </div> <!-- /.app-list booking-list -->
    </div>
    </div>
</template>
<script>

import {storeFile,download} from "~/js/helpers/File";
import API from '~/js/constants/api';
import {mapGetters,mapActions} from 'vuex'
import APICaller from '~/js/helpers/APICaller';
import { gep } from "~/js/helpers/Vuex";
import {isEmpty,sd} from "~/js/helpers/Common";
export default {
  name:'userFilesListPanel',
  props:{
    // bookings:{
    //   type: Array,
    //   default:[]
    // },
    title:{
      type:String,
      default: 'files'
    },
    updateFile: {
      type: Boolean,
      default: false,
    },
    refs:{
      default:'Files'
    }
  },
   watch: {
      	updateFile: function(newVal, oldVal) { // watch it
         this.getFiles();
        }
      },
  mounted () {
     this.getFiles();
  },
  data(){

    const _this = this;
    return {
      token: '',
      filesData: [{
      }],
      listCols: [{
          prop: 'id',
          label: _this.$t('id'),
          width: '',
        },
        {
          prop: 'display_name',
          label: _this.$t('display_name'),
          width: '',
        },
        {
          prop: 'name',
          label: _this.$t('name'),
          width: '',
        },
        {
          prop: 'type',
          label: _this.$t('type'),
          width: '',
        },
      ],
      tableRowClassName:[
        {
            field: 'flagged',
            value: true,
            class: 'is_flagged'
        },
        {
            field: 'is_test',
            value: true,
            class: 'is_test'
        }
      ]
    }
  },
  computed:{
    // iBookings:{
    //   get(){
    //   return this.files
    //   },
    //   set(v){
    //     this.$emit('input',v)
    //   }
    // },
    iTitle(){
      return this.title
    },
    iRefs(){
      return this.refs
    },
    ...mapGetters('language', {
        languageOpts: 'getLanguages'
    }),
  },
  methods:{
    // ...mapActions('textTranslation',{
    //   downloadFile:'downloadFile'
    // }),
    downloadFile(scope) {

      download(scope.row)
      // this.downloadFile(payload)
    },
    handleDetails(scope) {

      this.$router.push({
        name: 'booking-details',
        params: {
          id: scope.row.id
        }
      });

    },
    filesDelete(scope) {
      // console.log(scope.row);
      APICaller({
                method: 'delete',
                endpoint: gep(`users/${scope.row.id}/files`, "v3"),
            }).then(r => {
            this.getFiles();
            });
    },
    getFiles() {
       let apiPrefix = 'v3';
      this.token = localStorage.getItem('app_access_token')
      const apiOpts = {
          endpoint: gep(`users/${this.$route.params.id}/files`, apiPrefix),
          method: 'get',
          headers: {
              Authorization: `bearer ${this.token}`
          },
          isDataRaw: true
        };

        APICaller(apiOpts)
          .then((res) => {
            this.filesData = res.data;
            // this.$notify.success({
            //   title: 'Success!',
            //   message: 'Files was assigned'
            // });
          })
          .catch((e) => {
            console.error(e);
            // this.$notify.error({title: 'Error', message: 'Please contact the administrator.'});
          });
    //   console.log(this.$route.params.id, 'this is my route');
    }
  }
}
</script>

<style>

</style>
