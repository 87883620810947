<template>
  <div class="list-base-table">

    <div class="list-base-table-top-controls" v-if="hasTopControlsSlot">
      <slot name="topControls"/>
    </div> <!-- .list-base-table-top-controls -->

    <div class="list-base-table-wrapper">
      <!-- Base List Table -->
      <el-table :data="tableData" style="width: 100%" v-loading="loading"
                :row-class-name="checkRowClass"  @row-click.self="handleMap">
        <el-table-column 
          v-for="col in cols"
          :key="crProps(col, 'prop')"
          :prop="crProps(col, 'prop')"
          :label="crProps(col, 'label')"
          :width="crProps(col, 'width')"
          :class-name="'blt-col ' + crProps(col, 'className') + ' pointer'"
          :sortable="crProps(col, 'sortable')"
        >
          <template slot-scope="scope">
            <h4 class="cell-title">
              {{ crProps(col, 'label') }}
            </h4>
            <span class="cell-content" v-html="mok(scope.row, crProps(col, 'prop'), col.mutator)"></span>
          </template>
        </el-table-column>

        <!-- Actions Column -->
        <el-table-column class-name="actions-col" v-if="hasActionsColSlot && hasActionsCol"
                         :label="$t('actions')" :width="actionsColWidth">
          <template slot-scope="scope">
            <slot
              name="actionsColumn"
              :$index="scope.$index"
              :row="scope.row"/>
          </template>
        </el-table-column>

      </el-table>
      <!-- End of Base List Table -->
    </div> <!-- .list-base-table-wrapper -->


  </div> <!-- .list-base-table -->
</template>

<script>
  import {mok, crProps, isEmpty} from "../../../js/helpers/Common";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      tableData: {
        type: Array,
        default() {
          return [];
        }
      },
      extraCols: {
        type: Array,
        default() {
          return [];
        }
      },
      actionsColWidth: {
        type: String,
        default: '',
      },
      hasActionsCol: {
        type: Boolean,
        default: true,
      },
      loading: {
        type: Boolean,
        default: false
      },
      rowClass: {
        type: Array,
        default() {
          return [];
        }
      }

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data: function () {
      return {
        preloading: true,
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Define default columns (this should be array or objects).
       * Please refer to /src/assets/js/helpers/Common.js@crProps() for the
       * documentation of the expected values.
       *
       * @return {array}
       */
      cols: function () {

        let extra = this.extraCols;

        if (_.isEmpty(extra)) {
          extra = [
            {
              label: this.$t('id'),
              prop: 'id',
              width: '50px',
              className: 'id-col',
            },
          ];
        }

        return extra;

      }, // End of cols() method

      /**
       * Returns wether the topControls slot is defined.
       */
      hasTopControlsSlot() {
        return !!this.$slots['topControls'];
      },

      /**
       * Returns wether the actionsColumn scoped slot is defined.
       */
      hasActionsColSlot() {
        return !!this.$scopedSlots['actionsColumn'];
      },

      iRowClass() {
        return this.rowClass
      },
      empty(v){
        return isEmpty(v)
      }

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

        // Mapping of helpers
        crProps, mok,isEmpty,

        checkRowClass({row}) {
            if (!isEmpty(this.iRowClass)) {
                let className = '';
                _.each(this.iRowClass, (v) => {
                    if (row[v.field]) {
                        className = v.class
                    }
                })
                return className
            }
            return '';
        },

        handleMap(val) {
            window.open('http://www.google.com/maps/place/' + val.coordinates);
        },

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted: function () {

    }, // End of Component > mounted

  } // End of export default
</script>

<style>
    .pointer {
        cursor: pointer;
    }
</style>