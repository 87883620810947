<template>
    <div class="app-display dashboard-company-report-display">

       <!-- <div class="company-selector-container">
            <p>Select Company</p>
            <el-select :id="genFieldId('company_selector')"
                       v-model="company">
                <el-option label="All" value="all"/>
                <el-option label="Company" value="company"/>
            </el-select>
        </div> /.company-selector-container -->
        <div class="reports-container app-splitter two-cols">

            <div>
                <table cellpadding="0" cellspacing="0" v-if="this.tableData">
                    <tbody>
                    <tr v-for="value,key in tableData.slice(0,5)">
                        <td class="cell-label"><span class="cell">{{ value.municipality_name }}</span></td>
                        <td class="cell-value"><span class="cell">{{ value.count }}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <table cellpadding="0" cellspacing="0" v-if="this.tableData">
                    <tbody>
                    <tr v-for="value,key in tableData.slice(5,10)">
                        <td class="cell-label"><span class="cell">{{ value.municipality_name }}</span></td>
                        <td class="cell-value"><span class="cell">{{ value.count }}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div> <!-- /.reports-container app-splitter two-cols -->

    </div> <!-- /.app-display dashboard-company-report-display -->
</template>

<script>
    import {mapActions,mapGetters} from "vuex";
    import {populate} from "~/js/helpers/Vuex";
    export default {

        mounted(){
          let self = this;
          let token = localStorage.getItem("app_access_token");
          axios.get("https://api-gateway.digitaltolk.se/api/v3/bookings-statistics/municipality?type=bookings_municipality_normal",
      
          {
              headers:
          {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
          },
            }
            )
              .then(resp => {
                self.tableData  = resp.data;
              }).catch(err => {
                  console.log(err);       
              });
        },
        props : [],
        data() {
            return {
                window,
                tableData : null,
                company : null
            };
        },
        watch: {
          //
        },
        computed : {
          
        },
        methods : {
         
        }

    } // End of export default
</script>
