/*
|--------------------------------------------------------------------------
| Financial > Price List > mutations
|--------------------------------------------------------------------------
|
| Partial file for mutations object related to the scope.
*/

import isNil from 'lodash/isNil';

export default {

  /**
   * Set the state.list data and pagination.
   *
   * @param  {Object} state - state of the current Vuex Object
   * @param  {Object} payload - contains necessary values.
   * @returns {void}
   */
  setList: function (state, payload) {

    let firstKey = Object.keys(payload.data)[0];

    state.list.data = payload.data[firstKey];

    if (!isNil(payload.meta) && !isNil(payload.meta.pagination)) {
      state.list.pagination = payload.meta.pagination;
    }
  },


  /**
   * Set the state.list.loading;
   *
   * @param  {Object} state - state of the current Vuex Object
   * @param  {Boolean} payload - contains necessary values.
   * @returns {void}
   */
  setListLoading (state, payload) {
    state.list.loading = payload;
  },


  /**
   * Reset state.list to its pristine state.
   *
   * @param  {Object} state - state of the current Vuex Object
   * @returns {void}
   */
  resetList (state) {
    state.list.data = [];
    state.list.pagination = {};
  },

  /**
   * Set the state.listImport data and pagination.
   *
   * @param  {Object} state - state of the current Vuex Object
   * @param  {Object} payload - contains necessary values.
   * @returns {void}
   */
  setListImport: function (state, payload) {

    let firstKey = Object.keys(payload.data)[0];

    state.listImport.data = payload.data[firstKey];

    if (!isNil(payload.meta) && !isNil(payload.meta.pagination)) {
      state.listImport.pagination = payload.meta.pagination;
    }
  },


  /**
   * Set the state.list.loading;
   *
   * @param  {Object} state - state of the current Vuex Object
   * @param  {Boolean} payload - contains necessary values.
   * @returns {void}
   */
  setListImportLoading (state, payload) {
    state.listImport.loading = payload;
  },


  /**
   * Reset state.list to its pristine state.
   *
   * @param  {Object} state - state of the current Vuex Object
   * @returns {void}
   */
  resetListImport (state) {
    state.listImport.data = [];
    state.listImport.pagination = {};
  },

  /**
   * Set the state.item.loading.
   *
   * @param  {Object} state - state of the current Vuex Object
   * @param  {Boolean} payload - contains necessary values.
   * @returns {void}
   */
  setItemLoading (state, payload) {
    state.item.loading = payload;
  },

  /**
   * Set the state.templateItem.loading.
   *
   * @param  {Object} state - state of the current Vuex Object
   * @param  {Boolean} payload - contains necessary values.
   * @returns {void}
   */
  setTemplateLoading (state, payload) {
    state.templateItem.loading = payload;
  },

  /**
   * Set the state.importItem.loading.
   *
   * @param  {Object} state - state of the current Vuex Object
   * @param  {Boolean} payload - contains necessary values.
   * @returns {void}
   */
  setImportLoading (state, payload) {
    state.importItem.loading = payload;
  },

  /**
   * @param  {Object} state - state of the current Vuex Object
   * @returns {void}
   */
  resetForm (state) {
    state.form = {
      name: '',
      entity_id: 0,
      entity_type: '',
    };
  },

  /**
   * @param {object} state
   * @param {string} mode - varies between add | edit
   */
  setComposeMode (state, mode) {

    if (mode === 'add') {
      state.isComposeModeAdd = true;
      state.isComposeModeEdit = false;

    } else if (mode === 'edit') {
      state.isComposeModeEdit = true;
      state.isComposeModeAdd = false;
    }
  },

  /**
   * @param state
   */
  resetComposeMode (state) {
    state.isComposeModeAdd = false;
    state.isComposeModeEdit = false;
  },
}
