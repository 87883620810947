<template>
  <div>
    <el-dialog top="110" :title="$t('customer_type')" :visible.sync="dialogFormVisible" :modalAppendToBody="false">
      <el-form :model="form">
        <el-form-item :label="$t('type')">
          <el-input v-model="form.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('status')">
          <el-select v-model="form.status" :placeholder="$t('status')">
            <el-option :label="$t('enable')" value="Enable"></el-option>
            <el-option :label="$t('disable')" value="Disable"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelModal()">{{$t('cancel')}}</el-button>
        <el-button type="danger" v-if="editMode === true" @click="catDelete()">{{$t('delete')}}</el-button>
        <el-button type="primary" @click="save(form.name, form.status)">{{$t('confirm')}}</el-button>
      </span>
    </el-dialog>
    <el-form>
      <el-button type="primary" @click="dialogFormVisible = true">{{$t('create')}}</el-button>
      <el-table :data="customerTypes" style="width: 100%">
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column label="Name" prop="name"></el-table-column>
        <el-table-column label="Status" prop="enabled" :formatter="cellValueRenderer">
          <template slot-scope="scope">
        <el-tag
          :type="scope.row.enabled === true ? 'primary' : 'danger'"
          disable-transitions>{{scope.row.enabled === true ? 'Enable' : 'Disable'}}</el-tag>
      </template>
      </el-table-column>
      <el-table-column :label="$t('actions')">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">{{$t('update')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
  <el-pagination
    layout="total, sizes, prev, pager, next, jumper"
    :total="parseInt(pagination.total)"
    :page-size="parseInt(pagination.per_page)"
    :page-sizes= [10,25,50,100,200]
    :current-page="parseInt(pagination.current_page)"
    @size-change="handleSizeChange"
    @current-change="reloadList">
  </el-pagination>

    </div>
  </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import APICaller from "../../../js/helpers/APICaller";
import { gep } from "../../../js/helpers/Vuex";

let apiPrefix = "v3";

export default {
  mounted() {
    this.getCustomerTypes().then(response => {
      this.customerTypes = response.data.data.customer_types;
      this.pagination.total = response.data.meta.pagination.total;
      this.pagination.current_page = response.data.meta.pagination.current_page;
      this.pagination.total_pages = response.data.meta.pagination.total_pages;
      this.pagination.per_page = response.data.meta.pagination.per_page;
    });
  },
  data() {
    return {
      pagination: {
        per_page: null,
        current_page: null,
        total_pages: null
      },
      editMode: false,
      customerTypes: null,
      dialogFormVisible: false,
      form: {
        id: "",
        name: "",
        status: ""
      }
    };
  },
  methods: {
    ...mapActions({
      getCustomerTypes: "customerType/getCustomerTypes",
      updateCustomerTypes: "customerType/updateCustomerTypes",
      createCustomerTypes: "customerType/createCustomerTypes",
      deleteCustomerTypes: "customerType/deleteCustomerTypes",
      getCustomerTypesPerPage: "customerType/getCustomerTypesPerPage"
    }),
    // handleReload(curPage) {

    // },

    filterStaus(value, row) {
      return row.status === value;
    },

    reload() {
      this.getCustomerTypes().then(response => {
        this.customerTypes = response.data.data.customer_types;
      });
    },
    handleEdit(index, row) {
      this.dialogFormVisible = true;
      this.editMode = true;
      this.form = {
        id: row.id,
        name: row.name,
        status: row.enabled === true ? "Enable" : "Disable"
      };
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    cancelModal() {
      this.editMode = false;
      this.dialogFormVisible = false;
      this.form = {
        id: "",
        name: "",
        status: ""
      };
    },
    catDelete() {
      let self = this;
      this.deleteCustomerTypes(parseInt(self.form.id)).then(() => {
        this.cancelModal();
        this.reload();
      });
    },
    save(type, status) {
      if (this.editMode === true) {
        let payload = {
          id: this.form.id,
          name: type,
          enabled: status === "Enable" ? 1 : status === "Disable" ? 0 : null
        };
        this.updateCustomerTypes(payload).then(() => {
          this.cancelModal();
          this.reload();
        });
      } else {
        let payload = {
          name: type,
          enabled: status === "Enable" ? 1 : status === "Disable" ? 0 : null
        };
        this.createCustomerTypes(payload).then(() => {
          this.cancelModal();
          this.reload();
        });
      }
    },
    cellValueRenderer(row, column, cellValue, index) {
      let value = cellValue;
      if (typeof row[column.property] === "boolean") {
        if (cellValue == true) {
          value = "Enabled";
        } else {
          value = "Disabled";
        }
      }
      return value;
    },
    handleSizeChange(val) {
             this.reloadList(this.pagination.current_page, val);
    },
    reloadList(n, per_page) {
      if(!per_page){
        per_page = 10;
      }
      const params = {};
      params['n'] = n;
      params['per_page'] = per_page

      console.log(per_page);
      this.getCustomerTypesPerPage(params).then(response => {
        this.customerTypes = response.data.data.customer_types;
        this.pagination.total = parseInt(response.data.meta.pagination.total);
        this.pagination.current_page = parseInt(
          response.data.meta.pagination.current_page
        );
        this.pagination.total_pages = parseInt(
          response.data.meta.pagination.total_pages
        );
        this.pagination.per_page = parseInt(
          response.data.meta.pagination.per_page
        );
      });
    }
  }
};
</script>
