/*
|--------------------------------------------------------------------------
| Financial > shared > props > emergencyBooking
|--------------------------------------------------------------------------
*/

'use strict';

import constants from './constants';
import {genPropsFromCollection} from '~/js/helpers/Vuex';

export default {

  // pay_immediate_fee: false,
  // immediate_fees: genPropsFromCollection(
  //   constants.BOOKING_TYPES, {fee: 0}
  // ),

  pay_emergency_fee: false,
  emergency_minutes: 0,
  emergency_fees: genPropsFromCollection(
    constants.BOOKING_TYPES, {fee: 0}
  ),

  // Rush Booking Fee
  pay_rush_booking_fee: false,
  rush_booking_fees: genPropsFromCollection(
    constants.BOOKING_TYPES, {fee: 0, minutes: 0}
  ),

  different_type_immediate: false,
}
