/*
|--------------------------------------------------------------------------
| Store > Messaging-Centre > Blogs > Actions
|--------------------------------------------------------------------------
|
| This file contains the action of Auth Vuex Module
|
|
*/
import { Notification } from "element-ui";

import i18n from "../../../i18n";
import { isEmpty } from "../../../helpers/Common";
import APICaller from "../../../helpers/APICaller";
let apiPrefix = "v3";

import { gep } from "~/js/helpers/Vuex";

export default {
  getBlogs(context, payload) {
    let params = {};
    switch (payload.filter) {
      case 'second' : {
        params = {
          "filter[status]" : 2
        }
          break;
      }
      case 'third' : {
        params = {
          "filter[status]" : 1
        }
          break;
      }
    }
    const method = "get";
    const endpoint = gep("blog", apiPrefix);

    return APICaller({ method, endpoint, params })
      .then(r => {
        const data = r.data.data.items;
        context.commit("setBlogList", data);
      })
      .catch(e => {
        console.error(e);
      });
  },

  getSpecificBlog(context, payload) {
    const method = "get";
    const endpoint = gep(`blog/${payload}`, apiPrefix);

    return APICaller({ method, endpoint })
      .then(r => {
        const data = r.data.data.item;
        context.commit("setBlog", data);
        context.commit("setCompose");
      })
      .catch(e => {
        console.error(e);
      });
  },
  updateBlog(context, payload) {
    context.commit("setLoading", true);
    const blogId = payload.id;
    const apiOpts = {
      method: "PUT",
      endpoint: gep(`blog/${blogId}`, apiPrefix),
      data: payload,
      isDataRaw: true
    };

    return APICaller(apiOpts)
      .then(() => {
        Notification.success({
          title: i18n.t("sucesss"),
          message: "Blog Updated"
        });
      })
      .catch(e => {
        Notification.error({
          title: i18n.t("error"),
          message: e.response.data.data
        });
      })
      .finally(() => {
        context.commit("setLoading", false);
      });
  },

  createBlog(context, payload) {
    context.commit("setLoading", true);
    const blogId = payload.id;
    const apiOpts = {
      method: "POST",
      endpoint: gep(`blog`, apiPrefix),
      data: payload,
      isDataRaw: true
    };

    return APICaller(apiOpts)
      .then(() => {
        Notification.success({
          title: i18n.t("sucesss"),
          message: "Blog created"
        });
      })
      .catch(e => {
        Notification.error({
          title: i18n.t("error"),
          message: e.response.data.data
        });
      })
      .finally(() => {
        context.commit("setLoading", false);
      });
  },
  deleteBlog(context, payload) {
    const method = "delete";
    const endpoint = gep(`blog/${payload.id}`, apiPrefix);

    return APICaller({ method, endpoint })
      .then(r => {
        Notification.success({
          title: i18n.t("sucesss"),
          message: "Blog deleted"
        });
      })
      .catch(e => {
        console.error(e);
      });
  }
};
