import { gep } from "../../../../helpers/Vuex";

let apiPrefix = window.app_api_prefix_3;

let composeProps = {
  'booking_issue_id':'',
  'booking_issue_tag_id':'',
  'created_by': ''
};

export default {
  api:{
    browse: {method:'get',path:gep('booking-issue-tagged-issues',apiPrefix),params: {per_page: 25}},
    add:{method:'post',path:gep('booking-issue-tagged-issues',apiPrefix),params:{},data:{},},
    edit:{method:'put',path:gep('booking-issue-tagged-issues/{id}',apiPrefix),params:{},data:{},},
    read:{method:'get',path:gep('booking-issue-tagged-issues/{id}',apiPrefix),params:{},data:{},},
    delete:{method:'delete',path:gep('booking-issue-tagged-issues/{id}',apiPrefix),params:{},data:{},},
    getByBookingIssueID:{method:'get',path:gep('booking-issue-tagged-issues/{id}',apiPrefix),params:{},data:{},},
    getBookingIssueTagId:{method:'get',path:gep('booking-issue-tagged-issues/{id}',apiPrefix),params:{},data:{},},
    addIssueWithNewTag:{method:'post',path:gep('booking-issue-tagged-issues/{booking_issue}',apiPrefix),params:{},data:{},},
  },
  autoInjectPayload: true,
  autoReflectItem: true,
  autoUpdateList: true,

  composeFresh: _.cloneDeep(composeProps),
  compose: _.cloneDeep(composeProps),

  payloadData: {}, // End of payloadData state

}