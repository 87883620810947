import Vue from 'vue'
import VueI18n from 'vue-i18n'
const VERBIAGES = 'verbiages';
let en = require('./lang/en');
let se = require('./lang/se');
import enLocale from 'element-ui/lib/locale/lang/en'
import seLocale from 'element-ui/lib/locale/lang/sv-SE'
Vue.use(VueI18n);

let verbiage = null;
try {
    verbiage = JSON.parse(localStorage.getItem(VERBIAGES));
    if(_.isNil(verbiage)){
        verbiage = {
            en: en.default,
            se: se.default
        }
    }
} catch (e) {
    verbiage = {
        en: en.default,
        se: se.default
    }
}

export default new VueI18n({
    locale: window.app_locale, // set locale
    messages: {
        en: Object.assign(verbiage.en,enLocale),
        se: Object.assign(verbiage.se,seLocale)
    }, // set locale messages
});
