/*
|--------------------------------------------------------------------------
| Store > Invoice Reminder Settings
|--------------------------------------------------------------------------
|
| A Class file that manages all the properties and abilities in relation
| to Feedback.
|
*/

import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced:true,
  state,actions,mutations,getters
}