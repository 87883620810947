/*
|--------------------------------------------------------------------------
| Financial > Shared > methods > loadSettings
|--------------------------------------------------------------------------
| Partial file as a reusable method for it's purpose.
*/

'use strict';

import APICaller from "~/js/helpers/APICaller";
import pluralize from 'pluralize';

/**
 * Method to load the settings according to the given entity and entity id.
 *
 * @param context - the scope of the store.
 * @param payload - contains object of values that's usable inside the method.
 * @param payload.entity_type - the name of the target entity which varies between
 *                         municipality, company, department, and customer.
 * @param payload.entity_id - the id of the given entity.
 *
 * @return {Promise|boolean}
 */
export default function(context, payload) {

  // Make sure the necessary values are defined.
  const endpointKey = context.state.endpointKey;
  const entity_type = checkIfDefined(payload.entity_type, 'entity_type');
  const entity_id = checkIfDefined(payload.entity_id, 'entity_id');

  // If either of them is undefined, stop the process.
  if (!(endpointKey && entity_type && entity_id)) { return false; }

  const method = 'get';
  const endpoint = `/api/v3/${endpointKey}/settings/entity/${entity_type}/${entity_id}`;
  const params = {};
  const includeArr = [
    'booking_timings',
    'late_cancellation_rules',
    'charges',
    'parts_fees',
    'holiday_fees',
    'holiday_setting',
    'travel_setting',
    'travel_distance_fees',
    'travel_time_fees',
    'inconvenience_setting',
    'inconvenience_fees',
    'inconvenience_travel_time_fees',
    'weekend_setting',
    'weekend_fees',
    'expense_setting',
    'expense_types',
    'office_hours_fee',
    'emergency_setting',
    'booking_type_fees',
    'translator_level_fees',
    'emergency_fees',
    'transaction_fees',
    'rush_booking_fees',
    'immediate_fees',
  ];


  if (endpointKey === 'salaries') {
    const rem = [
      'office_hours_fee', 'emergency_setting', 'booking_type_fees', 'translator_level_fees',
      'emergency_fees', 'transaction_fees', 'rush_booking_fees', 'immediate_fees'
    ];
    _.remove(includeArr, (x) => { return _.includes(rem, x) });
  }

  // Set the include array as string.
  params.include = includeArr.join(',');

  return new Promise((resolve, reject) => {
    APICaller({method, endpoint, params})
      .then((r) => {
        const k = `${endpointKey}_setting`;
        const d = r.data.data[k];
        context.dispatch('setSettings', d);
        resolve(r);
      })
      .catch((e) => { reject(e) });
  });
}

/**
 * Helper method for check if the value was defined.
 * If the value is not defined, then, the screen will print an error.
 *
 * @param v - the value to be evaluated.
 * @param key - the key in the object.
 *
 * @return {*}
 */
function checkIfDefined(v, key) {

  if (!_.isNil(v)) {
    return key === 'entity_type' ? pluralize(v) : v;
  } else {
    console.error('Value for payload.' + key + ' was not defined.');
    return false;
  }
}