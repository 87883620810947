/*
|--------------------------------------------------------------------------
| Store > faq > Actions
|--------------------------------------------------------------------------
|
| This file contains the actions property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import APICaller from '~/js/helpers/APICaller';
import API from '~/js/constants/api';
import {extendObjParams, isEmpty, sd} from "~/js/helpers/Common";
import i18n from '../../i18n';
import {Notification} from "element-ui";
const endpoint = API.FAQ;
const subtitleEndpoint=API.SUBTITLE;
export default {
  /**
   * Request Faqs list
   *
   * @param {object} state
   *  : current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {void}
  */
  getFaqsList: ({commit}, payload) => {

    return APICaller({
      method: 'get',
      endpoint: endpoint
    }).then((response) => {
        commit('setFaqs', response.data)
        return response
      }
    ).catch( (e) => {

      console.log(e)
      return e
    })
  },


  /**
   * Get the faq data from the API.
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  getFaq: (context, payload) => {
    const {commit, dispatch, state, rootState} = context;
    const endpoint_with_id = `${API.FAQ}/${payload}`;
    const apiOpts = {
      method: 'GET',
      endpoint: endpoint_with_id
    };

    return APICaller(apiOpts)
      .then((response) => {
        commit('setFaq', response.data);
      }).catch(() => {
      })
      .finally(() => {
      });
  },
  createFaq(context, payload) {

   return APICaller({
     method: 'post',
     endpoint: endpoint,
     data: payload,
     isDataRaw: true
   }).then((response) => {
     let data = response.data
     Notification.success({
       title: 'Faq created',
       message: 'Faq created successfully',
     });
     return response
   }).catch(error => {

     return error
   })

 },
 getSubtitlesList: ({commit}, payload) => {

   return APICaller({
     method: 'get',
     endpoint: subtitleEndpoint,
     params:{
         'filter[faq_id]': payload,
     }
   })
     .then((response) => {
       commit('setSubtiles', response.data)
       return response
     }
   ).catch( (e) => {
     console.log(e)
     return e
   })
 },
 createSubtitle(context, payload) {

  return APICaller({
    method: 'post',
    endpoint: subtitleEndpoint,
    data: payload,
    isDataRaw: true
  }).then((response) => {
    let data = response.data
    Notification.success({
      title: 'Question created',
      message: 'Question created successfully',
    });
    return response
  }).catch(error => {

    return error
  })
},
deleteFaq(context, payload) {
 const endpoint_with_id = `${API.FAQ}/${payload}`;
 return APICaller({
   method: 'delete',
   endpoint: endpoint_with_id,
 }).then((response) => {
   let data = response.data
   Notification.success({
     title: 'Faq deleted',
     message: 'Faq deleted successfully',
   });
   return response
 }).catch(error => {

   return error
 })
},
deleteSubtitle(context, payload) {
 const endpoint_with_id = `${API.SUBTITLE}/${payload}`;
 return APICaller({
   method: 'delete',
   endpoint: endpoint_with_id,
 }).then((response) => {
   let data = response.data
   Notification.success({
     title: 'Question deleted',
     message: 'Question deleted successfully',
   });
   return response
 }).catch(error => {

   return error
 })
},
editSubtitle(context, payload) {
 const endpoint_with_id = `${API.SUBTITLE}/${payload.id}`;
 return APICaller({
   method: 'put',
   endpoint: endpoint_with_id,
   data: payload,
   isDataRaw: true
 }).then((response) => {
   let data = response.data
   Notification.success({
     title: 'Question edited',
     message: 'Question edited successfully',
   });
   return response
 }).catch(error => {

   return error
 })
},
}
