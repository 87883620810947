let testLanguages = {
    name: 'test_languages',
    value:[]

}


export default {

    composeFresh: _.cloneDeep(testLanguages),
    compose: _.cloneDeep(testLanguages),

}
