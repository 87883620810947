<template>
    <div class="app-list locale-group-list">
       
        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="success" title="Edit">
                    <span class="fa fa-pencil"></span>
                </el-button>
                <el-button class="circle" type="danger" title="Delete">
                    <span class="fa fa-trash"></span>
                </el-button>
            </template>
        </base-list-table>
    </div> <!-- /.app-list locale-group-list -->
</template>

<script>

        export default {

        /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                listCols: [
                    {
                        label: 'locale',
                        prop: 'locale',
                        width: '',
                        className: 'locale-col',
                    },
                    {
                        label: 'group',
                        prop: 'group',
                        width: '',
                        className: 'group-col',
                    }
                ],
                listData: [
                    {id:1, locale:'sv', group: 'app'},
                    {id:2, locale:'en', group: 'app'},
                    {id:3, locale:'sv', group: 'auth'},
                    {id:4, locale:'en', group: 'auth'},
                    {id:5, locale:'en', group: 'register'},
                    {id:6, locale:'sv', group: 'register'},
                    {id:7, locale:'en', group: 'default'},
                    {id:8, locale:'sv', group: 'default'},
                ]
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
        computed: {

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
        methods: {}, // End of Component > methods


        /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
            this.listData = this.listData.filter(data => {
                return data.locale === this.$route.params.group
            })
            
        }, // End of Component > mounted

    }
</script>