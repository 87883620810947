/*
|--------------------------------------------------------------------------
| store > bread
|--------------------------------------------------------------------------
|
| A Class file which contains all the properties, api connections and
| api activities related to the stated store file.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
    state, getters, mutations, actions
}
