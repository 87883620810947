<template>
  <div class="app-display" :class="blockClass">

    <div class="app-splitter two-cols">
      <!-- -------------------------------------------------- -->
      <!-- Booking Details Display -->
      <!-- -------------------------------------------------- -->
      <div :class="genBem(blockClass, 'details')" v-if="showDetails">
        <p>
          <span class="fa fa-info-circle" :class="genBem(blockClass, 'details-icon')"></span>
          <a href="#" @click.prevent.stop="handleClickBookingId" :class="genBem(blockClass, 'details-id')">
            #{{ booking.id }}
          </a>
          <span :class="genBem(blockClass, 'details-language')">
             - {{ booking.language_name }}
          </span>
        </p>
        <p>
          <span :class="genBem(blockClass, 'details-duration')">
              {{$t('duration')}}: {{ booking.duration }} {{$t('minutes')}}
          </span>
        </p>
        <p>
          <span :class="genBem(blockClass, 'details-customer')">
              {{ customerCompany }} / {{ customerName }}
          </span>
        </p>
        <div style="height: 1px; background-color: #d2d2d2; width: 70%; margin: 5px 0 7px;"></div>
        <p>
          <span :class="genBem(blockClass, 'details-due')">
              {{$t('date')}}: {{ booking.due }}
          </span>
        </p>
        <p>
          <span :class="genBem(blockClass, 'details-type')">
              {{$t('type')}}: {{ $t(booking.type) }}
          </span>
        </p>
        <p>
          <span :class="genBem(blockClass, 'details-due-duration')">
              {{ bookingDueDuration }}
          </span>
        </p>
      </div> <!-- End of Booking Details -->

      <!-- -------------------------------------------------- -->
      <!-- Booking Batches -->
      <!-- -------------------------------------------------- -->
      <div :class="genBem(blockClass, 'batches')">

        <div class="text-center">
          <el-button @click="handleClickLoadBatches"
                     type="primary"
                     size="mini">
            {{ batchesLoading ? $t('loading_translators') : (this.page === 1) ? $t('load_5_translators') :
            $t('load_5_additional_translators') }}
          </el-button>
        </div>

        <div class="text-center">
          <el-button @click="handleClickLoadNonSendable"
                     type="primary"
                     size="mini">
            {{$t('load_non_sendable_translators')}}
          </el-button>
        </div>


      </div> <!-- End of Booking Batches -->
    </div>
    <!-- /.app-splitter two-cols -->

    <!-- -------------------------------------------------- -->
    <!-- Chart -->
    <!-- -------------------------------------------------- -->
    <div :class="genBem(blockClass, 'chart')" v-loading="batchesLoading">

      <!-- -------------------------------------------------- -->
      <!-- Chart Translator List -->
      <!-- -------------------------------------------------- -->
      <div :class="genBem(blockClass, 'chart-translator-list')">

        <ul>
          <li
            :class="[
              genBem(blockClass, 'chart-potential'),
              getBem(blockClass, 'chart-translator-list-item')
            ]"
          >
            <div :class="getBem(blockClass, 'chart-translator-list-item-wrapper')">
              {{$t('potential_booking')}}
            </div>
          </li>
          <li
            v-for="(v) in activePool" v-if="activePool.length > 0"
            :class="getBem(blockClass, 'chart-translator-list-item')"
          >

            <a :class="genBem(blockClass, 'chart-translator__delete-btn')"
               href="#" @click.prevent.stop="handleClickDeleteTranslator(v)">
              <span class="fa fa-times-circle"></span>
            </a>

            <a
              :class="getBem(blockClass, 'chart-translator__resend-notification-btn')"
              href="#"
              @click.prevent="handleResendNotifToTranslator({id: v.id, name: v.name})"
            >
              <span class="fa fa-bell"></span>
            </a>

            <div data-simplebar :class="getBem(blockClass, 'chart-translator-list-item-wrapper')">

              <span :class="getBem(blockClass, 'chart-translator__name')">{{ v.name }}</span>

              <div v-if="isValidBatchEntry(v.entry)"
                   :class="getBem(blockClass, 'chart-translator-batch-entry')"
              >
                <div :class="getBem(blockClass, 'chart-translator-batch-entry-translator-level')">
                  {{ getTranslatorLevelName(v.entry.translator_level_id) }} Tolk
                </div>
                <span :class="getBem(blockClass, 'chart-translator-batch-entry-km')">
                  {{ getValueTravelKm(v.entry) }}
                </span>

                <el-rate
                  :class="getBem(blockClass, 'chart-translator-batch-entry-rate')"
                  v-model="v.entry.average_rating" disabled
                />
                <div
                  :class="getBem(blockClass, 'chart-translator-batch-entry-non-sendable')"
                  v-if="isNonSendable(v.entry)"
                >
                  {{$t('non_sendable')}} : {{ getValueNonSendable(v.entry) }}
                </div>
              </div>
            </div>

            <a v-if="!translatorHasConflict(v)"
               :class="genBem(blockClass, 'chart-translator__assign-btn')"
               href="#" @click.prevent.stop="handleClickAssignTranslator(v)">
              {{$t('assign_potential')}}
            </a>

          </li>
        </ul>

        <el-autocomplete
          :class="genBem(blockClass, 'chart-search')"
          v-model="q"
          :disabled="isSearchDisabled"
          :trigger-on-focus="false"
          :debounce="1000"
          value-key="name"
          :fetch-suggestions="handleFetchSuggestions"
          @select="handleSelectTranslator"
          :placeholder="$t('click_add_translator')"
          :highlight-first-item="true"
        />

      </div> <!-- End of Graph Translator List -->

      <!-- -------------------------------------------------- -->
      <!-- Graph Canvas -->
      <!-- -------------------------------------------------- -->
      <booking-availability-scheduler-chart
        :chart-data="chartData"
        :range="timeRange" />

    </div> <!-- End of Graph -->

  </div> <!-- /.app-display booking-availability-chart -->
</template>

<script>
  import {mapActions} from 'vuex';
  import isNil from 'lodash/isNil';
  import isEmpty from 'lodash/isEmpty';
  import map from 'lodash/map';
  import forEach from 'lodash/forEach';
  import remove from 'lodash/remove';
  import find from 'lodash/find';
  import filter from 'lodash/filter';
  import clone from 'lodash/clone';
  import cloneDeep from 'lodash/cloneDeep';
  import BookingAvailabilitySchedulerChart
    from '~/components/displays/booking-availability/BookingAvailabilitySchedulerChart';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {BookingAvailabilitySchedulerChart},

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      bookingId: {
        required: true
      },

      showDetails: {
        type: Boolean,
        default: true
      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {

        blockClass: 'booking-availability-scheduler',
        q: '',
        activePool: [],
        booking: {},
        isSearchDisabled: false,
        batchesList: [],
        batchesLoading: false,
        meta: {},
        page: 1,
        rateActiveColors: ['#f7bf00', '#f7bf00', '#f7bf00'],
        rateVoidColor: '#3d9ee6'
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Returns formatted value for booking due date (time) and duration.
       * @return {string}
       */
      bookingDueDuration () {

        if (this.booking.due !== undefined && this.booking.duration !== 0) {
          const due = this.booking.due;
          const dur = this.booking.duration;

          let date = window.moment(due, 'YYYY-MM-DD hh:mm:ss');
          const start = date.format('HH:mm');
          const end = date.add(dur, 'minutes').format('HH:mm');

          return `${start} - ${end}`;

        } else {
          return '00:00 - 00:00';
        }
      },

      /**
       * Returns minimum and maximum time range for the chart.
       *
       * @return {Object} - Returns object of Moment Objects.
       */
      timeRange () {
        let r = {min: null, max: null};

        if (this.booking.due !== undefined && this.booking.duration !== 0) {

          let bookingDue = (this.getBookingTimes(this.booking)).due;
          r.min = bookingDue.clone().subtract(4, 'hours');
          r.max = bookingDue.clone().add(4, 'hours');
        }

        return r;
      },

      /**
       * Returns the potential booking start and end time.
       *
       * @returns {Object}
       */
      timePotential () {
        // If this.booking is empty, just return the empty object.
        if (isEmpty(this.booking)) return {};

        return this.formatChartDataEntry(this.booking, {mode: 'potential'});
      },

      /**
       * Returns a formatted data compatible with the chart.
       *
       * @return {Array}
       */
      chartData () {
        let result = []; // Prepare result container.

        if (!isEmpty(this.booking)) {
          // Add the Potential Booking if this.booking container has been populated.
          // Insert the entry as array.
          result.push({
            user: {id: 0, name: 'Potential Booking'},
            bookings: [this.timePotential]
          });
        }

        if (this.activePool.length > 0) {
          // Add the users in active pool if there are any.

          forEach(this.activePool, (user) => {

            result.push({
              user: {id: user.id, name: user.name},
              bookings: map(
                user.bookings, (booking) => this.formatChartDataEntry(booking)
              )
            });

          });
        }

        return result;
      },

      /**
       * Returns the Customer Company Name
       *
       * @returns {string}
       */
      customerCompany () {
        return !isNil(this.booking.customer)
        && !isNil(this.booking.customer.customer_data)
          ? this.booking.customer.customer_data.department.company.name
          : '';
      },

      /**
       * Returns the Customer Name
       *
       * @returns {string}
       */
      customerName () {
        return !isNil(this.booking.customer)
        && !isNil(this.booking.customer.name)
          ? this.booking.customer.name
          : '';
      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions({
        actionGetBooking: 'booking/getBookingGeneric',
        actionGetTranslatorBookings: 'booking/getTranslatorBookings',
        actionFindUser: 'user/findUser',
        assignTranslatorBooking: 'booking/assignTranslator'
      }),


      /**
       * Handler for Async fetch suggestion for el-autocomplete.
       * @return {void}
       */
      handleFetchSuggestions (q, cb) {

        if (q !== '') {
          this.findTranslator(q)
              .then((r) => {
                let suggestions = r.data.data.users;

                // Remove the user in the suggestion if it exists in the activePool
                remove(suggestions, (v) => {
                  return find(this.activePool, (o) => o.id === v.id);
                });

                cb(suggestions);
              })
              .catch(() => { cb([]); });

        } else cb([]);
      },

      /**
       * Method for getting the target booking in the API.
       *
       * @return {void}
       */
      getBookingDetails () {

        const payload = {
          booking_id: parseInt(this.bookingId),
          params: {
            with: 'customer'
          }
        };
        this.actionGetBooking(payload)
            .then((r) => {
              this.booking = r.data.data.booking;
            });
      },

      /**
       * Method for finding the user using the API.
       *
       * @param query - query value to find.
       * @returns {*}
       */
      findTranslator (query) {

        return this.actionFindUser({
          query,
          column: 'name',
          params: {
            all: true,
            'filter[type]': 3
          }
        });
      },

      /**
       * Handler when a translator was selected in the Search Translator suggestions.
       *
       * @param user - the selected user.
       * @param user.id - the ID of the selected user.
       * @param user.name - the Name of the selected user.
       * @return {void}
       */
      handleSelectTranslator (user) {

        this.q = '';
        this.isSearchDisabled = true;
        this.getTranslatorBookings(user.id)
            .then((r) => {
              // Include another property inside the user (which is its bookings)
              // before offically adding it into the active pool.
              // noinspection JSUndefinedPropertyAssignment
              user.bookings = this.filterBookingsWithinRange(r.data.data.bookings);
              this.addToActivePool(user);
            })
            .catch((e) => {
              this.$notify.error({
                title: 'Error while getting the translator bookings.',
                message: 'Please check the error in the console, or contact the Administrator.'
              });
              console.error(e);
              console.error(e.response);

            })
            .finally(() => {
              this.isSearchDisabled = false;
            });
      },

      /**
       * Handler when the Delete Translator button was clicked.
       *
       * @param user - the targeted user for removal.
       * @return {void}
       */
      handleClickDeleteTranslator (user) {

        this.deleteFromActivePool(user);
      },

      /**
       * Handler when the Assign Translator button was clicked.
       *
       * @param user - the targeted user to be assigned.
       * @return {void}
       */
      handleClickAssignTranslator (user) {
        const payload = {
          id: this.bookingId,
          translator_id: user.id
        };
        this.assignTranslatorBooking(payload).then((r) => {
          if (!isEmpty(r.data.reasons) && r.status === 'error') {
            let reasons = r.data.reasons.join('\r\n');
            this.$confirm(`${reasons} You sure you want to assign Translator to the job?`, this.$t('warning'), {
              confirmButtonText: 'YES',
              cancelButtonText: 'NO',
              type: 'warning'
            }).then(() => {
              const forcePayload = {
                id: this.bookingId,
                translator_id: user.id,
                force_assign: true,
                accepted_via: 'dt-admin-web'
              };
              this.assignTranslatorBooking(forcePayload);
              this.$emit('translator_assigned');
            }).catch(() => {
              console.log('canceled');
            });
          }
        });
        /*const apiOpts = {
          endpoint: `${API.BOOKINGS}/${this.bookingId}`,
          method: 'put',
          data: {
            assigned_translator_id: user.id,
            id: this.bookingId
          },
          isDataRaw: true
        };

        APICaller(apiOpts)
          .then(() => {
            this.$notify.success({
              title: 'Success!',
              message: `Booking was assigned to ${user.name} (${user.id})`
            });
          })
          .catch((e) => {
            console.error(e);
            this.$notify.error({title: 'Error', message: 'Please contact the administrator.'});
          });*/
      },

      /**
       * Method to add the translator user to the active pool.
       *
       * @param user
       * @return {void}
       */
      addToActivePool (user) {

        if (
          !isEmpty(user) // If user is not an empty object
          && !find(this.activePool, user) // and doesn't exist yet inside the pool.
        ) {
          this.activePool.push(clone(user));
        }
      },

      /**
       * Method to delete/remove the translator user to the active pool.
       *
       * @param user
       * @return {void}
       */
      deleteFromActivePool (user) {

        if (
          !isEmpty(user) // If user is not an empty object
          && find(this.activePool, user) // and does exist yet inside the pool.
        ) {
          let cn = cloneDeep(this.activePool);
          remove(cn, (o) => o.id === user.id);
          this.activePool = cn;
        }
      },

      /**
       * Remove everything from the active pool.
       */
      clearActivePool () {
        this.activePool = [];
      },

      /**
       * Method for getting the bookings of a specific translator.
       *
       * @param translatorId - ID of the target translator.
       * @return {*}
       */
      getTranslatorBookings (translatorId) {

        const payload = {
          translator_id: translatorId,
          params: {
            'filter[status_id]': '2,3',
            all: true
          }
        };

        return this.actionGetTranslatorBookings(payload);
      },


      /**
       * Helper method to filter the collection of bookings based on the timeRange.
       *
       * @param {array} bookings - the collection of bookings
       * @return {array|void}
       */
      filterBookingsWithinRange (bookings) {
        if (isEmpty(bookings) || isNil(this.timeRange.min) || isNil(this.timeRange.max)) return;

        // Both min and max are moment objects.
        const min = this.timeRange.min;
        const maxExtended = this.timeRange.max.clone().add(2, 'hours');

        const result = [];

        // Iterate tru each bookings.
        forEach(bookings, (booking) => {

          const bTimes = this.getBookingTimes(booking);
          const start = bTimes.start;
          const end = bTimes.start;

          // Include the booking according to conditions ---
          if (start.isAfter(min, 'hour') && end.isBefore(maxExtended, 'hour')) {
            result.push(booking);
          }

        });

        // If the result is empty, insert a mock data to be a transparent placeholder to have a row.
        if (result.length === 0) {
          result.push({id: 0});
        }

        return result;

      },

      /**
       * Helper method to format the Chart Data Entry.
       *
       * @param {object} booking - Booking Entry.
       * @param {object} options - Contains values necessary within the method.
       * @param {string} [options.mode = ''] - Optional value to assign to mode (forcibly).
       * @return {object}
       */
      formatChartDataEntry (booking, options = {}) {
        // Set Default for options.
        options.mode = isNil(options.mode) ? '' : options.mode;

        let formattedData = {}; // Prepare container for the formattedData.

        const bTimes = this.getBookingTimes(booking);
        const start = bTimes.start;
        const end = bTimes.end;

        // Define Mode
        if (options.mode !== '') {
          formattedData.mode = options.mode;

        } else if (booking.id === 0) {
          formattedData.mode = 'empty';

        } else {
          const pStart = this.timePotential.booking.start;
          const pEnd = this.timePotential.booking.end;
          formattedData.mode = start.isBetween(pStart, pEnd) || start.isSame(pStart) ? 'conflict' : 'safe';
        }

        // Define booking.
        formattedData.booking = booking;

        if (booking.id === 0) {
          formattedData.booking.start = this.timeRange.min.toDate();
          formattedData.booking.end = this.timeRange.max.toDate();

        } else {
          formattedData.booking.start = start.toDate();
          formattedData.booking.end = end.toDate();
        }


        // Define position.
        formattedData.position = this.getPosition(start, end);

        return formattedData;
      },

      /**
       * Helper method to computed the correct percentage position and width for the bar.
       *
       * @param {object} start - Start (Moment Object) of the booking
       * @param {object} end - End (Moment Object) of the booking
       * @returns {Object}
       */
      getPosition (start, end) {
        const min = this.timeRange.min;
        const max = this.timeRange.max;
        const full = max.diff(min);

        // Point A and B containers.
        let a = start.clone();
        let b = end.clone();

        if (start.isAfter(min) && end.isBefore(max)) {
          // If the start and end is in between of min and max ranges, run this block...
          b = end.clone();

        } else if (start.isAfter(min) && end.isAfter(max)) {
          // If the start is after min range but end is after max range, run this block...
          b = max.clone();

        }

        return {
          start: a.diff(min) / full * 100,
          end: b.diff(min) / full * 100
        };
      },

      /**
       * Helper method to provided formatted booking times such as:
       * Due as Moment Object
       * Start as Moment Object
       * End as Moment Object
       *
       * @param {object} booking - Source of values.
       * @return {object} Returns Object of Moment Objects.
       */
      getBookingTimes (booking) {

        let due = window.moment(booking.due, 'YYYY-MM-DD hh:mm:ss');
        const start = due.clone();
        const end = due.clone().add(booking.duration, 'minutes');

        return {due, start, end};
      },

      /**
       * Helper method for check if there is a mode conflict inside the user's bookings.
       *
       * @param user
       * @returns {boolean}
       */
      translatorHasConflict (user) {
        let result = false;

        // Search if the user as a "conflict" in its bookings.
        if (user.bookings !== undefined && user.bookings.length > 0) {
          const found = filter(user.bookings, (booking) => booking.mode === 'conflict');
          result = found.length > 0;
        }

        return result;
      },

      /**
       * Handler when the Booking ID was clicked.
       *
       * @return {void}
       */
      handleClickBookingId () {
        this.$router.push({
          name: 'booking-details',
          params: {
            id: this.bookingId
          }
        });
      },

      /**
       * Handler when the button for loading the batches was clicked.
       *
       * @return {void}
       */
      handleClickLoadBatches () {
        this.batchesLoading = true;

        const payload = {
          id: this.$route.params.id, // Booking ID
          params: {
            page: this.page, per_page: 5
          }
        };

        this.$store.dispatch('bookingBatches/loadBatchesScheduler', payload)
            .then(() => {
              this.batchesList = this.$store.getters['bookingBatches/batchesSchedulerList'];
              this.page = this.$store.getters['bookingBatches/batchesSchedulerCurrentPage'];

              if (this.batchesList.length > 0) {
                this.addTranslatorsFromBatches(this.batchesList);
              }
            })
            .finally(() => {
              this.batchesLoading = false;
            });
      },

      /**
       * @returns {void}
       */
      handleClickLoadNonSendable () {
        this.batchesLoading = true;

        const payload = {
          id: this.$route.params.id, // Booking ID
          params: {
            all: true,
            'filter[sendable]': 0
          }
        };

        this.$store.dispatch('bookingBatches/loadBatchesScheduler', payload)
            .then(() => {
              this.batchesList = this.$store.getters['bookingBatches/batchesSchedulerList'];

              if (this.batchesList.length > 0) {
                this.addTranslatorsFromBatches(this.batchesList);
              }
            })
            .finally(() => {
              this.batchesLoading = false;
            });
      },

      /**
       * Helper method for added the translators inside the batches.
       *
       * @param batches
       */
      addTranslatorsFromBatches (batches) {
        const isValidBatches = !isNil(batches) && !isEmpty(batches);
        if (!isValidBatches) return;

        forEach(batches, (batch) => {
          const isValidEntries = !isNil(batch) && !isNil(batch.entries) && !isEmpty(batch.entries);
          if (!isValidEntries) return true; // Skip iteration.

          forEach(batch.entries, (entry) => {
            this.handleSelectTranslator({
              id: entry.translator.id,
              name: entry.translator.name,
              entry: entry
            });
          });
        });
      },

      /**
       * @returns {boolean}
       */
      isValidBatchEntry (entry) {
        return !isNil(entry) && !isEmpty(entry);
      },

      /**
       * @param entry
       * @returns {string}
       */
      getValueTravelKm (entry) {
        let result = '';

        if (!isNil(entry.temp_travel_distance_car) && entry.temp_travel_distance_car !== '') {
          result = entry.temp_travel_distance_car + '[car]';

        } else if (!isNil(entry.temp_travel_distance_public) && entry.temp_travel_distance_public !== '') {
          result = entry.temp_travel_distance_public + '[public]';

        } else {
          result = 'No';
        }

        return result + ' KM';
      },

      /**
       * @param entry - Batch Entry
       * @returns {boolean}
       */
      isNonSendable (entry) {
        return !isNil(entry)
          && !isNil(entry.sendable)
          && !entry.sendable;
      },

      /**
       * @param entry - Batch Entry
       * @returns {string}
       */
      getValueNonSendable (entry) {
        return this.isNonSendable(entry)
        && !isNil(entry.not_sent_reason)
        && entry.not_sent_reason !== ''
          ? entry.not_sent_reason
          : '';
      },

      /**
       * @param {int} translatorLevelId
       * @return {string|int}
       */
      getTranslatorLevelName (translatorLevelId) {
        const levels = {
          1: 'Reg',
          2: 'AT',
          3: 'GrT',
          4: 'UT',
          5: 'ST',
          6: 'RT'
        };

        const name = levels[translatorLevelId];
        return !isNil(name) ? name : translatorLevelId;
      },

      /**
       * Method for resending notification to specific translator button was clicked.
       *
       * @param {object} translator - Contains translator props.
       * @return {void}
       */
      handleResendNotifToTranslator (translator) {
        let bookingId = this.$route.params.id;
        const translatorId = translator.id;
        const translatorName = translator.name;
        const channels = ['push'];

        this.$store.dispatch('bookingBatches/resendNotif', {
          id: bookingId,
          translators: [translatorId],
          channels
        }).then(() => {
          this.$notify({
            type: 'success',
            message: `Notification re-sent for ${translatorName} (${translatorId}).`
          });
        }).catch(() => {
          this.$notify({
            type: 'error',
            message: `Faield resending notification for ${translatorName} (${translatorId}).`
          });
        });
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {

      // Set the timezone to local to avoid datetime mutation.
      window.moment.tz.setDefault();
      this.getBookingDetails();
    },

    /*
    |--------------------------------------------------------------------------
    | Component > beforeDestroy
    |--------------------------------------------------------------------------
    */
    beforeDestroy () {
      // Set the timezone back to what's defined in bootstrap.js.
      window.moment.tz.setDefault("Europe/Stockholm");
    }


  }; // End of export default
</script>
