/*
|--------------------------------------------------------------------------
| Route Paths
|--------------------------------------------------------------------------
|
| This file contains all the route to be used in Vue Routers.
| Kindly use this file for cleaner implementation.
*/

import Vue from 'vue';

export default [

  // Landing Page
  {
    path: '/', component: Vue.component('login-page'),
    meta: {
      page_class: 'home-route non-admin',
      show_sidebar: false,
      is_public: true,
    }
  },

  // Logout Route
  {
    path: '/logout', component: Vue.component('logout-page'),
    meta: {
      page_class: 'logout-route non-admin',
      show_sidebar: false,
    }
  },

  {
    path: '/booking/create', component: Vue.component('bookings-form-page'),
    name:'booking-create',
    meta: {
      page_class: 'home-route non-admin',
      show_sidebar: false,
    }
  },

  {
    name:'text-translations.create',
    path: '/text-translation/create', component: Vue.component('create-text-booking-page'),
    meta: {
      page_class: 'home-route non-admin',
      show_sidebar: false,
    }
  },
  {
    path: '/text-translation/bids/:id', component: Vue.component('text-bookings-bids-page'),
    name: 'text-translation-bids-view',
    meta: {
      page_class: 'home-route non-admin',
      show_sidebar: false,
    }
  },

  {
    path: '/text-translations/review/:id', component: Vue.component('text-bookings-review-page'),
    name: 'text-translation-review',
    meta: {
      page_class: 'dashboard-route',
    }
  },

  // Admin Dashboard Page
  {
    path: '/dashboard', component: Vue.component('dashboard-page'),
    name: 'starting-point',
    meta: {
      page_class: 'dashboard-route',
    }
  },

  // Admin Quick Stats Page
  {
    path: '/quick-stats', component: Vue.component('quick-stats-page'),
    name: 'quick-stats-starting-point',
    meta: {
      page_class: 'quick-stats-route'
    }
  },

  // Financial > Settings List
  {
    path: '/financial/settings/list', component: Vue.component('financial-settings-list-page'),
    name: 'financial.settings.list',
    meta: {
      page_class: 'financial-settings-list-route'
    }
  },

  // Financial > Price Template List
  {
    path: '/financial/price-template-list',
    component: Vue.component('financial-price-template-list-page'),
    name: 'financial-price-template-list',
    meta: {
      page_class: 'financial-price-template-list-route'
    }
  },

  {
    path: '/financial/price-template-list/add',
    component: Vue.component('financial-price-template-compose-page'),
    name: 'financial-price-template-add',
    meta: {
      page_class: 'financial-price-template-add-route',
    }
  },

  {
    path: '/financial/price-template-list/edit/:id',
    component: Vue.component('financial-price-template-compose-page'),
    name: 'financial-price-template-edit',
    meta: {
      page_class: 'financial-price-template-edit-route',
    }
  },

  // Financial Export
  {
    path: '/financial/export', component: Vue.component('financial-export-page'),
    name: 'financial.export',
    meta: {
      page_class: 'financial-export-route'
    }
  },

  // Financial Export Process
  {
    path: '/financial/export/:id/process', component: Vue.component('financial-export-process-page'),
    name: 'financial.export.process',
    meta: {
      page_class: 'financial-export-process-route'
    }
  },

  // Invoice List
  {
    path: '/financial/invoice-list', component: Vue.component('invoice-list-page'),
    meta: {
      page_class: 'invoice-list-route'
    }
  },

  // Admin Sector > Municipality
  {
    path: '/sector/municipality', component: Vue.component('municipality-page'),
    meta: {
      page_class: 'municipality-route'
    }
  },
  {
    path: '/sector/municipality/add', component: Vue.component('municipality-compose-page'),
    meta: {
      page_class: 'municipality-add-route', is_compose: true
    }
  },
  {
    name:'municipality.edit',
    path: '/sector/municipality/edit/:id', component: Vue.component('municipality-compose-page'),
    meta: {
      page_class: 'municipality-edit-route', is_compose: true
    }
  },

  // Admin Sector > Company
  {
    path: '/sector/company', component: Vue.component('company-page'),
    meta: {
      page_class: 'company-route',
    }
  },
  {
    path: '/sector/company/add', component: Vue.component('company-compose-page'),
    meta: {
      page_class: 'company-add-route', is_compose: true
    }
  },
  {
    name: 'company-edit',
    path: '/sector/company/edit/:id', component: Vue.component('company-compose-page'),
    meta: {
      page_class: 'company-edit-route', is_compose: true
    }
  },


  // Admin Sector > Department
  {
    path: '/sector/department', component: Vue.component('department-page'),
    meta: {
      page_class: 'department-route',
    }
  },
  {
    path: '/sector/department/add', component: Vue.component('department-compose-page'),
    meta: {
      page_class: 'department-add-route', is_compose: true
    }
  },
  {
    name: 'department.edit',
    path: '/sector/department/edit/:id', component: Vue.component('department-compose-page'),
    meta: {
      page_class: 'department-edit-route', is_compose: true
    }
  },

  // Audits
  {
    name: 'audits',
    path: '/audits/:auditable/:id', component: Vue.component('audits-page'),
    meta: {
      page_class: 'audits-route'
    }
  },

  {
    name: 'audits.financials.template',
    path: '/audits/financials/template/:template_id', component: Vue.component('audits-page'),
    meta: {
      page_class: 'audits-route'
    }
  },

  {
    name: 'audits.financials.adjustments',
    path: '/audits/financials/adjustments/:booking_id', component: Vue.component('audits-page'),
    meta: {
      page_class: 'audits-route'
    }
  },

  // Admin Bookings
  {
    name: 'bookings.index',
    path: '/bookings', component: Vue.component('bookings-page'),
    meta: {
      page_class: 'bookings-route',
    }
  },
  {
    name: 'booking-expiring',
    path: '/bookings/about-to-expire', component: Vue.component('bookings-about-expired-page'),
    meta: {
      page_class: 'bookings-route',
    }
  },
  {
    name: 'booking-pending',
    path: '/bookings/pending-for-future', component: Vue.component('bookings-pending-for-future'),
    meta: {
      page_class: 'bookings-route',
    }
  },
  {
    name: 'booking-to-work-on',
    path: '/bookings/to-work-on', component: Vue.component('bookings-to-work-on-page'),
    meta: {
      page_class: 'bookings-route',
    }
  },
  {
    name: 'booking-is-difficult',
    path: '/bookings/is-difficult', component: Vue.component('bookings-is-difficult-page'),
    meta: {
      page_class: 'bookings-route',
    }
  },
  {
    name: 'bookings-planned',
    path: '/bookings/planned', component: Vue.component('bookings-planned-page'),
    meta: {
      page_class: 'bookings-route',
    }
  },
  {
    name: 'bookings-to-be-planned',
    path: '/bookings/to-be-planned', component: Vue.component('bookings-to-be-planned-page'),
    meta: {
      page_class: 'bookings-route',
    }
  },
  {
    name: 'bookings-cannot-be-planned',
    path: '/bookings/cannot-be-planned', component: Vue.component('bookings-cannot-be-planned-page'),
    meta: {
      page_class: 'bookings-route',
    }
  },
  {
    path: '/text-translations', component: Vue.component('text-bookings-page'),
    name: 'text-translations.list.all',
    meta: {
      page_class: 'text-translations-all',
    }
  },
  {
    path: '/text-translations/pending', component: Vue.component('text-bookings-page'),
    name: 'text-translations.list.pending',
    meta: {
      page_class: 'text-translations-pending',
    }
  },
  {
    path: '/text-translations/on-going', component: Vue.component('text-bookings-page'),
    name: 'text-translations.list.ongoing',
    meta: {
      page_class: 'text-translations-ongoing',
    }
  },
  {
    path: '/text-translations/completed', component: Vue.component('text-bookings-page'),
    name: 'text-translations.list.completed',
    meta: {
      page_class: 'text-translations-completed',
    }
  },

  // Admin Booking > Details
  {
    path: '/booking/:id/details', component: Vue.component('booking-details-page'),
    name: 'booking-details',
    meta: {
      page_class: 'bookings-details-route',
    }
  },
  // Admin Booking > Details > View Translator
  {
    path: '/booking/:id/details/translator', component: Vue.component('translators-list-tabs-page'),
    name: 'booking.details.translator',
    meta: {
      page_class: 'bookings-details-translator-route',
    }
  },
  {
    path: '/bookings/:id/edit', component: Vue.component('booking-edit-page'),
    name: 'booking-edit',
    meta: {
      page_class: 'bookings-edit-route',
    }
  },

  // Admin Booking > Details > Financial Adjustment
  {
    path: '/booking/:id/financial-adjustment',
    component: Vue.component('booking-financial-adjustment-page'),
    name: 'booking-financial-adjustment',
    meta: {
      page_class: 'booking-financial-adjustment-route',
    }
  },

  // Admin Booking > Details > Trail Logs
  {
    path: '/booking/:id/trail-logs', component: Vue.component('booking-trail-logs-page'),
    name: 'booking-trail-logs',
    meta: {
      page_class: 'booking-trail-logs-route',
    }
  },

  {
    path: '/booking/:id/availability', component: Vue.component('booking-availability-page'),
    name: 'booking-availability',
    meta: {
      page_class: 'booking-availability-route'
    }
  },

  // New routes for composing customer and translator ---
  {
    name: 'user.add.customer',
    path: '/user/add/customer', component: Vue.component('user-customer-compose-page'),
    meta: {
      page_class: 'user-add-customer-route'
    }
  },

  {
    name: 'user.add.translator',
    path: '/user/add/translator', component: Vue.component('user-translator-compose-page'),
    meta: {
      page_class: 'user-add-translator-route'
    }
  },

  {
    name: 'user.edit.customer',
    path: '/user/edit/customer/:id', component: Vue.component('user-customer-compose-page'),
    meta: {
      page_class: 'user-edit-customer-route'
    }
  },

  {
    name: 'user.edit.translator',
    path: '/user/edit/translator/:id', component: Vue.component('user-translator-compose-page'),
    meta: {
      page_class: 'user-edit-translator-route'
    }
  },

  {
    name: 'user.duplicate.customer',
    path: '/user/duplicate/customer/:id', component: Vue.component('user-customer-compose-page'),
    meta: {
      page_class: 'user-duplicate-customer-route'
    }
  },

  {
    name: 'user.duplicate.translator',
    path: '/user/duplicate/translator/:id', component: Vue.component('user-translator-compose-page'),
    meta: {
      page_class: 'user-duplicate-translator-route'
    }
  },

  // End of new routes for composing customer and translator ---

  // User > profile
  {
    name: 'user.profile',
    path: '/user/profile/:id', component: Vue.component('user-profile-page'),
    meta: {
      page_class: 'user-compose-route',
    }
  },
  {
    path: '/user/late-withdrawal/:id', component: Vue.component('late-withdrawal-page'),
    meta: {
      page_class: 'user-compose-route',
    }
  },

  // User > List
  {
    path: '/user/list', component: Vue.component('user-list-page'),
    meta: {
      page_class: 'user-list-route',
    }
  },

  {
    path: '/user/translator/:id/bookings/weekly', component: Vue.component('user-translator-bookings-weekly-page'),
    name: 'user.translator.bookings.weekly',
    meta: {
      page_class: 'user-translator-bookings-weekly'
    }
  },

  {
    path: '/blacklist-users/:id', component: Vue.component('blacklisted-list-page'),
    meta: {
      page_class: 'user-list-route',
    }
  },

  {
    path: '/user-financial-form/:id', component: Vue.component('user-financial-form'),
    name: 'user.financial.settings.form',
    meta: {
      page_class: 'user-financial-route',
    }
  },

  //AES >  Mapings List
  {path: '/aes/mappings/municipality', component: Vue.component('municipality-mapings-page')},
  {path: '/aes/mappings/company', component: Vue.component('company-mappings-page')},
  {path: '/aes/mappings/user', component: Vue.component('user-mappings-page')},
  {path: '/aes/mappings/department', component: Vue.component('department-mappings-page')},
  {path: '/aes/mappings/booking', component: Vue.component('booking-mappings-page')},

  //AES > add Mappings
  {
    path: '/aes/add/municipality', component: Vue.component('municipality-mappings-form-page'),
    meta: {
      page_class: 'municipality-mappings-form-page',
    }
  },
  {
    path: '/aes/add/company', component: Vue.component('company-mappings-form-page'),
    meta: {
      page_class: 'company-mappings-form-page',
    }
  },
  {
    path: '/aes/add/user', component: Vue.component('user-mappings-form-page'),
    meta: {
      page_class: 'user-mappings-form-page',
    }
  },
  {
    path: '/aes/add/department', component: Vue.component('department-mappings-form-page'),
    meta: {
      page_class: 'department-mappings-form-page',
    }
  },
  {
    path: '/aes/add/booking', component: Vue.component('booking-mappings-form-page'),
    meta: {
      page_class: 'booking-mappings-form-page',
    }
  },

  // Communication logs
  // Sms Logs List  Page
  {
    path: '/logs/sms', component: Vue.component('sms-logs-page'),
    meta: {
      page_class: 'sms-logs-route',
    }
  },
  // Email Logs List  Page
  {
    path: '/logs/email', component: Vue.component('email-logs-page'),
    meta: {
      page_class: 'sms-logs-route',
    }
  },
  // Fax Logs List  Page
  {
    path: '/logs/fax', component: Vue.component('fax-logs-page'),
    meta: {
      page_class: 'fax-logs-route',
    }
  },
  {
    path: '/logs/push', component: Vue.component('push-logs-page'),
    meta: {
      page_class: 'push-logs-route',
    }
  },
  {
    path: '/logs/admin', component: Vue.component('admin-logs-page'),
    meta: {
      page_class: 'admin-logs-route',
    }
  },
  {
    path: '/logs/user-access-logs', component: Vue.component('user-access-logs-page'),
    meta: {
      page_class: 'user-access-logs-route',
    }
  },
  {
    path: '/logs/cron', component: Vue.component('cron-logs-page'),
    meta: {
      page_class: 'cron-logs-route',
    }
  },

  // Text Translation Booking
  {
    path: '/text-translation/fixed-price', component: Vue.component('fixed-text-translation-page'),
    meta: {
      page_class: 'fixed-text-translation-page',
    }
  },
  {
    path: '/text-translation/non-fixed-price',
    component: Vue.component('nonfixed-text-translation-page'),
    meta: {
      page_class: 'nonfixed-text-translation-page',
    }
  },

  // Admin user
  {
    path: '/admin-user/list', component: Vue.component('admin-user-list-page'),
    meta: {
      page_class: 'admin-user-list-page',
    }
  },
  {
    path: '/admin-user/add', component: Vue.component('admin-user-compose-page'),
    meta: {
      page_class: 'admin-user-compose-page',
    }
  },
  {
    path: '/admin-user/edit/:id', component: Vue.component('admin-user-compose-page'),
    meta: {
      page_class: 'admin-user-compose-page',
    }
  },

  //translation manager
  {
    path: '/locale', component: Vue.component('translation-manager-page'),
    meta: {
      page_class: 'translation-manager-page',
    }
  },
  {
    path: '/locale/create', component: Vue.component('locale-form-page'),
    meta: {
      page_class: 'locale-form-page',
    }
  },
  {
    path: '/locale/translation', component: Vue.component('translation-form-page'),
    meta: {
      page_class: 'translation-form-page',
    }
  },
  {
    path: '/locale/groups/:group', component: Vue.component('locale-group-list-page'),
    meta: {
      page_class: 'locale-group-list-page',
    }
  },
  {
    path: '/languages', component: Vue.component('languages-list-page'),
    meta: {
      page_class: 'languages-list-page',
    }
  },
  {
    path: '/languages/create', component: Vue.component('languages-form-page'),
    meta: {
      page_class: 'languages-form-page',
    }
  },
  {
    path: '/dialects', component: Vue.component('dialects-list-page'),
    meta: {
      page_class: 'dialects-list-page',
    }
  },
  {
    path: '/dialects/create', component: Vue.component('dialects-form-page'),
    meta: {
      page_class: 'dialects-form-page',
    }
  },
  {
    path: '/settings', component: Vue.component('settings-page'),
    meta: {
      page_class: 'settings-page',
    }
  },
  {
    path: '/settings/price-list/create', component: Vue.component('price-list-settings-page'),
    meta: {
      page_class: 'price-list-settings-page',
    }
  },
  {
    name: 'settings.price-list.edit',
    path: '/settings/price-list/edit/:id', component: Vue.component('price-list-settings-page'),
    meta: {
      page_class: 'price-list-settings-page',
    }
  },
  {
    path: '/admin-permissions/roles', component: Vue.component('admin-permissions-roles-list-page'),
    meta: {
      page_class: 'admin-permissions-roles-list-page',
    }
  },
  {
    path: '/admin-permissions/roles/create', component: Vue.component('admin-permissions-roles-list-settings-page'),
    meta: {
      page_class: 'admin-permissions-roles-list-settings-page',
    }
  },
  {
    path: '/admin-permissions/roles/edit/:id', component: Vue.component('admin-permissions-roles-list-settings-page'),
    meta: {
      page_class: 'admin-permissions-roles-list-settings-page',
    }
  },
  {
    path: '/admin-permissions', component: Vue.component('admin-permissions-list-page'),
    meta: {
      page_class: 'admin-permissions-list-page',
    }
  },
  {
    path: '/admin-permissions/by-admin/:admin_id', component: Vue.component('admin-permissions-list-page'),
    meta: {
      page_class: 'admin-permissions-list-page',
    }
  },
  {
    path: '/admin-permissions/create', component: Vue.component('admin-permissions-form-page'),
    meta: {
      page_class: 'admin-permissions-form-page',
    }
  },
  {
    path: '/admin-permissions/edit/:id', component: Vue.component('admin-permissions-form-page'),
    meta: {
      page_class: 'admin-permissions-form-page',
    }
  },
  {
    path: '/admin-permissions/by-admin/:admin_id/edit/:id', component: Vue.component('admin-permissions-form-page'),
    meta: {
      page_class: 'admin-permissions-form-page',
    }
  },
  {
    name: 'settings.batches.edit',
    path: '/settings/batches-settings/:id/edit',
    component: Vue.component('batches-settings-compose-page'),
    meta: {
      page_class: 'batches-settings-compose-page',
    }
  },
  {
    name: 'settings.planned.language.add',
    path: '/settings/planned-language/add',
    component: Vue.component('planned-language-form'),
    meta: {
      page_class: 'planned-language-form',
    }
  },
  {
    name: 'settings.planned.language.edit',
    path: '/settings/planned-language/edit/:id',
    component: Vue.component('planned-language-form'),
    meta: {
      page_class: 'planned-language-form',
    }
  },

  {
    name: 'translators.list.tabs',
    path: '/bookings/:id/translator-list', component: Vue.component('translators-list-tabs-page'),
    meta: {
      page_class: 'batches-settings-page',
    }
  },
  {
    name: 'booking-histories-audit',
    path: '/bookings/:id/histories-audit', component: Vue.component('booking-histories-audit-page'),
    meta: {
      page_class: 'batches-settings-page',
    }
  },
  {
    name: 'comlogs.list',
    path: '/bookings/:id/com-logs', component: Vue.component('com-logs-list-page'),
    meta: {
      page_class: 'com-logs-list-page',
    }
  },
  {
    name: 'send.sms',
    path: '/messages/sms', component: Vue.component('send-sms-page'),
    meta: {
      page_class: 'send-sms-page',
    }
  },
  {
    name: 'send.fax',
    path: '/messages/fax', component: Vue.component('send-fax-page'),
    meta: {
      page_class: 'send-fax-page',
    }
  },
  {
    path: '/handesk', component: Vue.component('handesk-frame'),
    meta: {
      page_class: 'handesk-route',
    }
  },
  {
    path: '/rocketchat', component: Vue.component('rocketchat-frame'),
    meta: {
      page_class: 'rocketchat-route',
    }
  },
  {
    path: '/tawk-to', component: Vue.component('tawk-to-frame'),
    meta: {
      page_class: 'tawk-to-route',
    }
  },
  {
    path: '/statistics', component: Vue.component('statistics-frame'),
    meta: {
      page_class: 'statistics-route',
    }
  },
  // Survey-Manager
  {
    path: '/survey-manager', component: Vue.component('survey-manager-page'),
    meta: {
      page_class: 'survey-manager-page',
    }
  },
  {
    name:'survey.details',
    path:'/survey/:id',  component: Vue.component('survey-detail-page'),
    meta: {
      page_class: 'comlog'
    }
  },
  {
    name:'survey.response',
    path:'/survey/response/:id',  component: Vue.component('survey-response-page'),
    meta: {
      page_class: 'comlog'
    }
  },
  {
    name:'survey.response.detail',
    path:'/survey/response/:user/:id/:survey',  component: Vue.component('survey-response-detail-page'),
  },
  {
    name:'survey.accumulate.response.page',
    path:'/survey/accumulate/response/:id/',  component: Vue.component('survey-accumulate-response-page'),
  },
  {
    name:'survey.accumulate.individual.response.page',
    path:'/survey/accumulate/individual/response/:id/:question',  component: Vue.component('survey-accumulate-individual-response-page'),
  },

  // End Survey Routes
  {
    path: '/certificate/create', component: Vue.component('create-certificate-form'),
    meta: {
      page_class: 'create-certificate',
    }
  },
  {
    name: 'email.debug',
    path: '/com-logs/email/:id', component: Vue.component('comlog-email-view'),
    meta: {
      page_class: 'comlog',
    }
  },
  {
    name: 'fax.debug',
    path: '/com-logs/fax/:id', component: Vue.component('comlog-fax-view'),
    meta: {
      page_class: 'comlog',
    }
  },
  {
    name:'feedback.low',
    path:'/feedback/low',  component: Vue.component('low-feedback-page'),
    meta: {
      page_class: 'comlog'
    }
  },
  {
    name:'feedback.low.solved',
    path:'/feedback-low/solved',  component: Vue.component('solved-low-feedback-page'),
    meta: {
      page_class: 'comlog'
    }
  },
  {
    name:'feedback.list',
    path:'/feedbacks/:id',  component: Vue.component('feedback-list-page'),
    meta: {
      page_class: 'comlog'
    }
  },
  {
    name:'feedback.low-details',
    path:'/feedback/low/:id',  component: Vue.component('low-feedback-details-page'),
    meta: {
      page_class: 'comlog'
    },
  },

  // Batch setting template
  {
    name:'batch.settings-template',
    path:'/batch/settings/template',  component: Vue.component('batch-settings-template-page'),
    meta: {
      page_class: 'comlog'
    }
  },
  {
    path: '/batch/settings/template/add',
    component: Vue.component('batch-setting-compose-page'),
    name: 'batch.settings.template.add',

  },
  {
    path: '/batch/settings/template/edit/:id',
    component: Vue.component('batch-setting-compose-page'),
    name: 'batch.settings.template.edit',

  },

  //booking issues
  {
    name:'booking.issue.list',
    path:'/booking-issues', component:Vue.component('booking-issue-list-page'),
    meta: {
      page_class: 'booking-issue'
    }
  },
  {
    name:'booking.issue.solved-list',
    path:'/booking-issues-solved', component:Vue.component('booking-issue-list-solved-page'),
    meta: {
      page_class: 'booking-issue'
    }
  },
  {
    name:'booking.issue.pending-list',
    path:'/booking-issues-pending', component:Vue.component('booking-issue-list-pending-page'),
    meta: {
      page_class: 'booking-issue'
    }
  },
  {
    name:'booking.issue.details',
    path:'/booking-issues/:id', component:Vue.component('booking-issue-details-page'),
    meta: {
      page_class: 'booking-issue'
    }
  },
  {
    name:'booking.issue.seetting.list',
    path:'/booking-issue-settings', component:Vue.component('booking-issue-setting-list-page'),
    meta: {
      page_class: 'booking-issue-setting'
    }
  },
  {
    name:'booking.issue.tags',
    path:'/booking-issue-tags', component:Vue.component('booking-issue-tags-page'),
    meta: {
      page_class: 'booking-issue-tag'
    }
  },
  {
    name:'booking.issue.tagged-list',
    path:'/booking-issue-tagged-list/:id', component:Vue.component('booking-issue-tagged-list-page'),
    meta: {
      page_class: 'booking-issue-tagged-list'
    }
  },
  {
    name:'court-order.lookup',
    path:'/court-order', component:Vue.component('court-order-lookup-form'),
    meta: {
      page_class: 'court-order'
    }
  },

  //Messaging Centre
  {
    name:'messaging.blog',
    path:'/messaging-centre/blogs/',  component: Vue.component('blogs-page'),
    meta: {
      page_class: 'comlog'
    }
  },
  {
    name:'messaging.blog.add',
    path:'/messaging-centre/blogs/add',  component: Vue.component('create-blog-page'),
    meta: {
      page_class: 'comlog'
    }
  },

  {
    name:'messaging.blog.edit',
    path:'/messaging-centre/blogs/edit/:id',  component: Vue.component('create-blog-page'),
    meta: {
      page_class: 'comlog'
    }
  },

  {
    name:'messaging.notification',
    path:'/messaging-centre/notification',  component: Vue.component('notification-page'),
    meta: {
      page_class: 'comlog'
    }
  },

  {
    name:'messaging.notification.edit',
    path:'/messaging-centre/notification/edit/:id',  component: Vue.component('notification-compose-page'),
    meta: {
      page_class: 'comlog'
    }
  },

  {
    name:'messaging.notification.add',
    path:'/messaging-centre/notification/add',  component: Vue.component('notification-compose-page'),
    meta: {
      page_class: 'comlog'
    }
  },

  // SMS Centre
  {
    name:'sms.centre',
    path:'/sms-centre',  component: Vue.component('sms-centre-compose-page'), props: true
  },


  // Trainings
  {
    name:'trainings',
    path:'/trainings',  component: Vue.component('trainings-list-page'),
    meta: {
      page_class: 'trainings'
    }
  },
  {
    name:'trainings.edit',
    path:'/trainings/edit/:id',  component: Vue.component('trainings-compose-page'),
    meta: {
      page_class: 'trainings'
    }
  },
  {
    name:'trainings.add',
    path:'/trainings/add',  component: Vue.component('trainings-compose-page'),
    meta: {
      page_class: 'trainings'
    }
  },
  {
    name:'training.records.add',
    path:'/training-records/add/:id',  component: Vue.component('trainings-section-add'),
    meta: {
      page_class: 'trainings'
    }
  },
  {
    name:'training.records.edit',
    path:'/training-records/edit/:id',  component: Vue.component('trainings-section-add'),
    meta: {
      page_class: 'trainings'
    }
  },

  // Misc
  {
    path: '*', component: Vue.component('page-not-found'),
    meta: {
      is_public: true
    }
  },
  {
    path: '/unauthorized', component: Vue.component('page-unauthorized'),
    meta: {
      page_class: 'page-unauthorized',
    }
  },

  // Resources
  {
    name: 'resources',
    path: '/booking-resources', component: Vue.component('resources-list-page'),
    meta: {
      page_class: 'resources',
    }
  },
  {
    name: 'resources.add',
    path: '/booking-resources/add', component: Vue.component('resources-compose-page'),
    meta: {
      page_class: 'resources',
    }
  },
  {
    name: 'resources.edit',
    path: '/booking-resources/edit/:id', component: Vue.component('resources-compose-page'),
    meta: {
      page_class: 'resources',
    }
  },
  {
    name: 'resources.booking',
    path: '/booking-resources/booking/:id', component: Vue.component('resources-list-page'),
    meta: {
      page_class: 'resources',
    }
  },
  //FAQ generator
  {
    name:'faq',
    path:'/faq-page', component:Vue.component('faq-page'),
    meta: {
      page_class: 'court-order'
    }
  },
  {
    path: '/faq/:id/details', component: Vue.component('faq-details-page'),
    name: 'faq-details',
    meta: {
      page_class: 'bookings-details-route',
    }
  },
  //Translator add unvailable time
  {
    path: '/translator/unavailable-times/add/:id', component: Vue.component('translator-unavailableTimes-add'),
    name: 'translator-times-add',
    meta: {
      page_class: 'bookings-details-route',
    }
  },
  {
    path: '/translator/unavailable-times/edit/:id', component: Vue.component('translator-unavailableTimes-add'),
    name: 'translator-times-edit',
    meta: {
      page_class: 'bookings-details-route',
    }
  },
  //Employees list
  {
    path: '/employees', component: Vue.component('employees-page'),
    name: 'employees',
    meta: {
      page_class: 'bookings-details-route',
    }
  },
  {
    path: '/user/translator/employee/:id/bookings/weekly', component: Vue.component('user-translator-employee-bookings-weekly-page'),
    name: 'user.translator.employee.bookings.weekly',
    meta: {
      page_class: 'user-translator-bookings-weekly'
    }
  },
  // Emails templates
  {
    path: '/emails-templates/add', component: Vue.component('emails-templates-add'),
    name: 'emails.templates.add',
    meta: {
      page_class: 'user-translator-bookings-weekly'
    }
  },
  {
    path: '/emails-templates/edit/:id', component: Vue.component('emails-templates-add'),
    name: 'emails.templates.edit',
    meta: {
      page_class: 'user-translator-bookings-weekly'
    }
  },
  {
    path: '/verbiages/clear', component: Vue.component('verbiages-clear'),
  },



] // End of default
