'use strict';

export default {

  resetForm (state) {
    state.form = window._.cloneDeep(state.formFresh);
  },

  setList (state, payload) {
    state.list.data = payload.data;
    state.list.pagination = payload.meta.pagination;
  },

  setListLoading (state, payload) {
    state.list.loading = payload;
  },

  setItemData (state, payload) {
    state.item.data = payload;
  },

  setItemLoading (state, payload) {
    state.item.loading = payload;
  },

}