<template>
  <div class="app-page" :class="getBem(blockClass)">
    <page-header
      title="Batches"
      :linkData="{
              text : this.$route.params.id,
              path : 'booking/' + this.$route.params.id + '/details',
              preText : '(#',
              postText : ')'
            }"
      :back-enabled="true"
      :back-cb="handleClickBack"
    />

    <div class="app-container">
      <div class="app-wrapper">
        <user-in-booking-feature :bookingId="bookingId" :userId="userId"></user-in-booking-feature>
        <div class="app-row">
          <div class="app-col full-width" :class="getBem(blockClass, 'tabs-container')">

            <el-tabs v-model="activeName" type="bordered-card" @tab-click="handleClick">
              <el-tab-pane :label="$t('tfv_all_translators')" name="all-translators">
                <translators-batch-list></translators-batch-list>
              </el-tab-pane>
              <el-tab-pane :label="$t('has_booking_translator')" name="fourth">
                <has-booking-translator-list></has-booking-translator-list>
              </el-tab-pane>
              <el-tab-pane :label="$t('translators_tries_accept')" name="five">
                <translators-tries-to-accept-list></translators-tries-to-accept-list>
              </el-tab-pane>
            </el-tabs>
          </div> <!-- /.app-col -->
        </div> <!-- /.app-row -->
      </div> <!-- /.app-wrapper -->
    </div> <!-- /.app-container -->
  </div> <!-- /.app-page translator-list-page -->
</template>

<script>
  import BreakToBatches from '~/components/lists/batches/BreakToBatchesList';
  import BlockedTranslatorsList from '~/components/lists/batches/BlockedTranslatorsList';
  import TranslatorsBatchList from '~/components/lists/batches/TranslatorsBatchList';
  import TranslatorsTriesToAcceptList from '~/components/lists/batches/TranslatorsTriesToAcceptList';
  import HasBookingTranslatorList from '~/components/lists/batches/HasBookingTranslatorList';
  import {mapActions} from 'vuex';
  export default {
    components: {
      BreakToBatches,
      TranslatorsTriesToAcceptList,
      TranslatorsBatchList,
      BlockedTranslatorsList,
      HasBookingTranslatorList
    },
    computed : {
        bookingId () {
        return !window._.isNil(this.$route.params['id'])
          ? parseInt(this.$route.params['id'])
          : 0;
      },

        userId(){
            return JSON.parse(localStorage.getItem("app_user_data")).id
        },

    },
    data () {
      return {
        activeName: 'all-translators',
        blockClass: 'translator-list-tabs-page'
      };
    },
    methods: {
      handleClick (tab, event) {
        this.$router.push({path: this.$route.path, query: {'tab': tab.name}});
      },
       ...mapActions({
          deleteUserInsideBooking: 'booking/deleteUserInsideBooking',
      }),

      /**
       * method for handling back to the booking details
       *
       * @return {void}
       */
      handleClickBack () {
        this.$router.push({
          name: "booking-details",
          params: {id: this.$route.params.id}
        });
      }
    },
    mounted () {
      if (!_.isEmpty(this.$route.query) && _.has(this.$route.query, 'tab')) {
        this.activeName = this.$route.query.tab;
      }
    },
     beforeRouteLeave (to, from, next) {
            let data = {
                booking_id : this.bookingId,
                user_id : this.userId,
            }
            this.deleteUserInsideBooking(data)
            next(true);
        },
  };
</script>
