<template>
  <div class="app-fields financial-business-rules-fields">

    <!-- Parts -->
    <el-form-item v-if="isUsePartsEnabled"
                  :id="genContainerId('parts_step',formElId)"
                  :label="$t('parts')" class="label-half-width">

      <el-input :id="genFieldId('parts_step',formElId)"
                v-model="iValue.parts_step"/>
    </el-form-item>

    <!-- Max Time -->
    <el-form-item v-if="isUsePartsEnabled"
                  :id="genContainerId('parts_max',formElId)"
                  prop="max_time" :label="$t('max_time')" class="label-half-width">

      <el-input :id="genFieldId('parts_max',formElId)"
                v-model="iValue.parts_max"/>
    </el-form-item>

    <!-- Rounding Minutes -->
    <el-form-item :id="genContainerId('rounding_minutes',formElId)"
                  :label="$t('rounding_minutes')" class="label-half-width">

      <el-input :id="genFieldId('rounding_minutes',formElId)"
                v-model="iValue.rounding_minutes" />
    </el-form-item>

    <template v-for="(v) in bookingTypes">
      <hr class="hr-type1">

      <h3>{{ genBookingTypeHeader(v) }}</h3>

      <!-- Parts -->
      <el-form-item :id="genContainerId( v + 'parts_timings_parts_step',formElId)"
                    label="Parts" class="label-half-width">

        <el-input :id="genFieldId(v + 'parts_timings_parts_step',formElId)"
                  v-model="iValue.parts_timings[v].parts_step" />
      </el-form-item>

      <!-- Max Time -->
      <el-form-item :id="genContainerId( v + 'parts_timings_parts_max',formElId)"
                    prop="max_time" :label="$t('max_time')" class="label-half-width">

        <el-input :id="genFieldId( v + 'parts_timings_parts_max',formElId)"
                  v-model="iValue.parts_timings[v].parts_max" />
      </el-form-item>

      <!-- Minimum charging time -->
      <el-form-item :id="genContainerId( v + 'booking_timings_minimum_hours',formElId)"
                    class="label-half-width">
        <template slot="label">
          {{$t('minimum_charging_time')}}
          <span v-if="false" class="app-badge badge-success">{{v}}</span>
        </template>

        <el-input :id="genFieldId( v + 'booking_timings_minimum_hours',formElId)"
                  v-model="iValue.booking_timings[v].minimum_hours"/>
      </el-form-item>

      <!-- Increment in Billing -->
      <el-form-item :id="genContainerId(v + 'booking_timings_succeeding_hours',formElId)"
                    class="label-half-width">
        <template slot="label">
          {{$t('text_after_minimum_charging_time')}}
        </template>

        <el-input :id="genFieldId(v + 'booking_timings_succeeding_hours',formElId)"
                  v-model="iValue.booking_timings[v].succeeding_hours"/>
      </el-form-item>

      <!-- Late Cancellation time Physical -->
      <el-form-item v-if="false"
                    :id="genContainerId(v + 'late_cancellation_rules_hours',formElId)"
                    prop="physical_cancel_time" class="label-half-width">
        <template slot="label">
          {{$t('late_cancellation_time')}}
        </template>

        <el-input :id="genFieldId(v + 'late_cancellation_rules_hours',formElId)"
                  v-model="iValue.late_cancellation_rules[v].hours"/>
      </el-form-item>
    </template>

  </div> <!-- /.app-fields financial-business-rules-fields -->
</template>

<script>
  import {BOOKING_TYPES} from '~/js/store/financial/settings/shared/props/constants';
  import remove from 'lodash/remove';
  import lowerCase from 'lodash/lowerCase';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      formElId: {
        type: String,
        default: 'finance_business_rules_form'
      },

      isUsePartsEnabled: {
        type: Boolean,
        default: true,
      },

      value: {
        type: Object,
        default() {
          return {
            // Please refer to the store for the format.
            // store > financial > shared >
            // props > businessRules
          }
        }
      },

      isEnabledEmergencyCharges: {
        type: Boolean,
        default: false,
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Returns collection of appropriate booking types.
       *
       * @returns {string[]}
       */
      bookingTypes () {
        let cn = [...BOOKING_TYPES];

        if (!this.isEnabledEmergencyCharges) {
          remove(cn, (v) => v === 'phone_immediate');
        }

        return cn;
      },

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * Helper method for generating header for Booking Type Sections.
       *
       * @param {string} v - the value to be evaluated.
       *
       * @return {string}
       */
      genBookingTypeHeader(v) {
        const locale = window.app_locale;
        return locale === 'se' ?
          this.$t(v) + ' ' + lowerCase(this.$t('booking')) :
          this.$t(v) + ' ' + this.$t('booking');
      }
    }, // End of Component > methods

  } // End of export default
</script>
