<template>
    <div class="app-display" :class="genBem(blockCls)">

        <!-- Header -->
        <div v-if="chartHeaders.length > 0"
             :class="genBem(blockCls, 'header')">

            <div v-for="header in chartHeaders"
                 :class="genBem(blockCls, 'header-item')"
                 :style="{width: header.style.width}">
                <span class="content-label">{{ header.date.name }}</span>
                <span class="content-date">{{ header.date.formatted }}</span>
            </div>

        </div> <!-- header -->

        <!-- Chart -->
        <div :class="genBem(blockCls, 'chart')" data-simplebar>

            <div :class="genBem(blockCls, 'chart-wrapper')">

                <!-- Labels -->
                <div :class="genBem(blockCls, 'chart-labels-wrapper')">
                    <!-- Label -->
                    <div v-for="label in chartLabels"
                         :class="genBem(blockCls, 'chart-label')"
                         :style="{height: label.style.height}">
                        <span>{{ label.time.name }}</span>
                    </div>
                </div> <!-- chart-labels-wrapper -->

                <!-- Cells -->
                <div :class="genBem(blockCls, 'chart-cells-wrapper')">

                    <div v-for="label in chartLabels"
                         :class="genBem(blockCls, 'chart-row')">

                        <!-- Cells -->
                        <div v-for="header in chartHeaders"
                             :class="genBem(blockCls, 'chart-cell')"
                             :style="{
                            width: header.style.width,
                            height: label.style.height
                         }">
                        </div>

                    </div> <!-- chart-row -->

                    <!-- Booking Bars -->
                    <bookings-weekly-chart-bar
                            v-if="formattedBookings.length > 0"
                            v-for="obj in formattedBookings"
                            :key="obj.booking.id"
                            @click.native.stop="handleToggleSide('show', obj.booking)"
                            @assign="(b) => {$emit('assign', b)}"
                            :booking="obj.booking"
                            :style="getBarStyle(obj.position)"
                    />
                    <!-- End of Potential Bars -->

                </div> <!-- chart-cells-wrapper -->

            </div> <!-- chart-wrapper -->
        </div> <!-- chart -->

        <transition name="side-info-transition">
            <bookings-weekly-chart-side-info
                    v-if="showSide"
                    :booking="sideInfo"
                    @close="handleToggleSide('hide')"/>
        </transition>

    </div> <!-- template wrapper -->
</template>

<script>
  import BookingsWeeklyChartBar from '~/components/displays/booking-weekly/BookingsWeeklyChartBar'
  import BookingsWeeklyChartSideInfo from '~/components/displays/booking-weekly/BookingsWeeklyChartSideInfo'

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      BookingsWeeklyChartBar,
      BookingsWeeklyChartSideInfo
    },

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      cellHeight: {
        type: Number,
        default: 80
      },

      range: {
        type: Object,
        required: true,
        default () {
          return {
            start: null, // Should be a Moment object
            end: null // Should be a Moment object
          }
        }
      },

      bookings: {
        type: Object,
        default () {
          return {
            potential: [],
            assigned: [],
            planned: []
          }
        }
      },

      startingTime: {
        type: String,
        default: '08:00'
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'bookings-weekly',
        week: '',
        xDistance: 100 / 7,

        showSide: false,
        sideInfo: {}
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Method to return the day headers for the chart.
       *
       * @returns {Array} - collection of header objects.
       */
      chartHeaders () {
        let headers = []; // Prepare container..

        // If the ranges are not provided, just return an empty array.
        if (this.range.start === null || this.range.end === null) return headers;

        let start = this.range.start.clone();
        let end = this.range.end.clone();
        let distance = this.xDistance; // Distance in percentage.

        let index = 0;

        // Iterate tru range, and collect the time prints.
        for (let m = start; m.isBefore(end) || m.isSame(end); m.add('1', 'day')) {
          headers.push({
            date: {
              name: m.format('dddd'),
              actual: m.format('YYYY-MM-DD'),
              formatted: m.format('MMM DD')
            },
            style: {
              x: `${distance * index}%`,
              width: `${distance}%`
            }
          });

          index++;
        }

        return headers;
      },

      /**
       * Method to generate and get the times to be used as labels.
       *
       * @returns {Array} - collection of label objects.
       */
      chartLabels () {
        let labels = [];

        // Add Zeroes to the hour string if it lacks of it.
        const time = window._.padStart(this.startingTime, 5, '0');

        let start = window.moment(time, 'HH:mm');
        let end = window.moment(time, 'HH:mm').add('23', 'hours');

        let index = 0;
        for (let m = start; m.isBefore(end) || m.isSame(end); m.add('1', 'hour')) {
          labels.push({
            time: {
              index,
              name: m.format('HH:mm')
            },
            style: {
              y: `${this.cellHeight * index}px`,
              height: `${this.cellHeight}px`
            }

          });

          index++;
        }

        return labels;
      },

      /**
       * Returns the collection of formatted bookings to be mapped into the chart.
       *
       * @return {array}
       */
      formattedBookings () {
        // Prepare container.
        let bookings = [];

        // Combine bookings
        let raw = window._.concat(this.bookings.potential, this.bookings.assigned,this.bookings.planned);
        // Sort the bookings
        const dueParser = (b) => window.moment(b.due, 'YYYY-MM-DD hh:mm:ss').toDate();
        const sorted = window._.orderBy(raw, [dueParser, 'duration'], ['asc', 'desc']);

        // Format the booking properties and positioning.
        window._.each(sorted, (booking, index) => {

          // Define start and end.
          const start = window.moment(booking.due, 'YYYY-MM-DD HH:mm:ss');
          const end = start.clone().add(booking.duration, 'minutes');

          let obj = {
            booking: {
              ...booking,
              start_duration: start,
              end_duration: end,
              start_duration_label: start.format('HH:mm'),
              end_duration_label: end.format('HH:mm')
            },
            position: this.getBarPosition(start, end)
          };

          // Adjust the position if there are overlaps
          if (index > 0) {
            const prev = bookings[index - 1];

            // Compare if the previous one overlaps with the current booking.
            if (
              prev.booking.start_duration.isSame(obj.booking.start_duration)
              || prev.booking.start_duration.isBetween(obj.booking.start_duration, obj.booking.end_duration)
              || prev.booking.end_duration.isBetween(obj.booking.start_duration, obj.booking.end_duration)) {

              // Get the offset point
              obj.position.overlapOffset = prev.position.overlapOffset + 1;

              // Adjust the bar position if there is an overlap
              obj.position.x = obj.position.x + obj.position.overlapOffset;
              obj.position.width = obj.position.width - obj.position.overlapOffset;
            }
          }

          // Collect the object with properties and position.
          bookings.push(obj);
        });

        return bookings;
      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * Method to handle the toggling of Side Info.
       *
       * @param mode - varies between 'show' || 'hide'.
       * @param [booking = {}] - contains the targeted booking.
       * @return {void}
       */
      handleToggleSide (mode, booking = {}) {
        if (mode === 'show') {

          // If the side-info bar is not shown, show it.
          if (!this.showSide) this.showSide = true;

          // Set the booking details to display.
          this.sideInfo = booking;
          console.log('Booking Details:', this.sideInfo);

        } else if (mode === 'hide' && this.showSide) {
          this.showSide = false;
          this.sideInfo = {};
        }
      },

      /**
       * Method to return the position for the booking
       *
       * @param {object} start - Moment Object which indicates the start of session.
       * @param {object} end - Moment Object which indicates the end of session.
       * @return {Object}
       */
      getBarPosition (start, end) {
        // Define distances.
        const xDistance = this.xDistance;

        // Define more specific formats.
        const startTime = start.format('HH:mm');
        const endTime = end.format('HH:mm');
        const day = start.format('e');

        // Get the positioning.
        return {
          x: (day - 1) * xDistance,
          width: xDistance,
          start: this.getTimePosition(startTime),
          end: this.getTimePosition(endTime),
          overlapOffset: 0
        }

      },

      /**
       * Method to generate a style object compatible for chart bars.
       *
       * @param position
       * @returns {object}
       */
      getBarStyle (position) {
        return {
          left: `${position.x}%`,
          top: `${position.start}px`,
          width: `${position.width}%`,
          height: `${position.end - position.start}px`,
          zIndex: 0
        }
      },

      /**
       * Helper method for getting the appropriate bar type for the booking.
       *
       * @param {object} booking
       * @return {string}
       */
      getBarType (booking) {
        return booking.status.code === 'pending' ? 'potential' : 'assigned';
      },

      /**
       * Get the computed position based on the time given.
       *
       * @param {string} time
       * @returns {number}
       */
      getTimePosition (time) {
        // Define the times.
        const times = this.chartLabels;

        const timeSplit = time.split(':');
        // Split the hour section of the string.
        const hour = `${timeSplit[0]}:`; // Include the semicolon for more accurate searching.
        const minute = timeSplit[1];

        // Find the hour in the times
        const found = window._.find(times, (o) => window._.includes(o.time.name, hour));

        // Compute the offset for the minute.
        // Get the percentage of the defined minute vs full minute (60).
        const percentage = parseInt(minute) / 60;
        // Get the percentage equivalent for the cellHeight
        const portion = this.cellHeight * percentage;

        // Add the Time Y Position and the percentage portion.
        return parseInt(found.style.y) + portion;
      },



    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {}


  }
</script>
