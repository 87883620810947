const verbiageBaseUrl = process.env.MIX_VERBIAGE_MS_API || 'https://fathomless-cove-lwlek4w4iksx.vapor-farm-a1.com';

export default {

  /**
   * @param context
   * @returns {Promise}
   */
  loadSet (context) {
    context.commit('setIsLoadingVerbiages', true);

    return new Promise((resolve, reject) => {

      const method = 'GET';
      const url = `${verbiageBaseUrl}/api/v3/generate`;
      const params = {locales: 'se,en'};

      window.axios({method, url, params})
            .then((r) => {
              context.commit('setVerbiages', r.data);
              resolve(r);
            })
            .catch((e) => {
              console.error(e);
              reject(e);
            })
            .finally(() => {
              context.commit('setIsLoadingVerbiages', false);
            });
    });
  },

  /**
   * @param context
   * @returns {Promise<any> | Promise}
   */
  getLastUpdate (context) {
    context.commit('setIsLoadingLastUpdate', true);

    return new Promise((resolve, reject) => {

      const method = 'GET';
      const url = `${verbiageBaseUrl}/api/v3/last-update`;

      window.axios({method, url})
            .then((r) => {
              context.commit('setNewLastUpdate', r.data);
              resolve(r);
            })
            .catch((e) => {
              console.error(e);
              reject(e);
            })
            .finally(() => {
              context.commit('setIsLoadingLastUpdate', false);
            });
    });
  },

  /**
   * @param context
   * @returns {void}
   */
  initialize (context) {
    context.dispatch('getLastUpdate')
           .then(() => {
             if (context.getters['isThereAnUpdate']) {
               context.commit('setLastUpdate', context.state.newLastUpdate);

               context.dispatch('loadSet').then(() => {
                 if (location != null) {
                   location.reload();
                 }
               });
             }
           });
  },

  /**
   * @param context
   */
  clear (context) {
    localStorage.removeItem(context.state.verbiagesCookieKey);
    localStorage.removeItem(context.state.lastUpdateCookieKey);
  }
};
