<template>
  <div class="app-page" :class="blockClass">
    <page-header :title="$t('booking_availability')"
                 :linkData="{
                text : this.$route.params.id,
                path : 'booking/' + this.$route.params.id + '/details',
                preText : '(#',
                postText : ')'
            }"
                 :back-enabled="true" />

        <div class="app-container">
            <div class="app-wrapper">
              <user-in-booking-feature :bookingId="bookingId" :userId="userId"></user-in-booking-feature>
                <div class="app-row">
                    <div class="app-col">

            <a
              :class="getBem(blockClass, 'batches-list-link')"
              @click.prevent="handleClickGotoBatchesList"
              href="#"
            >
              {{$t('go_to_batches_list')}}
            </a>

            <booking-availability-scheduler :booking-id="bookingId" />

          </div> <!-- /.app-col -->
        </div> <!-- /.app-row -->

      </div> <!-- /.app-wrapper -->
    </div> <!-- /.app-container -->

  </div> <!-- /.app-page booking-availability-page -->
</template>

<script>
  import BookingAvailabilityScheduler from '~/components/displays/booking-availability/BookingAvailabilityScheduler';
  import {mapActions} from 'vuex';
  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      BookingAvailabilityScheduler
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockClass: 'booking-availability-page'
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Returnns the Booking ID according the the route's param.
       * @returns {number}
       */
      bookingId () {
        return !window._.isNil(this.$route.params.id)
          ? this.$route.params.id
          : 0;
      },
      userId(){
          return JSON.parse(localStorage.getItem("app_user_data")).id
      },
    },

    methods: {
      ...mapActions({
        deleteUserInsideBooking: 'booking/deleteUserInsideBooking',
      }),

      /**
       * @returns {void}
       */
      handleClickGotoBatchesList () {
        this.$router.push({
          name: 'booking.details.translator',
          params: {id: this.bookingId}
        });
      }
    },

    beforeRouteLeave (to, from, next) {
          let data = {
              booking_id : this.bookingId,
              user_id : this.userId,
          }
          this.deleteUserInsideBooking(data)
          next(true);
      }
  } // End of export default
</script>

<style lang="scss" scoped>
  .booking-availability-page {
    &__batches-list-link {
      display: inline-block;
      padding: 7px 7px;
      font-size: 12px;
      font-weight: bold;
      color: #575757;
      background-color: #eaeaea;
      border-bottom: 1px solid darken(#eaeaea, 5%);
      border-radius: 3px;

      &:hover, &:focus, &:visited, &:active {
        color: #575757;
      }
    }
  }
</style>
