<template>
  <div class="app-fields financial-travel-settings-fields">

    <!-- --------------------------------------------- -->
    <!-- Pay Travel Distance? -->
    <!-- --------------------------------------------- -->
    <el-form-item :id="genContainerId('pay_travel_distance',formElId)"
                  :label="$t('km-reimbursement')" class="label-fluid">

      <el-switch :id="genFieldId('pay_travel_distance',formElId)"
                 v-model="iValue.pay_travel_distance"/>
    </el-form-item>

    <template v-if="iValue.pay_travel_distance">

      <!-- Maximum KM -->
      <el-form-item :id="genContainerId('maximum_km',formElId)"
                    :label="$t('maximum_km')" class="label-fluid">

        <el-input :id="genFieldId('maximum_km',formElId)"
                  v-model="iValue.maximum_km"/>
      </el-form-item>

      <!-- Minimum Payable KM -->
      <el-form-item :id="genContainerId('minimum_travel_distance_fee',formElId)"
                    :label="$t('minimum_travel_distance_fee')" class="label-fluid">

        <el-input :id="genFieldId('minimum_travel_distance_fee',formElId)"
                  v-model="iValue.minimum_travel_distance_fee"/>
      </el-form-item>

      <hr class="app-hr hr-type1">

      <template v-for="v in formattedTranslatorLevelOpts">

        <!-- Non Taxable Travel Distance Fees -->
        <el-form-item :key="`non_taxable_travel_distance_fees_${v.code}`"
                      :id="genContainerId(`non_taxable_travel_distance_fees_${v.code}`,formElId)"
                      class="label-fluid">
          <template slot="label">
            {{$t('non_taxable_travel_distance_fee_for')}}
            <span class="app-badge badge-success">{{ $t('tlvl_' + v.code) }}</span>
          </template>

          <el-input :id="genFieldId(`non_taxable_travel_distance_fees_${v.code}`,formElId)"
                    v-model="iValue.travel_distance_fees[v.code].fee_per_km"/>
        </el-form-item>

        <!-- Taxable Travel Distance Fees -->
        <el-form-item :key="`taxable_travel_distance_fees_${v.code}`"
                      :id="genContainerId(`taxable_travel_distance_fees_${v.code}`,formElId)"
                      class="label-fluid">
          <template slot="label">
            {{$t('taxable_travel_distance_fee_for')}}
            <span class="app-badge badge-success">{{ $t('tlvl_' + v.code) }}</span>
          </template>

          <el-input :id="genFieldId(`taxable_travel_distance_fees_${v.code}`,formElId)"
                    v-model="iValue.travel_distance_fees[v.code].taxable_fee_per_km"/>
        </el-form-item>

        <hr class="app-hr hr-type1">

      </template>

    </template>

    <!-- --------------------------------------------- -->
    <!-- Pay Travel Time -->
    <!-- --------------------------------------------- -->
    <el-form-item :id="genContainerId('pay_travel_time',formElId)"
                  :label="$t('pay_travel_time')" class="label-fluid">

      <el-switch :id="genFieldId('pay_travel_time',formElId)"
                 v-model="iValue.pay_travel_time"/>
    </el-form-item>

    <template v-if="iValue.pay_travel_time">

      <!-- Travel Time Chunk Hours -->
      <el-form-item :id="genContainerId('travel_time_chunk_hours',formElId)"
                    :label="$t('travel_time_chunk_hours')" class="label-fluid">

        <el-input :id="genFieldId('travel_time_chunk_hours',formElId)"
                  v-model="iValue.travel_time_chunk_hours"/>
      </el-form-item>

      <!-- --------------------------------------------- -->
      <!-- Pay Fixed Time -->
      <!-- --------------------------------------------- -->

      <!-- Pay fixed time? -->
      <el-form-item :id="genContainerId('pay_fixed_time',formElId)"
                    :label="$t('pay_fixed_time')" class="label-fluid">

        <el-switch :id="genFieldId('pay_fixed_time',formElId)"
                   v-model="iValue.pay_fixed_time"/>
      </el-form-item>

      <template v-if="iValue.pay_fixed_time">

        <!-- Fixed Time Chunk Hours -->
        <el-form-item :id="genContainerId('fixed_time_hours',formElId)"
                      :label="$t('fixed_time_hours')" class="label-fluid">

          <el-input :id="genFieldId('fixed_time_hours',formElId)"
                    v-model="iValue.fixed_time_hours"/>
        </el-form-item>

      </template>

      <!-- --------------------------------------------- -->
      <!-- Round Travel Time -->
      <!-- --------------------------------------------- -->

      <!-- Pay round travel time? -->
      <el-form-item :id="genContainerId('round_travel_time',formElId)"
                    :label="$t('pay_round_travel_time')" class="label-fluid">

        <el-switch :id="genFieldId('round_travel_time',formElId)"
                   v-model="iValue.round_travel_time"/>
      </el-form-item>

      <template v-if="iValue.round_travel_time">

        <!-- Rounding minutes -->
        <el-form-item :id="genContainerId('rounding_minutes',formElId)"
                      :label="$t('rounding_minutes')" class="label-fluid">

          <el-input :id="genFieldId('rounding_minutes',formElId)"
                    v-model="iValue.rounding_minutes"/>
        </el-form-item>

      </template>

      <!-- Unpaid Travel Hours -->
      <el-form-item :id="genContainerId('unpaid_travel_hours',formElId)"
                    :label="$t('unpaid_travel_hours')" class="label-fluid">

        <el-input :id="genFieldId('unpaid_travel_hours',formElId)"
                  v-model="iValue.unpaid_travel_hours"/>
      </el-form-item>

      <!-- Minimum Travel Hours -->
      <el-form-item :id="genContainerId('minimum_travel_hours',formElId)"
                    :label="$t('minimum_travel_hours')" class="label-fluid">

        <el-input :id="genFieldId('minimum_travel_hours',formElId)"
                  v-model="iValue.minimum_travel_hours"/>
      </el-form-item>

      <!-- Maximum Travel Hours -->
      <el-form-item :id="genContainerId('maximum_travel_hours',formElId)"
                    :label="$t('maximum_travel_hours')" class="label-fluid">

        <el-input :id="genFieldId('maximum_travel_hours',formElId)"
                  v-model="iValue.maximum_travel_hours"/>
      </el-form-item>

      <!-- Travel Time Fees -->
      <el-form-item v-for="v in formattedTranslatorLevelOpts"
                    :key="`travel_time_fees_${v.code}`"
                    :id="genContainerId(`travel_time_fees_{v.code}`,formElId)"
                    class="label-fluid">
        <template slot="label">
          {{$t('travel_time_fee_for')}}
          <span class="app-badge badge-success">{{ $t('tlvl_' + v.code) }}</span>
        </template>

        <el-input :id="genFieldId(`travel_time_fees_${v.code}`,formElId)"
                  v-model="iValue.travel_time_fees[v.code].fee_per_hour"/>
      </el-form-item>

    </template>

    <!-- --------------------------------------------- -->
    <!-- Pay Inconvenience Travel Time -->
    <!-- --------------------------------------------- -->

    <!-- Pay inconvenience travel time? -->
    <el-form-item :id="genContainerId('pay_inconvenience_travel_time',formElId)"
                  :label="$t('pay_inconvenience_travel_time')" class="label-fluid">

      <el-switch :id="genFieldId('pay_inconvenience_travel_time',formElId)"
                 v-model="iValue.pay_inconvenience_travel_time"/>
    </el-form-item>

    <template v-if="iValue.pay_inconvenience_travel_time">

      <!-- Inconvenience Travel Time Fees -->
      <el-form-item v-for="v in formattedTranslatorLevelOpts"
                    :key="`inconvenience_travel_time_fees_${v.code}`"
                    :id="genContainerId(`inconvenience_travel_time_fees_{v.code}`,formElId)"
                    class="label-fluid">
        <template slot="label">
          {{$t('Inconv_travel_time_for')}}
          <span class="app-badge badge-success">{{ $t('tlvl_' + v.code) }}</span>
        </template>

        <el-input :id="genFieldId(`inconvenience_travel_time_fees_${v.code}`,formElId)"
                  v-model="iValue.inconvenience_travel_time_fees[v.code].fee_per_half_hour"/>
      </el-form-item>

    </template>

    <!-- Pay same town travel time? -->
    <el-form-item
        :id="genContainerId('pay_same_town_travel_time',formElId)"
        :label="$t('pay_same_town_travel_time')" class="label-fluid"
    >
      <el-switch
          :id="genFieldId('pay_same_town_travel_time',formElId)"
          v-model="iValue.pay_same_town_travel_time"
      />
    </el-form-item>

    <template v-if="!iValue.pay_same_town_travel_time">
      <el-form-item :id="genContainerId('specific_cities',formElId)">
        <financial-travel-time-specific-cities-field
            :id="genFieldId('specific_cities',formElId)"
            v-model="iValue.specific_cities"
        />
      </el-form-item>

    </template>


  </div> <!-- /.app-fields finance-travel-settings-fields -->
</template>

<script>
  import {mapGetters} from "vuex";
  import FinancialTravelTimeSpecificCitiesField
    from '~/components/forms/financial/price/shared/travel-time/FinancialTravelTimeSpecificCitiesField.vue';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      FinancialTravelTimeSpecificCitiesField,
    },

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      formElId: {
        type: String,
        default: ''
      },

      value: {
        type: Object,
        default() {
          return {};
        }
      } // End of value

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data() {
      return {};
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters({
        formattedTranslatorLevelOpts: 'translatorLevel/formattedTranslatorLevelOpts',
      }),

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {}, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted() {}, // End of Component > mounted

  } // End of export default
</script>
