<template>
  <div class="holidays-list">

    <div class="holidays-list__top">
      <el-button type="success" @click="addHoliday">
        <i class="fa fa-plus"></i>
        <span>{{$t('add')}}</span>
      </el-button>
    </div>

    <el-table :data="listData">
      <el-table-column :label="$t('name')">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('start_date')">
        <template slot-scope="scope">
          {{ scope.row.start_date }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('end_date')">
        <template slot-scope="scope">
          {{ scope.row.end_date }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('actions')">
        <template slot-scope="scope">
          <el-button class="circle" type="success" title="Edit" @click="editHoliday(scope)">
            <span class="fa fa-pencil"></span>
          </el-button>
          <el-button class="circle" type="danger" title="Remove" @click="deleteHoliday(scope)">
            <span class="fa fa-trash"></span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="listPagination.total > listPagination.per_page"
      layout="prev, pager, next"
      :page-size="listPagination.per_page"
      :total="listPagination.total"
      :current-page="listPagination.current_page"
      @current-change="reloadList"
    />

    <holiday-dialog ref="addDialog" @updated="handleUpdated" />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { populate } from '~/js/helpers/Vuex'
  // components
  import HolidayDialog from './HolidayDialog'

  const moduleTarget = 'holidays'

  export default {
    components: {
      HolidayDialog
    },

    /*
    |--------------------------------------------------------------------------
    | Components > props
    |--------------------------------------------------------------------------
    */
    props: {}, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Components > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters(moduleTarget, ['listData', 'listPagination'])

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Components > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions(moduleTarget, [
        'browse',
        'delete'
      ]),
      addHoliday () {
        this.$refs.addDialog.open()
      },
      editHoliday (scope) {
        this.$refs.addDialog.open(scope.row)
      },
      deleteHoliday (scope) {
        this.$confirm('This will permanently delete the holiday. Continue?', this.$t('warning'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.delete({ id: scope.row.id })
        }).catch(() => {
          // Silence is golden.
        });
      },
      handleUpdated () {
        this.browse()
      }
    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Components > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      populate(this, 'browse', 'listData', {
        force: true,
        ref: 'Holidays Listing'
      })
    }
  }
</script>

<style lang="scss">
  .holidays-list {
    &__top {
      text-align: right;
      padding-bottom: 10px;
    }
  }
</style>
