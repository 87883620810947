<template>
    <div class="app-fields batches-settings-shared-fields">

        <h3>{{ btype.name }} Bookings</h3>

        <!-- Distance -->
        <el-form-item v-if="btype.code == 'physical'"
                      :id="genContainerId('distance',formElId)"
                      label="Distance" class="label-medium">
            <el-input :id="genFieldId('distance',formElId)"
                      v-model="iValue.distance"/>
        </el-form-item>

        <!-- Feedback -->
        <el-form-item :id="genContainerId('feedback',formElId)" label="Feedback" class="label-medium">
            <el-input :id="genFieldId('feedback',formElId)"
                      v-model="iValue.feedback"/>
        </el-form-item>

        <!-- Customer's Feedback -->
        <el-form-item :id="genContainerId('customer_feedback',formElId)" label="Customer Feedback"
                      class="label-medium">
            <el-input :id="genFieldId('customer_feedback',formElId)"
                      v-model="iValue.customer_feedback"/>
        </el-form-item>

        <!-- Late Cancellation -->
        <el-form-item :id="genContainerId('late_cancellation',formElId)" label="Late Cancelation"
                      class="label-medium">
            <el-input :id="genFieldId('late_cancellation',formElId)"
                      v-model="iValue.late_cancellation"/>
        </el-form-item>

        <!-- Translator's Preferences -->
        <el-form-item :id="genContainerId('translators_preferences',formElId)"
                      label="Translator's Preferences" class="label-medium">
            <el-input :id="genFieldId('translators_preferences',formElId)"
                      v-model="iValue.translators_preferences"/>
        </el-form-item>

        <!-- Translator's Levels -->
        <el-form-item :id="genContainerId('translators_levels',formElId)" label="Translator's Levels"
                      class="label-medium" v-show="iValue.translators_levels_mode!=1">
            <el-input :id="genFieldId('translators_levels',formElId)"
                      v-model="iValue.translators_levels"/>
        </el-form-item>

        <!-- Translator's Levels Mode -->
        <el-form-item :id="genContainerId('translators_levels_mode',formElId)"
                      label="Translators levels mode" class="label-medium">
            <el-radio v-model="iValue.translators_levels_mode" label="hard"
                      :id="genFieldId('translators_levels_mode_hard',formElId)">
                Hard
            </el-radio>
            <el-radio v-model="iValue.translators_levels_mode" label="soft"
                      :id="genFieldId('translators_levels_mode_soft',formElId)">
                Soft
            </el-radio>
        </el-form-item>

        <hr class="hr-type1">

        <h5>Translator Levels priority</h5>

        <el-form-item v-for="(v) in translatorLevelOpts"
                      :key="v.id"
                      :id="genContainerId('translator_levels_' + btype.code + '_' + v.code ,formElId)"
                      :label="v.name"
                      class="label-medium">

            <el-input :id="genFieldId('translator_levels_' + btype.code + '_' + v.code ,formElId)"
                      v-model="iValue.translator_levels[v.code]"/>

        </el-form-item>

    </div> <!-- /.app-fields batches-settings-shared-fields -->
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import {populate} from "../../../js/helpers/Vuex";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            formElId: {
                type: String,
                default: 'batches-settings-shared-fields',
            },

            btype: {
                type: Object,
                default: {
                    code: 'physical',
                    name: 'Physical',
                },
            },

            value: {
                type: Object,
                default() {
                    return {
                        distance: '',
                        feedback: '',
                        customer_feedback: '',
                        late_cancellation: '',
                        translator_levels: {
                            deluxe: '',
                            layman: '',
                            certified: '',
                            read_courses: '',
                            certified_in_law: '',
                            certified_in_health: '',
                        },
                        translators_levels: '',
                        translators_levels_mode: 'soft',
                        translators_preferences: '',
                    }
                }
            },

        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {};
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapGetters('translatorLevel', {
                translatorLevelOpts: 'listAsOptions',
            }),

            /**
             * Interface for the v-model of this component.
             */
            iValue: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                },
            },

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            ...mapActions('translatorLevel', {
                loadTranslatorLevels: 'browseAsOptions'
            }),

            ...mapActions('batchesSettings', {
                readEntry: 'read'
            }),

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {

            // Communicate to the API for reading the current entry.
            this.readEntry({id: this.$route.params.id});

            // Populate the following options.
            populate(this, 'loadTranslatorLevels', 'translatorLevelOpts', {
                ref: 'Translator Level Listing',
            });

        }, // End of Component > mounted

    } // End of export default
</script>
