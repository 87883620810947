<template>
  <div class="app-page-header">

    <el-button v-if="backEnabled"
               class="back-btn" type="default" @click="clickBackAction">
      <span class="fa fa-long-arrow-left"></span> {{$t('back')}}
    </el-button>

    <h1>
      {{title}}

      <span>{{formattedPreText}}</span><a href="javascript:;" v-if="linkData" @click="handlePageTitleLinkRequest" type="primary" class="page-title-link">{{ linkData.text }}</a><span>{{formattedPostText}}</span>
    </h1>

  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Page Title'
      },

      backEnabled: {
        type: Boolean,
        default: false,
      },

      backCb: {
        type: Function,
        default: null
      },

      linkData: {
        type: Object,
        default: null,
      }
    },

    computed: {
      formattedPreText: function() {
        if(this.linkData !== null) {
          return this.formatText(this.linkData.preText);
        }
      },

      formattedPostText: function() {
        if(this.linkData !== null) {
          return this.formatText(this.linkData.postText);
        }
      }
    },

    methods: {
      handlePageTitleLinkRequest: function() {
        console.log(this.linkData.path);
        // this.$router.push(`department/edit/${id}`);
        this.$router.replace('/' + this.linkData.path);
      },

      formatText: function(txt) {
        let fmText = '';
        if(this.linkData === true && txt !== null || txt !== undefined || txt !== false) {
          fmText = txt;
        }

        return txt;
      },

      /**
       * @returns {void}
       */
      clickBackAction () {
        if (this.backCb !== null) {
          this.backCb();

        } else {
          this.handleClickBack(); // use the global mixin.
        }
      }
    }
  }
</script>

<style lang="scss">
  .page-title-link {
    text-decoration: underline !important;
    cursor: pointer !important;

    &:hover {
      text-decoration: underline;
      color: #36A1EA !important;
    }
  }
</style>
