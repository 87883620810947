<template>
  <div class="app-fields" :class="getBem(blockClass)">

    <!-- --------------------------------------------- -->
    <!-- Allowance -->
    <!-- --------------------------------------------- -->

    <!-- Pay Allowance Switch -->
    <el-form-item
      :class="getBem(blockClass, 'allowance-settings-pay-allowance-container')"
      :label="$t('pay_allowance')"
      class="label-fluid"
    >
      <el-switch
        :class="getBem(blockClass, 'allowance-settings-pay-allowance-field')"
        v-model="iValue.allowance_setting.pay_allowance"
      />
    </el-form-item>

    <template v-if="iValue.allowance_setting.pay_allowance">

      <!-- Minimum KM -->
      <el-form-item
        :class="getBem(blockClass, 'allowance-settings-minimum-km-container')"
        :label="$t('minimum_km')"
        class="label-fluid"
      >
        <el-input
          :class="getBem(blockClass, 'allowance-settings-minimum-km-field')"
          v-model="iValue.allowance_setting.minimum_km"
        />
      </el-form-item>

      <!-- Allowance Fees Repeating -->
      <el-form-item
        :class="getBem(blockClass, 'allowance-fees-container')"
      >
        <financial-allowance-fees-field
          :class="getBem(blockClass, 'allowance-fees-field')"
          v-model="iValue.allowance_fees"
        />
      </el-form-item>

    </template>

    <hr class="app-hr hr-type1">

    <!-- --------------------------------------------- -->
    <!-- Expense Setting -->
    <!-- --------------------------------------------- -->

    <!-- Pay Expenses Switch -->
    <el-form-item
      :class="getBem(blockClass, 'expense-setting-pay-expenses-container')"
      :label="$t('qs_pay_expenses')"
      class="label-fluid"
    >
      <el-switch
        :class="getBem(blockClass, 'expense-setting-pay-expenses-field')"
        v-model="iValue.expense_setting.pay_expenses"
      />
    </el-form-item>

    <!-- Max Expenses -->
    <el-form-item
      v-if="iValue.expense_setting.pay_expenses"
      :class="getBem(blockClass, 'expense-setting-max-expenses-container')"
      :label="$t('max_expenses')"
      class="label-fluid"
    >
      <el-input
        :class="getBem(blockClass, 'expense-setting-max-expenses-field')"
        v-model="iValue.expense_setting.max_expenses"
      />
    </el-form-item>

    <hr class="app-hr hr-type1">

    <!-- --------------------------------------------- -->
    <!-- Appointment -->
    <!-- --------------------------------------------- -->

    <template v-if="isFormForInvoice">
      <!-- Appointment service used -->
      <el-form-item
        :class="getBem(blockClass, 'appointment-service-used-container')"
        class="label-fluid"
        :label="$t('appointment_service_used')"
      >
        <el-switch
          :class="getBem(blockClass, 'appointment-service-used-field')"
          v-model="temp_appointment.service_used"
        />
      </el-form-item>

      <el-form-item
        v-if="temp_appointment.service_used"
        :class="getBem(blockClass, 'appointment-service-fee-container')"
        class="label-fluid"
        :label="$t('appointment_service_fee')"
      >
        <el-input
          :class="getBem(blockClass, 'appointment-service-fee-field')"
          v-model="temp_appointment.service_fee"
        />
      </el-form-item>


      <el-form-item
        :class="getBem(blockClass, 'appointment-suggest-alternative-time-container')"
        class="label-fluid"
        :label="$t('suggest_alternative_time')"
      >
        <el-switch
          :class="getBem(blockClass, 'appointment-suggest-alternative-time-field')"
          v-model="temp_appointment.suggest_alternative_time"
        />
      </el-form-item>

      <el-form-item
        v-if="temp_appointment.suggest_alternative_time"
        :class="getBem(blockClass, 'appointment-alternative-time-container')"
        class="label-fluid"
        :label="$t('alternative_time')"
      >
        <el-input
          :class="getBem(blockClass, 'appointment-alternative-time-field')"
          v-model="temp_appointment.alternative_time"
        />
      </el-form-item>

      <el-form-item
        :class="getBem(blockClass, 'transparency-switch')"
        label="Enable Transparency?"
        class="label-fluid"
      >
        <el-switch
          :class="getBem(blockClass, 'transparency-switch')"
          v-model="iValue.transparency_enabled"
        />
      </el-form-item>

      <el-form-item
        v-if="iValue.transparency_enabled"
        :class="getBem(blockClass, 'transparency-props')"
        label="Transparency details"
        class="label-fluid"
      >
        <el-select
          :class="getBem(blockClass, 'transparency-props')"
          v-model="iValue.transparency_fields"
          filterable
          multiple
        >
          <el-option
            v-for="opt in transparencyPropsOpts"
            :key="opt.value"
            :value="opt.value"
            :label="opt.label"
          />
        </el-select>
      </el-form-item>
    </template>

    <!-- Translator Level Order -->
    <label class="label-fluid" style="display:block; margin-bottom: 15px;">
      {{ $t('translator_level_order') }}
    </label>

    <vue-draggable
      v-model="iTranslatorLevelOrder"
      :class="getBem(blockClass, 'translator-level-order')"
    >
      <div v-for="level in iTranslatorLevelOrder">
        <span class="fa fa-bars"></span>
        {{ $t(level.code) }}
      </div>
    </vue-draggable>

  </div>
</template>

<script>
  import FinancialAllowanceFeesField
    from '~/components/forms/financial/price/shared/allowance-fees/FinancialAllowanceFeesField'
  import {isEmpty} from "~/js/helpers/Common";
  import isNil from 'lodash/isNil';
  import map from 'lodash/map';
  import pick from 'lodash/pick';
  import forEach from 'lodash/forEach';
  import find from 'lodash/find';
  import sortBy from 'lodash/sortBy';
  import VueDraggable from 'vuedraggable';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      FinancialAllowanceFeesField,
      VueDraggable
    },

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      blockClass: {
        type: String,
        default: 'financial-other-settings-fields',
      },

      value: {
        type: Object,
        default () {
          return {};
        },
      },

      formType: {
        type: String,
        default: 'invoice',
      },

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {

        // TODO Implement API
        temp_appointment: {
          service_used: false,
          service_fee: '',
          suggest_alternative_time: false,
          alternative_time: ''
        },

        translatorLevelOrderRank: {
          certified_in_law: 1,
          certified_in_health: 2,
          certified: 3,
          trained: 4,
          read_courses: 5,
          layman: 6
        }
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        },
      },


      /**
       * Interface for this.iValue.translator_level_order
       * @returns {void}
       */
      iTranslatorLevelOrder: {
        get () {
          const opts = this.$store.getters['translatorLevel/formattedTranslatorLevelOpts'];

          if (!isNil(this.iValue.translator_level_order) && !isEmpty(this.iValue.translator_level_order)) {
            let result = [];

            forEach(this.iValue.translator_level_order, (id) => {
              const found = find(opts, (opt) => opt.id === id);
              if (!isNil(found)) {
                result.push({id: found.id, code: found.code});
              }
            });

            return result;

          } else {
            let def = map(opts, (opt) => pick(opt, 'id', 'code'));
            return sortBy(def, (o) => this.translatorLevelOrderRank[o.code]);
          }
        },

        set (collection) {
          // noinspection JSCheckFunctionSignatures
          this.iValue.translator_level_order = map(collection, `id`);
        }
      },

      /**
       * Determine if the form is used for invoice.
       *
       * @returns {boolean}
       */
      isFormForInvoice () {
        return this.formType === 'invoice';
      },

      /**
       * @returns {array}
       */
      transparencyPropsOpts () {
        return [
          {value: 'booked_time', label: 'Booking Time'},
          {value: 'session_time', label: 'Session Time'},
          {value: 'status', label: 'Status'},
          {value: 'translator_level', label: 'Translator Level'},
          {value: 'translator_id', label: 'Translator ID'},
        ];
      },

    }, // End of Component > computed

  } // End of export default
</script>

<style lang="scss">
  .financial-other-settings-fields {

    &__translator-level-order {
      position: relative;
      border: 1px solid #dedede;
      border-radius: 5px;
      margin-bottom: 20px;

      div {
        border-bottom: 1px solid #dedede;
        padding: 12px 10px;
        cursor: grab;
        font-size: 12px;

        span.fa {
          margin-right: 5px;
          color: #dedede;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
</style>
