<template>
    <div class="app-display" :class="genBem(blockCls)">

        <div :class="genBem(blockCls, 'wrapper')">

            <div :class="genBem(blockCls, 'header')" v-if="chartHeaders.length > 0">
                <div v-for="(v) in chartHeaders"
                     :class="[
                        {'in-between': v.isInBetween},
                        genBem(blockCls, 'header-item')
                     ]"
                     :style="getHeaderItemStyle(v)">
                    <span>{{ v.label }}</span>
                </div>
            </div> <!-- header -->

            <div :class="genBem(blockCls, 'body')" v-if="hasChartData">
                <!-- Iterate item per group -->
                <div v-for="(group) in chartData"
                     :style="{height: barStyle.height + 'px'}"
                     :class="genBem(blockCls, 'item')">

                    <div v-for="(bar) in group.bookings"
                         :class="[
                            genBem(blockCls, 'bar'),
                            getBarCls(bar)
                         ]"
                         :style="getBarStyle(bar)">
                        <div>
                            <p :class="genBem(blockCls, 'bar-id')">
                                <a href="#" @click.stop.prevent="gotoBooking(bar.booking.id)">
                                    <strong>#{{ bar.booking.id }}</strong>
                                </a>
                            </p>
                            <p :class="genBem(blockCls, 'bar-language-name')">
                                <strong>{{ bar.booking.language_name }}</strong>
                            </p>
                            <p :class="genBem(blockCls, 'bar-range')">
                                {{ formatTimeDisplay(bar.booking.start) }} -
                                {{ formatTimeDisplay(bar.booking.end) }}
                            </p>
                            <p :class="genBem(blockCls, 'bar-type')">
                                {{ $t(bar.booking.type) }}
                            </p>
                        </div>
                    </div> <!-- bar -->
                </div> <!-- item -->
            </div> <!-- body -->


        </div> <!-- wrapper -->
    </div> <!-- component container -->
</template>

<script>
  export default {
    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      // The suggested minimum and maximum time range to be displayed in the chart.
      range: {
        type: Object,
        required: true
      },

      chartData: {
        type: Array,
        required: true,
      },

      /**
       * Contains the necessary values for styling the bar.
       */
      barStyle: {
        type: Object,
        default () {
          return {
            height: 100
          }
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {

        blockCls: 'booking-availability-chart',

        colorMap: {
          potential: '#00b58a',
          conflict: '#ff4c52',
          safe: '#3475b3',
          empty: '#eaeaea'
        }

      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Method to return the headers for the chart.
       *
       * @returns {Array} - collection of objects.
       */
      chartHeaders () {
        let headers = []; // Prepare container..

        // If the ranges are not provided, just return an empty array.
        if (this.range.min === null || this.range.max === null) return headers;

        // Parse the ranges into moment objects.
        let min = this.range.min;
        let max = this.range.max;
        const full = max.diff(min);

        // Define the necessary values.
        const displayFormat = 'HH:mm'; // Will be used for formatting the time into strings.

        // Iterate tru range, and collect the time prints.
        for (let m = window.moment(min); m.isBefore(max) || m.isSame(max); m.add('30', 'minutes')) {
          const formatted = m.format(displayFormat);
          const isInBetween = window._.includes(formatted, '30');

          // Just print :30 if the time is an "in between". Else, print the formatted time.
          headers.push({
            label: isInBetween ? ':30' : formatted,
            isInBetween,
            x: m.diff(min) / full * 100
          });
        }
        return headers;
      },

      /**
       * Returns boolean to determine whether if chart data exists.
       * @returns {boolean}
       */
      hasChartData () {
        return !window._.isEmpty(this.chartData);
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * Method for producing style object values for Header Item.
       *
       * @param item - Object that contains values related to the Header Item.
       * @returns {Object}
       */
      getHeaderItemStyle (item) {
        const fontSize = 10;
        const fontBold = true;

        return {
          'left': `${item.x}%`,
          'font-size': `${fontSize}px`,
          'font-weight': fontBold ? 'bold' : 'normal'
        };
      },

      /**
       * Method for producing style object values for Bar Item.
       *
       * @param bar - Object that contains values related to the Bar Item.
       * @returns {Object}
       */
      getBarStyle (bar) {

        const a = bar.position.start;
        const b = bar.position.end;

        return {
          'left': `${a}%`,
          'width': `${b - a}%`
        };
      },

      /**
       * Method for producing class values for Bar Item.
       *
       * @param bar - Object that contains values related to the Bar Item.
       * @returns {Object}
       */
      getBarCls (bar) {
        return this.genBem(this.blockCls, 'bar', bar.mode);
      },

      /**
       * Simple Helper method for formatting time to string for display.
       *
       * @param time
       * @returns {string}
       */
      formatTimeDisplay (time) {
        return window.moment(time).format('HH:mm');
      },

      /**
       * Method to redirect the user to the booking details according to the given ID.
       *
       * @param id - Booking ID
       * @returns {void}
       */
      gotoBooking (id) {
        const url = `/#/booking/${id}/details`;
        window.open(url, '_blank');
      }

    }

  }
</script>
