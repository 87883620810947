<template>
    <div class="app-page user-compose-page">

        <page-header title="Batch Setting Template Compose"/>

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">

                    <div class="app-col full-width">
                        <div class="top-controls text-left">
                            <el-button @click="window.history.back()">
                                <span class="fa fa-caret-left" style="margin-right: 10px;"></span>
                                {{$t('back')}}
                            </el-button>
                        </div>
                        <div class="content-body">

                        <batch-setting-template-form/>

                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page user-compose-page -->
</template>

<script>
  import BatchSettingTemplateForm from '~/components/forms/batch-setting-template/BatchSettingTemplateForm';

  export default {

    components: {BatchSettingTemplateForm},

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        window
      };
    } // End of Component > data
  } // End of export default
</script>