<template>
    <div class="app-form sector-financial-settings-fields">

        <el-form :model="form" :id="formElId" :ref="formElId">

            <!-- Holiday New Year -->
            <el-form-item :id="genContainerId('is_holiday_new_year',formElId)"
                          :label="$t('holiday_new_year')" class="label-long">
                <el-switch :id="genFieldId('is_holiday_new_year',formElId)"
                           v-model="form.is_holiday_new_year"/>
            </el-form-item>

            <!-- Holiday Easter -->
            <el-form-item :id="genContainerId('is_holiday_easter',formElId)"
                          :label="$t('holiday_easter')" class="label-long">
                <el-switch :id="genFieldId('is_holiday_easter',formElId)"
                           v-model="form.is_holiday_easter"/>
            </el-form-item>

            <!-- Holiday Ascension Thursday -->
            <el-form-item :id="genContainerId('is_holiday_ascension_thursday',formElId)"
                          :label="$t('holiday_ascension_thursday')" class="label-long">
                <el-switch :id="genFieldId('is_holiday_ascension_thursday',formElId)"
                           v-model="form.is_holiday_ascension_thursday"/>
            </el-form-item>

            <!-- Holiday Christmas -->
            <el-form-item :id="genContainerId('is_holiday_christmas',formElId)"
                          :label="$t('holiday_christmas')" class="label-long">
                <el-switch :id="genFieldId('is_holiday_christmas',formElId)"
                           v-model="form.is_holiday_christmas"/>
            </el-form-item>

            <!-- Holiday Midsummer -->
            <el-form-item :id="genContainerId('is_holiday_midsummer',formElId)"
                          :label="$t('holiday_midsumme')" class="label-long">
                <el-switch :id="genFieldId('is_holiday_midsummer',formElId)"
                           v-model="form.is_holiday_midsummer"/>
            </el-form-item>

            <!-- Holiday National Day Sweden -->
            <el-form-item :id="genContainerId('is_holiday_nat_day_se',formElId)"
                          :label="$t('holiday_national_day_sweden')" class="label-long">
                <el-switch :id="genFieldId('is_holiday_nat_day_se',formElId)"
                           v-model="form.is_holiday_nat_day_se"/>
            </el-form-item>

            <!-- Holiday All Saints Day -->
            <el-form-item :id="genContainerId('is_holiday_all_saints_day',formElId)"
                            :label="$t('holiday_all_saints_day')" class="label-long">
                <el-switch :id="genFieldId('is_holiday_all_saints_day',formElId)"
                           v-model="form.is_holiday_all_saints_day"/>
            </el-form-item>

            <!-- Save -->
            <el-form-item :id="genContainerId('save',formElId)">
                <el-button type="primary" :id="genFieldId('save',formElId)">
                    {{$t('save')}}
                </el-button>
            </el-form-item>

        </el-form>

    </div> <!-- /.app-form sector-financial-settings-fields -->
</template>

<script>

    let formFresh = {
        is_holiday_new_year: true,
        is_holiday_easter: true,
        is_holiday_ascension_thursday: true,
        is_holiday_christmas: true,
        is_holiday_midsummer: true,
        is_holiday_nat_day_se: true,
        is_holiday_all_saints_day: true,
    };

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            formElId: {
                type: String,
                default: 'sector_financial_settings_form',
            },

            form: {
                type: Object,
                default() {
                    return _.cloneDeep(formFresh);
                }
            },

        }, // End of Component > props

    } // End of export default
</script>
