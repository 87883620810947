<template>
 <div class="app-page bookings-issue-page">

  <page-header :title="$t('pending_booking_issues')" />

  <div class="app-container">
    <div class="app-wrapper">
      <div class="app-row">
        <div class="app-col full-width">
          <div class="content-body" v-loading="list.loading">

            <booking-issue-pending-list @updateList="handleUpdateList"/>
            <el-pagination
                       layout="prev, pager, next"
                       :page-size="pagination.per_page"
                       :total="pagination.total"
                       :current-page="pagination.current_page"
                       @current-change="changePage">
        </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import {populate,forcePopulate} from "~/js/helpers/Vuex"
import {isEmpty,sd} from "../../../js/helpers/Common";
export default {
  data(){
    return {
      populatePayload:{
        sort:'-deadline_date',
        'filter[pending]':true,
        include:'booking.customer,booking.assigned_translator,type,translator'
      }
    }
  },
  computed:{
    ...mapState('bookingIssue',{list:'list'}),
    ...mapGetters('bookingIssue',{pagination: 'listPagination'})
  },
  methods:{
    ...mapActions({
      'browse':'bookingIssue/browse',
      'setting':'bookingIssueSetting/browse',
    }),

     reloadList(n, per_page) {
        let params=_.clone(this.$route.query);
        n = sd(n, sd(this.$route.query.page, 1)) // Set the page number.
        params.page = n;
        if(per_page && typeof per_page == 'number')
        {
            params['per_page']= per_page;
        }
        this.populatePayload.page = params.page

        Object.assign(params,this.populatePayload)


        this.$router.push({query: params}) // Reflect the chosen parameters in the URL Bar
        populate(this, 'browse', '', {
            force: true,
            params
        })
    }, // End of reloadList() method
    handleUpdateList(e){
      //Object.assign(this.populatePayload,e)
      this.resetPopulate();
      this.populatePayload= Object.assign(this.populatePayload,e)
      this.reloadList();
    },
    changePage(e){
      this.reloadList(e)
    },
    resetPopulate(){
      this.populatePayload = {
        'filter[pending]':true,
        include:'booking.customer,booking.assigned_translator,type,translator,entities'
      }
    }
  },
  mounted(){

    let params =_.clone(this.$route.query)
    Object.assign(params,this.populatePayload)
    const opts = {
        action: 'browse',
        data: 'listData',
        ref: 'Booking Issue Listing',
    };
    this.reloadList(null,opts,params)

    populate(this, 'setting', '', {
            force: true,
            params:{
              all:true
            }
        })
  }
}
</script>

<style>

</style>
