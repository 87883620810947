<template>
  <div class="alert-modal-container">
    <el-dialog
      :modal-append-to-body="appendToBody"
      :title="modalTitle"
      :visible.sync="modalVisible"
      center
      @close="handleClose"
    >
      <template v-if="!isEmpty(iForm.qr_code)">
        <div class="alert">
          <img :src="qrCodeSource" alt="QR Code" class="verify-2fa__qr-code" />
        </div>

        <div>
          <h3>Scan Qr to get activation key</h3>
        </div>
      </template>
      <template v-else>
        <div>
          <h3>Insert Verification key</h3>
        </div>
      </template>
      <el-form :model="iForm" @submit.native.prevent="handleClickConfirm">
        <el-form-item>
          <el-input v-model="iForm.verification_key" placeholder="Enter Code" />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClickConfirm()"
        >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { isEmpty } from "~/js/helpers/Common";

export default {
  name: "Verify2Fa",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    appendToBody: {
      type: Boolean,
      default: false
    },

    form: {
      type: Object,
      default() {
        return {
          client_id: "",
          client_secret: "",
          grant_type: "",
          access_code: "", // code from previous step, we don't to show it to user
          verification_key: "", // 6-digits so it's good to have validation if easy.
          qr_code: ""
        };
      }
    }
  },
  data() {
    return {
      modalVisible: this.visible
    };
  },
  computed: {
    iForm: {
      get() {
        return this.form;
      },
      set(v) {
        this.$emit("input", v);
      }
    },

    /**
     * @returns {string}
     */
    modalTitle() {
      return this.title === "" ? "Verify Authentication" : this.$t(this.title);
    },

    /**
     * @return {string}
     */
    qrCodeSource() {
      return (
        "data:image/svg+xml;base64," +
        Buffer.from(this.iForm.qr_code).toString("base64")
      );
    }
  },
  watch: {
    visible: function(newVal) {
      this.modalVisible = newVal;
    }
  }, // End of Component > watch
  methods: {
    ...mapActions("auth", ["requestAccessToken"]),
    handleClose: function() {
      this.$emit("update:visible", false);
    }, // End of handleClose() method
    isEmpty(v) {
      return isEmpty(v);
    },

    handleClickConfirm() {
      // this.$emit('update:visible', false);
      // Request freshly baked token from the oven!
      this.$store
        .dispatch("auth/requestAccessToken", { data: this.iForm })
        .then(r => {
          if (r.data.user.type === 1) {
            window.location.reload();
          } else {
            this.$notify.error({
              title: "Error",
              message: "User is not an Admin. Access Denied"
            });
          }
        })
        .catch(e => {
          this.$notify.error({
            title: "Something went wrong!",
            message: window._.isNil(e.response) ? "" : e.response.data.message
          });

          this.$emit("update:visible", false);
        });
    }
  }
};
</script>
<style lang="scss">
.alert-modal-container {
  .el-dialog__body {
    text-align: center;

    h1 {
      font-size: 40px;
    }

    h2 {
      font-size: 30px;
    }
  }
}

.verify-2fa__qr-code {
  width: 100% !important;
  height: auto;
  max-width: 192px;
  margin: 0 auto;
}
</style>
