<template>
  <div class="app-form ta-hours-form">
    Edit Project Scope
    <el-form :id="formElId" :ref="formElId">
      <el-form-item class="label-short" label='By Translation Agency'>
        <el-input v-model="form.hours_by_ta"></el-input>
      </el-form-item>
      <el-form-item class="label-short" label='By Translator'>
        <el-input  v-model="form.hours_by_translator"></el-input>
      </el-form-item>
      <el-form-item class="label-short" label='Words amount'>
        <el-input v-model="form.words_amount"></el-input>
      </el-form-item>
      <el-button type='primary' @click="updateWorkingHour()">Update</el-button>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props:{
    textTranslation: {
      type: Object,
      default(){
        return {
          hours_by_ta:0,
          hours_by_translator:0,
          words_amount:0
        }
      }
    }
  },
  data(){
    return {
      formElId:'edit-ta-working-hours',
    }
  },
  computed:{
    form:{
      get() { return this.textTranslation },
      set(v){ this.$emit('input',v) },
    }
  },
  methods:{
    ...mapActions('textTranslation',['updateTaWorkingHours']),
    updateWorkingHour(){
      let payload ={
        id: this.form.id,
        hours_by_ta: this.form.hours_by_ta,
        hours_by_translator: this.form.hours_by_translator,
        words_amount: this.form.words_amount
      }
      this.updateTaWorkingHours(payload)
    }
  }
}
</script>