<template>
    <div class="app-list user-list">

        <custom-user-list
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="actionsColumn" slot-scope="scope" style="z-index: 10">
                <el-button class="circle" type="success" :title="$t('restore')" @click.stop="handelRestoreTrashedUsers(scope)">
                    <span class="fa fa-repeat"></span>
                </el-button>
            </template>

        </custom-user-list>

        <!-- Pagination -->
        <el-pagination
                v-if="listPagination.total > listPagination.per_page"
                layout="prev, pager, next"
                :page-size="listPagination.per_page"
                :total="listPagination.total"
                :current-page="listPagination.current_page"
                @current-change="reloadLists">
        </el-pagination>

    </div> <!-- /.app-list user-list -->
</template>

<script>
  import {mapGetters, mapActions} from "vuex";
  import {sd} from "../../../js/helpers/Common";

  export default {
    components:{},
    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {}, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {

        listCols: [
          {
            label: 'ID',
            prop: 'id',
            width: '70px',
            className: 'id-col'
          },
          {
            label:  `${this.$t('name')}`,
            prop: 'name',
            width: '',
            className: 'name-col'
          },
          // {
          //     label: 'Email',
          //     prop: 'email',
          //     width: '',
          //     className: 'email-col',
          // },
           {
                        label: `${this.$t('notes')}`,
                        prop: 'notes',
                        width: '',
                        className: 'notes-col',
          },
          {
            label: `${this.$t('mobile')}`,
            prop: 'mobile',
            width: '',
            className: 'mobile-col'
          },
          {
            label: `${this.$t('user_type')}`,
            prop: 'type',
            width: '',
            className: 'user-type-col',
            mutator (v) {
              switch (v) {
                case 1:
                  return 'Admin';
                case 2:
                  return 'Customer';
                case 3:
                  return 'Translator';
              } // End of switch
            } // End of mutator(v) method
          }
        ], // End of listCols

        populatePayload: {
          user_type: 'users'
        },
        showCallMobile: false,
        mobile: '',

      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
        ...mapGetters("user", {
            listData: "getTrashedUsers",
            listPagination: "paginationTrashedUsers"
        }),

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions('user', {
        browse: 'readTrashedUsers',
        restoreTrashedUsers: 'restoreTrashedUsers'
      }),

      reloadLists (n) {
        const param = {
          page: n
        };
        this.browse(param)
      },
      
      handelRestoreTrashedUsers(scope){
        this.restoreTrashedUsers({id: scope.row.id}).then(() => {
            window.location.reload();
        }).catch(() => {
            // Silence is golden.
        });
      }

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      const opts = {
        action: 'browse',
        data: 'listData',
        ref: 'User Listing'
      };
      const params = _.clone(this.$route.query);

      this.reloadList(null, opts, params);
    } // End of Component > mounted

  } // End of export default
</script>
