<template>
  <div class="app-list booking-detail-list">

    <table class="detail-list">
      <template
        v-if="booking.type ==='text_translation' && !isEmpty(booking.text_translation) && booking.text_translation.is_asking_price ===1">
        <tr>
          <td class="cell-label"><span>{{$t('is_asking_price')}}</span></td>
          <td class="cell-value"><span></span></td>
        </tr>
      </template>
      <tr v-if="parseInt(booking.is_immediate) === 1">
        <td class="cell-label"><span></span></td>
        <td class="cell-value"><span>{{$t('emergency_booking')}}</span></td>
      </tr>
      <tr>
        <td class="cell-label"><span>{{$t('status')}}</span></td>
        <td class="cell-value"><span>{{ booking.status_id | getStatusList(statusList) }}</span></td>
      </tr>
      <tr>
        <td class="cell-label"><span>{{$t('language')}}</span></td>
        <td class="cell-value"><span>{{ booking.language_name}}</span></td>
      </tr>
      <tr v-if="booking.type === 'text_translation'">
        <td class="cell-label"><span>{{$t('to_language')}}</span></td>
        <td class="cell-value"><span>{{ booking.text_translation.to_language}}</span></td>
      </tr>

      <tr v-if="!isEmpty(booking.alternative_languages)">
        <td class="cell-label"><span>{{$t('alternative_language')}}:</span></td>
        <td class="cell-value"><span>{{  booking.alternative_languages | getLanguageName(languages) }}</span>
        </td>
      </tr>

      <tr>
        <td class="cell-label"><span>{{$t('tfv_type')}}</span></td>
        <td class="cell-value"><span>{{ $t(booking.type) }}</span></td>
      </tr>

      <tr>
        <td class="cell-label"><span>{{$t('ref')}}</span></td>
        <td class="cell-value"><span><a style="color:inherit !important; text-decoration: underline;"
                                        :href="`tel:${getNumFromRef(booking)}`">{{booking.reference}}</a></span>
        </td>
      </tr>

      <tr>
        <td class="cell-label"><span>{{$t('booked_name')}}</span></td>
        <td class="cell-value"><span>{{booking.booker_name}}</span></td>
      </tr>

      <tr>
        <td class="cell-label"><span>{{$t('user_department')}}</span></td>
        <td class="cell-value">
          <a href="#" style="color: inherit;"
             @click.stop.prevent="handleDepartment(booking.customer.customer_data)">
            <u>
              {{
              customerDepartment
              }}
            </u>
          </a>
        </td>
      </tr>

      <tr>
        <td class="cell-label"><span>{{$t('booking_customer_name')}}</span></td>
        <td class="cell-value">
          {{bookingCustomerName}} | <span class="blue-text">{{bookingCustomerId}}</span>
        </td>
      </tr>

      <tr>
        <td class="cell-label"><span>{{$t('instructions')}}</span></td>
        <td class="cell-value">
          {{booking.instructions}}
        </td>
      </tr>
      <tr>
        <td class="cell-label"><span>{{$t('staff_name')}}</span></td>
        <td class="cell-value">
          {{booking.staff_name}}
        </td>
      </tr>

      <tr>
        <td class="cell-label"><span>{{$t('tfv_customer')}} {{$t('email')}}</span></td>
        <td class="cell-value">
          <a href="#" style="color: inherit;"
             @click.stop.prevent="handleClickUserEmail(booking)">
            <u>{{bookingCustomerEmail}}</u>
          </a>
        </td>
      </tr>

      <template v-if="booking.notification_types">
        <tr v-for="t in booking.notification_types" :key="t.type">
          <td class="cell-label"><span>{{$t('tfv_notification_through')}}</span></td>
          <td class="cell-value"><span>{{t.recipient}}</span></td>
        </tr>
      </template>

      <tr>
        <td class="cell-label">
          <span v-if="booking.type ==='text_translation'">{{$t('text_translation_customer_deadline')}}:</span>
          <span v-else>{{$t('tfv_due')}}:</span>
        </td>
        <td class="cell-value"><span>{{booking.due}}</span></td>
      </tr>

      <tr>
        <td class="cell-label"><span>{{$t('start_time')}}</span></td>
        <td class="cell-value"><span>{{booking.due_time}}</span></td>
      </tr>

      <tr>
        <td class="cell-label"><span>{{$t('end_time')}}</span></td>
        <td class="cell-value"><span>{{bookingEndTime}}</span></td>
      </tr>

      <tr v-if="booking.type !=='text_translation'">
        <td class="cell-label"><span>{{$t('duration')}}</span></td>
        <td class="cell-value"><span>{{booking.duration | minutesToTime }}</span></td>
      </tr>

      <tr>
        <td class="cell-label"><span>{{$t('posted_at')}}</span></td>
        <td class="cell-value"><span>{{booking.created_at}}</span></td>
      </tr>

      <tr>
        <td class="cell-label"><span>{{$t('actual_session_time')}}</span></td>
        <td class="cell-value"><span>{{booking.session_time}}</span></td>
      </tr>

      <tr>
        <td class="cell-label"><span>LMA</span></td>
        <td class="cell-value"><span>{{booking.lma}}</span></td>
      </tr>

      <tr v-if="booking.type ==='text_translation'">
        <td class="cell-label">
          <span>{{$t('text_translation_translator_deadline')}}</span>
        </td>
        <td class="cell-value"><span>{{booking.text_translation.translator_deadline}}</span></td>
      </tr>
      <tr>
        <td class="cell-label"><span>{{$t('will_expire_at')}}</span></td>
        <td class="cell-value"><span>{{booking.expiry }}</span></td>
      </tr>
      <tr>
        <td class="cell-label"><span>{{$t('specific_translators')}}</span></td>
        <td class="cell-value">

          <el-tooltip placement="top">
            <div slot="content" v-for="(item, index) in booking.specific_translators_name" :key="index">
              {{index+1}}. {{item.translator.name}}<br></div>
            <span>{{ booking.specific_translators && booking.specific_translators.length > 0 ? 'Y' : 'N' }}</span>
          </el-tooltip>
        </td>
      </tr>
      <tr>
        <td class="cell-label"><span>{{$t('gender_preference')}}</span></td>
        <td class="cell-value">
          {{booking.gender}}
        </td>
      </tr>
      <tr>
        <td class="cell-label"><span>{{$t('created_via')}}</span></td>
        <td class="cell-value"><span>{{booking.created_via }}</span></td>
      </tr>

      <tr>
        <td class="cell-label"><span>{{$t('send_invoice_to_following_department')}}:</span></td>
        <td class="cell-value"><span> {{booking.invoicing_department && booking.invoicing_department.id}} - {{booking.invoicing_department && booking.invoicing_department.name}}</span>
        </td>
      </tr>

      <tr>
        <td class="cell-label"><span>{{$t('lma_number')}}:</span></td>
        <td class="cell-value"><span> {{booking.lma}}</span></td>
      </tr>


      <tr v-if="booking.cancelled_via">
        <td class="cell-label"><span>{{$t('cancelled_via')}}</span></td>
        <td class="cell-value"><span>{{booking.cancelled_via }}</span></td>
      </tr>
      <tr>
        <td class="cell-label"><span>{{$t('used_appointment_app')}}</span></td>
        <td class="cell-value"><span>{{booking.appointment_app_id != null ? 'Y' : 'N'}}</span></td>
      </tr>
      <tr v-if="booking.appointment_app_id">
        <td class="cell-label"><span>{{$t('client_made_suggestions')}}</span></td>
        <td class="cell-value"><span>{{booking.made_suggestions ? 'Y' : 'N'}}</span></td>
      </tr>


      <template v-if="isSpecificTranslatorReject">
        <tr>
          <td class="cell-label"><span>{{$t('has_rejected')}}</span></td>
          <td class="cell-label">
            <span>{{booking.has_rejected ? $t('yes') : $t('no')}}</span>
          </td>
        </tr>
      </template>
      <template v-if="['physical', 'video_physical'].includes(booking.type)">

        <tr>
          <td class="cell-label"> {{$t('booking_address')}}</td>
          <td class="cell-value">
            {{booking.address}}
          </td>
        </tr>


      </template>
      <template
        v-if="['video', 'video_skype', 'video_physical'].includes(booking.type) && !isEmpty(booking.video_booking)">

        <tr>
          <td class="cell-label">Provider</td>
          <td class="cell-value">
            {{booking.video_booking.provider}}
          </td>
        </tr>

        <tr>
          <td class="cell-label">Url</td>
          <td class="cell-value">
            <!-- <a :href="booking.video_booking.full_url" target="_blank"> Room {{booking.id}} url </a>-->
            <a :href="booking.video_booking.url" target="_blank"><span class="video-url"> {{(booking.video_booking.provider === 'jitsi') ?`Room ${booking.id} url` : booking.video_booking.url}} </span></a>
          </td>
        </tr>

      </template>
      <template v-if="booking.type ==='convey' && !isEmpty(booking.convey)">
        <tr>
          <td class="cell-label"><span>Covey detail</span></td>
          <td class="cell-value"><span></span></td>
        </tr>
        <tr>
          <td class="cell-label">Message</td>
          <td class="cell-value">
            {{booking.convey.message}}
          </td>
        </tr>
        <tr>
          <td class="cell-label">Additional Info</td>
          <td class="cell-value">
            {{booking.convey.additional_info}}
          </td>
        </tr>
        <tr>
          <td class="cell-label">Contact Phone</td>
          <td class="cell-value">
            {{booking.convey.phone}}
          </td>
        </tr>
        <tr>
          <td class="cell-label">Contact Email</td>
          <td class="cell-value">
            {{booking.convey.email}}
          </td>
        </tr>
        <tr>
          <td colspan="2">{{$t('files')}}</td>
        </tr>
        <tr>
          <td colspan="2">
            <ul class="list-files">
              <li v-for="item in booking.convey.files">
                <el-button type="text" @click="downloadFile(item)" size="mini">{{item.display_name}}</el-button>
              </li>
            </ul>

          </td>
        </tr>

      </template>
      <template v-if="booking.type ==='text_translation' && !isEmpty(booking.text_translation)">
        <tr>
          <td>{{$t('description')}}</td>
          <td>
            {{booking.text_translation.descriptions}}
          </td>
        </tr>
        <tr>
        <tr>
          <td>{{$t('is_express')}}</td>
          <td>
            {{(booking.text_translation.is_express ? 'yes':'no')}}
          </td>
        </tr>
        <tr>
          <td>{{$t('text_translation_is_stamped')}}</td>
          <td>
            {{(booking.text_translation.is_stamped ? 'yes':'no')}}
          </td>
        </tr>
        <tr>
          <td>{{$t('is_confidential')}}</td>
          <td>
            {{(booking.text_translation.is_confidential ? 'yes':'no')}}
          </td>
        </tr>
        <tr>
          <td>{{$t('text_translation_deliver_with')}}</td>
          <td>
            {{$t('deliver_with_' + booking.text_translation.deliver_with,{app:'TFV'})}}
          </td>
        </tr>
        <tr>
          <td>Customer Phone</td>
          <td>
            {{(booking.text_translation.phone)}}
          </td>
        </tr>
        <tr>
          <td>{{$t('cost')}}</td>
          <td>

            <template v-if="booking.text_translation.is_asking_price">
              <el-input v-model="booking.text_translation.cost"></el-input>
              <el-button type='primary' @click="handleClickSubmitCost()">{{$t('btn_send_cost_to_customer')}}</el-button>
            </template>
            <template v-else>
              kr {{booking.text_translation.cost}}
            </template>
          </td>
        </tr>
        <tr v-if="!isEmpty(booking.text_translation.additional_text)">
          <td>{{$t('text_translation_additional_text')}}</td>
          <td>
            {{booking.text_translation.additional_text}}
          </td>
        </tr>
        <tr>
          <td>{{$t('files')}}</td>
          <td></td>
        </tr>
        <tr>
          <td colspan="2">
            <ul class="list-files">
              <li v-for="item in booking.text_translation.files">
                {{(item.pivot.submission_type !=='review') ? item.pivot.submission_type.replace(/_/g,' ') : 'original'}}
                file:
                <el-button type="text" @click="downloadFile(item)" size="mini">{{item.display_name}}</el-button>
                <template v-if="item.pivot.submission_type ==='submited_for_review'">
                  <template v-if="booking.text_translation.status_code==='submited_for_review'">
                    <el-button type='primary' size="small" @click="handleAcceptTranslatedFile(item)">Accept</el-button>
                    <el-button type='primary' size="small" @click="handleRejectTranslatedFile(item)">Reject</el-button>
                  </template>
                </template>

              </li>
            </ul>
          </td>
        </tr>
        <template v-if="booking.status.code==='assigned'">
          <tr v-if="isEmpty(submitedForReviewFile)">
            <td colspan="2">
              <el-button type='primary' @click="handleClickSubmitTranslated()">Submit translated file</el-button>
              <SubmitTranslatedFileModal :visible.sync="showSubmitTranslatedFileModal" :booking="booking" @reload="handleReload"/>

            </td>
          </tr>
        </template>
        <template v-if="booking.text_translation.status_code==='in_review'">
          <el-button type='primary' size="small" @click="handleCompleteTextTranslation(booking.text_translation)">Set
            Complete
          </el-button>
        </template>


        <tr>
          <td colspan="2">
            <translator-hours-form :text-translation="booking.text_translation"/>
          </td>
        </tr>
      </template>
      <template v-if="booking.status_id != 14 && booking.status_id != 15 && booking.status_id != 16">
      <template v-if="!isEmpty(booking.assigned_translator)">
        <tr>
          <td class="cell-label"><span>{{$t('assigned_to')}}:</span></td>
          <td class="cell-value"><span></span></td>
        </tr>
        <tr>
          <td class="cell-label label-emphasize"><span>{{$t('translator_name')}}:</span></td>
          <td class="cell-value">
            <span @click="handleProfile(booking)">
              <u>{{booking.assigned_translator.name}}</u>
            </span> |
            <span class="blue-text">{{booking.assigned_translator.translator_id}}</span>
          </td>
        </tr>
        <tr>
          <td class="cell-label label-emphasize"><span>{{$t('translator_email')}}:</span></td>
          <td class="cell-value"><span>{{booking.assigned_translator.email}}</span></td>
        </tr>
        <tr>
          <td class="cell-label label-emphasize"><span>{{$t('notes')}}:</span></td>
          <td class="cell-value">
            <span><u> {{ booking.assigned_translator.translator.notes == null ? '' : booking.assigned_translator.translator.notes}}</u></span>
          </td>
        </tr>

        <tr>
          <td class="cell-label label-emphasize"><span>Translator Phone:</span></td>
          <td class="cell-value"><a style="color: inherit"
                                    :href="`tel:${booking.assigned_translator.phone}`">{{booking.assigned_translator.phone}}</a>
          </td>
        </tr>

        <tr>
          <td class="cell-label label-emphasize"><span>Translator Mobile:</span></td>
          <td class="cell-value"><a style="color: inherit"
                                    :href="`tel:${booking.assigned_translator.mobile}`">{{booking.assigned_translator.mobile}}</a>
          </td>
        </tr>


        <tr>
          <td class="cell-label label-emphasize"><span>Accepted through:</span></td>
          <td class="cell-value"><span>{{booking.assigned_translator.accepted_via}}</span></td>
        </tr>

        <tr>
          <td class="cell-label label-emphasize"><span>{{$t('accepted_at')}}:</span></td>
          <td class="cell-value"><span>{{booking.assigned_translator.created_at}}</span></td>
        </tr>

        <tr v-if="booking.status_id === 4">
          <td class="cell-label label-emphasize"><span>{{$t('session_time')}}:</span></td>
          <td class="cell-value"><span>{{booking.session_time}}</span></td>
        </tr>

        <tr v-if="booking.status_id === 4">
          <td class="cell-label label-emphasize"><span>{{$t('session_comment_translator')}}:</span></td>
          <td class="cell-value"><span>{{booking.session_comment_translator}}</span></td>
        </tr>

        <tr v-if="booking.status_id === 4">
          <td class="cell-label label-emphasize"><span>{{$t('session_comment_customer')}}:</span></td>
          <td class="cell-value"><span>{{booking.session_comment}}</span></td>
        </tr>

      </template>
      </template>

      <template v-if="!isEmpty(booking.planned_translator)">
        <tr>
          <td class="cell-label"><span>{{$t('planned_to')}}:</span></td>
          <td class="cell-value"><span></span></td>
        </tr>

        <tr>
          <td class="cell-label label-emphasize"><span>{{$t('translator_email')}}:</span></td>
          <td class="cell-value"><span>{{booking.planned_translator.translator.email}}</span></td>
        </tr>

        <tr>
          <td class="cell-label label-emphasize"><span>{{$t('name')}}:</span></td>
          <td class="cell-value"><span @click="handleProfile(booking)"><u>{{booking.planned_translator.translator.name }} {{ booking.planned_translator.translator.notes == null ? '' : booking.planned_translator.translator.notes}}</u></span>
          </td>
        </tr>

        <tr>
          <td class="cell-label label-emphasize"><span>{{$t('translator_phone')}}:</span></td>
          <td class="cell-value"><a style="color: inherit" :href="`tel:${booking.planned_translator.translator.phone}`">{{booking.planned_translator.translator.phone}}</a>
          </td>
        </tr>

        <tr>
          <td class="cell-label label-emphasize"><span>{{$t('translator_mobile')}}:</span></td>
          <td class="cell-value"><a style="color: inherit"
                                    :href="`tel:${booking.planned_translator.translator.mobile}`">{{booking.planned_translator.translator.mobile}}</a>
          </td>
        </tr>

        <tr>
          <td class="cell-label label-emphasize"><span>{{$t('accepted_at')}}:</span></td>
          <td class="cell-value"><span>{{booking.planned_translator.created_at}}</span></td>
        </tr>
      </template>


      <tr v-if="booking.cancelled_at">
        <td class="cell-label label-emphasize"><span>{{$t('cancelled_at')}}:</span></td>
        <td class="cell-value"><span>{{booking.cancelled_at}}</span></td>
      </tr>
      <tr v-if="booking.ended_at">
        <td class="cell-label label-emphasize"><span>{{$t('ended_at')}}:</span></td>
        <td class="cell-value"><span>{{booking.ended_at}}</span></td>
      </tr>
      <template
        v-if="!isEmpty(customerDepartmentNotes) || !isEmpty(customerCompanyNotes) || !isEmpty(customerMunicipalityNotes)">
        <tr>
          <td class="cell-label"><span>{{$t('customer_information')}}:</span></td>
          <td class="cell-value"><span></span></td>
        </tr>

        <tr v-if="!isEmpty(customerDepartmentNotes)">
          <td class="cell-label label-emphasize"><span>{{$t('department')}}:</span></td>
          <td class="cell-value"><span>{{ customerDepartmentNotes }}</span></td>
        </tr>

        <tr v-if="!isEmpty(customerCompanyNotes)">
          <td class="cell-label label-emphasize"><span>{{$t('company')}}:</span></td>
          <td class="cell-value"><span>{{ customerCompanyNotes }}</span></td>
        </tr>

        <tr v-if="!isEmpty(customerMunicipalityNotes)">
          <td class="cell-label label-emphasize"><span>{{$t('municipality')}}:</span></td>
          <td class="cell-value"><span>{{ customerMunicipalityNotes }}</span></td>
        </tr>
      </template>

      <template v-if="!isEmpty(booking.messages)">
        <tr>
          <td class="cell-label"><span>{{$t('customer_message')}}:</span></td>
          <td class="cell-value"><span>{{booking.messages.message}}</span></td>
        </tr>
        <tr>
          <td class="cell-label label-emphasize"><span>{{$t('files')}}:</span></td>
          <td class="cell-value">
            <ul class="list-files">
              <li v-for="item in booking.messages.files">
                <el-button type="text" @click="downloadFile(item)" size="mini">{{item.display_name}}</el-button>
              </li>
            </ul>
          </td>
        </tr>
      </template>

    </table>
    <!-- <BookingRelatedEmailsList :booking="booking"/>
    <booking-related-sms
      :booking="booking"
      v-if="booking.sms_channels"
    /> -->

  </div> <!-- /.app-list booking-detail-list -->
</template>

<script>

  import {download} from "~/js/helpers/File";
  import {mapGetters, mapActions, mapState} from 'vuex';
  import {isEmpty} from '../../../js/helpers/Common';
  import {populate} from "~/js/helpers/Vuex";
  import USER_TYPES from '~/js/constants/user-types';
  import BookingRelatedEmailsList from '~/components/forms/booking/partials/RelatedEmailsList';
  import SubmitTranslatedFileModal from '~/components/forms/booking/TextTranslation/SubmitTranslatedFile';
  import TranslatorHoursForm from '~/components/forms/booking/TextTranslation/TranslatorHoursForm';
  import i18n from '~/js/i18n';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      clonable: ''
    }, // End of Component > props
    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    components: {
      BookingRelatedEmailsList,
      SubmitTranslatedFileModal,
      TranslatorHoursForm
    },
    data () {
      return {
        thenum: '',
        bookingDetail: {},
        showSubmitTranslatedFileModal: false
      };
    }, // End of Component > data

    filters: {
      minutesToTime: (v) => {
        return window.moment().startOf('day').add(v, 'minutes').format('HH:mm:ss');
      },
      getKeyVal: (v, key) => {
        return _.map(v, key);
      },
      getLanguageName (v, languages) {

        return _.map(v, (o) => {

          if (!isEmpty(languages)) {
            const found = window._.find(languages, (x) => x.id === o);
            return found ? found.name : '';
          }
        }).join(', ');
      },
      getTownName: (v, o) => {

        if (!isEmpty(o) && !isEmpty(v)) {
          const found = window._.find(o, (x) => x.id === v);
          return found ? found.name : '';
        }
        return v;

      },
      getTranslatorNames (v, translators) {

        return _.map(v, (o) => {

          if (!isEmpty(translators)) {
            const found = window._.find(translators, (x) => x.id === o.translator_id);
            return found ? found.email : '';
          }
        }).join(', ');
      },
      getStatusList (v, o) {
        if (!isEmpty(o) && !isEmpty(v)) {
          const found = window._.find(o, (x) => x.id === v);
          return found ? i18n.t(`tfv_status_${found.code}`) : '';
        }
        return v;
      }
    },
    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters({
        booking: 'booking/getBooking',
        from_language: 'language/getLanguageFrom',
        to_language: 'language/getLanguageTo',
        customer: 'booking/getCustomer',
        translator: 'booking/getAssignedTranslator',
        translators: 'booking/getTranslatorsList',
        languages: 'language/getLanguages'

      }),
      ...mapState('booking', {
        statusList: 'statusList',
        isSpecificTranslatorReject: 'isSpecificTranslatorReject'
      }),
      ...mapGetters('town', {
        townOpts: 'listAsOptions'
      }),

      customerDepartment () {
        return (!isEmpty(this.booking.customer)
          && !isEmpty(this.booking.customer.customer_data)
          && !isEmpty(this.booking.customer.customer_data.department))
          ? this.booking.customer.customer_data.department.name : '';
      },

      customerDepartmentNotes () {
        return (!isEmpty(this.booking.customer)
          && !isEmpty(this.booking.customer.department))
          ? this.booking.customer.department.notes : '';
      },
      customerCompanyNotes () {
        return (!isEmpty(this.booking.customer)
          && !isEmpty(this.booking.customer.department)
          && !isEmpty(this.booking.customer.department.company))
          ? this.booking.customer.department.company.notes : '';
      },
      customerMunicipalityNotes () {
        return (!isEmpty(this.booking.customer)
          && !isEmpty(this.booking.customer.department)
          && !isEmpty(this.booking.customer.department.company)
          && !isEmpty(this.booking.customer.department.company.municipality))
          ? this.booking.customer.department.company.municipality.notes : '';
      },


      /**
       * @returns {string}
       */
      bookingCustomerEmail () {
        return !window._.isNil(this.booking)
        && !window._.isNil(this.booking.customer)
        && !window._.isNil(this.booking.customer.email)
          ? this.booking.customer.email : '';
      },

      /**
       * @returns {string}
       */
      bookingCustomerName () {
        return !window._.isNil(this.booking)
        && !window._.isNil(this.booking.customer)
        && !window._.isNil(this.booking.customer.name)
          ? this.booking.customer.name : '';
      },

      /**
       * @returns {string}
       */
      bookingCustomerId () {
        return !window._.isNil(this.booking)
        && !window._.isNil(this.booking.customer)
        && !window._.isNil(this.booking.customer.id)
          ? this.booking.customer.id : '';
      },

      //get translated file
      submitedForReviewFile () {
        return (this.booking.type === 'text_translation' && !isEmpty(this.booking.text_translation)) ? this.booking.text_translation.files.find(i => {
          return i.pivot.submission_type === 'submited_for_review';
        }) : {};
      },
      //booking end time
      bookingEndTime () {
        return window.moment(this.booking.due_time, 'HH:mm:ss').add(this.booking.duration, 'minutes').format('HH:mm:ss');
      }
      // inReviewFile(){
      //   return (this.booking.type === 'text_translation' && !isEmpty(this.booking.text_translation)) ? this.booking.text_translation.files.find(i => {
      //           return i.pivot.submission_type === 'in_review'
      //         }) : {}
      // },


    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > watch
    |--------------------------------------------------------------------------
    */
    watch: {}, // End of Component > watch

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions('textTranslation', {
        rejectFile: 'rejectFile',
        acceptFile: 'acceptFile',
        postSubmitComplete: 'postSubmitComplete',
        sendPriceToCustomer: 'sendPriceToCustomer'
      }),

      ...mapActions('language', {
        getLanguages: 'fetchLanguages'
      }),
      ...mapActions('town', {
        loadTowns: 'browseAsOptions'
      }),
      ...mapActions('booking', {
        getTranslators: 'fetchTranslators',
        getBooking: 'getBooking',
        read: 'read'
      }),
      isEmpty (v) {
        return isEmpty(v);
      },
      handleProfile (val) {
        this.$router.push({
          name: 'user.profile',
          params: {id: val.assigned_translator_id},
          query: {type: this.translator.type}
        });
      },
      getNumFromRef (val) {
        this.thenum = val.reference && val.reference.replace(/^\D+/g, '');
        return this.thenum;
      },
      handleDepartment (val) {
        this.$router.push({name: 'department.edit', params: {id: val.department_id}, query: {}});
      },

      /**
       * Handle when the user email was clicked.
       *
       * @param booking - Booking properties.
       * @return {void}
       */
      handleClickUserEmail (booking) {
        const userType = window._.chain(USER_TYPES)
                               .findKey((t) => t === booking.customer.type)
                               .value().toLowerCase();
        const userId = booking.customer.id;

        // Redirect to User Edit Page.
        this.$router.push({
          name: `user.edit.${userType}`,
          params: {id: userId}
        });

      },

      downloadFile (item) {
        download(item);
      },

      handleClickSubmitTranslated () {
        this.showSubmitTranslatedFileModal = true;
        this.gotoElement('.app-page');
      },

      gotoElement (e) {
        const element = document.querySelector(e);
        if (element) {
          window.scroll({
            top: element.getBoundingClientRect().top + window.scrollY,
            behavior: 'smooth'
          });
        }
      },

      handleRejectTranslatedFile (item) {
        let payload = {
          id: this.booking.text_translation.id,
          file_id: item.id
        };
        this.rejectFile(payload).then(r => {

          this.getBooking(this.booking.id);
        });
      },
      handleAcceptTranslatedFile (item) {
        let payload = {
          id: this.booking.text_translation.id,
          file_id: item.id
        };
        this.acceptFile(payload).then(r => {
          this.$emit('updateItem');
          // this.getBooking(this.book  ing.id)
        });
      },
      handleCompleteTextTranslation () {
        let payload = {
          id: this.booking.text_translation.id
        };
        this.postSubmitComplete(payload).then(r => {

          this.$emit('updateItem');
        });
      },
      handleClickSubmitCost () {
        this.sendPriceToCustomer({
          id: this.booking.text_translation.id,
          cost: this.booking.text_translation.cost
        });
      },

      handleReload(){
        this.getBooking(this.booking.id);
        // window.location.reload();
      }


    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      // this.getNumFromRef();
      populate(this, 'getLanguages', 'languages', {
        ref: 'Languages Options'
      });
      populate(this, 'loadTowns', 'townOpts', {
        ref: 'Towns Listing'
      });
      // populate(this, 'getTranslators', 'translators', {
      //     ref: 'Translators Listing',
      // });

    }
  }; // End of export default
</script>

<!--suppress CssUnusedSymbol -->
<style>
  .el-tooltip__popper.is-dark {
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 5px !important;
    font-size: 14px !important;

  }
</style>
<style lang="scss" scoped>
  .list-files {
    padding-inline-start: 0px;
    list-style-type: none;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
</style>
