<template>
    <div class="app-page user-list-page">

        <page-header title="Translator Late Withdrawal Bookings List" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">

                    <div class="app-col full-width">
                        <div class="content-body">
                          <div class="app-list">
                           <base-list-table
                          :table-data="attemptsDetails"
                          :extra-cols="listCols"
                          :hasDetailsCol="false">
                        <template slot="actionsColumn" slot-scope="scope" style="z-index: 10">
                          
                          <el-button class="circle" type="success" title="View Booking Details" @click.stop="handleView(scope)">
                              <span class="fa fa-pencil"></span>
                          </el-button>
                         
                      </template>
                      </base-list-table>
                      </div>
                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page user-list-page -->
</template>

<script>

import {mapActions,mapGetters} from 'vuex'
import { isEmpty } from '../../../js/helpers/Common';
export default {
  data(){
    return {
      listCols:[
        {
              label: 'Booking ID',
              prop: 'booking_id',
              width: '',
        },
        {
              label: 'Attempts by translator',
              prop: 'by_translator',
              width: '',
        },
        {
              label: 'Attempts by admin',
              prop: 'by_admin',
              width: '',
        },

      ]
    }
  },
  computed:{
    ...mapGetters('booking',['getLateWithdrawalList']),

    attemptsDetails(){
      let group = []
      let translator_id = this.$route.params.id;
    if(!isEmpty(this.getLateWithdrawalList)){
      group =  _(this.getLateWithdrawalList)
      .groupBy('booking_id')
      .map( function(i,v) {
      return {
        booking_id:v,
        attempts:i,
        by_translator: i.filter(o => o.request_by == translator_id).length,
        by_admin: i.filter(o => o.request_by != translator_id).length
      }
    }).value();
    }
      return group
    }
  },
  methods:{
    ...mapActions('booking',{
      fetchLateWithdrawal:'fetchLateWithdrawal'
    }),
    handleView(scope){
      console.log(scope.row.booking_id)
      this.$router.push({name:'booking-details',params:{id:scope.row.booking_id}})
    }
  },
  mounted(){
    this.fetchLateWithdrawal({
      'filter[translator_id]': this.$route.params.id,
      all:true
    })
  }
}
</script>

<style>

</style>
