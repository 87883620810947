/*
|--------------------------------------------------------------------------
| Store > Messaging-Centre > Notification > Actions
|--------------------------------------------------------------------------
|
| This file contains the action of Auth Vuex Module
|
|
*/
import { Notification } from "element-ui";

import i18n from "../../../i18n";
import { isEmpty } from "../../../helpers/Common";
import APICaller from "../../../helpers/APICaller";
let apiPrefix = "v3";
import { gep } from "~/js/helpers/Vuex";

export default {
  getNotification(context, payload) {
    let params = {};
    switch (payload.filter) {
      case 'second' : {
        params = {
          "filter[status]" : 2
        }
          break;
      }
      case 'third' : {
        params = {
          "filter[status]" : 1
        }
          break;
      }
    }
    const method = "get";
    const endpoint = gep("messaging-centre", apiPrefix);

    return APICaller({ method, endpoint, params })
      .then(r => {
        const data = r.data.data.items;
        context.commit("setNotificationList", data);
      })
      .catch(e => {
        console.error(e);
      });
  },

  getSpecificNotification(context, payload) {
  
    const method = "get";
    const endpoint = gep(`messaging-centre/${payload.id}?include=attachments`, apiPrefix);

    return APICaller({ method, endpoint })
      .then(r => {
        const data = r.data.data.item;
       
        context.commit("setNotification", data);
        context.commit("setCompose");
        context.commit("setBookingStatusById", data.sent_via)

      })
      .catch(e => {
        console.error(e);
      });
  },

  updateNotification(context, payload) {
  
    const method = "PUT";
    const endpoint = gep(`messaging-centre/${payload.id}`, apiPrefix);

    return APICaller({
      method,
      endpoint,
      data: payload,
      isDataRaw: true
    })
      .then(r => {
        context.dispatch('getSpecificNotification', { id: payload.id });
        Notification.success({
          title: i18n.t("sucesss"),
          message: "Notification Updated"
        });
      })
      .catch(e => {
        Notification.error({
          title: i18n.t("error"),
          message: e.response.data.data
        });
      })
      .catch(e => {
        console.error(e);
      });
  },

  createNotification(context, payload) {
    
      const method = "POST";
      const endpoint = gep(`messaging-centre`, apiPrefix);
  
      return APICaller({
        method,
        endpoint,
        data: payload,
        isDataRaw: true
      })
        .then(r => {
          Notification.success({
            title: i18n.t("sucesss"),
            message: "Notification Updated"
          });
        })
        .catch(e => {
          Notification.error({
            title: i18n.t("error"),
            message: e.response.data.data
          });
        })
        .catch(e => {
          console.error(e);
        });
    },

  deleteFile(context, payload) {

    const method = "POST";
    const endpoint = gep(`messaging-centre/${payload.id}/delete-attachment/${payload.file_id}`, apiPrefix);

    return APICaller({
        method,
        endpoint,
      })
      .then(r => {
        context.dispatch('getSpecificNotification', {
          id: payload.id
        });
        
        Notification.success({
          title: i18n.t("sucesss"),
          message: "File Deleted"
        });
      })
      .catch(e => {
        Notification.error({
          title: i18n.t("error"),
          message: e.response.data.data
        });
      })
      .catch(e => {
        console.error(e);
      });
  }


};
