<template>
   <div :class="`app-row ${iRefs}`" :id="iRefs" >
     <div class="app-col full-width">
    <el-button type="default" id="show-modal" @click="handleAdd" icon="fa fa-plus">
    {{$t('add')}}</el-button>

    <div>
      <el-table
        :data="emailsData"
        style="width: 100%">
            <el-table-column
              :label="$t('subject')">
              <template slot-scope="scope">
                <span>{{ scope.row.title }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('content')">
              <template slot-scope="scope">
                <span >{{ scope.row.email }}</span>
              </template>
            </el-table-column>
            <el-table-column
            :label="$t('actions')">
            <template slot-scope="scope">
                <el-button class="circle" type="success" title="Remove" @click.stop="handleEdit(scope)">
                    <span class="fa fa-pencil"></span>
                </el-button>
                 <el-button class="circle" type="danger" title="Remove" @click.stop="handleDelete(scope)">
                     <span class="fa fa-trash"></span>
                 </el-button>
            </template>
          </el-table-column>
      </el-table>
      <el-pagination
            layout="total, prev, pager, next, jumper"
            :page-size="listPagination.per_page"
            :total="listPagination.total"
            :current-page="listPagination.current_page"
            @current-change="reloadList">
        </el-pagination>

    </div>
    </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {isEmpty,sd} from "~/js/helpers/Common";
export default {
  name:'emailsTemplatesListPanel',
  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props: {
      vPopulatePayload:{
          type: Object,
          default(){
            return {
            }
          }
      },
      title:{
        type:String,
        default: 'Emails templates'
      },
      refs:{
        default:'emails-templates'
      }
  }, // End of Component > props

  data(){

    const _this = this;
    return {
      templateObject:{
        title:'',
        email:'',
      }
    }
  },
  computed:{

    iTitle(){
      return this.title
    },
    iRefs(){
      return this.refs
    },
    ...mapGetters('emailsTemplates', {
        listPagination: 'listPagination',
        emailsData: 'getEmails'
    }),
    populatePayload: {
        get(){
            return this.vPopulatePayload
        },set(v){
            this.$emit('input',v)
        }
    },
  },
  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods:{
    ...mapActions('emailsTemplates',{
      getEmailsList: 'getEmailsList',
      deleteEntry:'deleteEmail'
   }),
   handleDelete (scope) {

     this.$confirm('This will delete the entry. Continue?', 'Warning', {
       confirmButtonText: 'OK',
       cancelButtonText: 'Cancel',
       type: 'warning'
     }).then(() => {
       this.deleteEntry(scope.row.id).then(response => {
            this.getEmailsList();
       });
     }).catch(() => {
       // Silence is golden.
     });

   }, // End of handleEdit() method
   handleEdit (scope) {
     this.$router.push({path: '/emails-templates/edit/' + scope.row.id});
   },
  handleAdd(){
    this.$router.push({path: '/emails-templates/add'});
  },
 /**
  * Method for reloading the list.
  *
  * @param {int} n
  *   The target page number.
  *
  * @return {void}
  */
     reloadList(n, per_page) {
         let params=_.clone(this.$route.query);
         n = sd(n, sd(this.$route.query.page, 1)) // Set the page number.
         params.page = n;
         if(per_page && typeof per_page == 'number')
         {
             params['per_page']= per_page;
         }
         this.populatePayload.page = params.page
         Object.assign(params,this.populatePayload)
         this.$router.push({query: params}) // Reflect the chosen parameters in the URL Bar
         console.log(params);
         this.getEmailsList(params);
     }, // End of reloadList() method

  },
  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {
     let params =_.clone(this.$route.query)
     Object.assign(params,this.populatePayload)
     const opts = {
         action: 'getEmailsList',
         data: 'emailsData',
         ref: 'Emails Listing',
     };
     this.reloadList(null,opts,params)
  }, // End of Component > mounted
}
</script>
