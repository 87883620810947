<template>
  <el-dialog
    :title="$t('add_comment')"
    :visible.sync="iVisible"
    :modal-append-to-body="false"
    :append-to-body="true"
    custom-class="custom-modal"
    width='60%'>
      <el-form :model="form" :rules="rules"  ref="comment_form">
        <el-form-item label="" prop="comment">
          <wysiwyg v-model="form.comment" :aria-placeholder="$t('enter_text')" :placeholder="$t('enter_text')"/>
        </el-form-item>
        <el-form-item :label="$t('do_not_count_feedback')" v-if="(typeof issue.type != 'undefined') && issue.type.type === 'low_feedback'">
          <el-switch v-model="iDontcountfeedback"></el-switch>
        </el-form-item>
        <el-form-item class="el-form-actions">
          <el-button class="modal-size" type="default" @click="handleCancel()">{{$t('cancel')}}</el-button>
          <el-button class="modal-size" type="primary" @click="handleSubmit('comment_form')">{{$t('submit')}}</el-button>
        </el-form-item>
      </el-form>
  </el-dialog>
</template>

<script>
export default {
  props: {
      visible: {
        type: Boolean,
        default: false
      },
      dontcountfeedback: {
        type: Boolean,
        default: false
      },
      issue: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        form: {
          comment: ''
        },
        rules: {
          comment: [{
            required: true,
            message: 'Please Add Comment'
          }]
        }
      }
    },
    computed: {
      iVisible: {
        get() {
          return this.visible
        },
        set(v) {
          this.$emit('update:visible', v);
        }
      },
      iDontcountfeedback: {
        get() {
          return this.dontcountfeedback
        },
        set(v) {
          this.$emit('update:dontcountfeedback', v);
        }
      },
    },
    methods:{
      handleSubmit(form){
        this.$refs[form].validate((valid) => {
          if(valid){
          this.$emit('addComment',this.form)
          this.iVisible = false
          }else{
            return false
          }
        })
      },
      handleCancel(){
        this.form.comment = ''
        this.iVisible = !this.iVisible
      }
    }
}
</script>