<template>
  <div>
    <div class="app-page low-feedback-Detail-page">
      <page-header :title="$t('survey_response')" />
      <div class="app-container">
        <div class="app-wrapper">
          <el-row :gutter="20">
            <ElCol :span="16">
              <el-card class="box-card green">
                <div slot="header" class="clearfix">
                  <span>{{$route.params.survey}}</span>
                </div>
                <div class="text item">
                  <template>
                     <div v-for="(data, index) in surveyDetail">
                    <el-row  style="backgorund: #f2f2f2" :gutter="10">

                      <ElCol :md="4">
                        <div class="grid-content bg-purple">
                          <h1>{{index+1}}</h1>
                        </div>
                      </ElCol>
                      <ElCol :md="16">
                        <div class="grid-details bg-purple">
                          <p>Q: {{data.survey_question.title}} </p>
                        </div>
                        <div class="grid-details bg-purple-light">
                          <p v-if="!checkIfArray(data.answer)" >A: {{data.answer}}</p>
                          <div v-if="checkIfArray(data.answer)">
                            <p v-for="(item, index) in data.answer">A{{index+1}}: {{item}}</p>
                          </div>
                        </div>
                      </ElCol>
                    </el-row>
                      </div>
                  </template>
                </div>
              </el-card>
            </ElCol>
            <ElCol :span="8">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>{{$t('details')}}</span>
              </div>
              <template>
                <div class="survey-right">
                  <ul>
                    <li>
                      <h4>{{$t('name')}}</h4>
                      <p>
                        <span class="text-success" v-if="surveyDetail && surveyDetail[0]">{{surveyDetail[0].user.name}}</span>
                      </p>
                    </li>

                    <li>
                      <h4>{{$t('user_type')}}</h4>
                      <p>
                        <span class="text-success" v-if="surveyDetail && surveyDetail[0]">{{surveyDetail[0].user.type == 1 ? $t('admin') :
                           surveyDetail[0].user.type == 2 ? $t('customer'):
                            surveyDetail[0].user.type == 3 ? $t('translator'): null}}</span>
                      </p>
                    </li>

                    <li>
                      <h4>{{$t('date_submitted')}}</h4>
                      <p>
                        <span class="text-success" v-if="surveyDetail">{{surveyDetail.created_at | moment}}</span>
                      </p>
                    </li>
                  </ul>
                </div>

                <hr />
                <template>
                  <h4>{{$t('actions')}}</h4>
                  <div class="action-panel">
                    <ul style="list-style-type:none;">
                      <li>
                        <el-button type="primary">
                          <i class="fa fa-envelope-o" aria-hidden="true"></i>
                          <span class="action-btn" @click="showEmailModal = true">{{$t('send_email_responder')}}</span>
                        </el-button>
                      </li>
                    </ul>
                  </div>
                </template>
              </template>
            </el-card>
            </ElCol>
          </el-row>
        </div>
        <!-- /.app-wrapper -->
      </div>
    </div>
    <send-email-to-user-modal :user="surveyDetail" :visible.sync="showEmailModal"></send-email-to-user-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import i18n from "~/js/i18n";
import { Notification } from "element-ui";
import { resolve } from 'q';

export default {
  data() {
    return {
      surveyDetail: [],
      showEmailModal: false,
    }
  },
  methods: {
    ...mapActions({
      getSurveyResponse: "surveyManager/userSurveyResponse"
    }),
    /**
     * Check if Item is Array
     */
    checkIfArray(item) {
      if(item.length > 0) {
       return  Array.isArray(item)
      }
    },
    /**
     * Get survey response
     */
    getResponse() {
      let payload = {
        id: this.$route.params.id,
        user: this.$route.params.user
      };

      this.getSurveyResponse(payload).then(res => {
        this.surveyDetail = res.data.items;
      });
    }
  },
  filters: {
  moment: function (date) {
    return moment(date).format('YYYY-MM-DD');
  }
},

  mounted() {
    this.getResponse();
  }
};
</script>



<style lang="scss" scoped>
  //noinspection CssUnknownTarget
  @import '~/scss/global/_variables.scss';

.el-col {
  border-radius: 4px;
}
.el-card__header {
  background-color: $app-primary-color !important;
  color: white!important;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 20px;
  padding: 10px;
  text-align: center;
}
.grid-details {
  border-radius: 4px;
  min-height: 20px;
  padding: 10px;
  margin-top: 5px;
}
.survey-right h4 {
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 0.4em;
}
.survey-right p {
  line-height: 0.4em;
}
.action-panel .el-button {
  min-width: 220px;
  font-size: 12px;
}
.action-panel .el-button i.fa {
  float: left;
}
.action-btn {
  float: left;
  padding-left: 10px;
}

</style>
