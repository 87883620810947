<template>
    <div class="app-form" :class="getBem(blockCls)">

        <div class="app-splitter two-cols app-flex-space-b-top">

            <!-- Invoice Form Container -->
            <div class="invoice-other-settings-form-container">

                <h3 class="app-title title-shaded">
                    <span>{{$t('invoice')}}</span>
                </h3>

                <el-form :class="getBem(blockCls, 'invoice')" style="padding: 0 7px">
                    <financial-language-settings-fields
                        :class="getBem(blockCls, 'invoice-fields')"
                        v-model="invoiceForm.language_settings"
                    />
                </el-form>
            </div>

            <!-- Salary Form Container -->
            <div class="salary-other-settings-form-container">

                <h3 class="app-title title-shaded">
                    <span>{{$t('salary')}}</span>
                </h3>

                <el-form :class="getBem(blockCls, 'salary')" style="padding: 0 7px">
                    <financial-language-settings-fields
                        :class="getBem(blockCls, 'salary-fields')"
                        v-model="salaryForm.language_settings"
                    />
                </el-form>
            </div>

        </div>
        <!-- /.app-splitter two-cols -->

    </div> <!-- /.app-form price-other-settings-form -->
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import {populate} from '~/js/helpers/Vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'price-language-settings-form',
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('financialInvoice', {
        invoiceForm: 'settings',
      }),

      ...mapState('financialSalary', {
        salaryForm: 'settings',
      }),

      ...mapGetters('language', {
        languageOpts: 'getLanguages',
      }),
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions('language', {
        loadLanguages: 'fetchLanguages',
      }),
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      populate(this, 'loadLanguages', 'languageOpts', {
        ref: 'Languages Listing',
      });
    },

  }
</script>
