<template>
<el-row>
  <ElCol :span="12">
    <el-form :model="iForm" ref="cost-edit-form" @submit.native.prevent="handleSubmit">
    <el-row>
      <ElCol :span="11">Word count</ElCol>
      <ElCol :span="1">:</ElCol>
      <ElCol :span="12">
        <ElFormItem label="" >
      
          <el-input v-model="words_count" placeholder="Cost per words" @input="calculate"></el-input>
      </ElFormItem>
      </ElCol>
    </el-row>
   <el-row>
     <ElCol :span="11">Cost per words</ElCol>
     <ElCol :span="1">:</ElCol>
     <ElCol :span="12">
        <ElFormItem label="">
          <el-input v-model="cost_per_words" placeholder="Cost per words" @input="calculate"></el-input>
        </ElFormItem>
     </ElCol>
   </el-row>
   <el-row>
     <ElCol :span="11">Cost</ElCol>
     <ElCol :span="1">:</ElCol>
     <ElCol :span="12">
        <ElFormItem label="">
          <el-input v-model="iForm.cost" placeholder="Cost"></el-input>
        </ElFormItem>
     </ElCol>
   </el-row>
   
   <el-row>
     <ElCol :span="11">Salary per words</ElCol>
     <ElCol :span="1">:</ElCol>
     <ElCol :span="12"> 
        <ElFormItem label="">
          <el-input v-model="salary_per_words" placeholder="Salary per words" @input="calculate"></el-input>
        </ElFormItem>
    </ElCol>
   </el-row>
   <el-row>
     <ElCol :span="11">Salary</ElCol>
     <ElCol :span="1">:</ElCol>
     <ElCol :span="12"> 
         <ElFormItem label="">
          <el-input v-model="iForm.salary" placeholder="Salary"></el-input>
          </ElFormItem>
    </ElCol>
   </el-row>
   
    <el-form-item label="">
      <el-button type="success" @click="handleSubmit" >Submit</el-button>
    </el-form-item>
  </el-form>
  </ElCol>
</el-row>
  
</template>

<script>
import {mapActions,mapGetters} from 'vuex'
export default {
  props:{
    form:{
      type: Object,
      default(){
        return {
          cost:'',
          salary:'',
          id:''
        }
      }
    }
  },
  data(){
    return {
      cost_per_words:3,
      salary_per_words:2,
      words_count:0
    }
  },
  computed:{
    iForm:{
      get(){
        return this.form
      },
      set(v){
        this.$emit('input',v)
      }
    }
  },
  methods:{
    ...mapActions('textTranslation',{
      update:'putUpdate'
    }),
    handleSubmit(){
      this.update(this.iForm)
    },
    calculate(){
      this.iForm.cost = this.cost_per_words * this.words_count
      this.iForm.salary = this.salary_per_words * this.words_count
    }
  }
}
</script>

<style>

</style>
