<template>
  <div :class="getBem(blockClass)">
    <ul>
      <li :class="getBem(blockClass, 'input-field')">
        <el-input size="mini" v-model="id" :placeholder="$t('tfv_find_booking')" @keyup.enter.native="search">
          <span class="el-icon-search el-input__icon app-cursor-pointer" slot="suffix"
                @click="search"></span>
        </el-input>
      </li>
      <li :class="getBem(blockClass, 'input-field')">
        <el-input class="top-bar-extras-user-search-input"
                  v-model="userQ" size="mini"
                  @keyup.enter.native="browseUser"
                  :placeholder="$t('tfv_find_user')">
                    <span class="el-icon-search el-input__icon app-cursor-pointer"
                          slot="suffix" @click="browseUser"></span>
        </el-input>
      </li>
      <li :class="getBem(blockClass, 'icon-button')">
        <top-bar-notification/>
      </li>
      <li :class="getBem(blockClass, 'icon-button')">
        <el-tooltip :content="$t('users_list')" placement="bottom">
          <router-link to="/user/list" @click.stop.prevent :title="$t('users_list')">
            <img class="img-icon" src="/images/shortcuts/user-list-icon.png"
                 alt="user-list-icon.png"><br>
          </router-link>
        </el-tooltip>
      </li>
      <li :class="getBem(blockClass, 'icon-button')">
        <el-tooltip :content="$t('booking_list')" placement="bottom">
          <router-link to="/bookings" @click.stop.prevent :title="$t('booking_list')">
            <img class="img-icon" src="/images/shortcuts/booking-list-icon.png"
                 alt="booking-list-icon.png"><br>
          </router-link>
        </el-tooltip>
      </li>
      <li :class="getBem(blockClass, 'icon-button')">
        <el-tooltip :content="$t('create_booking')" placement="bottom">
          <router-link to="/booking/create" @click.stop.prevent :title="$t('create_booking')">
            <img class="img-icon" src="/images/shortcuts/booking-create-icon.png"
                 alt="booking-create-icon.png"><br>
          </router-link>
        </el-tooltip>
      </li>
      <!-- <li :class="getBem(blockClass, 'icon-button')">
        <el-tooltip :content="$t('statistics')" placement="bottom">
          <router-link to="/quick-stats" @click.stop.prevent :title="$t('statistics')">
            <span class="fa fa-bar-chart"></span>
          </router-link>
        </el-tooltip>
      </li> -->
      <li :class="getBem(blockClass, 'icon-button')">
        <el-tooltip :content="$t('settings')" placement="bottom">
          <router-link to="/settings" @click.stop.prevent :title="$t('settings')">
            <span class="fa fa-cog"></span>
          </router-link>
        </el-tooltip>
      </li>
      <li :class="getBem(blockClass, 'icon-button')">
        <el-tooltip :content="$t('logout')" placement="bottom">
          <router-link to="/logout" @click.stop.prevent :title="$t('logout')">
            <span class="fa fa-sign-out"></span>
          </router-link>
        </el-tooltip>
      </li>
      <li
        :class="[
              getBem(blockClass, 'select-field'),
              getBem(blockClass, 'color-theme-field')
          ]"
      >
        <el-select v-model="iColorTheme"
                   size="mini"
                   class="color-theme-selector"
                   popper-class="color-theme-selector-popper"
                   :placeholder="$t('select')">
          <el-option :label="$t('light')" value="default"/>
          <el-option :label="$t('dark')" value="dark"/>
        </el-select>
      </li>
      <li
        :class="[
              getBem(blockClass, 'select-field'),
              getBem(blockClass, 'locale-field')
          ]"
      >
        <el-select
          v-model="iLanguage"
          size="mini"
          class="color-theme-selector"
          popper-class="color-theme-selector-popper"
        >
          <el-option label="EN" value="en"/>
          <el-option label="SE" value="se"/>
        </el-select>
      </li>
    </ul>
  </div> <!-- /.top-bar-extras -->
</template>

<script>
  import APICaller from "../../js/helpers/APICaller";
  import {mapState, mapMutations, mapActions} from "vuex";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        id: '',
        userQ: '',
        blockClass: 'top-bar-extras'
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapState('ui', ['colorTheme', 'language']),

      iColorTheme: {
        get () {
          return this.colorTheme
        },
        set (v) {
          this.saveColorTheme(v);
        }
      },
      iLanguage: {
        get () {
          return this.language
        },
        set (v) {
          this.saveLanguage(v);
        }
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions({
        browse: 'booking/browse'
      }),

      ...mapMutations({
        saveColorTheme: 'ui/saveColorTheme',
        saveLanguage: 'ui/saveLanguage',
      }),

      search () {
        // populate(this, 'browse', '', {
        //     force: true,
        //     params: { 'filter[id]' : this.id}
        // })
        this.$router.replace({name: 'booking-details', params: {id: this.id}});
        this.id = '';
        // window.location.reload();
      },

      handleSearchUser () {
        const isNumeric = (x) => (typeof x === 'number');
        let key = isNumeric(this.userQ) ? 'id' : 'name';
        let query = {};
        query[`filter[${key}]`] = this.userQ;
        const path = '/user/list';
        this.$router.push({path, query});
        if (this.$route.path === path)
          window.location.reload();
      },

      browseUser () {
        let target = this.$store.state.user.api.browse['user'];
        let method = target.method;
        let endpoint = target.path;
        let params = {
          'filter[name]': this.userQ,
          'filter[type]': '2,3'
        };
        APICaller({method, endpoint, params})
          .then((r) => {
            const isResponseValid = !window._.isNil(r.data)
              && !window._.isNil(r.data.data)
              && !window._.isNil(r.data.data.users);

            if (isResponseValid) {
              const userCollection = r.data.data.users;

              if (userCollection.length === 1) {
                const firstUserId = userCollection[0].id;
                const firstUserType = userCollection[0].type;
                const routeName = firstUserType === 2 ? 'user.edit.customer' : 'user.edit.translator';

                this.$router.push({
                  name: routeName,
                  params: {id: firstUserId}
                });

              } else {
                this.handleSearchUser();
              }
            }
          });
      },
    },

  }

</script>
