<template>

  <div class="app-page quick-stats-page" style="background:none">

    <page-header title="Quick Stats" />

    <div class="dashboard-stats">
      <div class="dashboard-stat">
        <div class="dashboard-stat-title">Booking Created Today</div>
        <div class="dashboard-stat-number">{{ basicStatistics.total}}
        </div>
             <b>As of, {{this.getLastDate()}}</b>
      </div>
      <div class="dashboard-stat">
        <div class="dashboard-stat-title">Customer Bookings</div>
        <div class="dashboard-stat-number"> {{ basicStatistics.customer}}</div>
        <div class="dashboard-stat-text">Booking Made on Mobile App: {{ basicStatistics.mobile}}</div>
        <b>As of, {{this.getLastDate()}}</b>

      </div>
      <div class="dashboard-stat">
        <div class="dashboard-stat-title">Admin Bookings</div>
        <div class="dashboard-stat-number">{{ basicStatistics.admin}}</div>
        <b>As of, {{this.getLastDate()}}</b>
      </div>
    </div>

    <div class="app-container app-container--statPadding">
      <div class="app-wrapper">
        <div class="app-row">

          <div class="app-col full-width app-col--statPadding" id="bgcontainer">
            <div class="content-body">
              <el-tabs v-model="activeTab">
                <el-tab-pane label="Daily Bookings" name="daily_bookings">
                 <div  class="container-chart1 expiry-chart">
                  <div class="single-chart">
                    <h2>DAILY GRAPH</h2>
                    <div class="date">
                      <strong>{{ getMonth() }}</strong>
                      <span>Compare to Previous Month</span>
                    </div>
                    <multi-bar-chart-component chart-id="bookings-data-chart-1" type="booking_user_types_this_month" >
                    </multi-bar-chart-component>
                  </div>
                </div>
                   <div  class="container-chart1 expiry-chart">

                  <div class="single-chart">
                    <div class="date">
                      <strong>{{ getLastMonth() }}</strong>
                    </div>
                    <multi-bar-chart-component chart-id="bookings-data-chart-2" type="booking_user_types_prev_month">
                    </multi-bar-chart-component>

                  </div>
                   </div>
                <div  class="container-chart1 expiry-chart">
                  <div class="single-chart">
                    <h2>DAILY GRAPH _ SESSION TYPE BOOKED</h2>
                    <div class="date">
                      <strong>{{ getMonth() }}</strong>
                      <span>Compare to Previous Month</span>
                    </div>
                    <multi-chart-component chart-id="bookings-data-chart-3" type="booking_types_this_month">
                    </multi-chart-component>
                  </div>
                </div>
                <div  class="container-chart1 expiry-chart">

                  <div class="single-chart">
                    <div class="date">
                      <strong>{{ getLastMonth() }}</strong>
                    </div>
                    <multi-chart-component chart-id="bookings-data-chart-4" type="booking_types_prev_month">
                    </multi-chart-component>
                  </div>
                </div>
                <div  class="container-chart1 expiry-chart">
                  <div class="single-chart">
                    <h2>BOOKINGS IN LAST 24 MONTHS</h2>
                    <div class="date">
                      <b style="color:red;margin:0 1em 0 0;">As of, {{this.getLastDate()}}</b>
                      <strong>Last 24 Months</strong>
                    </div>
                    <multi-bar-chart-component chart-id="bookings-data-chart-5" type="booking_user_types_last_two_years">
                    </multi-bar-chart-component>
                  </div>
                </div>
              </el-tab-pane>


              <el-tab-pane label="Total Bookings in a Month" name="total_bookings_in_a_month">
                <div  class="container-chart1 expiry-chart">
                  <div class="single-chart">
                    <h2>TOTAL BOOKINGS IN A MONTH</h2>
                    <div class="date">
                      <strong>{{ getMonth() }}</strong>
                      <span>Compare to Previous Month</span>
                    </div>
                    <multi-bar-chart-component chart-id="bookings-data-chart-6" type="booking_user_types_this_month_to_date" >
                    </multi-bar-chart-component>
                  </div>
                </div>
                <div  class="container-chart1 expiry-chart">
                  <div class="single-chart">
                    <div class="date">
                     <strong>{{ getLastMonth() }}</strong>
                   </div>
                   <multi-bar-chart-component chart-id="bookings-data-chart-7" type="booking_user_types_prev_month_to_date">
                   </multi-bar-chart-component>
                 </div>
               </div>
               <div class="container-chart1 expiry-chart">
                <div class="single-chart">
                  <div class="date">
                    <strong>{{ getMonthLastYear() }}</strong>
                  </div>
                  <multi-chart-component chart-id="bookings-data-chart-8" type="booking_types_this_month_to_date">
                  </multi-chart-component>
                </div>
              </div>
            </el-tab-pane>

            <el-tab-pane label="Expired Bookings" name="expired_bookings">
              <div  class="container-chart1 expiry-chart">
                <div class="single-chart">
                  <h2>Expired Bookings</h2>
                  <div class="date">
                    <strong>{{ getMonth() }}</strong>
                    <span>Compare to Previous Month</span>
                  </div>
                  <single-bar-chart-component chart-id="bookings-data-chart-9" type="month" :fromDate="this.getStartMonth()" :toDate="this.getMonthDate()"></single-bar-chart-component>
                </div>
                </div>
                <div  class="container-chart1 expiry-chart">
                  <div class="single-chart">
                  <div class="date">
                    <strong>{{ getLastMonth() }}</strong>
                  </div>
                  <single-bar-chart-component chart-id="bookings-data-chart-10"  type="month"  :fromDate="this.getStartLastMonth()" :toDate="this.getStartMonth()"></single-bar-chart-component>
                </div>
                  </div>

              <div  class="container-chart1 expiry-chart">
                <h2>Expired Bookings in Last 24 Months</h2>
                <div class="date">
                  <strong>Last 24 Months</strong>
                </div>
                <single-bar-chart-component-with-label chart-id="bookings-data-chart-11"  type="annual"  :fromDate="this.getLastTwoYear()" :toDate="this.getMonthDate()"></single-bar-chart-component-with-label>
              </div>
            </el-tab-pane>
          </el-tabs>

          <!-- <admin-user-compose-form/>-->

        </div> <!-- /.content-body -->
      </div> <!-- /.app-col -->
      <!-- Expired Bookings -->
      <div id="backgroundleft" v-show="activeTab == 'expired_bookings'">
        <div class="container-table">
         <table-components title="MOST EXPIRED LANGUAGES - TODAY"
         :fromDate="this.getCurrentDate()" :toDate= "this.getCurrentDate()" :date="this.getDateFormat()" type= "expired" text="Total Booked Today"></table-components>
       </div>
       <div class="container-table">
         <!--<table-components title="MOST EXPIRED LANGUAGES"
         :fromDate="this.getLastBeforetDate()" :toDate= "this.getLastCurrentDate()" :date="this.getLastMonthDate()" type= "expired" text="Total Booked Today"></table-components>-->
         <table-components title="MOST EXPIRED LANGUAGES"
         :fromDate="this.getLastCurrentDate()" :toDate= "this.getLastCurrentDate()" :date="this.getLastMonthDate()" type= "expired" text="Total Booked Today"></table-components>
       </div>
     </div>
     <div id="backgroundright" v-show="activeTab == 'expired_bookings'">
      <div class="container-table">
       <table-components title="MOST EXPIRED LANGUAGES - THIS MONTH"
       :fromDate="this.getStartMonth()" :toDate= "this.getCurrentDate()" :date="this.getMonth()" type= "expired" text="Total Booked This Month"></table-components>
     </div>
     <div class="container-table">
       <table-components title="MOST EXPIRED LANGUAGES"
       :fromDate="this.getStartLastMonth()" :toDate= "this.getEndLastMonth()" :date="this.getLastMonth()" type= "expired" text="Total Booked This Month"></table-components>
     </div>
   </div>
   <div id="backgroundleft" v-show="activeTab == 'expired_bookings'">
     <div class="container-table">
       <tableMulti title="MOST EXPIRED BOOKINGS BY MUNICIPALITY"
       :date="this.getLastMonth()" text="Total Expired Daily" type="bookings_municipality_prev_expired"></tableMulti>
     </div>
   </div>
   <div id="backgroundright" v-show="activeTab == 'expired_bookings'">
     <div class="container-table">
       <tableMulti title="MOST EXPIRED BOOKINGS BY MUNICIPALITY"
       :date="this.getMonth()" text="Total Expired Monthly" type="bookings_municipality_expired"></tableMulti>
     </div>
   </div>
   <!-- Daily Bookings -->
   <div id="backgroundleft" v-show="activeTab == 'daily_bookings'">
    <div class="container-table">
     <table-components title="MOST BOOKED LANGUAGES - TODAY"
     :fromDate="this.getBeforetDate()" :toDate= "this.getCurrentDate()" :date="this.getDateFormat()" type= "normal" text="Total Booked Today"></table-components>
   </div>
   <div class="container-table">
     <table-components title="MOST BOOKED LANGUAGES"
     :fromDate="this.getLastBeforetDate()" :toDate= "this.getLastCurrentDate()" :date="this.getLastMonthDate()" type= "normal" text="Total Booked Today"></table-components>
   </div>
 </div>
 <div id="backgroundright" v-show="activeTab == 'daily_bookings'">
  <div class="container-table">
   <table-components title="MOST BOOKED LANGUAGES - THIS MONTH"
   :fromDate="this.getStartMonth()" :toDate= "this.getCurrentDate()" :date="this.getMonth()" type= "normal" text="Total Booked This Month"></table-components>
 </div>
 <div class="container-table">
   <table-components title="MOST BOOKED LANGUAGES"
   :fromDate="this.getStartLastMonth()" :toDate= "this.getEndLastMonth()" :date="this.getLastMonth()" type= "normal" text="Total Booked This Month"></table-components>
 </div>
</div>

<!-- Pie Chart For Daily Bookings -->
<div id="bgcontainer" v-show="activeTab == 'daily_bookings'">
  <div class="ratings-header">
    <div class="ratings-header-text">CUSTOMERS MADE BOOKINGS</div>
    <p class="date-current">{{ getLastDate() }}</p>
  </div>
  <div class="piechartContainer" v-if="this.pieData">
    <div class="piechartheading"> {{ this.pieData[0]['municipality_name'] }} </div>
    <pie-chart-component class="pie-chart" chart-id="pie-chart-" color-one="#D8D8D8" color-two="#D0CFFC" :first-val="this.pieData[0]['count']" :sec-val="this.pieData[0]['total']">
    </pie-chart-component>
  </div>
  <div class="piechartContainer" v-if="this.pieData">
    <div class="piechartheading"> {{ this.pieData[1]['municipality_name'] }} </div>
    <pie-chart-component class="pie-chart" chart-id="pie-chart-2" color-one="#D8D8D8" color-two="#FFC0B8" :first-val="this.pieData[1]['count']" :sec-val="this.pieData[1]['total']">
    </pie-chart-component>
  </div>
  <div class="piechartContainer" v-if="this.pieData">
    <div class="piechartheading"> {{ this.pieData[2]['municipality_name'] }} </div>
    <pie-chart-component class="pie-chart" chart-id="pie-chart-3" color-one="#D8D8D8" color-two="#8BD9F9" :first-val="this.pieData[2]['count']" :sec-val="this.pieData[2]['total']">
    </pie-chart-component>
  </div>
  <div class="piechartContainer" v-if="this.pieData">
    <div class="piechartheading"> {{ this.pieData[3]['municipality_name'] }} </div>
    <pie-chart-component class="pie-chart" chart-id="pie-chart-4" color-one="#D8D8D8" color-two="#FFDA83" :first-val="this.pieData[3]['count']" :sec-val="this.pieData[3]['total']">
    </pie-chart-component>
  </div>
</div>

<!-- Table At Bottom -->
<div id="bgcontainer" v-show="activeTab == 'daily_bookings'">
  <div class="ratings-header">
    <div class="ratings-header-text">CUSTOMERS MADE BOOKINGS</div>
    <p class="date-current">{{ getLastDate() }}</p>
  </div>
  <div class="container-table">
   <company-report-display/>
 </div>
</div>
</div>
<!--here -->
</div> <!-- /.app-row -->
</div> <!-- /.app-wrapper -->
</div> <!-- /.app-container -->

</div> <!-- /.app-page user-compose-page -->
</template>
<style scoped>

</style>
<script>
    //import {mapActions,mapGetters} from "vuex";
    //import {populate} from "~/js/helpers/Vuex";

    // const axios = require('axios');
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import fab from '~/components/displays/FloatingActionButton';
    import companyReportDisplay from './table-components/DashboardCompanyReportDisplay';
    import tableComponents from './table-components/tableComponents';
    import tableMulti from './table-components/tableComponentsMulti';
    import pieChartComponent from './table-components/pieChartComponent';
    import multiBarChartComponent from './table-components/multiBarChartComponent';
    import multiChartComponent from './table-components/multiChartComponent';
    import singleBarChartComponent from './table-components/singleBarChartComponent';
    import singleBarChartComponentWithLabel from './table-components/singleBarChartComponentWithLabel';
    export default {
      mounted() {
        let self = this;
        let token = localStorage.getItem("app_access_token");
        axios.get("https://api-gateway.digitaltolk.se/api/v3/bookings-statistics/municipality?type=bookings_municipality_normal",
          {
              headers:
          {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
          }
          })
          .then(resp => {
            self.pieData  = resp.data;
          }).catch(err => {
              console.log(err);
          });

          axios.get("https://api-gateway.digitaltolk.se/api/v3/bookings-statistics/basic",
          {
              headers:
          {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
          }
          })
          .then(resp => {
             this.basicStatistics = resp.data;
          }).catch(err => {
              console.log(err);
          });
      },
      props : {
      },
      components :{
        tableComponents : tableComponents,
        tableMulti: tableMulti,
        companyReportDisplay: companyReportDisplay,
        pieChartComponent : pieChartComponent,
        singleBarChartComponent: singleBarChartComponent,
        multiBarChartComponent : multiBarChartComponent,
        multiChartComponent: multiChartComponent,
        singleBarChartComponentWithLabel: singleBarChartComponentWithLabel,
        fab : fab
      },
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
          return {
            renderSingleChart : false,
            activeTab: 'daily_bookings',
            window,
            pieData : null,
            basicStatistics: null
          };
        }, // End of Component > data
        computed : {
          fromDateData :function(){
            var fromdate = moment().startOf('month').format('YYYY-MM-DD');
            return fromdate;
          },

          toDateData :function(){
            var todate = moment().format('YYYY-MM-DD');
            return todate;
          }
        },
        methods: {
        /*  getCustomerBookings: function(){
            return localStorage.getItem('customer_bookings');
          },
          getAdminBookings: function(){
            return localStorage.getItem('admin_bookings');
          },
          getAllBookings: function(){
            return localStorage.getItem('bookings_all');
          },
          */
          /* Get Year For Display in Header*/
          getYear: function(){
           var currentYear =  moment().format('YYYY');
           return currentYear;
         },
         /* Get Month For Display in Header*/
         getMonth: function(){
           var currentMonth =  moment().format('MMM YYYY');
           return currentMonth;
         },
         getMonthLastYear: function(){
            var currentMonth =  moment().subtract(1, 'years').format('MMM YYYY');
           return currentMonth;
         },
         /* Get Month For Display in Header*/
         getLastMonth: function(){
           var lastMonth =  moment().subtract(1, 'months').format('MMM YYYY');
           return lastMonth;
         },
         /* Get Date For Display in Header For Pie Chart*/
         getDate: function(){
           var todayDate =  moment().format('MMMM, DD YYYY');
           return todayDate;
         },
         getLastDate: function(){
           var todayDate =  moment().subtract(1,'days').format('MMMM, DD YYYY');
           return todayDate;
         },
         /* Get Last Month Current Date For Display in Header For Pie Chart*/
         getLastMonthDate: function(){
           var lastMonthDate =  moment().subtract(1, 'months').format('DD MMM YYYY');
           return lastMonthDate;
         },
         /* Get Date For Display in Header For Table*/
         getDateFormat: function(){
           var todayDate =  moment().format('DD MMM YYYY');
           return todayDate;
         },
         /* Get Current Date*/
         getCurrentDate: function(){
           var currentDate =  moment().format('YYYY-MM-DD');
           return currentDate;
         },
         /* Get Current Date*/
         getMonthDate: function(){
           var currentDate =  moment().add(1,'days').format('YYYY-MM-DD');
           return currentDate;
         },
         /* Get One Day ago Date*/
         getBeforetDate: function(){
           var beforeDate =  moment().subtract(1,'days').format('YYYY-MM-DD');
           return beforeDate;
         },
         /* Get One Day after Date*/
         getNextDate: function(){
           var beforeDate =  moment().add(1,'days').format('YYYY-MM-DD');
           return beforeDate;
         },
         /* Get One Day ago Date*/
         getBeforeDateFormat: function(){
           var beforeDate =  moment().subtract(1,'days').format('DD MMM YYYY');
           return beforeDate;
         },
         /* Get Last Month Current Date*/
         getLastCurrentDate: function(){
           var lastCurrentDate =  moment().subtract(1, 'months').add(1,'da').format('YYYY-MM-DD');
           return lastCurrentDate;
         },
         /* Get One Day ago Last Current Date*/
         getLastBeforetDate: function(){
           var beforeDate =  moment().subtract(1, 'months').format('YYYY-MM-DD');
           var lastBeforeDate = moment(beforeDate).subtract(1,'days').format('YYYY-MM-DD');
           return lastBeforeDate;
         },
         /* Get 24 Month Before Date */
         getLastTwoYear: function(){
           var lastTwoYear =  moment().subtract(24, 'months').format('YYYY-MM-DD');
           return lastTwoYear;
         },
         /* Get Start Date of Last Month */
         getStartLastMonth: function(){
          var startOfLast =  moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
          return startOfLast;
        },
        /* Get End Date of Last Month */
        getEndLastMonth: function(){
          var endOfLast =  moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
          return endOfLast;
        },
        /* Get Start Date of Current Month */
        getStartMonth: function(){
          var StartOfMonth =  moment().startOf('month').format('YYYY-MM-DD');
          return StartOfMonth;
        },
        /* Get Start Date of Current Month */
        getStartNextMonth: function(){
          var StartOfMonth =  moment().add(1, 'months').startOf('months').format('YYYY-MM-DD');
          return StartOfMonth;
        },
        /* Get End Date of Current Month */
        getEndMonth: function(){
          var EndOfMonth =  moment().endOf('month').format('YYYY-MM-DD');
          return EndOfMonth;
        }
      }
    } // End of export default
  </script>

  <style>
  .expiry-chart{
    padding-top: 15px;
    padding-bottom: 20px;
    margin-top: 10px;
  }
  .expiry-chart h2{
    font-size: 18px;
    color: #000 !important;
    margin-left: 15px;
    display: inline-block;
    text-transform: uppercase;
  }
  .expiry-chart .date{
    float: right;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .expiry-chart .date strong{
    font-weight: 500;
    font-size: 18px;
    margin-right: 15px;
  }
  .expiry-chart .date span{
    background-color: #A4AFB7 !important;
    color: #fff !important;
    padding: 10px 15px;
    border-radius: 5px;
    vertical-align: top;
    line-height: 15px;
    margin-right: 15px !important;
  }
  .expiry-chart .single-chart.sec{
    border-top: 1px solid #A4AFB7;
    margin-top: 15px;
  }
  .date-current{
    margin: 5px 0px 0px 0px  !important;
  }
  .dashboard-stats {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    width: calc(100% - 14em);
    z-index: 5;
    padding: 0 7em;
    top: 70px;
    left: 0px;
  }

  .dashboard-stat {
    padding: 1.5em;
    border-radius: 5px;
    background: linear-gradient(180deg, rgba(26,169,241,1) 5%, rgba(11,116,209,1) 77%);
    color: white;
    flex: 1;
    margin: 0 0.7em;
    box-shadow: 3px 3px 6px rgba(128, 128, 128, 0.47058823529411764);
  }

  .dashboard-stat-number {
    color: white;
    font-size: 3em;
    margin: 0.3em 0;
    font-weight: 900;
  }

  .dashboard-stat-title {
    font-size: 1.2em;
    font-weight: 700;
  }

  .dashboard-stat-text {
    font-style: italic;
  }

  .app-container.app-container--statPadding {
    padding-top: 100px;
  }

  .app-col.app-col--statPadding {
    padding: 100px 15px 7px !important;
  }

  .ratings-header {
    /*    display: flex;*/
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0.5em;
    font-size: 1.1em;
  }

  .ratings-header-text {
    font-weight: bold;
    color: #6F6f6d;
  }

  .ratings-header-date {
    color: #b5b5b5;
  }

  .ratings-table {
    text-align: center;
    width: 100%;
    font-size: 11px;
    border-spacing: 0px;
  }

  .ratings-table td,
  .ratings-table th {
    padding: 12px 0;
  }
  .ratings-table th {
    background-color: #edeffb;
  }

  .ratings-table tbody tr:not(:last-child) td {
    border-bottom: 1px solid #e2e2e2;
  }

  /* Pie Chart Css */

  .piechartContainer{
   width: 43%;
   height: auto;
   display: inline-block;
   background-color: #fff;
   border-radius: 5px;
   border: #efe9e9 2px solid;
   padding: 30px 35px 30px 35px;
   margin-top: 10px;
 }

 #bgcontainer{
  background-color: #fafafa;
  height: auto;
  width: 100%;
  padding: 15px;
}

.pie-chart{
  width: 200px !important;
  height: 100px !important;
  margin-left: 200px !important;
}

.piechartheading{
  position: relative;
  float: left;
  margin-top: 35px;
  width: 55%;
  font-size: 19px;
  font-weight: 750;
}

#backgroundleft{
  background-color: #fafafa;
  height: auto;
  width: 37%;
  padding: 15px;
  padding: 5px 15px 15px 15px ;
}
#backgroundright{
  background-color: #fafafa;
  height: auto;
  width: 37%;
  padding: 5px 15px 15px 15px ;
}

.container-table{
 width: auto;
 margin-top: 10px;
 height: auto;
 display: inline-block;
 margin-left: 5px;
 background-color: #fff;
 border-radius: 5px;
 border: #efe9e9 2px solid;
 padding: 5px 5px 5px 5px;
}
.container-chart1{
 width: auto;
 margin-top: 10px;
 height: auto;
 background-color: #fff;
 border-radius: 5px;
 border: #efe9e9 2px solid;
}
.container-chart2{
 width: auto;
 height: auto;
 background-color: #fff;
 border-radius: 5px;
 border: #efe9e9 2px solid;
 padding: 5px 5px 5px 5px;
}


/*-----Media Quries---*/


@media only screen and (max-width: 767px){
  .dashboard-stat-title{
    font-size: 10px;
  }
  .dashboard-stat-number{
    font-size: 24px;
  }
  .dashboard-stat-text{
    font-size: 10px;
  }
  .ratings-header-text{
    font-size: 8px !important;
  }
  .ratings-header-date{
    font-size: 8px !important;
  }
  .ratings-table th {
    font-size: 8px;
  }
  #container-table{
   width: auto;
   margin-top: 10px;
   height: auto;
   display: inline-block;
   margin-left: -5px;
   background-color: #fff;
   border-radius: 5px;
   border: #efe9e9 2px solid;
 }
 #piechart{
  background-color: #fafafa;
  height: auto;
  width: 100%;
  padding: 5px;
}
.pie-chart{
  width: 60px !important;
  height: 30px !important;
  margin-left: 90px !important;
}
.piechartContainer{
 width: 43%;
 height: auto;
 display: inline-block;
 background-color: #fff;
 border-radius: 5px;
 border: #efe9e9 2px solid;
 padding: 30px 5px 30px 5px;

}
.piechartheading{
  position: relative;
  float: left;
  margin-top: 10px;
  margin-left: 0px;
  font-size: 9px;
  font-weight: 750;
}

}

@media only screen and (min-width: 768px) and (max-width: 1024px){
  .dashboard-stat-title{
    font-size: 12px;
  }
  .dashboard-stat-number{
    font-size: 26px;
  }
  .dashboard-stat-text{
    font-size: 12px;
  }
  .pie-chart{
    width: 150px !important;
    height: 75px !important;
    margin-left: 165px !important;
  }
  .piechartContainer{
   width: 38%;
   height: auto;
   display: inline-block;
   background-color: #fff;
   border-radius: 5px;
   border: #efe9e9 2px solid;

 }
 .piechartheading{
  position: relative;
  float: left;
  margin-top: 30px;
  margin-left: 0px;
  font-size: 13px;
  font-weight: 750;
}

}


@media only screen and (min-width: 1024px) and (max-width:1366px){
 .dashboard-stat-title{
  font-size: 14px;
}
.dashboard-stat-number{
  font-size: 30px;
}
.dashboard-stat-text{
  font-size: 14px;
}
.pie-chart{
  width: 150px;
  height: 75px;
  margin-left: 130px;
}
.piechartheading{
  position: relative;
  float: left;
  margin-top: 40px;
  margin-left: 0px;
  width: 50%;
  font-size: 15px;
  font-weight: 750;
}
.piechartContainer{
  width: 41.9% !important;
}
}
</style>
