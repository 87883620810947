<template>
  <div>
    <base-list-table :table-data="listData" :extra-cols="listCols" :hasDetailsCol="false">
      <template slot="topControls">
                <el-row>
                    <el-col :span="12">
                        <el-button type="primary" icon="fa fa-plus" @click="handleAdd">
                            {{$t('add_item')}}
                        </el-button>
                    </el-col>
                </el-row>
            </template>
      <template slot="actionsColumn" slot-scope="scope">
        <el-button
          class="circle"
          type="primary"
          :title="$t('tfv_add_question')"
          icon="el-icon-circle-plus"
          @click="$router.push({props:{default: true}, name:'survey.details',params:{id:scope.row.id, name:scope.row.title}})"
        >
          <!-- <span class="fa fa-pencil"></span> -->
        </el-button>
        <el-button
          class="circle"
          type="primary"
          :title="$t('responses')"
          icon="fa fa-stack-exchange"
          @click="$router.push({name:'survey.response',params:{id:scope.row.id, name:scope.row.title}})"
        >
          <!-- <span class="fa fa-pencil"></span> -->
        </el-button>
         <el-button
          class="circle"
          type="primary"
          :title="$t('accumulate_respnse')"
          icon="fa fa-line-chart"
          @click="$router.push({name:'survey.accumulate.response.page',params:{id:scope.row.id}})"
        >
          <!-- <span class="fa fa-pencil"></span> -->
        </el-button>
        <el-button
          class="circle"
          type="danger"
          :title="$t('delete')"
          icon="fa fa-times"
          @click="handleDelete(scope.row.id)"
        >
          <!-- <span class="fa fa-pencil"></span> -->
        </el-button>
      </template>
    </base-list-table>
    <add-survey-modal
    :visible.sync="showAddModal"
    :addSurvey="handleAddSurvey"
      ></add-survey-modal>
  </div>
</template>


<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { populate, forcePopulate } from "~/js/helpers/Vuex";
import {Notification} from "element-ui";
import { isEmpty, sd } from "../../../js/helpers/Common";
export default {
  data() {
    const _this=this;
    return {
      listData: [],
      listCols: [
        {
          label: _this.$t('survey_id'),
          prop: "id",
          width: ""
        },
        {
          label: _this.$t('survey_name'),
          prop: "title",
          width: ""
        },
        {
          label: _this.$t('start_date'),
          prop: "start_date",
          width: ""
        },
        {
          label: _this.$t('end_date'),
          prop: "end_date",
          width: ""
        }
      ],
      showAddModal : false
    };
  },
  mounted() {
    this.getAll();
  },
  methods: {
     ...mapActions({
      getAllSurvey: "surveyManager/getSurvey",
      deleteSurvey: "surveyManager/deleteSurvey",
      addSurvey: "surveyManager/addSurvey"
    }) ,

    /**
     * Delete survey from DB
     */
    handleDelete(id) {
      this.$confirm(this.$t('are_you_sure_to_delete'), this.$t('warning'), {
        confirmButtonText: 'YES',
        cancelButtonText: 'NO',
        type: 'warning'
      }).then(() => {
               this.deleteSurvey(id).then(r => {
                    this.getAll();
                    Notification.success({
                    title: 'Success',
                    message: 'Survey delete successfully',
                  });
                });
      }).catch(() => {
        console.log('canceled')
      });
    },

    /**
     * Get All Survey
     */
    getAll() {
      this.getAllSurvey().then(res => {
        this.listData = res.data;
      });
    },
    /**
     * Show Add Modal
     */
    handleAdd() {
      this.showAddModal = true;
    },
    /**
     * Add Survey Method
     */
    handleAddSurvey(data) {
      let payload = {
        user_id: JSON.parse(localStorage.getItem("app_user_data")).id,
        start_date: data.date[0],
        end_date: data.date[1],
        title: data.title
      }
        this.addSurvey(payload).then(() => {
          this.showAddModal = false;
          this.getAll();
        })
    },
  }
};
</script>

<style>
.cell-title {
  display: none;
}
</style>
