import APICaller from "~/js/helpers/APICaller";

import { gep } from "~/js/helpers/Vuex";
import { Notification } from "element-ui";
import i18n from "../../../i18n";

let apiPrefix = 'v3';
export default {
    /**
     * Get KPI Settings from Setting Table
     * @param {*} context 
     * @param {*} payload 
     */
    getKpiSettings(context, payload) {
        let params = {
            "filter[name]": 'kpi'
        }
        return APICaller({
            method: 'get',
            params,
            endpoint: gep('settings', apiPrefix)
        }).then(resp => {
            if (resp.data.data.settings.length > 0) {
                context.commit('setCompose', resp.data.data)
            }
            else {
                context.commit('resetCompose')
            }
            return resp;

        }).catch(err => {
            console.log(err)
        })
    },
    /**
     * Handle POST KPI Settings
     * @param {*} context 
     * @param {*} payload 
     */
    postKpiInSettingsTable(context, payload) {
        const apiOpts = {
            method: "POST",
            endpoint: gep('settings', apiPrefix),
            data: payload,
            isDataRaw: true
        };

        return APICaller(apiOpts)
            .then(() => {
                Notification.success({
                    title: i18n.t("sucesss"),
                    message: "KPI Setting Created"
                });
            })
            .catch(e => {
                Notification.error({
                    title: i18n.t("error"),
                    message: e.response.data.data
                });
            })
    },

    updateKPISettings(context, payload) {
        const apiOpts = {
            method: "PUT",
            endpoint: gep(`settings/`+payload.id, apiPrefix),
            data: payload,
            isDataRaw: true
        };

        return APICaller(apiOpts)
            .then(() => {
                Notification.success({
                    title: i18n.t("sucesss"),
                    message: "KPI Setting Updated"
                });
            })
            .catch(e => {
                Notification.error({
                    title: i18n.t("error"),
                    message: e.response.data.data
                });
            })
    }
}