<template>
  <div class="app-display simple-count-display">

    <div class="simple-count-display__wrapper">

      <span class="simple-count-display__title">
      {{ $t(title) }}
    </span>

      <span class="simple-count-display__value" v-if="clickable">

      <router-link :to="url"> {{ value }}</router-link>
    </span>
      <span class="simple-count-display__value" v-else-if="modal">

      <!-- <button type="text" @click="handleModal">{{value}}</button> -->
      <a :href="`javascript:;`" @click="handleModal">{{value}}</a>
    </span>
      <span class="simple-count-display__value" v-else>

      {{ value }}
    </span>

      <slot name="additionalValue">
        </slot>


    </div> <!-- /.simple-count-display__wrapper -->


  </div> <!-- /.app-display dashboard-simple-count-display -->
</template>

<script>
  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      value: {
        required: true,
      },

      title: {
        type: String,
        default: ''
      },

      clickable:{
        type:Boolean,
        default:false
      },
      modal:{
        type:Boolean,
        default:false
      },
      url:{
        type:String,
        default:'/'
      }
    }, // End of Component > props
  methods:{
    handleModal(){
      this.$emit('updateModal')
    }
  }
  } // End of export default
</script>
