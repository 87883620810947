<template>
  <div>

   <h4>{{$t('description')}}</h4>
    <el-form-item label=''>
      <el-input type='textarea' v-model="iValue.descriptions" />
    </el-form-item>

    <template v-if="!isEmpty(iValue.additional_text)">
      <h4>{{$t('text_translation_additional_text')}}</h4>
      <el-form-item label=''>
        <el-input type='textarea' v-model="iValue.additional_text" />
      </el-form-item>
    </template>



   <h4>{{$t('text_translation_is_stamped')}}</h4>
    <el-form-item>
      <el-radio-group v-model="iValue.is_stamped" tabindex="0">
        <el-radio :label="true">Yes</el-radio>
        <el-radio :label="false">No</el-radio>
      </el-radio-group>
    </el-form-item>

  <h4>{{$t('text_translation_is_confidential')}}</h4>
  <el-form-item>
    <el-radio-group v-model="iValue.is_confidential" tabindex="0">
      <el-radio :label="true">Yes</el-radio>
      <el-radio :label="false">No</el-radio>
    </el-radio-group>
  </el-form-item>
  <h4>{{$t('text_translation_deliver_with')}} {{iValue.deliver_with}}</h4>
  <el-form-item>
    <el-radio-group v-model="iValue.deliver_with" tabindex="0">
      <el-radio label="email">Email</el-radio><br><br>
      <el-radio label="dt_web">DT web</el-radio><br><br>
      <el-radio label="mail">Mail</el-radio>
    </el-radio-group>
  </el-form-item>

   <h4>Files</h4>
    <el-form-item label=''>
      <aws-file-upload v-model="iValue.files"  @progress="handleProgess" @deleteFile="handleDeleteFile"/>
    </el-form-item>
  </div>
</template>

<script>

import AwsFileUpload from '~/components/displays/AwsFileUpload.vue'
import { mapActions } from 'vuex'
import { isEmpty } from '../../../../js/helpers/Common'
export default {
  props:{
    value:{
      type:Object,
      default:{}
    }
  },
  components:{
    AwsFileUpload
  },
  computed:{
    iValue:{
      get(){ return this.value},
      set(v){ this.$emit('input',v) }
    }
  },
  methods:{

    ...mapActions('booking',['deleteTextTranslationFile']),

    handleProgess(e){
    },
    async handleDeleteFile(e){
      if(!isEmpty(e.id)){
        try{
        let res = await this.deleteTextTranslationFile({
          id: e.pivot.text_translation_id,
          file_id: e.id
        });
        let filtered = this.iValue.files.filter((file) =>{
            return file.id !== e.id
          })
        this.iValue.files = filtered
        }catch(e){
          console.log(e)
        }
      }
    },

    isEmpty(v){
      return isEmpty(v)
    }


  }
}
</script>
