<template>
  <div class="app-form sector-batches-settings-form">
    <el-form :model="form" :id="formElId" :ref="formElId">
      <h4>{{$t('physical_bookings')}}</h4>

      <sector-batch-settings-booking-fields :form-el-id="formElId" :form="form.settings.physical"/>

      <h4>{{$t('translator_levels_priority')}}</h4>

      <sector-batch-settings-trans-lvls-prio-fields
        :form-el-id="formElId"
        :form="form.settings.physical.translator_levels"
      />

      <sector-batch-settings-max-travel-fields :form="form.settings.physical"/>

      <el-row>
        <el-col :md="12">
          <h4>{{$t('phone_bookings')}}</h4>
        </el-col>
        <el-col :md="12">
          <el-button type="primary" @click="handleImport">{{$t('import_from_physical')}}</el-button>
        </el-col>
      </el-row>

      <sector-batch-settings-booking-fields :phone="true" :form-el-id="formElId" :form="form.settings.phone"/>

      <h4>{{$t('translator_levels_priority')}}</h4>

      <sector-batch-settings-trans-lvls-prio-fields
        :form-el-id="formElId"
        :form="form.settings.phone.translator_levels"
      />

      <!-- Save -->
      <el-form-item :id="genContainerId('save',formElId)">
        <el-button type="primary" @click="save" :id="genFieldId('save',formElId)">{{$t('save')}}</el-button>
      </el-form-item>
    </el-form>
  </div>
  <!-- /.app-form sector-batches-settings-form -->
</template>

<script>
  import {genFieldId, genContainerId} from '../../../../js/helpers/Html';
  import SectorBatchSettingsTransLvlsPrioFields from './SectorBatchSettingsTransLvlsPrioFields';
  import {mapGetters, mapActions, mapState} from 'vuex';

  let formFresh = {
    physicalForm: {},
    phoneForm: {}
  };

  export default {
    components: {SectorBatchSettingsTransLvlsPrioFields},
    /*
          |--------------------------------------------------------------------------
          | Component > props
          |--------------------------------------------------------------------------
          */
    props: {
      changeBatchSetting: {
        type: Boolean
      },
      batchTemplateSetting: {
        type: Object
      },

      formElId: {
        type: String,
        default: 'sector_batches_settings_form'
      }
    }, // End of Component > props

    /*
          |--------------------------------------------------------------------------
          | Component > data
          |--------------------------------------------------------------------------
          */
    data() {
      return {
        formdata: {}
      };
    }, // End of Component > data
    /*
          |--------------------------------------------------------------------------
          | Component > computed
          |--------------------------------------------------------------------------
          */
    computed: {
      iValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        }
      },
      ...mapState('batchesSettings', {
        form: 'compose'
      }),

      modulePage() {
        if (_.includes(this.$route.path, 'municipality')) {
          return 'municipality';
        } else if (_.includes(this.$route.path, 'company')) {
          return 'company'
        }
      },

    }, // End of Component > computed

    /*
          |--------------------------------------------------------------------------
          | Component > watch
          |--------------------------------------------------------------------------
          */
    watch: {
      batchTemplateSetting: function (newVal, oldVal) {
        // watch it
        this.form.settings = newVal;
      }
    }, // End of Component > watch

    /*
          |--------------------------------------------------------------------------
          | Component > methods
          |--------------------------------------------------------------------------
          */
    methods: {
      // Map Helpers
      genFieldId: genFieldId,
      genContainerId: genContainerId,

      ...mapActions('translatorLevel', {
        loadTranslatorLevels: 'browseAsOptions'
      }),

      ...mapActions('batchesSettings', {
        getCompanyBatches: 'getCompanyBatchSetting',
        getMunicipalityBatches: 'getMunicipalityBatchSetting',
        updateMunicipalityBatch: 'updateMunicipalityBatchSetting',
        updateCompanyBatch: 'updateCompanyBatchSetting',
      }),

      save() {
        let payload = {
          settings: this.form,
          id: this.$route.params.id
        };
        if (this.modulePage === 'municipality') {
          this.updateMunicipalityBatch(payload).then(r => {
            r.status === 201
              ? this.$notify({
                title: 'Success',
                message: 'Batch setting updated',
                type: 'success'
              })
              : null;
          });
        } else if (this.modulePage === 'company') {
          this.updateCompanyBatch(payload).then(r => {
            r.status === 201
              ? this.$notify({
                title: 'Success',
                message: 'Batch setting updated',
                type: 'success'
              })
              : null;
          });
        }


      },
      handleImport() {
        let physicalForm = {...this.form.settings.physical};
        ['max_travel_distance', 'max_travel_time'].forEach(element => {
          delete physicalForm[element];
        });
        this.form.settings.phone = {...physicalForm};
      }
    }, // End of Component > methods

    /*
          |--------------------------------------------------------------------------
          | Component > mounted
          |--------------------------------------------------------------------------
          */
    mounted() {
      if (this.modulePage === 'municipality') {
        this.getMunicipalityBatches(this.$route.params.id);
      } else if (this.modulePage === 'company') {
        this.getCompanyBatches(this.$route.params.id);
      }


    } // End of Component > mounted
  }; // End of export default
</script>
