/*
|--------------------------------------------------------------------------
| Store > user > admin> getters
|--------------------------------------------------------------------------
|
| This file contains the getters property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';
import {isEmpty} from '~/js/helpers/Common'

export default {
  /**
   * Get the deletedUsers list state
   *
   *  * @param {object} state
   *  : current state of the vuex Object
   *
   * @return {array}
   */
  getTrashedUsers: (state) => state.trashedUsers,
  paginationTrashedUsers: (state) => state.paginationTrashedUsers,
}
