<template>
  <div class="app-row">
    <div class="app-col full-width">
      <el-row>
        <el-col :md="12">
          <h3>{{$t('admin_project_mode_user')}}</h3>
          <h4>
            {{$t('allowed_booking_types')}}
          </h4>

          <el-checkbox-group
            v-model="user.booking_types"
            @change="handleUpdateBookingType"
            :aria-label="$t('allowed_booking_types')"
          >
            <el-checkbox :label="$t('phone')">Telefontolkning</el-checkbox>
            <br/>
            <el-checkbox :label="$t('physical')">Plastolkning</el-checkbox>
            <br/>
            <el-checkbox :label="$t('video')">{{$t('video')}}</el-checkbox>
            <el-checkbox :label="$t('video_skype')" value="video_skype"/>
            <el-checkbox :label="$t('video_physical')" value="video_physical"/>
            <el-checkbox :label="$t('convey')" value="convey"/>
            <el-option :label="$t('text_translation')" value="text_translation"/>

          </el-checkbox-group>
        </el-col>
        <el-col :md="12">
          <h3>KPIS</h3>
          <el-form>
            <el-form-item :label="$t('manually_effect_value')" class="label-short">
              <el-switch
                v-model="form.additional_points.use_points"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="1"
                inactive-value="0"
              ></el-switch>
            </el-form-item>
            <el-col :md="12">
              <el-form-item
                v-if="form.additional_points.use_points == 1"
                label="Rule Add/Remove"
                class="label-short"
              >
                <el-row>
                  <el-col :md="12" v-if="form.additional_points.rule == 'add'">
                    <el-input v-model="form.additional_points.points" placeholder="points"></el-input>
                  </el-col>
                  <el-col :md="4">
                    <el-button
                      v-model="form.additional_points.rule"
                      @click="form.additional_points.rule == 'remove' ? form.additional_points.rule= 'add' : form.additional_points.rule='remove'"
                      type="success"
                      size="mini"
                    >{{form.additional_points.rule == 'add' ? 'Remove' : 'Add'}}
                    </el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-button
                type="primary"
                style="margin-left:75px"
                @click="handleClickSubmitEdit()"
                size="mini"
              >{{$t('save')}}
              </el-button>
            </el-col>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapState} from 'vuex';
  import {populate} from '~/js/helpers/Vuex';

  export default {
    name: 'userProjectTypePanel',

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      user: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        type: ['phone', 'video'],
        check: false,
        input: ''
      };
    },
    computed: {
      ...mapGetters('customerType', {
        customerTypeOpts: 'listAsOptions'
      }),

      /**
       * Vuex State for translator compose
       */
      ...mapState('translator', {
        form: 'compose'
      }),

      /**
       * Returns the user's ID from route param "id"
       * @return {int}
       */
      userId () {
        return !window._.isNil(this.$route.params) &&
        !window._.isNil(this.$route.params.id)
          ? parseInt(this.$route.params.id)
          : 0;
      }
    },
    methods: {
      /**
       * Vuex actions for translator
       */
      ...mapActions('translator', {
        readEntry: 'read',
        editEntryTranslator: 'edit'
      }),
      ...mapActions('user', {
        editEntry: 'edit',
        updateBookingType: 'updateBookingType'
      }),
      handleUpdateBookingType (e) {
        let payload = {
          id: this.$route.params.id,
          data: {booking_types: e}
        };
        this.updateBookingType(payload);
      },
      handleUpdateCustomerType (e) {
        let payload = {
          id: this.$route.params.id,
          data: {
            disallowed_customer_types: e
          }
        };
        this.updateBookingType(payload);
      },

      /**
       * Handle click submit button for translator KPI
       */
      handleClickSubmitEdit () {
        this.editEntryTranslator({id: this.userId});
      },
      /**
       * Read Entry for translator
       */
      prepareKpiFeature () {
        this.readEntry({id: this.userId}).catch(() => {
          this.$notify.error({
            title: 'Error',
            message: 'There is no translator by that ID.'
          });
        });
      }
    },
    mounted () {
      this.prepareKpiFeature();
    }
  };
</script>

<style lang="scss" scoped>
  .el-checkbox {
    width: 45%;
  }

  .el-checkbox + .el-checkbox {
    margin-left: 0px !important;
  }
</style>
