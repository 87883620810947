/*
|--------------------------------------------------------------------------
| store > user > translator > actions
|--------------------------------------------------------------------------
|
| This file contains the actions property of current Vuex scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import APICaller from '~/js/helpers/APICaller';
import {Notification} from "element-ui";
import API from '~/js/constants/api';
import {gep} from "~/js/helpers/Vuex";
let apiPrefix = 'v3';

export default {
  readCourtOrder(context, payload){

    return APICaller({
      endpoint:API.COURT_ORDER,
      method:'get',
      params:payload
    }).then(r => r)
    .catch(e => {
      return e;
    });
  },
  generateOrderFileLink(context,payload){
    return APICaller({
      endpoint:API.COURT_ORDER+'/file-link',
      method:'get',
      params:payload
    }).then(r => {
      window.open(r.data.data.order_link,'_blank')
    })
    .catch(e => {
      Notification.error({
        title: 'Error!',
        message: e.response.data,
      });
    });
  },
  postCourtOrder(context, payload){

    return APICaller({
      endpoint:API.COURT_ORDER,
      method:'post',
      data:payload
    }).then(r => r)
    .catch(e => {
      Notification.error({
        title: 'Error!',
        message: e.response.data,
      });
    });
  },
  courtIssue(context,payload){
    return APICaller({
      endpoint:`${API.BOOKING_ISSUE}`,
      method:'get',
      params:payload
    })
  },
  getEmployeesList: ({commit}, payload) => {

    return APICaller({
      method: 'get',
      endpoint: `${API.TRANSLATORS}`,
      params: payload,

    }).then((response) => {
        commit('setEmployees', response.data)
        return response
      }
    ).catch( (e) => {

      console.log(e)
      return e
    })
  },
  fetchDelayedBookings(context,payload){
    return APICaller({
      method:'get',
      endpoint: gep(`translators/${payload}/delayed`,apiPrefix)
    }).then(resp => {
      context.commit('setDelayedBookings',resp.data.data)
      return resp.data.data
    }).catch(err => {
      return err
    })
  }
}
