

/*
|--------------------------------------------------------------------------
| Store > Admin-Ui-Settings
|--------------------------------------------------------------------------
|
| A Class file that manages all the properties and abilities in relation
| to Feedback.
|
*/


let adminUiSettings = {
    name: 'adminUiSettings',
    value:
    {
        showPopUpWarning: false
    }

}


export default {

    composeFresh: _.cloneDeep(adminUiSettings),
    compose: _.cloneDeep(adminUiSettings),

}