<template>
        <div class="app-page user-compose-page">

        <page-header :title="$t('admin_user')" :back-enabled="true"/>

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">

                    <div class="app-col full-width">
                        <div class="top-controls">
                            <el-row>
                                <el-col :span="24" class="text-right">
                                    <el-button type="primary" @click="handleRedirectUserAccessLog(admin)">
                                        {{$t('user_access_log')}}
                                    </el-button>
                                    <el-button type="primary" @click="handleRedirectAudits">
                                        {{$t('audit_logs')}}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="content-body">

                            <admin-user-compose-form/>

                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page user-compose-page -->
</template>

<script>
    export default {
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                window,
                admin: ''
            };
        }, // End of Component > data

        methods: {

            /**
             * Handle when redirecting to the admin user's audit logs
             *
             * @return {void}
             */
            handleRedirectAudits() {
                this.$router.push({name:'audits', params:{id:this.$route.params.id,auditable:'users'}})
            },

            handleRedirectUserAccessLog(name) {
                this.$router.push({path:'/logs/user-access-logs', query:{admin:name}})
            }
        },

        created() {
            this.$store.dispatch('adminUser/read', {id: this.$route.params.id}).then(r => {
                this.admin = r.data.data.user.name;
            });
        }
    } // End of export default
</script>
