
'use strict';

import {extendObj} from '~/js/helpers/Common'
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import bread from '../bread'
export default {
  namespaced: true,
  state: extendObj(bread.state, state),
  getters: extendObj(bread.getters, getters),
  mutations: extendObj(bread.mutations, mutations),
  actions,
}