<template>
  <div class="app-fields financial-transaction-fee-fields">

    <!-- --------------------------------------------- -->
    <!-- Transaction Fee Section -->
    <!-- --------------------------------------------- -->

    <!-- Charge transaction fee? -->
    <el-form-item :id="genContainerId('pay_transaction_fee',formElId)"
                  :label="$t('charge_transaction_fee')" class="label-fluid">

      <el-switch :id="genFieldId('pay_transaction_fee',formElId)"
                 v-model="iValue.pay_transaction_fee"/>
    </el-form-item>

    <template v-if="iValue.pay_transaction_fee">

      <!-- Transaction Fees -->
      <el-form-item v-for="bmo in bookingMethodOpts"
                    :key="`transaction_fee_${bmo}`"
                    :id="genContainerId(`transaction_fee_${bmo}`,formElId)"
                    class="label-fluid">
        <template slot="label">
          <span>{{$t('transaction_fee_for')}} </span>
          <span class="app-badge badge-success">{{ bmo }}</span>
        </template>

        <el-input :id="genFieldId(`transaction_fee_${bmo}`,formElId)"
                  v-model="iValue.transaction_fees[bmo].fee"/>
      </el-form-item>

    </template>

    <!-- --------------------------------------------- -->
    <!-- Office Hours Section -->
    <!-- --------------------------------------------- -->

    <!-- Charge for outside working hours? -->
    <el-form-item :id="genContainerId('pay_outside_hours',formElId)"
                  :label="$t('charge_outside_working_hours')" class="label-fluid">

      <el-switch :id="genFieldId('pay_outside_hours',formElId)"
                 v-model="iValue.office_hours_fee.pay_outside_hours"/>
    </el-form-item>

    <template v-if="iValue.office_hours_fee.pay_outside_hours">

      <!-- Office Hours > Fee -->
      <el-form-item :id="genContainerId('outside_hours_fee',formElId)"
                    :label="$t('fee')" class="label-fluid">

        <el-input :id="genFieldId('outside_hours_fee',formElId)"
                  v-model="iValue.office_hours_fee.fee"/>
      </el-form-item>

      <!-- Office Hours > Start Time -->
      <el-form-item :id="genContainerId('office_hours_fee_start_time',formElId)"
                    :label="$t('start_time')" class="label-fluid">

        <el-time-picker :id="genFieldId('office_hours_fee_start_time',formElId)"
                        format="HH:mm"
                        v-model="iOfficeHoursStartTime"/>
      </el-form-item>

      <!-- Office Hours > End Time -->
      <el-form-item :id="genContainerId('office_hours_fee_end_time',formElId)"
                    :label="$t('end_time')" class="label-fluid">

        <el-time-picker :id="genFieldId('office_hours_fee_end_time',formElId)"
                        format="HH:mm"
                        v-model="iOfficeHoursEndTime"/>
      </el-form-item>

      <!-- Office Hours > Addition on transcation cost (%) -->
      <el-form-item :id="genContainerId('office_hours_fee_transaction_fee_percentage',formElId)"
                    :label="$t('addition_on_transcation_cost')"
                    class="label-fluid">

        <el-input :id="genFieldId('office_hours_fee_transaction_fee_percentage',formElId)"
                  v-model="iValue.office_hours_fee.transaction_fee_percentage" />
      </el-form-item>

    </template>

    <!-- --------------------------------------------- -->
    <!-- Booking Type Fee Section -->
    <!-- --------------------------------------------- -->

    <!-- Charge for booking type? -->
    <el-form-item :id="genContainerId('pay_booking_type_fee',formElId)"
                  :label="$t('charge_booking_type')" class="label-fluid">

      <el-switch :id="genFieldId('pay_booking_type_fee',formElId)"
                 v-model="iValue.pay_booking_type_fee"/>
    </el-form-item>

    <template v-if="iValue.pay_booking_type_fee">

      <!-- Booking Type Fees -->
      <el-form-item v-for="bto in bookingTypeOpts"
                    :key="`booking_type_fee_${bto}`"
                    :id="genContainerId(`booking_type_fee_${bto}`,formElId)"
                    class="label-fluid">
        <template slot="label">
          <span>{{$t('booking_type_fee_for')}} </span>
          <span class="app-badge badge-success">{{ bto }}</span>
        </template>

        <el-input :id="genFieldId(`booking_type_fee_${bto}`,formElId)"
                  v-model="iValue.booking_type_fees[bto].fee"/>
      </el-form-item>

    </template>

    <!-- --------------------------------------------- -->
    <!-- Translator Level Fee Section -->
    <!-- --------------------------------------------- -->

    <!-- Charge for Translator Level? -->
    <el-form-item :id="genContainerId('pay_translator_level_fee',formElId)"
                  :label="$t('charge_translator_level')" class="label-fluid">

      <el-switch :id="genFieldId('pay_translator_level_fee',formElId)"
                 v-model="iValue.pay_translator_level_fee"/>
    </el-form-item>

    <template v-for="tlvl in translatorLevelOpts" v-if="iValue.pay_translator_level_fee">

      <h3>{{ $t('tlvl_' + tlvl ) }}</h3>

      <template v-for="bto in bookingTypeOpts">

        <!-- Translator Level Fee -->
        <el-form-item :key="`translator_level_fee_${tlvl}_${bto}`"
                      :id="genContainerId(`translator_level_fee_${tlvl}_${bto}`,formElId)"
                      class="label-fluid">
          <template slot="label">
            <span>{{$t('translator_level_fee')}} </span>
            <span class="app-badge badge-success">{{ bto }}</span>
          </template>

          <el-input :id="genFieldId(`translator_level_fee_${tlvl}_${bto}`,formElId)"
                    v-model="iValue.translator_level_fees[tlvl][bto].fee"/>
        </el-form-item>

      </template>
    </template>

  </div> <!-- /.app-fields financial-transaction-fee-fields -->
</template>

<script>
  import constants from '~/js/store/financial/settings/shared/props/constants';
  import {timePickerFormatter} from '~/js/helpers/Vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      formElId: {
        type: String,
        default: 'financial_transaction_fee_fields'
      },

      value: {
        type: Object,
        default() { return {}; }
      }

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data() {
      return {};
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get() { return this.value; },
        set(v) { this.$emit('input', v); },
      },

      /**
       * Interface for this.iValue.office_hours_fee.start_time
       */
      iOfficeHoursStartTime: {
        get() {
          return timePickerFormatter('get', this.iValue.office_hours_fee.start_time);
        },
        set(v) {
          this.iValue.office_hours_fee.start_time = timePickerFormatter('set', v);
        }
      },

      /**
       * Interface for this.iValue.office_hours_fee.end_time
       */
      iOfficeHoursEndTime: {
        get() {
          return timePickerFormatter('get', this.iValue.office_hours_fee.end_time);
        },
        set(v) {
          this.iValue.office_hours_fee.end_time = timePickerFormatter('set', v);
        }
      },

      /**
       * Returns list of options for booking methods.
       * @return {array}
       */
      bookingMethodOpts: () => constants.BOOKING_METHODS,

      /**
       * Returns list of options for booking types.
       * @return {array}
       */
      bookingTypeOpts: () => constants.BOOKING_TYPES,

      /**
       * Returns list of options for translator levels.
       * @return {array}
       */
      translatorLevelOpts: () => constants.TRANSLATOR_LVLS,

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {



      capitalize: _.capitalize,

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted() {
    }, // End of Component > mounted

  } // End of export default
</script>
