<template>
  <div class="app-form app-fields sector-settings-fields">
    <!-- Organization Number -->
    <el-form-item :id="genContainerId('name',formElId)" :label="$t('name')" class="label-short">
      <el-input :id="genFieldId('name',formElId)" v-model="iValue.name" />
    </el-form-item>

    <!-- Additional Info -->
    <el-form-item :id="genContainerId('number',formElId)" :label="$t('number')" class="label-short">
      <el-input :id="genFieldId('number',formElId)" v-model="iValue.number" />
    </el-form-item>

    <el-form-item :id="genContainerId('sent_via',formElId)" :label="$t('sent_via')" class="label-short">
      <el-select v-model="iValue.sent_via" :placeholder="$t('please_select_how_send')">
        <el-option
          v-for="item in ReminderSentTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item
      v-if="iValue.sent_via ==  1"
      :id="genContainerId('email',formElId)"
      :label="$t('please_enter_email')"
      class="label-short"
    >
      <el-input :id="genFieldId('email',formElId)" v-model="iValue.value.email" />
    </el-form-item>

    <el-form-item
      v-if="iValue.sent_via ==  2"
      :id="genContainerId('address',formElId)"
      :label="$t('please_enter_address')"
      class="label-short"
    >
      <booking-maps-address v-model="iValue.value.address" :id="id" ></booking-maps-address>
            </el-form-item>
     <el-form-item
      v-if="iValue.sent_via ==  2"
      :id="genContainerId('email',formElId)"
      :label="$t('sent_to')"
      class="label-short"
    >
      <el-input :id="genFieldId('sent_to',formElId)" v-model="iValue.value.sent_to" />
    </el-form-item>
    <el-form-item
      v-if="iValue.sent_via ==  3"
      :id="genContainerId('comment',formElId)"
      :label="$t('please_enter_comment')"
      class="label-short"
    >
      <el-input type="textarea" :rows="2" :placeholder="$t('please_input')" v-model="iValue.value.comment"></el-input>
    </el-form-item>

    <!-- GLN -->
  </div>
  <!-- /.app-form app-fields sector-settings-fields -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
  props: {
    formElId: {
      type: String,
      default: ""
    },

    value: {
      type: Object,
      default() {
        return {
          name: "",
          number: "",
          sent_via: 1,
          value: {
            email: "",
            address: "",
            comment: ""
          }
        };
      }
    }
  }, // End of Component > props
  data() {
    return {
      id: 'municipality-page',
    }
  },

  /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
  computed: {
    ...mapGetters("invoiceReminderSettings", ["ReminderSentTypes"]),

    /**
     * Interface for the v-model of this component.
     */
    iValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  } // End of Component > computed
}; // End of export default
</script>
