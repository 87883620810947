/*
|--------------------------------------------------------------------------
| Store > Translator Level > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Translator Level Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {gep} from "../../helpers/Vuex";

let target = 'translator-levels';
let apiPrefix = window.app_api_prefix_3;
let asOptionsParams = {
    all:true,
};

let composeProps = {

    id: '',
    name: '',
    enabled: true,

};

export default {

    model_info: {
        name: 'Translator Level Store',
        module: 'translatorLevel',
        table: target,
    },

    api: {
        browse: { method: 'get', path: gep(target, apiPrefix), params: { per_page: 10, }},
        browseAsOptions: { method: 'get', path: gep(target, apiPrefix), params: asOptionsParams},
        read: { method: 'get', path: gep(target + '/{id}', apiPrefix), params: {}},
        edit: { method: 'put', path: gep(target + '/{id}', apiPrefix), params: {}, data: {}, },
        add: { method: 'post', path: gep(target, apiPrefix), params: {}, data: {}, },
        delete: { method: 'delete', path: gep(target + '/{id}', apiPrefix), params: {}, data: {}, },
    },

    composeFresh: _.cloneDeep(composeProps),
    compose: _.cloneDeep(composeProps),

    payloadData: {}, // End of payloadData state

    autoInjectPayload: true,
    autoReflectItem: true,
    autoUpdateList: true,

} // End of export default