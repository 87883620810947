import { render, staticRenderFns } from "./PriceListPage.vue?vue&type=template&id=7236e17b&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports