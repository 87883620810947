<template>
  <div class="app-fields" :class="getBem(blockCls)">
    <el-form-item>
      <el-checkbox-group
        :class="getBem(blockCls, 'checkbox-group')"
        v-model="iValue.languages"
        :aria-label="$t('language')"
      >
        <el-checkbox
          v-for="l in languageOpts"
          :key="l.id"
          :label="l.id"
        >
          {{ l.name }}
        </el-checkbox>
      </el-checkbox-group>
    </el-form-item>

  </div>
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      blockCls: {
        type: String,
        default: 'financial-language-settings-fields',
      },

      value: {
        type: Object,
        default () {
          return {};
        },
      }, // End of value

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {};
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters('language', {
        languageOpts: 'getLanguages',
      }),

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        },
      },

    }, // End of Component > computed

  } // End of export default
</script>

<style lang="scss">
  .financial-language-settings-fields {
    .el-checkbox {
      width: 48%;
      margin-right: 2%;
    }
  }
</style>
