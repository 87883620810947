<template>
  <div class="confirmation-fills-container">
    <el-dialog
      :title="$t('confirmation')"
      :visible.sync="iVisible"
      :append-to-body="true"
      :lock-scroll="true"
      :width="width"
    >
      <div class="calling" v-if="calling">
        <i class="el-icon-loading"></i><span> {{$t('scheduling_report')}}</span>
      </div>
      <div class="success" v-if="sentSuccessfull">
        {{$t('file_has_been_scheduled_be_sent')}}!
      </div>
      <el-form
        id="confirmation-fills-form"
        v-bind:model="aUser"
        v-bind:rules="rules"
        ref="sendForm"
      >
        <el-row :gutter="20">
          <el-col :span="16" class="col-modal">
            <span>{{$t('send_user_email_address')}}</span>
            <el-form-item prop="email" required class="field">
              <el-input v-model="aUser.email"></el-input>
            </el-form-item>
            <el-button v-if="cancel" @click="iVisible = false" type="default">{{$t('cancel')}}</el-button>
            <el-button v-if="submit" @click="handleSubmit" type="primary">{{$t('submit')}}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import APICaller from '~/js/helpers/APICaller';
  import { gep } from "~/js/helpers/Vuex";
  import { Notification } from "element-ui";
  export default {
    props: {
      user: {
        type: Object,
        required: true,
      },
      visible: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: "50%"
      },
      type: {
        type: String,
        default: ''
      }
    },
    data() {
      const _this=this;
      return {
        aUser: {
          email: '',
        },
        rules: {
          email: [
            { required: true, message: _this.$t('please_enter_email'), trigger: 'blur' },
            { type: 'email', message: _this.$t('please_enter_valid_email_address'), trigger: 'blur' },
          ],
        },
        sentSuccessfull: false,
        calling: false,
        cancel: true,
        submit: true,
      };
  },

  computed: {
    iVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit("update:visible", v);
      }
    },
  },
  methods: {
    handleSubmit() {
      this.$refs['sendForm'].validate((valid) => {
        if (valid) {
          let apiPrefix = 'v3';
          const apiOpts = {
            endpoint: gep('export_gdpr_data/' + this.user.id, apiPrefix),
            method: 'post',
            isDataRaw: true,
            data: {
              email: this.aUser.email
            }
          };
          this.calling = true;
          this.cancel = false;
          this.submit = false;
          APICaller(apiOpts)
            .then((res) => {
              this.calling = false;
              this.sentSuccessfull = true;
              this.cancel = true;
              this.submit = true;
            })
            .catch(err => {
              Notification.error({
                title: this.$t('error'),
                message: this.$t('something_went_wrong')
              });
              this.cancel = true;
              this.submit = true;
              this.calling = false;
              this.sentSuccessfull = false;
            });
        }
        else {
          return false;
        }
      });
    },
  }
};
</script>

<style lang="scss">

  .col-modal {
    width: 100%;
    text-align: center;
  }

  .field {
    margin-bottom: 27px;
  }

  .calling {
    width: 97%;
    background-color: #30A3B8;
    color: #FFFFFF;
    padding: 9px;
    margin-bottom: 27px;
    border-radius:9px;
  }

  .success {
    width: 97%;
    background-color: #3EA84C;
    color: #FFFFFF;
    padding: 9px;
    margin-bottom: 27px;
    border-radius:9px;
  }

  #confirmation-fills-form {
    .el-input__inner {
      border-radius: 5px;
      background-color: #ffffff;
      border: 1px solid #d8dce5;
    }
  }

</style>
