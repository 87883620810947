<template>
  <div class="app-display" :class="genBem(blockCls)">

    <!-- Header -->
    <div :class="genBem(blockCls, 'header')">
      <!-- Header Title -->
      <h3 v-if="title !== ''"
          :class="genBem(blockCls, 'header-title')">{{ title }}</h3>

      <!-- Header Buttons -->
      <div :class="genBem(blockCls, 'header-buttons')">
        <el-button @click="handleClickAdd">{{ $t('tfv_fn_add_item') }}</el-button>
      </div>

    </div> <!-- End of Header -->

    <!-- Preloader -->
    <div v-if="listLoading"
         :class="genBem(blockCls, 'preloader')"
         class="app-display-flex app-flex-center"
         style="height: 100%;">
      <div :class="genBem(blockCls, 'preloader-wrapper')">
        <p :class="genBem(blockCls, 'preloader-label')">
          Generating Credits...
        </p>
        <div :class="genBem(blockCls, 'preloader-icon')">
          <span v-loading="true"></span>
        </div>
      </div>
    </div> <!-- End of Preloader -->

    <!-- Actual Content -->
    <div v-else
         :class="genBem(blockCls, 'body')">

      <table v-if="list.length > 0"
             :class="genBem(blockCls, 'table')">
        <thead>
        <tr>
          <th class="col-header col-adjustment">
            <span class="cell">{{ $t('tfv_fn_adjustment') }}</span>
          </th>
          <th class="col-header col-description">
            <span class="cell">{{ $t('tfv_fn_description') }}</span>
          </th>
          <th class="col-header col-amount">
            <span class="cell">{{ $t('tfv_fn_amount') }}</span>
          </th>
          <th class="col-header col-per-unit">
            <span class="cell">{{ $t('tfv_fn_per_unit') }}</span>
          </th>
          <th class="col-header col-subtotal">
            <span class="cell">{{ $t('tfv_fn_subtotal') }}</span>
          </th>
          <th class="col-header col-action">
            <span class="cell">{{ $t('actions') }}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in list">
          <td class="col-value">
            <span class="cell">
              {{ setTableValue(item, 'name') }}
            </span>
          </td>
          <td class="col-value">
            <span class="cell">
              {{ setTableValue(item, 'description') }}
            </span>
          </td>
          <td class="col-value">
            <span class="cell">
              {{ setTableValue(item, 'quantity') }}
            </span>
          </td>
          <td class="col-value">
              <span class="cell">
                {{ setTableValue(item, 'price') }}
              </span>
          </td>
          <td class="col-value">
            <span class="cell">
              {{ setTableValue(item, 'subtotal') }}
            </span>
          </td>
          <td class="col-value">
            <el-button class="circle" type="success" :title="$t('edit')" @click="handleClickUpdatePrefill(index)">
              <span class="fa fa-pencil"></span>
            </el-button>
            <el-button class="circle" type="danger" :title="$t('remove')" @click="handleClickDeletePrefill(item)">
              <span class="fa fa-trash"></span>
            </el-button>
          </td>
        </tr>
        </tbody>
      </table>

      <div v-else
           style="width: 100%; text-align: center">
        <h4>{{ $t('no_data') }}</h4>
      </div>

    </div> <!-- End of Body -->

    <!-- Compose Modal -->
    <el-dialog id="invoice_crediting_booking_compose_modal"
               class="app-modal app-modal-primary"
               :title="$t('tfv_fn_input_your_corrections')"
               :visible.sync="isShowComposePrefillModal"
               :append-to-body="true"
               top="3%">
        <el-form>

          <!-- Form -->
          <financial-adjustment-crediting-form
            :form-el-id="`${target}_crediting_booking_form`"
            v-model="form"
            :target="target"
            :edit-mode="editMode"
            :item="indexItemInList"
            :visible.sync="isShowComposePrefillModal"
            @add="handleAddSubmit"
            @update="handleEditSubmit"
          />
        </el-form>
    </el-dialog>

  </div> <!-- template wrapper -->
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import {populate} from "~/js/helpers/Vuex";
import {notifSuccess, notifError} from "~/js/helpers/Notification";
import FinancialAdjustmentCreditingForm from '~/components/forms/financial/credit/FinancialAdjustmentCreditingForm';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import forOwn from 'lodash/forOwn';

export default {

  /*
  |--------------------------------------------------------------------------
  | Component > imported components
  |--------------------------------------------------------------------------
  */
  components: {
    FinancialAdjustmentCreditingForm
  },

  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props: {

    /**
     * Boolean for the visibility of the display.
     * Only used if this component will be inside a modal.
     */
    visible: {
      type: Boolean
    },

    /**
     * String to be used as the title in header.
     */
    title: {
      type: String,
      default: ''
    },

    /**
     * Varies between invoice | salary
     */
    target: {
      type: String,
      default: ''
    },

    /**
     * The current Booking's ID
     */
    bookingId: {
      default: 0
    },

    /**
     * Boolean to determine whether to show header buttons.
     */
    isShowHeaderButtons: {
      type: Boolean,
      default: true
    }

  },

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data () {
    return {
      blockCls: 'financial-preview-display',
      isShowComposePrefillModal: false,
      editMode: false,
      listCurrentIndex: 0
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  watch: {

    /**
     * Watcher method for this.visible property.
     *
     * @param  {Boolean} v
     * @return {void}
     */
    visible (v) {
      if (v) this.loadData();
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {

    ...mapGetters({
      // Invoice
      invoiceList: 'financialCreditInvoice/listData',
      invoiceListLoading: 'financialCreditInvoice/listLoading',

      // Salary
      salaryList: 'financialCreditSalary/listData',
      salaryListLoading: 'financialCreditSalary/listLoading'
    }),

    ...mapState('financialCreditInvoice', {
      invoiceForm: 'compose'
    }),

    ...mapState('financialCreditSalary', {
      salaryForm: 'compose'
    }),

    /**
     * Returns the target list Object.
     * @returns {*}
     */
    list () {
      const list = `${this.target}List`;
      return this[list];
    },

    form () {
      const form = `${this.target}Form`;
      return this[form];
    },

    /**
     * Returns boolean for the loading state of the list data.
     *
     * @returns {boolean}
     */
    listLoading () {
      const listLoading = `${this.target}ListLoading`;
      return this[listLoading];
    },

    firstItemInList () {
      if (this.list.length > 0) {
        // noinspection JSPotentiallyInvalidTargetOfIndexedPropertyAccess
        return this.list[0];
      } else {
        return {};
      }
    },

    indexItemInList () {
      if (this.list.length > 0) {
        // noinspection JSPotentiallyInvalidTargetOfIndexedPropertyAccess
        return this.list[this.listCurrentIndex];
      } else {
        return {};
      }
    }

  },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapMutations({
        // Invoice
        invoiceSetTargetBooking: 'financialCreditInvoice/setTargetBooking',

        // Salary
        salarySetTargetBooking: 'financialCreditSalary/setTargetBooking',
      }),

      ...mapActions({
        // Invoice
        invoiceLoadList: 'financialCreditInvoice/browse',
        invoiceDeletePrefill: 'financialCreditInvoice/delete',
        invoiceAdd: 'financialCreditInvoice/add',
        invoiceEdit: 'financialCreditInvoice/edit',

        // Salary
        salaryLoadList: 'financialCreditSalary/browse',
        salaryDeletePrefill: 'financialCreditSalary/delete',
        salaryAdd: 'financialCreditSalary/add',
        salaryEdit: 'financialCreditSalary/edit'
      }),

      /**
       * Method for setting the target booking id for Vuex use.
       *
       * @param id - Booking's ID
       * @return {void}
       */
      setTargetBooking (id) {
        const action = `${this.target}SetTargetBooking`;
        this[action](id);
      },

      /**
       * Method to load the data according to the selected target.
       *
       * @return {Promise}
       */
      loadData () {
        const action = `${this.target}LoadList`;
        return this[action]();
      },

      /**
       * Helper method for setting the value of table data.
       *
       * @param {object} item - Row object and the source of the value.
       * @param {object} key - The key (in the row) to reference the value.
       * @return {*}
       */
      setTableValue (item, key) {
        const v = item[key];
        const isExists = !window._.isNil(v) && v !== '' && v !== 0;
        const stringTypes = ['name', 'description'];
        const keyAmount = 'quantity';
        const keyPerunit = 'price'
        const keySubtotal = 'subtotal';

        if (
          (key === keyAmount && v % 1 !== 0)
          || (key === keyPerunit && v % 1 !== 0)
          || (key === keySubtotal && v % 1 !== 0)
        ) {
          // Conditions if the number is float. If it is, then...
          return isExists ? v.toFixed(3) : '0';
        }

        if (window._.includes(stringTypes, key)) {
          return isExists ? v : 'None';

        } else {
          return isExists ? v : '0';
        }
      },

      /**
       * Handler when the Add button was clicked.
       *
       * @return {void}
       */
      handleClickAdd () {
        this.isShowComposePrefillModal = true;
        this.editMode = false;
      },

      handleClickUpdatePrefill (index) {
        this.isShowComposePrefillModal = true;
        this.listCurrentIndex = index;
        this.editMode = true;
      },

      handleClickDeletePrefill (item) {
        const deletePrefill = this.target + 'DeletePrefill';

        this[deletePrefill]({
          booking: this.bookingId,
          id: item.id
        }).then(() => {
          notifSuccess({message: 'Deleted Successfully'});
        })
        .catch((e) => {
          notifError({message: e});
        });
      },

      populatePrefills () {
        populate(this, 'loadData', 'list', {
          ref: `Preview (${this.target})`,
          force: true
        });
      },

      /**
       * Method to handle the manner of submitting the ADD data to the API.
       *
       * @return {void}
       */
      handleAddSubmit () {
        const form = this[`${this.target}Form`];

        form.booking_id = this.$route.params.id;
        console.log(form);
        this[`${this.target}Add`](form).then(()=>{
          this.populatePrefills();
          notifSuccess({message: 'Added Successfully'});
        })
        .catch((e) => {
          this.notifyErrorHelper(e);
        });
      },

      /**
       * Method to handle the manner of submitting the EDIT data to the API.
       *
       * @return {void}
       */
      handleEditSubmit () {
        const form = this[`${this.target}Form`];

        form.booking_id = this.$route.params.id;
        this[`${this.target}Edit`](form)
          .then(() => {
            this.populatePrefills();
            notifSuccess({message: 'Updated Successfully'});
          })
          .catch((e) => {
            this.notifyErrorHelper(e);
          });
      },

      /**
       * @param {Object} e
       * @return {void}
       */
      notifyErrorHelper (e) {
        if (!(
          !isNil(e)
          && !isNil(e.response)
          && !isNil(e.response.data)
        )) {
          return;
        }

        const err = e.response.data;

        if (
          !isNil(err.status) && err.status === 'fail'
          && !isNil(err.message)
          && !isNil(err.data)
          && !isEmpty(err.data)
        ) {
          let messages = [];

          forOwn(err.data, (v) => {
            messages.push(...v);
          });

          notifError({
            title: err.message,
            message: messages.join('\n')
          });
        } else {
          notifError({message: e});
        }
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      this.setTargetBooking(this.bookingId);
      this.populatePrefills();
    },

  }
</script>
