<template>
    <div :class="getBem(blockCls)">

        <p :class="getBem(blockCls, 'label')">{{$t('input_cities_exlude_for_charging')}}</p>

        <div :class="getBem(blockCls, 'loop-wrapper')">
            <div :class="getBem(blockCls, 'loop')" v-for="i in indexes">

                <div :class="getBem(blockCls, 'fields')">

                    <!-- City -->
                    <el-form-item :class="getBem(blockCls, 'city-container')">
                        <el-input
                            v-model="valueCn[i]"
                            size="mini"
                            :class="getBem(blockCls, 'city-field')"
                        />
                    </el-form-item>
                </div>

                <div :class="getBem(blockCls, 'remove-action')">
                    <a href="#" @click.prevent="removeField(i)">
                        <i class="fa fa-trash-o"></i>
                    </a>
                </div>

            </div>
        </div>

        <div :class="getBem(blockCls, 'add-action')">
            <a href="#" @click.prevent="addField">
                {{$t('add_field')}} <i class="fa fa-plus"></i>
            </a>
        </div>
    </div>
</template>

<script>
  import Vue from 'vue';
  import isNil from 'lodash/isNil';
  import isEmpty from 'lodash/isEmpty';
  import keys from 'lodash/keys';
  import debounce from 'lodash/debounce';
  import forOwn from 'lodash/forOwn';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      value: {
        type: Array,
        required: true,
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-travel-time-specific-cities-field',
        valueCn: {},
        updateEmitted: false,
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > watch
    |--------------------------------------------------------------------------
    */
    watch: {

      /**
       * this.value watcher.
       */
      value () {
        if (!this.isEmittedValueUpdate) {
          this.applyValueToField();

        } else {
          if (this.isEmittedValueUpdate) this.isEmittedValueUpdate = false;
        }
      },

      /**
       * this.valueCn watcher.
       */
      valueCn: {
        deep: true,
        handler: debounce(function () {
          this.applyFieldToValue();
        }, 500),
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Returns collection of indexes taken from this.valueCn.
       *
       * @returns {array}
       */
      indexes () {
        return !isNil(this.valueCn)
        && !isEmpty(this.valueCn)
          ? keys(this.valueCn)
          : [];
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * Add a new repeating field.
       *
       * @returns {void}
       */
      addField () {
        const increasedIndex = (this.indexes.length - 1) + 1;
        Vue.set(this.valueCn, increasedIndex, '');
      },

      /**
       * Remove a new repeating field.
       *
       * @returns {void}
       */
      removeField (index) {
        Vue.delete(this.valueCn, index)
      },

      /**
       * Reflect this.value into this.valueCn.
       *
       * @returns {void}
       */
      applyValueToField () {
        this.valueCn = {};

        if (
          !isNil(this.value)
          && !isEmpty(this.value)
        ) {
          const len = this.value.length;

          for (let i = 0; i < len; i++) {
            const v = this.value[i];

            this.valueCn[i] = v;
          }
        }
      },

      /**
       * Reflect this.valueCn to this.value.
       *
       * @returns {void}
       */
      applyFieldToValue () {
        let result = [];

        forOwn(this.valueCn, (o) => { result.push(o); });
        this.$emit('input', result);

        if (!this.isEmittedValueUpdate) this.isEmittedValueUpdate = true;
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      this.applyValueToField();
    },
  }
</script>

<style lang="scss">
    .financial-travel-time-specific-cities-field {
        &__label {
            margin-top: 5px;
            font-weight: bold;
            font-size: 12px;
        }

        &__loop {
            display: table;
            width: 100%;
            margin-bottom: 15px;

            .el-form-item__label {
                text-align: left;
                margin-bottom: 0;
                padding-right: 0;
                width: 65px;
            }
        }

        &__loop:last-child {
            margin-bottom: 0;
        }

        &__fields, &__remove-action {
            display: table-cell;
            vertical-align: middle;
        }

        &__remove-action {
            a, a:focus, a:hover, a:visited, a:active {
                display: block;
                padding: 7px;
                color: #BDBDBD;
            }
        }

        &__add-action {
            a, a:focus, a:hover, a:visited, a:active {
                display: block;
                color: #65b4c6;
                font-size: 11px;
            }
        }
    }
</style>
