<template>
    <div class="app-display dashboard-circle-gauge-display">

        <div class="app-content-header">
            <h4>{{ title }}</h4>
        </div>

        <div class="app-content-body">
            <div class="gauge-container">
                <div ref="echarts_pie_chart" style="width: 100%; height: 100%;"></div>
            </div>
            <div class="floating-value-container">
                <div class="floating-value">{{ (value * 100) + '%' }}</div>
            </div>
        </div>

    </div> <!-- /.app-display dashboard-circle-gauge-display -->
</template>

<script>
    import echarts from 'echarts/lib/echarts';
    import 'echarts/lib/chart/pie';

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            /**
             * The title value that will be displayed.
             */
            title: {
                type: String,
                default: 'No Title',
            },

            /**
             * The percentage value that will be displayed.
             */
            value: {
                type: Number,
                default: 0,
            },

            /**
             * Hex code value (color) to be applied as the charts color.
             */
            chartColor: {
                type: String,
                default: '#333333',
            },

        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            let v = this.value;
            let color = this.chartColor;
            return {

                whole: 440,

                chartOpts: {
                    series: [
                        {
                            type: 'pie',
                            hoverAnimation: false,
                            radius: ['50%', '70%'],
                            label: {
                                normal: {
                                    show: false,
                                    position: 'center'
                                },
                                emphasis: {
                                    show: false,
                                    textStyle: {
                                        fontSize: '30',
                                        fontWeight: 'bold'
                                    }
                                }
                            },
                            data: [
                                {
                                    value: 100,
                                    itemStyle: { color: '#E4E4E4' }
                                },
                                {
                                    value: v * 100,
                                    itemStyle: { color }
                                },
                            ]
                        }
                    ],
                },

            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            iValue() {
                return (this.value * this.whole);
            }

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {

            // Initialize the chart to the target element.
            let el = this.$refs['echarts_pie_chart'];
            let chart = echarts.init(el);
            chart.setOption(_.cloneDeep(this.chartOpts));

        }, // End of Component > mounted

    } // End of export default
</script>
