/*
|--------------------------------------------------------------------------
| Store > User > Getters
|--------------------------------------------------------------------------
|
| This file contains the getters property of User Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {eut} from "../../helpers/Vuex";
import {keyAndLabels} from "../../helpers/Common";

export default {

    /**
     * Value to define if current is create customer mode.
     *
     * @return {boolean}
     */
    isCreateCustomer(state) {
        const type = window._.isNil(state.compose.user_type) ? 2 : state.compose.user_type;
        return eut(type) === 'customer';
    },

    /**
     * Value to determine the selected company id from the user form.
     *
     * @return {string}
     */
    selectedCompany(state) {
        return state.compose.company_id;
    },

    /**
     * Methods to return listAsOptionsTranslator as array and in paired format
     *
     * @return {object}
     */
    listAsOptionsTranslator: (state) => state.listAsOptionsTranslator,
    listAsOptionsPairedTranslator: (state) => keyAndLabels(state.listAsOptionsTranslator, {key: 'id', label: 'name'}),

    /**
     * Methods to return listAsOptionsCustomer as array and paired format
     *
     * @return {object}
     */
    listAsOptionsCustomer: (state) => state.listAsOptionsCustomer,
    listAsOptionsPairedCustomer: (state) => keyAndLabels(state.listAsOptionsCustomer, {key: 'id', label: 'name'}),
    /**
     * Get the deletedUsers list state
     *
     *  * @param {object} state
     *  : current state of the vuex Object
     *
     * @return {array}
     */
    getTrashedUsers: (state) => state.trashedUsers,
    paginationTrashedUsers: (state) => state.paginationTrashedUsers,

  /**
   * @returns {Object}
   */
  authUserData() {
    return JSON.parse(localStorage.getItem('app_user_data'));
  },

} // End of export default
