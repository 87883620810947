<template>
    <div class="app-list">

        <faq-base-list
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="topControls">
              <div class="text-right">
                <span class="text-right">{{$t('total_records')}} : {{listPagination.total}}</span>
              </div>
            </template>
            <template slot="actionsColumn" slot-scope="scope">
                <div @contextmenu.prevent="handleDetailsNew(scope)">
                <el-button class="circle b" type="success" title="Details" @click="handleDetails(scope)" size="mini" icon="el-icon-info">
                </el-button>
                </div>
            </template>
        </faq-base-list>

        <el-pagination
                layout="total,prev, pager, next, jumper"
                :page-size="listPagination.per_page"
                :total="listPagination.total"
                :current-page="listPagination.current_page"
                @current-change="reloadLists">
        </el-pagination>

    </div> <!-- /.app-list faq-list -->
</template>

<script>

    import {mapGetters, mapActions} from "vuex";
    import {populate,forcePopulate} from "~/js/helpers/Vuex";
    import {isEmpty,sd} from "../../../js/helpers/Common";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {
            vPopulatePayload:{
                type: Object,
                default(){
                    return {
                    'sort': '-created_at'
                }
                }
            }
        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {

            const _this = this;

            return {

                tableElId: 'FAQ_list',

                perPage: 15,
                search: '',

                listCols: [
                    {prop: 'name', label: _this.$t('title'), width: ''
                    },
                    {prop: 'subtitle', label: _this.$t('subtitle'), width: ''
                    },
                    {
                        prop: 'subtitles_count', label: _this.$t('total_questions'), width: '120px',
                        sortable: true,
                    },
                    {prop: 'role', label: _this.$t('published_for'), width: '120px',},
                    {prop: 'created_at', label: _this.$t('created'), width: '140px', sortable: true},
                    {prop: 'updated_at', label: _this.$t('updated'), width: '140px', sortable: true},
                ],
                row: null
            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {
           ...mapGetters('faq', ['listData', 'listPagination'])
        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {
        ...mapActions('faq', {
          browse: 'browse',
          deleteEntry: 'delete'
        }),
        ...mapActions('auth', {
          loginAs: 'loginAs'
        }),
        reloadLists (n) {
          const opts = {
            action: 'browse',
            data: 'listData',
            ref: 'User Listing'
          };
          const params = _.clone(this.$route.query);
          this.reloadList(n, opts, params)
        },

            /**
             * method for handling details popups
             * @param {object} scope
             *
             * @return {void}
             */
            handleDetails(scope) {
                this.$router.push({name: 'faq-details', params: {id: scope.row.id,title:scope.row.name,created_by:scope.row.created_by}});
            },

            handleDetailsNew(scope) {
                let route = this.$router.resolve({name: 'booking-details', params: {id: scope.row.id}});
                window.open(route.href, '_blank');
            },
        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {
          //  this.fetchFaqGroupe();
             //this.getList();
             const opts = {
               action: 'browse',
               data: 'listData',
               ref: 'User Listing'
             };
             const params = _.clone(this.$route.query);

             this.reloadList(null, opts, params);
        }, // End of Component > mounted


    } // End of export default

</script>
