import {
  Notification
} from 'element-ui';
import {
  playNotification
} from './Common';

export function showNotification (data) {
  console.log(data)
  // if (data.action === 'booking.created' && data.booking.is_immediate) {
  //   Notification(bookingCreated(data));
  // } else if (data.action === 'booking.accepted' && data.booking.is_immediate) {
  //   Notification(bookingAccepted(data));
  // }

  // if (data.action === 'booking.expiring_in_3_min'){
  //   Notification(bookingExpiring(data,3))
  // }
  // if(data.booking.is_immediate) {}
  // Notification({
  //     message: data.message,
  //     type: data.type || 'warning',
  //     title: (data.type || 'warning').toUpperCase(),
  //     onClick: data.callback || function() {},
  //     duration: data.duration || 5000, // 5 seconds
  //   });
}

export function bookingCreated (data) {
  // console.log(data);
  playNotification();
  let booking = data.booking;
  // Emergency booking (#JOBID) created by (company name). Link to booking details
  const message = `Emergency booking <a href="javascript:void(0)"># <strong>${booking.id}</strong></a>, created.`;
  return {
    message,
    type: 'info',
    dangerouslyUseHTMLString: true,
    onClick: function (data) {
      window.location.href = `/#/booking/${booking.id}/details/`;
    }
  };
}

export function bookingAccepted (data) {
  playNotification();
  const booking = data.booking;
  let translator = data.booking.assigned_translator;
  const message = `Emergency booking <a href="javascript:void(0)"># <strong>${booking.id}</strong></a>,
                    accepted by <strong>${translator.name}</strong>,<br/> mobile #
                    <a href="javascript:void(0)"><strong>${translator.mobile && translator.mobile}</strong></a>`;
  return {
    message,
    type: 'info',
    dangerouslyUseHTMLString: true,
    onClick: function () {
      window.location.href = `/#/booking/${booking.id}/details/`;
    },
    duration: 10000
  };
}

export function bookingExpiring (data,time) {
  playNotification();
  const booking = data.booking;
  const message = `Booking <a href="javascript:void(0)"># <strong>${booking.id}</strong></a>,
                    expiring in ${time} min`;
  return {
    message,
    type: 'info',
    dangerouslyUseHTMLString: true,
    onClick: function () {
      window.location.href = `/#/booking/${booking.id}/details/`;
    },
    duration: 10000
  };
}
