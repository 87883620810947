/*
|--------------------------------------------------------------------------
| Store > emails-templates > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';
export default {

  /**
   * Array of unvailable times data
   * @type {array|null}
   */
  emails: [],
  /**
   * unvailble object
   * @type {object|null}
   */
  email: {
    title:'',
    email:'',
  },
  listPagination:{},

}
