import APICaller from "../../helpers/APICaller";

import {gep} from "~/js/helpers/Vuex";

/*
|--------------------------------------------------------------------------
| Store > Feedback > Actions
|--------------------------------------------------------------------------
|
| This file contains the actions property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/

import {Notification} from "element-ui";

import i18n from '../../i18n';
import { isEmpty } from "../../helpers/Common";
let apiPrefix = 'v3';
export default {
   fetchFeedbacks: (context,params) =>{

    return  APICaller({
      method:'get',
      endpoint: gep('feedback',apiPrefix),
      params
    }).then(response => {
      // context.commit('booking/setBookingFeedbacks',response.data.data.feedback)
      if(!isEmpty(params.default) && params.default == true){

      context.commit('setFeedbackList',response.data.data.feedback)
      }else{
      context.commit('setFeedbacks',response.data.data.feedback)
      }
    }).catch(e =>{
      console.log(e)
    })
  },
  putFeedback:(context,params)=>{
    return APICaller({
      method:'put',
      endpoint:gep(`feedback/${params.id}`,apiPrefix),
      data:params,
      isDataRaw: true
    }).then(response => {
      Notification.success({
        title: i18n.t('success'),
        message: 'Feedback updated',
      });

      return response;
    }).catch(e => {
      Notification.error({
        title: i18n.t('error'),
        message: e.response.data.data,
      });
    })
  },
  storeFeedback:(context,data)=>{
    return APICaller({
      method:'post',
      endpoint:gep(`feedback`,apiPrefix),
      data,
      isDataRaw: true
    }).then(response => {
      context.dispatch('fetchFeedbacks',{'all':true,'filter[booking_id]':data.booking_id})

      Notification.success({
        title: i18n.t('success'),
        message: 'Feedback Added',
      });
    }).catch(e => {
      Notification.error({
        title: i18n.t('error'),
        message: e.response.data.data,
      });
    })
  },

  storeFeedbackIssue(context,data){
    context.commit('setLoading', true);
    return APICaller({
      method:'post',
      endpoint:gep(`feedback-issue?include=user`,apiPrefix),
      data,
      isDataRaw: true
    }).then(response => {

    context.commit('setLoading', false);
      Notification.success({
        title: i18n.t('success'),
        message: 'Feedback issue added',
      });
      return response
    }).catch(e => {

    context.commit('setLoading', false);
      Notification.error({
        title: i18n.t('error'),
        message: 'Something happen',
      });
      return e.response
    })
  },
  storeBlacklist(context,data){
    return APICaller({
      method:'post',
      endpoint:gep(`blacklisted-users`,apiPrefix),
      data,
      isDataRaw: true
    }).then(response => {
      Notification.success({
        title: i18n.t('success'),
        message: 'Translator blacklisted',
      });
      return response
    }).catch(e => {
      Notification.error({
        title: i18n.t('error'),
        message: 'Something happen',
      });
      return e.response
    })
  },
  

}