import {isEmpty} from '../../helpers/Common';


export default {
  setLoading: (state,payload) =>state.loading = payload,
  setError: (state, payload) => state.error = payload,
  setTextTranslation:(state,payload) => {
    let key = Object.keys(payload.data)[0];
    state.text_translation = payload.data[key]
  },
  setFiles:(state)=>{
    state.files = state.text_translation.files
  }
}