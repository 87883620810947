/*
|*****************************************************************************
| SE Locale
|*****************************************************************************
| Here resides all translations according to the title above
|
*/
export default {

  id: 'ID',
  batch_id: 'Batch ID',
  actions: 'Actions',
  hour: 'hour',
  min: 'min',
  translator_type: ' Translator Type',
  translator_level: 'Translator Level | Translator Levels',
  translator_name: 'Translator Name',
  translator_mobile: 'Translator Mobile',
  chargeable_time: 'Debiteringstid',
  inconvenience_charge: 'OB-tillagg',
  booking_fee: 'Formedlingsavgift',
  emergency_fee: 'Akutbokninstillagg',
  travel_time: 'Restid',
  reimbursement: 'Ersattning',
  km_reimbursement: 'KM-ersattning',

  mobile: 'Mobile',
  booking: 'Booking',

  phone: "Telefontolkning",
  phone_immediate: "Telefontolkning Immediate",
  telephone: "Telefontolkning",
  physical: "Platstolkning",
  patient_language: 'Patient Language | Patient Languages',
  video: 'Video',
  video_physical: 'Video Physical',
  video_skype: 'Video Skype',
  convey: 'Convey',
  excluded_translators: 'Utesluten översättare',
  choose_translator: 'Choose Translator',
  booking_created: 'Booking Created',
  booking_success: 'Skapa bokning framgångsrik',
  success: 'Success',
  error: 'Error',
  interval: 'Interval',

  accepted_at: 'Accepted At',
  accepted: 'Accepted',
  cancelled_at: 'Cancelled At',
  cancelled: 'Cancelled',
  rejected_at: 'Rejected At',
  rejected: 'Rejected',
  created_at: 'Created At',
  created: 'Created',
  updated_at: 'Updated At',
  updated: 'Updated',
  will_send_at: 'Will Send At',
  sent_at: 'Sent At',

  dist_car: 'KM Car',
  time_car: 'Time Car',
  dist_public: 'KM Public',
  time_public: 'Time Public',
  notes: 'Notes',

  // Translator Levels
  tlvl_layman: 'Registrerad tolk',
  tlvl_read_courses: 'Grundutbildad tolk',
  tlvl_certified: 'Auktoriserad tolk',
  tlvl_trained: 'Den utbildad tolk',
  tlvl_certified_in_health: 'Sjukvårdstolk',
  tlvl_certified_in_law: 'Rättstolk',
  tlvl_deluxe: 'Deluxe',

  // Notifications (prefixed with n)
  n_entry_added: 'Entry has been created.',
  n_entry_edited: 'Entry has been updated.',
  n_entry_deleted: 'Entry had been removed.',
  n_went_wrong: 'Something went wrong, please try again.',
  n_are_you_sure_to_delete: 'Are you sure you want to delete this item?',

  awaiting_price:'Awaiting Price',
  pending_translator: 'Pending Translator',
  being_translated: 'Being Translated',
  submited_for_review: 'Submited For Review',
  in_review: 'In Review',
  submited_from_review: 'Submited From Review',
  need_to_revised: 'Need To Revised',
  completed:'Completed',
  accept_other_gender:'Ni har valt {other} tolk. Om det ej finns tillgänglig tolk. Kan ni acceptera {actual} tolk i andra hand?',
  accept_alt_translator_levels:'Ni har auktoriserad tolk. Kan ni acceptera annan nivå om ingen auktoriserad tolk finns tillgänglig.',
  accept_alt_specific_translators:'Ni har bokat specifik tolk. Kan ni tänka er annan tolk ifall den ni valt ej är tillgänglig?',
  male:'Manlig',
  female: 'Kvinnlig',

  BOOKING_SPECIFICS: {
    TRANSLATOR: 'Can you accept another translator if this one is busy?',
    GENDER: 'Can you accept the other gender if nobody is available?',
    TRANSLATOR_LEVEL: 'Can you accept other levels if nobody available?',
    PHYSICAL: {
      INDEX: 'Telephone bookings are better for the environment and increase the likelihood of finding a translator. Would you accept a telephone translator?',
      CONVERT_TO_PHONE: 'Yes telephone translator is okay.',
      WAIT_FOR_A_CERTAIN_TIME_THEN_CONVERT_TO_PHONE: 'First hand I\'d prefer a physical translator within agreed max travel time; but if no translator is available you can try with a telephone.',
      STRICT_MAX_TRAVEL_TIME: 'No, I want a physical translator, and they must adhere to the agreed maximum travel times.',
      CONSIDER_REIMBURSE: 'No, Its very important that its a physical translator, even if that means that DT finds a translator outside the max travel time, we are okay with reimbursing with that.',
    }
  },
  text_translation:'Text Translations',
  trained_translator: 'Trained Translator',
  booking_locked_by: 'Locked By',
  click_to_make_changes: 'Click to make changes',
  show_pop_up_warning: 'Lås bokning och visa pop-up när två förmedlare är inne i bokningen samma tid',
  btn_update_admin_ui_settings: "Update Admin Ui Settings",
  unlock_booking: 'Klicka för att låsa upp',
  lock_booking: 'Klicka för att låsa bokning',
  sms_centre: 'SMS Help desk'

} // End of root
