<template>
  <div class="app-page booking-issue-detail-page">
    <page-header :title="$t('booking_issue_details')" :back-enabled="true" />
    <div class="app-container">
      <div class="app-wrapper">
        <div class="booking-issue-detail-container">
          <div class="booking-issue-detail-header">
            <h4>{{title}}</h4>
          </div>
          <el-row >
            <ElCol :md="16">
              <el-card>
                <div class="text item">
                  <booking-issue-comments :issue="issue" :list="issue.comments"></booking-issue-comments>
                  <template v-if="issue.type.type ==='specific_rejected'">
                    <specific-rejected-list :batches-entries="issue.booking.batches" />
                    <br />
                  </template>
                  <template v-if="issue.type.type ==='crime_check_up'">
                    <crime-checkup-details :issue="issue" />
                    <br />
                  </template>
                  <template v-if="issue.type.type ==='expense_report'">
                    <expense-issue :issue="issue" />
                    <br />
                  </template>
                  <template v-if="this.issue.type.type ==='municipality_renegotiation_date'">
                    <municipality-details :issue="issue" />
                    <br />
                  </template>
                  <!-- <template v-if="this.issue.type.type ==='invalid_geocode'"> -->
                  <template v-if="translatorRequest.includes(issue.type.type)">
                    <p>
                      Translator have {{issue.type.type_name}}, please check
                      <br />
                      <a
                        href="javascript:;"
                        @click="handleEditUser(issue.translator)"
                      >{{issue.translator.name}}</a>
                    </p>
                  </template>
                </div>
              </el-card>
            </ElCol>
            <ElCol :md="8">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <template v-if="!isEmpty(issue.booking)">
                    <ul class="booking-details">
                      <li>
                        <i class="fa fa-hashtag"></i>   {{$t('issue_id')}}
                        <br />
                        <div class="booking-details-label">{{issue.id}}</div>
                      </li>
                      <li>
                        <i class="fa fa-sticky-note"></i>   {{$t('tfv_booking_id')}}
                        <br />
                        <router-link :to="bookingUrl">
                          <div class="booking-details-label">{{issue.booking.id}}</div>
                        </router-link>
                      </li>
                      <li>
                        <i class="fa fa-user"></i>   {{$t('customer_name')}}
                        <br />
                        <router-link :to="customerProfileUrl">
                          <div class="booking-details-label">{{issue.booking.customer.name}}</div>
                        </router-link>
                      </li>
                      <li v-if="!isEmpty(issue.booking.assigned_translator)">
                        <i class="fa fa-user"></i>   {{$t('translator_name')}}
                        <br />
                        <router-link :to="translatorProfileUrl">
                          <div class="booking-details-label">{{issue.booking.assigned_translator.name}}</div>
                        </router-link>
                      </li>
                      <li>
                        <i class="fa fa-clock-o"></i>   {{$t('date_created')}}
                        <br />
                        <div class="booking-details-label">{{issue.booking.created_at}}</div>
                      </li>
                      <li>
                        <i class="fa fa-clock-o"></i>   {{$t('booking_due')}}
                        <br />
                        <div class="booking-details-label">{{issue.booking.due}}</div>
                      </li>
                    </ul>
                  </template>
                </div>
                <div class="text item">
                  <div>
                    <el-select
                      v-model="issue.assigned_admin_id"
                      placeholder
                      value-key="id"
                      filterable
                    >
                      <el-option
                        v-for="i in adminList"
                        :key="i.id"
                        :value="i.id"
                        :label="i.name"
                      >{{i.name}}</el-option>
                    </el-select>
                    <el-button type="success" @click="handleAssignAdminButton">{{$t('assign_admin')}}</el-button>
                  </div>

                  <hr/>
                  <feedback-cause-tag />
                  <hr/>
                  <template v-if="!isEmpty(issue.id)">

                    <related-emails :issue="issue" />
                  </template>
                  <hr/>

                  <template v-if="issue.type.type ==='low_feedback'">
                    <feedback-detail
                      :feedback="issue.booking.feedback"
                      :solved="!isEmpty(isSolved)"
                      :issue="issue"
                    />
                    <br />
                  </template>

                </div>
                <booking-issue-actions :issue="issue"/>

              </el-card>
            </ElCol>
          </el-row>
        </div>
      <!--  app-wrapper -->
    </div>
    </div>
    <!--  app-container -->
    <!-- <template v-if="!isEmpty(issue.booking)"> -->

    <!-- </template> -->

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations  } from "vuex";
import BookingIssueComments from "./partials/BookingIssueComment";
import FeedbackDetail from "./partials/FeedbackDetail";
import SpecificRejectedList from "./partials/SpecificRejectedList";
import ReportToCustomerModal from "./partials/SendReportToCustomer";
import CrimeCheckupDetails from "./partials/CrimeCheckupDetails";
import MunicipalityDetails from "./partials/MunicipalityIssueDetails";
import ExpenseIssue from "./partials/ExpenseIssue";
import { isEmpty } from "../../../js/helpers/Common";
import FeedbackCauseTag from './partials/FeedbackCauseTag';
import RelatedEmails from './partials/RelatedEmailsList';
import BookingIssueActions from './partials/BookingIssueActions';
export default {
  components: {
    BookingIssueComments,
    FeedbackDetail,
    SpecificRejectedList,
    ReportToCustomerModal,
    CrimeCheckupDetails,
    ExpenseIssue,
    MunicipalityDetails,
    FeedbackCauseTag,
    RelatedEmails,
    BookingIssueActions
  },
  data() {
    return {
      translatorRequest: [
        "invalid_geocode",
        "gdpr_export_request",
        "certificate_for_goverment_request",
        "certificate_for_insurance_request",
        "working_with_dt_request"
      ]
    };
  },
  computed: {
    ...mapState("auth", {
      user: "userData"
    }),
    ...mapGetters('todo', {
      todoItem: 'itemData'
    }),

    ...mapGetters("adminUser", { adminList: "listAsOptions" }),
    customerProfileUrl() {
      return `/user/profile/${this.issue.booking.customer_id}?type=2`;
    },
    translatorProfileUrl() {
      return `/user/profile/${this.issue.booking.assigned_translator.translator_id}?type=3`;
    },
    bookingUrl() {
      return `/booking/${this.issue.booking.id}/details`;
    },
    financialAdjustmentUrl() {
      return `/booking/${this.issue.booking.id}/financial-adjustment?type=${this.issue.booking.type}`;
    },
    ...mapGetters("bookingIssue", {
      issue: "itemData"
    }),
    title() {
      return this.$t(this.issue.type.type).toUpperCase();
    },
    isSolved() {
      return !isEmpty(this.issue.solved_at);
    }
  },
  methods: {
    ...mapActions("bookingIssue", {
      read: "read",
      updateIssue: "updateIssue",
      postComment: "postComment",
      postAssignAdmin: "postAssignAdmin",
    }),
    ...mapActions("booking", {
      addBookingComment: "addComment"
    }),
    ...mapActions("feedback", {
      putFeedback: "putFeedback"
    }),
    ...mapActions("adminUser", {
      browseAdmin: "browseAsOptions"
    }),
    ...mapActions("municipality", {
      getMunicipality: "read"
    }),
    ...mapMutations('bookingIssue', {
      setReviewType: 'setReviewType',
    }),
    ...mapActions("todo", {
      getTodo: "read"
    }),
    isEmpty(v) {
      return isEmpty(v);
    },
    async setAsSolved(e) {
      let params = {
        booking_issue_id: this.issue.id,
        solved: true
      };
      let issue = await this.updateIssue(params);
      if (issue.status === 200) {
        let payload = {
          booking_issue_id: this.issue.id,
          comment: e.comment,
          created_by: this.user.id
        };
        this.postComment(payload);
        // if (!isEmpty(this.issue.booking_id)) {
        //   this.addBookingComment({
        //     comment: e.comment,
        //     booking_id: this.issue.booking_id
        //   })
        // }

        // if(this.issue.type.type==='low_feedback' && !isEmpty(this.issue.entity_id)){
        //   this.putFeedback({action_comment:e.comment,id:this.issue.entity_id})
        // }
      }
    },
    handleAssignAdminButton() {
      let params = {
        id: this.issue.id,
        assigned_admin_id: this.issue.assigned_admin_id
      };

      this.postAssignAdmin(params).then(() => {
        let payload = {
          booking_issue_id: this.issue.id,
          comment: "assigned admin with ID" + this.issue.assigned_admin_id,
          created_by: this.user.id
        };
        this.postComment(payload);
      });
    },
    handleEditUser(user) {
      if (user.type === 3) {
        this.$router.push({
          name: "user.edit.translator",
          params: { id: user.id }
        });
      }
    },

    reloadItem(id){
      this.read({
      id: id
    }).then(r => {
      let item = r.data.data.booking_issue;
      if (item.type.type === "municipality_renegotiation_date") {
        this.getMunicipality({ id: item.entity_id });
      }else if(item.entity === "App\\Modules\\Todos\\Todo"){
        this.getTodo({ id: item.entity_id });
      }
      if(item.type.type === 'low_feedback'){
        this.setReviewType({type: item.entities.review_from_type })
      }
    });
    }

  },
  mounted() {
    this.reloadItem(this.$route.params.id)
    let params = {
      "filter[type]": 1,
      all: true
    };
    this.browseAdmin({ extendParams: params });
  }
};
</script>

<style lang="scss">
.green {
  &.el-card__header {
    background-color: #222 !important;
    color: #ffff !important;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  padding: 10px;
  text-align: center;
}
.grid-details {
  border-radius: 4px;
  min-height: 36px;
  padding: 10px;
}
</style>
