/*
|--------------------------------------------------------------------------
| store > user > shared > props
|--------------------------------------------------------------------------
|
| This file contains the shared properties to be used on various scope states.
|
*/

'use strict';

export const DEFAULT_PROPS = {
  company_id: '',
  department_id: ''
};

export const PERSONAL_INFO = {
  name: '',
  email: '',
  dob: '',
  phone: '',
  mobile: '',
  fax: '',
  gender: 'male',
  username: '',
  notes: '',
};

export const CREDENTIAL = {
  current_password: '',
  password: '',
  password_confirmation: ''
};

export const SERVICE = {
  towns: [],
  countries: [],
  booking_types: ['physical', 'phone','convey'],
  translator_customer_types: [],
  allowed_customer_types: [],
  disallowed_customer_types: [],
  languages: [],
  language_pair_levels: []
};

export const CUSTOMER_SETTINGS = {
  customer_type: '',
  blacklisted_users: [],
  blacklisted_users_temp: [],
  towns: [],
  cost_place: '1',
  booking_types: ['physical', 'phone','convey'],
  user_towns: []
};

export const TRANSLATOR_SETTINGS = {
  tax: 30,
  tax_type: "standard",
  bank_name: "",
  bank_account_number: ""
};

export const ADDRESS = {
  address: '',
  address_2: '',
  post_code: '',
  city: '',
  country_iso: 'SE',
  additional_info: '',
  coordinates:'',
  office_area:''
};

export const DEFAULT_QUESTIONS = {
  enabled: true,
  disable_comment: ''
  // tfa_enabled: false,
  // show_2fa_qr: false
};

export const CUSTOMER_QUESTIONS = {
  allow_show_invoices: false,
  allow_send_invoices: false,
  allow_fax_confirmation: false,
  allow_credit_card: false,
  allow_fallback_type: true,
  // SMS confirmation
  allow_sms_confirmation : false,
  allow_appointment_webapp: false,

  //Confidential fields
  is_sensitive_info: 0,
  confidential_message: '',
  is_protected_identity: 0,
  allow_suggest_time_appointment: true

};

export const TRANSLATOR_QUESTIONS = {

  has_training_certificate: false,
  has_police_background: false,
  has_contract: false,
  has_driving_license: false,
  will_invoice_manually: false,
  is_sensitive_info: 0,
  confidential_message: '',
  is_protected_identity: 0,
  translator_notify: false,
  translator_notify_emergency: false,
  translator_notify_nighttime: false,

  company_name: '',
  company_registration_no: '',
  invoice_tax: 0,

  availability_switch: false,
  available: false,
  available_from: {
    date: '',
    time: ''
  },
  available_until: {
    date: '',
    time: ''
  },
  WorkingHours:[{
    time_from: '',
    time_end: ''
  }],
  is_employee:false,
  is_available_weekend:false,
  higher_wage:false,
  lunch_switch:false,
  lunch_time_range_switch:false,
  lunch_time_fixed_switch:true,
  higher_wage_option:'Employee',
  salary_per_hour:0,
  amount_of_job:0,
  lunch_time_from:'',
  lunch_time_to:'',
  traveling_by: 'public_transport',
  is_mentor: false,
  is_passive: false,
  company_info_verified: false
};

export const BOOKING_CONFIRM_FIELDS = {
  // for usage of same fields as municipality
  same_as_municipality: false,
  // Booking Questions > Reference
  reference_enabled: false,
  reference_required: false,
  reference_placeholder: '',

  // Booking Questions > Booker's Name
  booker_name_enabled: false,
  booker_name_required: false,

  // Booking Questions > Staff Name / Translator's Name
  staff_name_enabled: false,
  staff_name_required: false,

  // Booking Questions > Instructions
  instructions_enabled: false,
  instructions_required: false,

  // Booking Questions > Alternative Language
  alt_language_enabled: false,
  alt_language_required: false,

  // Booking Questions > LMA
  lma_enabled: false,
  lma_required: false,



};
