/*
|--------------------------------------------------------------------------
| Store > Sector > Municipality
|--------------------------------------------------------------------------
|
| A file that manages all the properties and abilities in relation
| to Municipality Model and other related values.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {extendObj, extendObjParams, mapEndpoint} from "../../helpers/Common";
import APICaller from '~/js/helpers/APICaller';
import bread from '../bread';
import state from './state';
import mutations from './mutations';
import {Notification} from 'element-ui';
import i18n from '~/js/i18n';
import {gep} from "~/js/helpers/Vuex";
let apiPrefix = window.app_api_prefix_3;

let getters = {
    //getIsBooking: (state) => !state.is_show_on_booking,
};
let actions = {
    getTrainingRecordsByTranslator(context, payload = {}) {

        context.commit('setListLoading', true);

        let method = 'get';
        let endpoint = mapEndpoint('/api/v3/training-translator-records', payload);
        let params = {
          'filter[user_translator_id]' : payload.user_translator_id,
          include: 'training',
          all: 1
        };
    
        return new Promise((resolve, reject) => {
          APICaller({method, endpoint, params})
            .then((r) => {

              let trainingRecords = r.data.data.training_translator_records;
              let finalTrainingRecords = [];
              for (let tr of trainingRecords) {
                let obj = tr;
                let courseCode = [];
                try {
                  if (isNaN(obj.training.course_code)) {
                    courseCode = JSON.parse(obj.training.course_code);
                  } else {
                    throw "Training Course Code is a number.";
                  }
                } catch (e) {
                  courseCode.push(obj.training.course_code);
                } finally {
                  obj.training.course_code = courseCode.join(', ');
                }
                finalTrainingRecords.push(obj);
              }
              r.data.data.training_translator_records = finalTrainingRecords;

              context.commit('setListWithRecords', r.data);
              resolve(r);
            })
            .catch((e) => {
              reject(e);
            }).finally(() => {
              context.commit('setListLoading', false);
            });
        });
    },
    getTrainingRecord(context, payload = {}) {

      context.commit('setListLoading', true);

      let method = 'get';
      let endpoint = mapEndpoint('/api/v3/training-translator-records/{id}', payload);
      let params = {
      };
  
      return new Promise((resolve, reject) => {
        APICaller({method, endpoint, params})
          .then((r) => {
            //context.commit('setListWithRecords', r.data);
            resolve(r.data.data.training_translator_record);
          })
          .catch((e) => {
            reject(e);
          }).finally(() => {
            context.commit('setListLoading', false);
          });
      });
    },
    browseTrainings (context, payload = {}) {

      context.commit('setListLoading', true);
  
      return new Promise((resolve, reject) => {
        context.dispatch('browseGeneric', payload)
               .then((r) => {
                 
                 let trainings = r.data.data.trainings;
                 let finalTrainings = [];
                 for (let t of trainings) {
                    let obj = t;
                    let courseCode = [];
                    try {
                      courseCode = JSON.parse(obj.course_code);
                    } catch (e) {
                      courseCode.push(obj.course_code);
                    } finally {
                      obj.course_code = courseCode.join(', ');
                    }
                    finalTrainings.push(obj);
                 }
                 r.data.data.trainings = finalTrainings;

                 context.commit('setList', r.data);
                 resolve(r);
               })
               .catch((e) => {
                 reject(e);
               })
               .finally(() => {
                 context.commit('setListLoading', false);
               });
  
      });
    },
    browseCourseCodes (context, payload = {}) {

      let method = 'get';
      let endpoint = mapEndpoint(gep('training-course-code', 'v3'), payload);
      let params = extendObjParams(context.state.api.browse.params, payload);

      return new Promise((resolve, reject) => {
        APICaller({method, endpoint, params})
          .then((r) => {
            console.log(r);
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    saveTrainingRecordByTranslator (context, payload) {
        let method = 'post';
        let endpoint = mapEndpoint('/api/v3/training-translator-records', payload);
        let isDataRaw = true;

        return new Promise((resolve, reject) => {
          APICaller({method, endpoint, 'data': payload.form, isDataRaw})
            .then((r) => {
              context.dispatch('getTrainingRecordsByTranslator', {
                user_translator_id: payload.user_translator_id
              });

              Notification.success({
                title: i18n.t('success'),
                message: i18n.t('n_entry_added')
              });
              resolve(r);
            })
            .catch((e) => {
              Notification.error({
                title: i18n.t('error'),
                message: i18n.t('n_went_wrong')
              });
              reject(e);
            });
        });
    },
    editTrainingRecord (context, payload) {
      let method = 'put';
      let endpoint = mapEndpoint('/api/v3/training-translator-records/{id}', payload);
      let isDataRaw = true;

      return new Promise((resolve, reject) => {
        APICaller({method, endpoint, 'data': payload.form, isDataRaw})
          .then((r) => {
            context.dispatch('getTrainingRecordsByTranslator', {
              user_translator_id: payload.user_translator_id
            });
            
            Notification.success({
              title: i18n.t('success'),
              message: i18n.t('n_entry_edited')
            });
            resolve(r);
          })
          .catch((e) => {
            Notification.error({
              title: i18n.t('error'),
              message: i18n.t('n_went_wrong')
            });
            reject(e);
          });
      });
  },
    deleteTrainingRecordByTranslator (context, payload) {
        let method = 'delete';
        let endpoint = mapEndpoint('/api/v3/training-translator-records/{id}', payload);
        let isDataRaw = true;

        return new Promise((resolve, reject) => {
          APICaller({method, endpoint, 'data': {}, isDataRaw})
          .then((r) => {
            context.dispatch('getTrainingRecordsByTranslator', {
              user_translator_id: payload.user_translator_id
            });

            let message = {
              title: i18n.t('success'),
              message: i18n.t('n_entry_deleted')
            };

            if (message.message === 'n_entry_deleted') {
              message.message = 'Entry had been removed.';
            }

            Notification.success(message);
            resolve(r);
          })
          .catch((e) => {
            Notification.error({
              title: i18n.t('error'),
              message: i18n.t('n_went_wrong')
            });
            reject(e);
          });
        });
    },
    readTraining (context, payload = {}) {

      context.commit('setItemLoading', true);
  
      return new Promise((resolve, reject) => {
        context.dispatch('readGeneric', payload)
               .then((r) => {
                 let raw = r.data.data; // Store into container so varname will be shorter.
                 let data = raw[Object.keys(raw)[0]]; // Get the first member of the object.

                 let courseCode = [];
                 try {
                    courseCode = JSON.parse(data.course_code);
                 } catch (e) {
                    courseCode.push(data.course_code);
                 } finally {
                    data.course_code = courseCode;
                 }
  
                 context.commit('setItem', {data});
  
                 if (context.state.autoReflectItem) {
                   context.commit('setCompose', data);
                 }
                 resolve(r);
               })
               .catch((e) => {
                 reject(e);
               })
               .finally(() => {
                 context.commit('setItemLoading', false);
               });
      });
    },
    editTraining (context, payload) {

      let method = context.state.api.edit.method;
      let endpoint = mapEndpoint(context.state.api.edit.path, payload);
      let data = {};
      let isDataRaw = true;

      // Set the value for payload.data
      if (context.state.autoInjectPayload) {
        context.commit('setPayloadData');
        data = context.state.payloadData;
      } else {
        data = payload.data;
      }

      data.course_code = JSON.stringify(data.course_code);

      return new Promise((resolve, reject) => {
        APICaller({method, endpoint, data, isDataRaw})
          .then((r) => {

            if (context.state.autoUpdateList) {
              context.dispatch('browse');
              context.dispatch('browseAsOptions');
            }

            Notification.success({
              title: i18n.t('success'),
              message: i18n.t('n_entry_edited')
            });

            resolve(r);
          })
          .catch((e) => {

            Notification.error({
              title: i18n.t('error'),
              message: i18n.t('n_went_wrong')
            });

            reject(e);
          });
      });
    },
    addTraining (context, payload) {

      let method = context.state.api.add.method;
      let endpoint = context.state.api.add.path;
      let data = {};
      let isDataRaw = true;

      // Set the value for payload.data
      if (context.state.autoInjectPayload) {
        context.commit('setPayloadData');
        data = context.state.payloadData;
      } else {
        data = payload.data;
      }

      data.course_code = JSON.stringify(data.course_code);

      return new Promise((resolve, reject) => {
        APICaller({method, endpoint, data, isDataRaw})
          .then((r) => {

            if (context.state.autoUpdateList) {
              context.dispatch('browse');
              context.dispatch('browseAsOptions');
            }

            Notification.success({
              title: i18n.t('success'),
              message: i18n.t('n_entry_added')
            });

            resolve(r);
          })
          .catch((e) => {

            Notification.error({
              title: i18n.t('error'),
              message: i18n.t('n_went_wrong')
            });

            reject(e);
          });
      });
    },
    filterTrainings({ commit }, params) {
      return APICaller({
        method: 'get',
        endpoint: gep('trainings', apiPrefix),
        params
      }).then(resp => {
        return resp.data.data.trainings
      }).catch(err => {
        console.log(err)
        return err
      })
    },
};

export default {
    namespaced: true,
    state: extendObj(bread.state, state),
    getters: extendObj(bread.getters, getters),
    mutations: extendObj(bread.mutations, mutations),
    actions: extendObj(bread.actions, actions),
} // End of export default
