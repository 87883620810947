<template>

  <div class="app-list booking-issue-list">

                <custom-table-two
                  :table-data="listData"
                  :extra-cols="listCols"
                  :hasDetailsCol="false">

                    <!-- Row for Top Controls -->
                    <template slot="topControls">

                      <filters-panel @updateList="updateLists"></filters-panel>


                    </template>

                    <!-- Column for action buttons -->
                    <template slot="actionsColumn" slot-scope="scope">
                        <el-button class="circle" type="primary" :title="$t('details')" icon="el-icon-info" @click="handleDetailsButton(scope.row.id)"></el-button>
                        <el-button class="circle" type="warning" :title="$t('reopen')" icon="el-icon-info" @click="handleReopenButton(scope.row.id)"></el-button>
                        <!-- <span class="fa fa-trash"></span> -->
                    </template>
                </custom-table-two>
            </div> <!-- /.app-col -->

</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import {isEmpty} from '~/js/helpers/Common'
import FiltersPanel from '~/components/lists/booking-issue/Filters'

export default {
  components:{
    FiltersPanel
  },
  data(){
    const _this=this;
    return {
      type:'',
      listCols:[
        {
          label: _this.$t('id'),
          prop: 'id',
          width: '',
          className: 'type-col',
        },
        {
          label: _this.$t('type'),
          prop: 'type.type_name',
          width: '',
          className: 'type-col',

        },
        {
          label: _this.$t('booking_id'),
          prop: 'booking_id',
          width: '',
          className: 'booking_id-col',
        },
        {
          label: _this.$t('customer'),
          prop: 'booking.customer.name',
          width: '',
          className: 'booking_id-col',
        },
        {
          label: _this.$t('translator'),
          prop: 'translator.name',
          width: '',
          className: 'booking_id-col',
        },
        {
          label: _this.$t('due_date'),
          prop: 'deadline_date',
          width: '',
          className: 'booking_id-col',
          mutator(v,row) {
            return row.entity==='App\\Modules\\Todos\\Todo' ?  row.entities.date: v
          }
        },
        {
          label: _this.$t('solved_at'),
          prop: 'solved_at',
          width: '',
          className: 'booking_id-col',
        }
      ]
    }
  },
  computed:{
    ...mapGetters('bookingIssue',{listData:'listData'}),

    ...mapGetters('bookingIssueSetting',{settingList:'listData'}),
    ...mapGetters('todo', {
      todoList: 'listData'
    }),
  },
  methods:{
    ...mapActions('bookingIssue', {
      reopenIssue:'reopenIssue',
    }),
    ...mapActions('todo',{
      getTodos:'browse'
    }),
    isEmpty(v){
      return isEmpty(v);
    },
    handleDetailsButton(v){
      this.$router.push({name:'booking.issue.details',params:{id:v}})
    },
     updateFilter(e){
      let params = {
        'filter[type]' : e.join(',')
      }
      this.$emit('updateList', params)
    },
     updateSort(e){
      let params = {
        'sort' : e
      }
      this.$emit('updateList', params)
    },
     async handleReopenButton(v){
      let params = {
        id: v
      }
      let issue = await this.reopenIssue(params).then(()=>{
        this.$emit('updateList',{})
      })
    },
    updateLists(e){
      this.$emit('updateList',e)
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {
    this.getTodos();
  }, // End of Component > mounted
}
</script>

<style>

</style>
