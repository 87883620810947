<template>
  <div class="app-list audit-list">
    <div class="list-base-table-top-controls">
      <div class="app-row">
        <div class="app-col col-container half-width text-left">
<!--          <template v-if="showSwitches">-->
<!--            <el-switch v-model="includeTranslatorAvailability" @change="handleTranslatorAvailabilitySwitch"></el-switch>-->
<!--            Include Translator Availability-->
<!--          </template>-->
<!--          <template v-else>-->
<!--            &nbsp;-->
<!--          </template>-->
        </div> <!-- /.app-col -->
        <div class="app-col col-container half-width text-right">
          <el-input :placeholder="$t('search_records')" v-model="search" @keyup.enter.native="handleSearch"
                    style="max-width: 350px;">
            <el-button type="primary" slot="append" icon="el-icon-fa fa-search" @click="handleSearch"
                       style="min-width: 50px;"></el-button>
          </el-input>
        </div> <!-- /.app-col -->

      </div>
      <div class="app-col">
        <el-col :span="6">
          <el-date-picker
            v-model="date"
            type="datetimerange"
            :picker-options="pickerOptions"
            :range-separator="$t('to')"
            :start-placeholder="$t('start_date')"
            :end-placeholder="$t('end_date')"
          >
          </el-date-picker>
          <el-button @click="handleClickFilter()" type="primary">
            {{$t('get_audits')}}
          </el-button>
        </el-col>

      </div>

    </div>
    <el-table :data="list" border v-loading="loading">
      <el-table-column :label="$t('date_time')" :formatter="dateTimeFormat" width="160px"></el-table-column>
      <el-table-column :label="$t('value_before')">
        <template slot-scope="scope">
          <ul>
            <li v-for="(val, key) in scope.row.old_values" :key="key">
              {{ key }} = {{ val }}
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column :label="$t('value_after')">
        <template slot-scope="scope">
          <ul>
            <li v-for="(val, key) in scope.row.new_values" :key="key">
              {{ key }} = {{ val }}
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column :label="$t('tfv_audit_component_name')" prop="auditable_type" :formatter="componentName"></el-table-column>
      <el-table-column :label="$t('user')" :formatter="causer" width="160px"></el-table-column>
      <el-table-column :label="$t('action')" :formatter="messageFormat" width="100px"></el-table-column>
    </el-table>

    <div class="text-center" v-if="pagination.page > pagination.total_page">
      <h3>
        {{ $t('txt_end_of_list') }}
      </h3>
    </div>

  </div> <!-- /.app-list booking-history-list -->
</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from 'vuex';
import {populate} from '../../../js/helpers/Vuex';
import {isEmpty} from '../../../js/helpers/Common';

export default {

  /*
  |--------------------------------------------------------------------------
  | Components > props
  |--------------------------------------------------------------------------
  */
  props: {}, // End of Component > props

  /*
  |--------------------------------------------------------------------------
  | Components > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      search: '',
      tableElId: 'audits-list',
      compiledUsers: [],
      showSwitches: false,
      includeTranslatorAvailability: false,
      windowLastHeight: 0,
      date: [],
      pickerOptions: {
        shortcuts: [{
          text: this.$t('tfv_last_week'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('tfv_last_month'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('tfv_last_three_months'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },

    }
  }, // End of Component > data

  /*
  |--------------------------------------------------------------------------
  | Components > computed
  |--------------------------------------------------------------------------
  */
  computed: {


    ...mapGetters({
      list: 'audits/getAudits',
      loading: 'audits/getLoading',
      users: 'audits/getAdminUsers',
      financials: 'audits/getFinancials',
      pagination: 'audits/getPagination'
    }),


  }, // End of Component > computed

  /*
  |--------------------------------------------------------------------------
  | Components > watch
  |--------------------------------------------------------------------------
  */
  watch: {}, // End of Component > watch

  /*
  |--------------------------------------------------------------------------
  | Components > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    ...mapActions({
      fetchAudits: 'audits/fetchAudits',
      loadAuditsForward: 'audits/loadAuditsForward',
      fetchUsers: 'audits/fetchAllAdminUsers',
      readPriceTemplate: 'audits/fetchPriceTemplate',
      fetchInvoiceAdditionalCharge: 'audits/fetchFinancialInvoiceAdditionalChargeList',
      fetchSalaryAdditionalCharge: 'audits/fetchFinancialSalaryAdditionalChargeList',
      fetchAdjustments: 'audits/fetchAdjustments',
      fetchPartsAdjustments: 'audits/fetchPartsAdjustments',
      readUser: 'user/read',
    }),

    ...mapMutations({
      setAudits: 'audits/setAudits',
      resetPagination: 'audits/resetPagination',
    }),

    ...mapGetters({
      getAuditsBackup: 'audits/getAuditsBackup'
    }),

    /**
     * Helper method to check wether the column is enabled in the options or not.
     *
     * @param {string} key
     *   The switch value to check for whether to enable/disable a column.
     *
     * @return {boolean}
     */
    isColEnabled(key) {
      let r = true;

      if (_.includes(this.hiddenCols, key)) {
        r = false;
      }

      return r;

    }, // End of isColEnabled() method

    //column formater
    dateTimeFormat(row) {
      return moment(row.created_at).format('YYYY-MM-DD HH:mm:ss');
    },
    messageFormat(row) {
      return row.event.charAt(0).toUpperCase() + row.event.slice(1);
    },
    causer(row) {
      let user = row.users;
      return isEmpty(user) ? 'System' : user.name;
    },
    componentName(row) {
      let parts = row.auditable_type.split('\\');
      let str = (parts.length > 0) ? parts[parts.length - 1] : '';
      return str.replace(/([A-Z])/g, ' $1');
    },
    handleSearch() {
      let hits = [];
      let list = this.getAuditsBackup();
      if (this.search.length > 0) {
        for (let n in list) {
          for (let [key, val] of Object.entries(list[n])) {
            if (key === 'old_values' || key === 'new_values') {
              for (let [key2, val2] of Object.entries(list[n][key])) {
                if (String(val2).toLowerCase().includes(this.search.trim().toLowerCase())) {
                  hits[n] = list[n];
                }
              }
            } else if (key === 'user_id') {
              for (let u in this.users) {
                if (list[n][key] == this.users[u].id) {
                  let name = this.users[u].name;
                  if (String(name).toLowerCase().includes(this.search.trim().toLowerCase())) {
                    hits[n] = list[n];
                  }
                }
              }
            } else {
              if (String(val).toLowerCase().includes(this.search.trim().toLowerCase())) {
                hits[n] = list[n];
              }
            }
          }
        }
        this.setAudits(hits);
      } else {
        this.setAudits(this.getAuditsBackup());
      }
    },


    initialize(do_not_show_excluded) {
      this.resetPagination();
      this.setAudits([]);
      this.date[0] = moment().subtract(10, 'day').format('YYYY-MM-DD');
      this.date[1] = moment().format('YYYY-MM-DD');

      let relationships = this.$route.query.relationships || '';

      // Show switches
      let relationshipsArray = relationships.split(',');
      if (relationshipsArray.includes('translatorData')) {
        this.showSwitches = true;
      }
      if (this.$route.name === 'audits.financials.template' || this.$route.name === 'audits.financials.adjustments') {

        this.fetchAudits({
          id: this.$route.params.booking_id,
          auditable: 'booking',
          endpoint: 'financial/financial-audits',
          do_not_show_excluded,
          page: this.pagination.page,
          from_date: moment().subtract(10, 'day').format('YYYY-MM-DD'),
          to_date: moment().format('YYYY-MM-DD'),
          with: 'users',
          'relationships': this.financials.relationships.join(','),
        });

      } else {
        this.fetchAudits({
          id: this.$route.params.id,
          auditable: this.$route.params.auditable,
          page: this.pagination.page,
          from_date: moment().subtract(10, 'day').format('YYYY-MM-DD'),
          to_date: moment(this.date[1]).format('YYYY-MM-DD'),
          do_not_show_excluded,
          'relationships': relationships,
          with: 'users'
        });
      }
    },

    handleTranslatorAvailabilitySwitch() {
      this.initialize(!this.includeTranslatorAvailability);
    },

    /**
     * Method to attach the scroll listener to the DOM
     */
    applyScrollListener() {

      window.onscroll = () => {

        let bottomOfWindow = Math.round(document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;

        if (
          bottomOfWindow
          && this.windowLastHeight !== document.documentElement.offsetHeight
        ) {
          if (this.pagination.page > 1 && this.pagination.page <= this.pagination.total_page) {
            this.appendLoadAudit();
            this.windowLastHeight = document.documentElement.offsetHeight;
          }
        }
      };
    },
    appendLoadAudit() {
      let relationships = this.$route.query.relationships || '';

      // Show switches
      let relationshipsArray = relationships.split(',');
      if (relationshipsArray.includes('translatorData')) {
        this.showSwitches = true;
      }
      this.fetchAudits({
        id: this.$route.params.id,
        auditable: this.$route.params.auditable,
        do_not_show_excluded: false,
        'relationships': relationships,
        page: this.pagination.page,
        from_date: moment(this.date[0]).format('YYYY-MM-DD'),
        to_date: moment(this.date[1]).format('YYYY-MM-DD'),
        with: 'users',
        append: true
      });
    },
    handleClickFilter() {
        this.resetPagination();
        this.appendLoadAudit();
    }
  }, // End of Component > methods


  /*
  |--------------------------------------------------------------------------
  | Components > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {
    this.initialize();
    this.applyScrollListener();

  }, // End of Component > mounted

}
</script>

