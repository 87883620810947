<template>
  <div :class="`app-row `">
    <div class="app-col full-width">
      <el-form :model="form">
        <el-form-item class="label-long" :label="$t('show_pop_up_warning')">
          <el-switch active-value="1" inactive-value="0" v-model="form.value.showPopUpWarning"></el-switch>
        </el-form-item>
        <el-form-item class="text-right">
          <el-button type="primary" @click="handleAdminUiSubmit()">{{$t('btn_update_admin_ui_settings')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { isEmpty, sd } from '~/js/helpers/Common';
export default {
  name: 'adminUiSettings',

  computed: {
    ...mapState('adminUiSettings', {
      form: 'compose'
    })
  },
  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    ...mapActions('adminUiSettings', {
      postAdminUiInSettingsTable: 'postAdminUiInSettingsTable',
      getAdminUiSettings: 'getAdminUiSettings',
      updateAdminUiSettings: 'updateAdminUiSettings'
    }),

    /**
     * Handle kpi settings submit
     */ handleAdminUiSubmit() {
      var id = _.get(this.form, 'id');

      /**
       * If there is ID present in the form then just Update
       * Else POST KPI
       */
      if (!id) {
        this.postAdminUiInSettingsTable(this.form);
      } else {
        this.updateAdminUiSettings(this.form);
      }
    },
    
    /**
     * Prepare the basic setup for KPI Settings
     */
    prepareAdminUiSettingsForm() {
      this.getAdminUiSettings();
    }
  }, // end of component > method

  /*
  |----------------------------------------------------------------------------
  | Component > mounted
  |----------------------------------------------------------------------------
  */
  mounted() {
    this.prepareAdminUiSettingsForm();
  } // End of component > mounted
}; // End of export default
</script>
