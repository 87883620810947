<template>
<div class="app-page email-send-page">

    <page-header :title="$t('emails_templates_form')"/>
    <div class="app-container">
        <div class="app-wrapper">
            <div class="app-row">
                <div class="app-col">
    <el-form :model="emailTemplate" ref="emailTemplate" :validate-on-rule-change="true" :rules="rules">
        <el-form-item
                    :label="$t('subject')" class="label-short" prop="title">
            <el-input
             style="width:47%;"
             v-model="emailTemplate.title">
            </el-input>
        </el-form-item>

        <el-form-item
                    :label="$t('content')" class="label-short" prop="email">
            <!-- <el-input type="textarea" v-model="emailTemplate.email" placeholder="" size="medium" style="width:47%;"
            :autosize="{ minRows: 5, maxRows: 10}"></el-input> -->
            <wysiwyg v-model="emailTemplate.email" :aria-placeholder="$t('enter_text')" :placeholder="$t('enter_text')"/>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('emailTemplate')">
                {{ breadMode }}
            </el-button>

        </el-form-item>
    </el-form>
    </div>
  </div>
</div>
</div>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import {isEmpty,sd} from "~/js/helpers/Common";
import { checkBreadMode } from "~/js/helpers/Route";
import Vue from 'vue';
export default {
  name:'userBookingListPanel',
  props:{
    title:{
      type:String,
      default: 'Emails template'
    },
    refs:{
      default:'emails-template'
    }
  },
  data(){

    const _this = this;
    return {
      emailTemplate:{
        title:'',
        email:'',
      },
      rules: {
          title: [{
              required: true,
              message: 'Please enter an object'
          }],
          email: [{
              required: true,
              message: 'Please enter a message'
          }]
      },
    }
  },
  computed:{

    iTitle(){
      return this.title
    },
    iRefs(){
      return this.refs
    },
    breadMode() {
      return checkBreadMode(this);
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {
    if (this.breadMode === "edit") {
       this.readEntry({id: this.$route.params.id}).then(r => {
          let data=r.data.data.emails_template;
          this.emailTemplate = data;
       });
    }
  }, // End of Component > mounted
  methods:{
    ...mapActions('emailsTemplates',{
      createEmail:'createEmail',
      readEntry:'getEmail',
      editEntry:'editEmail',
   }),

   addEmail(){
      if(this.breadMode === 'add') {
         this.createEmail(this.emailTemplate).then(response => {
              this.$router.push({path: '/settings'});
         });
      }else{
          this.editEntry(this.emailTemplate).then(response => {
               this.$router.push({path: '/settings'});
          });
      }
   },
   submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.addEmail();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>
