/*
|--------------------------------------------------------------------------
| Store > Booking > Actions
|--------------------------------------------------------------------------
|
| This file contains the actions property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import APICaller from '~/js/helpers/APICaller';

import { gep } from "~/js/helpers/Vuex";
import API from '~/js/constants/api';
import { extendObjParams, isEmpty, sd } from "~/js/helpers/Common";
import { convertToFormData } from "~/js/helpers/Html";
import i18n from '../../i18n';
import { Notification } from "element-ui";
import store from '../../store';
import { notifSuccess, notifError } from '~/js/helpers/Notification';
import isNil from 'lodash/isNil';
import has from 'lodash/has';
let apiPrefix = 'v3';
export default {
  /**
   * Request Bookings list
   *
   * @param {object} state
   *  : current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {void}
  */
  getBookingsList: ({ commit }, payload) => {
    let params = {
      include: [
        'translator_levels',
        'specific_translators',
        'excluded_translators',
        'assigned_translator',
        'notification_types',
        'comments',
        'comments.user',
        'video_booking',
        'alternative_languages',
        'convey.files'
      ].join(','),
      with: [
        'travel_time',
        'feedback',
        // 'salary_setting',
      ].join(',')
    };

    if (!isEmpty(payload)) {
      Object.assign(params, payload)
    }
    // commit('setLoading', true);
    return APICaller({
      method: 'get',
      endpoint: gep('bookings', apiPrefix),
      params
    })
      .then((response) => {
        commit('setBookings', response.data)
        commit('setCurrentPending')
        commit('setCurrentAccepted')
        commit('setCurrentAccepted')
        commit('setTotalCompleted')
        commit('setTotalCancelled')
        commit('setLateCancelled')
        commit('setNotCarriedOutTranslator')
        commit('setNotCarriedOutCustomer')

        commit('setLoading', false);
        return response
      }
      ).catch((e) => {

        console.log(e)
        return e
      })
  },

  export: (context, payload) => {
    return APICaller({
      method: 'post',
      endpoint: gep('export-bookings', 'v3'),
      params: payload,
      isDataRaw: true,
    }).then((r) => {
      Notification.success({
        title: 'bookings exported',
        message: 'Exported booking will be sent to email',
      });
      console.log(r, 'this is response');
    }).catch((error) => {
      Notification.error({
        title: i18n.t('error'),
        message: error.response.data.data,
      });
      console.log(error, 'this is error');
    })
  },

  submitEmail: ({ commit, dispatch, state }, payload) => {
    return APICaller({
      method: 'get',
      endpoint: gep('check_email?email=' + payload, apiPrefix),
      params: {
      }
    })
  },

   exportBookingFinancial: (context, payload) => {
    return APICaller({
      method: 'post',
      endpoint: gep('export-bookings-financial', 'v3'),
      params: payload,
      isDataRaw: true
    }).then((r) => {
      Notification.success({
        title: 'bookings exported',
        message: 'Exported booking will be sent to email'
      });
      console.log(r, 'this is response');
    }).catch((error) => {
      Notification.error({
        title: i18n.t('error'),
        message: error.response.data.data
      });
      console.log(error, 'this is error');
    });
  },

  /**
   * Action to get the booking data from the API.
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  getBooking: (context, payload) => {
    const { commit, dispatch, state, rootState } = context;
    commit('setLoading', true);

    const apiOpts = {
      method: 'GET',
      endpoint: `${API.BOOKINGS}/${payload}`,
      params: {
        include: [
          'translator_levels',
          'specific_translators.translator',
          'excluded_translators',
          'assigned_translator',
          'notification_types',
          'comments',
          'comments.user',
          'sms_channels.sms_centre_conversation',
          'customer.department.company',
          'video_booking',
          'alternative_languages',
          'booker',
          'translators',
          'towns',
          'convey.files',
          'locked_booking.user',
          'translators_count',
          'planned_translator.translator',
          'text_translation.files',
          'language_pair_levels',
          'customer.department.company.municipality',
          'messages.files',
          'invoicing_department',
          // 'planned_translator.translator',
          // 'specific_preference'

        ].join(','),
        append:[
          'translators_sendable_count',
          'translators_non_sendable_count'
        ].join(','),
      }
    };

    return APICaller(apiOpts)
      .then((response) => {

        commit('setBooking', response.data);

        commit('setEditForm', response.data);

        commit('setBookingComments', response.data);
        commit('setLockedBooking', response.data);
        commit('setError', { status: false, message: '' });

        let assigned_translator = response.data.data.booking.assigned_translator;
        if (!isEmpty(assigned_translator)) {
          dispatch('fetchAssignedTranslator', assigned_translator.translator_id);
        }

        if (state.booking.isSpecificTranslator) {
          dispatch('bookingBatches/read', { id: state.booking.id }, { root: true }).then(() => {
            commit('setIsSpecificTranslatorReject', rootState)
          });
        }

        return response.data.data;

      }).catch(() => {
        commit('setError', { status: true, message: 'Error!!, Failed to get the Booking' });
        commit('setLoading', false);

      })
      .finally(() => {
        commit('setLoading', false);
      });
  },

  /**
   * send submit create new booking
   *
   * @param {object} state
   *  : current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {void}
   */
  submitForm: ({ commit }, payload) => {
    commit('setLoading', true)
    let data = convertToFormData(payload)
    let token = localStorage.getItem("app_access_token");
    let url = app_api + gep('bookings',apiPrefix)
    let params = {
      'include': 'customer,video_booking'

    };

    return axios.post(url,
      data,
      {
        headers:
          {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
          },
        // params,
      }
    ).then(response => {
      commit('setBooking', response.data)
      commit('setLoading', false)
      return response;
    }).catch(error => {
      console.log(error)
      // Notification.error({
      //     title: i18n.t('error'),
      //     message: i18n.t('n_went_wrong'),
      // });
      commit('setLoading', false)
      return error;

    })
    // commit('setLoading', true)
    // return APICaller({
    //   method: 'post',
    //   endpoint: gep('bookings', apiPrefix),
    //   data: payload,
    //   isDataRaw: true,
    // }).then(response => {
    //   commit('setBooking', response.data)
    //   commit('setLoading', false)
    //   return response;
    // }).catch(error => {
    //   console.log(error)
    //   // Notification.error({
    //   //     title: i18n.t('error'),
    //   //     message: i18n.t('n_went_wrong'),
    //   // });
    //   commit('setLoading', false)
    //   return error;

    // })
  },

  /**
   * update emrgency state
   *
   * @param {object} state
   *  : current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {void}
   */
  enableEmergency: ({ commit }, payload) => {
    let val = !payload
    commit('setIsImmediate', (val) ? 1 : 0);
  },

  updateGender: ({ commit }, payload) => {
    commit('setGender', payload);
  },
  /**
   * reset booking form state
   *
   * @param {object} state
   *  : current state of the vuex Object
   *
   * @return {void}
   */
  resetBookingForm: ({ commit }) => {
    let form = {
      company_id: '',
      department_id: '',
      customer_id: '',
      language_id: '',
      date: '',
      time: '',
      duration: '',
      type: '',
      to_language_id: [],
      from_language_id: '',
      translator_levels: [],
      specific_translators: [],
      exclude_translators: [],
      assigned_translator: '',
      convey: {
        message: '',
        phone: '',
        email: '',
        files: [],
        additional_info: ''
      },
      convey_files: [],
      'video_provider': 'jitsi',
      'video_url': '',
      deliver_with: 'email',
      is_express: 0,
      is_stamped: 0,
      is_confidential: 0,
      descriptions: '',
      language_pair_levels: ['deluxe', 'non-certified', 'certified'],
      text_translation_files: [],


    }
    commit('setIsImmediate', 0)
    commit('resetForm', form)
  },

  /**
   * Get Translators Levels
   */

  async fetchTranslatorLevels({ commit }) {
    return await APICaller({
      method: 'get',
      endpoint: gep('translator-levels?all&filter[visible]=true', apiPrefix),
    }).then((response) => {
      commit('setTranslatorLevels', response.data);
    });
  },

  //get all customers
  fetchCustomers: ({ commit }, params) => {
    if (isEmpty(localStorage.getItem('customers'))) {
      let url = 'customers?all&include=customer_data,customer_notification_types';
      commit('setCustomerListLoading', true)
      return APICaller({
        method: 'get',
        endpoint: gep(url, apiPrefix),
      }).then((response) => {
        commit('setCustomersList', response.data)
        commit('setCustomerListLoading', false)
      }).catch(error => {
        Notification.error({
          title: i18n.t('error'),
          message: i18n.t('n_went_wrong'),
        });
        commit('setCustomerListLoading', false)
      });
    }
  },

  //get all translators
  fetchTranslators: ({ commit }) => {
    return APICaller({
      method: 'get',
      endpoint: gep('translators?all', apiPrefix),
    }).then((response) => {
      commit('setTranslatorsList', response.data)
    });
  },

  //get all departments
  fetchDepartments: ({ commit }, params) => {
    commit('setDepartmentsListLoading', true)
    // let url = (params) ? 'departments?all&' + params : 'departments?all'
    params = (params) ? params : { all: true }
    return APICaller({
      method: 'get',
      endpoint: gep('departments', apiPrefix),
      params
    }).then((response) => {
      commit('setDepartmentsList', response.data)
      commit('setDepartmentsListLoading', false)

      return response.data.data.departments
    }).catch(error => {
      // Notification.error({
      //   title: i18n.t('error'),
      //   message: i18n.t('n_went_wrong'),
      // });
      commit('setDepartmentsListLoading', false)
      return error
    });
  },


  browse: (context, payload) => {

    context.commit('setLoading', true)
    let target = context.state.api.browse;
    // Set the target endpoint.
    let method = target.method;
    let endpoint = target.path;
    let params = extendObjParams(target.params, payload);

    return APICaller({ method, endpoint, params })
      .then((r) => {
        context.commit('setList', r.data);

        context.commit('setLoading', false)
      });

  },

  //validate booking
  bookingsValidate(context, payload) {

    context.commit('setLoading', true)
    return APICaller({
      method: 'post',
      endpoint: gep('bookings/validate', apiPrefix),
      data: payload,
      isDataRaw: true
    }).then((response) => {
      let data = response.data
      let bookers = _.map(data.confirmation_fills, 'booker_name');
      let staff_name = _.map(data.confirmation_fills, 'staff_name');
      let reference = _.map(data.confirmation_fills, 'reference');
      let emails = _.map(data.confirmation_fills, 'email');
      let address = payload.address;



      context.commit('setBookerNameList', _.compact(bookers, ""));
      context.commit('setReferenceList', _.compact(reference, ""));
      context.commit('setStaffNameList', _.compact(staff_name, ""));
      context.commit('setEmailList', _.compact(emails, ""));

      context.dispatch('updateCustomerFills', context.state.customer);
      if (!isEmpty(payload.address)) {
        context.commit('setAddress', address);
        if (!isEmpty(data.booking_exists)) {
          if (!isEmpty(data.booking_exists.city)) {
            context.commit('setCity', data.booking_exists.city);
          }
        }
      }
      context.commit('setBookingField', data.booking_field);
      context.commit('setAvailableTranslators', data.total_translators);
      context.commit('setLoading', false)
      return response
    }).catch(error => {
      Notification.error({
        title: i18n.t('error'),
        message: error.response.data.data,
      });
      context.commit('setLoading', false)
      return error
    })
  },

  //assign booking customer state
  assignCustomer: (context, payload) => {

    let customers = context.getters['getCustomersList']
    let customer = customers.find(x => { return x.id === payload });

    console.log(customer)
    if (isEmpty(customer)) {
      context.dispatch('fetchCustomer', payload)
    } else {

      context.commit('setCustomer', customer);
      context.commit('setBookingCustomer', customer)
    }


    // // if (!isEmpty(customer.customer_data)) {
    // //   context.dispatch('getCustomerDepartment', customer.customer_data.department_id);
    // // }

  },

  /**
   * @param {Object} context
   * @param {Object} payload
   * @param {int} payload.customerId
   * @return {Promise}
   */
  fetchAndAssignCustomer(context, payload = {}) {
    if (isNil(payload.customerId)) {
      throw new Error('Missing required value payload.customerId');
    }

    return APICaller({
      method: 'get',
      endpoint: `/api/v3/core/customers/${payload.customerId}`,
      onSuccessCallback(r) {
        const customer = r.data.data.customer;
        context.commit('setCustomer', customer);
        context.commit('setBookingCustomer', customer);

        if (!isEmpty(customer.customer_data)) {
          context.dispatch('getCustomerDepartment', customer.customer_data.department_id);
        }
      }
    });
  },

  getCustomerDepartment: (context, payload) => {
    return APICaller({
      method: 'get',
      endpoint: gep(`departments/${payload}`, apiPrefix),
    }).then(response => {
      context.commit('setCustomerDepartment', response.data)

    })
  },

  fetchCustomer: (context, payload) => {
    return APICaller({
      method: 'get',
      endpoint: gep(`customers/${payload}`, apiPrefix),
    }).then((response) => {
      let data = response.data.data.customer;

      context.commit('setCustomer', data)

      if (!isEmpty(data.customer_data)) {
        context.dispatch('getCustomerDepartment', data.customer_data.department_id);
      }
    });
  },
  fetchAssignedTranslator: (context, payload) => {

    return APICaller({
      method: 'get',
      endpoint: gep(`translators/${payload}`, apiPrefix),
    }).then((response) => {
      let data = response.data.data.translator;
      context.commit('setAssignedTranslator', data)
    });
  },

  changeTimeFormat: (context, payload) => {
    if (!isEmpty(payload)) {
      context.commit('setTimeFormat', moment(payload, 'HH:mm:ss').format('HH:mm:ss'))
    }
  },

  initBookingEdit(context, payload) {
    context.dispatch('getBooking', payload);
  },

  /**
   * Action to update the booking entry.
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  updateBooking: (context, payload) => {
    context.commit('setLoading', true);

    let isForceSpecificTranslatorValue = !isNil(payload.is_force_specific_translator_value)
      && payload.is_force_specific_translator_value === true;

    if (!isForceSpecificTranslatorValue && isEmpty(payload.specific_translators) && has(payload,'specific_translators')) {
      payload.specific_translators = '';
    }

    if (isEmpty(payload.exclude_translators) && has(payload,'exclude_translators')) {
      payload.exclude_translators = '';
    }

    let isForceAlternativeLanguagesValue = !isNil(payload.is_force_alternative_languages_value)
      && payload.is_force_alternative_languages_value === true;

    if (!isForceAlternativeLanguagesValue && isEmpty(payload.alternative_languages) && has(payload,'alternative_languages')) {
      payload.alternative_languages = '';
    }

    if(!isEmpty(payload.messages)) {
      if (payload.messages.message === '' && payload.messages.files.length === 0) {
        delete payload.messages;
      }
    }

    // payload = _.omitBy(payload,_.isNil)
    // let obj = {...payload}
    //   Object.keys(obj).forEach(function(key) {
    //     if(obj[key] === null) {
    //         obj[key] = '';
    //     }
    //     if(obj[key] === true){
    //       obj[key] ='1'
    //     }
    //     if(obj[key] === false){
    //       obj[key] ='0'
    //     }
    // })
    if (!payload.hasOwnProperty('accepted_via')) {
      payload.accepted_via = 'dt-admin-web';
    }
    payload.last_updated_by = JSON.parse(localStorage.getItem('app_user_data')).id
    const bookingId = payload.id;
    const apiOpts = {
      method: 'PUT',
      endpoint: `${API.BOOKINGS}/${bookingId}`,
      data: payload,
      isDataRaw: true
    };

    return APICaller(apiOpts)
      .then(() => {
        notifSuccess({ message: 'Booking Updated' });
        context.dispatch('getBooking', bookingId);
      })
      .catch((e) => {
        notifError({ message: e });
      })
      .finally(() => {
        context.commit('setLoading', false);
      });
  },

  /**
   * @param context
   * @param payload
   * @returns {Promise}
   */
  assignTranslator: (context, payload) => {
    context.commit('setLoading', true);

    return new Promise((resolve, reject) => {
      APICaller({
        method: 'post',
        endpoint: gep(`bookings/${payload.id}/accept`, apiPrefix),
        data: payload,
        isDataRaw: true
      })
        .then(response => {
          notifSuccess({ message: 'Translator assigned' });
          context.commit('setBooking', response.data);
          resolve(response);
          // window.location.reload();
        })
        .catch((e) => {
          notifError({ message: e });
          reject(e);
          // return JSON.parse(e.request.response);
          //context.commit('setReasons',error.request.response.data.reasons)
        })
        .finally(() => {
          context.commit('setLoading', false);
        });
    });
  },

  fetchHistoriesList: (context, payload) => {
    context.commit('setLoading', true);

    return APICaller({
      method: 'get',
      endpoint: gep(`activities?all&filter[subject_id]=${payload}&filter[subject_type]=Booking`, apiPrefix),
    }).then(response => {
      context.commit('setHistories', response.data)
      context.commit('setLoading', false);
    });

  },

  fetchHistoriesListAudit: (context, payload) => {
    context.commit('setLoading', true);

    let relationships = context.state.model_relationships.join(',');

    let params = {
      'relationships' : relationships,
      'auditable_type' : 'bookings',
      'auditable_id': payload.id,
      'from_date' : payload.from_date,
      'to_date' : payload.to_date,
      'per_page' : 1000,
      'with' : 'users',
      'all' : true,
    };
    return APICaller({
      method: 'get',
      endpoint: gep(`audits`, apiPrefix),
      params
    }).then(response => {
      store.dispatch('booking/fetchBookingTraveltimePromise', payload)
        .then((items) => {

          let fetchTravelTimeAudits = function (items, ord, after, tta) {
            if (typeof items[ord] !== 'undefined') {
              APICaller({
                method: 'get',
                endpoint: gep(`travel-time-audits?auditable_id=${items[ord].id}&auditable_type=travel_calculations&all=1`, apiPrefix),
              }).then(r => {
                for (let item of r.data.data.items) {
                  tta.push(item);
                }
                fetchTravelTimeAudits(items, ++ord, after, tta);
              }).catch((err) => {
                console.log(err);
                fetchTravelTimeAudits(items, ++ord, after, tta);
              });
            } else {
              after(tta);
            }
          };

          fetchTravelTimeAudits(items, 0, function (tta) {
            let data = response.data;
            for (let item of tta) {
              data.data.items.push(item);
            }
            context.commit('setHistoriesAudit', data);
            context.commit('setHistoriesAuditBackup', data);
            context.commit('setLoading', false);
          }, []);
        }).catch(e => {
        context.commit('setHistoriesAudit', response.data.audits);
        context.commit('setHistoriesAuditBackup', response.data.audits);
        context.commit('setLoading', false);
      });

      return response;
    });

    /*return APICaller({
      method: 'get',
      endpoint: gep(`activities?all&filter[subject_id]=${payload}&filter[subject_type]=Booking`, apiPrefix),
    }).then(response => {
      context.commit('setHistories', response.data);
      context.commit('setLoading', false);
      return response;
    });*/

  },


  // Get activities by subject type Notification
  fetchNotifHistoryList: (context, payload) => {
    context.commit('setLoading', true);

    return APICaller({
      method: 'get',
      endpoint: gep(`bookings/${payload}/resend-log-messages`, apiPrefix),
    });

  },

  fetchNotifHistoryListAll: (context, payload) => {
    context.commit('setLoading', true);

    return APICaller({
      method: 'get',
      endpoint: gep(`bookings/${payload}/resend-log-messages-for-all-and-individuals`, apiPrefix),
    });

  },

  fetchExpiringBookings: (context, payload) => {
    let hours = (payload.hours) ? payload.hours : 168; // default 1 week expiring time
    context.commit('setLoading', true);
    return APICaller({
      method: 'get',
      endpoint: gep(`bookings?filter[expire_within_hours]=${hours}&[status_id]=1&sort=expiry`, apiPrefix)
    }).then(response => {
      context.commit('setExpiringBookings', response.data)
      // context.commit('setList', response.data);
      context.commit('setLoading', false);

      return response;
    }).catch(error => {
      console.log(error)
    })
  },

  updateBookingListDataStatusById: ({ commit }, payload) => {
    commit('setBookingStatusById', payload)
  },

  async addComment({ commit }, payload) {
    commit('setLoading', true);
    let response = await APICaller({
      method: 'post',
      endpoint: gep(`bookings/${payload.booking_id}/comments`, apiPrefix),
      data: {
        comment: payload.comment
      }
    })
    if (response) {
      commit('setLoading', false);
      // console.log(response)
      // commit('setBooking', response.data)
      commit('setBookingComments', response.data)
      Notification.success({
        title: i18n.t('success'),
        message: 'Booking Comment Added',
      });
    } else {
      Notification.error({
        title: i18n.t('error'),
        message: 'Something happen, please try again',
      });
    }
  },
  async deleteBookingComment({ commit }, payload) {
    commit('setLoading', true);
    let response = await APICaller({
      method: 'delete',
      endpoint: gep(`booking-comments/${payload}`, apiPrefix)
    }).then(response => {
      commit('setLoading', false);
    }).catch(error => {
      commit('setLoading', false);
    });
    return response;
  },

  async updateBookingComment({ commit }, payload) {
    commit('setLoading', true);
    let response = await APICaller({
      method: 'put',
      endpoint: gep(`booking-comments/${payload.id}`, apiPrefix),
      data: payload,
      isDataRaw: true
    }).then(response => {
      commit('setLoading', false);
    }).catch(error => {
      Notification.error({
        title: i18n.t('error'),
        message: 'Something happen, please try again',
      });
      commit('setLoading', false);
    })
    return response;
  },

  updateCustomerFills: ({ state, commit }, customer) => {

    commit('resetConfirmationFills')
    let email_default = [];
    if (!isEmpty(state.emailList)) {
      email_default = _.last(state.emailList)
    }

    let notification_types = customer.customer_notification_types
    if (isEmpty(notification_types)) {
      state.confirmationFills.address = isEmpty(customer.customer_data) ? null : customer.customer_data.address;
      state.confirmationFills.city = isEmpty(customer.customer_data) ? '' : customer.customer_data.city;
      if (isEmpty(state.confirmationFills.city)) {
        state.confirmationFills.changeAddress = true
      }
      state.confirmationFills.email = (isEmpty(email_default)) ? [customer.email] : [email_default];
      state.confirmationFills.notification_types.push({
        type: 'email', recipient: state.confirmationFills.email[0]
      });
    } else {
      let fax = _.find(notification_types, ['notification_type', 'fax']);
      let email = _.find(notification_types, ['notification_type', 'email']);
      let sms = _.find(notification_types, ['notification_type', 'sms']);
      if (isEmpty(state.confirmationFills.city)) {
        state.confirmationFills.changeAddress = true
      }


      state.confirmationFills.address = isEmpty(customer.customer_data) ? null : customer.customer_data.address;
      state.confirmationFills.city = isEmpty(customer.customer_data) ? null : customer.customer_data.city;
      state.confirmationFills.town = isEmpty(customer.customer_data) ? null : customer.customer_data.city;
      state.confirmationFills.email = (isEmpty(email_default)) ? [customer.email] : [email_default];
      state.confirmationFills.fax = isEmpty(fax) && isEmpty(fax.default_recipient) ? null : fax.default_recipient;
      state.confirmationFills.faxEnabled = !isEmpty(fax) && !isEmpty(fax.default_recipient) ? true : false;
      state.confirmationFills.sms = isEmpty(sms) ? '' : sms.default_recipient;

      if (!isEmpty(fax) && !isEmpty(fax.default_recipient)) {
        state.confirmationFills.notification_types.push({
          type: 'fax', recipient: state.confirmationFills.fax
        });
      }

      state.confirmationFills.notification_types.push({
        type: 'email', recipient: state.confirmationFills.email[0]
      });
      if (!isEmpty(sms)) {
        state.confirmationFills.notification_types.push({
          type: 'sms', recipient: state.confirmationFills.sms
        });
      }
    }


  },

  /**
   * @param {object} context
   * @param {object} payload
   * @returns {Promise}
   */
  withdrawTranslator(context, payload = {}) {
    if (window._.isNil(payload.booking_id)) {
      throw new Error('Missing required value payload.booking_id');
    }

    let opts = {
      method: 'post',
      endpoint: `${API.BOOKINGS}/${payload.booking_id}/withdraw`
    };

    opts.data = window._.isNil(payload.data) ? {} : payload.data;
    opts.data.cancelled_via = 'dt-admin-web';

    context.commit('setLoading', true);

    return new Promise((resolve, reject) => {
      APICaller(opts)
        .then((r) => {
          notifSuccess({ message: 'Translator withdrawed' });
          resolve(r);
        })
        .catch((e) => {
          notifError({ message: e });
          reject(e);
        })
        .finally(() => {
          context.commit('setLoading', false);
        });
    });
  },

  recreateBatches(context, payload) {
    APICaller({
      method: 'post',
      endpoint: gep(`bookings/${payload}/recreate-batches`, apiPrefix),
    })
      .then(response => {
        Notification({
          type: 'success',
          message: `Batches recreated`
        })
        console.log(response)
        // location.reload();
      })
      .catch(err => {
        let message = 'Error!, Something happen'
        if (err.response.status === 500) {
          message = `Error ${err.response}`
        }
        Notification({
          type: 'error',
          message: message
        })
      })
  },
  cancelBooking(context, payload) {
    let data = {
      cancelled_via: 'dt-admin-web'
    }
    APICaller({
      method: 'post',
      endpoint: gep(`bookings/${payload}/cancel`, apiPrefix),
      data: data
    })
      .then(response => {
        Notification({
          type: 'success',
          message: `Booking Canceled`
        })
        location.reload();
      })
      .catch(err => {
        let message = 'Error!, Something happen'
        if (err.response && err.response.status === 500) {
          message = `Error!! ${err.response}`
        } else if (err.response && err.response.data) {
          message = `Error!! ${err.response.data.data}`
        }
        Notification({
          type: 'error',
          message: message
        })
      })
  },
  getAllUsers(context) {
    return APICaller({
      method: 'get',
      endpoint: gep('users?all', apiPrefix)
    }).then(resp => {
      context.commit('setUsersAll', resp.data.data.users)

    }).catch(err => {
      console.log(err)
    })
  },

  // filterCustomers(context,payload){
  //   return APICaller({
  //     method: 'get',
  //     endpoint: gep(`customers?all&filter[name]=${payload}`, apiPrefix)
  //   }).then(resp => {

  //     return resp.data.data.customers

  //   }).catch(err => {
  //     console.log(err)
  //   })
  // },
  fetchBookingTraveltime(context, payload) {
    return APICaller({
      method: 'get',
      endpoint: gep(`travel-calculation?filter[entity_type]=bookings&filter[entity_id]=${payload}&all`, apiPrefix)
    }).then(resp => {
      console.log(resp.data.data)
      context.commit('setBookingTraveltime', resp.data.data.items)
    }).catch(err => {
      let message = 'Error!, Something happen'
      if (err.response.status === 500) {
        message = `Error ${err.response}`
      }
      Notification({
        type: 'error',
        message: message
      })
    })
  },
  fetchBookingTraveltimePromise(context, payload) {
    return APICaller({
      method: 'get',
      endpoint: gep(`travel-calculation?filter[entity_type]=bookings&filter[entity_id]=${payload}&all`, apiPrefix)
    }).then(resp => {
      context.commit('setBookingTraveltime', resp.data.data.items);
      return resp.data.data.items;
    });
  },
  updateBookingTraveltime({ dispatch }, payload) {
    return APICaller({
      method: 'put',
      endpoint: gep(`travel-calculation/${payload.id}`, apiPrefix),
      data: payload,
      isDataRaw: true
    }).then(resp => {
      console.log(resp)
      dispatch('fetchBookingTraveltime', payload.entity_id)
      Notification.success({
        title: i18n.t('success'),
        message: 'Travel time updated',
      });
    }).catch(err => {
      let message = 'Error!, Something happen'
      if (err.response.status === 500) {
        message = `Error ${err.response}`
      }
      Notification({
        type: 'error',
        message: message
      })
    })
  },
  addBookingTraveltime({ dispatch }, payload) {
    return APICaller({
      method: 'post',
      endpoint: gep(`travel-calculation`, apiPrefix),
      data: payload,
      isDataRaw: true
    }).then(resp => {
      dispatch('fetchBookingTraveltime', payload.entity_id)
      Notification.success({
        title: i18n.t('success'),
        message: 'Travel time added',
      });
    }).catch(err => {
      let message = 'Error!, Something happen'
      if (err.response.status === 500) {
        message = `Error ${err.response}`
      }
      Notification({
        type: 'error',
        message: message
      })
    })

  },
  filterCustomers({ commit }, params) {
    return APICaller({
      method: 'get',
      endpoint: gep('customers', apiPrefix),
      params
    }).then(resp => {
      return resp.data.data.customers
    }).catch(err => {
      console.log(err)
      return err
    })
  },
  filterTranslators({ commit }, params) {
    return APICaller({
      method: 'get',
      endpoint: gep('translators', apiPrefix),
      params
    }).then(resp => {
      return resp.data.data.translators
    }).catch(err => {
      console.log(err)
      return err
    })
  },
  fetchCompanies({ commit }, params) {
    return APICaller({
      method: 'get',
      endpoint: gep('companies', apiPrefix),
      params
    }).then(resp => {
      commit('setCompaniesList', resp.data)
      return resp.data.data.companies
    }).catch(err => {
      console.log(err)
      return err
    })
  },
  fetchMunicipalities({ commit }, params) {
    return APICaller({
      method: 'get',
      endpoint: gep('municipalities', apiPrefix),
      params
    }).then(resp => {
      console.log(resp)
      return resp.data.data.municipalities
    }).catch(err => {
      console.log(err)
      return err
    })
  },

  /**
   * Generic action for getting booking details because
   * the getBooking in the action is coupled with a form.
   *
   * @params {object} context - current Vuex scope.
   * @params {object} payload - contains useful values.
   * @params {object} payload.booking_id - ID of the target booking.
   * @params {object} payload.params - query params that will be sent to API.
   * @returns {*}
   */
  getBookingGeneric(context, payload = {}) {

    // Make sure payload.booking_id is provided.
    if (window._.isNil(payload.booking_id)) {
      console.error('Booking ID does not exist!');
      return;
    }

    // Set defaults
    payload.params = sd(payload.params, {});

    // Set axios opts.
    const opts = {
      endpoint: `/api/v3/bookings/${payload.booking_id}`,
      method: 'GET',
      params: payload.params
    };

    // Call the API.
    return APICaller(opts)
      .then((r) => r)
      .catch((e) => {
        console.error(e);
        return e;
      });
  },

  /**
   * Action for getting the bookings of a specific translator.
   *
   * @params {object} context - current Vuex scope.
   * @params {object} payload - contains useful values.
   * @params {object} payload.translator_id - ID of the target translator.
   * @params {object} payload.params - query params that will be sent to API.
   * @returns {*}
   */
  getTranslatorBookings(context, payload = {}) {

    // Make sure payload.booking_id is provided.
    if (window._.isNil(payload.translator_id)) {
      console.error('Translator ID does not exist!');
      return;
    }

    // Set defaults
    payload.params = sd(payload.params, {});

    // Set the target translator_id in the param.
    let params = {};
    params['filter[assigned_translator]'] = payload.translator_id;
    params['sort'] = '-created_at';

    window._.extend(params, payload.params);

    // Set axios opts.
    const opts = {
      endpoint: `/api/v3/bookings`,
      method: 'GET',
      params
    };

    // Call the API.
    return APICaller(opts)
      .then((r) => r)
      .catch((e) => {
        console.error(e);
        return e;
      });
  },

  fetchLateWithdrawal({ commit }, params) {
    return APICaller({
      method: 'get',
      endpoint: gep('late-withdrawal-attempts', apiPrefix),
      params
    }).then(resp => {
      commit('setLateWithdrawal', resp.data.data.late_withdrawal_attempts)
      // commit('setLateWithdrawalList',resp.data.data.late_withdrawal_attempts)
      return resp.data.data.late_withdrawal_attempts
    }).catch(err => {
      return err
    })
  },
  fetchTowns({ commit }, params) {
    return APICaller({
      method: 'get',
      endpoint: '/api/v3/towns?all=1',
      params
    }).then(resp => {
      console.log(resp)
      commit('setTownList', resp.data.data.towns)
      // commit('setLateWithdrawalList',resp.data.data.late_withdrawal_attempts)
      return resp.data.data.towns
    }).catch(err => {
      console.log(err)
      return err
    })
  },

  postTranslatorLate(context, payload) {
    return APICaller({
      method: 'post',
      endpoint: gep(`booking/${payload.id}/translator/late`, apiPrefix),
      data: payload
    }).then(resp => {
      return resp
    }).catch(err => {
      return err
    })
  },
  postTranslatorNotPickup(context, payload) {
    return APICaller({
      method: 'post',
      endpoint: gep(`bookings/${payload.id}/not-pickup`, apiPrefix),
      data: payload
    }).then(resp => {
      return resp
    }).catch(err => {
      return err
    })
  },

  createOrUpdateBookingLock({ commit }, payload) {
    return APICaller({
      method: 'post',
      endpoint: gep(`bookings/${payload.booking_id}/locking`, apiPrefix),
      data: payload
    }).then(resp => {
      console.log(resp)
      commit('setLockedBooking', resp)
      commit('setLockedBookingUser', payload)
      return resp
    }).catch(err => {
      console.log(err)
      return err
    })
  },
  expireBooking(context, payload) {
    APICaller({
      method: 'put',
      endpoint: gep(`bookings/${payload}/expire`, apiPrefix),
    })
      .then(response => {
        Notification({
          type: 'success',
          message: `Booking Expired`
        })
        // location.reload();
      })
      .catch(err => {
        let message = 'Error!, Something happen'
        if (err.response && err.response.status === 500) {
          message = `Error!! ${err.response}`
        } else if (err.response && err.response.data) {
          message = `Error!! ${err.response.data.data}`
        }
        Notification({
          type: 'error',
          message: message
        })
      })
  },
  /**
   * Post method for submitting user data to firebolt associated with socket_id
   * @param {*} context
   * @param {*} param1
   */
  postUserDataToFirebolt(context, { socket_id, data }) {
    axios.post('https://api-gateway.digitaltolk.se/api/v3/websocket-connection', {
      socket_id, data
    })
  },
  /**
   * Adding user in table which landed inside the booking
   * @param {*} context
   * @param {*} payload
   */
  addUserInsideBooking(context, payload) {
    return APICaller({
      method: 'post',
      endpoint: gep(`user-in-booking/${payload.data.booking_id}`, apiPrefix),
      data: payload.data
    }).then(resp => {
      return resp
    }).catch(err => {
      return err
    })
  },
  /**
   * Delete user from the table when user left the booking edit page
   * @param {*} context
   * @param {*} payload
   */
  deleteUserInsideBooking(context, payload) {
    return APICaller({
      method: 'delete',
      endpoint: gep(`user-in-booking`, apiPrefix),
      data: payload,
      isDataRaw: true
    }).then(resp => {
      return resp
    }).catch(err => {
      return err
    })
  },
  /**
   * Getting list of users who are inside the booking
   * @param {*} context
   * @param {*} payload
   */
  getUserInsideBooking(context, payload) {
    return APICaller({
      method: 'get',
      endpoint: gep(`user-in-booking/${payload}`, apiPrefix),
    }).then(resp => {
      context.commit('setUserInsideBooking', resp.data.data.items)
      return resp
    }).catch(err => {
      return err
    })
  },
  /**
 * Action for getting the bookings blocked of a specific user.
 *
 * @params {object} context - current Vuex scope.
 * @params {object} payload - contains useful values.
 * @params {object} payload.translator_id - ID of the target translator.
 * @params {object} payload.params - query params that will be sent to API.
 * @returns {*}
 */
  getUserBlockingBookings({ commit }, payload = {}) {
    if (window._.isNil(payload.user_id)) {
      console.error('User ID does not exist!');
      return;
    }
    // Set defaults
    payload.params = sd(payload.params, {});

    // Set the target user_id in the param.
    let params = {};
    params['include'] = 'booking';
    params['filter[user_id]'] = payload.user_id;
    params['filter[is_locked]'] = payload.is_locked;
    params['sort'] = '-created_at';
    params['per_page'] = 5;

    window._.extend(params, payload.params);

    const opts = {
      endpoint: `/api/v3/locked-bookings`,
      method: 'GET',
      params
    };

    APICaller(opts)
      .then(response => {
        commit('setUserLockedBookings', response.data.data.items)
        return response.data.data.items
      })
      .catch((e) => {
        console.error(e);
        return e;
      });
  },

  deleteConveyFile(context, payload) {
    const opts = {
      endpoint: `/api/v3/convey/${payload.id}/file/${payload.file_id}`,
      method: 'DELETE',
    };

    return APICaller(opts)
      .then(response => {
        console.log(response)
        return response
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

  },
  /**
   * Delete the entry from locked booking when user leaves
   *
   * @param {*} context
   * @param {*} {user_id,booking_id}
   */
  unlockTheBookingWhenUserLeaves({ commit }, payload) {
    return APICaller({
      method: 'delete',
      endpoint: gep(`locked-bookings`, apiPrefix),
      data: payload,
      isDataRaw: true
    }).then(resp => {
      commit('removeUserFromLockedBooking', payload)
      removeUserFromLockedBooking
      return resp
    }).catch(err => {
      return err
    })
  },

  deleteTextTranslationFile(context, payload) {
    const opts = {
      endpoint: `/api/v3/text-translations/${payload.id}/delete-file/${payload.file_id}`,
      method: 'DELETE',
    };

    return APICaller(opts)
      .then(response => {
        console.log(response)
        return response
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

  },

  deleteMessageFile(context, payload) {
    const opts = {
      endpoint: `/api/v3/booking-messages/${payload.booking_message_id}/delete-file/${payload.file_id}`,
      method: 'POST',
    };

    return APICaller(opts)
      .then(response => {
        return response
      })
      .catch((e) => {
        console.error(e);
        return e;
      });
  },

  updateBookingMessage(context, payload) {
    const opts = {
      endpoint: `/api/v3/booking-messages/${payload.id}`,
      method: 'PUT',
      data: payload,
      isDataRaw: true
    };

    // let token = localStorage.getItem("app_access_token");
    // let url = gep(`booking-messages/${payload.id}`,apiPrefix)
    return APICaller(opts).then(response => {
      Notification({
        type: 'success',
        message: `Booking Message updated`
      });
      return response
    })
      .catch((e) => {
        console.error(e);
        return e;
      });
  },
  addBookingMessage(context, payload) {
    const opts = {
      endpoint: `/api/v3/booking-messages`,
      method: 'post',
      data: payload,
      isDataRaw: true
    };

    return APICaller(opts)
      .then(response => {
        Notification({
          type: 'success',
          message: `Booking Message Added`
        });
        return response
      })
      .catch((e) => {
        console.error(e);
        return e;
      });
  },
  deleteBatches(context, payload) {
    APICaller({
      method: 'delete',
      endpoint: gep(`bookings/${payload.id}/batches`, apiPrefix),
    })
      .then(response => {
        Notification({
          type: 'success',
          message: `Booking Batches deleted`
        })
      })
      .catch(err => {
        let message = 'Error!, Something happen'
        if (err.response.status === 500) {
          message = `Error ${err.response}`
        }
        Notification({
          type: 'error',
          message: message
        })
      })
  },


}
