<template>
  <div class="app-page blogs-page">
    <page-header :title="$t('notification_page')" />

    <div class="app-container">
      <div class="app-wrapper">
        <div class="app-row">
          <div class="app-col full-width">
            <el-row>
              <el-col :span="2" :offset="20">
                <router-link
                  tag="button"
                  to="/messaging-centre/notification/add"
                  @click.stop.prevent
                  title="Create"
                  class="el-button el-button--success"
                >
                  <i class="fa fa-plus"></i> {{$t('create')}}
                </router-link>
              </el-col>
            </el-row>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane :label="$t('all_post')" name="first"></el-tab-pane>
              <el-tab-pane :label="$t('published')" name="second"></el-tab-pane>
              <el-tab-pane :label="$t('draft')" name="third"></el-tab-pane>
            </el-tabs>
            <notification-list :data="getNotificationList" :activeName="activeName"></notification-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { setTimeout } from "timers";
export default {
  data() {
    return {
      activeName: "first"
    };
  },
  computed: {
    ...mapGetters("notificationCentre", {
      getNotificationList: "getNotificationList"
    })
  },
  methods: {
    ...mapActions("notificationCentre", {
      getNotification: "getNotification"
    }),
    preparePage() {
      this.getNotification({ filter: this.activeName });
    },
    handleClick(tab, event) {
      this.getNotification({ filter: this.activeName });
    }
  },
  mounted() {
    this.preparePage();
  }
};
</script>
