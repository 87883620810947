/*
|--------------------------------------------------------------------------
| Financial > Price Template > actions
|--------------------------------------------------------------------------
| Partial file for actions object related to the scope.
*/

'use strict';

import {extendObjParams, mapEndpoint} from '~/js/helpers/Common';
import APICaller from '../../../helpers/APICaller';
import {Notification} from 'element-ui';
import i18n from '../../../i18n';
import {uploadRequestData} from "~/js/helpers/File";

const baseEndpoint = '/api/v3/financial-templates';
const baseEndpointWithID = `${baseEndpoint}/{id}`;

export default {

  /**
   * Load All Items with no pagination
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} [payload]
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  browseAsOptions (context, payload = {}) {

    const method = 'GET';
    const endpoint = baseEndpoint;
    let params = {
      all: true,
      'fields[templates]': 'id,name'
    };

    return APICaller({method, endpoint, params})
      .then((r) => {
        context.commit('setListAsOptions', r.data);
      });

  }, // End of browseAsOptions() method

  /**
   * Load All Items
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} [payload]
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  browse (context, payload = {}) {

    const method = 'GET';
    const endpoint = baseEndpoint;
    let params = extendObjParams({sort: '-id'}, payload);

    return APICaller({method, endpoint, params})
      .then((r) => {
        context.commit('setList', r.data);
      });

  }, // End of browse() method

  /**
   * Get an Item according to the given ID
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} [payload]
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  read (context, payload = {}) {

    context.commit('resetForm');
    context.commit('resetPayload');
    context.commit('financialInvoice/resetSettings', {}, {root: true});
    context.commit('financialSalary/resetSettings', {}, {root: true});

    const method = 'GET';
    const endpoint = mapEndpoint(baseEndpointWithID, payload);
    const params = {
      include: [
        'invoice_setting',
        'salary_setting'
      ].join(',')
    };

    return APICaller({method, endpoint, params})
      .then((r) => {
        let raw = r.data.data; // Store into container so varname will be shorter.
        let data = raw[Object.keys(raw)[0]]; // Get the first member of the object.

        context.dispatch('setValues', data);

        return r;
      });

  }, // End of read() method

  /**
   * Update an item.
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  edit (context, payload) {

    const method = 'PUT';
    const endpoint = mapEndpoint(baseEndpointWithID, payload);

    context.dispatch('setSettingsPayload');

    const data = context.state.payload;
    const isDataRaw = true;

    const file = new Blob(
      [JSON.stringify(data)],
      {type: "application/json"}
    );

    return new Promise((resolve, reject) => {
      return uploadRequestData(file).then((cloudReponse) => {

        return APICaller({method, endpoint, data: {request_file: cloudReponse.cloud_details.key}, isDataRaw})
          .then((r) => {

            context.dispatch('browse');
            context.dispatch('browseAsOptions');

            context.commit('resetPayload');
            context.commit('resetSettingsPayload');

            Notification.success({
              title: i18n.t('success'),
              message: i18n.t('n_entry_edited')
            });

            resolve(r);
          })
          .catch((e) => {
            Notification.error({
              title: i18n.t('error'),
              message: i18n.t('n_went_wrong')
            });
            reject(e);
          });
      });
    });
  }, // End of edit() method

  /**
   * Create an item.
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  add (context, payload) {

    const method = 'POST';
    const endpoint = baseEndpoint;

    context.dispatch('setSettingsPayload');

    const data = context.state.payload;
    const isDataRaw = true;

    const file = new Blob(
      [JSON.stringify(data)],
      {type: "application/json"}
    );

    return new Promise((resolve, reject) => {
      return uploadRequestData(file).then((cloudReponse) => {

        return APICaller({method, endpoint, data: {request_file: cloudReponse.cloud_details.key}, isDataRaw})
          .then((r) => {

            context.dispatch('browse');
            context.dispatch('browseAsOptions');

            context.commit('resetPayload');
            context.commit('resetSettingsPayload');

            Notification.success({
              title: i18n.t('success'),
              message: i18n.t('n_entry_added')
            });

            resolve(r);
          })
          .catch((e) => {
            Notification.error({
              title: i18n.t('error'),
              message: i18n.t('n_went_wrong')
            });

            reject(e);
          });
      });
    });


  }, // End of add() method

  /**
   * Remove an item.
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  delete (context, payload) {

    const method = 'DELETE';
    const endpoint = mapEndpoint(baseEndpointWithID, payload);

    return APICaller({method, endpoint})
      .then(() => {

        context.dispatch('browse');
        context.dispatch('browseAsOptions');

        Notification.success({
          title: i18n.t('success'),
          message: i18n.t('n_entry_deleted')
        });
      })
      .catch(() => {
        Notification.error({
          title: i18n.t('error'),
          message: i18n.t('n_went_wrong')
        });
      });

  }, // End of delete() method

  /**
   * Action to for duplicating the template.
   *
   * @param {Object} context - Vuex store context.
   * @param {Object} payload - Object the contains necessary values.
   * @return {Promise|any}
   */
  duplicate (context, payload) {
    const method = 'POST';
    const endpoint = mapEndpoint(`${baseEndpointWithID}/duplicate`, payload);

    return APICaller({method, endpoint})
      .then(() => {
        context.dispatch('browse');
        context.dispatch('browseAsOptions');

        Notification.success({
          title: i18n.t('success'),
          message: 'Duplicated Successfully'
        });
      })
      .catch((e) => {
        const message = window._.isNil(e.response.data)
                        ? i18n.t('n_went_wrong')
                        : e.response.data;

        Notification.error({
          title: i18n.t('error'),
          message
        });
      });
  },

  /**
   * Helper Method to set state.payload
   *
   * @param  {object} context - the context of $store
   * @return {void}
   */
  setSettingsPayload (context) {

    const getPayload = (target) => {
      const uTarget = window._.upperFirst(target);
      const ns = `financial${uTarget}`;
      console.log(context);
      context.dispatch(`${ns}/setSettingsPayload`, {}, {root: true});
      return _.cloneDeep(
        context.rootState[ns].settingsPayload
      );
    };

    const invoiceSettingsPayload = getPayload('invoice');
    const salarySettingsPayload = getPayload('salary');
    const priceTemplateForm = _.cloneDeep(context.rootState.financialPriceTemplate.form);

    context.state.payload = {...priceTemplateForm};
    context.state.payload['invoice_setting'] = invoiceSettingsPayload;
    context.state.payload['salary_setting'] = salarySettingsPayload;
  },

  /**
   * Method to reset the settings payload for settings members (e.g. invoices, salaries... etc.)
   *
   * @param  {object} context - current Vuex context.
   * @returns {void}
   */
  resetSettingsPayload (context) {

    const resetPayload = (target) => {
      const uTarget = window._.upperFirst(target);
      const ns = `financial${uTarget}`;
      context.commit(`${ns}/resetSettingsPayload`, {}, {root: true});
    };

    resetPayload('invoice');
    resetPayload('salary');
  },

  /**
   * Helper Method to set state.payload
   *
   * @param  {object} context - the context of $store.
   * @param  {object} payload - values necessary inside the method.
   * @return {void}
   */
  setValues (context, payload) {

    context.commit('setForm', {
      name: payload.name,
      template_for: payload.template_for
    });

    setSettings('invoice', payload, context);
    setSettings('salary', payload, context);
  },

  /**
   * Action to apply the template content into the price forms.
   *
   * @param  {object} context - the context of $store.
   * @param  {object} payload - values necessary inside the method.
   * @return {Promise}
   */
  applyTemplate (context, payload) {

    const method = 'GET';
    const endpoint = mapEndpoint(baseEndpointWithID, payload);
    const params = {
      include: [
        'invoice_setting',
        'salary_setting'
      ].join(',')
    };

    return APICaller({method, endpoint, params})
      .then((r) => {
        let raw = r.data.data; // Store into container so varname will be shorter.
        let data = raw[Object.keys(raw)[0]]; // Get the first member of the object.

        setSettings('invoice', data, context);
        setSettings('salary', data, context);

        return r;
      });

  }

};

/**
 * Helper method for Setting the values in the setting form.
 *
 * @param {string} target - varies between invoice | salary
 * @param {object} src - the source for the values.
 * @param context - the context of the current store scope.
 * @return {void}
 */
function setSettings (target, src, context) {

  const uTarget = window._.upperFirst(target);
  if (!_.isNil(src[`${target}_setting`])) {

    context.dispatch(
      `financial${uTarget}/setSettings`,
      src[`${target}_setting`],
      {root: true}
    );

  } else {
    console.error(`Financial Price Templates: payload.${target}_setting should exist!`);
  }
}
