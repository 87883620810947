/*
|--------------------------------------------------------------------------
| Store > Financial > Adjustment > Props
|--------------------------------------------------------------------------
| File to define the properties that will be used for the forms.
*/

'use strict';

export const adjustment = {
  booking_id: '',
  booking_method_fee: '',
  emergency_fee: '',
  flat_rate: '',
  max_travel_distance: '',
  max_travel_time: '',
  minimum_fee: '',
  minimum_inconvenience_fee: '',
  pay_booking_method_fee: false,
  pay_emergency_fee: false,
  pay_travel_distance: false,
  pay_travel_time: false,
  succeeding_fee: '',
  succeeding_inconvenience_fee: '',
  translator_level_id: '',
  travel_distance: '',
  travel_distance_fee_per_km: '',
  travel_time: '',
  travel_time_fee_per_hour: ''
}