<template>
  <el-dialog
    :title="$t('apply_batch_template_settings')"
    :visible="dialogVisible"
    width="50%"
    :modal-append-to-body="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="changeFunction">
    <el-select v-model="selectedRow" :placeholder="$t('select')">
    <el-option
      v-for="item in gridData"
      :key="item.id"
      :label="item.name"
      :value="item.settings">
    </el-option>
    </el-select>
     <custom-company-list :handleSelectionChange="handleSelectionChange" :user="'company'" :tableData="company" />
    <span slot="footer" class="dialog-footer">
    <el-button size="mini" type="success" @click="importBatch">{{$t('apply_settings')}}</el-button>
  </span>
</el-dialog>
</template>

<script>
    import { mapActions } from "vuex";
    import APICaller from "../../../js/helpers/APICaller";
    import {Notification} from "element-ui";
    export default {
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        props: ['dialogVisible', 'importBatchTemplate', 'changeFunction', 'company'],
        data() {
            return {
                selectedRow: '',
                gridData: [],
                payload: {},
                multipleSelection: [],
            }
        },
        mounted() {
            this.getbatches().then((r) => {
              this.gridData = r.data
              console.log(this.gridData)
            })
          //  const id = this.$route.params.id;
          // let target = 'municipalities';
          // let apiPrefix = window.app_api_prefix_3;
          // APICaller({
          //   method: 'get',
          //   endpoint: gep(target + `/${id}`, apiPrefix),
          //   params: { include: 'companies' },
          //   data: {},
          // }).then(r => {
          //   console.log(this.tableData = r.data.data.municipality.companies)
          // });

        },
        methods: {
          ...mapActions('bookingBatches', {
            getbatches: 'getBatchTemplateList',

            }),
          ...mapActions('batchesSettings', {
            updateBatch: 'updateBatchSetting'
          }),
           importBatch() {
             if(this.selectedRow && this.multipleSelection) {
            this.multipleSelection.forEach(element => {
            // this.payload.id = element.id;
            // this.payload.settingsthis.selectedRow;
            this.payload = {
              'id' : element.id,
              'settings' : {
              'settings' : this.selectedRow
              }
            }
            // console.log(this.payload);
            this.updateBatch(this.payload).then((r) => {
                Notification.success({
                  title: 'Success',
                  message: 'Company '+element.name+ ' Batch Setting Updated',
                });
            }).catch((r) => {
               Notification.error({
                  title: 'Error',
                  message: 'Something Went Wrong',
            });
            });
            })
             }
          },
           handleSelectionChange(val) {
            this.multipleSelection = val;
        }
        },
    }
</script>

<style>
.el-dialog__footer {
  text-align: center !important;
}
</style>
