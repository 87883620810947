<template>
  <div>
    <div class="feedback-details bg-grey" >
      <template v-if="iIssue.type.type === 'low_feedback'">
        <template v-if="reviewType == 2">
          <customer-feedback :feedback="iIssue.booking.feedback"/>
        </template>
        <template v-if="reviewType == 3">
          <translator-feedback :feedback="iIssue.booking.feedback"/>
        </template>
        <hr>
      </template>

      <template v-if="iIssue.type.type === 'follow_up'">
        <h3><strong>{{$t('follow_up')}}-  {{iIssue.entities.title}}</strong></h3>
        <div class="el-tabs__content">
          <h4 class="blue-text">{{$t('description')}}</h4>
          <p> {{iIssue.entities.description}} </p>
        </div>
        <hr>
      </template>

      <div class="feedback-add-comment">
        <strong>{{iList.length}} {{$t('comments')}}</strong>
        <el-button class="add-comment-button" type="text" @click="handleAddComment"> <i class="fa fa-plus"></i> {{$t('add_comment')}}</el-button>
      </div>
      <template v-if="showAddComment">
        <div style="background-color:#fff">
          <wysiwyg v-model="form.comment" :aria-placeholder="$t('enter_text')" :placeholder="$t('enter_text')"/>
        </div>
        <div class="text-right">
          <el-button v-if="!isUpdate" size="mini" type="primary" @click="addComment">
          {{$t('save')}}
          </el-button>

          <el-button v-else size="mini" type="primary" @click="updateComment">
          {{$t('update')}}
          </el-button>
        </div>
      </template>
    </div>
    
    <el-row class="issue-comment-list" :gutter="10" v-for="(item,i) in list" :key="i">
      <ElCol :md="2">
        <i class="fa fa-user-circle fa-3x"></i>
      </ElCol>
      <ElCol :md="20">
        <el-row>
          <el-col :md="14"><span class="blue-text">{{item.user.name}}</span> <small>{{item.user.email}}</small></el-col>
          <el-col :md="7">{{item.created_at}}</el-col>
          <el-col :md="2" :offset="1"><span @click="handleEditComment(item)"><i class="fa fa-pencil"></i></span> <span @click="handleDeleteComment(item)"><i class="fa fa-trash"></i></span></el-col>
        </el-row>
        
        <br />
        <ul>
          <li v-for="i in item.assigned_users" :key="i.id">{{$t('assigned_to')}}: {{i.name}}</li>
        </ul>

        <p v-html="item.comment"></p>
      </ElCol>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import CustomerFeedback from "./CustomerFeedback";
import TranslatorFeedback from "./TranslatorFeedback";
export default {
  /*
  |--------------------------------------------------------------------------
  | Component > components
  |--------------------------------------------------------------------------
  */
  components: {
    CustomerFeedback,
    TranslatorFeedback
  },
  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    issue:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      showAddComment: false,
      form: {
        comment: ''
      },
      isUpdate: false,
      commentId: ''
    };
  },
  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapState('auth', {
      user: 'userData'
    }),
    ...mapGetters('bookingIssue', {
      reviewType: 'getReviewType'
    }),
    iList() {
      return this.list;
    },
    iIssue(){
      return this.issue
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */

  methods: {
    ...mapActions('bookingIssue', {
      postComment: 'postComment',
      editComment: 'editComment',
      deleteComment: 'deleteComment'
    }),
    addComment() {
      let payload = {
        booking_issue_id: this.$route.params.id,
        comment: this.form.comment
      };
      this.postComment(payload).then(() => {
        this.form.comment = '';
        this.showAddComment = !this.showAddComment;
      });
    },
    updateComment() {
      let payload = {
        id: this.commentId,
        comment: this.form.comment,
        booking_issue_id: this.$route.params.id,
      };
      this.editComment(payload).then(() => {
        this.form.comment = '';
        this.showAddComment = !this.showAddComment;
        this.commentId = '';
        this.isUpdate = false
      });
    },
    handleAddComment(){
      this.isUpdate = false
      this.showAddComment = !this.showAddComment
    },
    handleEditComment(item){
      this.isUpdate = true
      this.showAddComment = !this.showAddComment
      this.form.comment = item.comment
      this.commentId = item.id
    },
    handleDeleteComment(item){
      let payload = {
        id: item.id,
        booking_issue_id: this.$route.params.id,
      };

      this.$confirm(this.$t('delete_comment_warning'), this.$t('warning'), {
        confirmButtonText: 'YES',
        cancelButtonText: 'NO',
        type: 'warning'
      }).then(() => {
        this.deleteComment(payload);
      }).catch(() => {
        // silent
      });
      
    }
  }
};
</script>

<style>
</style>
