/*
|--------------------------------------------------------------------------
| Financial > Shared > methods > partsLoop
|--------------------------------------------------------------------------
| Partial file as a reusable method for it's purpose.
*/

'use strict';

/**
 * Method to return the parts loop collection.
 * @param {object} state
 * @return {array}
 */
export default function(state) {
  let result = [];
  let min = 1;
  let step = state.settings.business_rules.parts_step;
  let max = state.settings.business_rules.parts_max;
  const isValid = (v) => !_.isNil(v) && v !== '' && v != 0;

  if ( isValid(step) && isValid(max) && step < max ) {
    step = parseFloat(step);
    max = parseFloat(max);

    while (min < max) {
      min = min + step;
      result.push(Math.round(min * 10) / 10); // Round up to 1 decimal
    }
  }

  return result;
}