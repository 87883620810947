<template>
    <div class="app-fields department-main-fields">

        <!-- Municipalty -->
        <el-form-item :label="$t('municipality')" class="label-short">
                      <span style="text-decoration: underline;" slot="label" @click="handleMunicipalityClick">{{$t('municipality')}}</span>
            <el-input v-model="iValue.company.municipality.name"  :disabled="true"/>
        </el-form-item>

        <!-- Company -->
        <el-form-item :id="genContainerId('company_id',formElId)"
                      :label="$t('company')" class="label-short" >
                         <span style="text-decoration: underline;" slot="label" @click="handleCompanyClick">{{$t('company')}}</span>
            <el-select :id="genFieldId('company_id',formElId)"
                       v-model="iValue.company_id" clearable filterable :placeholder="$t('select_company')">
                <el-option v-for="o in companyOpts"
                           :key="o.key"
                           :value="o.key"
                           :label="o.label"/>
            </el-select>
        </el-form-item>

        <!-- Name -->
        <el-form-item :id="genContainerId('name',formElId)"
                      :label="$t('name')" class="label-short">
            <el-input :id="genFieldId('name',formElId)"
                      v-model="iValue.name" :placeholder="$t('input_department_name')"/>
        </el-form-item>

        <!-- Reference Person -->
          <el-form-item :id="genContainerId('reference_person',formElId)"
                      :label="$t('reference_person')" class="label-short">
            <el-input :id="genFieldId('reference_person',formElId)"
                      v-model="iValue.reference_person"
                      :placeholder="$t('input_department_reference_person')"/>
        </el-form-item>

        <!-- Cost Place -->
          <el-form-item :id="genContainerId('cost_place',formElId)"
                      :label="$t('cost_place')" class="label-short">
            <el-input :id="genFieldId('cost_place',formElId)"
                      v-model="iValue.cost_place"
                      :placeholder="$t('input_department_cost_place')"/>
        </el-form-item>

         <!-- Blacklisted Users -->
                  <el-form-item :id="genContainerId('town_id',formElId)"
                          :label="$t('blacklisted_translators')" class="label-short">
                <el-select :id="genFieldId('blacklisted_users',formElId)"
                           v-model="value.blacklisted_users"
                           @remove-tag="removeBlackListedTranslator"
                           @focus="handleClickBlacklistedTranslatorSelection"
                           :placeholder="$t('please_select_blacklisted_translator')" multiple filterable
                >
                    <el-option v-for="(v) in translatorOpts"
                               :key="v.id"
                               :value="v.id"
                               :value-key="v.id"
                               :label="v.name">
                        {{v.name}}
                    </el-option>
                </el-select>
                  </el-form-item>
        <!-- End Black Listed Users -->

      <email-setting-fields v-model="iValue"></email-setting-fields>


      <!-- Unified Number Settings -->
        <unified-number-setting v-model="iValue"></unified-number-setting>
        <!-- End of Unified Number Setting -->
        <el-form-item :id="genContainerId('notes',formElId)"
                      :label="$t('notes')" class="label-short">
            <el-input :id="genFieldId('notes',formElId)"
                      type="textarea"
                      :autosize="{ minRows: 4, maxRows: 7}"
                      v-model="iValue.notes"
                      :placeholder="$t('input_notes_about _department')"/>

        </el-form-item>
       <department-search v-model="iValue.invoicing_department_id"/>

  <!-- Confidential -->
  <br>
        <div v-if="shouldShowConfidential">
            <h3>{{$t('is_confidential')}}</h3>
           <div class="app-splitter two-cols">
              <el-form-item :id="genContainerId('is_confidential',formElId)"
                          :label="$t('is_confidential')" class="label-switch">
                            <span slot="label" ><p>{{$t('protected_identity')}}</p></span>
                <el-switch :id="genFieldId('is_protected_identity',formElId)"
                           v-model="iValue.is_protected_identity" :active-value="1" :inactive-value="0" @change="handleInfoChange(iValue.is_protected_identity)"/>

            </el-form-item>
            <el-form-item :id="genContainerId('', formElId)"
              :label="$t('senesitive_information')" class="label-switch">
               <span slot="label" ><p>{{$t('senesitive_information')}}</p></span>
                <el-switch :id="genFieldId('is_sensitive_info',formElId)"
                v-model="iValue.is_sensitive_info" :active-value="1" :inactive-value="0" @change="handleInfoChange(iValue.is_sensitive_info)"/>
            </el-form-item>
           </div>

                    <div v-if="iValue.is_sensitive_info || iValue.is_protected_identity == 1">
            <el-form-item :id="genContainerId('comments',formElId)"
                          label="" class="label-block">
                <el-input :id="genFieldId('comments' ,formElId)"
                          v-model="iValue.confidential_message"
                          type="textarea"
                          :rows="2"/>
            </el-form-item>
                    </div>
        </div>

            <!-- End Confidential -->



    </div> <!-- /.app-fields department-main-fields -->
</template>

<script>
    import {mapGetters, mapActions, mapState} from "vuex";
    import {populate} from "../../../../js/helpers/Vuex";
    import {isEmpty} from "../../../../js/helpers/Common";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            formElId: {
                type: String,
                default: 'department_compose_form',
            },

            value: {
                type: Object,
                default() {
                    return {
                        company_id: '',
                        name: '',
                        email: '',
                        head_user_id: '',
                        company: {
                          municipality: {
                              name: '',
                              id: ''
                          }
                        }
                    }
                }
            },
            shouldShowConfidential: {
                type: Boolean,
                default: true,
            }

        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            // Map company opts getter to be used for dropdown selection.
            ...mapGetters('company', {
                companyOpts: 'listAsOptionsPaired',
            }),

            ...mapGetters('user', ['selectedCompany']),
            ...mapGetters({
                translatorOpts: 'translator/listAsOptions',
            }),

            ...mapState('department', {
                blacklisted_users_array : 'compose'
            }),


            /**
             * Interface for the v-model of this component.
             */
            iValue: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                },
            },

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            // Map company opts list loader.
            ...mapActions('company', {
                loadCompanyOpts: 'browseAsOptions',
            }),

            ...mapActions({
                loadTranslatorOpts: 'translator/browseAsOptions',
                deleteBlackListedUser: 'blacklistedUsers/deleteBlackListedUser'
            }),

            handleCompanyClick() {
                this.$router.push({name:'company-edit',params:{id:this.iValue.company_id}})
            },
            handleMunicipalityClick() {
                this.$router.push({name:'municipality.edit',params:{id:this.iValue.company.municipality.id}})
            },
            handleInfoChange(value) {
                if(value == 0) {
                    this.iValue.confidential_message = '';
                }
            },
            handleClickBlacklistedTranslatorSelection () {
                populate(this, 'loadTranslatorOpts', 'translatorOpts', {
                ref: 'Translator Options Listing'
                });
            },
            removeBlackListedTranslator(v) {
                let obj = this.blacklisted_users_array.blacklisted_users.filter((id) =>  {
                   return id.translator_id === v
                })
                if(obj) this.deleteBlackListedUser(obj[0].id);
            }

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {

            // Populate the options for company to be used for dropdown selection.
            populate(this, 'loadCompanyOpts', 'companyOpts', {
                ref: 'Company Options'
            });

            if  (!isEmpty(this.selectedCompany)) {
                this.iValue.company_id = this.selectedCompany
            }

        }, // End of Component > mounted

    } // End of export default
</script>
