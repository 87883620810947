<template>
  <div>
    Clearing Verbiages values...
  </div>
</template>

<script>
  export default {
    mounted () {
      this.$store.dispatch('verbiages/clear');
      this.$router.push({path: '/'});
    }
  };
</script>
