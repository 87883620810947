<template>
    <div class="app-page department-compose-page">

        <page-header :title="$t('department')" :back-enabled="true"/>

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">

                    <div class="app-col full-width">
                        <div class="top-controls">
                            <el-row>
                                <el-col :span="24" class="text-right">
                                    <el-button type="primary" @click="handleRedirectAudits">
                                        {{$t('audit_logs')}}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="content-body">

                            <!-- Form for the Main Information -->
                            <el-form :id="mainFormElId" :ref="mainFormElId">

                                <department-main-fields v-model="form.main"/>

                                <el-form-item>
                                    <el-button type="primary" :id="genFieldId('save',mainFormElId)"
                                               @click="handleSubmit">
                                        {{$t(mode)}}
                                    </el-button>
                                </el-form-item>

                            </el-form>
                            <custom-table :user="'customer'" :tableData="tableData" />
                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                    <div class="app-col full-width">
                        <departement-invoice-credits-list></departement-invoice-credits-list>
                    </div> <!-- /.app-col -->

                    <div class="app-col half-width">
                        <div class="content-body">

                            <el-form :id="additionalInfoFormElId"
                                     :ref="additionalInfoFormElId">

                                <h3>{{$t('additional_information')}}</h3>
                                <el-collapse class="panel-tabbed">

                                    <el-collapse-item :title="$t('contact_information')" name="contact_info" v-if="false">
                                        <sector-contact-info-fields v-model="form.contact"
                                                                    :form-el-id="additionalInfoFormElId"/>
                                    </el-collapse-item>

                                    <el-collapse-item :title="$t('address')" name="address">
                                        <sector-address-fields v-model="form.address"
                                                               :form-el-id="additionalInfoFormElId"/>
                                    </el-collapse-item>

                                    <el-collapse-item :title="$t('settings')" name="settings">
                                        <sector-settings-fields v-model="form.sector_settings"
                                                                sector-type="department"
                                                                :form-el-id="additionalInfoFormElId"/>
                                    </el-collapse-item>

                                </el-collapse>

                                <!-- Save -->
                                <el-form-item :id="genContainerId('save',additionalInfoFormElId)">
                                    <el-button type="primary" :id="genFieldId('save',additionalInfoFormElId)"
                                               @click="handleSubmit">
                                        {{$t('save')}}
                                    </el-button>
                                </el-form-item>

                            </el-form>


                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                    <div class="app-col half-width">
                        <div class="content-body">

                            <h3>{{$t('financial_information')}}</h3>
                            <el-collapse class="panel-tabbed">

                                <el-collapse-item :title="$t('settings')" name="financial_settings">
                                    <sector-financial-settings-form/>
                                </el-collapse-item>

                            </el-collapse>


                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page department-compose-page -->
</template>

<script>
    import APICaller from "../../../../js/helpers/APICaller";
    import { gep } from "../../../../js/helpers/Vuex";
    import {mapState, mapGetters, mapMutations, mapActions} from "vuex";
    import {checkBreadMode} from "../../../../js/helpers/Route";
    import DepartementInvoiceCreditsList from '~/components/lists/sectors/department/DepartementInvoiceCreditsList';

    let moduleTarget = 'department';

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > imported components
        |--------------------------------------------------------------------------
        */
        components: {
            DepartementInvoiceCreditsList,
        },

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                tableData: [],
                mainFormElId: 'department_main_form',
                additionalInfoFormElId: 'department_additional_info_form',
            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapState(moduleTarget, {
                form: 'compose',
            }),

            ...mapGetters(moduleTarget, {
                entryData: 'itemData',
            }),

            /**
             * Value to determine the current mode whether it's add or edit.
             *
             * @return {string}
             */
            mode() {
                return checkBreadMode(this);
            },

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            ...mapActions(moduleTarget, {
                addEntry: 'add',
                editEntry: 'edit',
                readEntry: 'read',
            }),

            ...mapMutations(moduleTarget, {
                resetForm: 'resetCompose',
            }),

            /**
             * Handler when the Back button has been clicked.
             */
            handleClickBack() {
                window.history.back()
            }, // End of handleClickBack() method

            /**
             * Handle when the form has been submitted.
             *
             * @return {void}
             */
            handleSubmit() {

                // Set the action to be made.
                let action = this.mode + 'Entry';
                let payload = {}; // Set container for payload.

                // If the current compose mode is EDIT, then
                // add the ID of the target into the payload.
                if (this.mode == 'edit') {
                    payload.id = this.$route.params.id;
                }

                this[action](payload).then(r =>{
                    if(r.status == 201){
                        this.$router.push({name:'department.edit', params:{id:r.data.data.department.id}})
                    }
                });

            }, // End of handleSubmit() method

            /**
             * Handle when redirecting to the department's audit logs
             *
             * @return {void}
             */
            handleRedirectAudits() {
                this.$router.push({
                    name:'audits',
                    params:{
                        id:this.$route.params.id,
                        auditable:'departments'
                    },
                    query:{
                        relationships: 'headUser,blacklisted_users'
                    }
                })
            }

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
          watch: {
                    "$route.params.id"(val) {
                    let includes = [];
                    // call the method which loads your initial state
                  this.readEntry({id: this.$route.params.id});
            }
        },
        mounted() {
            const id = this.$route.params.id;
            let target = 'departments';
            let apiPrefix = window.app_api_prefix_3;
            APICaller({
                method: 'get',
                endpoint: gep(target + `/${id}`, apiPrefix),
                params: { include: 'customers' },
                data: {},
            }).then(r => {
                this.tableData = r.data.data.department.customers
            });

            this.resetForm();

            // If the current mode is on edit, fetch the property values for the targeted entry.
            if (this.mode == 'edit') {
                this.readEntry({id: this.$route.params.id});
            }

        }, // End of Component > mounted

    } // End of export default

</script>
