<template>

  <div class="app-list booking-issue-list">

                <custom-table-two
                  :table-data="listData"
                  :extra-cols="listCols"
                  :hasDetailsCol="false">

                    <!-- Row for Top Controls -->
                    <template slot="topControls">
                      <el-row>
                         <el-button type="primary" icon="fa fa-plus" @click="handleAdd">
                            {{$t('add_item')}}
                        </el-button>
                      </el-row>

                    </template>

                    <!-- Column for action buttons -->
                    <template slot="actionsColumn" slot-scope="scope">
                        <el-button class="circle" type="success" :title="$t('edit')" @click="handleEdit(scope.row)">
                            <span class="fa fa-pencil"></span>
                        </el-button>
                        <el-button class="circle" type="danger" :title="$t('delete')" @click="handleDelete(scope.row)">
                            <span class="fa fa-trash"></span>
                        </el-button>
                    </template>
                </custom-table-two>

                <el-dialog :title="$t('booking_issue_settings')" :visible.sync="formVisible"  :append-to-body="true" :modal-append-to-body="true">
                  <booking-issue-setting-form :mode="mode" @clicked="onCloseForm"></booking-issue-setting-form>
                </el-dialog>
            </div> <!-- /.app-col -->

</template>

<script>
import {mapState, mapGetters,mapActions} from 'vuex'
export default {

  data(){
    const _this=this;
    return {
      formVisible:false,
      mode:'add',
      deadlineOpt: [
        {value: `${_this.$t('today')}`, key: 'today'},
        {value: `1 ${_this.$t('tfv_day')}`, key: '1 day'},
        {value: `3 ${_this.$t('tfv_days')}`, key: '3 days'},
        {value: `7 ${_this.$t('tfv_days')}`, key: '7 days'},
        {value: `30 ${_this.$t('tfv_days')}`, key: '30 days'},
        {value: `${_this.$t('end_of_month')}`, key: 'end_of_month'}
      ],
      listCols:[
        {
          label: _this.$t('type'),
          prop: 'type',
          width: '',
          className: 'type-col',
          mutator(v,row){
            console.log(v)
            return _this.$t(v);
          },
        },
        {
          label: _this.$t('deadline'),
          prop: 'deadline',
          width: '',
          className: 'deadline-col',
          mutator(v,row){
            return _this.deadlineOpt.find(r => r.key == v).value;
          },
        }
      ]
    }
  },
  computed:{
    ...mapGetters('bookingIssueSetting',{listData:'listData'})
  },
  methods:{
    ...mapActions('bookingIssueSetting', {

      readEntry: 'read',
      deleteEntry: 'delete'

    }),
    handleAdd(){
      this.formVisible = true
    },
    handleEdit(row){
       this.readEntry({id: row.id});

      this.formVisible = true
      this.mode = 'edit'
    },
    handleDelete(row){
      this.deleteEntry({id: row.id});
    },
    onCloseForm(e){
      console.log(e)
      this.formVisible = false
    }
  }
}
</script>

<style>

</style>
