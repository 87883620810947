<template>
    <div class="app-display dashboard-company-report-display">

        <div class="company-selector-container">
            <p>{{$t('select_company')}}</p>
            <el-select :id="genFieldId('company_selector')"
                       v-model="company">
                <el-option :label="$t('all')" value="all"/>
                <el-option :label="$t('Company 1')" value="company1"/>
            </el-select>
        </div> <!-- /.company-selector-container -->

        <div class="reports-container app-splitter two-cols">

            <div>
                <table cellpadding="0" cellspacing="0">
                    <tbody>
                    <tr v-for="x in col1">
                        <td class="cell-label"><span class="cell">{{ x.label }}</span></td>
                        <td class="cell-value"><span class="cell">{{ x.value }}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <table cellpadding="0" cellspacing="0">
                    <tbody>
                    <tr v-for="x in col2">
                        <td class="cell-label"><span class="cell">{{ x.label }}</span></td>
                        <td class="cell-value"><span class="cell">{{ x.value }}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div> <!-- /.reports-container app-splitter two-cols -->

    </div> <!-- /.app-display dashboard-company-report-display -->
</template>

<script>
    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {

                company: '',

                col1: [
                    {
                        label: 'Company 1',
                        value: 32,
                    },
                    {
                        label: 'Company 2',
                        value: 21,
                    },
                    {
                        label: 'Company 3',
                        value: 19,
                    },
                    {
                        label: 'Company 4',
                        value: 17,
                    },
                    {
                        label: 'Company 5',
                        value: 13,
                    },
                ],

                col2: [
                    {
                        label: 'Company 6',
                        value: 12,
                    },
                    {
                        label: 'Company 7',
                        value: 9,
                    },
                    {
                        label: 'Company 8',
                        value: 4,
                    },
                    {
                        label: 'Company 9',
                        value: 3,
                    },
                    {
                        label: 'Company 10',
                        value: 2,
                    },
                ]

            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {}, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {}, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted(){} // End of Component > mounted

    } // End of export default
</script>
