<template>
  <div :class="getBem(blockClass)" v-if="isBatchesValid" v-loading="isLoading">

    <table>
      <!-- -------------------------------------------------- -->
      <!-- Headers -->
      <!-- -------------------------------------------------- -->
      <thead>

      <tr>
        <!-- HEADER : Select All Checkbox -->
        <th :class="[
          getBem(blockClass, 'header-select-checkbox'),
          getBem(blockClass, 'col-first'),
        ]">
          <el-checkbox v-model="selectAllBatches" @change="handleChangeSelectAll"/>
        </th>

        <!-- HEADER : Translator Info -->
        <th :class="[
          getBem(blockClass, 'header-translator-info'),
          getBem(blockClass, 'col-translator-info'),
        ]">
          <span>{{$t('translator_info')}}</span>
        </th>

        <!-- HEADER : Language -->
        <th :class="[
          getBem(blockClass, 'header-language'),
          getBem(blockClass, 'col-language'),
        ]">
          <span>{{$t('language')}}</span>
        </th>

        <!-- HEADER : Booking Type -->
        <th :class="[
          getBem(blockClass, 'header-booking-type'),
          getBem(blockClass, 'col-booking-type'),
        ]">
          <span>{{$t('tfv_booking_type')}}</span>
        </th>

        <!-- HEADER : Level -->
        <th :class="[
          getBem(blockClass, 'header-level'),
          getBem(blockClass, 'col-level'),
        ]">
          <translator-batch-list-table-header-title
            :sort.sync="iSort"
            :label="$t('tfv_translator_level')"
            col="translator_level_id"
            :is-sortable="true"
            @click-sort="handleClickSort"
          />
        </th>

        <!-- HEADER : Rejected -->
        <th :class="[
          getBem(blockClass, 'header-rejected'),
          getBem(blockClass, 'col-rejected'),
        ]">
          <translator-batch-list-table-header-title
            :sort.sync="iSort"
            :label="$t('rejected')"
            col="rejected_at"
            :is-sortable="true"
            @click-sort="handleClickSort"
          />
        </th>

        <!-- HEADER : Last Job Accepted -->
        <th :class="[
          getBem(blockClass, 'header-last-job-accepted'),
          getBem(blockClass, 'col-last-job-accepted'),
        ]">
          <translator-batch-list-table-header-title
            :sort.sync="iSort"
            :label="$t('last_job_accepted')"
            col="last_booking_due"
            :is-sortable="true"
            @click-sort="handleClickSort"
          />
        </th>

        <!-- HEADER : Jobs in 30 days -->
        <th :class="[
          getBem(blockClass, 'header-jobs-in-30-days'),
          getBem(blockClass, 'col-jobs-in-30-days'),
        ]">
          <translator-batch-list-table-header-title
            :sort.sync="iSort"
            :label="$t('jobs_30_days')"
            col="previous_bookings_count"
            :is-sortable="true"
            @click-sort="handleClickSort"
          />
        </th>

        <!-- HEADER : Distance -->
        <th :class="[
          getBem(blockClass, 'header-distance'),
          getBem(blockClass, 'col-distance'),
        ]">
          <translator-batch-list-table-header-title
            :sort.sync="iSort"
            col="temp_travel_distance_car"
            :is-sortable="true"
            @click-sort="handleClickSort"
          >
            <template #label>
              {{$t('distance')}} <br>
              ({{$t('car')}})
            </template>
          </translator-batch-list-table-header-title>
        </th>

        <!-- HEADER : Time (Car) -->
        <th :class="[
          getBem(blockClass, 'header-time-car'),
          getBem(blockClass, 'col-time-car'),
        ]">
          <translator-batch-list-table-header-title
            :sort.sync="iSort"
            col="temp_travel_time_car"
            :is-sortable="true"
            @click-sort="handleClickSort"
          >
            <template #label>
              {{$t('time')}} <br>
              ({{$t('car')}})
            </template>
          </translator-batch-list-table-header-title>
        </th>

        <!-- HEADER : Distance (Public) -->
        <th :class="[
          getBem(blockClass, 'header-distance-public'),
          getBem(blockClass, 'col-distance-public'),
        ]">
          <translator-batch-list-table-header-title
            :sort.sync="iSort"
            col="temp_travel_distance_public"
            :is-sortable="true"
            @click-sort="handleClickSort"
          >
            <template #label>
              {{$t('distance')}} <br>
              ({{$t('public')}})
            </template>
          </translator-batch-list-table-header-title>
        </th>

        <!-- HEADER : Time (Public) -->
        <th :class="[
          getBem(blockClass, 'header-time-public'),
          getBem(blockClass, 'col-time-public'),
        ]">
          <translator-batch-list-table-header-title
            :sort.sync="iSort"
            col="temp_travel_time_public"
            :is-sortable="true"
            @click-sort="handleClickSort"
          >
            <template #label>
              {{$t('time')}} <br>
              ({{$t('public')}})
            </template>
          </translator-batch-list-table-header-title>
        </th>

        <!-- HEADER : Sent At -->
        <th :class="[
          getBem(blockClass, 'header-send-at'),
          getBem(blockClass, 'col-send-at'),
        ]">
          <translator-batch-list-table-header-title
            :sort.sync="iSort"
            :label="$t('sent_at')"
            col="sent_at"
            :is-sortable="true"
            @click-sort="handleClickSort"
          />
        </th>

        <!-- HEADER : Action -->
        <th :class="[
          getBem(blockClass, 'header-action'),
          getBem(blockClass, 'col-action'),
        ]">
          {{$t('action')}}
        </th>

      </tr>
      </thead>

      <!-- -------------------------------------------------- -->
      <!-- Body -->
      <!-- -------------------------------------------------- -->
      <tbody :class="getBem(blockClass, 'batch-item')" v-for="batch in batches">

      <!-- Batch Item Header -->
      <tr :class="getBem(blockClass, 'batch-item-header')">

        <!-- Select Checkbox -->
        <td :class="[
              getBem(blockClass, 'batch-item-header-checkbox'),
              getBem(blockClass, 'col-first')
            ]"
        >

          <el-checkbox v-model="selectedBatches" :label="batch.id"><span></span></el-checkbox>
        </td>

        <!-- Batch ID -->
        <td :class="getBem(blockClass, 'batch-item-header-id')">
          <span title="Batch ID">
            #{{ batch.id }}
          </span>
        </td>

        <!-- Will Send At / Non sendable Flag -->
        <td :colspan="totalColumns - 2" :class="getBem(blockClass, 'batch-item-header-will-send-at')">
          <template v-if="isBatchSendable(batch)">
              <span :class="getBem(blockClass, 'batch-item-header-will-send-at-label')">
                {{ $t('will_send_at') }}
              </span>
            <span :class="getBem(blockClass, 'batch-item-header-will-send-at-value')">
                {{ batch.will_send_at }}
              </span>
          </template>
          <template v-else>
              <span :class="getBem(blockClass, 'batch-item-header-non-sendable')">
                <span class="fa fa-exclamation-triangle"></span>
                <span>{{ $t('non_sendable') }}</span>
                <span> : {{ getNonSendableReason(batch) }}</span>
              </span>
          </template>
        </td>
      </tr>

      <template v-for="entry in batch.entries">
        <!-- Batch Item Entry -->
        <tr :class="getBem(blockClass, 'batch-item-entry')">

          <!-- COLUMN : Icon Actions -->
          <td :class="[
            getBem(blockClass, 'batch-item-entry-col-first'),
            getBem(blockClass, 'col-first'),
          ]">
            <!--<a
              :class="getBem(blockClass, 'batch-item-not-picked-up')"
              href="#"
              @click.prevent="handleClickShowCall(entry)"
            >
              <span class="fa fa-phone"></span>
            </a>
            <a
              :class="getBem(blockClass, 'batch-item-entry-sms-help-desk')"
              href="#"
              @click.prevent="handleClickSmsHelpDesk(entry)"
            >
              <span class="fa fa-commenting"></span>
            </a>-->
            <a
              :class="getBem(blockClass, 'batch-item-entry-resend-notification')"
              href="#"
              @click.prevent="handleClickResendNotification(entry)"
            >
              <span class="fa fa-bell"></span>
            </a>
          </td>

          <!-- COLUMN : Translator Info -->
          <td :class="[
            getBem(blockClass, 'batch-item-entry-translator-info'),
            getBem(blockClass, 'col-translator-info'),
          ]">
            <div
              :class="getBem(blockClass, 'batch-item-entry-translator-info-name')"
              title="Translator Name"
            >
              <a
                :href="`/#/user/profile/${entry.translator.id}?type=3`"
                target="_blank"
              >
                {{ entry.translator.name }}
              </a>
            </div>
            <div :class="getBem(blockClass, 'batch-item-entry-translator-info-others')">
              <span
                :class="getBem(blockClass, 'batch-item-entry-translator-info-gender')"
                title="Translator Gender"
              >
                {{ $t(`tfv_${entry.translator.gender}`) }}
              </span>
              <el-rate
                :class="getBem(blockClass, 'batch-item-entry-translator-info-rate')"
                v-model="entry.average_rating" disabled
                :colors="rateActiveColors"
                :void-color="rateVoidColor"
                :disabled-void-color="rateVoidColor"
                title="Translator Rating"
              />
              <span
                :class="getBem(blockClass, 'batch-item-entry-translator-info-notes')"
                title="Translator Notes"
              >
              {{ entry.translator.notes }}
            </span>
            </div>
          </td>

          <!-- BODY : Language -->
          <td :class="[
            getBem(blockClass, 'batch-item-entry-language'),
            getBem(blockClass, 'col-language'),
          ]">
            <span title="Language">
              {{ getLanguageName(entry.language_id) }}
            </span>
            <span
              v-if="entry.translator.mobile != null"
              title="Mobile"
            >
              {{ entry.translator.mobile }}
            </span>
            <span
              v-else-if="entry.translator.mobile == null"
              :class="getBem(blockClass, 'batch-item-entry-no-value')"
            >
                {{ $t('tfv_no_mobile') }}
            </span>
          </td>

          <!-- BODY : Booking Type -->
          <td :class="[
            getBem(blockClass, 'batch-item-entry-booking-type'),
            getBem(blockClass, 'col-booking-type'),
          ]">
            <span title="booking-type">
              {{ $t(entry.booking_type) }}
            </span>
          </td>

          <!-- BODY : Level -->
          <td :class="[
            getBem(blockClass, 'batch-item-entry-level'),
            getBem(blockClass, 'col-level'),
          ]">
            <span title="Translator Level">
              {{ getTranslatorLevelName(entry.translator_level_id) }}
            </span>
          </td>

          <!-- BODY : Rejected -->
          <td :class="[
            getBem(blockClass, 'batch-item-entry-rejected'),
            getBem(blockClass, 'col-rejected'),
          ]">
            <template v-if="entry.rejected_at != null">
              <el-tooltip
                :class="getBem(blockClass, 'batch-item-entry-rejected-tooltip')"
                effect="dark"
                :content="getRejectedAtTooltipContent(entry)"
                placement="top"
              >
                <span>{{ $t('yes')}}</span>
              </el-tooltip>
            </template>
            <template v-else>
              <span title="Rejected At">{{ $t('no')}}</span>
            </template>
          </td>

          <!-- BODY : Last Job Accepted -->
          <td :class="[
            getBem(blockClass, 'batch-item-entry-last-job-accepted'),
            getBem(blockClass, 'col-last-job-accepted'),
          ]">
            <template v-if="entry.last_booking_due != null">
              <div title="Last Booking Due">{{ entry.last_booking_due }}</div>
            </template>
            <template v-else>
              <div :class="getBem(blockClass, 'batch-item-entry-no-value')">
                {{ $t('no_booking_due') }}
              </div>
            </template>

            <!-- <template v-if="entry.last_booking_city != null">
              <div title="Last Booking City">{{ entry.last_booking_city }}</div>
            </template>
            <template v-else>
              <div :class="getBem(blockClass, 'batch-item-entry-no-value')">
                {{ $t('no_booking_city') }}
              </div>
            </template> -->
          </td>

          <!-- BODY : Jobs in 30 days -->
          <td :class="[
            getBem(blockClass, 'batch-item-entry-jobs-in-30-days'),
            getBem(blockClass, 'col-jobs-in-30-days'),
          ]">
            <span
              v-if="entry.previous_bookings_count != null"
              title="Last Jobs in 30 Days"
            >
              {{ entry.previous_bookings_count }}
            </span>
            <span v-else :class="getBem(blockClass, 'batch-item-entry-no-value')">
              {{ $t('no_last_job') }}
            </span>
          </td>

          <!-- BODY : Distance (Car) -->
          <td :class="[
            getBem(blockClass, 'batch-item-entry-distance'),
            getBem(blockClass, 'col-distance'),
          ]">
            <span
              v-if="entry.temp_travel_distance_car != null"
              title="Distance (Car)"
            >
              {{ entry.temp_travel_distance_car }}
            </span>
            <span v-else :class="getBem(blockClass, 'batch-item-entry-no-value')">
              {{ $t('no_distance_car') }}
            </span>
          </td>

          <!-- BODY : Time (Car) -->
          <td :class="[
            getBem(blockClass, 'batch-item-entry-time-car'),
            getBem(blockClass, 'col-time-car'),
          ]">
            <span
              v-if="entry.temp_travel_time_car != null"
              title="Time (Car)"
            >
              {{ entry.temp_travel_time_car }}
            </span>
            <span v-else :class="getBem(blockClass, 'batch-item-entry-no-value')">
             {{ $t('no_time_car') }}
            </span>
          </td>

          <!-- BODY : Distance (Public) -->
          <td :class="[
            getBem(blockClass, 'batch-item-entry-distance-public'),
            getBem(blockClass, 'col-distance-public'),
          ]">
            <span
              v-if="entry.temp_travel_distance_public != null"
              title="Distance (Public)"
            >
              {{ entry.temp_travel_distance_public }}
            </span>
            <span v-else :class="getBem(blockClass, 'batch-item-entry-no-value')">
              {{ $t('no_distance_public') }}
            </span>
          </td>

          <!-- BODY : Time (Public) -->
          <td :class="[
            getBem(blockClass, 'batch-item-entry-time-public'),
            getBem(blockClass, 'col-time-public'),
          ]">
            <span
              v-if="entry.temp_travel_time_public != null"
              title="Time (Public)"
            >
              {{ entry.temp_travel_time_public }}
            </span>
            <span v-else :class="getBem(blockClass, 'batch-item-entry-no-value')">
              {{ $t('no_time_public') }}
            </span>
          </td>

          <!-- BODY : Sent At -->
          <td :class="[
            getBem(blockClass, 'batch-item-entry-send-at'),
            getBem(blockClass, 'col-send-at'),
          ]">
            <span
              v-if="entry.sent_at != null"
              title="Sent At"
            >
              {{ entry.sent_at }}
            </span>
            <span v-else :class="getBem(blockClass, 'batch-item-entry-no-value')">
              {{ $t('no_sent_at') }}
            </span>
          </td>

          <!-- BODY : Action -->
          <td :class="[
          getBem(blockClass, 'batch-item-entry-actions'),
          getBem(blockClass, 'col-action'),
        ]">
            <!-- Rejected -->
            <el-button
              :disabled="entry.rejected_at != null"
              @click="handleClickRejected(entry)"
            >
              {{ $t('rejected') }}
            </el-button>

            <!-- Not Picked Up Call -->
            <template v-if="!entry.is_called">
              <el-button @click="handleClickNotPickUpCall(entry)">
                {{ $t('not_pick_up_call') }}
              </el-button>
            </template>
            <template v-else>
              <el-tooltip
                :class="getBem(blockClass, 'batch-item-entry-rejected-tooltip')"
                effect="dark"
                placement="top"
              >
                <div slot="content">
                  <span>{{ entry.last_called_at }}</span>
                  <br>
                  <span>{{ lastCalledByName }}</span>
                </div>
                <div
                  class="el-button el-button--default is-disabled"
                  @mouseover="getLastCalledByName(entry)"
                >
                  {{ $t('not_pick_up_call') }}
                </div>
              </el-tooltip>
            </template>

            <!-- Assign -->
            <el-button @click="handleClickAssign(entry)">
              {{ $t('assign_button') }}
            </el-button>
          </td>
        </tr>

        <translator-batch-list-table-comment-row
          :parent-block-class="blockClass"
          :total-columns="totalColumns"
          :entry="entry"
          :placeholder="$t('write_comment')"
        />
      </template> <!-- loop entry in batch.entries -->

      </tbody>

    </table>

  </div>
</template>

<script>
  import {mapActions} from 'vuex';
  import isNil from 'lodash/isNil';
  import isEmpty from 'lodash/isEmpty';
  import forEach from 'lodash/forEach';
  import isArray from 'lodash/isArray';
  import map from 'lodash/map';
  import uniq from 'lodash/uniq';
  import filter from 'lodash/filter';
  import moment from 'moment';
  import TranslatorBatchListTableCommentRow from '~/components/lists/batches/TranslatorBatchListTableCommentRow';
  import TranslatorBatchListTableHeaderTitle from '~/components/lists/batches/TranslatorBatchListTableHeaderTitle';
  import APICaller from '~/js/helpers/APICaller';
  import API from '~/js/constants/api';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      TranslatorBatchListTableCommentRow,
      TranslatorBatchListTableHeaderTitle
    },

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      booking: {
        type: Object,
        required: true
      },

      batches: {
        type: Array,
        required: true
      },

      selected: {
        type: Array,
        default () {
          return [];
        }
      },

      isBookingCurrentlyAssigned: {
        type: Boolean,
        default: false
      },

      sort: {
        type: Object,
        default () {
          return {
            by: '',
            order: ''
          };
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockClass: 'translator-batch-list-table',
        selectAllBatches: false,
        selectedBatches: [],
        languageOpts: {},
        totalColumns: 13,
        isLoading: false,
        rateActiveColors: ['#3A75BA', '#3A75BA', '#3A75BA'],
        rateVoidColor: '#b9b9b9',
        lastCalledByName: '---',
        lastCalledByNameCollection: {}
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > watchers
    |--------------------------------------------------------------------------
    */
    watch: {
      selectedBatches (v) {
        this.$emit('update:selected', v);
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * @returns {boolean}
       */
      isBatchesValid () {
        return !isNil(this.batches)
          && !isEmpty(this.batches);
      },

      /**
       * @returns {boolean}
       */
      isPopulatedLanguageOpts () {
        return !isNil(this.languageOpts)
          && !isEmpty(this.languageOpts);
      },

      iSort: {
        get () {
          return this.sort;
        },
        set (v) {
          this.$emit('update:sort', v);
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions('language', {
        loadLanguageOpts: 'fetchLanguages'
      }),

      /**
       * @param {int} languageId
       * @return {string|int}
       */
      getLanguageName (languageId) {
        return this.isPopulatedLanguageOpts
          ? this.languageOpts[languageId]
          : languageId;
      },

      /**
       * @param {int} translatorLevelId
       * @return {string|int}
       */
      getTranslatorLevelName (translatorLevelId) {
        const levels = {
          1: 'Reg',
          2: 'AT',
          3: 'GrT',
          4: 'UT',
          5: 'ST',
          6: 'RT'
        };

        const name = levels[translatorLevelId];
        return !isNil(name) ? name : translatorLevelId;
      },

      /**
       * @param {Object} batch
       * @returns {boolean}
       */
      isBatchSendable (batch) {
        let sendable = true;

        forEach(batch.entries, (entry) => {
          if (!entry.sendable) {
            sendable = false;
            return false; // Break the loop
          }
        });

        return sendable;
      },

      /**
       * @param {Object} batch
       * @returns {string}
       */
      getNonSendableReason (batch) {
        if (
          !isNil(batch)
          && !isNil(batch.entries)
          && !isEmpty(batch.entries)
        ) {
          const filtered = filter(batch.entries, (entry) => {
            return !entry.sendable && entry.not_sent_reason !== '';
          });

          let reasons = !isEmpty(filtered) ? uniq(map(filtered, 'not_sent_reason')) : [];
          return reasons.join(', ');

        } else {
          return 'No Reason';
        }
      },

      /**
       * @returns {void}
       */
      handleChangeSelectAll () {
        if (this.selectAllBatches) {
          this.isLoading = true;

          let opts = {
            endpoint: `${API.BOOKINGS}/${this.booking.id}/batches`,
            method: 'GET',
            params: {
              all: 'true'
            }
          };

          APICaller(opts)
            .then((r) => {
              const collection = r.data.data.batches;

              if (!isNil(collection) && isArray(collection)) {
                this.selectedBatches = map(collection, 'id');
              }
            })
            .finally(() => {
              this.isLoading = false;
            });

        } else {
          this.selectedBatches = [];
        }
      },

      /**
       * @param entry - Batch Entry
       * @returns {void}
       */
      handleClickShowCall (entry) {
        this.$emit('click-show-call', entry);
      },

      /**
       * @param entry - Batch Entry
       * @returns {void}
       */
      handleClickSmsHelpDesk (entry) {
        this.$emit('click-sms-help-desk', entry);
      },

      /**
       * @param entry - Batch Entry
       * @returns {void}
       */
      handleClickResendNotification (entry) {
        this.$emit('click-resend-notification', entry);
      },

      /**
       * @param entry - Batch Entry
       * @returns {void}
       */
      handleClickRejected (entry) {
        this.$emit('click-rejected', entry);
      },

      /**
       * @param entry - Batch Entry
       * @returns {void}
       */
      handleClickNotPickUpCall (entry) {
        this.$emit('click-not-pick-up-call', entry);
      },

      /**
       * @param entry - Batch Entry
       * @returns {void}
       */
      handleClickAssign (entry) {
        this.$emit('click-assign', entry);
      },

      /**
       * @param {Object} entry
       * @returns {string}
       */
      getRejectedAtTooltipContent (entry) {
        if (entry.rejected_at != null) {
          return moment(entry.rejected_at, 'YYYY-MM-DD HH:mm:ss').isValid()
            ? entry.rejected_at
            : 'Date invalid';

        } else {
          return 'No Rejected Date';
        }
      },

      /**
       * @param {Object} sort
       * @param {string} sort.col
       * @param {string} sort.order
       * @returns {void}
       */
      handleClickSort (sort) {
        this.$emit('change-sort', sort);
      },

      /**
       * @param {Object} entry
       */
      getLastCalledByName (entry) {
        const userId = entry.last_called_by;
        const hasCache = !isNil(this.lastCalledByNameCollection[userId]);

        this.lastCalledByName = hasCache ? this.lastCalledByNameCollection[userId] : '---';

        if (!hasCache) {
          this.$store.dispatch('user/findUser', {column: 'id', query: userId})
              .then((r) => {
                if (!isNil(r.data.data.users) && !isEmpty(r.data.data.users)) {
                  const name = r.data.data.users[0].name;

                  this.lastCalledByNameCollection[userId] = name;
                  this.lastCalledByName = name;
                }
              });
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      if (!this.isPopulatedLanguageOpts)
        this.loadLanguageOpts().then((r) => {
          if (
            !isNil(r.data.data)
            && !isNil(r.data.data.languages)
            && isArray(r.data.data.languages)
          ) {
            forEach(r.data.data.languages, (language) => {
              if (language.active) {
                this.$set(this.languageOpts, language.id, language.name);
              }
            });
          }
        });
    }
  };
</script>
