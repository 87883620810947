<template>
  <div class="container app-list">
    <page-header :title="$t('faq_details')" :back-enabled="true"/>
    <div class="app-container">
      <div class="app-wrapper">
          <el-row>
              <el-col :md="17">
                <div class="faq-header-details">
                  <h2>{{faqParent}}</h2>
                  <h5>{{$t('created_by')}}: {{user}}</h5>
                </div>
                <div>
                  <el-collapse>
                    <el-collapse-item v-for="faq in faqs" v-bind:key="faq.id" class="faq-detail-col2">
                    <template slot="title">
                      <h3 class="faq-question-header">{{faq.question}}</h3>
                    </template>
                    <vue-editor class="faq-editor" v-model="faq.response" v-on:keyup="changed"></vue-editor>
                    <div class="el-form-actions faq-form-actions">
                      <el-button class="modal-size" type="default" @click="deleteSubtitleAction(faq.id)">{{$t('delete_question')}}</el-button>
                      <el-button class="modal-size" type="primary" @click="editFaq(faq)">{{$t('save_edit')}}</el-button>
                    </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </el-col>
              <el-col :md="6" class="faq-detail-col1">
                <el-button class="modal-size" type="primary" @click="dialogFormVisible = true">{{$t('add_question')}}</el-button>
                <el-button class="modal-size" type="default" @click="deleteFAQ()">{{$t('delete_faq')}}</el-button>
              </el-col>
          </el-row>
          <create-subtitle-modal :visible.sync="dialogFormVisible" ></create-subtitle-modal>
      </div><!-- /.app-wrapper-->
    </div><!-- /.app-container-->
  </div> <!-- /.app-list-->

</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {isEmpty} from '../../../js/helpers/Common';
import Vue from 'vue'
import { VueEditor } from "vue2-editor";

export default {

  /*
  |--------------------------------------------------------------------------
  | Component > components
  |--------------------------------------------------------------------------
  */
  components: {
    VueEditor
  },
  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data () {
    return {
      //faqs:[],
      isOpen: false,
      dialogFormVisible:false,
      faq:{
      question:'',
      response:'',
      faq_id:''
      },
      faqParent:'',
      user:'',
      editToggle:false,
      editresponse:'',
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapGetters({
      userName: 'auth/userName',
    }),
    ...mapGetters('faq',{
      faqs: 'getSutitles',
    }),
  },
  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
      ...mapActions('faq',{
        getList: 'getSubtitlesList',
        deleteFaq:'deleteFaq',
        deleteSubtitle:'deleteSubtitle',
        editSubtitle:'editSubtitle',
      }),
      ...mapActions('user', {
        read: 'read',
        findUser:'findUser',
      }),

      ...mapActions('adminUser', {
        readEntry: 'read',
      }),
      fetchFaq(){
        this.getList(this.$route.params.id);
      },
      toggleAccordion: function() {
        this.isOpen = !this.isOpen;
      },

      deleteFAQ(){
        this.$confirm(this.$t('are_you_sure_to_delete'), this.$t('warning'), {
            confirmButtonText: 'YES',
            cancelButtonText: 'NO',
            type: 'warning'
        }).then(() => {
            this.deleteFaq(this.$route.params.id).then(response => {
              this.$router.push({name: 'faq'});
            });
        }).catch(() => {
            //
        });

      },
      changed(event){
        this.editrespons=event.target.innerText;
        this.editToggle=true;
      },
      editFaq(item){
        this.editSubtitle(item).then(response => {
          this.fetchFaq();
        });
      },
      deleteSubtitleAction(id){
        this.$confirm(this.$t('are_you_sure_to_delete'), this.$t('warning'), {
            confirmButtonText: 'YES',
            cancelButtonText: 'NO',
            type: 'warning'
        }).then(() => {
            this.deleteSubtitle(id).then(response => {
             this.fetchFaq();
            });
        }).catch(() => {
            //
        });

      },
  },
  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {
      this.faqParent=this.$route.params.title;
      this.fetchFaq();
      this.readEntry({id: this.$route.params.created_by}).then((r) => {
          this.user=r.data.data.user.name;
      });
  }, // End of Component > mounted

}

</script>
