import { render, staticRenderFns } from "./AssignActionsForm.vue?vue&type=template&id=2a56ec8c&"
import script from "./AssignActionsForm.vue?vue&type=script&lang=js&"
export * from "./AssignActionsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports