<template>
      <el-dialog
        title="Partners collaborators"
        :visible.sync="iVisible"
        :modal-append-to-body="false"
        :append-to-body="true"
        custom-class="custom-modal"
        width='50%'>
            <el-form :model="form" ref="partners" :validate-on-rule-change="true" :rules="rules">
                <el-form-item
                  :label="$t('name')" class="label-short" prop="name">
                    <el-input v-model="form.name" :placeholder="$t('name')"></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('email')" class="label-short" prop="email">
                    <el-input v-model="form.email" :placeholder="$t('email')"></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('user')" class="label-short" prop="user">
                    <translator-select v-model="form.user_id"></translator-select>
                </el-form-item>
                <el-form-item
                  :label="$t('prio')" class="label-short" prop="prio">
                    <el-input-number v-model="form.prio" :min="1"></el-input-number>
                </el-form-item>
                <el-form-item :id="genContainerId('save_btn','email-template')" class="el-form-actions">
                    <el-button class="modal-size" type="default" @click="handleClose()">{{$t('cancel')}}</el-button>
                    <el-button class="modal-size" type="primary" @click="handleClickConfirm('partners')">{{$t('submit')}}</el-button>
                </el-form-item>
            </el-form>

      </el-dialog>
</template>

<script>

import { mapActions,mapGetters, mapState, mapMutations } from 'vuex';
import { isEmpty } from '../../../js/helpers/Common';
export default {
    name: 'partnersModal',
    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        value:{
            type: Object,
            default: {
                name: '',
                email: '',
                prio: '',
                user_id:''
            }
        },
        breadMode: {
            default: 'add'
        }
    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
     data() {
        return {
         window,
         loading:false,
         rules: {
          name: [{
              required: true,
              message: 'Please enter a name'
          }],
          email: [
            { required: true, message: 'Please enter an email'},
            { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
          ],
          prio: [{
              required: true,
              message: 'Please enter a prio'
          }]
      },
        }
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
        ...mapState('partners',{
            form: 'compose',
        }),
        /**
         * Interface for this.visible
         */
        iVisible: {
            get() {
                return this.visible
            },
            set(v) {
                this.$emit('update:visible', v);
            },
        },
        iValue:{
            get() {
                return this.value
            },
            set(v) {
                this.$emit('update:value', v);
            },
        },
        /**
         * Returns boolean whether if the current compose page
         * processes an ADD action.
         *
         * @returns {boolean}
         */
        isAdd () { return this.breadMode === 'add'; },

        /**
         * Returns boolean whether if the current compose page
         * processes an EDIT action.
         *
         * @returns {boolean}
         */
        isEdit () { return this.breadMode === 'edit'; },
    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods:{

        ...mapActions('partners',{
            addPartner: 'add',
            read: 'read',
            editPartner: 'edit'
        }),

        ...mapMutations('partners',{
            resetCompose: 'resetCompose'
        }),

        /**
         * Handle when the modal has been closed
         *
         * @return {void}
         */
        handleClose: function () {
            this.$emit('update:visible', false);
        }, // End of handleClose() method

        handleClickConfirm(ruleForm) {
            this.$refs[ruleForm].validate((valid) => {
                if (valid) {
                    if(this.isAdd){
                        this.addPartner({data: this.form}).then(()=>{
                            this.handleClose();
                        });
                    }else{
                        this.editPartner({data: this.form}).then(()=>{
                            this.handleClose();
                        });
                    }
                } else {
                    console.log('error submit !');
                    return false;
                }
            });
            
        },
    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > watch
    |--------------------------------------------------------------------------
    */
    watch: {
      value (newVa, OldVa) {
        if (!isEmpty(newVa)) {
            this.read(newVa);
        }else{
            this.resetCompose();
        }
      }
    }, // End of Component > watch

}
</script>
