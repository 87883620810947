<template>
    <div class="app-form app-fields user-company-form">

      <h3
        :id="genFieldId('is_belong_to_existing_company_label',formElId)"
        class="text-center"
      >
        {{$t('text_user_existing_company')}}
      </h3>

      <!-- isBelongToExistingCompany -->
      <el-form-item :id="genContainerId('is_belong_to_existing_company',formElId)"
                    class="full-width center-block text-center" style="max-width: 250px;">
        <el-radio-group
          :id="genFieldId('is_belong_to_existing_company',formElId)"
          :aria-labelledby="genFieldId('is_belong_to_existing_company_label',formElId)"
          v-model="isBelongToExistingCompany"
          tabindex="0"
        >
          <el-radio :label="true">{{$t('yes')}}</el-radio>
          <el-radio :label="false">{{$t('no')}}</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- isBelongToExistingCompany == true -->
      <template v-if="isBelongToExistingCompany">

        <el-form-item :id="genContainerId('company_selections',formElId)"
                      class="full-width center-block" style="max-width: 500px;">
          <el-select :id="genFieldId('company_selections',formElId)"
                     v-model="iValue" clearable filterable
                     @focus="handleClickCompanySelection"
                     :placeholder="$t('please_select_customer_company')">
            <el-option v-for="o in companyOpts"
                       :key="o.key"
                       :value="o.key"
                       :label="o.label"/>
          </el-select>
        </el-form-item>

      </template>

        <template v-else>

            <h3>{{$t('create_company')}}</h3>

            <!-- Form for the Main Information -->
            <el-form :id="mainFormElId" :ref="mainFormElId">

                <company-main-fields v-model="form.main"/>

            </el-form>

            <el-form :id="additionalInfoFormElId" :ref="additionalInfoFormElId">

                <h3>{{$t('additional_information')}}</h3>
                <el-collapse class="panel-tabbed">

                    <el-collapse-item :title="$t('contact')" name="Contact" v-if="false">
                        <sector-contact-info-fields v-model="form.contact"
                                                    :form-el-id="additionalInfoFormElId"/>
                    </el-collapse-item>

                    <el-collapse-item :title="$t('address')" name="address">
                        <sector-address-fields v-model="form.address"
                                               :form-el-id="additionalInfoFormElId"/>
                    </el-collapse-item>

                    <el-collapse-item :title="$t('settings')" name="settings">
                        <sector-settings-fields v-model="form.sector_settings"
                                                :form-el-id="additionalInfoFormElId"/>
                    </el-collapse-item>

                </el-collapse>

                <!-- Save -->
                <el-form-item :id="genContainerId('save',additionalInfoFormElId)">
                    <el-button type="primary" :id="genFieldId('save',additionalInfoFormElId)"
                               @click="handleSubmit">
                        {{$t('save')}}
                    </el-button>
                </el-form-item>

            </el-form>

        </template>

    </div> <!-- /.app-form app-fields user-company-form -->
</template>

<script>
  import {mapState, mapGetters, mapActions} from "vuex";
  import {populate} from "../../../js/helpers/Vuex";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      formElId: {
        type: String,
        default: 'user_company_form'
      },

      value: {
        default: ''
      }

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        isBelongToExistingCompany: true,

        mainFormElId: 'company_main_form',
        additionalInfoFormElId: 'company_additional_info_form'
      }
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapState('company', {
        form: 'compose'
      }),

      ...mapGetters('company', {
        companyOpts: 'listAsOptionsPaired'
      }),

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        }
      }

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions('company', {
        loadCompanyOpts: 'browseAsOptions',
        createEntry: 'add'
      }),

      /**
       * Handle when the form has been submitted.
       *
       * @return {void}
       */
      handleSubmit () {

        this.createEntry().then((r) => {

          let raw = r.data.data; // Store into container so varname will be shorter.
          let data = raw[Object.keys(raw)[0]]; // Get the first member of the object.
          this.iValue = data.id; // Set the created ID as the current value.

          this.isBelongToExistingCompany = true;
        });

      }, // End of handleSubmit() method

      /**
       * Handler when the Company selection was clicked.
       */
      handleClickCompanySelection () {
        populate(this, 'loadCompanyOpts', 'companyOpts', {
          ref: 'Company Options'
        });
      }

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {}


  } // End of export default
</script>
