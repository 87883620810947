/*
|--------------------------------------------------------------------------
| Financial > shared > props > charging
|--------------------------------------------------------------------------
*/

'use strict';

import constants from './constants';
import {genPropsFromCollection} from '~/js/helpers/Vuex';

export const chargesBase = {
  minimum_fee: 0,
  succeeding_fee: 0,
  parts_fees: {
    // This is dynamic, therefore format is not strictly defined.
    // -- Sample Format--
    // x1_5: 5,
    // x2: 10,
    // x2_5: 15,
  },
};

const chargesProps = genPropsFromCollection(
  constants.BOOKING_TYPES, {
    ...chargesBase
  }
);

'use strict';

export default {

  use_parts: true,
  is_flat_rate: false,
  flat_rate: 0,

  charges: genPropsFromCollection(
    constants.TRANSLATOR_LVLS, chargesProps
  ),

}
