<template>
    <div class="app-form app-fields user-translator-trainings-fields">

        <h3 class="text-center">{{ $t('tfv_txt_add_trainings') }}</h3>

        <div class="top-controls">
            <el-form-item :id="genContainerId('training_ids',formElId)"
              label="Trainings" class="label-block">
              <el-select :id="genFieldId('training_ids',formElId)"
                v-model="training_ids" filterable default-first-option multiple 
                placeholder="Select Trainings">
                <el-option v-for="item in listData" :key="item.id" :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <user-translator-training-record-fields
              v-for="(v, k) in listData"
              :key="v.id" :ord="k" 
              :tid="v.id"
              v-model="iValue"
              :title="v.name"
              :item="v"
              :training_ids="training_ids"
              :training_records="preloaded_training_records" />
            
        </div>

    </div> <!-- /.app-form app-fields user-translator-settings-fields -->
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from "vuex";
  import {populate} from "../../../js/helpers/Vuex";

  let moduleTarget = 'trainings';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      // Prop v-model of the component.
      value: {
        type: Object,
        required: true
      },

      // String reference for form element ID.
      formElId: {
        type: String,
        default: 'user_translator_trainings'
      },

    },

    data() {
      return {
        training_ids: [],
        preloaded_training_records: [],
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters(moduleTarget, ['listData', 'listPagination']),

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        }
      }

    },

    methods: {
      ...mapActions(moduleTarget, {
        // browse: 'browseTrainings',
        browse: 'browse',
        records: 'getTrainingRecordsByTranslator'
      }),

      ...mapMutations(moduleTarget, {
        setListWithRecords: 'setListWithRecords'
      })
    },

    mounted () {

      populate(this, 'browse', 'listData', {
        force: true
      });

      this.records({
        user_translator_id: this.$route.params.id
      }).then((r) => {
        let records = r.data.data.training_translator_records;
        for (let record of records) {
          this.training_ids.push(record.training.id);
          this.preloaded_training_records.push(record);
        }
      });
    }
  }
</script>
