<template>

  <div class="app-list booking-issue-list">

    <custom-table-two :table-data="listData" :extra-cols="listCols" :detailsColTitle="$t('assigned_admin')"
      :hasDetailsCol="true">

      <!-- Row for Top Controls -->
      <template slot="topControls">

        <filters-panel @updateList="updateLists"></filters-panel>
      </template>

      <template slot="detailsColumn" slot-scope="scope">
        <assign-admin-form :issue="scope.row"></assign-admin-form>
      </template>
      <!-- Column for action buttons -->
      <template slot="actionsColumn" slot-scope="scope">
        <el-button class="circle" type="success" :title="$t('set_as_solved')" icon="el-icon-success"
          @click="handleSolvedButton(scope.row)">
          <!-- <span class="fa fa-pencil"></span> -->
        </el-button>
        <div @contextmenu.prevent="handleDetailsNew(scope)" style="display: inline">
          <el-button class="circle" type="primary" :title="$t('details')" icon="el-icon-info"
          @click="handleDetailsButton(scope.row.id)">
        </el-button>
        </div>
        <template v-if="scope.row.type.type==='expense_report'">
          <el-button class="circle" type="danger" :title="$t('reject')" icon="el-icon-warning"
            @click="handleRejectExpense(scope.row)">
            <!-- <span class="fa fa-trash"></span> -->
          </el-button>
          <el-button class="circle" type="info" :title="$t('approve')" icon="el-icon-circle-check"
            @click="handleApprovedExpense(scope.row)">
            <!-- <span class="fa fa-trash"></span> -->
          </el-button>

        </template>
        <el-button class="circle" type="info" :title="$t('pending')" icon="el-icon-time"
            @click="handlePending(scope.row)">
            <!-- <span class="fa fa-trash"></span> -->
          </el-button>
      </template>
    </custom-table-two>
    <comment-modal
      :visible.sync="showAddComment"
      :dontcountfeedback.sync="do_not_count_feedback"
      :issue="issue"
      @addComment="setAsSolved">
    </comment-modal>

  </div> <!-- /.app-col -->
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import {isEmpty} from '~/js/helpers/Common'
import AssignAdminForm from './AssignAdminForm'
import CommentModal from '~/components/pages/booking-issue/partials/CommentModal'
import FiltersPanel from '~/components/lists/booking-issue/Filters'
export default {

  components: {
    AssignAdminForm,
    CommentModal,
    FiltersPanel
  },
  data() {
    const _this=this;
    return {
      showAddComment: false,
      issue: {},

      booking_id:'',
      listCols: [
        {
          label: _this.$t('id'),
          prop: 'id',
          width: '',
          className: 'id-col',

        },
        {
          label: _this.$t('type'),
          prop: 'type.type_name',
          className: 'type-col',
          width: "100px",
          mutator(v,row) {

            if (row.type.type === 'low_feedback') {
              return (!isEmpty(row.entities)) ?
                  (row.entities.review_from_type === 3) ? `${_this.$t(row.type.type + '_from_translator')}` : `${_this.$t(row.type.type + '__form_customer')}`
                  : _this.$tc(row.type.type,{n:1})
            }

            return _this.$t(`tfv_types_${row.type.type}`);
          }

        },
        {
          label: _this.$t('booking_id'),
          prop: 'booking_id',
          width: '',
          className: 'booking_id-col',
        },
        {
          label: _this.$t('customer'),
          prop: 'booking.customer.name',
          width: '',
          className: 'booking_id-col',
        },
        {
          label: _this.$t('translator'),
          prop: 'translator',
          width: '',
          className: 'booking_id-col',
          mutator(v) {

            return !isEmpty(v) ? v.name : ''
          }
        },
        {
          label: _this.$t('due_date'),
          prop: 'deadline_date',
          width: '',
          className: 'booking_id-col',
          mutator(v,row) {
            return row.entity==='App\\Modules\\Todos\\Todo' ?  row.entities.date: v
          }

        }
      ],
      do_not_count_feedback: false,
    }
  },
  computed: {
    ...mapState('auth', {
      user: 'userData'
    }),
    ...mapActions('adminUser', {
      browseAdmin: 'browseAsOptions',
    }),
    ...mapGetters('bookingIssue', {
      listData: 'listData'
    }),

    ...mapGetters('bookingIssueSetting', {
      settingList: 'listData'
    }),
    ...mapGetters('todo', {
      todoList: 'listData'
    })
  },
  methods: {
    ...mapActions('bookingIssue', {
      updateIssue: 'updateIssue',
      postComment: 'postComment',
      rejectExpense: 'rejectExpense',
      approveExpense: 'approveExpense',
      pendingIssue:'pendingIssue'
    }),
    ...mapActions('booking',{
      addBookingComment:'addComment'
    }),
    ...mapActions('feedback',{
      putFeedback:'putFeedback'
    }),
    ...mapActions('todo',{
      getTodos:'browse'
    }),
    isEmpty(v) {
      return isEmpty(v);
    },
    handleDetailsButton(v) {
      this.$router.push({
        name: 'booking.issue.details',
        params: {
          id: v
        }
      })
    },

    handleDetailsNew(v){
      let route = this.$router.resolve({
        name: 'booking.issue.details',
        params: {id: v.row.id}
      });
      window.open(route.href, "_blank");
    },

    handleSolvedButton(issue) {
      this.$confirm(this.$t('txt_set_status_solved'), 'Warning', {
        confirmButtonText: this.$t('OK'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.issue = Object.assign(this.issue,issue)
        this.showAddComment = true
      }).catch(() => {

      });

    },
    async setAsSolved(e) {
      let params = {
        booking_issue_id: this.issue.id,
        solved: true
      };
      let issue = await this.updateIssue(params);

      if (issue.status === 200) {
        let payload = {
          booking_issue_id: this.issue.id,
          comment: e.comment,
          created_by: this.user.id
        }
        this.postComment(payload);
        e.comment=''

        if (this.do_not_count_feedback) {
          this.putFeedback({
            id: this.issue.entity_id,
            is_rejected: true
          }).then(r => {
            this.do_not_count_feedback = false;
          });
          this.postComment({
            booking_issue_id: this.issue.id,
            comment: "Admin has decided not to count this rating towards the average rating",
            created_by: this.user.id
          });
        }

        this.$emit('updateList', {'sort':'-deadline_date'})
      }
    },
    async handleApprovedExpense(scope) {
      let params = {
        booking_id: scope.booking_id,
        id: scope.entity_id,
      }
      let response = await this.approveExpense(params)
      if (response.status === 200) {
        let payload = {
          booking_issue_id: scope.id,
          comment: `Expense approved by ${this.user.name}`,
          created_by: this.user.id,
          message: `Expense approved`
        }
        this.postComment(payload)
      }
    },
    async handleRejectExpense(scope) {
      let params = {
        booking_id: scope.booking_id,
        id: scope.entity_id,
      }
      let response = await this.rejectExpense(params)
      if (response.status === 200) {
        let payload = {
          booking_issue_id: scope.id,
          comment: `Expense reject by ${this.user.name}`,
          created_by: this.user.id,
          message: `Expense rejected`
        }
        this.postComment(payload)
      }
    },


    handlePending(row){
        this.$confirm(this.$t('txt_set_status_pending'), this.$t('warning'), {
          confirmButtonText: this.$t('OK'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
            let params = {
              id: row.id
            }
            this.pendingIssue(params).then(()=>{
              this.$emit('updateList', {'sort':'-deadline_date'})
            })
        }).catch(() => {

        });
    },
    updateLists(e){
      this.$emit('updateList',e)
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {
    this.getTodos();
  }, // End of Component > mounted


}
</script>

<style lang="scss">
.booking-issue-list{
  .type-col{
    .cell{
      .cell-content{
        word-break: break-word;
      }
    }
  }
}
</style>

