<template>
    <div class="app-list user-list">

        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="topControls">
                <el-row>
                    <el-col :span="12">
                        <el-button type="primary" icon="fa fa-plus" @click="handleAdd">
                            {{$t('add_item')}}
                        </el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-input :placeholder="$t('search_admin')" v-model="search" @keyup.enter.native="handleSearch">
                            <el-select class="filter-dropdown" v-model="searchOption" slot="prepend"
                                       placeholder="Select">
                                <el-option :label="$t('name')" value="name" ></el-option>
                                <el-option :label="$t('tfv_email')" value="email"></el-option>
                            </el-select>
                            <span class="el-icon-search el-input__icon app-cursor-pointer"
                                  slot="suffix" @click="handleSearch"></span>
                        </el-input>
                    </el-col>
                </el-row>
            </template>
            <template slot="actionsColumn" slot-scope="scope">
                <el-button v-if="userData.is_super_admin" class="circle" type="warning" :title="$t('login')" @click.stop="handleLoginAs(scope)">
                    <span class="fa fa-sign-in"></span>
                </el-button>
                <el-button class="circle" type="warning" :title="$t('user_access_log')" @click="handleRedirectUserAccessLog(scope)">
                    <span class="fa fa-key"></span>
                </el-button>
                <el-button class="circle" type="success" :title="$t('edit')" @click="handleEdit(scope)">
                    <span class="fa fa-pencil"></span>
                </el-button>
                <el-button class="circle" type="danger" :title="$t('remove')" @click="handleDelete(scope)">
                    <span class="fa fa-trash"></span>
                </el-button>
            </template>

        </base-list-table>

        <!-- Pagination -->
        <el-pagination
                v-if="listPagination.total > listPagination.per_page"
                layout="prev, pager, next"
                :page-size="listPagination.per_page"
                :total="listPagination.total"
                :current-page="listPagination.current_page"
                @current-change="reloadList">
        </el-pagination>

    </div> <!-- /.app-list user-list -->
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import {populate} from "../../../js/helpers/Vuex";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {

                listCols: [
                    {
                        label: `${this.$t('name')}`,
                        prop: 'name',
                        width: '',
                        className: 'name-col',
                    },
                    {
                        label: `${this.$t('tfv_email')}`,
                        prop: 'email',
                        width: '',
                        className: 'email-col',
                    },
                ], // End of listCols

                populatePayload: {
                    user_type: 'users',
                },
                search: '',
                searchOption: 'name'
            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapGetters('adminUser', ['listData', 'listPagination']),
            ...mapGetters('auth', ['userData']),

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            ...mapActions('adminUser', {
                browse: 'browse',
                deleteEntry: 'delete',
            }),

            ...mapActions('auth', {
                loginAs: 'loginAs',
                getUserData: 'getUserData',
                setAccessToken: 'setAccessToken',
            }),

            /**
             * Handler for Add event in the table.
             *
             * @return {void}
             */
            handleAdd() {

                window.location.href = '/#/admin-user/add/';

            }, // End of handleAdd() method

            /**
             * Handler for edit event in the table.
             *
             * @param {object} scope
             *   The current scope(row) target.
             *
             * @return {void}
             */
            handleEdit(scope) {

                // Redirect to the edit page.
                window.location.href = '/#/admin-user/edit/' + scope.row.id;

            }, // End of handleEdit() method

            handleSearch() {
                this.reloadList(1, this.search);
            },

            /**
             * Handler for delete event in the table.
             *
             * @param {object} scope
             *   The current scope(row) target.
             *
             * @return {void}
             */
            handleDelete(scope) {

                this.$confirm('This will permanently delete the entry. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.deleteEntry({id: scope.row.id});
                }).catch(() => {
                    // Silence is golden.
                });

            }, // End of handleDelete() method


            /**
             * Method for reloading the list.
             *
             * @param {int} n
             *   The target page number.
             *
             * @return {void}
             */
            reloadList(n, name) {
                let params = {
                    page: n,
                };
                if (typeof name !== 'undefined') {
                    params['filter[' + this.searchOption + ']'] = name;
                }

                populate(this, 'browse', '', {
                    force: true,
                    params,
                    ref: 'Admin User Listing (forced)',
                });
            }, // End of reloadList() method

            handleRedirectUserAccessLog(scope) {
                this.$router.push({path:'/logs/user-access-logs', query:{admin:scope.row.name}})
            },

            handleLoginAs (scope) {
                this.loginAs({email: scope.row.email}).then((response) => {
                    if (response.data.is_admin == true) {
                        this.setAccessToken(response.data.token);
                        this.getUserData().then(r => {
                            let name = r.data.data.user.name;
                            this.$alert('Admin ' + name +' is now logged in', 'Proceed', {
                                confirmButtonText: 'OK',
                                callback: action => {
                                    window.location.reload();
                                }
                            });
                        });
                    } else {
                        throw new Error("Unable to Login");
                    }
                }).catch(error => {
                    this.$notify.error({
                        title: 'Error',
                        message: 'Unable to Login'
                    });
                });
            },

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {

            populate(this, 'browse', 'listData', {
                ref: 'Admin User Listing',
            });

        }, // End of Component > mounted

    } // End of export default
</script>
