/*
|--------------------------------------------------------------------------
| Store > Company > Mutations
|--------------------------------------------------------------------------
|
| This file contains the mutations property of Company Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {mapProps} from "../../../helpers/Common";

export default {

    /**
     * Method to map the value target entry's values into the form.
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    setCompose(state) {
        state.compose.blacklisted_users =  state.item.data.blacklisted_users
        state.item.data.blacklisted_users = _.map(state.item.data.blacklisted_users, 'translator_id');
        mapProps(state.item.data, state.compose.main);
        mapProps(state.item.data, state.compose.address);
        mapProps(state.item.data, state.compose.contact);
        mapProps(state.item.data, state.compose.sector_settings);
    },

    /**
     * Method to reset the compose prop to its pristine state.
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    resetCompose(state) {
        state.compose = _.cloneDeep(state.composeFresh);
    },

    /**
     * Method to map the form values into the payload
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    setPayloadData(state) {
        _.merge(
            state.payloadData, // The destination of the merged values.

            // Below are source of the values to be merged.
            state.compose.main,
            state.compose.address,
            state.compose.contact,
            state.compose.sector_settings,
        );
    }, // End of preparePayload

} // End of export default