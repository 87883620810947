<template>
  <div style="padding:0px 50px;">
    
    
    <el-form :model='form' ref="form" :rules="rules" :inline="true" @submit.native.prevent>
      <el-form-item prop='from_language_id' :label="$t('from_language')">
        <el-select 
            v-model="form.from_language_id"  filterable
            :placeholder="$t('please_select_the_initial_language')">
            <el-option v-for="l in languageOpts"
              :key="l.id"
              :value="l.id"
              :label="l.name"/>
        </el-select>
      </el-form-item>
      <el-form-item prop='to_language_id' :label="$t('to_language')" >
         <el-select 
            v-model="form.to_language_id"  filterable
            :placeholder="$t('please_select_destination_language')">
            <el-option v-for="l in languageOpts"
              :key="l.id"
              :value="l.id"
              :label="l.name"/>
        </el-select>
      </el-form-item>
      <el-form-item prop="code" :label="$t('level')">
        <el-select v-model="form.code" :placeholder="$t('please_select_translation_level')" multiple>
          <el-option value="deluxe">{{$t('deluxe')}}</el-option>
          <el-option value="non-certified">{{$t('non-certified')}}</el-option>
          <el-option value="certified">{{$t('certified')}}</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=' '>
        <!-- <el-button type="primary" @click="submitAdd" size='small'>Add Level</el-button> -->
        <el-button
            class="circle b"
            type="primary"
            title="Add"
            @click="submitAdd"
            size="mini"
            icon="el-icon-plus"
          >
          </el-button>
      </el-form-item>
    </el-form>
    <div style="width:90%">
     <base-list-table
                :table-data="iValue"
                :extra-cols="listCols"
                :hasDetailsCol="false">
         <template slot="actionsColumn" slot-scope="scope">
           <el-button
            class="circle b"
            type="danger"
            title="Delete"
            @click="handleDelete(scope)"
            size="mini"
            icon="el-icon-delete"
          >
          </el-button>
         </template>
        </base-list-table>
        </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data(){
    const _this = this;
    return {
      form:{
        from_language_id:'',
        to_language_id:'',
        code:[]
      },
      rules:{
        from_language_id:[
          {required:true,message:_this.$t('please_select_from_language')}
        ],
        to_language_id:[
          {required:true,message:_this.$t('please_select_to_language')}
        ],
        code:[
          {required:true,message:_this.$t('please_select_language_level')}
        ]
      },
      listCols:[
        {
            label:  _this.$t('from_language'),
            prop: 'from_language',
            width: '',
        },
        {
            label:  _this.$t('to_language'),
            prop: 'to_language',
            width: '',
        },
        {
            label:  _this.$t('level'),
            prop: 'code',
            width: '',
            mutator(v){
              return _this.$t(v)
            }
        },
        
      ]
    }
  },
   props: {
      // Prop v-model of the component.
      value: {
        type: Array,
        required: true
      },
   },
   computed: {
     ...mapGetters('language', {
        languageOpts: 'getLanguages'
      }),

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        }
      }
    },
    methods:{
      submitAdd(){
        this.form = Object.assign(this.form,{
          to_language: this.languageOpts.find(v => v.id ===this.form.to_language_id).name,
          from_language: this.languageOpts.find(v => v.id ===this.form.from_language_id).name
        })
        this.form.code.forEach(i => {
         
          let payload = {
            code: i,
            to_language: this.form.to_language,
            from_language: this.form.from_language,
            to_language_id: this.form.to_language_id,
            from_language_id: this.form.from_language_id,
            
          }
          this.iValue.push(payload)
          console.log(payload)
        })
        
      
        // this.form = Object.assign(this.form,{
        //   to_language: this.languageOpts.find(v => v.id ===this.form.to_language_id).name,
        //   from_language: this.languageOpts.find(v => v.id ===this.form.from_language_id).name
        // })
        // this.iValue.push(this.form)
      },
      handleDelete(v){
        this.iValue.splice(v.$index)
      }
    }
}
</script>