<template>
  <div class="app-form" :class="getBem(blockClass)">
    <user-in-booking-feature :bookingId="bookingId" :userId="userId"></user-in-booking-feature>
    <!-- id="booking-edit-form" -->
    <el-form :id="formElId" :ref="formElId" :rules="rules" :model="form">

      <el-row>
        <el-col :md="12">
          <el-row>
            <el-col :md="12">
              <!--                            <h4>{{$t('due')}}</h4>-->
              <h4 v-if="form.type ==='text_translation'">{{$t('text_translation_customer_deadline')}}</h4>
              <h4 v-else>{{$t('due')}}</h4>
              <el-form-item label="" :id="genContainerId('due',formElId)">
                <el-date-picker v-model="form.due" type="datetime" :placeholder="$t('select_date_time')"
                                format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                                popper-class="day-picker-only" :id="genFieldId('due',formElId)">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <h4>{{$t('end_time')}}</h4>
              <el-form-item label="" :id="genContainerId('due',formElId)">
                <el-date-picker v-model="toTime" type="datetime" :placeholder="$t('select_date_time')"
                                format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                                popper-class="day-picker-only" :id="genFieldId('end_time',formElId)"
                                @select="handleToTimeChange" @blur="handleToTimeChange">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <template v-if="form.type !== 'text_translation'">
          <el-col :md="11" :offset="1">
            <h4>{{$t('will_expire_at')}}</h4>
            <el-form-item label="" :id="genContainerId('will_expire_at',formElId)">
              <el-date-picker v-model="form.expiry" type="datetime" :placeholder="$t('select_date_time')"
                              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                              popper-class="day-picker-only" :id="genFieldId('will_expire_at',formElId)">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :md="11">
            <h4>{{$t('will_expire_at_partner')}}</h4>
            <el-form-item label="" :id="genContainerId('will_expire_at',formElId)">
              <el-date-picker v-model="form.expiry_time_partner" type="datetime" :placeholder="$t('select_date_time')"
                              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                              popper-class="day-picker-only" :id="genFieldId('will_expire_at',formElId)">
              </el-date-picker>
            </el-form-item>
          </el-col>

        </template>
        <template v-if="form.type === 'text_translation'">
          <el-col :md="11" :offset="1">
            <h4>{{$t('text_translation_translator_deadline')}}</h4>
            <el-form-item label=''>
              <!-- <el-input type='textarea' v-model="iValue.translator_deadline" /> -->
              <el-date-picker v-model="form.text_translation.translator_deadline" type="datetime"
                              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                              popper-class="day-picker-only">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
      <el-row>
        <el-col :md="12" v-if="form.status_id === 4">
          <!-- Due -->
          <h4>{{$t('session_time')}}</h4>
          <el-form-item label="" :id="genContainerId('due',formElId)" prop="session_time">
            <el-time-picker v-model="form.session_time" :placeholder="$t('select_due_time')" format="HH:mm"
                            value-format="HH:mm:ss"></el-time-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :md="12" v-if="form.status_id === 4">
          <!-- Due -->
          <h4>{{$t('session_comment_customer')}}</h4>
          <el-form-item label="" :id="genContainerId('due',formElId)" prop="session_time">
            <el-input v-model="form.session_comment" :placeholder="$t('session_comment_placeholder')" >

            </el-input>
          </el-form-item>
        </el-col>

        <el-col :md="12" v-if="form.status_id === 4">
          <!-- Due -->
          <h4>{{$t('session_comment_translator')}}</h4>
          <el-form-item label="" :id="genContainerId('due',formElId)" prop="session_time">
            <el-input v-model="form.session_comment_translator" :placeholder="$t('session_comment_placeholder')" >

            </el-input>
          </el-form-item>
        </el-col>

      </el-row>


      <el-row>
        <el-col :md="12">
          <h4>{{$t('tfv_translator_email')}} </h4>
          <translator-select v-model="form.assigned_translator_id"></translator-select>
          <el-button type="primary" @click="handleClickAssignTranslator()">{{$t('assign_translator')}}</el-button>
        </el-col>
        <el-col :md="11" :offset="1">
          <h4>{{$t('or')}}</h4>
          <el-form-item :label="$t('choose_related_translator')"
                        :id="genContainerId('related_translators',formElId)"
                        class="label-long">
            <el-button type="primary" @click="handleRelatedTranslatorClick">
              {{$t('click_to_view_translator')}}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- Assigned Translator informations-->
      <el-row v-if="!empty(form.assigned_translator)">
        <el-col :span="24" class="grid-content">
          <div>
            <h3 class="title">{{$t('assigned_translator_detail')}}</h3>

            <el-row>
              <!-- translator_name -->
              <el-col :md="11">
                <h4>{{$t('translator_name')}}</h4>
                <el-form-item label="">
                  <el-input v-model="form.assigned_translator.name"
                            :placeholder="$t('input_translator_name')"></el-input>
                </el-form-item>
              </el-col>

              <!-- translator_mobile -->
              <el-col :md="11" :offset="1">
                <h4>{{$t('translator_mobile')}}</h4>
                <el-form-item label="">
                  <el-input v-model="form.assigned_translator.mobile"
                            :placeholder="$t('input_translator_mobile')"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="grid-content">
          <el-row>
            <el-col :md="11">
              <h4>{{$t('from')}} {{$t('language')}}</h4>
              <el-form-item label="" :id="genContainerId('from_language',formElId)">
                <el-select v-model="form.from_language_id" filterable
                           :placeholder="$t('type_to_search')"
                >
                  <el-option v-for="item in languages" :key="item.id" :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>

              </el-form-item>

              <h4>{{$t('alternative_languages')}}</h4>
              <!-- <template v-if="!empty(form.alternative_languages)"> -->
              <el-form-item label="" :id="genContainerId('alternative_languages',formElId)">
                <el-select v-model="form.alternative_languages" filterable
                           :placeholder="$t('type_add_alternative_language')" multiple
                >
                  <el-option v-for="item in languages" :key="item.id" :label="item.name"
                             :value="item.id">{{item.name}}
                  </el-option>
                </el-select>

              </el-form-item>
              <!-- </template> -->
            </el-col>
            <el-col :md="11" :offset="1">
              <template v-if="form.type ==='text_translation'">
                <h4>{{$t('to')}} {{$t('language')}}</h4>
                <el-form-item label="" :id="genContainerId('to_language',formElId)">
                  <el-select v-model="form.to_language_id" filterable
                             :placeholder="$t('type_to_search')"
                  >
                    <el-option v-for="item in languages" :key="item.id" :label="item.name"
                               :value="item.id">
                    </el-option>
                  </el-select>

                </el-form-item>
              </template>

              <h4>{{$t('status')}}</h4>
              <booking-status-select v-model="form.status_id"></booking-status-select>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="grid-content">
          <div>
            <h3 class="title">{{$t('booking_detail')}}:</h3>

            <el-row>
              <el-col :md="12">
                <h4>{{ $t('customer') }}</h4>
                <el-select v-model="form.customer_id" filterable :placeholder="$t('type_to_search')"
                           remote
                           :remote-method="searchCustomer" :loading="searchCustomerLoading"
                           value-key="id">
                  <el-option v-for="customer in searchResult" :key="customer.id"
                             :label="customer.name"
                             :value="customer.id"> {{customer.name}}
                  </el-option>
                </el-select>
              </el-col>

            </el-row>

            <el-row>
              <el-col :md="12">
                <h4>{{$t('reference')}}</h4>
                <el-form-item label="">
                  <el-input v-model="form.reference" :placeholder="$t('input_reference')"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="11" :offset="1">
                <h4>{{$t('staff_name')}}</h4>
                <el-form-item label="">

                  <el-form-item label="">
                    <el-input v-model="form.staff_name"
                              :placeholder="$t('input_staff_name')"></el-input>
                  </el-form-item>
                  <!-- <el-input v-model="email" placeholder="Input email"></el-input> -->
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :md="12">
                <h4>{{$t('specific_translators')}}</h4>
                <el-form-item label="">
                  <el-select v-model="form.specific_translators"
                             :placeholder="$t('select_translator')" multiple filterable value-key="id"
                             clearable>
                    <el-option v-for="translator in translators" :key="translator.id"
                               :label="`${translator.email} - ${translator.name}`"
                               :value="translator.id">{{`${translator.name} - ${translator.email}`}}
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="11" :offset="1">
                <h4>{{$t('booked_name')}}</h4>
                <el-form-item label="">
                  <el-input v-model="form.booker_name"
                            :placeholder="$t('input_booker_name')"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="12">
                <h4>{{$t('lma_number')}}
                </h4>
                <el-input v-model="form.lma"></el-input>
              </el-col>

              <el-col :md="11" :offset="1">
                <h4>{{$t('send_invoice_to_following_department')}}
                </h4>
                <department-search :showLabel="false" v-model="form.invoicing_department_id"/>
              </el-col>
            </el-row>
<!--            <el-row>-->
<!--              <el-col :md="12">-->
<!--                <h4>{{$t('excluded_translators')}} </h4>-->
<!--                <el-form-item label="">-->
<!--                  <el-select v-model="form.excluded_translators"-->
<!--                             :placeholder="$t('select_translator')" multiple filterable>-->
<!--                    <el-option v-for="translator in translators" :key="translator.id"-->
<!--                               :label="translator.email" :value="translator.id"></el-option>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->


            <template v-if="form.type==='physical' || form.type==='video_physical'">
              <el-row>
                <el-col :md="12">
                  <h4>{{$t('address_for_booking')}}</h4>
                  <!-- <span>{{form.address}}</span> -->
                  <el-form-item label="">
                    <booking-here-address-field :id="genFieldId('address',formElId)"
                                                v-model="form.address" @updateAddress="updateAddress"/>
                    <!-- <el-input v-model="form.address" placeholder="Input address"></el-input> -->
                  </el-form-item>
                </el-col>
                <el-col :md="11" :offset="1">
                  <h4>{{$t('town')}}</h4>
                  <el-form-item label="">

                    <el-input v-model="form.city" :placeholder="$t('input_town')"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :md="12">
                  <h4>{{$t('instructions')}}</h4>
                  <el-form-item label="">
                    <el-input v-model="form.instructions"
                              :placeholder="$t('input_instructions')"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="11" :offset="1">
                  <h4>{{ $t('tfv_edit_additional_towns') }}</h4>
                  <el-form-item label="">
                    <el-select v-model="form.towns" placeholder="" value-key="id" filterable
                               multiple clearable>
                      <el-option v-for="i in townList" :key="i.id" :value="i.id"
                                 :label="i.name"></el-option>
                    </el-select>
                    <!-- <el-input v-model="form.towns" placeholder="Input Addititown"></el-input> -->
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :md="12">
                  <h4>{{ $t('geo_coordinate') }}</h4>
                  <el-form-item label="">
                    <el-input v-model="form.coordinates"
                              :placeholder="$t('latitute_longitude')"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24" class="grid-content">
          <div>
            <h3>{{$t('notification_types')}}</h3>
            <el-row>
              <el-col :span="12">
                <h4>{{$t('email')}}</h4>
                <input-tag
                  :tags.sync="form.email_notifications"
                  :placeholder="$t('tfv_booking_add_email')"
                  :validate="'email'"
                  v-on:update:tags="setNotificationTypes('email')"
                  @blur="setNotificationTypes('email')"
                  :addTagOnBlur="true"/>
              </el-col>

              <el-col class="align-bottom" :span="12">

                <!--                <el-form-item label="">-->
<!--                  <el-row>-->
<!--                    <el-col :md="3" :offset="1">-->
<!--                      <el-checkbox-->
<!--                        name="fax"-->
<!--                        label="Fax"-->
<!--                        v-model="form.fax_notification_enabled"-->
<!--                        @change="setNotificationTypes('fax', $event)"-->
<!--                        aria-label="Set Fax Notification"-->
<!--                      />-->
<!--                    </el-col>-->
<!--                    <el-col :md="18">-->
<!--                      <el-input-->
<!--                        ref="input_fax"-->
<!--                        v-model="form.fax_notification"-->
<!--                        auto-complete="off"-->
<!--                        placeholder=""-->
<!--                        :disabled="!form.fax_notification_enabled"-->
<!--                        @input="handleNotifInputChange($event ,'fax')"-->
<!--                      />-->

<!--                      &lt;!&ndash;  &ndash;&gt;-->
<!--                      &lt;!&ndash; @blur="setNotificationTypes('fax')" &ndash;&gt;-->
<!--                    </el-col>-->
<!--                  </el-row>-->
<!--                </el-form-item>-->

                <!-- <el-form-item label="">
                  <el-row>
                    <el-col :md="3" :offset="1">
                      <el-checkbox
                        name="sms"
                        label="SMS"
                        v-model="form.sms_notification_enabled"
                        @change="setNotificationTypes('sms', $event)"
                        aria-label="Set SMS Notification"
                      />
                    </el-col>
                    <el-col :md="18">
                      <el-input
                        ref="input_sms"
                        v-model="form.sms_notification"
                        placeholder=""
                        auto-complete="off"
                        :disabled="!form.sms_notification_enabled"
                        @input="handleNotifInputChange($event ,'sms')"
                      />
                    </el-col>
                  </el-row>
                </el-form-item> -->
              </el-col>
            </el-row>

          </div>
        </el-col>

      </el-row>

      <el-row>
        <el-col :md="12">
          <template v-if="form.type!=='text_translation'">
            <h4>{{$t('translator_levels')}}</h4>
            <el-checkbox-group
              v-model="form.translator_levels"
              :aria-label="$t('translator_levels')"
            >
              <ul>
                <li v-for="(level, index) in translatorsLevels" :key="index">
                  <el-checkbox
                    :label="level.id"
                    :aria-label="'Translator Level ID : ' + level.id"
                  >
                    {{level.name}}
                  </el-checkbox>
                </li>
              </ul>
            </el-checkbox-group>
          </template>
          <template v-else>
            <h4>{{$t('language_pair_levels')}}</h4>
            <el-checkbox-group
              v-model="form.language_pair_levels"
              aria-label="Language Pair Levels"
            >
              <ul>
                <li>
                  <el-checkbox label="deluxe">{{$t('deluxe')}}</el-checkbox>
                </li>
                <li>
                  <el-checkbox label="certified">{{$t('certified')}}</el-checkbox>
                </li>
                <li>
                  <el-checkbox label="non-certified">{{$t('non-certified')}}</el-checkbox>
                </li>
              </ul>
            </el-checkbox-group>
          </template>

        </el-col>
        <template v-if="form.type !=='text_translation'">
          <el-col :md="12">

            <!-- Gender Field -->
            <h4
              :id="getBem(blockClass, 'gender-label')"
            >
              {{$t('gender')}}
            </h4>
            <el-form-item>
              <el-radio-group
                v-model="form.gender"
                :class="getBem(blockClass, 'gender-field')"
                :aria-labelledby="getBem(blockClass, 'gender-label')"
                tabindex="0"
              >
                <el-radio :label="null">{{$t('all')}}</el-radio>
                <el-radio label="male">{{$t('male')}}</el-radio>
                <el-radio label="female">{{$t('female')}}</el-radio>
              </el-radio-group>
            </el-form-item>

            <!-- Type Field -->
            <h4
              :id="getBem(blockClass, 'type-label')"
            >
              {{ $t('booking_types') }} {{ $t(form.type) }}
            </h4>
            <el-form-item>
              <el-radio-group
                v-model="form.type"
                :class="getBem(blockClass, 'gender-field')"
                :aria-labelledby="getBem(blockClass, 'gender-label')"
                @input="changeText"
                tabindex="0"
              >
                <el-radio label="phone">{{$t('phone')}}</el-radio>
                <el-radio label="physical">{{$t('physical')}}</el-radio>
                <el-radio label="video">{{$t('video')}}</el-radio>
                <el-radio label="video_skype">{{$t('video_skype')}}</el-radio>
                <el-radio label="video_physical">{{$t('video_physical')}}</el-radio>
                <el-radio label="convey">{{$t('convey')}}</el-radio>
              </el-radio-group>
            </el-form-item>

            <template v-if="form.customer.customer_data.allow_fallback_type && form.type==='physical'">
              <el-form-item label="">

                <!-- <el-checkbox v-model="allow_fallback_type">{{fallback_text}} {{form.allow_fallback_type}}</el-checkbox> -->
                <el-switch v-model="form.allow_fallback_type"></el-switch>
                {{fallback_text}}
              </el-form-item>
            </template>

            <template v-if="form.type ==='video_skype'">
              <h4>Video url</h4>
              <el-form-item>
                <el-input v-model="form.video_url"></el-input>
              </el-form-item>
            </template>
          </el-col>
        </template>
      </el-row>


      <template v-if="form.type ==='convey'">
        <convey-edit-form v-model="form.convey"/>
      </template>
      <template v-if="form.type ==='text_translation'">
        <text-translation-edit-form v-model="form.text_translation"/>
      </template>

      <hr>
      <el-row v-if="!['text_translation'].includes(form.type)">
        <el-col>
          <booking-message-form v-model="form.messages"/>
        </el-col>
      </el-row>


      <el-form-item label="" prop="comment">

        <wysiwyg v-model="form.comment" :aria-placeholder="$t('enter_text')" :placeholder="$t('enter_text')"/>
      </el-form-item>

      <el-form-item>
        <el-tooltip v-if="locked !== undefined && locked.length > 0 && locked[0].is_locked === 1" placement="top"
                    :content="locked[0].comment">
          <div class="disabled-btn">
            <el-button :id="genFieldId('save_btn',formElId)" type="primary"
                       :disabled="handleDisableEditButton()" @click="editBooking()">
              {{$t('edit')}}
            </el-button>
          </div>
        </el-tooltip>
        <el-button v-else :id="genFieldId('edit_btn',formElId)" type="primary"
                   :disabled="handleDisableEditButton()" @click="editBooking()">
          {{$t('edit')}}
        </el-button>
        <el-button :id="genFieldId('locked_btn',formElId)" type="primary"
                   @click="handleLockedBooking(status, true, '150vh')">
          {{(locked !== undefined && locked.length > 0 && locked[0].is_locked === 1) ? $t('unlock') :
          $t('lock')}}
        </el-button>
      </el-form-item>

    </el-form>

    <locked-booking-modal :status="status" :top="top" :closeTag="showCloseTag" :lockBooking="lockBooking"
                          :visible.sync="showLockedBookingModal"/>
  </div>
</template>

<script>
import { isEmpty } from "../../../js/helpers/Common";
import { mapActions, mapGetters, mapState } from 'vuex';
import BookingTypeRadio from './partials/BookingTypeRadio.vue';
import GenderTypeRadio from './partials/GenderTypeRadio.vue';
import ConveyEditForm from './partials/ConveyEditForm'
import TextTranslationEditForm from './partials/TextTranslationEditForm';
import BookingMessageForm from './partials/BookingMessageForm';
import { notifError } from "~/js/helpers/Notification";
import BookingHereAddressField from '~/components/forms/misc/BookingHereAddressField';


export default {
  components: {
    BookingHereAddressField,
    BookingTypeRadio,
    GenderTypeRadio,
    ConveyEditForm,
    TextTranslationEditForm,
    BookingMessageForm
  },
    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockClass: 'booking-edit-form',
        disableEditButton: false,
        showCloseTag: false,
        top: '',
        status: '',
        showLockedBookingModal: false,
        formElId: 'booking-edit-form',
        searchCustomerLoading: false,
        searchResult: [],
        email: '',
        selectedCustomer: {},
        translator_id: '',
        // assigned_translator_id:'',
        statusChanged: false,
        comments: '',
        fallback_text: 'Accept Phone?',
        comment: '',
        allow_fallback_type: '',
        specific_translators: [],
        exclude_translators: [],
        toTime: ''
      };
    },// End of Component > data
    computed: {
      ...mapState('booking', {
        form: 'editBookingForm',
        locked: 'locked_booking'
      }),
      bookingId () {
        return !window._.isNil(this.$route.params['id'])
          ? parseInt(this.$route.params['id'])
          : 0;
      },

      userId () {
        return JSON.parse(localStorage.getItem("app_user_data")).id
      },

      ...mapGetters({
        customers: 'booking/getCustomersList',
        customer: 'booking/getCustomer',
        translators: 'booking/getTranslatorsList',
        translatorsLevels: 'booking/getTranslatorLevels',
        selectedTranslatorlevels: 'booking/getSelectedTranslatorLevels',
        languages: 'language/getLanguages',
        townList: 'booking/getTownList'
      }),
      notification_types () {
        return this.form.notification_types;
      },
      rules () {
        const _this = this;
        return {
          session_time: [{
            required: (this.form.status_id === 4 && isEmpty(this.form.session_time)),
            message: _this.$t('tfv_session_time_placeholder'),
            trigger: 'blur'
          }],
          comment: [{required: true, message: _this.$t('tfv_comment_placeholder'), trigger: 'blur'}]
        };
      }
    },
    methods: {

      assignTranslator (form, contFrom, comment) {
        var duration = moment.duration(moment().diff(moment(form.due)));
        var hours = parseInt(duration.asHours());
        let contFlag = typeof contFrom !== 'undefined' ? true : false; // check if it's continued from editBooking()

        if (this.form.status_id !== 2 && !isEmpty(this.form.assigned_translator && hours <= 24) && !contFlag) {
          this.$confirm('Set previous translator as late withdraw?', this.$t('warning'), {
            confirmButtonText: 'YES',
            cancelButtonText: 'NO',
            type: 'warning'
          }).then(() => {
            this.checkAssignTranslator(form, true, comment);
          }).catch(() => {
            this.checkAssignTranslator(form, false, comment);
          });
        } else {
          if (form && form.assigned_translator_id) {
            this.checkAssignTranslator(form, true);
          }
        }
      },
      checkAssignTranslator (form, late_withdraw, comment) {
        const payload = {
          id: form.id,
          translator_id: form.assigned_translator_id,
          late_withdraw: late_withdraw,
          notification_types: this.form.notification_types
        };

        this.assignTranslatorBooking(payload).then((r) => {
          if (!isEmpty(r.data.reasons) && r.status === 'error') {
            let reasons = r.data.reasons.join('\r\n');
            this.$confirm(`${reasons} You sure you want to assign Translator to the job?`, this.$t('warning'), {
              confirmButtonText: this.$t('yes'),
              cancelButtonText: this.$t('no'),
              type: 'warning'
            }).then(() => {
              const forcePayload = {
                id: form.id,
                translator_id: form.assigned_translator_id,
                force_assign: true,
                accepted_via: 'dt-admin-web'
              };
              this.assignTranslatorBooking(forcePayload);
              this.$emit('translator_assigned');
            }).catch(() => {
              console.log('canceled');
            });
          }
        });

      },

      ...mapActions({
        assignCustomer: 'booking/assignCustomer',
        updateBooking: 'booking/updateBooking',
        getBooking: 'booking/getBooking',
        fetchCustomers: 'booking/fetchCustomers',
        filterCustomers: 'booking/filterCustomers',
        createOrUpdateBookingLock: 'booking/createOrUpdateBookingLock',
        assignTranslatorBooking: 'booking/assignTranslator',
        withdrawTranslator: 'booking/withdrawTranslator',
        fetchBooking: 'booking/getBooking'


      }),
      searchCustomer (query) {
        if (query !== '' && query.length > 2) {
          this.searchCustomerLoading = true;
          let params = {
            'filter[name]': query,
            'all': true
          };

          setTimeout(() => {
            this.filterCustomers(params).then((response) => {

              if (isEmpty(response)) {
                let params2 = {
                  'filter[email]': query,
                  'all': true
                };
                this.filterCustomers(params2).then((response) => {
                  this.searchResult = response;

                });
              }

              this.searchResult = response;

              this.searchCustomerLoading = false;
            });

          }, 200);
        } else {
          // this.searchResult = this.customers;
        }
      },

      setNotificationTypes (type, flag) {

        // handle email
        if (type === 'email' && !isEmpty(this.form.email_notifications)) {
          this.form.notification_types = this.form.notification_types.filter(e => e.type !== 'email');
          this.form.email_notifications.forEach((item) => {
            this.form.notification_types.push({
              type: 'email',
              recipient: item
            });
          });
        } else { // handle sms & fax
          this.handleNotifChecks(type, flag);
        }
      },

      handleNotifChecks (type, flag) {
        let nv = this.form[type + '_notification']; // notification value shortened
        this.form.notification_types = this.form.notification_types.filter(e => e.type !== type);
        if (flag) {
          this.form.notification_types.push({
            type: type,
            recipient: nv
          });

          this.$nextTick(() => {
            this.$refs['input_' + type].focus();
          });
        }
      },

      handleNotifInputChange (v, type) {
        this.form.notification_types.find(v => v.type === type).recipient = v; // setting the fax / sms number value to notification type
      },

      editBooking () {

        //Manipulating files and booking message
        this.form.message = this.form.messages.message;
        if (!isEmpty(this.form.messages.files)) {
        this.form.message_files = this.form.messages.files
        }


        this.$refs[this.formElId].validate((valid) => {
          if (valid) {
            let form = _.omit(this.form, ['translators']);
            this.form.customer_id = _.isString(this.form.customer_id) ? this.customer.id : this.form.customer_id;

            if (this.form.type === 'convey') {
              form.convey_files = this.form.convey.files
            }
            if (_.isNil(this.form.comment)) {
              return false;
            }

            const comment = this.form.comment;

            if (this.form.type === 'text_translation') {
              form.text_translation_files = this.form.text_translation.files;
              form.descriptions = this.form.text_translation.descriptions;
              form.additional_text = this.form.text_translation.additional_text;
              form.is_stamped = this.form.text_translation.is_stamped;
              form.is_confidential = this.form.text_translation.is_confidential;
              form.deliver_with = this.form.text_translation.deliver_with;
              form.translator_deadline = this.form.text_translation.translator_deadline;
              delete form.expiry;
            }

            form.gender = isEmpty(form.gender) ? '' : form.gender;
            form.accepted_via = 'dt-admin-web';
            form.assigned_translator_id = this.form.assigned_translator_id;
            form.notification_types = this.form.notification_types;

            if (this.form.allow_fallback_type) {
              if (form.type === 'phone') {
                form.fallback_type = 'physical';
              } else if (form.type === 'physical') {
                form.fallback_type = 'phone';
              }
            } else {
              form.fallback_type = '';
            }
            let duration = moment.duration(moment().diff(moment(form.due)));
            let hours = parseInt(duration.asHours());
            if (this.form.status_id !== 2 && !isEmpty(this.form.assigned_translator && hours < 24)) {
              // form = Object.assign(form,{late_withdraw:true})
              this.$confirm(this.$t('txt_set_previous_translator_as_withdraw'), this.$t('warning'), {
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
                type: 'warning'
              }).then(() => {
                form = Object.assign(form, {late_withdraw: true});
                this.confirmUpdateBooking(form);
              }).catch(() => {
                this.confirmUpdateBooking(form);
              });
            } else {
              this.confirmUpdateBooking(form);
            }
          } else {
            return false;
          }
        });

      },

      confirmUpdateBooking(form){
        this.$confirm(this.$t('tfv_txt_inform_booking_update'), this.$t('warning'), {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
          type: 'warning'
        }).then(() => {
          form.notify_customer = true;
          this.updateBooking(form).then(r => {
            this.addComment();
          });
        }).catch(() => {
          form.notify_customer = false;
          this.updateBooking(form)
              .then(r => {
                this.addComment();
              });
        });
      },

      addComment (comment) {
        if (!_.isNil(this.form.comment)) {
          let commentToUse = '';
          if (this.form.comment.length > 0) {
            commentToUse = this.form.comment;
          } else if (!_.isNil(comment)) {
            commentToUse = comment;
          }
          let data = {
            comment: commentToUse,
            booking_id: this.form.id
          };
          this.$store.dispatch('booking/addComment', data);
          this.form.comment = '';
        } else {
          this.$notify.error({
            title: 'Error while adding a comment.',
            message: 'Please check the error in the console, or contact the Administrator.'
          });
        }
      },
      putAssignTranslator (id) {
        let data = {
          id: id,
          translator_id: this.form.assigned_translator_id
        };
        this.assignTranslator(data);
      },

      handleRelatedTranslatorClick () {
        this.$router.push({name: 'translators.list.tabs', query: {'tab': 'all-translators'}});
      },
      onUpdateAddressAutoComplete (value) {
        this.confirmationFills.address = value.route;
        // this.confirmationFills.town = value.administrative_area_level_1;
        this.form.city = value.administrative_area_level_1;
      },
      changeText (e) {
        switch (e) {
          case 'phone':
            this.fallback_text = 'Accept Physical?';
            break;
          case 'physical':
            this.fallback_text = 'Accept Physical?';
            break;
          case 'video_skype':
            this.form.video_provider = 'skype';
            break;
          case 'video':
            this.form.video_provider = 'jitsi';
            break;

          default:
            break;
        }
      },

      removeTag (event) {
        console.log(event);

      },
      /**
       * Showing modal and other settings of booking locking feature
       */
      handleLockedBooking (status, showClose, top) {
        if (this.locked[0]) {
          this.locked[0].is_locked === 0 ? this.status = 'Lock booking' : this.status = 'Unlock booking';
          this.showLockedBookingModal = true;
          this.showCloseTag = showClose;
          this.top = top;
          // this.showLockedBookingWarningModal = false;
        } else {
          this.status = 'Lock booking';
          this.showLockedBookingModal = true;
          // this.showLockedBookingWarningModal = false;
          this.showCloseTag = showClose;
          this.top = top;
        }
      },
      /**
       * Lock booking method which will create entry in the model
       * for locking current status
       */
      lockBooking (payload) {
        let option = {
          comment: payload.comment,
          is_locked: payload.is_locked,
          is_manual: true,
          booking_id: this.$route.params.id,
          user_id: JSON.parse(localStorage.getItem('app_user_data')).id
        };
        this.createOrUpdateBookingLock(option).then((r) => {
          this.showLockedBookingModal = false;
        });
      },
      /**
       * Method for disabling edit button
       * when booking is locked
       */
      handleDisableEditButton () {
        let locked = this.locked !== undefined && this.locked.length > 0 && this.locked[0].is_locked === 1;
        if (locked === true && this.locked[0].user_id !== JSON.parse(localStorage.getItem('app_user_data')).id) {
          return locked;
        } else {
          return false;
        }
      },
      updateBookingEvent () {
        if (window.WS) {
          window.WS.subscribe('booking.updated')
                .listen('booking.updated', this.updateBookingListener);
        }
      },
      updateBookingListener (data) {
        if (data.booking.id === this.$route.params.id) {
          this.$store.dispatch('booking/getBooking', this.$route.params.id);
        }
      },
      updateAddress (e) {
        this.form.post_code = e.postal_code;
        this.form.city = e.town;
        this.form.coordinates = e.coord;
      },
      empty (v) {
        return isEmpty(v);
      },
      handleToTimeChange () {
        let start = moment(this.form.due, 'YYYY-MM-DDTHH:mm:ss');
        let end = moment(this.toTime, 'YYYY-MM-DDTHH:mm:ss');
        this.form.duration = end.diff(start, 'minutes');
      },
      prepareForm () {
        if (!isEmpty(this.form.due) && !isEmpty(this.form.duration)) {
          this.toTime = new Date(window.moment(this.form.due, 'YYYY-MM-DDTHH:mm:ss').add(this.form.duration, 'minutes').format('YYYY-MM-DDTHH:mm:ss'));
        }
      },

      /**
       * @returns {void}
       */
      handleClickAssignTranslator () {
        const hasSelectedTranslator = !window._.isNil(this.form.assigned_translator_id);

        if (hasSelectedTranslator) {
          this.verifyToAssignThisBooking();

        } else {
          notifError({
            message: 'Please select a translator first'
          });
        }
      },
      /**
       * @returns {void}
       */
      verifyToAssignThisBooking () {
        if (window._.isNil(this.form)) throw new Error('Missing required value this.booking.id');

        const hours = moment().diff(moment(this.form.due), 'hours');
        const isBookingCurrentlyAssigned = !window._.isEmpty(this.form.assigned_translator) && hours <= 24;

        this.$confirm( this.$t('txt_confirm_assign_translator'), this.$t('warning'), {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
          type: 'warning'
        })
          .then(() => {

            if (isBookingCurrentlyAssigned) {
              this.verifySetBookingToLateWithdraw()
                .finally(() => {
                  this.assignSelectedTranslatorToBooking();
                });

            } else {
              this.assignSelectedTranslatorToBooking();
            }
          })
          .catch((e) => {
            if (window._.isObject(e)) console.error(e);
            this.translatorId = null;
          });
      },
      /**
       *
       * @param {Object} options
       * * @param {boolean} options.is_forced
       * @returns {void}
       */
      assignSelectedTranslatorToBooking (options = {}) {
        if (window._.isNil(this.form.id)) throw new Error('Missing required value this.booking.id');
        if (window._.isNil(this.form.assigned_translator_id)) throw new Error('Missing required value this.translatorId');
        options.is_forced = !window._.isNil(options.is_forced) ? options.is_forced : false;

        let payload = {
          id: this.form.id,
          translator_id: this.form.assigned_translator_id,
          expiry_time_partner: this.form.expiry_time_partner
        };

        if (options.is_forced) payload.force_assign = true;

        this.assignTranslatorBooking(payload)
          .then(() => {
            this.fetchBooking(this.form.id);
            this.$emit('translator_assigned');
          })
          .catch((e) => {
            console.error(e);
            const errorData = e.response.data.data;
            if (
              window._.isObject(errorData)
              && window._.has(errorData, 'reasons')
              && !window._.isNil(errorData, 'reasons')
              && !window._.isEmpty(errorData, 'reasons')
            ) {
              this.verifyForceAssignSelectedTranslatorToBooking(errorData.reasons);

            } else if (
              window._.isString(errorData)
              && (
                errorData === 'Du har redan en tolkning den tiden. Tolkning ej accepterad.'
                || errorData === 'Tolkningen har accepterats av annan tolk.'
              )
            ) {
              this.verifyForceAssignSelectedTranslatorToBooking(errorData);
            }
          });
      },

      /**
       * @returns {Promise}
       */
      verifySetBookingToLateWithdraw () {
        // noinspection JSUnusedLocalSymbols
        return new Promise((resolve, reject) => {
          this.$confirm(this.$t('txt_set_previous_translator_as_withdraw'), this.$t('warning'), {
            confirmButtonText: this.$t('yes'),
            cancelButtonText: this.$t('no'),
            type: 'warning'
          })
            .then(() => {
              this.withdrawTranslatorFromBooking(true)
                .then(() => {
                  resolve();
                })
                .catch((e) => console.error(e));
            })
            .catch((e) => {
              if (window._.isObject(e)) console.error(e);
              this.withdrawTranslatorFromBooking()
                .then(() => {
                  // Use resolve just to suppress catch() handling from outside this method.
                  resolve();
                })
                .catch((e) => console.error(e));
            });
        });
      },

      /**
       * @param {boolean} [isLate]
       * @returns {Promise}
       */
      withdrawTranslatorFromBooking (isLate = false) {
        if (window._.isNil(this.form.id)) throw new Error('Missing required value this.booking.id');

        let payload = {booking_id: this.form.id};
        if (isLate) payload.data = {late_withdraw: true};

        return new Promise((resolve, reject) => {
          this.withdrawTranslator(payload)
            .then((r) => {
              this.fetchBooking(this.form.id);
              resolve(r);
            })
            .catch((e) => {
              reject(e);
            });
        });
      },
      /**
       * @param reasons
       * @returns {void}
       */
      verifyForceAssignSelectedTranslatorToBooking (reasons) {
        let message = this.$t('problem_assign_translator_text') + '\n\n';

        if (window._.isArray(reasons)) {
          for (let i = 0; i < reasons.length; i++) {
            message += i + 1 + '. ' + reasons[i] + '\n';
          }

        } else if (window._.isString(reasons)) {
          message += '1. ' + reasons + '\n';
        }

        message += '\n' + this.$t('force_assign_translator_text');

        this.$confirm(message, this.$t('warning'), {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
          type: 'warning',
          customClass: 'force-assign-select-translator-to-booking-modal'
        })
          .then(() => {
            this.assignSelectedTranslatorToBooking({is_forced: true});
          })
          .catch(() => {});
      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      this.updateBookingEvent();
      // this.$refs.comment.focus()
      // this.allow_fallback_type = this.form.allow_fallback_type
      this.searchResult.push(this.form.customer);
      this.prepareForm();
    },

    /*
    |--------------------------------------------------------------------------
    | Component > beforeDestroy
    |--------------------------------------------------------------------------
    */
    beforeDestroy () {
      if (window.WS) {
        window.WS.unsubscribe('booking.updated');
      }
    }
  };// End of export default

</script>
<style lang="scss" scoped>
  .grid-content {
    border: 1px solid #d8dce5;
    border-radius: 5px;
    padding: 10px;
    margin: 15px 0px 15px 0px;

    .title {
      padding: 10px;
    }
  }

  .disabled-btn {
    display: inline-block;
    padding: 0;
    margin: 0;
    border: none;
  }
  .align-bottom {
    position: absolute; bottom: 0; right: 0
  }
</style>

