/*
|--------------------------------------------------------------------------
| store > user > translator > state
|--------------------------------------------------------------------------
|
| This file contains the state property of current Vuex scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import API from '~/js/constants/api';
import {
  PERSONAL_INFO,
  CREDENTIAL,
  SERVICE,
  TRANSLATOR_SETTINGS,
  ADDRESS,
  DEFAULT_QUESTIONS,
  TRANSLATOR_QUESTIONS
} from '~/js/store/user/shared/props'

// Translator KPI's

const TRANSLATOR_KPI = {
  use_points: 0,
  rule : 'add',
  points : 100
}

// Set the values of the compose.
const COMPOSE_PROPS = {
  personal_info: PERSONAL_INFO,
  credential: CREDENTIAL,
  service: SERVICE,
  settings: TRANSLATOR_SETTINGS,
  address: ADDRESS,
  questions: {
    ...DEFAULT_QUESTIONS,
    ...TRANSLATOR_QUESTIONS
  },
  trainings: {
    items: []
  },
  additional_points: {
    ...TRANSLATOR_KPI
  }
}



// Set the values of the paths.
const PATH = API.TRANSLATORS;
const PATH_W_ID = API.TRANSLATORS + '/{id}';

// Set the instance.
export default {

  model_info: {
    name: 'Translator Store',
    module: 'translator',
    table: 'users'
  },

  api: {
    browse: {method: 'get', path: PATH, params: {per_page: 25}},
    read: {
      method: 'get', path: PATH_W_ID,
      params: {
        include: [
          'translator_data',
          'languages',
          'countries',
          'towns',
          'translator_notification_setting',
          'translator_customer_types',
          'allowed_customer_types',
          'disallowed_customer_types',
          'booking_types',
          'language_pair_levels'
        ].join(',')
      }
    },
    edit: {method: 'put', path: PATH_W_ID},
    add: {method: 'post', path: PATH},
    delete: {method: 'delete', path: PATH_W_ID},
  },

  composeFresh: _.cloneDeep(COMPOSE_PROPS),
  compose: _.cloneDeep(COMPOSE_PROPS),

  payloadData: {},
  employees:[],
  pagination:{},
  autoInjectPayload: true,
  autoReflectItem: true,
  autoUpdateList: true,
  delayedBookings:[],

  relationships: [
    'translatorData',
    'languages',
    'countries',
    'blacklistedUsers',
    'bookingTypes',
    'files',
    'createdBy',
    // 'bookedBy',
    // 'confirmationFills',
    // 'towns',
    // 'specificTranslator',
    // 'bookingComment',
    // 'customerType',
    // 'lateAcceptAttempts',
    // 'translatorBookings',
    // 'survey',
    // 'surveyQuestion',
    // 'surveyAnswer',
    'warnings',
    // 'lockedBooking',
    'trainingRecords',
    'unavailableTimes',
    'townUsersPivot',
    // 'translatorCustomerTypesPivot',
    // 'translatorNotificationSetting',
    // 'translatorUnavailableTimes',
    'gdprExportTriggers',
    // 'employeeWorkingHours',
    // 'languagePairLevels',
    // 'textTranslationTranslator',
    'translatorLanguagePairLevels'
  ],
}
