<template>
    <div class="app-form app-fields sector-settings-fields">

        <!-- Organization Number -->
        <el-form-item :id="genContainerId('organization_number',formElId)"
                      :label="$t('organization_number')" class="label-short">
            <el-input :id="genFieldId('organization_number',formElId)"
                      v-model="iValue.organization_number"/>
        </el-form-item>

        <!-- Additional Info -->
        <el-form-item :id="genContainerId('additional_info',formElId)"
                      :label="$t('additional_info')" class="label-short">
            <el-input :id="genFieldId('additional_info',formElId)"
                      v-model="iValue.additional_info"/>
        </el-form-item>

        <!-- GLN -->
        <el-form-item :id="genContainerId('gln',formElId)"
                      label="GLN" class="label-short">
            <el-input :id="genFieldId('gln',formElId)"
                      v-model="iValue.gln"/>
        </el-form-item>

        <!-- Email -->
        <el-form-item :id="genContainerId('email',formElId)"
                      :label="$t('email')" class="label-short">
            <el-input :id="genFieldId('email',formElId)"
                      v-model="iValue.email"/>
        </el-form-item>

        <!-- Cost Place -->
        <!-- <el-form-item :id="genContainerId('cost_place',formElId)"
                      label="Cost Place" class="label-short">
            <el-input :id="genFieldId('cost_place',formElId)"
                      v-model="iValue.cost_place"/>
        </el-form-item> -->

        <!-- Payment Terms -->
        <el-form-item :id="genContainerId('payment_terms',formElId)"
                      :label="$t('payment_terms')" class="label-short">
            <el-input :id="genFieldId('payment_terms',formElId)"
                      v-model="iValue.payment_terms"/>
        </el-form-item>

        <!-- Reference Person -->
        <!-- <el-form-item :id="genContainerId('reference_person',formElId)"
                      label="Reference Person" class="label-short">
            <el-input :id="genFieldId('reference_person',formElId)"
                      v-model="iValue.reference_person"/>
        </el-form-item> -->

        <!-- Invoice Type Field -->
        <el-form-item :id="genContainerId('invoice_type',formElId)"
                      :label="$t('invoice_type')" class="label-short">
            <el-select v-model="iValue.invoice_type" :placeholder="$t('invoice_type')">
                <el-option v-for="type in invoiceTypes" :key="type" :label="type | capitalize" :value="type"></el-option>
            </el-select>
        </el-form-item>

        <!-- Verified For Invoice Field -->
        <el-form-item :id="genContainerId('invoice_verification',formElId)"
                      class="label-short"
                      :label="$t('verified_for_invoice')">

            <el-select v-model="iValue.invoice_verification" :placeholder="$t('verified_for_invoice')">
                <el-option v-for="(key, value) in invoiceSalaryVerification" :key="key" :label="value | capitalize" :value="key"></el-option>
            </el-select>
        </el-form-item>

        <!-- Verified For Invoice Field -->
        <el-form-item :id="genContainerId('salary_verification',formElId)"
                      class="label-short"
                      :label="$t('verified_for_salary')">

            <el-select v-model="iValue.salary_verification" :placeholder="$t('verified_for_salary')">
                <el-option v-for="(key, value) in invoiceSalaryVerification" :key="key" :label="value | capitalize" :value="key"></el-option>
            </el-select>
        </el-form-item>
        <!--<el-form-item :id="genContainerId('email_invoice',formElId)"-->
                      <!--label="Email Invoice" class="label-short">-->
            <!--<el-switch :id="genFieldId('email_invoice',formElId)"-->
                       <!--v-model="iValue.email_invoice"/>-->
        <!--</el-form-item>-->

        <!-- Electronic Invoice -->
        <!--<el-form-item :id="genContainerId('e_invoice',formElId)"-->
                      <!--label="Electronic Invoice" class="label-short">-->
            <!--<el-switch :id="genFieldId('e_invoice',formElId)"-->
                       <!--v-model="iValue.e_invoice"/>-->
        <!--</el-form-item>-->

        <!-- Allow Deluxe? -->
        <el-form-item v-if="sectorType == 'company'"
                      :id="genContainerId('allow_deluxe',formElId)"
                      :label="$t('allow_deluxe')" class="label-short">
            <el-switch :id="genFieldId('allow_deluxe',formElId)"
                       v-model="iValue.allow_deluxe"/>
        </el-form-item>
        <!-- allow_users_view_bookings -->
        <el-form-item v-if="sectorType == 'department'"
                      :id="genContainerId('disallow_users_view_bookings',formElId)"
                      :label="$t('disallow_users_view_bookings')" class="label-short">
            <el-switch :id="genFieldId('disallow_users_view_bookings',formElId)"
                       v-model="iValue.disallow_users_view_bookings"/>
        </el-form-item>

    </div> <!-- /.app-form app-fields sector-settings-fields -->
</template>

<script>
    import {mapGetters} from "vuex";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            formElId: {
                type: String,
                default: '',
            },

            value: {
                type: Object,
                default() {
                    return {
                        organization_number: '',
                        additional_info: '',
                        gln: '',
                        email:'',
                        //cost_place: '',
                        payment_terms: '',
                        invoice_type: '',
                        invoice_verification: '',
                        salary_verification: '',
                        //reference_person: '',
                        allow_deluxe: false,
                        disallow_users_view_bookings:false
                    };
                }
            },

            sectorType: {
                type: String,
                default: 'company',
            }

        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapGetters('form', [
                'invoiceTypes',
                'invoiceSalaryVerification'
            ]),

            /**
             * Interface for the v-model of this component.
             */
            iValue: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                },
            },

        }, // End of Component > computed

    } // End of export default
</script>
