<template>
  <div class="app-list invoice-list">
    <base-list-table
      class="invoice-list-table"
      :table-data="listData"
      :extra-cols="listCols"
      :hasDetailsCol="false">

      <template slot="topControls">
        <div class="app-splitter two-cols">

          <!-- Download Buttons -->
          <div class="download-btn-container">
            <p>Invoice Filter ready to download</p>
            <el-button type="primary" @click="handleDownload">
              <span class="fa fa-download"></span> Download
            </el-button>
            <el-button type="primary" @click="handleDownloadXML">
              <span class="fa fa-download"></span> Download XML
            </el-button>
          </div>

          <!-- Refresh Buttons -->
          <div class="refresh-btn-container">
            <el-button type="default" @click="handleRefreshInvoice">
              <span class="fa fa-refresh"></span> Refresh Invoice
            </el-button>
            <el-button type="default" @click="handleRefreshSalaries">
              <span class="fa fa-refresh"></span> Refresh Salaries
            </el-button>
          </div>
        </div> <!-- /.app-splitter two-cols -->
      </template>
    </base-list-table>

    <!-- Pagination -->
    <el-pagination
      v-if="listPagination.total > listPagination.per_page"
      layout="prev, pager, next"
      :page-size="listPagination.per_page"
      :total="listPagination.total"
      :current-page="listPagination.current_page"
      @current-change="reloadList">
    </el-pagination>

  </div> <!-- /.app-list invoice-list -->
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  // import {populate} from '../../../../js/helpers/Vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters('financial', {
        listData: 'getInvoiceListData',
        listPagination: 'getInvoiceListData',
      }),

      listCols() {
        return [
          {
            label: 'Booking ID',
            prop: 'booking.id',
            width: '70px',
            className: 'booking-id-col',
          },
          {
            label: 'Customer Name',
            prop: 'customer.name',
            className: 'customer-name-col',
          },
          {
            label: 'Customer ID',
            prop: 'customer.id',
            className: 'customer-id-col',
          },
          {
            label: 'Customer Ref ID',
            prop: 'customer.ref_id',
            className: 'customer-ref-id-col',
          },
          {
            label: 'Department Name',
            prop: 'department.name',
            className: 'department-name-col',
          },
          {
            label: 'Language',
            prop: 'booking.language',
            className: 'language-col',
          },
        ];
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions('financial', {
        reloadList: 'browseInvoiceList',
      }),

      handleDownload() {
        console.log('Download button was clicked.');
      },

      handleDownloadXML() {
        console.log('Download XML button was clicked.');
      },

      handleRefreshInvoice() {
        console.log('Refresh Invoice button was clicked.');
      },

      handleRefreshSalaries() {
        console.log('Refresh Salaries button was clicked.');
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted() {
      // populate(this, 'reloadList', 'listData');
    }
  }
</script>
