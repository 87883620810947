<template>
    <div class="app-page" :class="getComponentClass">

        <h3>{{ pageHeaderTitle }}</h3>

        <!-- Top Controls -->
        <div>
            <el-button :class="getBem(blockCls, 'use-template-button')"
                       type="primary" size="mini"
                       @click="handleClickUseTemplate">
                Use Template
            </el-button>

            <!-- TAN: Hidden this button because the manner of selecting -->
            <!-- the entity produces multiple records of pricelists. -->
            <!-- Therefore, its result is not valid to be used for -->
            <!-- the importing feature.-->
            <el-button :class="getBem(blockCls, 'import-settings-button')"
                       type="primary" size="mini"
                       @click="handleClickImportSettings">
                Import Settings
            </el-button>

            <el-button :class="getBem(blockCls, 'reset-button')"
                       type="primary" size="mini"
                       @click="handleClickReset">
                Reset
            </el-button>
        </div>

        <!-- Form -->
        <div v-loading="isLoadingPriceList || isLoadingPriceTemplate || isLoadingImport ">

            <!-- Price list name -->
            <div :class="getBem(blockCls, 'name-container')">
                <span :class="getBem(blockCls, 'name-label')">Name: </span>
                <el-input v-model="priceListName" :class="getBem(blockCls, 'name-field')" />
            </div>

            <el-tabs v-model="activeTab">

                <el-tab-pane :label="$t('tfv_business_rules')" name="business_rules">
                  <price-business-rules-form/>
                </el-tab-pane>

                <el-tab-pane :label="$t('charging')" name="charging">
                    <price-charging-form />
                </el-tab-pane>

                <el-tab-pane :label="$t('transaction_fee')" name="transaction_fee">
                    <price-transaction-fee-form />
                </el-tab-pane>

                <el-tab-pane :label="$t('emergency_booking')" name="emergency_booking">
                    <price-emergency-booking-form />
                </el-tab-pane>

                <el-tab-pane :label="$t('inconvenience_charge')" name="inconvenience_charge">
                    <price-inconvenience-charge-form />
                </el-tab-pane>

                <el-tab-pane :label="$t('travel_settings')" name="travel_settings">
                    <price-travel-settings-form />
                </el-tab-pane>

                <el-tab-pane :label="$t('late_cancellations')" name="late_cancellations">
                    <price-late-cancellations-form />
                </el-tab-pane>

                <el-tab-pane :label="$t('language_settings')" name="language_settings">
                    <price-language-settings-form />
                </el-tab-pane>

                <el-tab-pane :label="$t('text_translation')" name="text_translation">
                    <price-text-translation-form />
                </el-tab-pane>

                <el-tab-pane :label="$t('other_settings')" name="other_settings">
                    <price-other-settings-form />
                </el-tab-pane>
            </el-tabs>

            <div :class="getBem(blockCls, 'actions')">
                <el-button type="primary" @click="saveSettings">
                    {{$t('save')}}
                </el-button>
            </div>
        </div>

        <el-dialog :class="getBem(blockCls, 'template-modal')"
                   class="app-modal-primary with-sidebar-margin"
                   title="Use Template"
                   :visible.sync="isShowTemplateModal"
                   :append-to-body="true"
                   top="10%">
            <financial-settings-template-selector
                @apply="handleApplyTemplate"
            />
        </el-dialog>

        <el-dialog :class="getBem(blockCls, 'import-modal')"
                   class="app-modal-primary with-sidebar-margin"
                   title="Import Settings"
                   :visible.sync="isShowImportModal"
                   :append-to-body="true"
                   width="70%"
                   top="10%">
            <financial-settings-import-selector
                @apply="handleApplyImport"
            />
        </el-dialog>

    </div> <!-- Component DIV Wrapper -->
</template>

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex';
  import FinancialSettingsTemplateSelector
    from '~/components/forms/financial/settings/FinancialSettingsTemplateSelector';
  import FinancialSettingsImportSelector
    from '~/components/forms/financial/settings/FinancialSettingsImportSelector';
  import {populate} from '~/js/helpers/Vuex';
  import isNil from 'lodash/isNil';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      FinancialSettingsTemplateSelector,
      FinancialSettingsImportSelector,
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-settings-compose-partial',
        activeTab: 'business_rules',
        isShowTemplateModal: false,
        isShowImportModal: false,
        priceListName: '',

        // Prepare containers for setting Ids.
        invoiceSettingId: 0,
        salarySettingId: 0,
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters('financialPriceList', {
        isLoadingPriceList: 'isLoadingItem',
        isLoadingPriceTemplate: 'isLoadingTemplateItem',
        isLoadingImport: 'isLoadingImportItem',
        isComposeModeAdd: 'isComposeModeAdd',
        isComposeModeEdit: 'isComposeModeEdit',
      }),

      /**
       * Returns string to be displayed as the page header title.
       *
       * @returns {string}
       */
      pageHeaderTitle () {
        if (this.isComposeModeAdd) {
          return 'Add Financial Setting';

        } else if (this.isComposeModeEdit) {
          return `Financial Settings (${this.itemId})`;
        }
      },

      /**
       * Returns the Item ID based on this.$route.params.id
       *
       * @returns {Integer}
       */
      itemId () {
        return !isNil(this.$route.query)
        && !isNil(this.$route.query.record_id)
          ? parseInt(this.$route.query.record_id)
          : 0;
      },

      /**
       * Helper method for getting value to be used in this component.
       *
       * @returns {string}
       */
      getComponentClass () {
        const def = this.getBem(this.blockCls);

        const suffix = this.isComposeModeAdd ? 'mode-add' : 'mode-edit';
        const modeClass = this.getBem(this.blockCls, '', suffix);

        return `${def} ${modeClass}`;
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapMutations('financialInvoice', {
        resetInvoiceSettings: 'resetSettings',
      }),

      ...mapMutations('financialSalary', {
        resetSalarySettings: 'resetSettings',
      }),

      ...mapMutations('financialPriceList', {
        setComposeMode: 'setComposeMode',
      }),

      ...mapActions('translatorLevel', {
        loadTranslatorLevels: 'browseAsOptions',
      }),

      ...mapActions('financialPriceList', {
        readItem: 'read',
        addItem: 'add',
        editItem: 'edit',
        reloadList: 'uiReloadList',
        loadTemplateItem: 'readTemplateItem',
        loadImportItem: 'readImportItem',
        setPricelistName: 'setPricelistName',
      }),

      ...mapActions('financialInvoice', {
        setInvoiceSettings: 'setSettings',
      }),

      ...mapActions('financialSalary', {
        setSalarySettings: 'setSettings',
      }),

      /**
       * Handler when the Use Template button was clicked.
       *
       * @returns {void}
       */
      handleClickUseTemplate () {
        this.isShowTemplateModal = true;
      },

      /**
       * Handler for the apply event of Template Selector.
       *
       * @param {Object} payload - contains values that came from the emitter,
       *                           which in this case is the targeted price template.
       * @returns {void}
       */
      handleApplyTemplate (payload) {
        this.loadTemplateItem({id: payload.template_id})
            .then((r) => {
              let invoiceSetting = r.data.data.financial_template.invoice_setting;
              invoiceSetting.id = this.invoiceSettingId; // Modify the Setting ID.
              this.setInvoiceSettings(invoiceSetting);

              let salarySetting = r.data.data.financial_template.salary_setting;
              salarySetting.id = this.salarySettingId; // Modify the Setting ID.
              this.setSalarySettings(salarySetting);

              this.isShowTemplateModal = false; // Close the modal.
            });
      },

      /**
       * Handler when the Import button was clicked.
       *
       * @returns {void}
       */
      handleClickImportSettings () {
        this.isShowImportModal = true;
      },

      /**
       * Handler for the apply event of Template Selector.
       *
       * @param {Object} payload - contains values that came from the emitter,
       *                           which in this case is the targeted price list.
       * @returns {void}
       */
      handleApplyImport (payload) {
        this.loadImportItem({id: payload.id})
            .then((r) => {
              let invoiceSetting = r.data.data.pricelist.invoice_setting;
              invoiceSetting.id = this.invoiceSettingId; // Modify the Setting ID.
              this.setInvoiceSettings(invoiceSetting);

              let salarySetting = r.data.data.pricelist.salary_setting;
              salarySetting.id = this.salarySettingId; // Modify the Setting ID.
              this.setSalarySettings(salarySetting);

              this.isShowImportModal = false; // Close the modal.
            });
      },

      /**
       * Handler when the reset button was clicked.
       *
       * @returns {void}
       */
      handleClickReset () {
        if (this.isComposeModeAdd) {
          this.resetInvoiceSettings();
          this.resetSalarySettings();

        } else if (this.isComposeModeEdit) {
          this.initialDataLoad();
        }
      },

      /**
       * Handler for saveSettings event
       * @param {object} opts - contains values usable inside the method.
       * @return {void}
       */
      saveSettings (opts) {
        const entityId = this.$route.query.entity_id;
        const entityType = this.$route.query.entity_type;
        const name = this.priceListName;

        if (isNil(name) || name === '') {
          let errMsg = 'Name value should exist!';

          this.$notify.error({
            title: 'Error',
            message: errMsg,
          });
          throw new Error(errMsg);
        }

        if (isNil(entityId) || isNil(entityType)) {
          let errMsg = 'Entity ID and Entity Type values should exist!';

          this.$notify.error({
            title: 'Error',
            message: errMsg,
          });
          throw new Error(errMsg);
        }

        const payload = {
          name,
          entity_id: entityId,
          entity_type: entityType,
        };

        if (this.isComposeModeAdd) {
          this.addItem(payload)
              .then((r) => {

                this.$notify.success({
                  title: 'Success',
                  message: 'Pricelist successfully saved!',
                });

                if (
                  !isNil(this.$route.query.compose_mode)
                  && this.$route.query.compose_mode === 'add'
                ) {
                  this.$router.push({
                    query: {
                      ...this.$route.query,
                      compose_mode: 'edit',
                      record_id: r.data.data.pricelist.id,
                    },
                  });
                  this.setComposeMode('edit');
                }

                this.reloadList();
              })
              .catch((e) => {
                this.$notify.success({
                  title: 'Error',
                  message: 'Something went wrong, please check on the console log.',
                });
                console.error(e);
              });

        } else if (this.isComposeModeEdit) {
          payload.id = this.itemId;

          this.editItem(payload)
              .then(() => {
                this.$notify.success({
                  title: 'Success',
                  message: 'Pricelist successfully updated!',
                });
                this.reloadList();
              })
              .catch((e) => {
                this.$notify.success({
                  title: 'Error',
                  message: 'Something went wrong, please check on the console log.',
                });
                console.error(e);
              });
        }
      },

      /**
       * Method for initially loading the data.
       *
       * @returns {void}
       */
      initialDataLoad () {

        // Delay to make way for the query param "record_id" value to settle before loading data.
        setTimeout(() => {

          this.readItem({id: this.$route.query.record_id}).then((r) => {
            const pricelist = r.data.data.pricelist;

            if (!isNil(pricelist.invoice_setting)) {
              this.invoiceSettingId = pricelist.invoice_setting.id; // Store the Setting ID for later use.
              this.setInvoiceSettings(pricelist.invoice_setting);
            } else {
              this.invoiceSettingId = 0;
            }

            if (!isNil(pricelist.salary_setting)) {
              this.salarySettingId = pricelist.salary_setting.id; // Store the Setting ID for later use.
              this.setSalarySettings(pricelist.salary_setting);
            } else {
              this.salarySettingId = 0;
            }

            this.priceListName = pricelist.name;
          });

        }, 500);
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      populate(this, 'loadTranslatorLevels', 'formattedTranslatorLevelOpts', {
        ref: 'Translator Level Listing',
      });

      this.resetInvoiceSettings();
      this.resetSalarySettings();


      if (this.isComposeModeEdit) {
        this.initialDataLoad();
      }
    }
  }
</script>

<style lang="scss">
    .financial-settings-compose-partial {
        &__name {
            &-container {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            &-label {
                margin-right: 7px;
                display: inline-block;
            }

            &-field {
                width: 50%;
            }
        }
    }

    // **************************************************
    // Extra-Extra small/Mobile Portrait Viewport
    @media (max-width: 480px - 1) {
        .financial-settings-compose-form {
            &__name {
                &-label {
                    margin-bottom: 7px;
                }

                &-field {
                    width: 100%;
                }
            }
        }
    }

    // **************************************************
    // Extra Small/Mobile Landscape Viewport
    @media (min-width: 480px) and (max-width: 768px - 1) {
        .financial-settings-compose-form {
            &__name {
                &-label {
                    margin-bottom: 7px;
                }

                &-field {
                    width: 100%;
                }
            }
        }
    }
</style>
