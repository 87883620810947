import APICaller from "../../../../helpers/APICaller";
import {gep} from "~/js/helpers/Vuex";
import {Notification} from "element-ui";
import i18n from '~/js/i18n';
// import {extendObjParams, isEmpty, sd} from "~/js/helpers/Common";

let apiPrefix = 'v3';

export default{
  
  

  getBookingIssueTagsPerPage(context, n) {
      return APICaller({
          method: 'get',
          endpoint: gep(`booking-issue-tags/?per_page=${n['per_page']}&page=${parseInt(n['n'])}`, apiPrefix)
      })
          .then((response) => {
              return response;
          }
          ).catch((e) => {

              console.log(e)
              return e
          })
  },
}