/*
|--------------------------------------------------------------------------
| Store > TEST-LANGUAGES
|--------------------------------------------------------------------------
|
| A Class file that manages all the properties and abilities in relation
| to Feedback.
|
*/

import state from './state'
import actions from './actions'
import mutations from './mutations'

export default {
  namespaced:true,
  state,
  actions,
  mutations
}
