<template>

<div class="app-page low-feedback-manager-page">
        <page-header title="Feedbacks List" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col full-width">
                      <div class="app-list booking-mappings-list">

        <div class="app-row list-container">
            <div class="app-col col-container">
                <base-list-table
                        :table-data="feedbacks"
                        :extra-cols="listCols"
                        :hasDetailsCol="false">

                    <!-- Row for Top Controls -->
                    <template slot="topControls" slot-scope="scope">
                        <div class="text-right">
                            <router-link tag="button" to="/aes/add/booking" @click.stop.prevent title="Create"
                                         class="el-button el-button--success">
                                <i class="fa fa-plus"> </i> Create
                            </router-link>
                        </div>
                    </template>

                    <!-- Column for action buttons -->
                    <!-- <template slot="actionsColumn" slot-scope="scope"> -->
                        <!-- <el-button class="circle" type="success" title="Edit">
                            <span class="fa fa-pencil"></span>
                        </el-button>
                        <el-button class="circle" type="danger" title="Delete">
                            <span class="fa fa-trash"></span>
                        </el-button> -->
                    <!-- </template> -->
                </base-list-table>
            </div> <!-- /.app-col -->
        </div> <!-- /.app-row list-container -->

    </div> <!-- /.app-list booking-mappings-list -->

                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page translation-manager-page -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isEmpty } from '../../../js/helpers/Common';
  
export default {
  data(){
    return {
      listCols: [{
          label: 'Review From',
          prop: 'review_from',
          width: '400px',
          mutator(v) {
            return `${v.name} - ${v.email} - ${v.id}`
          },
        },
        {
          label: 'Booking',
          prop: 'booking_id',
          width: '100px',
        
        },
        {
          label: 'Rating',
          prop: 'rating',
          width: '60px',
        },
        {
          label: 'Comments',
          prop: 'comment',
          width: '',
        },
        {
          label: 'Action Comments',
          prop: 'action_comment',
          width: '',
        }
      ],
    }
  },
  computed:{
    ...mapGetters({
      feedbacks:'feedback/getFeedbacks'
    }),
  },
  methods:{
    ...mapActions({
      fetchFeedbacks:'feedback/fetchFeedbacks'
    })
  },
  mounted(){
    let query = {
      include:'booking,review_to,review_from',
      'filter[review_to_id]': this.$route.params.id,
      default:true,
      sort:'-booking_id',
      all:true
    }
    if(!isEmpty(this.$route.query.low) && this.$route.query.low){
      query = Object.assign(query,{
       'filter[rating]':'1,2'
      })
    }
    this.fetchFeedbacks(query);
  }
}
</script>

<style>

</style>
