/*
|--------------------------------------------------------------------------
| Financial > shared > props > textTranslation
|--------------------------------------------------------------------------
*/

'use strict';

import {TRANSLATOR_LVLS} from './constants';
import {genPropsFromCollection} from '~/js/helpers/Vuex';
import filter from 'lodash/filter';

// Get only the two Levels from Tranlator Levels Constant.
let translatorLevels = filter(TRANSLATOR_LVLS, (level) => {
  return level === 'layman' || level === 'certified';
});

const textTranslationFeesProps = {
  price_per_word: 0,
  minimum_charge_fee: 0,
  formatting_hourly_fee: 0,
  minimum_formatting_fee: 0,
  express_hours: 0,
  express_surcharge_percentage: 0,
  verification_fee: 0,
  transaction_fee: 0
};

export default {
  text_translation_fees: genPropsFromCollection(
    translatorLevels, textTranslationFeesProps
  )
};
