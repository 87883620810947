/*
|--------------------------------------------------------------------------
| Financial > shared > props > lateCancellations
|--------------------------------------------------------------------------
*/

'use strict';

import {BOOKING_TYPES} from './constants';
import {genPropsFromCollection} from '~/js/helpers/Vuex';

export default {

  late_cancellation_rules: genPropsFromCollection(
    BOOKING_TYPES, {
      rule: '', // hours_before | time_of_day
      data: {

        // Enabled only if rule is hours_before
        hours: 0,

        // Enabled only if rule is time_of_day
        offset_days: 0,
        time: '00:00:00',
      },
      maximum_minutes_to_pay: 0,
      exclude_weekend: false,
      exclude_holiday: false,
    },
  ),

  pay_late_cancellation_travel_time: false,
  late_cancellation_actual_travel_only: false,
}
