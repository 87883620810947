/*
|--------------------------------------------------------------------------
| Store > Sector > Municipality
|--------------------------------------------------------------------------
|
| A file that manages all the properties and abilities in relation
| to Municipality Model and other related values.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {extendObj} from "../../../helpers/Common";
import bread from '../../bread';
import state from './state';
import mutations from './mutations';
import API from '~/js/constants/api';
import APICaller from "~/js/helpers/APICaller";
import i18n from "../../../i18n";
import { Notification } from "element-ui";

let endpoint = API.SETTINGS;
let getters = {};
let actions = {
    getDefaultMunicipality(context, payload) {
        let params = {
            "filter[name]": 'default_municipality'
        }
        return APICaller({
            method: 'get',
            params,
            endpoint: endpoint
        }).then(resp => {
            return resp;
        }).catch(err => {
            console.log(err)
        })
    },

    updateDefaultMunicipality(context, payload) {
        const endpoint_with_id = `${API.SETTINGS}/${payload.id}`;
        payload.name = 'default_municipality';
        const apiOpts = {
            method: "PUT",
            endpoint: endpoint_with_id,
            data: payload,
            isDataRaw: true
        };
 
        return APICaller(apiOpts)
            .then(() => {
                Notification.success({
                    title: i18n.t("sucesss"),
                    message: "Updated Default Municipality"
                });
            })
            .catch(e => {
                Notification.error({
                    title: i18n.t("error"),
                    message: e.response.data.data
                });
            })
     }
};

export default {
    namespaced: true,
    state: extendObj(bread.state, state),
    getters: extendObj(bread.getters, getters),
    mutations: extendObj(bread.mutations, mutations),
    actions: extendObj(bread.actions, actions),
} // End of export default
