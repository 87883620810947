<template>
    <el-dialog 
     :title="$t('add_new_faq')" 
     :visible.sync="iVisible"
     :modal-append-to-body="false"
     :lock-scroll="false">
        <el-form class="faq-form-modal">
            <el-form-item :label="$t('name')" class="label-short">
                <el-input
                    v-model="faqgroupe.name"
                    :placeholder="$t('faq_name')">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('subtitle')" class="label-short">
                <el-input
                    v-model="faqgroupe.subtitle"
                    :placeholder="$t('faq_subtitle')">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('role')" class="label-short">
                <el-select filterable v-model="faqgroupe.role" :placeholder="$t('select_faq_role')">
                    <el-option v-for="role in roles" :key="role.id" :value="role.name" :label="role.name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="el-form-actions">
                <el-button class="modal-size" type="default" @click="cancelModal()">{{$t('cancel')}}</el-button>
                <el-button class="modal-size" type="primary" @click="addFAQ">{{$t('save')}}</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapActions} from "vuex";

export default {
    name: 'CreateFaqModal',
    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
        visible: {
          type: Boolean,
          default: false
        }
    },
    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data(){
        return {
            payloadParams: {
                'sort': '-created_at'
            },
            dialogFormVisible: false,
            faqgroupe:{
              name:'',
              role:'',
              created_by:'',
              subtitle:'',
            },
            roles:[
             {
               id:1,
               name:'public'
             },
             {
               id:2,
               name:'Admins'
             },
             {
               id:3,
               name:'Translators'
             },
             {
               id:4,
               name:'Customers'
             },
            ]
        }
    },
    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
        ...mapGetters('faq',{
            faqData: 'getFaqs'
        }),
        ...mapGetters({
            userName: 'auth/userName',
            user: 'auth/userData',
        }),
        iVisible: {
            get() {
                return this.visible;
            },
            set(v) {
                this.$emit("update:visible", v);
            }
        },
    },
    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods:{
        ...mapActions('faq',{
            getList: 'browse',
            createFaq:'createFaq'
        }),
        cancelModal() {
            this.iVisible = false;
            this.faqgroupe = {
                name: '',
                role: '',
                created_by:''
            };
        },
        addFAQ() {
            this.faqgroupe.created_by=this.user.id;
            this.createFaq(this.faqgroupe).then(response => {
                this.getList();
                this.cancelModal();
            });
        },
    }
}
</script>
