/*
|--------------------------------------------------------------------------
| Financial > Salary > state
|--------------------------------------------------------------------------
| Partial file for state object related to the scope.
*/

'use strict';

import businessRules from '../shared/props/businessRules';
import charging from '../shared/props/charging';
import transactionFee from '../shared/props/transactionFee';
import emergencyBooking from '../shared/props/emergencyBooking';
import inconvenienceCharge from '../shared/props/inconvenienceCharge';
import travelSettings from '../shared/props/travelSettings';
import lateCancellations from '../shared/props/lateCancellations';
import languageSettings from '../shared/props/languageSettings';
import textTranslation from '../shared/props/textTranslation';
import otherSettings from '../shared/props/otherSettings';

let settingsFresh = {
  id: 0,
  business_rules: _.cloneDeep(businessRules),
  charging: _.cloneDeep(charging),
  transaction_fee: _.cloneDeep(transactionFee),
  emergency_booking: _.cloneDeep(emergencyBooking),
  inconvenience_charge: _.cloneDeep(inconvenienceCharge),
  travel_settings: _.cloneDeep(travelSettings),
  late_cancellations: _.cloneDeep(lateCancellations),
  language_settings: _.cloneDeep(languageSettings),
  text_translation: _.cloneDeep(textTranslation),
  other_settings: _.cloneDeep(otherSettings),
};

export default {

  endpointKey: 'salaries',

  settings: _.cloneDeep(settingsFresh),
  settingsFresh: _.cloneDeep(settingsFresh),

  settingsPayload: {},

  isSettingsLoaded: false,

}
