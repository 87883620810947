<template>
  <div>
   <template v-if="!isEmpty(item)">
  
     <el-row>
       <el-col :span="6">ID</el-col>
       <el-col :span="6">:
         <router-link :to="`/sector/municipality/edit/${item.id}`">
                      <span class="text-success">{{item.id}}</span>
                    </router-link>
         <!-- <el-button type="text" @click="$router.push({name:'municipality.edit',params:{id:item.id}})">{{item.id}}</el-button> -->
         </el-col>
     </el-row>
     <el-row>
       <el-col :span="6">Name</el-col>
       <el-col :span="6">: {{item.name}}</el-col>
     </el-row>
     <el-row>
       <el-col :span="6">Email</el-col>
       <el-col :span="6">: {{item.email}}</el-col>
     </el-row>
     <el-row>
       <el-col :span="6">Organization Number</el-col>
       <el-col :span="6">: {{item.organization_number}}</el-col>
     </el-row>
     <el-row>
       <el-col :span="6">Contract start</el-col>
       <el-col :span="6">: {{item.contract_start}}</el-col>
     </el-row>
     <el-row>
       <el-col :span="6">Contract end</el-col>
       <el-col :span="6">: {{item.contract_end}}</el-col>
     </el-row>
     <el-row>
       <el-col :span="6">Renegotiation Date</el-col>
       <el-col :span="6">: {{item.renegotiation_date}}</el-col>
     </el-row>
   </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from '../../../../js/helpers/Common';
export default {
  props:{
    issue:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return {

    }
  },
  
  computed:{
    ...mapGetters('municipality',{
      item:'itemData'
    }),
    iIssue(){
      return this.issue
    }
  },
  methods:{
  
    isEmpty(v){
      return isEmpty(v)
    }
  }
}
</script>

<style>

</style>
