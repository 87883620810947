<template>
  <div class="app-form app-fields user-customer-personal-info-fields">

    <h3 class="text-center" style="margin-bottom: 35px;">{{$t('personal_info_input')}}</h3>

    <div class="app-splitter two-cols">

      <!-- Name -->
      <el-form-item :id="genContainerId('name',formElId)"
                    :label="$t('name')" class="label-short">
        <el-input :id="genFieldId('name',formElId)"
                  v-model="iValue.name"
                  :placeholder="$t('please_input_customer_name')"/>
      </el-form-item>

      <!-- Notes -->
      <el-form-item :id="genContainerId('notes',formElId)"
                    :label="$t('notes')" class="label-short">
        <el-input :id="genFieldId('notes',formElId)"
                  v-model="iValue.notes"
                  :placeholder="$t('please_input_customer_notes')"/>
      </el-form-item>

      <!-- Email -->
      <el-form-item :id="genContainerId('email',formElId)"
                    :label="$t('email')" class="label-short">
        <el-input :id="genFieldId('email',formElId)"
                  v-model="iValue.email"
                  :placeholder="$t('please_input_customer_email')"/>
      </el-form-item>

      <!-- Phone -->
      <el-form-item :id="genContainerId('phone',formElId)"
                    :label="$t('phone')" class="label-short">
        <el-input :id="genFieldId('phone',formElId)"
                  v-model="iValue.phone"
                  :placeholder="$t('please_input_customer_phone')"/>
      </el-form-item>

      <!-- Mobile -->
      <el-form-item :id="genContainerId('mobile',formElId)"
                    :label="$t('mobile')" class="label-short">
        <el-input :id="genFieldId('mobile',formElId)"
                  v-model="iValue.mobile"
                  @change="handleMobileChange"
                  :placeholder="$t('please_input_customer_mobile')"/>
      </el-form-item>

      <!--      Commenting this because not required by tfv-->

      <!--      &lt;!&ndash; Fax &ndash;&gt;-->
      <!--      <el-form-item :id="genContainerId('fax',formElId)"-->
      <!--                    :label="$t('fax')" class="label-short">-->
      <!--        <el-input :id="genFieldId('fax',formElId)"-->
      <!--                  v-model="iValue.fax"-->
      <!--                  :placeholder="$t('please_input_customer_fax')"/>-->
      <!--      </el-form-item>-->

      <!-- DOB -->
      <el-form-item :id="genContainerId('dob',formElId)"
                    :label="$t('tfv_dob')" class="label-short">
        <el-input :id="genFieldId('dob',formElId)"
                  v-model="iValue.dob"
                  :placeholder="$t('please_input_customer_dob')"/>
      </el-form-item>

      <!-- Username -->
      <el-form-item :id="genContainerId('x_input_usrnm',formElId)"
                    :label="$t('username')" class="label-short">
        <el-input :id="genFieldId('x_input_usrnm',formElId)"
                  v-model="iValue.username" autocomplete="dontfill"
                  :placeholder="$t('please_input_customer_username')"/>
      </el-form-item>

    </div> <!-- /.app-splitter two-cols -->

  </div> <!-- /.app-form app-fields user-customer-personal-info-fields -->
</template>

<script>
  import {makeMobileInternational} from "~/js/helpers/Common";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      formElId: {
        type: String,
        default: ''
      },

      value: {
        type: Object,
        required: true
      }

    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data() {
      return {};
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      handleMobileChange() {
        this.iValue.mobile = makeMobileInternational(this.iValue.mobile);
      }
    },
    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        }
      }

    }

  }; // End of export default
</script>
