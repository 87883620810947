export default {
  isLoadingVerbiages: false,
  isLoadingLastUpdate: false,
  verbiages: {},
  lastUpdate: null,
  newLastUpdate: null,

  verbiagesCookieKey: 'verbiages',
  lastUpdateCookieKey: 'verbiages_last_update'
};
