<template>
  <div class="app-form price-travel-settings-form">

    <div class="app-splitter two-cols app-flex-top">

      <!-- Invoice Form -->
      <div>
        <h3 class="app-title title-shaded">
          <span>{{$t('invoice')}}</span>
        </h3>
        <el-form :id="invoiceFormElId" :ref="invoiceFormElId" style="padding: 0 5px;">
          <financial-travel-settings-fields
            :form-el-id="invoiceFormElId"
            v-model="invoiceForm.travel_settings"
          />
        </el-form>
      </div>

      <!-- Salary Form -->
      <div>
        <h3 class="app-title title-shaded">
          <span>{{$t('salary')}}</span>
        </h3>
        <el-form :id="salaryFormElId" :ref="salaryFormElId" style="padding: 0 5px;">
          <financial-travel-settings-fields
            :form-el-id="salaryFormElId"
            v-model="salaryForm.travel_settings"
          />
        </el-form>
      </div>

    </div> <!-- /.app-splitter three-cols -->

  </div> <!-- /.app-form price-travel-settings-form -->
</template>

<script>
  import {mapState} from 'vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data() {
      return {
        invoiceFormElId: 'invoice_travel_settings_form',
        salaryFormElId: 'salary_travel_settings_form'
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('financialInvoice', {
        invoiceForm: 'settings',
      }),

      ...mapState('financialSalary', {
        salaryForm: 'settings',
      }),
    },

  } // End of export default
</script>
