<template>
  <div class="app-page create-blogs-page">
    <page-header :title="$t('blogs')" :back-enabled="true"/>

    <div class="app-container">
      <div class="app-wrapper">
        <div class="app-row">
          <el-row :gutter="20">
            <el-form :model="form" :rules="rules" ref="blog-form">
              <ElCol :span="16">
                <div class="app-col full-width">
                  <h4>{{$t('title')}}</h4>
                  <el-form-item label prop="title">
                    <el-input v-model="form.title" :placeholder="$t('enter_title')"></el-input>
                  </el-form-item>
                  <h4>{{$t('edit_your_post')}}</h4>
                  <el-form-item label prop="content">
                    <!--                    <vue-editor-->
                    <!--                      useCustomImageHandler-->
                    <!--                      :editor-toolbar="customToolbar"-->
                    <!--                      @image-added="handleImageAdded"-->
                    <!--                      v-model="form.content"-->
                    <!--                    />-->
                    <ckeditor :editor="editor" v-model="form.content"  @ready="onReady"></ckeditor>
                  </el-form-item>
                </div>
              </ElCol>
              <ElCol :span="8">
                <div class="app-col full-width">
                  <h4>{{$t('publish_to')}}</h4>
                  <el-form-item label prop="tags">
                    <el-select v-model="form.tags" multiple :placeholder="$t('select')">
                      <el-option v-for="item in tags" :key="item" :label="$t(`tfv_${item}`)" :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                  <!-- <h4>Category</h4>
                  <el-form-item label prop="category">
                    <el-checkbox-group v-model="form.category">
                      <el-checkbox v-for="item in form.category" :label="item" :key="item">{{item}}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item> -->
                  <!-- <el-input v-model="addCategory" placeholder="Enter category name"></el-input> -->
                  <!-- <el-form-item>
                    <div class="text-center">
                      <a href="#" @click.prevent.stop="handleAddCategory">
                        <u>Add new category</u>
                      </a>
                    </div>
                  </el-form-item> -->

                  <el-row>

                    <el-col :md="8">

                      <h4>{{$t('featured_blog')}}</h4>
                      <el-form-item>
                        <el-switch v-model="form.is_featured"></el-switch>
                      </el-form-item>
                    </el-col>

                    <el-col :md="8">

                      <h4>{{$t('is_critical')}}</h4>
                      <el-form-item>
                        <el-switch :active-value="1" :inactive-value="0" v-model="form.is_critical"></el-switch>
                      </el-form-item>
                    </el-col>

                  </el-row>

                  <el-form-item v-if="form.is_critical === 1">
                    <el-input type="textarea" rows="5" v-model="form.critical_message">

                    </el-input>
                  </el-form-item>

                  <el-form-item>
                    <div class="text-center">
                      <el-upload
                        class="avatar-uploader"
                        action
                        :show-file-list="false"
                        ref="upload"
                        :before-upload="beforeAvatarUpload"
                        :http-request="uploadSectionFile"
                      >
                        <img v-if="form.feature_image" :src="form.feature_image" class="avatar"/>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <h4>{{$t('featured_image')}}</h4>
                    </div>
                  </el-form-item>

                  <hr/>
                  <h4>{{$t('actions')}}</h4>
                  <div class="action-panel">
                    <ul style="list-style-type:none;">
                      <li>
                        <el-button type="primary" @click="handleSubmit('publish')">
                          <i class="fa fa-check-square" aria-hidden="true"></i>
                          <span class="action-btn" >{{$t('publish')}}</span>
                        </el-button>
                      </li>
                      <li>
                        <el-button type="success" @click="handleSubmit('draft')">
                          <i class="fa fa-envelope-open-o"  aria-hidden="true"></i>
                          <span class="action-btn" >{{$t('save_draft')}}</span>
                        </el-button>
                      </li>
                      <li>
                        <el-button type="default" @click="handleCancel">{{$t('cancel')}}</el-button>
                      </li>
                    </ul>
                  </div>
                </div>
              </ElCol>
            </el-form>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {checkBreadMode} from '~/js/helpers/Route';
  import {mapState, mapActions, mapMutations} from 'vuex';
  import Ckeditor from '@ckeditor/ckeditor5-vue'
  import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
  import firebase from 'firebase/app';
  import 'firebase/storage';
  import ToolBarSetting from '../toolbar-settings/toolbar';

  export default {

    components: {
      ckeditor: Ckeditor.component
    },
    data() {
      return {
        addCategory: '',
         tags: ['translator', 'customer', 'public'],
        customToolbar: ToolBarSetting,
        editor: DecoupledEditor,
        editorConfig: {
          // The configuration of the editor.
          plugins: [
            // Base64UploadAdapter
          ]
        }
      };
    },

    computed: {
      ...mapState('blogs', {
        form: 'compose'
      }),

      /**
       * Rules defined for the form which fields are required
       *
       */
      rules() {
        return {
          title: [
            {required: true, message: 'Please input title', trigger: 'blur'}
          ],
          content: [
            {required: true, message: 'Please input content', trigger: 'blur'}
          ],
          category: [
            {required: true, message: 'Please input category', trigger: 'blur'}
          ],
          tags: [
            {
              required: true,
              message: 'Please select publish to',
              trigger: 'blur'
            }
          ]
        };
      },

      /**
       * Returns boolean whether if the current compose page
       * processes an ADD action.
       *
       * @returns {boolean}
       */
      isAdd() {
        return this.breadMode === 'add';
      },

      /**
       * Returns boolean whether if the current compose page
       * processes an EDIT action.
       *
       * @returns {boolean}
       */
      isEdit() {
        return this.breadMode === 'edit';
      },

      /**
       * Returns the compose mode of the page. Varies between add | edit.
       *
       * @returns {string}
       */
      breadMode() {
        return checkBreadMode(this);
      },

      /**
       * Return the Id of the current blog
       */
      blogId() {
        return !window._.isNil(this.$route.params) &&
        !window._.isNil(this.$route.params.id)
          ? parseInt(this.$route.params.id)
          : 0;
      }
    },
    methods: {
      ...mapActions('blogs', {
        getSpecificBlog: 'getSpecificBlog',
        updateBlog: 'updateBlog',
        createBlog: 'createBlog'
      }),
      ...mapMutations('blogs', {
        resetForm: 'resetCompose'
      }),
      prepareBlogPage() {
        this.resetForm();
        if (this.isEdit) {
          this.getSpecificBlog(this.blogId);
        }
      },
      handleAddCategory() {
        if (this.addCategory) {
          this.form.category.push(this.addCategory);
        }
      },
      handleSubmit(mode) {
        switch (mode) {
          case 'draft': {
            this.form.status = 1;
            if (this.isAdd) {
              this.form.author_id = JSON.parse(
                localStorage.getItem('app_user_data')
              ).id;
              this.$refs['blog-form'].validate(valid => {
                if (valid) {

                  this.createBlog(this.form);
                }
              });
            } else {
              this.$refs['blog-form'].validate(valid => {
                if (valid) {
                  this.updateBlog(this.form);
                }
              });
            }
            break;
          }
          case 'publish': {
            this.form.status = 2;
            if (this.isAdd) {
              this.form.author_id = JSON.parse(
                localStorage.getItem('app_user_data')
              ).id;
              this.$refs['blog-form'].validate(valid => {
                if (valid) {
                  this.createBlog(this.form);
                }
              });
            } else {
              this.$refs['blog-form'].validate(valid => {
                if (valid) {
                  this.updateBlog(this.form);
                }
              });
            }
            break;
          }
        }
      },
      handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
        firebase
          .storage()
          .ref('images/' + file.name)
          .put(file)
          .then(r =>
            r.ref.getDownloadURL().then(downloadUrl => {
              Editor.insertEmbed(cursorLocation, 'image', downloadUrl);
              resetUploader();
            })
          );
      },
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!file.type === 'image/jpeg' || !file.type === 'image/png') {
          this.$message.error('Avatar picture must be JPG/png format!');
        }
        if (!isLt2M) {
          this.$message.error('Avatar picture size can not exceed 2MB!');
        }
        console.log(file.type === 'image/jpeg');
        return isLt2M;
      },
      uploadSectionFile(payload) {
        console.log(payload);
        firebase
          .storage()
          .ref('images/' + payload.file.name)
          .put(payload.file)
          .then(r =>
            r.ref.getDownloadURL().then(downloadUrl => {
              this.form.feature_image = downloadUrl;
            })
          );
        console.log(payload);
      },
      handleChange() {
        this.$refs.upload.submit();
      },
      handleCancel() {
        this.$router.push({name: 'messaging.blog'});
      },
      onReady( editor )  {
        // Insert the toolbar before the editable area.
        editor.ui.getEditableElement().parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
      },
      createUploadAdapter(file) {
        debugger;
      }
    },
    mounted() {
      this.prepareBlogPage();
    }
  };
</script>

<style lang="scss" scoped>
  .action-panel .el-button {
    min-width: 220px;
    font-size: 12px;
    margin-left: 20px;
  }

  .action-panel .el-button i.fa {
    float: left;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .ul-inline {
    display: inline-block;
    padding: 0;
    vertical-align: top;
    margin-left: 20px;
  }

</style>
