<template>
    <div class="app-form app-fields user-translator-settings-fields">

        <h3 class="text-center">{{$t('text_fill_settings')}}</h3>

        <div class="app-splitter two-cols">

            <!-- Tax -->
            <el-form-item :id="genContainerId('tax',formElId)"
                          :label="$t('tax')" class="label-block">
                <el-input :id="genFieldId('tax' ,formElId)"
                          v-model="iValue.tax"/>
            </el-form-item>

            <!-- Tax Type -->
            <el-form-item :id="genContainerId('tax_type',formElId)"
                          :label="$t('tax_type')" class="label-block">
                <el-select :id="genFieldId('tax_type' ,formElId)" v-model="iValue.tax_type" :filterable="false" :allow-create="false">
                  <el-option
                    v-for="item in taxTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
               </el-select>
            </el-form-item>

            <!-- Bank Name -->
            <el-form-item :id="genContainerId('bank_name',formElId)"
                          :label="$t('bank_name')" class="label-block">
                <el-input :id="genFieldId('bank_name' ,formElId)"
                          v-model="iValue.bank_name"/>
            </el-form-item>

            <!-- Bank Account Number -->
            <el-form-item :id="genContainerId('bank_account_number',formElId)"
                          :label="$t('bank_account_number')" class="label-block">
                <el-input :id="genFieldId('bank_account_number' ,formElId)"
                          v-model="iValue.bank_account_number"/>
            </el-form-item>

        </div> <!-- /.app-splitter two-cols -->

    </div> <!-- /.app-form app-fields user-translator-settings-fields -->
</template>

<script>
  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      // Prop v-model of the component.
      value: {
        type: Object,
        required: true
      },

      // String reference for form element ID.
      formElId: {
        type: String,
        default: 'user_translator_settings'
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data() {
      return {
        taxTypes: [{
          value: 'table',
          label: 'table'
        }, {
          value: 'standard',
          label: 'standard %'
        }],
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        }
      }
    }

  }
</script>
