/*
|--------------------------------------------------------------------------
| Financial > Shared > methods > setSettings
|--------------------------------------------------------------------------
| Partial file as a reusable method for it's purpose.
*/

'use strict';

let TLVL_OPTS = [];

export default function (context, payload) {

  // Reset settings state.
  context.state.settings = _.cloneDeep(context.state.settingsFresh);

  const state = context.state;
  const BUSINESS_RULES = state.settings.business_rules;
  const CHARGING = state.settings.charging;
  const TRANSACTION_FEE = state.settings.transaction_fee;
  const EMERGENCY_BOOKING = state.settings.emergency_booking;
  const INCONVENIENCE_CHARGE = state.settings.inconvenience_charge;
  const TRAVEL_SETTINGS = state.settings.travel_settings;
  const LATE_CANCELLATIONS = state.settings.late_cancellations;
  const LANGUAGE_SETTINGS = state.settings.language_settings;
  const TEXT_TRANSLATION = state.settings.text_translation;
  const OTHER_SETTINGS = state.settings.other_settings;
  TLVL_OPTS = context.rootGetters['translatorLevel/formattedTranslatorLevelOpts'];

  const isInvoice = state.endpointKey === 'invoices';
  // const isSalary = state.endpointKey === 'salaries';

  console.log('Applying API response to Settings State', payload);

  // Set the settings ID.
  state.settings.id = payload.id;

  // ----------------------------------------
  // ----- BUSINESS RULES -----
  // ----------------------------------------

  aie(BUSINESS_RULES, 'parts_step', payload.parts_step);
  aie(BUSINESS_RULES, 'parts_max', payload.parts_max);
  aie(BUSINESS_RULES, 'rounding_minutes', payload.rounding_minutes);

  assignPartsTimings(BUSINESS_RULES, payload.parts_timings);
  assignBookingTimings(BUSINESS_RULES, payload.booking_timings);

  // aie(BUSINESS_RULES, 'late_cancellation_rules',
  //   arrToObj(payload.late_cancellation_rules, 'booking_type', {
  //     include: ['hours'],
  //   }),
  // );

  // ----------------------------------------
  // ----- CHARGING -----
  // ----------------------------------------

  aie(CHARGING, 'use_parts', payload.use_parts);
  aie(CHARGING, 'is_flat_rate', payload.is_flat_rate);

  assignCharges(CHARGING, payload);

  // ----------------------------------------
  // ----- TRANSACTION FEE -----
  // ----------------------------------------

  aie(TRANSACTION_FEE, 'pay_transaction_fee', payload.pay_transaction_fee);
  aie(TRANSACTION_FEE, 'transaction_fees',
    arrToObj(payload.transaction_fees, 'booking_method'));

  if (context.state.endpointKey === 'invoices') {
    if (!_.isNil(payload.office_hours_fee)) {
      aie(TRANSACTION_FEE.office_hours_fee,
        'pay_outside_hours', payload.office_hours_fee.pay_outside_hours);
      aie(TRANSACTION_FEE.office_hours_fee, 'fee', payload.office_hours_fee.fee);
      aie(TRANSACTION_FEE.office_hours_fee, 'start_time', payload.office_hours_fee.start_time);
      aie(TRANSACTION_FEE.office_hours_fee, 'end_time', payload.office_hours_fee.end_time);
      aie(TRANSACTION_FEE.office_hours_fee, 'transaction_fee_percentage',
        payload.office_hours_fee.transaction_fee_percentage);
    }

    aie(TRANSACTION_FEE, 'pay_booking_type_fee', payload.pay_booking_type_fee);
    aie(TRANSACTION_FEE, 'booking_type_fees',
      arrToObj(payload.booking_type_fees, 'booking_type'));

    aie(TRANSACTION_FEE, 'pay_translator_level_fee', payload.pay_translator_level_fee);
    aie(TRANSACTION_FEE, 'translator_level_fees',
      mTranslatorLevelFees(payload.translator_level_fees));
  }


  // ----------------------------------------
  // ----- EMERGENCY BOOKING -----
  // ----------------------------------------

  // aie(EMERGENCY_BOOKING, 'pay_immediate_fee', payload.pay_immediate_fee);
  // aie(EMERGENCY_BOOKING, 'immediate_fees',
  //   arrToObj(payload.immediate_fees, 'booking_type'));

  if (!_.isNil(payload.emergency_setting)) {
    aie(EMERGENCY_BOOKING, 'pay_emergency_fee', payload.emergency_setting.pay_emergency_fee);
    aie(EMERGENCY_BOOKING, 'emergency_minutes', payload.emergency_setting.emergency_minutes);
  }

  aie(EMERGENCY_BOOKING, 'emergency_fees',
    arrToObj(payload.emergency_fees, 'booking_type'));

  aie(EMERGENCY_BOOKING, 'pay_rush_booking_fee', payload.pay_rush_booking_fee);
  aie(EMERGENCY_BOOKING, 'rush_booking_fees',
    arrToObj(payload.rush_booking_fees, 'booking_type'));

  aie(EMERGENCY_BOOKING, 'different_type_immediate', payload.different_type_immediate);

  // ----------------------------------------
  // ----- TRAVEL SETTINGS -----
  // ----------------------------------------

  if (!_.isNil(payload.travel_setting)) {

    aie(TRAVEL_SETTINGS, 'pay_travel_distance',
      payload.travel_setting.pay_travel_distance);
    aie(TRAVEL_SETTINGS, 'maximum_km',
      payload.travel_setting.maximum_km);
    aie(TRAVEL_SETTINGS, 'minimum_travel_distance_fee',
      payload.travel_setting.minimum_travel_distance_fee);
    aie(TRAVEL_SETTINGS, 'pay_travel_time',
      payload.travel_setting.pay_travel_time);
    aie(TRAVEL_SETTINGS, 'travel_time_chunk_hours',
      payload.travel_setting.travel_time_chunk_hours);

    aie(TRAVEL_SETTINGS, 'pay_fixed_time',
      payload.travel_setting.pay_fixed_time);
    aie(TRAVEL_SETTINGS, 'fixed_time_hours',
      payload.travel_setting.fixed_time_hours);

    aie(TRAVEL_SETTINGS, 'round_travel_time',
      payload.travel_setting.round_travel_time);
    aie(TRAVEL_SETTINGS, 'rounding_minutes',
      payload.travel_setting.rounding_minutes);

    aie(TRAVEL_SETTINGS, 'pay_inconvenience_travel_time',
      payload.travel_setting.pay_inconvenience_travel_time);
    aie(TRAVEL_SETTINGS, 'unpaid_travel_hours',
      payload.travel_setting.unpaid_travel_hours);

    aie(TRAVEL_SETTINGS, 'minimum_travel_hours',
      payload.travel_setting.minimum_travel_hours);
    aie(TRAVEL_SETTINGS, 'maximum_travel_hours',
      payload.travel_setting.maximum_travel_hours);

    aie(TRAVEL_SETTINGS, 'travel_distance_fees',
      mTravelFees(payload.travel_distance_fees));

    aie(TRAVEL_SETTINGS, 'travel_time_fees',
      mTravelFees(payload.travel_time_fees));

    aie(TRAVEL_SETTINGS, 'inconvenience_travel_time_fees',
      mTravelFees(payload.inconvenience_travel_time_fees));

    aie(TRAVEL_SETTINGS, 'pay_same_town_travel_time',
      payload.travel_setting.pay_same_town_travel_time);

    aie(TRAVEL_SETTINGS, 'specific_cities',
      payload.travel_setting.specific_cities);
  }

  // ----------------------------------------
  // ----- INCONVENIENCE CHARGE -----
  // ----------------------------------------

  if (!_.isNil(payload.inconvenience_setting)) {

    aie(INCONVENIENCE_CHARGE, 'start_time',
      payload.inconvenience_setting.start_time);

    aie(INCONVENIENCE_CHARGE, 'end_time',
      payload.inconvenience_setting.end_time);

    aie(INCONVENIENCE_CHARGE, 'is_flat_rate',
      payload.inconvenience_setting.is_flat_rate);

    aie(INCONVENIENCE_CHARGE, 'flat_rate',
      payload.inconvenience_setting.flat_rate);

    aie(INCONVENIENCE_CHARGE, 'inconvenience_fees',
      mInconvenienceFees(payload.inconvenience_fees));

    aie(INCONVENIENCE_CHARGE, 'holiday_fees',
      mInconvenienceFees(payload.holiday_fees));

    aie(INCONVENIENCE_CHARGE, 'weekend_fees',
      mInconvenienceFees(payload.weekend_fees));

  }

  // ----------------------------------------
  // ----- LATE CANCELLATIONS -----
  // ----------------------------------------

  assignLateCancellationRules(LATE_CANCELLATIONS, payload.late_cancellation_rules);

  // Originally the two props below belongs to travel settings,
  // but categorized inside late cancellations

  if (!_.isNil(payload.travel_setting)) {
    aie(LATE_CANCELLATIONS, 'pay_late_cancellation_travel_time',
      payload.travel_setting.pay_late_cancellation_travel_time);

    aie(LATE_CANCELLATIONS, 'late_cancellation_actual_travel_only',
      payload.travel_setting.late_cancellation_actual_travel_only);
  }

  // ----------------------------------------
  // ----- LANGUAGE SETTINGS -----
  // ----------------------------------------

  assignLanguages(LANGUAGE_SETTINGS, payload.languages);

  // ----------------------------------------
  // ----- TEXT TRANSLATION SETTINGS -----
  // ----------------------------------------

  assignTextTranslationFees(TEXT_TRANSLATION, payload.text_translation_fees);

  // ----------------------------------------
  // ----- OTHER SETTINGS -----
  // ----------------------------------------

  aie(OTHER_SETTINGS, 'allowance_setting', payload.allowance_setting);

  if (
    !_.isNil(payload.allowance_setting)
    && !_.isNil(payload.allowance_setting.pay_allowance)
    && payload.allowance_setting.pay_allowance
  ) {
    aie(OTHER_SETTINGS, 'allowance_fees', payload.allowance_fees);
  }

  aie(OTHER_SETTINGS, 'expense_setting', payload.expense_setting);

  if (isInvoice) {
    aie(OTHER_SETTINGS, 'transparency_enabled', payload.transparency_enabled);

    aie(OTHER_SETTINGS, 'transparency_fields', payload.transparency_fields);
  }

  // noinspection JSUnresolvedVariable
  if (
    !_.isNil(payload.translator_level_setting)
    && !_.isNil(payload.translator_level_setting.level_order)
    && !_.isEmpty(payload.translator_level_setting.level_order)
  ) {
    // noinspection JSUnresolvedVariable
    OTHER_SETTINGS.translator_level_order = payload.translator_level_setting.level_order;
  }

} // End of export default function

/**
 * Helper method for mapping out response
 * values of charges to the form.
 *
 * @param {array} arr
 * @return {object}
 */
function mTranslatorLevelFees (arr) {
  let result = {};
  _.each(arr, (item) => {
    // TODO: Refactor method
    if (item.translator_level_id !== 7) {
      const tlvl = findTlvl(item.translator_level_id, 'id', 'code');

      // If the container is empty, instantiate it.
      if (_.isNil(result[tlvl])) {
        result[tlvl] = {};
      }

      result[tlvl][item.booking_type] = {
        fee: item.fee
      };
    }
  });

  return result;
}

/**
 * Helper method for mapping out response
 * values of charges to the form.
 *
 * @param {array} arr
 * @param {string} key
 * @return {object}
 */
function mTravelFees (arr, key) {
  let result = {};
  _.each(arr, (item) => {
    // TODO: Refactor method
    if (item.translator_level_id !== 7) {
      const tlvl = findTlvl(item.translator_level_id, 'id', 'code');

      // If the container is empty, instantiate it.
      if (_.isNil(result[tlvl])) {
        result[tlvl] = {};
      }

      result[tlvl] = item;
    }
  });

  return result;
}

/**
 * Helper method for mapping out response
 * values of charges to the form.
 *
 * @param {array} arr
 * @return {object}
 */
function mInconvenienceFees (arr) {
  let result = {};
  _.each(arr, (item) => {
    // TODO: Refactor method
    if (item.translator_level_id !== 7) {
      const tlvl = findTlvl(item.translator_level_id, 'id', 'code');

      // If the container is empty, instantiate it.
      if (_.isNil(result[tlvl])) {
        result[tlvl] = {};
      }

      result[tlvl][item.booking_type] = {
        minimum_fee: item.minimum_fee,
        succeeding_fee: item.succeeding_fee
      };
    }
  });

  return result;
}

/**
 * Helper method to convert the array into a keyed object.
 *
 * @param arr - the collection to be iterated.
 * @param keyRef - the identifier to use as the object key
 * @param {object} options
 * @param {array} options.include - keys only to include (in the object) before assignment.
 *
 * @return {object}
 */
function arrToObj (arr, keyRef, options = {}) {

  if (_.isNil(arr) || _.isEmpty(arr)) return {};

  let result = {};

  _.each(arr, (item) => {

    let key;

    if (
      keyRef === 'translator_level_id' &&
      (item.translator_level_id !== 7)
    ) {
      key = findTlvl(item[keyRef], 'id', 'code');

    } else {
      key = item[keyRef];
    }

    if (
      !_.isNil(options.include)
      && !_.isEmpty(options.include)
    ) {
      result[key] = _.pick(item, options.include);

    } else {
      result[key] = item;
    }


  });

  return result;
}

/**
 * Assign if Exists (aif)
 * is a helper method to assign the value only if it exists.
 *
 * @param {object} obj - the container where to assign the value into.
 * @param {*} key - the key reference of the assignment.
 * @param {*} val - the value to be assigned.
 * @param {object} opts - contains option values usable inside the method.
 * @param {*} opts.def - the default value if to assign if preferred.
 * @param {boolean} opts.assign - boolean to determine wether to just assign values in existing props.
 * @param {Function} opts.mutator - mutator of the value.
 *
 * @return {void}
 */
function aie (obj, key, val, opts = {}) {
  const mutator = _.isNil(opts.mutator) ? (xv) => xv : opts.mutator;

  if (_.isObjectLike(val) && !_.isEmpty(val) && !_.isNil(opts.assign) && opts.assign) {
    obj[key] = _.assign(obj[key], mutator(val));

  } else if (_.isObjectLike(val) && !_.isEmpty(val)) {
    _.merge(obj[key], mutator(val));

  } else if (!_.isObjectLike(val) && !_.isNil(val)) {
    obj[key] = mutator(val);

  } else if (!_.isNil(opts.def)) {
    obj[key] = opts.def;
  }
}

/**
 * @param {object} dest
 * @param {object[]} collection
 * @returns {void}
 */
function assignPartsTimings (dest, collection) {
  const cn = arrToObj(collection, 'booking_type');

  _.forOwn(cn, (obj, bookingType) => {
    dest.parts_timings[bookingType].parts_step = obj.parts_step;
    dest.parts_timings[bookingType].parts_max = obj.parts_max;
  });
}

/**
 * @param {object} dest
 * @param {object[]} collection
 * @returns {void}
 */
function assignBookingTimings (dest, collection) {
  const cn = arrToObj(collection, 'booking_type');

  _.forOwn(cn, (obj, bookingType) => {
    dest.booking_timings[bookingType].minimum_hours = obj.minimum_hours;
    dest.booking_timings[bookingType].succeeding_hours = obj.succeeding_hours;
  });
}

/**
 * Helper method for applying values to CHARGING.charges
 * @param {object} dest
 * @param {object} src
 */
function assignCharges (dest, src) {
  const srcCharges = src.charges;
  const srcPartsFees = src.parts_fees;

  // Charges
  if (!_.isNil(srcCharges) && !_.isEmpty(srcCharges)) {
    _.each(srcCharges, (item) => {

      if (item.translator_level_id !== 7) {
        const tlvl = findTlvl(item.translator_level_id, 'id', 'code');
        const bt = item.booking_type;

        if (_.isNil(dest.charges[tlvl][bt])) dest.charges[tlvl][bt] = {};

        dest.charges[tlvl][bt].minimum_fee = item.minimum_fee;
        dest.charges[tlvl][bt].succeeding_fee = item.succeeding_fee;
      }
    });
  }

  // Parts Fees
  if (!_.isNil(srcPartsFees) && !_.isEmpty(srcPartsFees)) {
    _.each(srcPartsFees, (item) => {

      if (item.translator_level_id !== 7) {
        const tlvl = findTlvl(item.translator_level_id, 'id', 'code');
        const bt = item.booking_type;
        const step = 'x' + _.replace(item.step, '.', '_');

        dest.charges[tlvl][bt].parts_fees[step] = item.fee;
      }
    });
  }
}

/**
 * @param {object} dest
 * @param {object[]} languages
 * @returns {void}
 */
function assignLanguages (dest, languages) {
  if (!_.isNil(languages) && !_.isEmpty(languages)) {
    dest.languages = _.map(languages, 'language_id');
  }
}

/**
 * @param {object} dest
 * @param {object[]} textTranslationFees
 * @param textTranslationFees
 */
function assignTextTranslationFees (dest, textTranslationFees) {
  _.forEach(textTranslationFees, (fee) => {
    const translatorLevelName = getTranslatorLevelName(fee.translator_level_id);
    _.unset(fee, 'translator_level_id');

    dest.text_translation_fees[translatorLevelName] = fee;
  });
}

/**
 * @param {object} dest
 * @param {object[]} collection
 * @returns {void}
 */

function assignLateCancellationRules (dest, collection) {

  const cn = arrToObj(collection, 'booking_type');

  _.forOwn(cn, (obj, bookingType) => {

    dest.late_cancellation_rules[bookingType].rule = obj.rule;

    if (!_.isNil(obj.data)) {
      if (obj.rule === 'hours_before') {
        dest.late_cancellation_rules[bookingType].data.hours = obj.hours;

      } else if (obj.rule === 'time_of_day') {

        dest.late_cancellation_rules[bookingType].data.offset_days = obj.data.offset_days;
        dest.late_cancellation_rules[bookingType].data.time = obj.data.time;
      }
    }

    dest.late_cancellation_rules[bookingType].maximum_minutes_to_pay = obj.maximum_minutes_to_pay;
    dest.late_cancellation_rules[bookingType].exclude_weekend = obj.exclude_weekend;
    dest.late_cancellation_rules[bookingType].exclude_holiday = obj.exclude_holiday;
  });

}

/**
 * Helper method for finding and returning a Translator Level
 *
 * @param {*} find - value to be searched.
 * @param {string} via - key on where to look
 * @param {string} [key] - if provided, method will return the value of this key.
 *                         But if not, method will just return the whole found object.
 *
 * @return {*}
 */
function findTlvl (find, via, key = '') {
  let found = window._.find(TLVL_OPTS, (x) => x[via] === find);

  if (!_.isNil(found)) {
    return key !== '' ? found[key] : found;

  } else {
    console.error('There is no Translator Level equivalent for:');
    console.error('FIND: ', find);
    console.error('VIA: ', via);
  }
}

/**
 * @param {int} translatorLevelId
 * @returns {string}
 */
function getTranslatorLevelName (translatorLevelId) {
  return findTlvl(translatorLevelId, 'id', 'code');
}
