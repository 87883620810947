<template>
  <div class="app-form price-business-rules-form">

    <div class="app-splitter two-cols app-flex-space-b-top">

      <!-- Invoice Form Container -->
      <div class="invoice-business-rules-form-container">

        <h3 class="app-title title-shaded">
          <span>{{$t('invoice')}}</span>
        </h3>

        <el-form :id="invoiceFormElId" :ref="invoiceFormElId" style="padding: 0 7px">
          <financial-business-rules-fields
              v-model="invoiceForm.business_rules"
              :is-use-parts-enabled="isInvoiceUsePartsEnabled"
              :is-enabled-emergency-charges="isEnabledInvoiceEmergencyCharges"
              :form-el-id="invoiceFormElId"
          />
        </el-form>
      </div>

      <!-- Salary Form Container -->
      <div class="salary-business-rules-form-container">

        <h3 class="app-title title-shaded">
          <span>{{$t('salary')}}</span>
        </h3>

        <el-form :id="salaryFormElId" :ref="salaryFormElId" style="padding: 0 7px">
          <financial-business-rules-fields
              v-model="salaryForm.business_rules"
              :is-use-parts-enabled="isSalaryUsePartsEnabled"
              :is-enabled-emergency-charges="isEnabledSalaryEmergencyCharges"
              :form-el-id="salaryFormElId"
          />
        </el-form>
      </div>

    </div>
    <!-- /.app-splitter two-cols -->

  </div> <!-- /.app-form price-business-rules-form -->
</template>

<script>
  import {mapState, mapGetters} from 'vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data() {
      return {
        invoiceFormElId: 'invoice_business_rules_form',
        salaryFormElId: 'salary_business_rules_form',
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('financialInvoice', {
        invoiceForm: 'settings',
      }),

      ...mapState('financialSalary', {
        salaryForm: 'settings',
      }),

      ...mapGetters('financialInvoice', {
        isInvoiceUsePartsEnabled: 'isUsePartsEnabled',
        isEnabledInvoiceEmergencyCharges: 'isEnabledEmergencyCharges',
      }),

      ...mapGetters('financialSalary', {
        isSalaryUsePartsEnabled: 'isUsePartsEnabled',
        isEnabledSalaryEmergencyCharges: 'isEnabledEmergencyCharges',
      }),
    },

  } // End of export default
</script>
