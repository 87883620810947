<template>
<el-form-item class="full-width center-block" style="max-width: 500px;" id="select-status">
    <el-select v-model="iStatus" :placeholder="$t('select_status')" filterable>
      <el-option v-for="t in statusList" :key="t.id" :value="t.id" :label="$t(`tfv_status_${t.code}`)"></el-option>
    </el-select>
</el-form-item>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props:{
        value: {
            default: '',
        }
    },
    name: 'booking-status-select',
    
    computed:{
        ...mapGetters({
            statusList: 'booking/getStatusList'
        }),
         iStatus: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit('input', v);
            },
        },
    }
}
</script>

<style>

</style>
