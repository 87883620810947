  <template>
    <el-table
    :data="tableData"
      style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="id"
        label="ID">
      </el-table-column>
      <el-table-column
        label="Name">
        <template slot-scope="scope">
          <p @click="handleClick(scope.row.id)"><u>{{scope.row.name}}</u></p>
        </template>
      </el-table-column>
    </el-table>
  </template>

  <script>
    import APICaller from "../../../js/helpers/APICaller";
    import { gep } from "../../../js/helpers/Vuex";
    export default {
      data() {
        return {
        }
      },
      props: {
        user: '',
        tableData: {
          type: Array,
          default() {
            return [];
          }
        },
        handleSelectionChange: {
         type : Function,
         default: () => {}
         }
      },

      methods: {
        handleClick(id) {
          if(this.user == 'customer'){
            this.$router.push({name:'user.edit', params: {id: id}, query:{type: 3}})
          }
          else if(this.user == 'company'){
            this.$router.push({name:'company-edit', params: {id: id}, query:{type: 3}})
          }
          if(this.user == 'department'){
            this.$router.push({name:'department.edit', params: {id: id}, query:{type: 3}})
          }
        },
      },

      mounted() {
        }, // End of Component > mounted
    }
  </script>