/*
|--------------------------------------------------------------------------
| Store > User > Mutations
|--------------------------------------------------------------------------
|
| This file contains the mutations property of User Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

export default {
    /**
     * Method to fill deleted users from the payload
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    setTrashedUsers(state, payload) {
        state.trashedUsers = payload.data.users;
        state.paginationTrashedUsers = payload.meta.pagination;
    },

} // End of export default
