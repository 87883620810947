<template>
    <div class="app-list test-users-list">
        <el-row :gutter="20">
          <el-col :span="12">
              <el-form :model="form">
            <el-form-item label="">
                <el-input v-model="form.email" placeholder="Enter name or email" width="80%"></el-input>
                <el-button type="success"> submit</el-button>
            </el-form-item>
          </el-form>
          </el-col>
          
          <el-col :span="12">
            <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="danger" title="Delete">
                    <span class="fa fa-trash"></span>
                </el-button>
            </template>
        </base-list-table>
          </el-col>
        </el-row>

        
    </div> <!-- /.app-list test-users-list -->
</template>

<script>
    //import {mapGetters} from vuex

        export default {

        /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                listCols: [
                    {
                        label: 'name',
                        prop: 'name',
                        width: '',
                        className: 'name-col',
                    }
                ],
                form: {
                    email: ''
                },
                listData: [
                    {id: 1, name:'test 1'},
                    {id: 2, name:'test 2'},
                    {id: 3, name:'test 3'},
                ]
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            //...mapGetters('',['listData'])

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
        methods: {}, // End of Component > methods


        /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {}, // End of Component > mounted

    }
</script>