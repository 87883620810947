/*
|--------------------------------------------------------------------------
| Store > translator-unavailable-times > Mutations
|--------------------------------------------------------------------------
|
| This file contains the mutations property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
import {isEmpty, setTimedLocalStorage} from '../../helpers/Common';


export default {

  setError: (state, payload) => state.error = payload,
  /**
   * Set unavailable list state
   *
   * @param {object} state
   *  : current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {array}
   */
  setUnavailables: (state, payload) => {
    let key = Object.keys(payload.data)[0];
    state.unavailables = payload.data[key];
    state.pagination = payload.meta.pagination;
  },

  /**
   * Set Unavailable object state
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {object}
   */
  setUnavailable: (state, payload) => {

    let key = Object.keys(payload.data)[0];
    state.unavailable = payload.data[key];
  },

}
