<template>
    <div class="app-form" :class="genBem(blockCls)">

        <el-form>

            <div class="app-splitter two-cols">
                <!-- Name -->
                <el-form-item :class="genBem(blockCls, 'field')"
                              class="label-short"
                              :label="$t('tfv_fn_export_name')">
                    <el-input
                            v-model="iValue.name"
                            :class="genBem(blockCls, 'field__name')"
                            :placeholder="$t('tfv_txt_enter_name')"
                            size="mini"/>
                </el-form-item>

                <!-- Salary Payment Date -->
                <el-form-item :class="genBem(blockCls, 'field')"
                              class="label-short"
                              :label="$t('tfv_fn_salary_payment_date')">
                    <el-date-picker
                            v-model="iValue.salary_payment_date"
                            :class="genBem(blockCls, 'field__salary_payment_date')"
                            type="date"
                            :placeholder="$t('tfv_day_pick_label')"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            popper-class="day-picker-only"
                            size="mini">
                    </el-date-picker>
                </el-form-item>

            </div> <!-- DIV column splitter -->

            <el-collapse>
                <el-collapse-item>
                    <template slot="title">
                        <h3>{{ $t('options') }}</h3>
                    </template>

                    <div class="app-splitter two-cols">

                        <!-- Period Start -->
                        <el-form-item :class="genBem(blockCls, 'field')"
                                      class="label-short"
                                      :label="$t('period_start')">
                            <el-date-picker
                                    v-model="iValue.period_start"
                                    :class="genBem(blockCls, 'field__period-start')"
                                    type="date"
                                    :placeholder="$t('tfv_day_pick_label')"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    popper-class="day-picker-only"
                                    size="mini">
                            </el-date-picker>
                        </el-form-item>

                        <!-- Period End -->
                        <el-form-item :class="genBem(blockCls, 'field')"
                                      class="label-short"
                                      :label="$t('period_end')">
                            <el-date-picker
                                    v-model="iValue.period_end"
                                    :class="genBem(blockCls, 'field__period-end')"
                                    type="date"
                                    :placeholder="$t('tfv_day_pick_label')"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    popper-class="day-picker-only"
                                    size="mini">
                            </el-date-picker>
                        </el-form-item>

                        <!-- Month -->
                        <el-form-item :class="genBem(blockCls, 'field')"
                                      class="label-short"
                                      :label="$t('tfv_month_txt')">
                            <el-select
                                    v-model="iValue.options.month"
                                    :class="genBem(blockCls, 'field__month')"
                                    size="mini" filterable>
                                <el-option v-for="o in monthOpts"
                                           :key="o.key"
                                           :value="o.key"
                                           :label="o.label"/>
                            </el-select>
                        </el-form-item>

                        <!-- Bookings -->
                        <el-form-item :class="genBem(blockCls, 'field')"
                                      class="label-short"
                                      :label="$t('booking_number')">
                            <el-select
                                    v-model="iValue.options.bookings"
                                    :class="genBem(blockCls, 'field__bookings')"
                                    placeholder="Enter Booking IDs"
                                    size="mini"
                                    allow-create
                                    filterable
                                    default-first-option
                                    multiple>
                                <el-option label="Type in Booking ID" :value="0"></el-option>
                            </el-select>
                        </el-form-item>

                        <!-- Companies -->
                        <el-form-item :class="genBem(blockCls, 'field')"
                                      class="label-short"
                                      :label="$t('companies')">
                            <el-select
                                    v-model="iValue.options.companies"
                                    :class="genBem(blockCls, 'field__companies')"
                                    size="mini" filterable multiple>
                                <el-option v-for="o in companyOpts"
                                           :key="o.key"
                                           :value="o.key"
                                           :label="o.label"/>
                            </el-select>
                        </el-form-item>

                        <!-- Departments -->
                        <el-form-item :class="genBem(blockCls, 'field')"
                                      class="label-short"
                                      :label="$t('departments')">
                            <el-select
                                    v-model="iValue.options.departments"
                                    :class="genBem(blockCls, 'field__departments')"
                                    size="mini" filterable multiple>
                                <el-option v-for="o in departmentOpts"
                                           :key="o.key"
                                           :value="o.key"
                                           :label="o.label"/>
                            </el-select>
                        </el-form-item>


                    </div> <!-- DIV column splitter -->

                </el-collapse-item>
            </el-collapse>

            <!-- Submit Button -->
            <el-form-item :class="genBem(blockCls, 'submit-btn')">
                <el-button type="primary" @click="handleClickSubmit">
                    {{ $t('submit') }}
                </el-button>
            </el-form-item>

        </el-form>

    </div> <!-- Component DIV Wrapper -->
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import {populate} from "~/js/helpers/Vuex";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */

    props: {

      value: {
        type: Object,
        default () {
          return {};
        }
      },


    },


    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-export-compose-financial-list-form'
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters('company', {
        companyOpts: 'listAsOptionsPaired',
      }),

      ...mapGetters('department', {
        departmentOpts: 'listAsOptionsPaired',
      }),

      /**
       * Returns the Financial List ID according to URL param ID
       *
       * @returns {int}
       */
      financialListId () {
        return window._.isNil(this.$route.params.id)
          ? 0 : parseInt(this.$route.params.id);
      },

      /**
       * Returns options for month selector.
       *
       * @returns {array}
       */
      monthOpts () {
        return [
          {label: 'January', key: 'January'},
          {label: 'February', key: 'February'},
          {label: 'March', key: 'March'},
          {label: 'April', key: 'April'},
          {label: 'May', key: 'May'},
          {label: 'June', key: 'June'},
          {label: 'July', key: 'July'},
          {label: 'August', key: 'August'},
          {label: 'September', key: 'September'},
          {label: 'October', key: 'October'},
          {label: 'November', key: 'November'},
          {label: 'December', key: 'December'},
        ];
      },

      /**
       * Interface for v-model/this.value of this component.
       */
      iValue: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v);
        },
      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions('company', {
        loadCompanyOpts: 'browseAsOptions'
      }),

      ...mapActions('department', {
        loadDepartmentOpts: 'browseAsOptions'
      }),

      /**
       * Handle whent the submit button was clicked.
       *
       * @return {void}
       */
      handleClickSubmit () {
        this.$emit('submit');
      },

      /**
       * Method to group the loading of the options.
       *
       * @return {void}
       */
      populateOpts () {

        populate(this, 'loadCompanyOpts', 'companyOpts', {
          ref: 'Company Options'
        });

        populate(this, 'loadDepartmentOpts', 'departmentOpts', {
          ref: 'Department Options'
        });

      },

    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      window.moment.tz.setDefault();
      this.populateOpts();
    },

    /*
    |--------------------------------------------------------------------------
    | Component > beforeDestroy
    |--------------------------------------------------------------------------
    */
    beforeDestroy () {
      // Set the timezone back to what's defined in bootstrap.js.
      window.moment.tz.setDefault("Europe/Stockholm");
    }

  }
</script>