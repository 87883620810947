<template>
   <div class="app-page blogs-page">
      <page-header :title="formTitle" />
      <div class="app-container">
         <div class="app-wrapper">
            <div class="app-row">
               <div class="app-col full-width">
                  <el-form class="form-modal">
                     <el-form-item :label="$t('translator_trainings')" class="label-block">
                        <el-select v-model="form.training_id"
                                    :placeholder="$t('select_training')"
                                    remote
                                    :remote-method="searchTrainings"
                                    :loading="searchTrainingLoading" clearable auto-complete="off"
                                    filterable
                        >
                           <el-option v-for="i in searchTrainingResult" :key="i.id" :label="i.name"
                                 :value="i.id">
                           </el-option>
                        </el-select>
                      </el-form-item>
                     <el-form-item :label="$t('language')" class="label-block">
                        <el-input v-model="form.language" />
                     </el-form-item>
                     <el-form-item :label="$t('grade')" class="label-block">
                        <el-input v-model="form.grade" />
                     </el-form-item>
                     <el-form-item :label="$t('tfv_course_coordinator')" class="label-block">
                        <el-input v-model="form.course_coordinator" />
                     </el-form-item>
                     <el-form-item :label="$t('trainer')" class="label-block">
                        <el-input v-model="form.trainer" />
                     </el-form-item>
                     <el-form-item :label="$t('hours_of_raining')" class="label-block">
                        <el-input v-model="form.hours_of_training" />
                     </el-form-item>
                     <el-form-item :label="$t('university_credits')" class="label-block">
                        <el-input v-model="form.university_credits" />
                     </el-form-item>
                     <el-form-item :label="$t('tfv_excution_start')">
                        <el-date-picker v-model="form.execution_from" type="date" :placeholder="$t('tfv_txt_select_date')" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                           popper-class="day-picker-only">
                        </el-date-picker>
                     </el-form-item>
                     <el-form-item :label="$t('tfv_excution_end')">
                        <el-date-picker v-model="form.execution_to" type="date" :placeholder="$t('tfv_txt_select_date')" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                           popper-class="day-picker-only">
                        </el-date-picker>
                     </el-form-item>
                     <el-form-item>
                        <el-button type="primary" @click="handleSave">
                            {{ $t(breadMode) }}
                        </el-button>
                      </el-form-item>
                  </el-form>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { checkBreadMode } from "~/js/helpers/Route";
import {mapGetters, mapActions} from "vuex";
import {isEmpty,sd} from "~/js/helpers/Common";
import {populate} from "~/js/helpers/Vuex";

export default {
  props:{
    title:{
      type:String,
      default: 'Translator Trainings'
    },
    refs:{
      default: 'trainings'
    }
  },
  data(){

    const _this = this;
    return {
      form: {
          training_id: null,
          user_translator_id: 0,
          language: '',
          grade: '',
          course_coordinator: '',
          trainer: '',
          hours_of_training: 0,
          university_credits: '',
          execution_from: '',
          execution_to: ''
      },
      searchTrainingResult: [],
      searchTrainingLoading:false,

    }
  },
  computed:{
    ...mapGetters('trainings', ['listData']),

    breadMode() {
      return checkBreadMode(this);
    },

    formTitle() {
      return checkBreadMode(this)=== 'add' ? this.$t('add_training') : this.$t('edit_training');
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {
    populate(this, 'browse', 'listData', {
      force: true
    });

    if (this.breadMode === "edit") {
       this.readEntry({id: this.$route.params.id}).then(r => {
          this.form = r;
       });
    }
  }, // End of Component > mounted
  methods: {

    ...mapActions('trainings', {
        browse: 'browse',
        readEntry: 'getTrainingRecord',
        editEntry: 'editTrainingRecord',
        save: 'saveTrainingRecordByTranslator',
        filterTrainings: 'filterTrainings'
      }),

   handleSave() {
      if (this.breadMode === 'add') {
         this.form.user_translator_id = this.$route.params.id;
         let payload = {
            user_translator_id: this.$route.params.id,
            form: this.form
         }
         this.save(payload).then(r => {
            this.$router.push({path: '/user/profile/' + this.$route.params.id + '?type=3'});
         });
      } else {
         let payload = {
            id: this.$route.params.id,
            user_translator_id: this.form.user_translator_id,
            form: this.form
         }
         this.editEntry(payload);
      }
   },
   searchTrainings(query){
      if(query!==''){
         this.searchTrainingLoading = true;
         let params ={
            'filter[name]': query,
            'all': true
         }

         setTimeout(()=> {
            this.filterTrainings(params).then((response) => {
               this.searchTrainingResult =  response
               this.searchTrainingLoading = false;
            })
         },300)

      }else{
         this.searchTrainingResult = []
      }
   },

  }
}
</script>

<style>

</style>
