<template>
  <div class="expense-types-list">

    <div class="expense-types-list__top">
      <el-button type="success" @click="addExpenseType">
        <i class="fa fa-plus"></i>
        <span>{{$t('add')}}</span>
      </el-button>
    </div>

    <el-table :data="listData">
      <el-table-column :label="$t('name')">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('actions')">
        <template slot-scope="scope">
          <el-button class="circle" type="success" title="Edit" @click="editExpenseType(scope)">
            <span class="fa fa-pencil"></span>
          </el-button>
          <el-button class="circle" type="danger" title="Remove" @click="deleteExpenseType(scope)">
            <span class="fa fa-trash"></span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="listPagination.total > listPagination.per_page"
      layout="prev, pager, next"
      :page-size="listPagination.per_page"
      :total="listPagination.total"
      :current-page="listPagination.current_page"
      @current-change="reloadList"
    />

    <expense-type-dialog
      ref="expenseTypeDialog"
      @updated="handleUpdated"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { populate } from '~/js/helpers/Vuex'
// components
import ExpenseTypeDialog from './ExpenseTypeDialog'

const moduleTarget = 'expenseTypes'

export default {
  components: {
    ExpenseTypeDialog
  },
  computed: {
    ...mapGetters(moduleTarget, ['listData', 'listPagination'])
  },
  methods: {
    ...mapActions(moduleTarget, [
      'browse',
      'delete'
    ]),
    addExpenseType () {
      this.$refs.expenseTypeDialog.open()
    },
    editExpenseType (scope) {
      this.$refs.expenseTypeDialog.open(scope.row)
    },
    deleteExpenseType (scope) {
      this.$confirm('This will permanently delete the expense type. Continue?', this.$t('warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.delete({ id: scope.row.id })
      }).catch(() => {
        // Silence is golden.
      });
    },
    handleUpdated () {
      this.browse()
    }
  },
  mounted () {
    populate(this, 'browse', 'listData', {
      force: true,
      ref: 'Expense Types Listing'
    })
  }
}
</script>

<style lang="scss">
.expense-types-list {
  &__top {
    text-align: right;
    padding-bottom: 10px;
  }
}
</style>
