/*
|--------------------------------------------------------------------------
| Store > User > Admin > Mutations
|--------------------------------------------------------------------------
|
| This file contains the mutations property of User > Admin Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {isEmpty, mapProps} from "../../../helpers/Common";

export default {

    /**
     * Method to map the value target entry's values into the form.
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    setCompose(state) {
        mapProps(state.item.data, state.compose);
    },

    /**
     * Method to reset the compose prop to its pristine state.
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    resetCompose(state) {
        state.compose = _.cloneDeep(state.composeFresh);
    },

    /**
     * Method to map the form values into the payload
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    setPayloadData(state) {
        _.merge(
            state.payloadData, // The destination of the merged values.

            // Below are source of the values to be merged.
            state.compose,
        );

        if  (isEmpty(state.payloadData.password) &&
            isEmpty(state.payloadData.password_confirmation)) {
            _.unset(state.payloadData, 'password');
            _.unset(state.payloadData, 'password_confirmation');
        }

    }, // End of preparePayload
    /**
     * Method to fill deleted users from the payload
     *
     * @param  {object} state
     *   : the state property the current Vuex Object.
     *
     * @return {void}
     */
    setTrashedUsers(state, payload) {
        state.trashedUsers = payload.data.users;
        state.paginationTrashedUsers = payload.meta.pagination;
    },

} // End of export default
