<template>
        <canvas :id="chartId"></canvas>
</template>
<script>
    import Chart from 'chart.js';
    import pieChartData from '../data/chart-data-pie.js';
     export default {
        mounted() {
            this.pieChart(this.colorOne,this.colorTwo,this.firstVal,this.secVal);
        },
        props: ["chart-id","color-one","color-two","first-val","sec-val"],
        
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                pieChartData : pieChartData
            };
        }, // End of Component > data
        methods: {
            pieChart: function(ColorOne, ColorSec, ValOne, ValSec){
              var piechartdata = {
                labels: [
                'Count', 'Total',
                ],
                datasets: [{
                  data: [ValOne, ValSec],
                  backgroundColor: [
                  ColorOne,
                  ColorSec
                  ]
                }]
              };
              this.createChart(this.chartId, this.pieChartData, piechartdata);
            },
            createChart(mychartId, chartData, DataPie) {
                const ctx1 = document.getElementById(mychartId);
                const myChart = new Chart(ctx1, {
                type: chartData.type,
                data: DataPie,
                options: chartData.options,
                plugins :[{
                beforeDraw: function(chart)  {
                    var width = chart.chart.width,
                        height = chart.chart.height,
                        ctx = chart.chart.ctx;

                    ctx.restore();
                    var fontSize = (height / 85).toFixed(2);
                    ctx.font = fontSize + "em sans-serif";
                    ctx.textBaseline = "middle";
                    
                    var percent = Math.round((parseFloat(chart.config.data.datasets[0].data[0]) * 100) / (parseFloat(chart.config.data.datasets[0].data[0]) + parseFloat(chart.config.data.datasets[0].data[1])));
                    var text = percent + "%",
                        textX = (Math.round((width - ctx.measureText(text).width) / 2) + 5),
                        textY = (height / 2) + 5;

                    ctx.fillText(text, textX, textY);
                    ctx.save();
                  }
                  }]
                });

            }
        }
    }
</script>