<template>
    <div class="app-form app-fields sector-batches-settings-trans-lvls-prio-fields">

        <!-- Layman -->
        <el-form-item :id="genContainerId('layman',formElId)"
                      :label="$t('layman')" class="label-medium">
            <el-input :id="genFieldId('layman',formElId)"
                      v-model="form.layman"/>
        </el-form-item>

        <!-- Read Translation courses -->
        <el-form-item :id="genContainerId('read_courses',formElId)"
                      :label="$t('read_translation_courses')" class="label-medium">
            <el-input :id="genFieldId('read_courses',formElId)"
                      v-model="form.read_courses"/>
        </el-form-item>

         <!-- Trained -->
        <el-form-item :id="genContainerId('trained',formElId)"
                      :label="$t('trained_translator')" class="label-medium">
            <el-input :id="genFieldId('trained',formElId)"
                      v-model="form.trained"/>
        </el-form-item>

        <!-- Certified -->
        <el-form-item :id="genContainerId('certified',formElId)"
                      :label="$t('certified')" class="label-medium">
            <el-input :id="genFieldId('certified',formElId)"
                      v-model="form.certified"/>
        </el-form-item>

        <!-- Certified with specialisation in health care -->
        <el-form-item :id="genContainerId('certified_in_health',formElId)"
                      :label="$t('certified_with_specialisation_health_care')" class="label-medium">
            <el-input :id="genFieldId('certified_in_health',formElId)"
                      v-model="form.certified_in_health"/>
        </el-form-item>

        <!-- Certified with specialisation in law -->
        <el-form-item :id="genContainerId('certified_in_law',formElId)"
                      :label="$t('certified_with_specialisation_law')" class="label-medium">
            <el-input :id="genFieldId('certified_in_law',formElId)"
                      v-model="form.certified_in_law"/>
        </el-form-item>

        <!-- Deluxe -->
        <el-form-item :id="genContainerId('Deluxe',formElId)"
                      :label="$t('deluxe')" class="label-medium">
            <el-input :id="genFieldId('',formElId)"
                      v-model="form.deluxe"/>
        </el-form-item>

    </div> <!-- /.app-form app-fields sector-batches-settings-trans-lvls-prio-fields -->
</template>

<script>

    let formFresh = {
        layman: '',
        read_courses: '',
        certified: '',
        certified_in_health: '',
        certified_in_law: '',
        deluxe: '',
        trained: '',
    };

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            formElId: {
                type: String,
                default: '',
            },

            form: {
                type: Object,
                default() {
                    return _.cloneDeep(formFresh);
                }
            },

        }, // End of Component > props

        mounted() {
            console.log(this.form);
        }

    } // End of export default
</script>
