/*
|--------------------------------------------------------------------------
| Financial > Invoice > Main Store File
|--------------------------------------------------------------------------
| The main store file for the said scope/namespace.
*/

'use strict';
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state, getters, mutations, actions
}