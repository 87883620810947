/*
|--------------------------------------------------------------------------
| Store > Financial > Credits > Actions
|--------------------------------------------------------------------------
| File to define the actions that is relevant for the current scope.
*/

'use strict';

import APICaller from '~/js/helpers/APICaller';
import {Notification} from "element-ui";
import API from '~/js/constants/api';
import {gep} from "~/js/helpers/Vuex";
import {extendObjParams, mapEndpoint, sd} from '~/js/helpers/Common';
import i18n from '~/js/i18n';
let apiPrefix = 'v3';

export default {

  /**
   * Load All Items with no pagination
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} [payload]
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  browseAsOptions (context, payload = {}) {

    // Define the target booking id if it doesn't exist yet.
    payload.booking = sd(payload.booking, context.state.targetBooking);

    // Define fallback value for context.state.api.browseAsOptions
    let browseAsOptions = sd(context.state.api.browseAsOptions, {});
    let browse = context.state.api.browse;

    let method = sd(browseAsOptions.method, browse.method);
    let endpoint = mapEndpoint(sd(browseAsOptions.path, browse.path), payload);
    let params = extendObjParams(sd(browseAsOptions.params, browse.params), payload);

    _.unset(params, 'page'); // Remove page value if it is defined.

    return APICaller({method, endpoint, params})
      .then((r) => {
        context.commit('setListAsOptions', r.data);
      });

  }, // End of browseAsOptions() method

  /**
   * Load All Items
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} [payload]
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  browse (context, payload = {}) {

    // Define the target booking id if it doesn't exist yet.
    payload.booking = sd(payload.booking, context.state.targetBooking);

    let method = context.state.api.browse.method;
    let endpoint = mapEndpoint(context.state.api.browse.path, payload);
    let params = extendObjParams(context.state.api.browse.params, payload);

    context.commit('setListLoading', true);

    return APICaller({method, endpoint, params})
      .then((r) => {
        context.commit('setList', r.data);
      })
      .finally(() => {
        context.commit('setListLoading', false);
      });

  }, // End of browse() method

  /**
   * Get an Item according to the given ID
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} [payload]
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  read (context, payload = {}) {

    // Define the target booking id if it doesn't exist yet.
    payload.booking = sd(payload.booking, context.state.targetBooking);

    let method = context.state.api.read.method;
    let endpoint = mapEndpoint(context.state.api.read.path, payload);
    let params = extendObjParams(context.state.api.read.params, payload);

    return APICaller({method, endpoint, params})
      .then((r) => {
        let raw = r.data.data; // Store into container so varname will be shorter.
        let data = raw[Object.keys(raw)[0]]; // Get the first member of the object.
        context.commit('setItem', {data});

        if (context.state.autoReflectItem) {
          context.commit('setCompose');
        }

        return r;
      });

  }, // End of read() method

  /**
   * Update an item.
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  edit (context, payload = {}) {

    // Define the target booking id if it doesn't exist yet.
    payload.booking = sd(payload.booking, context.state.targetBooking);

    let method = context.state.api.edit.method;
    let endpoint = mapEndpoint(context.state.api.edit.path, payload);
    let data = {};
    let isDataRaw = true;

    // Set the value for payload.data
    if (context.state.autoInjectPayload) {
      context.commit('setPayloadData');
      data = context.state.payloadData;
    } else {
      data = payload.data;
    }

    return APICaller({method, endpoint, data, isDataRaw})
      .then((r) => {

        if (context.state.autoUpdateList) {
          context.dispatch('browse');
          context.dispatch('browseAsOptions');
        }

        Notification.success({
          title: i18n.t('success'),
          message: i18n.t('n_entry_edited'),
        });

        return r;
      });

  }, // End of edit() method
  getTranslatorSalaryCredits(context,payload){
    return APICaller({
      method:'get',
      endpoint: gep(`translators/${payload.translator_id}/salary-credits`,apiPrefix)
    }).then(resp => {
      context.commit('setTranslatorSalaryCredits',resp.data)
      return resp.data.data
    }).catch(err => {
      return err
    })
  },

  getDepartmentInvoiceCredits(context,payload){
    return APICaller({
      method:'get',
      endpoint: gep(`departments/${payload.department_id}/invoice-credits`,apiPrefix)
    }).then(resp => {
      context.commit('setDepartmentInvoiceCredits',resp.data)
      return resp.data.data
    }).catch(err => {
      return err
    })
  },

  /**
   * Remove an item.
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  delete (context, payload = {}) {

    // Define the target booking id if it doesn't exist yet.
    payload.booking = sd(payload.booking, context.state.targetBooking);

    let method = context.state.api.delete.method;
    let endpoint = mapEndpoint(context.state.api.delete.path, payload);
    let params = extendObjParams(context.state.api.delete.params, payload);

    return APICaller({method, endpoint, params})
      .then(() => {

        if (context.state.autoUpdateList) {
          context.dispatch('browse');
          context.dispatch('browseAsOptions');
        }

        Notification.success({
          title: i18n.t('success'),
          message: i18n.t('n_entry_deleted'),
        });
      })
      .catch(() => {
        Notification.error({
          title: i18n.t('error'),
          message: i18n.t('n_went_wrong'),
        });
      });

  }, // End of delete() method

  /**
   * Create an item.
   *
   * @param  {object} context
   *   : the context of $store
   *
   * @param  {object} payload
   *   : an object which contains option values
   *
   * @return {Promise}
   */
  add (context, payload = {}) {

    // Define the target booking id if it doesn't exist yet.
    payload.booking = sd(payload.booking_id, context.state.targetBooking);

    let method = context.state.api.add.method;
    let endpoint = mapEndpoint(context.state.api.add.path, payload);
    let data = {};
    let isDataRaw = true;

    // Set the value for payload.data
    if (context.state.autoInjectPayload) {
      context.commit('setPayloadData');
      data = context.state.payloadData;
    } else {
      data = payload.data;
    }

    return APICaller({method, endpoint, data, isDataRaw})
      .then((r) => {

        if (context.state.autoUpdateList) {
          context.dispatch('browse');
          context.dispatch('browseAsOptions');
        }

        Notification.success({
          title: i18n.t('success'),
          message: i18n.t('n_entry_added'),
        });

        return r;
      });

  }, // End of add() method
}
