<template>
    <div class="app-form app-fields sector-contact-info-fields">

        <!-- Phone -->
        <el-form-item :id="genContainerId('phone',formElId)"
                      :label="$t('phone')" class="label-short">
            <el-input :id="genFieldId('phone',formElId)"
                      v-model="iValue.phone"/>
        </el-form-item>

        <!-- Mobile -->
        <el-form-item :id="genContainerId('mobile',formElId)"
                      :label="$t('mobile')" class="label-short">
            <el-input :id="genFieldId('mobile',formElId)"
                      v-model="iValue.mobile"/>
        </el-form-item>

    </div> <!-- /.app-form app-fields sector-contact-info-fields -->
</template>

<script>
    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            formElId: {
                type: String,
                default: '',
            },

            value: {
                type: Object,
                default() {
                    return {
                        phone: '',
                        mobile: '',
                    };
                }
            },

        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            /**
             * Interface for the v-model of this component.
             */
            iValue: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                },
            },

        }, // End of Component > computed

    } // End of export default
</script>
