<template>
  <div class="app-list blogs-list">
    <base-list-table :table-data="data" :extra-cols="listCols" :hasDetailsCol="false">
      <template slot="actionsColumn" slot-scope="scope">
        <el-button
          class="circle"
          type="success"
          @click="$router.push({name:'messaging.blog.edit',params:{id:scope.row.id}})"
          :title="$t('edit')"
        >
          <span class="fa fa-pencil"></span>
        </el-button>
        <el-button class="circle" type="danger" @click="handleDelete(scope)" :title="$t('delete')">
          <span class="fa fa-trash"></span>
        </el-button>
      </template>
    </base-list-table>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
  props: {
    data: {
      type: Array
    },
    activeName: {
      type: String,
      default: ""
    }
  }, // End of Component > props

  /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
  data() {
    const _this=this;
    return {
      listCols: [
        {
          label: _this.$t('post_title'),
          prop: "title",
          width: ""
        },
        {
          label: _this.$t('recipients'),
          prop: "tags",
          width: "",
          mutator(v) {
            return v.map(e =>{ return _this.$t(`tfv_${e}`) }).join(", ");
          }
        },
        {
          label: _this.$t('created_at'),
          prop: "created_at",
          width: ""
        },
        {
          label: _this.$t('updated_at'),
          prop: "updated_at",
          width: ""
        }
      ]
    };
  }, // End of Component > data

  /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
  computed: {}, // End of Component > computed

  /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
  watch: {}, // End of Component > watch

  /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
  methods: {
    ...mapActions("blogs", {
      deleteBlog: "deleteBlog",
      getBlogs: "getBlogs"
    }),
    handleDelete(scope) {
      this.$confirm(
        "This will permanently delete the entry. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          this.deleteBlog({ id: scope.row.id }).then(() => {
            this.getBlogs({ filter: this.activeName });
          });
        })
        .catch(() => {});
    }
  }, // End of Component > methods

  /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
  mounted() {} // End of Component > mounted
};
</script>
