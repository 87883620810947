/**
 * This file contains all the actions that is related
 * to the Initialization of the application
 *
 * @param app
 *   The VueJS App Object
 */

import {
  showNotification,
} from '~/js/helpers/DesktopNotifications';
import WSClass from '~/js/helpers/WS';
import forEach from 'lodash/forEach';

export default function (app) {

  // noinspection JSIgnoredPromiseFromCall
  app.$store.dispatch('viewport/initWindowSizeListener');

  console.groupCollapsed('Initializing User Data');

  app.$store.commit('auth/initAccessToken');


  if (app.$store.getters['auth/hasAccessToken']) {
    // noinspection JSIgnoredPromiseFromCall

    app.$store.state.adminPermissions.navData.navItemsFinal = app.$store.state.adminPermissions.navData.navItems;

    if (!app.$store.getters['auth/hasUserData']) {
      app.$store.dispatch('auth/getUserData');
    } else {
      app.$store.commit('auth/initUserData');
    }

    if (app_pusher_enabled) {
      let user = app.$store.getters['auth/userData'];
      window.WS = new WSClass({user});
      window.WS.connect();

      forEach([
        'booking.created',
        'booking.accepted',
      ], (channel) => {
        window.WS.subscribe(channel)
              .listen(channel, showNotification);
      });
    }

    app.$store.commit('ui/loadColorTheme');
    app.$store.commit('ui/loadLanguage');

  }

  console.groupEnd();

}
