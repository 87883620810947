import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

let paths = (require('./routes/paths.js')).default;

export default new VueRouter({
  routes: paths,
  scrollBehavior (to, from, savedPosition) {

    const isSameRoute = from.path === to.path;
    const isValidHash = to.hash && to.hash !== '';

    if (isSameRoute) {
      if (isValidHash) {
        return {selector: to.hash};

      } else {
        return savedPosition;
      }

    } else {
      return {x: 0, y: 0};
    }
  }
});
