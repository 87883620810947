<template>
    <div class="app-modal compose-modal">

        <el-dialog
                :title="modalTitle"
                :visible.sync="iVisible"
                width="90%" top="5%">

            <!-- Beginning of Form -->
            <el-form ref="form" :model="form" :rules="rules">

                <!-- User ID selection -->
                <el-form-item label="Customer" prop="author_id" v-if="mode != 'edit'">
                    <el-select v-model="form.author_id" placeholder="Select User">
                        <el-option
                                v-for="item in consumerOpts"
                                :key="item.key"
                                :label="item.label"
                                :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- Service selection -->
                <el-form-item label="Service" prop="service" v-if="mode != 'edit'">
                    <el-select v-model="form.service" placeholder="Select Service">
                        <el-option
                                v-for="item in serviceOpts"
                                :key="item.key"
                                :label="item.label"
                                :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>

                <template v-for="f in dfe">
                    <component
                            :is="f.comp"
                            :key="f.key"
                            :idx="f.key"
                            v-model="form.fields[f.key]"
                            @remove="removeField">
                    </component>
                </template>

                <!-- Add Field Button -->
                <el-form-item>
                    <el-button
                            type="default"
                            icon="el-icon-fa fa-plus-circle"
                            @click="addField()">
                        Add Field
                    </el-button>
                </el-form-item>

            </el-form>
            <!-- End of Form -->


            <span slot="footer" class="dialog-footer">
                <el-button @click="iVisible = false">Cancel</el-button>
                <el-button type="primary" @click="handleClickConfirm()">Confirm</el-button>
            </span>
        </el-dialog>


    </div> <!-- /.app-modal compose-modal -->
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            visible: {
                type: Boolean,
                default: false
            },

            mode: {
                type: String,
                default: '',
            },

            targetItem: {
                type: Object,
                default() {
                    return {};
                }
            }

        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                form: {
                    author_id: '',
                    service: '',
                    fields: {},
                },
                dfe: [
                    {
                        key: 1,
                        comp: 'dynamic-field-entry'
                    }
                ],

                rules: {
                    author_id: [
                        {type: 'number', required: true, message: 'This is required.', trigger: 'blur'},
                    ],
                    service: [
                        {required: true, message: 'This is required.', trigger: 'blur'},
                    ],
                }
            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapGetters('form', [
                'consumerOpts',
                'serviceOpts'
            ]),

            /**
             * Interface for this.visible
             */
            iVisible: {
                get() {
                    return this.visible
                },
                set(v) {
                    if ( v ) {
                        this.whenVisible();
                    }
                    this.$emit('update:visible', v);
                }
            },

            /**
             * Value for the modal's title.
             */
            modalTitle() {
                return this.mode == 'create' ? 'Create' : 'Edit';
            },

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            ...mapActions('form', [
                'loadConsumerOpts',
                'loadServiceOpts'
            ]),

            ...mapActions('formGenerator', {
                createForm: 'create',
            }),

            /**
             * Handler when the modal has been visible initially.
             *
             * @return {void}
             */
            whenVisible() {

                if ( this.mode == 'create' ) {
                    // IF CURRENTLY ON CREATE MODE, RUN THIS BLOCK...

                    this.resetForm();


                } else if ( this.mode == 'edit' ) {
                    // IF CURRENTLY ON EDIT MODE, RUN THIS BLOCK...

                    this.resetForm();

                    // Get the fields
                    const fields = this.targetItem.attributes.field_data;
                    let fieldsCn = {}; // Create container for result.

                    // Create field objects with keys.
                    _.each(fields, (v, k) => {

                        fieldsCn[k + 1] = v;

                    }); // End of _.each()

                    // Prepare components for fields
                    this.dfe = []; // set dfe to empty.
                    const comp = 'dynamic-field-entry';
                    for (let i = 0; i < fields.length; i++) {
                        let key = i + 1;
                        this.dfe.push({ key, comp });
                    }
                    
                    // Set the value of the fields
                    this.form.fields = _.cloneDeep(fieldsCn);
                    
                    // Set the value for the author_id and service
                    this.form.author_id = this.targetItem.attributes.author_id;
                    this.form.service = this.targetItem.attributes.service_code;

                } // End of if mode ... else

            }, // End of whenVisible() method

            /**
             * Handles the addition of DFE in the render
             *
             * @return {void}
             */
            addField() {

                // Define the following values.
                const obj = (_.maxBy(this.dfe, 'key'));
                const max = _.isUndefined(obj) ? 1 : obj.key;

                // Define the props.
                const key = max + 1;
                const comp = 'dynamic-field-entry';

                // Add them to collection.
                this.dfe.push({key, comp});

            }, // End of addField() method

            /**
             * Handles the removal of DFE in the render
             *
             * @return {void}
             */
            removeField(idx) {
                _.remove(this.dfe, (f) => f.key == idx);
                _.unset(this.form.fields, idx);
                this.$forceUpdate();
            },

            /**
             * Handler when the confirm button has been clicked.
             *
             * @return {void}
             */
            handleClickConfirm() {

                if ( this.mode == 'create' ) {
                    // IF CURRENTLY ON CREATE MODE, RUN THIS BLOCK...

                    // Validate the form, and then run this block...
                    this.$refs['form'].validate((valid) => {
                        if ( valid ) {

                            this.saveForm();

                        } // End of if (valid)
                    });

                } else if ( this.mode == 'edit' ) {
                    // ELSE IF CURRENTLY ON EDIT MODE, RUN THIS BLOCK...

                    this.saveForm();

                } // End of if mode ... else

            }, // End of handleClickConfirm() method

            /**
             * Saves the form.
             *
             * @return {void}
             */
            saveForm() {

                // Create data container.
                let data = {};

                // Prepare data for fields.
                let fields = [];
                _.forOwn(this.form.fields, (v) => {
                    fields.push(v);
                });

                data.author_id = this.form.author_id;
                data.service = this.form.service;
                data.fields = JSON.stringify(fields);

                // Reach out to API
                this.createForm({data}).then(() => {
                    this.$emit('reload-list');
                    this.iVisible = false;
                });

            }, // End of saveForm() method

            /**
             * Handler to reset the form.
             *
             * @return {void}
             */
            resetForm() {

                this.form.author_id = '';
                this.form.service = '';
                this.form.fields = {};
                this.dfe = [{
                    key: 1,
                    comp: 'dynamic-field-entry'
                }];

            }, // End of resetForm() method 


        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {

            // Load Options
            this.loadConsumerOpts();
            this.loadServiceOpts();

        }, // End of Component > mounted

    } // End of export default
</script>

