import moment from 'moment';

export default {

  /**
   * @param state
   * @returns {Object}
   */
  verbiages (state) {
    let stored = localStorage.getItem(state.verbiagesCookieKey);
    state.verbiages = stored != null ? JSON.parse(stored) : {};
    return state.verbiages;
  },

  /**
   * @param state
   * @returns {boolean}
   */
  isThereAnUpdate (state) {
    let stored = localStorage.getItem(state.lastUpdateCookieKey);

    if (stored != null) {
      stored = JSON.parse(stored);

    } else {
      return true;
    }

    const old = moment(stored.verbiages);
    const recent = moment(state.newLastUpdate.verbiages);
    return recent.isAfter(old);
  }
};
