<template>
    <div class="app-list admin-logs-list">
        <el-row>
            <el-col :span="2" :offset="20">
                <router-link tag="button" to="" @click.stop.prevent title="Create" class="el-button el-button--success">
                    <i class="fa fa-plus"></i> {{$t('create')}}
                </router-link>
            </el-col>
        </el-row>

       <el-table :data="listData" border >
         <el-table-column label="" type="expand">
             <template slot-scope="props">
                 <el-row>
                   <el-col :span="12">Activity</el-col>
                   <el-col :span="12">{{props.row.data.activity}}</el-col>
                 </el-row>
                 <el-row >
                   <el-col :span="12">Object Target</el-col>
                   <el-col :span="12">{{props.row.data.object_target}}</el-col>
                 </el-row>
                 <el-row>
                   <el-col :span="12">Object Parent</el-col>
                   <el-col :span="12">{{props.row.data.object_target}}</el-col>
                 </el-row>
                <el-row :gutter="20" class="comparisson">
                  <el-col>Object Comparison</el-col>
                </el-row>
                <el-row :gutter="20" class="old">
                  <el-col>Old</el-col>
                </el-row>
                <el-row v-for="(value,key, index) in props.row.data.old" :key="index" >
                  <el-col :span="12">{{key}}</el-col>
                  <el-col :span="12">{{value}}</el-col>
                </el-row>
                <el-row :gutter="20" class="new">
                  <el-col>New</el-col>
                </el-row>
                <el-row v-for="(value,key, index) in props.row.data.new" :key="index" >
                  <el-col :span="12">{{key}}</el-col>
                  <el-col :span="12">{{value}}</el-col>
                </el-row>
            </template>
         </el-table-column>
        <el-table-column label="Status" width="60">
            <template slot-scope="props">
                <span class="el-tag">{{props.row.status}}</span>
            </template>
        </el-table-column>
        <el-table-column label="Logs" prop="logs">
        </el-table-column>
        <el-table-column label="Created At" prop="created_at">
        </el-table-column>
         </el-table-column>
       </el-table>
    </div> <!-- /.app-list admin-logs-list -->
</template>

<script>
    // import {mapGetters} from vuex

        export default {

        /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                listCols: [
                    {
                        label: 'Status',
                        prop: 'status',
                        width: '',
                        className: 'status-col',
                    },
                    {
                        label: 'Logs',
                        prop: 'logs',
                        width: '',
                        className: 'logs-col',
                    },
                    {
                        label: 'Created at',
                        prop: 'created_at',
                        width: '100px',
                        className: 'created_at-col',
                    }
                ],
                listData: [
                    { status: 'info',
                      logs: 'Karolina Salinas admin (1485) has updated Job(74670)',
                      created_at: '2018-08-21 15:36:40',
                      data: {
                          activity: 'Updated',
                          object_target: 'USERMETA(10167)',
                          object_parent: 'USER(10437)',
                          old: {
                              customer_id: 0,
                              time_to_charge: 0,
                              time_to_pay: 0,
                              maximum_km: 0,
                              allow_fax_confirmation: 0,
                              fax_number: 0,
                              placeholders: {reference: 'Bokarens Namn & Telefonummer'},
                              fields_settings: {reference: {"mandatory": "yes"}},
                          },
                          new: {
                              customer_id: '',
                              time_to_charge: '',
                              time_to_pay: '',
                              maximum_km: '',
                              allow_fax_confirmation: 1,
                              fax_number: 4692083185,
                              placeholders: {reference: 'Bokarens Namn & Telefonummer'},
                              fields_settings: {reference: {"mandatory": "yes"}},

                          }
                      }
                    },
                    { status: 'info',
                      logs: 'Karolina Salinas admin (1485) has updated Job(74671)',
                      created_at: '2018-08-21 15:36:40',
                      data: {
                          activity: 'Updated',
                          object_target: 'USERMETA(101897)',
                          object_parent: 'USER(10437)',
                          old: {
                              customer_id: 0,
                              time_to_charge: 0,
                              time_to_pay: 0,
                              maximum_km: 0,
                              allow_fax_confirmation: 0,
                              fax_number: 0,
                              placeholders: {reference: 'Bokarens Namn & Telefonummer'},
                              fields_settings: {reference: {"mandatory": "yes"}},
                          },
                          new: {
                              customer_id: '',
                              time_to_charge: '',
                              time_to_pay: '',
                              maximum_km: '',
                              allow_fax_confirmation: 1,
                              fax_number: 4692083185,
                              placeholders: {reference: 'Bokarens Namn & Telefonummer'},
                              fields_settings: {reference: {"mandatory": "yes"}},

                          }
                      }
                    },
                    { status: 'info',
                      logs: 'Karolina Salinas admin (1485) has updated Job(74620)',
                      created_at: '2018-08-21 15:36:40',
                      data: {
                          activity: 'Updated',
                          object_target: 'USERMETA(11230167)',
                          object_parent: 'USER(10437)',
                          old: {
                              customer_id: 0,
                              time_to_charge: 0,
                              time_to_pay: 0,
                              maximum_km: 0,
                              allow_fax_confirmation: 0,
                              fax_number: 0,
                              placeholders: {reference: 'Bokarens Namn & Telefonummer'},
                              fields_settings: {reference: {"mandatory": "yes"}},
                          },
                          new: {
                              customer_id: '',
                              time_to_charge: '',
                              time_to_pay: '',
                              maximum_km: '',
                              allow_fax_confirmation: 2,
                              fax_number: 4692083185,
                              placeholders: {reference: 'Bokarens Namn & Telefonummer'},
                              fields_settings: {reference: {"mandatory": "yes"}},

                          }
                      }
                    }
                ]
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            // ...mapGetters('',['listData'])

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
        methods: {}, // End of Component > methods


        /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {}, // End of Component > mounted

    }
</script>

<style  lang="scss" scoped>

  .comparisson {
      background-color: #999999;
      text-align: center;
      padding: 5px;
        color: #f9fafc;
  }
  .old,.new {
       background-color: rgba(224, 222, 222, 0.667);
      text-align: center;
      padding: 5px;
  }
</style>
