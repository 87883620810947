<template>
    <div class="app-page text-translation-create-page">
        <div class="app-page-top-bar">

            <div class="app-page-top-bar-wrapper">
                <div class="logo">
                    <img src="/images/defaults/brand-logo.png" alt="" srcset="">
                </div>
                <div class="top-bar-extras content-body">
                    <ul>
                        <li>
                            <router-link to="/dashboard" title="Statistics">
                                <span class="fa fa-bar-chart"></span> Admin Panel
                            </router-link>
                        </li>
                        <li>
                            <el-dropdown>
                                <i class="el-icon-setting" style="margin-right: 15px"></i>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>Logout</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <section>
            <div class="bg-banner">
                <el-row>
                    <el-col :offset="1">
                        <h1>UnFixed Price</h1>
                    </el-col>
                </el-row>
            </div>


        </section>
        <section class="text-translation-form-section">
            <el-row>
                <el-col :span="20" :offset="1">
                    <div class="content-body text-translation-form">
                        <h3>Jobs Detail</h3>
                        <el-row>
                            <el-col :span="4">Review Document:</el-col>
                            <el-col :span="6"><i class="fa fa-paperclip"></i> transalated.txt</el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">Language From</el-col>
                            <el-col :span="6">Swedish</el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">Language To</el-col>
                            <el-col :span="6">English</el-col>
                        </el-row>
                        <el-row>
                            <el-col>
                                <p> Description of the assigment</p>
                                <span class="test-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed
                                    ullam voluptatem iste expedita vel ipsam suscipit. Quos voluptates mollitia ea
                                    vitae, corrupti eos perspiciatis pariatur voluptatum explicabo beatae, dolores
                                    ipsa!</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">Date:</el-col>
                            <el-col :span="6">May 1,2018</el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">Time:</el-col>
                            <el-col :span="6">02:30 PM</el-col>
                        </el-row>
                        <hr>
                        <el-row>

                            <el-col :span="8">
                                <el-form :model="form">
                                    <h3>Enter Bid Amount</h3>
                                    <el-form-item label="kr:">
                                        <el-input v-model="form.bid" placeholder="Enter Bid Amount"></el-input>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </el-row>
                        <hr>
                        <el-row>
                          <el-col :span="6">
                              <el-button type="success">Submit</el-button>
                          </el-col>
                        </el-row>
                    </div>

                </el-col>
            </el-row>



        </section>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    bid: ''
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .bg-banner {
        background-color: #24bd8e;

        h1 {
            color: white !important;
        }
    }

    .text-translation-form {
        .el-row {
            margin-bottom: 10px;
        }
    }
</style>