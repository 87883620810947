<template>
  <el-form :model="form" @submit.native.prevent="submitForm" ref="form" :rules="rules">
    <el-form-item label="Fax" prop="phone">
      <!-- <el-input v-model="form.phone" placeholder="Inset Phone Number for example: +94123456, +89123456"></el-input> -->
      <!-- <el-select v-model="form.phone" placeholder="Insert Fax Number for example: +94123456, +89123456" filterable allow-create multiple
      @blur.native.capture="setPhones" ref="phone"
      v-on:keyup.tab.space="setPhones"
      >
        <el-option v-for="(i, index) in phones" :key="index" :value="i" :label="i"></el-option>
      </el-select>
      <small>press [TAB] for add multiple values</small> -->

    <multiselect
      v-model="form.phone"
      :options="phones"
      :multiple="true"
      :taggable="true"
      placeholder="Inset Phone Number for example: +94123456, +89123456"
      @tag="setTag"
      >
      <span slot="noResult">Inset Phone Number for example: +94123456, +89123456</span>

      </multiselect>

    </el-form-item>
    <el-form-item label="Text" prop="text">
      <el-input type="textarea" v-model="form.text" placeholder="" size="large"
      :autosize="{ minRows: 10, maxRows:15}"></el-input>
      <div class="text-right">
        <!-- <small >{{count}} characters left</small> -->
      </div>
    </el-form-item>
    <small>Please observe that the user can not respond to the fax and you will receive no response</small>
    <div class="text-right">
    <el-button native-type="submit" size="mini">submit</el-button>
    </div>
  </el-form>
</template>

<script>

import Multiselect from 'vue-multiselect'
import {isEmpty} from '~/js/helpers/Common'
import {Notification} from "element-ui";
import i18n from '~/js/i18n';
export default {
name:'sendFaxForm',
components:{Multiselect},
data(){
  return {
    form:{
      phone:[],
      text:''
    },
    rules:{
      phone: [{required:true,message:'Please add fax number'}],
      text: [
        {required:true,message:'Please add text'},
        ],
    },
    phones:[]
  }
},
computed:{
  // count(){
  //   return 160 - this.form.text.length
  // }
},
methods:{
  submitForm(e){
    console.log(e)
    this.$refs['form'].validate((valid) => {
      console.log(valid)
      if(valid){
      let data = {
        notification_type: 'fax',
        template_code: 'blank-fax',
        project_code: app_dt_notifier_project_code,
        data: {
          text: this.form.text,
          date: moment().format('YYYY-MM-DD HH:mm:ss')
        },
        recipients: this.form.phone
      }
      // axios.post(`${app_dt_notifier_url}/api/v1/notifications`,data)
        let token = localStorage.getItem("app_access_token");
        axios.post(`${app_dt_notifier_url}/api/v3/notifications`,data,{
          headers:{
            'Authorization': 'Bearer '+ token
          }
        })
      .then(resp => {
        Notification.success({
          title: i18n.t('success'),
          message: 'send fax notification requested',
        });
        this.form.phone = [];
        this.form.text = '';
      }).catch(err => {

         Notification.error({
          title: i18n.t('error'),
          message: i18n.t('n_went_wrong'),
        });
      })
      }
    })
  },
  setPhones(e){
    console.log(e)
    if(!isEmpty(event.target.value) && !this.form.phone.includes(event.target.value)){
    this.form.phone.push(e.target.value)
    this.$refs.phone.$el.focus();
    event.target.focus();
    }
  },
  setTag(e){
    this.phones.push(e)
    this.form.phone.push(e)
  },
}
}
</script>

<style>
.el-select__tags-text {
  max-width: 100px;
}

.multiselect, .multiselect__tags, .multiselect__single, .multiselect__input{
    font-size: 12px !important;
}
</style>
