
import APICaller from '~/js/helpers/APICaller';
import {extendObjParams, mapEndpoint, sd} from '~/js/helpers/Common';
import {Notification} from 'element-ui';
import i18n from '~/js/i18n';
import { gep } from '../../helpers/Vuex';

let apiPrefix = 'v3';
export default {
  postBookingTodo(context, payload){
    return APICaller({
      method:'post',
      endpoint: gep(`todos`,apiPrefix),
      data:payload
    }).then(r =>{
      let data=r.data.data.todo;
      Notification.success({
        title: i18n.t('success'),
        message: `Follow up item added #${data.issue.id}`,
        onClick: function () {
          window.location.href = `/#/booking-issues/${data.issue.id}/`
        }
      });
    }).catch(error =>{
      Notification.error({
        title: i18n.t('error'),
        message: i18n.t('n_went_wrong'),
      });
    })
  }
}
