/*
|--------------------------------------------------------------------------
| Store > emails-templates > Mutations
|--------------------------------------------------------------------------
|
| This file contains the mutations property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
import {isEmpty, setTimedLocalStorage} from '../../helpers/Common';


export default {

  setError: (state, payload) => state.error = payload,
  /**
   * Set Emails list state
   *
   * @param {object} state
   *  : current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {array}
   */
  setEmails: (state, payload) => {
    let key = Object.keys(payload.data)[0];
    state.emails = payload.data[key];
    state.listPagination = payload.meta.pagination;
  },

  /**
   * Set Email object state
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {object}
   */
  setEmail: (state, payload) => {
    let key = Object.keys(payload.data)[0];
    state.email = payload.data[key];
  },

}
