/*
|--------------------------------------------------------------------------
| Store > Town > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Town Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {gep} from "../../helpers/Vuex";

let target = 'userAccessLogs';
let apiPrefix = window.app_api_prefix_3;
let asOptionsParams = {
};

let composeProps = {
    id: '',
    admin_id: 0,
    user_id: 0,
    action: ''

};

let composeSearchProps = {
    admin_name: '',
    action: '',
    user_name: '',
    user_type: '',
    created_at: ''
};

export default {

    model_info: {
        name: 'User Access Logs',
        module: 'userAccessLogs',
        table: target,
    },

    api: {
        browse: {
            method: 'get',
            path: gep('user-access-logs', apiPrefix),
            params: {
                per_page: 20,
                include: 'admin,user'
            }
        },
        browseAsOptions: { method: 'get', path: gep('user-access-logs', apiPrefix), params: asOptionsParams},
        read: { method: 'get', path: gep('user-access-logs' + '/{id}', apiPrefix), params: {}},
        edit: { method: 'put', path: gep('user-access-logs' + '/{id}', apiPrefix), params: {}, data: {}, },
        add: { method: 'post', path: gep('user-access-logs', apiPrefix), params: {}, data: {}, },
        delete: { method: 'delete', path: gep('user-access-logs' + '/{id}', apiPrefix), params: {}, data: {}, },
        addActivity: {
            method: 'post',
            path: gep('user-access-logs' + '/{action}' + '/{id}', apiPrefix),
            params: {},
            data: {},
        }
    },

    composeSearch: _.cloneDeep(composeSearchProps),
    composeFresh: _.cloneDeep(composeProps),
    compose: _.cloneDeep(composeProps),

    payloadData: {}, // End of payloadData state

    autoInjectPayload: true,
    autoReflectItem: true,
    autoUpdateList: true,

} // End of export default