<template>
  <div class="app-page">
    <div class="app-container">
      <div class="app-wrapper">
        <div class="app-row">
          <div class="app-col full-width">
            <div :class="blockCls">
              <div :class="getBem(blockCls, 'top-control')">
                <div :class="getBem(blockCls, 'inner-controls')">
                  <el-row>
                    <el-col :md="4">
                      <el-button type="primary" @click="handleClickNewSms" icon="el-icon-plus">New SMS</el-button>
                    </el-col>
                    <div :class="getBem(blockCls, 'filters')">

                      <el-button
                        type="primary"
                        class="modal-size"
                        @click="filterConversation('reset')"
                      >{{$t('reset')}}
                      </el-button>


                      <el-col :md="4">

                        <el-date-picker
                          size="medium"
                          v-model="searchBy['created_at']"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          placeholder="Pick a date"
                          popper-class="day-picker-only"
                          @change="filterConversation('created_at')"
                        ></el-date-picker>
                      </el-col>

                      <el-col :md="4">
                        <el-input size="medium" v-model="searchBy['booking_id']" placeholder="Booking #"
                                  @keyup.enter.native="filterConversation('booking_id')">
                      <span class="el-icon-search el-input__icon app-cursor-pointer" slot="suffix"
                            @click="filterConversation"
                      ></span>
                        </el-input>
                      </el-col>


                      <el-col :md="4">
                        <el-input size="medium" v-model="searchBy['to_recipient_number']" placeholder="Mobile #"
                                  @keyup.enter.native="filterConversation('to_recipient_number')">
                      <span class="el-icon-search el-input__icon app-cursor-pointer" slot="suffix"
                            @click="filterConversation"
                      ></span>
                        </el-input>
                      </el-col>

                      <el-col :md="4">
                        <el-input size="medium" v-model="searchBy['name']" placeholder="Name"
                                  @keyup.enter.native="filterConversation('name')">
                      <span class="el-icon-search el-input__icon app-cursor-pointer" slot="suffix"
                            @click="filterConversation"
                      ></span>
                        </el-input>
                      </el-col>

                    </div>
                  </el-row>
                </div>
              </div>
            </div>


            <el-row>
              <el-col :md="8">
                <sms-centre-side-bar :currentRow="currentRow" :handleClickConversation="handleClickConversation"/>
              </el-col>
              <el-col :md="16">
                <sms-centre-conversation-body :messageBatch="message" :conversation="conversation"/>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <recipient-select-modal :visible.sync="showModal"></recipient-select-modal>

  </div>
</template>

<script>
  import {mapActions, mapMutations} from "vuex";
  import {isEmpty} from "~/js/helpers/Common";

  export default {
    name: "SmsCentreComposePage",
    data() {
      return {
        conversation: [],
        blockCls: 'sms-centre-compose-page',
        searchBy: [],
        currentRow: null,
        showModal: false
      }
    },
    props: {
      smsConversation: {
        type: Object,
        default() {
          return {}
        }
      },
      message: {
        type: String,
        default: ''
      }
    },

    methods: {

      ...mapActions('smsCentre', {
        getConversation: 'getConversationList',
        getSpecificConversation: 'getSpecificConversation',

      }),
      ...mapMutations('smsCentre', {
        setConversation: 'setConversation',
        setBookingIds: 'setBookingIds',
        setSelectedRow: 'setSelectedRow'

      }),
      /**
       * Handle Click Conversation
       * @param conversation
       */
      handleClickConversation(conversation) {
        this.conversation = conversation.sms_centre_conversation;
      },
      /**
       * Listener for the event
       */
      smsCentreListener(event) {
        this.getConversation();
      },

      /**
       * Event for sms conversation
       */
      smsCentreEvent() {
        if (window.WS) {
          window.WS.subscribe('sms.conversation.created')
            .listen('sms.conversation.created', this.smsCentreListener);
        }
      },

      /**
       * Check if its redirected from some page
       */
      checkIfRedirected() {
        if (!isEmpty(this.smsConversation)) {
          this.getSpecificConversation({id: this.smsConversation.id})
          this.setSelectedRow(this.smsConversation.id);
        }
      },
      /**
       * filter conversation method
       */
      filterConversation(type) {
        let param = {};
        if (type !== 'reset') {
          param[`filter[${type}]`] = this.searchBy[type];
        } else {
          this.searchBy = [];
        }
        this.getConversation({params: param});
      },
      /**
       * Open Modal
       */
      handleClickNewSms() {
        this.showModal = true;
      },
    },
    /**
     * Mounted method for the component
     */

    mounted() {
      this.checkIfRedirected();
      this.smsCentreEvent();
    },

    /**
     * Unsubscribe to events
     */

    beforeDestroy() {
      window.WS.unsubscribe([
        'sms.conversation.created'
      ]);
    }
  }
</script>

<style lang="scss">
  .sms-centre-compose-page {

    &__top-control {
      background-color: #FAFAFA;
      height: 75px;
    }

    &__inner-controls {
      padding: 10px;
    }

    &__filters {
      display: flex;
      justify-content: space-evenly;
      margin-top: 10px;

      .el-button {
        margin-top: 0px;
      }
    }

  }


</style>
