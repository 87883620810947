/*
|--------------------------------------------------------------------------
| Store > Sector > Town
|--------------------------------------------------------------------------
|
| A file that manages all the properties and abilities in relation
| to Town Model and other related values.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {extendObj} from "../../helpers/Common";
import bread from '../bread';
import state from './state';
import {Notification} from 'element-ui';
import i18n from '~/js/i18n';

let warning422 = (e) => {
    let data = e.response.data;
    for (let field in data.data) {
        for (let err of data.data[field]) {
            let msg = field + ': ' + err;
            Notification.error({
                title: i18n.t('error'),
                message: msg
            });
        }
    }
};

let mutations = {};
let getters = {};
let actions = {
    addRole (context, payload) {

        return new Promise((resolve, reject) => {
          context.dispatch('addGeneric', payload)
                 .then((r) => {
    
                   if (context.state.autoUpdateList) {
                     context.dispatch('browse');
                     context.dispatch('browseAsOptions');
                   }
    
                   Notification.success({
                     title: i18n.t('success'),
                     message: i18n.t('n_entry_added')
                   });
    
                   resolve(r);
                 })
                 .catch((e) => {
                    if (e.response.status === 422) {
                        warning422(e);
                    } else {
                        Notification.error({
                            title: i18n.t('error'),
                            message: i18n.t('n_went_wrong')
                        });
                    }
        
                    reject(e);
                 });
        });
    
    },

    editRole (context, payload) {

        return new Promise((resolve, reject) => {
          context.dispatch('editGeneric', payload)
                 .then((r) => {
    
                   if (context.state.autoUpdateList) {
                     context.dispatch('browse');
                     context.dispatch('browseAsOptions');
                   }
    
                   Notification.success({
                     title: i18n.t('success'),
                     message: i18n.t('n_entry_edited')
                   });
    
                   resolve(r);
                 })
                 .catch((e) => {
                    if (e.response.status === 422) {
                        warning422(e);
                    } else {
                        Notification.error({
                            title: i18n.t('error'),
                            message: i18n.t('n_went_wrong')
                        });
                    }
                   reject(e);
                 });
        });
      },
};

export default {
    namespaced: true,
    state: extendObj(bread.state, state),
    getters: extendObj(bread.getters, getters),
    mutations: extendObj(bread.mutations, mutations),
    actions: extendObj(bread.actions, actions),
} // End of export default
