/*
|--------------------------------------------------------------------------
| User Types
|--------------------------------------------------------------------------
| Contains the Key - Value pair for the user types.
| Key is the User Type Name, and the Value is the User Type ID.
*/

'use strict';

export default {
  ADMIN: 1,
  CUSTOMER: 2,
  TRANSLATOR: 3,
}