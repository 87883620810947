<template>
  <div class="app-form price-charging-form">

    <div class="app-splitter two-cols app-flex-top">

      <!-- Invoice Form -->
      <div>
        <el-form :id="invoiceFormElId" :ref="invoiceFormElId">

          <h3 class="app-title title-shaded">
            <span>{{$t('invoice')}}</span>
          </h3>

          <financial-charging-fields
              :form-el-id="invoiceFormElId"
              v-model="invoiceForm.charging"
              :business-rules="invoiceBusinessRules"
              :is-enabled-emergency-charges="isEnabledInvoiceEmergencyCharges"
              @clone-charges="cloneInvoiceCharges"
          />
        </el-form>
      </div>

      <!-- Salary Form -->
      <div>
        <el-form :id="salaryFormElId" :ref="salaryFormElId">

          <h3 class="app-title title-shaded">
            <span>{{$t('salary')}}</span>
          </h3>

          <financial-charging-fields
              :form-el-id="salaryFormElId"
              v-model="salaryForm.charging"
              :business-rules="salaryBusinessRules"
              :is-enabled-emergency-charges="isEnabledSalaryEmergencyCharges"
              @clone-charges="cloneSalaryCharges"
          />
        </el-form>
      </div>

    </div> <!-- /.app-splitter two-cols -->

  </div> <!-- /.app-form price-charging-form -->
</template>

<script>
  import {mapState, mapGetters, mapMutations} from 'vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data() {
      return {
        invoiceFormElId: 'invoice_charging_form',
        salaryFormElId: 'salary_charging_form',
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('financialInvoice', {
        invoiceForm: 'settings',
      }),

      ...mapState('financialSalary', {
        salaryForm: 'settings',
      }),

      ...mapGetters('financialInvoice', {
        invoiceBusinessRules: 'businessRules',
        isEnabledInvoiceEmergencyCharges: 'isEnabledEmergencyCharges',
      }),

      ...mapGetters('financialSalary', {
        salaryBusinessRules: 'businessRules',
        isEnabledSalaryEmergencyCharges: 'isEnabledEmergencyCharges',
      }),
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapMutations('financialInvoice', {
        cloneInvoiceCharges: 'cloneCharges',
      }),

      ...mapMutations('financialSalary', {
        cloneSalaryCharges: 'cloneCharges',
      }),
    },

  } // End of export default
</script>
