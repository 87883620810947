<template>
    <div class="app-form test-languages-form">
      <el-dialog top="10%" :title="$t('test_languages')" :visible.sync="dialogFormVisible" :modalAppendToBody="false">
        <el-form :model="form" :id="formElId" :ref="formElId">
            <el-form-item :id="genContainerId('language',formElId)"
                        :label="$t('test_languages')" class="label-short">
                <el-select v-model="languageId" filterable placeholder="select languges">
                  <el-option v-for="language in languages" :key="language.id" :label="language.name" :value="language.id"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item :id="genContainerId('save_btn',formElId)" class="el-form-actions">
                <el-button :id="genFieldId('cancel_btn',formElId)" type="dafault" @click="handleClose">
                    {{$t('cancel')}}
                </el-button>
                <el-button :id="genFieldId('save_btn',formElId)" type="primary" @click="submitForm(languageId)">
                    {{$t('save')}}
                </el-button>
            </el-form-item>
        </el-form>
      </el-dialog>
      <el-button type="primary" @click="dialogFormVisible = true">{{$t('create')}}</el-button>
      <el-table :data="form.value" style="width: 100%" :sort-by="form.value">
        <el-table-column :label="$t('languages')">
          <template slot-scope="scope">
            {{ scope.row | getLanguageName(languages)}}
          </template>
        </el-table-column>
        <el-table-column :label="$t('actions')">
          <template slot-scope="scope">
            <el-button class="circle" type="danger" title="Remove" @click="handleDelete(scope.row)">
                <span class="fa fa-trash"></span>
            </el-button>
          </template>
        </el-table-column>
    </el-table>
    </div>
</template>

<script>
    import {genFieldId, genContainerId} from "../../../js/helpers/Html";
    import {
        mapGetters,
        mapActions,
        mapState
    } from 'vuex';
    import {isEmpty} from '../../../js/helpers/Common';
    import {populate,forcePopulate} from "~/js/helpers/Vuex"
    export default {
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                formElId: 'test-languages-form',
                dialogFormVisible:false,
                languageId:''
            };
        },// End of Component > data
        /*
        |--------------------------------------------------------------------------
        | Component > filters
        |--------------------------------------------------------------------------
        */
        filters: {
          getLanguageName (v, o) {
              if (!isEmpty(o)) {
                let found =o.find(x=>x.id==v);
                return found ? found.name+' ('+v+')': v;
              }
          }
        },
        /*
        |--------------------------------------------------------------------------
        | component > computed
        |--------------------------------------------------------------------------
        */
        computed: {
            ...mapGetters({
                languages: 'language/getLanguages',
                language:'language/getLanguageFrom'
            }),
            ...mapState('testLanguages', {
              form: 'compose'
            }),
       },// End of Component > computed
       /*
       |--------------------------------------------------------------------------
       | component > methods
       |--------------------------------------------------------------------------
       */
       methods: {
         ...mapActions('language',{
             getLanguages: 'fetchLanguages',
             fetchLanguage:'fetchLanguage'
         }),
         ...mapActions({
           getTestLanguages: "testLanguages/getTestLanguages",
           createTestLanguages: "testLanguages/postTestLanguages",
           updateTestLanguages: "testLanguages/updateTestLanguages",
         }),
         submitForm(value){
           if(!this.form.value.includes(value.toString())){
             this.form.value.push(value.toString());
             this.updateTestLanguages(this.form).then(response => {
               this.getTestLanguages();
             });
             this.handleClose();
           }else{
             console.log('language exist');
             this.handleClose();
           }
         },
         handleDelete(scope){
           this.$confirm('This will remove language from test languages, Continue?', 'Warning', {
             confirmButtonText: 'YES',
             cancelButtonText: 'NO',
             type: 'warning'
           }).then(() => {
               this.form.value=this.form.value.filter(x=>x!==scope.toString());
               console.log(this.form.value);
               this.updateTestLanguages(this.form).then(response => {
                 this.getTestLanguages();
               });
           }).catch(() => {
             console.log('canceled');
           });

         },
         handleClose: function () {
             this.dialogFormVisible=false;
         }
      },// End of Component > methods
      /*
      |--------------------------------------------------------------------------
      | component > mounted
      |--------------------------------------------------------------------------
      */
      mounted() {
        populate(this, 'getLanguages', 'languages', {
            ref: 'Languages Options'
        });
        this.getTestLanguages();
      },// End of Component > mounted

    }// End of export default

</script>
