<template>
  <div class="app-fields" :class="getBem(blockClass)">
    <template v-for="translatorLevel in translatorLevels">
      <h3>{{ $t(`tlvl_${translatorLevel}`) }}</h3>

      <!-- Price per word -->
      <el-form-item
        :class="getBem(blockClass, [translatorLevel, 'price-per-word-container'])"
        :label="$t('financial_text_translation_price_per_word')"
        class="label-half-width"
      >

        <el-input
          :class="getBem(blockClass, [translatorLevel, 'price-per-word-field'])"
          v-model="iValue.text_translation_fees[translatorLevel].price_per_word"
        />
      </el-form-item>

      <!-- Minimum charge fee -->
      <el-form-item
        :class="getBem(blockClass, [translatorLevel, 'minimum-charge-fee-container'])"
        :label="$t('financial_text_translation_minimum_charge_fee')"
        class="label-half-width"
      >

        <el-input
          :class="getBem(blockClass, [translatorLevel, 'minimum-charge-fee-field'])"
          v-model="iValue.text_translation_fees[translatorLevel].minimum_charge_fee"
        />
      </el-form-item>

      <!-- Formatting Hourly fee -->
      <el-form-item
        :class="getBem(blockClass, [translatorLevel, 'formatting-hourly-fee-container'])"
        :label="$t('financial_text_translation_formatting_hourly_fee')"
        class="label-half-width"
      >

        <el-input
          :class="getBem(blockClass, [translatorLevel, 'formatting-hourly-fee-field'])"
          v-model="iValue.text_translation_fees[translatorLevel].formatting_hourly_fee"
        />
      </el-form-item>

      <!-- Minimum formatting fee -->
      <el-form-item
        :class="getBem(blockClass, [translatorLevel, 'minimum-formatting-fee-container'])"
        :label="$t('financial_text_translation_minimum_formatting_fee')"
        class="label-half-width"
      >

        <el-input
          :class="getBem(blockClass, [translatorLevel, 'minimum-formatting-fee-field'])"
          v-model="iValue.text_translation_fees[translatorLevel].minimum_formatting_fee"
        />
      </el-form-item>

      <!-- Express Hours -->
      <el-form-item
        :class="getBem(blockClass, [translatorLevel, 'express-hours-container'])"
        :label="$t('financial_text_translation_express_hours')"
        class="label-half-width"
      >

        <el-input
          :class="getBem(blockClass, [translatorLevel, 'express-hours-field'])"
          v-model="iValue.text_translation_fees[translatorLevel].express_hours"
        />
      </el-form-item>

      <!-- Express Surcharge Percentage -->
      <el-form-item
        :class="getBem(blockClass, [translatorLevel, 'express-surcharge-percentage-container'])"
        :label="$t('financial_text_translation_express_surcharge_percentage')"
        class="label-half-width"
      >

        <el-input
          :class="getBem(blockClass, [translatorLevel, 'express-surcharge-percentage-field'])"
          v-model="iValue.text_translation_fees[translatorLevel].express_surcharge_percentage"
        />
      </el-form-item>

      <!-- Verification Fee -->
      <el-form-item
        :class="getBem(blockClass, [translatorLevel, 'verification-fee-container'])"
        :label="$t('financial_text_translation_verification_fee')"
        class="label-half-width"
      >

        <el-input
          :class="getBem(blockClass, [translatorLevel, 'verification-fee-field'])"
          v-model="iValue.text_translation_fees[translatorLevel].verification_fee"
        />
      </el-form-item>

      <!-- Transaction Fee -->
      <el-form-item
        :class="getBem(blockClass, [translatorLevel, 'transaction-fee-container'])"
        :label="$t('financial_text_translation_transaction_fee')"
        class="label-half-width"
      >

        <el-input
          :class="getBem(blockClass, [translatorLevel, 'transaction-fee-field'])"
          v-model="iValue.text_translation_fees[translatorLevel].transaction_fee"
        />
      </el-form-item>

      <hr class="app-hr hr-type1">
    </template>


  </div>
</template>

<script>
  import {BOOKING_TYPES, TRANSLATOR_LVLS} from '~/js/store/financial/settings/shared/props/constants';
  import remove from 'lodash/remove';
  import lowerCase from 'lodash/lowerCase';
  import filter from 'lodash/filter';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      blockClass: {
        type: String,
        default: 'financial-text-translation-form'
      },

      isUsePartsEnabled: {
        type: Boolean,
        default: true
      },

      value: {
        type: Object,
        default () {
          return {
            // Please refer to the store for the format.
            // store > financial > shared > props > textTranslation
          };
        }
      },

      isEnabledEmergencyCharges: {
        type: Boolean,
        default: false
      },

      formType: {
        type: String,
        default: 'invoice' // varies between invoice | salary
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Returns collection of appropriate booking types.
       *
       * @returns {string[]}
       */
      bookingTypes () {
        let types = [...BOOKING_TYPES];

        if (!this.isEnabledEmergencyCharges) {
          remove(types, (type) => type === 'phone_immediate');
        }

        return types;
      },

      /**
       * @returns {string[]}
       */
      translatorLevels () {
        return filter(TRANSLATOR_LVLS, (level) => {
          return level === 'layman' || level === 'certified';
        });
      },

      /**
       * Returns boolean to determine if the form is currently used for invoice.
       *
       * @returns {boolean}
       */
      isFormTypeInvoice () {
        return this.formType === 'invoice';
      },

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        }
      }

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * Helper method for generating header for Booking Type Sections.
       *
       * @param {string} v - the value to be evaluated.
       *
       * @return {string}
       */
      genBookingTypeHeader (v) {
        const locale = window.app_locale;
        return locale === 'se' ?
               this.$t(v) + ' ' + lowerCase(this.$t('booking')) :
               this.$t(v) + ' ' + this.$t('booking');
      }
    } // End of Component > methods

  }; // End of export default
</script>
