<template>
  <div class="app-page financial-export-process-page">
    <page-header :title="`Export Process #${financialListId}`" :back-enabled="true"/>

    <div class="app-container"
         :class="genBem(blockClass)"
         v-loading="isItemLoading || isLoadingPage">
      <div class="app-wrapper">
        <div class="app-row">
          <div class="app-col full-width"
               :class="genBem(blockClass, 'info')">

            <el-button
              :class="getBem(blockClass, 'mark-as-complete-button')"
              size="mini"
              type="primary"
              :disabled="isDisabledMarkAsComplete"
              @click="markAsComplete"
            >
              Mark as complete
            </el-button>

            <p>{{ $t('id') }}: {{ itemData.id }}</p>
            <p>{{ $t('name') }}: {{ itemData.name }}</p>
            <p>{{ $t('period_start') }}: {{ itemData.period_start }}</p>
            <p>{{ $t('periode_end') }}: {{ itemData.period_end }}</p>
            <p>
              {{ $t('completed') }}:
              <span :class="[
                getBem(blockClass, 'info-is-complete'),
                getBem(blockClass, 'info-is-complete', itemData.is_complete ? 'yes' : 'no'),
              ]">
                {{ itemData.is_complete ? $t('yes') : $t('no') }}
              </span>
            </p>

            <p>
              <el-button
                :class="genBem(blockClass, 'precheck-btn')"
                type="primary"
                size="mini"
                @click="handleClickPrecheck">
                {{ $t('run_precheck') }}
              </el-button>
            </p>

            <div>
              <email-picker
                v-model="recipients"
                placeholder="Input the recipients of the Exported file in here"
              />
              <el-button
                :class="genBem(blockClass, 'precheck-export-btn')"
                type="primary"
                size="mini"
                @click="handleClickPrecheckExport">
                {{ $t('run_precheck_export') }}
              </el-button>
            </div>

            <h3>{{ $t('progress') }}</h3>
            <div :class="genBem(blockClass, 'progress-financial-list')">
              <p>
                {{ $t('checked') }}: {{ progressFinancialList.checked }}
              </p>

              <template v-if="isNotNullNorEmpty(progressFinancialList.unchecked)">
                <h3 class="text-danger">{{ $t('errors') }}</h3>

                <financial-export-message-display
                  :title="$t('unchecked') + ':'"
                  v-model="progressFinancialList.unchecked"
                />
              </template>
            </div>


            <p :class="getBem(blockClass, 'enable-force-progress-check')" v-if="false">
              {{ $t('enable_force_progress_check') }}:
              <el-switch v-model="isEnabledForceProgressCheck"/>
            </p>

          </div> <!-- /.app-col -->

          <!-- -------------------------------------------------------------------------------- -->
          <!-- INVOICE -->
          <!-- -------------------------------------------------------------------------------- -->
          <div class="app-col half-width text-center"
               :class="genBem(blockClass, 'invoice-controls')">
            <h3>{{ $t('invoice') }} #{{ invoiceId }}</h3>

            <div :class="getBem(blockClass, 'invoice-drivers')">
              <p>Drivers</p>
              <el-checkbox-group v-model="invoiceDrivers">
                <el-checkbox label="departments-xml">
                  Departments XML
                </el-checkbox>
                <el-checkbox label="items-xml">
                  Items XML
                </el-checkbox>
                <el-checkbox label="fast-excel">
                  Fast Excel
                </el-checkbox>
                <el-checkbox label="csv">
                  CSV
                </el-checkbox>
              </el-checkbox-group>
            </div>

            <template v-if="isEnabledForceProgressCheck">
              <el-button :class="genBem(blockClass, 'progress-invoice-btn')"
                         type="primary"
                         size="mini"
                         @click="handleClickCheckProgressInvoice">
                {{ $t('force_check_progress_invoice') }}
              </el-button>
              <br>
              <el-button :class="genBem(blockClass, 'uncalculated-invoice-btn')"
                         type="primary"
                         size="mini"
                         @click="handleClickCheckUncalculatedInvoice">
                {{ $t('force_check_uncalculated_invoice') }}
              </el-button>
            </template>


            <el-button :class="genBem(blockClass, 'calculate-invoice-btn')"
                       type="primary"
                       size="mini"
                       @click="handleClickCalculateInvoice">
              {{ $t('calculate_invoice') }}
            </el-button>

            <el-button :class="genBem(blockClass, 'export-invoice-btn')"
                       type="primary"
                       size="mini"
                       @click="handleClickExportInvoice">
              {{ $t('export_invoice') }}
            </el-button>

            <h3>{{ $t('progress') }}</h3>
            <div :class="genBem(blockClass, 'progress-invoice')">
              <p>
                {{ $t('calculated') }}: {{ progressInvoice.calculated }}
              </p>

              <template
                v-if="isNotNullNorEmpty(progressInvoice.uncalculated)
                || isNotNullNorEmpty(progressInvoice.unpopulated)"
              >
                <h3 class="text-danger">{{ $t('errors') }}</h3>

                <financial-export-message-display
                  :title="$t('uncalculated') + ':'"
                  v-if="isNotNullNorEmpty(progressInvoice.uncalculated)"
                  v-model="progressInvoice.uncalculated"
                />

                <financial-export-message-display
                  :title="$t('unpopulated') + ':'"
                  v-if="isNotNullNorEmpty(progressInvoice.unpopulated)"
                  v-model="progressInvoice.unpopulated"
                />
              </template>
            </div>

          </div> <!-- /.app-col -->

          <!-- -------------------------------------------------------------------------------- -->
          <!-- SALARY -->
          <!-- -------------------------------------------------------------------------------- -->
          <div class="app-col half-width text-center"
               :class="genBem(blockClass, 'salary-controls')">
            <h3>{{ $t('salary') }} #{{ salaryId }}</h3>

            <template v-if="isEnabledForceProgressCheck">
              <el-button :class="genBem(blockClass, 'progress-salary-btn')"
                         type="primary"
                         size="mini"
                         @click="handleClickCheckProgressSalary">
                Force Check Progress Salary
              </el-button>
              <br>
              <el-button :class="genBem(blockClass, 'uncalculated-salary-btn')"
                         type="primary"
                         size="mini"
                         @click="handleClickCheckUncalculatedSalary">
                Force Check Uncalculated Salary
              </el-button>
            </template>

            <el-button :class="genBem(blockClass, 'calculate-salary-btn')"
                       type="primary"
                       size="mini"
                       @click="handleClickCalculateSalary">
              Calculate Salary
            </el-button>

            <el-button :class="genBem(blockClass, 'export-salary-btn')"
                       type="primary"
                       size="mini"
                       @click="handleClickExportSalary">
              Export Salary
            </el-button>

            <h3>{{ $t('progress') }}</h3>
            <div :class="genBem(blockClass, 'progress-salary')">
              <p>
                {{ $t('calculated') }}: {{ progressSalary.calculated }}
              </p>

              <template
                v-if="isNotNullNorEmpty(progressSalary.uncalculated)
                || isNotNullNorEmpty(progressSalary.unpopulated)"
              >
                <h3 class="text-danger">{{ $t('errors') }}</h3>

                <financial-export-message-display
                  :title="$t('uncalculated') + ':'"
                  v-if="isNotNullNorEmpty(progressSalary.uncalculated)"
                  v-model="progressSalary.uncalculated"
                />

                <financial-export-message-display
                  :title="$t('unpopulated') + ':'"
                  v-if="isNotNullNorEmpty(progressSalary.unpopulated)"
                  v-model="progressSalary.unpopulated"
                />
              </template>
            </div>

          </div> <!-- /.app-col -->

          <!-- Spacer -->
          <div class="app-col col-container half-width text-center"></div>

        </div> <!-- /.app-row -->
      </div> <!-- /.app-wrapper -->
    </div> <!-- /.app-container -->

  </div> <!-- Component DIV Wrapper -->
</template>

<script>
import {mapGetters} from 'vuex';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import size from 'lodash/size';
import moment from 'moment';
import EmailPicker from '~/components/forms/misc/EmailPicker';
import FinancialExportMessageDisplay from '~/components/displays/financial/export/FinancialExportMessageDisplay';
import {notifError, notifSuccess} from '~/js/helpers/Notification';

export default {

  /*
  |--------------------------------------------------------------------------
  | Component > imported components
  |--------------------------------------------------------------------------
  */
  components: {
    EmailPicker,
    FinancialExportMessageDisplay
  },

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data () {
    return {
      blockClass: 'financial-export-process',
      recipients: [],
      invoiceDrivers: ['departments-xml', 'items-xml', 'fast-excel', 'csv'],
      salaryDrivers: ['tfv-txt'],
      isEnabledForceProgressCheck: false,
      progressInterval: null,
      isProgressCompleteInvoice: false,
      isProgressCompleteSalary: false,
      isProgressCompleteFinancialList: false,
      isLoadingPage: false
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {

    ...mapGetters('financialExport', [
      'itemData',
      'isItemLoading',

      'progressInvoice',
      'progressSalary',
      'progressFinancialList',

      'uncalculatedInvoice',
      'uncalculatedSalary'
    ]),

    /**
     * Returns the Financial List ID according to URL param ID
     *
     * @returns {int}
     */
    financialListId () {
      return isNil(this.$route.params.id)
        ? 0 : parseInt(this.$route.params.id);
    },

    /**
     * Returns the Invoice ID according to this.itemData
     *
     * @return {int}
     */
    invoiceId () {
      return isEmpty(this.itemData)
        ? 0 : this.itemData.invoice.id;
    },

    /**
     * Returns the Salary ID according to this.itemData
     *
     * @return {int}
     */
    salaryId () {
      return isEmpty(this.itemData)
        ? 0 : this.itemData.salary.id;
    },

    /**
     * @returns {boolean}
     */
    isValidRecipients () {
      return !isNil(this.recipients) && !isEmpty(this.recipients);
    },

    /**
     * @returns {boolean}
     */
    isDisabledMarkAsComplete () {
      const start = moment('08:00', 'HH:mm').local().subtract(1, 'minute');
      const end = moment('05:00', 'HH:mm').local().add(1, 'minute');
      return moment().isBetween(start, end);
    }

  },

  /*
  |--------------------------------------------------------------------------
  | Component > method
  |--------------------------------------------------------------------------
  */
  methods: {

    /**
     * @return {void}
     */
    handleClickPrecheck: debounce(function () {
      this.$store.dispatch('financialExport/runPrecheck', {
        financial_list: this.financialListId
      })
          .then((r) => {
            if (r.status === 200 && r.data.status === 'success') {
              this.$notify({
                type: 'success',
                message: 'Precheck Successful'
              });
            } else {
              this.$notify({
                type: 'warning',
                message: 'Something went wrong. Please try again.'
              });
              console.warn(r);
            }
          })
          .catch((e) => {
            this.$notify({
              type: 'error',
              message: 'There is an error. Please contact your administrator.'
            });
            console.error(e);
          });
    }, 750),

    /**
     * @return {void}
     */
    handleClickPrecheckExport: debounce(function () {
      if (!this.isValidRecipients) {
        this.$notify({
          type: 'warning',
          message: 'Please input email recipient.'
        });
        return;
      }

      this.$store.dispatch('financialExport/runPrecheckExport', {
        financial_list: this.financialListId,
        data: {
          recipients: this.recipients
        }
      })
          .then((r) => {
            if (r.status === 200 && r.data.status === 'success') {
              this.$notify({
                type: 'success',
                message: 'Precheck Successful'
              });
            } else {
              this.$notify({
                type: 'warning',
                message: 'Something went wrong. Please try again.'
              });
              console.warn(r);
            }
          })
          .catch((e) => {
            this.$notify({
              type: 'error',
              message: 'There is an error. Please contact your administrator.'
            });
            console.error(e);
          });
    }, 750),

    /**
     * Handle when the Check Progress Invoice button
     * was clicked.
     *
     * @return {void}
     */
    handleClickCheckProgressInvoice: debounce(function () {
      this.$store.dispatch('financialExport/checkProgressInvoice', {
        invoice: this.invoiceId
      });
    }, 750),

    /**
     * Handle when the Check Progress Salary button
     * was clicked.
     *
     * @return {void}
     */
    handleClickCheckProgressSalary: debounce(function () {
      this.$store.dispatch('financialExport/checkProgressSalary', {
        salary: this.salaryId
      });
    }, 750),

    /**
     * Handle when the Check Uncalculated Invoice button
     * was clicked.
     *
     * @return {void}
     */
    handleClickCheckUncalculatedInvoice: debounce(function () {
      this.$store.dispatch('financialExport/checkUncalculatedInvoice', {
        invoice: this.invoiceId
      })
          .then(() => {
            this.uncalculatedChecker(this.uncalculatedInvoice, 'Invoice');
          });
    }, 750),

    /**
     * Handle when the Check Uncalculated Salary button
     * was clicked.
     *
     * @return {void}
     */
    handleClickCheckUncalculatedSalary: debounce(function () {
      this.$store.dispatch('financialExport/checkUncalculatedSalary', {
        salary: this.salaryId
      })
          .then(() => {
            this.uncalculatedChecker(this.uncalculatedSalary, 'Salary');
          });
    }, 750),

    /**
     * Handle when the Calculate Invoice button
     * was clicked.
     *
     * @return {void}
     */
    handleClickCalculateInvoice: debounce(function () {
      this.$store.dispatch('financialExport/calculateInvoice', {
        invoice: this.invoiceId
      });
    }, 750),

    /**
     * Handle when the Calculate Salary button
     * was clicked.
     *
     * @return {void}
     */
    handleClickCalculateSalary: debounce(function () {
      this.$store.dispatch('financialExport/calculateSalary', {
        salary: this.salaryId
      });
    }, 750),

    /**
     * Handle when the Populate Invoice button
     * was clicked.
     *
     * @return {void}
     */
    handleClickPopulateInvoice: debounce(function () {
      this.$store.dispatch('financialExport/populateInvoice', {
        invoice: this.invoiceId
      });
    }, 750),

    /**
     * Handle when the Populate Salary button
     * was clicked.
     *
     * @return {void}
     */
    handleClickPopulateSalary: debounce(function () {
      this.$store.dispatch('financialExport/populateSalary', {
        salary: this.salaryId
      });
    }, 750),

    /**
     * Handle when the Export Invoice button
     * was clicked.
     *
     * @return {void}
     */
    handleClickExportInvoice: debounce(function () {
      if (!this.isValidRecipients) {
        this.$notify({message: 'Please input email recipient.'});
        return;
      }

      this.$store.dispatch('financialExport/exportInvoice', {
        invoice: this.invoiceId,
        data: {
          recipients: this.recipients,
          drivers: this.invoiceDrivers
        }
      }).then(() => {
        notifSuccess({message: 'Export has been sent to recipient.'});

      }).catch((e) => {
        notifError({message: e});
      });
    }, 750),

    /**
     * Handle when the Export Salary button
     * was clicked.
     *
     * @return {void}
     */
    handleClickExportSalary: debounce(function () {
      if (!this.isValidRecipients) {
        this.$notify({
          message: 'Please input email recipient.'
        });
        return;
      }

      this.$store.dispatch('financialExport/exportSalary', {
        salary: this.salaryId,
        data: {
          recipients: this.recipients,
          drivers: this.salaryDrivers
        }
      }).then(() => {
        notifSuccess({message: 'Export has been sent to recipient.'});

      }).catch((e) => {
        notifError({message: e});
      });
    }, 750),

    /**
     * Method to group the progress reseters.
     *
     * @return {void}
     */
    resetProgress () {
      this.$store.commit('financialExport/resetProgressInvoice');
      this.$store.commit('financialExport/resetProgressSalary');
    },

    /**
     * Method to group the progress checkers.
     *
     * @return {void}
     */
    checkProgress () {
      if (!this.isProgressCompleteInvoice) {
        this.$store.dispatch('financialExport/checkProgressInvoice', {
          invoice: this.invoiceId
        }).then(() => {
          const progressInvoice = this.$store.getters['financialExport/progressInvoice'];
          if (
            this.isProgressComplete(progressInvoice.calculated)
            && this.isProgressComplete(progressInvoice.populated)
          ) {
            this.isProgressCompleteInvoice = true;
          }
          this.assessContinuityOfCheckingProgress();
        });
      }

      if (!this.isProgressCompleteSalary) {
        this.$store.dispatch('financialExport/checkProgressSalary', {
          salary: this.salaryId
        }).then(() => {
          const progressSalary = this.$store.getters['financialExport/progressSalary'];
          if (
            this.isProgressComplete(progressSalary.calculated)
            && this.isProgressComplete(progressSalary.populated)
          ) {
            this.isProgressCompleteSalary = true;
          }
          this.assessContinuityOfCheckingProgress();
        });
      }

      if (!this.isProgressCompleteFinancialList) {
        this.$store.dispatch('financialExport/checkProgressFinancialList', {
          financial_list: this.financialListId
        }).then(() => {
          const progressFinancialList = this.$store.getters['financialExport/progressFinancialList'];
          if (this.isProgressComplete(progressFinancialList.checked)) {
            this.isProgressCompleteFinancialList = true;
          }
          this.assessContinuityOfCheckingProgress();
        });
      }
    },

    /**
     * @param {string} progressText
     * @returns {boolean}
     */
    isProgressComplete (progressText) {
      const sections = progressText.split('/');
      const part = (sections[0].substring(0, sections[0].indexOf('(') - 1)).trim();
      const total = sections[1].trim();

      return part === total;
    },

    /**
     * Helper method for checking uncalculated items for the given collection.
     *
     * @param {object} collection - Collection of Uncalculated Entiries.
     * @param label - Reference to be used for the notification.
     * @return {void}
     */
    uncalculatedChecker (collection, label) {
      const uncalculated = size(collection);
      let notifOpts = {
        message: `There are ${uncalculated} uncalculated items for ${label}`
      };

      if (uncalculated !== 0) {
        notifOpts.type = 'warning';
        notifOpts.duration = 0;

      } else {
        notifOpts.type = 'success';
      }

      this.$notify(notifOpts);
    },

    /**
     * Method to group the checkers for uncalculated.
     *
     * @return {void}
     */
    checkUncalculated () {
      this.$store.dispatch('financialExport/checkUncalculatedInvoice', {
        invoice: this.invoiceId
      })
          .then(() => {
            this.uncalculatedChecker(this.uncalculatedInvoice, 'Invoice');
          });

      this.$store.dispatch('financialExport/checkUncalculatedSalary', {
        salary: this.salaryId
      })
          .then(() => {
            this.uncalculatedChecker(this.uncalculatedSalary, 'Salary');
          });
    },

    /**
     * Method to start the checking of progress in a certain interval.
     *
     * @return {void}
     */
    startCheckingProgress () {
      this.progressInterval = setInterval(this.checkProgress, 5000);
    },

    /**
     * Method to stop the continuous check of progress.
     *
     * @return {void}
     */
    stopCheckingProgress () {
      clearInterval(this.progressInterval);
      this.progressInterval = null;
    },

    assessContinuityOfCheckingProgress () {
      if (
        this.isProgressCompleteInvoice
        && this.isProgressCompleteSalary
        && this.isProgressCompleteFinancialList
      ) {
        this.stopCheckingProgress();
      }
    },

    /**
     * @return {void}
     */
    markAsComplete: debounce(function () {
      this.isLoadingPage = true;
      this.$store.dispatch('financialExport/markAsComplete', {
        financialListId: this.financialListId
      }).then(() => {
        notifSuccess({message: `Marked complete #${this.financialListId}`})

      }).catch((e) => {
        notifError({message: e});

      }).finally(() => {
        this.isLoadingPage = false;
      });
    }, 500),

    /**
     * @param {*} v
     * @returns {boolean}
     */
    isNotNullNorEmpty (v) {
      return !isNil(v) && !isEmpty(v);
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted () {
    this.resetProgress();

    this.$store.dispatch('financialExport/getFinancialList', {
      financial_list: this.financialListId
    })
        .then(() => {
          this.checkProgress();
          this.checkUncalculated();
          this.startCheckingProgress();
        });

  },

  /*
  |--------------------------------------------------------------------------
  | Component > beforeDestroy
  |--------------------------------------------------------------------------
  */
  beforeDestroy () {
    this.stopCheckingProgress();
  }

}
</script>

<!--suppress CssUnknownTarget, SassScssResolvedByNameOnly -->
<style lang="scss">
@import '~/scss/global/_variables.scss';

.financial-export-process {

  &__info {
    &-is-complete {
      &--yes, &--no {
        font-weight: bold;
      }

      &--yes {
        color: $app-primary-color;
      }

      &--no {
        color: $app-danger-color;
      }
    }
  }

  &__enable-force-progress-check {
    padding-top: 15px;
  }

  &__invoice-drivers,
  &__salary-drivers {
    height: 140px;

    p {
      margin: 0;
    }

    .el-checkbox {
      display: block;
      margin: 0;
      font-size: 11px;

      &-group {
        width: 150px;
        display: inline-block;
        text-align: left;

      }
    }
  }

  .email-picker {
    max-width: 350px;
  }

  &__mark-as-complete-button {
    float: right;

    &:after {
      content: '';
      clear: both;
      display: table;
    }
  }
}
</style>
