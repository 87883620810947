<template>
    <div class="app-form user-list-filter-form">

        <h3>{{$t('user_filter')}}</h3>

        <el-form ref="formElId" :model="form" :id="formElId">

            <div class="app-splitter three-cols">

                <!-- Search -->
                <el-form-item :id="genContainerId('search',formElId)">
                    <el-input :id="genFieldId('search',formElId)"
                              v-model="form.search"
                              @keyup.enter.native="handleSearch"
                              suffix-icon="el-icon-search"
                              :placeholder="$t('search')"/>
                </el-form-item>

                <!-- User Type -->
                <el-form-item :id="genContainerId('user_type',formElId)">
                    <el-select :id="genFieldId('user_type',formElId)"
                               v-model="form.user_type"
                               @change="handleChangeUserType"
                               :placeholder="$t('select_user_type')" clearable>
                        <el-option :label="$t('admin')" :value="1" v-if="false"/>
                        <el-option :label="$t('customer')" :value="2"/>
                        <el-option :label="$t('translator')" :value="3"/>
                    </el-select>
                </el-form-item>

                <!-- Search From -->
                <el-form-item :id="genContainerId('search_from',formElId)">
                    <el-select :id="genFieldId('search_from',formElId)"
                               v-model="form.search_from"
                               placeholder="Select From Column" clearable>
                        <el-option :label="$t('id')" value="id"/>
                        <el-option :label="$t('name')" value="name"/>
                        <el-option :label="$t('email')" value="email"/>
                        <el-option :label="$t('username')" value="username"/>
                        <el-option :label="$t('dob')" value="dob"/>
                        <el-option :label="$t('mobile')" value="mobile"/>
                        <el-option :label="$t('phone_number')" value="phone"/>
                        <el-option :label="$t('notes')" value="notes"/>
                    </el-select>
                </el-form-item>
                <!-- Search by Town -->
               <el-form-item :id="genContainerId('search_town',formElId)">
                    <el-select :id="genFieldId('search_town',formElId)"
                        :placeholder="$t('town')"   v-model="form.search_town" filterable>
                        <el-option v-for="(v) in townOpts"
                                :key="v.id"
                                :value="v.id"
                                :label="v.name"/>
                    </el-select>
                </el-form-item>

                <!-- search by language -->
                <el-form-item :id="genContainerId('search_language',formElId)">
                    <el-select v-model="form.language_id"  :placeholder="$t('select_language')"  ref="language" multiple filterable>
                   <el-option v-for="language in languages" :key="language.id" :label="language.name" :value="language.id"></el-option>
                         </el-select>
                </el-form-item>

                <!-- Search by country -->
                <el-form-item :id="genContainerId('countries',formElId)"
                            class="label-short">
                    <el-select :id="genFieldId('countries',formElId)"
                            :placeholder="$t('select_country')" v-model="form.countries" filterable >
                        <el-option v-for="(v, k) in countryIsoOpts"
                                :key="k"
                                :label="v"
                                :value="k"/>
                    </el-select>
                </el-form-item>

                <!-- gender -->
                <el-form-item :id="genContainerId('search_gender',formElId)">
                    <el-select :id="genFieldId('search_gender',formElId)"
                               v-model="form.search_gender"
                               :placeholder="$t('select_gender')" clearable>
                        <el-option label="Man" value="male"/>
                        <el-option label="Kvinna" value="female"/>
                    </el-select>
                </el-form-item>

                <!-- Search by Level -->
                <el-form-item :id="genContainerId('user_level',formElId)"
                            class="label-short">
                    <el-select :id="genFieldId('user_level',formElId)"
                            :placeholder="$t('select_translator_level')" v-model="form.user_level" filterable >
                        <el-option v-for="(v, k) in levelsOpts"
                                :key="k"
                                :label="v.name"
                                :value="v.id"/>
                    </el-select>
                </el-form-item>
                <!-- To avoid space between the last 2 fields -->
                <el-form-item>

                </el-form-item>

            </div> <!-- /.app-splitter three-cols -->
            <div class="user-filter-action-buttons">
              <el-button type="default" @click="handleClickReset">{{$t('reset')}}</el-button>
              <el-button type="primary" @click="handleSearch">{{$t('find_now')}}</el-button>
            </div>
        </el-form>

    </div> <!-- /.app-form user-list-filter-form -->
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {forcePopulate} from "../../../js/helpers/Vuex";
      import {populate} from "../../../js/helpers/Vuex";
    import {isEmpty} from "../../../js/helpers/Common";

    let formFresh = {
        search: '',
        user_type: '',
        search_from: 'name',
        user_level:''
    };

    export default {

        data() {
            return {
                formElId: 'user_list_filter_form',
                form: _.cloneDeep(formFresh),
            };
        },
        computed: {
            ...mapGetters('town', {
                townOpts: 'listAsOptions',
            }),
            ...mapGetters({
                languages: 'language/getLanguages',
            }),
            ...mapGetters('form', ['countryIsoOpts']),
            ...mapGetters('translatorLevel', {
                levelsOpts: 'listData',
            }),
        },

        methods: {
            ...mapActions('user', ['browse']),

            ...mapActions('town', {
                loadTowns: 'browseAsOptions'
            }),
            ...mapActions('language',{
                getLanguages: 'fetchLanguages',
            }),
            ...mapActions('translatorLevel', {
                browseTranslatorLevel:'browse'
            }),
            

            handleSearch() {
                // if (!isEmpty(this.form.search) || !isEmpty(this.form.town)) {
                    const q = this.form.search;
                    const key = this.form.search_from;
                    const type = this.form.user_type;
                    const town = this.form.search_town;
                    const gender = this.form.search_gender;
                    const country = this.form.countries;
                    const language = this.form.language_id;
                    const level = this.form.user_level;
                    const params = {};
                    // Set params values.
                    params[`filter[${key}]`] = q;
                    if (!isEmpty(type)) {
                        params['filter[type]'] = type;
                    }
                    if(!isEmpty(town))
                    {
                        params['filter[town]'] = town;
                    }
                    if (!isEmpty(country)) {
                       params["filter[country]"] = country;
                    }
                    if(!isEmpty(gender))
                    {
                        params['filter[gender]'] = gender;
                    }
                    if(!isEmpty(language))
                    {
                        params['filter[language]'] = language;
                    }
                    console.log(type);
                    if(!isEmpty(level))
                    {
                        params['filter[translator_level]'] = level;
                    }
                    
                    this.$router.push({query: params}); // Reflect params to URL.
                    forcePopulate(this, 'browse', 'User Listing', params);
            },

            handleClickReset() {
                this.resetForm();
                forcePopulate(this, 'browse', 'User Listing');
            },

            handleChangeUserType() {
                forcePopulate(this, 'browse', 'User Listing', {
                    "filter[type]": this.form.user_type,
                });
            },

            resetForm() {
                this.$router.push({query: {}});
                this.form = _.cloneDeep(formFresh);
            },
        },

        mounted() {
            populate(this, 'getLanguages', 'languages', {
                ref: 'Languages Options'
            });
            populate(this, 'loadTowns', 'townOpts', {
                ref: 'Towns Listing',
            });
            // Assign the query values stuff to the form ---
            const q = _.clone(this.$route.query);
            const filters = _.filter(Object.keys(q), (v) => _.includes(v, 'filter'));

            if (filters.length > 0) {
                _.each(filters, (x) => {
                    // Form value assignments.
                    this.form.search_from = _.words(x)[1];
                    this.form.search = q[x];
                });
            }
            this.browseTranslatorLevel();
        },
    }
</script>
<style>
.user-filter-action-buttons{
  text-align: right;
}
</style>
