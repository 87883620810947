<template>
    <div class="app-form municipality-compose-form">
        <el-form :model="form" :id="formElId" :ref="formElId" :rules="rules">

            <!-- Name Field -->
            <el-form-item :id="genContainerId('name',formElId)"
                          class="label-short"
                          :label="$t('name')">

                <el-input :id="genFieldId('name',formElId)"
                          v-model="form.name"
                          :placeholder="$t('please_input_municipality_name')"/>
            </el-form-item>

            <!-- Email Field -->
            <el-form-item :id="genContainerId('email',formElId)"
                          class="label-short"
                          :label="$t('email')">

                <el-input :id="genFieldId('email',formElId)"
                          v-model="form.email"
                          :placeholder="$t('please_input_municipality_email')"/>
            </el-form-item>

            <!-- GLN Field -->
            <el-form-item :id="genContainerId('gln',formElId)"
                          class="label-short"
                          label="GLN">

                <el-input :id="genFieldId('gln',formElId)"
                          class="label-short"
                          v-model="form.gln"
                          :placeholder="$t('please_input_gln')"/>
            </el-form-item>

            <!-- Phone Field -->
            <el-form-item :id="genContainerId('phone',formElId)"
                          class="label-short"
                          :label="$t('phone')">

                <el-input :id="genFieldId('phone',formElId)"
                          v-model="form.phone"
                          @change="handlePhoneChange"
                          :placeholder="$t('please_input_municipality_phone')"/>
            </el-form-item>

            <!-- Organization Number Field -->
            <el-form-item :id="genContainerId('organization_number',formElId)"
                          class="label-short"
                          :label="$t('organization_number')">

                <el-input :id="genFieldId('organization_number',formElId)"
                          v-model="form.organization_number"
                          :placeholder="$t('please_input_organization_number')"/>
            </el-form-item>

            <!-- Invoice Type Field -->
            <el-form-item :id="genContainerId('invoice_type',formElId)"
                          class="label-short"
                          :label="$t('invoice_type')">

                <el-select v-model="form.invoice_type" :placeholder="$t('invoice_type')">
                    <el-option v-for="type in invoiceTypes" :key="type" :label="type | capitalize" :value="type"></el-option>
                </el-select>
            </el-form-item>

            <!-- Verified For Invoice Field -->
            <el-form-item :id="genContainerId('invoice_verification',formElId)"
                          class="label-short"
                          :label="$t('verified_for_invoice')">

                <el-select v-model="form.invoice_verification" :placeholder="$t('verified_for_invoice')">
                    <el-option v-for="(key, value) in invoiceSalaryVerification" :key="key" :label="value | capitalize" :value="key"></el-option>
                </el-select>
            </el-form-item>

            <!-- Verified For Salary Field -->
            <el-form-item :id="genContainerId('salary_verification',formElId)"
                          class="label-short"
                          :label="$t('verified_for_salary')">

                <el-select v-model="form.salary_verification" :placeholder="$t('verified_for_salary')">
                    <el-option v-for="(key, value) in invoiceSalaryVerification" :key="key" :label="value | capitalize" :value="key"></el-option>
                </el-select>
            </el-form-item>

            <!-- Address Field -->
            <el-form-item :id="genContainerId('address',formElId)"
                          class="label-short"
                          :label="$t('address')">

                <booking-maps-address :id="genFieldId('address',formElId)"
                                      v-model="form.address"
                                      :city.sync="form.city" />

            </el-form-item>

            <!-- Address 2 Field -->
            <el-form-item :id="genContainerId('address_2',formElId)"
                          class="label-short"
                          :label="$t('address_2')">

                <el-input :id="genFieldId('address_2',formElId)"
                          v-model="form.address_2"
                          :placeholder="$t('please_input_municipality_address2')"/>
            </el-form-item>

            <!-- City Field -->
            <el-form-item :id="genContainerId('city',formElId)"
                          class="label-short"
                          :label="$t('city')">

                <el-input :id="genFieldId('city',formElId)"
                          v-model="form.city"
                          :placeholder="$t('please_input_city')"/>
            </el-form-item>

            <!-- Post Code Field -->
            <el-form-item :id="genContainerId('post_code',formElId)"
                          class="label-short"
                          :label="$t('post_code')">

                <el-input :id="genFieldId('post_code',formElId)"
                          v-model="form.post_code"
                          :placeholder="$t('please_input_postal_code')"/>
            </el-form-item>

            <el-form-item :id="genContainerId('country_iso',formElId)"
                          :label="$t('country')" class="label-short">
                <el-select :id="genFieldId('country_iso',formElId)"
                           v-model="form.country_iso" filterable
                           :placeholder="$t('country')">
                    <el-option v-for="(v, k) in countryIsoOpts"
                               :key="k"
                               :label="v"
                               :value="k"/>
                </el-select>
            </el-form-item>
            <el-form-item :id="genContainerId('email_for_cost_place',formElId)"
                          :label="$t('email_questions_about_cost_place')" class="label-short">
                          <el-input :id="genFieldId('please_input_email_ask_about_cost_place',formElId)" v-model="form.email_for_cost_place" :placeholder="$t('please_input_organization_number')"/>
            </el-form-item>
            <el-form-item :id="genContainerId('town_id',formElId)"
                          :label="$t('town_for_project')" class="label-short">
                <el-select :id="genFieldId('town_id',formElId)"
                           v-model="form.town_id"
                           filterable
                           :placeholder="$t('select_town_project')"
                           @focus="handleClickTownSelection"
                >
                    <el-option v-for="(v) in townOpts"
                               :key="v.id"
                               :value="v.id"
                               :label="v.name"/>
                </el-select>
            </el-form-item>

                  <el-form-item :id="genContainerId('town_id',formElId)"
                          :label="$t('blacklisted_translators')" class="label-short">
                <el-select :id="genFieldId('blacklisted_users',formElId)"
                           v-model="form.blacklisted_users"
                           @remove-tag="removeBlackListedTranslator"
                           :placeholder="$t('please_select_blacklisted_translator')" multiple filterable
                           @focus="handleClickBlacklistedTranslatorSelection"
                >
                    <el-option v-for="(v) in translatorOpts"
                               :key="v.id"
                               :value="v.id"
                               :value-key="v.id"
                               :label="v.name">
                        {{v.name}}
                    </el-option>
                </el-select>
            </el-form-item>
            <unified-number-setting v-model="form"></unified-number-setting>


          <email-setting-fields v-model="form"></email-setting-fields>


          <el-form-item :id="genContainerId('notes',formElId)"
                      :label="$t('notes')" class="label-short">
                <el-input :id="genFieldId('notes',formElId)"
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 7}"
                        v-model="form.notes"
                        :placeholder="$t('please_input_notes_about_municipality')"/>
            </el-form-item>

            <el-collapse v-model="activePanels">
                <el-collapse-item
                    :name="1"
                    :title="$t('user_related_fields')"
                    :class="genBem(blockCls, 'user-related-fields')"
                >
                <div class="app-splitter two-cols">
                    <el-form-item :id="genContainerId('customer_type',formElId)"
                          :label="$t('customer_type')" class="label-medium">
                        <el-select :id="genFieldId('customer_type',formElId)"
                            filterable
                            v-model="form.customer_type_id"
                            :placeholder="$t('select_customer_type')"
                            @focus="handleClickCustomerTypeSelection"
                        >
                            <el-option v-for="(v) in customerTypeOpts"
                                :key="v.id"
                                :value="v.id"
                                :label="v.name"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                          :id="genContainerId('allow_send_invoices',formElId)"
                          :label="$t('allow_send_invoices')"
                          class="label-medium">
                        <el-switch :id="genFieldId('allow_send_invoices',formElId)"
                            v-model="form.allow_send_invoices"/>
                    </el-form-item>
                </div>
                <div class="app-splitter two-cols">
                    <el-form-item :id="genContainerId('allow_show_invoices',formElId)"
                          :label="$t('allow_show_invoices')" class="label-medium">
                        <el-switch :id="genFieldId('allow_show_invoices',formElId)"
                            v-model="form.allow_show_invoices"/>
                    </el-form-item>
                    <el-form-item
                          :id="genContainerId('allow_fallback_type',formElId)"
                          :label="$t('allow_fallback_type')"
                          class="label-medium">
                        <el-switch :id="genFieldId('allow_fallback_type',formElId)"
                            v-model="form.allow_fallback_type"/>
                    </el-form-item>
                </div>

                </el-collapse-item>
                <!-- Booking Confirm Fields -->
                <el-collapse-item :name="2"
                    :title="$t('booking_confirm_fields')"
                    :class="genBem(blockCls, 'booking-confirm-fields')">
                    <el-form-item>
                        <user-booking-confirm-fields is-municipality v-model="bcf" :form-el-id="formElId"/>
                    </el-form-item>
                </el-collapse-item>
                <!-- Booking Confirm Fields -->
                <el-collapse-item :name="3"
                    :title="$t('user_registration')"
                    :class="genBem(blockCls, 'user-registration')">
                    <div class="app-splitter two-cols">
                        <el-form-item
                            :id="genContainerId('registration_enabled',formElId)"
                            :label="$t('qs_registration_enabled')"
                            class="label-medium">
                            <el-switch
                                v-model="form.registration_enabled">
                                </el-switch>
                        </el-form-item>
                        <div v-if="form.registration_enabled">
                            <el-form-item
                                :id="genContainerId('valid_domains',formElId)"
                                :label="$t('list_valid_email_domains')"
                                class="label-medium">
                                <el-select
                                    no-data-text="Write a valid domain"
                                    v-model="valid_domains_array"
                                    multiple
                                    filterable
                                    allow-create
                                    default-first-option
                                    :placeholder="$t('type_valid_email_domains')"
                                    @change="handleDomainSelectionChange"
                                    >
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Company Label">
                                <el-input :id="genFieldId('company_name_register_label' ,formElId)" v-model="form.company_name_register_label" :placeholder="$t('input_company_label')"/>
                            </el-form-item>
                            <el-form-item label="Department Label">
                                <el-input :id="genFieldId('department_name_register_label' ,formElId)" v-model="form.department_name_register_label" :placeholder="$t('input_department_label')"/>
                            </el-form-item>
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item :name="4"
                    :title="$t('contract_validity')"
                    :class="genBem(blockCls, 'contract-information')">

                    <el-form-item :id="genContainerId('contract_start',formElId)" :label="$t('contract_start')" class="label-short" prop="contract_start">
                        <el-date-picker v-model="form.contract_start" :placeholder="$t('select_contract_start_date')" format="yyyy-MM-dd" value-format="yyyy-MM-dd" popper-class="day-picker-only"></el-date-picker>
                    </el-form-item>
                    <el-form-item :id="genContainerId('contract_end',formElId)" :label="$t('contract_end')" class="label-short" prop="contract_end">
                        <el-date-picker v-model="form.contract_end" :placeholder="$t('select_contract_end_date')" format="yyyy-MM-dd" value-format="yyyy-MM-dd" popper-class="day-picker-only"></el-date-picker>
                    </el-form-item>
                    <el-form-item :id="genContainerId('renegotiation_date',formElId)" :label="$t('renegotiation_date')" class="label-short" >
                        <el-date-picker v-model="form.renegotiation_date" :placeholder="$t('select_renegotiation_date')" format="yyyy-MM-dd" value-format="yyyy-MM-dd" popper-class="day-picker-only"></el-date-picker>
                    </el-form-item>
                </el-collapse-item>
            </el-collapse>

            <!-- settings -->
            <h3>{{$t('settings')}}</h3>
            <el-collapse class="panel-tabbed">
                <el-row :gutter="5">
                    <el-col :md="12">
                        <el-collapse-item :title="$t('invoice_reminder_settings')" name="invSettings">
                            <invoice-reminder-settings-fields v-model="invoiceReminder" />
                        </el-collapse-item>
                    </el-col>
                    <el-col :md="12">
                        <el-collapse-item :title="$t('batch_settings')" name="batches_settings">
                            <!-- Batch Setting Template Button -->
                            <el-button :id="genFieldId('batch_btn',formElId)"
                                    type="primary"
                                    @click="handleBatchSetting">
                                {{$t('batch_setting_template')}}
                            </el-button>
                            <sector-batch-settings-form :changeBatchSetting="changeBatchSetting" :batchTemplateSetting="batchTemplateSetting"/>
                        </el-collapse-item>
                    </el-col>
                </el-row>


            </el-collapse>

            <!-- Confidential -->
            <h3>{{$t('is_confidential')}}</h3>
           <div class="app-splitter two-cols">
              <el-form-item :id="genContainerId('is_confidential',formElId)"
                          :label="$t('is_confidential')" class="label-switch">
                            <span slot="label" ><p>{{$t('protected_identity')}}</p></span>
                <el-switch :id="genFieldId('is_protected_identity',formElId)"
                           v-model="form.is_protected_identity" :active-value="1" :inactive-value="0" @change="handleInfoChange(form.is_protected_identity)"/>

            </el-form-item>
            <el-form-item :id="genContainerId('', formElId)"
              :label="$t('senesitive_information')" class="label-switch">
               <span slot="label" ><p>{{$t('senesitive_information')}}</p></span>
                <el-switch :id="genFieldId('is_sensitive_info',formElId)"
                v-model="form.is_sensitive_info" :active-value="1" :inactive-value="0" @change="handleInfoChange(form.is_sensitive_info)"/>
            </el-form-item>
           </div>

                    <div v-if="form.is_sensitive_info || form.is_protected_identity == 1">
            <el-form-item :id="genContainerId('comments',formElId)"
                          label="" class="label-block">
                <el-input :id="genFieldId('comments' ,formElId)"
                          v-model="form.confidential_message"
                          type="textarea"
                          :rows="2"/>
            </el-form-item>
                    </div>
            <!-- End Confidential -->
            <!-- Save button -->
            <el-form-item :id="genContainerId('save_btn',formElId)">

                <el-button :id="genFieldId('save_btn',formElId)"
                           type="primary"
                           @click="handleSubmit">
                    {{$t(mode)}}
                </el-button>

            </el-form-item>

            <municipality-batch-setting :company="company" :changeFunction="handleBatchSetting" :dialogVisible="dialogVisible"></municipality-batch-setting>

        </el-form>
        <import-batch-template :dialogVisible="importBatchDialog" :changeFunction="changeFunction" :importBatchTemplate="importBatchTemplate" ></import-batch-template>
    </div> <!-- /.app-form municipality-compose-form -->
</template>

<script>
    import {mapState, mapGetters, mapMutations, mapActions} from "vuex";
    import {checkBreadMode} from "../../../../js/helpers/Route";
    import UserBookingConfirmFields from '~/components/forms/user/questions-fields/UserBookingConfirmFields';
    import {populate} from '~/js/helpers/Vuex';
    import {makeMobileInternational} from "../../../../js/helpers/Common";
    import { isEmpty } from '../../../../js/helpers/Common';

    let moduleTarget = 'municipality';

    export default {

        components: {
            UserBookingConfirmFields,
        },

        props: ['company'],
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            var checkEnd = (rule, value, callback) => {
                console.log(this.$refs[moduleTarget + '_compose_form'].model.contract_start);
                if(!isEmpty(this.$refs[moduleTarget + '_compose_form'].model.contract_start)){
                    let start = window.moment(this.$refs[moduleTarget + '_compose_form'].model.contract_start)
                    let val = window.moment(value)
                    // if(value === ''){
                    // return callback(new Error('Please input contract end value'));
                    // }else
                    if(start.isSameOrAfter(val) ){
                    return callback(new Error('Contract end date must be after contract start date'));
                    }else{
                        callback()
                    }
                } else {
                    callback()
                }

            }
            var checkStart = (rule, value, callback) => {
                if(!isEmpty(this.$refs[moduleTarget + '_compose_form'].model.contract_end)){

                    let end = window.moment(this.$refs[moduleTarget + '_compose_form'].model.contract_end)
                    let val = window.moment(value)
                    // if(value === ''){
                    // return callback(new Error('Please input contract start value'));
                    // }else
                     if(val.isSameOrAfter(end) ){
                    return callback(new Error('Contract start date must be before contract end date'));
                    }else{
                        callback()
                    }
                } else {
                    callback()
                }
            }
             var checkNumberSetting = (rule, value, callback) => {
                    let status =  this.$refs[moduleTarget + '_compose_form'].model.number_settings_status
                    if(status == 'inherit' || status == 'allowed')
                    {
                        if(!this.$refs[moduleTarget + '_compose_form'].model.number_settings_number)
                        {
                           return callback(new Error('Number must be entered when '+status+ ' is selected'));
                        }
                        else {
                             callback()
                        }
                    }
            }
            return {

                formElId: moduleTarget + '_compose_form',
                activePanels: [1, 2, 3, 4],
                blockCls: 'municipality-compose-page',
                valid_domains_array: [],
                dialogVisible : false,
                bcf: {},
                rules:{
                    contract_start:[
                         {validator: checkStart,trigger:'blur'}
                    ],
                    contract_end:[

                        {validator: checkEnd,trigger:'blur'}
                    ],
                    renegotiation_date:[
                        //{required:true,message:'Please input date'}
                    ],
                    number_settings_number: [
                        {validator: checkNumberSetting}
                    ],
                },
                changeBatchSetting: false,
                batchTemplateSetting: {},
                importBatchDialog: false

            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapState(moduleTarget, {
                form: 'compose',
                payloadData: 'payloadData',
            }),

            ...mapState('invoiceReminderSettings', {
                invoiceReminder: 'compose'
            }),

            ...mapState('municipality', {
                blacklisted_users_array : 'compose'
            }),


            ...mapGetters(moduleTarget, {
                entryData: 'itemData',
            }),

            ...mapGetters('form', [
                'countryIsoOpts',
                'invoiceTypes',
                'invoiceSalaryVerification'
            ]),
            ...mapGetters({
                translatorOpts: 'translator/listAsOptions',
                townOpts: 'town/listAsOptions',
                customerTypeOpts: 'customerType/listAsOptions',
            }),

            /**
             * Value to determine the current mode whether it's add or edit.
             *
             * @return {string}
             */
            mode() {
                return checkBreadMode(this);
            },
            municipalityId() {
                return this.$route.params.id
            }

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            ...mapActions(moduleTarget, {
                addEntry: 'add',
                editEntry: 'edit',
                readEntry: 'read',
            }),
            ...mapActions('customer', {
                editBcfConfig: 'editBcfConfig',
                readBcfConfig: 'readBcfConfigMunicipality',
            }),

            ...mapMutations(moduleTarget, {
                resetForm: 'resetCompose',
            }),
            ...mapActions({
                loadTranslatorOpts: 'translator/browseAsOptions',
                loadTownOpts: 'town/browseAsOptions',
                storeBlacklist: 'feedback/storeBlacklist',
                loadCustomerTypeOpts: 'customerType/browseAsOptions',
                deleteBlackListedUser: 'blacklistedUsers/deleteBlackListedUser'
            }),

            handleClickBlacklistedTranslatorSelection () {
                populate(this, 'loadTranslatorOpts', 'translatorOpts', {
                ref: 'Translator Options Listing'
                });
            },

            /**
             * Handle when the form has been submitted.
             *
             * @return {void}
             */
            handleSubmit() {
                this.$refs[this.formElId].validate((valid) => {
                    if(valid){
                        this.submitInvoiceReminder();
                        this.form.valid_domains = this.valid_domains_array + ''; // to convert it in comma separated string
                        // Set the action to be made.
                        let action = this.mode + 'Entry';
                        let payload = {}; // Set container for payload.

                        // If the current compose mode is EDIT, then
                        // add the ID of the target into the payload.
                        if (this.mode == 'edit') {
                            payload.id = this.$route.params.id;
                        }

                        this[action](payload).then(r =>{
                            const municipalityId = r.data.data.municipality.id;
                            this.handleUpdateBcf(municipalityId);
                        });
                    }
                })

            }, // End of handleSubmit() method

            handleBatchSetting() {
                    this.importBatchDialog = !this.importBatchDialog;
            },
            changeFunction() {
                this.importBatchDialog = !this.importBatchDialog
            },
            importBatchTemplate(val) {
                this.changeBatchSetting = true;
                this.batchTemplateSetting = val;
                this.importBatchDialog = false;
            },

            setDefaults(mappings) {
                _.forEach(mappings, (value, key) => {
                    this.form[key] = this.form[key] || value;
                });
            },
            ...mapActions('invoiceReminderSettings', {
                getInvoiceReminderSetting: 'getInvoiceReminderSetting',
                createOrUpdateInvoiceReminderSetting: 'createOrUpdateInvoiceReminderSetting'
            }),

            prepareInvoiceSettings() {
                let payload = {
                    params : {
                        'filter[municipality_id]': this.municipalityId
                    }
                }
                this.getInvoiceReminderSetting(payload);
            },

            submitInvoiceReminder() {
                 if(this.invoiceReminder) {
                    this.invoiceReminder.municipality_id = this.municipalityId
                    this.createOrUpdateInvoiceReminderSetting(this.invoiceReminder);
                }
            },

            /**
            * Handle when the configuration for Booking Confirm Fields is submitted.
            *
            * @return {void}
            */
            async handleUpdateBcf (municipalityId) {
                // Define the data to be sent.
                let data = _.cloneDeep(this.bcf);
                console.log(data);
                if(!isEmpty(data)) {
                data.municipality_id = municipalityId;
                delete data.customer_id;
                // Reach out to API for creating/updating the entry.
                await this.editBcfConfig({data});
                }
                this.$router.push({name:'municipality.edit',params:{id:municipalityId}})
            },
            handleDomainSelectionChange(value) {
                const validDomains = value.filter(single => {
                    return /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/.test(single)
                })
                this.valid_domains_array = validDomains;
            },
            handleInfoChange(value) {
                if(value == 0) {
                    this.form.confidential_message = '';
                }
            },

            /**
             * Handler when the town selector field was clicked.
             *
             * @return {void}
             */
            handleClickTownSelection () {
                populate(this, 'loadTownOpts', 'townOpts', {
                ref: 'Towns Options Listing'
                });
            },

            /**
            * Handler when the customer type selector field was clicked.
            *
            * @return {void}
            */
            handleClickCustomerTypeSelection () {
            populate(this, 'loadCustomerTypeOpts', 'customerTypeOpts', {
                ref: 'Customer Type Options Listing'
            });
            },
            removeBlackListedTranslator(v) {
                 let obj = this.blacklisted_users_array.blacklisted_users_array.filter((id) =>  {
                   return id.translator_id === v
                })
                if(obj) this.deleteBlackListedUser(obj[0].id);
            },
            handlePhoneChange(){
              this.form.phone = makeMobileInternational(this.form.phone)
            }


        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
       watch: {
                    "$route.params.id"(val) {
                    let includes = [];
                    // call the method which loads your initial state
                  this.readEntry({id: this.$route.params.id});
            }
        },
        mounted() {
            this.prepareInvoiceSettings();
            this.resetForm();

            // If the current mode is on edit, fetch the property values for the targeted entry.
            if (this.mode == 'edit') {
                this.readEntry({id: this.$route.params.id}).then(response => {
                    this.form.registration_enabled = this.form.registration_enabled == 1 ? true : false;
                    this.valid_domains_array = this.form.valid_domains.split(',')
                });
                this.readBcfConfig({municipality_id: this.$route.params.id,level:"municipality"}).then(response => {
                    this.bcf = _.cloneDeep(response.data.data.booking_field)
                })
            }

            this.setDefaults({
                invoice_type: 'none',
                invoice_verification: 0, // warning
                salary_verification: 0, // warning
                country_iso: 'SE' // Sweden
            });

        }, // End of Component > mounted

    } // End of export default
</script>

<style lang="scss" scoped>
.municipality-compose-page__contract-information{
    .el-form-item{
        margin-bottom:20px;
    }
}
</style>
