/*
|--------------------------------------------------------------------------
| Financial > shared > props > businessRules
|--------------------------------------------------------------------------
*/

'use strict';

import constants from './constants';
import {genPropsFromCollection} from '~/js/helpers/Vuex';

export const partsTimingsBase = {
  parts_step: 0,
  parts_max: 0,
};

export const bookingTimingsBase = {
  minimum_hours: 0,
  succeeding_hours: 0,
};

// export const lateCancellationRulesBase = {
//   hours: 0,
// };

export default {

  parts_step: 0,
  parts_max: 0,
  rounding_minutes: 0,

  parts_timings: genPropsFromCollection(
    constants.BOOKING_TYPES, {
      ...partsTimingsBase,
    }
  ),

  booking_timings: genPropsFromCollection(
    constants.BOOKING_TYPES, {
      ...bookingTimingsBase,
    }
  ),

  // late_cancellation_rules: genPropsFromCollection(
  //   constants.BOOKING_TYPES, {
  //     ...lateCancellationRulesBase,
  //   },
  // ),

}
