<template>
    <div :class="getBem(blockCls)" v-if="isValueValid">

        <p :class="getBem(blockCls, 'label')">Allowance Fees</p>

        <div :class="getBem(blockCls, 'loop-wrapper')">
            <div :class="getBem(blockCls, 'loop')" v-for="i in indexes">

                <div :class="getBem(blockCls, 'fields')">

                    <!-- Minimum -->
                    <el-form-item
                        :class="getBem(blockCls, 'minimum-minutes-container')"
                        :label="$t('min_minutes')"
                    >
                        <el-input
                            v-model="valueCn[i].minimum_minutes"
                            size="mini"
                            :class="getBem(blockCls, 'minimum-minutes-field')"
                        />
                    </el-form-item>

                    <!-- Fee -->
                    <el-form-item
                        :class="getBem(blockCls, 'fee-container')"
                        :label="$t('fee')"
                    >
                        <el-input
                            v-model="valueCn[i].fee"
                            size="mini"
                            :class="getBem(blockCls, 'fee-field')"
                        />
                    </el-form-item>
                </div>

                <div :class="getBem(blockCls, 'remove-action')">
                    <a href="#" @click.prevent="removeField(i)">
                        <i class="fa fa-trash-o"></i>
                    </a>
                </div>

            </div>
        </div>

        <div :class="getBem(blockCls, 'add-action')">
            <a href="#" @click.prevent="addField">
                {{$t('add_field')}} <i class="fa fa-plus"></i>
            </a>
        </div>
    </div>
</template>

<script>
  import Vue from 'vue';
  import {allowanceFeesItem} from '~/js/store/financial/settings/shared/props/otherSettings';
  import isNil from 'lodash/isNil';
  import isEmpty from 'lodash/isEmpty';
  import keys from 'lodash/keys';
  import debounce from 'lodash/debounce';
  import forOwn from 'lodash/forOwn';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      value: {
        type: Array,
        required: true,
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-allowance-fees-field',
        valueCn: {},
        updateEmitted: false,
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > watch
    |--------------------------------------------------------------------------
    */
    watch: {

      /**
       * this.value watcher.
       */
      value () {
        if (!this.isEmittedValueUpdate) {
          this.applyValueToField();

        } else {
          if (this.isEmittedValueUpdate) this.isEmittedValueUpdate = false;
        }
      },

      /**
       * this.valueCn watcher.
       */
      valueCn: {
        deep: true,
        handler: debounce(function () {
          this.applyFieldToValue();
        }, 500),
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Returns boolean to determine whether if the value/v-model
       * provided is valid.
       *
       * @returns {boolean}
       */
      isValueValid () {
        return !isNil(this.value)
          && !isEmpty(this.value);
      },

      /**
       * Returns collection of indexes taken from this.valueCn.
       *
       * @returns {array}
       */
      indexes () {
        return !isNil(this.valueCn)
        && !isEmpty(this.valueCn)
          ? keys(this.valueCn)
          : [];
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * Add a new repeating field.
       *
       * @returns {void}
       */
      addField () {
        const increasedIndex = (this.indexes.length - 1) + 1;
        Vue.set(this.valueCn, increasedIndex, {...allowanceFeesItem});
      },

      /**
       * Remove a new repeating field.
       *
       * @returns {void}
       */
      removeField (index) {
        Vue.delete(this.valueCn, index)
      },

      /**
       * Reflect this.value into this.valueCn.
       *
       * @returns {void}
       */
      applyValueToField () {
        this.valueCn = {};

        if (this.isValueValid) {
          const len = this.value.length;

          for (let i = 0; i < len; i++) {
            const obj = this.value[i];
            this.valueCn[i] = obj;
          }
        }
      },

      /**
       * Reflect this.valueCn to this.value.
       *
       * @returns {void}
       */
      applyFieldToValue () {
        let result = [];

        forOwn(this.valueCn, (o) => { result.push(o); });
        this.$emit('input', result);

        if (!this.isEmittedValueUpdate) this.isEmittedValueUpdate = true;
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      this.applyValueToField();
    },
  }
</script>

<style lang="scss">
    .financial-allowance-fees-field {
        &__label {
            margin-top: 5px;
            font-weight: bold;
            font-size: 12px;
        }

        &__fields {
            .el-form-item {
                display: inline-block;
                box-sizing: border-box;
                width: 49%;
                padding-right: 1%;

                &:last-child {
                    padding-right: 0;
                }

                &__label {
                    text-align: left;
                    margin-bottom: 0;
                    padding-right: 0;
                    width: 100%;
                }
            }
        }

        &__loop {
            display: table;
            width: 100%;
            margin-bottom: 15px;
        }

        &__loop:last-child {
            margin-bottom: 0;
        }

        &__fields, &__remove-action {
            display: table-cell;
            vertical-align: text-bottom;
        }

        &__remove-action {
            a, a:focus, a:hover, a:visited, a:active {
                display: block;
                padding: 7px;
                color: #BDBDBD;
            }
        }

        &__add-action {
            a, a:focus, a:hover, a:visited, a:active {
                display: block;
                color: #65b4c6;
                font-size: 11px;
            }
        }

        // **************************************************
        // Extra-Extra small/Mobile Portrait Viewport
        @media (max-width: 480px - 1) {
            .financial-allowance-fees-field {
                &__fields {
                    .el-form-item {
                        width: 100%;
                        padding-right: 0;
                    }
                }

                &__fields, &__remove-action {
                    display: table-cell;
                    vertical-align: middle;
                }
            }
        }
    }
</style>
