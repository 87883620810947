<template>
    <div class="app-fields user-questions-translator-fields">

        <div class="app-splitter two-cols">

            <!-- Has Training Certificate? -->
            <!-- <el-form-item :id="genContainerId('has_training_certificate',formElId)"
                          :label="$t('has_training_certificate')"
                          class="label-medium">
                <el-switch :id="genFieldId('has_training_certificate',formElId)"
                           v-model="iValue.has_training_certificate"/>
            </el-form-item> -->

            <!-- Has Police Background? -->
            <!-- <el-form-item :id="genContainerId('has_police_background',formElId)"
                          :label="$t('has_police_background')"
                          class="label-medium">
                <el-switch :id="genFieldId('has_police_background',formElId)"
                           v-model="iValue.has_police_background"/>
            </el-form-item> -->

            <!-- Has Contract? -->
            <!-- <el-form-item :id="genContainerId('has_contract',formElId)"
                          :label="$t('has_contract')"
                          class="label-medium">
                <el-switch :id="genFieldId('has_contract',formElId)"
                           v-model="iValue.has_contract"/>
            </el-form-item> -->

            <!-- Has Driving License? -->
            <!-- <el-form-item :id="genContainerId('has_driving_license',formElId)"
                          :label="$t('has_driving_license')"
                          class="label-medium">
                <el-switch :id="genFieldId('has_driving_license',formElId)"
                           v-model="iValue.has_driving_license"/>
            </el-form-item> -->

            <!-- Traveling by -->
            <el-form-item :id="genContainerId('traveling_by',formElId)"
                          :label="$t('traveling_by')"
                          class="label-medium">
                          <el-select v-model="iValue.traveling_by" :placeholder="$t('select')" style="width:50%;">
                            <el-option :label="$t('public_transport')" value="public_transport"></el-option>
                            <el-option :label="$t('car')" value="car"></el-option>
                          </el-select>
            </el-form-item>

            <!-- Is User mentor? -->
            <el-form-item :id="genContainerId('is_mentor',formElId)"
                          :label="$t('mentor')"
                          class="label-medium">
                <el-switch :id="genFieldId('is_mentor',formElId)"
                           v-model="iValue.is_mentor"/>
            </el-form-item>

            <!-- Is User enabled? -->
            <el-form-item :id="genContainerId('is_enabled',formElId)"
                          :label="$t('is_user_enabled')"
                          class="label-medium">
                <el-switch :id="genFieldId('is_enabled',formElId)"
                           v-model="iValue.enabled"/>
            </el-form-item>

            <!-- Is User passive? -->
            <el-form-item :id="genContainerId('is_passive',formElId)"
                          :label="$t('is_user_passive')"
                          class="label-medium">
                <el-switch :id="genFieldId('is_passive',formElId)"
                           v-model="iValue.is_passive"/>
            </el-form-item>

            <div>
              <el-form-item v-if="!iValue.enabled" :id="genContainerId('disable_comment',formElId)"
                  :label="$t('reason_for_disabling_user')"
                  class="label-medium">
                  <el-input :id="genFieldId('disable_comment',formElId)"
                        v-model="iValue.disable_comment" type="textarea"
                        :rows="5"/>
              </el-form-item>
            </div>

        </div> <!-- DIV column splitter -->

        <h3>{{$t('notifications')}}</h3>

        <div class="app-splitter two-cols">

            <!-- Notify? -->
            <el-form-item :id="genContainerId('translator_notify',formElId)"
                          :label="$t('notify')"
                          class="label-medium">
                <el-switch :id="genFieldId('translator_notify',formElId)"
                           v-model="iValue.translator_notify"/>
            </el-form-item>

            <!-- Notify Emergency? -->
            <el-form-item :id="genContainerId('translator_notify_emergency',formElId)"
                          :label="$t('notify_emergency')"
                          class="label-medium">
                <el-switch :id="genFieldId('translator_notify_emergency',formElId)"
                           v-model="iValue.translator_notify_emergency"/>
            </el-form-item>

            <!-- Notify Night time? -->
            <el-form-item :id="genContainerId('translator_notify_nighttime',formElId)"
                          :label="$t('notify_night_time')"
                          class="label-medium">
                <el-switch :id="genFieldId('translator_notify_nighttime',formElId)"
                           v-model="iValue.translator_notify_nighttime"/>
            </el-form-item>

        </div> <!-- DIV column splitter -->

        <!--<h3>{{$t('availability')}}</h3>

        <div class="app-splitter two-cols">

            <el-form-item :id="genContainerId('availability_switch',formElId)"
                          :label="$t('text_enable_translator_availability_feature')"
                          class="label-medium">
                <el-switch :id="genFieldId('availability_switch',formElId)"
                           v-model="iValue.availability_switch"/>
            </el-form-item>

            <template v-if="iValue.availability_switch">

                <el-form-item :id="genContainerId('available',formElId)"
                              :label="$t('is_translator_available')"
                              class="label-medium">
                    <el-switch :id="genFieldId('available',formElId)"
                               v-model="iValue.available"/>
                </el-form-item>

                <el-form-item :id="genContainerId('available_from',formElId)"
                              :label="$t('available_from')"
                              class="label-short">
                    <el-date-picker :id="genFieldId('available_from_date',formElId)"
                                    style="width:47%;"
                                    v-model="iValue.available_from.date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    popper-class="day-picker-only"
                                    size="mini"/>
                    <el-time-picker :id="genFieldId('available_from_time',formElId)"
                                    style="width:47%;"
                                    v-model="iValue.available_from.time"
                                    format="HH:mm"
                                    value-format="HH:mm:ss"
                                    size="mini"/>
                </el-form-item>

                <el-form-item :id="genContainerId('available_until',formElId)"
                              :label="$t('available_until')"
                              class="label-short">
                    <el-date-picker :id="genFieldId('available_until_date',formElId)"
                                    style="width:47%;"
                                    v-model="iValue.available_until.date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    popper-class="day-picker-only"
                                    size="mini"/>
                    <el-time-picker :id="genFieldId('available_until_time',formElId)"
                                    style="width:47%;"
                                    v-model="iValue.available_until.time"
                                    format="HH:mm"
                                    value-format="HH:mm:ss"
                                    size="mini"/>
                </el-form-item>


            </template>

        </div>--> <!-- DIV column splitter -->

        <h3>{{$t('is_confidential')}}</h3>
           <div class="app-splitter two-cols">
              <el-form-item :id="genContainerId('is_confidential',formElId)"
                          :label="$t('is_confidential')" class="label-switch">
                            <span slot="label" ><p>{{$t('protected_identity')}}</p></span>
                <el-switch :id="genFieldId('is_protected_identity',formElId)"
                           v-model="iValue.is_protected_identity" :active-value="1" :inactive-value="0" @change="handleInfoChange(iValue.is_protected_identity)"/>

            </el-form-item>
            <el-form-item :id="genContainerId('', formElId)"
              :label="$t('senesitive_information')" class="label-switch">
               <span slot="label" ><p>{{$t('senesitive_information')}}</p></span>
                <el-switch :id="genFieldId('is_sensitive_info',formElId)"
                v-model="iValue.is_sensitive_info" :active-value="1" :inactive-value="0" @change="handleInfoChange(iValue.is_sensitive_info)"/>
            </el-form-item>
           </div> <!--  div column splitter -->

         <div v-if="iValue.is_sensitive_info || iValue.is_protected_identity == 1">
            <el-form-item :id="genContainerId('comments',formElId)"
                          label="" class="label-block">
                <el-input :id="genFieldId('comments' ,formElId)"
                          v-model="iValue.confidential_message"
                          type="textarea"
                          :rows="2"/>
            </el-form-item>

           </div> <!-- div comment box -->
          <h3>{{$t('employement')}}</h3>
          <div class="employement-section">
               
            <el-form-item :id="genContainerId('is_employee', formElId)"
                          :label="$t('is_dt_employee')"
                          class="label-medium"
                          style="max-width: 245px;">
                <el-switch :id="genFieldId('is_employee' ,formElId)"
                          v-model="iValue.is_employee"/>
            </el-form-item>

           <!--<template v-if="iValue.is_employee">
            <div class="app-splitter two-cols">
                   <div class="col-1">
                   <el-form-item :id="genContainerId('higher_wage_option' ,formElId)" class="label-short">
                     <el-radio-group
                       :id="genFieldId('higher_wage_option',formElId)"
                       v-model="iValue.higher_wage_option"
                       tabindex="0"
                     >
                       <el-radio label="Employee">System should maximise(Employee)</el-radio>
                       <br/>
                       <el-radio label="Freelancer">Nice to make loyal (Freelancer)</el-radio>
                     </el-radio-group>
                   </el-form-item>
                       <template v-if="iValue.higher_wage_option=='Employee'">
                          <el-form-item :id="genContainerId('salary_per_hour',formElId)"
                                        label="Salary" size="mini" class="label-short">
                              <div class="input-group">
                                    <el-input
                                      style="width:40%;"
                                      v-model="iValue.salary_per_hour">
                                    </el-input>
                                    <span>kr per hour</span>

                               </div>
                          </el-form-item>
                        </template>
                        <el-form-item :id="genContainerId('amount_of_job',formElId)"
                                      label="Amount of hours" size="mini" class="label-short">
                            <div class="input-group">
                                  <el-input
                                    style="width:40%;"
                                    v-model="iValue.amount_of_job">
                                  </el-input>
                                  <span>per day</span>
                          </div>
                        </el-form-item>
                        <el-form-item :id="genContainerId('WorkingHours',formElId)"
                                      label="Time he/she able to take jobs" class="label-short">
                            <div class="input-group" v-for="(WorkingHour, k) in iValue.WorkingHours" :key="k">
                                   <el-time-picker :id="genFieldId('WorkingHours_time_from',formElId)"
                                            style="width:27%;"
                                            v-model="iValue.WorkingHours[k].time_from"
                                            format="HH:mm"
                                            value-format="HH:mm"
                                            size="mini"/>
                                    <span>-</span>
                                    <el-time-picker :id="genFieldId('WorkingHours_time_end',formElId)"
                                            style="width:27%;"
                                            v-model="iValue.WorkingHours[k].time_end"
                                            format="HH:mm"
                                            value-format="HH:mm"
                                            size="mini"/>
                                            <span class="fa fa-trash" @click="deleteRow(k)" v-show="k || ( !k && iValue.WorkingHours.length > 1)"></span>
                                            <span class="fa fa-plus" @click="addRow" v-show="k == iValue.WorkingHours.length-1">
                                            </span>

                            </div>
                        </el-form-item>

                        <el-form-item :id="genContainerId('is_available_weekend', formElId)"
                                      label="Available on weekends"
                                      class="label-medium"
                                      style="max-width: 245px;">
                            <el-switch :id="genContainerId('is_available_weekend', formElId)"
                                       v-model="iValue.is_available_weekend"/>
                        </el-form-item>

                          <template v-if="iValue.is_available_weekend && iValue.higher_wage_option=='Employee'">
                                <el-form-item :id="genContainerId('higher_wage', formElId)"
                                              label="Higher wage"
                                              class="label-medium"
                                              style="max-width: 245px;">
                                    <el-switch :id="genContainerId('higher_wage', formElId)"
                                               v-model="iValue.higher_wage"/>
                                </el-form-item>
                                <template v-if="iValue.higher_wage">
                                    <el-form-item :id="genContainerId('wage_on_weekend',formElId)"
                                                  label="Wage on weekend" size="mini" class="label-short">
                                        <div class="input-group">
                                              <el-input
                                                style="width:40%;"
                                                v-model="iValue.wage_on_weekend">
                                              </el-input>
                                              <span>hours</span>
                                         </div>
                                    </el-form-item>
                                </template>

                        </template>
                    </div>
                   <div class="available-weekend">

                       <el-form-item :id="genContainerId('lunch_switch', formElId)"
                                     label="Lunch settings"
                                     class="label-medium"
                                     style="max-width: 245px;">
                           <el-switch :id="genContainerId('lunch_switch', formElId)"
                                      v-model="iValue.lunch_switch"/>
                       </el-form-item>

                       <template v-if="iValue.lunch_switch">
                       <el-form-item :id="genContainerId('duration',formElId)"
                                     label="Lunch duration" size="mini" class="label-short">
                           <div class="input-group">
                                 <el-input
                                   style="width:40%;"
                                   v-model="iValue.duration">
                                 </el-input>
                                 <span>minutes</span>
                         </div>
                       </el-form-item>
                         <el-form-item :id="genContainerId('lunch_time_fixed_switch', formElId)"
                                       label="Fixed time"
                                       class="label-medium"
                                       style="max-width: 245px;">
                             <el-switch :id="genContainerId('lunch_time_fixed_switch', formElId)"
                                        v-model="iValue.lunch_time_fixed_switch" @change="iValue.lunch_time_range_switch=false"/>
                         </el-form-item>

                           <template v-if="iValue.lunch_time_fixed_switch">
                             <el-form-item :id="genContainerId('lunch_time',formElId)"
                                           label="Lunch time" class="label-short">
                                   <el-time-picker :id="genFieldId('lunch_time',formElId)"
                                     style="width:27%;"
                                     v-model="iValue.lunch_time"
                                     format="HH:mm"
                                     value-format="HH:mm"
                                     size="mini"/>
                             </el-form-item>
                           </template>
                           <el-form-item :id="genContainerId('lunch_time_range_switch', formElId)"
                                         label="Lunch range"
                                         class="label-medium"
                                         style="max-width: 245px;">
                               <el-switch :id="genContainerId('lunch_time_range_switch', formElId)"
                                          v-model="iValue.lunch_time_range_switch" @change="iValue.lunch_time_fixed_switch=false"/>
                           </el-form-item>
                           <template v-if="iValue.lunch_time_range_switch">
                               <el-form-item :id="genContainerId('lunch_time_from',formElId)"
                                             label="Lunch time from" class="label-short">
                                     <el-time-picker :id="genFieldId('lunch_time_from',formElId)"
                                       style="width:27%;"
                                       v-model="iValue.lunch_time_from"
                                       format="HH:mm"
                                       value-format="HH:mm"
                                       size="mini"/>
                               </el-form-item>
                               <el-form-item :id="genContainerId('lunch_time_to',formElId)"
                                             label="Lunch time to" class="label-short">
                                     <el-time-picker :id="genFieldId('lunch_time_to',formElId)"
                                       style="width:27%;"
                                       v-model="iValue.lunch_time_to"
                                       format="HH:mm"
                                       value-format="HH:mm"
                                       size="mini"/>
                               </el-form-item>
                           </template>

                       </template>
                </div>
            </div>
            </template>-->
          </div><!-- /.employement-section -->
    </div> <!-- /.app-fields user-questions-translator-fields -->
</template>

<script>
import {mapGetters, mapActions} from "vuex";
  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {

      // Parent form reference.
      formElId: {
        type: String,
        default: ''
      },

      value: {
        type: Object,
        required: true
      }

    }, // End of Component > props
    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        isEmployee: false,
        availableWeekend: false,
        higherWage: false,
        WorkingHours: [{
          time_from:'',
          time_end:''
        }],
      }
    }, // End of Component > data
    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () {
          return this.value;
        },
        set (v) {
          this.$emit('input', v);
        }
      },


    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      // Shakir's isNumber Method
      isNumber ($event) {
        let charCode = ($event.which) ? $event.which : $event.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 190) {
          this.iValue.invoice_tax = this.iValue.invoice_tax.toString().replace($event.key, '')
        } else {
          return true;
        }
      },
     handleInfoChange(value) {
      console.log(value);
      if(value == 0) {
           this.iValue.confidential_message = '';
         }
       },
    addRow() {
      this.iValue.WorkingHours.push({
        time_from: '',
        time_end: ''
      })
    },
    deleteRow(index) {
      this.iValue.WorkingHours.splice(index,1)
    }

    }

  } // End of export default
</script>
