/*
|--------------------------------------------------------------------------
| Financial > Price List > state
|--------------------------------------------------------------------------
|
| Partial file for state object related to the scope.
*/

export default {

  entitySelectorForm: {
    entity_type: '',
    entity_id: '',
  },

  list: {
    data: [],
    pagination: {},
    loading: false,
  },

  listImport: {
    data: [],
    pagination: {},
    loading: false,
  },

  item: {
    data: {},
    loading: false,
  },

  isComposeModeAdd: false,
  isComposeModeEdit: false,

  templateItem: {
    data: {},
    loading: false,
  },

  importItem: {
    data: {},
    loading: false,
  },
}
