<template>
    <div class="app-list booking-list">
        <booking-base-list
            :table-data="listData"
            :extra-cols="listCols"
            :hasDetailsCol="false"
            actions-col-width="55"
            actions-col-fixed="right"
            :row-class="tableRowClassName"
            :loading="loading"
            :isbooking="true"
        >
            <template slot="topControls">
                <div class="text-right">
                  <span class="text-right">{{$t('total_records')}} : {{ listPagination.total }}</span>
                </div>
            </template>
            <template slot="actionsColumn" slot-scope="scope">
                <div @contextmenu.prevent="handleDetailsNew(scope)">
                    <el-button
                        class="circle b"
                        type="success"
                        title="Details"
                        @click="handleDetails(scope)"
                        size="mini"
                        icon="el-icon-info"
                    >
                    </el-button>
                </div>
            </template>
        </booking-base-list>

        <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :page-size="listPagination.per_page"
            :page-sizes="[10, 10, 50, 100, 200]"
            :total="listPagination.total"
            :current-page="listPagination.current_page"
            @size-change="handleSizeChange"
            @current-change="reloadList"
        >
        </el-pagination>
    </div>
    <!-- /.app-list booking-list -->
</template>

<script>
  import {mapActions, mapGetters, mapMutations} from "vuex";
  import {populate} from "~/js/helpers/Vuex";
  import {isEmpty, sd} from "../../../js/helpers/Common";
  import includes from 'lodash/includes';
  import forEach from 'lodash/forEach';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      vPopulatePayload: {
        type: Object,
        default () {
          return {
            "filter[is_test]": "0",
            sort: "-created_at"
          };
        }
      }
    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      const _this = this;

      return {
        tableElId: "booking_list",

        perPage: 50,
        search: "",

        listCols: [
          {
            label: '',
            prop: "user_in_booking",
            showPopover: true,
            width: "20px",
            popoverClass: 'to-work-on-popover',
            mutator(v, row) {
              if(v.length > 0) {
                return '<span><i style="color: #65B4C6" class="fa fa-users"></i></span>';
              }
            },
            popover(v, row) {
               return _.uniqBy(v, function (e) {
                return e.user_id;
              }).map((unique)=> {
                return unique.user.name
               }).join('<br/>')
            }
          },
          {
            label: '',
            prop: "locked_booking",
            showPopover: true,
            width: "20px",
            popoverClass: 'to-work-on-popover',
            mutator(v, row) {

              if(v.length > 0 && Boolean(v[0].is_locked) === true) {
                return '<span><i style="color: #DE4848" class="fa fa-lock"></i></span>';
              }
            },
            popover(v, row) {
              return v.map((user) => {
                return user.user.name
              }).join('<br/>')
            }
          }
          ,{
            prop: "id",
            label: `${this.$t('job_id')}`,
            width: "",
            sortable: true,
            mutator (v, row) {
              let val = `${v}<br>`;
              let counts = ``;

              if (row.batches_by_local) {
                val += `<span class="yellow-dot"></span>`;
              }
              if (row.batches_by_prio) {
                val += `<span class="green-dot"></span>`;
              }
              if (row.translators_count < 10) {
                val += `<span class="red-dot"></span>`;
              }
              if (row.has_tickets) {
                val += `<span class="has-tickets-icon"><i class="fa fa-envelope"></i></span>`;
                // _this.checkTicketHasReplies($scope)
              }

              if(row.translators_count != undefined){
                counts += `S:${row.translators_sendable_count} | N:${row.translators_non_sendable_count}`
              }

              return (
                val + `${row.translators_count === undefined ? "Not created yet" : counts}`
              );
            }
          },
          {
            prop: "from_language_id",
            label: `${this.$t('language')}`,
            width: "",
            sortable: true,
            showPopover: true,
            mutator (v, row) {
              if (!isEmpty(_this.languageOpts) && !isEmpty(v)) {
                const found = _.find(_this.languageOpts, x => x.id === v);
                let alternative = "";
                if (!isEmpty(row.alternative_languages)) {
                  let values =
                    row.alternative_languages < 2
                      ? row.alternative_languages
                      : _.take(row.alternative_languages, 2);
                  alternative = values
                    .map(v => {
                      const f = _.find(
                        _this.languageOpts,
                        x => x.id === v.language_id
                      );
                      return f ? f.name : "";
                    })
                    .join(", ");
                }
                let other = !isEmpty(alternative) ? `<br> ${alternative}` : "";
                // let result = (found)? `${found.name}` :''
                return `${found ? found.name : ""} ${other} `;
              }
            },
            //popover content value
            popover (v, row) {
              //show popover if value more than 2
              if (
                !isEmpty(row.alternative_languages) &&
                row.alternative_languages.length > 2
              ) {
                return row.alternative_languages
                  .map(v => {
                    const f = _.find(
                      _this.languageOpts,
                      x => x.id === v.language_id
                    );
                    return f ? f.name : "";
                  })
                  .join(", ");
              }
            }
          },

          {prop: "duration", label: this.$t('dur'), width: "30px", sortable: true},
          {
            prop: "status",
            label: `${this.$t('status')}`,
            width: "",
            sortable: true,
            mutator (v) {
              return _this.$t(`tfv_status_${v.code}`);
            }
          },
          {
            prop: "is_immediate",
            label: `${this.$t('immediate')}`,
            width: "70px",
            sortable: true,
            mutator (v) {
              return v ? "yes" : "no";
            }
          },

          {
            prop: "gender", label: this.$t('gen'), width: "30px", sortable: true,
            /**
             * @param {string} v
             * @returns {null|string}
             */
            mutator (v) {
              if (v == null) return null;

              let result = [];
              const cn = v.split(',');

              if (includes(cn, 'male')) result.push('M');
              if (includes(cn, 'female')) result.push('F');

              return result.join(', ');
            }
          },
          // {prop: 'level', label: 'Level', width: ''},
          {
            prop: "type",
            label: `${this.$t('job_type')}`,
            width: "",
            sortable: true,
            mutator (v, row) {
              return row.fallback_type
                ? `${_this.$t(v)} <br> ${_this.$t('job_fallback_type_accept')} ${_this.$t(row.fallback_type)}`
                : _this.$t(v);
            }
          },
          // {prop: 'book_type', label: 'Bokn.', width: ''},
          {prop: "due", label: `${this.$t('tfv_due')}`, width: "80px", sortable: true},
          {prop: "created_at", label: `${this.$t('created')}`, width: "80px", sortable: true},
        ],

        hiddenCols: ["id"],

        row: null,
        tableRowClassName: [
          {
            field: "flagged",
            value: true,
            class: "is_flagged"
          },
          {
            field: "is_test",
            value: true,
            class: "is_test"
          },
          {
            field: "is_difficult",
            value: true,
            class: "is_difficult"
          },
          {
            field: "has_tickets",
            value: true,
            class: "has_tickets"
          },
          {
            field: "is_ticket_replied",
            value: true,
            class: "has_replies"
          },
          {
            field: "dt_expired",
            value: true,
            class: "dt_expired"
          },
        ]
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters("booking", {
        listData: "listData",
        listPagination: "listPagination",
        loading: "getLoading",
        customersOpts: "getCustomersList"
      }),

      ...mapGetters("language", {
        languageOpts: "getLanguages"
      }),

      populatePayload: {
        get () {
          return this.vPopulatePayload;
        },
        set (v) {
          this.$emit("input", v);
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions("booking", ["browse"]),

      ...mapActions("language", {
        loadLanguages: "fetchLanguages"
      }),

      ...mapActions("booking", {
        fetchCustomers: "fetchCustomers"
      }),
      ...mapMutations("booking", {
        appendListData: "appendListData"
      }),
      handleSizeChange (val) {
        this.reloadList(this.listPagination.current_page, val);
      },
      /**
       * Helper method to check wether the column is enabled in the options or not.
       *
       * @param {string} key
       *   The switch value to check for whether to enable/disable a column.
       *
       * @return {boolean}
       */
      isColEnabled (key) {
        let r = true;

        if (_.includes(this.hiddenCols, key)) {
          r = false;
        }

        return r;
      }, // End of isColEnabled() method

      /**
       * Method for reloading the list.
       *
       * @param {int} n
       *   The target page number.
       *
       * @param per_page
       * @return {void}
       */
      reloadList (n=1 , per_page=null) {
        let params = _.clone(this.$route.query);
        n = sd(n, sd(this.$route.query.page, 1)); // Set the page number.
        params.page = n;
        if (per_page && typeof per_page == "number") {
          params["per_page"] = per_page;
        }
        this.populatePayload.page = params.page;

        if (
          !isEmpty(params["filter[is_test]"]) &&
          params["filter[is_test]"] === 1
        ) {
          this.populatePayload["filter[is_test]"] = 1;
        }

        Object.assign(params, this.populatePayload);

        this.$router.push({query: params}); // Reflect the chosen parameters in the URL Bar
        populate(this, "browse", "", {
          force: true,
          params
        });
      }, // End of reloadList() method

      /**
       * method for handling details popups
       * @param {object} scope
       *
       * @return {void}
       */
      handleDetails (scope) {
        this.$router.push({
          name: "booking-details",
          params: {id: scope.row.id}
        });
      },

      handleDetailsNew (scope) {
        let route = this.$router.resolve({
          name: "booking-details",
          params: {id: scope.row.id}
        });
        window.open(route.href, "_blank");
      },

      /**
       * method for handling booking event
       * @param  {[object]} payload
       * @return {void}
       */
      handleBookingEvent (payload) {
        this.$store.dispatch("booking/updateBookingListDataStatusById", {
          id: payload.booking.id,
          status: payload.booking.status
        });
      },

      updateBookingListener (data) {
        this.reloadList();
      },

      checkTicketsIsReplied(booking,index){

        axios.get(`${app_dt_handesk_url}api/tickets?booking_id=${booking.id}`,
          {
            headers:{
              'token':app_dt_handesk_token
            }
          }).then(r => {
              let idx = this.listData.findIndex(b => b.id === booking.id);
              let table = document.getElementsByClassName('el-table__body');

              let tickets = r.data.data;
              if(!isEmpty(tickets)){
                tickets.forEach(i => {
                  (!isEmpty(i.comments) && booking.status_id !== 2)
                  ? table[0].rows[idx].classList.add('has_replies')
                  :'';

                })
              }
              // if (comments.length > 0){

              // }
          })
      }

    },

    /*
    |--------------------------------------------------------------------------
    | Component > watch
    |--------------------------------------------------------------------------
    */
    watch:{
      // listData(val){
      //   val
      //   .filter(i => i.has_tickets === true)
      //   .forEach((i,index) => {
      //     this.checkTicketsIsReplied(i,index)
      //   });
      // }
    },
    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      let params = _.clone(this.$route.query);
      Object.assign(params, this.populatePayload);
      const opts = {
        action: "browse",
        data: "listData",
        ref: "Booking Listing"
      };
      this.reloadList();

      // populate(this, "fetchCustomers", "customersOpts", {
      //   ref: "Languages Options"
      // });
      populate(this, "loadLanguages", "languageOpts", {
        ref: "Languages Options"
      });
      this.$store.dispatch("dashboard/loadCountToExpire");
      this.$store.dispatch("dashboard/loadCountPending");

      if (window.WS) {
        forEach([
          'booking.cancelled',
          'booking.ended',
          'booking.expired',
          'booking.expiring',
          'booking.started'
        ], (channel) => {
          window.WS.subscribe(channel)
                .listen(channel, this.handleBookingEvent);
        });

        window.WS.channel('booking.accepted')
              .listen('booking.accepted', this.handleBookingEvent);
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > beforeDestroy
    |--------------------------------------------------------------------------
    */
    beforeDestroy () {
      // leave or stop listening to the channel booking
      if (window.WS) {
        window.WS.unsubscribe([
          'booking.cancelled',
          'booking.accepted',
          'booking.ended',
          'booking.expired',
          'booking.expiring',
          'booking.started'
        ]);

        window.WS.channel('booking.accepted')
              .unlisten('booking.accepted', this.handleBookingEvent);
      }
    }

  }; // End of export default
</script>

<style>
    .red-dot {
        background-color: #f56c6c !important;
        height: 10px;
        width: 10px;
        color: #f56c6c;
        margin: auto;
        border-radius: 50%;
        display: inline-block;
    }

    .green-dot {
        background-color: #67c23a !important;
        height: 10px;
        width: 10px;
        color: #67c23a;
        margin: auto;
        border-radius: 50%;
        display: inline-block;
    }

    .yellow-dot {
        background-color: #e6a23c !important;
        height: 10px;
        width: 10px;
        color: #e6a23c;
        margin: auto;
        border-radius: 50%;
        display: inline-block;
    }
</style>
