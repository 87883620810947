/*
|--------------------------------------------------------------------------
| Store > Sector > Municipality
|--------------------------------------------------------------------------
|
| A file that manages all the properties and abilities in relation
| to Municipality Model and other related values.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {extendObj, extendObjParams, mapEndpoint} from "../../../helpers/Common";
import APICaller from '~/js/helpers/APICaller';
import bread from '../../bread';
import state from './state';
import mutations from './mutations';
import i18n from '~/js/i18n';
import {Notification} from "element-ui";

let getters = {
    getIsBooking: (state) => !state.is_show_on_booking,
};
let actions = {
    browseWithBooking (context, payload = {}) {

        context.commit('setListLoading', true);

        let method = context.state.api.browseWithBooking.method;
        let endpoint = mapEndpoint(context.state.api.browseWithBooking.path, payload);
        let params = extendObjParams(context.state.api.browseWithBooking.params, payload);

        return new Promise((resolve, reject) => {
            APICaller({method, endpoint, params})
                .then((r) => {
                context.commit('setList', r.data);
                resolve(r);
            })
            .catch((e) => {
                reject(e);
            })
            .finally(() => {
                context.commit('setListLoading', false);
            });
        });
    },
    browseResourcesByBooking (context, payload = {}) {

      context.commit('setListLoading', true);

      let method = context.state.api.browseResourcesByBooking.method;
      let endpoint = mapEndpoint(context.state.api.browseResourcesByBooking.path, payload);
      let params = extendObjParams(context.state.api.browseResourcesByBooking.params, payload);

      return new Promise((resolve, reject) => {
          APICaller({method, endpoint, params})
              .then((r) => {
              context.commit('setList', r.data);
              resolve(r);
          })
          .catch((e) => {
              reject(e);
          })
          .finally(() => {
              context.commit('setListLoading', false);
          });
      });
  },
    addWithBooking (context, payload) {

        let method = context.state.api.addWithBooking.method;
        let endpoint = mapEndpoint(context.state.api.addWithBooking.path, payload);
        let data = {};
        let isDataRaw = true;

        data = payload.data;

        return new Promise((resolve, reject) => {
            APICaller({method, endpoint, data, isDataRaw})
                .then((r) => {
                resolve(r);
            })
            .catch((e) => {
              reject(e);
            });
        });
    },
    editResource (context, payload) {

        let method = context.state.api.edit.method;
        let endpoint = mapEndpoint(context.state.api.edit.path, payload);
        let data = {};
        let isDataRaw = true;

        // Set the value for payload.data
        if (context.state.autoInjectPayload) {
          context.commit('setPayloadData');
          data = context.state.payloadData;
        } else {
          data = payload.data;
        }

        data.types = JSON.stringify(data.types);

        return new Promise((resolve, reject) => {
          APICaller({method, endpoint, data, isDataRaw})
            .then((r) => {

              if (context.state.autoUpdateList) {
                context.dispatch('browse');
                context.dispatch('browseAsOptions');
              }

              Notification.success({
                title: i18n.t('success'),
                message: i18n.t('n_entry_edited')
              });

              resolve(r);
            })
            .catch((e) => {

              Notification.error({
                title: i18n.t('error'),
                message: i18n.t('n_went_wrong')
              });

              reject(e);
            });
        });
    },
    addResource (context, payload) {

        let method = context.state.api.add.method;
        let endpoint = context.state.api.add.path;
        let data = {};
        let isDataRaw = true;

        // Set the value for payload.data
        if (context.state.autoInjectPayload) {
          context.commit('setPayloadData');
          data = context.state.payloadData;
        } else {
          data = payload.data;
        }

        data.types = JSON.stringify(data.types);

        return new Promise((resolve, reject) => {
          APICaller({method, endpoint, data, isDataRaw})
            .then((r) => {

              if (context.state.autoUpdateList) {
                context.dispatch('browse');
                context.dispatch('browseAsOptions');
              }

              Notification.success({
                title: i18n.t('success'),
                message: i18n.t('n_entry_added')
              });

              resolve(r);
            })
            .catch((e) => {

              Notification.error({
                title: i18n.t('error'),
                message: i18n.t('n_went_wrong')
              });

              reject(e);
            });
        });
    },
    readResource(context, payload = {}) {

        context.commit('setItemLoading', true);

        return new Promise((resolve, reject) => {
            context.dispatch('readGeneric', payload)
                .then((r) => {
                    let raw = r.data.data; // Store into container so varname will be shorter.
                    let data = raw[Object.keys(raw)[0]]; // Get the first member of the object.

                    let types = [];
                    try {
                        types = JSON.parse(data.types);
                    } catch (e) {
                        types.push(data.types);
                    } finally {
                        data.types = types;
                    }

                    context.commit('setItem', {
                        data
                    });

                    if (context.state.autoReflectItem) {
                        context.commit('setCompose', data);
                    }
                    resolve(r);
                })
                .catch((e) => {
                    reject(e);
                })
                .finally(() => {
                    context.commit('setItemLoading', false);
                });
        });
    },
    unassignResource(context, payload = {}) {
      let method = 'put';
      let endpoint = mapEndpoint(context.state.api.addWithBooking.path, payload);
      let data = {};
      let isDataRaw = true;

      data = payload.data;

      return new Promise((resolve, reject) => {
          APICaller({method, endpoint, data, isDataRaw})
              .then((r) => {
              resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    deleteAssignment (context, payload) {

      let method = context.state.api.deleteAssignment.method;
      let endpoint = mapEndpoint(context.state.api.deleteAssignment.path, payload);
      let params = extendObjParams(context.state.api.deleteAssignment.params, payload);

      return new Promise((resolve, reject) => {
        APICaller({method, endpoint, params})
          .then((r) => {
            Notification.success({
              title: 'Success',
              message: 'Item removed.'
            });
            resolve(r);
          })
          .catch((e) => {
            Notification.error({
              title: i18n.t('error'),
              message: i18n.t('n_went_wrong')
            });
            reject(e);
          });
      });
    },
};

export default {
    namespaced: true,
    state: extendObj(bread.state, state),
    getters: extendObj(bread.getters, getters),
    mutations: extendObj(bread.mutations, mutations),
    actions: extendObj(bread.actions, actions),
} // End of export default
