/*
|--------------------------------------------------------------------------
| Store > Messaging-Centre > Blogs > Getters
|--------------------------------------------------------------------------
|
| This file contains the getters property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/


export default {
    getBlogs: (state) => state.blogs,
  }