<template>
    <div class="app-page low-feedback-Detail-page">
      <page-header title="Feedback Details" />

      <div class="app-container">
        <div class="app-wrapper">
          <el-row :gutter="20">
            <ElCol :span="16">
              <el-card class="box-card green">
                <div slot="header" class="clearfix">
                  <span>Customer Feedback</span>
                </div>
                <div class="text item">
                  <template v-if="!isEmpty(customerLowFeedback)">
                    <el-row :gutter="10">
                    <ElCol :md="4">
                      <div class="grid-content bg-purple">
                        <h1>{{customerLowFeedback.rating}}</h1>
                      </div>
                      <!-- <el-badge :value="lowFeedback.rating"></el-badge> -->
                    </ElCol>
                    <ElCol :md="20">
                      <div class="grid-details bg-purple">
                        <p>{{customerLowFeedback.comment}}</p>
                        <hr>
                        {{customerLowFeedback.issues.length}} comments
                      </div>
                      <div class="grid-details bg-purple-light" v-if="customerLowFeedback.issues.length > 0">
                        <h4>Last Activities</h4>
                        <!-- <div class="item text" v-for="item in lowFeedback.issues" :key="item.id">
                                    {{item.comment}}
                                  </div> -->
                        <el-row :gutter="10" v-for="(item,i) in customerLowFeedback.issues" :key="i">
                          <ElCol :md="2"><i class="fa fa-user-circle fa-3x"></i></ElCol>
                          <ElCol :md="20">
                            <el-row>
                              <el-col :md="16">{{item.user.name}}</el-col>
                              <el-col :md="8">{{item.created_at}}</el-col>
                            </el-row>
                            
                            <br>
                            <small> {{item.user.email}}</small>

                            <p v-html="item.comment"></p>

                          </ElCol>
                        </el-row>

                      </div>
                      <div class="grid-details bg-purple-light">
                        <el-button type="text" @click="showAddComment = !showAddComment">{{ $t('add_comment') }}</el-button>
                        <template v-if="showAddComment">
                          <!-- <el-input v-model="form.comment" placeholder="" type="textarea">
                          </el-input> -->
                          <div style="background-color:#fff">
                            <wysiwyg v-model="form.comment" :aria-placeholder="$t('enter_text')" :placeholder="$t('enter_text')"/>
                          </div>
                          <div class="text-right">
                            <el-button class="circle" @click="addComment"><i class="fa fa-paper-plane"></i></el-button>
                          </div>

                        </template>
                      </div>

                    </ElCol>
                  </el-row>
                  </template>
                  
                </div>
              </el-card>
 
            </ElCol>
            <ElCol :span="8">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>Details</span>
                </div>
                <template v-if="!isEmpty(booking)">
                  <ul>
                    <li>Booking Id<br>
                    <router-link :to="bookingUrl">
                      <span class="text-success">{{booking.id}}</span>
                    </router-link>
                    </li>
                    <li>
                      Customer Name<br>
                      <router-link :to="customerProfileUrl">
                      <span class="text-success">{{booking.customer.name}}</span>
                      </router-link>
                    </li>
                    <li>
                      Translator Name<br>
                      <router-link :to="translatorProfileUrl">
                        <span class="text-success">
                        {{booking.assigned_translator.name}}
                      </span>
                      </router-link>
                      
                    </li>
                    <li>
                      Date Created<br>
                      <span class="text-success">
                        {{booking.created_at}}
                      </span>
                    </li>
                  </ul>
                  <hr>
                  <template v-if="!isEmpty(translatorLowFeedback)">
                  <h4>Translator Feedback</h4>
                  <el-rate disabled v-model="translatorLowFeedback.rating"></el-rate>
                  <p>
                    {{translatorLowFeedback.comment}}
                  </p>
                  </template>
                  <template v-else>
                  <span class="el-alert el-alert--warning">No Feedback From Translator Yet</span>
                  </template>
                  <hr>
                  <template v-if="!isEmpty(customerLowFeedback)">
                  <h4>Customer Feedback</h4>
                  <el-rate disabled v-model="customerLowFeedback.rating"></el-rate>
                  <p>
                    {{customerLowFeedback.comment}}
                  </p>
                  </template>
                  <template v-else>
                  <span class="el-alert el-alert--warning">No Feedback From Customer Yet</span>
                  </template>
                  <hr>
                  <h4>Actions</h4>
                  <template v-if="customerLowFeedback.solved_issue === false">
                    
                  <el-button type="success" @click="handleClickSolved"><i class="fa fa-check"></i>Solved</el-button>
                  </template>
                  <el-button type="primary" @click="showModal = !showModal"><i class="fa fa-envelope"></i>Send report to
                    customer</el-button>
                  <el-button type="warning" @click="handleShowPenaltyModal"><i class="fa fa-warning"></i>Penalty translator</el-button>
                  <el-button type="danger" @click="handleBlacklistTranslator"> <i class="fa fa-minus"></i>Blacklist
                    translator</el-button>
                </template>
              </el-card>
            </ElCol>
          </el-row>
        </div> <!-- /.app-wrapper -->
      </div> <!-- /.app-container -->
      <template>

      </template>
      <template v-if="!isEmpty(customerLowFeedback)">
        
      <low-feedback-details-modal :visible.sync="showModal" :row="booking" :feedback="customerLowFeedback" @updateIssues="updateIssuesList"/>
      </template>
      <penalty-modal :visible.sync="showPenaltyModal" :booking="booking" :feedback="customerLowFeedback" @updateIssues="updateIssuesList"/>
    </div> <!-- /.app-page translation-manager-page -->
</template>

<script>
import{mapGetters,mapActions,mapState} from 'vuex'

import { isEmpty } from '../../../js/helpers/Common';
export default {
  name:'lowFeedbackDetails',
  data(){
    return {
      form:{
        commnent:''
      },
      showAddComment:false,
      showModal:false,
      showPenaltyModal:false
    }
  },
  computed:{
    customerProfileUrl(){
        return `/user/profile/${this.booking.customer.id}?type=2`
    },
    translatorProfileUrl(){
      return `/user/profile/${this.booking.assigned_translator.translator_id}?type=3`
    },
    bookingUrl(){
      return `/booking/${this.booking.id}/details`
    },
    ...mapGetters({
        booking: 'booking/itemData',
    }),
    ...mapState('auth',{
      user:'userData'
    }),
    customerLowFeedback(){
      if(!isEmpty(this.booking.feedback)){
        let feedbacks = this.booking.feedback.filter(o => o.review_from_type ==2)
        let feedback = _.sortBy(feedbacks, 'rating')
        return feedback.find(o => o.rating < 3)
      }
      return {
        id: null,
       review_from_id: null,
       review_from_type: null,
       review_to_id: null,
       review_to_type: null,
       booking_id: null,
       rating: null,
       issue_solved:false,
       comment: '', created_at: '', updated_at: '',
       issues:[]
       }
    },
    translatorLowFeedback(){
      if(!isEmpty(this.booking.feedback)){
        let feedbacks = this.booking.feedback.find(o => o.review_from_type == 3)
        // let feedback = _.sortBy(feedbacks, 'rating')
        return feedbacks
      }
      return {
        id: null,
       review_from_id: null,
       review_from_type: null,
       review_to_id: null,
       review_to_type: null,
       booking_id: null,
       issue_solved:false,
       rating: 0,
       comment: '', created_at: '', updated_at: '',
       issues:[]
       }
    }
  },
  methods:{
    ...mapActions({
      getBooking:'booking/read',
      storeFeedbackIssue: 'feedback/storeFeedbackIssue',
      storeBlacklist:'feedback/storeBlacklist'
    }),
    isEmpty(v){
      return isEmpty(v)
    },
    addComment(){
      let form = {
        comment: this.form.comment,
        created_by: this.user.id,
        user: this.user,
        feedback_id:this.customerLowFeedback.id
      }
      this.storeFeedbackIssue(form).then(r => {
        if(r.status == 201){
          this.customerLowFeedback.issues.push(form)
          this.form ={
            comment:''
          }
        }
      })
      
    },
    handleBlacklistTranslator(){
      let data = {
        'user_id': this.booking.customer_id,
        'translator_id': this.booking.assigned_translator.translator_id,
      }
      this.storeBlacklist(data)
    },
    handleShowPenaltyModal(){
      this.showPenaltyModal = true
    },
    handleClickSolved(){
      let form = {
        comment: `set as solved by ${this.user.name}`,
        created_by: this.user.id,
        user: this.user,
        feedback_id:this.customerLowFeedback.id,
        flag_id:5
      }
      this.storeFeedbackIssue(form).then(r => {
        if(r.status == 201){
          this.customerLowFeedback.issues.push(form)
          this.$router.push({name:'feedback.low'})
        }
      })
    },
    updateIssuesList(e){
      this.customerLowFeedback.issues.push(e)
    }
  },
  mounted(){
    let payload={
      id: this.$route.params.id,
      extendParams:{include:'customer,feedback,assigned_translator,feedback.issues,feedback.issues.user'}
    }
    this.getBooking(payload)

  },
}
</script>

<style lang="scss" scoped>
.green{
  &.el-card__header{
    background-color: #222 !important;
    color: #ffff !important;
  }
}
.el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    padding: 10px;
    text-align: center;
  }
  .grid-details{
    border-radius: 4px;
    min-height: 36px;
    padding: 10px;
  }
</style>
