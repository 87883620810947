<template>
   <div :class="`app-row ${iRefs}`" :id="iRefs" >
     <div class="app-col full-width">
    <h3>{{iTitle}}</h3>

    <div class="app-list booking-list">
        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false"
                actions-col-width='130'
                :row-class="tableRowClassName"
                >
            <template slot="topControls">
              <div class="text-right">
                <!-- <span class="text-right">Total Records : {{listPagination.total}}</span> -->
              </div>
            </template>
            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="success" title="Details" @click="handleDetails(scope)" size="mini" icon="el-icon-info">
                </el-button>

            </template>
        </base-list-table>

        <el-pagination
                layout="prev, pager, next"
                :page-size="perPage"
                :total="iBookings.length"
                :current-page="pageNumber"
                @current-change="reloadList">
        </el-pagination>

    </div> <!-- /.app-list booking-list -->
    </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {isEmpty,sd} from "~/js/helpers/Common";
export default {
  name:'userBookingListPanel',
  props:{
    bookings:{
      type: Array,
      default:[]
    },
    title:{
      type:String,
      default: 'Future Bookings'
    },
    refs:{
      default:'future'
    }
  },
  data(){

    const _this = this;
    return {
      listCols: [{
          prop: 'id',
          label: _this.$t('job_id'),
          width: '',
          sortable: true
        },
        {
          prop: 'from_language_id',
          label: _this.$t('language'),
          width: '',
          sortable: true,
          showPopover: true,
          mutator(v, row) {
            if (!isEmpty(_this.languageOpts) && !isEmpty(v)) {
              const found = _.find(_this.languageOpts, (x) => x.id == v)
              let alternative = ''
              if (!isEmpty(row.alternative_languages)) {

                let values = (row.alternative_languages < 2) ? row.alternative_languages : _.take(row.alternative_languages, 2)
                alternative = values.map(v => {
                  const f = _.find(_this.languageOpts, (x) => x.id == v.language_id)
                  return f ? f.name : '';
                }).join(', ')
              }
              let other = (!isEmpty(alternative)) ? `<br> ${alternative}` : ''
              // let result = (found)? `${found.name}` :''
              let result = `${(found? found.name:'')} ${other} `
              return result;
            }
          },
          //popover content value
          popover(v, row) {
            //show popover if value more than 2
            if (!isEmpty(row.alternative_languages) && row.alternative_languages.length > 2) {
              let alternative = row.alternative_languages.map(v => {
                const f = _.find(_this.languageOpts, (x) => x.id == v.language_id)
                return f ? f.name : '';
              }).join(', ')
              return alternative
            }

          }

        },

        {
          prop: 'duration',
          label: _this.$t('duration'),
          width: '60px',
          sortable: true,
        },
        {
          prop: 'status',
          label: _this.$t('status'),
          width: '',
          sortable: true,
          mutator(v) {
            return v.name
          }

        },
        {
          prop: 'is_immediate',
          label: _this.$t('immediate'),
          width: '70px',
          sortable: true,
          mutator(v) {
            return (v) ? 'yes' : 'no';
          }
        },

        {
          prop: 'gender',
          label: _this.$t('gender'),
          width: '',
          sortable: true
        },
        {
          prop: 'type',
          label: _this.$t('job_type'),
          width: '',
          sortable: true,
          mutator(v, row) {
            return (row.fallback_type) ? `${v} <br> accept ${row.fallback_type}` : v
          }
        },
        {
          prop: 'created_at',
          label: _this.$t('created_at'),
          width: '80px',
          sortable: true
        },
        {
          prop: 'due',
          label: _this.$t('due'),
          width: '80px',
          sortable: true
        },
      ],
      tableRowClassName:[
        {
            field: 'flagged',
            value: true,
            class: 'is_flagged'
        },
        {
            field: 'is_test',
            value: true,
            class: 'is_test'
        }
      ],
      pageNumber: 1,
      perPage:10,
      listData:[]
    }
  },
  computed:{
    iBookings:{
      get(){
        return this.bookings
      },
      set(v){
        this.$emit('input',v)
      }
    },
    iTitle(){
      return this.title
    },
    iRefs(){
      return this.refs
    },
    ...mapGetters('language', {
        languageOpts: 'getLanguages'
    })
  },
  methods:{
    handleDetails(scope) {

      this.$router.push({
        name: 'booking-details',
        params: {
          id: scope.row.id
        }
      });

    },
    reloadList(page){
      --page;
      let data=this.iBookings;
      this.listData=data.slice(page *this.perPage, (page + 1) * this.perPage);
    },
  },
  watch: {
      iBookings: function (value) {
        this.reloadList(1);
      }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {

  },
}
</script>

<style>

</style>
