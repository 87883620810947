/*
|--------------------------------------------------------------------------
| Store > Town > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Town Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {gep} from "../../helpers/Vuex";

let target = 'adminPermissions';
let apiPrefix = window.app_api_prefix_3;
let asOptionsParams = {
  all:true
};

let composeProps = {
  id: '',
  page_name: '',
  role_id: '',
  can_view: false,
  can_create: false,
  can_update: false,
  paths: [],
  admins: [],
  sync: {}
};

export default {

  model_info: {
    name: 'Admin Permissions Store',
    module: 'adminPermissions',
    table: target,
  },

  api: {
    browse: { method: 'get', path: gep('admin/{id}/permissions', apiPrefix), params: { per_page: 10, include: 'admins' }},
    browseAsOptions: { method: 'get', path: gep('admin/:id/permissions', apiPrefix), params: asOptionsParams},
    read: { method: 'get', path: gep('admin/{admin_id}/permissions' + '/{id}', apiPrefix), params: {
        include: 'paths,admins'
      }},
    edit: { method: 'put', path: gep('admin/permissions' + '/{id}', apiPrefix), params: {}, data: {}, },
    add: { method: 'post', path: gep('admin/{admin_id}/permissions', apiPrefix), params: {}, data: {}, },
    delete: { method: 'delete', path: gep('admin/permissions' + '/{id}', apiPrefix), params: {}, data: {}, },
    deletePaths: { method: 'post', path: gep('admin/permissions/{id}/remove-paths', apiPrefix), params: {}, data: {}, },
    batchAdd: { method: 'post', path: gep('admin/permissions/batch-store', apiPrefix), params: {}, data: {}, },
  },

  composeFresh: _.cloneDeep(composeProps),
  compose: _.cloneDeep(composeProps),

  payloadData: {}, // End of payloadData state

  autoInjectPayload: true,
  autoReflectItem: true,
  autoUpdateList: true,

  navData: {
    navItems: [
      {path: '/dashboard', label: 'dashboard', icon: 'multiline_chart'},
      // {
      //   path: '/batch/settings/template', label: 'Batch Templates', icon: 'fa fa-magic',
      // },
      //{path: '/quick-stats', label: 'quickstats', icon: 'assessment'},
      // {path: '/rocketchat', label: 'Manuella åtgärder', icon: 'fa fa-comments'},
      {
        path: '/user', label: 'user', icon: 'face',
        subItems: [
          {path: '/user/add/customer', label: 'create_customer', icon: 'person_add'},
          {path: '/user/add/translator', label: 'create_translator', icon: 'group_add'},
          {path: '/user/list', label: 'user_list', icon: 'people_alt'},
          //{path: '/user/pending', label: 'pending_users', icon: 'people_outline'},
          //{path: '/user/files', label: 'files', icon: 'folder'},
        ],
      },
      {
        path: '/admin-user/list', label: 'admin_user', icon: 'local_library',
        // subItems: [
        //   {path: '/admin-user/list', label: 'Admin User List'},
        //   {path: '/admin-user/add', label: 'Create Admin User'},
        // ],
      },
      {
        path: '/admin-permissions', label: 'admin_roles', icon: 'person_pin',
        subItems: [
          {path: '/admin-permissions/roles', label: 'admin_role_groups', icon:'supervised_user_circle'}
        ],
      },
      // {
      //   path: '/aes', label: 'AES Mappings', icon: 'fa fa-users',
      //   subItems: [
      //     {path: '/aes/mappings/municipality', label: 'Municipality Mappings'},
      //     {path: '/aes/mappings/company', label: 'Company Mappings'},
      //     {path: '/aes/mappings/department', label: 'Department Mappings'},
      //     {path: '/aes/mappings/user', label: 'User Mappings'},
      //     {path: '/aes/mappings/booking', label: 'Booking Mappings'},
      //   ],
      // },
      // {path: '/locale', label: 'Translation Manager', icon: 'fa fa-globe'},
      { path: "/booking-issues", label: "actions_manager", icon: "dns",
        subItems: [{
          path: "/booking-issues?filter[open]=true",
          label: "open_issue",
          icon: "crop_3_2"
        },
          {
            path: "/booking-issues-solved",
            label: "solved_issue",
            icon: "done_outline"
          },
          {
            path: "/booking-issues-pending",
            label: "pending_issue",
            icon: "pause_circle_outline"
          },
          {
            path: "/booking-issue-settings",
            label: "settings",
            icon: "perm_data_setting"
          },
          {
            path: "/booking-issue-tags",
            label: "tags",
            icon: "style"
          }
        ]
      },
      {
        path: '/messaging-centre', label: 'messaging_centre', icon: 'all_inbox',
        subItems: [
          {path: '/messaging-centre/blogs', label: 'blogs', icon: 'note_add'},
          /*{path: '/messaging-centre/notification', label: 'notifications', icon: 'notifications'},
          {path: '/messaging-centre/notification/add', label: 'create_notification', icon: 'notifications_active'},*/
        ],
      },

      /*{
        path: '/sms-centre', label: 'sms_help_desk', icon: 'question_answer'
      },*/
      // {path: '/feedback/low', label: 'feedback_manager', icon: 'fa fa-globe',
      // subItems:[
      //   {path: '/feedback/low', label: 'open_issue', icon: 'fa fa-globe'},
      //   {path: '/feedback-low/solved', label: 'solved_issue', icon: 'fa fa-globe'}
      // ]
      // },
      {path: '/survey-manager', label: 'survey_manager', icon: 'chrome_reader_mode'},

      //removed due to DIG-2028
      // {
      //     path: '/messaging-centre', label: 'Messaging Centre', icon: 'fa fa-comments',
      //     subItems: [
      //         {path: '/messaging-centre/customer', label: 'For Customer'},
      //         {path: '/messaging-centre/translator', label: 'For Translator'},
      //     ],
      // },

      // {path: '/languages', label: 'Languages', icon: 'fa fa-language'},
      // {path: '/dialects', label: 'dialects', icon: 'fa fa-language'},
      {path: '/bookings', label: 'bookings', icon: 'description'},
      {path: '/text-translations', label: 'translations', icon: 'perm_camera_mic',
        subItems: [
          {
            path: '/text-translations',
            label: 'all_booking'
          },
          {
            path: '/text-translations/pending',
            label: 'pending'
          },
          {
            path: '/text-translations/on-going',
            label: 'on-going'
          },
          {
            path: '/text-translations/completed',
            label: 'completed'
          },
        ]},
      // {
      //   path: '/bookings', label: 'Bookings', icon: 'fa fa-book',
      //   subItems: [
      //     {path: '/bookings', label: 'Normal Bookings'},
      //     // { path: '/text-bookings', label: 'Text Bookings'}  //removed due to DIG-2026
      //   ]
      // },
      //removed due to DIG-2026
      // {
      //     path: '/text-translations', label: 'Text Translation', icon: 'fa fa-book',
      //     subItems: [
      //         {path: '/text-translation/fixed-price', label: 'Fixed Price', icon: 'fa fa-language'},
      //         {
      //             path: '/text-translation/non-fixed-price',
      //             label: 'Non Fixed Price',
      //             icon: 'fa fa-language'
      //         },
      //     ]
      // },
      {
        path: '/bookings/about-to-expire',
        label: 'about_expire',
        icon: 'notification_important',
        badge: 'to_expire'
      },
      /*{
        path: "/bookings/pending-for-future",
        label: "pending_for_future",
        icon: "class",
        badge: "pending"
      },*/

      {
        path: "/bookings/to-work-on",
        label: "to_work_on",
        icon: "assignment",
        badge: "to_work_on"
      },
      {
        path: "/bookings/is-difficult",
        label: "is_difficult",
        icon: "report",
        badge: "is_difficult",
      },

      {
        path: "/bookings/planned",
        label: "bookings_planned",
        icon: "assignment_turned_in",
        badge: "planned"
      },

      {
        path: "/bookings/to-be-planned",
        label: "bookings_to_be_planned",
        icon: "assignment_returned",
        badge: "to_be_planned"
      },

      {
        path: "/bookings/cannot-be-planned",
        label: "bookings_cannot_be_planned",
        icon: "assignment_late",
        badge: "cannot_be_planned"
      },

      //{path: '/faq-page', label: 'faqs', icon: 'speaker_notes'},
      /*{
        path: '/logs', label: 'logs_and_alerts', icon: 'storage',
        subItems: [
          {path: '/logs/admin', label: 'admin_logs', icon: 'line_weight'},
          {path: '/logs/cron', label: 'cron_logs', icon: 'line_weight'},
          {path: '/logs/email', label: 'email_logs', icon: 'line_weight'},
          {path: '/logs/fax', label: 'fax_logs', icon: 'line_weight'},
          {path: '/logs/sms', label: 'sms_logs', icon: 'line_weight'},
          {path: '/logs/push', label: 'push_logs', icon: 'line_weight'},
          // {path: '/logs/long-sessions', label: 'Long Sessions'},
          // {path: '/logs/failed-logins', label: 'Failed Logins'},
          // {path: '/logs/low-feedback', label: 'Low Feedback'},
          // {path: '/logs/bookings/physical-phone', label: 'Physical/Phone'},
          // {path: '/logs/bookings/physical', label: 'Physical'},
          // {path: '/logs/bookings/flagged', label: 'Flagged'},
          // {path: '/logs/no-salary', label: 'No Salary'},
          {path: '/logs/user-access-logs', label: 'user_access_logs', icon: 'line_weight'},
        ],
      },*/

      {
        path: '/financial', label: 'finance', icon: 'attach_money',
        subItems: [
          {path: '/financial/settings/list', label: 'financial_settings', icon: 'post_add'},
          {path: '/financial/price-template-list', label: 'price_templates', icon: 'developer_board'},
          {path: '/financial/export', label: 'financial_export', icon: 'receipt'},
          //{path: '/financial/invoice-list', label: 'invoice_list', icon: 'library_books'},
        ],
      },

      {
        path: '/sector', label: 'sector', icon: 'map',
        subItems: [
          {path: '/sector/municipality', label: 'municipality', icon: 'museum'},
          {path: '/sector/company', label: 'company', icon: 'store_mall_directory'},
          {path: '/sector/department', label: 'department', icon: 'business'},
        ],
      },

      // {path: '/contacts', label: 'Contacts', icon: 'fa fa-address-card'},
      // {path: '/handesk', label: 'Mailkommunikation', icon: 'fa fa-comments'},
      // {path: '/tawk-to', label: 'Chatsupport', icon: 'fa fa-comments'},
      {path: '/messages/sms', label: 'send_sms', icon: 'textsms'},
      // {path: '/messages/fax', label: 'Send Fax', icon: 'fa fa-fax'},
      //{path: '/court-order', label: 'court_order', icon: 'account_balance'},

      {path: '/settings', label: 'settings', icon: 'settings'},
      //{path: '/statistics', label: 'statistics', icon: 'assessment'},
      /* {
        path: '/employees', label: 'employees', icon: 'work',
      }, */
    ],

    navItemsFinal: [],
  }

} // End of export default
