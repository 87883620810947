import APICaller from "~/js/helpers/APICaller";

import { gep } from "~/js/helpers/Vuex";
import { Notification } from "element-ui";
import i18n from "../../../i18n";
import API from '~/js/constants/api';

let endpoint = API.SETTINGS;
export default {
    /**
     * Get KPI Settings from Setting Table
     * @param {*} context
     * @param {*} payload
     */
    getTestLanguages(context, payload) {
        let params = {
            "filter[name]": 'test_languages'
        }
        return APICaller({
            method: 'get',
            params,
            endpoint: endpoint
        }).then(resp => {
            if (resp.data.data.settings.length > 0) {
                context.commit('setCompose', resp.data.data)
            }
            else {
                context.commit('resetCompose')
            }
            return resp;

        }).catch(err => {
            console.log(err)
        })
    },
    /**
     * Handle POST KPI Settings
     * @param {*} context
     * @param {*} payload
     */
    postTestLanguages(context, payload) {
        const apiOpts = {
            method: "POST",
            endpoint: endpoint,
            data: payload,
            isDataRaw: true
        };

        return APICaller(apiOpts)
            .then(() => {
                Notification.success({
                    title: i18n.t("sucesss"),
                    message: "Test Language Created"
                });
            })
            .catch(e => {
                Notification.error({
                    title: i18n.t("error"),
                    message: e.response.data.data
                });
            })
    },

    updateTestLanguages(context, payload) {
       const endpoint_with_id = `${API.SETTINGS}/${payload.id}`;
        const apiOpts = {
            method: "PUT",
            endpoint: endpoint_with_id,
            data: payload,
            isDataRaw: true
        };

        return APICaller(apiOpts)
            .then(() => {
                Notification.success({
                    title: i18n.t("sucesss"),
                    message: "Test Languages list update"
                });
            })
            .catch(e => {
                Notification.error({
                    title: i18n.t("error"),
                    message: e.response.data.data
                });
            })
    }
}
