<template>
    <div class="app-page user-profile-page">
        <page-header :title="$t('user_profile')" :back-enabled="true"/>


        <div class="app-container">
            <div class="app-wrapper">

                <user-statistics-panel :user="user"></user-statistics-panel>

        <user-info-panel :user="user" @clicked="fileUpdated"></user-info-panel>
        <user-projects-type-panel v-if="$route.query.type == 3 " :user="user"></user-projects-type-panel>

        <user-latest-checkings v-if="$route.query.type == 3" :user="user"></user-latest-checkings>
        <!-- <user-translator-booking-warning v-if="$route.query.type == 3 && !empty(user.warnings)" :user="user"></user-translator-booking-warning> -->
        <user-files-list-panel :updateFile="updateFile"></user-files-list-panel>
          <user-bookings-list-panel :bookings="bookings.filter(o => o.status_id<=2 || o.status_id>=14)" :title="$t('future_bookings')" :refs="'future'">
          </user-bookings-list-panel>
          <user-bookings-list-panel :bookings="bookings.filter(o => o.status_id>2 & o.status_id<14)" :title="$t('previous_bookings')"
            :refs="'previous'"></user-bookings-list-panel>
        <user-unavailable-times v-if="$route.query.type == 3" :refs="'unavailable'" :title="$t('translator_unavailable_times')"></user-unavailable-times>
        <trainings-section v-if="$route.query.type == 3" :title="$t('translator_trainings')"></trainings-section>
        <translator-salary-credits-list v-if="$route.query.type == 3"/>
      </div> <!-- /.app-wrapper -->

        </div> <!-- /.app-container -->

    </div> <!-- /.app-page user-profile-page -->
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex'
  import {userDataKey, populate} from "~/js/helpers/Vuex";
  import USER_TYPES from '~/js/constants/user-types';
  import UserStatisticsPanel from '~/components/lists/user/UserStatisticsPanel';
  import UserInfoPanel from '~/components/lists/user/UserInfoPanel';
  import UserProjectTypePanel from '~/components/lists/user/UserProjectTypePanel';
  import UserBookingListPanel from '~/components/lists/user/UserBookingListPanel';
  import UserTranslatorBookingWarning from '~/components/lists/user/UserTranslatorBookingWarning';
  import { isEmpty } from '../../../js/helpers/Common';
  import TranslatorSalaryCreditsList from '~/components/lists/user/TranslatorSalaryCreditsList';

  export default {
    name: 'userProfilePage',

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      UserStatisticsPanel,
      UserInfoPanel,
      UserProjectTypePanel,
      UserBookingListPanel,
      UserTranslatorBookingWarning,
      TranslatorSalaryCreditsList
    },

    data () {
      return {
        type: 2,
        updateFile: false,
        user: {
          court_order:{}
        },
      }
    },
    computed: {
     ...mapState('user', {
      form: 'compose',
      }),
      ...mapState('booking', {
        bookings: 'bookings',
      }),
      ...mapGetters('booking', {
        // bookings: 'getBookings',
        currentPending: 'getCurrentPending'
      }),
      ...mapGetters('customerType', {
        customerTypeOpts: 'listAsOptions',
      }),

      ...mapGetters('town', {
        townOpts: 'listAsOptions',
      }),
      ...mapGetters('language', {
        languageOpts: 'getLanguages'
      }),

      ...mapGetters('blacklistedUsers', {
        blacklist: 'getBlacklistedUsers'
      }),

          /**
       * Returns the user's ID from route param "id"
       * @return {int}
       */
      userId () {
        return !window._.isNil(this.$route.params) && !window._.isNil(this.$route.params.id)
          ? parseInt(this.$route.params.id)
          : 0;
      },

      /**
       * Returns the user's type from route query param "type"
       * @return {int}
       */
      queryType () {
        return !window._.isNil(this.$route.query) && !window._.isNil(this.$route.query.type)
          ? parseInt(this.$route.query.type)
          : 0;
      },

      /**
       * Returns the user's data key for reference purpose.
       * @return {string}
       */
      userDataKey () {
        return this.queryType === 2 ? 'customer_data' : 'translator_data';
      },

    },
    methods: {
      ...mapActions('user', {
        readEntry: 'read',
      }),
      empty(v){
        return isEmpty(v)
      },
      ...mapActions({
        customerReadAction: 'customer/readGeneric',
        translatorReadAction: 'translator/readGeneric',
        translatorCourtOrder: 'translator/readCourtOrder',
        courtIssue: 'translator/courtIssue',
        addActivity: 'userAccessLogs/addLogs'
      }),

      /**
       * Wrapper for read entry method for both customer and translator.
       *
       * @param payload
       * @return {void}
       */
      readEntry (payload = {}) {
        const key = window._.chain(USER_TYPES)
                          .findKey((t) => t === this.queryType)
                          .value().toLowerCase();
        const action = `${key}ReadAction`;

        payload.id = window._.isNil(payload.id) ? this.userId : payload.id;

        this[action](payload).then((r) => {
          this.user =  r.data.data[key];

          console.log('Printing User Data:', this.user);

        })
      },

      ...mapActions('booking', {
        getBookingsList: 'getBookingsList',
        fetchLateWithdrawal: 'fetchLateWithdrawal',

      }),
      ...mapActions('language', {
        loadLanguages: 'fetchLanguages'
      }),
      ...mapActions('translator', {
        fetchDelayedBookings:'fetchDelayedBookings'
      }),
      ...mapActions('customerType', {
        loadCustomerTypes: 'browseAsOptions'
      }),

      ...mapActions('feedback', {
        fetchFeedbacks: 'fetchFeedbacks'
      }),
      ...mapActions('blacklistedUsers', {
        fetchBlacklistedUsers: 'fetchBlacklistedUsers'
      }),
      fileUpdated (value) {
        console.log(value);
      this.updateFile = true // someValue
      },

    },
    mounted () {

      this.type = this.queryType;
      let includes = [];
      let params = {};
      let user_type = 'user';
      let blacklisted = {
        all: true,
        include: 'translator,user'
      };
      if (this.queryType === 2) {
        user_type = 'customer';
        // If user is a type of Customer. Include the
        // following properties for request.
        includes.push(
          userDataKey(2),
          'customer_notification_types',
          'blacklisted_users',
          'blacklisted_users.translator',
          'booking_types',
          'customer_data.customer_type',
          'customer_data.department.company',
          'customer_data.department',

        );

        params = {
          'filter[customer_id]': this.$route.params.id,
          'all': true
        };

        blacklisted = Object.assign(blacklisted, {
          'filter[user_id]': this.$route.params.id
        })
      } else if (this.queryType === 3) {
        user_type = 'translator';
        // Else If user is a type of Translator. Include the
        // following properties for request.
        includes.push(
          userDataKey(3),
          'languages',
          'languages.level',
          'countries',
          'towns',
          'translator_notification_setting',
          'translator_customer_types',
          'allowed_customer_types',
          'disallowed_customer_types',
          'warnings',
          'booking_types');


        params = {
          'filter[assigned_translator]': this.$route.params.id,
          'all': true
        };

        blacklisted = Object.assign(blacklisted, {
          'filter[translator_id]': this.$route.params.id
        })


      }

      // Fetch the user entry.
      this.readEntry({
        user_type,
        id: this.$route.params.id,
        extendParams: {
          include: includes.join(),
        },
      });

      this.fetchFeedbacks({
        include: 'booking,review_to,review_from',
        'filter[review_to_id]': this.$route.params.id,
        'filter[is_rejected]': 0,
        default: true
      });

      this.getBookingsList(params);

      this.fetchBlacklistedUsers(blacklisted);
      if (this.queryType === 3) {
        this.fetchLateWithdrawal({
          'filter[translator_id]': this.$route.params.id,
          all: true
        });

        this.fetchDelayedBookings(this.$route.params.id)

        // this.translatorCourtOrder({
        //   id:this.$route.params.id,
        // }).then(r =>{
        //   // this.user = Object.assign(this.user.court_order, r.data.data.order)
        //   // console.log(this.user)
        // })
      }
      populate(this, 'loadCustomerTypes', 'customerTypeOpts', {
        ref: 'Customer Type Listing',
        force: true,
      });
      populate(this, 'loadLanguages', 'languageOpts', {
        ref: 'Languages Listing',
        force: true,
      });

      this.addActivity({
        action: 'has-visited',
        id: this.$route.params.id,
        message: 'Profile Page',
      });
    }
  }
</script>

<style>

</style>
