<template>
  <!-- <div class="todo-form-container"> -->
  <el-dialog
    :title="$t('follow_up_item')"
    :visible.sync="iVisible"
    :modal-append-to-body="false"
    :append-to-body="true"
    custom-class="custom-modal"
    width='60%'>
    <!-- <div class="app-form create-todo-form"> -->
    <el-form :model="form" v-loading="loading" :rules="rules" ref="todo_form">
      <el-form-item
        :label="$t('tfv_booking_id')"
        class="label-medium"
        v-if="!empty(iBooking.id)"
      >#{{form.booking_id}}</el-form-item>
      <el-form-item :label="$t('tfv_todo_title')" class="label-medium" prop="title">
        <el-input v-model="form.title" placeholder></el-input>
      </el-form-item>
      <el-form-item :label="$t('description')" class="label-medium" prop="description">
        <el-input type="textarea" v-model="form.description" placeholder></el-input>
      </el-form-item>
      <el-form-item :label="$t('date_time')" class="label-medium" prop="date">
        <el-date-picker
          v-model="form.date"
          type="datetime"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          popper-class="day-picker-only"
        ></el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('reminder')" class="label-medium" prop="reminder">
        <el-radio-group v-model="form.reminder" tabindex="0">
          <el-radio label="-45 minutes">{{$t('45_minutes_before')}}</el-radio>
          <el-radio label="-2 hours">{{$t('2_hours_before')}}</el-radio>
          <el-radio label="-24 hours">{{$t('24_hours_before')}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('follow_up_by')" class="label-medium" prop="persons">
        <el-select v-model="form.assigned_admin_id" placeholder value-key="id" clearable filterable>
          <el-option v-for="i in adminList" :key="i.id" :value="i.id" :label="i.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el-form-actions">
        <el-button class="modal-size" type="default" @click="iVisible = false" size="small">{{$t('cancel')}}</el-button>
        <el-button class="modal-size" type="primary" size="small" @click="submitForm('todo_form')">{{$t('submit')}}</el-button>
      </el-form-item>
    </el-form>
    <!-- </div> -->

  </el-dialog>
  <!-- </div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { isEmpty } from "../../../js/helpers/Common";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    booking: {
      type: Object,
      default() {
        return {
          id: ""
        };
      }
    }
  },
  data() {
    return {
      loading: false,
      form: {
        booking_id: "",
        title: "",
        description: "",
        date: "",
        reminder: "",
        assigned_admin_id: ""
      },
      rules: {
        title: [
          {
            required: true,
            message: "Please add title"
          }
        ],
        description: [
          {
            required: true,
            message: "Please add descriptions"
          }
        ],
        date: [
          {
            required: true,
            message: "Please add date"
          }
        ],
        reminder: [
          {
            required: true,
            message: "Please select reminder"
          }
        ],
        assigned_admin_id: [
          {
            required: true,
            message: "Please select admin"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters("adminUser", { adminList: "listAsOptions" }),
    iVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit("update:visible", v);
      }
    },
    iBooking() {
      this.form.booking_id = !isEmpty(this.booking) ? this.booking.id : "";

      return !isEmpty(this.booking) ? this.booking : { id: "" };
    }
  },
  methods: {
    ...mapActions("adminUser", {
      browseAdmin: "browseAsOptions"
    }),
    ...mapActions("todo", {
      postBookingTodo: "postBookingTodo"
    }),

    submitForm(form) {
      this.loading = true;
      this.$refs[form].validate(valid => {
        if (valid) {
          this.postBookingTodo(this.form).then(() => {
            this.loading = false;
            this.iVisible = false;
          });
        } else {
          this.loading = false;
        }
      });
    },
    empty(v) {
      return isEmpty(v);
    }
  },
  mounted() {
    let params = {
      "filter[type]": 1,
      all: true
    };
    this.browseAdmin({ extendParams: params });
  }
};
</script>

<style>
</style>
