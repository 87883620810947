/*
|--------------------------------------------------------------------------
| Store > Feedback > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
export default {
  feedback:{
    id: '',
    review_from_id: '',
    review_from_type: null,
    review_to_id: null,
    review_to_type: null,
    booking_id: null,
    rating: 0,
    comment: '' ,
    created_at: null,
    updated_at: null,
    issues:[]
  },
  feedbacks: [],
  feedbackList: [],
  addFeedback: false,
  type: [],
  loading:false

}