<template>
  <div class="app-list resource-list">

    <custom-table-two
      :table-data="listData"
      :extra-cols="listCols"
      :hasDetailsCol="false"
      :hasSelectCol="show_select_col">
      <template slot="topControls">
        <el-row>
          <el-col :span="12">
            <el-button type="primary" icon="fa fa-plus" @click="handleAdd" v-if="show_add_resource_btn">
              {{$t('add_resource')}}
            </el-button>
            <el-button type="primary" icon="fa fa-plus" @click="handleAssignRedirect" v-if="show_assign_redirect_btn">
              {{$t('assign_resource')}}
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-input :placeholder="$t('search')" v-model="search" v-if="show_search_bar" @keyup.enter.native="handleSearch">
              <el-select class="filter-dropdown" v-model="searchOption" slot="prepend"
                         placeholder="Select">
                <el-option :label="$t('id')" value="id" ></el-option>
                <el-option :label="$t('name')" value="name"></el-option>
              </el-select>
              <span class="el-icon-search el-input__icon app-cursor-pointer"
                    slot="suffix" @click="handleSearch"></span>
            </el-input>
            <el-row>
              <el-col :span="12">
                <el-form v-if="show_assign_booking_dropdown">
                  <el-form-item v-if="show_assign_booking">
                    <el-select v-model="booking_to_assign">
                      <el-option v-for="b in listDataBookingsNoExpired"
                                 :key="b.id"
                                 :value="b.id"
                                 :label="b.label"/>
                    </el-select>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="12">
                <el-button size="small" type="primary" icon="fa fa-plus" v-if="show_assign_booking" @click="handleAssignToBooking">
                  {{$t('assign_booking')}}
                </el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

      </template>
      <template slot="actionsColumn" slot-scope="scope">
        <el-button class="circle" type="success" title="Edit" @click="handleEdit(scope)">
          <span class="fa fa-pencil"></span>
        </el-button>
        <el-button v-if="getIsBooking" class="circle" type="danger" title="Remove" @click="handleDelete(scope)">
          <span class="fa fa-trash"></span>
        </el-button>
        <el-button v-if="show_unassign_btn" class="circle" type="danger" title="Unassign" @click="handleUnassign(scope)">
          <span class="fa fa-trash"></span>
        </el-button>
      </template>

      <template slot="selectColumn" slot-scope="scope">
        <el-checkbox @change="handleCheck(scope)"></el-checkbox>
      </template>

    </custom-table-two>

    <!-- Pagination -->
    <el-pagination
      v-if="listPagination.total > listPagination.per_page"
      layout="prev, pager, next"
      :page-size="listPagination.per_page"
      :total="listPagination.total"
      :current-page="listPagination.current_page"
      @current-change="reloadList">
    </el-pagination>

  </div> <!-- /.app-list resource-list -->
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from "vuex";
  import {populate} from "../../../../js/helpers/Vuex";
  import {sd} from "~/js/helpers/Common";

  let moduleTarget = 'resources';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      const _this=this;
      return {

        listCols: [{
          label: _this.$t('name'),
          prop: 'name',
          width: '',
          className: 'name-col'
        }, {
          label: _this.$t('description'),
          prop: 'description',
          width: '',
          className: 'description-col'
        }
        ], // End of listCols
        search: '',
        searchOption: 'name',

        idsToAssign: new Set,
        show_assign_booking: false,
        show_assign_booking_dropdown: true,
        show_add_resource_btn: true,
        show_assign_redirect_btn: false,
        show_select_col: true,
        show_search_bar: true,

        listDataBookingsNoExpired: [],
        booking_to_assign: null,
        show_unassign_btn: false,
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters(moduleTarget, ['listData', 'listPagination', 'getIsBooking']),

      ...mapGetters('booking', {
        listDataBookings: 'listData'
      })

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions(moduleTarget, {
        browse: 'browse',
        browseWithBooking: 'browseWithBooking',
        addWithBooking: 'addWithBooking',
        deleteEntry: 'delete',
        unassignResource: 'unassignResource',
      }),

      ...mapActions('booking', {
        browseBookings: 'browse'
      }),

      ...mapMutations(moduleTarget, {
        is_show: 'setIsBooking'
      }),

      /**
       * Handler for edit event in the table.
       *
       * @param {object} scope
       *   The current scope(row) target.
       *
       * @return {void}
       */
      handleEdit (scope) {

        // Redirect to the edit item page.
        window.location.href = '/#/booking-resources/edit/' + scope.row.id;

      }, // End of handleEdit() method

      /**
       * Handler for add event in the table.
       *
       * @return {void}
       */
      handleAdd () {

        // Redirect to the add item page.
        window.location.href = '/#/booking-resources/add';

      }, // End of handleEdit() method

      handleAssignRedirect () {

        // Redirect to the add item page.
        //window.location.href = '/#/booking-resources?assign_booking_id=' + this.$route.params.id;
        this.$router.push({
          name: 'resources',
          query: {
            assign_booking_id: this.$route.params.id
          }
        });
      },

      /**
       * Handler for delete event in the table.
       *
       * @param {object} scope
       *   The current scope(row) target.
       *
       * @return {void}
       */
      handleDelete (scope) {

        this.$confirm('This will permanently delete the entry. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.deleteEntry({id: scope.row.id});
        }).catch(() => {
          // Silence is golden.
        });

      }, // End of handleDelete() method

      handleUnassign(scope) {
        this.$confirm('This will unassign the resource from this booking. Continue?', this.$t('warning'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {

          let ids = [scope.row.id];
          let booking_id = this.$route.params.id;
          if (ids.length > 0 && booking_id != null) {
            this.unassignResource({
              id: booking_id,
              data: ids
            }).then((r) => {
              this.$notify({
                type: 'success', message: 'Successfully unassigned from this booking.'
              });
              this.reloadList();
            })
              .catch((e) => {
                this.$notify({
                  type: 'error', message: e.response.data
                });
              });
          } else {
            this.$notify({
              type: 'error', message: 'Please select a resource and/or a booking'
            });
          }

        }).catch(() => {
          // Silence is golden.
        });
      },

      /**
       * Method for reloading the list.
       *
       * @param {int} n
       *   The target page number.
       *
       * @return {void}
       */
      reloadList (n) {
        let params = _.clone(this.$route.query);
        n = sd(n, sd(this.$route.query.page, 1)) // Set the page number.
        params.page = n;
        this.$router.push({query: params})
        if (this.$route.name == 'resources.booking') {
          populate(this, 'browseWithBooking', 'listData', {
            force: true,
            params,
            payload: {
              id: this.$route.params.id
            },
            ref: 'Resource Listing (forced)'
          });
        } else {
          populate(this, 'browse', '', {
            force: true,
            params,
            ref: 'Resource Listing (forced)'
          })
        }
      },

      setShowAssignBooking (v) {
        if (this.$route.name != 'resources.booking') {
          this.show_assign_booking = v;
          this.show_search_bar = !v;
        } else {
          this.show_assign_booking = false;
          this.show_search_bar = true;
        }
      },

      handleCheck(scope) {
        let id = scope.row.id;
        if (this.idsToAssign.has(id)) {
          this.idsToAssign.delete(id);
        } else {
          this.idsToAssign.add(id);
        }
        this.setShowAssignBooking(this.idsToAssign.size > 0 ? true : false);

        if (this.listDataBookingsNoExpired.length <= 0) {
          let bookings = [];
          for (let b of this.listDataBookings) {
            let sel = {};
            if (!this.bookingIsDone(b)) {
              sel = {
                id: b.id,
                label: '#' + b.id + ' - ' + b.customer.department.company.municipality.name
              };
              if (this.booking_to_assign != null && this.booking_to_assign == sel.id) {
                bookings.push(sel);
              } else if (this.booking_to_assign == null) {
                bookings.push(sel);
              }
            }
          }
          this.listDataBookingsNoExpired = bookings;
        }
      },

      bookingIsDone(booking) {
        let due = (booking.due != null && booking.due.length > 0) ? moment(booking.due) : null;
        let expiry = (booking.expiry != null && booking.expiry.length > 0) ? moment(booking.expiry) : null;
        let ended_at = (booking.ended_at != null && booking.ended_at.length > 0) ? moment(booking.ended_at) : null;
        let cancelled_at = (booking.cancelled_at != null && booking.cancelled_at.length > 0) ? moment(booking.cancelled_at) : null;

        let final_end = due;
        if (expiry != null && expiry.isAfter(final_end)) {
          final_end = expiry;
        }
        if (ended_at != null && ended_at.isAfter(final_end)) {
          final_end = ended_at;
        }
        if (cancelled_at != null && cancelled_at.isAfter(final_end)) {
          final_end = cancelled_at;
        }

        let now = moment();

        return final_end.isBefore(now) ? true : false;
      },

      handleAssignToBooking() {
        let ids = [...this.idsToAssign];
        let booking_id = this.booking_to_assign;
        if (ids.length > 0 && booking_id != null) {
          this.addWithBooking({
            id: booking_id,
            data: ids
          }).then((r) => {
            let title = '';
            for (let r of r.data.data.booking_resources) {
              title += '(#' + r.id + ') ' + r.name + ', ';
            }
            if (title.length > 0) {
              title = title.slice(0, -2);
              this.$notify({
                type: 'error', message: 'Cannot assign ' + title + ' to booking #' + booking_id
              });
            } else {
              this.$notify({
                type: 'success', message: 'Successfully assigned to a booking.'
              });
            }
          })
            .catch((e) => {
              this.$notify({
                type: 'error', message: e.response.data
              });
            });
        } else {
          this.$notify({
            type: 'error', message: 'Please select a resource and/or a booking'
          });
        }
      },

      handleSearch (e) {
        let params = {}
        if (this.searchOption == 'name') {
          params = {
            'filter[name]': this.search
          }
        } else {
          params = {
            'filter[id]': this.search
          }
        }
        params.page = 1
        this.$router.push({query: params})
        populate(this, 'browse', 'listData', {
          force: true,
          params,
          ref: 'Resource Listing'
        });

      }

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {

      if (this.$route.name == 'resources.booking') {
        populate(this, 'browseWithBooking', 'listData', {
          force: true,
          payload: {
            id: this.$route.params.id
          },
          ref: 'Resource Listing'
        });
        this.is_show({
          is_hide_on_booking: true
        });

        this.show_add_resource_btn = false;
        this.show_select_col = false;
        this.show_search_bar = false;
        this.show_assign_redirect_btn = true;
        this.show_unassign_btn = true;
      } else {

        populate(this, 'browseBookings', 'listDataBookings', {
          force: true,
          params: {
            per_page: 100,
            'include': 'alternative_languages,customer,specific_translators,specific_translators.translator,customer.department.company.municipality',
            sort: '-created_at'
          },
          ref: 'Booking Listing'
        });

        populate(this, 'browse', 'listData', {
          force: true,
          payload: this.populatePayload,
          ref: 'Resource Listing'
        });
        this.is_show({
          is_hide_on_booking: false
        });

        this.show_add_resource_btn = true;
        this.show_select_col = true;
      }

      if (this.$route.query.assign_booking_id != null) {
        this.booking_to_assign = this.$route.query.assign_booking_id;
        this.show_assign_booking_dropdown = false;
        this.show_add_resource_btn = false;
      }

    } // End of Component > mounted

  } // End of export default
</script>
