<template>
  <div class="alert-modal-container">
    <el-dialog
      @close="handleClose"
      :title="$t('make_call')"
      :append-to-body="true"
      :lock-scroll="true"
      :visible.sync="modalVisible"
      :destroy-on-close="true"
      :center="true"
      custom-class="call-mobile-modal-wrapper"
    >

      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="24" :md="9">
          <p>{{$t('calling')}} {{ this.mobile }} ...</p>
          <p>{{$t('please_select_provider')}}</p>
        </el-col>
      </el-row>

      <el-row type="flex" class="row-bg call-mobile-icons" justify="space-around">
        <el-col :xs="24" :sm="24" :md="6">
          <a :href="'sip:' +mobile.replace((/[+]/g,''))+ '@portal.mobeelco.com?method=call'">
            <div class="grid-content">
              <img src="/images/callicons/Linphone_Logo.png">
              <p>LinPhone</p>
              <p>{{$t('with_caller_id')}}</p>
            </div>
          </a>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6">
          <a :href="'facetime-audio:'+mobile">
            <div class="grid-content">
              <img src="/images/callicons/FaceTime_Logo.png">
              <p>FaceTime</p>
            </div>
          </a>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6">
          <a :href="'skype:' +mobile+ '?call'">
            <div class="grid-content">
              <img src="/images/callicons/Skype_Logo.png">
              <p>Skype</p>
            </div>
          </a>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6">
          <a href="#" @click.prevent.stop="handleSMS">
            <div class="grid-content">
              <img src="/images/callicons/SMS_Logo.png">
              <p>SMS</p>
            </div>
          </a>
        </el-col>
        <el-col :sm="24" :md="4">
          <a href="#" @click.prevent.stop="handlePush">
            <div class="grid-content">
              <el-image
                style="width: 80px; height: 80px"
                src="/images/callicons/push_notification_logo.png"
                fit="fill"></el-image>
              <p>Push</p>
            </div>
          </a>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
  import {mapActions} from "vuex";

  export default {
    name: "CallMobile",
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      mobile: {
        type: String,
        default: ''
      },
      translator: {
        type: Object,
        default () {
          return {};
        }
      },
      sentFromBatch : {
        Type: Boolean,
        default: false
      },
      booking: {
        type: Object,
        default: {}
      }
    },
    data() {
      return {
        modalVisible: this.visible,
      }
    },
    watch: {
      visible: function (newVal) {
        this.modalVisible = newVal;
      },
    }, // End of Component > watch
    methods: {

      ...mapActions('smsCentre', {
        createSMSAction: 'createSMS'
      }),
      handleClose: function () {
        this.$emit('update:visible', false);
      }, // End of handleClose() method

      handleClickConfirm() {
        const self = this;
        this.$emit('update:visible', false);
      },
      generateMessage() {
        if (this.booking.type === 'physical') {
          return "Ny bokning " + moment(this.booking.due).format('YYYY-MM-DD') + " kl " + moment(this.booking.due).format('HH:mm') + "\n" +
            "Typ: " + this.$t(this.booking.type) + "\n" +
            "Kund: " + this.booking.customer.department.company.name + "\n" +
            "Språk: " + this.booking.language_name + "\n" +
            "Kan du ta det, eller annan tid?\n" +
            "Ort: " + this.booking.batch_town.map((town) => {
              return town.name
            }).join(',') + "\n" +
            "Tack\n" +
            "DigitalTolk";
        } else {
          return "Ny bokning " + moment(this.booking.due).format('YYYY-MM-DD') + " kl " + moment(this.booking.due).format('HH:mm') + "\n" +
            "Typ: " + this.$t(this.booking.type) + "\n" +
            "Kund: " + this.booking.customer.department.company.name + "\n" +
            "Språk: " + this.booking.language_name + "\n" +
            "Kan du ta det, eller annan tid?\n" +
            "Tack\n" +
            "DigitalTolk";
        }
      },

      handleSMS() {
        console.log(this.translator)
        this.createSMSAction({to_recipient_number: this.mobile, to_recipient: this.translator.id}).then((r) => {
          this.$router.push({name: 'sms.centre', params: {smsConversation: r, message: this.generateMessage()}})
        });
      },
      handlePush() {
        if(this.sentFromBatch === true) {
          var booking_id = this.booking.id
        }

        let routeData = this.$router.resolve({
            name: 'messaging.notification.add',
            query: {
                recipient: this.translator.email,
                sent_via: 2,
                booking_id: booking_id
            }
        });
        window.open(routeData.href, '_blank');
      }

      // let routeData = this.$router.resolve({
      //     name: 'messaging.notification.add',
      //     query: {
      //         recipient: this.mobile
      //     }
      // });
      // window.open(routeData.href, '_blank');

    }
  }
</script>
