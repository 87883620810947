<template>
  <div class="app-page blogs-page">
    <page-header :title="$t('notification_page')" :back-enabled="true"/>

    <div class="app-container">
      <div class="app-wrapper">
        <div class="app-row">
          <div class="app-col full-width">
            <el-form>
              <el-row>
                <div class="text-left">
                  <el-row>
                    <el-col :md="6">
                      <h4>{{$t('select_how_to_send')}}</h4>
                      <el-select
                        v-model="form.sent_via"
                        @change="handleOptionChange"
                        placeholder="Select"
                      >
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-col>
                    <el-col :md="4">
                      <h4>{{$t('booking_id')}}</h4>
                      <el-input placeholder="Input booking id" v-model="form.booking_id">

                      </el-input>
                    </el-col>
                  </el-row>
                </div>

                <base-recipients-page :sent_via="form.sent_via" :recipient="form.recipients"></base-recipients-page>

                <div class="email-body" v-if="form.sent_via == 3 || form.sent_via == 4">

                  <ElCol :span="16">
                    <div class="app-col full-width">
                      <h4>
                        <i class="el-icon-edit"></i> {{$t('write_your_message')}}
                      </h4>
                      <el-form-item :label="$t('subject')" class="label-short">
                        <el-input v-model="form.title" :placeholder="$t('subject')"/>
                      </el-form-item>
                      <vue-editor v-model="form.content"></vue-editor>

                      <div class="attachments-wrapper">
                        <template v-if="isEdit">
                          <div>
                            <attachments-list :files="form.attachments"/>
                          </div>
                        </template>
                        <h5>Add Attachments</h5>
                        <aws-file-upload v-model="form.attachments"/>
                      </div>
                    </div>
                  </ElCol>
                </div>

                <div class="push-body" v-if="form.sent_via == 2">
                  <ElCol :span="16">
                    <div class="app-col full-width">
                      <h4>
                        <i class="el-icon-edit"></i> {{$t('write_your_message')}}
                      </h4>
                      <el-form-item :label="$t('subject')" class="label-short">
                        <el-input v-model="form.title" :placeholder="$t('subject')"/>
                      </el-form-item>
                      <el-row :gutter="20">
                        <el-col :span="14">
                          <el-form-item :label="$t('message_link')" class="label-short">
                            <el-input v-model="form.message_link" :placeholder="$t('input_message_link')"/>
                          </el-form-item>
                        </el-col>
                        <el-col :span="10">
                          <el-form-item :label="$t('text_link')" class="label-short">
                            <el-input v-model="form.text_link"/>
                          </el-form-item>
                        </el-col>
                      </el-row>

                      <vue-editor v-model="form.content" :aria-placeholder="$t('please_input_vue_editor')"></vue-editor>


                    </div>
                  </ElCol>
                </div>

                <div class="push-body" v-if="form.sent_via == 1">
                  <ElCol :span="16">
                    <div class="app-col full-width">
                      <h4>
                        <i class="el-icon-edit"></i> {{$t('write_your_message')}}
                      </h4>
                      <el-form-item :label="$t('subject')" class="label-short">
                        <el-input v-model="form.title"/>
                      </el-form-item>
                      <el-row :gutter="20">
                        <el-col :span="14">
                          <el-form-item :label="$t('message_link')" class="label-short">
                            <el-input v-model="form.message_link"/>
                          </el-form-item>
                        </el-col>
                      </el-row>

                      <el-input
                        type="textarea"
                        :rows="6"
                        placeholder="Please input"
                        :maxlength="150"
                        show-word-limit
                        v-model="form.content"
                      ></el-input>

                    </div>
                  </ElCol>
                </div>

                <ElCol :span="8">
                  <div class="app-col full-width">

                    <div class="text-center">
                      <h4>Supported Tags</h4>
                      <el-tag type="info">Name: [name]</el-tag>
                    </div>

                    <div class="text-center">
                      <h4>{{$t('schedule')}}</h4>
                      <el-date-picker v-model="form.schedule" type="datetime" style="width:56%"
                                      placeholder="Select date and time" format="yyyy-MM-dd HH:mm:ss"
                                      value-format="yyyy-MM-dd HH:mm:ss" popper-class="day-picker-only">
                      </el-date-picker>
                    </div>
                    <div class="text-center">
                      <h4>{{$t('actions')}}</h4>
                      <div class="action-panel">
                        <ul style="list-style-type:none; padding-left: 0px;">
                          <li>
                            <el-button type="primary" @click="handleSubmit('published')">
                              <i class="fa fa-check-square" aria-hidden="true"></i>
                              <span class="action-btn">{{$t('publish')}}</span>
                            </el-button>
                          </li>
                          <li>
                            <el-button type="success" @click="handleSubmit('draft')">
                              <i class="fa fa-envelope-open-o" aria-hidden="true"></i>
                              <span class="action-btn">{{$t('save_draft')}}</span>
                            </el-button>
                          </li>
                          <li>
                            <el-button type="default" @click="handleCancel">{{$t('cancel')}}</el-button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ElCol>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {setTimeout} from "timers";
  import {checkBreadMode} from "~/js/helpers/Route";
  import {mapState, mapActions, mapMutations} from "vuex";
  import AwsFileUpload from '~/components/displays/AwsFileUpload'
  import {isEmpty} from '../../../../js/helpers/Common';
  import AttachmentsList from './partials/AttachmentsList'

  export default {
    components: {
      AwsFileUpload,
      AttachmentsList
    },
    data() {
      return {
        options: [
          {
            value: 3,
            label: "Email"
          },
          {
            value: 4,
            label: "Fax"
          },
          {
            value: 2,
            label: "Push"
          },
          {
            value: 1,
            label: "SMS"
          }
        ]
      };
    },
    computed: {
      ...mapState("notificationCentre", {
        form: "compose"
      }),

      /**
       * Returns boolean whether if the current compose page
       * processes an ADD action.
       *
       * @returns {boolean}
       */
      isAdd() {
        return this.breadMode === "add";
      },

      /**
       * Returns boolean whether if the current compose page
       * processes an EDIT action.
       *
       * @returns {boolean}
       */
      isEdit() {
        return this.breadMode === "edit";
      },

      /**
       * Returns the compose mode of the page. Varies between add | edit.
       *
       * @returns {string}
       */
      breadMode() {
        return checkBreadMode(this);
      },
      /**
       * Return the Id of the current blog
       */
      notificationId() {
        return !window._.isNil(this.$route.params) &&
        !window._.isNil(this.$route.params.id)
          ? parseInt(this.$route.params.id)
          : 0;
      }
    },
    methods: {
      ...mapActions("notificationCentre", {
        getSpecificNotification: "getSpecificNotification",
        updateNotification: "updateNotification",
        createNotification: "createNotification"
      }),
      ...mapMutations("notificationCentre", {
        resetForm: "resetCompose"
      }),
      prepareNotificationComposePage() {
        this.resetForm();
        if (this.isEdit) {
          this.getSpecificNotification({id: this.notificationId}).then(() => {

          });
        }
      },
      handleOptionChange() {
        this.form.recipients = [];
        this.form.content = ''
      },
      handleSubmit(status) {
        this.form.sent_by = JSON.parse(localStorage.getItem("app_user_data")).id;

        if (this.isEdit) {
          if (status == "published") {
            this.form.id = this.notificationId;
            this.form.status = 2;

            this.updateNotification(this.form);
          }
          if (status == "draft") {
            this.form.id = this.notificationId;
            this.form.status = 1;
            this.updateNotification(this.form);
          }
        }
        if (this.isAdd) {
          if (status == "published") {
            this.form.id = this.notificationId;
            this.form.status = 2;

            this.createNotification(this.form);
          }
          if (status == "draft") {
            this.form.id = this.notificationId;
            this.form.status = 1;
            this.createNotification(this.form);
          }
        }
      },
      handleCancel() {
        this.$router.push({name: 'messaging.notification'});
      }
    },
    mounted() {
      this.prepareNotificationComposePage();
      setTimeout(() => {
        console.log(this.form);
      }, 4000);

      if (this.$route.query.recipient != null) {
        this.form.recipients.push(this.$route.query.recipient);
      }
      if (this.$route.query.sent_via != null) {
        this.form.sent_via = parseInt(this.$route.query.sent_via);
      }
      if (this.$route.query.booking_id != null) {
        this.form.booking_id = parseInt(this.$route.query.booking_id);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .el-select {
    width: 220px;
  }

  .action-panel .el-button {
    min-width: 220px;
    font-size: 12px;
  }

  .action-panel .el-button i.fa {
    float: left;
  }

  .attachments-wrapper {
    margin-bottom: 50px;
  }
</style>
