/*
|--------------------------------------------------------------------------
| Store > Financial > Adjustment > Mutations
|--------------------------------------------------------------------------
| File to define the mutations that is relevant for the current scope.
*/

'use strict';

export default {

  /**
   * Set the target booking
   *
   * @param  {object} state - the state property the current Vuex Object
   * @param  {object} payload - an object which contains option values
   * @return {void}
   */
  setTargetBooking (state, payload) { state.targetBooking = payload; }

}