<template>
    <div class="app-form resource-compose-form">
        <el-form :model="form" :id="formElId" :ref="formElId">

            <!-- Name Field -->
            <el-form-item :id="genContainerId('name',formElId)"
                          class="label-short"
                          :label="$t('name')">

                <el-input :id="genFieldId('name',formElId)"
                          v-model="form.name"/>
            </el-form-item>

            <el-form-item :id="genContainerId('resource_types', formElId)"
                            class="label-short"
                            :label="$t('resource_types')">
                <multiselect v-model="form.types" :options="resource_types" :multiple="true" :taggable="true"
                    placeholder="Resource Types" :clear-on-select="true" :hide-selected="true" @tag="addTag"
                    :internal-search="false">
                    <span slot="noResult">{{$t('insert_resource_types')}}</span>
                </multiselect>
            </el-form-item>

            <!-- Description Field -->
            <el-form-item :id="genContainerId('description',formElId)"
                          class="label-short"
                          :label="$t('description')">

                <el-input :id="genFieldId('description',formElId)"
                          v-model="form.description" />
            </el-form-item>

            <!-- Economic value -->
            <el-form-item :id="genContainerId('economic_value',formElId)"
                          class="label-short"
                          :label="$t('economic_value')">

                <el-input :id="genFieldId('economic_value',formElId)"
                          v-model="form.economic_value" />
            </el-form-item>

            <!-- Location -->
            <el-form-item :id="genContainerId('location',formElId)"
                          class="label-short"
                          :label="$t('location')">

                <booking-maps-address :id="genFieldId('location',formElId)"
                            v-model="form.location" />

            </el-form-item>

            <!-- Location Description -->
            <el-form-item :id="genContainerId('location_description',formElId)"
                          class="label-short"
                          :label="$t('location_description')">

                <el-input :id="genFieldId('location_description',formElId)"
                          v-model="form.location_description" />
            </el-form-item>

            <!-- Save button -->
            <el-form-item :id="genContainerId('save_btn',formElId)">

                <el-button :id="genFieldId('save_btn',formElId)"
                           type="primary"
                           @click="handleSubmit">
                    {{mode}}
                </el-button>
            </el-form-item>
        </el-form>
    </div> <!-- /.app-form resources-compose-form -->
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import {mapState, mapGetters, mapMutations, mapActions} from "vuex";
    import {checkBreadMode} from "../../../../js/helpers/Route";
    import {populate} from '~/js/helpers/Vuex';
    import { Notification } from "element-ui";

    let moduleTarget = 'resources';

    export default {

        components: {
            Multiselect
        },

        props: [],
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {

                formElId: moduleTarget + '_compose_form',
                blockCls: 'resource-compose-page',
                selected_resource_types: [],

            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapState(moduleTarget, {
                form: 'compose',
                payloadData: 'payloadData',
            }),

            ...mapState('resourceTypes', {
                formTypes: 'compose',
                payloadDataTypes: 'payloadData',
            }),

            ...mapGetters(moduleTarget, {
                entryData: 'itemData',
            }),

            ...mapGetters('resourceTypes', {
                resource_types: 'listTypes',
            }),

            /**
             * Value to determine the current mode whether it's add or edit.
             *
             * @return {string}
             */
            mode() {
                return checkBreadMode(this);
            }

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            ...mapActions(moduleTarget, {
                addEntry: 'addResource',
                editEntry: 'editResource',
                readEntry: 'readResource'
            }),

            ...mapMutations(moduleTarget, {
                resetForm: 'resetCompose',
            }),

            ...mapActions('resourceTypes', {
                browseTypes: 'browse',
                storeType: 'add'
            }),

            /**
             * Handle when the form has been submitted.
             *
             * @return {void}
             */
            handleSubmit() {

                // Set the action to be made.
                let action = this.mode + 'Entry';
                let payload = {}; // Set container for payload.

                // If the current compose mode is EDIT, then
                // add the ID of the target into the payload.
                if (this.mode == 'edit') {
                    payload.id = this.$route.params.id;
                }

                this[action](payload).then(r =>{
                    // code here
                });

            }, // End of handleSubmit() method

            setDefaults(mappings) {
                _.forEach(mappings, (value, key) => {
                    this.form[key] = this.form[key] || value;
                });
            },

            addTag(newTag) {
                if (this.form.types == null) {
                    this.form.types = [newTag];
                } else {
                    this.form.types.push(newTag);
                }
                this.formTypes.type = newTag;
                this.storeType().then(r => {
                    populate(this, 'browseTypes', 'resource_types', {
                        force: true,
                    });
                });
            }

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
       watch: {
            "$route.params.id"(val) {
                // call the method which loads your initial state
                this.readEntry({id: this.$route.params.id});
            }
        },
        mounted() {
            this.resetForm();

            // If the current mode is on edit, fetch the property values for the targeted entry.
            if (this.mode == 'edit') {
                this.readEntry({id: this.$route.params.id}).then(response => {
                    // code here
                });
            }

            this.setDefaults({
            });

            populate(this, 'browseTypes', 'resource_types', {
                force: true,
            });

        }, // End of Component > mounted

    } // End of export default
</script>
