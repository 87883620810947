<template>
    <div class="table-component list-base-booking">

        <template v-if="isByMonth">
            <el-collapse class="" v-model="activePanels">

                <el-collapse-item v-for="(items, month, index) in dataByMonth"
                                  :key="month" :name="'panel-'+index"
                                  :class="'monthly-bookings '+month+'-bookings'">
                    <template slot="title">
                        <div class="booking-panel-title-container">
                            <div class="booking-panel-title">
                                <h3>{{ $t( month.toLowerCase() ) }}</h3>
                            </div>
                            <div class="booking-list-count">
                                <p>({{ $t( 'total_bookings' ) }} : <span>{{ items.length }}</span>)</p>
                            </div>
                        </div> <!-- .booking-panel-title-container -->
                    </template>

                    <base-list-table :table-data="items" :extra-cols="cols" actions-col-width="200px"
                                     :has-details-col="hasDetailsCol" :has-actions-col="hasActionsCol"
                                     :has-info-col="hasInfoCol">
                        <template slot="detailsColumn" slot-scope="scope">
                            <!--suppress JSUnresolvedVariable -->
                            <slot
                                    name="detailsColumn"
                                    :$index="scope.$index"
                                    :row="scope.row"/>
                        </template>

                        <template slot="actionsColumn" slot-scope="scope">
                            <!--suppress JSUnresolvedVariable -->
                            <slot
                                    name="actionsColumn"
                                    :$index="scope.$index"
                                    :row="scope.row"/>
                        </template>

                        <template slot="infoColumn" slot-scope="scope">
                            <!--suppress JSUnresolvedVariable -->
                            <slot
                                    name="infoColumn"
                                    :$index="scope.$index"
                                    :row="scope.row"/>
                        </template>

                    </base-list-table>

                </el-collapse-item>
            </el-collapse>
        </template>

        <template v-if="!isByMonth">
            <base-list-table :table-data="tableData" :extra-cols="cols"
                             actions-col-width="100px" info-col-width="250px"
                             :has-details-col="hasDetailsCol" :has-actions-col="hasActionsCol"
                             :has-info-col="hasInfoCol">
                <template slot="detailsColumn" slot-scope="scope">
                    <!--suppress JSUnresolvedVariable -->
                    <slot
                            name="detailsColumn"
                            :$index="scope.$index"
                            :row="scope.row"/>
                </template>

                <template slot="actionsColumn" slot-scope="scope">
                    <!--suppress JSUnresolvedVariable -->
                    <slot
                            name="actionsColumn"
                            :$index="scope.$index"
                            :row="scope.row"/>
                </template>

                <template slot="infoColumn" slot-scope="scope">
                    <!--suppress JSUnresolvedVariable -->
                    <slot
                            name="infoColumn"
                            :$index="scope.$index"
                            :row="scope.row"/>
                </template>
            </base-list-table>
        </template>

        <el-pagination v-if="pagination.per_page < pagination.total"
                       layout="prev, pager, next"
                       :page-size="pagination.per_page"
                       :total="pagination.total"
                       :current-page="pagination.current_page"
                       @current-change="changePage">
        </el-pagination>

    </div> <!-- .list-base-booking -->
</template>

<script>
    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {
            tableData: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            extraCols: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            isByMonth: {
                type: Boolean,
                default: false,
            },
            pagination: {
                type: Object,
                default: () => {
                    return {
                        per_page: 0,
                        total: 0,
                        current_page: 0,
                    };
                }
            },
            hasDetailsCol: {
                type: Boolean,
                default: true,
            },
            hasActionsCol: {
                type: Boolean,
                default: true,
            },
            hasInfoCol: {
                type: Boolean,
                default: true,
            },


        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data: function () {
            return {
                activePanels: ['panel-0'],
            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            cols: function () {

                let r = [
                    {
                        label: 'Start',
                        prop: 'attributes.start',
                        width: '',
                        className: 'start-col',
                    },
                    {
                        label: 'End',
                        prop: 'attributes.end',
                        width: '',
                        className: 'end-col',
                    },
                    {
                        label: 'Service',
                        prop: 'attributes.service_name',
                        width: '',
                        className: 'service-col',
                    },
                    {
                        label: 'Status',
                        prop: 'attributes.booking_status',
                        width: '',
                        className: 'booking-status-col',
                    },
                ];

                let extra = this.extraCols;

                if ( !_.isEmpty(extra) ) {
                    _.forEach(extra, (x) => {
                        r.push(x);
                    });
                }

                return r;

            }, // End of cols() method

            /**
             * Formats the given tableData and group it by Month.
             *
             * @return {array}
             */
            dataByMonth: function () {

                // Put tableData into a container.
                let td = _.cloneDeep(this.tableData);

                // If tableData is not empty.
                if ( td.length != 0 ) {

                    // Sort the array of items by month and year.
                    td.sort((a, b) => {
                        let left = moment.utc(a.attributes.date);
                        let right = moment.utc(b.attributes.date);
                        return left.diff(right);
                    });

                    let col = {}; // Prepare the collection container.

                    // Iterate tru each items.
                    _.each(td, (x) => {

                        // Reformat the date.
                        let key = moment(x.attributes.date)
                            .format('MMMM')
                            .toLowerCase();

                        // If col[key] is undefined, instantiate it with
                        // an empty array.
                        if ( _.isUndefined(col[key]) ) {
                            col[key] = [];
                        }

                        // Push the item into collection categorized by month.
                        col[key].push(x)
                    });

                    console.log(col);
                    return col; // Return the collection.
                }
                // Else if tableData is empty, just return an empty array.
                else {
                    return [];
                }

            }, // End of dataByMonth() method

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            /**
             * Method as event dispatcher when a number in the pagination
             * was clicked
             *
             * @param  {int} n
             *   : the page number selected
             *
             * @return {void}
             */
            changePage: function (n) {
                this.$emit('change-page', n);
            }, // End of changePage() method

        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        mounted: function () {

        }, // End of Component > mounted

    } // End of export default
</script>
