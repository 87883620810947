<template>
  <div class="app-form batches-settings-form">
    <el-form :model="form" :id="formElId" :ref="formElId">

      <div class="app-container">
        <div class="app-wrapper">
          <div class="app-row">
            <div class="app-col full-width" v-if="false">

              <h4>Batches settings list</h4>

              <el-form-item :id="genContainerId('code',formElId)"
                            label="Code (keyword for DB. Without spaces, symbols.)" class="label-long">
                <el-input :id="genFieldId('code',formElId)"
                          v-model="form.code"/>
              </el-form-item>

              <el-form-item :id="genContainerId('title',formElId)"
                            label="Title" class="label-medium">
                <el-input :id="genFieldId('title',formElId)"
                          v-model="form.title"/>
              </el-form-item>
              <h4>Lists values</h4>
              <small>Please, define values in <b>percentages</b> (without %). Be sure in total you have no
                more then 100%.
              </small>
              <el-form-item
                :id="genContainerId('mix_language',formElId)"
                label="Mix related languages" class="label-medium"
              >
                <el-radio
                  v-model="form.mix_language" label="1"
                  :id="genFieldId('mix_language_yes',formElId)"
                  aria-labelledby="Mix related languages"
                >
                  Yes
                </el-radio>
                <el-radio
                  v-model="form.mix_language" label="0"
                  :id="genFieldId('mix_language_no',formElId)"
                  aria-labelledby="Mix related languages"
                >
                  No
                </el-radio>
              </el-form-item>

            </div> <!-- /.app-col -->

            <div class="app-col full-width" v-for="(v, i) in btypes">

              <batches-settings-shared-fields :key="i"
                                              :btype="{name: v, code: i}"
                                              v-model="form.settings[i]"/>
            </div> <!-- /.app-col -->

            <div class="app-col col-container text-right">
              <el-form-item :id="genContainerId('save_btn',formElId)">
                <el-button :id="genFieldId('save_btn',formElId)"
                           type="primary"
                           @click="handleSubmit">
                  Save
                </el-button>
              </el-form-item>
            </div> <!-- /.app-col -->

          </div> <!-- /.app-row -->
        </div> <!-- /.app-wrapper -->
      </div> <!-- /.app-container -->
    </el-form>
  </div>
</template>

<script>
  import {mapState, mapActions} from "vuex";

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        formElId: 'batches-settings-form',

        btypes: {
          physical: 'Physical',
          phone: 'Phone',
        },
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapState('batchesSettings', {
        form: 'compose',
      })

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions('batchesSettings', {
        editEntry: 'edit'
      }),

      /**
       * Handler whent the form is submitted.
       *
       * @return {void}
       */
      handleSubmit () {
        this.editEntry({id: this.$route.params.id});
      }, // End of handleSubmit

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
    }, // End of Component > mounted

  } // End of export default


</script>
