<template>
  <div class="app-list notification-list">
    <base-list-table :table-data="data" :extra-cols="listCols" :hasDetailsCol="false">
      <template slot="actionsColumn" slot-scope="scope">
        <el-button
          class="circle"
          type="success"
          @click="$router.push({name:'messaging.notification.edit',params:{id:scope.row.id}})"
          :title="$t('edit')"
        >
          <span class="fa fa-pencil"></span>
        </el-button>
        <el-button class="circle" type="danger" @click="handleDelete(scope)" :title="$t('delete')">
          <span class="fa fa-trash"></span>
        </el-button>
      </template>
    </base-list-table>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
  props: {
    data: {
      type: Array
    },
    activeName: {
      type: String,
      default: ""
    }
  }, // End of Component > props

  /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
  data() {
    const _this=this;
    return {
      listCols: [
        {
          label: _this.$t('post_title'),
          prop: "title",
          width: ""
        },
        {
          label: _this.$t('sent_through'),
          prop: "sent_via",
          width: "",
          mutator(v) {
            return v.code;
          }
        },
        {
          label: _this.$t('total_recipients'),
          prop: "recipients",
          width: "",
          mutator(v) {
            return v.length
          }
        },
        {
          label: "Scheduled At",
          prop: "schedule",
          width: ""
        },
        {
          label: _this.$t('sent_at'),
          prop: "sent_at",
          width: ""
        },
        {
            label: _this.$t('created_by'),
          prop: "sent_by",
          width: "",
        }
      ]
    };
  }, // End of Component > data

  /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
  computed: {}, // End of Component > computed

  /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
  watch: {}, // End of Component > watch

  /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
  methods: {
    ...mapActions("blogs", {
      deleteBlog: "deleteBlog",
      getBlogs: "getBlogs"
    }),
    handleDelete(scope) {
      this.$confirm(
        "This will permanently delete the entry. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          this.deleteBlog({ id: scope.row.id }).then(() => {
            this.getBlogs({ filter: this.activeName });
          });
        })
        .catch(() => {});
    }
  }, // End of Component > methods

  /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
  mounted() {} // End of Component > mounted
};
</script>
