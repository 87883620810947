/*
|--------------------------------------------------------------------------
| Store > User > Admin > State
|--------------------------------------------------------------------------
|
| This file contains the state property of User > Admin Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {gep} from "../../../helpers/Vuex";

let target = 'users';
let apiPrefix = window.app_api_prefix_3;
let commonBrowseParams = {
    'fields[users]': 'id,name,email,type',
    "filter[type]" : 1,
};

let composeProps = {

    // Main props
    id: '',
    name: '',
    email: '',
    mobile: '',
    phone: '',
    password: '',
    password_confirmation: '',
    type: 1,

    // 2FA values.
    tfa_enabled: false,
    show_2fa_qr: false,

    // roles
    roles: [],
    
    //is user enabled
    enabled: false

};

export default {

    model_info: {
        name: 'Admin User Store',
        module: 'admin',
        table: target,
    },

    api: {
        browse: { method: 'get', path: gep(target, apiPrefix), params: commonBrowseParams },
        read: { method: 'get', path: gep(target + '/{id}', apiPrefix), params: {
            // include: 'roles'
        }, data: {}, },
        edit: { method: 'put', path: gep(target + '/{id}', apiPrefix), params: {}, data: {}, },
        add: { method: 'post', path: gep(target, apiPrefix), params: {}, data: {}, },
        delete: { method: 'delete', path: gep(target + '/{id}', apiPrefix), params: {}, data: {}, },
    },

    composeFresh: _.cloneDeep(composeProps),
    compose: _.cloneDeep(composeProps),

    payloadData: {}, // End of payloadData state

    autoInjectPayload: true,
    autoReflectItem: true,
    autoUpdateList: true,
    trashedUsers:[],
    paginationTrashedUsers:{}

} // End of export default
