'use strict';

import APICaller from "~/js/helpers/APICaller";
import API from '~/js/constants/api';
import {mapEndpoint} from "~/js/helpers/Common";
import {Notification} from 'element-ui';
import isNil from 'lodash/isNil';

export default {

  /**
   * Action for loading the financial lists.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  loadFinancialLists (context, payload) {

    const apiOpts = {
      method: 'GET',
      endpoint: `${API.FINANCIAL.EXPORT.FINANCIAL_LIST}`,
    };

    if (!window._.isNil(payload.params)) {
      apiOpts.params = payload.params;
    } else {
      apiOpts.params = {};
    }

    apiOpts.params.sort = '-id';

    context.commit('setListLoading', true);
    return new APICaller(apiOpts)
      .then((r) => {
        const data = r.data.data.financial_lists;
        const meta = r.data.meta;
        context.commit('setList', {data, meta});
      })
      .finally(() => {
        context.commit('setListLoading', false);
      });
  },

  /**
   * Action for generating financial list.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  createFinancialList (context, payload) {
    const apiOpts = {
      method: 'POST',
      endpoint: `${API.FINANCIAL.EXPORT.FINANCIAL_LIST}`,
      data: context.state.exportForm,
      isDataRaw: true,
    };

    return new APICaller(apiOpts)
      .then(() => {
        Notification({
          type: 'success',
          title: 'Successful',
          message: 'Financial List Successfully Created.'
        });
      })
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Something went wrong.',
          message: 'Please contact your Administrator'
        });
      });
  },

  /**
   * Action to get the Financial List details from the API.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  getFinancialList (context, payload) {
    const apiOpts = {
      method: 'GET',
      endpoint: mapEndpoint(
        `${API.FINANCIAL.EXPORT.FINANCIAL_LIST}/{financial_list}`,
        payload
      ),
      params: {
        include: ['invoice', 'salary'].join(','),
        append: ['progress', 'unchecked'].join(',')
      },
      isDisableUiUrl: true
    };

    context.commit('setItemLoading', true);
    return new APICaller(apiOpts)
      .then((r) => {
        context.commit('setItemData', r.data.data.financial_list);
      })
      .finally(() => {
        context.commit('setItemLoading', false);
      });
  },

  /**
   * Action to remove the Financial List Entry from the API.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  removeFinancialList (context, payload) {
    const apiOpts = {
      method: 'DELETE',
      endpoint: mapEndpoint(
        `${API.FINANCIAL.EXPORT.FINANCIAL_LIST}/{financial_list}`,
        payload
      ),
    };

    return new APICaller(apiOpts);
  },

  /**
   * Action to run a precheck for Financial List.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  runPrecheck (context, payload) {
    if (window._.isNil(payload.financial_list)) {
      payload.financial_list = context.state.financialListId;
    }

    let apiOpts = {
      method: 'POST',
      endpoint: mapEndpoint(`${API.FINANCIAL.EXPORT.PRECHECK}`, payload),
      isDisableUiUrl: true
    };

    return new APICaller(apiOpts);
  },

  /**
   * Action to run a precheck for Financial List.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  runPrecheckExport (context, payload) {
    if (window._.isNil(payload.financial_list)) {
      payload.financial_list = context.state.financialListId;
    }

    let apiOpts = {
      method: 'POST',
      endpoint: mapEndpoint(`${API.FINANCIAL.EXPORT.PRECHECK_EXPORT}`, payload),
      isDisableUiUrl: true
    };

    if (!window._.isNil(payload.data)) {
      apiOpts.data = {
        recipients: payload.data.recipients
      };
      apiOpts.isDataRaw = true;
    }

    return new APICaller(apiOpts);
  },

  /**
   * Action to calculate Invoice.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  calculateInvoice (context, payload) {
    const apiOpts = {
      method: 'POST',
      endpoint: mapEndpoint(`${API.FINANCIAL.EXPORT.CALCULATE.INVOICES}`, payload),
    };

    return new APICaller(apiOpts)
      .catch((e) => {
        Notification({
          type: 'error',
          title: 'Error',
          message: e.response.data.data
        });
      });
  },

  /**
   * Action to calculate Salary.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  calculateSalary (context, payload) {
    const apiOpts = {
      method: 'POST',
      endpoint: mapEndpoint(`${API.FINANCIAL.EXPORT.CALCULATE.SALARIES}`, payload),
    };

    return new APICaller(apiOpts)
      .catch((e) => {
        Notification({
          type: 'error',
          title: 'Error',
          message: e.response.data.data
        });
      });
  },

  /**
   * Action to populate Invoice.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  populateInvoice (context, payload) {
    const apiOpts = {
      method: 'POST',
      endpoint: mapEndpoint(`${API.FINANCIAL.EXPORT.POPULATE.INVOICES}`, payload),
    };

    return new APICaller(apiOpts)
      .catch((e) => {
        Notification({
          type: 'error',
          title: 'Error',
          message: e.response.data.data
        });
      });
  },

  /**
   * Action to populate Salary.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  populateSalary (context, payload) {
    const apiOpts = {
      method: 'POST',
      endpoint: mapEndpoint(`${API.FINANCIAL.EXPORT.POPULATE.SALARIES}`, payload),
    };

    return new APICaller(apiOpts)
      .catch((e) => {
        Notification({
          type: 'error',
          title: 'Error',
          message: e.response.data.data
        });
      });
  },

  /**
   * Action to check the process progress for Invoice.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  checkProgressInvoice (context, payload) {
    const apiOpts = {
      method: 'GET',
      endpoint: mapEndpoint(`${API.FINANCIAL.EXPORT.PROGRESS.INVOICES}`, payload)
    };
    return new APICaller(apiOpts).then((r) => {
      context.commit('setProgressInvoice', r.data.data.invoice);
    });
  },

  /**
   * Action to check the process progress for Salary.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  checkProgressSalary (context, payload) {
    const apiOpts = {
      method: 'GET',
      endpoint: mapEndpoint(`${API.FINANCIAL.EXPORT.PROGRESS.SALARIES}`, payload)
    };
    return new APICaller(apiOpts).then((r) => {
      context.commit('setProgressSalary', r.data.data.salary);
    });
  },

  /**
   * Action to check the process progress for Financial List.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  checkProgressFinancialList (context, payload = {}) {
    if (isNil(payload.financial_list)) {
      throw new Error('Missing required value payload.financial_list');
    }

    const apiOpts = {
      method: 'GET',
      endpoint: `${API.FINANCIAL.EXPORT.FINANCIAL_LIST}/${payload.financial_list}`,
      params: {
        append: ['progress', 'unchecked'].join(',')
      }
    };
    return new APICaller(apiOpts).then((r) => {
      context.commit('setProgressFinancialList', r.data.data.financial_list);
    });
  },

  /**
   * Action to check the Uncalculated entries for Invoice.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  checkUncalculatedInvoice (context, payload) {
    const apiOpts = {
      method: 'GET',
      endpoint: mapEndpoint(`${API.FINANCIAL.EXPORT.UNCALCULATED.INVOICES}`, payload)
    };
    return new APICaller(apiOpts).then((r) => {
      context.commit('setUncalculatedInvoice', r.data.data.invoice.uncalculated);
    });
  },

  /**
   * Action to check the Uncalculated entries for Salary.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  checkUncalculatedSalary (context, payload) {
    const apiOpts = {
      method: 'GET',
      endpoint: mapEndpoint(`${API.FINANCIAL.EXPORT.UNCALCULATED.SALARIES}`, payload)
    };
    return new APICaller(apiOpts).then((r) => {
      context.commit('setUncalculatedSalary', r.data.data.salary.uncalculated);
    });
  },

  /**
   * Action to export Invoice.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  exportInvoice (context, payload) {
    const apiOpts = {
      method: 'POST',
      endpoint: mapEndpoint(`${API.FINANCIAL.EXPORT.INVOICES}`, payload),
      data: payload.data,
      isDataRaw: true,
    };
    return APICaller(apiOpts);
  },

  /**
   * Action to export Salary.
   *
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  exportSalary (context, payload) {
    const apiOpts = {
      method: 'POST',
      endpoint: mapEndpoint(`${API.FINANCIAL.EXPORT.SALARIES}`, payload),
      data: payload.data,
      isDataRaw: true,
    };
    return APICaller(apiOpts);
  },

  /**
   * @param context
   * @param payload
   * @return {Promise|any}
   */
  exportTranslatorInvoice (context, payload) {
    const apiOpts = {
      method: 'POST',
      endpoint: (API.FINANCIAL.EXPORT.TRANSLATOR_INVOICES).replace('{salary}', payload.salary),
      data: payload.data,
      isDataRaw: true
    };
    return new APICaller(apiOpts);
  },

  /**
   * @param {Object} context - Vuex context.
   * @param {Object} payload - Container for necessary values.
   * @param {int} payload.financialListId - Target Financial List.
   * @returns {Promise}
   */
  markAsComplete (context, payload) {
    if (isNil(payload.financialListId)) {
      throw new Error('Missing required value payload.financialListId');
    }

    const endpoint = mapEndpoint(`${API.FINANCIAL.EXPORT.FINANCIAL_LIST}/{financialList}/mark-complete`, {
      financialList: payload.financialListId
    });

    return APICaller({endpoint, method: 'POST'});
  }
}
