/*
|--------------------------------------------------------------------------
| Financial > Shared > methods > setSettingsPayload
|--------------------------------------------------------------------------
| Partial file as a reusable method for it's purpose.
*/

'use strict';

import remove from 'lodash/remove';

let TLVL_OPTS = [];

export default function (context, payload) {
  const state = context.state;

  let pl = {...payload};
  const BUSINESS_RULES = state.settings.business_rules;
  const CHARGING = state.settings.charging;
  const TRANSACTION_FEE = state.settings.transaction_fee;
  const EMERGENCY_BOOKING = state.settings.emergency_booking;
  const INCONVENIENCE_CHARGE = state.settings.inconvenience_charge;
  const TRAVEL_SETTINGS = state.settings.travel_settings;
  const LATE_CANCELLATIONS = state.settings.late_cancellations;
  const LANGUAGE_SETTINGS = state.settings.language_settings;
  const TEXT_TRANSLATION = state.settings.text_translation;
  const OTHER_SETTINGS = state.settings.other_settings;
  TLVL_OPTS = context.rootGetters['translatorLevel/formattedTranslatorLevelOpts'];

  const isInvoice = state.endpointKey === 'invoices';
  // const isSalary = state.endpointKey === 'salaries';

  // Set defaults that is overridable.
  // This code is Removable once the whole implementation is complete.

  // ----------------------------------------
  // ----- BUSINESS RULES -----
  // ----------------------------------------

  pl.parts_step = BUSINESS_RULES.parts_step;
  pl.parts_max = BUSINESS_RULES.parts_max;
  pl.rounding_minutes = BUSINESS_RULES.rounding_minutes;

  pl.parts_timings = objToArr(BUSINESS_RULES.parts_timings, 'booking_type');
  pl.booking_timings = objToArr(BUSINESS_RULES.booking_timings, 'booking_type');
  // pl.late_cancellation_rules = objToArr(BUSINESS_RULES.late_cancellation_rules, 'booking_type');

  if (!EMERGENCY_BOOKING.different_type_immediate) {
    remove(pl.parts_timings, (o) => o.booking_type === 'phone_immediate');
    remove(pl.booking_timings, (o) => o.booking_type === 'phone_immediate');
    // remove(pl.late_cancellation_rules, (o) => o.booking_type === 'phone_immediate');
  }

  // ----------------------------------------
  // ----- CHARGING -----
  // ----------------------------------------

  pl.use_parts = CHARGING.use_parts;
  pl.is_flat_rate = CHARGING.is_flat_rate;

  pl.charges = fCharges(CHARGING.charges);

  if (!EMERGENCY_BOOKING.different_type_immediate) {
    remove(pl.charges, (o) => o.booking_type === 'phone_immediate');
  }

  if (pl.use_parts) {
    console.log('Adding Fees');
    pl.parts_fees = fPartsFees(CHARGING.charges);
  }

  // ----------------------------------------
  // ----- TRANSACTION FEE -----
  // ----------------------------------------

  pl.pay_transaction_fee = TRANSACTION_FEE.pay_transaction_fee;
  if (pl.pay_transaction_fee) {
    pl.transaction_fees = objToArr(TRANSACTION_FEE.transaction_fees, 'booking_method');
  }

  pl.office_hours_fee = {};

  pl.office_hours_fee.pay_outside_hours = TRANSACTION_FEE.office_hours_fee.pay_outside_hours;
  pl.office_hours_fee.fee = TRANSACTION_FEE.office_hours_fee.fee;
  pl.office_hours_fee.start_time = TRANSACTION_FEE.office_hours_fee.start_time;
  pl.office_hours_fee.end_time = TRANSACTION_FEE.office_hours_fee.end_time;
  pl.office_hours_fee.transaction_fee_percentage = TRANSACTION_FEE.office_hours_fee.transaction_fee_percentage;

  pl.pay_booking_type_fee = TRANSACTION_FEE.pay_booking_type_fee;
  if (pl.pay_booking_type_fee) {
    pl.booking_type_fees = objToArr(TRANSACTION_FEE.booking_type_fees, 'booking_type');
  }

  pl.pay_translator_level_fee = TRANSACTION_FEE.pay_translator_level_fee;
  if (pl.pay_translator_level_fee) {
    pl.translator_level_fees = fTranslatorLevelFees(TRANSACTION_FEE.translator_level_fees);
  }

  // ----------------------------------------
  // ----- EMERGENCY BOOKING -----
  // ----------------------------------------

  // pl.pay_immediate_fee = EMERGENCY_BOOKING.pay_immediate_fee;
  // if (pl.pay_immediate_fee) {
  //   pl.immediate_fees = objToArr(EMERGENCY_BOOKING.immediate_fees, 'booking_type');
  // }

  pl.emergency_setting = {};
  pl.emergency_setting.pay_emergency_fee = EMERGENCY_BOOKING.pay_emergency_fee;
  if (pl.emergency_setting.pay_emergency_fee) {
    pl.emergency_setting.emergency_minutes = EMERGENCY_BOOKING.emergency_minutes;
    pl.emergency_fees = objToArr(EMERGENCY_BOOKING.emergency_fees, 'booking_type');
  }

  pl.pay_rush_booking_fee = EMERGENCY_BOOKING.pay_rush_booking_fee;
  if (pl.pay_rush_booking_fee) {
    pl.rush_booking_fees = objToArr(EMERGENCY_BOOKING.rush_booking_fees, 'booking_type');
  }

  pl.different_type_immediate = EMERGENCY_BOOKING.different_type_immediate;

  // ----------------------------------------
  // ----- TRAVEL SETTINGS -----
  // ----------------------------------------

  pl.travel_setting = {};
  pl.travel_setting.pay_travel_distance = TRAVEL_SETTINGS.pay_travel_distance;
  if (pl.travel_setting.pay_travel_distance) {
    pl.travel_setting.maximum_km = TRAVEL_SETTINGS.maximum_km;
    pl.travel_setting.minimum_travel_distance_fee = TRAVEL_SETTINGS.minimum_travel_distance_fee;
    pl.travel_distance_fees = objToArr(TRAVEL_SETTINGS.travel_distance_fees, 'translator_level_id');
  }

  pl.travel_setting.pay_travel_time = TRAVEL_SETTINGS.pay_travel_time;
  if (pl.travel_setting.pay_travel_time) {
    pl.travel_setting.travel_time_chunk_hours = TRAVEL_SETTINGS.travel_time_chunk_hours;
    pl.travel_time_fees = objToArr(TRAVEL_SETTINGS.travel_time_fees, 'translator_level_id');
  }

  pl.travel_setting.pay_fixed_time = TRAVEL_SETTINGS.pay_fixed_time;
  if (pl.travel_setting.pay_fixed_time) {
    pl.travel_setting.fixed_time_hours = TRAVEL_SETTINGS.fixed_time_hours;
  }

  pl.travel_setting.pay_fixed_time = TRAVEL_SETTINGS.pay_fixed_time;
  if (pl.travel_setting.pay_fixed_time) {
    pl.travel_setting.fixed_time_hours = TRAVEL_SETTINGS.fixed_time_hours;
  }

  pl.travel_setting.round_travel_time = TRAVEL_SETTINGS.round_travel_time;
  if (pl.travel_setting.round_travel_time) {
    pl.travel_setting.rounding_minutes = TRAVEL_SETTINGS.rounding_minutes;
  }

  pl.travel_setting.unpaid_travel_hours = TRAVEL_SETTINGS.unpaid_travel_hours;
  pl.travel_setting.minimum_travel_hours = TRAVEL_SETTINGS.minimum_travel_hours;
  pl.travel_setting.maximum_travel_hours = TRAVEL_SETTINGS.maximum_travel_hours;

  pl.travel_setting.pay_inconvenience_travel_time = TRAVEL_SETTINGS.pay_inconvenience_travel_time;
  if (pl.travel_setting.pay_inconvenience_travel_time) {
    pl.inconvenience_travel_time_fees = objToArr(
      TRAVEL_SETTINGS.inconvenience_travel_time_fees, 'translator_level_id'
    );
  }

  pl.travel_setting.pay_same_town_travel_time = TRAVEL_SETTINGS.pay_same_town_travel_time;
  pl.travel_setting.specific_cities = TRAVEL_SETTINGS.specific_cities;

  // ----------------------------------------
  // ----- INCONVENIENCE CHARGE -----
  // ----------------------------------------
  pl.inconvenience_setting = {};
  pl.inconvenience_setting.start_time = INCONVENIENCE_CHARGE.start_time;
  pl.inconvenience_setting.end_time = INCONVENIENCE_CHARGE.end_time;
  pl.inconvenience_setting.is_flat_rate = INCONVENIENCE_CHARGE.is_flat_rate;
  pl.inconvenience_setting.flat_rate = INCONVENIENCE_CHARGE.flat_rate;

  pl.inconvenience_fees = fInconvenienceFees(INCONVENIENCE_CHARGE.inconvenience_fees);
  if (!EMERGENCY_BOOKING.different_type_immediate) {
    remove(pl.inconvenience_fees, (o) => o.booking_type === 'phone_immediate');
  }

  pl.holiday_fees = fInconvenienceFees(INCONVENIENCE_CHARGE.holiday_fees);
  if (!EMERGENCY_BOOKING.different_type_immediate) {
    remove(pl.holiday_fees, (o) => o.booking_type === 'phone_immediate');
  }

  pl.weekend_fees = fInconvenienceFees(INCONVENIENCE_CHARGE.weekend_fees);
  if (!EMERGENCY_BOOKING.different_type_immediate) {
    remove(pl.weekend_fees, (o) => o.booking_type === 'phone_immediate');
  }

  // ----------------------------------------
  // ----- LATE CANCELLATIONS -----
  // ----------------------------------------

  assignLateCancellationRules(pl, LATE_CANCELLATIONS.late_cancellation_rules);

  // Originally the two props below belongs to travel settings,
  // but categorized inside late cancellations

  pl.travel_setting.pay_late_cancellation_travel_time = LATE_CANCELLATIONS.pay_late_cancellation_travel_time;
  pl.travel_setting.late_cancellation_actual_travel_only = LATE_CANCELLATIONS.late_cancellation_actual_travel_only;

  // ----------------------------------------
  // ----- LANGUAGE SETTINGS -----
  // ----------------------------------------

  pl.languages = LANGUAGE_SETTINGS.languages;

  // ----------------------------------------
  // ----- TEXT TRANSLATION SETTINGS -----
  // ----------------------------------------

  pl.text_translation_fees = objToArr(TEXT_TRANSLATION.text_translation_fees, 'translator_level_id', {
    parseNumbers: true
  });

  // ----------------------------------------
  // ----- OTHER SETTINGS -----
  // ----------------------------------------

  pl.allowance_setting = OTHER_SETTINGS.allowance_setting;
  pl.allowance_fees = OTHER_SETTINGS.allowance_fees;

  pl.expense_setting = OTHER_SETTINGS.expense_setting;

  if (isInvoice) {
    pl.transparency_enabled = OTHER_SETTINGS.transparency_enabled;

    if (OTHER_SETTINGS.transparency_enabled) {
      pl.transparency_fields = OTHER_SETTINGS.transparency_fields;
    }
  }

  if (
    !_.isNil(OTHER_SETTINGS.translator_level_order)
    && !_.isEmpty(OTHER_SETTINGS.translator_level_order)
  ) {

    if (_.isNil(pl.translator_level_setting)) {
      pl.translator_level_setting = {};
    }

    pl.translator_level_setting.level_order = OTHER_SETTINGS.translator_level_order;
  }

  console.log('Settings payload preview: ', pl);

  state.settingsPayload = _.cloneDeep(pl);
} // End of export default function

function fCharges (obj) {
  let result = [];
  _.forOwn(obj, (tlvl_item, tlvl_index) => {

    _.forOwn(tlvl_item, (bt_item, bt_index) => {

      result.push(
        _.omit({
          booking_type: bt_index,
          translator_level_id: findTlvl(tlvl_index, 'code', 'id'),
          ...bt_item
        }, ['parts_fees'])
      );
    });
  });
  return result;
}

function fPartsFees (obj) {
  let result = [];
  _.forOwn(obj, (tlvl_item, tlvl_index) => {

    _.forOwn(tlvl_item, (bt_item, bt_index) => {

      if (!_.isEmpty(bt_item.parts_fees)) {
        _.forOwn(bt_item.parts_fees, (pf_value, pf_index) => {
          result.push({
            step: _.chain(pf_index).replace('_', '.').replace('x', '').value(),
            fee: pf_value,
            booking_type: bt_index,
            translator_level_id: findTlvl(tlvl_index, 'code', 'id')
          });
        });
      }
    });

  });
  return result;
}

function fInconvenienceFees (obj) {
  let result = [];
  _.forOwn(obj, (tlvl_item, tlvl_index) => {

    _.forOwn(tlvl_item, (bt_item, bt_index) => {

      result.push({
        booking_type: bt_index,
        translator_level_id: findTlvl(tlvl_index, 'code', 'id'),
        ...bt_item
      });
    });
  });
  return result;
}

function fTranslatorLevelFees (obj) {
  let result = [];
  _.forOwn(obj, (tlvl_item, tlvl_index) => {

    _.forOwn(tlvl_item, (bt_item, bt_index) => {

      result.push({
        booking_type: bt_index,
        translator_level_id: findTlvl(tlvl_index, 'code', 'id'),
        ...bt_item
      });
    });
  });
  return result;
}


/**
 * @param {object} dest
 * @param {object} form
 */
function assignLateCancellationRules (dest, form) {
  const arr = objToArr(form, 'booking_type');
  dest.late_cancellation_rules = [];

  _.forEach(arr, (o) => {
    if (!_.isNil(o.rule) && o.rule !== '') {
      let r = {};

      r.booking_type = o.booking_type;
      r.rule = o.rule;
      r.data = {};

      if (o.rule === 'hours_before') {
        r.data.hours = o.data.hours;

      } else if (o.rule === 'time_of_day') {
        r.data.offset_days = o.data.offset_days;
        r.data.time = o.data.time;
      }

      r.maximum_minutes_to_pay = o.maximum_minutes_to_pay;
      r.exclude_weekend = o.exclude_weekend;
      r.exclude_holiday = o.exclude_holiday;

      dest.late_cancellation_rules.push(r);
    }
  });
}

function objToArr (obj, key, options = {}) {
  options.parseNumbers = options.parseNumbers || false;

  let result = [];
  _.forOwn(obj, (value, index) => {
    let cn = {...value};

    if (String(key) === 'translator_level_id') {
      cn[key] = findTlvl(index, 'code', 'id');
    } else {
      cn[key] = index;
    }

    if (options.parseNumbers) {
      _.forOwn(cn, (j, k) => {
        if (isNumeric(j)) {
          const isInt = parseFloat(j) % 1 === 0;
          cn[k] = isInt ? parseInt(j) : parseFloat(j);

        } else {
          cn[k] = j;
        }
      });
    }

    result.push(cn);
  });
  return result;
}

/**
 * Helper method for finding and returning a Translator Level
 *
 * @param {*} find - value to be searched.
 * @param {string} via - key on where to look
 * @param {string} [key] - if provided, method will return the value of this key.
 *                         But if not, method will just return the whole found object.
 *
 * @return {*}
 */
function findTlvl (find, via, key = '') {
  let found = _.find(TLVL_OPTS, (x) => x[via] === find);

  if (!_.isNil(found)) {
    return key !== '' ? found[key] : found;

  } else {
    console.error('There is no Translator Level equivalent for:');
    console.error('FIND: ', find);
    console.error('VIA: ', via);
  }
}

/**
 * @param {*} value
 * @returns {boolean}
 */
function isNumeric (value) {
  return _.isNumber(value) || (!_.isEmpty(value) && !_.isNaN(parseFloat(value)));
}
