<template>
    <div class="app-list">
    <base-list-table
                :table-data="list"
                :extra-cols="tableCols"
                :hasDetailsCol="false"
                actions-col-width='130'
                :show-selection="false"
                >

            <template slot="actionsColumn" slot-scope="scope">
                 <el-button type="primary" icon="el-icon-refresh" size="mini" class="circle" title="Resend" @click="handleResendButton(scope.row)"></el-button>
                <el-button type="success" icon="el-icon-document" size="mini" class="circle" title="View" @click="handleViewButton(scope.row)"></el-button>

            </template>
        </base-list-table>

        <el-dialog title="Resend" :visible.sync="showResendForm" :append-to-body="true">
    <el-form :model="form">

        <el-form-item label="recipients" label-width="120px">
        <!-- <el-select v-model="form.emails" placeholder="Please add recipients"> -->
             <el-select
                v-model="form.recipients"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="Please add recipients">
                <el-option
                v-for="item in recipients"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>

        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="showResendForm = false">Cancel</el-button>
        <el-button type="primary" @click="handleResendConfirm">Confirm</el-button>
    </span>
    </el-dialog>
    </div>
</template>

<script>

import ColExpandTable from './ColExpandTable.vue'
import { mapActions } from 'vuex';
import moment from "moment";
export default {
    props:{
        list:{
            type:Array,
            default:[]
        }
    },
    components:{
        ColExpandTable
    },
    data(){
        const _this=this;
        return {

            tableElId: 'push-logs-list',
            tableCols: [
                    {prop:'id',label:_this.$t('id')},
                    {prop:'template_code',label:_this.$t('template_code')},
                    {prop:'recipients',label:_this.$t('recipients'),
                     mutator(v){
                            return v.join(', ')
                      }
                    },
                    {prop:'status',label:_this.$t('status')},
                    {
                      rop:'created_at',
                      label:_this.$t('created_at'),
                      mutator(v) {
                        let date = moment(v)
                        return date.tz('Europe/Stockholm"').format('YYYY-MM-DD HH:mm:ss')
                      }
                    },
                ],

            hiddenCols: [],
            showResendForm: false,
            form:{
                recipients:[],
                id:''
            },
            recipients:[]
        }
    },
    methods: {

         isColEnabled(key) {
                let r = true;

                if (_.includes(this.hiddenCols, key)) {
                    r = false;
                }

                return r;

            }, // End of isColEnabled() method
        handleResendButton(row){
        // this.$rrouter.push({ name: 'email.debug', params: { id: row.id } })
        this.form.recipients = row.recipients
        this.form.id = row.id
        this.recipients = row.recipients
        this.showResendForm = true

        },
        handleViewButton(row){
            this.$router.push({ name: 'fax.debug', params: { id: row.id } })
        },
        handleResendConfirm(){
            let form = {
                id: this.form.id,
                recipients: this.form.recipients.join(',')
            }
            this.$store.dispatch('logs/postResend',form)
            this.showResendForm = false
            this.$alert('Resend Notification is processed', 'processed', {
                confirmButtonText: 'OK',
                callback: action => {
                    this.$message({
                    type: 'info',
                    message: `action: ${ action }`
                    });
                }
            });
        }

    },
}
</script>

<style>

</style>
