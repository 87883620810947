/*
|--------------------------------------------------------------------------
| Store > Department > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Department Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {gep} from "../../../helpers/Vuex";

let target = 'departments';
let apiPrefix = window.app_api_prefix_3;

let composeProps = {

    // Main props
    main: {
        company_id: '',
        id: '',
        name: '',
        email: '',
        head_user_id: '',
        cost_place: '',
        reference_person: '',
        is_protected_identity:0,
        is_sensitive_info:0,
        confidential_message: '',
        blacklisted_users: [],
        invoicing_department_id: '',
        company: {
            municipality: {
                name: '',
                id: ''
            }
        },
        number_settings_status: 'none',
        number_settings_number: '',
        notes: '',
        email_setting_status: 'inherit',
        email_setting_enabled: false,
        send_email_before_due: false,
    },

    // Contact props
    contact: {
        phone: '',
        mobile: '',
    },

    // Address props
    address: {
        address: '',
        address_2: '',
        city: '',
        town_name: '',
        post_code: '',
        country_iso: '',
        office_area: '',
    },

    // Sector Settings Props
    sector_settings: {
        gln: '',
        organization_number: '',
        // cost_place: '',
        additional_info: '',
        email:'',
        head_user_id:'',
        payment_terms: '30',
        // reference_person: '',
        invoice_type: 'inherit',
        invoice_verification: 2, // inherit,
        salary_verification: 2, // inherit
        allow_deluxe: false,
        disallow_users_view_bookings:false
    },

    blacklisted_users: []

};

export default {

    model_info: {
        name: 'Department Store',
        module: 'department',
        table: target,
    },

    api: {
        browse: {method: 'get', path: gep(target, apiPrefix), params: {per_page: 10, include: 'customers'}},
        browseAsOptions: {params: {'fields[departments]':'id,name,company_id', sort: 'name'}},
        read: {method: 'get', path: gep(target + '/{id}', apiPrefix), params: {include: 'company.municipality,blacklisted_users'}, data: {},},
        edit: {method: 'put', path: gep(target + '/{id}', apiPrefix), params: {}, data: {},},
        add: {method: 'post', path: gep(target, apiPrefix), params: {}, data: {},},
        delete: {method: 'delete', path: gep(target + '/{id}', apiPrefix), params: {}, data: {},},
    },

    composeFresh: _.cloneDeep(composeProps),
    compose: _.cloneDeep(composeProps),

    payloadData: {}, // Container for payload data.

    autoInjectPayload: true,
    autoReflectItem: true,
    autoUpdateList: true,

} // End of export default
