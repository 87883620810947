// store > booking > batches > mutations
'use strict';


import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Mutation for setting the batches list data.
 *
 * @param {Object} state - Vuex context state.
 * @param {Object}  payload - Necessary Values.
 * @param {Object[]}  payload.data - Batch entries collection.
 * @param {Object}  payload.meta - Pagination object container.
 * @param {string} payload.mode - Mode whether to set/append the data into current list.
 * @param {string} stateRef - State Reference on where to assign the values.
 * @param {Object} options - Container for option values.
 * @param {boolean} options.no_grouping - Boolean to determine force "no grouping" of the batches.
 */
function setBatchesHelper (state, payload, stateRef, options = {}) {
  const entries = payload.data.batch_entries;
  const mode = payload.mode;
  const noGrouping = options.no_grouping || true;

  if (!(!isNil(entries) && !isEmpty(entries))) {
    console.warn('Batches Entries cannot be null or empty');
    return;
  }

  // Group Entries into Batches
  let batches = [];
  let lastBatchId = 0;

  forEach(entries, (entry) => {

    if (lastBatchId === entry.batch_id && !noGrouping) {
      // If the batch id of the current is the same as the last one processed,
      // and noGrouping flag is not true
      // Run this block...
      delete entry.batch;
      batches[batches.length - 1].entries.push(entry);

    } else {
      // If the batch id of the current is different than the last one processed,
      // or is force to "not to be grouped"
      // Run this block...
      const batch = entry.batch;
      delete entry.batch;

      batches.push({
        ...batch,
        entries: [entry]
      });
    }

    lastBatchId = entry.batch_id;
  });

  if (mode === 'set') {
    state[stateRef].fetched = entries.length;
    state[stateRef].list = batches;

  } else if (mode === 'append' && state[stateRef].list.length > 0) {
    state[stateRef].fetched += entries.length;

    forEach(batches, (batch) => {

      const lastIndex = state[stateRef].list.length - 1;
      if (state[stateRef].list[lastIndex].id === batch.id && !noGrouping) {
        // Check the last batch if it matches the current batch id
        // and noGrouping flag is not true.
        // If it does, append its entries.
        forEach(batch.entries, (entry) => {
          state[stateRef].list[lastIndex].entries.push(entry);
        });

      } else {
        // Else, just add the batch in the existing batch list.
        state[stateRef].list.push(batch);
      }
    });
  }

  if (!isNil(payload.meta) && !isNil(payload.meta.pagination)) {
    state[stateRef].total = parseInt(payload.meta.pagination.total);
    state[stateRef].end_of_data = state[stateRef].fetched === state[stateRef].total;
    state[stateRef].page = parseInt(state[stateRef].page) + 1;
  }
}

export default {

  setBooking (state, payload) {
    state.booking = payload.data;
  },

  /**
   * Mutation for setting the batches list data.
   *
   * @param {Object} state - Vuex context state.
   * @param {Object}  payload - Necessary Values.
   * @param {Object[]}  payload.data - Batch entries collection.
   * @param {Object}  payload.meta - Pagination object container.
   * @param {string} payload.mode - Mode whether to set/append the data into current list.
   */
  setBatches (state, payload) {
    setBatchesHelper(state, payload, 'batches');
  },

  /**
   * Mutation to set state.batches.loading.
   *
   * @param state
   * @param payload
   */
  setBatchesLoading (state, payload) {
    state.batches.loading = payload;
  },

  /**
   * Mutation to set state.batches.per_page
   *
   * @param state
   * @param payload
   */
  setBatchesPerPage (state, payload) {
    state.batches.per_page = payload;
  },
  /**
   * Mutation to set state.batches.per_page
   *
   * @param state
   * @param payload
   */
  setNonSendableBatchesPerPage(state, payload) {
    state.batchesNonSendable.per_page = payload;
  },

  /**
   * Mutation to reset state.batches to fresh state.
   *
   * @param state
   */
  resetBatches (state) {
    state.batches = cloneDeep(state.batchesFresh);
  },
  /**
   * Setting translator tries to accept list
   * @param {*} state
   * @param {*} payload
   */
  setTranslatorTriesToAcceptList (state, payload) {
    state.translatorTriesToAcceptList = payload.late_accept_attempts;
    console.log(state);
  },

  setBatchesNonSendable (state, payload) {
    setBatchesHelper(state, payload, 'batchesNonSendable');
  },

  /**
   * Mutation to reset state.batches to fresh state.
   *
   * @param state
   */
  resetBatchesNonSendable (state) {
    state.batchesNonSendable = cloneDeep(state.batchesNonSendableFresh);
  },
  setBatchesStatus (state, payload) {
    state.batchesStatus = payload;
  },


  /**
   * Mutation for setting the batches list data.
   *
   * @param {Object} state - Vuex context state.
   * @param {Object}  payload - Necessary Values.
   * @param {Object[]}  payload.data - Batch entries collection.
   * @param {Object}  payload.meta - Pagination object container.
   * @param {string} payload.mode - Mode whether to set/append the data into current list.
   */
  setBatchesScheduler (state, payload) {
    setBatchesHelper(state, payload, 'batchesScheduler');
  },

  /**
   * Mutation to set state.batches.loading.
   *
   * @param state
   * @param payload
   */
  setBatchesSchedulerLoading (state, payload) {
    state.batchesScheduler.loading = payload;
  },

  /**
   * Mutation to set state.batches.per_page
   *
   * @param state
   * @param payload
   */
  setBatchesSchedulerPerPage (state, payload) {
    state.batchesScheduler.per_page = payload;
  },

  /**
   * Mutation to reset state.batches to fresh state.
   *
   * @param state
   */
  resetBatchesScheduler (state) {
    state.batchesScheduler = cloneDeep(state.batchesSchedulerFresh);
  }

}
