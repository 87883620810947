<template>
    <ul class="sidebar-nav-set" :class="className">
        <template v-if="navItems.length > 0">
            <sidebar-nav-item
                    v-for="(item, key) in navItems"
                    :key="key"
                    :index="genIndex(item.path)"
                    :class-name="sd(item.className, '')"
                    :label="$t(sd(item.label, ''))"
                    :icon="sd(item.icon, '')"
                    :badge="sd(item.badge, '')"
                    :path="sd(item.path, '')"
                    :sub-items="sd(item.subItems, [])"
                    :isfa="sd(item.isfa, false)"/>

        </template>
        <template v-else>
            <li>Error: There are no nav items to render.</li>
        </template>
    </ul> <!-- /.sidebar-nav-set -->
</template>

<script>
    import {sd} from "../../../js/helpers/Common";

    export default {

        /*
        |--------------------------------------------------------------------------
        | component > props
        |--------------------------------------------------------------------------
        */
        props: {

            /**
             * The collection of nav-items to be rendered.
             */
            navItems: {
                type: Array,
                default: [],
            },

            /**
             * The css class name that will be assigned to
             * this certain element.
             */
            className: {
                type: String,
                default: '',
            },

        }, // End of component > props

        /*
        |--------------------------------------------------------------------------
        | component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            /**
             * Helper method to generate index value basing on the path.
             *
             * @param {string} path
             *   The string value that will be transformed.
             *
             * @return {string}
             */
            genIndex(path) {
                return _.snakeCase(path);
            }, // End of genIndex() method

            // ===== Map Common helper methods. =====
            sd: sd, // Helper for setting default values if value is empty.

        }, // End of component > methods

    } // End of export default
</script>
