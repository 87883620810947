 <template>
    <div>
          <canvas :id="chartId"></canvas>        
    </div>
 </template>
 <script>
    import {mapActions,mapGetters} from "vuex";
    import {populate} from "~/js/helpers/Vuex";

    import Chart from 'chart.js';
    import expiredChartData from '../data/chart-data-expired.js';
    import tableComponentsVue from './tableComponents.vue';
    export default {
        mounted() {
            let self = this;
            let token = localStorage.getItem("app_access_token");
            axios.get("https://api-gateway.digitaltolk.se/api/v3/bookings-statistics/expired?type="+this.typeOfGraph+"&from="+this.fromDateData+"&to="+this.toDateData,
            {
              headers:{
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
              },
            }
            ).then(resp => {
                self.data  = resp.data;
                  self.createChart(self.chartId, self.expiredChartData, self.dataExpiredFunc);
              }).catch(err => {     
            });
        },
        props: ["chart-id", "from-date", "to-date", "type"],
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                expiredChartData : expiredChartData,
                window,
                data : null
            };
        }, // End of Component > data
        computed : {
          typeOfGraph : function(){
              return this.type;
          },
          fromDateData :function(){
            return this.fromDate;
          },

          toDateData :function(){
            return this.toDate;
          },
          dataExpiredFunc: function(){
            return this.populateExpiredColors('#FF7185');
          },
          countLanguages: function (){
            var obj = {};
            for(var i = 0; i < this.data.length; i ++){
                obj[this.data[i].date] = this.data[i].count;
            }
           return obj;
          },
          countLabels: function (){
            var obj = {};
            for(var i = 0; i < this.data.length; i ++){

                obj[this.data[i].date] = this.data[i].total;
            }
           return obj;
          },
        },
        methods: {
            populateExpiredColors: function(colorCode){
              var colors  = [];
              var colorLabels = [];
              var listmydata = this.countLanguages;
              var checkdata = Object.values(listmydata);
              var listmydata1 = this.countLabels;
               var checkdata1 = Object.values(listmydata1); 
              for(var i = 0; i < this.data.length; i ++){
                  colors.push(colorCode);
                  if(this.typeOfGraph == 'month'){
                      colorLabels.push(moment(this.data[i].date).format('DD'));
                  }else{
                    colorLabels.push(this.data[i].date);
                  }
              }
          
              var dataExpired = {
                  labels: colorLabels,
                  datasets: [
                    {
                      // another line graph
                      label: 'Expired Bookings',
                      data: checkdata,
                      la : checkdata1,
                      backgroundColor: colors,
                      borderColor: colors,
                      borderWidth: 0
                    }
                  ]
                };
            return dataExpired;
          },
          createChart(chartId, chartData, expiredData) {
              const ctx = document.getElementById(chartId);
              const myChart = new Chart(ctx, {
                type: chartData.type,
                data: expiredData,
                options: chartData.options,
              });
          }
        }
    }
 </script>