<template>
    <div class="countdown-container">
        <el-dialog
        :append-to-body="true"
        :visible.sync="modalVisible" center>
        <div class="text-center">

        <h4 v-if="message != ''" v-html="message"></h4>
        </div>
        <el-row v-if="time > 0 && message == ''" class="text-center">
            <h3>
                {{ $t('trying_to_find_translator') }}
            </h3>
          <el-col :md="6" :offset="6">
              <div class="time-countdown">
                <span>{{time | toMinutes | split('m')}}</span>
                <br>
                minutes
              </div>
              
          </el-col>
          <el-col :md="6">
              <div class="time-countdown">
                        <span>{{time | toMinutes | split('s')}}</span>
                        <br>
                    seconds
              </div>
          </el-col>
        </el-row>
        
        
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
            booking: {
                required: true,
            },
            visible: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                time: 0,
                timeInterval: null,
                message: '',
                modalVisible: this.visible,
            }
        },
        watch: {
            visible: function (newVal) {
                this.modalVisible = newVal;
            },
        },
        filters:{
            toMinutes: function (value) {
                let seconds = null;
                let minutes = null;
                let hours = null;

                seconds =  Math.floor ((value/1000)%60);
                minutes =  Math.floor ((value/(1000*60))%60);
                hours =  Math.floor ((value/(1000*60*60))%24);

                hours = (hours < 10) ? "0" + hours : hours;
                minutes = (minutes < 10) ? "0" + minutes : minutes;
                seconds = (seconds < 10) ? "0" + seconds : seconds;

                if(hours == '00') {
                return minutes + ":" + seconds;
                }

                return hours + ":" + minutes + ":" + seconds;
            },
            split: function(value,format){
                let split = value.split(':');
                let minutes = split[0];
                let seconds = split[1];

                if (format == 'm') {
                    return minutes;
                }

                return seconds;
            }
        },
        beforeDestroy() {
            // clear interval
            clearInterval(this.timeInterval);
            this.timeInterval = null;

            // remove listener to BOOKING_ACCEPTED
            // emitter.off(BOOKING_ACCEPTED, this.onAcceptBooking)
        },
         mounted() {
            
            this.timeInterval = setInterval(() => {
                console.log('time interval')
                // let due = moment(this.booking.due);
                let due = 0;
                let now = moment();
                this.time = due.diff(now);

                if (this.time > 0) {
                    return null;
                }
                console.log(this.time)
                this.message = 'Tyvärr hade ingen tolk möjlighet att ta sig an uppdraget. Försök gärna boka en annan tid.';
                clearInterval(this.timeInterval);
                this.timeInterval = null;
            }, 1000);
         },
        methods:{
             onAcceptBooking(data) {
                clearInterval(this.timeInterval);
                this.timeInterval = null;
                console.log('Displaying data : ', data);

                this.message = 'En tolk har accepterat din förfrågan. Du dirigeras nu till mina bokningar där du hittar tolkens kontaktuppgifter.';

                setTimeout(() => window.location.href = '/bookings', 3000);
            }
        }
}
</script>

<style lang="scss" scoped>
.time-countdown {
  padding: 40px 20px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 4px solid #00C48D;
  
}

.time-countdown > span {
  font-size: 3em;
}
</style>
