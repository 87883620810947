<template>
  <div class="app-fields financial-additional-shared-fields">

    <!-- Name / Adjustment being done -->
    <el-form-item :id="genContainerId('name',formElId)"
                  :class="[getValidFieldClass('name')]"
                  :label="$t('tfv_fn_adjustment')" class="label-short">
      <el-input :id="genFieldId('name',formElId)"
                v-model="iValue.name"/>
    </el-form-item>

    <!-- Description / Explanation  -->
    <el-form-item :id="genContainerId('description',formElId)"
                  :class="[getValidFieldClass('description')]"
                  :label="$t('tfv_fn_description')" class="label-short">
      <el-input :id="genFieldId('description',formElId)"
                v-model="iValue.description"/>
    </el-form-item>

    <!-- Quantity / Amount -->
    <el-form-item :id="genContainerId('quantity',formElId)"
                  :label="$t('quantity')"
                  :class="[getValidFieldClass('quantity')]"
                  class="label-short">
      <el-input :id="genFieldId('quantity',formElId)"
                v-model="iValue.quantity" @change="autoFillTotal"/>
    </el-form-item>

    <!-- Per Unit -->
    <el-form-item :id="genContainerId('price',formElId)"
                  :label="$t('tfv_fn_per_unit')"
                  :class="[getValidFieldClass('price')]"
                  class="label-short">
      <el-input :id="genFieldId('price',formElId)"
                v-model="iValue.price" @change="autoFillTotal"/>
    </el-form-item>

    <!-- Type -->
    <el-form-item :id="genContainerId('type',formElId)"
                  :label="$t('tfv_fn_type')"
                  :class="[getValidFieldClass('type')]"
                  class="label-short">
      <el-select :id="genFieldId('type_selections',formElId)"
                 v-model="iValue.type" clearable filterable>
        <el-option
          v-for="(o) in typeOpts"
          :key="o.key"
          :value="o.key"
          :label="o.label"
        />
      </el-select>
    </el-form-item>

    <!-- subtotal -->
    <el-form-item :id="genContainerId('subtotal',formElId)"
                  :label="$t('tfv_fn_subtotal')"
                  :class="[getValidFieldClass('subtotal')]"
                  class="label-short">
      <el-input :id="genFieldId('subtotal',formElId)"
                v-model="iValue.subtotal"/>
    </el-form-item>

    <!-- Actions -->
    <el-form-item v-if="!iEditMode"
                  :id="genContainerId('add_btn',formElId)"
                  class="el-form-actions">
      <el-button :id="genFieldId('cancel_btn',formElId)"
                 type="default"
                 class="modal-size"
                 @click="handleClickCancel">
        {{$t('cancel')}}
      </el-button>
      <el-button :id="genFieldId('add_btn',formElId)"
                 type="primary"
                 class="modal-size"
                 @click="handleClickAdd">
        {{$t('add')}}
      </el-button>
    </el-form-item>


    <!-- Update and Reset Button -->
    <template v-else>
      <el-form-item :id="genContainerId('update_btn',formElId)" class="el-form-actions">
        <el-button :id="genFieldId('cancel_btn',formElId)"
                 type="default"
                 class="modal-size"
                 @click="handleClickCancel">
        {{$t('cancel')}}
      </el-button>
        <el-button :id="genFieldId('update_btn',formElId)"
                   type="primary"
                   class="modal-size"
                   @click="handleClickUpdate">
          {{$t('update')}}
        </el-button>
      </el-form-item>
    </template> <!-- v-else -->


  </div> <!-- /.app-form financial-additional-shared-fields -->
</template>

<script>
import {additional} from '~/js/store/financial/additional/shared/props';
import {mapGetters} from 'vuex';
import some from 'lodash/some';
import forOwn from 'lodash/forOwn';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

export default {

  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props: {

    formElId: {
      type: String,
      default: 'financial-additional-shared-fields'
    },

    value: {
      type: Object,
      default: () => _.cloneDeep(additional)
    },

    target: {
      type: String,
      default: ''
    },

    enableSalaryType: {
      type: Boolean,
      default: true
    },

    editMode: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }

  }, // End of Component > props

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data () {
    return {
      validFields: {}
    };
  }, // End of Component > data

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapGetters('financialOptions', [
      'invoiceItemTypeOpts',
      'salaryItemTypeOpts'
    ]),

    /**
     * Returns array of values for selection regarding "salary types"
     * @return {array}
     */
    typeOpts () {
      if (this.target === 'invoice') {
        return this.invoiceItemTypeOpts;
      } else if (this.target === 'salary') {
        return this.salaryItemTypeOpts;
      }
    },

      /**
       * Interface for the v-model of this component.
       */
      iValue: {
        get () { return this.value; },
        set (v) { this.$emit('input', v); },
      },

      /**
       * Interface for this.editMode of this component.
       */
      iEditMode: {
        get () { return this.editMode; },
        set (v) { this.$emit('update:editMode', v); },
      },
      iVisible: {
        get() {
            return this.visible
        },
        set(v) {
            this.$emit('update:visible', v);
        }
      },

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      /**
       * Handler when the Add button was clicked.
       *
       * @return {void}
       */
      handleClickAdd () {
        this.validFields = this.validateForm();
        if (!some(this.validFields, (f) => !f)) {
          this.$emit('add', this.target);
        }
      },

      /**
       * Handler when the Update/Save button was clicked during edit mode.
       *
       * @return {void}
       */
      handleClickUpdate () {
        this.validFields = this.validateForm();
        if (!some(this.validFields, (f) => !f)) {
          this.$emit('update', this.target);
        }
      },

      handleClickCancel () {
        this.iVisible = false;
      },
      applyItemValuesToForm () {
        if (!window._.isEmpty(this.item)) {
          window._.assign(this.iValue, this.item);
        }

        if (isNil(this.iValue.type)) {
          this.$set(this.iValue, 'type', '');
        }
      },
      autoFillTotal () {
        this.iValue.subtotal = this.iValue.price * this.iValue.quantity;
      },

      /**
       * @return {Object}
       */
      validateForm () {
        let required = [
          'name',
          'description',
          'quantity',
          'price',
          'type',
          'subtotal'
        ];

        let result = {};

        forOwn(this.iValue, (v, k) => {
          if (includes(required, k)) {
            result[k] = v !== '';
          }
        });

        return result;
      },

      /**
       * @param {string} k
       * @return {boolean}
       */
      isValidField (k) {
        if (
          !isNil(this.validFields)
          && !isEmpty(this.validFields)
          && !isNil(this.validFields[k])
          && this.validFields[k] !== ''
        ) {
          return this.validFields[k];
        } else {
          return true;
        }
      },

      /**
       * @param {string} k
       * @return {string}
       */
      getValidFieldClass (k) {
        return this.isValidField(k) ? '' : 'is-error';
      }

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted() {
      this.$store.dispatch('financialOptions/loadInvoiceItemTypeOpts');
      this.$store.dispatch('financialOptions/loadSalaryItemTypeOpts');
      this.applyItemValuesToForm();
    }, // End of Component > mounted

  } // End of export default
</script>
