<template>
    <div :class="getBem(blockCls)"
         class="app-container"
         v-loading="isLoadingList">

        <template v-if="isValidListData">
            <el-table :data="listData" :class="getBem(blockCls, 'table')">
                <el-table-column label="ID" prop="id" width="50px" />
                <el-table-column :label="$t('name')" prop="name" />

                <el-table-column :label="$t('actions')">
                    <template #default="{row}">
                        <el-button type="primary" class="extra-mini"
                                   @click="handleClickApply(row)">
                            {{ $t('tfv_actions_apply') }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <template v-else>
            <h3 class="issue-warn" style="width: 100%; text-align: center;">
                No Price Lists Loaded.
            </h3>
        </template>

        <el-pagination v-if="isValidListPagination"
                       layout="prev, pager, next"
                       :page-size="listPagination.per_page"
                       :total="listPagination.total"
                       :current-page="listPagination.current_page"
                       @current-change="changePage">
        </el-pagination>

    </div> <!-- Component DIV Wrapper -->
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import isEmpty from 'lodash/isEmpty';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      entityType: {
        type: String,
        default: '',
      },

      entityId: {
        type: String | Number,
        default: '',
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-settings-import-list',
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters('financialPriceList', {
        listData: 'listImportData',
        listPagination: 'listImportPagination',
        isLoadingList: 'isLoadingListImport',
      }),

      /**
       * Returns boolean to determine whether
       * the list data is valid to be displayed.
       *
       * @return {Boolean}
       */
      isValidListData () {
        return !window._.isEmpty(this.listData);
      },

      /**
       * Returns boolean whether if the list pagination is valid.
       *
       * @returns {Boolean}
       */
      isValidListPagination () {
        return !isEmpty(this.listPagination)
          && this.listPagination.per_page < this.listPagination.total;
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions('financialPriceList', {
        loadList: 'browseForImport',
      }),

      /**
       * Handler for list page change event.
       *
       * @param {Integer} page
       * @returns {void}
       */
      changePage (page) {
        this.reloadList({page});
      },

      /**
       * Handler for reloading/repopulating the list.
       *
       * @param {Object} payload - contains options necessary for the API call.
       * @returns {void}
       */
      reloadList (payload = {}) {

        payload.page = payload.page || 1;
        payload.entity_type = this.entityType;
        payload.entity_id = this.entityId;

        if (payload.entity_type !== '' && payload.entity_id !== '') {
          this.loadList(payload);
        }
      },

      /**
       * Handler when the apply button was clicked.
       *
       * @param {Object} item - The targeted price list item.
       * @returns {void}
       */
      handleClickApply (item) {
        this.$emit('apply', item);
      },

    },
  }
</script>
