 <template>
    <div>
          <canvas :id="chartId"></canvas>        
    </div>
 </template>
 <script>
    import Chart from 'chart.js';
    import multiBarChartData from '../data/chart-data.js';

  
    export default {
        mounted() {
            this.multiBarChartFunction('#60E3A1','#56d9fe','#A3A1FB');
        },
        props: ["chart-id", "type"],
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                multiBarChartData : multiBarChartData,
            };
        }, // End of Component > data
        computed: {
            fromDateData : function(){
                return this.fromDate;
            },
            toDateData : function(){
                return this.toDate;
            },
            returnType :function(){
                return this.type;
            }
        },
        methods: {

            multiBarChartFunction: function(ColorOne, ColorSec, ColorThird){
            let self = this;
            let d1 = [];
            let d2 = [];
            let d3 = [];
            let c1 = [];
            let c2 = [];
            let c3 = [];
            let sum = [];
            let labels = [];
            let labels1  = [];
            let token = localStorage.getItem("app_access_token");
            axios.get("https://api-gateway.digitaltolk.se/api/v3/bookings-statistics/user-or-type?type="+this.returnType,  
                {
                    headers:
                    {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then(resp => {
                    labels = [];
                    labels1 = [];
                    for(var i = 0; i < resp.data.length; i++)
                    {
                        d1.push(resp.data[i].data.customer);
                        d2.push(resp.data[i].data.admin);
                        if(this.returnType == "booking_user_types_last_two_years")
                        {     
                            labels.push(resp.data[i].date);
                            labels1.push(resp.data[i].data.admin + resp.data[i].data.customer);
                        }else{
                            if(this.returnType == 'booking_user_types_this_month'){
                                localStorage.setItem('customer_bookings', resp.data[i].data.customer);
                                localStorage.setItem('admin_bookings', resp.data[i].data.admin);
                                localStorage.setItem('bookings_all', resp.data[i].data.admin + resp.data[i].data.customer);
                                
                            }
                             labels.push(moment(resp.data[i].date).format('DD'));
                        }
                        c1.push(ColorOne);
                        c2.push(ColorSec); 
                        c3.push(ColorThird);
                        sum.push(resp.data[i].data.admin + resp.data[i].data.customer);
                    }
                      console.log("Data");
                        console.log(labels1.filter(val => val !== 0));
                        var multichartdata = {
                        labels: labels,
                        datasets: [
                            { // one line graph
                                label: 'Customer Bookings',
                                data: d1,
                                backgroundColor: c1,
                                borderColor: c1,
                                
                                borderWidth: 0
                            },
                            { // another line graph
                                label: 'Admin Bookings',
                                data: d2,
                            
                                backgroundColor: c2,
                                borderColor: c2,
                                sumValues : sum,
                                borderWidth: 0
                            }
                            ]
                        };
                        this.createChart(this.chartId, this.multiBarChartData, multichartdata);
                    //self.data  = resp.data;
                }).catch(err => {
                    console.log(err);
                });
               
        
            
             //  return multichartdata;
            },
            createChart(chartId, chartData, MultiData) {
                const ctx = document.getElementById(chartId);
                let bookings = 0;
                    
                const myChart = new Chart(ctx, {
                type: chartData.type,
                data: MultiData,
                options: chartData.options,
                plugins :[{
                    afterDatasetsDraw: function (context, easing) {
                      var ctx = context.chart.ctx;
                          
                      context.data.datasets.forEach(function (dataset) {   
                        for (var i = 0; i < dataset.data.length; i++) {
                          if(dataset.label == 'Admin Bookings'){
                            if (dataset.data[i] != 0) {
                                    var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                                    var textY = model.y + (dataset.type == "line" ? -3 : 15);
                                    ctx.font = Chart.helpers.fontString(15, 'normal', Chart.defaults.global.defaultFontFamily);
                                    ctx.textAlign = 'center';
                                    ctx.textBaseline = 'middle';
                                    ctx.fillStyle = dataset.type == "line" ? "black" : "black";
                                    ctx.save();
                                    ctx.translate(model.x, textY-25);
                                    ctx.fillText( dataset.sumValues[i], 0, 0);
                                    ctx.restore();
                              }
                          }
                        }
                      });
                    }
                }]
                });
            }
        }
    }
 </script>