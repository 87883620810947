'use strict';

export default {

  /**
   * Mutation to reset state.exportForm to its original state.
   *
   * @param state
   * @return {void}
   */
  resetExportForm (state) {
    state.exportForm = window._.cloneDeep(state.exportFormFresh);
  },

  /**
   * Mutation to set state.list object
   *
   * @param state
   * @param payload
   * @return {void}
   */
  setList (state, payload) {
    state.list.data = payload.data;
    if (!window._.isNil(payload.meta) && !window._.isNil(payload.meta.pagination)) {
      state.list.pagination = payload.meta.pagination;
    }
  },

  /**
   * Mutation to set state.list.loading.
   *
   * @param state
   * @param payload
   * @return {void}
   */
  setListLoading (state, payload) {
    state.list.loading = payload;
  },

  /**
   * Mutation to set state.item.data.
   *
   * @param state
   * @param payload
   * @return {void}
   */
  setItemData (state, payload) {
    state.item.data = payload;
  },

  /**
   * Mutation to set state.list.loading.
   *
   * @param state
   * @param payload
   * @return {void}
   */
  setItemLoading (state, payload) {
    state.item.loading = payload;
  },

  /**
   * Mutation to set state.progress.invoice.
   *
   * @param state
   * @param payload
   * @return {void}
   */
  setProgressInvoice (state, payload) {
    state.progress.invoice.calculated = payload.progress.calculated;
    state.progress.invoice.populated = payload.progress.populated;
    state.progress.invoice.uncalculated = payload.uncalculated;
    state.progress.invoice.unpopulated = payload.unpopulated;
  },

  /**
   * Mutation to reset state.progress.invoice into original state.
   *
   * @param state
   * @param payload
   * @return {void}
   */
  resetProgressInvoice (state, payload) {
    state.progress.invoice = {
      calculated: null,
      populated: null,
      uncalculated: null,
      unpopulated: null
    };
  },

  /**
   * Mutation to set state.progress.salary.
   *
   * @param state
   * @param payload
   * @return {void}
   */
  setProgressSalary (state, payload) {
    state.progress.salary.calculated = payload.progress.calculated;
    state.progress.salary.populated = payload.progress.populated;
    state.progress.salary.uncalculated = payload.uncalculated;
    state.progress.salary.unpopulated = payload.unpopulated;
  },

  /**
   * Mutation to reset state.progress.salary into original state.
   *
   * @param state
   * @param payload
   * @return {void}
   */
  resetProgressSalary (state, payload) {
    state.progress.salary = {
      calculated: null,
      populated: null,
      uncalculated: null,
      unpopulated: null
    };
  },

  /**
   * Mutation to set state.progress.salary.
   *
   * @param state
   * @param payload
   * @return {void}
   */
  setProgressFinancialList (state, payload) {
    state.progress.financial_list.checked = payload.progress.checked;
    state.progress.financial_list.unchecked = payload.unchecked;
  },

  /**
   * Mutation to reset state.progress.salary into original state.
   *
   * @param state
   * @param payload
   * @return {void}
   */
  resetProgressFinancialList (state, payload) {
    state.progress.financial_list = {
      checked: null
    };
  },

  /**
   * Mutation to set state.uncalculated.invoice.
   *
   * @param state
   * @param payload
   * @return {void}
   */
  setUncalculatedInvoice (state, payload) {
    state.uncalculated.invoice = payload;
  },

  /**
   * Mutation to reset state.uncalculated.invoice into original state.
   *
   * @param state
   * @param payload
   * @return {void}
   */
  resetUncalculatedInvoice (state, payload) {
    state.uncalculated.invoice = [];
  },

  /**
   * Mutation to set state.uncalculated.salary.
   *
   * @param state
   * @param payload
   * @return {void}
   */
  setUncalculatedSalary (state, payload) {
    state.uncalculated.salary = payload;
  },

  /**
   * Mutation to reset state.uncalculated.salary into original state.
   *
   * @param state
   * @param payload
   * @return {void}
   */
  resetUncalculatedSalary (state, payload) {
    state.uncalculated.salary = [];
  }

}
