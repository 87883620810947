<template>
<div class="app-page blogs-page">
  <page-header :title="$t('tfv_add_translator_unavailable')" />

  <div class="app-container">
    <div class="app-wrapper">
      <div class="app-row">
        <div class="app-col full-width">
    <el-form :model="unavailableObject" ref="unavailableObject" :validate-on-rule-change="true" :rules="rules">
        <el-form-item
            :label="$t('description')" class="label-short" prop="description">
            <el-input
             style="width:47%;"
             v-model="unavailableObject.description">
            </el-input>
        </el-form-item>
        <el-form-item
            :label="$t('tfv_unavailable_bookings_type')" class="label-short" prop="booking_types">
            <el-select
                style="width:47%;"
                v-model="unavailableObject.booking_types" multiple>
                <el-option :label="$t('all')" value="all"/>
                <el-option :label="$t('tfv_unavailable_phone')" value="phone"/>
                <el-option :label="$t('tfv_unavailable_physical')" value="physical"/>
                <el-option :label="$t('tfv_unavailable_video')" value="video"/>
                <el-option :label="$t('tfv_unavailable_video_skype')" value="video_skype"/>
                <el-option :label="$t('tfv_unavailable_video_physical')" value="video_physical"/>
                <el-option :label="$t('tfv_unavailable_convey')" value="convey"/>
            </el-select>
        </el-form-item>
        <el-form-item
            :label="$t('address')" class="label-short">
            <google-maps-address style="width:47%;"
                                 v-model="unavailableObject.address"/>
        </el-form-item>
        <el-form-item
          :label="$t('unavailable_from')" class="label-short" prop="unavailable_from">
          <el-date-picker
            style="width:47%;"
            v-model="unavailableObject.unavailable_from"
            type="datetime"
            format="dd-MM-yyyy HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            popper-class="day-picker-only"
            size="mini"/>
        </el-form-item>
        <el-form-item
          :label="$t('unavailable_until')" class="label-short" prop="unavailable_until">
          <el-date-picker
            style="width:47%;"
            v-model="unavailableObject.unavailable_until"
            type="datetime"
            format="dd-MM-yyyy HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            popper-class="day-picker-only"
            size="mini"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('unavailableObject')">
            {{ $t(breadMode) }}
          </el-button>
        </el-form-item>
    </el-form>
    </div>
  </div>
</div>
</div>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import {isEmpty,sd} from "~/js/helpers/Common";
import { checkBreadMode } from "~/js/helpers/Route";
import Vue from 'vue';
export default {
  name:'userBookingListPanel',
  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props:{
    title:{
      type:String,
      default: 'Translator unavailable times'
    },
    refs:{
      default:'unavailable'
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data(){
    var checkTo = (rule, value, callback) => {
       let start = window.moment(this.$refs.unavailableObject.model.unavailable_from,'YYYY-MM-DD HH:mm:ss')
       let end = window.moment(this.$refs.unavailableObject.model.unavailable_until,'YYYY-MM-DD HH:mm:ss')
        if(value === ''){
           return callback(new Error('Please input unavailable until'));
        }else if(end.isSameOrBefore(start) ){
           return callback(new Error('Please input from time value before than until time'));
        }else{
            callback()
        }
    }
    const _this = this;
    return {
      unavailableObject:{
        translator_id:'',
        description:'',
        unavailable_from:'',
        unavailable_until:'',
        booking_types:[]
      },
      rules: {
          description: [{
              required: true,
              message: 'Please enter a description'
          }],
          booking_types: [{
              required: true,
              message: 'Please enter a booking type'
          }],
          unavailable_from: [{
              required: true,
              message: 'Please add Due time'
          }],
          unavailable_until: [{
              required: true,validator: checkTo, trigger: 'blur'
          }],
      },
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed:{

    iTitle(){
      return this.title
    },
    iRefs(){
      return this.refs
    },
    breadMode() {
      return checkBreadMode(this);
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods:{
    ...mapActions('translatorUnavailableTimes',{
      createUnavailable:'createUnavailable',
      readEntry:'getUnavailable',
      editEntry:'editUnavailable',
   }),

   addUnavailable(){
      if(this.breadMode === 'add') {
         this.unavailableObject.translator_id=this.$route.params.id;
         this.createUnavailable(this.unavailableObject).then(() => {
            this.$router.push({path: '/user/profile/'+this.$route.params.id+'?type=3#unavailable'});
         })
         .catch((e) => {
          
        });
      }else{
          this.editEntry(this.unavailableObject).then(() => {
            this.$router.push({path: '/user/profile/'+this.$route.params.id+'?type=3#unavailable'});
         })
         .catch((e) => {
          
        });
      }
   },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.addUnavailable();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {
    if (this.breadMode === "edit") {
       console.log("Edit mode");
       this.readEntry({id: this.$route.params.id}).then(r => {
          let data=r.data.data.translator_unvailable_time;
          this.unavailableObject = data;
          let booking_types=[];
          for(let key of data.translator_unvailable_booking_types) {
             booking_types.push(key.booking_type);
          }
          Vue.set(this.unavailableObject, 'booking_types', booking_types);
       });
    }
  } // End of Component > mounted
}
</script>
