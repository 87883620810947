<template>
  <nav :class="getBem(blockClass)">

    <div :class="getBem(blockClass, 'toggle-button')">
      <a href="#"
         class="app-display-flex app-flex-center"
         @click.prevent="handleClickToggleSidebar">
        <i :class="toggleIcon"></i>
      </a>
    </div>

    <div :class="getBem(blockClass, 'wrapper')">

      <div :class="getBem(blockClass, 'content-body')" data-simplebar>
        <div class="sidebar-body-nav-items-container">
          <sidebar-nav-set :nav-items="navData.navItemsFinal" />
        </div>
      </div>

    </div>
  </nav>
</template>

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      expiring: {
        type: String,
        default: `0`
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockClass: 'sidebar-nav'
      };
    },

    /*
    |--------------------------------------------------------------------------
    | component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters({
        expiringBookings: 'dashboard/counts',
        userName: 'auth/userName',
        userEmail: 'auth/userEmail',
        roles: 'auth/userRoles',
      }),

      ...mapGetters('sidebar', ['isFoldSidebar']),

      ...mapState('adminPermissions', {
        navData: 'navData',
      }),

      iExpiring: {
        get () {
          return this.expiring
        },
        set (v) {
          this.expiring = v
        }
      },

      /**
       * @returns {string}
       */
      toggleIcon () {
        let icon = 'fa fa-angle-';
        return this.isFoldSidebar
            ? icon + 'right'
            : icon + 'left';
      }
    },

    /*
    |--------------------------------------------------------------------------
    | component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapMutations('sidebar', ['setIsFoldSidebar']),
      ...mapActions('adminPermissions', ['getNavItemsFinal']),

      /**
       * @returns {void}
       */
      handleClickToggleSidebar () {
        this.setIsFoldSidebar(!this.isFoldSidebar);
      }
    },

    /*
    |--------------------------------------------------------------------------
    | component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      // this.iExpiring = `3`
      this.getNavItemsFinal().then(r => {
        console.log(r);
      });
    },


  } // End of export default
</script>
