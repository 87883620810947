/*
|--------------------------------------------------------------------------
| store > user > admin > actions
|--------------------------------------------------------------------------
|
| This file contains the actions property of current Vuex scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import APICaller from '~/js/helpers/APICaller';
import {Notification} from "element-ui";
import API from '~/js/constants/api';
import {gep} from "~/js/helpers/Vuex";
let apiPrefix = 'v3';

export default {

  readTrashedUsers(context, payload){
    let params = {}
    params['filter[type]'] = '1'
    params = Object.assign(params,payload)

    return APICaller({
        endpoint:API.TRASHED_USERS,
        method:'get',
        params : params
    }).then((response) => {
        context.commit('setTrashedUsers', response.data)
        return response
      }
    ).catch( (e) => {
        console.log(e)
        return e
    })
  },
  restoreTrashedUsers(context, payload){
    return APICaller({
        endpoint: `${API.RESTORE_TRASHED_USERS}/${payload.id}`,
        method:'post'
    }).then((response) => {

      Notification.success({
        title: 'Success',
        message: 'Admin user is restored.',
      });

      return response
    }).catch( (e) => {
        console.log(e)
        return e
    })
  }

}
