<template>
  <div style="height: 100vh;
  overflow: auto;">
    <iframe :src="url" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  data(){
    return {
      url:''
    }
  },
  mounted(){
    let token = localStorage.getItem("app_access_token");

    // this.url = `${app_dt_notifier_url}/debug/${this.$route.params.id}`
    this.url = `${app_dt_notifier_url}/preview/${this.$route.params.id}?access_token=${token}`
  }
}
</script>


<style lang="scss" scoped>
iframe{
  border: 0; position:fixed; top:0; left:0; right:0; bottom:0; width:100%; height:100vh;
}
</style>

