<template>
    <div class="table-component list-base-bread">

        <!-- List Table -->
        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :details-col-width="detailsColWidth"
                :actions-col-width="actionsColWidth">

            <!-- Define the top controls -->
            <template slot="topControls">

                <template v-if="enableTopControlsDefault">
                    <!-- Create Button -->
                    <div class="create-btn-container">
                        <el-button
                                size="small"
                                type="primary"
                                class="bread-create-btn"
                                @click="handleActivity('add')">
                            {{ $t('add_item') }}
                        </el-button>
                    </div> <!-- /.create-btn-container -->
                </template>
                <template v-else>
                    <slot name="topControls"
                          :$index="scope.$index"
                          :row="scope.row"/>
                </template>

            </template> <!-- eo topControls slot -->

            <!-- Define contents inside Details Column -->
            <template slot="detailsColumn" slot-scope="scope">

                <template v-if="enableDetailsDefault">
                    <!-- Details Button -->
                    <el-button
                            size="small"
                            type="default"
                            class="table-list-btn bread-details-btn"
                            :id="scope.row.id+'_details_btn'"
                            @click="handleActivity('details',scope.$index, scope.row)">
                        {{ $t('details') }}
                    </el-button>
                </template>
                <template v-else>
                    <slot name="detailsColumn"
                          :$index="scope.$index"
                          :row="scope.row"/>
                </template>

            </template> <!-- eo detailsColumn slot -->

            <!-- Define contents inside Actions Column -->
            <template slot="actionsColumn" slot-scope="scope">

                <template v-if="enableActionsDefault">

                    <!-- Edit Button -->
                    <el-button
                            size="small"
                            type="primary"
                            class="table-list-btn bread-edit-btn"
                            :id="scope.row.id+'_edit_btn'"
                            @click="handleActivity('edit', scope.$index, scope.row)">
                        {{ $t('edit') }}
                    </el-button>

                    <!-- Delete Button -->
                    <el-button
                            size="small"
                            type="primary"
                            class="table-list-btn bread-delete-btn"
                            :id="scope.row.id+'_delete_btn'"
                            @click="handleActivity('delete' ,scope.$index, scope.row)">
                        {{ $t('delete') }}
                    </el-button>

                </template>
                <template v-else>
                    <slot name="actionsColumn"
                          :$index="scope.$index"
                          :row="scope.row"/>
                </template>

            </template> <!-- eco actionsColumn slot -->
        </base-list-table>

        <!-- Pagination -->
        <el-pagination
                v-if="listPagination.per_page < listPagination.total"
                layout="prev, pager, next"
                :page-size="listPagination.per_page"
                :total="listPagination.total"
                :current-page="listPagination.current_page"
                @current-change="handleReload">
        </el-pagination>

        <!-- Details Modal -->
        <base-details-modal
                v-if="enableDetailsDefault"
                :visible.sync="detailsVisible"
                custom-class="list-base-bread-modal"
                :extra-rows="detailsRows"
                :info="detailsInfo">
        </base-details-modal>

        <!-- Compose Modal -->
        <el-dialog
                :title="composeTitle"
                :visible.sync="composeVisible"
                :width="width"
                :top="top">

            <slot name="composeForm" :mode="composeMode" :item="itemInfo"/>

            <span slot="footer" class="dialog-footer">
                <el-button
                        class="compose-cancel-btn"
                        @click="composeVisible = false">
                    {{ $t('cancel') }}
                </el-button>
                <el-button
                        class="compose-confirm-btn"
                        @click="handleCompose(composeMode, itemInfo)">
                    {{ $t('confirm') }}
                </el-button>
            </span>
        </el-dialog>

    </div> <!-- /.list-base-bread -->
</template>

<script>
    import {isEmpty, mapProps} from "../../../js/helpers/Common";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            width: {
                type: String,
                default: '30%',
            },

            top: {
                type: String,
                default: '5%',
            },

            list: {
                type: Object,
                default: () => {
                    return {};
                }
            },

            listCols: {
                type: Array,
                default: () => {
                    return [];
                }
            },

            detailsRows: {
                type: Array,
                default: () => {
                    return [];
                }
            },

            detailsColWidth: {
                type: String,
                default: '',
            },

            actionsColWidth: {
                type: String,
                default: '',
            },

            enableDetailsDefault: {
                type: Boolean,
                default: true,
            },

            enableActionsDefault: {
                type: Boolean,
                default: true,
            },

            enableTopControlsDefault: {
                type: Boolean,
                default: true,
            },

            composeForm: {
                type: Object,
                default: () => {
                    return {};
                }
            },

            api: {
                type: Object,
                default: () => {
                    return {};
                }
            },

            methodAdd: {default: false,},
            methodEdit: {default: false,},
            methodDelete: {default: false,},
            methodReload: {default: false,},

            overrideAddData: {default: false,},
            overrideEditData: {default: false,},

        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {

                composeTitle: '',
                composeMode: '',
                composeVisible: false,
                composeFresh: {},

                // Item Info Container
                itemInfo: {},

                // Details Modal vars
                detailsVisible: false,
                detailsInfo: {},

            };
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            /**
             * This is an interface for the (this.)list property. This method also
             * requires the use of this.api.get_browse strictly.
             *
             */
            iList() {

                if ( !isEmpty(this.list) ) {

                    // If this.list is not empty, then return it.
                    return this.list;

                } else {

                    // Else if this.list is empty, check if the api for its getter exists.
                    if ( !isEmpty(this.api.get_browse) ) {
                        return this.$store.getters[this.api.get_browse];

                    } else {
                        throw new Error('There is no list data or api getter reference provided.');
                    }
                }
            }, // End of list() method

            /**
             * Returns the list's data
             *
             * @return {Array}
             */
            listData() {
                if ( !isEmpty(this.iList) ) {
                    return this.iList.data;
                } else {
                    return [];
                }
            }, // End of End of listData() method

            /**
             * Returns the list's pagination
             *
             * @return {Object}
             */
            listPagination() {
                if ( !isEmpty(this.iList) ) {
                    return this.iList.pagination;
                }
                else {
                    return {};
                }
            }, // End of listPagination() method

            /**
             * This is an interface for (this.)composeForm property.
             */
            iComposeForm: {
                get() {
                    return this.composeForm;
                },
                set(v) {
                    this.$emit('update:composeForm', v);
                }
            }

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {

            /**
             * Handles the activities of the current model.
             *
             * @param {string} mode
             *   Varies between add or edit, just to distinguish the activity
             *
             * @param {int|string} [rowIndex]
             *   The value of the current row index targeted in the table.
             *   This value is optional.
             *
             * @param {object} [rowItem]
             *   The value (as an object) of the current row item displayed in the row.
             *   This value is optional.
             *
             * @return {void}
             */
            handleActivity(mode, rowIndex, rowItem) {

                this.itemInfo = rowItem;

                switch (mode) {
                    case 'details':
                        this.detailsVisible = true;
                        this.detailsInfo = rowItem;
                        break;

                    case 'add':
                        this.composeMode = 'add';
                        this.composeTitle = this.$t('add_item');
                        this.iComposeForm = _.cloneDeep(this.composeFresh); // Reset form.
                        this.composeVisible = true;
                        break;

                    case 'edit':
                        this.composeMode = 'edit';
                        this.composeTitle = this.$t('edit');
                        this.iComposeForm = _.cloneDeep(this.composeFresh); // Reset form, and then map defaults
                        this.iComposeForm = mapProps(rowItem.attributes, this.iComposeForm, { byRef:false });
                        this.composeVisible = true;
                        break;

                    case 'delete':
                        if ( this.methodDelete !== false ) {
                            this.methodDelete(rowItem);
                        } else {
                            this.handleDelete(rowItem);
                        }
                        break;

                    default:
                        throw new Error('No mode specified in the activity');
                }

            }, // End of handleActivity() method

            /**
             * Handles the confirmation of the compose form
             *
             * @param {string} mode
             *   The compose mode that varies between add or edit
             *
             * @param {object} [rowItem]
             *   The value (as an object) of the current row item displayed in the row.
             *   This value is optional.
             *
             * @return {void}
             */
            handleCompose(mode, rowItem) {

                let methodRef = _.camelCase(`method ${mode}`);
                let handleRef = _.camelCase(`handle ${mode}`);

                if ( this[methodRef] !== false ) {
                    this[methodRef](rowItem);
                } else {
                    this[handleRef](rowItem);
                }

            }, // End of handleCompose() method

            /**
             * Handles the creation of an item
             *
             * @return {void}
             */
            handleAdd() {

                // Store form values into data container.
                let data = _.cloneDeep(this.iComposeForm);

                // If overrideAddData exists, run it to change data.
                if ( this.overrideAddData !== false ) {
                    this.overrideAddData(data);
                }

                if ( !isEmpty(this.api.add) ) {

                    // If API reference exists, then...
                    // Reach out to API and pass the data.
                    this.$store.dispatch(this.api.add, { data })
                        .then(() => {
                            this.composeVisible = false;
                            this.handleReload();
                        });
                    
                } else {
                    // Else if API reference doesn't exist, throw an error
                    throw new Error('Missing API reference for ADD item.');
                }

            }, // End of handleAdd() method

            /**
             * Handles the update activity of an item
             *
             * @param {Object} item
             *   The row object item that is currently targeted
             *
             * @return {void}
             */
            handleEdit(item) {

                // Store form values into data container.
                let data = _.cloneDeep(this.iComposeForm);

                // Add the ID to the data
                data.id = item.id;

                // If overrideEditData exists, run it to change data.
                if ( this.overrideEditData !== false ) {
                    this.overrideEditData(data);
                }

                if ( !isEmpty(this.api.edit) ) {

                    // If API reference exists, then...
                    // Reach out to API and pass the data.
                    this.$store.dispatch(this.api.edit, { data })
                        .then(() => {
                            this.composeVisible = false;
                            this.handleReload();
                        });

                } else {
                    // Else if API reference doesn't exist, throw an error
                    throw new Error('Missing API reference for EDIT item.');
                }

            }, // End of handleEdit() method

            /**
             * Handles the deletion/removal of an item
             *
             * @param {Object} item
             *   The row object item that is currently targeted
             *
             * @return {void}
             */
            handleDelete(item) {

                // Check if API reference exists, and then run this block...
                if ( !isEmpty(this.api.delete) ) {

                    // Show a confirmation popup before continuing...
                    this.$confirm(this.$t('n_item_q_delete_w_id', [item.id]), '', {
                        customClass: 'delete-confirmation-modal',
                        confirmButtonText: this.$t('confirm'),
                        cancelButtonText: this.$t('cancel'),
                        type: 'warning'
                    }).then(() => {

                        //
                        this.$store.dispatch(this.api.delete, {id: item.id})
                            .then(() => {
                                this.handleReload();
                            })
                            .catch(() => {
                            });
                    });

                } else {
                    // Else if API reference doesn't exist, throw an error
                    throw new Error('Missing API reference for DELETE item.');
                }

            }, // End of handleDelete() method

            /**
             * Handler for reloading list/table data
             *
             * @param {int} [curPage]
             *   The current page of the pagination
             *
             * @return {void}
             */
            handleReload(curPage) {

                let page = isEmpty(curPage) ? 1 : curPage;

                if ( this.methodReload !== false ) {

                    // If this.methodReload is provided, run it.
                    this.methodReload(page);

                } else {

                    // Else... if this.methodReload doesn't exist...
                    // Check if listData has content, if it has content then use it.
                    if ( isEmpty(this.list) && !isEmpty(this.api.browse) ) {

                        // noinspection JSIgnoredPromiseFromCall
                        this.$store.dispatch(this.api.browse, {extendParams: {page}});

                    } else {
                        throw new Error('No list data and API for calling BROWSE/ALL');
                    }
                }

            }, // End of handleReload() method


        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component mounted
        |--------------------------------------------------------------------------
        */
        mounted: function () {

            // Load the data into the list
            this.handleReload();

            // Store the prestine state of composeForm
            this.composeFresh = _.cloneDeep(this.composeForm);

        }, // End of component > mounted

    } // End of export default
    
</script>
