import APICaller from "../../helpers/APICaller";

import { gep } from "~/js/helpers/Vuex";
import { Notification } from "element-ui";

import i18n from '../../i18n';
import { isEmpty } from "../../helpers/Common";
let apiPrefix = 'v3';
export default {
  getSurvey(context) {
    return APICaller({
      method: 'get',
      endpoint: gep('survey', apiPrefix)
    }).then(resp => {
      return resp;

    }).catch(err => {
      console.log(err)
    })
  },
  addQuestions({ commit }, payload) {
    console.log(payload)
    return APICaller({
      method: 'post',
      endpoint: gep(`survey/${payload[0].survey_id}/add-question`, apiPrefix),
      data: {
        'questions': payload
      },
      isDataRaw: true
    })
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((e) => {
        console.log(e)
        return e;
      })

  },
  deleteQuestion({ commit }, payload) {
    console.log(payload)
    return APICaller({
      method: 'delete',
      endpoint: gep(`survey-question/${payload}`, apiPrefix),
    })
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((e) => {
        console.log(e)
        return e;
      })

  },
  getSurveywithQuestions({ context }, payload) {
    let params = {
      include: ['survey_question', 'user']
    }
    return APICaller({
      method: 'get',
      params,
      endpoint: gep(`survey/${payload}`, apiPrefix)
    }).then(resp => {
      return resp.data.data.item;

    }).catch(err => {
      console.log(err)
    })
  },

  updateSurvey({ commit }, payload) {
    console.log(payload.id)
    return APICaller({
      method: 'put',
      endpoint: gep(`survey/${payload.id}`, apiPrefix),
      data: payload,
      isDataRaw: true
    })
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((e) => {
        console.log(e)
        return e;
      })

  },

  addRecipient({ commit }, payload) {
    console.log(payload.id)
    return APICaller({
      method: 'post',
      endpoint: gep(`survey-add-users/${payload.id}`, apiPrefix),
      data: payload,
      isDataRaw: true
    })
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((e) => {
        console.log(e)
        return e;
      })

  },
  userResponse({ context }, payload) {
    return APICaller({
      method: 'get',
      endpoint: gep(`survey-response/${payload}`, apiPrefix)
    }).then(resp => {
      return resp.data.data.items;

    }).catch(err => {
      console.log(err)
    })
  },

  userSurveyResponse({ context }, payload) {
    let params = {
      user: payload.user
    }
    console.log(params);
    return APICaller({
      method: 'get',
      params,
      endpoint: gep(`survey-response/user/${payload.id}`, apiPrefix)
    }).then(resp => {
      return resp.data;

    }).catch(err => {
      console.log(err)
    })
  },

  deleteSurvey({ context }, payload) {
    return APICaller({
      method: 'Delete',
      endpoint: gep(`survey/${payload}`, apiPrefix)
    }).then(resp => {
      return resp.data;

    }).catch(err => {
      console.log(err)
    })
  },

  addSurvey({ context }, payload) {
    return APICaller({
      method: 'POST',
      data: payload,
      endpoint: gep(`survey`, apiPrefix)
    }).then(resp => {
      return resp.data;

    }).catch(err => {
      console.log(err)
    })
  },

  getAccumulatedResponse({ context }, payload) {
    return APICaller({
      method: 'get',
      endpoint: gep(`survey-response/${payload}/accumulated`, apiPrefix)
    }).then(resp => {
      return resp.data;

    }).catch(err => {
      console.log(err)
    })
  },

  getAccumulatedIndividualResponse({ context }, payload) {
    let params = {
      question_id: payload.question_id
    }
    return APICaller({
      method: 'get',
      params,
      endpoint: gep(`survey-response/${payload.id}/individual`, apiPrefix)
    }).then(resp => {
      return resp.data;

    }).catch(err => {
      console.log(err)
    })
  },

  updateSurveyQuestion(context, payload) {
    return APICaller({
      method: 'put',
      endpoint: gep(`survey-questions`, apiPrefix),
      data: { payload },
      isDataRaw: true
    })
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((e) => {
        console.log(e)
        return e;
      })
  }
}