<template>
    <div class="app-page booking-financial-adjustment-page">

        <page-header :title="`${ $t('tfv_txt_financial_adjusment_title') }`"
            :linkData="{
                text : this.$route.params.id,
                path : 'booking/' + this.$route.params.id + '/details',
                preText : '(#',
                postText : ')'
            }"
            :back-enabled="true"/>

        <div class="app-container">
            <div class="app-wrapper">
                 <user-in-booking-feature :bookingId="bookingId" :userId="userId"></user-in-booking-feature>

                <div class="audits-cnt">
                    <el-button @click="handleRedirectAudits">{{ $t('audit_logs') }}</el-button>
                </div>

                <div class="app-row row-1">

                    <!-- ------------------------------ -->
                    <!-- Invoice Related Components -->
                    <!-- ------------------------------ -->
                    <div class="col-container half-width">

                        <!-- Invoice Preview -->
                        <div class="app-row inner-row">
                            <div class="app-col">
                              <financial-preview-display
                                id="invoice_financial_preview_display"
                                :title="$t('tfv_invoice_preview')"
                                :booking-id="bookingId"
                                target="invoice"
                              />
                            </div> <!-- /.app-col -->
                        </div> <!-- /.app-row inner-row -->

                        <!-- Invoice Additional List -->
                        <div class="app-row inner-row">
                            <div class="app-col">
                                <financial-additional-list
                                        id="invoice_financial_additional_list"
                                        :title="$t('tfv_txt_invoice_additional_charges')"
                                        :booking-id="bookingId"
                                        target="invoice"/>
                            </div> <!-- /.app-col -->
                        </div> <!-- /.app-row inner-row -->

                        <!-- Invoice Adjustment Form -->
                        <!-- <div class="app-row inner-row" v-if="bookingType === 'physical'">
                            <div class="app-col">
                                <financial-adjustment-travel-settings
                                        id="invoice_financial_adjustment_list"
                                        :title="$t('tfv_invoice_travel_settings')"
                                        :booking-id="bookingId"
                                        target="invoice"/>
                            </div>
                        </div> -->

                        <!-- Invoice Crediting Form -->
                        <div class="app-row inner-row">
                            <div class="app-col">
                                <financial-credit-booking
                                id="financial_crediting_booking"
                                :title="$t('tfv_invoice_crediting')"
                                :booking-id="bookingId"
                                target="invoice"/>
                            </div> <!-- /.app-col -->
                        </div> <!-- /.app-row inner-row -->

                    </div> <!-- /.app-col.half-width -->

                    <!-- ------------------------------ -->
                    <!-- Salary Related Components -->
                    <!-- ------------------------------ -->
                    <div class="col-container half-width">

                        <!-- Salary Preview -->
                        <div class="app-row inner-row">
                            <div class="app-col">
                                <financial-preview-display
                                  id="salary_financial_preview_display"
                                  :title="$t('tfv_salary_preview')"
                                  :booking-id="bookingId"
                                  target="salary"
                                />
                            </div> <!-- /.app-col -->
                        </div> <!-- /.app-row inner-row -->

                        <!-- Salary Additional List -->
                        <div class="app-row inner-row">
                            <div class="app-col">
                                <financial-additional-list
                                        id="salary_financial_additional_list"
                                        :title="$t('tfv_salary_penalty_list')"
                                        :booking-id="bookingId"
                                        target="salary"/>
                            </div> <!-- /.app-col -->
                        </div> <!-- /.app-row inner-row -->

                        <!-- Salary Adjustment Form -->
                        <!-- <div class="app-row inner-row" v-if="bookingType === 'physical'">
                            <div class="app-col">
                                <financial-adjustment-travel-settings
                                        id="salary_financial_adjustment_list"
                                        :title="$t('tfv_txt_salary_travel_settings')"
                                        :booking-id="bookingId"
                                        target="salary"/>
                            </div>
                        </div> --> <!-- /.app-row inner-row -->

                        <!-- Salary Crediting Form -->
                        <div class="app-row inner-row">
                            <div class="app-col">
                                <financial-credit-booking
                                id="financial_salary_crediting_booking"
                                :title="$t('tfv_fn_salary_crediting')"
                                :booking-id="bookingId"
                                target="salary"/>
                            </div> <!-- /.app-col -->
                        </div> <!-- /.app-row inner-row -->

                    </div> <!-- /.app-col.half-width -->

                </div> <!-- /.app-row.row-1 -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->
    </div> <!-- /.app-page booking-financial-adjustment-page -->
</template>

<script>
  import FinancialAdditionalList from '~/components/lists/financial/FinancialAdditionalList';
  import FinancialAdjustmentTravelSettings
    from '~/components/forms/financial/adjustment/FinancialAdjustmentTravelSettingsForm';
  import FinancialPreviewDisplay from '~/components/displays/financial/preview/FinancialPreviewDisplay';
  import {mapActions} from 'vuex'
  import FinancialInvoicePreviewDisplay from '~/components/displays/financial/preview/FinancialInvoicePreviewDisplay';
  import FinancialCreditBooking from '~/components/lists/financial/FinancialCreditBooking';

  // noinspection JSUnusedGlobalSymbols
  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > Imported components
    |--------------------------------------------------------------------------
    */
    components: {
      FinancialPreviewDisplay,
      FinancialInvoicePreviewDisplay,
      FinancialAdditionalList,
      FinancialAdjustmentTravelSettings,
      FinancialCreditBooking
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {};
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Returns the Booking ID value according to the route param ID.
       *
       * @return {int}
       */
      bookingId () {
        return !window._.isNil(this.$route.params['id'])
          ? parseInt(this.$route.params['id'])
          : 0;
      },

      userId(){
            return JSON.parse(localStorage.getItem("app_user_data")).id
        },

      /**
       * Returns the Booking Type according to query param value.
       *
       * @returns {string}
       */
      bookingType () {
        return !window._.isNil(this.$route.query) && !window._.isNil(this.$route.query['type'])
          ? this.$route.query['type']
          : 'physical';
      },

    }, // End of Component > computed


    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
         ...mapActions({
          deleteUserInsideBooking: 'booking/deleteUserInsideBooking',
      }),
        handleRedirectAudits() {
            this.$router.push({name:'audits.financials.adjustments', params:{booking_id:this.$route.params.id}});
        },
    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {}, // End of Component > mounted
    beforeRouteLeave (to, from, next) {
             let data = {
                    booking_id : this.bookingId,
                    user_id : this.userId,
                }
                this.deleteUserInsideBooking(data)
                next(true);
         },

  } // End of export default
</script>

<style scoped>
    .audits-cnt {
        text-align: right;
    }
</style>
