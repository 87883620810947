  <template>
    <el-table
    :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="id"
        :label="$t('id')">
      </el-table-column>
      <el-table-column
        :label="$t('name')">
        <template slot-scope="scope">
          <a href="#" style="color: inherit;" @click.stop.prevent="handleClick(scope.row.id)"><u>{{scope.row.name}}</u></a>
        </template>
      </el-table-column>
    </el-table>
  </template>

  <script>
    import USER_TYPES from '~/js/constants/user-types';
    import APICaller from "../../../js/helpers/APICaller";
    import { gep } from "../../../js/helpers/Vuex";
    export default {
      data() {
        return {
        }
      },
      props: {
        user: '',
        tableData: {
          type: Array,
          default() {
            return [];
          }
        }
      },

      methods: {
        handleClick(id) {
          if (this.user === 'customer') {
            const userType = window._.chain(USER_TYPES).findKey((t) => t === 2).value().toLowerCase();

            // Redirect to User Edit Page.
            this.$router.push({name: `user.edit.${userType}`, params: {id}});
          }
          else if(this.user == 'company'){
            this.$router.push({name:'company-edit', params: {id: id}, query:{type: 3}})
          }
          if(this.user == 'department'){
            this.$router.push({name:'department.edit', params: {id: id}, query:{type: 3}})
          }
        },
      },

      mounted() {
        }, // End of Component > mounted
    }
  </script>
