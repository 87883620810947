<template>
  <el-dialog
    :class="getBem(blockClass)"
    :visible.sync="isShowModal"
    width="90%"
    top="3%"
    :append-to-body="true"
    @close="close"
  >
    <div :class="getBem(blockClass, 'container')">
      <div :class="getBem(blockClass, 'wrapper')" data-simplebar>
        <booking-availability-scheduler
          ref="booking-availability-scheduler"
          :booking-id="bookingId"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import isNil from 'lodash/isNil';
  import isEmpty from 'lodash/isEmpty';
  import BookingAvailabilityScheduler
    from '~/components/displays/booking-availability/BookingAvailabilityScheduler';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
    components: {
      BookingAvailabilityScheduler
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockClass: 'booking-availability-scheduler-modal',
        isShowModal: false,
        booking: {},
        batches: []
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * @returns {boolean}
       */
      isValidBooking () {
        return !isNil(this.booking)
          && !isEmpty(this.booking);
      },

      /**
       * @returns {number}
       */
      bookingId () {
        return this.isValidBooking ? this.booking.id : 0;
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * @param {object} payload
       * @param {object} payload.booking - current target booking.
       * @param {object} payload.batches - selected batches.
       * @returns {void}
       */
      open (payload) {
        this.isShowModal = true;
        this.booking = payload.booking;
        this.batches = payload.batches;
        setTimeout(() => {
          this.$refs['booking-availability-scheduler'].addTranslatorsFromBatches(this.batches);
        }, 250);
      },

      /**
       * @returns {void}
       */
      close () {
        this.booking = {};
        this.batches = [];
        this.$refs['booking-availability-scheduler'].clearActivePool();
        this.isShowModal = false;
      }
    }

  };
</script>

<style lang="scss">
  .booking-availability-scheduler-modal {
    overflow: hidden;

    .el-dialog {
      &__header {
        position: relative;
        padding: 5px;
        z-index: 5;
      }

      &__body {
        position: relative;
        padding: 0 7px 15px;
      }
    }

    &__wrapper {
      width: 100%;
      height: 92vh;
    }

    .booking-availability-scheduler {
      width: 97%;
      box-sizing: border-box;
    }

    .el-dialog__headerbtn:focus .el-dialog__close,
    .el-dialog__headerbtn:hover .el-dialog__close {
      color: #3A75BA;
    }
  }
</style>
