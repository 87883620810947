<template>
    <div class="app-page admin-permissions-list-page">
        <page-header :title="$t('admin_permissions')" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">

                    <div v-if="is_admin" class="app-col full-width">
                        <admin-permissions-list-by-admin :is_admin="is_admin" />
                    </div> <!-- /.app-col -->
                    <div v-else class="app-col full-width">
                        <admin-permissions-list />
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page price-list-settings-page -->
</template>

<script>
export default {

    data() {
        return {
            is_admin: false
        }
    },

    mounted() {
        if (this.$route.path.includes('/admin-permissions/by-admin/')) {
            this.is_admin = true;
        }
    }
}
</script>
