<template>
    <div class="app-page company-compose-page">
        <import-batch-template :dialogVisible="dialogVisible" :changeFunction="changeFunction" :importBatchTemplate="importBatchTemplate" ></import-batch-template>

        <page-header :title="$t('company')" :back-enabled="true"/>

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col full-width">
                        <div class="top-controls">
                            <el-row>
                                <el-col :span="24" class="text-right">

                                    <el-button type="primary" @click="handleRedirectAudits">
                                        {{$t('audit_logs')}}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="content-body">
                            <!-- Form for the Main Information -->
                            <el-form :model="form" :rules="rules" :id="mainFormElId" :ref="mainFormElId">
                                <company-main-fields v-model="form.main"/>
                                <el-form-item>
                                    <el-button type="primary" :id="genFieldId('save',mainFormElId)"
                                               @click="handleSubmit">
                                        {{$t(mode)}}
                                    </el-button>
                                </el-form-item>
                            </el-form>
                            <custom-table :user="'department'" :tableData="tableData" />
                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->
                    <div class="app-col half-width">
                        <div class="content-body">
                            <el-form :id="additionalInfoFormElId" :ref="additionalInfoFormElId">
                                <h3>{{$t('additional_information')}}</h3>
                                <el-collapse class="panel-tabbed">
                                    <el-collapse-item :title="$t('contact')" name="Contact" v-if="false">
                                        <sector-contact-info-fields v-model="form.contact"
                                                                    :form-el-id="additionalInfoFormElId"/>
                                    </el-collapse-item>
                                    <el-collapse-item :title="$t('address')" name="address">
                                        <sector-address-fields v-model="form.address"
                                                               :form-el-id="additionalInfoFormElId"/>
                                    </el-collapse-item>
                                    <el-collapse-item :title="$t('settings')" name="settings">
                                        <sector-settings-fields v-model="form.sector_settings"
                                                                :form-el-id="additionalInfoFormElId"/>
                                    </el-collapse-item>
                                      <el-collapse-item :title="$t('invoice_reminder_settings')" name="invSettings">
                                        <invoice-reminder-settings-fields v-model="invoiceReminder"
                                                                :form-el-id="additionalInfoFormElId"/>
                                    </el-collapse-item>
                                </el-collapse>
                                <!-- Save -->
                                <el-form-item :id="genContainerId('save',additionalInfoFormElId)">
                                    <el-button type="primary" :id="genFieldId('save',additionalInfoFormElId)"
                                               @click="handleSubmit">
                                        {{$t('save')}}
                                    </el-button>
                                </el-form-item>
                            </el-form>
                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->
                    <div class="app-col col-container half-width">
                        <div class="content-body">
                            <!-- Inner App Container -->
                            <div class="app-container">
                                <div class="app-wrapper">
                                    <div class="app-row">
                                        <div class="app-col full-width">
                                            <h3>{{$t('financial_information')}}</h3>
                                            <el-collapse class="panel-tabbed">
                                                <el-collapse-item :title="$t('settings')" name="financial_settings">
                                                    <sector-financial-settings-form/>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div> <!-- /.app-col -->
                                        <div class="app-col full-width">
                                            <el-form :inline="true">
                                            <el-form-item>
                                            <h3>{{$t('batch_settings')}}</h3>
                                            </el-form-item>
                                            <el-form-item class="button-batch">
                                            <el-button type="primary" size="mini" @click="dialogVisible = true">{{$t('import_from_template')}}</el-button>
                                            </el-form-item>
                                            </el-form>
                                            <el-collapse class="panel-tabbed">
                                                <el-collapse-item :title="$t('list_values')" name="batches_settings">
                                                    <sector-batch-settings-form :changeBatchSetting="changeBatchSetting" :batchTemplateSetting="batchTemplateSetting"/>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div> <!-- /.app-col -->
                                    </div> <!-- /.app-row -->
                                </div> <!-- /.app-wrapper -->
                            </div> <!-- /.app-container -->
                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->
    </div> <!-- /.app-page company-compose-page -->
</template>

<script>
    import APICaller from "../../../../js/helpers/APICaller";
    import { gep } from "../../../../js/helpers/Vuex";
    import {mapState, mapGetters, mapMutations, mapActions} from "vuex";
    import {checkBreadMode} from "../../../../js/helpers/Route";
    let moduleTarget = 'company';
    export default {
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            var checkNumberSetting = (rule, value, callback) => {
                let status =  this.$refs[this.mainFormElId].model.main.number_settings_status
                console.log(this.$refs[this.mainFormElId].model)
                    if(status == 'inherit' || status == 'allowed')
                    {
                        if(!this.$refs[this.mainFormElId].model.main.number_settings_number)
                        {
                           return callback(new Error('Number must be entered when '+status+ ' is selected'));
                        }
                        else {
                             callback()
                        }
                    }
            }
            return {
                changeBatchSetting: false,
                batchTemplateSetting: {},
                dialogVisible: false,
                tableData: [],
                mainFormElId: 'company_main_form',
                additionalInfoFormElId: 'company_additional_info_form',
                rules: {
                    number_settings_number: [
                        {validator: checkNumberSetting}
                    ],
                }
            };

        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapState(moduleTarget, {
                form: 'compose'
            }),

            ...mapGetters(moduleTarget, {
                entryData: 'itemData',
            }),

            ...mapState('invoiceReminderSettings', {
                invoiceReminder: 'compose'
            }),

            /**
             * Value to determine the current mode whether it's add or edit.
             *
             * @return {string}
             */
            mode() {
                return checkBreadMode(this);
            },
            companyId() {
                return this.$route.params.id
            }

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Component > methods
        |--------------------------------------------------------------------------
        */
        methods: {
            importBatchTemplate(val) {
                this.changeBatchSetting = true;
                this.batchTemplateSetting = val;
                this.dialogVisible = false;
            },
            changeFunction() {
                this.dialogVisible = !this.dialogVisible
                // done();
            },
            ...mapActions(moduleTarget, {
                addEntry: 'add',
                editEntry: 'edit',
                readEntry: 'read',
            }),

            ...mapMutations(moduleTarget, {
                resetForm: 'resetCompose',
            }),
            ...mapActions('invoiceReminderSettings', {
                getInvoiceReminderSetting: 'getInvoiceReminderSetting',
                createOrUpdateInvoiceReminderSetting: 'createOrUpdateInvoiceReminderSetting'
            }),
            /**
             * Handler when the Back button has been clicked.
             */
            handleClickBack() {
                window.history.back()
            }, // End of handleClickBack() method

            prepareInvoiceSettings() {
                let payload = {
                    params : {
                        'filter[company_id]': this.companyId
                    }
                }
                this.getInvoiceReminderSetting(payload);
            },
            submitInvoiceReminder() {
                 if(this.invoiceReminder && this.invoiceReminder.name && this.invoiceReminder.number) {
                    this.invoiceReminder.company_id = this.companyId
                    this.createOrUpdateInvoiceReminderSetting(this.invoiceReminder);
                }
            },

            /**
             * Handle when the form has been submitted.
             *
             * @return {void}
             */
            handleSubmit() {
                 this.$refs[this.mainFormElId].validate((valid) => {
                    if(valid){
                this.submitInvoiceReminder();
                // Set the action to be made.
                let action = this.mode + 'Entry';
                let payload = {}; // Set container for payload.

                // If the current compose mode is EDIT, then
                // add the ID of the target into the payload.
                if (this.mode == 'edit') {
                    payload.id = this.$route.params.id;
                }

                this[action](payload).then(r =>{
                    if(r.status==201){
                        this.$router.push({name:'company-edit',params:{id:r.data.data.company.id}})
                    }
                });
            }})
            }, // End of handleSubmit() method

            /**
             * Handle when redirecting to the companies's audit logs
             *
             * @return {void}
             */
            handleRedirectAudits() {
                this.$router.push({
                    name:'audits',
                    params:{
                        id:this.$route.params.id,
                        auditable:'companies'
                    },
                    query: {
                        relationships: 'invoiceReminderSetting,blacklisted_users,batches_settings',
                    }})
            }
        }, // End of Component > methods

        /*
        |--------------------------------------------------------------------------
        | Component > mounted
        |--------------------------------------------------------------------------
        */
        watch: {
                    "$route.params.id"(val) {
                    let includes = [];
                    // call the method which loads your initial state
                  this.readEntry({id: this.$route.params.id});
            },
        },
        mounted() {
            setTimeout(() => {
                console.log(this.invoiceReminder)
            },4000)
            this.prepareInvoiceSettings();
            const id = this.$route.params.id;
            let target = 'companies';
            let apiPrefix = window.app_api_prefix_3;
            APICaller({
                method: 'get',
                endpoint: gep(target + `/${id}`, apiPrefix),
                params: { include: 'departments,blacklisted_users' },
                data: {},
            }).then(r => {
                this.tableData = r.data.data.company.departments
            });

            this.resetForm();

            // If the current mode is on edit, fetch the property values for the targeted entry.
            if (this.mode == 'edit') {
                this.readEntry({id: this.$route.params.id});
            }

        }, // End of Component > mounted

    } // End of export default

</script>
<style>
.button-batch div.el-form-item__content {
    right: -160px;
    top: 5px;
}
</style>
