/*
|--------------------------------------------------------------------------
| Store > Batches Settings > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Batches Settings Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {gep} from "../../../helpers/Vuex";

let apiPrefix = window.app_api_prefix_3;

let partialProps = {
    distance: '',
    feedback: '',
    customer_feedback: '',
    late_cancellation: '',
    translator_levels: {
        deluxe: '',
        layman: '',
        certified: '',
        read_courses: '',
        certified_in_law: '',
        certified_in_health: '',
        trained : '',
    },
    translators_levels: '',
    translators_levels_mode: 'soft',
    translators_preferences: '',
    delays: '',
    kpi: ''
};


let kpiSettings = {
    name: 'kpi',
    value: [
        {
            feedback: '',
            customer_feedback: '',
            late_cancellation: '',
            inactive_translator: '',
            translator_preferences: ''
        }
    ]
}

let maxTravel = {
    max_travel_distance:  {
        global : '',
        certified : '',
        certified_in_law : '',
        certified_in_health : '',
        trained : '',
        read_courses : '',
        layman : '',
    },
    max_travel_time : {
        global : '',
        certified : '',
        certified_in_law : '',
        certified_in_health : '',
        trained : '',
        read_courses : '',
        layman : '',
    }
}

let composeProps = {

    settings: {
        phone: _.cloneDeep(partialProps),
        physical: _.cloneDeep(_.merge(partialProps, maxTravel)),
    }

};

export default {

    model_info: {
        name: 'Batches Settings Store',
        module: 'batches',
        table: 'batches_settings',
    },

    api: {
        read: {method: 'get', path: gep('companies/{id}/batches-settings', apiPrefix), params: {}, data: {},},
        edit: {method: 'post', path: gep('companies/{id}/batches-settings', apiPrefix), params: {}, data: {},},
        // browse: {method: 'get', path: gep(target, apiPrefix), params: {per_page: 10,}, data: {},},
        // add: {method: 'post', path: gep(target, apiPrefix), params: {}, data: {},},
        // delete: {method: 'delete', path: gep(target + '/{id}', apiPrefix), params: {}, data: {},},
    },

    composeFresh: _.cloneDeep(composeProps),
    compose: _.cloneDeep(composeProps),


    composeKpi: _.cloneDeep(kpiSettings),
    composeFreshKpi: _.cloneDeep(kpiSettings),

    payloadData: {}, // Container for payload data.

    autoInjectPayload: true,
    autoReflectItem: true,
    autoUpdateList: false,

} // End of export default