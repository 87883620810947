'use strict';

import {mapEndpoint} from "~/js/helpers/Common";
import APICaller from "~/js/helpers/APICaller";

export default {

  browse (context, payload) {
    const apiOpts = {
      method: 'GET',
      endpoint: mapEndpoint(context.state.api, payload)
    };

    return APICaller(apiOpts)
      .then((r) => {
        const data = r.data.data;
        const meta = r.data.meta;
        context.commit('setList', {data, meta});
      });
  },

  read (context, payload) {
    const apiOpts = {
      method: 'GET',
      endpoint: mapEndpoint(`${context.state.api}/{item}`, payload)
    };

    return APICaller(apiOpts)
      .then((r) => {
        context.commit('setItemData', r.data.data);
      });
  },

  addOrEdit (context, payload) {
    const apiOpts = {
      method: 'POST',
      endpoint: mapEndpoint(`${context.state.api}`, payload),
      data: context.state.form,
      isDataRaw: true
    };

    return APICaller(apiOpts)
      .then(() => {
        if (context.state.autoReloadList) {
          context.dispatch('browse');
        }
      });
  },

  delete (context, payload) {
    let apiOpts = {
      method: 'POST',
      endpoint: mapEndpoint(`${context.state.api}`, payload),
      data: payload.item,
      isDataRaw: true
    };

    apiOpts.data.price = 0;
    apiOpts.data.quantity = 0;
    apiOpts.data.subtotal = 0;

    return APICaller(apiOpts)
      .then(() => {
        if (context.state.autoReloadList) {
          context.dispatch('browse');
        }
      });
  }
}
