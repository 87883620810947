<template>
  <div class="app-list low-feedback-list">


    <base-list-table :table-data="listData" :extra-cols="listCols" :hasDetailsCol="false">
      <template slot="actionsColumn" slot-scope="scope">
        <template v-if="$route.name === 'feedback.low'">
          <el-button class="circle" type="primary" title="Details" icon="el-icon-info"
            @click="$router.push({name:'feedback.low-details',params:{id:scope.row.id}})">
            <!-- <span class="fa fa-pencil"></span> -->
          </el-button>
          <el-button class="circle" type="success" title="Sloved" icon="el-icon-success"
            @click="handleClickSolved(scope.row)">
          </el-button>
        </template>

        <template v-else>
          <el-button class="circle" type="primary" title="Details" icon="el-icon-info"
            @click="$router.push({name:'feedback.low-details',params:{id:scope.row.id}})">
            <!-- <span class="fa fa-pencil"></span> -->
          </el-button>
        </template>

      </template>

    </base-list-table>
    <el-pagination layout="total, sizes, prev, pager, next, jumper" :page-size="listPagination.per_page"
      :page-sizes=[3,10,25,50,100,200] :total="listPagination.total" :current-page="listPagination.current_page"
      @size-change="handleSizeChange" @current-change="reloadList">
    </el-pagination>
  </div> <!-- /.app-list translation-languages-list -->
</template>

<script>
    import {mapGetters,mapActions,mapState} from 'vuex'
    import {populate,forcePopulate} from "~/js/helpers/Vuex"
    import {isEmpty,sd} from "../../../js/helpers/Common";
    export default {

      /*
      |--------------------------------------------------------------------------
      | Components > props
      |--------------------------------------------------------------------------
      */
      props: {}, // End of Component > props

      /*
      |--------------------------------------------------------------------------
      | Components > data
      |--------------------------------------------------------------------------
      */
      data() {
        return {

          perPage: 50,
          // hiddenCols: ['id'],
          listCols: [{
              label: 'Booking ID',
              prop: 'id',
              width: '',
            },
            {
              label: 'Customer',
              prop: 'customer.name',
              width: '',
            },
            {
              label: 'Translator',
              prop: 'assigned_translator.name',
              width: '',
            },
            {
              label: 'Rating From',
              prop: '',
              width: '',
              mutator(v, row) {
                let feedback = _.sortBy(row.feedback, 'rating')
                let type = feedback.find(o => o.rating < 3).review_from_type
                return (type===2)?'Customer':'Translator'
              }
            },
            {
              label: 'Rating',
              prop: 'feedback',
              width: '',
              mutator(v, row) {
                let feedback = _.sortBy(row.feedback, 'rating')
                return feedback.find(o => o.rating < 3).rating
              }
            },
            {
              label: 'Created at',
              prop: 'created_at',
              width: '',

            }
          ],
          populatePayload: {}
        }
      }, // End of Component > data

      /*
      |--------------------------------------------------------------------------
      | Components > computed
      |--------------------------------------------------------------------------
      */
      computed: {

        ...mapGetters('booking', ['listData', 'listPagination', 'getLoading']),

        ...mapState('auth',{
          user:'userData'
        }),
      }, // End of Component > computed

      /*
      |--------------------------------------------------------------------------
      | Components > watch
      |--------------------------------------------------------------------------
      */
      watch: {}, // End of Component > watch

      /*
      |--------------------------------------------------------------------------
      | Components > methods
      |--------------------------------------------------------------------------
      */
      methods: {
        ...mapActions('booking', ['browse']),

      ...mapActions({
            storeFeedbackIssue: 'feedback/storeFeedbackIssue',
          }),
        reloadList(n, per_page) {
          let params = _.clone(this.$route.query);
          n = sd(n, sd(this.$route.query.page, 1)) // Set the page number.
          per_page = sd(per_page, sd(this.$route.query.per_page, 10)) // Set the page number.
          params.page = n;
          params.per_page = per_page;


          this.populatePayload.page = params.page
          this.populatePayload.per_page = per_page
          Object.assign(params, this.populatePayload)

          this.$router.push({
            query: params
          }) // Reflect the chosen parameters in the URL Bar
          populate(this, 'browse', '', {
            force: true,
            params
          })
        },
        handleSizeChange(val) {
          
          this.reloadList(this.listPagination.current_page, val);
        },
        handleClickSolved(row){
          if(!isEmpty(row.feedback)){
            let feedbacks = _.sortBy(row.feedback, 'rating')
            let feedback = feedbacks.find(o => o.rating < 3)

            let form = {
              comment: `set as solved by ${this.user.name}`,
              created_by: this.user.id,
              user: this.user,
              feedback_id: feedback.id,
              flag_id:5
            }
            this.storeFeedbackIssue(form).then(r => {
              if(r.status == 201){
                // this.$router.push({name:'feedback.low.solved'})
              }
            })
          }
        }
      }, // End of Component > methods

      /*
      |--------------------------------------------------------------------------
      | Components > mounted
      |--------------------------------------------------------------------------
      */
      mounted() {
        if(this.$route.name === 'feedback.low.solved'){

          this.listCols= [{
              label: 'Booking ID',
              prop: 'id',
              width: '',
            },
            {
              label: 'Customer',
              prop: 'customer.name',
              width: '',
            },
            {
              label: 'Translator',
              prop: 'assigned_translator.name',
              width: '',
            },
            {
              label: 'Rating From',
              prop: '',
              width: '',
              mutator(v, row) {
                let feedback = _.sortBy(row.feedback, 'rating')
                let type = feedback.find(o => o.rating < 3).review_from_type
                return (type===2)?'Customer':'Translator'
              }
            },
            {
              label: 'Rating',
              prop: 'feedback',
              width: '',
              mutator(v, row) {
                let feedback = _.sortBy(row.feedback, 'rating')
                return feedback.find(o => o.rating < 3).rating
              }
            },
            {
              label: 'Solved at',
              prop: 'updated_at',
              width: '',

            }
          ],
          this.populatePayload= {
            'filter[solved_low_feedback_rating]': true,
            'include': 'customer,assigned_translator,feedback',
            'sort':'updated_at',
            per_page:10
          }
        }else {
          this.populatePayload= {
            'filter[unsolved_low_feedback_rating]': true,
            'include': 'customer,assigned_translator,feedback',
            per_page:10
          }
        }
        let params = _.clone(this.$route.query)
        
        Object.assign(params, this.populatePayload)
        const opts = {
          action: 'browse',
          data: 'listData',
          ref: 'Booking Listing',
        };

        this.reloadList()

      }, // End of Component > mounted

    }
</script>