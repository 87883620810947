/*
|--------------------------------------------------------------------------
| Store > Maintenance Mode Settings
|--------------------------------------------------------------------------
|
| A Class file that manages all the properties and abilities in relation
| to Maintenance Mode Settings.
|
*/

import state from './state'
import actions from './actions'
import mutations from './mutations'

export default {
  namespaced:true,
  state,
  actions,
  mutations
}