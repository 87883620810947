/*
|--------------------------------------------------------------------------
| Store > financial > Credits > getters
|--------------------------------------------------------------------------
|
| This file contains the getters property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

export default {
  translatorSalaryCredits:(state) => state.translatorSalaryCredits,
  departmentInvoiceCredits:(state) => state.departmentInvoiceCredits,
  pagination:(state) => state.pagination,
}
