<template>
    <div class="app-list booking-list">
        <booking-base-list
            :table-data="listData"
            :extra-cols="listCols"
            :hasDetailsCol="false"
            actions-col-width="55"
            actions-col-fixed="right"
            :row-class="tableRowClassName"
            :loading="loading"
            :isbooking="true"
        >
            <template slot="topControls">
                <div class="text-right">
                  <span class="text-right">{{$t('total_records')}} : {{ listPagination.total }}</span>
                </div>
            </template>
            <template slot="actionsColumn" slot-scope="scope">
                <div @contextmenu.prevent="handleDetailsNew(scope)">
                    <el-button
                        class="circle b"
                        type="success"
                        title="Details"
                        @click="handleDetails(scope)"
                        size="mini"
                        icon="el-icon-info"
                    >
                    </el-button>
                </div>
            </template>
        </booking-base-list>

        <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :page-size="listPagination.per_page"
            :page-sizes="[10, 10, 50, 100, 200]"
            :total="listPagination.total"
            :current-page="listPagination.current_page"
            @size-change="handleSizeChange"
            @current-change="reloadList"
        >
        </el-pagination>
    </div>
    <!-- /.app-list booking-list -->
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import {populate} from "~/js/helpers/Vuex";
  import {isEmpty, sd} from "../../../js/helpers/Common";
  import includes from 'lodash/includes';
  import forEach from 'lodash/forEach';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      vPopulatePayload: {
        type: Object,
        default () {
          return {
            "filter[is_test]": "0",
            sort: "-created_at"
          };
        }
      }
    }, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      const _this = this;

      return {
        tableElId: "booking_list",

        perPage: 50,
        search: "",

        listCols: [
          {
            prop: "id",
            label: `${this.$t('job_id')}`,
            width: "",
            sortable: true,
            mutator (v, row) {
              let val = `${v}<br>`;
              if (row.batches_by_local) {
                val += `<span class="yellow-dot"></span>`;
              }
              if (row.batches_by_prio) {
                val += `<span class="green-dot"></span>`;
              }
              if (row.translators_count < 10) {
                val += `<span class="red-dot"></span>`;
              }
              return (
                val + `${row.translators_count === undefined ? "Not created yet" : row.translators_count + " match"}`
              );
            }
          },
          {
            prop: "from_language_id",
            label: `${this.$t('language')}`,
            width: "",
            sortable: true,
            showPopover: true,
            mutator (v, row) {
              if (!isEmpty(_this.languageOpts) && !isEmpty(v)) {
                const found = _.find(_this.languageOpts, x => x.id === v);
                let alternative = "";
                if (!isEmpty(row.alternative_languages)) {
                  let values =
                    row.alternative_languages < 2
                      ? row.alternative_languages
                      : _.take(row.alternative_languages, 2);
                  alternative = values
                    .map(v => {
                      const f = _.find(
                        _this.languageOpts,
                        x => x.id === v.language_id
                      );
                      return f ? f.name : "";
                    })
                    .join(", ");
                }
                let other = !isEmpty(alternative) ? `<br> ${alternative}` : "";
                // let result = (found)? `${found.name}` :''
                return `${found ? found.name : ""} ${other} `;
              }
            },
            //popover content value
            popover (v, row) {
              //show popover if value more than 2
              if (
                !isEmpty(row.alternative_languages) &&
                row.alternative_languages.length > 2
              ) {
                return row.alternative_languages
                  .map(v => {
                    const f = _.find(
                      _this.languageOpts,
                      x => x.id === v.language_id
                    );
                    return f ? f.name : "";
                  })
                  .join(", ");
              }
            }
          },
          {
            prop: "to_language_id",
            label: `${this.$t('to_language')}`,
            width: "",
            sortable: true,
            showPopover: true,
            mutator(v,row){
              return !isEmpty(row.text_translation) ? row.text_translation.to_language :'';
            }
          },
          {
            prop: "status",
            label: `${this.$t('status')}`,
            width: "",
            sortable: true,
            mutator (v) {
              return v.name;
            }
          },

          // {prop: 'book_type', label: 'Bokn.', width: ''},
          {prop: "due", label: `${this.$t('due')}`, width: "80px", sortable: true},
          {prop: "text_translation", label: `${this.$t('translator_deadline')}`, width: "80px", sortable: true,
            mutator(v,row){
              return !isEmpty(row.text_translation) ? row.text_translation.translator_deadline :'';
            }
          },
          {prop: "created_at", label: `${this.$t('created')}`, width: "80px", sortable: true},
        ],

        hiddenCols: ["id"],

        row: null,
        tableRowClassName: [
          {
            field: "flagged",
            value: true,
            class: "is_flagged"
          },
          {
            field: "is_test",
            value: true,
            class: "is_test"
          }
        ]
      };
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters("booking", {
        listData: "listData",
        listPagination: "listPagination",
        loading: "getLoading",
        customersOpts: "getCustomersList"
      }),

      ...mapGetters("language", {
        languageOpts: "getLanguages"
      }),

      populatePayload: {
        get () {
          return this.vPopulatePayload;
        },
        set (v) {
          this.$emit("input", v);
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions("booking", ["browse"]),

      ...mapActions("language", {
        loadLanguages: "fetchLanguages"
      }),

      ...mapActions("booking", {
        fetchCustomers: "fetchCustomers"
      }),
      handleSizeChange (val) {
        this.reloadList(this.listPagination.current_page, val);
      },
      /**
       * Helper method to check wether the column is enabled in the options or not.
       *
       * @param {string} key
       *   The switch value to check for whether to enable/disable a column.
       *
       * @return {boolean}
       */
      isColEnabled (key) {
        let r = true;

        if (_.includes(this.hiddenCols, key)) {
          r = false;
        }

        return r;
      }, // End of isColEnabled() method

      /**
       * Method for reloading the list.
       *
       * @param {int} n
       *   The target page number.
       *
       * @return {void}
       */
      reloadList (n, per_page) {
        let params = _.clone(this.$route.query);
        n = sd(n, sd(this.$route.query.page, 1)); // Set the page number.
        params.page = n;
        if (per_page && typeof per_page == "number") {
          params["per_page"] = per_page;
        }
        this.populatePayload.page = params.page;

        if (
          !isEmpty(params["filter[is_test]"]) &&
          params["filter[is_test]"] === 1
        ) {
          this.populatePayload["filter[is_test]"] = 1;
        }

        Object.assign(params, this.populatePayload);

        this.$router.push({query: params}); // Reflect the chosen parameters in the URL Bar
        populate(this, "browse", "", {
          force: true,
          params
        });
      }, // End of reloadList() method

      /**
       * method for handling details popups
       * @param {object} scope
       *
       * @return {void}
       */
      handleDetails (scope) {
        this.$router.push({
          name: "booking-details",
          params: {id: scope.row.id}
        });
      },

      handleDetailsNew (scope) {
        let route = this.$router.resolve({
          name: "booking-details",
          params: {id: scope.row.id}
        });
        window.open(route.href, "_blank");
      },

      /**
       * method for handling booking event
       * @param  {[object]} payload
       * @return {void}
       */
      handleBookingEvent (payload) {
        this.$store.dispatch("booking/updateBookingListDataStatusById", {
          id: payload.booking.id,
          status: payload.booking.status
        });
      },

      updateBookingListener (data) {
        this.reloadList();
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {
      let params = _.clone(this.$route.query);
      Object.assign(params, this.populatePayload);
      const opts = {
        action: "browse",
        data: "listData",
        ref: "Booking Listing"
      };
      this.reloadList(null, opts, params);

      // populate(this, "fetchCustomers", "customersOpts", {
      //   ref: "Languages Options"
      // });
      populate(this, "loadLanguages", "languageOpts", {
        ref: "Languages Options"
      });
      this.$store.dispatch("dashboard/loadCountToExpire");
      this.$store.dispatch("dashboard/loadCountPending");

      if (window.WS) {
        forEach([
          'booking.cancelled',
          'booking.ended',
          'booking.expired',
          'booking.expiring',
          'booking.started'
        ], (channel) => {
          window.WS.subscribe(channel)
                .listen(channel, this.handleBookingEvent);
        });

        window.WS.channel('booking.accepted')
              .listen('booking.accepted', this.handleBookingEvent);
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > beforeDestroy
    |--------------------------------------------------------------------------
    */
    beforeDestroy () {
      // leave or stop listening to the channel booking
      if (window.WS) {
        window.WS.unsubscribe([
          'booking.cancelled',
          'booking.accepted',
          'booking.ended',
          'booking.expired',
          'booking.expiring',
          'booking.started'
        ]);

        window.WS.channel('booking.accepted')
              .unlisten('booking.accepted', this.handleBookingEvent);
      }
    }

  }; // End of export default
</script>

<style>
    .red-dot {
        background-color: #f56c6c !important;
        height: 10px;
        width: 10px;
        color: #f56c6c;
        margin: auto;
        border-radius: 50%;
        display: inline-block;
    }

    .green-dot {
        background-color: #67c23a !important;
        height: 10px;
        width: 10px;
        color: #67c23a;
        margin: auto;
        border-radius: 50%;
        display: inline-block;
    }

    .yellow-dot {
        background-color: #e6a23c !important;
        height: 10px;
        width: 10px;
        color: #e6a23c;
        margin: auto;
        border-radius: 50%;
        display: inline-block;
    }
</style>
