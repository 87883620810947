<template>
    <div class="app-form dialects-form">
        <el-form :model="form" :id="formElId" :ref="formElId">
            <el-form-item :id="genContainerId('name',formElId)"
                        label="Name" class="label-short">
                <el-input :id="genFieldId('name',formElId)"
                 v-model="form.name"/>
                </el-input>
            </el-form-item>
            <el-form-item :id="genContainerId('language_id',formElId)"
                        label="Language" class="label-short">
                <!-- <el-input :id="genFieldId('language_id',formElId)"
                 v-model="form.name"/>
                </el-input> -->
                <el-select filterable v-model="form.language_id" placeholder="Select Language" :id="genFieldId('language_id',formElId)">
                  <el-option v-for="language in languages" :key="language.id" :value="language.id" :label="language.name"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item :id="genContainerId('save_btn',formElId)">
                <el-button :id="genFieldId('save_btn',formElId)" type="primary">
                    Save
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {genFieldId, genContainerId} from "../../../js/helpers/Html";
    export default {
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                formElId: 'dialects-form',
                form: {
                    name: '',
                    language_id:''
                },
                languages: [
                    {id:1, name: 'Albanska',dialects: '', related_languages: ''},
                    {id:2, name: 'Amhariska',dialects:'Algeriet/Marocko/Tunisien, Irak/Libanon/Syrien/Egypten/Jordanien, Sudan, Somalisk', related_languages: ''},
                    {id:3, name: 'arabiska',dialects:'', related_languages: ''},
                ]
            };
        },// End of Component > data
    }// End of export default

</script>