/*
|--------------------------------------------------------------------------
| Financial > shared > props > inconvenienceCharge
|--------------------------------------------------------------------------
*/

'use strict';

import constants from './constants';
import {genPropsFromCollection} from '~/js/helpers/Vuex';

const inconvenienceFeesSubProps = genPropsFromCollection(
  constants.BOOKING_TYPES, {
    minimum_fee: 0,
    succeeding_fee: 0,
  }
);

export default {

  start_time: '00:00',
  end_time: '00:00',
  is_flat_rate: false,
  flat_rate: 0,

  inconvenience_fees: genPropsFromCollection(
    constants.TRANSLATOR_LVLS, inconvenienceFeesSubProps
  ),

  holiday_fees: genPropsFromCollection(
    constants.TRANSLATOR_LVLS, inconvenienceFeesSubProps
  ),

  weekend_fees: genPropsFromCollection(
    constants.TRANSLATOR_LVLS, inconvenienceFeesSubProps
  ),

}