<template>
    <div class="app-form price-text-translation-form">

        <div class="app-splitter two-cols app-flex-space-b-top">

            <!-- Invoice Form Container -->
            <div class="invoice-text-translation-form-container">

                <h3 class="app-title title-shaded">
                    <span>{{$t('invoice')}}</span>
                </h3>

                <el-form style="padding: 0 7px">
                    <financial-text-translation-fields
                        v-model="invoiceForm.text_translation"
                        form-type="invoice"
                    />
                </el-form>
            </div>

            <!-- Salary Form Container -->
            <div class="salary-text-translation-form-container">

                <h3 class="app-title title-shaded">
                    <span>{{$t('salary')}}</span>
                </h3>

                <el-form style="padding: 0 7px">
                    <financial-text-translation-fields
                        v-model="salaryForm.text_translation"
                        form-type="salary"
                    />
                </el-form>
            </div>

        </div>
        <!-- /.app-splitter two-cols -->

    </div> <!-- /.app-form price-text-translation-form -->
</template>

<script>
  import {mapState} from 'vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapState('financialInvoice', {
        invoiceForm: 'settings',
      }),

      ...mapState('financialSalary', {
        salaryForm: 'settings',
      }),
    },

  } // End of export default
</script>
