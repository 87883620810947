<template>
    <div class="app-list department-list">

        <custom-table-two
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="topControls">
                <el-row>
                    <el-col :span="12">
                        <el-button type="primary" icon="fa fa-plus" @click="handleAdd">
                            {{$t('add_item')}}
                        </el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-input :placeholder="$t('type_to_search')" v-model="search" @keyup.enter.native="handleSearch">
                            <el-select class="filter-dropdown" v-model="searchOption" slot="prepend"
                                       :placeholder="$t('select')">
                                <el-option label="ID" value="id"></el-option>
                                <el-option :label="$t('name')" value="name"></el-option>
                            </el-select>
                            <span class="el-icon-search el-input__icon app-cursor-pointer"
                                  slot="suffix" @click="handleSearch"></span>
                        </el-input>
                    </el-col>
                </el-row>

            </template>
            <template slot="usersColumn" slot-scope="scope" :label="$t('users')">
            </template>
            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="success" :title="$t('edit')" @click="handleEdit(scope)">
                    <span class="fa fa-pencil"></span>
                </el-button>
                <el-button class="circle" type="danger" :title="$t('remove')" @click="handleDelete(scope)">
                    <span class="fa fa-trash"></span>
                </el-button>
            </template>

        </custom-table-two>

        <!-- Pagination -->
        <el-pagination
                v-if="listPagination.total > listPagination.per_page"
                layout="prev, pager, next"
                :page-size="listPagination.per_page"
                :total="listPagination.total"
                :current-page="listPagination.current_page"
                @current-change="reloadList">
        </el-pagination>

    </div> <!-- /.app-list department-list -->
</template>

<script>
  import {mapGetters, mapActions} from "vuex";
  import {populate} from "../../../../js/helpers/Vuex";

  import {sd} from "~/js/helpers/Common";

  let moduleTarget = 'department';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {}, // End of Component > props

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {

        listCols: [
          {
            label: 'ID',
            prop: 'id',
            width: '',
            className: 'id-col'
          },
          {
            label: `${this.$t('name')}`,
            prop: 'name',
            width: '',
            className: 'name-col'
          }
        ], // End of listCols
        search: '',
        searchOption: 'name'
      };
    }, // End of Component > data

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      ...mapGetters(moduleTarget, ['listData', 'listPagination'])

    }, // End of Component > computed

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      ...mapActions(moduleTarget, {
        browse: 'browse',
        deleteEntry: 'delete'
      }),

      /**
       * Handler for edit event in the table.
       *
       * @param {object} scope
       *   The current scope(row) target.
       *
       * @return {void}
       */
      handleEdit (scope) {

        // Redirect to the edit item page.
        window.location.href = '/#/sector/' + moduleTarget + '/edit/' + scope.row.id;

      }, // End of handleEdit() method

      /**
       * Handler for add event in the table.
       *
       * @return {void}
       */
      handleAdd () {

        // Redirect to the add item page.
        window.location.href = '/#/sector/' + moduleTarget + '/add';

      }, // End of handleEdit() method

      /**
       * Handler for delete event in the table.
       *
       * @param {object} scope
       *   The current scope(row) target.
       *
       * @return {void}
       */
      handleDelete (scope) {

        this.$confirm('This will permanently delete the entry. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.deleteEntry({id: scope.row.id});
        }).catch(() => {
          // Silence is golden.
        });

      }, // End of handleDelete() method


      /**
       * Method for reloading the list.
       *
       * @param {int} n
       *   The target page number.
       *
       * @return {void}
       */
      reloadList (n) {
        let params = _.clone(this.$route.query);
        n = sd(n, sd(this.$route.query.page, 1)) // Set the page number.
        params.page = n;
        this.$router.push({query: params})
        populate(this, 'browse', '', {
          force: true,
          params,
          ref: 'Municipality Listing (forced)'
        })
      }, // End of reloadList() method

      handleSearch (e) {
        let params = {}
        if (this.searchOption == 'name') {
          params = {
            'filter[name]': this.search
          }
        } else {
          params = {
            'filter[id]': this.search
          }
        }
        params.page = 1
        this.$router.push({query: params})
        populate(this, 'browse', '', {
          force: true,
          params,
          ref: 'Municipality Listing (forced)'
        })
      }

    }, // End of Component > methods

    /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
    mounted () {

      populate(this, 'browse', 'listData', {
        payload: this.populatePayload,
        ref: 'Department Listing',
        force: true
      });

    } // End of Component > mounted

  } // End of export default
</script>
