
import {gep} from "~/js/helpers/Vuex";
let target = 'planned-languages';
let apiPrefix = window.app_api_prefix_3;
let composeProps = {
  language_id: '',
  rule: 'hours_before',
  data:{
    hours: '',
    offset_days: '',
    time: ''
  },
  offer_hours: ''
}
let asOptionsParams={
  all:true
}

export default {
  api: {
    browse: { method: 'get', path: gep(target, apiPrefix), params: { per_page: 10 }},
    browseAsOptions: { method: 'get', path: gep(target, apiPrefix), params: asOptionsParams},
    read: { method: 'get', path: gep(target + '/{id}', apiPrefix), params: {}},
    edit: { method: 'put', path: gep(target + '/{id}', apiPrefix), params: {}, data: {}, },
    add: { method: 'post', path: gep(target, apiPrefix), params: {}, data: {}, },
    delete: { method: 'delete', path: gep(target + '/{id}', apiPrefix), params: {}, data: {}, },
},

composeFresh: _.cloneDeep(composeProps),
compose: _.cloneDeep(composeProps),

payloadData: {}, // End of payloadData state

autoInjectPayload: true,
autoReflectItem: true,
autoUpdateList: true,
}