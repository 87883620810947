/*
|--------------------------------------------------------------------------
| Store > faq > getters
|--------------------------------------------------------------------------
|
| This file contains the getters property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';
import {isEmpty} from '~/js/helpers/Common'

export default {
  /**
   * get faq object state
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   * @return {object}
   */
  getFaq: (state) => state.faq,
  getSutitles: (state) => state.subtitles,
  getSutitle: (state) => state.subtitle,
}
