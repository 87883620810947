import {genColId, genContainerId, genFieldId, genBem} from "../helpers/Html";
import {populate} from "../helpers/Vuex";
import {sd} from "../helpers/Common";

export default {
  methods: {
    genColId,
    genFieldId,
    genContainerId,
    genBem,
    getBem: genBem,

    /**
     * Handler when the Back button has been clicked.
     */
    handleClickBack() {
      window.history.back()
    }, // End of handleClickBack() method

    /**
     * Method for reloading the list.
     *
     * @param {int} n
     *   The target page number.
     *
     * @param {object} opts
     *   Option values that is passable to the populate method. Expected are:
     *   - action
     *   - data
     *   - force
     *   - ref
     *
     * @param {object} params
     *   Query parameters that should pass onto the request, except page.
     *
     * @return {void}
     */
    reloadList(n, opts = {}, params = {}) {

      n = sd(n, sd(this.$route.query.page, 1)); // Set the page number.
      params.page = n;

      this.$router.push({query: params}); // Reflect the chosen parameters in the URL Bar.

      populate(this, sd(opts.action, 'browse'), sd(opts.data, ''), {
        force: sd(opts.force, true),
        params,
        ref: sd(opts.ref, 'Listing (forced)')
      })
    }, // End of reloadList() method
  },
}
