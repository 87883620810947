<template>
    <div class="app-page resource-compose-page">

        <page-header :title="$t('booking_resource')" :back-enabled="true"/>

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">

                    <div class="app-col full-width">
                        <div class="top-controls text-left">
                            <el-row>
                                <el-col :span="12">
                                    <el-button @click="window.history.back()">
                                        <span class="fa fa-caret-left" style="margin-right: 10px;"></span>
                                        {{$t('back')}}
                                    </el-button>
                                </el-col>
                                <el-col :span="12" class="text-right">
                                    <el-button type="primary" @click="handleRedirectAudits">
                                        {{$t('audit_logs')}}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="content-body">

                            <resource-compose-form :company="tableData"/>

                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page resources-compose-page -->
</template>

<script>
    import APICaller from "../../../../js/helpers/APICaller";
    import { gep } from "../../../../js/helpers/Vuex";
    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                tableData: [],
                window,
            };
        }, // End of Component > data

        methods: {
            handleRedirectAudits() {
                this.$router.push({
                    name:'audits',
                    params:{
                        id:this.$route.params.id,
                        auditable:'booking_resources'
                    }
                })
            },
        },

        mounted() {
        }, // End of Component > mounted

    } // End of export default
</script>
