<template>
    <div class="app-list cron-logs-list">
       
        <base-list-table
                :table-data="listData"
                :extra-cols="listCols"
                :hasDetailsCol="false">
            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="success" title="View">
                    <span class="fa fa-search"></span>
                </el-button>
            </template>
        </base-list-table>
    </div> <!-- /.app-list cron-logs-list -->
</template>

<script>
    // import {mapGetters} from vuex

        export default {

        /*
        |--------------------------------------------------------------------------
        | Components > props
        |--------------------------------------------------------------------------
        */
        props: {}, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Components > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                listCols: [
                    {
                        label: 'date',
                        prop: 'date',
                        width: '',
                        className: 'date-col',
                    }
                ],
                listData: [
                    {id: 1, date: '2018-08-01'},
                    {id: 2, date: '2018-08-02'},
                ]
            }
        }, // End of Component > data

        /*
        |--------------------------------------------------------------------------
        | Components > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            // ...mapGetters('',['listData'])

        }, // End of Component > computed

        /*
        |--------------------------------------------------------------------------
        | Components > watch
        |--------------------------------------------------------------------------
        */
        watch: {}, // End of Component > watch

        /*
        |--------------------------------------------------------------------------
        | Components > methods
        |--------------------------------------------------------------------------
        */
        methods: {}, // End of Component > methods


        /*
        |--------------------------------------------------------------------------
        | Components > mounted
        |--------------------------------------------------------------------------
        */
        mounted() {}, // End of Component > mounted

    }
</script>