<template>
  <div
    v-if="maintenanceMode"
    class="wrap-maintenance-mode">
    <div class="wrap-inner">
      <div class="inner icon">
        <img src="/images/maintenance-mode/maintenance.svg" alt="img-maintenance">
      </div>
      <div class="inner text">
        <p>This site is <b>under scheduled maintenance.</b></p>
        <p>Please <b>do not change</b> any bookings, customer data, etc.</p>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapState, mapActions} from 'vuex';
  import isNil from 'lodash/isNil';
  import isEmpty from 'lodash/isEmpty';

  export default {

    data () {
      return {
        maintenanceMode: false,
      }
    },

    computed: {
      ...mapState('maintenanceMode', {
        form: 'compose'
      })
    },

    methods: {
      ...mapActions('maintenanceMode', {
        getMaintenanceModeSettings: 'getMaintenanceModeSettings',
      }),

      prepGetMaintenanceSettings () {
        // Fix for unauthenticated call for getting maintenance mode value.
        if (!this.$store.getters['auth/hasAccessToken']) {
          return;
        }

        this.getMaintenanceModeSettings().then(r => {
          return !(
            !isNil(r.data.data) && !isEmpty(r.data.data)
            && !isNil(r.data.data.settings[0]) && !isEmpty(r.data.data.settings[0])
            && !isNil(r.data.data.settings[0].value) && !isEmpty(r.data.data.settings[0].value)
            && !isNil(r.data.data.settings[0].value.admin_panel)
            && !isEmpty(r.data.data.settings[0].value.admin_panel)
            && !isNil(r.data.data.settings[0].value.admin_panel.read_only)
            && parseInt(r.data.data.settings[0].value.admin_panel.read_only) === 0
          );
        });
      }
    },

    mounted () {
      this.prepGetMaintenanceSettings();
    }
  }
</script>

<style lang="scss">
  .wrap-maintenance-mode {
    background: #454545;
    color: white;
    height: 200px;

    .wrap-inner {
      .inner {
        width: 50%;

        &.icon {
          float: left;
          text-align: right;
          height: 200px;

          img {
            width: 170px;
            margin-right: 20px;
            margin-top: 15px;
          }
        }

        &.text {
          float: right;
          height: 130px;
          margin-top: 70px;

          p {
            font-size: 20px;
            margin: 0px;
          }
        }
      }
    }

    .clearfix {
      float: none;
    }
  }
</style>
