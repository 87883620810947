/*
|--------------------------------------------------------------------------
| Store > Booking > Batches > Actions
|--------------------------------------------------------------------------
|
| This file contains the actions property of the Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
*/

'use strict';

import {mapEndpoint, sd} from "~/js/helpers/Common";
import APICaller from "~/js/helpers/APICaller";
import API from '~/js/constants/api';
import {gep} from "~/js/helpers/Vuex";
import { notifSuccess, notifError } from '~/js/helpers/Notification';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import merge from 'lodash/merge';

export default {

  /**
   * Action for filtering batches.
   */
  filterBatchesList (context, payload) {

    const method = 'GET';
    const endpoint = mapEndpoint(`${API.BOOKINGS}/{id}/filtered-batches`, payload);
    const params = {
      include: 'entries.translator'
    };

    params[payload.via] = payload.query;

    return APICaller({method, endpoint, params})
      .then((r) => {
        const data = r.data.data;
        const meta = r.data.meta;
        const mode = 'set';

        context.commit('setBatches', {data, meta, mode});
      });
  },

  /**
   * Action mainly used for populating Batch List in an Infinite Scroll manner.
   *
   * @param {Object} context - Vuex store context.
   * @param {Object} payload - Necessary values.
   * @param {int} payload.id - Booking ID.
   * @param {string} [payload.mode] - Varies between set | append.
   * @param {Object} [payload.params] - Query params to be sent with the request.
   * @returns {Promise|*}
   */
  loadBatchesForward (context, payload) {
    if (isNil(payload.id)) throw new Error('Missing required value payload.id');

    const bookingId = payload.id;
    const mode = !isNil(payload.mode) ? payload.mode : 'set';
    const queryParams = !isNil(payload.params) && !isEmpty(payload.params) ? payload.params : false;

    context.commit('setBatchesLoading', true);

    let apiOpts = {
      endpoint: `${API.BOOKINGS}/${bookingId}/batch-entries`,
      method: 'GET',
      params: {
        page: context.state.batches.page,
        per_page: context.state.batches.per_page,
        include: 'translator,batch',
        sort: 'batch_id'
      }
    };

    if (queryParams) {

      // Page
      if (!isNil(queryParams.page) && queryParams.page !== '' && queryParams.page !== 0) {
        apiOpts.params.page = queryParams.page;
      }

      // Filter
      if (!isNil(queryParams.filter_query) && !isNil(queryParams.filter_by)) {
        apiOpts.params[`filter[${queryParams.filter_by}]`] = queryParams.filter_query;
      }

      if (!isNil(queryParams.filter_action)) {
        // Absence of Rejected
        if (!includes(queryParams.filter_action, 'rejected')) {
          apiOpts.params[`filter[null]`] = 'rejected_at';
        }

        // Absence of Not picked up call
        if (!includes(queryParams.filter_action, 'not_picked_up_call')) {
          apiOpts.params[`filter[is_called]`] = 0;
        }

        // Absence of Non sendable
        if (!includes(queryParams.filter_action, 'non_sendable')) {
          apiOpts.params[`filter[sendable]`] = 1;
        }
      }

      // Sort
      if (
        !isNil(queryParams.sort_by) && queryParams.sort_by !== ''
        && !isNil(queryParams.sort_order) && queryParams.sort_order !== ''
      ) {
        const order = queryParams.sort_order === 'asc' ? '' : '-';
        apiOpts.params['sort'] = order + queryParams.sort_by;
      }
    }

    return APICaller(apiOpts)
      .then((r) => {

        if (mode === 'set') {
          context.commit('resetBatches');
        }

        context.commit('setBatches', {
          data: r.data.data,
          meta: r.data.meta,
          mode
        });
      })
      .finally(() => {
        context.commit('setBatchesLoading', false);
      });
  },

  /**
   * Action mainly used for populating Batch List in an Infinite Scroll manner.
   *
   * @param {Object} context - Vuex store context.
   * @param {Object} payload - Necessary values.
   * @param {int} payload.id - Booking ID.
   * @param {string} [payload.mode] - Varies between set | append.
   * @returns {Promise|*}
   */
  loadBatchesScheduler (context, payload) {
    if (isNil(payload.id)) throw new Error('Missing required value payload.id');

    const bookingId = payload.id;
    const mode = !isNil(payload.mode) ? payload.mode : 'set';
    let params = {
      include: 'translator,batch',
      sort: 'batch_id'
    };

    params = merge(params, payload.params);

    return APICaller({
      endpoint: `${API.BOOKINGS}/${bookingId}/batch-entries`,
      method: 'GET',
      params
    })
      .then((r) => {
        context.commit('setBatchesScheduler', {
          data: r.data.data,
          meta: r.data.meta,
          mode
        });

      });
  },

  /**
   * Action for loading the batch list from the API.
   * This method is for the sole purpose of loading batches forwardly,
   * mainly used for Infinite scroll feature.
   *
   * @param  {object} context - the current scope of $store.
   * @param  {object} [payload] - an object which contains option values.
   *
   * @return {Promise}
   */
  loadBatchesForwardLegacy (context, payload) {
    context.commit('setBatchesLoading', true);

    payload.extendParams = {
      page: context.state.batches.page,
      per_page: context.state.batches.per_page,
      include: 'entries.translator,booking.feedback'
      // sort: 'id,-entries.sendable'
    };

    return new Promise((resolve, reject) => {
      context.dispatch('readGeneric', payload)
        .then((r) => {
          const data = r.data.data;
          const meta = r.data.meta;
          const mode = payload.mode;

          context.commit('setBatches', {data, meta, mode});
          resolve(r);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          context.commit('setBatchesLoading', false);
        });
    });
  },

  /**
   * Action to resend the notification according to the given batch ID.
   *
   * @param  {object} context - the current scope of $store.
   * @param  {object} [payload] - an object which contains option values.
   * @param  {int} [payload.id] - contains the booking id.
   * @param  {array} [payload.translators] - contains collection of translator ids.
   *
   * @return {Promise}
   */
  resendNotif (context, payload) {

    const method = 'post';
    const endpoint = mapEndpoint(`${API.BOOKINGS}/{id}/resend`, payload);
    const translators = sd(payload.translators, []);
    const channels = sd(payload.channels, []);
    const data = {translators, channels};

    return APICaller({method, endpoint, data}).then((r) => r);
  },

  /**
   * Action to get the state of the current booking
   *
   * @param  {object} context - the current scope of $store.
   * @param  {object} [payload] - an object which contains option values.
   * @param  {int} [payload.id] - contains the booking id.
   *
   * @return {Promise}
   */
  getBooking (context, payload) {

    const method = 'get';
    const endpoint = mapEndpoint(`${API.BOOKINGS}/{id}`, payload);

    const params = {
      include: [
        'assigned_translator',
        'notification_types',
        'translator_levels',
        'specific_translators',
        'customer.department.company.municipality',
        'towns'
      ].join(','),
      append:[
        'translators_sendable_count',
        'translators_non_sendable_count'
      ].join(',')
      // with: 'customers'
    };

    return APICaller({method, endpoint, params})
      .then((r) => {
        const raw = r.data.data; // Store into container so varname will be shorter.
        const data = raw[Object.keys(raw)[0]]; // Get the first member of the object.
        context.commit('setBooking', {data});
        return r;
      });
  },

  //get batches template list
  getBatchTemplateList (context, payload) {

    const method = 'get';
    const endpoint = gep('batch-templates', 'v3');

    return APICaller({method, endpoint})
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
      });
  },
  getBatchTemplateById (context, payload) {

    const method = 'get';
    const endpoint = gep(`batch-templates/${payload}`, 'v3');

    return APICaller({method, endpoint})
      .then((r) => {
        return r.data;
      })
      .catch((e) => {
        console.error(e);
      });
  },
  //END get batches template list

  deleteBatchTemplate (context, payload) {

    const method = 'delete';
    const endpoint = gep(`batch-templates/${payload.id}`, 'v3');

    return APICaller({method, endpoint})
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
      });
  },

  updateBatchTemplate (context, payload) {
    const method = 'put';
    const endpoint = gep(`batch-templates/${payload.id}`, 'v3');
    const data = {
      settings: payload.settings,
      name: payload.settings.template_name
    }

    return APICaller({method, endpoint, data: data, isDataRaw: true})
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
      });
  },

  createBatchTemplate (context, payload) {
    console.log(payload)
    const method = 'post';
    const endpoint = gep(`batch-templates`, 'v3');
    const data = {
      settings: payload,
      name: payload.template_name
    }
    return APICaller({method, endpoint, data: data, isDataRaw: true})
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
      });
  },


  /**
   * Action to assign the booking to a translator.
   *
   * @param  {object} context - the current scope of $store.
   * @param  {object} [payload] - an object which contains option values.
   * @param  {int} [payload.id] - contains the booking id.
   * @param  {int} [payload.translator_id] - contains the translator's id.
   *
   * @return {Promise}
   */
  assignToTranslator (context, payload) {
    const {translator_id} = payload;
    const method = 'post';
    const endpoint = mapEndpoint(`${API.BOOKINGS}/{id}/accept`, payload);
    const data = {translator_id};

    return APICaller({method, endpoint, data}).then((r) => {
      notifSuccess({ message: 'Translator assigned' });
    });
  },

  /**
   * Action to withdraw (or unassign) translator form the booking.
   *
   * @param  {object} context - the current scope of $store.
   * @param  {object} [payload] - an object which contains option values.
   * @param  {int} [payload.id] - contains the booking id.
   *
   * @return {Promise}
   */
  withdrawFromTranslator (context, payload) {
    const method = 'post';
    const endpoint = mapEndpoint(`${API.BOOKINGS}/{id}/withdraw`, payload);

    return APICaller({method, endpoint}).then((r) => r);
  },
  /**
   * Action for fetching translator tries to accept list
   * @param {*} context
   * @param {*} payload
   */
  getTranslatorTriesToAcceptList (context, payload) {
    let apiPrefix = 'v3'
    let params = {
      include: 'translator.translator_data'
    }
    return APICaller({
      method: "get",
      params,
      endpoint: gep(`bookings/${payload}/late-accept-attempts`, apiPrefix)
    })
      .then(response => {
        console.log(response.data);
        context.commit("setTranslatorTriesToAcceptList", response.data.data);
      })
      .catch(e => {
        console.log(e.response);
        return e;
      });

  },
  updateSendable (context, payload) {
    const method = 'post';
    const endpoint = `${API.BATCHES}/${payload.id}/update`;

    return APICaller({method, endpoint, data: payload})
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
      });
  },
  readNonSendable (context, payload) {
    context.commit('setBatchesLoading', true);

    const method = 'get';
    const params = {
      'filter[entries.sendable]': 0,
      include: 'entries.translator',
      page: context.state.batchesNonSendable.page,
      per_page: context.state.batchesNonSendable.per_page

    }

    const endpoint = `${API.BOOKINGS}/${payload.id}/batches`;

    return APICaller({method, endpoint, params: params})
      .then((r) => {
        const data = r.data.data;
        const meta = r.data.meta;
        const mode = payload.mode;

        context.commit('setBatchesNonSendable', {data, meta, mode});
        context.commit('setBatchesLoading', false);

        return r;
      })
      .catch((e) => {
        context.commit('setBatchesLoading', false);

        return e;
      });
  },

  /*,
  loadBatchesForwardNonSendable(context, payload) {
    const method = 'get';
    const params: {
      'filter[entries.sendable]':1,
      include: 'entries.translator'
    }
    const endpoint = `${API.BOOKINGS}/${payload.id}/batches`;

    return APICaller({method, endpoint, data:payload,params: params})
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
      });
  },*/


  filterBatchesListNonSendableLegacy (context, payload) {

    const method = 'GET';
    const endpoint = mapEndpoint(`${API.BOOKINGS}/{id}/filtered-batches`, payload);
    const params = {
      'filter[entries.sendable]': 0,
      include: 'entries.translator'
    };

    params[payload.via] = payload.query;

    return APICaller({method, endpoint, params})
      .then((r) => {
        const data = r.data.data;
        const meta = r.data.meta;
        const mode = 'set';

        context.commit('setBatchesNonSendable', {data, meta, mode});
        return r;
      });
  },

  /**
   * Action for fetching the status of prioapp
   * @param {*} context
   * @param {*} payload
   */
  getBatchStatus (context, payload) {
    let apiPrefix = 'v3'
    return APICaller({
      method: "get",
      endpoint: gep(`calculations/${payload.id}/status`, apiPrefix)
    })
      .then(response => {
        let data = response.data.data.item;
        context.commit('setBatchesStatus', data.status)
      })
      .catch(e => {
        console.log(e.response);
        return e;
      });

  }

} // End of export default
