import Vue from 'vue';
import InputTag from 'vue-input-tag'
/*
|--------------------------------------------------------------------------
| Forms
|--------------------------------------------------------------------------
*/

// Financial > Price
Vue.component('price-business-rules-form', require('../components/forms/financial/price/PriceBusinessRulesForm').default);
Vue.component('price-charging-form', require('../components/forms/financial/price/PriceChargingForm').default);
Vue.component('price-transaction-fee-form', require('../components/forms/financial/price/PriceTransactionFeeForm').default);
Vue.component('price-emergency-booking-form', require('../components/forms/financial/price/PriceEmergencyBookingForm').default);
Vue.component('price-travel-settings-form', require('../components/forms/financial/price/PriceTravelSettingsForm').default);
Vue.component('price-inconvenience-charge-form', require('../components/forms/financial/price/PriceInconvenienceChargeForm').default);
Vue.component('price-late-cancellations-form', require('../components/forms/financial/price/PriceLateCancellationsForm').default);
Vue.component('price-language-settings-form', require('../components/forms/financial/price/PriceLanguageSettingsForm').default);
Vue.component('price-text-translation-form', require('../components/forms/financial/price/PriceTextTranslationForm').default);
Vue.component('price-other-settings-form', require('../components/forms/financial/price/PriceOtherSettingsForm').default);

// Financial > Shared > Fields
Vue.component('financial-business-rules-fields', require('../components/forms/financial/price/shared/FinancialBusinessRulesFields').default);
Vue.component('financial-charging-fields', require('../components/forms/financial/price/shared/charging/FinancialChargingFields').default);
Vue.component('financial-charging-timings-fields', require('../components/forms/financial/price/shared/charging/FinancialChargingTimingsFields').default);
Vue.component('financial-charging-use-parts-fields', require('../components/forms/financial/price/shared/charging/FinancialChargingUsePartsFields').default);
Vue.component('financial-travel-settings-fields', require('../components/forms/financial/price/shared/FinancialTravelSettingsFields').default);
Vue.component('financial-inconvenience-charge-fields', require('../components/forms/financial/price/shared/FinancialInconvenienceChargeFields').default);
Vue.component('financial-additional-shared-fields', require('../components/forms/financial/additional/FinancialAdditionalSharedFields').default);
Vue.component('financial-transaction-fee-fields', require('../components/forms/financial/price/shared/FinancialTransactionFeeFields').default);
Vue.component('financial-emergency-booking-fields', require('../components/forms/financial/price/shared/FinancialEmergencyBookingFields').default);
Vue.component('financial-late-cancellations-fields', require('../components/forms/financial/price/shared/FinancialLateCancellationsFields').default);
Vue.component('financial-language-settings-fields', require('../components/forms/financial/price/shared/FinancialLanguageSettingsFields').default);
Vue.component('financial-text-translation-fields', require('../components/forms/financial/price/shared/FinancialTextTranslationFields').default);
Vue.component('financial-other-settings-fields', require('../components/forms/financial/price/shared/FinancialOtherSettingsFields').default);

// Financial > Invoice
Vue.component('invoice-compose-form', require('../components/forms/financial/invoice/InvoiceComposeForm').default);

// Sector
Vue.component('municipality-compose-form', require('../components/forms/sectors/municipality/MunicipalityComposeForm').default);
Vue.component('company-main-fields', require('../components/forms/sectors/company/CompanyMainFields').default);
Vue.component('department-main-fields', require('../components/forms/sectors/department/DepartmentMainFields').default);
Vue.component('department-search', require('../components/forms/sectors/department/DepartmentSearch').default);
Vue.component('sector-contact-info-fields', require('../components/forms/sectors/shared/SectorContactInfoFields').default);
Vue.component('sector-address-fields', require('../components/forms/sectors/shared/SectorAddressFields').default);
Vue.component('email-setting-fields', require('../components/forms/sectors/shared/EmailSettingFields').default);
Vue.component('sector-settings-fields', require('../components/forms/sectors/shared/SectorSettingsFields').default);
Vue.component('sector-financial-settings-form', require('../components/forms/sectors/shared/SectorFinancialSettingsForm').default);
Vue.component('sector-batch-settings-form', require('../components/forms/sectors/shared/SectorBatchSettingsForm').default);
Vue.component('sector-batch-settings-booking-fields', require('../components/forms/sectors/shared/SectorBatchSettingsBookingsFields').default);
Vue.component('sector-batch-settings-trans-lvls-prio-fields', require('../components/forms/sectors/shared/SectorBatchSettingsTransLvlsPrioFields').default);
Vue.component('sector-batch-settings-max-travel-fields', require('../components/forms/sectors/shared/SectorBatchSettingsMaxTravelFields').default);
Vue.component('invoice-reminder-settings-fields', require('../components/forms/sectors/shared/InvoiceReminderSettingsFields').default);


//Batch Settings Template
Vue.component('batch-settings-template-page', require('../components/pages/batches/BatchSettingTemplatePage').default);
Vue.component('batch-settings-template-form', require('../components/forms/batch-setting-template/BatchSettingTemplateForm').default);

// Booking
Vue.component('booking-filter-form', require('../components/forms/booking/BookingFilterForm').default);
Vue.component('booking-edit-form', require('../components/forms/booking/BookingEditForm').default);
Vue.component('fixed-text-translation-form', require('../components/forms/booking/TextTranslation/FixedPriceForm').default);
Vue.component('nonfixed-text-translation-form', require('../components/forms/booking/TextTranslation/NonFixedPriceForm').default);
// Vue.component('create-text-translation-form',require('../components/forms/booking/TextTranslation/CreateForm').default);
Vue.component('booking-status-select', require('../components/forms/booking/partials/BookingStatusSelect').default);
Vue.component('translator-select', require('../components/forms/booking/partials/TranslatorSelect').default);
Vue.component('multiple-translator-select', require('../components/forms/booking/partials/MultipleTranslatorSelect').default);
Vue.component('booking-feedback', require('../components/forms/booking/partials/FeedbackComponent.vue').default);
Vue.component('booking-traveltime', require('../components/forms/booking/partials/TraveltimeComponent.vue').default);
// User
Vue.component('user-list-filter-form', require('../components/forms/user/UserListFilterForm').default);
Vue.component('admin-user-compose-form', require('../components/forms/user/AdminUserComposeForm').default);
Vue.component('customer-allow-video-booking-fields', require('../components/forms/user/CustomerAllowVideoBookingFields').default);
Vue.component('user-financial-form', require('../components/forms/user/UserFinancialForm').default);
Vue.component('user-statistics-panel', require('../components/lists/user/UserStatisticsPanel').default);
Vue.component('user-info-panel', require('../components/lists/user/UserInfoPanel').default);
Vue.component('user-files-list-panel', require('../components/lists/user/UserFilesListPanel').default);
Vue.component('user-projects-type-panel', require('../components/lists/user/UserProjectTypePanel').default);
Vue.component('user-bookings-list-panel', require('../components/lists/user/UserBookingListPanel').default);
Vue.component('custom-table', require('../components/lists/custom-table/customTable').default);
Vue.component('custom-company-list', require('../components/lists/custom-table/CustomCompanyList.vue').default);
Vue.component('custom-user-list', require('../components/lists/custom-table/customUserList').default);
Vue.component('custom-table-two', require('../components/lists/custom-table/customTableTwo').default);
Vue.component('custom-table-user-access-logs', require('../components/lists/custom-table/customTableUserAccessLogs').default);
Vue.component('user-files-list', require('../components/lists/user/UserFilesListPanel').default);
Vue.component('user-latest-checkings', require('../components/lists/user/UserLatestCheckingsPanel').default);
Vue.component('custom-checkins-list', require('../components/lists/custom-table/customCheckinsList').default);
Vue.component('user-unavailable-times', require('../components/lists/user/UserUnavailableTimes').default);
//AES
Vue.component('municipality-mappings-form', require('../components/forms/aes/MunicipalityMappingsForm').default);
Vue.component('company-mappings-form', require('../components/forms/aes/CompanyMappingsForm').default);
Vue.component('user-mappings-form', require('../components/forms/aes/UserMappingsForm').default);
Vue.component('booking-mappings-form', require('../components/forms/aes/BookingMappingsForm').default);
Vue.component('department-mappings-form', require('../components/forms/aes/DepartmentMappingsForm').default);

//Translation Manager

Vue.component('locale-form', require('../components/forms/translation-manager/LocaleForm').default);
Vue.component('translation-form', require('../components/forms/translation-manager/TranslationForm').default);

//Languages
Vue.component('dialects-form', require('../components/forms/languages/DialectsForm').default);
Vue.component('languages-field', require('../components/forms/languages/LanguagesField').default);
Vue.component('languages-translator-levels-field',
  require('../components/forms/languages/LanguagesTranslatorLevelsField').default);

// settings
Vue.component('basic-settings-form', require('../components/forms/settings/BasicSettingsForm').default);
Vue.component('price-list-settings-form', require('../components/forms/settings/PriceListSettingsForm').default);
Vue.component('test-languages-settings-form', require('../components/forms/settings/TestLanguagesForm').default);
Vue.component('taxes-settings-form', require('../components/forms/settings/TaxesSettingsForm').default);
Vue.component('batches-settings-form', require('../components/forms/settings/BatchesSettingsForm').default);
Vue.component('batches-settings-shared-fields', require('../components/forms/settings/BatchesSettingsSharedFields').default);
Vue.component('kpi-settings', require('../components/forms/settings/KpiSettings').default);
Vue.component('default-municipality-settings', require('../components/forms/settings/DefaultMunicipality').default);
Vue.component('maintenance-mode-settings', require('../components/forms/settings/MaintenanceModeSettings').default);
Vue.component('admin-permissions-roles-list-settings-form', require('../components/forms/settings/AdminPermissionsRolesForm').default);
Vue.component('admin-permissions-form', require('../components/forms/admin-roles-and-permissions/AdminPermissionsForm').default);
Vue.component('admin-permissions-form-by-admin', require('../components/forms/admin-roles-and-permissions/AdminPermissionsFormByAdmin').default);
Vue.component('kpi-settings', require('../components/forms/settings/KpiSettings').default);
Vue.component('planned-language-form', require('../components/forms/settings/PlannedLanguagesForm').default);
Vue.component('admin-ui-settings', require('../components/forms/settings/AdminUiSettings').default);

// Booking Resources
Vue.component('resource-compose-form', require('../components/forms/booking/resources/ResourceComposeForm').default);

/*
|--------------------------------------------------------------------------
| Lists
|--------------------------------------------------------------------------
*/
Vue.component('base-list-table', require('../components/lists/base/BaseListTable').default);
Vue.component('base-booking-list', require('../components/lists/base/BaseBookingList').default);
Vue.component('base-bread-list', require('../components/lists/base/BaseBreadList').default);
Vue.component('batch-settings-list', require('../components/lists/batches/BatchSettingsList').default);
//Communication logs
Vue.component('sms-logs-list', require('../components/lists/logs/SmsLogsList.vue').default);
Vue.component('email-logs-list', require('../components/lists/logs/EmailLogsList.vue').default);
Vue.component('fax-logs-list', require('../components/lists/logs/FaxLogsList.vue').default);
Vue.component('push-logs-list', require('../components/lists/logs/PushLogsList.vue').default);
Vue.component('cron-logs-list', require('../components/lists/logs/CronLogsList.vue').default);
Vue.component('admin-logs-list', require('../components/lists/logs/AdminLogsList.vue').default);

Vue.component('user-access-logs-list', require('../components/lists/logs/UserAccessLogsList.vue').default);

// Booking
Vue.component('booking-list', require('../components/lists/booking/BookingList').default);
Vue.component('booking-about-expired-list', require('../components/lists/booking/BookingAboutExpiredList').default);
Vue.component('booking-to-work-on-list', require('../components/lists/booking/BookingToWorkOnList').default);
Vue.component('booking-is-difficult-list', require('../components/lists/booking/BookingIsDifficultList').default);
Vue.component('booking-base-list', require('../components/lists/booking/BookingBaseList').default);
Vue.component('booking-trail-log-list', require('../components/lists/booking/BookingTrailLogList').default);
Vue.component('booking-histories-audit-list', require('../components/lists/booking/BookingHistoryAuditList').default);
Vue.component('booking-planned-list', require('../components/lists/booking/BookingPlannedList').default);
Vue.component('booking-to-be-planned-list', require('../components/lists/booking/BookingToBePlannedList').default);
Vue.component('booking-cannot-be-planned-list', require('../components/lists/booking/BookingCannotBePlannedList').default);

// User
Vue.component('user-list', require('../components/lists/user/UserList').default);
Vue.component('admin-user-list', require('../components/lists/user/AdminUserList').default);
Vue.component('admin-deleted-user-list', require('../components/lists/user/AdminDeletedUserList').default);
Vue.component('deleted-user-list', require('../components/lists/user/DeletedUserList').default);

// AES Mappings
Vue.component('municipality-mappings-list', require('../components/lists/aes/MunicipalityMappingsList').default);
Vue.component('company-mappings-list', require('../components/lists/aes/CompanyMappingsList').default);
Vue.component('user-mappings-list', require('../components/lists/aes/UserMappingsList').default);
Vue.component('department-mappings-list', require('../components/lists/aes/DepartmentMappingsList').default);
Vue.component('booking-mappings-list', require('../components/lists/aes/BookingMappingsList').default);

// Translation Manager
Vue.component('translation-languages-list', require('../components/lists/translation-manager/TranslationsLanguagesList').default);
Vue.component('locale-group-list', require('../components/lists/translation-manager/LocaleGroupList').default);

// Languages
Vue.component('languages-list', require('../components/lists/languages/LanguagesList').default);
Vue.component('dialects-list', require('../components/lists/languages/DialectsList').default);

// Settings
Vue.component('financial-settings-list', require('../components/lists/settings/FinancialList').default);
Vue.component('holidays-settings-list', require('../components/lists/settings/HolidaysList').default);
Vue.component('test-users-settings-list', require('../components/lists/settings/TestUsersList').default);
Vue.component('batches-settings-list', require('../components/lists/settings/BatchesList').default);
Vue.component('customer-types', require('../components/lists/settings/CustomerTypes').default);
Vue.component('admin-permissions-roles-settings-list', require('../components/lists/settings/AdminPermissionsRolesList').default);
Vue.component('expense-types-list', require('../components/lists/settings/ExpenseTypesList').default);
Vue.component('planned-languages-list', require('../components/lists/settings/PlannedLanguagesList').default);
Vue.component('partners-list', require('../components/lists/settings/PartnersList').default);

// Sectors
Vue.component('municipality-files-list-panel', require('../components/lists/sectors/municipality/MunicipalityFilesListPanel').default);
Vue.component('municipality-list', require('../components/lists/sectors/municipality/MunicipalityList').default);
Vue.component('company-list', require('../components/lists/sectors/company/CompanyList').default);
Vue.component('department-list', require('../components/lists/sectors/department/DepartmentList').default);

// Financial
Vue.component('invoice-list', require('../components/lists/financial/invoice/InvoiceList').default);
Vue.component('financial-price-template-list', require('../components/lists/financial/FinancialPriceTemplateList').default);

//text translation list
Vue.component('text-translation-list', require('../components/lists/text-translation/List').default);

//feedback
Vue.component('low-feedback-list', require('../components/lists/feedback-manager/LowFeedbackList').default);

// Audits
Vue.component('audits-list', require('../components/lists/audits/AuditList').default);
// Resources
Vue.component('resources-list', require('../components/lists/booking/resources/ResourcesList').default);

/*
|--------------------------------------------------------------------------
| Displays
|--------------------------------------------------------------------------
*/
Vue.component('dashboard-count-display', require('../components/displays/dashboard/DashboardCountDisplay').default);
Vue.component('dashboard-circle-gauge-display', require('../components/displays/dashboard/DashboardCircleGaugeDisplay').default);
Vue.component('dashboard-company-report-display', require('../components/displays/dashboard/DashboardCompanyReportDisplay').default);
Vue.component('dashboard-user-blocked-booking', require('../components/displays/dashboard/DashboardUserBlockedBooking').default);
Vue.component('simple-count-display', require('../components/displays/SimpleCountDisplay').default);
Vue.component('preloader-gear', require('../components/displays/PreloaderGear').default);

// Booking Details
Vue.component('booking-details-info-highlights', require('../components/displays/booking-details/BookingDetailsInfoHighlights').default);

// Maintenance Mode
Vue.component('maintenance-mode-display', require('../components/displays/maintenance-mode/MaintenanceMode.vue').default);

/*
|--------------------------------------------------------------------------
| Modals
|--------------------------------------------------------------------------
*/
Vue.component('compose-modal', require('../components/modals/bases/ComposeModal').default);
Vue.component('base-details-modal', require('../components/modals/bases/BaseDetailsModal').default);
Vue.component('confirmation-fills-modal', require('~/components/modals/booking/ConfirmationFillsModal').default);
Vue.component('resend-notif-confirmation', require('~/components/modals/translator/ResendNotifConfirmation').default);
Vue.component('todo-form', require('~/components/modals/booking/TodoForm').default);
Vue.component('municipality-batch-setting', require('~/components/modals/municipality/MunicipalityBatchSetting').default);
Vue.component('upload-files-modal', require('~/components/modals/uploads/UploadFilesModal').default);
Vue.component('send-gdpr-data', require('~/components/modals/translator/SendGdprData').default);
Vue.component('temporary-number-modal', require('~/components/modals/translator/TemporaryNumberModal').default);
Vue.component('create-department', require('~/components/modals/booking/CreateDepartment').default);
Vue.component('locked-booking-modal', require('~/components/modals/booking/LockedBookingModal').default);
Vue.component('export-booking-modal', require('~/components/modals/booking/ExportBookingModal').default);
Vue.component('clone-booking-modal', require('~/components/modals/booking/CloneBooking').default);
Vue.component('create-subtitle-modal', require('../components/modals/faq/CreateSubtitleModal').default);
Vue.component('create-faq-modal', require('../components/modals/faq/CreateFaqModal').default);
Vue.component('locked-booking-alert-modal', require('~/components/modals/booking/LockedBookingAlertModal').default);
Vue.component('partners-modal', require('~/components/modals/settings/PartnersModal').default);

/*
|--------------------------------------------------------------------------
| Layouts
|--------------------------------------------------------------------------
*/
Vue.component('master-layout', require('../components/layouts/master-layout').default);

/*
|--------------------------------------------------------------------------
| Navs
|--------------------------------------------------------------------------
*/
Vue.component('sidebar-nav', require('../components/navs/sidebar/SidebarNav').default);
Vue.component('sidebar-nav-item', require('../components/navs/sidebar/SidebarNavItem').default);
Vue.component('sidebar-nav-user', require('../components/navs/sidebar/SidebarNavUser').default);
Vue.component('sidebar-nav-set', require('../components/navs/sidebar/SidebarNavSet').default);

/*
|--------------------------------------------------------------------------
| Pages
|--------------------------------------------------------------------------
*/
Vue.component('login-page', require('../components/pages/user/LoginPage').default);
Vue.component('logout-page', require('../components/pages/user/LogoutPage').default);
Vue.component('dashboard-page', require('../components/pages/misc/DashboardPage').default);
Vue.component('financial-price-template-list-page', require('../components/pages/financial/PriceTemplateListPage').default);
Vue.component('financial-price-template-compose-page', require('../components/pages/financial/PriceTemplateComposePage').default);
Vue.component('invoice-list-page', require('../components/pages/financial/InvoiceListPage').default);
Vue.component('municipality-page', require('../components/pages/sectors/municipality/MunicipalityPage').default);
Vue.component('municipality-compose-page', require('../components/pages/sectors/municipality/MunicipalityComposePage').default);
Vue.component('company-compose-page', require('../components/pages/sectors/company/CompanyComposePage').default);
Vue.component('import-batch-template', require('../components/pages/sectors/company/ImportBatchTemplate').default);
Vue.component('department-compose-page', require('../components/pages/sectors/department/DepartmentComposePage').default);
Vue.component('company-page', require('../components/pages/sectors/company/CompanyPage').default);
Vue.component('department-page', require('../components/pages/sectors/department/DepartmentPage').default);
Vue.component('bookings-page', require('../components/pages/booking/BookingsPage').default);
Vue.component('bookings-about-expired-page', require('../components/pages/booking/BookingsAboutExpiredPage').default);
Vue.component('bookings-to-work-on-page', require('../components/pages/booking/BookingsToWorkOnPage').default);
Vue.component('bookings-is-difficult-page', require('../components/pages/booking/BookingsIsDifficultPage').default);
Vue.component('bookings-planned-page', require('../components/pages/booking/BookingsPlannedPage').default);
Vue.component('bookings-to-be-planned-page', require('../components/pages/booking/BookingsToBePlannedPage').default);
Vue.component('bookings-cannot-be-planned-page', require('../components/pages/booking/BookingsCannotBePlannedPage').default);
Vue.component('bookings-pending-for-future', require('../components/pages/booking/BookingPendingForFuture.vue').default);
Vue.component('booking-details-page', require('../components/pages/booking/BookingDetailsPage').default);
Vue.component('booking-edit-page', require('../components/pages/booking/BookingEditPage').default);
Vue.component('booking-financial-adjustment-page', require('../components/pages/booking/BookingFinancialAdjustmentPage').default);
Vue.component('booking-trail-logs-page', require('../components/pages/booking/BookingTrailLogsPage').default);
Vue.component('user-list-page', require('../components/pages/user/UserListPage').default);
Vue.component('admin-user-compose-page', require('../components/pages/user/AdminUserComposePage').default);
Vue.component('admin-user-list-page', require('../components/pages/user/AdminUserListPage').default);
Vue.component('send-sms-page', require('../components/pages/misc/SendSmsPage').default);
Vue.component('send-fax-page', require('../components/pages/misc/SendFaxPage').default);
Vue.component('page-not-found', require('../components/pages/misc/NotFoundPage').default);
Vue.component('page-unauthorized', require('../components/pages/misc/Unauthorized').default);
Vue.component('sms-logs-page', require('../components/pages/logs/SmsLogsPage.vue').default);
Vue.component('email-logs-page', require('../components/pages/logs/EmailLogsPage.vue').default);
Vue.component('fax-logs-page', require('../components/pages/logs/FaxLogsPage.vue').default);
Vue.component('push-logs-page', require('../components/pages/logs/PushLogsPage.vue').default);
Vue.component('cron-logs-page', require('../components/pages/logs/CronLogsPage.vue').default);
Vue.component('admin-logs-page', require('../components/pages/logs/AdminLogsPage.vue').default);
Vue.component('user-access-logs-page', require('../components/pages/logs/UserAccessLogsPage.vue').default);
Vue.component('text-bookings-page', require('../components/pages/booking/TextBookingsPage.vue').default);
Vue.component('text-bookings-bids-page', require('../components/pages/booking/TextTranslation/BidsFormPage.vue').default);
Vue.component('text-bookings-review-page', require('../components/pages/booking/TextTranslation/ReviewPage.vue').default);
Vue.component('translators-list-tabs-page', require('../components/pages/batches/TranslatorListTabsPage.vue').default);
Vue.component('com-logs-list-page', require('../components/pages/logs/ComLogsPage.vue').default);
Vue.component('booking-histories-audit-page', require('../components/pages/booking/BookingHistoriesAuditPage.vue').default);
Vue.component('audits-page', require('../components/pages/audits/AuditsPage.vue').default);
Vue.component('user-profile-page', require('../components/pages/user/UserProfilePage.vue').default);
Vue.component('booking-availability-page', require('../components/pages/booking/BookingAvailabilityPage').default);
Vue.component('late-withdrawal-page', require('../components/pages/user/LateWithdrawalListPage').default);
Vue.component('user-customer-compose-page', require('../components/pages/user/UserCustomerComposePage').default);
Vue.component('user-translator-compose-page', require('../components/pages/user/UserTranslatorComposePage').default);
Vue.component('user-translator-bookings-weekly-page', require('../components/pages/user/UserTranslatorBookingsWeeklyPage').default);
Vue.component('financial-export-page', require('../components/pages/financial/FinancialExportPage').default);
Vue.component('financial-export-process-page', require('../components/pages/financial/FinancialExportProcessPage').default);
Vue.component('batch-setting-compose-page', require('../components/pages/batches/BatchSettingComposePage').default);
Vue.component('quick-stats-page', require('../components/pages/quick-stats/quickStats').default);
//Employees list
Vue.component('employees-page', require('../components/pages/user/EmployeesPage').default);
Vue.component('employees-list', require('../components/lists/user/EmployeesList').default);
Vue.component('user-translator-employee-bookings-weekly-page', require('../components/pages/user/UserTranslatorEmployeeBookingsWeeklyPage').default);
Vue.component('financial-settings-list-page', require('../components/pages/financial/FinancialSettingsListPage').default);

//AES Mappings
Vue.component('municipality-mapings-page', require('../components/pages/aes/MunicipalityMappingsPage').default);
Vue.component('municipality-mappings-form-page', require('../components/pages/aes/MunicipalityMappingsFormPage').default);
Vue.component('company-mappings-page', require('../components/pages/aes/CompanyMappingsPage').default);
Vue.component('company-mappings-form-page', require('../components/pages/aes/CompanyMappingsFormPage').default);
Vue.component('user-mappings-page', require('../components/pages/aes/UserMappingsPage').default);
Vue.component('user-mappings-form-page', require('../components/pages/aes/UserMappingsFormPage').default);
Vue.component('department-mappings-page', require('../components/pages/aes/DepartmentMappingsPage').default);
Vue.component('department-mappings-form-page', require('../components/pages/aes/DepartmentMappingsFormPage').default);
Vue.component('booking-mappings-page', require('../components/pages/aes/BookingMappingsPage').default);
Vue.component('booking-mappings-form-page', require('../components/pages/aes/BookingMappingsFormPage').default);

//settings
Vue.component('settings-page', require('../components/pages/settings/SettingsPage').default);
Vue.component('price-list-settings-page', require('../components/pages/settings/PriceListPage').default);
Vue.component('batches-settings-compose-page', require('../components/pages/settings/BatchesSettingsComposePage').default);

// Admin Permissions and Roles
Vue.component('admin-permissions-roles-list-page', require('../components/pages/admin-roles-and-permissions/AdminPermissionsRolesListPage').default);
Vue.component('admin-permissions-roles-list-settings-page', require('../components/pages/settings/AdminPermissionsRolesPage').default);
Vue.component('admin-permissions-list-page', require('../components/pages/admin-roles-and-permissions/AdminPermissionsListPage').default);
Vue.component('admin-permissions-list', require('../components/lists/admin-roles-and-permissions/AdminPermissionsList').default);
Vue.component('admin-permissions-list-by-admin', require('../components/lists/admin-roles-and-permissions/AdminPermissionsListByAdmin').default);
Vue.component('admin-permissions-form-page', require('../components/pages/admin-roles-and-permissions/AdminPermissionsPage').default);

//Text Translation Bookings
Vue.component('fixed-text-translation-page', require('../components/pages/booking/TextTranslation/FixedPricePage').default);
Vue.component('nonfixed-text-translation-page', require('../components/pages/booking/TextTranslation/NonFixedPricePage').default);
Vue.component('create-text-translation-page', require('../components/pages/booking/CreateTextTranslationBookingPage').default);
Vue.component('create-text-booking-page', require('../components/pages/booking/CreateTextBookingPage').default);

//translation manager

Vue.component('translation-manager-page', require('../components/pages/translation-manager/TranslationManagerPage').default);
Vue.component('locale-form-page', require('../components/pages/translation-manager/LocaleFormPage').default);
Vue.component('translation-form-page', require('../components/pages/translation-manager/TranslationFormPage').default);
Vue.component('locale-group-list-page', require('../components/pages/translation-manager/LocaleGroupListPage').default);

// Languages

Vue.component('languages-list-page', require('../components/pages/languages/LanguagesListPage').default);
Vue.component('languages-form-page', require('../components/pages/languages/LanguagesFormPage').default);
Vue.component('dialects-list-page', require('../components/pages/languages/DialectsListPage').default);
Vue.component('dialects-form-page', require('../components/pages/languages/DialectsFormPage').default);

//make booking

Vue.component('bookings-form-page', require('../components/pages/booking/CreateBookingPage').default);

//Feedback Page
Vue.component('low-feedback-page', require('../components/pages/feedback-manager/LowFeedbackPage').default);
Vue.component('solved-low-feedback-page', require('../components/pages/feedback-manager/SolvedLowFeedbackPage').default);
Vue.component('feedback-list-page', require('../components/pages/feedback-manager/FeedbackListPage').default);
Vue.component('low-feedback-details-page', require('../components/pages/feedback-manager/LowFeedbackDetailsPage').default);
Vue.component('low-feedback-details-modal', require('../components/modals/feedback-manager/ReportToCustomerModal').default);
Vue.component('penalty-modal', require('../components/modals/feedback-manager/PenaltyModal.vue').default);


//Survey Manager
Vue.component('survey-manager-page', require('../components/pages/survey-manager/SurveyManagerPage').default);
Vue.component('survey-list', require('../components/pages/survey-manager/SurveyList').default);
Vue.component('survey-detail-page', require('../components/pages/survey-manager/SurveyDetailPage').default);
Vue.component('edit-recipent-modal', require('../components/pages/survey-manager/EditRecipentModal').default);
Vue.component('edit-survey-period', require('../components/pages/survey-manager/EditSurveyPeriod').default);
Vue.component('survey-response-list', require('../components/pages/survey-manager/SurveyResponseList').default);
Vue.component('survey-response-page', require('../components/pages/survey-manager/SurveyResponsePage').default);
Vue.component('survey-response-detail-page', require('../components/pages/survey-manager/SurveyResponseDetailPage').default);
Vue.component('add-survey-modal', require('../components/pages/survey-manager/AddSurveyModal').default);
Vue.component('survey-accumulate-response-page', require('../components/pages/survey-manager/SurveyAccumulateResponsePage').default);
Vue.component('survey-accumulate-individual-response-page', require('../components/pages/survey-manager/SurveyAccumulateIndividualResponsePage').default);
Vue.component('send-email-modal', require('../components/pages/survey-manager/SendEmailModal').default);
Vue.component('send-email-to-user-modal', require('../components/pages/survey-manager/SendEmailToUserModal').default);

//Messaging Centre
Vue.component('blogs-list', require('../components/lists/messaging-centre/blogs/BlogsList.vue').default);
Vue.component('notification-list', require('../components/lists/messaging-centre/notification/NotificationList.vue').default);
Vue.component('blogs-page', require('../components/pages/messaging-centre/blogs/BlogsPage.vue').default);
Vue.component('create-blog-page', require('../components/pages/messaging-centre/blogs/CreateBlogPage.vue').default);
Vue.component('notification-page', require('../components/pages/messaging-centre/notification/NotificationPage.vue').default);
Vue.component('notification-compose-page', require('../components/pages/messaging-centre/notification/NotificationComposePage.vue').default);
Vue.component('base-recipients-page', require('../components/pages/messaging-centre/base/BaseRecipientsPage.vue').default);


//SMS Centre
Vue.component('sms-centre-compose-page', require('../components/pages/sms-centre/SmsCentreComposePage.vue').default);
Vue.component('sms-centre-side-bar', require('../components/pages/sms-centre/partials/SmsCentreSideBar.vue').default);
Vue.component('sms-centre-conversation-body', require('../components/pages/sms-centre/partials/SmsCentreConversationBody.vue').default);
Vue.component('recipient-select-modal', require('../components/pages/sms-centre/modals/RecipientSelectModal.vue').default);
Vue.component('booking-related-sms', require('../components/pages/sms-centre/booking/BookingRelatedSms.vue').default);

/*
|--------------------------------------------------------------------------
| partials
|--------------------------------------------------------------------------
*/
Vue.component('page-top-bar', require('../components/partials/PageTopBar').default);
Vue.component('top-bar-extras', require('../components/partials/TopBarExtras').default);
Vue.component('top-bar-notification', require('../components/partials/TopBarNotification').default);
Vue.component('top-bar-search-content', require('../components/partials/TopBarSearchContent').default);
Vue.component('top-bar-shortcuts-content', require('../components/partials/TopBarShortcutsContent').default);
Vue.component('page-header', require('../components/partials/PageHeader').default);
Vue.component('editable-col', require('../components/partials/EditableColumn').default);

/*
|---------------------------------------------------------------------------
| others compomenents
|---------------------------------------------------------------------------
*/
Vue.component('input-tag', InputTag);
Vue.component('google-maps-address', require('../components/forms/misc/GoogleMapsAddressField').default);
Vue.component('user-in-booking-feature', require('../components/forms/misc/UserInBookingFeature').default);
Vue.component('booking-maps-address', require('~/components/forms/booking/partials/BookingMapsAddressField').default);
Vue.component('count-down', require('~/components/modals/booking/EmergencyBookingCountdown').default);
Vue.component('el-autocomplete-default-first', require('../components/forms/misc/ElAutocompleteDefaultFirst').default);
Vue.component('send-sms-form', require('../components/forms/misc/SendSmsForm').default);
Vue.component('send-fax-form', require('../components/forms/misc/SendFaxForm').default);
Vue.component('unified-number-setting', require('../components/forms/misc/UnifiedNumberSetting').default);
Vue.component('handesk-frame', require('../components/pages/misc/HandeskFrame').default);
Vue.component('rocketchat-frame', require('../components/pages/misc/RocketChatFrame').default);
Vue.component('statistics-frame', require('../components/pages/misc/StatisticsFrame').default);
Vue.component('tawk-to-frame', require('../components/pages/misc/TawkToFrame').default);
Vue.component('locked-booking-warning', require('~/components/partials/LockedBookingWarning').default);


// Certificate
Vue.component('certificate-page', require('../components/pages/certificate/CertificatePage').default);
Vue.component('create-certificate-form', require('../components/forms/certificate/CreateCertificateForm').default);
Vue.component('comlog-email-view', require('../components/pages/logs/partials/ViewEmailComponents').default);
Vue.component('comlog-fax-view', require('../components/pages/logs/partials/ViewFaxComponents').default);


Vue.component('blacklisted-list-page', require('../components/pages/user/BlacklistedListPage').default);

//booking issue
Vue.component('booking-issue-list-page', require('../components/pages/booking-issue/ListPage').default);
Vue.component('booking-issue-list-solved-page', require('../components/pages/booking-issue/SolvedListPage').default);
Vue.component('booking-issue-list-pending-page', require('../components/pages/booking-issue/PendingListPage').default);
Vue.component('booking-issue-list', require('../components/lists/booking-issue/List').default);
Vue.component('booking-issue-solved-list', require('../components/lists/booking-issue/SolvedList').default);
Vue.component('booking-issue-pending-list', require('../components/lists/booking-issue/PendingList').default);
Vue.component('booking-issue-setting-list', require('../components/lists/booking-issue/SettingList').default);
Vue.component('booking-issue-details-page', require('../components/pages/booking-issue/DetailsPage').default);
Vue.component('booking-issue-setting-list-page', require('../components/pages/booking-issue/SettingListPage').default);
Vue.component('booking-issue-setting-form', require('../components/forms/booking-issue/SettingForm').default);
Vue.component('court-order-lookup-form', require('../components/pages/booking-issue/partials/CourtOrderLookupForm').default);
Vue.component('booking-issue-tags-page', require('../components/pages/booking-issue/TagsPage').default);
Vue.component('booking-issue-tags-form', require('../components/forms/booking-issue/TagsForm').default);
Vue.component('booking-issue-tags-list', require('../components/lists/booking-issue/TagsList').default);
Vue.component('booking-issue-tagged-list-page', require('../components/pages/booking-issue/TaggedListPage').default);


// Booking resource
Vue.component('resources-list-page', require('../components/pages/booking/resources/ResourcesListPage').default);
Vue.component('resources-compose-page', require('../components/pages/booking/resources/ResourceComposePage').default);
//FAQs generators

Vue.component('faq-page', require('../components/pages/faq/faq').default);
Vue.component('faq-list', require('../components/lists/FAQ/FAQList').default);
Vue.component('faq-base-list', require('../components/lists/FAQ/FaqBaseList').default);
Vue.component('faq-details-page', require('../components/pages/faq/FAQDetails').default);
Vue.component('translator-unavailableTimes-add', require('../components/forms/user/translatorUnavailableTimesAdd').default);

Vue.component('trainings-list', require('../components/lists/trainings/TrainingsList').default);
Vue.component('training-compose-form', require('../components/forms/trainings/TrainingComposeForm').default);
Vue.component('trainings-list-page', require('../components/pages/trainings/TrainingListPage').default);
Vue.component('trainings-compose-page', require('../components/pages/trainings/TrainingComposePage').default);
Vue.component('user-translator-trainings-fields', require('../components/forms/user/UserTranslatorTrainingsField').default);
Vue.component('user-translator-training-record-fields', require('../components/forms/user/UserTranslatorTrainingRecordFields').default);

Vue.component('trainings-section', require('../components/lists/user/TranslatorTrainings').default);
Vue.component('trainings-section-add', require('../components/forms/user/TranslatorTrainingsAdd').default);
// Booking resource
Vue.component('resources-list-page', require('../components/pages/booking/resources/ResourcesListPage').default);
Vue.component('resources-compose-page', require('../components/pages/booking/resources/ResourceComposePage').default);
//Emails templates
Vue.component('emails-templates-list', require('../components/lists/settings/emails-templates/EmailsTemplatesList').default);
Vue.component('emails-templates-add', require('../components/forms/settings/EmailsTemplateForm.vue').default);
Vue.component('emails-templates-send-modal', require('~/components/modals/settings/EmailsTemplateSendModal').default);

Vue.component('verbiages-clear', require('~/components/pages/misc/VerbiagesClear').default);

Vue.component('verbiages-clear', require('~/components/pages/misc/VerbiagesClear').default);
