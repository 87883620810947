import APICaller from '~/js/helpers/APICaller';
import API from '~/js/constants/api';

export default {
  createExpenseType (context, payload) {
    const apiOpts = {
      endpoint: `${API.EXPENSE_TYPES}`,
      method: 'post',
      data: payload,
      isDataRaw: true
    }

    return APICaller(apiOpts)
      .then((res) => {
        return res
      }).catch(err => {
        return err
      })
  },
  editExpenseType (context, payload) {
    const apiOpts = {
      endpoint: `${API.EXPENSE_TYPES}/${payload.id}`,
      method: 'put',
      data: payload,
      isDataRaw: true
    }

    return APICaller(apiOpts)
      .then((res) => {
        return res
      }).catch(err => {
        return err
      })
  }
}