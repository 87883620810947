<template>
  <div class="app-list">
    <h4>{{$t('actions')}}</h4>
    <div class="issues-action-form">
        <template v-if="issue.type.type ==='financial_adjustment'">
            <el-button class="issues-action-form-button" type="primary" @click="$router.push({path:financialAdjustmentUrl})">
                <i class="fa fa-envelope"></i>{{$t('go_to_financial_adjustment')}}
            </el-button>
            <el-button class="issues-action-form-button" type="primary" @click="$router.push({path: bookingUrl})">
                <i class="fa fa-envelope"></i>{{$t('go_to_booking_details')}}
            </el-button>

        </template>

        <template v-if="issue.type.type ==='expense_report'">
            <el-button class="issues-action-form-button" type="primary" @click="$router.push({path:financialAdjustmentUrl})">
                <i class="fa fa-envelope"></i>{{$t('go_to_financial_adjustment')}}
            </el-button>
            <el-button class="issues-action-form-button" type="primary" @click="handleRejectExpense()">
                <i class="fa fa-envelope"></i>{{$t('reject')}}
            </el-button>
            <el-button class="issues-action-form-button" type="primary" @click="handleApprovedExpense()">
                <i class="fa fa-envelope"></i>{{$t('approve')}}
            </el-button>
            <br />

        </template>

        <template v-else>
            <template v-if="!isSolved">
              <el-button class="issues-action-form-button btn-green" type="primary" @click="showComment = true"><i class="fa fa-check-circle"></i>  {{$t('solved')}}</el-button>
            </template>
        </template>
        
        <el-button class="issues-action-form-button" type="primary" @click="handleFollowupButton"><i class="fa fa-plus"></i>  {{$t('follow_up')}}</el-button>
        <el-button class="issues-action-form-button" type="primary" v-if="isBookingCustomer" @click="handleAssignButton(issue.booking.customer)">
            <i class="fa fa-envelope"></i>  {{$t('assign_to_customer')}}
        </el-button>
        <el-button class="issues-action-form-button" type="primary" v-if="isTranslator" @click="handleAssignButton(issue.translator)">
            <i class="fa fa-envelope"></i>  {{$t('assign_to_translator')}}
        </el-button>

        <template v-if="issue.type.type ==='low_feedback' && !isEmpty(isSolved)">
            <el-button class="issues-action-form-button" type="primary" @click="showModal = !showModal"><i class="fa fa-envelope"></i>  {{$t('send_report_to_customer')}}</el-button>
            <el-button class="issues-action-form-button btn-red" type="primary" @click="handleShowPenaltyModal"><i class="fa fa-warning"></i>  {{$t('penalty_translator')}}</el-button>
            <el-button class="issues-action-form-button btn-red" type="primary" @click="handleBlacklistTranslator"><i class="fa fa-minus"></i>  {{$t('blacklist_translator')}}</el-button>
        </template>
        
    </div>

    <comment-modal
        :visible.sync="showComment"
        :dontcountfeedback.sync="do_not_count_feedback"
        :issue="issue"
        @addComment="setAsSolved">
    </comment-modal>
    
    <todo-form :visible.sync="showTodoForm" :booking="issue.booking"></todo-form>
    <assign-actions-form
        :visible.sync="showAssignActionsModal"
        :assigned-user="assignedUser"
        :issue-id="iIssue.id"
        />
    <template v-if="issue.type.type ==='low_feedback' && !isEmpty(iIssue.booking)">
        <template v-if="!isEmpty(iIssue.entities)">
            <report-to-customer-modal :row="iIssue.booking" :feedback="iIssue.entities" :visible.sync="showModal"></report-to-customer-modal>
        </template>
        <penalty-modal :visible.sync="showPenaltyModal" :booking="iIssue.booking" :feedback="customerFeedback" @updateIssues="handleSubmitPenalty"/>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { isEmpty } from "~/js/helpers/Common";
import CommentModal from "./CommentModal";
import AssignActionsForm from "./AssignActionsForm";
import ReportToCustomerModal from './SendReportToCustomer';


export default {
    /*
    |--------------------------------------------------------------------------
    | Component > components
    |--------------------------------------------------------------------------
    */
    components: {
        CommentModal,
        AssignActionsForm,
        ReportToCustomerModal
    },
    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props:{
        issue:{
            type:Object,
            default(){
                return {}
            }
        }
    },
    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data(){
        return {
            showComment: false,
            showTodoForm: false,
            showAssignActionsModal: false,
            assignedUser: {},
            showModal:false,
            showPenaltyModal:false,
            do_not_count_feedback: false,
        }
    },
    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed:{
        ...mapState("auth", {
            user: "userData"
        }),
        iIssue(){
            return this.issue
        },
        isBookingCustomer() {
            return (
                !isEmpty(this.issue.booking) && !isEmpty(this.issue.booking.customer)
            );
        },
        isTranslator() {
            return !isEmpty(this.issue.translator);
        },
        isSolved() {
            return !isEmpty(this.issue.solved_at);
        },
        customerFeedback(){
            let val = this.iIssue.booking.feedback.filter(o=>o.review_from_type==2).find(o=>o.rating<3);
           return !isEmpty(val) ? val: this.iIssue.booking.feedback.find(o=>o.review_from_type==2)
        },
        bookingUrl() {
            return `/booking/${this.issue.booking.id}/details`;
        },
        financialAdjustmentUrl() {
            return `/booking/${this.issue.booking.id}/financial-adjustment?type=${this.issue.booking.type}`;
        },

    },
    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
        ...mapActions("bookingIssue", {
            updateIssue: "updateIssue",
            postComment: "postComment",
            rejectExpense: "rejectExpense",
            approveExpense: "approveExpense"
        }),
        ...mapActions("feedback", {
            putFeedback: "putFeedback",
        }),
        async setAsSolved(e) {
            let params = {
                booking_issue_id: this.issue.id,
                solved: true
            };
            let issue = await this.updateIssue(params);

            if (issue.status === 200) {
                let payload = {
                    booking_issue_id: this.issue.id,
                    comment: e.comment,
                    created_by: this.user.id
                };
                this.postComment(payload);

                if (this.do_not_count_feedback) {
                    this.putFeedback({
                        id: this.issue.entity_id,
                        is_rejected: true
                    });
                    this.postComment({
                        booking_issue_id: this.issue.id,
                        comment: "Admin has decided not to count this rating towards the average rating",
                        created_by: this.user.id
                    });
                }
            }
        },
        handleFollowupButton() {
            this.showTodoForm = true;
        },
        handleAssignButton(user) {
            this.assignedUser = user;
            this.showAssignActionsModal = true;
        },
        isEmpty(v) {
            return isEmpty(v);
        },
        async handleRejectExpense(type) {
            let params = {
                booking_id: this.issue.booking_id,
                id: this.issue.entity_id
            };
            let response = await this.rejectExpense(params);
            if (response.status === 200) {
                params.booking_issue_id = this.issue.id
                params.solved = true
                this.updateIssue(params);
                let payload = {
                booking_issue_id: this.issue.id,
                comment: `Expense reject by ${this.user.name}`,
                created_by: this.user.id
                };
                this.postComment(payload);
            }
        },
        async handleApprovedExpense(type) {
            let params = {
                booking_id: this.issue.booking_id,
                id: this.issue.entity_id
            };
            let response = await this.approveExpense(params);
            if (response.status === 200) {
                params.booking_issue_id = this.issue.id
                params.solved = true
                this.updateIssue(params);
                let payload = {
                booking_issue_id: this.issue.id,
                comment: `Expense approved by ${this.user.name}`,
                created_by: this.user.id
                };
                this.postComment(payload);
            }
        },
        handleShowPenaltyModal() {
            this.showPenaltyModal = true
        },
        handleBlacklistTranslator(){
            this.$confirm(this.$t('txt_blacklist_translator'), this.$t('warning'), {
                confirmButtonText: this.$t('OK'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning'
            }).then(() => {
                let data = {
                    'user_id': this.iIssue.booking.customer_id,
                    'translator_id': this.iIssue.booking.assigned_translator.translator_id,
                }
                this.storeBlacklist(data).then(()=>{
                    let payload = {
                        booking_issue_id: this.issue.id,
                        comment: 'Translator Blacklisted',
                        created_by: this.user.id
                    }

                    this.postComment(payload)
                })
            }).catch(() => {

            });

        },
        handleSubmitPenalty(v){
            let payload = {
                booking_issue_id: this.issue.id,
                comment: v.comment,
                created_by: this.user.id
            }
            this.postComment(payload)
        }
    }
}
</script>

