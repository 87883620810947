import {Notification} from 'element-ui';
import i18n from '~/js/i18n';
import includes from 'lodash/includes';

let apiPrefix = 'v3';

/**
 *
 * @param v
 * @returns {string|null}
 */
function getGender(v) {

  if (v == null) return null;

  let result = [];
  const cn = v.split(',');

  if (includes(cn, 'male')) result.push(i18n.t('male'));
  if (includes(cn, 'female')) result.push(i18n.t('female'));

  return result.join(', ');

}

export default {

    sendPartner(context, payload){
        let type = i18n.t(payload.booking.type)
        // let data =
        let duration = moment().startOf('day').add(payload.booking.duration, 'minutes').format('HH:mm')
      let gender = getGender(payload.booking.gender);

      let content = `
                <p>Hej </br>

                Kan ni hjälpa oss med nedanstående uppdrag?</p>
                <p>
                Bok. nr: ${payload.booking.id} <br>
                Språk : ${payload.booking.language_name}tolk <br>
                Datum : ${payload.booking.due} <br>
                Tid : ${duration} <br>
                Typ : ${type} <br>
                ${gender !== null ? `Ev: Krav : ${gender}` : ''} <br>
                ${payload.booking.type === 'physical' ? 'Address : '+payload.booking.address+'<br>' :''}
                </p>

                 <p>
                 Med vänlig hälsning<br>
                  Tolkförmedling Väst<br>
                  Telefonnummer: 0770-813 000<br>
                 </p>`;
      let data = {
        notification_type: 'email',
        template_code: 'send-email-to-partner',
        project_code: app_dt_notifier_project_code,
        data: {
          content: content,
          subject: `Hjälp med att tillsätta beställning (bok.nr ${payload.booking.id})`
        },
        recipients: [payload.partner.email],
        booking_id:payload.booking.id,
      }

      let token = localStorage.getItem("app_access_token");
      // axios.post(`${app_dt_notifier_url}/api/v1/notifications`,data)
     return axios.post(`${app_dt_notifier_url}/api/v1/notifications`,data)
        .then(resp => {
          Notification.success({
            title: i18n.t('success'),
            message: 'send email notification requested',
          });
        }).catch(err => {

        Notification.error({
          title: i18n.t('error'),
          message: i18n.t('n_went_wrong'),
        });
      })

    }
}
