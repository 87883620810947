/*
|--------------------------------------------------------------------------
| store > Audits
|--------------------------------------------------------------------------
|
| A Class file which contains all the properties, api connections and
| api activities related to the stated store file.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import APICaller from '~/js/helpers/APICaller';
import {gep} from "~/js/helpers/Vuex";

let apiPrefix = 'v3';

let state = {
  audit_pagination: {
    page: 0,
    total: 0,
    total_page: 0,
    fetched: 0,
  },
  audits: [],
  auditsBackup: [],
  adminUsers: [],
  loading: false,
  financials: {
    relationships: [
      'booking_export_value',
      'company',
      'customer',
      'customer_type',
      'department',
      'expense',
      'expense_attachment',
      'expense_type',
      'export_financial_booking_request',
      'financial_list',
      'holiday',
      'insurance_certificate',
      'invoice_credit',
      'invoice_department_credit',
      'municipality',
      'salary_credit',
      'salary_translator_credit',
      'template',
      'translator',
      'translator_export_value',
      'allowance_fee',
      'allowance_setting',
      'booking_timing',
      'charge',
      'expense_setting',
      'export_request',
      'financial_log',
      'holiday_fee',
      'holiday_setting',
      'inconvenience_fee',
      'inconvenience_setting',
      'inconvenience_travel_time_fee',
      'inconvenience_travel_distance_fee',
      'late_cancellation_rule',
      'parts_fee',
      'parts_timing',
      'pricelist',
      'pricelist_language',
      'translator_level_setting',
      'travel_distance_fee',
      'travel_setting',
      'travel_time_fee',
      'text_translation_fee',
      'weekend_fee',
      'weekend_setting',
      'invoices_additional_item',
      'invoices_booking_type_fee',
      'invoices_calculation',
      'invoices_emergency_fee',
      'invoices_emergency_setting',
      'invoices_export_invoice_request',
      'invoices_immediate_fee',
      'invoices_invoice',
      'invoices_item',
      'invoices_office_hours_fee',
      'invoices_prefill_item',
      'invoices_rush_booking_fee',
      'invoices_setting',
      'invoices_transaction_fee',
      'invoices_translator_level_fee',
      'invoices_booking',
      'salaries_additional_item',
      'salaries_calculation',
      'salaries_item',
      'salaries_mentor_fee',
      'salaries_prefill_item',
      'salaries_salary',
      'salaries_setting',
      'translator_payroll',
      'salaries_booking',
      'adjustments_booking_adjustment',
      'adjustments_invoice_adjustment',
      'adjustments_salary_adjustment',
      'adjustments_invoice_parts_adjustment',
      'adjustments_salary_parts_adjustment'
    ],
  },
};

let getters = {
  getAudits: (state) => state.audits,
  getAuditsBackup: (state) => state.auditsBackup,
  getLoading: (state) => state.loading,
  getAdminUsers: (state) => state.adminUsers,
  getFinancials: (state) => state.financials,
  getPagination: (state) => state.audit_pagination,
};

const auditsModification = (audits) => {

  audits = audits.sort((a, b) => {
    let aDate = new Date(a.created_at);
    let bDate = new Date(b.created_at);
    return bDate - aDate;
  });
  audits = audits.filter(function (audit, index, arr) {
    let oldValuesPassed = false;
    let newValuesPassed = false;

    // Check old values
    if (Array.isArray(audit.old_values)) {
      oldValuesPassed = audit.old_values.length > 0 ? true : false;
    } else {
      oldValuesPassed = true;
    }

    // Check new values
    if (Array.isArray(audit.new_values)) {
      newValuesPassed = audit.new_values.length > 0 ? true : false;
    } else {
      newValuesPassed = true;
    }

    return oldValuesPassed || newValuesPassed;
  });
  audits = audits.filter(function (audit, index, arr) {

    // Remove null and empty string autologged.
    let valuesWithNullOrEmptyStr = [];
    if (audit.hasOwnProperty('old_values')) {
      for (let field in audit.old_values) {
        if (audit.old_values[field] === null || audit.old_values[field] === '') {
          valuesWithNullOrEmptyStr.push(field);
        }
      }
    }
    if (audit.hasOwnProperty('new_values') && valuesWithNullOrEmptyStr.length > 0) {
      let countConfirmed = 0;
      for (let field in audit.new_values) {
        if (audit.new_values[field] === null || audit.new_values[field] === '') {
          let found = valuesWithNullOrEmptyStr.find(v => v === field);
          if (found) {
            countConfirmed++;
          }
        }
      }
      if (valuesWithNullOrEmptyStr.length === countConfirmed) {
        return false;
      }
    }
    return true;
  });

  return audits;
};

let mutations = {
  setAudits: (state, payload) => {
    let audits = _.values(payload);
    state.audits = audits;
    state.fetched = state.audits.length;
  },
  appendNewAudits: (state, payload) => {
    let audits = _.values(payload);
    let oldAudits = state.audits;
    state.audits = oldAudits.concat(audits);
  },
  setAuditsBackup: (state, payload) => {
    state.auditsBackup = _.values(payload);
  },
  appendAudits: (state, payload) => {
    let audits = state.audits.concat(payload);
    state.audits = audits.sort((a, b) => {
      let aDate = new Date(a.created_at);
      let bDate = new Date(b.created_at);
      return bDate - aDate;
    });
  },
  appendAuditsBackup: (state, payload) => {
    let audits = state.auditsBackup.concat(payload);
    state.auditsBackup = audits.sort((a, b) => {
      let aDate = new Date(a.created_at);
      let bDate = new Date(b.created_at);
      return bDate - aDate;
    });
  },
  setLoading: (state, payload) => {
    state.loading = payload
  },
  setAdminUsers: (state, payload) => {
    state.adminUsers = payload
  },
  setPagination(state, payload) {
    state.audit_pagination.page = state.audit_pagination.page + 1;
    state.audit_pagination.total = payload.total;
    state.audit_pagination.total_page = payload.total_page;
    state.audit_pagination.fetched += _.size(payload.audits);
  },
  resetPagination(state, payload) {
    state.audit_pagination.page = 1;
    state.audit_pagination.total = 0;
    state.audit_pagination.total_page = 0;
    state.audit_pagination.fetched = 0;
    state.audits = [];
  }
};

let actions = {
  fetchAudits: (context, payload) => {
    context.commit('setLoading', true);
    let endpoint = 'audits';
    let append = false;
    if (payload.hasOwnProperty('endpoint')) {
      endpoint = payload.endpoint;
    }
    if (payload.hasOwnProperty('append')) {
      append = payload.append;
    }

    let path = `?auditable_id=${payload.id}&auditable_type=${payload.auditable}&all=1&page=${payload.page}&with=${payload.with}&from_date=${payload.from_date}&to_date=${payload.to_date}`;
    if (payload.relationships != null && payload.relationships.length > 0) {
      path += `&relationships=${payload.relationships}`;
    }
    if (payload.do_not_show_excluded != null) {
      path += `&do_not_show_excluded=${payload.do_not_show_excluded}`;
    } else {
      path += '&do_not_show_excluded=true';
    }
    return APICaller({
      method: 'get',
      endpoint: gep(`${endpoint}${path}`, apiPrefix),
    }).then(response => {
      if (append) {
        context.commit('appendNewAudits', response.data.audits);
        context.commit('setPagination', response.data);
        // context.commit('appendAuditsBackup', response.data.audits);
      } else {
        context.commit('setAudits', response.data.audits);
        context.commit('setAuditsBackup', response.data.audits);
        context.commit('setPagination', response.data);
      }
      context.commit('setLoading', false);
      context.state.total = response.data.total;
      return response;
    });
  },
  fetchAllAdminUsers(context) {
    return APICaller({
      method: 'get',
      endpoint: gep('users?filter[type]=1', apiPrefix)
    }).then(resp => {
      context.commit('setAdminUsers', resp.data.data.users)
    }).catch(err => {
      console.log(err)
    })
  },

  // Financials
  fetchPriceTemplate(context, id) {
    return APICaller({
      method: 'get',
      endpoint: gep(`financial-templates/${id}`, apiPrefix)
    });
  },
  fetchFinancialInvoiceAdditionalChargeList(context, id) {
    return APICaller({
      method: 'get',
      endpoint: gep(`bookings/${id}/additional-invoice-items?all=1`, apiPrefix)
    }).then(r => {
      return r.data.data.invoices_additional_items;
    });
  },
  fetchFinancialSalaryAdditionalChargeList(context, id) {
    return APICaller({
      method: 'get',
      endpoint: gep(`bookings/${id}/additional-salary-items?all=1`, apiPrefix)
    }).then(r => {
      return r.data.data.salaries_additional_items;
    });
  },
  fetchAdjustments(context, obj) {
    return APICaller({
      method: 'get',
      endpoint: gep(`bookings/${obj.id}/${obj.base}${obj.base.length > 0 ? '-' : ''}adjustments`, apiPrefix)
    }).then(r => {
      return r.data.data[`${obj.base}_adjustment`];
    }).catch(err => {
      return {};
    })
  },
  fetchPartsAdjustments(context, obj) {
    return APICaller({
      method: 'get',
      endpoint: gep(`bookings/${obj.id}/${obj.base}-parts-adjustments`, apiPrefix)
    }).then(r => {
      return r.data.data[`${obj.base}_parts_adjustments`];
    }).catch(err => {
      return [];
    })
  },

};

export default {
  namespaced: true,
  state, actions, mutations, getters
}

