<template>
    <div class="app-form locale-form">
        <h3>New Locale</h3>
        <el-form :model="form" :id="formElId" :ref="formElId">
            <el-form-item :id="genContainerId('locale',formElId)"
                        label="Locale" class="label-short">
                <el-input :id="genFieldId('locale',formElId)"
                 v-model="form.locale"/>
                </el-input>
            </el-form-item>

            <el-form-item :id="genContainerId('name',formElId)"
                        label="Name" class="label-short">
                <el-input :id="genFieldId('name',formElId)"
                 v-model="form.name"/>
                </el-input>
            </el-form-item>
            <el-form-item label="Copy from" :id="genContainerId('copy_from',formElId)" class="label-short">
                <el-select v-model="form.copy_from" placeholder="Select copy from" :id="genFieldId('copy_from',formElId)">
                  <el-option v-for="locale in locales" :key="locale.id" :value="locale.id" :label="locale.name"> {{locale.name}} </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :id="genContainerId('save_btn',formElId)">
                <el-button :id="genFieldId('save_btn',formElId)" type="primary">
                    Save
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {genFieldId, genContainerId} from "../../../js/helpers/Html";
    export default {
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                formElId: 'locale-form',
                form: {
                    locale: '',
                    name: '',
                    copy_from: ''
                },
                locales: [
                    {id:1,name: 'Swedish - sv'},
                    {id:2,name: 'English - en'},
                ]
            };
        },// End of Component > data
    }// End of export default

</script>