<template>
    <div class="app-page audits-page">
          <page-header :title="`${$t(auditableType)} #${auditableID} ${$t('audit_logs')}`" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col full-width">
                        <audits-list></audits-list>
                    </div> <!-- /.app-col -->
                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->
        </div> <!-- /.app-container -->

    </div> <!-- /.app-page booking-histories-page -->
</template>

<script>
export default {
    data(){
        return {
            auditableID:0,
            auditableType:'',
        }
    },
    mounted() {
        if (this.$route.name == 'audits') {
            let type = this.$route.params.auditable;
            this.auditableID = this.$route.params.id;
            this.auditableType = type;
        } else if (this.$route.name == 'audits.financials.template') {
            this.auditableID = this.$route.params.template_id;
            this.auditableType = 'price_templates';
        } else if (this.$route.name == 'audits.financials.adjustments') {
            this.auditableID = this.$route.params.booking_id;
            this.auditableType = 'tfv_financial_adjustments';
        }
    },
}
</script>
