<template>
    <div class="app-form user-mappings-form">

        <h3>Create Mapping</h3>

        <el-form :model="form" :id="formElId" :ref="formElId">
            <el-form-item :id="genContainerId('dt_id',formElId)"
                        label="DT ID" class="label-short">
                <el-input :id="genFieldId('dt_id',formElId)"
                 v-model="form.dt_id"/>
            </el-form-item>
            
            <el-form-item :id="genContainerId('aes_id',formElId)"
                        label="AES ID" class="label-short">
                <el-input :id="genFieldId('aes_id',formElId)"
                 v-model="form.aes_id"/>
            </el-form-item>

            <el-form-item :id="genContainerId('save_btn',formElId)">
                <el-button :id="genFieldId('save_btn',formElId)" type="primary">
                    Save
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        /*
        |--------------------------------------------------------------------------
        | Component > data
        |--------------------------------------------------------------------------
        */
        data() {
            return {
                formElId: 'user_mappings_form',
                form: {
                    dt_id: '',
                    aes_id: ''
                }
            };
        },// End of Component > data
    }// End of export default

</script>