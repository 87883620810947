<template>
    <div class="app-form" :class="getBem(blockCls)">
        <el-form>
            <div class="app-splitter two-cols">

                <!-- Template ID Selector -->
                <el-form-item :class="getBem(blockCls, 'template-id-select-container')">
                    <el-select :class="getBem(blockCls, 'template-id-select')"
                               v-model="templateId"
                               placeholder="Choose Template"
                               @focus="handleClickFinancialTemplateSelect"
                               clearable
                               filterable>
                        <el-option v-for="v in financialTemplateOpts"
                                   :key="v.id"
                                   :value="v.id"
                                   :label="formatOptionName(v.name)" />
                    </el-select>
                </el-form-item>

                <el-form-item :class="getBem(blockCls, 'apply-button-container')">
                    <el-button :class="getBem(blockCls, 'apply-button')"
                               type="primary" size="mini"
                               @click="handleClickApply">
                        Apply
                    </el-button>
                </el-form-item>
            </div>
        </el-form>
    </div> <!-- Component DIV Wrapper -->
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'financial-settings-template-selector',
        templateId: ''
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {
      ...mapGetters('financialPriceTemplate', {
        'financialTemplateOpts': 'listAsOptions'
      })
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {
      ...mapActions('financialPriceTemplate', {
        loadFinancialTemplateOpts: 'browseAsOptions'
      }),

      /**
       * Handler when the Financial Template Select was clicked.
       *
       * @return {void}
       */
      handleClickFinancialTemplateSelect () {
        if (this.financialTemplateOpts.length === 0) {
          this.loadFinancialTemplateOpts();
        }
      },

      /**
       * Helper method for formatting the Template name values as options.
       *
       * @return {string}
       */
      formatOptionName (name) {
        const limit = 50;
        if (name.length > limit) return name.substr(0, limit) + '...';
        else {return name}
      },

      /**
       * Handler when the Apply button was clicked.
       *
       * @return {void}
       */
      handleClickApply () {
        this.$emit('apply', {template_id: this.templateId});
      }
    }
  }
</script>
