/*
|--------------------------------------------------------------------------
| store > user > customer > mutations
|--------------------------------------------------------------------------
|
| This file contains the mutations property of current Vuex scope.
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {isEmpty, sd} from '~/js/helpers/Common';

/**
 * Helper method to mutate collection values for applying payload to compose.
 *
 * @param {object} col - collection of values to be mapped.
 * @param {int|string} key - key to where the value will be
 *                           pushed in the result.
 * @returns {array}
 */
function mc (col, key) {
  let r = [];
  if (!window._.isEmpty(col)) {
    _.forOwn(col, (item) => r.push(item[key]));
  }
  return r;
}

/**
 * Helper method for assign the values from source to destination.
 *
 * @param dest - destination object.
 * @param destKey - key on where to assign the value in destination.
 * @param src - source object.
 * @param [srcKey] - key on where to find the value in source.
 * @param [def] - default value whenever the source value does not exist.
 * @return {void}
 */
function av (dest, destKey, src, srcKey = null, def = null) {

  // Set Default value for srcKey
  srcKey = srcKey === null ? destKey : srcKey;

  if (isValueValid(src[srcKey])) {
    // If the source value is valid, run this block.
    dest[destKey] = src[srcKey]

  } else if (!window._.isNull(def)) {
    // Else if the source value is not valid, and default value is not null, run this block.
    dest[destKey] = def;
  }
}

/**
 * Helper to check whether the value is valid to be assigned.
 *
 * @param v - value to be evaluated.
 * @returns {boolean}
 */
function isValueValid (v) {
  if (v && typeof v === 'object' && v.constructor === Object) {
    return !window._.isEmpty(v);

  } else {
    return !window._.isUndefined(v) && !window._.isNull(v);
  }
}

/**
 * Helper method for getting the notification object of a certain type.
 *
 * @param {object} types - collection types that will be search into.
 * @param {string} type - notifiction type to be returned.
 * @return {object}
 */
function getNotifTypeObj (types, type) {
  return window._.find(types, {notification_type: type});
}

export default {

  /**
   * Method for setting the values in state.compose
   *
   * @param state - state of the Vuex scope.
   * @param payload - source of the values.
   * @return {void}
   */
  setCompose (state, payload) {

    let src = payload;
    let dest = state.compose;
    let dk = 'customer_data'; // Data Key

    // Default Props
    av(dest, 'department_id', src[dk].department, 'id');
    av(dest, 'company_id', src[dk].department, 'company_id');

    // Personal Info
    if(!payload.isDuplicated){
      av(dest.personal_info, 'name', src);
      av(dest.personal_info, 'email', src);
      av(dest.personal_info, 'dob', src);
      av(dest.personal_info, 'username', src);
    }else{
      dest.personal_info.name="";
      dest.personal_info.email="";
      dest.personal_info.dob="";
      dest.personal_info.username="";
    }

    av(dest.personal_info, 'notes', src);
    av(dest.personal_info, 'gender', src);
    av(dest.personal_info, 'phone', src);
    av(dest.personal_info, 'mobile', src);

    const fax = getNotifTypeObj(src.customer_notification_types, 'fax');
    if (!window._.isNil(fax)) {
      av(dest.personal_info, 'fax', fax, 'default_recipient');
    }

    // Settings
    av(dest.settings, 'customer_type', src[dk], 'customer_type_id');
    dest.settings.blacklisted_users_temp = src.blacklisted_users
    dest.settings.blacklisted_users = sd(mc(src.blacklisted_users, 'translator_id'), []);
    av(dest.settings, 'town_id', src[dk]);
    av(dest.settings, 'user_towns', src[dk]);
    av(dest.settings, 'cost_place', src[dk]);
    const distinctTowns = window._.uniqBy(src.towns, 'id'); // Remove any duplicate entries for towns.
    dest.settings.towns = sd(mc(distinctTowns, 'id'), []);
    dest.settings.booking_types = sd(mc(src.booking_types, 'booking_type'), []);

    // Address
    av(dest.address, 'address', src[dk]);
    av(dest.address, 'address_2', src[dk]);
    av(dest.address, 'post_code', src[dk]);
    av(dest.address, 'city', src[dk]);
    av(dest.address, 'country_iso', src[dk]);
    av(dest.address, 'additional_info', src[dk]);
    av(dest.address, 'coordinates', src[dk]);
    av(dest.address, 'office_area', src[dk]);

    // Questions
    av(dest.questions, 'enabled', src);
    av(dest.questions, 'disable_comment', src);
    // av(dest.questions, 'tfa_enabled', src);
    // av(dest.questions, 'show_2fa_qr', src);
    av(dest.questions, 'allow_show_invoices', src[dk]);
    av(dest.questions, 'allow_send_invoices', src[dk]);
    if (!window._.isNil(fax)) {
      av(dest.questions, 'allow_fax_confirmation', fax, 'enabled');
    }

    av(dest.questions, 'allow_credit_card', src[dk]);
    av(dest.questions, 'allow_fallback_type', src[dk]);
    av(dest.questions, 'allow_sms_confirmation', src[dk]);
    av(dest.questions, 'is_sensitive_info', src[dk]);
    av(dest.questions, 'confidential_message', src[dk]);
    av(dest.questions, 'is_protected_identity', src[dk]);
    av(dest.questions, 'allow_appointment_webapp', src[dk]);
    av(dest.questions, 'allow_suggest_time_appointment', src[dk]);

  },


  /**
   * Method for setting the values in state.payload
   *
   * @param state - state of the Vuex scope.
   * @return {void}
   */
  setPayloadData (state) {
    let src = state.compose;
    let dest = {};
    let dk = 'customer_data';
    dest[dk] = {}; // Instantiate the dk child container value.

    console.log('COMPOSE', src);

    // Personal Info
    av(dest, 'name', src.personal_info);
    av(dest, 'notes', src.personal_info);
    av(dest, 'username', src.personal_info);
    av(dest, 'email', src.personal_info);
    av(dest, 'dob', src.personal_info);
    av(dest, 'phone', src.personal_info);
    av(dest, 'mobile', src.personal_info);
    av(dest, 'towns', src.settings);

    // Credential
    if (
      !isEmpty(src.credential.password)
      && !isEmpty(src.credential.password_confirmation)
    ) {
      av(dest, 'current_password', src.credential);
      av(dest, 'password', src.credential);
      av(dest, 'password_confirmation', src.credential);
    }

    // Sector
    av(dest[dk], 'department_id', src);
    av(dest[dk], 'company_id', src);

    // Settings
    av(dest, 'booking_types', src.settings);
    av(dest[dk], 'customer_type_id', src.settings, 'customer_type');
    av(dest[dk], 'town_id', src.settings);
    av(dest[dk], 'user_towns', src.settings);
    av(dest[dk], 'cost_place', src.settings);

    // Address
    av(dest[dk], 'address', src.address);
    av(dest[dk], 'address_2', src.address);
    av(dest[dk], 'post_code', src.address);
    av(dest[dk], 'city', src.address);
    av(dest[dk], 'country_iso', src.address);
    av(dest[dk], 'additional_info', src.address);
    av(dest[dk], 'coordinates', src.address);
    av(dest[dk], 'office_area', src.address);

    // Questions
    av(dest, 'enabled', src.questions);
    av(dest, 'disable_comment', src.questions);
    // av(dest, 'tfa_enabled', src.questions);
    // av(dest, 'show_2fa_qr', src.questions);
    av(dest[dk], 'allow_show_invoices', src.questions);
    av(dest[dk], 'allow_send_invoices', src.questions);
    av(dest[dk], 'allow_credit_card', src.questions);
    av(dest[dk], 'allow_sms_confirmation', src.questions);
    av(dest[dk], 'allow_appointment_webapp', src.questions);
    av(dest[dk], 'allow_fallback_type', src.questions);
    av(dest[dk], 'is_sensitive_info', src.questions);
    av(dest[dk], 'confidential_message', src.questions);
    av(dest[dk], 'is_protected_identity', src.questions);
    av(dest[dk], 'allow_suggest_time_appointment', src.questions);

    // Set notification related values.
    dest.customer_notification_types = [
      {
        notification_type: 'email',
        enabled: true,
        default_recipient: src.personal_info.email
      },
      {
        notification_type: 'fax',
        enabled: src.questions.allow_fax_confirmation,
        default_recipient: src.personal_info.fax
      }
    ];

    console.log(src);
    av(dest[dk], 'same_as_municipality', src.booking_confirm_fields);

    av(dest, 'blacklisted_users', src.settings);

    console.log('PAYLOAD', dest);

    state.payloadData = _.cloneDeep(dest);
  },

  /**
   * Set the Booking Confirm Field Config to the state.
   *
   * @param state - state of the Vuex scope.
   * @param payload - source of the values.
   * @return {void}
   */
  setBcfConfig (state, payload) {
    state.compose.booking_confirm_fields = _.merge(payload.data);
  },
  setBcfConfigDefault(state, payload) {
    console.log('settiing')
    state.compose.booking_confirm_fields_default = _.cloneDeep(payload.data);
  },

  /**
   * Set the Booking Confirm Field Config with selected municipality to the state.
   *
   * @param state - state of the Vuex scope.
   * @param payload - source of the values.
   * @return {void}
   */
  setBcfConfigMunicipality (state, payload) {
    delete payload.data['synced_from_municipality']
    state.compose.booking_confirm_fields_municipality = _.merge(payload.data);
  }

}
