<template>
      <el-dialog
        title="Email"
        :visible.sync="iVisible"
        top="15%"
        width='70%'
        :modal-append-to-body="false"
        :close-on-press-escape="false"
        :close-on-click-modal="true"
        :lock-scroll="false"
        v-loading="loading">
            <el-form :model="emailTemplate" ref="emailTemplate" >
                <el-form-item
                  label="Subject" class="label-short" prop="title">
                    <el-input v-model="iEmail.title"></el-input>
                </el-form-item>
                <el-form-item
                  label="Content" class="label-short" prop="email">
                    <!-- <el-input
                      type="textarea"
                      v-model="iEmail.email"
                      placeholder=""
                      size="medium"
                      :autosize="{ minRows: 5, maxRows: 10}">
                    </el-input> -->
                     <wysiwyg v-model="iEmail.email" :aria-placeholder="$t('enter_text')" :placeholder="$t('enter_text')"/>

                </el-form-item>
                <el-form-item :id="genContainerId('save_btn','email-template')" class="el-form-actions">
                    <el-button class="modal-size" type="default" @click="handleClose()">{{$t('cancel')}}</el-button>
                    <el-button class="modal-size" type="primary" @click="handleClickConfirm()" v-loading='loading'>{{$t('submit')}}</el-button>
                </el-form-item>
            </el-form>

      </el-dialog>
</template>

<script>

import { mapActions,mapGetters } from 'vuex';
import { isEmpty } from '../../../js/helpers/Common';
export default {
    name: 'reportToCustomer',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        email:{
            default: ''
        },
        booker:{
            default: ''
        },
        booking:{

        }
    },
     data() {
        return {
         window,
         emailTemplate:{},
         loading:false
        }
    },
    computed: {
      /**
       * Interface for this.visible
       */
      iVisible: {
        get() {
            return this.visible
        },
        set(v) {
            this.$emit('update:visible', v);
        },
      },
      iEmail:{
        get() {
            return this.email
        },
        set(v) {
            this.$emit('update:email', v);
        },
      },
      iBooker:{
        get() {
            return this.booker
        },
        set(v) {
            this.$emit('update:booker', v);
        },
      },
      iBooking:{
        get() {
            return this.booking
        },
        set(v) {
            this.$emit('update:booking', v);
        },
      }
    },
    methods:{

       ...mapActions('emailsTemplates',{
         sendEmail: 'sendEmail',
       }),
       ...mapActions('booking',{
         updateBooking: 'updateBooking',
       }),
       /**
       * Handle when the modal has been closed
       *
       * @return {void}
       */
      handleClose: function () {
          this.$emit('update:visible', false);
      }, // End of handleClose() method
      handleAddComment(payload) {
        let data = {
          comment: `Related email: <a href="${app_dt_handesk_url}/tickets/${payload.id}" target="_blank">#${payload.id}</a> created`,
          booking_id: this.iBooking.id
        }
        this.$store.dispatch('booking/addComment', data).then(r =>{
                 let params = {
                    id: this.booking.id,
                    has_tickets: 1,
                    silent: true
                  };

                  if(!isEmpty(this.booking.specific_translators)){
                    params.specific_translators = this.booking.specific_translators
                  }

                  this.updateBooking(params).then(r => {
                    window.location.reload();
                  });

              });
      },
      handleClickConfirm() {
        this.loading = true;
        this.iEmail.booker=this.iBooker;
        this.iEmail.booking=this.iBooking;
        let emails = this.iBooking.notification_types.filter(i => i.type === 'email')
        if (!isEmpty(emails)) {
          emails.forEach(i => {
            this.iEmail.recipient = i.recipient
            this.sendEmail(this.iEmail).then((r) =>{
                    // console.log(r)
              this.loading = false;
              this.$emit('update:visible', false);
              this.handleAddComment(r.data.data);
              })
          });

        }else{
          this.iEmail.recipient = this.iBooking.customer.email
            this.sendEmail(this.iEmail).then((r) =>{
                    // console.log(r)
              this.loading = false;
              this.$emit('update:visible', false);
              this.handleAddComment(r.data.data);
            })

        }


      }
    }
}
</script>
