<template>
   <div :class="`app-row ${iRefs}`" :id="iRefs" >
     <div class="app-col full-width">
    <h3>{{$t(iTitle)}}</h3>

    <div class="app-list booking-list">
        <base-list-table
                :table-data="filesData"
                :extra-cols="listCols"
                :hasDetailsCol="false"
                actions-col-width='130'
                :row-class="tableRowClassName"
                >
            <template slot="topControls">
              <div class="text-right">
                <!-- <span class="text-right">Total Records : {{listPagination.total}}</span> -->
              </div>
            </template>
            <template slot="actionsColumn" slot-scope="scope">
                <el-button class="circle" type="success" :title="$t('download')" @click="download(scope)" size="mini" icon="el-icon-download">
                </el-button>
                <el-button class="circle" type="danger" :title="$t('delete')" @click="filesDelete(scope)" size="mini" icon="el-icon-delete">
                </el-button>

            </template>
        </base-list-table>
         <el-col :span="2" :offset="20">
    <el-button
           type="primary"
            @click="uploadModal">
            {{$t('upload_files')}}<i class="el-icon-upload el-icon-right"></i>
      </el-button>
         </el-col>
                  <upload-files-modal :type="type" :getFiles="getFiles" :visible.sync="showModal"
                 :width="modalWidth"> </upload-files-modal>

    </div>
    </div>
    </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex'
import APICaller from '~/js/helpers/APICaller';
import { gep } from "~/js/helpers/Vuex";
import {isEmpty,sd} from "~/js/helpers/Common";
export default {
  name:'userFilesListPanel',
  props:{
    title:{
      type:String,
      default: 'files'
    },
    updateFile: {
      type: Boolean,
      default: false,
    },
    refs:{
      default:'Files'
    },
     modalWidth: '50%',
  },
   watch: {
      	updateFile: function(newVal, oldVal) { // watch it
         this.getFiles();
        }
      },
  mounted () {
     this.getFiles();
  },
  data(){

    const _this = this;
    return {
      showModal: false,
      type:'municipality',
      token: '',
      filesData: [{
      }],
      listCols: [{
          prop: 'id',
          label: `${this.$t('id')}`,
          width: '',
        },
        {
          prop: 'display_name',
          label: `${this.$t('display_name')}`,
          width: '',
        },
        {
          prop: 'name',
          label: `${this.$t('name')}`,
          width: '',
        },
        {
          prop: 'type',
          label: `${this.$t('type')}`,
          width: '',
        },
      ],
      tableRowClassName:[
        {
            field: 'flagged',
            value: true,
            class: 'is_flagged'
        },
        {
            field: 'is_test',
            value: true,
            class: 'is_test'
        }
      ]
    }
  },
  computed:{
    iBookings:{
      get(){
      return this.files
      },
      set(v){
        this.$emit('input',v)
      }
    },
    iTitle(){
      return this.title
    },
    iRefs(){
      return this.refs
    },
    ...mapGetters('language', {
        languageOpts: 'getLanguages'
    }),
  },
  methods:{
    ...mapActions('textTranslation', {
      downloadFile:'downloadFile'
    }),
    download(scope) {
        // window.open(app_api + gep(`storage/get?path=${scope.row.path}`, "v3"));
        let payload ={
        params: `path=${scope.row.path}&display_name=${scope.row.display_name}`,
        filename: scope.row.display_name
      }
      this.downloadFile(payload)
    },
    filesDelete(scope) {
      // console.log(scope.row);
      APICaller({
                method: 'delete',
                endpoint: gep(`users/${scope.row.id}/files`, "v3"),
            }).then(r => {
                console.log(r)
            this.getFiles();

            });
    },
    getFiles() {
       let apiPrefix = 'v3';
      this.token = localStorage.getItem('app_access_token')
      const apiOpts = {
          endpoint: gep(`municipalities/${this.$route.params.id}/files`, apiPrefix),
          method: 'get',
          headers: {
              Authorization: `bearer ${this.token}`
          },
          isDataRaw: true
        };

        APICaller(apiOpts)
          .then((res) => {
            this.filesData = res.data;
            // this.$notify.success({
            //   title: 'Success!',
            //   message: 'Files was assigned'
            // });
          })
          .catch((e) => {
            console.error(e);
            // this.$notify.error({title: 'Error', message: 'Please contact the administrator.'});
          });
    //   console.log(this.$route.params.id, 'this is my route');
    },
    uploadModal() {
      this.showModal = true;
    }
  }
}
</script>

<style>

</style>
