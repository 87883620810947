/*
|--------------------------------------------------------------------------
| Booking Status
|--------------------------------------------------------------------------
| Contains the Key - Value pair for the booking status.
| Key is the Booking Status Name, and the Value is the Booking Status ID.
*/

'use strict';

export default {
  PENDING: 1,
  POTENTIAL: 1, // pseudo booking status with the same meaning as PENDING bookings
  ASSIGNED: 2,
  STARTED: 3,
  COMPLETED: 4,
  CANCELLED: 5,
  LATE_CANCELLED: 6,
  COMPENSATION: 7,
  EXPIRED: 8,
  NOT_CARRIED_OUT_TRANSLATOR: 9,
  NOT_CARRIED_OUT_CUSTOMER: 10,
  TEMPORARY_PENDING: 11,
  LATE_CANCELLED_CUSTOMER: 12,
  LATE_CANCELLED_CUSTOMER_TRANSLATOR: 13,
  TO_BE_PLANNED: 14,
  PLANNED: 15,
}