'use strict';

const exportFormFresh = {
  name: '',
  period_start: '',
  period_end: '',
  salary_payment_date: '',
  options: {
    month: '',
    bookings: [],
    departments: [],
    companies: []
  }
};

export default {

  exportForm: window._.cloneDeep(exportFormFresh),
  exportFormFresh: window._.cloneDeep(exportFormFresh),

  list: {
    data: [],
    pagination: {
      count: 0,
      current_page: 1,
      per_page: 15,
      total: 0,
      total_pages: 0,
    },
    loading: false,
  },

  item: {
    data: {},
    loading: false,
  },

  progress: {
    invoice: {
      calculated: null,
      populated: null,
      uncalculated: null,
      unpopulated: null
    },
    salary: {
      calculated: null,
      populated: null,
      uncalculated: null,
      unpopulated: null
    },
    financial_list: {
      checked: null
    }
  },

  uncalculated: {
    invoice: [],
    salary: []
  },

  unchecked: {
    financial_list: []
  }

}
