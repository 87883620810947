// Load on dependencies
import './bootstrap';

import 'es6-promise/auto';

// Include Simple Bar lib
import 'simplebar';

// Import window.Vue...
import Vue from 'vue';

// and its plugins...
import ElementUI from './elementui';

// Language
import locale from 'element-ui/lib/locale/lang/en';
// import locale from './lang/element-ui-se';

import VueFuse from 'vue-fuse'

// Use the plugins
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });
Vue.use(VueFuse);

import './components';
import store from './store';
import router from './routes';
import middlewares from './routes/middlewares';
import i18n from './i18n';
import AppInitialization from './custom/AppInitialization';
import MobileResponsiveHandler from "./custom/MobileResponsiveHandler";
import firebase from 'firebase/app';
import {isEmpty, mapProps} from "./helpers/Common";

const VERBIAGES = 'verbiages';
const LAST_UPDATE = 'last_update';
// Use the following helpers as mixins
import globalMixin from "./custom/GlobalMixin";

Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });
Vue.mixin(globalMixin);

//rich text editor
import wysiwyg from "vue-wysiwyg";
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

Vue.use(wysiwyg, {
    hideModules: {
        "image": true,
        "link": true,
        "code":true,
        "table": true,
        "justifyLeft":true,
        "justifyCenter":true,
        "justifyRight":true,
        "headings":true
        },
}); // config is optional. more below


const firebaseConfig = {
    apiKey: "AIzaSyDt81riV5GecCgpEbTjl_K_wVuf2fzlAXA",
    authDomain: "dt-blog-786b4.firebaseapp.com",
    databaseURL: "https://dt-blog-786b4.firebaseio.com",
    projectId: "dt-blog-786b4",
    storageBucket: "dt-blog-786b4.appspot.com",
    messagingSenderId: "759111325694",
    appId: "1:759111325694:web:e06555a67330924a"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);


Vue.filter('capitalize', (value) => {
    return _.startCase(value);
});

router.beforeEach(middlewares);

// Fetch the verbiage set for i18n use.
store.dispatch('verbiages/initialize');

new Vue({
    el: '#app',
    i18n, store, router,
    beforeCreate: function() {
        AppInitialization(this);
      // MobileResponsiveHandler(this);
    },

});
