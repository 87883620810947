<template>
  <div>
    <base-list-table
      :table-data="translatorTriesToAcceptList"
      :extra-cols="listCols"
      :hasDetailsCol="false"
    ></base-list-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

//Start of export default
export default {
    /*
    |---------------------------------------------
    | Component > data
    |---------------------------------------------
    */
  data() {
    const _this=this;
    return {
      listCols: [
        {
          label: _this.$t('translator_name'),
          prop: 'translator.name',
          width: ''
        },

        {
          label: _this.$t('accepted_via'),
          prop: 'accepted_via',
          width: ''
        },
        {
          label: _this.$t('mobile'),
          prop: 'translator.mobile',
          width: ''
        },
        {
          label: _this.$t('translator_id'),
          prop: 'translator_id',
          width: ''
        },
        {
          label: _this.$t('attempted_accept_at'),
          prop: 'created_at',
          width: ''
        }
      ]
    };
  }, // end of component > data
  /*
    |---------------------------------------------
    | Component > computed
    |---------------------------------------------
    */
  computed: {
    /**
     * Getter of translator tries to accept list
     */
    ...mapGetters('bookingBatches', {
      translatorTriesToAcceptList: 'translatorTriesToAcceptList'
    }),
    /**
     * fetching booking Id from route
     */
    bookingId() {
      return this.$route.params.id;
    }
  }, // end of component > computed
    /*
    |---------------------------------------------
    | Component > methods
    |---------------------------------------------
    */
  methods: {
    /**
     * Action for translator tries to accept list
     */
    ...mapActions('bookingBatches', {
      getTranslatorsTriesToAcceptList: 'getTranslatorTriesToAcceptList'
    }),

    prepareList() {
      this.getTranslatorsTriesToAcceptList(this.bookingId);
    }
  }, //end of component > methods

    /*
    |---------------------------------------------
    | Component > mounted
    |---------------------------------------------
    */
  mounted() {
    this.prepareList();
  } //end of component > mounted
}; // End of export default
</script>
