<template>
    <div class="app-form app-fields sector-address-fields">

        <!-- Address 1 -->
        <el-form-item :id="genContainerId('address',formElId)"
                      :label="$t('address')" class="label-short">
            <google-maps-address :id="genFieldId('address',formElId)"
                                 v-model="iValue.address" @updateAddress="updateAddress"/>
        </el-form-item>
        <!-- Address 2 -->
        <el-form-item :id="genContainerId('address_2',formElId)"
                      :label="$t('tfv_address_2')" class="label-short">
            <el-input :id="genFieldId('address_2',formElId)"
                      v-model="iValue.address_2"/>
        </el-form-item>

        <!-- City -->
        <el-form-item :id="genContainerId('city',formElId)"
                      :label="$t('tfv_city')" class="label-short">
            <el-input :id="genFieldId('city',formElId)"
                      v-model="iValue.city"/>
        </el-form-item>

        <!-- Town -->
        <el-form-item :id="genContainerId('town_name',formElId)"
                      :label="$t('tfv_town')" class="label-short">
            <el-input :id="genFieldId('town_name',formElId)"
                      v-model="iValue.town_name"/>
        </el-form-item>

        <!-- Post Code -->
        <el-form-item :id="genContainerId('post_code',formElId)"
                      :label="$t('tfv_post_code')" class="label-short">
            <el-input :id="genFieldId('post_code',formElId)"
                      v-model="iValue.post_code"/>
        </el-form-item>

        <!-- Country -->
        <el-form-item :id="genContainerId('country_iso',formElId)"
                      :label="$t('country')" class="label-short">
            <el-select :id="genFieldId('country_iso',formElId)"
                       v-model="iValue.country_iso" filterable>
                <el-option v-for="(v, k) in countryIsoOpts"
                           :key="k"
                           :label="v"
                           :value="k"/>

            </el-select>
        </el-form-item>

        <!-- Office Area  -->
        <el-form-item :id="genContainerId('office_area',formElId)"
                      :label="$t('office_area')" class="label-short">
            <el-input :id="genFieldId('office_area',formElId)"
                      v-model="iValue.office_area"/>
        </el-form-item>

    </div> <!-- /.app-form app-fields sector-address-fields -->
</template>

<script>
    import {mapGetters} from "vuex";

    export default {

        /*
        |--------------------------------------------------------------------------
        | Component > props
        |--------------------------------------------------------------------------
        */
        props: {

            formElId: {
                type: String,
                default: '',
            },

            value: {
                type: Object,
                default() {
                    return {
                        address: '',
                        address_2: '',
                        city: '',
                        town_name: '',
                        post_code: '',
                        country_iso: '',
                        office_area: '',
                    };
                }
            },

        }, // End of Component > props

        /*
        |--------------------------------------------------------------------------
        | Component > computed
        |--------------------------------------------------------------------------
        */
        computed: {

            ...mapGetters('form', ['countryIsoOpts']),

            /**
             * Interface for the v-model of this component.
             */
            iValue: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                },
            },

        }, // End of Component > computed
        methods:{
            updateAddress(e){
                this.iValue.post_code = e.postal_code
                this.iValue.city = e.town
            }
        }

    } // End of export default
</script>
