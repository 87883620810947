<template>
    <div class="app-page bookings-page">

        <page-header :title="$t('bookings_about_to_expired')" />

        <div class="app-container">
            <div class="app-wrapper">
                <div class="app-row">
                    <div class="app-col booking-filter-form-container">
                        <div class="content-body">

                            <h3>{{$t('filter_booking_by')}}:</h3>
                            <booking-filter-form :v-populate-payload="payloadParams"/>

                        </div> <!-- /.content-body -->

                     <fab @update.sync="handleUpdate"/>
                    </div> <!-- /.app-col -->

                    <div class="app-col booking-list-container">
                        <div class="content-body">

                            <booking-about-expired-list :v-populate-payload="payloadParams"/>

                        </div> <!-- /.content-body -->
                    </div> <!-- /.app-col -->

                </div> <!-- /.app-row -->
            </div> <!-- /.app-wrapper -->

        </div> <!-- /.app-container -->

    </div> <!-- /.app-page bookings-page -->

</template>

<script>
import fab from '~/components/displays/FloatingActionButton'

export default {
    components: {fab},
     data(){
        let start = moment(new Date()).format("YYYY-MM-DD");
        let end = moment(new Date()).add(8, 'days').format("YYYY-MM-DD");
        return {
            payloadParams: {
                'filter[status_id]':1,
                'filter[is_test]':0,
                'filter[date_range]': `due, ${start}, ${end}`,
                'sort':'expiry',
                'per_page':50,
                append:'has_batches,translators_count,translators_sendable_count,translators_non_sendable_count'
            },
        }
    },
    methods:{
        handleUpdate(e){
            this.payloadParams = e
            console.log(this.payloadParams)
        }
    },
}
</script>

<style lang="scss" scoped>
 .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }

</style>
