<template>
    <div class="app-display" :class="[
        genBem(blockCls),
        bookingStatusCls,
        mismatchCls
    ]">
        <div :class="genBem(blockCls, 'wrapper')">
            <div :class="genBem(blockCls, 'header')">
                <a href="#" @click.prevent.stop="handleClickClose">
                    <span class="fa fa-times"></span>
                </a>

                <span class="content-title">
                    Booking Details
                </span>

            </div> <!-- side-header -->

            <div v-if="booking.id !== undefined"
                 :class="genBem(blockCls, 'body')">

                <div :class="genBem(blockCls, 'body-wrapper')">
                    <div :class="genBem(blockCls, 'body__info-id')">
                        <span class="content-label">
                            ID:
                        </span>
                        <a href="#" class="content-value" @click.prevent.stop="handleClickBookingId(booking.id)">
                            #{{ booking.id }}
                        </a>
                    </div>
                    <div :class="genBem(blockCls, 'body__info-company')">
                        <span class="content-label">
                            Company:
                        </span>
                        <span class="content-value">
                            {{ bookingCustomer.customer_data.department.company.name }}
                        </span>
                    </div>
                    <div :class="genBem(blockCls, 'body__info-department')">
                        <span class="content-label">
                            Department:
                        </span>
                        <span class="content-value">
                            {{ bookingCustomer.customer_data.department.name }}
                        </span>
                    </div>
                    <div :class="genBem(blockCls, 'body__info-language')">
                        <span class="content-label">
                            Language:
                        </span>
                        <span class="content-value">
                            {{ booking.language_name }}
                        </span>
                    </div>
                    <div :class="genBem(blockCls, 'body__info-duration')">
                        <span class="content-label">
                            Duration:
                        </span>
                        <span class="content-value">
                            {{ booking.duration }} minutes
                        </span>
                    </div>
                    <div :class="genBem(blockCls, 'body__info-duration-label')">
                        <span class="content-label">
                            Range:
                        </span>
                        <span class="content-value">
                            {{ booking.start_duration_label }} - {{ booking.end_duration_label }}
                        </span>
                    </div>
                    <div :class="genBem(blockCls, 'body__info-due')">
                        <span class="content-label">
                            Due:
                        </span>
                        <span class="content-value">
                            {{ booking.due }}
                        </span>
                    </div>
                    <div :class="genBem(blockCls, 'body__info-type')">
                        <span class="content-label">
                            Type:
                        </span>
                        <span class="content-value">
                            {{ booking.type }}
                        </span>
                    </div>
                    <div :class="genBem(blockCls, 'body__info-status')">
                        <span class="content-label">
                            Status:
                        </span>
                        <span class="content-value">
                            {{ getBookingStatusName(booking.status.id) }}
                        </span>
                    </div>
                    <div :class="genBem(blockCls, 'body__info-immediate')">
                        <span class="content-label">
                            Immediate:
                        </span>
                        <span class="content-value">
                            {{ booking.immediate ? 'Yes' : 'No' }}
                        </span>
                    </div>
                    <div v-if="booking.mismatch_flags !== undefined"
                         :class="genBem(blockCls, 'body__info-mismatch-flags')">
                        <hr>
                        <span class="content-title">Mismatch Flags:</span>

                        <div :class="genBem(blockCls, 'body__info-mismatch-flags__gender')">
                            <span class="fa fa-flag content-icon"></span>
                            <span class="content-label">Gender:</span>
                            <span class="content-value">{{ booking.mismatch_flags.gender ? 'Yes' : 'No' }}</span>
                        </div>

                        <div :class="genBem(blockCls, 'body__info-mismatch-flags__translator-level')">
                            <span class="fa fa-flag content-icon"></span>
                            <span class="content-label">Translator Level:</span>
                            <span class="content-value">{{ booking.mismatch_flags.translator_level ? 'Yes' : 'No' }}</span>
                        </div>

                        <div :class="genBem(blockCls, 'body__info-mismatch-flags__specific-translator')">
                            <span class="fa fa-flag content-icon"></span>
                            <span class="content-label">Specific Translator:</span>
                            <span class="content-value">{{ booking.mismatch_flags.specific_translator ? 'Yes' : 'No' }}</span>
                            <ul v-if="booking.specific_translators.length > 0">
                                <li v-for="(b) in booking.specific_translators">
                                    <a href="#" @click.prevent.stop="handleClickTranslatorId(b.translator.id)">
                                        #{{b.translator.id}} {{b.translator.name}}
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div :class="genBem(blockCls, 'body__info-mismatch-flags__excluded-translator')">
                            <span class="fa fa-flag content-icon"></span>
                            <span class="content-label">Excluded Translator:</span>
                            <span class="content-value">{{ booking.mismatch_flags.excluded_translator ? 'Yes' : 'No' }}</span>
                        </div>

                    </div>
                </div> <!-- side-body-wrapper -->

            </div> <!-- side-body -->
        </div> <!-- side-wrapper -->
    </div> <!-- template wrapper -->
</template>

<script>
  import BOOKING_STATUS from '~/js/constants/booking-status';
  export default {

    /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
    props: {
      booking: {
        type: Object,
        required: true
      },
    },

    /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
    data () {
      return {
        blockCls: 'bookings-weekly__chart-side-info'
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
    computed: {

      /**
       * Returns a css class depending from mismatch flags.
       *
       * @returns {string}
       */
      mismatchCls () {
        const flags = this.booking.mismatch_flags;
        let result = '';

        if (flags === undefined) return '';

        // Loop tru object properties
        for (let key in flags) {
          if (flags.hasOwnProperty(key) && flags[key]) {
            result = window._.kebabCase(`mismatch-${key}`);
            break;
          }
        }

        return result === ''
          ? ''
          : this.genBem(this.blockCls, '', result);
      },

      /**
       * Returns a css class depending from booking status.
       *
       * @returns {string}
       */
      bookingStatusCls () {
        let result = '';

        if (this.booking.status.id === 1) {
          result = 'potential'
        } else {
          let str = window._.findKey(BOOKING_STATUS, (v) => v === this.booking.status.id);
          result = window._.kebabCase(str);
        }

        return this.genBem(this.blockCls, '', result);
      },

      /**
       * @returns {Object}
       */
      bookingCustomer() {
        let customer = {
          customer_data: {}
        };

        const isValidCustomer = !window._.isNil(this.booking) && !window._.isNil(this.booking.customer);

        if (isValidCustomer && window._.isObject(this.booking.customer)) {
          customer = this.booking.customer;

        } else if (isValidCustomer && window._.isArray(this.booking.customer)) {
          customer = this.booking.customer[0];
        }

        return customer;
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
    methods: {

      /**
       * Handle when the close icon was clicked.
       *
       * @return {void}
       */
      handleClickClose () {
        this.$emit('close');
      },

      /**
       * Handler when the Booking ID was clicked.
       *
       * @param id - Booking ID
       * @returns {void}
       */
      handleClickBookingId (id) {
        // Redirect the user to the booking details according to the given ID.
        const url = `/#/booking/${id}/details`;
        window.open(url, '_blank');
      },

      /**
       * Handler when the Translator ID was clicked.
       *
       * @param id - Translator ID
       * @returns {void}
       */
      handleClickTranslatorId (id) {
        // Redirect the user to the translator details according to the given ID.
        const url = `/#/user/profile/${id}?type=3`;
        window.open(url, '_blank');
      },

      /**
       *
       * @param statusId
       */
      getBookingStatusName (statusId) {
        let statusName = window._.findKey(BOOKING_STATUS, (v) => v === statusId);
        return window._.lowerCase(statusName);
      }
    },
  }
</script>
