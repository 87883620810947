/*
|--------------------------------------------------------------------------
| Financial > Price Template > getter
|--------------------------------------------------------------------------
| Partial file for getter object related to the scope.
*/

'use strict';

import {keyAndLabels} from '../../../helpers/Common';

export default {

  /**
   * Returns both List data and pagination in an object
   *
   * @return {object}
   */
  list: (state) => {
    return state.list
  },

  /**
   * Returns the List's Data in array form
   *
   * @return {array}
   */
  listData: (state) => {
    return state.list.data
  },

  /**
   * Returns the List's Pagination in object form
   *
   * @return {object}
   */
  listPagination: (state) => {
    return state.list.pagination
  },

  /**
   * Returns listAsOptions array
   *
   * @return {object}
   */
  listAsOptions: (state) => {
    return state.listAsOptions;
  },

  /**
   * Returns listAsOptions array in paired format
   *
   * @return {object}
   */
  listAsOptionsPaired: (state) => {
    let mapper = {key: 'id', label: 'name'};
    return keyAndLabels(state.listAsOptions, mapper);
  },

}