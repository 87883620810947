
import {gep} from "~/js/helpers/Vuex";
export default {
    // getLogs()
    async getBookingComLogs({commit},payload){
        commit('setLoading',true)
        // let response = await axios({
        //     url: `${app_dt_notifier_url}/api/v1/notifications`,
        //     method:'get',
        //     params:{
        //         'filter[booking_id]': payload,
        //         'include': 'delivery_status',
        //         'all':true
        //     }
        // })
      let url = app_dt_notifier_url + '/api/v3/notifications';
      let token = localStorage.getItem("app_access_token");
      let response = await axios.get(`${url}`,{
        headers:{
          'Authorization': 'Bearer '+ token
        },
       params: {
          'filter[booking_id]': payload,
         'fields[notification_requests]': 'id,template_code,recipients,notification_type,created_at,booking_id,not_sending_reason,should_send',
         //  'include': 'delivery_status',
         // 'fields[delivery_status]': ''
         'all':true
        }
      });

        if(response.status===200){
            let data = response.data.data.items
            commit('setLogs',data)
            let emails = _.filter(data, ['notification_type','email']);

            commit('setEmailLogs',emails)
            let fax = _.filter(data, ['notification_type','fax']);
            commit('setFaxLogs',fax)
            let sms = _.filter(data, ['notification_type','sms']);
            commit('setSmsLogs',sms)
            let push = _.filter(data, ['notification_type','push']);
            commit('setPushLogs',push)
            commit('setLoading',false)

        }else{
            console.error(response)
        }
    },

    async postResend({},payload){
      let token = localStorage.getItem("app_access_token");

      try {
            let response = await axios({
                url: `${app_dt_notifier_url}/api/v1/resend/${payload.id}`,
                method:'post',
                data:payload,
                headers:{
                  'Authorization': 'Bearer '+ token
                }
            })

            return response;
        } catch (error) {
            return error;
        }

    }
}
